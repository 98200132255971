import omit from 'lodash.omit';
import once from 'lodash.once';
import { observable, action, when, computed } from 'mobx';
import UIStore from './UIStore';
import Worker from '../models/Worker';

import {
  WorkerForm,
  workerFormOptions,
  workerFormFields,
  workerFormRules,
  workerFormFieldOptions,
  workerFormLabels,
  workerFormPlugins
} from 'forms/worker';

import alertErrorHandler from 'utils/alertErrorHandler';
import isDataURL from 'utils/isDataURL';

import TeamWorkerProjectsUI from './TeamWorkerProjectsUI';
import TeamWorkerCertificationsUI from './TeamWorkerCertificationsUI';

import { t } from 'utils/translate';
import history from 'utils/history';

export default class TeamWorkerProfileUI extends UIStore {
  @observable entryForEdit;
  @observable entryEditForm;
  @observable entryEditForm;
  @observable fetchingEntryForEdit;

  constructor(options) {
    super(options);

    // Editing
    this.entryForEdit = null;
    this.entryEditForm = null;
    this.entryEditForm = null;
    this.fetchingEntryForEdit = false;

    this.fetchClassificationsOnce = once(
      this.classificationsUI.fetchClassifications
    );

    this.teamWorkerProjectsUI = new TeamWorkerProjectsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.teamWorkerCertificationsUI = new TeamWorkerCertificationsUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound async setup(id) {
    await this.setupWorkerAttributes();
    this.findOrFetchWorker(id);
    this.fetchClassificationsOnce();
  }

  @action.bound tearDown() {
    this.cancelEntryEdit();
    this.tearDownWorkerAttributes();
  }

  @action.bound cancelEntryEdit() {
    this.clearValidationDetails();
    this.entryForEdit = null;
    this.entryEditForm = null;
    this.fetchingEntryForEdit = false;
  }

  @action.bound async findOrFetchWorker(workerUuid) {
    if (this.entryForEdit || this.fetchingEntryForEdit) return;

    let workerModel = this.workers.models.find(
      worker => worker.uuid === workerUuid
    );

    if (workerModel) {
      this.fetchingEntryForEdit = true;

      await workerModel.fetch();

      await this.setEntryForEdit(workerModel);

      this.fetchingEntryForEdit = false;
    } else {
      workerModel = new Worker(
        {
          workerUuid: workerUuid,
          uuid: workerUuid
        },
        {
          rootStore: this.rootStore
        }
      );

      try {
        this.fetchingEntryForEdit = true;

        await workerModel.fetch();

        await this.setEntryForEdit(workerModel);
      } catch (error) {
        this.backToWorkers(true);
      } finally {
        this.fetchingEntryForEdit = false;
      }
    }
  }

  @action.bound async setEntryForEdit(worker) {
    this.entryForEdit = worker;

    when(
      () => !this.entryForEdit.settings.fetchingWorkerDefaults,
      () => {
        this.entryEditForm = new WorkerForm(
          {
            fields: workerFormFields,
            rules: this.workerFormRules,
            labels: workerFormLabels,
            values: this.entryForEdit.formValues,
            options: workerFormFieldOptions
          },
          {
            options: workerFormOptions,
            plugins: workerFormPlugins,
            rootStore: this.rootStore
          }
        );
      }
    );
  }

  @computed get workerFormRules() {
    return Object.assign({}, workerFormRules, {
      email: `string|email|max:200|worker_email_available`
    });
  }

  @computed get workerExists() {
    return (
      this.entryEditForm.existingWorker &&
      this.entryEditForm.existingWorker.id !== this.entryForEdit.id
    );
  }

  @computed get workerExistsMessage() {
    if (!this.workerExists) return '';

    if (this.entryEditForm.$('employeeId').value) {
      return t('A worker with this name and Employee Id already exists.');
    }

    return t(
      'A worker this name already exists. Use a unique Employee Id to differentiate.'
    );
  }

  @computed get entryEditFormInvalid() {
    return this.workerExists || this.entryEditForm.check('hasError');
  }

  @computed get disableEntryEditSaveButton() {
    if (!this.authorization.canEditWorkers) return true;

    return this.entryForEdit.saving || this.entryEditFormInvalid;
  }

  @action.bound submitEntryEditForm(event) {
    event.preventDefault();

    if (this.entryEditForm.submitting) return;

    this.entryEditForm.submit({
      onSuccess: this.submitEntryEditFormSuccess,
      onError: this.submitEntryEditFormError
    });
  }

  @action.bound async submitEntryEditFormSuccess() {
    if (this.saving) return;

    this.clearValidationDetails();

    const values = this.entryEditForm.trimmedValues();

    values.settings = {
      workerDefaultShift: values.defaultShift.uuid,
      workerDefaultCostCode: values.defaultCostCode.uuid,
      workerDefaultCrewName: values.defaultCrewName.uuid
    };

    if (isDataURL(values.avatar)) {
      values.avatar = {
        base64: values.avatar
      };
    } else {
      delete values.avatar;
    }

    values.groupUuids = values.groups.map(group => group.uuid);

    delete values.groups;

    // Todo classificationId to be deprecated
    delete values.classificationId;

    try {
      await this.entryForEdit.save(
        omit(values, [
          'id',
          'defaultShift',
          'defaultCostCode',
          'defaultCrewName'
        ]),
        {
          wait: true,
          stripNonRest: false
        }
      );

      // Used to get the hasWorkersWithoutContactDetails field when things change.
      this.company.fetch();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Worker info saved')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound submitEntryEditFormError() {
    history.push(`${this.entryForEdit.viewUrl}/info`);
    console.error(this.entryEditForm.errors());
  }

  @action.bound backToWorkers(replaceHistory = false) {
    if (replaceHistory) {
      history.replace('/workers');
    } else {
      history.push('/workers');
    }
  }

  @action.bound async setupWorkerAttributes() {
    const promises = [
      this.costCodeSelectorUI.setup(),
      this.shiftSelectorUI.setup(),
      this.classificationSelectorUI.setup(),
      this.groupSelectorUI.setup()
    ];

    try {
      await Promise.all(promises);
    } catch (error) {
      alertErrorHandler(error, this.rootStore.notificationsUI.pushError);
    }
  }

  @action.bound tearDownWorkerAttributes() {
    this.shiftSelectorUI.tearDown();
    this.classificationSelectorUI.tearDown();
    this.costCodeSelectorUI.tearDown();
    this.costCodeSelectorUI.tearDown();
    this.groupSelectorUI.tearDown();
  }

  @computed get classificationOptions() {
    return this.classificationsUI.selectableClassifications.map(
      classification => {
        return {
          uuid: classification.uuid,
          name: classification.name
        };
      }
    );
  }

  @computed get profileLinks() {
    let links = [
      {
        path: `${this.entryForEdit.viewUrl}/info`,
        label: t('Info')
      }
    ];

    if (this.authorization.canEditWorkers) {
      links.push({
        path: `${this.entryForEdit.viewUrl}/attributes`,
        label: t('Default attributes')
      });
    }

    if (this.authorization.canEditWorkerProjects) {
      links.push({
        path: `${this.entryForEdit.viewUrl}/projects`,
        label: t('Projects')
      });
    }

    links.push({
      path: `${this.entryForEdit.viewUrl}/certifications`,
      label: t('Certifications')
    });

    return links;
  }

  @computed get selectedGroupUuids() {
    return this.entryEditForm
      .$('groups')
      .values()
      .map(value => value.uuid);
  }

  @computed get selectedGroupOptions() {
    return this.groupSelectorUI.options.filter(option =>
      this.selectedGroupUuids.includes(option.uuid)
    );
  }
}
