import { action, observable, computed } from 'mobx';
import ProjectChildUI from './ProjectChildUI';
import { t } from 'utils/translate';
import history from 'utils/history';
import { insertIf } from 'utils/insertIf';

export default class SettingsChildUI extends ProjectChildUI {
  @observable form;
  @observable nextUrl;

  constructor(options) {
    super(options);
    this.form = null;
    this.nextUrl = null;
  }

  @action.bound tearDown() {
    this.clearUIState();
    this.unblockHistory();
  }

  @action.bound blockHistoryIfFormChanged() {
    this.unblock = history.block((location, action) => {
      if (this.form.check('isDirty')) {
        this.showDiscardModal(location.pathname);
        return 'Blocked';
      }
    });
  }

  @action.bound unblockHistory() {
    this.unblock && this.unblock();
  }

  @action.bound
  showDiscardModal(nextUrl) {
    this.showModal('DiscardChangesModal');
    this.nextUrl = nextUrl;
  }

  @action.bound
  async discardChanges() {
    await this.hideActiveModal();
    this.moveToNextUrl();
  }

  @action.bound
  async moveToNextUrl() {
    this.unblock();
    history.push(this.nextUrl);
    this.nextUrl = null;
  }

  @action.bound submitForm(event) {
    event.preventDefault();
    event.stopPropagation();

    this.form.submit({
      onSuccess: this.submitFormSuccess,
      onError: this.submitFormError
    });
  }

  @action.bound async submitFormSuccess() {
    // Override in extended store
  }

  @action.bound submitFormError() {
    console.error(this.form.errors());
  }

  @action.bound resetForm() {
    this.clearValidationDetails();
    this.form.reset();
    this.form.each(field => {
      field.resetValidation();
    });
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.nextUrl = null;
    this.activeModal = null;
    this.form = null;
    this.saving = false;
  }

  @computed get disableCancelButton() {
    return Boolean(this.saving || !this.hasWriteAccess);
  }

  @computed get disableSaveButton() {
    return Boolean(this.saving || !this.hasWriteAccess);
  }

  @computed get saveButtonText() {
    if (this.saving) {
      return t('Saving...');
    }

    return t('Save');
  }

  @computed get hasWriteAccess() {
    // Override in extended store based on authorization.canDoSomething rule
    return true;
  }

  @computed get showProductionSettings() {
    return (
      this.authorization.canEditWorkers ||
      this.authorization.canEditCostCodes ||
      this.authorization.canCUDMaterials
    );
  }

  @computed get navigationTabs() {
    let tabs;
    const pathname = history.location.pathname;
    switch (true) {
      case pathname.includes('/general'):
        tabs = [
          {
            label: t('Info'),
            path: `/settings/general/info`,
            dataQA: 'info'
          },
          {
            label: t('PDF & logo'),
            path: `/settings/general/pdf-logo`,
            dataQA: 'pdf-logo'
          },
          {
            label: t('Locations'),
            path: `/settings/general/locations`,
            dataQA: 'locations'
          }
        ];
        break;
      case pathname.includes('/daily-reporting'):
        tabs = [
          {
            label: t('Report display'),
            path: `/settings/daily-reporting/display`,
            dataQA: 'display'
          },
          {
            label: t('Report sections'),
            path: `/settings/daily-reporting/reports`,
            dataQA: 'included-sections'
          },
          {
            label: t('Survey'),
            path: `/settings/daily-reporting/survey`,
            dataQA: 'survey'
          },
          {
            label: t('Recipients'),
            path: `/settings/daily-reporting/recipients`,
            dataQA: 'recipients'
          },
          {
            label: t('Segments'),
            path: `/settings/daily-reporting/segments`,
            dataQA: 'segments'
          }
        ];
        break;
      case pathname.includes('/payroll-time'):
        tabs = [
          ...[
            {
              label: t('Track time'),
              path: `/settings/payroll-time/track-time`,
              dataQA: 'track-time'
            },
            {
              label: t('Pay types'),
              path: `/settings/payroll-time/pay-types`,
              dataQA: 'pay-types'
            },
            {
              label: t('Breaks'),
              path: `/settings/payroll-time/breaks`,
              dataQA: 'breaks'
            },
            {
              label: t('Shifts'),
              path: `/settings/payroll-time/shifts`,
              dataQA: 'shifts'
            },
            {
              label: t('Project radius'),
              path: `/settings/payroll-time/radius`,
              dataQA: 'radius'
            },
            {
              label: t('Time capture'),
              path: `/settings/payroll-time/time-capture`,
              dataQA: 'time-capture'
            }
          ]
        ];
        break;
      case pathname.includes('/production-tracking'):
        tabs = [
          {
            label: t('Cost codes'),
            path: `/settings/production-tracking/costcodes`,
            dataQA: 'cost-codes'
          },
          {
            label: t('Materials'),
            path: `/settings/production-tracking/materials`,
            dataQA: 'materials'
          },
          {
            label: t('Budgets'),
            path: `/settings/production-tracking/budgets`,
            dataQA: 'budgets'
          }
        ];
        break;
      case pathname.includes('/photos'):
        tabs = insertIf(this.featureFlags.FF_WATERMARKING_SETTINGS, [
          {
            label: t('Watermark'),
            path: `/settings/photos/watermark`,
            dataQA: 'watermark'
          }
        ]);
        break;
      default:
        tabs = [];
    }
    return tabs;
  }

  @computed get headerTitle() {
    return t('Manage project settings');
  }
}
