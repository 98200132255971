import { action, computed } from 'mobx';
import ProjectChildEditUI from './../ProjectChildEditUI';
import EquipmentDeployment from 'stores/models/equipment/EquipmentDeployment';

import history from 'utils/history';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';

import { callTrack } from 'utils/segmentIntegration';
import { EQUIPMENT_LOG_EDITED } from 'utils/segmentAnalytics/eventSpec';

import EquipmentLogsUI from './EquipmentLogsUI';

import {
  EquipmentDeploymentForm,
  equipmentDeploymentFormOptions,
  equipmentDeploymentFormFields,
  equipmentDeploymentFormRules,
  equipmentDeploymentFormLabels,
  equipmentDeploymentFormPlugins,
  equipmentDeploymentFormRelated
} from 'forms/project/equipmentDeployment';

export default class EquipmentDeploymentEditUI extends ProjectChildEditUI {
  constructor(options) {
    super(options);

    this.equipmentLogsUI = new EquipmentLogsUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound setup(id) {
    this.equipmentSelectorUI.setup();
    this.costCodeSelectorUI.setup();
    this.fetchEntry(id);
  }

  @action.bound tearDown() {
    this.equipmentSelectorUI.tearDown();
    this.costCodeSelectorUI.tearDown();
    this.unblockHistory();
    this.clearUIState();
  }

  @action.bound async fetchEntry(uuid) {
    if (this.entryForEdit) return;

    let model = this.parent.equipmentDeployments.getByIdOrUuid(uuid);

    if (!model) {
      model = new EquipmentDeployment(
        {
          type: 'EquipmentDeployment',
          uuid: uuid
        },
        {
          rootStore: this.rootStore
        }
      );
    }

    try {
      await model.fetch();

      this.setEntryForEdit(model);
    } catch (error) {
      this.cancelEquipmentDeploymentEdit();
    }
  }

  @action.bound setEntryForEdit(model) {
    this.entryForEdit = model;

    this.entryEditForm = new EquipmentDeploymentForm(
      {
        fields: equipmentDeploymentFormFields,
        rules: equipmentDeploymentFormRules,
        values: this.entryForEdit.formValues,
        labels: equipmentDeploymentFormLabels,
        related: equipmentDeploymentFormRelated
      },
      {
        options: equipmentDeploymentFormOptions,
        plugins: equipmentDeploymentFormPlugins
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @action.bound blockHistoryIfFormChanged() {
    this.unblock = history.block((location, action) => {
      if (location.pathname.includes('notes')) return undefined;

      if (this.entryEditForm.check('isDirty')) {
        // Allow adding and editing notes if the main equipment log
        // form has changed.
        this.showDiscardModal(location.pathname);
        return 'Blocked';
      }
    });
  }

  @action.bound async submitEntryEditFormSuccess() {
    this.saving = true;

    try {
      const values = this.entryEditForm.values();

      const payload = {
        startDate: values.startDate,
        endDate: values.endDate,
        costCode: {
          uuid: values.costCode.uuid
        }
      };

      await this.entryForEdit.save(payload, {
        wait: true
      });

      callTrack(EQUIPMENT_LOG_EDITED, {
        start_date: this.entryForEdit.startDate,
        end_date: this.entryForEdit.endDate,
        cost_code: this.entryForEdit.costCode?.displayCode
      });

      this.unblockHistory();
      this.parent.refetchEquipmentDeployments();
      this.cancelEquipmentDeploymentEdit();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Equipment log saved')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound cancelEquipmentDeploymentEdit() {
    history.push({
      pathname: `${this.project.viewUrl}/equipment`,
      search: this.baseQueryParams
    });
  }

  @computed get title() {
    return this.entryForEdit
      ? `${this.entryForEdit?.equipment?.name} - ${this.entryForEdit?.equipment?.equipmentId}`
      : ``;
  }

  @computed get equipmentUI() {
    return this.parent.equipmentUI;
  }
}
