import { action, computed, observable } from 'mobx';
import TimeEntriesWorkLogUI from '../workLogs/TimeEntriesWorkLogUI';
import TimeEntriesWorkLog from 'stores/models/workLogs/TimeEntriesWorkLog';

import {
  TIMECARD_STARTED,
  TIMECARD_ADDED
} from 'utils/segmentAnalytics/eventSpec';

import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import history from 'utils/history';

export default class WorkLogAddCrewUI extends TimeEntriesWorkLogUI {
  @observable nextUrl;

  constructor(options) {
    super(options);
    this.nextUrl = null;
  }

  @computed get title() {
    return this.parent.title;
  }

  @computed get timesheetsMode() {
    return this.parent.timesheetsMode;
  }

  @computed get backButtonText() {
    return this.parent.backButtonText;
  }

  @computed get project() {
    return this.parent.project;
  }

  @computed get workLogParams() {
    return {
      date: this.parent.date,
      projectUuid: this.parent.projectUuid,
      segmentUuid: this.parent.segmentUuid
    };
  }

  @computed
  get currentTimeEntriesWorkLogDate() {
    return this.parent.date;
  }

  @action.bound setup() {
    this.sendTimeCardAnalyticEvent(TIMECARD_STARTED);

    this.setupSelectors();
    this.workLogWorkersUI.fetchWorkers();
    this.fetchOvertimeRules(this.project.uuid);

    this.timeEntriesWorkLogForCreation = new TimeEntriesWorkLog(
      {
        projectUuid: this.workLogParams.projectUuid,
        segmentUuid: this.workLogParams.segmentUuid
        //reportDate: this.workLogParams.date // TODO Switch to new endpoint
      },
      { rootStore: this.rootStore }
    );

    this.initTimeEntriesWorkLogForm();
    this.addTimeCardsToTimeEntriesWorkLogForm();

    this.blockHistoryIfFormChanged();

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      if (!this.timesheetsMode) {
        this.workLogWorkersUI.showModal('searchAndSelectWorkerModal');
      }
    }, 750);
  }

  @action.bound blockHistoryIfFormChanged() {
    this.unblock = history.block((location, action) => {
      if (
        this.timeEntriesWorkLogForm.hasTimeCards ||
        !this.timeEntriesWorkLogForm.workLogIsPristine ||
        this.timeEntriesWorkLogForCreation.hasAttachments
      ) {
        this.showDiscardModal({
          pathname: location.pathname,
          search: location.search
        });
        return 'Blocked';
      }
    });
  }

  @action.bound unblockHistory() {
    this.unblock && this.unblock();
  }

  @action.bound
  showDiscardModal(nextUrl) {
    this.showModal('DiscardChangesModal');
    this.nextUrl = nextUrl;
  }

  @action.bound
  async discardChanges() {
    await this.hideActiveModal();
    this.moveToNextURL();
  }

  @action.bound
  async moveToNextURL() {
    this.unblock();
    history.push(this.nextUrl);
    this.nextUrl = null;
  }

  @action.bound tearDown() {
    clearTimeout(this.timeout);
    this.clearUIState();
    this.unblockHistory();
    this.tearDownSelectors();
  }

  @action.bound
  async saveTimeEntriesWorkLog() {
    this.timeEntriesWorkLogForm.allocateHoursWorked();
    this.timeEntriesWorkLogForm.validateBeforeSaving();

    if (this.timeEntriesWorkLogForm.workLogIsValid) {
      const payload = this.timeEntriesWorkLogForm.cleanedValues;

      if (this.currentTimeEntriesWorkLog.attachments.hasModels) {
        payload.attachments = this.currentTimeEntriesWorkLog.attachments.models.map(
          attachment => {
            return { uuid: attachment.uuid };
          }
        );
      }

      try {
        await this.currentTimeEntriesWorkLog.save(
          Object.assign(payload, this.parent.reportParams),
          {
            wait: true,
            reset: true,
            stripNonRest: false
          }
        );

        this.sendTimeCardAnalyticEvent(TIMECARD_ADDED);

        this.savingTimeCards = false;
        this.workLogLoading = false;

        if (!this.timesheetsMode) {
          this.parent.parent.sortByLastCreated();
        } else {
          this.payPeriodSelectorUI.tearDown();
          this.payPeriodSelectorUI.setup();
        }

        this.unblockHistory();
        this.cancelWorkLogAdd();

        this.rootStore.notificationsUI.pushNotification({
          snackbar: 'warning',
          icon: 'notification',
          title: t('Time entries have been saved.')
        });
      } catch (error) {
        alertErrorHandler(error, this.setValidationDetails);
        this.savingTimeCards = false;
        this.workLogLoading = false;
      }
    } else {
      this.savingTimeCards = false;
      this.workLogLoading = false;
    }
  }

  @action.bound async cancelTimeEntriesWorkLogCreationOrEditing() {
    await this.hideActiveModal();
    this.parent.cancelWorkLogAdd();
  }

  @action.bound
  cancelWorkLogAdd() {
    //prevent closing when data updating or saving
    if (this.workLogLoading || this.timeCardsLoading || this.savingTimeCards)
      return;

    this.parent.cancelWorkLogAdd();
  }

  @action.bound clearUIState() {
    this.timeEntriesWorkLogForm = null;
    this.timeEntriesWorkLogForCreation = null;
    this.workLogPage = 1;
    this.timeCardSearchQuery = '';
    this.timeCardSearchInputValue = '';
    this.workersWeekTotalHours.clear();
    this.savingTimeCards = false;
    this.workLogLoading = false;
    this.activeModal = null;
    this.nextUrl = null;
  }
}
