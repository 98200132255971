import moment from 'moment';

export default (timeFrame, customEndDay) => {
  switch (timeFrame) {
    case 'TODAY':
      return moment().format('YYYY-MM-DD');
    case 'YESTERDAY':
      return moment()
        .subtract(1, 'day')
        .format('YYYY-MM-DD');
    case 'LAST_7_DAYS':
      return moment().format('YYYY-MM-DD');
    case 'LAST_14_DAYS':
      return moment().format('YYYY-MM-DD');
    case 'LAST_4_WEEKS':
      return moment().format('YYYY-MM-DD');
    case 'THIS_MONTH':
      return moment()
        .endOf('month')
        .format('YYYY-MM-DD');
    case 'LAST_MONTH':
      return moment()
        .subtract(1, 'month')
        .endOf('month')
        .format('YYYY-MM-DD');
    case 'LAST_3_MONTHS':
      return moment().format('YYYY-MM-DD');
    case 'LAST_6_MONTHS':
      return moment()
        .endOf('month')
        .format('YYYY-MM-DD');
    case 'LAST_12_MONTHS':
      return moment()
        .endOf('month')
        .format('YYYY-MM-DD');
    case 'THIS_YEAR':
      return moment()
        .endOf('year')
        .format('YYYY-MM-DD');
    case 'LAST_YEAR':
      return moment()
        .subtract(1, 'year')
        .endOf('year')
        .format('YYYY-MM-DD');
    case 'CUSTOM':
      return customEndDay;
    default:
      return moment().format('YYYY-MM-DD');
  }
};
