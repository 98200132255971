import { RakenCollection } from '../lib';
import MaterialReport from '../models/MaterialReport';

export default class MaterialReports extends RakenCollection {
  model() {
    return MaterialReport;
  }

  url() {
    return `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/material/reports/scheduled`;
  }
}
