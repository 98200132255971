import UIStore from './UIStore';
import { action, observable, computed } from 'mobx';

import moment from 'moment-timezone';
import request from 'axios';

import {
  TimesheetsTransferForm,
  timesheetsTransferFormOptions,
  timesheetsTransferFormFields,
  timesheetsTransferFormRules,
  timesheetsTransferFormLabels,
  timesheetsTransferFormPlugins
} from 'forms/timesheetsTransfer';

import Project from 'stores/models/Project';
import alertErrorHandler from 'utils/alertErrorHandler';

export default class TimesheetTransferProjectUI extends UIStore {
  @observable displayTransferInputs;
  @observable saving;
  @observable timeSheetsTransferProject;
  @observable timesheetsTransferForm;

  constructor(options) {
    super(options);

    this.displayTransferInputs = false;
    this.saving = false;
    this.timeSheetsTransferProject = null;
  }

  @action.bound async setup() {
    await this.projectSelectorUI.setup({
      projectStates: ['ACTIVE'],
      userUuid: this.parent.timeCardToEdit.worker.uuid
    });

    this.timesheetsTransferForm = new TimesheetsTransferForm(
      {
        fields: timesheetsTransferFormFields,
        rules: timesheetsTransferFormRules,
        values: {
          project: ''
        },
        labels: timesheetsTransferFormLabels
      },
      {
        options: timesheetsTransferFormOptions,
        plugins: timesheetsTransferFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound async getProjectSegments(project) {
    /*
     * We need to fetch the related project for the form to work as
     * the form checks for segments on the project and these are not available
     * on the project data from the project selector
     */
    this.timeSheetsTransferProject = new Project(
      { uuid: project.value },
      {
        rootStore: this.rootStore
      }
    );

    await this.timeSheetsTransferProject.fetch();
  }

  @action.bound async updateProject(project) {
    this.timesheetsTransferForm?.$('project').set(project);

    await this.getProjectSegments(project);

    // Make segment a required field on the form if the project has one
    if (this.hasActiveSegments) {
      this.timesheetsTransferForm.$('segment').set('rules', 'required');
    } else {
      this.timesheetsTransferForm.$('segment').set('rules', '');
    }
  }

  @action.bound clearUIState() {
    this.displayTransferInputs = false;
    this.timeSheetsTransferProject = null;
    this.timesheetsTransferForm = null;
    this.clearValidationDetails();
  }

  @action.bound tearDown() {
    this.projectSelectorUI.tearDown();
    this.clearUIState();
  }

  @action.bound showTransferInputs() {
    this.setup();
    this.displayTransferInputs = true;
  }

  @action.bound hideTransferInputs() {
    this.clearValidationDetails();
    this.displayTransferInputs = false;
    this.tearDown();
  }

  @action.bound transferProject() {
    this.timesheetsTransferForm.submit({
      onSuccess: this.timesheetsTransferFormSuccess,
      onError: this.timesheetsTransferFormError
    });
  }

  @action.bound
  async timesheetsTransferFormSuccess() {
    this.saving = true;
    const values = this.timesheetsTransferForm.values();

    try {
      await request.post(
        `${this.rootStore.urlMicroService('performanceTracking')}/companies/${
          this.rootStore.me.company.uuid
        }/worklogs/timecards/${this.parent.timeCardToEdit.uuid}/move`,
        {
          projectUuid: values.project.value,
          segmentUuid: values.segment.uuid
        }
      );

      this.hideTransferInputs();

      this.parent.setup(this.parent.timeCardToEdit.uuid);
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  timesheetsTransferFormError() {
    console.log(this.timesheetsTransferForm.errors());
  }

  @computed
  get activeSegments() {
    return this.timeSheetsTransferProject?.getActiveSegmentsForDate(
      moment().format('YYYY-MM-DD')
    );
  }

  @computed
  get hasActiveSegments() {
    return this.activeSegments?.length > 0;
  }
}
