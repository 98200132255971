const downloadURI = (uri, name, targetBlank) => {
  const link = document.createElement('a');
  link.download = name;
  link.href = uri;
  if (targetBlank) {
    link.rel = 'noopener noreferrer';
    link.target = '_blank';
  }
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default downloadURI;
