import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled, { css } from 'styled-components';
import { Alert, Box } from '@raken/athens-web/lib';

const StyledAlert = styled(Alert)`
  align-items: center;

  & .MuiAlert-icon {
    display: none;
  }

  & .MuiAlert-message {
    flex: 1 1 auto;
  }

  & .MuiButtonBase-root {
    margin-top: 12px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: 768px) {
    & .MuiAlert-icon {
      display: initial;
    }

    & .MuiButtonBase-root {
      margin-top: 0;
    }
  }

  ${({ $hideIcon }) =>
    $hideIcon &&
    css`
      & .MuiAlert-icon {
        display: none;
      }
    `}
`;

const StyledAlertBox = styled(Box)`
  text-align: center;

  @media (min-width: 768px) {
    text-align: ${props => (props.textAlign ? props.textAlign : 'initial')};
    display: flex;
    align-items: center;
    justify-content: ${props =>
      props.justifyContent ? props.justifyContent : 'space-between'};
  }
`;

const AlertBanner = observer(props => {
  const { children, button, hideIcon, ...alertProps } = props;
  return (
    <Box mb={4}>
      <StyledAlert severity={'warning'} $hideIcon={hideIcon} {...alertProps}>
        <StyledAlertBox
          justifyContent={props.justifyContent}
          textAlign={props.textAlign}
        >
          <Box>{props.children}</Box>
          {props.button && <Box flex={'0 0 auto'}>{props.button}</Box>}
        </StyledAlertBox>
      </StyledAlert>
    </Box>
  );
});

AlertBanner.propTypes = {
  hideIcon: PropTypes.bool,
  children: PropTypes.any.isRequired
};

AlertBanner.defaultProps = {
  hideIcon: false
};

export default AlertBanner;
