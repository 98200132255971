import request from 'axios';
import debounce from 'lodash.debounce';
import orderBy from 'lodash.orderby';
import { action, computed, observable, reaction } from 'mobx';
import { BASE_DEBOUNCE } from 'fixtures/constants';
import { t } from 'utils/translate';
import formatIntegrationSyncMessage from 'utils/formatIntegrationSyncMessage';

import UIStore from 'stores/ui/UIStore';

import Projects from 'stores/collections/Projects';
import SageIntacctProjects from 'stores/collections/integrations/SageIntacctProjects';

import IntegrationSageIntacctProjectImportUI from './IntegrationSageIntacctProjectImportUI';
import IntegrationSageIntacctProjectMatchesUI from './IntegrationSageIntacctProjectMatchesUI';

import errorHandler from 'utils/errorHandler';
import alertErrorHandler from 'utils/alertErrorHandler';
import history from 'utils/history';

import IntegrationIds from 'fixtures/integrationIds';

export default class IntegrationSageIntacctProjectMappingUI extends UIStore {
  @observable loading;
  // Internal
  @observable rakenProjectOptionsQuery;
  @observable selectedRakenProjectOption;
  // External
  @observable loadingSageIntacctProjectOptions;
  @observable selectedSageIntacctProjectOption;
  @observable sageIntacctProjectOptionsQuery;
  // Mappings
  @observable searchQuery;
  @observable sortField;
  @observable sortDirection;
  @observable pageSize;
  @observable page;

  @observable showSelectionExpandedAlert;
  @observable selectionExpanded;

  @observable showImportAlert;

  constructor(options) {
    super(options);

    this.loading = false;

    // Internal Options
    this.rakenProjectOptionsQuery = '';
    this.selectedRakenProjectOption = null;

    // Bulk actions
    this.selectedMappings = observable([]);
    this.showSelectionExpandedAlert = false;
    this.selectionExpanded = false;

    this.showImportAlert = false;

    this.rakenProjectOptions = new Projects(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchRakenProjectOptionsDebounced = debounce(
      this.fetchRakenProjectOptions,
      BASE_DEBOUNCE
    );

    // External options
    this.sageIntacctProjectOptionsQuery = '';
    this.selectedSageIntacctProjectOption = null;

    this.sageIntacctProjectOptions = new SageIntacctProjects(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchSageIntacctProjectOptionsDebounced = debounce(
      this.fetchSageIntacctProjectOptions,
      BASE_DEBOUNCE
    );

    // Mappings
    this.searchQuery = '';
    this.sortField = 'rakenProject.name';
    this.sortDirection = 'asc';
    this.pageSize = 10;
    this.page = 1;

    this.sageIntacctProjectMappings = new SageIntacctProjects(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.integrationSageIntacctProjectImportUI = new IntegrationSageIntacctProjectImportUI(
      {
        parent: this,
        rootStore: this.rootStore
      }
    );

    this.integrationSageIntacctProjectMatchesUI = new IntegrationSageIntacctProjectMatchesUI(
      {
        parent: this,
        rootStore: this.rootStore
      }
    );
  }

  @computed get projectStatesParam() {
    if (this.isSuperAdmin) {
      return {
        projectStatuses: 'ACTIVE'
      };
    }

    return {
      projectStates: 'ACTIVE'
    };
  }

  @computed get integrations() {
    return this.parent.integrations;
  }

  @computed get activeIntegration() {
    return this.parent.activeIntegration;
  }

  @computed get activeCompany() {
    return this.parent.activeCompany;
  }

  @computed get baseUrl() {
    return this.parent.baseUrl;
  }

  @action.bound async setup() {
    this.loading = true;

    this.setupReactions();

    await Promise.all([
      this.fetchRakenProjectOptions(),
      this.fetchSageIntacctProjectOptions(),
      this.fetchSageIntacctProjectMappings()
    ]);

    this.loading = false;
  }

  @action.bound setupReactions() {
    this.cancelSearchRakenProjectOptionsReaction = reaction(
      () => this.rakenProjectOptionsQuery,
      query => {
        this.fetchRakenProjectOptionsDebounced();
      }
    );

    this.cancelSearchSageIntacctProjectOptionsReaction = reaction(
      () => this.sageIntacctProjectOptionsQuery,
      query => {
        this.fetchSageIntacctProjectOptionsDebounced();
      }
    );
  }

  @action.bound tearDown() {
    this.clearUIState();
    this.tearDownReactions();
  }

  @action.bound tearDownReactions() {
    this.cancelSearchRakenProjectOptionsReaction();
    this.cancelSearchSageIntacctProjectOptionsReaction();
  }

  @action.bound setRakenProjectOptionsQuery(value) {
    this.rakenProjectOptionsQuery = value;
  }

  @action.bound
  async fetchRakenProjectOptions(options) {
    try {
      await this.rakenProjectOptions.fetch({
        params: {
          sortField: 'name',
          sortDirection: 'asc',
          limit: 50,
          externalId: 'null',
          query: this.rakenProjectOptionsQuery,
          projectTypes: 'PARENT',
          ...this.projectStatesParam
        }
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  fetchNextRakenProjectOptions = async rakenProjectOptionsAutocomplete => {
    const dropdown = rakenProjectOptionsAutocomplete.current;
    const scrollTop = dropdown.scrollTop;
    const scrollHeight = dropdown.scrollHeight;
    const dropdownHeight = dropdown.clientHeight;

    if (scrollTop + dropdownHeight === scrollHeight) {
      this.rakenProjectOptions.fetchNextPage()?.then(() => {
        rakenProjectOptionsAutocomplete.current.scrollTop =
          scrollHeight - dropdownHeight;
      });
    }
  };

  @computed
  get rakenProjectOptionsForRender() {
    return this.rakenProjectOptions.models.slice();
  }

  @action.bound selectRakenProjectOption(option) {
    this.selectedRakenProjectOption = option;
    this.rakenProjectOptionsQuery = '';
  }

  @action.bound setSageIntacctProjectOptionsQuery(value) {
    this.sageIntacctProjectOptionsQuery = value;
  }

  @action.bound clearSageIntacctProjectOptionsQuery() {
    this.sageIntacctProjectOptionsQuery = '';
  }

  @computed get hasSageIntacctProjectOptions() {
    return this.sageIntacctProjectOptions.hasModels;
  }

  @action.bound
  async fetchSageIntacctProjectOptions(options) {
    this.loadingSageIntacctProjectOptions = true;

    try {
      await this.sageIntacctProjectOptions.fetch({
        params: {
          limit: 50,
          unlinkedOnly: true,
          query: this.sageIntacctProjectOptionsQuery
        }
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    } finally {
      this.loadingSageIntacctProjectOptions = false;
    }
  }

  fetchNextSageIntacctProjectOptions = async scrollRef => {
    const scrollableElement = scrollRef.current;
    const scrollTop = scrollableElement.scrollTop;
    const scrollHeight = scrollableElement.scrollHeight;
    const dropdownHeight = scrollableElement.clientHeight;

    if (scrollTop + dropdownHeight === scrollHeight) {
      this.sageIntacctProjectOptions.fetchNextPage()?.then(() => {
        scrollRef.current.scrollTop = scrollHeight - dropdownHeight;
      });
    }
  };

  @computed
  get sageIntacctProjectOptionsForRender() {
    return this.sageIntacctProjectOptions.models.slice();
  }

  @action.bound selectSageIntacctProjectOption(option) {
    this.selectedSageIntacctProjectOption = option;
    this.sageIntacctProjectOptionsQuery = '';
  }

  @action.bound
  clearUIState() {
    this.selectedMappings.clear();
    this.rakenProjectsOptionsQuery = '';
    this.rakenProjectOptions.reset();
    this.sageIntacctProjectOptions.reset();
    this.sageIntacctProjectMappings.reset();
    this.sageIntacctProjectOptionsQuery = '';
    this.clearSelectionExpanded();
    this.selectedRakenProjectOption = null;
    this.selectedSageIntacctProjectOption = null;
    this.showImportAlert = false;
    this.loading = false;
    this.page = 1;
  }

  @computed get disableAddConfigurationButton() {
    if (!this.isSuperAdmin) return true;

    if (this.saving || this.loading) return true;

    return (
      !this.selectedRakenProjectOption || !this.selectedSageIntacctProjectOption
    );
  }

  @action.bound async saveMapping() {
    this.saving = true;

    const {
      selectedRakenProjectOption,
      selectedSageIntacctProjectOption
    } = this;

    try {
      await selectedRakenProjectOption.save(
        {
          externalId: selectedSageIntacctProjectOption.id
        },
        {
          wait: true
        }
      );

      this.sageIntacctProjectMappings.add(
        Object.assign(selectedSageIntacctProjectOption.toJSON(), {
          rakenRef: selectedRakenProjectOption.toJSON(),
          isNew: true
        })
      );

      this.sortField = 'isNew';
      this.sortDirection = 'asc';
      this.page = 1;

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Project added')
      });

      this.refetchOptionsAfterChange();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound async removeMapping(mapping) {
    const sageIntacctProject = this.sageIntacctProjectMappings.get(mapping.id);

    const rakenProject = sageIntacctProject.rakenProject;

    try {
      await rakenProject.save(
        {
          externalId: ''
        },
        {
          url: `/ra/companies/${this.activeCompany.uuid}/projects/${rakenProject.uuid}`,
          wait: true
        }
      );

      this.sageIntacctProjectMappings.remove(sageIntacctProject);

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Project removed')
      });

      if (this.paginatedMappings.length < 1 && this.page > 1) {
        this.page = this.page - 1;
      }

      this.refetchOptionsAfterChange();
    } catch (error) {
      this.sageIntacctProjectMappings.add(sageIntacctProject);
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound async resyncMapping(mapping) {
    await this.authorization.checkFeatureAccess('EditIntegrations');

    try {
      const { data } = await request.post(
        `${this.integrationSageIntacctProjectImportUI.projects.url()}/sync`,
        {
          externalIds: [mapping.id]
        }
      );

      const successfulCount = data.collection.find(
        syncResult =>
          syncResult.externalId === mapping.id && syncResult.successful === true
      )
        ? 1
        : 0;
      const title = formatIntegrationSyncMessage(
        'Project resync',
        successfulCount,
        1 - successfulCount
      );
      const snackbar = successfulCount > 0 ? 'warning' : 'error';
      this.notifications.pushNotification({
        snackbar,
        icon: 'checkmark',
        title
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  @action.bound importSageIntacctProjects() {
    history.push(
      `/companies/${this.activeCompany.uuid}/integrations/${IntegrationIds.SAGE_INTACCT}/projects/import`
    );
  }

  @action.bound confirmSageIntacctProjectMatches() {
    history.push(
      `/companies/${this.activeCompany.uuid}/integrations/${IntegrationIds.SAGE_INTACCT}/projects/matches`
    );
  }

  @computed get hasSelectedMappings() {
    return this.selectedMappings.length > 0;
  }

  @action.bound async refetchOptionsAfterChange() {
    this.selectedRakenProjectOption = null;
    this.selectedSageIntacctProjectOption = null;

    this.rakenProjectOptionsQuery = '';
    this.sageIntacctProjectOptionsQuery = '';

    this.rakenProjectOptions.reset();
    this.sageIntacctProjectOptions.reset();

    this.fetchRakenProjectOptions();
    this.fetchSageIntacctProjectOptions();
  }

  // Mappings
  @action.bound
  async fetchSageIntacctProjectMappings() {
    try {
      await this.sageIntacctProjectMappings.fetch({
        params: {
          limit: 1000,
          linkedOnly: true
        }
      });

      if (this.sageIntacctProjectMappings.nextOffset) {
        this.fetchNextSageIntacctProjectMappings();
      }
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  @action.bound async fetchNextSageIntacctProjectMappings() {
    await this.sageIntacctProjectMappings.fetchNextPage();

    if (this.sageIntacctProjectMappings.nextOffset) {
      this.fetchNextSageIntacctProjectMappings();
    }
  }

  @computed get mappingsLoading() {
    return this.sageIntacctProjectMappings.fetching;
  }

  @computed get hasMappings() {
    return this.mappings.length > 0;
  }

  @computed get sortedMappings() {
    if (this.sortField === 'isNew') {
      return orderBy(
        this.mappings,
        [this.sortField, 'name'],
        [this.sortDirection]
      );
    }

    return orderBy(this.mappings, [this.sortField], [this.sortDirection]);
  }

  @computed get searchedMappings() {
    const query = this.searchQuery.toLowerCase();

    if (query) {
      return this.sortedMappings.filter(
        mapping =>
          mapping.rakenProject.name.toLowerCase().indexOf(query) >= 0 ||
          mapping.name.toLowerCase().indexOf(query) >= 0
      );
    }

    return this.sortedMappings;
  }

  @computed get hasSearchedMappings() {
    return this.searchedMappings.length > 0;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.searchedMappings.length / this.pageSize);
  }

  @computed
  get paginatedMappings() {
    return this.searchedMappings.slice(
      (this.page - 1) * this.pageSize,
      (this.page - 1) * this.pageSize + this.pageSize
    );
  }

  @computed get showTable() {
    return this.hasSearchedMappings || this.mappingsLoading;
  }

  @computed get showEmptySearchState() {
    return (
      this.searchQuery && !this.hasSearchedMappings && !this.mappingsLoading
    );
  }

  @computed get showEmptyState() {
    return (
      !this.searchQuery && !this.hasSearchedMappings && !this.mappingsLoading
    );
  }

  @action.bound setSearchQuery(value) {
    this.clearSelectionExpanded();
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.clearSelectionExpanded();
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(page) {
    this.clearSelectionExpanded();
    this.page = page;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @action.bound expandSelection() {
    this.selectionExpanded = true;
  }

  @action.bound clearSelectionExpanded() {
    this.showSelectionExpandedAlert = false;
    this.selectionExpanded = false;
  }

  @computed get mappingsNotOnPage() {
    return this.sortedMappings.length - this.paginatedMappings.length;
  }

  @computed get selectionExpandedAlertText() {
    if (this.selectionExpanded) {
      return t(
        `{mappingsCount} configurations are selected. Would you like to clear your selection?`,
        {
          templateStrings: {
            mappingsCount: this.sortedMappings.length
          }
        }
      );
    }

    return t(
      `All {mappingsOnPage} configurations on this page are selected. Would you like to expand the selection to include {mappingsNotOnPage} configurations not shown?`,
      {
        templateStrings: {
          mappingsOnPage: this.paginatedMappings.length,
          mappingsNotOnPage: this.mappingsNotOnPage
        }
      }
    );
  }

  @computed get mappings() {
    return this.sageIntacctProjectMappings.models;
  }

  findSelectedMapping = mappingId => {
    return this.selectedMappings.find(
      selectedMapping => selectedMapping.id === mappingId
    );
  };

  @action.bound
  toggleSelectMapping(mapping) {
    const selectedMapping = this.findSelectedMapping(mapping.id);

    this.clearSelectionExpanded();

    if (selectedMapping) {
      this.selectedMappings.remove(selectedMapping);
    } else {
      this.selectedMappings.push(mapping);
    }
  }

  @computed
  get allMappingsSelected() {
    if (!this.hasMappings) return false;

    return (
      this.paginatedMappings.filter(mapping =>
        this.findSelectedMapping(mapping.id)
      ).length === this.paginatedMappings.length
    );
  }

  @action.bound
  toggleSelectAllMappings() {
    if (this.allMappingsSelected) {
      this.clearSelectionExpanded();

      this.selectedMappings.replace(
        this.selectedMappings.filter(
          selectedMapping =>
            !this.paginatedMappings.some(
              mapping => mapping.id === selectedMapping.id
            )
        )
      );
    } else {
      if (this.page === 1) {
        this.showSelectionExpandedAlert = true;
      }

      this.paginatedMappings.forEach(mapping => {
        this.selectedMappings.push(mapping);
      });
    }
  }

  @action.bound clearAllSelectedMappings() {
    this.clearSelectionExpanded();
    this.selectedMappings.clear();
  }

  @computed get bulkActions() {
    return [
      {
        title: t('Resync selected projects'),
        onClick: () => {
          this.bulkResyncSelectedMappings();
        }
      },
      {
        title: t('Remove selected projects'),
        onClick: () => {
          this.bulkRemoveSelectedMappings();
        }
      }
    ];
  }

  @action.bound bulkResyncSelectedMappings() {
    this.showModal('BulkResyncSelectedMappings');
  }

  @action.bound async confirmBulkResyncSelectedMappings() {
    await this.authorization.checkFeatureAccess('EditIntegrations');

    try {
      this.saving = true;

      let externalIds;

      if (this.selectionExpanded) {
        externalIds = ['*'];
      } else {
        externalIds = this.selectedMappings.map(mapping => mapping.id);
      }

      const { data } = await request.post(
        `${this.integrationSageIntacctProjectImportUI.projects.url()}/sync`,
        {
          externalIds
        }
      );

      const successfulCount = data.collection.filter(
        syncResult => syncResult.successful === true
      ).length;
      const failedCount = data.collection.length - successfulCount;
      const title = formatIntegrationSyncMessage(
        'Projects resync',
        successfulCount,
        failedCount
      );
      const snackbar = successfulCount > 0 ? 'warning' : 'error';

      await this.hideActiveModal();

      this.notifications.pushNotification({
        snackbar,
        icon: 'checkmark',
        title
      });

      this.selectedMappings.clear();
      this.clearSelectionExpanded();
      this.refetchOptionsAfterChange();
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    } finally {
      this.saving = false;
    }
  }

  @action.bound bulkRemoveSelectedMappings() {
    this.showModal('BulkRemoveSelectedMappings');
  }

  @action.bound async confirmBulkRemoveSelectedMappings() {
    this.saving = true;

    try {
      let mappings;

      if (this.selectionExpanded) {
        mappings = this.sortedMappings;
      } else {
        mappings = this.selectedMappings;
      }

      const payload = mappings.map(mapping => {
        return {
          uuid: mapping.rakenProject.id,
          externalId: ''
        };
      });

      await request.patch(
        `ra/companies/${this.activeCompany.uuid}/projects/externalIds`,
        payload
      );

      if (this.selectionExpanded) {
        this.sageIntacctProjectMappings.clear();
      } else {
        this.sageIntacctProjectMappings.remove(mappings.slice());
      }

      this.selectedMappings.clear();
      this.clearSelectionExpanded();
      this.refetchOptionsAfterChange();

      await this.hideActiveModal();

      // Go to previous page if current page is empty after bulk delete
      if (this.paginatedMappings.length < 1 && this.page > 1) {
        this.page = this.page - 1;
      }

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Projects removed')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }
}
