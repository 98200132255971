import { RakenCollection } from '../../lib';
import ChecklistTemplate from 'stores/models/checklists/ChecklistTemplate';

export default class ChecklistTemplates extends RakenCollection {
  model() {
    return ChecklistTemplate;
  }

  url() {
    return `${this.rootStore.urlMicroService('toolbox')}/companies/${
      this.rootStore.me.company.uuid
    }/checklist-templates`;
  }
}
