import request from 'axios';
import moment from 'moment';
import { observable, action, computed, runInAction } from 'mobx';
import UIStore from './UIStore';
import omitBy from 'lodash.omitby';
import isNil from 'lodash.isnil';
import history from 'utils/history';
import parseGeolocation from 'utils/parseGeolocation';
import geocodeAddress from 'utils/geocodeAddress';
import { callTrack } from 'utils/segmentIntegration';
import { PROJECT_ADDED } from 'utils/segmentAnalytics/eventSpec';
import ProjectCreationForm from 'forms/projectCreation';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';

import searchedPlaceReaction from 'utils/searchedPlaceReaction';
import countryReaction from 'utils/countryReaction';

export default class ProjectCreationUI extends UIStore {
  @observable searchedPlace;
  @observable projectCreationForm;

  @action
  setupReactions() {
    this.reactToSearchedPlace = searchedPlaceReaction(
      this,
      this.projectCreationForm
    );

    this.reactToCountry = countryReaction(this, this.projectCreationForm);
  }

  @action
  destroyReactions() {
    this.reactToSearchedPlace && this.reactToSearchedPlace();
    this.reactToCountry && this.reactToCountry();
  }

  @action
  setupProjectCreationForm() {
    this.projectCreationForm = new ProjectCreationForm(
      {
        fields: ProjectCreationForm.fields,
        rules: ProjectCreationForm.rules,
        values: Object.assign(ProjectCreationForm.values, {
          address: {
            country: this.defaultCountry
          }
        }),
        labels: ProjectCreationForm.labels
      },
      {
        options: ProjectCreationForm.options,
        plugins: ProjectCreationForm.plugins,
        rootStore: this.rootStore
      }
    );
  }

  @action
  destroyProjectCreationForm() {
    this.projectCreationForm = null;
  }

  @action.bound
  openProjectCreationLink() {
    history.push('/projects/create');
    this.showProjectCreationModal();
  }

  @action.bound
  closeProjectCreationLink() {
    history.push('/projects');
    this.hideProjectCreationModal();
  }

  @action.bound
  showProjectCreationModal() {
    return this.authorization.checkFeatureAccess('CreateProjects').then(() => {
      runInAction(() => {
        this.groupSelectorUI.setup();
        this.setupProjectCreationForm();
        this.setupReactions();
        this.setDefaultProjectTemplate();
        this.showModal('ProjectCreationModal');
      });
    });
  }

  @action.bound
  hideProjectCreationModal() {
    this.clearSearchedPlace();

    return this.hideActiveModal().then(() => {
      this.destroyReactions();
      this.destroyProjectCreationForm();
      this.groupSelectorUI.tearDown();
    });
  }

  @action.bound
  setDefaultProjectTemplate() {
    this.getProjectTemplates().then(() => {
      const defaultTemplate = this.projectTemplates.defaultTemplate;

      this.projectCreationForm.set({
        templateId: defaultTemplate.id
      });
    });
  }

  @action.bound
  getProjectTemplates() {
    return new Promise(resolve => {
      if (this.projectTemplates.length > 0) {
        resolve(this.projectTemplates);
      } else {
        this.projectTemplates
          .fetch({ params: { mc: 'full', mn: 'full' } })
          .then(projectTemplates => resolve(projectTemplates))
          .catch(err => {
            console.error(err);
          });
      }
    });
  }

  @action.bound
  submitProjectCreationForm(e) {
    e.preventDefault();
    e.stopPropagation();

    this.projectCreationForm.submit({
      onSuccess: () => {
        this.submitProjectCreationFormSuccess();
      },
      onError: () => {
        console.error(this.projectCreationForm.errors());
      }
    });
  }

  @action.bound
  async submitProjectCreationFormSuccess() {
    this.clearValidationDetails();
    this.saving = true;

    const values = this.formParams;

    // Attempt to geocode address
    const geocodedAddress = await geocodeAddress(
      Object.assign({}, values.address, {
        country: `${values.address.country}`
      })
    );

    if (geocodedAddress) {
      values.address.geolocation = geocodedAddress.location;
      values.address.postCode =
        values.address.postCode || geocodedAddress.postCode;
    }

    request
      .post(`/ra/companies/${this.company.uuid}/projects`, values)
      .then(response => {
        callTrack(PROJECT_ADDED, {
          project_id: response.data?.id,
          project_name: response.data?.name
        });

        this.hideProjectCreationModal().then(() => {
          runInAction(() => {
            this.rootStore.me.projectUuids.push(response.data.uuid);

            // Reset the header projects
            this.headerUI.headerProjectSelectorUI.resetProjectOptions();

            history.push(`/projects/${response.data.uuid}`);
          });
        });
      })
      .catch(error => {
        alertErrorHandler(error, this.setValidationDetails);
      })
      .finally(() => {
        this.saving = false;
      });
  }

  @computed
  get formParams() {
    const form = this.projectCreationForm.values();

    return {
      name: form.name,
      startDate: form.startDate
        ? moment(form.startDate).format('YYYY-MM-DD')
        : null,
      endDate: form.endDate ? moment(form.endDate).format('YYYY-MM-DD') : null,
      projectState: form.projectState,
      projectNo: form.projectNo,
      address: omitBy(form.address, isNil),
      templateId: form.templateId,
      groupUuids: form.groups.map(group => group.uuid)
    };
  }

  @action.bound
  setSearchedPlace(place) {
    this.searchedPlace = parseGeolocation(place);
  }

  @action.bound
  clearSearchedPlace() {
    this.searchedPlace = null;
  }

  @computed
  get projectTemplateSelectDisabled() {
    return (
      !this.authorization.onPerformancePlan || this.projectTemplates.fetching
    );
  }

  @computed
  get defaultCountry() {
    return this.rootStore?.me?.company?.address?.country;
  }

  @computed get stateOptions() {
    const value = this.projectCreationForm.$('address.state').value;

    if (!value || this.regions.isValidUsState(value)) {
      return this.regions.asOptions;
    }

    return [{ name: t('Unrecognized: ') + value, value: value }].concat(
      this.regions.asOptions
    );
  }

  @computed get selectedState() {
    return this.stateOptions.find(state => {
      return state.value === this.projectCreationForm.$('address.state').value;
    });
  }

  @computed get selectedGroupUuids() {
    return this.projectCreationForm
      .$('groups')
      .values()
      .map(value => value.uuid);
  }

  @computed get selectedGroupOptions() {
    return this.groupSelectorUI.options.filter(option =>
      this.selectedGroupUuids.includes(option.uuid)
    );
  }
}
