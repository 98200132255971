import styled, { css } from 'styled-components';
import media from '../utils/mediaTemplate';

const Label = styled.label`
  cursor: ${props => (props.cursor ? props.cursor : 'inherit')};
  display: block;
  font-weight: 700;
  text-transform: ${props => props.transform};
  line-height: 2rem;
  margin-right: ${props => props.marginRight || 0}px;
  text-align: ${props => props.align || 'left'};
  width: ${props => (props.width ? props.width + 'px' : 'auto')};
  ${props =>
    props.marginBottom &&
    css`
      margin-bottom: ${props => props.marginBottom}px;
    `};
  ${media.desktop`
    white-space: ${props => (props.wrapText ? 'normal' : 'nowrap')};
  `};
  ${props =>
    props.hidden &&
    css`
      visibility: hidden;
    `};
`;

Label.defaultProps = {
  marginBottom: 10,
  transform: 'capitalize'
};

export default Label;
