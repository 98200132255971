import { RakenCollection } from '../../lib';
import Ping from '../../models/superAdmin/Ping';

export default class Pings extends RakenCollection {
  model() {
    return Ping;
  }

  url() {
    return `/ra/cheaters/user/${this.rootStore.activeUser.id}/pings`;
  }
}
