import React from 'react';
import styled, { css } from 'styled-components';
import Text from './Text';
import Icon from './Icon';

import { spin, fadeInOut } from './utils/Animations';
import { t } from 'utils/translate';

const Wrapper = styled.div`
  background-color: ${props =>
    props.backdrop ? props.theme.colors.darkSteel : 'transparent'};
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: ${props => getPositionValue(props)};
  top: ${props => props.top || 0}px;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  ${Text} {
    display: block;
    animation: ${fadeInOut} 2s ease-in-out infinite -0.5s;
  }
`;

const RotatingIcon = styled.div`
  display: block;
  animation: ${spin} 2s ease-in-out infinite -0.5s;
  animation-fill-mode: forwards;
  ${props =>
    props.text &&
    css`
      margin: 0 auto 10px auto;
    `};
  transform-origin: center center;
  width: ${props => props.size / 10}rem;
  height: ${props => props.size / 10}rem;
  svg {
    display: block;
    transform-origin: center center;
  }
`;

const getPositionValue = () => ({ fixed, inline }) => {
  return fixed ? 'fixed' : inline ? 'inline' : 'absolute';
};

const Loader = ({
  size,
  fixed,
  backdrop,
  top,
  text,
  white,
  color,
  inline,
  dataQA
}) => (
  <Wrapper fixed={fixed} backdrop={backdrop} top={top} inline={inline}>
    <RotatingIcon size={size} text={text} data-qa={dataQA}>
      <Icon kind="loader" color={color} size={size} />
    </RotatingIcon>

    {text && (
      <Text color="rakenOrange" size={16}>
        {t('Loading…')}
      </Text>
    )}
  </Wrapper>
);

Loader.defaultProps = {
  size: 150,
  fixed: true,
  inline: false,
  backdrop: false,
  text: false,
  color: 'rakenOrange',
  dataQA: 'loader'
};

export default Loader;
