import moment from 'moment';
import { t } from 'utils/translate';

export default timestamp => {
  var now = moment();
  var then = moment.utc(timestamp).local();

  if (now.isSame(then, 'day')) {
    return t('Today at') + ' ' + then.format('h:mm A');
  }

  if (now.subtract(1, 'day').isSame(then, 'day')) {
    return t('Yesterday at') + ' ' + then.format('h:mm A');
  }

  if (now.isSame(then, 'year')) {
    return then.format('MMM D, h:mm A');
  }

  return then.format('MMM D, YYYY h:mm A');
};
