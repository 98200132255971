import { action, observable, computed } from 'mobx';
import UIStore from 'stores/ui/UIStore';

import Industries from 'stores/collections/company/Industries';
import SubIndustries from 'stores/collections/company/SubIndustries';
import EmployeeRanges from 'stores/collections/company/EmployeeRanges';

import alertErrorHandler from 'utils/alertErrorHandler';
import history from 'utils/history';
import { t } from 'utils/translate';

import countryReaction from 'utils/countryReaction';
import searchedPlaceReaction from 'utils/searchedPlaceReaction';

import {
  CompanyInfoForm,
  companyInfoFormOptions,
  companyInfoFormFields,
  companyInfoFormRules,
  companyInfoFormValues,
  companyInfoFormLabels,
  companyInfoFormPlugins
} from 'forms/superAdmin/companyInfo';

import parseGeolocation from 'utils/parseGeolocation';

export default class SuperAdminCompanyInfoUI extends UIStore {
  @observable companyInfoForm;
  @observable nextURL;
  @observable searchedPlace;

  constructor(options) {
    super(options);
    this.companyInfoForm = null;
    this.nextURL = null;
    this.searchedPlace = null;

    this.industries = new Industries(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.subIndustries = new SubIndustries(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.employeeRanges = new EmployeeRanges(null, {
      parent: this,
      rootStore: this.rootStore
    });
  }

  @computed
  get activeCompany() {
    return this.parent.activeCompany;
  }

  @computed
  get extendedPermissions() {
    return this.parent.extendedPermissions;
  }

  @computed
  get canToggleInternal() {
    return (
      this.parent.extendedPermissions || this.authorization.canMergeCompanies
    );
  }

  @action.bound async setup() {
    await this.fetchOptions();
    this.instantiateCompanyInfoForm();
    this.setupReactions();
  }

  @action.bound async fetchOptions() {
    await Promise.all([
      this.industries.fetch(),
      this.subIndustries.fetch(),
      this.employeeRanges.fetch()
    ]);
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.industries.clear();
    this.subIndustries.clear();
    this.employeeRanges.clear();
    this.companyInfoForm = null;
    this.searchedPlace = null;
    this.nextURL = null;
  }

  @action.bound
  instantiateCompanyInfoForm() {
    this.companyInfoForm = new CompanyInfoForm(
      {
        fields: companyInfoFormFields,
        rules: companyInfoFormRules,
        values: Object.assign(
          {},
          companyInfoFormValues,
          this.activeCompany.infoFormValues
        ),
        labels: companyInfoFormLabels,
        defaults: this.activeCompany.infoFormValues
      },
      {
        options: companyInfoFormOptions,
        plugins: companyInfoFormPlugins
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @action.bound
  setupReactions() {
    this.reactToCountry = countryReaction(this, this.companyInfoForm);
    this.reactToSearchedPlace = searchedPlaceReaction(
      this,
      this.companyInfoForm
    );
  }

  @action.bound tearDownReactions() {
    this.reactToCountry && this.reactToCountry();
    this.reactToSearchedPlace && this.reactToSearchedPlace();
  }

  @action.bound blockHistoryIfFormChanged() {
    this.unblock = history.block((location, action) => {
      if (this.companyInfoForm && this.companyInfoForm.check('isDirty')) {
        this.showExitModal(location.pathname);
        return 'Blocked';
      }
    });
  }

  @computed
  get formIsValid() {
    return Boolean(
      this.companyInfoForm && this.companyInfoForm.check('isValid')
    );
  }

  @action.bound
  cancelChanges(e) {
    e.preventDefault();
    this.companyInfoForm.reset();
  }

  @action.bound
  submitForm(e) {
    e.preventDefault();
    this.companyInfoForm.submit({
      onSuccess: this.submitCompanyFormSuccess,
      onError: this.submitCompanyFormError
    });
  }

  @action.bound
  async submitCompanyFormSuccess() {
    this.saving = true;

    try {
      const values = this.companyInfoForm.values();

      values.parentCompany = values.parentCompany.id
        ? { id: Number(values.parentCompany.id) }
        : { id: null, name: null, uuid: null };

      delete values.salesforceAccountLink;

      await this.activeCompany.save(values, {
        wait: true
      });

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: `New company settings info saved`
      });

      this.unblockHistory();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitCompanyFormError() {
    console.log(this.companyInfoForm.errors());
  }

  @action.bound
  showExitModal(nextURL) {
    this.showModal('DiscardChangesModal');
    this.nextURL = nextURL;
  }

  @action.bound
  cancelExitModal() {
    return this.hideActiveModal().then(() => {
      this.nextURL = null;
    });
  }

  @action.bound
  discardExitModal() {
    return this.hideActiveModal().then(() => {
      this.moveToNextURL();
    });
  }

  @action.bound
  saveExitModal(e) {
    this.submitForm(e);
  }

  @action.bound moveToNextURL() {
    this.unblock();
    history.push(this.nextURL);
    this.nextURL = null;
  }

  @action.bound unblockHistory() {
    this.unblock && this.unblock();
  }

  @computed get stateOptions() {
    const value = this.companyInfoForm.$('address.state').value;

    if (!value || this.regions.isValidUsState(value)) {
      return this.regions.asOptions;
    }

    return [{ name: t('Unrecognized: ') + value, value: value }].concat(
      this.regions.asOptions
    );
  }

  @action.bound
  setSearchedPlace(place) {
    this.searchedPlace = parseGeolocation(place);
  }

  @action.bound
  clearSearchedPlace() {
    this.searchedPlace = null;
  }
}
