import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { InputLink, Icon, AddOn } from '.';
import { phoneInputCss } from './utils/inputStyles';

const ControlWrapper = styled.div`
  align-items: center;
  display: flex;
  position: relative;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  > svg {
    position: absolute;
    right: 10px;
    top: ${props => (props.component === 'textarea' ? '2rem' : '50%')};
    transform: translateY(-50%);
    pointer-events: none;
  }
  ${phoneInputCss};
`;

const renderIcon = (
  component,
  hasError,
  isValid,
  showValidIcon,
  showErrorIcon
) => {
  if (component === 'selectbox' || component === 'timepicker') return;

  if (component === 'select') {
    if (isValid && showValidIcon) {
      return <Icon kind="check" color="goGreen" size={15} />;
    }

    return <Icon kind="expand" color="lightGrey3" size={15} />;
  }

  if (hasError && showErrorIcon) {
    return <Icon kind="close" color="red" size={15} />;
  }

  if (isValid && showValidIcon) {
    return <Icon kind="check" color="goGreen" size={15} />;
  }
};

const renderComponent = (children, componentProps, addOn, addOnControl) => {
  if (addOn) {
    return (
      <ControlWrapper>
        {React.Children.map(children, child => {
          return React.cloneElement(child, componentProps);
        })}
        {addOn && (
          <AddOn>
            <div>{addOn}</div>
          </AddOn>
        )}
      </ControlWrapper>
    );
  }

  return React.Children.map(children, child => {
    return React.cloneElement(child, componentProps);
  });
};

const InputGroup = ({
  hasError,
  isValid,
  errorColor,
  inputLink,
  inputLinkColor,
  onInputLinkClick,
  highlight,
  focusColor,
  component,
  children,
  showValidIcon,
  showErrorIcon,
  addOn,
  isAddOnControl,
  hasAddOnControl
}) => {
  const componentProps = {
    isValid,
    hasError,
    errorColor,
    focusColor,
    highlight,
    showValidIcon,
    withAddOn: addOn || hasAddOnControl,
    isAddOn: isAddOnControl
  };

  // React Phone Number has is own prop called isValid.
  // So cannot pass this down to the component.
  if (component === 'phonenumber') {
    delete componentProps.isValid;
  }

  return (
    <Wrapper
      hasError={hasError}
      isValid={isValid}
      errorColor={errorColor}
      focusColor={focusColor}
      component={component}
    >
      {renderComponent(children, componentProps, addOn)}

      {renderIcon(component, hasError, isValid, showValidIcon, showErrorIcon)}

      {inputLink && (
        <InputLink
          color={inputLinkColor}
          onClick={onInputLinkClick}
          data-qa={`link_${inputLink.toLowerCase()}`}
        >
          {inputLink}
        </InputLink>
      )}
    </Wrapper>
  );
};

InputGroup.propTypes = {
  hasError: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  errorColor: PropTypes.string.isRequired
};

InputGroup.defaultProps = {
  hasError: false,
  isValid: false,
  errorColor: 'red'
};

export default InputGroup;
