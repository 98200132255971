import styled from 'styled-components';

const getKnobPosition = props => {
  let size;
  if (props.size === 'large') {
    size = props.active ? '32px' : '3px';
  } else if (props.size === 'small') {
    size = props.active ? '10px' : '1px';
  } else {
    size = props.active ? '17px' : '2px';
  }
  return size;
};

const getSize = size => {
  switch (size) {
    case 'large':
      return {
        width: '35px',
        height: '36px'
      };
    case 'small':
      return {
        width: '13px',
        height: '13px'
      };
    default:
      return {
        width: '20px',
        height: '21px'
      };
  }
};

export default styled.a`
  position: absolute;
  top: ${props => (props.size === 'small' ? '1px' : '2px')};
  left: ${props => getKnobPosition(props)};
  transition: left 0.25s ease-in-out;
  outline: none;
  border: none;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.white};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  display: block;
  width: ${props => getSize(props.size).height};
  height: ${props => getSize(props.size).height};
`;
