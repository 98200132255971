import moment from 'moment';

export default (timeFrame, earliest, customStartDay) => {
  switch (timeFrame) {
    case 'TODAY':
      return moment().format('YYYY-MM-DD');
    case 'YESTERDAY':
      return moment()
        .subtract(1, 'day')
        .format('YYYY-MM-DD');
    case 'LAST_7_DAYS':
      return moment()
        .subtract(6, 'days')
        .format('YYYY-MM-DD');
    case 'LAST_14_DAYS':
      return moment()
        .subtract(13, 'days')
        .format('YYYY-MM-DD');
    case 'LAST_4_WEEKS':
      return moment()
        .subtract(27, 'days')
        .format('YYYY-MM-DD');
    case 'THIS_MONTH':
      return moment()
        .startOf('month')
        .format('YYYY-MM-DD');
    case 'LAST_MONTH':
      return moment()
        .subtract(1, 'month')
        .startOf('month')
        .format('YYYY-MM-DD');
    case 'LAST_3_MONTHS':
      return moment()
        .subtract(2, 'months')
        .subtract(1, 'day')
        .endOf('day')
        .format('YYYY-MM-DD');
    case 'LAST_6_MONTHS':
      return moment()
        .subtract(5, 'months')
        .startOf('month')
        .format('YYYY-MM-DD');
    case 'LAST_12_MONTHS':
      return moment()
        .subtract(11, 'months')
        .startOf('month')
        .format('YYYY-MM-DD');
    case 'THIS_YEAR':
      return moment()
        .startOf('year')
        .format('YYYY-MM-DD');
    case 'LAST_YEAR':
      return moment()
        .subtract(1, 'year')
        .startOf('year')
        .format('YYYY-MM-DD');
    case 'CUSTOM':
      return customStartDay;
    default:
      return moment(earliest).format('YYYY-MM-DD');
  }
};
