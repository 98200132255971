import uuid from 'uuid-v4';
import { action, reaction, computed } from 'mobx';
import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import { t } from 'utils/translate';
import pick from 'lodash.pick';

const companyProjectTemplateFormPlugins = extendedPlugins;

const companyProjectTemplateFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false,
  retrieveOnlyEnabledFields: false
};

const companyProjectTemplateFormFields = [
  'collaboratorQuestions',
  'classificationsEditableOnTimeCards',
  'classificationsOnTimeCards',
  'collaboratorQuestions[].category',
  'collaboratorQuestions[].id',
  'collaboratorQuestions[].question',
  'collaboratorQuestions[].readOnly',
  'collaboratorQuestions[].tag',
  'collaboratorQuestions[].uuid',
  'costCodesOnTimeCards',
  'breakSetting',
  'dateFormat',
  'emailInterval',
  'enableCrewGrouping',
  'enableKiosk',
  'enableManualTimeCapture',
  'enableTaskSwitching',
  'enableTimeClock',
  'enableWatermark',
  'externalMembersRecipient',
  'externalMembersRecipients[]',
  'fahrenheit',
  'frequency',
  'hiddenBreakTypes',
  'hiddenPayTypes',
  'hiddenShifts',
  'hideEmptySections',
  'hideEmptySubcontractors',
  'hideGrandTotal',
  'hideInactiveSubcontractors',
  'inches',
  'includeCompletedChecklists',
  'includeDailySurvey',
  'includeEquipment',
  'includeEquipmentForExternalMembers',
  'includeHours',
  'includeHoursToDate',
  'includeMaterialQuantity',
  'includeMaterialQuantityForExternalMembers',
  'includeMyCompanyQuestionsForSubcontractors',
  'includeNotes',
  'includeQualityControlObservations',
  'includeQuantity',
  'includeSafetyObservations',
  'includeStampsForEntries',
  'includeStampsForPhotos',
  'includeTimeCards',
  'includeTimeCardsForExternalMembers',
  'includeToolboxTalks',
  'includeWeather',
  'includeWorkLogs',
  'internalMembersRecipient',
  'internalMembersRecipients[]',
  'intervals',
  'isDefault',
  'languageId',
  'milesPerHour',
  'overtimeRuleSetUuid',
  'projectRadius',
  'questions',
  'questions[].category',
  'questions[].id',
  'questions[].question',
  'questions[].readOnly',
  'questions[].required',
  'questions[].tag',
  'questions[].uuid',
  'sendBySign',
  'superDailyIndex',
  'templateName',
  'timeCardsStartAndEndTime',
  'clockInChecklistTemplateUuid',
  'clockOutChecklistTemplateUuid',
  'requirePhotoId',
  'watermarkAlignment',
  'watermarkIncludeAutoGenLocation',
  'watermarkIncludeCompanyLogo',
  'watermarkIncludeCompanyName',
  'watermarkIncludeGPSCoords',
  'watermarkIncludeProjectName',
  'watermarkIncludeTimestamp',
  'weatherIntervals'
];

const companyProjectTemplateFormLabels = {
  templateName: t('template name'),
  languageId: t('report display language')
};

const companyProjectTemplateFormRules = {
  default: 'boolean',
  classificationsEditableOnTimeCards: 'boolean',
  classificationsOnTimeCards: 'string',
  costCodesOnTimeCards: 'string',
  breakSetting: 'string',
  dateFormat: 'string',
  emailInterval: 'string',
  enableWatermark: 'boolean',
  externalMembersRecipient: 'email',
  externalMembersRecipients: 'array',
  fahrenheit: 'boolean',
  frequency: 'required',
  hiddenBreakTypes: 'array',
  hiddenPayTypes: 'array',
  hiddenShifts: 'array',
  hideEmptySections: 'boolean',
  hideEmptySubcontractors: 'boolean',
  hideGrandTotal: 'boolean',
  hideInactiveSubcontractors: 'boolean',
  'questions[].category': 'required|string',
  'questions[].question': 'required|string',
  'collaboratorQuestions[].category': 'required|string',
  'collaboratorQuestions[].question': 'required|string',
  inches: 'boolean',
  includeCompletedChecklists: 'boolean',
  includeDailySurvey: 'boolean',
  includeEquipment: 'boolean',
  includeEquipmentForExternalMembers: 'boolean',
  includeHours: 'boolean',
  includeHoursToDate: 'boolean',
  includeMaterialQuantity: 'boolean',
  includeMaterialQuantityForExternalMembers: 'boolean',
  includeMyCompanyQuestionsForSubcontractors: 'boolean',
  includeNotes: 'boolean',
  includeQualityControlObservations: 'boolean',
  includeQuantity: 'boolean',
  includeSafetyObservations: 'boolean',
  includeStampsForEntries: 'boolean',
  includeStampsForPhotos: 'boolean',
  includeTimeCards: 'boolean',
  includeTimeCardsForExternalMembers: 'boolean',
  includeToolboxTalks: 'boolean',
  includeWeather: 'boolean',
  includeWorkLogs: 'boolean',
  internalMembersRecipient: 'email',
  internalMembersRecipients: 'array',
  intervals: 'array',
  milesPerHour: 'boolean',
  sendBySign: 'boolean',
  superDailyIndex: 'boolean',
  templateName: 'required|string',
  timeCardsStartAndEndTime: 'boolean',
  clockInChecklistTemplateUuid: 'string',
  clockOutChecklistTemplateUuid: 'string',
  requirePhotoId: 'boolean',
  watermarkAlignment: 'string',
  watermarkIncludeAutoGenLocation: 'boolean',
  watermarkIncludeCompanyLogo: 'boolean',
  watermarkIncludeCompanyName: 'boolean',
  watermarkIncludeGPSCoords: 'boolean',
  watermarkIncludeProjectName: 'boolean',
  watermarkIncludeTimestamp: 'boolean',
  weatherIntervals: 'array'
};

const companyProjectTemplateFormValues = {
  dateFormat: 'MM/dd/YYYY',
  classificationsEditableOnTimeCards: true,
  classificationsOnTimeCards: 'NOT_REQUIRED',
  collaboratorQuestions: [],
  costCodesOnTimeCards: 'NOT_REQUIRED',
  breakSetting: 'REQUIRE_DURATION_ONLY',
  emailInterval: '02:00',
  enableCrewGrouping: true,
  enableKiosk: false,
  enableManualTimeCapture: false,
  enableTaskSwitching: true,
  enableTimeClock: false,
  enableWatermark: false,
  externalMembersRecipient: '',
  externalMembersRecipients: [],
  fahrenheit: true,
  frequency: 'WEEKLY',
  hiddenBreakTypes: [],
  hiddenPayTypes: [],
  hiddenShifts: [],
  hideEmptySections: false,
  hideEmptySubcontractors: false,
  hideGrandTotal: false,
  hideInactiveSubcontractors: true,
  inches: true,
  includeCompletedChecklists: false,
  includeDailySurvey: true,
  includeEquipment: true,
  includeHours: true,
  includeHoursToDate: true,
  includeMaterialQuantity: true,
  includeMyCompanyQuestionsForSubcontractors: false,
  includeNotes: true,
  includeQualityControlObservations: true,
  includeQuantity: true,
  includeSafetyObservations: true,
  includeStampsForEntries: true,
  includeStampsForPhotos: true,
  includeTimeCards: true,
  includeTimeCardsForExternalMembers: false,
  includeToolboxTalks: false,
  includeWeather: true,
  includeWorkLogs: true,
  internalMembersRecipients: [],
  intervals: [2, 3, 4, 5, 6],
  isDefault: false,
  milesPerHour: true,
  projectRadius: 0,
  questions: [
    {
      uuid: uuid(),
      category: 'CATEGORY_SAFETY',
      question: t('Any accidents on site today?'),
      readOnly: false
    },
    {
      uuid: uuid(),
      category: 'CATEGORY_DELAYS',
      question: t('Any schedule delays occur?'),
      readOnly: false
    },
    {
      uuid: uuid(),
      category: 'CATEGORY_DELAYS_WEATHER',
      question: t('Any weather delays occur?'),
      readOnly: false
    },
    {
      uuid: uuid(),
      category: 'CATEGORY_VISITORS',
      question: t('Any visitors on site?'),
      readOnly: false
    },
    {
      uuid: uuid(),
      category: 'CATEGORY_DELAYS',
      question: t("Any areas that can't be worked on?"),
      readOnly: false
    },
    {
      uuid: uuid(),
      category: 'CATEGORY_EQUIPMENT',
      question: t('Any equipment rented on site?'),
      readOnly: false
    }
  ],
  sendBySign: true,
  superDailyIndex: false,
  templateName: '',
  timeCardsStartAndEndTime: false,
  clockInChecklistTemplateUuid: '',
  clockOutChecklistTemplateUuid: '',
  requirePhotoId: false,
  watermarkAlignment: 'BOTTOM_LEFT',
  watermarkIncludeAutoGenLocation: false,
  watermarkIncludeCompanyLogo: false,
  watermarkIncludeCompanyName: false,
  watermarkIncludeGPSCoords: false,
  watermarkIncludeProjectName: false,
  watermarkIncludeTimestamp: false,
  weatherIntervals: ['06:00', '12:00', '16:00']
};

class companyProjectTemplateForm extends MobxReactForm {
  constructor(settings, options) {
    super(settings, options);

    this.parent = options.parent;

    this.reactToIncludeMyCompanyQuestionsForSubcontractors = reaction(
      () => this.$('includeMyCompanyQuestionsForSubcontractors').value,
      value => {
        if (value) {
          this.copyQuestions();
        } else {
          this.clearCollaboratorQuestions();
        }
      }
    );
  }

  @action.bound
  updateCollaboratorQuestions(e, item, fieldName) {
    const { questionsTab } = this.parent;
    let { collaboratorQuestions } = this.values();
    const { question, category } = item.values();

    if (questionsTab === 'questions') {
      collaboratorQuestions = collaboratorQuestions.map(
        collaboratorQuestion => {
          if (
            category === collaboratorQuestion.category &&
            question === collaboratorQuestion.question
          ) {
            collaboratorQuestion[fieldName] = e.target.value;
          }

          return collaboratorQuestion;
        }
      );

      this.update({
        collaboratorQuestions: collaboratorQuestions
      });
    }

    item.update({
      [fieldName]: e.target.value
    });
  }

  @action.bound
  copyQuestions() {
    const questions = this.$('questions').values();
    const collaboratorQuestions = this.$('collaboratorQuestions').values();

    questions.reverse().forEach(question => {
      const sameQuestion = collaboratorQuestions.find(collaboratorQuestion => {
        return (
          collaboratorQuestion.question === question.question &&
          collaboratorQuestion.category === question.category
        );
      });

      if (sameQuestion) {
        collaboratorQuestions.splice(
          collaboratorQuestions.indexOf(sameQuestion),
          1
        );
      }

      collaboratorQuestions.unshift(
        Object.assign({}, pick(question, ['category', 'question']), {
          uuid: question.uuid,
          readOnly: false
        })
      );
    });

    this.update({
      collaboratorQuestions: collaboratorQuestions
    });
  }

  @action.bound
  clearCollaboratorQuestions() {
    const questions = this.$('questions').value;
    let collaboratorQuestions = this.$('collaboratorQuestions').value;

    collaboratorQuestions = collaboratorQuestions.filter(
      collaboratorQuestion => {
        return !questions.find(
          question =>
            question.category === collaboratorQuestion.category &&
            question.question === collaboratorQuestion.question
        );
      }
    );

    this.update({
      collaboratorQuestions: collaboratorQuestions
    });
  }

  @action.bound
  handleToggleEnableQuestionsForSubcontractors() {
    const includeMyCompanyQuestionsForSubcontractors = this.$(
      'includeMyCompanyQuestionsForSubcontractors'
    ).value;

    this.update({
      includeMyCompanyQuestionsForSubcontractors: !includeMyCompanyQuestionsForSubcontractors
    });
  }

  @computed
  get timeCaptureEnabled() {
    return this.$('timeCardsStartAndEndTime').value;
  }
}

export {
  companyProjectTemplateForm,
  companyProjectTemplateFormOptions,
  companyProjectTemplateFormFields,
  companyProjectTemplateFormRules,
  companyProjectTemplateFormValues,
  companyProjectTemplateFormPlugins,
  companyProjectTemplateFormLabels
};
