import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import trimObject from '../utils/trimObject';
import { t } from 'utils/translate';

const groupFormPlugins = extendedPlugins;

const groupFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const groupFormFields = [
  'groupClass',
  'groupClass.uuid',
  'groupClass.name',
  'groupName'
];

const groupFormValues = {};

const groupFormRules = {
  'groupClass.uuid': 'string|required',
  groupName: 'string|required|max:255'
};

const groupFormLabels = {
  groupName: t('group name'),
  'groupClass.uuid': t('class')
};

class GroupForm extends MobxReactForm {
  trimmedValues() {
    return trimObject(this.values());
  }
}

export {
  GroupForm,
  groupFormPlugins,
  groupFormOptions,
  groupFormFields,
  groupFormRules,
  groupFormLabels,
  groupFormValues
};
