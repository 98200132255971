import { reaction, computed } from 'mobx';
import MobxReactForm from 'mobx-react-form';
import validatorjs from 'validatorjs';
import { t } from 'utils/translate';

const plugins = { dvr: validatorjs };

const options = {
  validateOnInit: true,
  validateOnChange: true
};

const fields = [
  'timeFrameType',
  'fromDate',
  'toDate',
  'projectIncludeOption',
  'projects',
  'talkIds',
  'emails',
  'email'
];

const rules = {
  timeFrameType: 'required',
  projects: 'array|required_if:projectIncludeOption,SELECTED',
  talkIds: 'required|array',
  emails: 'array|required',
  email: 'required_without:emails|email'
};

const values = {
  projectIncludeOption: '',
  projects: [],
  talkIds: [],
  email: ''
};

const labels = {
  projects: t('Projects to include'),
  talkIds: t('Talks to include'),
  emails: t('Send to')
};

export class ToolboxTalksReportForm extends MobxReactForm {
  constructor(settings, options) {
    super(settings, options);

    this.cancelReactToEnableDatePickers = reaction(
      () => this.enableFromToDate,
      enableFromToDate => {
        if (enableFromToDate) {
          this.$('fromDate').set('rules', 'required|dateCustom:YYYY-MM-DD');
          this.$('toDate').set(
            'rules',
            'required|dateCustom:YYYY-MM-DD|afterOrSameAsCustom:fromDate,YYYY-MM-DD'
          );
        } else {
          this.$('fromDate').set('rules', '');
          this.$('toDate').set('rules', '');
        }
      },
      {
        fireImmediately: true
      }
    );
  }

  @computed get enableFromToDate() {
    return this.$('timeFrameType').value === 'SPECTIMEFRAME';
  }
}

export default {
  options,
  fields,
  rules,
  values,
  labels,
  plugins
};
