import { computed, action } from 'mobx';
import { Model } from 'mobx-mc';
import omit from 'lodash.omit';
import moment from 'moment-timezone';

export default class WorkersMapLocation extends Model {
  get restAttributes() {
    return ['location', 'workers'];
  }

  @action.bound
  parse(attributes) {
    this.parseWorkers(attributes);

    return {
      ...omit(attributes, ['workers'])
    };
  }

  @action.bound
  parseWorkers(attributes) {
    this.workers = attributes.workers.map(worker => {
      worker.expanded = false;
      return worker;
    });
  }

  idAttribute() {
    return 'uuid';
  }

  @computed get count() {
    return this.workers.length;
  }

  @computed get locationTimezone() {
    return (
      this.rootStore.companyUI.company?.address?.timezone || moment.tz.guess()
    );
  }
}
