import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/pt';

import { computed, action } from 'mobx';
import { Model } from 'mobx-mc';

import CostCodes from 'stores/collections/CostCodes';
import Shifts from 'stores/collections/Shifts';
import Groups from 'stores/collections/groups/Groups';

moment.locale('en');

export default class Settings extends Model {
  constructor(data, options) {
    super(data, options);

    this.defaultCostCodes = new CostCodes(null, {
      rootStore: this.rootStore
    });

    this.defaultShifts = new Shifts(null, {
      rootStore: this.rootStore
    });

    this.defaultCrewNames = new Groups(null, {
      rootStore: this.rootStore
    });
  }

  url() {
    return '/ra/user/settings';
  }

  // Add settings here
  get restAttributes() {
    return [
      'insightsOrderPanels',
      'projectProductionEnableCompletionTracking',
      'preventEditProjectTemplateNotice',
      'payrollHideCostCodes',
      'payrollHideStartEndBreakTimes',
      'payrollHidePayrollNotes',
      'requestUpgradeSafetySent',
      'requestUpgradeProductionSent',
      'inviteTeamMemberCompleted',
      'defaultWorklogType',
      'defaultExpandWorkLogsTableRows',
      'workerDefaultCostCode',
      'workerDefaultCrewName',
      'workerDefaultShift',
      'timeSheetsFilters',
      'blockTimeClock'
    ];
  }

  get restAttributeDefaults() {
    return {
      insightsOrderPanels:
        '["All Time Stats","Workers","Top users","Compliance by Subcontractor","Hours","Project Delays","Compliance By Project","Open Tasks","Safety Incidents","Toolbox Talks"]',
      projectProductionEnableCompletionTracking: false,
      payrollHideCostCodes: true,
      payrollHideStartEndBreakTimes: true,
      payrollHidePayrollNotes: true,
      defaultWorklogType: 'standard',
      defaultExpandWorkLogsTableRows: false,
      timeSheetsFilters: {},
      blockTimeClock: false
    };
  }

  /**
   * Override is new so all saves use PATCH
   */
  get isNew() {
    return false;
  }

  @computed
  get defaultShift() {
    if (!this.workerDefaultShift) return null;

    return this.defaultShifts?.getOrFetch(this.workerDefaultShift);
  }

  @computed
  get defaultCostCode() {
    if (!this.workerDefaultCostCode) return null;

    return this.defaultCostCodes?.getOrFetch(this.workerDefaultCostCode);
  }

  @computed
  get defaultCrewName() {
    if (!this.workerDefaultCrewName) return null;

    return this.defaultCrewNames?.getOrFetch(this.workerDefaultCrewName);
  }

  // It will return true if we are still fetching the models
  @computed get fetchingWorkerDefaults() {
    return (
      (this.workerDefaultShift && this.defaultShift.fetching) ||
      (this.workerDefaultCostCode && this.defaultCostCode.fetching) ||
      (this.workerDefaultCrewName && this.defaultCrewName.fetching)
    );
  }

  @computed get fetchingWorkerShiftsAndCostCodes() {
    return (
      (this.workerDefaultShift && this.defaultShift.fetching) ||
      (this.workerDefaultCostCode && this.defaultCostCode.fetching)
    );
  }

  @action.bound
  fetchDefaults() {
    this.defaultShift;
    this.defaultCostCode;
    this.defaultCrewName;
  }

  @action.bound
  clearDefaults() {
    this.defaultShifts.reset();
    this.defaultCostCodes.reset();
    this.defaultCrewNames.reset();
  }
}
