import { action, computed, observable } from 'mobx';
import SettingsChildUI from './SettingsChildUI';

import Material from 'stores/models/Material';

import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import history from 'utils/history';

import {
  MaterialForm,
  materialFormOptions,
  materialFormFields,
  materialFormRules,
  materialFormPlugins,
  materialFormLabels
} from 'forms/material';

export default class SettingsMaterialEditUI extends SettingsChildUI {
  @observable entryForEdit;
  @observable entryEditForm;

  @observable isDefault;

  constructor(options) {
    super(options);

    this.entryForEdit = null;
    this.entryEditForm = null;

    this.isDefault = false;
  }

  @action.bound setup(id) {
    this.fetchEntry(id);
  }

  @action.bound async fetchEntry(uuid) {
    if (this.entryForEdit) return;

    try {
      let model = this.parent.materials.get(uuid);

      if (!model) {
        model = new Material(
          {
            uuid: uuid
          },
          {
            rootStore: this.rootStore
          }
        );

        await model.fetch();
      }

      this.setEntryForEdit(model);
    } catch (error) {
      console.error(error);
      this.cancelMaterialEdit();
    }
  }

  @action.bound setEntryForEdit(model) {
    this.entryForEdit = model;

    this.entryEditForm = new MaterialForm(
      {
        fields: materialFormFields,
        rules: materialFormRules,
        labels: materialFormLabels,
        values: this.entryForEdit.formValues
      },
      {
        options: materialFormOptions,
        plugins: materialFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound submitEntryEditForm(event) {
    event.preventDefault();
    event.stopPropagation();

    this.entryEditForm.submit({
      onSuccess: this.submitEntryEditFormSuccess,
      onError: this.submitEntryEditFormError
    });
  }

  @action.bound async submitEntryEditFormSuccess() {
    this.saving = true;

    try {
      const values = this.entryEditForm.values();

      await this.entryForEdit.save(
        {
          name: values.name,
          unit: {
            uuid: values.unit
          },
          isDefault: this.isDefault,
          projects: [
            {
              uuid: this.project.uuid
            }
          ]
        },
        {
          wait: true
        }
      );

      // TODO: Uncomment once API support for sort by updated / created
      // this.parent.sortByLastUpdated();

      // TODO: Remove once API support for sort by updated / created
      this.parent.refetchMaterials();

      this.cancelMaterialEdit();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Material updated')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitEntryAddFormError() {
    console.error(this.entryEditForm.errors());
  }

  @action.bound cancelMaterialEdit() {
    history.push({
      pathname: `${this.project.viewUrl}/settings/production-tracking/materials`,
      search: this.baseQueryParams
    });
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.activeModal = null;
    this.entryForEdit = null;
    this.entryEditForm = null;
    this.isDefault = false;
    this.saving = false;
  }

  @computed get disableCancelButton() {
    return this.saving;
  }

  @computed get disableSaveButton() {
    return (
      this.saving || this.materialExistsOnProject || this.entryForEdit.isDefault
    );
  }

  @computed get saveButtonText() {
    if (this.saving) {
      return t('Saving...');
    }

    return t('Save');
  }

  @computed
  get materialExistsOnProject() {
    if (this.saving) return false;

    return (
      this.entryEditForm?.existingMaterial &&
      this.entryEditForm?.existingMaterial.getProjectByUuid(
        this.project.uuid
      ) &&
      this.entryEditForm?.existingMaterial.uuid !== this.entryForEdit.uuid
    );
  }

  @computed
  get materialExistsOnProjectText() {
    return t(
      'The material {material} already exists for this project. Please provide a unique combination.',
      {
        templateStrings: {
          material: this.entryEditForm.existingMaterial.materialKey
        }
      }
    );
  }

  @action.bound toggleIsDefault() {
    this.isDefault = !this.isDefault;
  }

  @computed get selectedUnitOption() {
    return this.parent.unitOptions.find(
      option => option.value === this.entryEditForm.$('unit').value
    );
  }
}
