import { Model } from 'mobx-mc';

export default class WorkerPhotoIdSummary extends Model {
  constructor(attributes, options) {
    super(attributes, options);
  }

  get restAttributes() {
    return [
      'workerUuid',
      'workerName',
      'photosTaken',
      'failedMatches',
      'timeSheetUuid'
    ];
  }
}
