import orderBy from 'lodash.orderby';
import { action, runInAction, observable, computed } from 'mobx';
import UIStore from './UIStore';
import { t } from 'utils/translate';
import errorHandler from 'utils/errorHandler';

import {
  ClassificationForm,
  classificationFormOptions,
  classificationFormFields,
  classificationFormRules,
  classificationFormLabels,
  classificationFormPlugins
} from 'forms/classification';

export default class ClassificationsUI extends UIStore {
  @observable classificationForm;

  constructor(options) {
    super(options);

    // Creation
    this.classificationForm = null;
    this.formToUpdate = null;
    this.fieldToUpdate = null;
  }

  @action.bound
  clearUIState() {
    this.activeModal = null;
    this.classificationForm = null;
  }

  @computed
  get sortedClassifications() {
    return orderBy(this.rootStore.classifications.models, ['name'], ['asc']);
  }

  @computed
  get selectableClassifications() {
    return this.sortedClassifications
      .filter(model => {
        return model.status !== 'DELETED';
      })
      .map(model => {
        return {
          id: model.uuid,
          uuid: model.uuid,
          name: model.name
        };
      });
  }

  @action.bound
  fetchClassifications() {
    this.rootStore.classifications.fetch({
      params: {
        limit: 10000
      }
    });
  }

  @action.bound
  showCreateClassificationModal(form, fieldName) {
    this.authorization.checkFeatureAccess('CUDClassifications').then(() => {
      runInAction(() => {
        this.formToUpdate = form;
        this.fieldToUpdate = fieldName;
        this.activeModal = 'createClassification';

        this.classificationForm = new ClassificationForm(
          {
            fields: classificationFormFields,
            rules: classificationFormRules,
            labels: classificationFormLabels
          },
          {
            options: classificationFormOptions,
            plugins: classificationFormPlugins
          }
        );
      });
    });
  }

  @action.bound
  cancelClassificationCreate() {
    this.hideActiveModal().then(() => {
      runInAction(() => {
        this.classificationForm = null;
        this.formToUpdate = null;
        this.fieldToUpdate = null;
      });
    });
  }

  @action.bound
  submitClassificationForm(e) {
    e.preventDefault();
    e.stopPropagation();

    this.classificationForm.submit({
      onSuccess: this.submitClassificationFormSuccess,
      onError: this.submitClassificationFormError
    });
  }

  @action.bound
  submitClassificationFormSuccess() {
    this.saving = true;

    this.rootStore.classifications
      .create(
        Object.assign({}, this.classificationForm.values(), {
          workerType: 'PERSON'
        }),
        {
          wait: true
        }
      )
      .then(classification => {
        runInAction(() => {
          this.saving = false;
          this.cancelClassificationCreate();
          this.updateFormWithNewClassification(classification);
        });
      })
      .catch(error => {
        this.saving = false;
        errorHandler(error, this.rootStore.notificationsUI.pushError);
      });
  }

  @action.bound
  updateFormWithNewClassification(classification) {
    this.formToUpdate.update({
      [this.fieldToUpdate]: classification.uuid
    });

    this.formToUpdate = null;
    this.fieldToUpdate = null;
  }

  @action.bound
  submitClassificationFormError() {
    console.log(this.classificationForm.errors());
  }

  @action.bound
  softDeleteClassification(classification) {
    this.authorization.checkFeatureAccess('CUDClassifications').then(() => {
      runInAction(() => {
        this.rootStore.classifications.remove(classification);

        this.rootStore.notificationsUI.pushNotification({
          onUndo: () => {
            this.cancelDeleteClassification(classification);
          },
          onDismiss: () => {
            this.confirmDeleteClassification(classification);
          },
          title: t('Classification Deleted'),
          text: t('The {classificationName} classification was deleted.', {
            templateStrings: {
              classificationName: classification.name
            }
          })
        });
      });
    });
  }

  @action.bound
  cancelDeleteClassification(classification) {
    this.rootStore.classifications.add(classification);
  }

  @action.bound
  confirmDeleteClassification(classification) {
    if (classification.status !== 'DELETED') {
      classification.destroy();
      classification.status = 'DELETED';
      this.removeClassificationFromWorkers(classification);
    }
  }

  @action.bound
  removeClassificationFromWorkers(classification) {
    this.workers.models
      .filter(worker => {
        return worker.classification.uuid === classification.uuid;
      })
      .forEach(worker => {
        worker.removeClassification();
      });
  }
}
