import MobxReactForm from 'mobx-react-form';
import { when } from 'mobx';
import { t } from 'utils/translate';
import extendedPlugins from '../utils/extendedPlugins';

const renameFormFormPlugins = extendedPlugins;

const renameFormFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const renameFormFormFields = ['name', 'formUrl', 'initialName'];

const renameFormFormRules = {
  name: 'string|required|max:250|form_name_available'
};

const renameFormFormLabels = {
  name: t('name')
};

class RenameFormForm extends MobxReactForm {
  constructor(settings, options) {
    super(settings, options);
    this.rootStore = options.rootStore;

    when(
      () => this.rootStore.me.company,
      () => {
        this.$('formUrl').set(
          `ra/companies/${this.rootStore.me.company.uuid}/forms?projectUuids=${this.rootStore.projectUI.projectUuid}`
        );
        //        'https://requestly.dev/api/mockv2/forms?username=user266'
      }
    );
  }
}

export {
  RenameFormForm,
  renameFormFormOptions,
  renameFormFormFields,
  renameFormFormRules,
  renameFormFormLabels,
  renameFormFormPlugins
};
