import { computed, action, observable } from 'mobx';

import request from 'axios';
import UIStore from './UIStore';

import ProjectsMinimal from '../collections/ProjectsMinimal';
import TeamMembersActionsProjectsUI from './TeamMembersActionsProjectsUI';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

import history from 'utils/history';
import { callTrack } from 'utils/segmentIntegration';
import { TEAM_MEMBER_REMOVED } from 'utils/segmentAnalytics/eventSpec';
import download from 'utils/download';

import { seatEstimate, seatEstimateTooltip } from 'utils/billing/seatEstimates';

export default class TeamMembersActionsUI extends UIStore {
  @observable selectedMember;
  @observable selectedMemberAction;
  @observable selectedMemberCallback;
  @observable selectedMemberRole;

  constructor(options) {
    super(options);

    // Individual Actions
    this.selectedMember = null;
    this.selectedMemberAction = null;
    this.selectedMemberCallback = null;
    this.selectedMemberRole = null;

    // Bulk Actions
    this.selectedMembers = observable([], { deep: false });

    // Bulk Project Actions
    this.teamMembersActionsProjectsUI = new TeamMembersActionsProjectsUI({
      parent: this,
      rootStore: this.rootStore
    });

    // Bulk Project Actions
    this.paginatedProjects = new ProjectsMinimal(null, {
      rootStore: this.rootStore
    });
  }

  @computed get members() {
    return this.parent.members;
  }

  @computed get projects() {
    return this.paginatedProjects;
  }

  @computed get hasProjects() {
    return this.projects.hasModels;
  }

  @action showTeamMemberProfile(user) {
    history.push(`${user.viewUrl}/info`);
  }

  getMemberActions = member => {
    let actions;

    switch (member.status) {
      case 'INVITED':
        actions = [
          {
            title: t('View profile'),
            onClick: () => {
              this.showTeamMemberProfile(member);
            }
          },
          {
            title: 'Resend invite',
            disabled: member.lastLogin,
            onClick: () => {
              this.memberAction('RESEND_INVITE', member);
            }
          },
          {
            title: t('Remove invite'),
            onClick: () => {
              this.memberAction('REMOVE_INVITE', member);
            },
            disabled: member.isMe
          }
        ];
        break;
      case 'INACTIVE':
      case 'DELETED':
        actions = [
          {
            title: t('View profile'),
            onClick: () => {
              this.showTeamMemberProfile(member);
            }
          },
          {
            title: t('Make active'),
            onClick: () => {
              this.memberAction('REACTIVATE', member);
            },
            disabled: member.isMe
          }
        ];
        break;
      default:
        actions = [
          {
            title: t('View profile'),
            onClick: () => {
              this.showTeamMemberProfile(member);
            }
          },
          {
            title: t('Make inactive'),
            onClick: () => {
              this.memberAction('DEACTIVATE', member);
            },
            disabled: member.isMe
          },
          {
            title: t('Change to worker'),
            onClick: () => {
              this.memberAction('DEMOTE', member);
            },
            disabled:
              member.status !== 'ACTIVE' ||
              member.role === 'ROLE_ACCOUNT_ADMIN' ||
              member.isMe
          },
          ...(this.authorization.hasTimeClockAccess &&
          !member.settings.blockTimeClock
            ? [
                {
                  title: t('Block from time clock'),
                  onClick: () => {
                    this.memberAction('BLOCK', member);
                  }
                }
              ]
            : []),
          ...(this.authorization.hasTimeClockAccess &&
          member.settings.blockTimeClock
            ? [
                {
                  title: t('Unblock from time clock'),
                  onClick: () => {
                    this.memberAction('UNBLOCK', member);
                  }
                }
              ]
            : []),
          {
            title: t('Delete'),
            onClick: () => {
              this.memberAction('DELETE', member);
            },
            disabled: member.isMe
          }
        ];
        break;
    }

    if (member.isPending) {
      actions.splice(1, 0, {
        title: 'Approve',
        onClick: () => {
          this.memberAction('APPROVE', member);
        }
      });
    }

    return actions;
  };

  @computed get memberActionTitle() {
    if (!this.selectedMemberAction) return null;

    switch (this.selectedMemberAction) {
      case 'DELETE':
        return t(`Delete team member?`);
      case 'DEACTIVATE':
        return t(`Deactivate team member?`);
      case 'DEMOTE':
        return t('Demote team member?');
      case 'REACTIVATE':
        return t(`Reactivate team member?`);
      case 'APPROVE':
        return t(`Approve team member?`);
      case 'RESEND_INVITE':
        return t(`Resend invite?`);
      case 'REMOVE_INVITE':
        return t(`Remove invite?`);
      case 'BLOCK':
        return t(`Block team member from time clock?`);
      case 'UNBLOCK':
        return t(`Unblock team member from time clock?`);
      default:
        return '';
    }
  }

  @computed get memberActionText() {
    if (!this.selectedMemberAction) return null;

    switch (this.selectedMemberAction) {
      case 'DELETE':
        return t(
          `You are attempting to delete a team member from your account. Doing so will remove their project assignments permanently and allow you to re-use their username. Would you like to proceed?`
        );
      case 'DEACTIVATE':
        return t(
          `You are attempting to deactivate a team member. Doing so will remove their access to Raken and open their seat, but will maintain their project assignments in case they need to be reactivated. Would you like to proceed?`
        );
      case 'DEMOTE':
        return t(
          `You are attempting to demote a team member on your account. Doing so will change their role to a Worker and will remove their ability to log into Raken and create reports. They will still be assigned to the same projects. Would you like to proceed?`
        );
      case 'REACTIVATE':
        return t(
          `You are attempting to reactivate a team member. Doing so will take up a seat on your account. Would you like to proceed?`
        );
      case 'APPROVE':
        return t(
          `This team member will take a seat on your subscription. You can then invite this member to your company's projects.`
        );
      case 'RESEND_INVITE':
        return t(
          `You are attempting to resend the invite for a team member. Doing so will send them a new invitation email. Would you like to proceed?`
        );
      case 'REMOVE_INVITE':
        return t(
          `You are attempting to remove an invite for a team member. Doing so will make any invite they have received invalid. Would you like to proceed?`
        );
      case 'BLOCK':
        return t(
          `You are attempting to block a team member from the time clock. Doing so will revoke their access from using the time clock through the mobile application. You can re enable their access at any time. Would you like to proceed?`
        );
      case 'UNBLOCK':
        return t(
          `You are attempting to unblock a team member from the time clock. Doing so will give this user access to the time clock through the mobile application. Would you like to proceed?`
        );
      default:
        return '';
    }
  }

  @computed get memberActionButtonColor() {
    switch (this.selectedMemberAction) {
      case 'DELETE':
      case 'DEACTIVATE':
      case 'DEMOTE':
      case 'REMOVE_INVITE':
      case 'BLOCK':
      case 'UNBLOCK':
        return 'red';
      default:
        return 'orange';
    }
  }

  @computed get memberActionButtonText() {
    switch (this.selectedMemberAction) {
      case 'DELETE':
        return t(`Delete`);
      case 'DEACTIVATE':
        return t(`Deactivate`);
      case 'DEMOTE':
        return t('Demote');
      case 'REACTIVATE':
        return t(`Reactivate`);
      case 'APPROVE':
        return t(`Approve`);
      case 'RESEND_INVITE':
        return t(`Resend`);
      case 'REMOVE_INVITE':
        return t(`Remove`);
      case 'BLOCK':
        return t(`Block from time clock`);
      case 'UNBLOCK':
        return t(`Unblock from time clock`);
      default:
        return '';
    }
  }

  @computed get memberActionButtonTextActive() {
    switch (this.selectedMemberAction) {
      case 'DELETE':
        return t(`Deleting...`);
      case 'DEMOTE':
        return t(`Demoting...`);
      case 'DEACTIVATE':
        return t(`Deactivating...`);
      case 'REACTIVATE':
        return t(`Reactivating...`);
      case 'APPROVE':
        return t(`Approving...`);
      case 'RESEND_INVITE':
        return t(`Resening...`);
      case 'REMOVE_INVITE':
        return t(`Removing...`);
      case 'BLOCK':
        return t(`Blocking ...`);
      case 'UNBLOCK':
        return t(`Unblocking ...`);
      default:
        return '';
    }
  }

  @computed get memberActionNotificationText() {
    switch (this.selectedMemberAction) {
      case 'DELETE':
        return t(`Team member deleted`);
      case 'DEMOTE':
        return t(`Team member demoted`);
      case 'DEACTIVATE':
        return t(`Team member deactivated`);
      case 'REACTIVATE':
        return t(`Team member reactivated`);
      case 'APPROVE':
        return t(`Team member approved`);
      case 'RESEND_INVITE':
        return t(`Invite sent`);
      case 'REMOVE_INVITE':
        return t(`Invite removed`);
      case 'BLOCK':
        return t(`Team member blocked`);
      case 'UNBLOCK':
        return t(`Team member unblocked`);
      default:
        return '';
    }
  }

  @action.bound async memberAction(action, member, callBack) {
    if (action === 'RESEND_INVITE' || action === 'REMOVE_INVITE') {
      await this.authorization.checkFeatureAccess('CUDCompanyInvites');
    } else {
      await this.authorization.checkFeatureAccess('CUDCompanyMembers');
    }

    this.selectedMember = member;
    this.selectedMemberAction = action;
    this.selectedMemberCallback = callBack;

    this.showModal('MemberActionModal');
  }

  @action.bound
  async cancelMemberAction() {
    await this.hideActiveModal();
    this.selectedMember = null;
    this.selectedMemberAction = null;
    this.selectedMemberCallback = null;
  }

  @action.bound
  async confirmMemberAction() {
    try {
      this.clearValidationDetails();
      this.saving = true;

      switch (this.selectedMemberAction) {
        case 'DELETE':
          await this.selectedMember.save(
            {
              status: 'DELETED'
            },
            { wait: true }
          );

          if (
            !this.parent.statusFilters
              .map(option => option.id)
              .includes('DELETED')
          ) {
            this.selectedMember.collection.remove(this.selectedMember);
          }
          break;
        case 'DEMOTE':
          await this.selectedMember.save(
            {
              role: 'ROLE_WORKER'
            },
            { wait: true }
          );

          // Remove member as they are now on the workers page
          this.selectedMember.collection.remove(this.selectedMember);
          break;
        case 'DEACTIVATE':
          await this.selectedMember.save(
            {
              status: 'INACTIVE'
            },
            { wait: true }
          );

          if (
            !this.parent.statusFilters
              .map(option => option.id)
              .includes('INACTIVE')
          ) {
            this.selectedMember.collection.remove(this.selectedMember);
          }
          break;
        case 'REACTIVATE':
          await this.reactivateMember();
          break;
        case 'APPROVE':
          await request.post(`${this.rootStore.users.url()}/approve`, {
            allowSubscriptionUpgrade: true,
            uuids: [this.selectedMember.uuid]
          });
          break;
        case 'RESEND_INVITE':
          await this.resendInvite();
          break;
        case 'REMOVE_INVITE':
          await this.selectedMember.destroy({
            wait: true
          });
          break;
        case 'BLOCK':
        case 'UNBLOCK':
          await this.selectedMember.save(
            {
              settings: {
                blockTimeClock:
                  this.selectedMemberAction === 'BLOCK' ? true : false
              }
            },
            { wait: true, stripNonRest: false }
          );
          break;
      }

      this.parent.loading = true;

      if (!this.parent.hasMembers) {
        this.parent.setPage(null, 1);
        this.parent.fetchMembers();
      } else {
        this.parent.fetchMembers();
      }

      await this.hideActiveModal();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: this.memberActionNotificationText
      });

      this.parent.refetchSubscription();

      if (this.selectedMemberCallback) {
        this.selectedMemberCallback();
      }

      this.selectedMember = null;
      this.selectedMemberAction = null;
      this.selectedMemberCallback = null;
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound async reactivateMember() {
    const showMemberProjects = this.selectedMember.status === 'DELETED';

    await this.selectedMember.save(
      {
        status: 'ACTIVE',
        allowSubscriptionUpgrade: true
      },
      {
        wait: true,
        stripNonRest: false
      }
    );

    if (showMemberProjects) {
      history.push(`${this.selectedMember.viewUrl}/projects`);
    }
  }

  @action.bound
  async resendInvite() {
    await request.post(`/ra/invite/${this.selectedMember.inviteId}`, {
      companyId: this.selectedMember.company.id
    });
  }

  @action.bound trackMemberActionEvent() {
    const properties = {
      first_name: this.selectedMember.firstName,
      lastName: this.selectedMember.lastName,
      email: this.selectedMember.username
    };

    // TODO: New ticket will be to add events for each action type
    // We are only supporting the original Removed event for now.
    switch (this.selectedMemberAction) {
      // case 'DELETE':
      //   callTrack(TEAM_MEMBER_DELETED, properties);
      //   break;
      // case 'DEACTIVATE':
      //   callTrack(TEAM_MEMBER_DEACTIVATED, properties);
      //   break;
      // case 'REACTIVATE':
      //   callTrack(TEAM_MEMBER_REACTIVATED, properties);
      //   break;
      // case 'APPROVE':
      //   callTrack(TEAM_MEMBER_APPROVED, properties);
      //   break;
      // case 'REMOVE_INVITE':
      //   callTrack(INVITE_REMOVED, properties);
      //   break;
      default:
        callTrack(TEAM_MEMBER_REMOVED, properties);
        break;
    }
  }

  // Bulk Actions
  @computed get bulkMemberActions() {
    let actions = [];

    if (this.hasSelectedActiveMembers) {
      actions.push({
        title: t('Delete'),
        onClick: () => {
          this.bulkMemberAction('DELETE');
        },
        disabled: this.onlyLoggedInUserIsSelected
      });
    }

    if (this.hasSelectedInvitedMembers) {
      actions.push({
        title: t('Resend invite'),
        onClick: () => {
          this.bulkMemberAction('RESEND_INVITE');
        }
      });
    }

    if (this.hasSelectedInvitedMembers) {
      actions.push({
        title: t('Remove invite'),
        onClick: () => {
          this.bulkMemberAction('REMOVE_INVITE');
        }
      });
    }

    if (this.hasSelectedMembers) {
      actions.push({
        title: t('Change role'),
        onClick: () => {
          this.bulkMemberAction('CHANGE_ROLE');
        },
        disabled: this.onlyLoggedInUserIsSelected
      });

      actions.push({
        title: t('Add to projects'),
        onClick: () => {
          this.bulkMemberAction('ADD_PROJECTS');
        }
      });
    }

    actions.push({
      title: this.hasNoSelectedMembers
        ? t('Export all team members')
        : t('Export selected team members'),
      onClick: () => {
        this.exportMembers();
      }
    });

    return actions;
  }

  @computed get hasSelectedMembers() {
    return this.selectedMembers.length > 0;
  }

  @computed get hasNoSelectedMembers() {
    return this.selectedMembers.length === 0;
  }

  @computed get hasSelectedActiveMembers() {
    return this.selectedMembers.filter(model => model.status === 'ACTIVE')
      .length;
  }

  @computed get hasSelectedInvitedMembers() {
    return this.selectedMembers.filter(model => model.status === 'INVITED')
      .length;
  }

  @computed get onlyLoggedInUserIsSelected() {
    return this.selectedMembers.length === 1 && this.selectedMembers[0].isMe;
  }

  @computed get allMembersSelected() {
    if (!this.hasSelectedMembers) return false;

    return (
      this.members.models.filter(member => {
        return this.findSelectedMember(member);
      }).length === this.members.length
    );
  }

  findSelectedMember = member => {
    return this.selectedMembers.find(
      selectedMember => selectedMember.uuid === member.uuid
    );
  };

  @action.bound toggleSelectAllMembers() {
    if (this.allMembersSelected) {
      this.members.models.forEach(member => {
        this.selectedMembers.remove(this.findSelectedMember(member));
      });
    } else {
      this.members.models.forEach(member => {
        if (!this.findSelectedMember(member)) {
          this.selectedMembers.push(member);
        }
      });
    }
  }

  @action.bound toggleSelectMember(member) {
    const selectedMember = this.findSelectedMember(member);

    if (selectedMember) {
      this.selectedMembers.remove(selectedMember);
    } else {
      this.selectedMembers.push(member);
    }
  }

  @action.bound async bulkMemberAction(action) {
    switch (action) {
      case 'RESEND_INVITE':
      case 'REMOVE_INVITE':
        await this.authorization.checkFeatureAccess('CUDCompanyInvites');
        break;
      case 'CHANGE_ROLE':
        await this.authorization.checkFeatureAccess('CUDCompanyMemberRoles');
        break;
      default:
        await this.authorization.checkFeatureAccess('CUDCompanyMembers');
        break;
    }

    this.selectedMemberAction = action;
    this.showModal('MemberBulkActionModal');
  }

  @action.bound async cancelBulkMemberAction() {
    await this.hideActiveModal();
    this.selectedMemberAction = null;
    this.selectedMemberRole = null;
  }

  @computed get bulkMemberActionTitle() {
    if (!this.selectedMemberAction) return null;

    switch (this.selectedMemberAction) {
      case 'DELETE':
        return t(`Delete team members?`);
      case 'REMOVE_INVITE':
        return t(`Remove invites?`);
      case 'RESEND_INVITE':
        return t(`Resend invites?`);
      case 'CHANGE_ROLE':
        return t(`Change role?`);
      case 'ADD_PROJECTS':
        return t(`Add to projects`);
      default:
        return '';
    }
  }

  @computed get bulkMemberActionText() {
    if (!this.selectedMemberAction) return null;

    switch (this.selectedMemberAction) {
      case 'DELETE':
        return t(
          `You are attempting to delete one or more team members from your account. Doing so will remove their project assignments permanently and allow you to re-use their username. Would you like to proceed?`
        );
      case 'REMOVE_INVITE':
        return t(
          `You are attempting to remove the invite for one or more team members. Doing so will make any invite they have received invalid. Would you like to proceed?`
        );
      case 'RESEND_INVITE':
        return t(
          `You are attempting to resend the invite for one or more team members. Doing so will send them a new invitation email. Would you like to proceed?`
        );
      case 'CHANGE_ROLE':
        return t(
          `You are attempting to change the role for one or more team members. Doing so will modify their permissions. Would you like to proceed?`
        );
      default:
        return '';
    }
  }

  @computed get bulkMemberActionNoOperationText() {
    if (!this.selectedMemberAction) return null;

    switch (this.selectedMemberAction) {
      case 'DELETE':
        return t(
          `The following users will be ignored - You, the currently logged in user, and any selected inactive or deleted team members will be ignored.`
        );
      case 'CHANGE_ROLE':
        return t(
          `The following users will be ignored - You, the currently logged in user.`
        );
      case 'REMOVE_INVITE':
        return t(
          `Any selected team members that have accepted their invite will be ignored.`
        );
      case 'RESEND_INVITE':
        return t(
          `Any selected team members that have accepted their invite will be ignored.`
        );
      default:
        return '';
    }
  }

  @computed get bulkMemberActionButtonColor() {
    switch (this.selectedMemberAction) {
      case 'DELETE':
      case 'REMOVE_INVITE':
        return 'red';
      default:
        return 'orange';
    }
  }

  @computed get bulkMemberActionButtonDisabled() {
    switch (this.selectedMemberAction) {
      case 'CHANGE_ROLE':
        return this.saving || !this.selectedMemberRole;
      default:
        return this.saving;
    }
  }

  @computed get bulkMemberActionButtonText() {
    switch (this.selectedMemberAction) {
      case 'DELETE':
        return t(`Delete`);
      case 'REMOVE_INVITE':
        return t(`Remove`);
      case 'RESEND_INVITE':
        return t(`Resend`);
      case 'CHANGE_ROLE':
        return t(`Save`);
      case 'ADD_PROJECTS':
        return t(`Save`);
      default:
        return '';
    }
  }

  @computed get bulkMemberActionButtonTextActive() {
    switch (this.selectedMemberAction) {
      case 'DELETE':
        return t(`Deleting...`);
      case 'REMOVE_INVITE':
        return t(`Removing...`);
      case 'RESEND_INVITE':
        return t(`Resending...`);
      case 'CHANGE_ROLE':
        return t(`Saving...`);
      case 'ADD_PROJECTS':
        return t(`Saving...`);
      default:
        return '';
    }
  }

  @computed get bulkMemberActionNotificationText() {
    switch (this.selectedMemberAction) {
      case 'DELETE':
        return t(`Team members deleted`);
      case 'REMOVE_INVITE':
        return t(`Invites removed`);
      case 'RESEND_INVITE':
        return t(`Invites sent`);
      case 'CHANGE_ROLE':
        return t(`Team member roles updated`);
      case 'ADD_PROJECTS':
        return t(`Team members added to projects`);
      default:
        return '';
    }
  }

  @computed get bulkMemberActionRoleOptions() {
    return this.parent.roleOptions;
  }

  @action.bound setSelectedMemberRole(role) {
    this.selectedMemberRole = role;
  }

  @action.bound async confirmBulkMemberAction() {
    this.clearValidationDetails();
    this.saving = true;

    try {
      switch (this.selectedMemberAction) {
        case 'DELETE':
          await this.bulkDelete();
          break;
        case 'RESEND_INVITE':
          await this.bulkResendInvites();
          break;
        case 'REMOVE_INVITE':
          await this.bulkRemoveInvites();
          break;
        case 'CHANGE_ROLE':
          await this.bulkChangeRole();
          break;
        case 'ADD_PROJECTS':
          await this.bulkAddProjects();
          break;
      }

      this.parent.loading = true;
      this.parent.setPage(null, 1);
      this.parent.fetchMembers();

      await this.hideActiveModal();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: this.bulkMemberActionNotificationText
      });

      this.parent.refetchSubscription();
      this.selectedMembers.clear();
      this.selectedMemberAction = null;
      this.selectedMemberRole = null;
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound async bulkDelete() {
    const payload = this.selectedMembers
      .filter(member => !member.isMe)
      .map(member => {
        return {
          uuid: member.uuid,
          status: 'DELETED'
        };
      });

    return await request.patch(`${this.members.url()}/batch`, payload);
  }

  @action.bound async bulkResendInvites() {
    const requests = [];

    this.selectedMembers
      .filter(member => member.status === 'INVITED')
      .forEach(member => {
        requests.push(
          request.post(`/ra/invite/${member.inviteId}`, {
            companyId: member.company.id
          })
        );
      });

    return await Promise.all(requests);
  }

  @action.bound async bulkRemoveInvites() {
    const payload = this.selectedMembers
      .filter(member => member.status === 'INVITED')
      .map(member => {
        return {
          uuid: member.uuid
        };
      });

    return await request.delete(`${this.members.url()}/batch`, {
      data: payload
    });
  }

  @action.bound async bulkChangeRole() {
    const payload = this.selectedMembers
      .filter(member => !member.isMe)
      .map(member => {
        return {
          uuid: member.uuid,
          role: this.selectedMemberRole.value
        };
      });

    return await request.patch(`${this.members.url()}/batch`, payload);
  }

  @action.bound async bulkAddProjects() {
    await this.teamMembersActionsProjectsUI.addMembersToProjects();
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.saving = false;
    this.selectedMember = null;
    this.selectedMemberAction = null;
    this.selectedMemberCallback = null;
    this.selectedMemberRole = null;
    this.selectedMembers.clear();
  }

  @action.bound
  exportMembers() {
    download({
      store: this.rootStore,
      url: `${this.rootStore.apiURL}/ra/companies/${this.rootStore.me.company.uuid}/members/csv`,
      xhttpOptions: {
        sendXApiKey: true
      },
      data: {
        uuids: this.selectedMembers.map(member => member.uuid),
        type: 'MEMBER'
      }
    });
  }

  @computed get seatEstimate() {
    if (!this.subscription || this.selectedMemberAction !== 'REACTIVATE')
      return null;

    return seatEstimate(this.subscription, 1, this.isRecurlyBilling);
  }

  @computed get seatEstimateTooltip() {
    return seatEstimateTooltip(this.subscription, 1, this.isRecurlyBilling);
  }
}
