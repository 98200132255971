export default (error, notificationCallback) => {
  if ('response' in error) {
    if (!error.response) {
      console.error(error.stack);
      notificationCallback({
        validationDetails: [
          {
            fieldMessage: 'An unknown network error has occurred.'
          }
        ]
      });
    } else {
      if (error.response.status !== 401) {
        notificationCallback(error.response.data);
      }
    }
    console.error(error);
  } else {
    notificationCallback(error);
    console.error(error);
  }
};
