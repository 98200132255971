import PropTypes from 'prop-types';
import styled from 'styled-components';

const FormGroupMessage = styled.div`
  color: ${props =>
    props.hasError
      ? props.theme.colors[props.errorColor]
      : props.theme.colors.lightGrey1};
  font-size: ${props => (props.size === 'small' ? 1.2 : 1.4)}rem;
  margin: 5px 0 0;
  text-align: ${props => props.align};
`;

FormGroupMessage.propTypes = {
  errorColor: PropTypes.string.isRequired
};

FormGroupMessage.defaultProps = {
  align: 'left',
  errorColor: 'red'
};

export default FormGroupMessage;
