import { Model } from 'mobx-mc';
import { action, runInAction } from 'mobx';
import request from 'axios';

export default class CompanyPermission extends Model {
  get restAttributes() {
    return [
      'role',
      'roleName',
      'description',
      'enabled',
      'permission',
      'description',
      'permissionEnum',
      'locked',
      'isDefault'
    ];
  }

  get isNew() {
    return false;
  }

  url() {
    return this.collection.url();
  }

  @action.bound
  updatePermission() {
    this.setRequestLabel('saving', true);
    const originalAttributes = this.attributes.toJSON();

    this.set({
      enabled: !originalAttributes.enabled,
      isDefault: !originalAttributes.isDefault
    });

    const payload = this.attributes.toJSON();
    const statusRole = this.collection.models
      .filter(model => model.permissionEnum === payload.permissionEnum)
      .filter(model => model.enabled).length;

    return new Promise((resolve, reject) => {
      request
        .put(this.url(), [payload])
        .then(response => {
          runInAction('update-success', () => {
            if (!this.rootStore.me.role === 'ROLE_ACCOUNT_ADMIN') {
              /*
               * We don't need to update roles for account admins as they always remain true
               */
              this.rootStore.me.permissions[payload.permissionEnum] = Boolean(
                statusRole
              );
            }

            resolve(this);
          });
        })
        .catch(error => {
          runInAction('update-error', () => {
            this.set(originalAttributes);

            reject(error);
          });
        })
        .finally(() => {
          this.setRequestLabel('saving', false);
        });
    });
  }
}
