import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import { t } from 'utils/translate';

const classificationFormPlugins = extendedPlugins;

const classificationFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const classificationFormFields = ['name'];

const classificationFormRules = {
  name: 'string|required|max:255'
};

const classificationFormLabels = {
  name: t('Classification Name')
};

class ClassificationForm extends MobxReactForm {}

export {
  ClassificationForm,
  classificationFormOptions,
  classificationFormFields,
  classificationFormRules,
  classificationFormLabels,
  classificationFormPlugins
};
