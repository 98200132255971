import React, { Component } from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  display: ${props => (props.show ? 'block' : 'none')};
  position: absolute;
  width: ${props => props.width || 300}px;
  pointer-events: none;
  z-index: ${props => props.zIndex};
  top: ${props => props.top}px;
  right: ${props => (props.right ? props.right + 'px' : 'auto')};
  left: ${props => (props.left ? props.left + 'px' : 'auto')};
  ${props =>
    props.responsive &&
    css`
      max-width: calc(100% - 50px);
      min-width: calc(100% - 50px);
    `};
`;

const Content = styled.div`
  background-color: #fff;
  border-radius: ${props => (props.borderRadius ? props.borderRadius : 10)}px;
  border: 1px solid ${props => props.theme.colors.lightGrey3};
  box-sizing: border-box;
  width: ${props => props.contentWidth};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  position: absolute;
  pointer-events: all;

  ${props =>
    props.enableArrow
      ? css`
          &::after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-bottom: 20px solid #fff;
            top: -20px;
            right: ${props => props.arrowPosition}px;
          }
          &::before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-left: 16px solid transparent;
            border-right: 16px solid transparent;
            border-bottom: 21px solid ${props => props.theme.colors.lightGrey3};
            box-shadow: 0 16px 10px -25px rgba(0, 0, 0, 0.25);
            top: -22px;
            right: ${props => props.arrowPosition - 1}px;
            z-index: -1;
          }
        `
      : ''};
`;

class Dropdown extends Component {
  static defaultProps = {
    enableArrow: true,
    arrowPosition: 75,
    right: 25,
    responsive: false,
    width: 300,
    contentWidth: '100%',
    zIndex: 50
  };

  componentDidMount(e) {
    window.addEventListener('click', this.hideOnClickOutside);
  }

  componentWillUnmount(e) {
    window.removeEventListener('click', this.hideOnClickOutside);
  }

  hideOnClickOutside = e => {
    if (e.target === this.props.target) return;

    if (this.content && this.content.contains(e.target)) return;

    if (e.target === this.props.ignoreClick) return;

    this.props.onHide && this.props.onHide.call();
  };

  render() {
    const {
      width,
      borderRadius,
      show,
      arrowPosition,
      right,
      top,
      left,
      children,
      responsive,
      enableArrow,
      zIndex,
      contentWidth
    } = this.props;

    return (
      <Wrapper
        width={width}
        show={show}
        right={right}
        left={left}
        top={top}
        responsive={responsive}
        zIndex={zIndex}
      >
        <Content
          borderRadius={borderRadius}
          enableArrow={enableArrow}
          arrowPosition={arrowPosition}
          ref={ref => (this.content = ref)}
          contentWidth={contentWidth}
        >
          {children}
        </Content>
      </Wrapper>
    );
  }
}

export default Dropdown;
