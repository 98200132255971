import MobxReactForm from 'mobx-react-form';
import validatorjs from 'validatorjs';

const companyNameFormPlugins = { dvr: validatorjs };

const companyNameFormOptions = {
  validateOnInit: false,
  validateOnChange: true
};

const companyNameFormFields = ['type', 'name'];

const companyNameFormRules = {
  name: 'required|string|max:150'
};

const companyNameFormValues = {
  type: 'companyName'
};

class CompanyNameForm extends MobxReactForm {}

export {
  CompanyNameForm,
  companyNameFormValues,
  companyNameFormRules,
  companyNameFormFields,
  companyNameFormOptions,
  companyNameFormPlugins
};
