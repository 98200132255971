import { action, computed } from 'mobx';
import ProjectChildAddUI from './ProjectChildAddUI';
import Note from '../../models/Note';

import {
  NoteForm,
  noteFormOptions,
  noteFormFields,
  noteFormRules,
  noteFormValues,
  noteFormLabels,
  noteFormPlugins
} from 'forms/note';

import history from 'utils/history';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';

import { callTrack } from 'utils/segmentIntegration';
import { NOTE_ADDED } from 'utils/segmentAnalytics/eventSpec';

export default class NoteAddUI extends ProjectChildAddUI {
  @action.bound setup() {
    this.entryForAdd = new Note(
      { type: 'Note' },
      {
        rootStore: this.rootStore
      }
    );

    this.entryAddForm = new NoteForm(
      {
        fields: noteFormFields,
        rules: noteFormRules,
        values: Object.assign(noteFormValues, { category: 'NOTES' }),
        labels: noteFormLabels
      },
      {
        options: noteFormOptions,
        plugins: noteFormPlugins
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @action.bound async submitEntryAddFormSuccess() {
    this.saving = true;

    try {
      const attachments = this.entryForAdd.attachments.models.map(
        attachment => {
          return {
            uuid: attachment.uuid
          };
        }
      );

      await this.entryForAdd.save(
        Object.assign(this.entryAddForm.values(), this.reportParams, {
          attachments: attachments
        }),
        {
          wait: true,
          stripNonRest: false
        }
      );

      callTrack(NOTE_ADDED, {
        project_name: this.project.name,
        project_date: this.date,
        note_category_name: this.entryForAdd.category,
        has_attachments: this.entryForAdd.hasAttachments
      });

      this.unblockHistory();
      this.parent.sortByLastCreated();
      this.cancelNoteAdd();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Note created')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound cancelNoteAdd() {
    history.push({
      pathname: `${this.project.viewUrl}/notes`,
      search: this.baseQueryParams
    });
  }

  @computed get categoryOptions() {
    return this.parent.categoryOptions;
  }

  @computed get selectedCategoryOption() {
    return this.categoryOptions.find(
      option => option.value === this.entryAddForm.$('category').value
    );
  }

  @computed get title() {
    return t('Add note');
  }
}
