import { BASE_DEBOUNCE } from 'fixtures/constants';
import debounce from 'lodash.debounce';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import DocumentsSearch from 'stores/collections/documents/DocumentsSearch';

import DocumentActionsUI from 'stores/ui/project/documents/DocumentActionsUI';
import DocumentEditUI from 'stores/ui/project/documents/DocumentEditUI';

import alertErrorHandler from 'utils/alertErrorHandler';
import history from 'utils/history';
import { t } from 'utils/translate';
import ProjectChildUI from '../ProjectChildUI';

export default class DocumentSearchUI extends ProjectChildUI {
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;

  @observable documentsSearch;

  constructor(options) {
    super(options);

    this.loading = true;

    this.sortField = 'name';
    this.sortDirection = 'asc';
    this.page = 1;
    this.pageSize = 20;
    this.searchQuery = '';

    this.documentActionsUI = new DocumentActionsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.documentEditUI = new DocumentEditUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.documentsSearch = new DocumentsSearch(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchSearchResultsDebounced = debounce(
      this.fetchSearchResults,
      BASE_DEBOUNCE
    );
  }

  @action.bound setup() {
    window.scrollTo(0, 0);
    if (this.hasRequiredParams) {
      this.fetchSearchResults();
    } else {
      this.goToIndex();
    }

    this.setupReactions();
  }

  @action.bound tearDown() {
    if (this.parent) {
      this.parent.refetchDocuments();
    }

    this.tearDownReactions();
    this.clearUIState();
  }

  @action.bound goToIndex() {
    history.push({
      pathname: `${this.project.viewUrl}/documents/index`,
      search: this.baseQueryParams,
      state: { previousPath: this.documentsSearch.viewUrl }
    });
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchSearchResultsDebounced();
        });
      }
    );

    this.reactToSearch = reaction(
      () => !this.hasRequiredParams,
      params => {
        this.goToIndex();
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
    this.reactToSearch && this.reactToSearch();
  }

  @computed
  get params() {
    return {
      projectUuid: this.projectUuid,
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      sortField: this.sortField,
      sortDirection: this.sortDirection
    };
  }

  @computed get hasRequiredParams() {
    return this.params.projectUuid && this.searchQuery?.length >= 3;
  }

  @computed get documentsConstants() {
    return this.parent.documentsConstants;
  }

  @computed get documentsStat() {
    return this.parent.documentsStat;
  }

  @computed get documents() {
    return this.documentsSearch;
  }

  @action.bound async fetchSearchResults() {
    if (!this.hasRequiredParams) return;

    this.documentsSearch.cancelRequest();
    this.documentsSearch.clear();

    try {
      await this.documentsSearch.fetch({
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasChildren() {
    return !!this.documentsSearch?.hasModels;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed
  get totalPages() {
    return !!this.documentsSearch?.totalElements
      ? Math.ceil(this.documentsSearch.totalElements / this.pageSize)
      : 1;
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound clearSelector() {
    this.parent.documentSelectorUI.clearUIState();
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.page = 1;
    this.loading = true;
    this.saving = false;
    this.sortField = 'name';
    this.sortDirection = 'asc';

    this.documentsSearch.clear();
    this.documentActionsUI.clearUIState();
    this.documentEditUI.clearUIState();
  }

  @computed get showEmptyState() {
    return !this.loading && !this.hasChildren && !this.searchQuery;
  }

  @computed get showEmptySearchState() {
    return !this.loading && !this.hasChildren;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @computed get hasWriteAccess() {
    return this.authorization.canCUDDocuments;
  }

  @action.bound sortByLastUpdated() {
    this.sortField = 'updatedTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchSearchResultsDebounced();
  }

  @action.bound editDocument(document) {
    history.push({
      pathname: document.viewUrl,
      search: this.baseQueryParams,
      state: { previousPath: this.documentsSearch.viewUrl }
    });
  }

  @action.bound goToParentFolder(document) {
    const folderId = document.parentFolderUuid
      ? document.parentFolderUuid
      : 'index';

    history.push({
      pathname: `${this.project.viewUrl}/documents/${folderId}`,
      search: this.baseQueryParams,
      state: { previousPath: this.documentsSearch.viewUrl }
    });
  }

  @action.bound showUpgradeModal() {
    this.parent.showUpgradeModal();
  }

  @action.bound hideUpgradeModal() {
    this.parent.hideActiveModal();
  }

  getChildActions = model => {
    return [
      {
        title: t('Go to folder'),
        onClick: () => {
          this.clearSelector();
          this.goToParentFolder(model);
        }
      }
    ];
  };
}
