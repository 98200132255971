import request from 'axios';
import { observable, action, computed, reaction } from 'mobx';
import debounce from 'lodash.debounce';
import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import UIStore from './UIStore';
import ProjectsMinimal from '../collections/ProjectsMinimal';

import { BASE_DEBOUNCE } from 'fixtures/constants';

export default class TeamWorkersActionsProjectsUI extends UIStore {
  // Projects
  @observable projectSelectionMethod;
  @observable loading;
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable isDefault;

  constructor(options) {
    super(options);

    // Projects
    this.paginatedProjects = new ProjectsMinimal(null, {
      rootStore: this.rootStore
    });

    this.loading = true;
    this.projectSelectionMethod = 'ACTIVE';
    this.selectedProjects = observable([]);
    this.isDefault = false;
    this.sortField = 'name';
    this.sortDirection = 'ASC';
    this.searchQuery = '';
    this.pageSize = 10;
    this.page = 1;

    this.fetchProjectsDebounced = debounce(this.fetchProjects, BASE_DEBOUNCE);
  }

  @computed get projects() {
    return this.paginatedProjects;
  }

  @computed get hasProjects() {
    return this.projects.hasModels;
  }

  @action.bound setup() {
    this.setupReactions();
    this.fetchProjects();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.cancelReactToParams = reaction(
      () => this.params,
      params => {
        this.fetchProjects();
      }
    );

    this.cancelReactToSearchQuery = reaction(
      () => this.searchQuery,
      params => {
        this.fetchProjectsDebounced();
      }
    );
  }

  tearDownReactions() {
    this.cancelReactToParams && this.cancelReactToParams();
    this.cancelReactToSearchQuery && this.cancelReactToSearchQuery();
  }

  @action.bound
  async fetchProjects() {
    this.loading = true;

    await this.projects.fetch({
      params: pickBy(
        Object.assign(this.params, {
          query: this.searchQuery
        }),
        identity
      )
    });

    this.loading = false;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @computed get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      sortField: this.sortField,
      sortDirection: this.sortDirection,
      projectStates: 'ACTIVE'
    };
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }
  }

  @computed
  get totalPages() {
    return Math.ceil(this.projects.totalElements / this.pageSize);
  }

  @action.bound setProjectSelectionMethod(event) {
    this.selectedProjects.clear();

    if (event.target.value === 'ACTIVE') {
      this.selectAllProjects();
    }

    this.projectSelectionMethod = event.target.value;
    this.page = 1;
  }

  @computed get hasSelectedProjects() {
    return this.selectedProjects.length > 0;
  }

  @action.bound
  toggleSelectProject(projectUuid) {
    if (this.selectedProjects.find(uuid => uuid === projectUuid)) {
      this.selectedProjects.remove(projectUuid);
    } else {
      this.selectedProjects.push(projectUuid);
    }
  }

  @computed
  get allProjectsSelected() {
    return (
      this.hasProjects &&
      this.projects.models.every(project =>
        this.selectedProjects.includes(project.uuid)
      )
    );
  }

  @action.bound selectAllProjects() {
    this.projects.models.forEach(project => {
      if (!this.selectedProjects.includes(project.uuid)) {
        this.selectedProjects.push(project.uuid);
      }
    });
  }

  @action.bound
  toggleSelectAllProjects() {
    if (this.allProjectsSelected) {
      this.selectedProjects.replace(
        this.selectedProjects.filter(uuid => {
          return !this.projects.models
            .map(project => project.uuid)
            .includes(uuid);
        })
      );
    } else {
      this.projects.models.forEach(project => {
        if (!this.selectedProjects.includes(project.uuid)) {
          this.selectedProjects.push(project.uuid);
        }
      });
    }
  }

  @action.bound toggleIsDefault() {
    this.isDefault = !this.isDefault;
  }

  @action.bound async addWorkersToProjects() {
    let payload;

    if (this.projectSelectionMethod === 'SELECTED') {
      payload = this.parent.selectedWorkers.map(member => {
        return {
          uuid: member.uuid,
          projectUuids: this.selectedProjects.slice(),
          allowSubscriptionUpgrade: true,
          isDefault: this.isDefault,
          projectMembershipUpdateMode: 'APPEND'
        };
      });
    } else {
      payload = this.parent.selectedWorkers.map(member => {
        return {
          uuid: member.uuid,
          addToActiveProjects: true,
          allowSubscriptionUpgrade: true,
          isDefault: this.isDefault
        };
      });
    }

    return await request.patch(
      `${this.rootStore.workers.url()}/batch`,
      payload
    );
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.searchQuery = '';
    this.selectedProjects.clear();
    this.projectSelectionMethod = 'ACTIVE';
    this.projects.clear();
    this.isDefault = false;
    this.page = 1;
    this.loading = true;
  }
}
