import {
  DocumentFiltersForm,
  documentFiltersFormFields,
  documentFiltersFormOptions,
  documentFiltersFormPlugins,
  documentFiltersFormRules
} from 'forms/project/documents/documentFilters';
import orderBy from 'lodash.orderby';
import { action, computed, observable, runInAction } from 'mobx';
import UIStore from 'stores/ui/UIStore';
import errorHandler from 'utils/errorHandler';
import { t } from 'utils/translate';

export default class DocumentFiltersUI extends UIStore {
  @observable typeFilter;
  @observable createdByFilters;
  @observable updatedByFilters;

  constructor(options) {
    super(options);

    this.typeFilter = '';
    this.createdByFilters = [];
    this.updatedByFilters = [];
  }

  @action.bound
  initFilterForm() {
    this.activeForm = new DocumentFiltersForm(
      {
        fields: documentFiltersFormFields,
        rules: documentFiltersFormRules,
        values: {
          typeFilter: this.typeFilter,
          createdByFilters: this.createdByFilters,
          updatedByFilters: this.updatedByFilters
        }
      },
      {
        options: documentFiltersFormOptions,
        plugins: documentFiltersFormPlugins
      }
    );
  }

  @action.bound
  terminateFilterForm() {
    this.activeForm = null;
  }

  @action.bound async showDocumentsFilterDialog() {
    this.showModal('DocumentFiltersModal');

    try {
      await this.memberSelectorUI.setup({
        role: [
          'ROLE_ACCOUNT_ADMIN',
          'ROLE_ADMIN',
          'ROLE_PROJECT_MEMBER',
          'ROLE_USER'
        ]
      });

      this.initFilterForm();
    } catch (error) {
      this.hideActiveModal();
      errorHandler(error, this.notifications.pushError);
    }
  }

  @action.bound
  handleClearAllFiltersClick() {
    this.clearUIState();
  }

  @action.bound
  clearUIState() {
    return this.hideActiveModal().then(() => {
      runInAction(() => {
        this.terminateFilterForm();
        this.typeFilter = '';
        this.createdByFilters.clear();
        this.updatedByFilters.clear();
        if (this.parent) {
          this.parent.page = 1;
        }
      });
    });
  }

  @action.bound async applyAllFilters() {
    return this.hideActiveModal().then(() => {
      runInAction(() => {
        this.typeFilter = this.activeForm.$('typeFilter').value;

        this.createdByFilters.replace(
          this.activeForm.$('createdByFilters').values()
        );

        this.updatedByFilters.replace(
          this.activeForm.$('updatedByFilters').values()
        );

        this.terminateFilterForm();

        if (this.parent) {
          this.parent.page = 1;
        }
      });
    });
  }

  @action.bound
  async hideFilters() {
    await this.hideActiveModal();

    this.terminateFilterForm();
    this.memberSelectorUI.tearDown();
  }

  @computed
  get filtersCounter() {
    let counter = 0;

    if (this.typeFilter) {
      counter++;
    }

    if (this.createdByFilters.length > 0) {
      counter++;
    }

    if (this.updatedByFilters.length > 0) {
      counter++;
    }

    return counter;
  }

  @computed get hasAppliedFilters() {
    return !!this.filtersCounter;
  }

  @computed get findTypeFilterByOption() {
    return this.typeFilterOptions.find(item => item.id === this.typeFilter);
  }

  @computed
  get typeFilterOptions() {
    let result = [
      {
        id: '',
        title: t('All')
      },
      {
        id: 'FOLDER',
        title: t('Folders')
      }
    ];

    result = result.concat(
      this.parent.documentsConstants.models.map(item => {
        return {
          id: item.fileTypes.map(fileType => fileType.fileType).join(','),
          title: item.name
        };
      })
    );

    return orderBy(result, ['title'], ['asc']);
  }
}
