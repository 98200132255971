import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';

const questionFormPlugins = extendedPlugins;

const questionFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const questionFormFields = ['category', 'question'];

const questionFormRules = {
  category: 'required|string',
  question: 'required|string'
};

class QuestionForm extends MobxReactForm {}

export {
  QuestionForm,
  questionFormOptions,
  questionFormFields,
  questionFormRules,
  questionFormPlugins
};
