import { Model } from 'mobx-mc';
import omit from 'lodash.omit';
import WorkerPhotoIdDetails from '../collections/WorkerPhotoIdDetails';

export default class WorkerPhotoIdDetailActivity extends Model {
  get restAttributes() {
    return [
      'uuid',
      'dateRange',
      'dateRangePrev',
      'dateRangeNext',
      'page',
      'periodType',
      'workerName'
    ];
  }

  get urlRoot() {
    return `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/photo-ids/workers`;
  }

  parse(attributes) {
    this.parseWorkerPhotoIdDetails(attributes);

    return {
      ...omit(attributes, [
        'workerPhotoIdDetails' // Make sure we don't pass this through to the restAttributes
      ])
    };
  }

  parseWorkerPhotoIdDetails(attributes) {
    if (this.workerPhotoIdDetails) {
      // If we already have an instance, no need to create a new one.
      this.workerPhotoIdDetails.reset(attributes.workerPhotoIdDetails);
    } else {
      this.workerPhotoIdDetails = new WorkerPhotoIdDetails(
        attributes.workerPhotoIdDetails,
        {
          rootStore: this.rootStore,
          parent: this
        }
      );
    }
  }
}
