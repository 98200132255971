import request from 'axios';
import { action, observable, when } from 'mobx';
import ProjectChildUI from './ProjectChildUI';
import history from 'utils/history';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';

// import { REPORT_SIGNED } from 'utils/segmentAnalytics/eventSpec';
// import { callTrack } from 'utils/segmentIntegration';

export default class SignUI extends ProjectChildUI {
  @observable padSigned;
  @observable signature;

  constructor(options) {
    super(options);

    this.padSigned = false;
    this.signature = null;
  }

  @action.bound setup() {
    when(
      () => !this.parent.fetchingReportSummary,
      () => {
        if (!this.enableSignRoute) {
          this.cancelSignReportModal();
        }
      }
    );
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.padSigned = false;
    this.signature = null;
    this.saving = false;
  }

  @action.bound async showSignReportModal() {
    await this.authorization.checkFeatureAccess('VerifyReport');

    const pathname = history.location.pathname;

    history.push({
      pathname: pathname,
      search: `${this.baseQueryParams}&action=sign`
    });
  }

  @action.bound async cancelSignReportModal() {
    const pathname = history.location.pathname;

    history.push({
      pathname: pathname,
      search: this.baseQueryParams
    });
  }

  @action.bound signPad(signature) {
    this.padSigned = true;
    this.signature = signature;
  }

  @action.bound unsignPad() {
    this.padSigned = false;
    this.signature = null;
  }

  @action.bound async signReport(signature) {
    this.saving = true;

    try {
      const response = await request.post(
        `ra/companies/${this.company.uuid}/reports/sign`,
        {
          isSaveSignature: this.me.isSaveSignature,
          signature: signature.base64,
          reports: [{ ...this.reportParams }]
        }
      );

      if (this.me.isSaveSignature) {
        this.me.saveUserSignature(signature.base64);
      } else {
        this.me.saveUserSignature(null);
      }

      this.reportSummary.set(response.data.collection[0]);

      this.cancelSignReportModal();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Report signed')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }

    return true;
  }
}
