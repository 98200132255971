import MobxReactForm from 'mobx-react-form';

import { t } from 'utils/translate';

import extendedPlugins from 'forms/utils/extendedPlugins';

const sageIntacctConnectFormPlugins = extendedPlugins;

const sageIntacctConnectFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const sageIntacctConnectFormFields = ['companyId', 'userId', 'password'];

const sageIntacctConnectFormRules = {
  companyId: 'string|required',
  userId: 'string|required',
  password: 'string|required'
};

const sageIntacctConnectFormLabels = {
  companyId: t('companyId'),
  userId: t('userId'),
  password: t('password')
};

const sageIntacctConnectFormValues = {};

class SageIntacctConnectForm extends MobxReactForm {}

export {
  SageIntacctConnectForm,
  sageIntacctConnectFormOptions,
  sageIntacctConnectFormFields,
  sageIntacctConnectFormRules,
  sageIntacctConnectFormLabels,
  sageIntacctConnectFormValues,
  sageIntacctConnectFormPlugins
};
