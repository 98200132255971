import omit from 'lodash.omit';
import { action, computed } from 'mobx';
import ReportEntryWithAttachments from './ReportEntryWithAttachments';
import { t } from 'utils/translate';
import kebabCase from 'lodash.kebabcase';

export default class MaterialLogActivity extends ReportEntryWithAttachments {
  get urlRoot() {
    return `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/companies/${this.rootStore.me.company.uuid}/materiallogs`;
  }

  get restAttributes() {
    return [
      'uuid',
      'createdTimestamp',
      'updatedTimestamp',
      'quantity',
      'desc',
      'materialLog',
      'reportDate',
      'type',
      'project',
      'company',
      'material',
      'costCode',
      'attachments',
      'segmentUuid',
      'activityViewUrl',
      'activitySegmentName',
      'unit'
    ];
  }

  get restAttributeDefaults() {
    return {
      activityViewUrl: '/'
    };
  }

  idAttribute() {
    return 'uuid';
  }

  @action.bound
  parse(attributes) {
    this.parseCreatedBy(attributes);
    this.parseUpdatedBy(attributes);
    this.parseProject(attributes);
    this.parseCompany(attributes);
    this.parseAttachments(attributes);

    return {
      ...omit(attributes, [
        'attachments',
        'createdBy',
        'updatedBy',
        'project',
        'company'
      ])
    };
  }

  @computed
  get title() {
    return t('Material Log');
  }

  @computed get activityTitle() {
    return `${t('submitted a')} ${this.title}`;
  }

  @computed get slug() {
    if (this.costCode) {
      return kebabCase(`${this.material.name} ${this.costCode}`);
    }

    return kebabCase(this.material.name);
  }

  @computed
  get canShowQuantity() {
    return this.material && this.unit;
  }
}
