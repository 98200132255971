import { Model } from 'mobx-mc';
import { action, computed } from 'mobx';
import omit from 'lodash.omit';
import orderBy from 'lodash.orderby';

import kebabCase from 'lodash.kebabcase';

export default class OverTimeRule extends Model {
  urlRoot = `${this.rootStore.urlMicroService(
    'performanceTracking'
  )}/overtimerules`;

  get restAttributes() {
    return ['uuid', 'type', 'rules', 'name', 'default'];
  }

  idAttribute() {
    return 'uuid';
  }

  @action.bound parse(attributes) {
    this.parseRules(attributes);

    return {
      ...omit(attributes, 'rules')
    };
  }

  parseRules(attributes) {
    this.rules = orderBy(attributes.rules, ['order']);
  }

  @computed
  get formValues() {
    return {
      uuid: this.uuid,
      name: this.name,
      type: this.type,
      rules: this.rules
        .filter(rule => !rule.default)
        .map(rule => {
          return {
            uuid: rule.uuid,
            order: rule.order,
            payType: { uuid: rule.payType.uuid, name: rule.payType.name },
            dailyLimit: rule.dailyLimit,
            weeklyLimit: rule.weeklyLimit
          };
        }),
      default: this.rules.find(rule => rule.default)
    };
  }

  @action.bound
  fetchByProjectId(projectId) {
    return this.fetch({
      url: `${this.urlRoot}/${projectId}`
    });
  }

  @action.bound
  fetchByRuleSetId(ruleSetId) {
    return this.fetch({
      url: `${this.urlRoot}/${ruleSetId}`
    });
  }

  @computed get slug() {
    return kebabCase(this.name);
  }
}
