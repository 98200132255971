import { RakenCollection } from '../lib';
import ProjectMinimal from '../models/ProjectMinimal';
import { computed, observable, action } from 'mobx';

export default class ProjectsMinimal extends RakenCollection {
  @observable hasFetched;

  constructor(data, options) {
    super(data, options);
    this.hasFetched = false;
  }

  model() {
    return ProjectMinimal;
  }

  url() {
    if (!this.rootStore.isSuperAdmin) {
      return `/ra/companies/${this.rootStore.me.company.uuid}/projects`;
    }

    return '';
  }

  @action.bound
  set(data, options) {
    super.set(data, options);
    this.hasFetched = true;
  }

  @computed
  get active() {
    return this.models.filter(project => project.isActive);
  }

  @computed
  get hasActive() {
    return this.active.length > 0;
  }

  @computed
  get inactive() {
    return this.models.filter(project => project.isInactive);
  }

  @computed
  get hasInactive() {
    return this.inactive.length > 0;
  }

  @computed
  get deleted() {
    return this.models.filter(project => project.isDeleted);
  }

  @computed
  get activeAndInactive() {
    return this.models.filter(
      project => project.isActive || project.isInactive
    );
  }

  @computed
  get hasDeleted() {
    return this.deleted.length > 0;
  }

  @action.bound getProjectByUuid(uuid) {
    return this.models.find(project => project.uuid === uuid);
  }

  @action.bound getProjectByOptionIdValue(uuid) {
    return this.models.find(project => project.optionIdValue === uuid);
  }
}
