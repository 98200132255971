import { computed } from 'mobx';
import ReportEntryWithAttachments from './ReportEntryWithAttachments';
import ProjectMinimal from './ProjectMinimal';

import omit from 'lodash.omit';
import formatTimestamp from 'utils/formatTimestamp';

export default class TimeClockQuestionAnswer extends ReportEntryWithAttachments {
  get urlRoot() {
    return `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/timecardchecklist/question/details`;
  }

  get restAttributes() {
    return [
      'checklistUuid',
      'timeCardUuid',
      'timesheetUuid',
      'questionText',
      'completedBy',
      'response',
      'note',
      'completedDate',
      'templateName'
    ];
  }

  parse(attributes) {
    super.parse(attributes);

    return {
      ...omit(attributes, ['projectName'])
    };
  }

  parseProject(attributes) {
    attributes.project = { name: attributes.projectName };

    this.project = new ProjectMinimal(attributes.project, {
      rootStore: this.rootStore
    });
  }

  idAttribute() {
    return 'uuid';
  }

  @computed
  get completedDateFormatted() {
    return formatTimestamp(this.completedDate);
  }
}
