import { computed, observable, action } from 'mobx';
import { RakenCollection } from '../lib';
import User from '../models/User';

export default class Users extends RakenCollection {
  @observable fetchedDeleted;
  @observable fetchedInactive;

  constructor(data, options) {
    super(data, options);

    this.fetchedDeleted = false;
    this.fetchedInactive = false;
  }

  model() {
    return User;
  }

  url() {
    if (this.rootStore.isSuperAdmin) {
      return `/ra/companies/${this.parent.activeCompany?.uuid}/members`;
    }

    return `/ra/companies/${this.rootStore.me.company?.uuid}/members`;
  }

  @action.bound
  setFetchedDeleted() {
    this.fetchedDeleted = true;
  }

  @action.bound
  setFetchedInactive() {
    this.fetchedInactive = true;
  }

  @computed get activeUsers() {
    return this.models.filter(
      user => user.status !== 'INACTIVE' && user.status !== 'DELETED'
    );
  }

  @computed
  get loggedInActiveUsers() {
    return this.activeUsers.filter(user => !user.isNew);
  }
}
