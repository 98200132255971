import React from 'react';
import styled from 'styled-components';
import media from '../utils/mediaTemplate';

const Line = styled.div`
  align-items: center;
  background: ${props => props.theme.colors.lightGrey5};
  color: ${props =>
    props.theme.colors[props.color] || props.theme.colors.charcoalGrey};
  display: flex;
  height: 1px;
  justify-content: center;
  margin: ${props => `${props.gutterMobile}`};
  position: relative;
  z-index: ${props => props.zIndex || 11};
  text-transform: ${props => props.textTransform || 'inherit'};
  ${media.desktop`
    margin: ${props => `${props.gutterDesktop}`};
  `};
`;

const Content = styled.div`
  background-color: ${props =>
    props.backgroundColorMobile
      ? props.theme.colors[props.backgroundColorMobile]
      : props.theme.colors[props.backgroundColor]};
  font-size: 1.2rem;
  padding: 0 10px;

  ${media.desktop`
    background-color: ${props => props.theme.colors[props.backgroundColor]};
  `};
`;

export default ({
  textTransform,
  zIndex,
  color,
  backgroundColor,
  backgroundColorMobile,
  gutterMobile,
  gutterDesktop,
  children
}) => (
  <Line
    textTransform={textTransform}
    zIndex={zIndex}
    color={color}
    gutterDesktop={gutterDesktop}
    gutterMobile={gutterMobile}
  >
    {children && (
      <Content
        backgroundColor={backgroundColor}
        backgroundColorMobile={backgroundColorMobile}
      >
        {children}
      </Content>
    )}
  </Line>
);

Line.defaultProps = {
  gutterMobile: '2rem 0',
  gutterDesktop: '2rem 0'
};

Content.defaultProps = {
  backgroundColor: 'lightGrey2',
  backgroundColorMobile: 'lightGrey2'
};
