import { action, computed, observable } from 'mobx';

import SettingsPayTypesUI from 'stores/ui/project/SettingsPayTypesUI';
import { t } from 'utils/translate';
import toggleArrayFieldValue from 'forms/utils/toggleArrayFieldValue';

import {
  ProjectTemplatePayTypesForm,
  projectTemplatePayTypesFormOptions,
  projectTemplatePayTypesFormFields,
  projectTemplatePayTypesFormRules,
  projectTemplatePayTypesFormPlugins,
  projectTemplatePayTypesFormLabels
} from 'forms/projectTemplates/projectTemplatePayTypes';

export default class ProjectTemplatePayTypesUI extends SettingsPayTypesUI {
  @observable form;
  constructor(options) {
    super(options);

    this.form = null;

    this.saveTitle = t('Project template pay types saved');
  }

  @computed get selectedTemplate() {
    return this.parent.selectedTemplate;
  }

  @computed get showEmptyState() {
    return !this.payTypes.hasModels && !this.loading;
  }

  @action.bound async setup() {
    this.setupReactions();
    this.fetchPayTypes();

    this.setupForm();
  }

  @action.bound
  setupForm() {
    this.form = new ProjectTemplatePayTypesForm(
      {
        fields: projectTemplatePayTypesFormFields,
        rules: projectTemplatePayTypesFormRules,
        labels: projectTemplatePayTypesFormLabels,
        values: this.selectedTemplate.payTypesValues
      },
      {
        options: projectTemplatePayTypesFormOptions,
        plugins: projectTemplatePayTypesFormPlugins
      }
    );
  }

  @action.bound tearDown() {
    this.form = null;
    this.tearDownReactions();
    this.clearUIState();
  }

  @computed
  get templateHasNoPayTypes() {
    return this.form.$('hiddenPayTypes').value.length === this.payTypes.length;
  }

  @action.bound
  async togglePayTypeForTemplate(payType) {
    toggleArrayFieldValue(this.form, 'hiddenPayTypes', payType.uuid);

    if (this.templateHasNoPayTypes) {
      // We can't allow a template with no pay types so if they toggle the last one off we need to add it back.
      this.rootStore.notificationsUI.pushNotification({
        showUndo: false,
        snackbar: 'warning',
        title: t('This template must contain at least one pay type')
      });
      setTimeout(() => {
        // We need to set time out to meet ticket AC: "When they select the slider it should animate off and then back on"
        toggleArrayFieldValue(this.form, 'hiddenPayTypes', payType.uuid);
      }, 500);

      return;
    }

    try {
      await this.selectedTemplate.save(this.form.values(), {
        wait: true,
        url: `/ra/project-templates/${this.selectedTemplate.id}`
      });

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: this.form
          .$('hiddenPayTypes')
          .value?.find(hiddenPayType => hiddenPayType === payType.uuid)
          ? t('Pay type disabled')
          : t('Pay type enabled')
      });

      this.setupForm();
    } catch (error) {
      this.notifications.pushNotification({
        snackbar: 'error',
        icon: 'close',
        title: t('Pay type change cannot be saved')
      });
      toggleArrayFieldValue(this.form, 'hiddenPayTypes', payType.uuid);
    } finally {
      this.saving = false;
    }
  }
}
