import { action, computed, observable } from 'mobx';
import { BASE_DEBOUNCE } from 'fixtures/constants';
import debounce from 'lodash.debounce';
import alertErrorHandler from 'utils/alertErrorHandler';
import {
  SubmissionSettingsForm,
  submissionSettingsFormOptions,
  submissionSettingsFormFields,
  submissionSettingsFormLabels,
  submissionSettingsFormRules,
  submissionSettingsFormPlugins
} from 'forms/submissionSettings';
import SubmissionSettings from 'stores/models/SubmissionSettings';
import { t } from 'utils/translate';
import UIStore from '../../UIStore';
import { callTrack } from 'utils/segmentIntegration';
import {
  COMPANY_INCIDENTS_SETTINGS_VIEWED,
  COMPANY_INCIDENTS_SETTINGS_SAVED
} from 'utils/segmentAnalytics/eventSpec';
import history from 'utils/history';

export default class IncidentsSubmissionSettingsUI extends UIStore {
  @observable loading;
  @observable submissionSettingsForm;
  @observable nextUrl;

  constructor(options) {
    super(options);

    this.loading = true;
    this.submissionSettingsForm = null;
    this.submissionSettings = new SubmissionSettings(null, {
      parent: this,
      rootStore: this.rootStore
    });
    this.fetchSubmissionSettingsDebounced = debounce(
      this.fetchSubmissionSettings,
      BASE_DEBOUNCE
    );
    this.nextUrl = null;
  }

  @action.bound async setup() {
    callTrack(COMPANY_INCIDENTS_SETTINGS_VIEWED);
    this.loading = true;
    await this.submissionSettings.fetch();
    this.setupSubmissionSettingsForm();
    this.loading = false;
  }

  @action.bound
  async setupSubmissionSettingsForm() {
    this.submissionSettingsForm = new SubmissionSettingsForm(
      {
        fields: submissionSettingsFormFields,
        rules: submissionSettingsFormRules,
        labels: submissionSettingsFormLabels,
        values: this.submissionSettings.formValues
      },
      {
        options: submissionSettingsFormOptions,
        plugins: submissionSettingsFormPlugins,
        rootStore: this.rootStore
      }
    );
    this.blockHistoryIfFormChanged();
  }

  @action.bound tearDown() {
    this.clearUIState();
    this.unblockHistory();
  }

  @action.bound resetSubmissionSettingsForm(e) {
    e.preventDefault();
    this.submissionSettingsForm.reset();
  }

  @action.bound
  async submitSubmissionSettingsForm(e) {
    e.preventDefault();
    await this.rootStore.authorizationUI.checkFeatureAccess('CUDIncidents');
    this.submissionSettingsForm.submit({
      onSuccess: this.submitSubmissionSettingsFormSuccess,
      onError: e => {
        console.error(this.submissionSettingsForm.errors());
      }
    });
  }

  @action.bound
  async submitSubmissionSettingsFormSuccess() {
    this.saving = true;
    this.clearValidationDetails();

    try {
      await this.submissionSettings.save(this.submissionSettingsForm.values(), {
        method: 'put',
        wait: true
      });
      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Submission settings saved')
      });

      const {
        establishmentName,
        city,
        state,
        naicsCode,
        establishmentSize,
        annualAverageEmployees,
        totalHoursWorked
      } = this.submissionSettingsForm.values();

      callTrack(COMPANY_INCIDENTS_SETTINGS_SAVED, {
        establishment_name: establishmentName,
        city,
        state,
        naics_code: naicsCode,
        establishment_size: establishmentSize,
        average_annual_employees: annualAverageEmployees,
        total_hours_worked: totalHoursWorked
      });
      this.unblockHistory();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound async fetchSubmissionSettings() {
    this.loading = true;
    try {
      await this.submissionSettings.fetch();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get establishmentSizeOptions() {
    return [
      { id: 'LESS_THAN_20', name: '< 20 employees' },
      { id: 'BETWEEN_21_99', name: '20-99 employees ' },
      { id: 'BETWEEN_100_249', name: '100-249 employees ' },
      { id: 'GREATER_THAN_249', name: '250+ employees' }
    ];
  }

  @computed get stateOptions() {
    const value = this.submissionSettingsForm.$('state').value;

    if (!value || this.regions.isValidUsState(value)) {
      return this.regions.asOptions;
    }

    return [{ name: t('Unrecognized: ') + value, value: value }].concat(
      this.regions.asOptions
    );
  }

  @computed get selectedState() {
    return this.stateOptions.find(state => {
      return state.value === this.submissionSettingsForm.$('state').value;
    });
  }

  @action.bound clearUIState() {
    this.loading = true;
    this.saving = false;
    this.submissionSettingsForm = null;
    this.nextUrl = null;
  }

  @computed get hasWriteAccess() {
    return this.authorization.canCUDIncidents;
  }

  @action.bound blockHistoryIfFormChanged() {
    this.unblock = history.block((location, action) => {
      if (
        this.submissionSettingsForm &&
        this.submissionSettingsForm.check('isDirty')
      ) {
        this.showDiscardModal(location.pathname);
        return 'Blocked';
      }
    });
  }

  @action.bound
  showDiscardModal(nextUrl) {
    this.showModal('DiscardChangesModal');
    this.nextUrl = nextUrl;
  }

  @action.bound
  async discardChanges() {
    await this.hideActiveModal();
    this.moveToNextUrl();
  }

  @action.bound
  async moveToNextUrl() {
    this.unblock();
    history.push(this.nextUrl);
    this.nextUrl = null;
  }

  @action.bound unblockHistory() {
    this.unblock && this.unblock();
  }
}
