import { computed } from 'mobx';
import { RakenCollection } from '../lib';
import Region from '../models/Region';

export default class Regions extends RakenCollection {
  model() {
    return Region;
  }

  url() {
    return `ra/regions`;
  }

  @computed get asOptions() {
    return this.models.map(model => {
      return {
        value: model.code,
        name: model.displayName
      };
    });
  }

  isValidUsState = code => {
    return !!this.asOptions.find(option => option.value === code);
  };
}
