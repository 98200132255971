import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';
import { t } from 'utils/translate';

const addSuperUserFormPlugins = extendedPlugins;

const addSuperUserFormOptions = {
  validateOnInit: false,
  showErrorsOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const addSuperUserFormFields = [
  'username',
  'password',
  'firstName',
  'lastName'
];

const addSuperUserFormRules = {
  password: 'required|string|min:6|max:255',
  username: 'required|superUsername_available|rakenAppEmail:username',
  lastName: 'string|max:255',
  firstName: 'string|max:255'
};

const addSuperUserFormLabels = {
  username: t('username'),
  password: t('password'),
  lastName: t('last name'),
  firstName: t('first name')
};

class AddSuperUserForm extends MobxReactForm {}

export {
  AddSuperUserForm,
  addSuperUserFormOptions,
  addSuperUserFormFields,
  addSuperUserFormRules,
  addSuperUserFormLabels,
  addSuperUserFormPlugins
};
