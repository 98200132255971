import { BASE_DEBOUNCE } from 'fixtures/constants';
import debounce from 'lodash.debounce';
import { action, computed, observable, reaction, runInAction } from 'mobx';

import DocumentsFolder from 'stores/models/documents/DocumentsFolder';

import DocumentActionsUI from 'stores/ui/project/documents/DocumentActionsUI';
import DocumentAddFilesUI from 'stores/ui/project/documents/DocumentAddFilesUI';
import DocumentEditUI from 'stores/ui/project/documents/DocumentEditUI';
import DocumentFiltersUI from 'stores/ui/project/documents/DocumentFiltersUI';

import alertErrorHandler from 'utils/alertErrorHandler';
import history from 'utils/history';
import { t } from 'utils/translate';
import ProjectChildUI from '../ProjectChildUI';

export default class DocumentFolderUI extends ProjectChildUI {
  @observable sortField;
  @observable sortDirection;
  @observable pageSize;
  @observable page;
  @observable loading;

  @observable folder;

  constructor(options) {
    super(options);

    this.folder = null;

    this.loading = true;

    this.sortField = 'name';
    this.sortDirection = 'asc';
    this.page = 1;
    this.pageSize = 20;

    this.documentActionsUI = new DocumentActionsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.documentFiltersUI = new DocumentFiltersUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.documentAddFilesUI = new DocumentAddFilesUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.documentEditUI = new DocumentEditUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.folder = new DocumentsFolder(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchFolderDebounced = debounce(this.fetchFolder, BASE_DEBOUNCE);
  }

  @action.bound setup() {
    window.scrollTo(0, 0);
    this.fetchFolder();
    this.setupReactions();
  }

  @action.bound setFolder(folderId = null) {
    if (folderId === 'index') {
      folderId = null;
    }

    this.folder.id = folderId;
  }

  @action.bound tearDown() {
    if (this.parent) {
      this.parent.refetchDocuments();
    }

    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchFolderDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed
  get params() {
    let result = {
      projectUuids: this.projectUuid,
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      sortField: this.sortField,
      sortDirection: this.sortDirection
    };

    if (this.filterValues.typeFilter) {
      if (this.filterValues.typeFilter === 'FOLDER') {
        result.types = 'FOLDER';
      } else {
        result.fileType = this.filterValues.typeFilter;
      }
    }

    if (this.filterValues.createdByFilters?.length > 0) {
      result.createdBy = this.filterValues.createdByFilters.join(',');
    }

    if (this.filterValues.updatedByFilters?.length > 0) {
      result.updatedBy = this.filterValues.updatedByFilters.join(',');
    }

    return result;
  }

  @computed get hasRequiredParams() {
    return this.params.projectUuids;
  }

  @computed get documentsConstants() {
    return this.parent.documentsConstants;
  }

  @computed get documentsStat() {
    return this.parent.documentsStat;
  }

  @computed get documents() {
    return this.folder?.children;
  }

  @action.bound async fetchFolder() {
    if (!this.hasRequiredParams) {
      this.loading = false;
      return;
    }

    this.folder.cancelRequest();
    this.folder.clear();

    try {
      await this.folder.fetch({
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasChildren() {
    return !!this.folder?.children?.hasModels;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed
  get totalPages() {
    return !!this.documents?.totalElements
      ? Math.ceil(this.documents.totalElements / this.pageSize)
      : 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.page = 1;
    this.loading = true;
    this.saving = false;
    this.sortField = 'name';
    this.sortDirection = 'asc';

    this.folder.clear();

    this.documentFiltersUI.clearUIState();
    this.documentActionsUI.clearUIState();
    this.documentAddFilesUI.clearUIState();
    this.documentEditUI.clearUIState();
  }

  // Can be used to show top level folder info like breadcrumbs
  // We use the usual states belong for the table of children items
  @computed get showFolderUI() {
    return this.folder?.name;
  }

  @computed get showEmptyState() {
    return (
      !this.loading &&
      !this.hasChildren &&
      !this.documentFiltersUI.hasAppliedFilters
    );
  }

  @computed get showEmptyFilterState() {
    return (
      !this.loading &&
      !this.hasChildren &&
      this.documentFiltersUI.hasAppliedFilters
    );
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @action.bound sortByLastUpdated() {
    this.sortField = 'updatedTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchFolderDebounced();
  }

  @action.bound refetchDocuments() {
    this.loading = true;
    this.setPage(null, 1);
    this.fetchFolder();
  }

  @action.bound editDocument(document) {
    history.push({
      pathname: document.viewUrl,
      search: this.baseQueryParams
    });
  }

  @action.bound showUpgradeModal() {
    this.parent.showUpgradeModal();
  }

  @action.bound hideUpgradeModal() {
    this.parent.hideActiveModal();
  }

  getChildActions = model => {
    // Used to populate the action buttons on each row in the table.
    // A model could be isFile or isFolder so would change depending on that.
    let actions = [];

    if (model.isFile) {
      actions.push({
        title:
          this.hasWriteAccess &&
          model?.document?.contentType === 'application/pdf'
            ? t('Edit')
            : t('View'),
        onClick: () => {
          this.editDocument(model);
        }
      });
    }

    if (this.hasWriteAccess) {
      actions.push(
        {
          title: t('Rename'),
          onClick: () => {
            this.documentActionsUI.renameDocument(model);
          }
        },
        {
          title: t('Delete'),
          onClick: () => {
            this.documentActionsUI.deleteDocument(model);
          }
        }
      );
    }

    actions.push({
      title: t('Download'),
      onClick: () => {
        model.isFile
          ? this.documentActionsUI.downloadDocumentFile(model)
          : this.documentActionsUI.downloadDocuments(model);
      }
    });

    return actions;
  };

  @computed get hasWriteAccess() {
    return this.authorization.canCUDDocuments;
  }

  @computed get filterValues() {
    return {
      typeFilter: this.documentFiltersUI.typeFilter,
      createdByFilters: this.documentFiltersUI.createdByFilters.map(
        owner => owner.uuid
      ),
      updatedByFilters: this.documentFiltersUI.updatedByFilters.map(
        owner => owner.uuid
      )
    };
  }

  @computed get enableBulkActions() {
    return true;
  }
}
