import UIStore from './UIStore';
import { action, observable, computed, reaction } from 'mobx';
import errorHandler from 'utils/errorHandler';

import Project from '../models/Project';
import orderBy from 'lodash.orderby';
import { t } from 'utils/translate';

export default class CollaboratorSelectorUI extends UIStore {
  @observable searchQuery;
  @observable loading;

  constructor(options) {
    super(options);

    this.loading = false;
    this.searchQuery = '';
  }

  @computed
  get projectUuid() {
    return (
      this.project?.uuid ||
      this.rootStore.companyUI.checklistSchedulesUI?.projectUuid
    );
  }

  @action.bound
  setup() {
    // If we are on the project page we don't need to fetch
    if (this.project) {
      this.selectedProject = this.project;
    } else {
      this.selectedProject = new Project(
        {
          id: this.projectUuid
        },
        {
          rootStore: this.rootStore
        }
      );

      this.fetchProject();
    }

    this.setupReactions();
  }

  @action.bound
  tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  @action.bound setupReactions() {
    this.reactToProjectUuid = reaction(
      () => this.projectUuid,
      projectUuid => {
        if (projectUuid) this.fetchProject();
      }
    );
  }

  @action.bound tearDownReactions() {
    this.reactToProjectUuid && this.reactToProjectUuid();
  }

  @action.bound clearUIState() {
    this.loading = false;
    this.searchQuery = '';
    this.selectedProject = null;
  }

  @computed get options() {
    const options = this.projectUuid
      ? this.selectedProject.childProjects.models
          .map(projectTeam => {
            return {
              name: projectTeam.name,
              uuid: projectTeam.uuid
            };
          })
          .filter(option =>
            option.name.toLowerCase().includes(this.searchQuery.toLowerCase())
          )
      : [];

    return orderBy(options, ['name'], ['asc']);
  }

  @computed get noOptionsText() {
    return this.loading
      ? t('Searching')
      : !this.options.length
      ? t('No results')
      : false;
  }

  @action.bound
  setSearchQuery(query) {
    this.searchQuery = query;
  }

  @action.bound
  clearSearchQuery() {
    this.searchQuery = '';
  }

  @action.bound
  async fetchProject() {
    if (!this.projectUuid) return;

    this.loading = true;

    try {
      await this.selectedProject?.fetch({
        url: `ra/companies/${this.company.uuid}/projects/${this.projectUuid}`,
        params: {
          mn: 'full',
          mc: 'full'
        }
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    } finally {
      this.loading = false;
    }
  }
}
