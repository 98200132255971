import orderBy from 'lodash.orderby';
import { RakenCollection } from './../lib';
import { computed, observable, action } from 'mobx';

class PageableBilling extends RakenCollection {
  @observable page = 0;
  @observable pageSize = 20;
  @observable sortField;

  constructor(data, options) {
    super(data, options);

    this.sortField = 'createdTimeStamp';
  }

  @action.bound
  nextPageBilling() {
    this.page += 1;
  }

  @action.bound
  resetPage() {
    this.page = 0;
  }

  @computed get hasFilteredResults() {
    return this.filteredResults.length > 0;
  }

  @computed
  get resultsPerPage() {
    return this.page * this.pageSize;
  }

  @computed
  get sortedFilteredResults() {
    return orderBy(
      this.filteredResults,
      [this.sortField],
      [this.sortDirection]
    );
  }

  @computed
  get paginatedFilteredResults() {
    return this.sortedFilteredResults.slice(
      this.page * this.pageSize,
      this.page * this.pageSize + this.pageSize
    );
  }

  @computed
  get hasMorePages() {
    return this.filteredResults.length > this.pageSize * this.page;
  }

  @action.bound
  sortByColumn(fieldName) {
    if (this.sortField === fieldName) {
      if (this.sortDirection === 'asc') {
        this.sortDirection = 'desc';
      } else {
        this.sortDirection = 'asc';
      }
    } else {
      this.sortField = fieldName;
      this.sortDirection = 'asc';
    }
  }

  @action.bound
  setPage(page) {
    this.page = page.selected;
  }

  @action.bound
  setPageAthens(event, page) {
    this.page = page - 1;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.filteredResults.length / this.pageSize);
  }
}

export { PageableBilling };
