import React from 'react';
import styled, { css } from 'styled-components';
import Icon from './Icon';

const Wrapper = styled.div`
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  justify-content: center;
  position: absolute;
  ${props =>
    props.left &&
    css`
      left: ${props => props.left};
    `};
  ${props =>
    props.right &&
    css`
      right: ${props => props.right};
    `};
  top: 50%;
  ${props =>
    props.iconBorder &&
    css`
      border: 1px solid;
      border-color: ${props =>
        props.open || props.withBorder
          ? props.theme.colors[props.iconColor || 'lightGrey1']
          : 'transparent'};
    `};

  border-radius: 50%;
  display: flex;
  transform: ${props => (props.disableTransform ? 'none' : 'translateY(-50%)')};
  height: 25px;
  width: 25px;
  svg {
    width: 11px;
    height: 11px;
    fill: ${props => props.theme.colors[props.iconColor || 'lightGrey1']};
    transform: rotate(${props => (props.open ? '180deg' : '0deg')});
    ${props =>
      props.animate &&
      css`
        transition: 1s all;
      `};
  }
  &:hover {
    border-color: ${props =>
      props.theme.colors[props.iconColor || 'lightGrey1']};
  }
`;

const ExpandIcon = ({
  open,
  top,
  left,
  right,
  iconBorder,
  withBorder,
  onClick,
  animate,
  disableTransform,
  iconColor,
  iconBorderColor
}) => (
  <Wrapper
    open={open}
    top={top}
    left={left}
    right={right}
    iconBorder={iconBorder}
    withBorder={withBorder}
    onClick={onClick}
    animate={animate}
    disableTransform={disableTransform}
    iconBorderColor={iconBorderColor}
    iconColor={iconColor}
  >
    <Icon kind="expand" />
  </Wrapper>
);

ExpandIcon.defaultProps = {
  open: false,
  withBorder: false,
  iconBorder: true,
  animate: true
};

export default ExpandIcon;
