import { computed } from 'mobx';
import { Model } from 'mobx-mc';

export default class IntegrationProjectConfiguration extends Model {
  get restAttributes() {
    return [
      'key',
      'type',
      'value',
      'displayName',
      'description',
      'hidden',
      'projectUuid',
      'configurations'
    ];
  }

  idAttribute() {
    return 'projectUuid';
  }

  @computed get configuration() {
    return this.configurations[0];
  }
}
