import request from 'axios';
import { action, computed, observable } from 'mobx';
import UIStore from '../UIStore';

import alertErrorHandler from 'utils/alertErrorHandler';
import history from 'utils/history';
import { t } from 'utils/translate';

import {
  BillingInfoForm,
  billingInfoFormOptions,
  billingInfoFormFields,
  billingInfoFormRules,
  billingInfoFormLabels,
  billingInfoFormPlugins,
  billingInfoFormRelated
} from 'forms/billing/billingInfo';

import countryReaction from 'utils/countryReaction';

export default class BillingInfoEditUI extends UIStore {
  @observable form;

  constructor(options) {
    super(options);
    this.form = null;
  }

  @computed get billingInfo() {
    return this.parent.billingInfo;
  }

  @computed get invoicesUI() {
    return this.parent.invoicesUI;
  }

  @computed get company() {
    return this.parent.company;
  }

  @action.bound setup() {
    this.setupForm();
    this.setupReactions();
  }

  @action.bound setupForm() {
    let values;

    if (this.billingInfo.isNew) {
      values = {
        payment_method_type: 'CREDIT_CARD',
        country: this.company.address?.country || 'US'
      };
    } else {
      values = this.billingInfo.formValues;
    }

    this.form = new BillingInfoForm(
      {
        fields: billingInfoFormFields,
        rules: billingInfoFormRules,
        values: values,
        labels: billingInfoFormLabels,
        related: billingInfoFormRelated
      },
      {
        options: billingInfoFormOptions,
        plugins: billingInfoFormPlugins
      }
    );
  }

  @action.bound
  setupReactions() {
    this.reactToCountry = countryReaction(this, this.form, 'country', 'state');
  }

  @action.bound tearDownReactions() {
    this.reactToCountry && this.reactToCountry();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.form = null;
    this.clearValidationDetails();
  }

  @action.bound submitForm(recurly, formRef) {
    this.form.submit({
      onSuccess: () => {
        this.submitFormSuccess(recurly, formRef);
      },
      onError: this.submitFormError
    });
  }

  @action.bound async submitFormSuccess(recurly, formRef) {
    this.clearValidationDetails();

    this.saving = true;

    for (const k of ['country', 'state']) {
      const input = document.querySelector(`[data-recurly="${k}"]`);
      input.value = this.form.$(k).value;
    }

    if (this.form.$('payment_method_type').value === 'BANK_ACCOUNT_INFO') {
      const accountTypeInput = document.querySelector(
        '[data-recurly="account_type"]'
      );

      accountTypeInput.value = this.form.$('account_type').value;

      recurly.bankAccount.token(formRef.current, async (error, token) => {
        this.submitTokenToAPI(error, token);
      });
    } else {
      recurly.token(formRef.current, async (error, token) => {
        this.submitTokenToAPI(error, token);
      });
    }
  }

  @action.bound async submitTokenToAPI(error, token) {
    if (error) {
      this.setValidationDetails([
        {
          field: 'message',
          fieldMessage: error.message
        }
      ]);

      this.saving = false;
      // Do something with errors
      return;
    }

    try {
      await request.put(this.billingInfo.url(), {
        creditCardToken: token.id
      });

      await Promise.all([
        this.parent.fetchBillingInfo(),
        this.invoicesUI.refetchInvoices()
      ]);

      this.cancelBillingInfoEdit();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Billing info saved')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitFormError() {
    console.error(this.form.errors());
  }

  @action.bound cancelBillingInfoEdit() {
    history.push(`/companies/${this.company.uuid}/subscription-billing`);
  }

  @computed get countryOptions() {
    return this.countries.asOptions;
  }

  @computed get selectedCountry() {
    return this.countryOptions.find(country => {
      return country.value === this.form.$('country').value;
    });
  }

  @computed get stateOptions() {
    const value = this.form.$('state').value;

    if (!value || this.regions.isValidUsState(value)) {
      return this.regions.asOptions;
    }

    return [{ name: t('Unrecognized: ') + value, value: value }].concat(
      this.regions.asOptions
    );
  }

  @computed get selectedState() {
    return this.stateOptions.find(state => {
      return state.value === this.form.$('state').value;
    });
  }

  @computed get accountTypeOptions() {
    return [
      {
        value: 'checking',
        name: 'Checking'
      },
      {
        value: 'savings',
        name: 'Savings'
      }
    ];
  }

  @computed get selectedAccountType() {
    return this.accountTypeOptions.find(option => {
      return option.value === this.form.$('account_type').value;
    });
  }
}
