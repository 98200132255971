import { action, observable } from 'mobx';
import UIStore from 'stores/ui/UIStore';

import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';
import downloadURI from 'utils/downloadURI';

import {
  RenameFormTemplateForm,
  renameFormTemplateFormOptions,
  renameFormTemplateFormFields,
  renameFormTemplateFormRules,
  renameFormTemplateFormLabels,
  renameFormTemplateFormPlugins
} from 'forms/project/renameFormTemplate';

import {
  FORM_TEMPLATE_DOWNLOADED,
  FORM_TEMPLATE_RENAMED,
  FORM_TEMPLATE_DELETED
} from 'utils/segmentAnalytics/eventSpec';

import { callTrack } from 'utils/segmentIntegration';

export default class FormTemplateActionsUI extends UIStore {
  @observable selectedFormTemplate;
  @observable renameFormTemplateForm;

  constructor(options) {
    super(options);

    this.loading = true;

    this.selectedFormTemplate = null;
    this.renameFormTemplateForm = null;
  }

  @action.bound clearUIState() {
    this.selectedFormTemplate = null;
    this.renameFormTemplateForm = null;
  }

  @action.bound async deleteFormTemplate(formTemplate) {
    await this.authorization.checkFeatureAccess('CUDForms');

    this.selectedFormTemplate = formTemplate;
    this.showModal('DeleteModal');
  }

  @action.bound async cancelDeleteFormTemplate() {
    await this.hideActiveModal();

    this.selectedFormTemplate = null;
  }

  @action.bound async confirmDeleteFormTemplate() {
    this.saving = true;

    try {
      await this.selectedFormTemplate.destroy({ wait: true });

      this.parent.refetchFormTemplates();

      callTrack(FORM_TEMPLATE_DELETED, {
        template_name: this.selectedFormTemplate.name
      });

      await this.hideActiveModal();

      this.selectedFormTemplate = null;

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Form template deleted')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound renameFormTemplate(formTemplate) {
    this.selectedFormTemplate = formTemplate;

    this.renameFormTemplateForm = new RenameFormTemplateForm(
      {
        fields: renameFormTemplateFormFields,
        rules: renameFormTemplateFormRules,
        values: {
          name: this.selectedFormTemplate.name.replace(/\.[^/.]+$/, ''),
          initialName: this.selectedFormTemplate.name
        },
        labels: renameFormTemplateFormLabels
      },
      {
        options: renameFormTemplateFormOptions,
        plugins: renameFormTemplateFormPlugins,
        rootStore: this.rootStore
      }
    );

    this.showModal('RenameFormTemplateModal');
  }

  @action.bound async cancelRenameFormTemplate() {
    await this.hideActiveModal();

    this.renameFormTemplateForm = null;
    this.selectedFormTemplate = null;
  }

  @action.bound submitRenameFormTemplateForm(event) {
    event.preventDefault();

    this.renameFormTemplateForm.submit({
      onSuccess: this.submitRenameFormTemplateFormSuccess,
      onError: this.submitRenameFormTemplateFormError
    });
  }

  @action.bound async submitRenameFormTemplateFormSuccess() {
    this.saving = true;

    const values = this.renameFormTemplateForm.values();
    const name = `${values.name}.${this.selectedFormTemplate.name
      .split('.')
      .pop()}`;

    try {
      await this.selectedFormTemplate.save(
        {
          name: name
        },
        {
          wait: true
        }
      );

      callTrack(FORM_TEMPLATE_RENAMED, {
        original_template_name: this.selectedFormTemplate.name,
        new_template_name: name
      });

      this.cancelRenameFormTemplate();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Form template renamed')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitRenameFormTemplateFormError() {
    console.error(this.renameFormTemplateForm.errors());
  }

  @action.bound downloadFormTemplate(formTemplate) {
    downloadURI(
      `${formTemplate.document.contentUrl}?version=${formTemplate.lastUpdated}`,
      formTemplate.name,
      false
    );

    callTrack(FORM_TEMPLATE_DOWNLOADED, {
      form_template_name: formTemplate.name
    });
  }
}
