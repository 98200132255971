import { Model } from 'mobx-mc';
import { computed } from 'mobx';
import moment from 'moment';

export default class CompletedDaily extends Model {
  get restAttributes() {
    return ['id', 'status', 'date', '_links', 'segmentUuid'];
  }

  @computed
  get statusText() {
    const status = this.status.replace(/_/gi, ' ');
    return status === 'NO WORK DONE' ? 'NO WORK' : status;
  }

  @computed
  get relatedProject() {
    return this.collection.relatedProject;
  }

  @computed
  get dateFormatted() {
    return moment(this.date).format('YYYY-MM-DD');
  }

  @computed
  get pdfs() {
    let pdfs = {};

    if (this._links.dailyPdf) {
      pdfs.daily = this._links.dailyPdf.href;
    }

    if (this._links.superDailyPdfFull) {
      pdfs.super = this._links.superDailyPdfFull.href;
    }

    return pdfs;
  }

  @computed get segmentName() {
    if (!this.segmentUuid) return null;

    return this.relatedProject.segments?.find(
      segment => segment.uuid === this.segmentUuid
    )?.name;
  }
}
