import moment from 'moment';
import omit from 'lodash.omit';
import kebabCase from 'lodash.kebabcase';
import orderBy from 'lodash.orderby';
import { computed } from 'mobx';
import { Model } from 'mobx-mc';

import Attachments from '../collections/Attachments';

export default class MemberCertification extends Model {
  get urlRoot() {
    return `ra/companies/${this.rootStore.me.company.uuid}/members/${this
      .workerUuid || this.workerUuidFallback}/certifications`;
  }

  @computed get workerUuidFallback() {
    return (
      this.parent?.entryForEdit?.workerUuid ||
      this.collection?.parent?.entryForEdit?.workerUuid
    );
  }

  get restAttributes() {
    return [
      'uuid',
      'type',
      'workerName',
      'workerUuid',
      'certification',
      'description',
      'expirationDate',
      'updatedTimestamp',
      'createdTimestamp'
    ];
  }

  idAttribute() {
    return 'uuid';
  }

  parse(attributes) {
    this.parseAttachments(attributes);

    return {
      ...omit(attributes, ['attachments'])
    };
  }

  @computed get certificationSlug() {
    return kebabCase(this.certification.name);
  }

  parseAttachments(attributes) {
    if (this.attachments) {
      this.attachments.reset(attributes.attachments);
    } else {
      this.attachments = new Attachments(attributes.attachments, {
        rootStore: this.rootStore,
        parent: this
      });
    }
  }

  @computed
  get hasAttachments() {
    return this.attachments && this.attachments.length > 0;
  }

  @computed
  get sortedAttachments() {
    return orderBy(this.attachments.models, ['createdTimestamp'], ['desc']);
  }

  @computed
  get attachmentsCount() {
    return this.attachments?.length;
  }

  @computed
  get attachmentsCountPreviewImage() {
    // Find the first attachment that has a thumbUrl
    const attachmentWithThumbUrl = this.attachments?.models.find(
      attachment => attachment.thumbUrl
    );

    return attachmentWithThumbUrl?.thumbUrl;
  }

  @computed get formValues() {
    return {
      certification: {
        uuid: this.certification.uuid,
        name: this.certification.name,
        certificationType: this.certification.certificationType
      },
      expirationDate: this.expirationDate,
      description: this.description
    };
  }

  @computed get expired() {
    return moment(this.expirationDate)
      .endOf('day')
      .isBefore(moment());
  }
}
