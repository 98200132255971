import UIStore from './UIStore';
import { action, observable } from 'mobx';
import moment from 'moment';
import history from 'utils/history';
import Talks from '../collections/Talks';

export default class ToolboxTalks extends UIStore {
  @observable downloadForm;
  @observable statusDownloadPdf;
  @observable redirectUploadTalk;
  @observable uploadingTalks;
  @observable loading;

  constructor(options) {
    super(options);

    this.redirectUploadTalk = false;
    this.uploadingTalks = false;
    this.loading = false;

    this.completedTalks = new Talks(null, {
      rootStore: this.rootStore,
      parent: this
    });

    this.wholeTalks = new Talks(null, {
      rootStore: this.rootStore,
      parent: this
    });
  }

  @action.bound handleUploadTalk() {
    this.showModal('talksUpload');
  }

  @action.bound
  redirectUploadManager() {
    this.redirectUploadTalk = true;

    let baseUrl = '';

    if (this.project) {
      baseUrl = `/project/${this.project.id}/talks/`;
    } else {
      baseUrl = `/company-settings/safety/toolbox-talks`;
    }

    return new Promise(resolve => {
      if (this.tab !== 'all') {
        history.push(baseUrl);

        this.fetchTalks().then(() => {
          resolve();
        });
      } else {
        this.redirectUploadTalk = false;
        resolve();
      }
    });
  }

  @action.bound
  fetchTalksWhole() {
    return this.wholeTalks.fetch({
      params: {
        limit: 10000
      }
    });
  }

  @action.bound setUploading() {
    this.uploadingTalks = true;
  }

  @action.bound clearUploading() {
    this.uploadingTalks = false;
  }

  @action.bound
  handleSearchDateFormatted(search) {
    const date = moment(
      search,
      [
        'MM/DD/YYYY',
        'MM/DD/YY',
        'MMM D, YYYY',
        'MMMM D, YYYY',
        'MMMM Do,YYYY',
        'MMMM Do, YYYY'
      ],
      true
    );

    return date.isValid() ? date.format('YYYY-MM-DD') : search;
  }
}
