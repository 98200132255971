import styled from 'styled-components';

export default styled.div`
  background-color: ${props =>
    props.color
      ? props.theme.colors[props.color]
      : props.theme.colors.lightGrey2};
  border-radius: 4px;
  display: inline-block;
  height: ${props => props.size || 16}px;
  margin-bottom: ${props => props.marginBottom || 0}px;
  margin-right: ${props => props.marginRight || 0}px;
  width: ${props => props.size || 16}px;
  min-width: ${props => props.size || 16}px;
`;
