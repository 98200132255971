import { action, runInAction, computed, observable } from 'mobx';
import UIStore from './UIStore';
import errorHandler from 'utils/errorHandler';
import orderBy from 'lodash.orderby';

import {
  UnitForm,
  unitFormOptions,
  unitFormFields,
  unitFormRules,
  unitFormLabels,
  unitFormPlugins
} from 'forms/unit';

import { t } from 'utils/translate';

export default class UnitsUI extends UIStore {
  @observable materialForm;
  @observable createUnitForm;

  constructor(options) {
    super(options);
    this.materialForm = null;
    this.createUnitForm = null;
  }

  @action.bound showCreateUnitModal(materialForm) {
    this.createUnitForm = new UnitForm(
      {
        fields: unitFormFields,
        rules: Object.assign(unitFormRules, {
          name: `string|required|not_in:${this.rootStore.units.models
            .map(unit => unit.name)
            .join(',')}`
        }),
        labels: unitFormLabels
      },
      {
        options: unitFormOptions,
        plugins: unitFormPlugins,
        rootStore: this.rootStore
      }
    );

    this.materialForm = materialForm;

    this.showModal('createUnit');
  }

  @action.bound submitCreateUnitForm() {
    this.createUnitForm.submit({
      onSuccess: this.submitCreateUnitFormSuccess,
      onError: this.submitCreateUnitFormError
    });
  }

  @action.bound submitCreateUnitFormSuccess() {
    this.saving = true;

    this.rootStore.units
      .create(this.createUnitForm.values(), {
        wait: true
      })
      .then(unit => {
        this.updateMaterialForm(unit.uuid);
        this.hideCreateUnitModal();
      })
      .catch(error => {
        runInAction(() => {
          this.saving = false;
          errorHandler(error, this.notifications.pushError);
        });
      });
  }

  @action.bound submitCreateUnitFormError() {
    console.log(this.createUnitForm.errors());
  }

  @computed get createUnitFormInvalid() {
    return (
      this.createUnitForm.check('hasError') ||
      this.createUnitForm.check('isPristine')
    );
  }

  @action.bound hideCreateUnitModal() {
    this.hideActiveModal().then(() => {
      runInAction(() => {
        this.saving = false;
        this.createUnitForm = null;
        this.materialForm = null;
      });
    });
  }

  @action.bound updateMaterialForm(uuid) {
    if (this.materialForm) {
      this.materialForm.$('unit').set(uuid);
    }
  }

  @action.bound fetchUnits() {
    if (this.rootStore.units.length) return;

    this.rootStore.units.fetch({
      params: {
        limit: 10000,
        mn: 'full',
        mc: 'full'
      }
    });
  }

  @action.bound
  softDeleteUnit(unit) {
    unit = this.rootStore.units.get(unit.id);

    this.rootStore.units.remove(unit);

    if (this.materialForm) {
      this.materialForm.$('unit').set(null);
    }

    this.rootStore.notificationsUI.pushNotification({
      onUndo: () => {
        this.cancelDeleteUnit(unit);
      },
      onDismiss: () => {
        this.confirmDeleteUnit(unit);
      },
      title: t('Unit Deleted'),
      text: t('The {unitName} unit was deleted.', {
        templateStrings: {
          unitName: unit.name
        }
      })
    });
  }

  @action.bound
  cancelDeleteUnit(unit) {
    this.rootStore.units.add(unit);

    if (this.materialForm) {
      this.materialForm.$('unit').set(unit.id);
    }
  }

  @action.bound
  confirmDeleteUnit(unit) {
    unit.destroy();
  }

  @computed get sortedUnits() {
    return orderBy(
      this.rootStore.units.models,
      [unit => unit.name.toLowerCase()],
      ['asc']
    );
  }

  @computed
  get unitExists() {
    if (!this.createUnitForm) return false;

    const values = this.createUnitForm.trimmedValues();

    if (!values.name) return false;

    return this.rootStore.units.models.find(unit => {
      return unit.name.toLowerCase() === values.name.toLowerCase();
    });
  }
}
