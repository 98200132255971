import { action, computed, observable, runInAction, reaction } from 'mobx';
import SettingsChildUI from './SettingsChildUI';
import { BASE_DEBOUNCE } from 'fixtures/constants';
import debounce from 'lodash.debounce';
import identity from 'lodash.identity';
import pickBy from 'lodash.pickby';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import matchModelField from 'utils/matchModelField';
import orderBy from 'lodash.orderby';
import Breaks from 'stores/collections/Breaks';

export default class SettingsBreaksUI extends SettingsChildUI {
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable sortDirection;
  @observable sortField;
  @observable loading;

  constructor(options) {
    super(options);

    // Sorting
    this.sortDirection = 'asc';
    this.sortField = 'break';

    // Search & Pagination
    this.searchQuery = '';
    this.pageSize = 20;
    this.page = 1;

    this.loading = true;

    this.paginatedBreaks = new Breaks(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchBreaksDebounced = debounce(this.fetchBreaks, BASE_DEBOUNCE);
  }

  @action.bound setup() {
    this.setupReactions();
    this.fetchBreaks();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchBreaksDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      sortField: this.sortField,
      query: this.searchQuery,
      sortDirection: this.sortDirection
    };
  }

  @action.bound
  async fetchBreaks() {
    this.breaks.cancelRequest();
    this.breaks.reset();

    try {
      await this.breaks.fetch({
        params: pickBy(this.params, identity),
        add: true,
        remove: false,
        update: true
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  sortByColumn() {
    if (this.sortDirection === 'asc') {
      this.sortDirection = 'desc';
    } else {
      this.sortDirection = 'asc';
    }
  }

  @action.bound
  restoreBreak(breakItem) {
    const breakIndex = breakItem.hiddenProjects
      .map(item => item.uuid)
      .indexOf(this.project.uuid);

    breakItem.hiddenProjects.splice(breakIndex, 1);
  }

  @action.bound
  async toggleBreakForProject(breakItem) {
    await this.authorization.checkFeatureAccess('EditBreaks');

    if (!breakItem.assignedToCurrentProject) {
      this.restoreBreak(breakItem);
    } else {
      breakItem.hiddenProjects.push({ uuid: this.project.uuid });
    }

    try {
      await breakItem.save();
      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t(
          `Break ${breakItem.assignedToCurrentProject ? 'enabled' : 'disabled'}`
        )
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
      this.notifications.pushNotification({
        snackbar: 'error',
        icon: 'close',
        title: t('Break change cannot be saved')
      });
      this.restoreBreak(breakItem);
    }
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.breaks.clear();
    this.searchQuery = '';
    this.page = 1;
    this.loading = true;
    this.sortField = 'break';
    this.sortDirection = 'asc';
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @computed get headerTitle() {
    return t('Manage required employee breaks');
  }

  @computed get hasWriteAccess() {
    return this.authorization.canEditBreaks;
  }

  @computed get breaks() {
    return this.paginatedBreaks;
  }

  @computed
  get hiddenBreaksForProject() {
    return this.breaks.models.filter(breakItem =>
      breakItem.hiddenProjects.find(
        hiddenProject => hiddenProject.uuid === this.project.uuid
      )
    );
  }

  @computed get sortedBreaks() {
    return orderBy(this.breaks.models, ['order'], [this.sortDirection]);
  }

  @computed get searchedBreaks() {
    if (!this.searchQuery) {
      return this.sortedBreaks;
    }

    const query = this.searchQuery.toLowerCase();

    return this.sortedBreaks.filter(breakItem => {
      return (
        matchModelField(breakItem, query, 'name') ||
        matchModelField(breakItem, query, 'code')
      );
    });
  }

  @computed get showEmptyState() {
    return !this.loading && !this.hasBreaks;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @computed get hasBreaks() {
    return this.breaks.hasModels;
  }

  @computed get hasSearchedBreaks() {
    return this.searchedBreaks.length > 0;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.breaks.totalElements / this.pageSize);
  }

  @computed get title() {
    return t('Breaks settings');
  }
}
