import request from 'axios';
import { action, runInAction, observable, computed } from 'mobx';
import UIStore from './UIStore';
import pickBy from 'lodash.pickby';

import moment from 'moment-timezone';

import { t } from 'utils/translate';

import {
  VIOLATIONS_REPORT_DOWNLOADED,
  VIOLATIONS_REPORT_SCHEDULED,
  VIOLATIONS_REPORT_STARTED
} from 'utils/segmentAnalytics/eventSpec';

import { callTrack } from 'utils/segmentIntegration';
import errorHandler from 'utils/errorHandler';
import alertErrorHandler from 'utils/alertErrorHandler';

import ViolationsReport from '../models/ViolationsReport';

import {
  ViolationsReportForm,
  violationsReportFormOptions,
  violationsReportFormFields,
  violationsReportFormRules,
  violationsReportFormLabels,
  violationsReportFormValues,
  violationsReportFormPlugins
} from 'forms/violationsReport';

export default class ViolationsReportsUI extends UIStore {
  @observable activeReportTab;
  @observable scheduledReportToEdit;

  constructor(options) {
    super(options);

    this.activeReportTab = 'runOnce';
    this.scheduledReportToEdit = null;
  }

  @action.bound async showViolationsReportModal() {
    await this.rootStore.authorizationUI.checkFeatureAccess(
      'ScheduleTimeCardReports',
      true
    );

    this.showModal('violationsReport');
    callTrack(VIOLATIONS_REPORT_STARTED);
    this.fetchScheduled();
    this.setupModalForm();
  }

  @action.bound
  fetchScheduled() {
    this.violationsReports.fetch();
  }

  @computed
  get projectDefaultValue() {
    if (this.scheduledReportToEdit?.projects.length) {
      return this.scheduledReportToEdit.projects.map(project => {
        return {
          value: project.uuid,
          name: project.name
        };
      });
    }

    if (this.project) {
      return [
        {
          value: this.project.uuid,
          name: this.project.name,
          startDate: this.project.startDate,
          endDate: this.project.endDate
        }
      ];
    }

    return [];
  }

  @action.bound
  setupModalForm() {
    this.memberSelectorUI.setup({ companySettingsTrackMemberTime: true });

    this.initReportForm();
    if (this.project) {
      this.setProjectIncludeOption('SELECTED');
      this.selectProjects(this.projectDefaultValue);
    } else {
      this.setProjectIncludeOption('ACTIVE');
    }
  }

  @action.bound
  initReportForm() {
    const rules =
      this.activeReportTab === 'scheduled'
        ? Object.assign({}, violationsReportFormRules, {
            name: 'required|string|max:255',
            time: 'required'
          })
        : violationsReportFormRules;

    const values =
      this.activeReportTab === 'scheduled' && this.scheduledReportToEdit
        ? Object.assign(
            {},
            violationsReportFormValues,
            this.scheduledReportToEdit.formValues
          )
        : violationsReportFormValues;

    this.activeForm = new ViolationsReportForm(
      {
        fields: violationsReportFormFields,
        rules: rules,
        values: values,
        labels: violationsReportFormLabels
      },
      {
        options: violationsReportFormOptions,
        plugins: violationsReportFormPlugins,
        reportType: this.activeReportTab
      }
    );
  }

  @action.bound
  hideViolationsReportModal() {
    this.hideActiveModal().then(() => {
      runInAction(() => {
        this.activeForm = null;
        this.activeReportTab = 'runOnce';
        this.scheduledReportToEdit = null;
        this.saving = false;
        this.memberSelectorUI.tearDown();
      });
    });
  }

  @action.bound
  switchTab(e, tab) {
    this.activeForm = null;
    this.scheduledReportToEdit = null;
    this.activeReportTab = tab;
    this.setupModalForm();
  }

  @computed
  get scheduledReportNewOrSaveButtonText() {
    if (this.saving) return t('Saving...');

    return this.scheduledReportToEdit
      ? this.scheduledReportToEdit?.isNew
        ? t('Schedule report')
        : t('Save changes')
      : t('New report');
  }

  @computed get violationsReports() {
    return this.rootStore.violationsReports;
  }

  @computed
  get hasNoScheduledReports() {
    return (
      !this.fetchingScheduledReports &&
      !this.violationsReports.fetching &&
      !this.violationsReports.hasModels
    );
  }

  @action.bound
  selectProjects(selectedOptions) {
    this.activeForm.update({
      projects: selectedOptions.map(option => {
        return {
          uuid: option.value,
          name: option.name
        };
      })
    });

    this.activeForm.validate();
  }

  @computed
  get projectIncludeOptions() {
    return [
      {
        label: t('Active projects'),
        value: 'ACTIVE',
        dataQA: 'active-projects'
      },
      {
        label: t('Active and inactive projects'),
        value: 'ACTIVE_AND_INACTIVE',
        dataQA: 'active-and-inactive-projects'
      },
      {
        label: t('Selected'),
        value: 'SELECTED',
        dataQA: 'selected-projects'
      }
    ];
  }

  @action.bound
  setProjectIncludeOption(value) {
    if (value === 'ACTIVE' || value === 'ACTIVE_AND_INACTIVE') {
      this.activeForm.update({
        projects: []
      });
    }

    this.activeForm.$('projectIncludeOption').set('value', value);

    this.activeForm.validate();
  }

  @computed get scheduleFrequencies() {
    return [
      {
        id: 'WEEKLY',
        title: t('Weekly')
      },
      {
        id: 'MONTHLY',
        title: t('Monthly')
      }
    ];
  }

  @computed get selectedScheduleFrequencyOption() {
    return this.scheduleFrequencies.find(
      scheduleFrequency =>
        scheduleFrequency.id === this.activeForm.$('scheduleFrequency').value
    );
  }

  @action.bound
  setScheduleFrequency(scheduleFrequency) {
    this.activeForm.update({
      scheduleFrequency: scheduleFrequency.id
    });
  }

  @action.bound
  updateWorkerUuids(workers) {
    this.activeForm.update({
      workers: workers
    });
  }

  @action.bound
  submitViolationsReportForm(event) {
    event.preventDefault();

    if (this.activeReportTab === 'scheduled' && !this.scheduledReportToEdit) {
      this.createNewScheduledReport();
      return;
    }

    if (this.saving) return;

    this.clearValidationDetails();

    this.activeForm.submit({
      onSuccess: this.submitViolationsReportFormSuccess,
      onError: this.submitViolationsReportFormError
    });
  }

  @action.bound submitViolationsReportFormSuccess() {
    if (this.activeReportTab === 'runOnce') {
      this.submitViolationsRunOnceReport();
    } else {
      this.submitViolationsScheduledReport();
    }
  }

  @action.bound submitViolationsReportFormError() {
    console.error(this.activeForm.errors());
  }

  @action.bound async submitViolationsRunOnceReport() {
    this.saving = true;

    const values = this.activeForm.values();

    const cleanedValues = pickBy(
      values,
      value => value !== null && (value !== undefined) & (value !== '')
    );

    cleanedValues.projectUuids = cleanedValues.projects.map(project => {
      return project.uuid;
    });

    cleanedValues.workerUuids = cleanedValues.workers?.map(employee => {
      return employee.workerUuid;
    });

    delete cleanedValues.projects;

    delete cleanedValues.workers;

    try {
      await request.post(
        `${this.rootStore.urlMicroService(
          'performanceTracking'
        )}/timeclock/violation/reports/run-once`,
        cleanedValues
      );

      callTrack(VIOLATIONS_REPORT_DOWNLOADED);

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Your download request has been sent.')
      });

      this.hideViolationsReportModal();
    } catch (error) {
      this.saving = false;
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound async submitViolationsScheduledReport() {
    this.saving = true;

    const values = this.activeForm.values();

    const hoursMins = values.time.split(':');

    values.time = {
      hour: parseInt(hoursMins[0], 10),
      minute: parseInt(hoursMins[1], 10)
    };

    values.projects = values.projects.map(project => {
      return {
        uuid: project.uuid,
        name: project.name
      };
    });

    const cleanedValues = pickBy(
      values,
      value => value !== null && (value !== undefined) & (value !== '')
    );

    const method = this.scheduledReportToEdit.isNew ? 'post' : 'put';

    const notificationMessage = this.scheduledReportToEdit.isNew
      ? t('Scheduled report created.')
      : t('Scheduled report updated.');

    try {
      await this.scheduledReportToEdit.save(cleanedValues, {
        method,
        wait: true
      });

      callTrack(VIOLATIONS_REPORT_SCHEDULED, {
        frequency: values.timeFrameType
      });

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: notificationMessage
      });

      this.fetchScheduled();
      this.cancelEditingReport();
    } catch (error) {
      this.saving = false;
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound
  createNewScheduledReport() {
    // Use a new model to hold our report when creating a new one.
    this.scheduledReportToEdit = new ViolationsReport(
      {},
      {
        rootStore: this.rootStore
      }
    );

    this.setupModalForm();
  }

  @action.bound
  cancelEditingReport() {
    this.activeForm = null;
    this.scheduledReportToEdit = null;
    this.saving = false;
  }

  @computed get scheduleText() {
    switch (this.activeForm.$('timeFrameType').value) {
      case 'MONTHLY':
        return t('This report will be sent monthly starting:');
      case 'WEEKLY':
        return t('This report will be sent weekly starting:');
      case 'BI_WEEKLY':
        return t('This report will be sent bi-weekly starting:');
      default:
        return t('This report will be sent daily at:');
    }
  }

  @computed
  get enableSubmit() {
    if (!this.scheduledReportToEdit && this.activeReportTab === 'scheduled') {
      return true;
    }

    if (!this.activeForm || this.activeForm.isPristine || this.saving) {
      return false;
    }

    return this.activeForm.isValid;
  }

  @action.bound
  editScheduledReport(report) {
    this.scheduledReportToEdit = report;

    this.initReportForm();
  }

  @action.bound
  async deleteScheduledReport(report) {
    try {
      await report.destroy({ wait: true });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  @action.bound
  handleScheduledReportCancelButton() {
    this.scheduledReportToEdit
      ? this.cancelEditingReport()
      : this.hideViolationsReportModal();
  }

  @computed
  get weekFirstDay() {
    return moment(new Date()).add(
      (this.company.preferences.weekFirstDay -
        moment(new Date()).isoWeekday() -
        7) %
        7,
      'days'
    );
  }

  @computed
  get weekLastDay() {
    return moment(this.weekFirstDay).add(6, 'days');
  }

  @computed
  get monthFirstDay() {
    return moment().startOf('month');
  }

  @computed get timeFrames() {
    return [
      {
        id: 'DAILY',
        title: t('Daily'),
        projectEntire: true
      },
      {
        id: 'WEEKLY',
        title: t('Weekly'),
        actionDate: this.weekLastDay,
        projectEntire: true
      },
      {
        id: 'BI_WEEKLY',
        title: t('Bi-weekly'),
        actionDate: this.weekLastDay,
        projectEntire: true
      },
      {
        id: 'MONTHLY',
        title: t('Monthly'),
        actionDate: this.monthFirstDay,
        projectEntire: true
      }
    ];
  }

  @computed get fixedTimeFrameOption() {
    return {
      id: 'PROJECTENTIRE',
      title: t('Entire Duration of the Project'),
      projectEntire: true
    };
  }

  @computed get selectedTimeFrameOption() {
    return this.timeFrames.find(
      timeFrame => timeFrame.id === this.activeForm.$('timeFrameType').value
    );
  }

  @action.bound
  setTimeFrame(timeFrame) {
    const actionDate = timeFrame.actionDate
      ? moment(timeFrame.actionDate).format('YYYY-MM-DD')
      : '';

    this.activeForm.update({
      timeFrameType: timeFrame.id,
      projectEntire: timeFrame.projectEntire,
      actionDate: actionDate
    });
  }

  @action.bound setFromDate(value) {
    this.activeForm.$('fromDate').set(moment(value).format('YYYY-MM-DD'));
  }

  @action.bound setToDate(value) {
    this.activeForm.$('toDate').set(moment(value).format('YYYY-MM-DD'));
  }

  @action.bound setActionDate(value) {
    this.activeForm.$('actionDate').set(moment(value).format('YYYY-MM-DD'));
  }
}
