import request from 'axios';
import debounce from 'lodash.debounce';
import orderBy from 'lodash.orderby';
import { action, computed, observable, reaction } from 'mobx';
import { BASE_DEBOUNCE } from 'fixtures/constants';

import UIStore from 'stores/ui/UIStore';

import Workers from 'stores/collections/Workers';
import QuickbooksEmployees from 'stores/collections/integrations/QuickbooksEmployees';

import IntegrationQBOEmployeeImportUI from './IntegrationQBOEmployeeImportUI';

import errorHandler from 'utils/errorHandler';
import alertErrorHandler from 'utils/alertErrorHandler';
import history from 'utils/history';
import { t } from 'utils/translate';
import formatIntegrationSyncMessage from 'utils/formatIntegrationSyncMessage';

export default class IntegrationQBOEmployeeMappingUI extends UIStore {
  @observable loading;
  // Internal
  @observable rakenWorkerOptionsQuery;
  @observable selectedRakenWorkerOption;
  // External
  @observable loadingQuickbooksEmployeeOptions;
  @observable quickbooksEmployeeOptionsQuery;
  @observable selectedQuickbooksEmployeeOption;
  // Mappings
  @observable sortField;
  @observable sortDirection;
  @observable pageSize;
  @observable page;

  constructor(options) {
    super(options);

    this.loading = false;

    // Internal Options
    this.rakenWorkerOptionsQuery = '';
    this.selectedRakenWorkerOption = null;

    // Bulk actions
    this.selectedMappings = observable([]);

    this.rakenWorkerOptions = new Workers(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchRakenWorkerOptionsDebounced = debounce(
      this.fetchRakenWorkerOptions,
      BASE_DEBOUNCE
    );

    // External options
    this.quickbooksEmployeeOptionsQuery = '';
    this.selectedQuickbooksEmployeeOption = null;

    this.quickbooksEmployeeOptions = new QuickbooksEmployees(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchQuickbooksEmployeeOptionsDebounced = debounce(
      this.fetchQuickbooksEmployeeOptions,
      BASE_DEBOUNCE
    );

    // Mappings
    this.sortField = 'slug';
    this.sortDirection = 'asc';
    this.pageSize = 10;
    this.page = 1;

    this.rakenWorkerMappings = new Workers(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.quickbooksEmployeeMappings = new QuickbooksEmployees(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.integrationQBOEmployeeImportUI = new IntegrationQBOEmployeeImportUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @computed get integrations() {
    return this.parent.integrations;
  }

  @computed get activeIntegration() {
    return this.parent.activeIntegration;
  }

  @computed get activeCompany() {
    return this.parent.activeCompany;
  }

  @computed get company() {
    if (this.isSuperAdmin) {
      return this.parent.activeCompany;
    }

    return this.rootStore.me.company;
  }

  @computed get baseUrl() {
    return this.parent.baseUrl;
  }

  @action.bound async setup() {
    this.loading = true;

    this.setupReactions();

    await Promise.all([
      this.fetchRakenWorkerOptions(),
      this.fetchRakenWorkerMappings(),
      this.fetchQuickbooksEmployeeOptions(),
      this.fetchQuickbooksEmployeeMappings()
    ]);

    this.loading = false;
  }

  @action.bound setupReactions() {
    this.cancelSearchRakenWorkerOptionsReaction = reaction(
      () => this.rakenWorkerOptionsQuery,
      query => {
        this.fetchRakenWorkerOptionsDebounced();
      }
    );

    this.cancelSearchQuickbooksEmployeeOptionsReaction = reaction(
      () => this.quickbooksEmployeeOptionsQuery,
      query => {
        this.fetchQuickbooksEmployeeOptionsDebounced();
      }
    );
  }

  @action.bound tearDown() {
    this.clearUIState();
    this.tearDownReactions();
  }

  @action.bound tearDownReactions() {
    this.cancelSearchRakenWorkerOptionsReaction();
    this.cancelSearchQuickbooksEmployeeOptionsReaction();
  }

  @action.bound setRakenWorkerOptionsQuery(value) {
    this.rakenWorkerOptionsQuery = value;
  }

  @action.bound
  async fetchRakenWorkerOptions(options) {
    try {
      await this.rakenWorkerOptions.fetch({
        params: {
          sortField: 'firstName,lastName',
          sortDirection: 'asc',
          limit: 50,
          externalId: 'null',
          companyUuids: this.company.uuid,
          status: 'ACTIVE,INVITED',
          query: this.rakenWorkerOptionsQuery
        }
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  fetchNextRakenWorkerOptions = async rakenWorkerOptionsAutocomplete => {
    const dropdown = rakenWorkerOptionsAutocomplete.current;
    const scrollTop = dropdown.scrollTop;
    const scrollHeight = dropdown.scrollHeight;
    const dropdownHeight = dropdown.clientHeight;

    if (scrollTop + dropdownHeight === scrollHeight) {
      this.rakenWorkerOptions.fetchNextPage()?.then(() => {
        rakenWorkerOptionsAutocomplete.current.scrollTop =
          scrollHeight - dropdownHeight;
      });
    }
  };

  fetchNextQuickBooksEmployeeOptions = async scrollRef => {
    const scrollableElement = scrollRef.current;
    const scrollTop = scrollableElement.scrollTop;
    const scrollHeight = scrollableElement.scrollHeight;
    const dropdownHeight = scrollableElement.clientHeight;

    if (scrollTop + dropdownHeight === scrollHeight) {
      this.quickbooksEmployeeOptions.fetchNextPage()?.then(() => {
        scrollRef.current.scrollTop = scrollHeight - dropdownHeight;
      });
    }
  };

  @computed
  get rakenWorkerOptionsForRender() {
    return this.rakenWorkerOptions.models.slice();
  }

  @action.bound selectRakenWorkerOption(option) {
    this.selectedRakenWorkerOption = option;
    this.rakenWorkerOptionsQuery = '';
  }

  @action.bound setQuickbooksEmployeeOptionsQuery(value) {
    this.quickbooksEmployeeOptionsQuery = value;
  }

  @action.bound clearQuickbooksEmployeeOptionsQuery() {
    this.quickbooksCustomerOptionsQuery = '';
  }

  @computed get hasQuickbooksEmployeeOptions() {
    return this.quickbooksEmployeeOptions.hasModels;
  }

  @action.bound
  async fetchQuickbooksEmployeeOptions(options) {
    this.loadingQuickbooksEmployeeOptions = true;

    try {
      await this.quickbooksEmployeeOptions.fetch({
        params: {
          limit: 50,
          unlinkedOnly: true,
          query: this.quickbooksEmployeeOptionsQuery
        }
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    } finally {
      this.loadingQuickbooksEmployeeOptions = false;
    }
  }

  fetchNextQuickBooksEmployeeOptions = async scrollRef => {
    const scrollableElement = scrollRef.current;
    const scrollTop = scrollableElement.scrollTop;
    const scrollHeight = scrollableElement.scrollHeight;
    const dropdownHeight = scrollableElement.clientHeight;

    if (scrollTop + dropdownHeight === scrollHeight) {
      this.quickbooksEmployeeOptions.fetchNextPage()?.then(() => {
        scrollRef.current.scrollTop = scrollHeight - dropdownHeight;
      });
    }
  };

  @computed
  get quickbooksEmployeeOptionsForRender() {
    return this.quickbooksEmployeeOptions.models.slice();
  }

  @action.bound selectQuickbooksEmployeeOption(option) {
    this.selectedQuickbooksEmployeeOption = option;
    this.quickbooksEmployeeOptionsQuery = '';
  }

  @action.bound
  clearUIState() {
    this.selectedMappings.clear();
    this.rakenWorkerOptionsQuery = '';
    this.rakenWorkerOptions.reset();
    this.quickbooksEmployeeOptions.reset();
    this.rakenWorkerMappings.reset();
    this.quickbooksEmployeeMappings.reset();
    this.selectedRakenWorkerOption = null;
    this.quickbooksEmployeeOptionsQuery;
    this.selectedQuickbooksEmployeeOption = null;
    this.loading = false;
  }

  @computed get disableAddConfigurationButton() {
    if (this.saving || this.loading) return true;

    return (
      !this.selectedRakenWorkerOption || !this.selectedQuickbooksEmployeeOption
    );
  }

  @action.bound async saveMapping() {
    this.saving = true;

    const {
      selectedRakenWorkerOption,
      selectedQuickbooksEmployeeOption
    } = this;

    try {
      await selectedRakenWorkerOption.save(
        {
          externalId: selectedQuickbooksEmployeeOption.id
        },
        {
          wait: true
        }
      );

      this.rakenWorkerMappings.add(selectedRakenWorkerOption.toJSON(), {
        parse: false
      });

      this.quickbooksEmployeeMappings.add(
        selectedQuickbooksEmployeeOption.toJSON(),
        {
          parse: false
        }
      );

      this.refetchOptionsAfterChange();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound async removeMapping(mapping) {
    const quickbooksEmployee = this.quickbooksEmployeeMappings.get(
      mapping.quickbooksCustomerId
    );

    const rakenWorker = this.rakenWorkerMappings.get(mapping.id);

    try {
      await rakenWorker.save(
        {
          externalId: ''
        },
        {
          wait: true
        }
      );
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
      return;
    }

    if (quickbooksEmployee) {
      this.quickbooksEmployeeMappings.remove(quickbooksEmployee);
    }

    this.rakenWorkerMappings.remove(rakenWorker);

    this.refetchOptionsAfterChange();
  }

  @action.bound async resyncMapping(mapping) {
    await this.authorization.checkFeatureAccess('EditIntegrations');
    try {
      const { data } = await request.post(
        `${this.integrationQBOEmployeeImportUI.employees.url()}/sync`,
        {
          externalIds: [mapping.quickbooksEmployeeId]
        }
      );
      const successfulCount = data.collection.find(
        syncResult =>
          syncResult.externalId === mapping.quickbooksEmployeeId &&
          syncResult.successful === true
      )
        ? 1
        : 0;
      const snackbar = successfulCount > 0 ? 'warning' : 'error';
      const title = formatIntegrationSyncMessage(
        'Employee resync',
        successfulCount,
        1 - successfulCount
      );
      this.notifications.pushNotification({
        snackbar,
        icon: 'checkmark',
        title
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  @action.bound async importQuickbooksEmployees() {
    await this.authorization.checkFeatureAccess('EditIntegrations');

    if (this.isSuperAdmin) {
      history.push(
        `/companies/${this.activeCompany.uuid}/integrations/1019/employees/import`
      );
    } else {
      history.push('/company-settings/integrations/1019/employees/import');
    }
  }

  @action.bound async refetchOptionsAfterChange() {
    this.selectedRakenWorkerOption = null;
    this.selectedQuickbooksEmployeeOption = null;

    this.rakenWorkerOptionsQuery = '';
    this.quickbooksEmployeeOptionsQuery = '';

    this.rakenWorkerOptions.reset();
    this.quickbooksEmployeeOptions.reset();

    this.fetchRakenWorkerOptionsDebounced();
    this.fetchQuickbooksEmployeeOptionsDebounced();
  }

  // Mappings
  @action.bound
  async fetchQuickbooksEmployeeMappings() {
    try {
      await this.quickbooksEmployeeMappings.fetch({
        params: {
          limit: 1000,
          linkedOnly: true
        }
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  @action.bound
  async fetchRakenWorkerMappings() {
    try {
      await this.rakenWorkerMappings.fetch({
        params: {
          limit: 1000,
          externalId: '*',
          skipProjectMembershipLookup: true,
          status: 'ACTIVE,INVITED',
          companyUuids: this.company.uuid
        }
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  @computed get mappingsLoading() {
    return (
      this.quickbooksEmployeeMappings.fetching ||
      this.rakenWorkerMappings.fetching
    );
  }

  @computed get hasMappings() {
    return this.mappings.length > 0;
  }

  @computed get mappings() {
    return this.rakenWorkerMappings.models.map(rakenWorker => {
      const quickbooksEmployee = this.quickbooksEmployeeMappings.models.find(
        quickbooksEmployee => quickbooksEmployee.id === rakenWorker.externalId
      );

      return {
        id: rakenWorker.id,
        employeeId: rakenWorker.employeeId,
        name: rakenWorker.fullName,
        userId: rakenWorker.userId,
        role: rakenWorker.role,
        slug: rakenWorker.slug,
        quickbooksEmployeeName:
          quickbooksEmployee?.name || `Unknown: ${rakenWorker.externalId}`,
        quickbooksEmployeeId: quickbooksEmployee?.id || null,
        quickbooksEmployeeNumber:
          quickbooksEmployee?.data?.employeeNumber || null,
        hasError: !quickbooksEmployee
      };
    });
  }

  @computed get sortedMappings() {
    return orderBy(this.mappings, [this.sortField], [this.sortDirection]);
  }

  @computed
  get totalPages() {
    return Math.ceil(this.sortedMappings.length / this.pageSize);
  }

  @computed
  get paginatedMappings() {
    return this.sortedMappings.slice(
      (this.page - 1) * this.pageSize,
      (this.page - 1) * this.pageSize + this.pageSize
    );
  }

  @action.bound
  setPage(page) {
    this.page = page;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed get hasSelectedMappings() {
    return this.selectedMappings.length > 0;
  }

  findSelectedMapping = mappingId => {
    return this.selectedMappings.find(
      selectedMapping => selectedMapping.id === mappingId
    );
  };

  @action.bound
  toggleSelectMapping(mapping) {
    const selectedMapping = this.findSelectedMapping(mapping.id);

    if (selectedMapping) {
      this.selectedMappings.remove(selectedMapping);
    } else {
      this.selectedMappings.push(mapping);
    }
  }

  @computed
  get allMappingsSelected() {
    if (!this.hasMappings) return false;

    return (
      this.paginatedMappings.filter(mapping =>
        this.findSelectedMapping(mapping.id)
      ).length === this.paginatedMappings.length
    );
  }

  @action.bound
  toggleSelectAllMappings() {
    if (this.allMappingsSelected) {
      this.selectedMappings.replace(
        this.selectedMappings.filter(
          selectedMapping =>
            !this.paginatedMappings.some(
              mapping => mapping.id === selectedMapping.id
            )
        )
      );
    } else {
      this.paginatedMappings.forEach(mapping => {
        this.selectedMappings.push(mapping);
      });
    }
  }

  @action.bound clearAllSelectedMappings() {
    this.selectedMappings.clear();
  }

  @computed get bulkActions() {
    return [
      {
        title: t('Resync selected employees'),
        onClick: () => {
          this.bulkResyncSelectedMappings();
        }
      },
      {
        title: t('Remove selected employees'),
        onClick: () => {
          this.bulkRemoveSelectedMappings();
        }
      }
    ];
  }

  @action.bound bulkResyncSelectedMappings() {
    this.showModal('BulkResyncSelectedMappings');
  }

  @action.bound async confirmBulkResyncSelectedMappings() {
    await this.authorization.checkFeatureAccess('EditIntegrations');

    try {
      this.saving = true;

      const { data } = await request.post(
        `${this.integrationQBOEmployeeImportUI.employees.url()}/sync`,
        {
          externalIds: this.selectedMappings.map(
            mapping => mapping.quickbooksEmployeeId
          )
        }
      );

      await this.hideActiveModal();

      const successfulCount = data.collection.filter(
        syncResult => syncResult.successful === true
      ).length;
      const failedCount = data.collection.length - successfulCount;
      const title = formatIntegrationSyncMessage(
        'Employee resync',
        successfulCount,
        failedCount
      );
      const snackbar = successfulCount > 0 ? 'warning' : 'error';
      this.notifications.pushNotification({
        snackbar,
        icon: 'checkmark',
        title
      });

      this.selectedMappings.clear();
      this.refetchOptionsAfterChange();
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    } finally {
      this.saving = false;
    }
  }

  @action.bound bulkRemoveSelectedMappings() {
    this.showModal('BulkRemoveSelectedMappings');
  }

  @action.bound async confirmBulkRemoveSelectedMappings() {
    this.saving = true;

    this.selectedMappings.forEach(async (mapping, index) => {
      const quickbooksEmployee = this.quickbooksEmployeeMappings.get(
        mapping.quickbooksEmployeeId
      );

      const rakenWorker = this.rakenWorkerMappings.get(mapping.id);

      try {
        await rakenWorker.save(
          {
            externalId: ''
          },
          {
            wait: true
          }
        );

        if (quickbooksEmployee) {
          this.quickbooksEmployeeMappings.remove(quickbooksEmployee);
        }

        this.rakenWorkerMappings.remove(rakenWorker);

        if (index == this.selectedMappings.length - 1) {
          await this.hideActiveModal();

          this.selectedMappings.clear();
          this.refetchOptionsAfterChange();

          this.notifications.pushNotification({
            snackbar: 'warning',
            icon: 'checkmark',
            title: t('Employees removed')
          });
        }
      } catch (error) {
        this.quickbooksEmployeeMappings.add(quickbooksEmployee);
        this.rakenWorkerMappings.add(rakenWorker);
        alertErrorHandler(error, this.setValidationDetails);
      } finally {
        this.saving = false;
      }
    });
  }
}
