import request from 'axios';
import {
  DocumentNameForm,
  documentNameFormFields,
  documentNameFormLabels,
  documentNameFormOptions,
  documentNameFormPlugins,
  documentNameFormRules,
  documentNameFormValues
} from 'forms/project/documents/documentName';
import {
  DownloadDocumentForm,
  downloadDocumentFormFields,
  downloadDocumentFormLabels,
  downloadDocumentFormOptions,
  downloadDocumentFormPlugins,
  downloadDocumentFormRules,
  downloadDocumentFormValues
} from 'forms/project/documents/downloadDocument';
import { action, computed, observable } from 'mobx';
import alertErrorHandler from 'utils/alertErrorHandler';
import crossOriginDownload from 'utils/crossOriginDownload';
import history from 'utils/history';
import {
  DOCUMENTS_BULK_DOWNLOAD,
  DOCUMENTS_FOLDER_DOWNLOADED,
  DOCUMENTS_NEW_FOLDER_CREATED
} from 'utils/segmentAnalytics/eventSpec';
import { callTrack } from 'utils/segmentIntegration';

import { t } from 'utils/translate';
import ProjectChildUI from '../ProjectChildUI';

export default class DocumentActionsUI extends ProjectChildUI {
  @observable selectedDocument;
  @observable selectedDocumentAction;
  @observable downloadDocumentForm;
  @observable renameDocumentForm;
  @observable addFolderForm;

  constructor(options) {
    super(options);

    this.loading = true;

    // Bulk Actions
    this.selectedDocuments = observable([], { deep: false });

    this.downloadDocumentForm = null;
    this.renameDocumentForm = null;
    this.selectedDocument = null;
    this.selectedDocumentAction = null;
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.downloadDocumentForm = null;
    this.renameDocumentForm = null;
    this.selectedDocument = null;
    this.selectedDocumentAction = null;
    this.selectedDocuments.clear();
  }

  @computed get documents() {
    return this.parent.documents;
  }

  @computed get hasSelectedDocuments() {
    return this.selectedDocuments.length > 0;
  }

  @computed get hasNoSelectedDocuments() {
    return this.selectedDocuments.length === 0;
  }

  findSelectedDocument = document => {
    return this.selectedDocuments.find(
      selectedDocument => selectedDocument.uuid === document.uuid
    );
  };

  @computed get allDocumentsSelected() {
    if (!this.hasSelectedDocuments) return false;

    return (
      this.documents?.models.filter(document => {
        return this.findSelectedDocument(document);
      }).length === this.documents?.length
    );
  }

  @action.bound toggleSelectAllDocuments() {
    if (this.allDocumentsSelected) {
      this.documents?.models.forEach(document => {
        this.selectedDocuments.remove(this.findSelectedDocument(document));
      });
    } else {
      this.documents?.models.forEach(document => {
        if (!this.findSelectedDocument(document)) {
          this.selectedDocuments.push(document);
        }
      });
    }
  }

  @action.bound toggleSelectDocument(document) {
    const selectedDocument = this.findSelectedDocument(document);

    if (selectedDocument) {
      this.selectedDocuments.remove(selectedDocument);
    } else {
      this.selectedDocuments.push(document);
    }
  }

  @action.bound async deleteDocument(document) {
    await this.authorization.checkFeatureAccess('CUDDocuments');

    this.selectedDocument = document;

    document.isFile
      ? this.showModal('DeleteFileModal')
      : this.showModal('DeleteFolderModal');
  }

  @action.bound async cancelDeleteDocument() {
    await this.hideActiveModal();

    this.selectedDocument = null;
  }

  @action.bound async confirmDeleteDocument() {
    this.saving = true;

    try {
      await this.selectedDocument.destroy({ wait: true });

      this.parent.loading = true;

      await this.hideActiveModal();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Document deleted')
      });

      this.parent.refetchDocuments();
      this.selectedDocument = null;
      this.selectedDocuments.clear();
      this.selectedDocumentAction = null;
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound async downloadDocumentFile(document) {
    crossOriginDownload(document.document.contentUrl, document.name, false);
  }

  @action.bound async downloadDocuments(document = null) {
    this.selectedDocument = document;

    this.downloadDocumentForm = new DownloadDocumentForm(
      {
        fields: downloadDocumentFormFields,
        rules: downloadDocumentFormRules,
        values: downloadDocumentFormValues,
        labels: downloadDocumentFormLabels
      },
      {
        options: downloadDocumentFormOptions,
        plugins: downloadDocumentFormPlugins,
        rootStore: this.rootStore
      }
    );

    if (this.selectedDocument?.isFolder) {
      await this.validateDownloadDocument();
    } else {
      await this.showModal('DownloadFolderModal');
    }
  }

  @action.bound async validateDownloadDocument() {
    try {
      const resp = await request.get(this.selectedDocument.url());

      if (resp.data.children?.page?.totalElements > 0) {
        await this.showModal('DownloadFolderModal');
      } else {
        await this.showModal('DownloadEmptyFolderModal');
      }
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound async cancelDownloadDocument() {
    await this.hideActiveModal();

    this.downloadDocumentForm = null;
    this.selectedDocument = null;
  }

  @action.bound submitDownloadDocumentForm(event) {
    event.preventDefault();

    this.downloadDocumentForm.submit({
      onSuccess: this.submitDownloadDocumentFormSuccess,
      onError: this.submitDownloadDocumentFormError
    });
  }

  @computed
  get bulkDownloadParams() {
    // Property names should match API parameter names
    let documents, emails;

    if (this.selectedDocument) {
      documents = [this.selectedDocument.id];
    } else {
      documents = this.selectedDocuments.map(document => document.uuid);
    }

    const recipients = this.downloadDocumentForm.$('emails').value;

    if (recipients.length > 0) {
      emails = recipients;
    }

    // Remove undefined properties
    return {
      ...(documents?.length > 0 && { documents }),
      ...(emails?.length > 0 && { emails })
    };
  }

  @action.bound async submitDownloadDocumentFormSuccess() {
    this.saving = true;

    try {
      await request.post(
        `/ra/companies/${this.rootStore.me.company.uuid}/document-storage/download`,
        this.bulkDownloadParams
      );

      callTrack(
        this.selectedDocuments?.length > 0
          ? DOCUMENTS_BULK_DOWNLOAD
          : DOCUMENTS_FOLDER_DOWNLOADED,
        {
          project_id: this.project.id,
          project_name: this.project.name
        }
      );

      this.selectedDocuments.clear();

      this.cancelDownloadDocument();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Your download request has been sent.')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitDownloadDocumentFormError() {
    console.error(this.downloadDocumentForm.errors());
  }

  @action.bound renameDocument(document) {
    this.selectedDocument = document;

    this.renameDocumentForm = new DocumentNameForm(
      {
        fields: documentNameFormFields,
        rules: documentNameFormRules,
        values: {
          name: this.parent.documentsConstants.removeExtensions(
            this.selectedDocument.name
          ),
          initialName: this.selectedDocument.name
        },
        labels: documentNameFormLabels
      },
      {
        options: documentNameFormOptions,
        plugins: documentNameFormPlugins,
        rootStore: this.rootStore
      }
    );

    document.isFile
      ? this.showModal('RenameFileModal')
      : this.showModal('RenameFolderModal');
  }

  @action.bound async cancelRenameDocument() {
    await this.hideActiveModal();

    this.renameDocumentForm = null;
    this.selectedDocument = null;
  }

  @action.bound submitRenameDocumentForm(event) {
    event.preventDefault();

    this.renameDocumentForm.submit({
      onSuccess: this.submitRenameDocumentFormSuccess,
      onError: this.submitRenameDocumentFormError
    });
  }

  @action.bound async submitRenameDocumentFormSuccess() {
    this.saving = true;

    const values = this.renameDocumentForm.values();

    let name;

    // Check isFile, then include the file extension.
    if (this.selectedDocument.isFile) {
      name = `${values.name}.${this.selectedDocument.name.split('.').pop()}`;
    } else {
      name = values.name;
    }

    try {
      await this.selectedDocument.save(
        {
          name: name
        },
        {
          wait: true
        }
      );

      this.parent.sortByLastUpdated();

      this.cancelRenameDocument();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: this.selectedDocument.isFile
          ? t('File renamed')
          : t('Folder renamed')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitRenameDocumentFormError() {
    console.error(this.renameDocumentForm.errors());
  }

  @action.bound async addFolder() {
    await this.authorization.checkFeatureAccess('CUDDocuments');

    this.addFolderForm = new DocumentNameForm(
      {
        fields: documentNameFormFields,
        rules: documentNameFormRules,
        values: documentNameFormValues,
        labels: documentNameFormLabels
      },
      {
        options: documentNameFormOptions,
        plugins: documentNameFormPlugins,
        rootStore: this.rootStore
      }
    );

    this.showModal('DocumentsAddFolderModal');
  }

  @action.bound async cancelAddFolder() {
    await this.hideActiveModal();

    this.addFolderForm = null;
  }

  @action.bound submitAddFolderForm(event) {
    event.preventDefault();

    this.addFolderForm.submit({
      onSuccess: this.submitAddFolderFormSuccess,
      onError: this.submitAddFolderFormError
    });
  }

  @action.bound async submitAddFolderFormSuccess() {
    this.saving = true;

    const { name } = this.addFolderForm.values();

    try {
      await request.post(
        `/ra/companies/${this.company.uuid}/document-storage/folders`,
        {
          projectUuid: this.projectUuid,
          parentFolderUuid: this.documents?.parent.id,
          name: name
        }
      );

      this.parent.sortByLastUpdated();

      this.cancelAddFolder();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Folder created')
      });

      callTrack(DOCUMENTS_NEW_FOLDER_CREATED, {
        name: name,
        project_id: this.project.id,
        project_name: this.project.name
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitAddFolderFormError() {
    console.error(this.addFolderForm.errors());
  }

  @computed
  get createActions() {
    return [
      {
        title: t('Upload'),
        onClick: () => {
          this.addFiles();
        }
      },
      {
        title: t('New Folder'),
        onClick: () => {
          this.addFolder();
        }
      }
    ];
  }

  @action.bound async addFiles() {
    await this.authorization.checkFeatureAccess('CUDDocuments');

    history.push({
      pathname: `${this.parent.folder?.viewUrl}/add-files`,
      search: this.baseQueryParams
    });
  }

  // Bulk Actions
  @computed
  get bulkActions() {
    let actions = [];

    if (this.hasSelectedDocuments) {
      actions.push({
        title: t('Download'),
        onClick: () => {
          this.downloadDocuments();
        }
      });

      // TODO: Hiding for now but we need to add this back after creating new bulk delete endpoint
      // if (this.parent.hasWriteAccess) {
      //   actions.push({
      //     title: t('Delete'),
      //     onClick: () => {
      //       this.bulkDocumentAction('DELETE');
      //     }
      //   });
      // }
    }

    return actions;
  }

  @action.bound async bulkDocumentAction(action) {
    await this.authorization.checkFeatureAccess('CUDDocuments');

    this.selectedDocumentAction = action;
    this.showModal('DocumentBulkActionModal');
  }

  @action.bound async cancelBulkDocumentAction() {
    await this.hideActiveModal();
    this.selectedDocumentAction = null;
  }

  @computed get bulkDocumentActionTitle() {
    if (!this.selectedDocumentAction) return null;

    switch (this.selectedDocumentAction) {
      case 'DELETE':
        return t(`Delete items`);
      default:
        return '';
    }
  }

  @computed get bulkDocumentActionText() {
    if (!this.selectedDocumentAction) return null;

    switch (this.selectedDocumentAction) {
      case 'DELETE':
        return t(`Are you sure you want to delete these items permanently?`);
      default:
        return '';
    }
  }

  @computed get bulkDocumentActionButtonColor() {
    switch (this.selectedDocumentAction) {
      case 'DELETE':
        return 'red';
      default:
        return 'orange';
    }
  }

  @computed get bulkDocumentActionButtonDisabled() {
    switch (this.selectedDocumentAction) {
      default:
        return this.saving;
    }
  }

  @computed get bulkDocumentActionButtonText() {
    switch (this.selectedDocumentAction) {
      case 'DELETE':
        return t(`Delete`);
      default:
        return '';
    }
  }

  @computed get bulkDocumentActionButtonTextActive() {
    switch (this.selectedDocumentAction) {
      case 'DELETE':
        return t(`Deleting...`);
      default:
        return '';
    }
  }

  @computed get bulkDocumentActionNotificationText() {
    switch (this.selectedDocumentAction) {
      case 'DELETE':
        return t(`Documents deleted`);
      default:
        return '';
    }
  }

  @computed get downloadDocumentHeaderText() {
    return this.selectedDocument?.isFolder
      ? t('Download folder')
      : t('Download documents');
  }

  @action.bound async bulkDelete() {
    this.selectedDocuments.forEach(document =>
      document.destroy({ wait: true })
    );
  }

  @action.bound async confirmBulkDocumentAction() {
    this.clearValidationDetails();
    this.saving = true;

    try {
      switch (this.selectedDocumentAction) {
        case 'DELETE':
          await this.bulkDelete();
          break;
      }

      this.parent.loading = true;

      await this.hideActiveModal();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: this.bulkDocumentActionNotificationText
      });

      this.parent.refetchDocuments();
      this.selectedDocuments.clear();
      this.selectedDocumentAction = null;
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }
}
