import MobxReactForm from 'mobx-react-form';
import validatorjs from 'validatorjs';
import { t } from 'utils/translate';

const companyCertificationFormPlugins = { dvr: validatorjs };

const companyCertificationFormOptions = {
  validateOnInit: false,
  validateOnChange: true
};

const companyCertificationFormFields = [
  'name',
  'certificationType',
  'certificationType.name',
  'certificationType.uuid'
];

const companyCertificationFormRules = {
  name: 'required|string|max:255',
  certificationType: 'required',
  'certificationType.name': 'required|string|max:255',
  'certificationType.uuid': 'required|string|max:255'
};

const companyCertificationFormLabels = {
  'certificationType.uuid': t('type')
};

const companyCertificationFormValues = {
  name: '',
  certificationType: {}
};

class CompanyCertificationForm extends MobxReactForm {}

export {
  CompanyCertificationForm,
  companyCertificationFormLabels,
  companyCertificationFormValues,
  companyCertificationFormRules,
  companyCertificationFormFields,
  companyCertificationFormOptions,
  companyCertificationFormPlugins
};
