import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';
import { t } from 'utils/translate';
import trimObject from '../../utils/trimObject';

const budgetFormPlugins = extendedPlugins;

const budgetFormOptions = {
  validateOnInit: false,
  strictUpdate: false,
  validateOnChange: true
};

const budgetFormFields = [
  'costCode',
  'costCode.uuid',
  'costCode.code',
  'costCode.division',
  'budgetedHours',
  'material',
  'material.uuid',
  'material.name',
  'material.unit',
  'material.unit.uuid',
  'material.unit.name',
  'quantity',
  'materials',
  'materialQuantities[]',
  'materialQuantities[].material.uuid',
  'materialQuantities[].material.name',
  'materialQuantities[].material.unit',
  'materialQuantities[].material.unit.uuid',
  'materialQuantities[].material.unit.name',
  'materialQuantities[].quantity'
];

const budgetFormRules = {
  'costCode.uuid': 'required',
  budgetedHours:
    'numeric|greaterThan0|notMoreThanTwoDecimalPlaces|mustNotExceed8Digits',
  quantity:
    'numeric|notMoreThanTwoDecimalPlaces|mustNotExceed8Digits|greaterThan0',
  'materialQuantities[].quantity':
    'required|numeric|notMoreThanTwoDecimalPlaces|mustNotExceed8Digits|greaterThan0'
};

const budgetFormLabels = {
  'costCode[].uuid': t('cost code'),
  budgetedHours: t('budgeted hours')
};

class BudgetForm extends MobxReactForm {
  trimmedValues() {
    return trimObject(this.values());
  }
}

export {
  BudgetForm,
  budgetFormOptions,
  budgetFormFields,
  budgetFormRules,
  budgetFormLabels,
  budgetFormPlugins
};
