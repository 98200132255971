import { RakenCollection } from '../../lib';
import Coupon from '../../models/superAdmin/Coupon';

export default class Coupons extends RakenCollection {
  model() {
    return Coupon;
  }

  url() {
    return 'ra/coupons';
  }

  parse(data) {
    return data.data;
  }
}
