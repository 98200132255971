import { RakenCollection } from '../../lib';
import SuperAdminProjectMember from '../../models/superAdmin/SuperAdminProjectMember';

export default class SuperAdminProjectMemberships extends RakenCollection {
  model() {
    return SuperAdminProjectMember;
  }

  url() {
    return `ra/sadmin/projects/${this.parent.id}/members`;
  }
}
