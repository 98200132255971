import React from 'react';
import DOMPurify from 'dompurify';

import { findAll } from 'highlight-words-core';
import kebabCase from 'lodash.kebabcase';

function Highlighter({ textToHighlight = '', searchWords, autoEscape }) {
  /**
   * We cast as a string to safe guard against the highlighter been passed an integer.
   * The lib highlight-words-core findAll will fail sliently in this case.
   */
  textToHighlight = String(textToHighlight);
  /**
   * Search words need to be array or will cause an NPE on the library.
   * Typescript would be good here.
   */
  const arraySearchWords = searchWords ? searchWords.split(' ') : [''];
  /**
   * We need to test above and check the search string doesn't contain tags from html chars like < or >
   * and the text to search does not also contain html. Or we get weird results.
   * This is kind of edge case but in this case I think it's best not to highlight.
   */

  const searchPattern = /[<>/]/;
  let formattedHTML;

  const searchContaintsTagChars = arraySearchWords?.some(word =>
    searchPattern.test(word)
  );

  const textPattern = /<[^<>]+>/;

  const textContainsHtmlTag = textPattern.test(textToHighlight);

  const chunks = findAll({
    caseSensitive: false,
    searchWords: arraySearchWords,
    textToHighlight,
    autoEscape: autoEscape ? true : false
  });

  formattedHTML = chunks
    .map(chunk => {
      const { end, highlight, start } = chunk;
      const text = textToHighlight.substr(start, end - start);

      if (highlight) {
        return `<mark class="textHighlight" data-qa="highlight-${kebabCase(
          text
        )}">${text}</mark>`;
      } else {
        return text;
      }
    })
    .join('');

  // Sanitize the HTML content
  const sanitizedHtml = DOMPurify.sanitize(
    searchContaintsTagChars && textContainsHtmlTag
      ? textToHighlight
      : formattedHTML
  );

  // Render the sanitized HTML content
  return <span dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
}

export default Highlighter;
