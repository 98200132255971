import MobxReactForm from 'mobx-react-form';
import validatorjs from 'validatorjs';
import { t } from 'utils/translate';
import { action, computed, observable } from 'mobx';
import sortBy from 'lodash.sortby';

import ProjectMemberships from 'stores/collections/ProjectMemberships';
import Groups from 'stores/collections/groups/Groups';

const scheduleBulkFormPlugins = { dvr: validatorjs };

const scheduleBulkFormOptions = {
  validateOnInit: true,
  validateOnChange: true
};

const scheduleBulkFormFields = [
  'uuid',
  'name',
  'talks',
  'talks[]',
  'talks[].uuid',
  'talks[].name',
  'talks[].selected',
  'talks[].selectedAutocomplete',
  'selectedTalks',
  'selectedTalks[]',
  'selectedTalks[].uuid',
  'selectedTalks[].name',
  'selectedTalks[].order',
  'startDate',
  'timeFrame',
  'days',
  'days[]',
  'type',
  'finishDate',
  'occurrenceCount',
  'projects',
  'projects[]',
  'projects[].uuid',
  'projects[].name',
  'projects[].selected',
  'groups',
  'groups[]',
  'groups[].uuid',
  'groups[].name',
  'groups[].selected',
  'default',
  'search'
];

const scheduleBulkFormRules = {
  startDate: 'required|string',
  timeFrame: 'required|string',
  days: 'array',
  type: 'required|string',
  occurrenceCount: 'integer|required|min:1',
  search: 'string'
};

const scheduleBulkFormValues = {
  uuid: '',
  name: '',
  talks: [],
  selectedTalks: [],
  startDate: '',
  fromDate: '',
  timeFrame: 'WEEKLY',
  days: [],
  type: 'NEVER',
  finishDate: '',
  occurrenceCount: 1,
  projects: [],
  groups: [],
  default: false,
  search: ''
};

const scheduleBulkFormLabels = {
  talks: t('Selected talks'),
  selectedTalks: t('Order of talks'),
  startDate: t('When does the first talk take place?'),
  projects: t('Selected projects'),
  groups: t('Selected groups'),
  occurrenceCount: t('Occurrence')
};

class ScheduleBulkForm extends MobxReactForm {
  @observable selectAllProjectsForBulk;
  @observable selectAllTalksForBulk;
  @observable selectAllGroupsForBulk;

  constructor(settings, options) {
    super(settings, options);

    this.selectAllProjectsForBulk = false;
    this.selectAllTalksForBulk = false;
    this.selectAllGroupsForBulk = false;

    this.rootStore = options.rootStore;
    this.parent = options.parent;

    this.projectMemberships = new ProjectMemberships(null, {
      parent: this.parent,
      rootStore: this.rootStore
    });

    this.groups = new Groups(null, {
      parent: this.parent,
      rootStore: this.rootStore
    });
  }

  @action.bound
  fetchGroups() {
    return this.groups.fetch({
      params: {
        limit: 10000,
        status: ['ACTIVE'],
        offset: 0,
        mn: 'full',
        mc: 'full'
      }
    });
  }

  @action.bound
  fetchProjectMemberships() {
    return this.projectMemberships.fetch({
      params: {
        limit: 10000,
        projectStates: 'ACTIVE',
        offset: 0,
        mn: 'full',
        mc: 'full'
      }
    });
  }

  @action.bound
  handleToggleSelectAllProjectsForBulk() {
    this.selectAllProjectsForBulk = !(
      this.selectedProjects.length === this.$('projects').value.length
    );

    this.$('projects').map(project => {
      project.$('selected').set(this.selectAllProjectsForBulk);

      return project;
    });
  }

  @action.bound
  handleToggleSelectAllGroupsForBulk() {
    this.selectAllGroupsForBulk = !(
      this.selectedGroups.length === this.$('groups').value.length
    );

    this.$('groups').map(group => {
      group.$('selected').set(this.selectAllGroupsForBulk);
      return group;
    });
  }

  @action.bound
  handleToggleSelectAllTalksForBulk() {
    this.selectAllTalksForBulk = !(
      this.selectedTalks.length === this.$('talks').value.length
    );

    this.$('talks').map(talk => {
      talk.$('selected').set(this.selectAllTalksForBulk);

      return talk;
    });

    if (!this.selectAllTalksForBulk) {
      this.update({
        selectedTalks: []
      });
    }
  }

  @action.bound
  handleAllOrderTalksDelete() {
    this.$('talks').map(talk => {
      talk.$('selected').set(false);

      return talk;
    });

    this.update({
      selectedTalks: []
    });
  }

  @action.bound
  handleOrderTalkDelete(item) {
    this.$('selectedTalks').del(item.key);

    this.$('talks').map(talk => {
      if (talk.$('uuid').value === item.$('uuid').value) {
        talk.$('selected').set(false);
      }

      return talk;
    });
  }

  @action.bound
  handleTalkDelete(item) {
    item.$('selected').set(!item.$('selected').value);

    this.$('selectedTalks').map(talkOrder => {
      if (talkOrder.$('uuid').value === item.$('uuid').value) {
        talkOrder.del();
      }
    });
  }

  @computed
  get searchTalks() {
    const { talks, search } = this.values();

    return talks.filter(
      talk => talk.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
    );
  }

  @action.bound
  hasSearchTalk(talk) {
    const name = talk.$('name').value.toLowerCase();
    const search = this.$('search').value.toLowerCase();

    return name.indexOf(search) === -1;
  }

  @computed
  get searchGroups() {
    const { groups, search } = this.values();

    return groups.filter(
      group =>
        group.groupName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
        group.groupClass.toLowerCase().indexOf(search.toLowerCase()) !== -1
    );
  }

  @action.bound
  hasSearchGroup(group) {
    const groupClass = group.$('groupClass').value.toLowerCase();
    const name = group.$('groupName').value.toLowerCase();
    const search = this.$('search').value.toLowerCase();
    return groupClass.indexOf(search) === -1 && name.indexOf(search) === -1;
  }

  @computed
  get searchProjects() {
    const { projects, search } = this.values();

    return projects.filter(
      project => project.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
    );
  }

  @action.bound
  hasSearchProject(project) {
    const name = project.$('name').value.toLowerCase();
    const search = this.$('search').value.toLowerCase();

    return name.indexOf(search) === -1;
  }

  @computed
  get selectedProjects() {
    const { projects } = this.values();

    return projects.filter(project => project.selected);
  }

  @computed
  get selectedGroups() {
    const { groups } = this.values();

    return groups.filter(group => group.selected);
  }

  @computed
  get selectedTalks() {
    const { talks } = this.values();

    return talks.filter(talk => talk.selected);
  }

  @computed
  get selectedTalksAutocomplete() {
    const { talks } = this.values();

    return talks.filter(talk => talk.selectedAutocomplete);
  }

  @action.bound
  handleUpdateTalks(bulkSchedule) {
    let talks = this.parent.wholeTalks.models.map(talk => ({
      uuid: talk.uuid,
      name: talk.name,
      selected: false,
      selectedAutocomplete: false
    }));

    if (bulkSchedule) {
      talks = talks.map(talk => {
        const bulkScheduleTalk = bulkSchedule.talks.find(
          bulkTalk => talk.uuid === bulkTalk.uuid
        );

        talk.selected = Boolean(bulkScheduleTalk);

        return talk;
      });
    }

    this.update({
      talks: talks
    });
  }

  @action.bound
  handleUpdateSelectedTalks(bulkSchedule) {
    const selectedTalks = this.$('selectedTalks').value;
    let newSelectedTalks = null;

    if (selectedTalks.length) {
      newSelectedTalks = this.selectedTalks.filter(
        talk =>
          !selectedTalks.find(
            talkOrder => talkOrder.uuid && talkOrder.uuid === talk.uuid
          )
      );

      newSelectedTalks = [
        ...selectedTalks,
        ...newSelectedTalks.map((talk, index) => ({
          uuid: talk.uuid,
          name: talk.name,
          order: selectedTalks.length + (index + 1)
        }))
      ];
    } else {
      if (bulkSchedule) {
        newSelectedTalks = bulkSchedule.talks
          .filter(talk => talk.status !== 'DELETED')
          .map((talk, index) => ({
            uuid: talk.uuid,
            name: talk.name,
            order: index + 1
          }));
      } else {
        newSelectedTalks = this.selectedTalks.map((talk, index) => ({
          uuid: talk.uuid,
          name: talk.name,
          order: index + 1
        }));
      }
    }

    this.update({
      selectedTalks: newSelectedTalks
    });

    this.reAssignOrderNumbersByDragging();
  }

  @action.bound
  handleUpdateProjects(bulkSchedule) {
    let projects = this.projectMemberships.models.map(projectMembership => {
      return {
        uuid: projectMembership.uuid,
        name: projectMembership.name,
        selected: false
      };
    });

    if (bulkSchedule) {
      projects = projects.map(project => {
        bulkSchedule.projectUuids.forEach(projectUuid => {
          if (project.uuid === projectUuid) {
            project.selected = true;
          }
        });

        return project;
      });
    }

    this.update({
      projects: sortBy(projects, [project => project['name'].toLowerCase()])
    });
  }

  @action.bound
  handleUpdateGroups(bulkSchedule) {
    let groups = this.groups.models.map(group => {
      return {
        uuid: group.uuid,
        groupName: group.groupName,
        groupClass: group.groupClass.name,
        selected: false
      };
    });

    if (bulkSchedule) {
      groups = groups.map(group => {
        bulkSchedule.groupUuids?.forEach(groupUuid => {
          if (group.uuid === groupUuid) {
            group.selected = true;
          }
        });

        return group;
      });
    }

    this.update({
      groups: sortBy(groups, [group => group['groupName'].toLowerCase()])
    });
  }

  @action.bound
  handleNewOrder(index, newOrder = []) {
    const orders = [
      ...this.$('selectedTalks').value.map(talk => talk.order),
      ...newOrder
    ];
    const minID = 1;
    const listID = {};

    if (index) {
      orders.push(index);
    }

    orders.forEach(order => (listID[order] = order));

    let id = minID;
    while (id in listID) {
      id++;
    }

    return id;
  }

  @action.bound
  handleUpdateSelectedTalksFromAutoComplete(selectedTalks) {
    const newOrders = [];

    const newSelectedTalks = [
      ...this.$('selectedTalks').value,
      ...selectedTalks.map((talk, index) => {
        const newOrder = this.handleNewOrder(index, newOrders);

        newOrders.push(newOrder);

        return {
          uuid: talk.uuid,
          order: newOrder,
          name: talk.name
        };
      })
    ];

    const newTalks = this.$('talks').value.map(talk => {
      selectedTalks.forEach(selectedTalk => {
        if (talk.uuid === selectedTalk.uuid) {
          talk.selected = true;
          talk.selectedAutocomplete = false;
        }
      });
      return talk;
    });

    this.update({
      talks: newTalks,
      selectedTalks: newSelectedTalks
    });
  }

  @action.bound
  reAssignOrderNumbersByDragging() {
    const reOrderedSelectedTalks = this.$('selectedTalks').values();

    this.update({
      selectedTalks: reOrderedSelectedTalks.map((talk, index) => ({
        uuid: talk.uuid,
        name: talk.name,
        order: index + 1
      }))
    });
  }

  @action.bound
  reAssignOrderNumbersByInputs() {
    const currentOrder = this.$('selectedTalks').values();
    const reOrderedSelectedTalks = sortBy(currentOrder, [talk => talk.order]);

    this.update({
      selectedTalks: reOrderedSelectedTalks.map((talk, index) => ({
        uuid: talk.uuid,
        name: talk.name,
        order: index + 1
      }))
    });
  }

  @computed
  get isValidSummary() {
    return Boolean(
      !this.$('selectedTalks').value.length ||
        !this.selectedTalks.length ||
        !this.selectedProjects.length
    );
  }
}

export {
  ScheduleBulkForm,
  scheduleBulkFormOptions,
  scheduleBulkFormFields,
  scheduleBulkFormRules,
  scheduleBulkFormValues,
  scheduleBulkFormLabels,
  scheduleBulkFormPlugins
};
