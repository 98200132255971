import UIStore from './UIStore';
import { action, observable, runInAction, computed } from 'mobx';
import request from 'axios';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

import moment from 'moment';

import {
  TimesheetsReportForm,
  timesheetsReportFormRules,
  timesheetsReportFormFields,
  timesheetsReportFormOptions,
  timesheetsReportFormPlugins,
  timesheetsReportFormValues
} from 'forms/timesheetsReport';
import TimesheetsScheduledReportUI from './TimesheetsScheduledReportUI';

export default class TimesheetsReportUI extends UIStore {
  @observable activeReportTab;
  @observable allPayPeriodOptions;
  @observable loading;

  constructor(options) {
    super(options);
    this.activeReportTab = 'runOnce';

    // Timesheet Scheduled Report UI Store

    this.timesheetsScheduledReportUI = new TimesheetsScheduledReportUI({
      rootStore: this.rootStore,
      parent: this
    });

    // Logic for project filters
    this.timesheetFiltersUI = options.parent.timesheetFiltersUI;

    //Pay periods
    this.allPayPeriodOptions = [];

    this.loading = false;
  }

  @action.bound
  initReportForm() {
    this.activeForm = new TimesheetsReportForm(
      {
        fields: timesheetsReportFormFields,
        rules: timesheetsReportFormRules,
        values: timesheetsReportFormValues
      },
      {
        options: timesheetsReportFormOptions,
        plugins: timesheetsReportFormPlugins
      }
    );
  }

  @computed get projectDefaultValue() {
    if (this.project) {
      return [
        {
          value: this.project.uuid,
          name: this.project.name
        }
      ];
    }

    return [];
  }

  @computed get payPeriodOptions() {
    return [
      {
        name: t('Specific time frame'),
        key: 'SPECIFIC',
        value: { from: '', to: '' }
      },
      ...this.payPeriodSelectorUI.recentPayPeriodOptions
    ];
  }

  @action.bound
  openTimesheetsReport() {
    this.loading = true;

    this.authorization
      .checkFeatureAccess('ScheduleTimeCardReports', true)
      .then(() => {
        runInAction(async () => {
          this.showModal('timesheetsReport');
          await this.payPeriodSelectorUI.setup();
          this.initReportForm();

          if (this.project) {
            this.setProjectIncludeOption('SELECTED');
            this.selectProjects(this.projectDefaultValue);
          } else {
            this.setProjectIncludeOption('ACTIVE_AND_INACTIVE');
          }

          this.loading = false;
        });
      });
  }

  @action.bound
  hideTimesheetsReport() {
    this.hideActiveModal().then(() => {
      runInAction(() => {
        this.activeForm?.tearDownReactions();
        this.activeForm = null;
        this.timesheetsScheduledReportUI.activeForm = null;
        this.timesheetsScheduledReportUI.scheduledReportToEdit = null;
        this.activeReportTab = 'runOnce';
        this.payPeriodSelectorUI.tearDown();

        this.saving = false;
      });
    });
  }

  @action.bound
  switchTab(e, tab) {
    this.activeReportTab = tab;
  }

  @action.bound
  setRecentPayPeriodFilter(period) {
    this.activeForm.$('recentPayPeriodFilter').set('value', period);
  }

  @action.bound
  setSpecificStartDate(date) {
    this.activeForm.$('from').set(date?.format('YYYY-MM-DD'));

    // Clear out end date if it is before start date
    if (
      this.activeForm.$('to').value &&
      moment(this.activeForm.$('to').value).isBefore(
        this.activeForm.$('from').value
      )
    ) {
      this.activeForm.$('to').set(null);
    }
  }

  @action.bound
  setSpecificEndDate(date) {
    this.activeForm.$('to').set(date?.format('YYYY-MM-DD'));
  }

  @action.bound
  resetSpecificPayPeriod() {
    this.activeForm
      .$('specificPayPeriodFilter')
      .set('value', { name: '', value: { from: '', to: '' } });
  }

  @computed
  get showSpecificPeriod() {
    return this.activeForm.$('payPeriodFilterStatus').value === 'SPECIFIC';
  }

  @action.bound
  selectProjects(selectedProjects) {
    this.activeForm.update({
      projectFilters: selectedProjects
    });
  }

  @action.bound
  toggleWorkersCostCodes() {
    this.activeForm
      .$('includeCostCode')
      .set('value', !this.activeForm.$('includeCostCode').value);
  }

  @computed
  get projectIncludeOptions() {
    return [
      {
        label: t('All projects'),
        value: 'ACTIVE_AND_INACTIVE',
        dataQA: 'all-projects'
      },
      {
        label: t('Selected'),
        value: 'SELECTED',
        dataQA: 'selected-projects'
      }
    ];
  }

  @action.bound
  setProjectIncludeOption(value) {
    this.activeForm.$('projectIncludeOption').set('value', value);

    if (value === 'ACTIVE_AND_INACTIVE') {
      this.activeForm.update({
        projects: []
      });
    }
  }

  @computed
  get enableRunOnceSend() {
    if (this.saving || !this.activeForm) return false;

    return this.activeForm.isValid && this.activeForm.projectFiltersIsValid;
  }

  @action.bound
  async submitTimesheetsRunOnceReportForm(e) {
    e.preventDefault();
    e.stopPropagation();

    if (this.saving) return;

    this.clearValidationDetails();

    this.activeForm.submit({
      onSuccess: this.submitTimesheetsRunOnceReportFormSuccess,
      onError: this.submitTimesheetsRunOnceReportFormError
    });
  }

  submitTimesheetsRunOnceReportFormError(values) {
    console.log(this.userForm.errors());
  }

  @action.bound
  async submitTimesheetsRunOnceReportFormSuccess() {
    const values = this.activeForm.values();

    const payload = {
      projectIncludeOption: values.projectIncludeOption,
      projects: values.projects,
      emails: values.emails,
      includeCostCode: values.includeCostCode,
      from: values.from,
      to: values.to
    };

    const url = `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/timesheets/reports/run-once`;

    this.saving = true;

    try {
      await request.post(url, payload);
      this.hideTimesheetsReport();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Your download request has been sent.')
      });
    } catch (error) {
      this.saving = false;
      alertErrorHandler(error, this.setValidationDetails);
    }
  }
}
