import React, { useState, useCallback } from 'react';
import { Observer } from 'mobx-react';

import {
  AvatarV2,
  Navigation,
  Box,
  Popover,
  Link,
  List,
  Text,
  Icon
} from '@raken/athens-web/lib';

import { useHistory } from 'react-router-dom';
import { t } from 'utils/translate';
import styled from 'styled-components';
import redirectToExternalUrl from 'utils/redirectToExternalUrl';

const StyledNavigation = styled(Navigation)`
  .active {
    :after {
      background-color: ${({ theme }) => theme.palette.orange.main};
    }
  }
`;

const DashboardLink = styled(Link)`
  svg {
    color: ${({ theme }) => theme.palette.grey.main};
  }
  &.active {
    svg {
      color: ${({ theme }) => theme.palette.darkGrey.main};
    }
  }
`;

const DashboardIconWrapper = styled(Box)`
  height: 50px;
`;

const SuperAdminHeader = ({ store }) => {
  const history = useHistory();

  const handleNavigation = useCallback(
    path => {
      history.push(path);
    },
    [history]
  );

  const links = [
    {
      path: '/users',
      label: 'Users'
    },
    {
      path: '/companies',
      label: 'Companies'
    },
    {
      path: '/projects',
      label: 'Projects'
    },
    {
      path: '/demos',
      label: 'Demos'
    }
  ];

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);

  return (
    <Observer>
      {() => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          minWidth={1024}
          role="banner"
          data-qa="nav_main"
          style={{ backgroundColor: 'white' }}
        >
          <Box display="flex">
            <Box>
              <DashboardIconWrapper
                px={4}
                display="flex"
                alignItems="center"
                onClick={() => {
                  handleNavigation('/');
                }}
              >
                <DashboardLink to="/" dataQA="home">
                  <Icon kind="home" size="big" dataQA="home-link" />
                </DashboardLink>
              </DashboardIconWrapper>
            </Box>
            <Box p={3}>
              <Text bold>
                {store.me.company &&
                  t('Company ID: {id} | Company UUID: {uuid}', {
                    templateStrings: {
                      id: store.me.company.id,
                      uuid: store.me.company.uuid
                    }
                  })}
              </Text>
            </Box>
          </Box>
          <Box display="flex">
            <StyledNavigation
              dataQA={'super-admin-navigation'}
              links={
                store.superAdminUI.extendedPermissions
                  ? [
                      ...links,
                      {
                        path: '/admin/debug',
                        label: t('Debug'),
                        onClick: event => {
                          event.preventDefault();
                          redirectToExternalUrl('/admin/debug');
                        }
                      },
                      {
                        path: '/super-users',
                        label: t('Super users')
                      }
                    ]
                  : links
              }
            />
          </Box>
          <Box display="flex">
            <Box onClick={handleClick} px={8}>
              <AvatarV2
                src={
                  store.me.avatarImage || `${store.assetsURL}/images/avatar.png`
                }
                data-qa="image_main-avatar"
                style={{
                  width: '32px',
                  height: '32px',
                  cursor: 'pointer'
                }}
              />
            </Box>
            <Popover
              disableScrollLock
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              elevation={0}
            >
              <List dataQA="main-dropdown">
                <List.Item button onClick={handleClose} dataQA="user-name">
                  <Box display="flex" flexDirection="column">
                    <Text light>{t('Logged in as: ')}</Text>
                    <Text light>{store.me.fullName || store.me.username}</Text>
                  </Box>
                </List.Item>
                <List.Item
                  button
                  component="a"
                  onClick={() => {
                    store.logout();
                    handleClose();
                  }}
                  dataQA="user-logout"
                >
                  <Text>{t('Logout')}</Text>
                </List.Item>
              </List>
            </Popover>
          </Box>
        </Box>
      )}
    </Observer>
  );
};

export default SuperAdminHeader;
