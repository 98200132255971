import moment from 'moment';
import request from 'axios';
import { action, computed } from 'mobx';
import { RakenCollection } from '../lib';
import Attachment from '../models/Attachment';
import errorHandler from 'utils/errorHandler';
import { callTrack } from 'utils/segmentIntegration';
import { ATTACHMENT_ADDED } from 'utils/segmentAnalytics/eventSpec';

export default class Attachments extends RakenCollection {
  constructor(data, options) {
    super(data, options);
    if (options.uploadLimit) {
      this.uploadLimit = options.uploadLimit;
    }
  }

  model() {
    return Attachment;
  }

  url() {
    return `/ra/media`;
  }

  @computed
  get uploadUrl() {
    if (!this.parent || this.parent.isNew) {
      return '/ra/media';
    }

    return `${this.parent.url()}/attachments`;
  }

  @action.bound
  async upload(formData, progressCallback, file, module) {
    try {
      const lastModified = moment(
        file.lastModified || file.lastModifiedDate.getTime()
      ).toISOString();

      const type = file.type || 'application/octet-stream';

      const signedUrlResponse = await request.post('/ra/media/request-upload', {
        originalFileName: file.name,
        lastModifiedDateTime: lastModified,
        contentType: `${type}`,
        contentLength: file.size,
        withContentDisposition: true
      });

      if (signedUrlResponse.data) {
        await request.put(signedUrlResponse.data.url, file, {
          onUploadProgress: progressEvent => {
            let percentCompleted = Math.floor(
              (progressEvent.loaded * 100) / progressEvent.total
            );

            if (progressCallback) {
              progressCallback(percentCompleted);
            }
          },
          transformRequest: [
            function(data, headers) {
              headers.put['Content-Type'] = `${type}`;

              Object.keys(signedUrlResponse.data.customHeaders).forEach(key => {
                headers.put[key] = signedUrlResponse.data.customHeaders[key];
              });

              return data;
            }
          ]
        });

        let data = {};

        if (this.rootStore.projectUI.segmentUuid) {
          data = {
            s3ObjectKeys: [signedUrlResponse.data.key],
            segmentUuid: this.rootStore.projectUI.segmentUuid
          };
        } else {
          data = {
            s3ObjectKeys: [signedUrlResponse.data.key]
          };
        }

        const response = await request.post(`${this.uploadUrl}`, data);

        if (response.data) {
          const media = response.data.collection[0];

          this.add(media);

          const attachment = this.get(media.uuid);

          if (attachment.mediaType === 'IMAGE') {
            attachment.setPreview(file.preview);
          }

          attachment.fetchThumbUrl();

          callTrack(ATTACHMENT_ADDED, {
            project_id: this.rootStore?.projectUI?.project?.id,
            project_name: this.rootStore?.projectUI?.project?.name,
            attachment_type: media.contentType,
            module: module
          });
        }
      }
    } catch (error) {
      errorHandler(error, this.rootStore.notificationsUI.pushNotification);
    }
  }

  // TODO: Super Daily Two - Rename and remove Version One
  @action.bound
  async uploadVersionTwo(options) {
    const { file, metadata, progressCallback, analytics } = options;

    const lastModified = moment(
      file.lastModified || file.lastModifiedDate.getTime()
    ).toISOString();

    const type = file.type || 'application/octet-stream';

    const signedUrlResponse = await request.post('/ra/media/request-upload', {
      originalFileName: file.name,
      lastModifiedDateTime: lastModified,
      contentType: `${type}`,
      contentLength: file.size,
      withContentDisposition: true
    });

    if (signedUrlResponse.data) {
      await request.put(signedUrlResponse.data.url, file, {
        onUploadProgress: progressEvent => {
          let percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          if (progressCallback) {
            progressCallback(percentCompleted);
          }
        },
        transformRequest: [
          function(data, headers) {
            headers.put['Content-Type'] = `${type}`;

            Object.keys(signedUrlResponse.data.customHeaders).forEach(key => {
              headers.put[key] = signedUrlResponse.data.customHeaders[key];
            });

            return data;
          }
        ]
      });

      let data = {};

      if (metadata) {
        data = {
          s3ObjectKeys: [signedUrlResponse.data.key],
          ...metadata
        };
      } else {
        data = {
          s3ObjectKeys: [signedUrlResponse.data.key]
        };
      }

      const response = await request.post(
        options.uploadUrl || this.uploadUrl,
        data
      );

      if (response.data.collection) {
        const media =
          response.data.collection[response.data.collection.length - 1];

        if (options.previewAttachment) {
          this.add(media, {
            at: this.models.indexOf(options.previewAttachment)
          });
        } else {
          this.add(media);
        }

        const attachment = this.get(media.uuid);

        if (attachment.mediaType === 'IMAGE') {
          attachment.setPreview(file.preview);
        }

        attachment.fetchThumbUrl();

        if (analytics) {
          callTrack(ATTACHMENT_ADDED, {
            project_id: analytics.project.id,
            project_name: analytics.project.name,
            attachment_type: media.contentType,
            module: analytics.module
          });
        }
      }
    }
  }
}
