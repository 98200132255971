import styled from 'styled-components';

const ListGroup = styled.ul`
  margin-top: ${props => props.marginTop};
  margin-right: ${props => props.marginRight};
  margin-bottom: ${props => props.marginBottom};
  margin-left: ${props => props.marginLeft};
  padding: 0;
  width: 100%;
  &:last-child {
    margin-bottom: 0;
  }
`;

ListGroup.defaultProps = {
  marginBottom: '2.5rem'
};

export default ListGroup;
