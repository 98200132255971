import { RakenCollection } from '../lib';
import EquipmentMake from '../models/EquipmentMake';

export default class EquipmentMakes extends RakenCollection {
  model() {
    return EquipmentMake;
  }

  url() {
    return `${this.rootStore.appConfig.tracking_api_url}/companies/${this.rootStore.me.company.uuid}/equipment/makes`;
  }
}
