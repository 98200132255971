import { RakenCollection } from '../lib';
import ScheduledReport from '../models/ScheduledReport';

export default class ScheduledReports extends RakenCollection {
  model() {
    return ScheduledReport;
  }

  url() {
    return `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/timesheets/reports/scheduled`;
  }
}
