import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { action, computed, observable, reaction } from 'mobx';
import UIStore from '../../UIStore';
import history from 'utils/history';
import {
  checklistScheduleFormFields,
  checklistScheduleFormOptions,
  checklistScheduleFormPlugins,
  checklistScheduleFormRules,
  checklistScheduleFormLabels,
  ChecklistScheduleForm
} from 'forms/checklistSchedule';
import ChecklistSchedule from 'stores/models/checklists/ChecklistSchedule';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

import { callTrack } from 'utils/segmentIntegration';
import { CHECKLIST_SCHEDULE_EDITED } from 'utils/segmentAnalytics/eventSpec';
import formatChecklistDateToUTC from 'utils/formatChecklistDateToUTC';

const moment = extendMoment(Moment);

export default class ChecklistScheduleEditUI extends UIStore {
  @observable entryForEdit;
  @observable entryEditForm;

  constructor(options) {
    super(options);
    this.entryEditForm = null;
    this.entryForEdit = null;
  }

  @action.bound setup(id) {
    this.setupReactions();
    this.fetchEntry(id);

    this.projectSelectorUI.setup({
      projectStates: ['ACTIVE'],
      projectTypes: ['PARENT']
    });
  }

  @action.bound async fetchEntry(uuid) {
    if (this.entryForEdit) return;

    try {
      let model = this.parent.checklistSchedules.get(uuid);
      if (!model) {
        model = new ChecklistSchedule(
          {
            uuid: uuid
          },
          {
            rootStore: this.rootStore
          }
        );

        await model.fetch();
      }

      this.parent.setProjectUuid(model.project.uuid);
      await this.projectLocationSelectorUI?.setup(model.project.uuid);

      this.setEntryForEdit(model);
    } catch (error) {
      console.error(error);
      this.cancelChecklistScheduleEdit();
    }
  }

  @action.bound setEntryForEdit(model) {
    this.entryForEdit = model;

    const responsibleType = this.entryForEdit.responsible.classification
      ? 'responsibleWorkerClassification'
      : 'responsibleChildProject';

    this.entryEditForm = new ChecklistScheduleForm(
      {
        fields: checklistScheduleFormFields,
        rules: checklistScheduleFormRules,
        labels: checklistScheduleFormLabels,
        values: {
          ...this.entryForEdit.formValues,
          location: this.entryForEdit.location,
          project: this.entryForEdit.project,
          [responsibleType]: {
            uuid: this.entryForEdit.responsible.uuid,
            name: this.entryForEdit.responsibleName
          }
        }
      },
      {
        options: checklistScheduleFormOptions,
        plugins: checklistScheduleFormPlugins,
        rootStore: this.rootStore
      }
    );

    this.setProjectType(
      model.responsible.type === 'WorkerClassification'
        ? 'MY_TEAM'
        : 'collaborator'
    );
  }

  setupReactions() {
    this.reactToProjectUuid = reaction(
      () => this.parent.projectUuid,
      projectUuid => {
        if (projectUuid) {
          this.projectLocationSelectorUI.setup(projectUuid);
        }
      }
    );
  }

  tearDownReactions() {
    this.reactToProjectUuid && this.reactToProjectUuid();
    this.projectSelectorUI.resetProjectOptions();
  }

  @action.bound tearDown() {
    this.projectSelectorUI.tearDown();
    this.projectLocationSelectorUI.tearDown();
    this.tearDownReactions();
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.parent.projectUuid = null;
    this.activeModal = null;
    this.entryForEdit = null;
    this.entryEditForm = null;
    this.saving = false;
  }

  @computed
  get checklistTemplates() {
    return this.rootStore.checklistTemplates;
  }

  @computed get checklistTemplateOptions() {
    return this.checklistTemplates.models.map(template => ({
      name: template.name,
      id: template.uuid,
      type: template.typeString
    }));
  }

  @computed get selectedChecklistTemplateOption() {
    return this.checklistTemplateOptions?.find(
      option =>
        option.id === this.entryEditForm.$('checklistTemplateUuid').value
    );
  }

  @computed get times() {
    return Array.from(
      moment.range(moment().startOf('day'), moment().endOf('day')).by('hour')
    ).map(time => {
      return {
        value: time.format('HH:mm'),
        name: time.format('h:mm A')
      };
    });
  }

  @computed get frequencyOptions() {
    return [
      {
        value: 'ONE_OFF',
        name: t('One-off')
      },
      {
        value: 'DAILY',
        name: t('Daily (Monday-Friday)')
      },
      {
        value: 'WEEKLY',
        name: t('Weekly')
      },
      {
        value: 'BI_WEEKLY',
        name: t('Bi weekly')
      },
      {
        value: 'MONTHLY',
        name: t('Monthly')
      }
    ];
  }

  @computed get displayBeforeOptions() {
    return [
      {
        value: '1-HOUR',
        name: t('One hour before due')
      },
      {
        value: '1-DAY',
        name: t('One day before due')
      },
      {
        value: '2-DAY',
        name: t('Two days before due')
      },
      {
        value: '3-DAY',
        name: t('Three days before due')
      },
      {
        value: '4-DAY',
        name: t('Four days before due')
      },
      {
        value: '5-DAY',
        name: t('Five days before due')
      },
      {
        value: '6-DAY',
        name: t('Six days before due')
      },
      {
        value: '7-DAY',
        name: t('Seven days before due')
      }
    ];
  }

  @computed get hasWriteAccess() {
    return this.parent.hasWriteAccess;
  }

  @computed get enableClassificationSelector() {
    return this.entryEditForm.$('projectType').value === 'MY_TEAM';
  }

  @computed get disableFrequency() {
    return moment(this.entryEditForm.$('startDate').value).isSame(
      moment(this.entryEditForm.$('endDate').value)
    );
  }

  @computed get selectedFrequency() {
    return this.disableFrequency
      ? {
          value: 'ONE_OFF',
          name: t('One-off')
        }
      : this.frequencyOptions.find(
          option =>
            option.value === this.entryEditForm.$('scheduleFrequency').value
        );
  }

  @computed get disableDisplayBefore() {
    return ['ONE_OFF', 'DAILY'].includes(this.selectedFrequency?.value);
  }

  @computed get selectedDisplayBefore() {
    return this.disableDisplayBefore
      ? {
          value: '1-HOUR',
          name: t('One hour before due')
        }
      : this.displayBeforeOptions.find(
          option => option.value === this.entryEditForm.$('displayBefore').value
        );
  }

  @computed get showCreateMessage() {
    const diffFromToday = moment(
      this.entryEditForm.$('startDate').value,
      'YYYY-MM-DD'
    ).diff(moment().format('YYYY-MM-DD'), 'days');
    const selectedDisplayBeforeIndex = this.displayBeforeOptions.findIndex(
      option => option.value === this.selectedDisplayBefore?.value
    );
    return diffFromToday <= selectedDisplayBeforeIndex;
  }

  @action.bound
  async submitChecklistScheduleEditSuccess() {
    this.saving = true;
    const {
      projectType,
      checklistTemplateUuid,
      location,
      responsibleWorkerClassification,
      responsibleChildProject,
      project,
      enableReminder,
      startDate,
      startTime,
      endDate,
      scheduleFrequency,
      displayBefore
    } = this.entryEditForm.trimmedValues();

    const responsibleType =
      projectType === 'MY_TEAM'
        ? 'responsibleWorkerClassificationUuid'
        : 'responsibleChildProjectUuid';

    const responsibleUuid =
      projectType === 'MY_TEAM'
        ? responsibleWorkerClassification.uuid
        : responsibleChildProject.uuid;

    try {
      await this.entryForEdit.save(
        {
          projectUuid: project.uuid,
          checklistTemplateUuid,
          locationUuid: location?.uuid,
          [responsibleType]: responsibleUuid,
          enableReminder,
          startDate: formatChecklistDateToUTC(startDate, startTime).format(
            'YYYY-MM-DD'
          ),
          startTime: formatChecklistDateToUTC(startDate, startTime).format(
            'HH:mm'
          ),
          endDate: formatChecklistDateToUTC(endDate, startTime).format(
            'YYYY-MM-DD'
          ),
          scheduleFrequency: this.disableFrequency
            ? 'ONE_OFF'
            : scheduleFrequency,
          displayBefore: this.disableDisplayBefore ? '1-HOUR' : displayBefore
        },
        {
          wait: true
        }
      );

      callTrack(CHECKLIST_SCHEDULE_EDITED, {
        checklist_template_uuid: checklistTemplateUuid,
        location_uuid: location?.uuid,
        responsible_workerclassification_uuid:
          responsibleWorkerClassification.uuid,
        responsible_child_project_uuid: responsibleChildProject.uuid,
        project_uuid: 'project.uuid',
        start_date: moment(startDate).format('YYYY-MM-DD'),
        start_time: startTime,
        end_date: moment(endDate).format('YYYY-MM-DD'),
        schedule_frequency: scheduleFrequency,
        display_before: displayBefore
      });

      this.parent.sortByLastUpdated();
      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Checklist schedule updated')
      });
      this.cancelChecklistScheduleEdit();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  submitChecklistScheduleEdit(e) {
    e.preventDefault();
    e.stopPropagation();

    if (
      this.entryEditForm.$('scheduleFrequency').value !==
      this.selectedFrequency.value
    ) {
      this.entryEditForm
        .$('scheduleFrequency')
        .set(this.selectedFrequency.value);
    }

    if (
      this.entryEditForm.$('displayBefore').value !==
      this.selectedDisplayBefore.value
    ) {
      this.entryEditForm
        .$('displayBefore')
        .set(this.selectedDisplayBefore.value);
    }

    this.entryEditForm.submit({
      onSuccess: this.submitChecklistScheduleEditSuccess,
      onError: e => {
        console.error(this.entryEditForm.errors());
      }
    });
  }

  @action.bound
  cancelChecklistScheduleEdit() {
    history.push({
      pathname: `/company-settings/checklists/schedules`,
      search: this.baseQueryParams
    });
  }

  @action.bound async setProjectType(projectType) {
    this.entryEditForm.update({ projectType });
    const anyFieldHasError = Array.from(
      this.entryEditForm.fields.values()
    ).some(field => field.error != null);

    if (anyFieldHasError) {
      await this.entryEditForm
        .$('responsibleWorkerClassification.uuid')
        .validate();
      await this.entryEditForm.$('responsibleChildProject.uuid').validate();
      this.entryEditForm.validate({ showErrors: true });
    }
  }

  @action.bound resetResponsible() {
    this.entryEditForm.$('responsibleWorkerClassification').reset();
    this.entryEditForm.$('responsibleWorkerClassification').resetValidation();
    this.entryEditForm.$('responsibleChildProject').reset();
    this.entryEditForm.$('responsibleChildProject').resetValidation();
  }
}
