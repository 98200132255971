import { computed, action, observable } from 'mobx';
import { Model } from 'mobx-mc';
import sortBy from 'lodash.sortby';
import groupBy from 'lodash.groupby';
import orderBy from 'lodash.orderby';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import ProjectTeamCompletedDailies from '../collections/ProjectTeamCompletedDailies';
import ProjectTeamMissedDailies from '../collections/ProjectTeamMissedDailies';
import { t } from 'utils/translate';

import Company from './Company';

const moment = extendMoment(Moment);

export default class ProjectTeam extends Model {
  @observable memberSortField;
  @observable memberSortDirection;

  urlRoot = '/ra/project-teams';

  constructor(data, options) {
    super(data, options);

    this.completedDailies = new ProjectTeamCompletedDailies(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.missedDailies = new ProjectTeamMissedDailies(null, {
      parent: this,
      rootStore: this.rootStore
    });

    // Member sorting
    this.memberSortField = 'fullName';
    this.memberSortDirection = 'asc';
  }

  get restAttributes() {
    return [
      'id',
      'uuid',
      'type',
      'createdTimestamp',
      'updatedTimestamp',
      'projectState',
      'firstReportDate',
      'projectType',
      'startDate',
      'endDate',
      'companyRef',
      'stats',
      'members'
    ];
  }

  get restAttributeDefaults() {
    return {
      stats: {
        hours: 0,
        workers: 0,
        totalProjectDays: 0,
        missedDailies: 0,
        dailiesCompliancePercent: 0,
        pendingReports: 0
      }
    };
  }

  @computed
  get hasMembers() {
    return this.members.length > 0;
  }

  @computed
  get relatedProject() {
    if (this.projectType === 'PARENT') {
      return this.parent;
    }

    return this.collection?.parent;
  }

  @computed
  get company() {
    if (this.companyRef) {
      return new Company(this.companyRef, {
        rootStore: this.rootStore
      });
    }

    return null;
  }

  @computed
  get name() {
    return this.company && this.company.name;
  }

  @computed get subName() {
    if (this.projectType === 'CHILD') {
      return t('(Sub)');
    }

    return t('(Parent)');
  }

  @computed
  get years() {
    const range = moment.range(
      moment(this.firstReportDate)
        .startOf('year')
        .format('YYYY-MM-DD'),
      moment().format('YYYY-MM-DD')
    );

    return Array.from(range.by('year')).map(momentObj => {
      return momentObj.format('YYYY');
    });
  }

  @computed
  get sortedYears() {
    return sortBy(this.years);
  }

  @computed
  get durationDays() {
    const range = moment.range(
      moment(this.startDate)
        .startOf('year')
        .format('YYYY-MM-DD'),
      moment(this.endDate).format('YYYY-MM-DD')
    );

    return Array.from(range.by('day')).length;
  }

  @action.bound
  fetchPendingApprovalsByTeam() {
    return this.pendingApprovals.fetch();
  }

  @computed
  get hasPendingApprovals() {
    return this.stats.pendingReports > 0;
  }

  @action.bound
  fetchMissedDailies() {
    return this.missedDailies.fetch();
  }

  @computed
  get hasMissedDailies() {
    return this.missedDailies.length > 0;
  }

  @computed
  get missedDailiesOrderedBySegmentAndDate() {
    return orderBy(this.missedDailies.models.slice(), [
      'dateFor',
      'segmentOrder'
    ]);
  }

  @computed
  get missedDailiesGroupedByMonth() {
    return groupBy(this.missedDailiesOrderedBySegmentAndDate, model =>
      moment(model.dateFor).format('YYYY-MM')
    );
  }

  @computed
  get formValues() {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
      projectState: this.projectState
    };
  }
}
