import MobxReactForm from 'mobx-react-form';
import { computed, reaction } from 'mobx';
import extendedPlugins from '../utils/extendedPlugins';
import trimObject from '../../utils/trimObject';

const settingsSegmentsFormPlugins = extendedPlugins;

const settingsSegmentsFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false,
  retrieveOnlyEnabledFields: false
};

const settingsSegmentsFormFields = [
  'enableSegments',
  'enableScSegments',
  'segments',
  'segments[].uuid',
  'segments[].name',
  'segments[].index',
  'enableKiosk'
];

const settingsSegmentsFormRules = {
  enableSegments: 'boolean',
  enableScSegments: 'boolean',
  segments: 'array',
  'segments[].uuid': 'string',
  'segments[].name': 'string',
  'segments[].index': 'integer',
  enableKiosk: 'boolean'
};

const settingsSegmentsFormDisabled = {};

const settingsSegmentsFormLabels = {};

const settingsSegmentsFormValues = {};

class SettingsSegmentsForm extends MobxReactForm {
  constructor(settings, options) {
    super(settings, options);

    this.parent = options.parent;
    this.rootStore = this.parent.rootStore;

    this.setupReactions();
  }

  setupReactions() {
    // Only enforce segments validation if segmentsEnabled
    this.reactToSegmentsEnabled = reaction(
      () => this.$('enableSegments').value,
      enabled => {
        if (enabled) {
          this.$('segments').map(segment => {
            segment.$('name').set('rules', 'required|between:1,60');
          });
        } else {
          this.$('segments').map(segment => {
            segment.$('name').set('rules', 'string');
            segment.$('name').resetValidation();
          });
        }
      },
      { fireImmediately: true }
    );
  }

  tearDownReactions() {
    this.reactToSegmentsEnabled();
  }

  trimmedValues() {
    return trimObject(this.values());
  }

  @computed
  get segmentLengthLessThanTwo() {
    return (
      this.$('enableSegments').value && this.$('segments').value?.length < 2
    );
  }

  @computed
  get segmentNamesNotUnique() {
    const segmentNames = this.$('segments')
      .value.filter(value => value.name)
      .map(value => {
        return value.name;
      });

    const duplicateNames = segmentNames.filter(
      (item, index) => segmentNames.indexOf(item) != index
    );

    if (this.$('enableSegments').value) {
      return duplicateNames.length > 0 ? duplicateNames[0] : false;
    } else {
      return false;
    }
  }

  @computed
  get enableSegments() {
    return this.$('enableSegments').value;
  }

  @computed
  get segmentsDefinition() {
    return {
      segments: this.$('segments').value,
      enableSegments: this.$('enableSegments').value
    };
  }
}

export {
  SettingsSegmentsForm,
  settingsSegmentsFormOptions,
  settingsSegmentsFormFields,
  settingsSegmentsFormRules,
  settingsSegmentsFormValues,
  settingsSegmentsFormLabels,
  settingsSegmentsFormPlugins,
  settingsSegmentsFormDisabled
};
