import { Model } from 'mobx-mc';
import { observable, action, computed } from 'mobx';
import ScheduledTalks from '../collections/ScheduledTalks';
import orderBy from 'lodash.orderby';
import omit from 'lodash.omit';
import moment from 'moment';

export default class ParticipatingProject extends Model {
  @observable open;
  @observable page;
  @observable limit;
  @observable sortField;
  @observable sortDirection;
  @observable scheduledTalks;

  constructor(data, options) {
    super(data, options);

    this.open = false;
    this.sortField = 'scheduleDate';
    this.sortDirection = 'asc';
    this.limit = 10000;

    // Pagination
    this.pageSize = 10;
    this.page = 0;

    if (this.parent) {
      this.scheduledTalks = new ScheduledTalks(null, {
        rootStore: this.rootStore
      });
    }
  }

  get restAttributes() {
    return [
      'id',
      'uuid',
      'name',
      'projectState',
      'completedTalks',
      'compliancePercent',
      'missedTalks',
      'participating'
    ];
  }

  @action.bound
  parse(attributes) {
    this.scheduledTalks = new ScheduledTalks(attributes.scheduledTalks, {
      rootStore: this.rootStore
    });

    return {
      ...omit(attributes, ['scheduledTalks'])
    };
  }

  @computed
  get paramsTalksDetails() {
    const config = this.collection
      ? this.collection.parent.params
      : this.parent.params;

    return {
      dateFrom: config.startDay,
      dateTo: config.endDay,
      limit: this.limit
    };
  }

  @action.bound
  fetchScheduledTalks() {
    return this.scheduledTalks.fetch({
      url: `${this.rootStore.urlMicroService('toolbox')}/tbt/projects/${
        this.uuid
      }/project-talks/detailedTbtInsights`,
      params: this.paramsTalksDetails
    });
  }

  @computed
  get hasStats() {
    return Boolean(this.completedTalks || this.missedTalks);
  }

  @computed
  get totalPages() {
    return Math.ceil(this.searchedTalks.length / this.pageSize);
  }

  @computed
  get talks() {
    return this.scheduledTalks.models;
  }

  @computed
  get hasQueryAndSearchedTalks() {
    return Boolean(this.collection.parent.query && this.searchedTalks.length);
  }

  @computed
  get hasQueryAndNotFoundTalks() {
    return Boolean(this.collection.parent.query && !this.searchedTalks.length);
  }

  @computed
  get hasQueryProject() {
    const query = this.collection.parent.query.toLowerCase();

    return Boolean(query && this.name.toLowerCase().indexOf(query) !== -1);
  }

  @computed
  get searchedTalks() {
    const query = this.collection.parent.query.toLowerCase();

    return this.talks.filter(talk => {
      const scheduleDate = moment(talk.scheduleDate).format('YYYY-MM-DD');

      // Name
      const hasName =
        talk.talk.name && talk.talk.name.toLowerCase().indexOf(query) !== -1;

      // Date
      const hasDate =
        scheduleDate
          .toLowerCase()
          .indexOf(this.collection.parent.handleSearchDateFormatted(query)) !==
        -1;

      // Attendees
      const hasAttendees = talk.attendees.filter(item => {
        const firstName = item.worker.firstName;
        const lastName = item.worker.lastName;
        const fullName = `${firstName} ${lastName}`;

        return fullName.toLowerCase().indexOf(query) !== -1;
      }).length;

      return hasName || hasDate || hasAttendees;
    });
  }

  @computed
  get hasTalks() {
    return this.searchedTalks.length > 0;
  }

  @computed
  get paginatedTalks() {
    return orderBy(
      this.searchedTalks.slice(
        this.page * this.pageSize,
        this.page * this.pageSize + this.pageSize
      ),
      [this.sortField],
      [this.sortDirection]
    );
  }

  @action.bound
  handleSortByColumn(sortField) {
    this.page = 0;

    this.sortByColumn(sortField);
  }

  @action.bound
  handleChangePage(data) {
    const { selected } = data;

    this.page = selected;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      if (this.sortDirection === 'asc') {
        this.sortDirection = 'desc';
      } else {
        this.sortDirection = 'asc';
      }
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }
  }
}
