import { action } from 'mobx';
import { Collection } from 'mobx-mc';
import Plan from '../../models/billing/Plan';

export default class Plans extends Collection {
  model() {
    return Plan;
  }

  url() {
    return `/ra/billing-plans`;
  }

  @action.bound
  set(response, options) {
    super.set(response, options);
    this.bookmark = response.bookmark;
  }

  @action.bound
  clear() {
    super.clear();
    this.bookmark = null;
  }

  parse(response) {
    return response.data;
  }
}
