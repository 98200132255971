import { computed } from 'mobx';
import ReportEntryWithAttachments from './ReportEntryWithAttachments';
import capitalize from 'lodash.capitalize';
import omit from 'lodash.omit';

import Tasks from '../collections/Tasks';
import User from './User';
import moment from 'moment-timezone';
import formatTimestamp from 'utils/formatTimestamp';
import uniq from 'lodash.uniq';

export default class Observation extends ReportEntryWithAttachments {
  get urlRoot() {
    return `${this.rootStore.urlMicroService('toolbox')}/companies/${
      this.rootStore.me.company.uuid
    }/observations`;
  }

  get restAttributes() {
    return [
      'uuid',
      'projectUuid',
      'type',
      'category',
      'priority',
      'status',
      'description',
      'location',
      'assignees',
      'emails',
      'entityStatus',
      'createdBy',
      'createdTimestamp',
      'emailUsers',
      'signature',
      'companyUuid'
    ];
  }

  idAttribute() {
    return 'uuid';
  }

  parse(attributes) {
    super.parse(attributes);

    this.parseTasks(attributes);

    return {
      ...omit(attributes, [
        'attachments',
        'assignees',
        'createdBy',
        'updatedBy',
        'company',
        'project'
      ])
    };
  }

  parseTasks(attributes) {
    if (this.tasks) {
      this.tasks.reset(attributes.assignees);
    } else {
      this.tasks = new Tasks(attributes.assignees, {
        rootStore: this.rootStore,
        parent: this
      });
    }
  }

  parseCreatedBy(attributes) {
    if (!attributes.createdBy) return null;

    if (typeof attributes.createdBy === 'string') {
      this.createdBy = attributes.createdBy;
      return;
    }

    this.createdBy = new User(attributes.createdBy, {
      rootStore: this.rootStore
    });
  }

  get categoryDisplayName() {
    return capitalize(this.category);
  }

  get categoryDisplayColor() {
    if (this.category === 'NEGATIVE') {
      return 'red';
    }

    return 'green';
  }

  get priorityDisplayName() {
    return capitalize(this.priority);
  }

  get priorityDisplayColor() {
    switch (this.priority) {
      case 'CRITICAL':
        return 'red';
      case 'HIGH':
        return 'orange';
      case 'MEDIUM':
        return 'yellow';
      default:
        return 'green';
    }
  }

  get formValues() {
    return {
      attachments: this.attachments,
      entityStatus: this.entityStatus,
      type: this.type,
      category: this.category,
      priority: this.priority,
      status: this.status,
      location: this.location,
      description: this.description,
      emails: this.emails
    };
  }

  @computed get statusOptionValue() {
    return {
      value: this.status?.uuid,
      title: this.status?.name
    };
  }

  @computed
  get createdDate() {
    return formatTimestamp(this.createdTimestamp);
  }

  @computed get observationImages() {
    return [
      ...this.attachments.models.map(attachment => {
        attachment.observationImage = true;
        return attachment;
      }),
      ...this.tasks.models.map((task, key) =>
        task.attachments.models.map(attachment => {
          attachment.assigneeCount = key + 1;
          return attachment;
        })
      )
    ].flat();
  }

  @computed
  get assigneeDueDates() {
    return this.tasks.models
      .filter(task => task.dueDate)
      .map(task => moment(task.dueDate).format('MMM D, YYYY'));
  }

  @computed get hasAssigneeDueDates() {
    return this.assigneeDueDates.length > 0;
  }

  @computed
  get assigneeCompanies() {
    return uniq(this.tasks.models.map(task => task.assignee?.businessName));
  }

  @computed get hasAssigneeCompanies() {
    return this.assigneeCompanies.length > 0;
  }

  @computed get createdByUuid() {
    if (typeof this.createdBy === 'object') {
      return this.createdBy?.uuid;
    }
    return this.createdBy;
  }

  @computed get canEditDeleteObservation() {
    /**
     * If is above role USER and observation is created by someone in my own company
     * We can edit it
     */
    return (
      (this.rootStore.me.isAdmin || this.rootStore.me.isProjectMember) &&
      this.companyUuid === this.rootStore.me.company.uuid
    );
  }

  @computed get hideStatusSelector() {
    if (this.entityStatus === 'DRAFT' || !this.canEditDeleteObservation) {
      return true;
    }

    return false;
  }

  @computed get formattedEntityStatus() {
    return capitalize(this.entityStatus);
  }

  @computed get label() {
    return `${this.type.type} - ${this.type.subType}`;
  }

  @computed get observationViewTitle() {
    if (this.location) {
      return `${this?.type?.type} - ${this.location.name}`;
    }

    return this?.type?.type;
  }
}
