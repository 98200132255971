import { Model } from 'mobx-mc';
import { action, computed, observable } from 'mobx';
import kebabCase from 'lodash.kebabcase';

export default class Break extends Model {
  @observable new;

  constructor(data, options) {
    super(data, options);

    this.new = false;
  }

  get restAttributes() {
    return [
      'uuid',
      'name',
      'code',
      'type',
      'defaultDuration',
      'minimumBreakDuration',
      'durationRequired',
      'startTimeRequired',
      'endTimeRequired',
      'billable',
      'hiddenProjects'
    ];
  }

  get restAttributeDefaults() {
    return {
      hiddenProjects: []
    };
  }

  idAttribute() {
    return 'uuid';
  }

  @computed get identificationString() {
    return `${this.name}, ${this.code}`;
  }

  @computed
  get formValues() {
    return {
      name: this.name,
      code: this.code,
      type: this.type,
      defaultDuration: this.defaultDuration,
      durationRequired: this.durationRequired,
      minimumBreakDuration: this.minimumBreakDuration,
      startTimeRequired: this.startTimeRequired,
      endTimeRequired: this.endTimeRequired,
      billable: this.billable
    };
  }

  @action.bound setAsNew() {
    this.new = true;
  }

  @action.bound clearNew() {
    this.new = false;
  }

  @computed get slug() {
    return kebabCase(this.name);
  }

  @computed get currentProject() {
    return this.rootStore.projectUI.project;
  }

  @computed get assignedToCurrentProject() {
    return !this.hiddenProjects.find(
      project =>
        project.uuid === this.currentProject?.uuid ||
        project.uuid ===
          this.rootStore.timesheetsUI?.timeCardToEdit?.projectUuid
    );
  }
}
