import { computed } from 'mobx';
import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import { t } from 'utils/translate';

const equipmentLogFormPlugins = extendedPlugins;

const equipmentLogFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const equipmentLogFormFields = [
  'date',
  'hours',
  'description',
  'costCode',
  'operator',
  'logStatus',
  'idleHours',
  'odometerReading',
  'fuelConsumption',
  'faultCode',
  'deficiencyDescription',
  'deficiencies[]',
  'deficiencies[].faultCode',
  'deficiencies[].deficiencyDescription'
];

const equipmentLogFormLabels = {
  date: t('date'),
  hours: t('hours in use'),
  description: t('notes'),
  costCode: t('cost code'),
  operator: t('operator'),
  logStatus: t('status'),
  idleHours: t('idle hours'),
  odometerReading: t('end mileage'),
  fuelConsumption: t('fuel consumed')
};

const equipmentLogFormRules = {
  hours: 'numeric|min:0|max:24|notMoreThanTwoDecimalPlaces',
  idleHours:
    'numeric|min:0|max:24|notMoreThanTwoDecimalPlaces|lessThanOrEqualToTotalHours:hours',
  fuelConsumption: 'numeric|min:0|max:1000|notMoreThanTwoDecimalPlaces',
  description: 'string|max:255',
  logStatus: 'string|required',
  date: 'required|dateCustom:YYYY-MM-DD',
  odometerReading: 'numeric|min:0|max:1000000',
  faultCode: 'string|max:10',
  deficiencyDescription: 'string|max:100',
  'deficiencies[].faultCode': 'string|max:10',
  'deficiencies[].deficiencyDescription': 'string|max:100'
};

const equipmentLogFormRelated = {
  hours: ['description'],
  description: ['hours']
};

class EquipmentLogForm extends MobxReactForm {
  @computed get deficiencyValues() {
    const nestedValues = this.$('deficiencies')
      .values()
      .map(deficiency => {
        const values = {
          faultCode: deficiency.faultCode,
          description: deficiency.deficiencyDescription
        };

        if (deficiency.uuid) {
          values.uuid = deficiency.uuid;
        }

        return values;
      });

    return [
      ...nestedValues,
      ...(this.$('faultCode').value || this.$('deficiencyDescription').value
        ? [
            {
              faultCode: this.$('faultCode').value,
              description: this.$('deficiencyDescription').value
            }
          ]
        : [])
    ];
  }
}

export {
  EquipmentLogForm,
  equipmentLogFormOptions,
  equipmentLogFormFields,
  equipmentLogFormRules,
  equipmentLogFormLabels,
  equipmentLogFormPlugins,
  equipmentLogFormRelated
};
