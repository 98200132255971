import moment from 'moment';
import { action, observable, computed } from 'mobx';
import UIStore from './UIStore';

import Attachment from 'stores/models/Attachment';
import MemberCertification from 'stores/models/MemberCertification';

import {
  MemberCertificationForm,
  memberCertificationFormOptions,
  memberCertificationFormFields,
  memberCertificationFormLabels,
  memberCertificationFormPlugins,
  memberCertificationFormValues,
  memberCertificationFormRules
} from 'forms/memberCertification';

import getFilePreviewIcon from 'utils/getFilePreviewIcon';
import fileToBase64 from 'utils/fileToBase64';
import bytesToSize from 'utils/bytesToSize';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import history from 'utils/history';
import { callTrack } from 'utils/segmentIntegration';
import { MEMBER_CERTIFICATION_ADDED } from 'utils/segmentAnalytics/eventSpec';

export default class TeamMemberCertificationAddUI extends UIStore {
  @observable entryForAdd;
  @observable entryAddForm;

  constructor(options) {
    super(options);

    this.entryForAdd = null;
    this.entryAddForm = null;
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound setup() {
    this.entryForAdd = new MemberCertification(
      {},
      {
        parent: this.parent,
        rootStore: this.rootStore
      }
    );

    this.entryAddForm = new MemberCertificationForm(
      {
        fields: memberCertificationFormFields,
        rules: memberCertificationFormRules,
        labels: memberCertificationFormLabels,
        values: memberCertificationFormValues
      },
      {
        options: memberCertificationFormOptions,
        plugins: memberCertificationFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound submitEntryAddForm(event) {
    event.preventDefault();
    event.stopPropagation();

    this.entryAddForm.submit({
      onSuccess: this.submitEntryAddFormSuccess,
      onError: this.submitEntryAddFormError
    });
  }

  @action.bound async submitEntryAddFormSuccess() {
    this.saving = true;

    try {
      const payload = this.entryAddForm.values();

      payload.expirationDate = payload.expirationDate
        ? moment(payload.expirationDate).format('YYYY-MM-DD')
        : null;

      payload.attachments = this.entryForAdd.attachments.models.map(
        attachment => {
          return {
            uuid: attachment.uuid
          };
        }
      );

      payload.certification.certificationType = {
        uuid: payload.certification.certificationType.uuid,
        name: payload.certification.certificationType.name
      };

      await this.entryForAdd.save(payload, {
        wait: true,
        stripNonRest: false
      });

      this.parent.sortByLastCreated();
      this.cancelMemberCertificationAdd();

      callTrack(MEMBER_CERTIFICATION_ADDED);

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Certification created')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitEntryAddFormError() {
    console.error(this.entryAddForm.errors());
  }

  @action.bound resetEntryAddForm() {
    this.clearValidationDetails();
    this.entryAddForm.reset();
    this.entryAddForm.showErrors(false);
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.entryForAdd = null;
    this.entryAddForm = null;
    this.saving = false;
  }

  @computed get disableCancelButton() {
    return Boolean(this.saving || this.hasAttachmentsUploading);
  }

  @computed get disableSaveButton() {
    return Boolean(this.saving || this.hasAttachmentsUploading);
  }

  @computed get saveButtonText() {
    if (this.saving) {
      return t('Saving...');
    }

    if (this.hasAttachmentsUploading) {
      return t('Uploading files...');
    }

    return t('Save');
  }

  @computed get hasAttachmentsUploading() {
    return this.entryForAdd?.attachments.models.find(
      attachment => attachment.isNew
    );
  }

  @action.bound async uploadAttachment(uploadItem) {
    await this.authorization.checkFeatureAccess('UploadAttachments');

    const file = uploadItem.file;

    if (file.size > 62914560) {
      this.rootStore.notificationsUI.pushNotification({
        title: `${t('File is too big ')} (${bytesToSize(file.size)}). ${t(
          'Limit is 60MB.'
        )}`,
        snackbar: 'error',
        icon: 'notification'
      });

      return;
    }

    file.preview = await fileToBase64(file);

    const filePreviewIcon = getFilePreviewIcon(this.rootStore.assetsURL, file);

    const previewAttachment = new Attachment(
      {
        fileName: file.name,
        thumbUrl: filePreviewIcon
      },
      {
        rootStore: this.rootStore
      }
    );

    this.entryForAdd.attachments.add(previewAttachment);

    try {
      await this.entryForAdd.attachments.uploadVersionTwo({
        file: file,
        previewAttachment: previewAttachment,
        progressCallback: percentCompleted => {
          // If we wanted to show a progress bar on the attachment
          // we can use previewAttachment.uploadProgress.
          previewAttachment.setUploadProgress(percentCompleted);
        }
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      // Remove the preview model now that the new one is ready
      this.entryForAdd.attachments.remove(previewAttachment);
    }
  }

  @action.bound cancelMemberCertificationAdd() {
    history.push({
      pathname: `${this.parent.entryForEdit.viewUrl}/certifications`
    });
  }

  @computed get certificationOptions() {
    return this.parent.certificationOptions;
  }
}
