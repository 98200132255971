import { RakenCollection } from '../lib';
import ObservationStatus from '../models/ObservationStatus';

export default class ObservationStatuses extends RakenCollection {
  model() {
    return ObservationStatus;
  }

  url() {
    return `${this.rootStore.urlMicroService('toolbox')}/companies/${
      this.rootStore.me.company.uuid
    }/observations/statuses`;
  }
}
