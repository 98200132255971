import MobxReactForm from 'mobx-react-form';
import { t } from '../utils/translate';
import extendedPlugins from './utils/extendedPlugins';

const timeFrameFormPlugins = extendedPlugins;

const timeFrameFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const timeFrameFormFields = ['fromDate', 'toDate'];

const timeFrameFormRules = {
  fromDate: 'required|dateCustom:YYYY-MM-DD',
  toDate:
    'required|dateCustom:YYYY-MM-DD|afterOrSameAsCustom:fromDate,YYYY-MM-DD'
};

const timeFrameFormValues = {};

const timeFrameFormLabels = {
  fromDate: t('from date'),
  toDate: t('to date')
};

class TimeFrameForm extends MobxReactForm {}

export {
  TimeFrameForm,
  timeFrameFormOptions,
  timeFrameFormFields,
  timeFrameFormRules,
  timeFrameFormValues,
  timeFrameFormLabels,
  timeFrameFormPlugins
};
