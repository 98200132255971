import styled from 'styled-components';
import { Observer } from 'mobx-react';
import React, { Fragment, useEffect } from 'react';

import {
  Button,
  Img,
  Container,
  Box,
  Text,
  Alert
} from '@raken/athens-web/lib';

import { t } from 'utils/translate';

const StyledContainer = styled(Container)`
  min-height: 100vh;
`;

const AppConnectFailure = ({ store, location, match }) => {
  const uiStore = store.appConnectUI;

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    uiStore.initializeFailure({
      integrationId: params.get('integrationId'),
      companyId: params.get('companyId'),
      companyUuid: params.get('companyUuid'),
      projectId: params.get('projectId'),
      projectUuid: params.get('projectUuid'),
      error: params.get('error')
    });
  }, [uiStore, location]);

  return (
    <Observer>
      {() => {
        if (uiStore.loading || !uiStore.activeIntegration) return null;

        return (
          <Fragment>
            <StyledContainer mx="auto" height="100%">
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                <Box mb={8}>
                  <Text align="center" variant="h1" dataQA="raken" bold>
                    {t(
                      'Your {integrationName} account could not be connected.',
                      {
                        templateStrings: {
                          integrationName: uiStore.activeIntegration.title
                        }
                      }
                    )}
                  </Text>
                </Box>

                {uiStore.error && (
                  <Box mb={8}>
                    <Alert>{uiStore.error}</Alert>
                  </Box>
                )}

                <Box mb={6}>
                  <Img
                    src={uiStore.assetsURL + '/svg/raken-logo-new.svg'}
                    alt="Raken"
                    data-qa="image_active-integration-logo"
                    height="55"
                  />
                </Box>
                <Box mb={8}>
                  <Img
                    src={uiStore.activeIntegration.logoLarge}
                    alt={uiStore.activeIntegration.name}
                    data-qa="image_active-integration-logo"
                    height="55"
                  />
                </Box>

                <Box width="100%">
                  <Button
                    onClick={() => {
                      window.close();
                    }}
                    type="button"
                    fullWidth
                    dataQA="close"
                  >
                    {t('Close')}
                  </Button>
                </Box>
              </Box>
            </StyledContainer>
          </Fragment>
        );
      }}
    </Observer>
  );
};

export default AppConnectFailure;
