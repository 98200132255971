import { action, observable, computed, reaction, runInAction } from 'mobx';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import moment from 'moment-timezone';
import orderBy from 'lodash.orderby';

import TimeCardEventsByDates from 'stores/collections/TimeCardEventsByDates';

import ProjectChildUI from './ProjectChildUI';

import { t } from 'utils/translate';
import history from 'utils/history';

import { callTrack } from 'utils/segmentIntegration';

import {
  MAP_CLICKED,
  MAP_SEARCH_BAR_CLICKED,
  MAP_EMPLOYEE_CLICKED,
  MAP_VIOLATIONS_ENABLED,
  MAP_NO_GPS_ENABLED,
  MAP_CALENDAR_CLICKED,
  MAP_EVENT_PIN_CLICKED,
  MAP_EVENT_EMPLOYEE_CLICKED
} from 'utils/segmentAnalytics/eventSpec';

import UserEventsMapEventsViewUI from './UserEventsMapEventsViewUI';

import {
  UserEventsMapFiltersForm,
  userEventsMapFiltersFormRules,
  userEventsMapFiltersFormFields,
  userEventsMapFiltersFormOptions,
  userEventsMapFiltersFormPlugins
} from 'forms/project/userEventsMapFilters';

export default class UserEventsMapUI extends ProjectChildUI {
  @observable map;
  @observable timeFrameFilter;
  @observable searchQuery;
  @observable sidebarOpen;
  @observable calendarOpen;
  @observable userEventsMapFiltersForm;
  @observable timeClockStatus;
  @observable onlyNoGps;
  @observable onlyViolations;
  @observable workerToView;
  @observable initalFetchComplete;

  constructor(options) {
    super(options);

    this.google = window.google;
    this.map = null;
    this.mapRef = null;

    this.clusterer = null;

    this.searchQuery = '';
    this.sidebarOpen = true;

    this.calendarOpen = false;

    this.userEventsMapFiltersForm = null;

    this.userEventsMapEventsViewUI = new UserEventsMapEventsViewUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.workerToView = null;

    // TimeCardEvents collection
    this.timeCardEvents = new TimeCardEventsByDates(null, {
      parent: this,
      rootStore: this.rootStore
    });

    // Request Params
    this.timeFrameFilter = {
      title: t('Last 24 hours'),
      value: '24_HOURS'
    };

    this.clusterIconSelected = window.btoa(`<svg width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="2" y="1.5" width="30" height="30" rx="15" fill="white"/>
    <rect x="2" y="1.5" width="30" height="30" rx="15" stroke="#1277A5" stroke-width="3"/>
    </svg>`);

    this.clusterIconUnSelected = window.btoa(`<svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.75" y="1.25" width="28.5" height="28.5" rx="14.25" fill="#34AFE8"/>
    <rect x="0.75" y="1.25" width="28.5" height="28.5" rx="14.25" stroke="#1277A5" stroke-width="1.5"/>
    </svg>`);

    // Filters
    this.timeClockStatus = {
      title: t('All'),
      value: 'ALL'
    };
    this.onlyNoGps = false;
    this.onlyViolations = false;
    this.eventOrigins = observable([]);
    this.eventTypes = observable([]);
    this.workerUuids = observable([]);
    this.initalFetchComplete = false;
  }

  @action.bound
  async setup(mapRef) {
    await this.fetchTimeCardEvents();

    this.mapRef = mapRef;

    var styles = [
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }]
      }
    ];

    this.map = new this.google.maps.Map(this.mapRef, {
      zoomControl: true,
      animatedZoom: false,
      mapTypeControl: false,
      fullscreenControl: false,
      clickableIcons: false,
      center: this.project.address.geolocation,
      zoom: 15,
      zoomControlOptions: {
        position: this.google.maps.ControlPosition.LEFT_BOTTOM
      },
      streetViewControl: true,
      streetViewControlOptions: {
        position: this.google.maps.ControlPosition.LEFT_BOTTOM
      },
      gestureHandling: 'cooperative',
      styles
    });

    const streetView = new this.google.maps.StreetViewPanorama(
      this.map.getDiv(),
      {
        visible: false,
        enableCloseButton: true,
        addressControlOptions: {
          position: this.google.maps.ControlPosition.BOTTOM_CENTER
        }
      }
    );

    this.map.setStreetView(streetView);

    this.setupReactions();
    this.memberSelectorUI.setup({ companySettingsTrackMemberTime: true });

    this.google.maps.event.addListener(
      this.map,
      'click',
      callTrack(MAP_CLICKED)
    );
  }

  @action.bound
  tearDown() {
    this.google.maps.event.clearListeners(this.map, 'idle');
    this.tearDownReactions();
    this.memberSelectorUI.tearDown();
    this.clearUIState();
  }

  @action.bound
  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        this.fetchTimeCardEvents();
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed get params() {
    // We are only doing one day at a time so from and to date are the same.
    return {
      fromDate: this.date,
      toDate: this.date,
      eventOrigins: this.eventOrigins
        .map(eventOrigin => eventOrigin.value)
        .join(),
      timeClockStatus:
        this.timeClockStatus.value === 'ALL' ? '' : this.timeClockStatus.value,
      onlyNoGps: this.onlyNoGps,
      onlyViolations: this.onlyViolations,
      eventTypes: this.eventTypes.map(eventType => eventType.value).join(),
      workerUuids: this.workerUuids
        .map(workerUuid => workerUuid.workerUuid)
        .join()
    };
  }

  @action.bound async fetchTimeCardEvents() {
    if (this.timeCardEvents.fetching || !this.parent.project) return;

    await this.timeCardEvents.fetch({
      params: this.params,
      add: true,
      remove: true,
      update: false
    });

    if (this.clusterer) {
      this.clearPins();
    }

    const clusterIconUnSelected = this.clusterIconUnSelected;

    this.clusterer = new MarkerClusterer({
      map: this.map,
      renderer: {
        render({ count, position }, stats) {
          return new window.google.maps.Marker({
            position,
            icon: {
              url: `data:image/svg+xml;base64,${clusterIconUnSelected}`,
              scaledSize: new window.google.maps.Size(30, 30)
            },
            label: {
              text: String(count),
              color: '#00182E',
              fontSize: '14px'
            },
            title: `Cluster of ${count} markers`,
            // adjust zIndex to be above other markers
            zIndex: Number(window.google.maps.Marker.MAX_ZINDEX) + count
          });
        }
      },
      onClusterClick: (event, cluster, map) => {
        runInAction(() => {
          this.clearSelected();

          cluster.marker.setIcon(
            `data:image/svg+xml;base64,${this.clusterIconSelected}`
          );

          cluster.markers.forEach(marker => {
            const event = this.eventMapPins.find(
              userEvent => userEvent.uuid === marker.eventUuid
            );

            this.selectEvent(event);
          });
        });
      }
    });

    this.dropPins();

    this.initalFetchComplete = true;
  }

  syncMapDateWithWorklogDate(date) {
    this.parent.navigateToDate(moment(date));
  }

  clearPins() {
    this.eventMapPins.forEach(event => {
      event.pin = null;
    });

    this.clusterer.clearMarkers();
  }

  dropPins() {
    this.eventMapPins.forEach(event => {
      const pin = this.addPin(event);
      event.pin = pin;
    });
  }

  getPinIcon(workerEvent) {
    if (this.workerToView) {
      // If in event view use event view icons
      return workerEvent.selected
        ? workerEvent.outOfProjectArea
          ? `${this.assetsURL}/svg/map-pin-outside-radius-event-selected.svg`
          : `${this.assetsURL}/svg/map-pin-inside-radius-event-selected.svg`
        : workerEvent.outOfProjectArea
        ? `${this.assetsURL}/svg/map-pin-outside-radius-event.svg`
        : `${this.assetsURL}/svg/map-pin-inside-radius-event.svg`;
    }

    return workerEvent.selected
      ? `${this.assetsURL}/svg/map-pin-selected.svg`
      : workerEvent.outOfProjectArea
      ? `${this.assetsURL}/svg/map-pin-outside-radius.svg`
      : `${this.assetsURL}/svg/map-pin-inside-radius.svg`;
  }

  addPin(workerEvent) {
    const pin = new this.google.maps.Marker({
      position: {
        lat: parseFloat(workerEvent.latitude),
        lng: parseFloat(workerEvent.longitude)
      },
      map: this.map,
      icon: this.getPinIcon(workerEvent)
    });

    this.clusterer.addMarker(pin);

    pin.addListener('click', event => {
      this.clearSelected();
      callTrack(
        this.workerToView ? MAP_EVENT_PIN_CLICKED : MAP_EVENT_EMPLOYEE_CLICKED
      );
      this.selectEvent(workerEvent);
      workerEvent.pin.setIcon(this.getPinIcon(workerEvent));
    });

    pin.addListener('mouseout', () => {
      pin.setAnimation(null);
    });

    pin.eventUuid = workerEvent.uuid;

    return pin;
  }

  // Selected Pins
  @action.bound
  clearSelected() {
    this.clusterer.clusters.forEach(cluster => {
      cluster.marker.setIcon(
        `data:image/svg+xml;base64,${this.clusterIconUnSelected}`
      );
    });

    this.eventMapPins.forEach(event => {
      event.selected = false;
      event.pin?.setIcon(this.getPinIcon(event));
    });
  }

  @action.bound
  clickWorkerEventSidebar(worker, match) {
    callTrack(MAP_EMPLOYEE_CLICKED);
    history.push(
      `${match.url}/${worker.workerDetails.workerUuid}?date=${this.date}`
    );
  }

  @action.bound clearUIState() {
    this.timeCardEvents.reset();
    this.timeFrameFilter = {
      title: t('Last 24 hours'),
      value: '24_HOURS'
    };

    this.timeClockStatus = this.statusFilterOptions[0];
    this.onlyNoGps = false;
    this.onlyViolations = false;
    this.eventTypes.clear();
    this.workerUuids.clear();
    this.eventOrigins.clear();

    this.map = null;
    this.searchQuery = '';
    this.workerToView = null;
    this.initalFetchComplete = false;
  }

  // Search
  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
  }

  findLatestEvent(worker) {
    let latestEvent = null;
    let latestDeviceDate = null;

    for (const timeCard of worker.timeCards) {
      for (const event of timeCard.events.models) {
        const deviceDate = moment(event.deviceDate);

        if (!latestEvent || deviceDate.isAfter(latestDeviceDate)) {
          event.isInFlight = timeCard.isInFlight;
          latestEvent = event;
          latestDeviceDate = deviceDate;
        }
      }
    }

    return latestEvent;
  }

  @computed get eventMapPins() {
    if (this.workerToView) {
      if (!this.timeCardEvents.models[0]) return [];

      const worker = this.timeCardEvents.models[0].workers.find(
        worker =>
          worker.workerDetails.uuid === this.workerToView.workerDetails.uuid
      );

      return worker.timeCards
        .reduce((acc, entry) => {
          acc.push(entry.events.models.slice());
          return acc;
        }, [])
        .flat();
    }

    return this.timeCardEvents.models.reduce((acc, entry) => {
      for (const worker of entry.workers) {
        const latestEvent = this.findLatestEvent(worker);

        if (latestEvent) {
          acc.push(latestEvent);
        }
      }
      return acc;
    }, []);
  }

  @computed
  get orderedLatestEventPins() {
    return orderBy(
      this.eventMapPins,
      ['selected', 'workerDetails.firstName', 'workerDetails.lastName'],
      ['desc']
    );
  }

  @computed get searchedLatestEventPins() {
    if (!this.searchQuery) {
      return this.orderedLatestEventPins;
    }

    const query = this.searchQuery.toLowerCase();

    return this.orderedLatestEventPins.filter(event => {
      return (
        event.workerDetails.firstName?.toLowerCase().indexOf(query) > -1 ||
        event.workerDetails.lastName?.toLowerCase().indexOf(query) > -1 ||
        event.workerDetails.employeeId?.toLowerCase().indexOf(query) > -1
      );
    });
  }

  @computed
  get hasSearchedLatestEventPins() {
    return this.searchedLatestEventPins.length > 0;
  }

  //Sidebar
  @action.bound toggleSidebar() {
    this.sidebarOpen = !this.sidebarOpen;
  }

  // Filters
  @action.bound setCalendarOpen(value) {
    this.calendarOpen = value;
  }

  @computed
  get navigateToDate() {
    return this.parent.navigateToDate;
  }

  @computed get navigateToToday() {
    return this.parent.navigateToToday;
  }

  @computed get disableNavigateToToday() {
    return this.parent.disableNavigateToToday;
  }

  @computed get navigateToPreviousDay() {
    return this.parent.navigateToPreviousDay;
  }

  @computed get navigateToNextDay() {
    return this.parent.navigateToNextDay;
  }

  @computed get disableNavigateToNextDay() {
    return this.parent.disableNavigateToNextDay;
  }

  // Filters Modal
  @action.bound
  openUserEventsMapFiltersModal() {
    this.activeModal = 'userEventsMapFilters';
  }

  @action.bound
  hideUserEventsMapFiltersModal() {
    this.hideActiveModal();
  }

  @computed
  get filterFormDefaults() {
    return {
      timeClockStatus: this.statusFilterOptions[0],
      eventTypes: [],
      workerUuids: [],
      eventOrigins: [],
      noGps: false,
      violations: false
    };
  }

  @computed
  get filterFormValues() {
    return {
      timeClockStatus: this.timeClockStatus,
      eventTypes: this.eventTypes.slice(),
      workerUuids: this.workerUuids.slice(),
      eventOrigins: this.eventOrigins.slice(),
      violations: false,
      noGps: false
    };
  }

  @action.bound
  setupFiltersModal() {
    this.userEventsMapFiltersForm = new UserEventsMapFiltersForm(
      {
        fields: userEventsMapFiltersFormFields,
        rules: userEventsMapFiltersFormRules,
        values: this.filterFormValues
      },
      {
        options: userEventsMapFiltersFormOptions,
        plugins: userEventsMapFiltersFormPlugins
      }
    );
  }

  @action.bound
  tearDownFiltersModal() {
    this.userEventsMapFiltersForm = null;
  }

  @action.bound
  selectEvent(event) {
    event.selected = true;
  }

  @computed
  get statusFilterOptions() {
    return [
      {
        title: t('All'),
        value: 'ALL'
      },
      {
        title: t('On the clock'),
        value: 'ON_THE_CLOCK'
      },
      {
        title: t('Abandoned'),
        value: 'ABANDONED'
      },
      {
        title: t('Completed'),
        value: 'COMPLETED'
      }
    ];
  }

  @computed
  get eventTypeFilterOptions() {
    return [
      {
        title: t('Clock in'),
        value: 'TIME_CARD_STARTED'
      },
      {
        title: t('Clock out'),
        value: 'TIME_CARD_ENDED'
      },
      {
        title: t('Break start'),
        value: 'BREAK_STARTED'
      },
      {
        title: t('Break end'),
        value: 'BREAK_ENDED'
      },
      {
        title: t('Crew changed'),
        value: 'CREW_CHANGED'
      },
      {
        title: t('Task changed'),
        value: 'TASK_CHANGED'
      },
      { title: t('Note updated'), value: 'PAYROLL_NOTE_UPDATED' }
    ];
  }

  @computed
  get eventOriginOptions() {
    return [
      {
        title: t('Kiosk'),
        value: 'KIOSK'
      },
      {
        title: t('Time clock'),
        value: 'TIME_CLOCK'
      }
    ];
  }

  @action.bound
  applyFilters(event) {
    event.preventDefault();

    this.eventOrigins.replace(
      this.userEventsMapFiltersForm.$('eventOrigins').value
    );
    this.timeClockStatus = this.userEventsMapFiltersForm.$(
      'timeClockStatus'
    ).value;
    this.onlyNoGps = this.userEventsMapFiltersForm.$('noGps').value;
    this.onlyViolations = this.userEventsMapFiltersForm.$('violations').value;
    this.eventTypes.replace(
      this.userEventsMapFiltersForm.$('eventTypes').value
    );
    this.workerUuids.replace(
      this.userEventsMapFiltersForm.$('workerUuids').value
    );

    this.hideActiveModal();
  }

  @action.bound clearFilters(event) {
    this.userEventsMapFiltersForm?.update(this.filterFormDefaults);
    this.applyFilters(event);
  }

  @action.bound clearWorkerToView() {
    this.workerToView = null;
    history.push(`/projects/${this.project.uuid}/map`);

    this.clearPins();
    this.fetchTimeCardEvents();
  }

  @computed get showNoGpsWarning() {
    return this.workerToView && this.eventMapPins.find(event => event.noGpsFix);
  }

  @computed get projectTimezone() {
    return this.project.address?.timezone || moment.tz.guess();
  }

  mapSearchClicked() {
    callTrack(MAP_SEARCH_BAR_CLICKED);
  }

  @action.bound
  toggleViolationsFilter(value) {
    callTrack(MAP_VIOLATIONS_ENABLED);
    this.userEventsMapFiltersForm.$('violations').set(value);
  }

  @action.bound
  toggleNoGpsFilter(value) {
    callTrack(MAP_NO_GPS_ENABLED);
    this.userEventsMapFiltersForm.$('noGps').set(value);
  }

  sendCalendarClickedEvent() {
    callTrack(MAP_CALENDAR_CLICKED);
  }
}
