import omit from 'lodash.omit';
import { Model } from 'mobx-mc';
import { computed, action } from 'mobx';
import ProjectTeamMeasures from '../collections/ProjectTeamMeasures';

export default class ProjectMeasure extends Model {
  get restAttributes() {
    return ['id', 'type', 'stats', 'project', 'ranking', 'projectTeamId'];
  }

  @action.bound
  parse(attributes) {
    if (attributes.teamMeasures) {
      this.teamMeasures = new ProjectTeamMeasures(attributes.teamMeasures, {
        rootStore: this.rootStore,
        parent: this
      });
    }

    return {
      ...omit(attributes, ['teamMeasures'])
    };
  }

  @computed
  get manDays() {
    return this.stats.hours / 8;
  }

  @computed
  get relatedProject() {
    return (
      this.rootStore.projectUI.project ||
      this.rootStore.insightsUI.projects.models.find(
        project => project.parentProjectId === this.project.id
      )
    );
  }

  @computed
  get projectName() {
    return this.project.name;
  }

  @computed
  get hasTeamMeasures() {
    return this.teamMeasures && this.teamMeasures.length > 0;
  }

  @computed
  get hasExternalTeamMeasures() {
    return (
      this.hasTeamMeasures &&
      this.teamMeasures.models.filter(teamMeasure => {
        return teamMeasure.stats.company.id !== this.rootStore.me.company.id;
      }).length > 0
    );
  }

  @computed
  get relatedInsightsPeriod() {
    return this.collection.parent;
  }

  @computed
  get startDay() {
    return this.relatedInsightsPeriod.start;
  }

  @computed
  get formattedStartDay() {
    return this.relatedInsightsPeriod.formattedStartDay;
  }

  @computed
  get endDay() {
    return this.relatedInsightsPeriod.end;
  }

  @computed
  get dateRange() {
    return this.relatedInsightsPeriod.dateRange;
  }

  @computed
  get tooltipDateRange() {
    return this.relatedInsightsPeriod.tooltipDateRange;
  }

  @computed
  get selectedProjectMeasures() {
    return this.relatedInsightsPeriod.collection.parent.projectTableSeries
      .filter(series => {
        return this.relatedInsightsPeriod.collection.parent.chartProjects
          .map(project => project.parentProjectId)
          .includes(series.id);
      })
      .flatMap(series => {
        return series.data;
      })
      .map(seriesItem => seriesItem.projectMeasure);
  }

  @computed
  get selectedProjectMeasuresWithSameDateRangeAndChartStat() {
    return this.selectedProjectMeasures.filter(projectMeasure => {
      return (
        projectMeasure.dateRange === this.dateRange &&
        projectMeasure.stats[this.relatedInsightsPeriod.chartStat] ===
          this.stats[this.relatedInsightsPeriod.chartStat]
      );
    });
  }

  @computed
  get hasSelectedProjectMeasuresWithSameDateRangeAndChartStat() {
    return this.selectedProjectMeasuresWithSameDateRangeAndChartStat.length > 1;
  }

  @computed
  get viewUrl() {
    return `/projects/${this.project.uuid}`;
  }

  @computed
  get surveyUrl() {
    return `${this.viewUrl}/survey?date=${this.startDay}`;
  }

  @computed
  get taskUrl() {
    return `${this.viewUrl}/tasks?date=${this.startDay}`;
  }
}
