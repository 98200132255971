import MobxReactForm from 'mobx-react-form';
import validatorjs from 'validatorjs';
import { reaction, computed } from 'mobx';

const observationsReportFormPlugins = { dvr: validatorjs };

const observationsReportFormOptions = {
  validateOnInit: true,
  validateOnChange: true
};

const observationsReportFormFields = [
  'timeFrameType',
  'fromDate',
  'toDate',
  'projectIncludeOption',
  'projects',
  'groups',
  'classes',
  'emails',
  'email'
];

const observationsReportFormRules = {
  timeFrameType: 'required',
  projects: 'array|required_if:projectIncludeOption,SELECTED',
  groups: 'array|required_if:projectIncludeOption,SELECTED_GROUPS',
  classes: 'array',
  emails: 'array|required',
  email: 'required_without:emails|email'
};

const observationsReportFormValues = {
  projectIncludeOption: '',
  projects: [],
  groups: [],
  classes: [],
  email: ''
};

class ObservationsReportForm extends MobxReactForm {
  constructor(settings, options) {
    super(settings, options);

    this.cancelReactToEnableDatePickers = reaction(
      () => this.enableFromToDate,
      enableFromToDate => {
        if (enableFromToDate) {
          this.$('fromDate').set('rules', 'required|dateCustom:YYYY-MM-DD');
          this.$('toDate').set(
            'rules',
            'required|dateCustom:YYYY-MM-DD|afterOrSameAsCustom:fromDate,YYYY-MM-DD'
          );
        } else {
          this.$('fromDate').set('rules', '');
          this.$('toDate').set('rules', '');
        }
      },
      {
        fireImmediately: true
      }
    );
  }

  @computed get enableFromToDate() {
    return this.$('timeFrameType').value === 'SPECTIMEFRAME';
  }
}

export {
  ObservationsReportForm,
  observationsReportFormRules,
  observationsReportFormFields,
  observationsReportFormOptions,
  observationsReportFormPlugins,
  observationsReportFormValues
};
