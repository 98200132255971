import ReportEntryWithAttachments from './ReportEntryWithAttachments';
import { computed, action } from 'mobx';
import CostCode from './CostCode';
import Equipment from './Equipment';
import omit from 'lodash.omit';
import { t } from 'utils/translate';
import moment from 'moment';
import kebabCase from 'lodash.kebabcase';

export default class EquipmentLog extends ReportEntryWithAttachments {
  get urlRoot() {
    return `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/companies/${this.rootStore.me.company.uuid}/equipmentlogs`;
  }

  get restAttributes() {
    return [
      'uuid',
      'startDate',
      'endDate',
      'status',
      'daysOnsite',
      'projectUuid',
      'createdTimestamp',
      'updatedTimestamp',
      'type',
      'daysIdle',
      'noteCount',
      'project',
      'teamId',
      'equipment',
      'costCode'
    ];
  }

  idAttribute() {
    return 'uuid';
  }

  get restAttributeDefaults() {
    return {
      status: 'INUSE',
      noteCount: 0,
      equipment: {
        projects: [],
        rate: null
      }
    };
  }

  @action.bound
  parse(attributes) {
    super.parse(attributes);
    this.parseEquipment(attributes);
    this.parseCostCode(attributes);

    return {
      ...omit(attributes, [
        'createdBy',
        'updatedBy',
        'equipment',
        'costCode',
        'project',
        'company'
      ])
    };
  }

  @action.bound
  parseCostCode(attributes) {
    if (attributes.costCode && attributes.costCode.uuid) {
      this.costCode = new CostCode(attributes.costCode, {
        rootStore: this.rootStore
      });
    } else {
      this.costCode = null;
    }
  }

  @action.bound
  parseEquipment(attributes) {
    if (attributes.equipment) {
      if (attributes.equipment.deleted || !attributes.equipment.owned) {
        this.equipment = new Equipment(attributes.equipment, {
          rootStore: this.rootStore
        });
      } else {
        this.equipment = this.rootStore.equipment.updateOrAdd(
          attributes.equipment
        );
      }
    }
  }

  @computed
  get formattedEndDate() {
    if (!this.endDate) {
      return '';
    }

    return moment(this.endDate).format('YYYY-MM-DD');
  }

  @computed
  get formattedStartDate() {
    if (!this.startDate) {
      return moment().format('YYYY-MM-DD');
    }

    return moment(this.startDate).format('YYYY-MM-DD');
  }

  @computed
  get formValues() {
    return {
      id: this.uuid ? this.uuid : null,
      equipment: {
        uuid: this.equipment.uuid,
        name: this.equipment.name,
        supplier: this.equipment.supplier,
        equipmentId: this.equipment.equipmentId
      },
      status: this.status,
      startDate: this.formattedStartDate,
      endDate: this.formattedEndDate,
      costCode: {
        uuid: this.costCode?.uuid || '',
        code: this.costCode?.code || '',
        division: this.costCode?.division || '',
        description: this.costCode?.description || ''
      }
    };
  }

  @computed get statusText() {
    switch (this.status) {
      case 'INUSE':
        return t('In use');
      case 'IDLE':
        if (this.daysIdle > 0) {
          return t('Idle ({daysIdle} day{s})', {
            templateStrings: {
              daysIdle: this.daysIdle,
              s: this.daysIdle === 1 ? '' : 's'
            }
          });
        }

        return t('Idle');
      case 'REMOVED':
        return t('Removed');
      default:
        return t('None');
    }
  }

  @computed get statusTextNoIdleDaysCount() {
    switch (this.status) {
      case 'INUSE':
        return t('In use');
      case 'IDLE':
        return t('Idle');
      case 'REMOVED':
        return t('Removed');
      default:
        return t('None');
    }
  }

  @computed get statusColor() {
    switch (this.status) {
      case 'INUSE':
        return 'goGreen';
      case 'IDLE':
        return 'lightGrey1';
      case 'REMOVED':
        return 'red';
      default:
        return 'lightGrey1';
    }
  }

  // Todo - replace the above once fully migrated
  // to athens
  @computed get athensStatusColor() {
    switch (this.status) {
      case 'INUSE':
        return 'green';
      case 'IDLE':
        return 'grey';
      case 'REMOVED':
        return 'red';
      default:
        return 'grey';
    }
  }

  @computed
  get title() {
    return t('Equipment Log');
  }

  @computed get activityTitle() {
    return `${t('submitted an')} ${this.title}`;
  }

  @computed get slug() {
    if (this.costCode) {
      return kebabCase(
        `${this.equipment.name} ${this.costCode.division} ${this.costCode.code}`
      );
    }

    return kebabCase(this.equipment.name);
  }

  @computed
  get equipmentDeleted() {
    return !this.rootStore.equipment.get(this.equipment.id);
  }

  @computed
  get costCodeDeleted() {
    return this.costCode?.deleted;
  }

  @computed
  get equipmentFormValues() {
    return this.equipment.formValues;
  }

  @computed
  get displayCostCode() {
    return this.costCode.displayCode;
  }
}
