import request from 'axios';
import debounce from 'lodash.debounce';
import { action, computed, observable, reaction } from 'mobx';
import ProjectChildUI from './../ProjectChildUI';

import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

import {
  EquipmentForm,
  equipmentFormOptions,
  equipmentFormFields,
  equipmentFormValues,
  equipmentFormRules,
  equipmentFormLabels,
  equipmentFormPlugins
} from 'forms/project/equipment';

import { callTrack } from 'utils/segmentIntegration';
import { EQUIPMENT_CREATED } from 'utils/segmentAnalytics/eventSpec';

import { BASE_DEBOUNCE } from 'fixtures/constants';

export default class EquipmentUI extends ProjectChildUI {
  @observable equipmentForm;
  @observable equipmentLogField;
  @observable equipmentLog;

  @observable equipmentNameQuery;
  @observable supplierNameQuery;

  constructor(options) {
    super(options);

    this.equipmentForm = null;
    this.equipmentLogField = null;
    this.equipmentLog = null;

    this.equipmentNames = observable([]);
    this.supplierNames = observable([]);

    this.fetchEquipmentNamesDebounced = debounce(
      this.fetchEquipmentNames,
      BASE_DEBOUNCE
    );

    this.fetchSupplierNamesDebounced = debounce(
      this.fetchSupplierNames,
      BASE_DEBOUNCE
    );

    this.equipmentNameQuery = '';
    this.supplierNameQuery = '';
  }

  @action.bound async addOrEditEquipment(field, entry) {
    await this.authorization.checkFeatureAccess('CRUDEquipmentLogs');

    this.equipmentLogField = field;
    this.equipmentLog = entry;

    this.equipmentForm = new EquipmentForm(
      {
        fields: equipmentFormFields,
        rules: equipmentFormRules,
        labels: equipmentFormLabels,
        values: Object.assign(
          equipmentFormValues,
          this.equipmentLog.equipment.formValues
        )
      },
      {
        options: equipmentFormOptions,
        plugins: equipmentFormPlugins,
        rootStore: this.rootStore
      }
    );

    this.setupReactions();

    this.showModal('EquipmentSelectorAddOrEditModal');
  }

  @computed get typeOptions() {
    let options = [
      {
        value: false,
        name: t('Rented')
      }
    ];

    if (this.equipmentLog.isNew) {
      if (this.authorization.canCRUDOwnedEquipment) {
        options.push({
          value: true,
          name: t('Owned')
        });
      }
    } else {
      options.push({
        value: true,
        name: t('Owned')
      });
    }

    return options;
  }

  @computed get hasWriteAccess() {
    if (this.equipmentForm.$('owned').value) {
      return this.authorization.canCRUDOwnedEquipment;
    }

    return true;
  }

  @computed get selectedTypeOption() {
    return this.typeOptions.find(
      option => option.value === this.equipmentForm.$('owned').value
    );
  }

  @computed get frequencyOptions() {
    return [
      {
        value: 'HOURLY',
        name: t('per hour')
      },
      {
        value: 'DAILY',
        name: t('per day')
      },
      {
        value: 'WEEKLY',
        name: t('per week')
      },
      {
        value: 'MONTHLY',
        name: t('per month')
      }
    ];
  }

  @computed get selectedFrequencyOption() {
    return this.frequencyOptions.find(
      option => option.value === this.equipmentForm.$('frequency').value
    );
  }

  @computed get addOrEditEquipmentTitle() {
    if (this.equipmentLogField.$('uuid').value) {
      return t('Edit equipment');
    }

    return t('Add equipment');
  }

  @computed get existingEquipment() {
    if (
      !Boolean(this.equipmentForm.$('owned').value === 'true') ||
      !this.equipmentForm.existingEquipment
    )
      return false;

    return (
      this.equipmentForm.existingEquipment.identificationString ===
      this.equipmentForm.identificationString
    );
  }

  @computed get equipmentExistsMessage() {
    return t('Equipment with this name and ID already exists.');
  }

  @computed get disableSaveButton() {
    return Boolean(
      this.saving ||
        this.equipmentForm.existingEquipment ||
        !this.hasWriteAccess
    );
  }

  @action.bound async cancelEquipmentAddOrEdit() {
    await this.hideActiveModal();

    this.tearDownReactions();

    this.equipmentNameQuery = '';
    this.supplierNameQuery = '';
    this.equipmentForm = null;
    this.equipmentLogField = null;
    this.equipmentLog = null;
    this.equipmentNames.clear();
    this.supplierNames.clear();
  }

  @action.bound submitEquipmentForm(event) {
    event.preventDefault();
    event.stopPropagation();

    this.equipmentForm.submit({
      onSuccess: this.submitEquipmentFormSuccess,
      onError: this.submitEquipmentFormError
    });
  }

  @action.bound async submitEquipmentFormSuccess() {
    this.saving = true;

    const values = this.equipmentForm.values();

    const payload = {
      name: values.name,
      supplier: values.supplier,
      equipmentId: values.equipmentId,
      rate: values.rate,
      frequency: values.frequency,
      owned: values.owned
    };

    let equipment;

    try {
      if (!this.equipmentLog.isNew) {
        equipment = await this.equipmentLog.equipment.save(payload, {
          url: `${this.rootStore.urlMicroService(
            'performanceTracking'
          )}/equipment/${this.equipmentLog.equipment.uuid}`,
          wait: true
        });
      } else {
        equipment = await this.equipmentSelectorUI.equipment.create(
          Object.assign(payload, {
            projectUuid: payload.owned ? null : this.project.uuid
          }),
          {
            wait: true
          }
        );
      }

      callTrack(EQUIPMENT_CREATED, {
        equipment_type: payload.owned ? 'Owned' : 'Rented',
        supplier_entered: Boolean(payload.supplier),
        id_entered: Boolean(payload.equipmentId)
      });

      this.updateExternalField(equipment);
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitEquipmentFormError() {
    console.log(this.equipmentForm.errors());
  }

  @action.bound updateExternalField(equipment) {
    // Set it on the form
    this.equipmentLogField.set({
      uuid: equipment.uuid,
      name: equipment.name,
      supplier: equipment.supplier,
      equipmentId: equipment.equipmentId,
      inUseOrIdle: '',
      activeProject: ''
    });

    this.cancelEquipmentAddOrEdit();
  }

  @action.bound setupReactions() {
    this.reactToEquipmentName = reaction(
      () => this.equipmentNameQuery,
      name => {
        this.fetchEquipmentNamesDebounced();
      }
    );

    this.reactToSupplierName = reaction(
      () => this.supplierNameQuery,
      name => {
        this.fetchSupplierNamesDebounced();
      }
    );
  }

  @action.bound tearDownReactions() {
    this.reactToEquipmentName && this.reactToEquipmentName();
  }

  @action.bound setEquipmentNameQuery(value) {
    this.equipmentNameQuery = value;
  }

  @action.bound async fetchEquipmentNames() {
    this.equipmentNames.clear();

    const response = await request.get(
      `${this.rootStore.urlMicroService(
        'performanceTracking'
      )}/equipment/names`,
      {
        params: {
          query: this.equipmentNameQuery
        }
      }
    );

    this.equipmentNames.replace(
      response.data.collection.map(name => {
        return {
          value: name,
          title: name
        };
      })
    );
  }

  @action.bound setSupplierNameQuery(value) {
    this.supplierNameQuery = value;
  }

  @action.bound async fetchSupplierNames() {
    this.supplierNames.clear();

    const response = await request.get(
      `${this.rootStore.urlMicroService(
        'performanceTracking'
      )}/equipment/suppliers`,
      {
        params: {
          query: this.supplierNameQuery
        }
      }
    );

    this.supplierNames.replace(
      response.data.collection.map(name => {
        return {
          value: name,
          title: name
        };
      })
    );
  }
}
