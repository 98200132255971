import { action, computed, reaction } from 'mobx';
import InsightsUIModel from './InsightsUIModel';
import { t } from 'utils/translate';

export default class ProjectInsightsObservationsRaisedByTeamMember extends InsightsUIModel {
  constructor(attributes, options) {
    super(attributes, options);

    // React to selection changes
    this.reactToSelection = reaction(
      () => this.params,
      params => {
        if (params) {
          this.fetchStats();
        } else {
          this.cancelRequest();
          this.insights.clear();
        }
      },
      { fireImmediately: true }
    );

    this.typeClasses = options.typeClasses;
  }

  @computed
  get params() {
    return {
      projectTeamUuids: this.parent.projectUuid,
      startDay: this.parent.startDay,
      endDay: this.parent.endDay,
      typeClasses: this.typeClasses
    };
  }

  url() {
    return '/ra/insights/observations/raisedByWorkUnit';
  }

  get restAttributes() {
    return ['insights'];
  }

  @action.bound
  fetchStats() {
    return this.fetch({
      params: this.params
    });
  }

  @computed
  get hasStats() {
    return this.insights?.length > 0;
  }

  @computed
  get chartSeries() {
    let counts = {};

    this.insights?.forEach(item => {
      item.details.forEach(detail => {
        const name = `${detail.firstName} ${detail.lastName}`;
        if (counts[name]) {
          counts[name].positiveCount += detail.positiveCount;
          counts[name].negativeCount += detail.negativeCount;
        } else {
          counts[name] = {
            positiveCount: detail.positiveCount,
            negativeCount: detail.negativeCount
          };
        }
      });
    });

    return [
      {
        name: 'Positive',
        color: '#4caf50',
        data: Object.keys(counts).map(name => ({
          y: counts[name].positiveCount,
          name: name
        }))
      },
      {
        name: 'Negative',
        color: '#F44336',
        data: Object.keys(counts).map(name => ({
          y: counts[name].negativeCount,
          name: name
        }))
      }
    ];
  }

  @computed
  get chartConfig() {
    return {
      chart: {
        type: 'bar'
      },
      title: {
        text: ''
      },
      xAxis: {
        type: 'category'
      },
      yAxis: {
        title: {
          text: null
        },
        labels: {
          formatter: function() {
            if (Math.round(this.value) !== this.value) {
              return;
            }
            return Math.abs(this.value);
          }
        },
        accessibility: {
          description: t('Number of Obervations')
        }
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        column: {
          stacking: 'normal'
        }
      },
      series: this.chartSeries
    };
  }
}
