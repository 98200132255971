import React, { useEffect, useRef, useState } from 'react';

import {
  Avatar,
  Badge,
  Box,
  Icon,
  Link,
  List,
  Navigation,
  Popover,
  SelectWithSearch,
  Tag,
  Text
} from '@raken/athens-web/lib';

import { NavLink } from 'react-router-dom';
import { DesktopOnly, MobileOnly } from 'raken-ui';

import kebabCase from 'lodash.kebabcase';
import styled from 'styled-components';
import media from 'utils/mediaTemplate';
import { Observer } from 'mobx-react';
import history from 'utils/history';
import { t } from 'utils/translate';
import { callTrack } from 'utils/segmentIntegration';

import {
  HOME_BUTTON_CLICKED,
  QUICK_PROJECT_SELECTED
} from 'utils/segmentAnalytics/eventSpec';
import AlertBanner from './AlertBanner';

const StyledNavigation = styled(Navigation)`
  .active {
    :after {
      background-color: ${({ theme }) => theme.palette.orange.main};
    }
  }
`;

const DashboardLink = styled(Link)`
  svg {
    color: ${({ theme }) => theme.palette.grey.main};
  }
  &.active {
    svg {
      color: ${({ theme }) => theme.palette.darkGrey.main};
    }
  }
`;

const HeaderWrapper = styled.div`
  min-height: 60px;
  background-color: white;
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.lightGrey.main}`};
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.04);
  position: fixed;
  width: 100%;
  z-index: 1000;
  top: 0;
  transition: top 0.3s;
`;

const Divider = styled.div`
  width: 1px;
  height: 32px;
  background-color: ${({ theme }) => theme.palette.lightGrey.main};
`;

const NavigationWrapper = styled(Box)`
  flex: 2;
  display: flex;
  ${media.desktop`
justify-content: center;
  `};
  background-color: white;
  min-height: 60px;
`;

const Wrapper = styled(Box)`
  flex: 1;
  display: flex;
  align-items: center;
`;

const StyledAvatar = styled(Avatar)`
  cursor: pointer;
`;

const DashboardIconWrapper = styled(Box)`
  height: 50px;
`;

const ListItemText = styled(Text)``;

const TagWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const ListItemLink = ({ children, handleClick, to, ...props }) => {
  const CustomLink = React.useMemo(
    () =>
      React.forwardRef((props, ref) => (
        <NavLink innerRef={ref} to={to} {...props} />
      )),
    [to]
  );

  return (
    <List.Item onClick={handleClick} button component={CustomLink} {...props}>
      {children}
    </List.Item>
  );
};

const UserNavigation = ({ uiStore, store, location }) => {
  return (
    <Observer>
      {() => {
        if (!uiStore.me.id) return null;

        return (
          <Popover
            open={Boolean(uiStore.anchorEl)}
            anchorEl={uiStore.anchorEl}
            onClose={uiStore.closeUserMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            elevation={0}
          >
            <List dataQA="user-profile">
              <ListItemLink
                dataQA="user-profile-my-profile"
                handleClick={uiStore.closeUserMenu}
                to={{
                  pathname: `${uiStore.me.viewUrl}/info`,
                  state: { previousPath: location.pathname }
                }}
              >
                <ListItemText>{t('My profile')}</ListItemText>
              </ListItemLink>

              <ListItemLink
                handleClick={uiStore.closeUserMenu}
                to={{
                  pathname: `${uiStore.me.viewUrl}/projects`,
                  state: { previousPath: location.pathname }
                }}
                dataQA="user-profile-my-projects"
              >
                <ListItemText>{t('My projects')}</ListItemText>
              </ListItemLink>
              {uiStore.authorization.canReviewAndApproveTimeCards && (
                <ListItemLink
                  handleClick={uiStore.closeUserMenu}
                  to={`/timesheets`}
                  dataQA="user-profile-time-sheets"
                >
                  <TagWrapper>
                    <ListItemText>{t('Timesheets')}</ListItemText>
                    {uiStore.rootStore.headerUI.headerNotificationCounts
                      .outstandingApprovals > 0 && (
                      <Tag
                        label={uiStore.rootStore.headerUI.headerNotificationCounts.outstandingApprovals.toString()}
                        rakenColor="grey"
                        dataQA="raken"
                      />
                    )}
                  </TagWrapper>
                </ListItemLink>
              )}
              <ListItemLink
                handleClick={uiStore.closeUserMenu}
                to={{
                  pathname: `${uiStore.me.viewUrl}/notifications`,
                  state: { previousPath: location.pathname }
                }}
                dataQA="user-profile"
              >
                <ListItemText>{t('Notifications')}</ListItemText>
              </ListItemLink>
              <ListItemLink
                handleClick={uiStore.closeUserMenu}
                to={{
                  pathname: `${uiStore.me.viewUrl}/tasks`,
                  state: { previousPath: location.pathname }
                }}
                dataQA="user-profile-tasks"
              >
                <ListItemText>{t('Tasks')}</ListItemText>
              </ListItemLink>
              <ListItemLink
                handleClick={uiStore.closeUserMenu}
                to={{
                  pathname: `${uiStore.me.viewUrl}/email-preferences`,
                  state: { previousPath: location.pathname }
                }}
                dataQA="user-profile-email-preference"
              >
                <ListItemText>{t('Email preferences')}</ListItemText>
              </ListItemLink>
              <List.Item
                button
                component="a"
                onClick={uiStore.closeUserMenu}
                href="https://help.rakenapp.com/en/"
                target="_blank"
                dataQA="user-profile-help"
              >
                <ListItemText>{t('Help')}</ListItemText>
              </List.Item>

              {store.showDebugSection && (
                <List.Item
                  button
                  onClick={() => {
                    store.debugUI.showDebugModalPage();
                    uiStore.closeUserMenu();
                  }}
                  dataQA="user-profile-debug"
                >
                  <ListItemText>{t('Debug')}</ListItemText>
                </List.Item>
              )}

              <List.Item
                button
                component="a"
                onClick={() => {
                  store.logout();
                }}
                dataQA="user-profile-log-out"
              >
                <ListItemText>{t('Log out')}</ListItemText>
              </List.Item>
            </List>
          </Popover>
        );
      }}
    </Observer>
  );
};

const MainNavigation = ({ location, match, store }) => (
  <Observer>
    {() => {
      return (
        <NavigationWrapper data-qa="nav_main">
          {!store.me.fetching && (
            <StyledNavigation>
              <Navigation.Link
                label={t('Dashboard')}
                data-qa="link_main-dashboard"
                to={'/'}
                isActive={() => {
                  return (
                    location.pathname === '/' ||
                    location.pathname === '/report-activity' ||
                    location.pathname === '/insights'
                  );
                }}
                dataQA="main-activity"
              />
              <Navigation.Link
                label={t('Projects')}
                data-qa="link_main-projects"
                to={'/projects'}
                isActive={() => {
                  return location.pathname === '/projects';
                }}
                dataQA="main-projects"
              />
              {store.authorizationUI.canViewTeam && (
                <Navigation.Link
                  label={t('Team')}
                  data-qa="link_main-team"
                  to={'/team'}
                  isActive={(match, location) => {
                    return (
                      location.pathname.match(/^\/team/g) ||
                      location.pathname.match(/^\/workers/g)
                    );
                  }}
                  dataQA="main-team"
                />
              )}
              {store.authorizationUI.canViewCompany && (
                <Navigation.Link
                  label={t('Company')}
                  data-qa="link_main-company"
                  to={'/company-settings'}
                  isActive={() => {
                    return location.pathname.match('/company-settings');
                  }}
                  dataQA="main-company"
                />
              )}
            </StyledNavigation>
          )}
        </NavigationWrapper>
      );
    }}
  </Observer>
);

const ViolationBannerWrapper = styled.div`
  margin-bottom: -20px;
`;

const Header = ({ avatarImg, uiStore, location, store }) => {
  const headerRef = useRef();
  const [prevScrollPos, setPrevScrollPosition] = useState(window.pageYOffset);
  const { headerProjectSelectorUI, messagingUI } = uiStore;

  const projectsAutocomplete = useRef();

  useEffect(() => {
    const handleScrollEvent = () => {
      let currentScrollPos = window.pageYOffset;

      if (currentScrollPos < 300 || prevScrollPos > currentScrollPos) {
        headerRef.current.style.top = '0';
        uiStore.toggleHeaderVisibleState(true);
      } else {
        //disabled for the new project page with a side drawer
        if (!window.location.pathname.includes('/projects/')) {
          headerRef.current.style.top = window.matchMedia('(max-width: 768px)')
            .matches
            ? '-110px'
            : '-60px';

          uiStore.toggleHeaderVisibleState(false);
          setPrevScrollPosition(currentScrollPos);
        }
      }
    };
    window.addEventListener('scroll', handleScrollEvent);
    return () => window.removeEventListener('scroll', handleScrollEvent);
  }, [uiStore, headerProjectSelectorUI, prevScrollPos]);

  return (
    <Observer>
      {() => (
        <HeaderWrapper ref={headerRef}>
          <Box display="flex" alignItems="center">
            <Wrapper>
              <DashboardIconWrapper
                px={4}
                display="flex"
                alignItems="center"
                onClick={() => {
                  callTrack(HOME_BUTTON_CLICKED);
                }}
              >
                <DashboardLink
                  to="/"
                  isActive={() => {
                    return !(
                      location.pathname.match('/project/') ||
                      location.pathname.match('/user-profile')
                    );
                  }}
                  dataQA="home"
                >
                  <Icon kind="home" size="big" dataQA="home-link" />
                </DashboardLink>
              </DashboardIconWrapper>

              <Divider />

              <Box maxWidth={350}>
                <SelectWithSearch
                  label={false}
                  options={headerProjectSelectorUI.projectOptionsForRender}
                  filterOptions={() => {
                    return headerProjectSelectorUI.projectOptionsForRender;
                  }}
                  onChange={(event, option) => {
                    if (option) {
                      const project = headerProjectSelectorUI.projectOptions.getProjectByOptionIdValue(
                        option.value
                      );

                      callTrack(QUICK_PROJECT_SELECTED, {
                        project_name: project.name
                      });

                      history.push(project.viewUrl);
                    }

                    headerProjectSelectorUI.resetProjectOptions();
                  }}
                  onClose={headerProjectSelectorUI.resetProjectOptions}
                  optionTitle={'name'}
                  onInputChange={(event, value, reason) => {
                    if (reason === 'clear' || reason === 'input') {
                      headerProjectSelectorUI.setProjectOptionsSearchQuery(
                        value
                      );
                    }
                  }}
                  ListboxProps={{
                    ref: projectsAutocomplete,
                    onScroll: () =>
                      headerProjectSelectorUI.fetchNextProjectOptions(
                        projectsAutocomplete
                      )
                  }}
                  renderOption={(option, { selected }) => {
                    return (
                      <Box
                        pt={1}
                        pb={1}
                        data-qa={`option_project-${kebabCase(option.name)}`}
                        display="flex"
                        alignItems="flex-start"
                        justifyContent={'space-between'}
                        width="100%"
                      >
                        <Box>
                          <Box>
                            <Text
                              dataQA={`header-project-${kebabCase(
                                option.name
                              )}-name`}
                            >
                              {option.name}
                            </Text>
                          </Box>

                          {option.projectNo && (
                            <Box>
                              <Text
                                rakenColor="grey"
                                dataQA={`header-project-${kebabCase(
                                  option.projectNo
                                )}-company`}
                              >
                                {option.projectNo}
                              </Text>
                            </Box>
                          )}
                        </Box>

                        {selected && (
                          <Box ml="auto">
                            <Icon kind="checkmark" rakenColor="grey" />
                          </Box>
                        )}
                      </Box>
                    );
                  }}
                  value={uiStore.projectUI.projectAsSelectOption}
                  lineClamp={2}
                  dropdownWidth="350px"
                  noBorders
                  removeDescriptionSpace
                  placeholder={t('Select project')}
                  searchPlaceholder={t('Search by name or job #')}
                  dataQA="header-project-name"
                  noOptionsText={t('No projects found.')}
                />
              </Box>
            </Wrapper>
            <DesktopOnly>
              <MainNavigation location={location} store={store} />
            </DesktopOnly>
            <Wrapper justifyContent="flex-end">
              <>
                {messagingUI.hasUnreadMessages && (
                  <Box mx={4}>
                    <Link
                      to={{
                        pathname: `/messaging`,
                        state: { previousPath: location.pathname }
                      }}
                    >
                      <Badge
                        badgeContent={messagingUI.unreadMessageDisplay}
                        max={9}
                        overlap="circle"
                        rakenColor="red"
                      >
                        <Icon kind="message" size="large" rakenColor="grey" />
                      </Badge>
                    </Link>
                  </Box>
                )}

                {!messagingUI.hasUnreadMessages && (
                  <Box mx={4}>
                    <Link
                      to={{
                        pathname: `/messaging`,
                        state: { previousPath: location.pathname }
                      }}
                    >
                      <Icon kind="message" size="large" rakenColor="grey" />
                    </Link>
                  </Box>
                )}

                <Divider />
              </>

              <Box mx={4}>
                {uiStore.rootStore.headerUI.hasHeaderNotifications && (
                  <Badge variant="dot" overlap="circle">
                    <StyledAvatar
                      src={avatarImg}
                      onClick={uiStore.openUserMenu}
                      dataQA="main-avatar"
                    />
                  </Badge>
                )}

                {!uiStore.rootStore.headerUI.hasHeaderNotifications && (
                  <StyledAvatar
                    src={avatarImg}
                    onClick={uiStore.openUserMenu}
                    dataQA="main-avatar"
                  />
                )}

                <UserNavigation
                  uiStore={uiStore}
                  store={store}
                  location={location}
                />
              </Box>
            </Wrapper>
          </Box>
          <MobileOnly>
            <MainNavigation location={location} store={store} />
          </MobileOnly>
          {store.me.isViolator && (
            <ViolationBannerWrapper>
              <AlertBanner
                textAlign="center"
                justifyContent="center"
                hideIcon={true}
                dataQA={'violator-banner'}
              >
                <Text bold>
                  {t(
                    'User(s) on your account have violated our licensing policy.'
                  )}
                </Text>
                <Text>
                  {t(
                    'Please contact your account Admin or call Raken at 866-438-0646 for help.'
                  )}
                </Text>
              </AlertBanner>
            </ViolationBannerWrapper>
          )}
        </HeaderWrapper>
      )}
    </Observer>
  );
};

export default Header;
