import { Model } from 'mobx-mc';
import { computed, observable } from 'mobx';

import { t } from 'utils/translate';

import capitalize from 'lodash.capitalize';

export default class TimeCardEvent extends Model {
  @observable selected;

  constructor(data, options) {
    super(data, options);

    this.selected = false;
  }

  get restAttributes() {
    return [
      'uuid',
      'timeCardUuid',
      'workLog',
      'workerDetails',
      'projectUuid',
      'eventType',
      'deviceDate',
      'latitude',
      'longitude',
      'platform',
      'timezone',
      'eventOrigin',
      'noGpsFix',
      'outOfProjectArea',
      'costCode',
      'totalHours',
      'timeCardDetails'
    ];
  }

  idAttribute() {
    return 'uuid';
  }

  @computed
  get eventTypeDisplayName() {
    switch (this.eventType) {
      case 'TIME_CARD_STARTED':
        return t('Clock In');
      case 'TIME_CARD_ENDED':
        return t('Clock Out');
      case 'BREAK_STARTED':
        return t('Break Start');
      case 'BREAK_ENDED':
        return t('Break End');
      case 'CREW_CHANGED':
        return t('Crew Changed');
      case 'TASK_CHANGED':
        return t('Task Changed');
      default:
        return capitalize(this.eventType).replace(/_/g, ' ');
    }
  }

  @computed
  get viaKiosk() {
    return this.eventOrigin === 'KIOSK';
  }

  @computed
  get formattedTotalHours() {
    const hours = Math.floor(this.totalHours);
    const minutes = Math.floor((this.totalHours - hours) * 60);

    const formattedTime = `${hours}h ${minutes}m`;
    return formattedTime;
  }

  @computed
  get formattedTimeCardTotalHours() {
    const hours = Math.floor(this.timeCardDetails.timeCardTotalHours);
    const minutes = Math.floor(
      (this.timeCardDetails.timeCardTotalHours - hours) * 60
    );

    const formattedTime = `${hours}h ${minutes}m`;
    return formattedTime;
  }

  @computed
  get showAlertIcon() {
    return this.outOfProjectArea;
  }

  @computed
  get timeCardHasOutOfProjectAreaEvent() {
    return this.timeCardDetails.outOfProjectArea;
  }

  @computed
  get timeCardHasNoGpsFixEvent() {
    return this.timeCardDetails.noGpsFix;
  }
}
