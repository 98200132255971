import orderBy from 'lodash.orderby';
import { computed } from 'mobx';

import InsightsTopUsers from './InsightsTopUsers';

export default class ProjectInsightsTopUsers extends InsightsTopUsers {
  @computed
  get project() {
    return this.parent.project;
  }

  @computed
  get params() {
    return {
      projectIds: this.project?.id,
      startDay: this.parent.startDay,
      endDay: this.parent.endDay
    };
  }

  @computed get filteredUserMeasures() {
    return this.userMeasures.models.filter(userMeasure => {
      return this.parent.filteredProjectTeams.find(projectTeam => {
        return projectTeam.company.id === userMeasure.company.id;
      });
    });
  }

  @computed
  get hasUserMeasures() {
    return this.filteredUserMeasures && this.filteredUserMeasures.length > 0;
  }

  @computed
  get sortedUserMeasures() {
    switch (this.sortField) {
      case 'name':
        return orderBy(
          this.filteredUserMeasures,
          [userMeasure => userMeasure.user.fullName.toLowerCase()],
          [this.sortDirection]
        );
      default:
        return orderBy(
          this.filteredUserMeasures,
          [userMeasure => userMeasure[this.sortField]],
          [this.sortDirection]
        );
    }
  }
}
