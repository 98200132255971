import { RakenCollection } from '../lib';
import Observation from '../models/Observation';

export default class Observations extends RakenCollection {
  model() {
    return Observation;
  }

  url() {
    return `${this.rootStore.urlMicroService('toolbox')}/companies/${
      this.rootStore.me.company.uuid
    }/observations`;
  }
}
