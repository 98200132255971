import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import media from '../utils/mediaTemplate';
import Icon from './Icon';

const getDesktopSize = props => {
  switch (props.size) {
    case 'x-large':
      return '10rem';
    case 'large':
      return '6rem';
    case 'small':
      return '2.5rem';
    case 'mini':
      return '1.5rem';
    default:
      return '4rem';
  }
};

const getMobileSize = props => {
  if (!props.responsive) {
    return getDesktopSize(props);
  }

  // For now always return small size for mobile
  return '2.5rem';
};

const getDesktopIconSize = props => {
  switch (props.size) {
    case 'x-large':
      return '6rem';
    case 'large':
      return '3rem';
    case 'small':
      return '1.2rem';
    case 'mini':
      return '0.8rem';
    default:
      return '2.2rem';
  }
};

const getMobileIconSize = props => {
  if (!props.responsive) {
    return getDesktopIconSize(props);
  }

  // For now always return small size for mobile
  return '1.2rem';
};

const getHoverBackground = props => {
  if (props.disabled && !props.solid) {
    return 'transparent';
  }

  if (props.activeColor) {
    return props.theme.colors[props.activeColor];
  }

  if (props.borderColor) {
    return props.theme.colors[props.borderColor];
  }

  if (props.hoverBackgroundColor) {
    return props.theme.colors[props.hoverBackgroundColor];
  }

  return props.theme.colors[props.color];
};

const getHoverBorder = props => {
  if (props.disabled) {
    if (props.borderColor) {
      return props.theme.colors[props.borderColor];
    }

    return props.theme.colors[props.color];
  }

  if (props.activeColor) {
    return props.theme.colors[props.activeColor];
  }

  if (props.borderColor) {
    return props.theme.colors[props.borderColor];
  }

  if (props.hoverBorderColor) {
    return props.theme.colors[props.hoverBorderColor];
  }

  if (props.hoverBackgroundColor) {
    return props.theme.colors[props.hoverBackgroundColor];
  }

  return props.theme.colors[props.color];
};

const getHoverColor = props => {
  if (props.disabled && !props.solid) {
    return props.theme.colors[props.color];
  }

  if (props.hoverColor) {
    return props.theme.colors[props.hoverColor];
  }

  if (props.color === 'white') {
    return props.theme.colors.charcoalGrey;
  }

  return props.theme.colors.white;
};

const styles = css`
  background-color: ${props =>
    props.solid || props.active
      ? props.theme.colors[props.color]
      : 'transparent'};
  border: 1px solid
    ${props =>
      props.borderColor
        ? props.theme.colors[props.borderColor]
        : props.theme.colors[props.color]};
  border-radius: 50%;
  box-sizing: border-box;
  color: ${props =>
    props.solid ? props.theme.colors.white : props.theme.colors[props.color]};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  display: ${props => (props.inline ? 'inline-flex' : 'flex')};
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  height: ${props => getMobileSize(props)};
  width: ${props => getMobileSize(props)};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  outline: 0;
  margin-left: ${props => (props.marginLeft ? props.marginLeft / 10 : 0)}rem;
  margin-right: ${props => (props.marginRight ? props.marginRight / 10 : 0)}rem;
  margin-bottom: ${props =>
    props.marginBottom ? props.marginBottom / 10 : 0}rem;
  margin-top: ${props => (props.marginTop ? props.marginTop / 10 : 0)}rem;
  padding: 0;
  position: relative;
  transition: all 0.15s ease-out;
  transition-property: box-shadow, background-color;
  svg {
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    fill: ${props =>
      props.solid || props.active
        ? props.theme.colors.white
        : props.theme.colors[props.color]};
    width: auto;
    line-height: 0;
    font-size: 0;
    height: ${props =>
      props.iconSize ? props.iconSize : getDesktopIconSize(props)};
    ${media.phone`
      height: ${props =>
        props.iconSize ? `${props.iconSize}px` : getMobileIconSize(props)};
    `};
    ${media.desktop`
      height: ${props =>
        props.iconSize ? `${props.iconSize}px` : getDesktopIconSize(props)};
    `};
  }
  &:focus {
    box-shadow: ${props =>
      props.disabled || props.active
        ? 'none'
        : `0px 1px 1px 0px ${props.shadowColor}`};
  }
  ${props =>
    !props.disableHover &&
    css`
      &:hover,
      &:focus {
        background-color: ${props => getHoverBackground(props)};
        border: 1px solid ${props => getHoverBorder(props)};
        box-shadow: ${props =>
          props.disabled || props.active
            ? 'none'
            : `0px 2px 5px 0px ${props.shadowColor}`};
        color: ${props => getHoverColor(props)};
        svg {
          fill: ${props => getHoverColor(props)};
        }
      }
    `};

  ${media.desktop`
    height: ${props => getDesktopSize(props)};
    width: ${props => getDesktopSize(props)};
  `};
`;

const Button = styled.button`
  ${styles};
`;
const Link = styled.a`
  ${styles};
`;

const CircleButton = ({ icon, tag, dataQA, ...props }) => {
  if (tag === 'a') {
    return (
      <Link data-qa={dataQA} {...props}>
        <Icon kind={icon} />
      </Link>
    );
  }

  return (
    <Button type="button" data-qa={dataQA} {...props}>
      <Icon kind={icon} />
    </Button>
  );
};

CircleButton.propTypes = {
  color: PropTypes.string.isRequired,
  borderColor: PropTypes.string,
  activeColor: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  solid: PropTypes.bool,
  active: PropTypes.bool,
  shadowColor: PropTypes.string,
  responsive: PropTypes.bool,
  iconSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hoverColor: PropTypes.string,
  hoverBorderColor: PropTypes.string,
  hoverBackgroundColor: PropTypes.string,
  tag: PropTypes.oneOf(['button', 'a']),
  inline: PropTypes.bool,
  dataQa: PropTypes.string
};

CircleButton.defaultProps = {
  color: 'darkSteel',
  size: 'default',
  disabled: false,
  solid: false,
  active: false,
  shadowColor: 'rgba(0, 0, 0, 0.5)',
  responsive: false,
  tag: 'button',
  inline: false
};

export default CircleButton;
