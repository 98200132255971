import pickBy from 'lodash.pickby';
import moment from 'moment';
import request from 'axios';
import { action, runInAction, observable, computed } from 'mobx';
import UIStore from './UIStore';
import Talks from '../collections/Talks';

import { t } from 'utils/translate';

import { callTrack } from 'utils/segmentIntegration';

import {
  TBT_REPORT_DOWNLOADED,
  TBT_REPORT_STARTED
} from 'utils/segmentAnalytics/eventSpec';

import alertErrorHandler from 'utils/alertErrorHandler';

import form, { ToolboxTalksReportForm } from 'forms/toolboxTalksReport';

export default class ToolboxTalksReportsUI extends UIStore {
  @observable loading;

  constructor(options) {
    super(options);
    this.loading = false;

    this.completedTalks = new Talks(null, {
      rootStore: this.rootStore
    });
  }

  @computed get hasCompletedTalks() {
    return this.completedTalks.hasModels;
  }

  @action.bound
  fetchCompletedTalks() {
    return this.completedTalks.fetch({
      url: `${this.urlMicroService(
        'toolbox'
      )}/tbt/company/project-talks/completed`,
      params: {
        limit: 10000
      }
    });
  }

  @action.bound async showToolboxTalksReportModal() {
    this.authorization.checkFeatureAccess('ViewToolboxTalks').then(() => {
      runInAction(async () => {
        this.showModal('toolboxTalksReport');
        callTrack(TBT_REPORT_STARTED);
        await this.initReportForm();

        if (this.project) {
          this.setProjectIncludeOption('SELECTED');
          this.selectProjects(this.projectDefaultValue);
        } else {
          this.setProjectIncludeOption('ACTIVE');
        }
      });
    });
  }

  @computed
  get projectDefaultValue() {
    if (this.project) {
      return [
        {
          value: this.project.uuid,
          name: this.project.name,
          startDate: this.project.startDate,
          endDate: this.project.endDate
        }
      ];
    }

    return [];
  }

  @action.bound async initReportForm() {
    this.loading = true;

    if (!this.hasCompletedTalks) {
      await this.fetchCompletedTalks();
    }

    this.loading = false;

    this.activeForm = new ToolboxTalksReportForm(
      {
        fields: form.fields,
        rules: form.rules,
        values: form.values,
        labels: form.labels
      },
      {
        options: form.options,
        plugins: form.plugins
      }
    );
  }

  @action.bound
  hideToolboxTalksReportModal() {
    this.hideActiveModal().then(() => {
      runInAction(() => {
        this.activeForm = null;
        this.saving = false;
        this.loading = false;
      });
    });
  }

  @action.bound
  selectProjects(selectedOptions) {
    this.activeForm.update({
      projects: selectedOptions.map(option => {
        return {
          uuid: option.value,
          name: option.name,
          startDate: option.startDate,
          endDate: option.endDate
        };
      })
    });

    this.activeForm.validate();
  }

  @computed
  get projectIncludeOptions() {
    return [
      {
        label: t('Active projects'),
        value: 'ACTIVE',
        dataQA: 'active-projects'
      },
      {
        label: t('Active and inactive projects'),
        value: 'ACTIVE_AND_INACTIVE',
        dataQA: 'active-and-inactive-projects'
      },
      {
        label: t('Selected'),
        value: 'SELECTED',
        dataQA: 'selected-projects'
      }
    ];
  }

  @action.bound
  setProjectIncludeOption(value) {
    if (value === 'ACTIVE' || value === 'ACTIVE_AND_INACTIVE') {
      this.activeForm.update({
        projects: []
      });
    }

    this.activeForm.$('projectIncludeOption').set('value', value);

    this.activeForm.validate();
  }

  @computed
  get talkOptions() {
    return this.completedTalks.models.map(talk => {
      return {
        title: talk.name,
        id: talk.id
      };
    });
  }

  @computed get selectedTalkOptions() {
    return this.talkOptions.filter(option => {
      return this.activeForm
        .$('talkIds')
        .values()
        .includes(option.id);
    });
  }

  @action.bound setTalkIds(event, values) {
    this.activeForm.update({
      talkIds: values.map(value => value.id)
    });

    this.activeForm.validate();
  }

  @computed get allTalksSelected() {
    return Boolean(
      this.activeForm.$('talkIds').size === this.talkOptions.length
    );
  }

  @action.bound toggleAllTalks() {
    if (this.allTalksSelected) {
      this.activeForm.update({
        talkIds: []
      });
    } else {
      this.activeForm.update({
        talkIds: this.talkOptions.map(option => option.id)
      });
    }

    this.activeForm.validate();
  }

  @computed get numberOfSelectedProjects() {
    return this.activeForm?.$('projects').values().length;
  }

  @computed
  get weekFirstDay() {
    return moment(new Date()).add(
      (this.company.preferences.weekFirstDay -
        moment(new Date()).isoWeekday() -
        7) %
        7,
      'days'
    );
  }

  @computed
  get weekLastDay() {
    return moment(this.weekFirstDay).add(6, 'days');
  }

  @computed
  get biWeeklyFirstDay() {
    return moment(this.weekFirstDay).subtract(7, 'days');
  }

  @computed get timeFrames() {
    const timeFrames = [
      {
        id: 'DAILY',
        fromDate: moment(new Date()),
        toDate: moment(new Date()),
        title: t('Current day'),
        projectEntire: false
      },
      {
        id: 'WEEKLY',
        fromDate: this.weekFirstDay,
        toDate: this.weekLastDay,
        title: t('Current week'),
        projectEntire: false
      },
      {
        id: 'BI_WEEKLY',
        fromDate: this.biWeeklyFirstDay,
        toDate: this.weekLastDay,
        title: t('Bi-weekly'),
        projectEntire: false
      },
      {
        id: 'MONTHLY',
        fromDate: moment(new Date()).startOf('month'),
        toDate: moment(new Date()).endOf('month'),
        title: t('Current month'),
        projectEntire: false
      },
      {
        id: 'SPECTIMEFRAME',
        title: t('Specific time frame'),
        projectEntire: false
      }
    ];

    if (this.numberOfSelectedProjects === 1) {
      timeFrames.push({
        id: 'PROJECTENTIRE',
        title: t('Entire duration of the project')
      });
    }

    return timeFrames;
  }

  @computed get selectedTimeFrameOption() {
    return this.timeFrames.find(
      timeFrame => timeFrame.id === this.activeForm.$('timeFrameType').value
    );
  }

  @action.bound
  setTimeFrame(timeFrame) {
    const fromDate = timeFrame.fromDate
      ? moment(timeFrame.fromDate).format('YYYY-MM-DD')
      : '';

    const toDate = timeFrame.toDate
      ? moment(timeFrame.toDate).format('YYYY-MM-DD')
      : '';

    this.activeForm.update({
      timeFrameType: timeFrame.id,
      fromDate: fromDate,
      toDate: toDate
    });
  }

  @action.bound setFromDate(value) {
    this.activeForm.$('fromDate').set(moment(value).format('YYYY-MM-DD'));
  }

  @action.bound setToDate(value) {
    this.activeForm.$('toDate').set(moment(value).format('YYYY-MM-DD'));
  }

  @computed get enableSubmit() {
    return (
      !this.activeForm.isPristine && this.activeForm.isValid && !this.saving
    );
  }

  @action.bound
  submitToolboxTalksReportForm(event) {
    event.preventDefault();

    if (this.saving) return;

    this.clearValidationDetails();

    this.activeForm.submit({
      onSuccess: this.submitToolboxTalksReportFormSuccess,
      onError: this.submitToolboxTalksReportFormError
    });
  }

  @action.bound async submitToolboxTalksReportFormSuccess() {
    this.saving = true;

    const values = this.activeForm.values();
    const project = values.projects[0];

    const cleanedValues = pickBy(
      values,
      value => value !== null && (value !== undefined) & (value !== '')
    );

    cleanedValues.projects = cleanedValues.projects.map(project => {
      return {
        uuid: project.uuid,
        name: project.name
      };
    });

    // If there is a single project populate start and end times
    if (project && values.timeFrameType === 'PROJECTENTIRE') {
      cleanedValues.fromDate = project.startDate;

      if (moment(project.endDate).isBefore()) {
        cleanedValues.toDate = project.endDate;
      } else {
        cleanedValues.toDate = moment().format('YYYY-MM-DD');
      }
    }

    try {
      await request.post(
        `${this.urlMicroService('toolbox')}/tbt/company/talks/report/pdf/batch`,
        cleanedValues
      );

      callTrack(TBT_REPORT_DOWNLOADED);

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Your download request has been sent.')
      });

      this.hideToolboxTalksReportModal();
    } catch (error) {
      this.saving = false;
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound submitToolboxTalksReportFormError() {
    console.error(this.activeForm.errors());
  }

  @computed get showForm() {
    return !this.loading && this.activeForm;
  }
}
