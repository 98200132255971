import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';
import { t } from 'utils/translate';

const billingInfoFormPlugins = extendedPlugins;

const billingInfoFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const billingInfoFormFields = [
  'payment_method_type',
  'first_name',
  'last_name',
  'address1',
  'city',
  'state',
  'postal_code',
  'country',
  'name_on_account',
  'account_type',
  'routing_number',
  'account_number',
  'account_number_confirmation'
];

const billingInfoFormRules = {
  payment_method_type: 'string',
  first_name: 'string|required_if:payment_method_type,CREDIT_CARD',
  last_name: 'string|required_if:payment_method_type,CREDIT_CARD',
  address1: 'required|string',
  city: 'required|string',
  state: 'required|string',
  postal_code: 'required|string',
  country: 'required|string',
  name_on_account: 'string|required_if:payment_method_type,BANK_ACCOUNT_INFO',
  account_type: 'string|required_if:payment_method_type,BANK_ACCOUNT_INFO',
  routing_number: 'string|required_if:payment_method_type,BANK_ACCOUNT_INFO',
  account_number:
    'confirmed|string|required_if:payment_method_type,BANK_ACCOUNT_INFO',
  account_number_confirmation:
    'string|required_if:payment_method_type,BANK_ACCOUNT_INFO'
};

const billingInfoFormLabels = {
  first_name: t('first name'),
  last_name: t('last name'),
  address1: t('address'),
  city: t('city'),
  state: t('state'),
  postal_code: t('zip'),
  country: t('country'),
  account_type: t('account type'),
  name_on_account: t('name on account'),
  routing_number: t('routing number'),
  account_number: t('account number'),
  account_number_confirmation: t('account number confirmation')
};

const billingInfoFormRelated = {
  account_number: ['account_number_confirmation'],
  account_number_confirmation: ['account_number']
};

class BillingInfoForm extends MobxReactForm {}

export {
  BillingInfoForm,
  billingInfoFormOptions,
  billingInfoFormFields,
  billingInfoFormRules,
  billingInfoFormLabels,
  billingInfoFormPlugins,
  billingInfoFormRelated
};
