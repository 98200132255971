import React, { Component } from 'react';
import styled, { css } from 'styled-components';

const Header = styled.h3`
  font-size: 1.4rem;
  margin: 0;
  padding: 1.5rem 1rem;
  ${props =>
    props.hide &&
    css`
      display: none;
    `}
`;

export default class SelectBoxOptionGroupHeader extends Component {
  componentDidMount() {
    const { focused, onOptionGroupHeaderFocus } = this.props;

    if (focused && onOptionGroupHeaderFocus) {
      onOptionGroupHeaderFocus();
    }
  }

  componentDidUpdate() {
    const { focused, onOptionGroupHeaderFocus } = this.props;

    if (focused && onOptionGroupHeaderFocus) {
      onOptionGroupHeaderFocus();
    } else {
      this.wrapperRef.blur();
    }
  }

  render() {
    const { children, hide } = this.props;

    return (
      <Header hide={hide} ref={ref => (this.wrapperRef = ref)}>
        {children}
      </Header>
    );
  }
}
