import styled, { css } from 'styled-components';

import media from 'utils/mediaTemplate';

export default styled.tr`
  cursor: ${props => (props.onClick ? 'pointer' : 'inherit')};
  padding: 0;
  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
      pointer-events: none;
    `};
  ${props =>
    props.selected &&
    css`
      box-shadow: 0 6px 6px -2px rgba(0, 0, 0, 0.5);
      td {
        background-color: ${props => props.theme.colors.lightGrey2};
        border-top: 2px solid ${props => props.theme.colors.goGreen};
        border-bottom: 2px solid ${props => props.theme.colors.goGreen};
      }
      td:first-child {
        border-left: 2px solid ${props => props.theme.colors.goGreen};
      }
      td:last-child {
        border-right: 2px solid ${props => props.theme.colors.goGreen};
      }
      ${media.edge`
        box-shadow: none;
      `};
    `};
`;
