import { Model } from 'mobx-mc';

export default class GroupClass extends Model {
  get restAttributes() {
    return ['uuid', 'name'];
  }

  idAttribute() {
    return 'uuid';
  }
}
