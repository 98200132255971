import { computed, action, observable, runInAction, reaction } from 'mobx';

import request from 'axios';
import moment from 'moment';
import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import debounce from 'lodash.debounce';
import UIStore from 'stores/ui/UIStore';
import { t } from 'utils/translate';
import history from 'utils/history';
import {
  MemberCertificationsForm,
  memberCertificationsFormFields,
  memberCertificationsFormOptions,
  memberCertificationsFormPlugins,
  memberCertificationsFormRules
} from 'forms/memberCertifications';
import { BASE_DEBOUNCE } from 'fixtures/constants';
import alertErrorHandler from 'utils/alertErrorHandler';

export default class CertificationMembersBulkAddUI extends UIStore {
  @observable loading;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable selectedFilterOptions = {
    statuses: [{ id: 'ACTIVE', title: 'Active' }]
  };
  @observable isAssigned;
  @observable workers;
  @observable memberCertificationsForm;

  constructor(options) {
    super(options);

    this.loading = true;

    this.searchQuery = '';
    this.pageSize = 20;
    this.page = 1;
    this.selectedMembers = observable([]);
    this.workers = [];
    this.isAssigned = null;
    this.fetchMembersDebounced = debounce(this.fetchMembers, BASE_DEBOUNCE);
  }

  @action.bound setup() {
    this.fetchMembers();
    this.setupReactions();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  @computed get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      status: this.selectedFilterOptionsAsParams.statuses,
      companyUuids: this.company?.uuid,
      withProjectMembershipLookup: 'false'
    };
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchMembersDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @action.bound
  async fetchMembers() {
    this.members.cancelRequest();
    this.members.clear();

    await this.members.fetch({
      params: pickBy(this.params, identity)
    });

    this.loading = false;
  }

  @computed
  get totalPages() {
    const totals = this.isAssigned
      ? this.memberCertificationsForm.$('memberCertifications').length
      : this.members.models.length;
    return Math.ceil(totals / this.pageSize);
  }

  @action.bound
  setAssigned(event) {
    event.preventDefault();
    this.isAssigned = true;
    this.memberCertificationsForm = new MemberCertificationsForm(
      {
        fields: memberCertificationsFormFields,
        rules: memberCertificationsFormRules,
        values: {
          memberCertifications: this.selectedMembers.map(member => {
            return {
              workerUuid: member.workerUuid,
              workerName: member.firstName + ' ' + member.lastName,
              expirationDate: null
            };
          })
        }
      },
      {
        options: memberCertificationsFormOptions,
        plugins: memberCertificationsFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound
  setSearchQuery(query) {
    this.page = 1;
    this.searchQuery = query;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound submitForm(event) {
    event.preventDefault();
    if (!this.isAssigned) {
      return;
    }
    this.memberCertificationsForm.submit({
      onSuccess: this.submitFormSuccess,
      onError: this.submitFormError
    });
  }

  @action.bound
  submitFormError() {
    console.log(this.form.errors());
  }

  @action.bound
  async submitFormSuccess() {
    this.saving = true;
    const payload = this.memberCertificationsForm
      .values()
      .memberCertifications.map(memberCertification => ({
        workerUuid: memberCertification.workerUuid,
        expirationDate: moment(memberCertification.expirationDate).format(
          'YYYY-MM-DD'
        )
      }));
    const url = `ra/companies/${this.company?.uuid}/certifications/${this.parent.entryForEdit.uuid}/batch`;

    try {
      await request.post(url, payload);
      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Certifications assigned')
      });
      this.parent.sortByLastCreated();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.cancelMemberCertificationAssign();
      this.parent.refetchMemberCertifications();
      this.saving = false;
    }
  }

  @computed get showEmptyState() {
    return !this.loading && !this.hasMembers;
  }

  @computed get showTable() {
    return !this.showEmptyState;
  }

  @computed get selectedFilterOptionsAsParams() {
    return {
      statuses: this.selectedFilterOptions.statuses
        .map(option => option.id)
        .join(',')
    };
  }

  @action.bound clearUIState() {
    this.searchQuery = '';
    this.members.clear();
    this.page = 1;
    this.loading = true;
    this.saving = false;
    this.selectedMembers.clear();
    this.workers = [];
    this.isAssigned = null;
    this.memberCertificationsForm = null;
  }

  @action.bound cancelMemberCertificationAssign() {
    history.push({
      pathname: `${this.parent.entryForEdit.viewUrl}/employees`
    });
  }

  @action.bound toggleSelectMemberCertification(event, model) {
    event.stopPropagation();
    event.preventDefault();
    const selected = this.selectedMembers.find(
      member => member.id === model.id
    );
    if (selected) {
      this.selectedMembers.remove(selected);
    } else {
      this.selectedMembers.push(model);
    }
  }

  @computed get allMemberCertificationsSelected() {
    const selected = this.selectedMembers.filter(selectedMember =>
      this.members.models.find(member => member.id === selectedMember.id)
    );
    return !!selected.length && selected.length === this.members.models.length;
  }

  @action.bound toggleSelectAllMemberCertifications() {
    const allMembers = this.members.models;
    if (this.allMemberCertificationsSelected) {
      this.selectedMembers.clear();
    } else {
      this.selectedMembers.replace(allMembers);
    }
  }

  @computed get hasSelectedMembers() {
    return this.selectedMembers.length > 0;
  }

  @computed get title() {
    return this.isAssigned
      ? t('Set certification expiration')
      : t('Select employees');
  }
}
