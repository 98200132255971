import omit from 'lodash.omit';
import orderBy from 'lodash.orderby';
import { Model } from 'mobx-mc';
import ChecklistResponses from 'stores/collections/checklists/ChecklistResponses';
import { action, computed } from 'mobx';

export default class ChecklistQuestion extends Model {
  url() {
    return `${this.rootStore.urlMicroService('toolbox')}/companies/${
      this.rootStore.me.company.uuid
    }/checklists/${this.relatedChecklist.uuid}/questions/${this.uuid}`;
  }

  get restAttributes() {
    return [
      'uuid',
      'position',
      'questionText',
      'responseType',
      'required',
      'allowMultiple',
      'attachmentGuidance'
    ];
  }

  idAttribute() {
    return 'uuid';
  }

  get restAttributeDefaults() {
    return {
      allowMultiple: false
    };
  }

  @action.bound parse(attributes) {
    this.parseChecklistResponses(attributes);

    return {
      ...omit(attributes, 'checklistResponses')
    };
  }

  @action.bound parseChecklistResponses(attributes) {
    if (this.checklistResponses) {
      this.checklistResponses.reset(attributes.checklistResponses);
    } else {
      this.checklistResponses = new ChecklistResponses(
        attributes.checklistResponses,
        {
          parent: this,
          rootStore: this.rootStore
        }
      );
    }
  }

  @computed get checklistResponse() {
    return this.checklistResponses.at(0);
  }

  @computed get checklistResponseFormValues() {
    if (this.checklistResponses.hasModels) {
      return orderBy(this.checklistResponses.models, 'position', 'asc').map(
        checklistResponse => {
          return checklistResponse.formValues;
        }
      );
    } else {
      return [
        {
          note: '',
          value: ''
        }
      ];
    }
  }

  @computed get formValues() {
    return {
      uuid: this.uuid,
      position: this.position,
      questionText: this.questionText,
      responseType: this.responseType,
      required: this.required,
      checklistResponses: this.checklistResponseFormValues,
      allowMultiple: this.allowMultiple,
      attachmentGuidance: this.attachmentGuidance
    };
  }

  @computed get relatedChecklist() {
    return this.collection.parent.relatedChecklist;
  }
}
