import { RakenCollection } from '../../lib';
import SuperAdminSalesRep from '../../models/superAdmin/SuperAdminSalesRep';

export default class SuperAdminSalesReps extends RakenCollection {
  model() {
    return SuperAdminSalesRep;
  }

  url() {
    return 'ra/sadmin/sales-representatives';
  }
}
