import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import ReactPhoneInput from 'react-phone-input-2';
import styled from 'styled-components';

import {
  FormGroup,
  FormGroupMessage,
  Label,
  InputGroup,
  Input,
  TextArea,
  Select,
  SelectBox,
  SelectBoxItem,
  SelectBoxOptionGroupHeader,
  AutoComplete,
  TimePicker,
  Light,
  Bold,
  Text
} from '.';

import { t } from 'utils/translate';

const SelectWrapper = styled.div`
  position: relative;
`;

const SelectCatchDisabledClick = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

@observer
class FormControl extends Component {
  handleRef = ref => {
    const { innerRef } = this.props;

    // Support backwards compat
    this.componentRef = ref;

    if (innerRef) {
      innerRef(this.componentRef);
    }
  };

  renderSelectOptionGroups = optionGroups => {
    return optionGroups.map(optionGroup => {
      return (
        <optgroup key={optionGroup.label} label={optionGroup.label}>
          {this.renderSelectOptions(optionGroup.options)}
        </optgroup>
      );
    });
  };

  renderSelectOptions = options => {
    return options.map(option => {
      return (
        <option key={option.value} value={option.value}>
          {t(option.name)}
        </option>
      );
    });
  };

  renderSelectBoxOptionGroups = optionGroups => {
    return optionGroups.map(optionGroup => {
      return [
        <SelectBoxOptionGroupHeader
          hide={optionGroup.hideGroupHeader}
          key={optionGroup.label}
        >
          {optionGroup.label}
        </SelectBoxOptionGroupHeader>,
        this.renderSelectBoxOptions(optionGroup.options)
      ];
    });
  };

  renderSelectBoxItemControls = option => {
    const { selectBoxItemControls } = this.props;

    if (option.disableControls) return null;

    return React.Children.map(selectBoxItemControls, (child, index) => {
      return React.cloneElement(child, {
        onClick: e => {
          child.props.onClick(e, option);
        }
      });
    });
  };

  renderWithTooltip = (element, option, optionKeyValue, subTextDisplay) => {
    return React.cloneElement(
      element,
      null,
      <SelectBoxItem
        key={option.id}
        value={option[optionKeyValue]}
        block={Boolean(option.subText)}
        disabled={option.disabled}
        controls={this.renderSelectBoxItemControls(option)}
        hide={option.hide}
      >
        <Text
          {...(subTextDisplay ? { [subTextDisplay]: true } : {})}
          marginRight={subTextDisplay !== 'block' ? 5 : 0}
        >
          {option.name}
        </Text>
        {option.subText && (
          <Text
            color="lightGrey1"
            size="10"
            {...(subTextDisplay ? { [subTextDisplay]: true } : {})}
          >
            {option.subText}
          </Text>
        )}
      </SelectBoxItem>
    );
  };

  renderSelectBoxOptions = options => {
    const {
      optionKeyValue,
      subTextDisplay,
      tooltip,
      blockSelectItems
    } = this.props;

    return options.map(option => {
      if (tooltip && option.showTooltip) {
        const element = tooltip(option);
        return this.renderWithTooltip(
          element,
          option,
          optionKeyValue,
          subTextDisplay
        );
      }

      return (
        <SelectBoxItem
          key={option.id}
          value={option[optionKeyValue]}
          block={Boolean(option.subText) || blockSelectItems}
          disabled={option.disabled}
          controls={this.renderSelectBoxItemControls(option)}
          hide={option.hide}
        >
          <Text
            color={option.color ? option.color : 'inherit'}
            {...(subTextDisplay ? { [subTextDisplay]: true } : {})}
            marginRight={subTextDisplay !== 'block' ? 5 : 0}
            ellipsis
          >
            {t(option.name)}
          </Text>
          {option.subText && (
            <Text
              color="lightGrey1"
              size="10"
              {...(subTextDisplay ? { [subTextDisplay]: true } : {})}
              ellipsis
            >
              {t(option.subText)}
            </Text>
          )}
        </SelectBoxItem>
      );
    });
  };

  renderSelectBoxChildren = () => {
    const { options, optionGroups } = this.props;

    if (optionGroups) {
      return this.renderSelectBoxOptionGroups(optionGroups);
    }

    if (options) {
      return this.renderSelectBoxOptions(options);
    }

    return null;
  };

  renderComponent = identifier => {
    const {
      form,
      fieldName,
      placeholder,
      autoFocus,
      autoSize,
      disabled,
      disableAsReadOnly,
      type,
      onBlur,
      onKeyPress,
      onKeyDown,
      onFocus,
      onClose,
      component,
      onChange,
      onClear,
      options,
      optionGroups,
      rows,
      tabIndex,
      step,
      min,
      getItemValue,
      renderItem,
      renderMenu,
      onSelect,
      footerOption,
      headerOption,
      width,
      height,
      maxLength,
      menuHeight,
      menuWidth,
      makeContainer,
      hideControls,
      bold,
      value,
      onClickDisabled,
      capitalizePlaceholder,
      disableTopOrientation,
      changeOnMount,
      ellipsis,
      isAddOnControl,
      textTransform,
      scrollRef,
      showDefaultValue
    } = this.props;

    const disableField = form.$(fieldName).disabled || disabled;

    if (component === 'timepicker') {
      return (
        <TimePicker
          fieldName={fieldName}
          form={form}
          onChange={onChange}
          value={form.$(fieldName).value}
          changeOnMount={changeOnMount}
        />
      );
    }

    if (component === 'select') {
      const SelectRender = (
        <Select
          id={form.$(fieldName).id}
          placeholder={placeholder}
          name={form.$(fieldName).id}
          value={form.$(fieldName).value}
          onChange={onChange ? onChange : form.$(fieldName).sync}
          autoFocus={autoFocus}
          disabled={disableField}
          type={type}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          tabIndex={tabIndex}
          textTransform={textTransform}
          ref={this.handleRef}
          showSelectArrow={true}
          data-qa={identifier}
          showDefaultValue={showDefaultValue}
        >
          <option key={0} value="" default>
            {placeholder}
          </option>

          {optionGroups && this.renderSelectOptionGroups(optionGroups)}

          {options && this.renderSelectOptions(options)}
        </Select>
      );

      return (form.$(fieldName).disabled || disabled) && onClickDisabled ? (
        <SelectWrapper>
          {SelectRender}
          <SelectCatchDisabledClick onClick={onClickDisabled} />
        </SelectWrapper>
      ) : (
        SelectRender
      );
    }

    if (component === 'phonenumber') {
      return (
        <ReactPhoneInput
          id={form.$(fieldName).id}
          placeholder={placeholder}
          name={form.$(fieldName).id}
          value={form.$(fieldName).value}
          onChange={(value, data) => {
            const dialCode = `+${data.dialCode}`;

            form.$(fieldName).set(value);

            value = value.replace(/[^0-9]+/g, '').slice(data.dialCode.length);
            form.$('rawPhone').set(value);
            form.$('dialCode').set(dialCode);
          }}
          disabled={disableField && !disableAsReadOnly}
          readOnly={disableField && disableAsReadOnly}
          tabIndex={tabIndex}
          innerRef={this.handleRef}
          autoSize={autoSize}
          defaultCountry="us"
          countryCodeEditable="false"
          enableSearchField
          disableAreaCodes
          enableLongNumbers
          isValid={() => true}
          inputExtraProps={{
            'data-qa': identifier,
            tabIndex: tabIndex,
            disabled: disableField && !disableAsReadOnly,
            readOnly: disableField && disableAsReadOnly,
            autoFocus: autoFocus
          }}
          inputStyle={{
            borderColor: '#E4E4E4',
            height: '40px',
            width: width ? width : '100%',
            fontSize: '1.4rem',
            color: '#4A4A4A',
            backgroundColor:
              form.$(fieldName).disabled || disabled ? '#f9f9f9' : '#fff'
          }}
          dropdownStyle={{
            zIndex: 1051,
            textAlign: 'left'
          }}
        />
      );
    }

    if (component === 'textarea') {
      return (
        <TextArea
          id={form.$(fieldName).id}
          placeholder={placeholder}
          name={form.$(fieldName).id}
          value={form.$(fieldName).value}
          onChange={form.$(fieldName).sync}
          autoFocus={autoFocus}
          disabled={disableField && !disableAsReadOnly}
          readOnly={disableField && disableAsReadOnly}
          type={type}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          rows={rows}
          tabIndex={tabIndex}
          innerRef={this.handleRef}
          autoSize={autoSize}
          height={height}
          maxLength={maxLength}
          dataQA={identifier}
          capitalizePlaceholder={capitalizePlaceholder}
        />
      );
    }

    if (component === 'autocomplete') {
      return (
        <AutoComplete
          autoFocus={autoFocus}
          disabled={disableField && !disableAsReadOnly}
          readOnly={disableField && disableAsReadOnly}
          placeholder={placeholder}
          tabIndex={tabIndex}
          items={options}
          onChange={onChange}
          value={value || form.$(fieldName).value}
          getItemValue={getItemValue}
          renderMenu={renderMenu}
          renderItem={renderItem}
          onSelect={onSelect}
          maxLength={maxLength}
          inputProps={{
            onBlur: onBlur,
            onKeyDown: onKeyDown,
            onFocus: onFocus,
            'data-qa': identifier
          }}
          innerRef={this.handleRef}
        />
      );
    }

    if (component === 'selectbox') {
      return (
        <SelectBox
          placeholder={placeholder}
          width={width}
          menuHeight={menuHeight}
          menuWidth={menuWidth}
          tabIndex={tabIndex}
          marginBottom={0}
          disableTopOrientation={disableTopOrientation}
          makeContainer={makeContainer}
          value={form.$(fieldName).value}
          onChange={onChange ? onChange : form.$(fieldName).sync}
          onClose={onClose}
          footerOption={footerOption}
          headerOption={headerOption}
          disabled={disableField}
          disableAsReadOnly={disableAsReadOnly}
          isAddOnControl={isAddOnControl}
          dataQA={identifier}
          autoFocus={autoFocus}
          innerRef={this.handleRef}
          scrollRef={scrollRef}
          onClear={onClear}
        >
          {this.renderSelectBoxChildren()}
        </SelectBox>
      );
    }

    return (
      <Input
        id={form.$(fieldName).id}
        placeholder={placeholder}
        name={form.$(fieldName).id}
        value={form.$(fieldName).value}
        onChange={onChange ? onChange : form.$(fieldName).sync}
        autoFocus={autoFocus}
        disabled={disableField && !disableAsReadOnly}
        readOnly={disableField && disableAsReadOnly}
        type={type}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyPress={onKeyPress}
        tabIndex={tabIndex}
        ref={this.handleRef}
        hideControls={hideControls}
        step={step}
        min={min}
        bold={bold}
        height={height}
        maxLength={maxLength}
        data-qa={identifier}
        capitalizePlaceholder={capitalizePlaceholder}
        ellipsis={ellipsis}
      />
    );
  };

  renderOptionalText = () => {
    const { optional } = this.props;

    if (!optional) return null;

    if (optional === 'short') {
      return (
        <Light transform="capitalize" color="lightGrey1" marginLeft={5} italic>
          {t('Opt')}
        </Light>
      );
    }

    return (
      <Light color="lightGrey1" marginLeft={5} italic>
        {t('Optional')}
      </Light>
    );
  };

  renderHelpText = identifier => {
    const {
      form,
      fieldName,
      helpText,
      helpTextAlign,
      helpTextSize,
      maxLength
    } = this.props;

    if (!helpText) return null;

    if (maxLength) {
      const charactersLeft = maxLength - form.$(fieldName).value.length;
      let color;

      if (charactersLeft > 20) {
        color = 'goGreen';
      } else if (charactersLeft > 10) {
        color = 'rakenOrange';
      } else {
        color = 'red';
      }

      return (
        <FormGroupMessage
          align={helpTextAlign}
          size={helpTextSize}
          data-qa={`text_message-${identifier}`}
        >
          {t('Characters left:')}{' '}
          <Bold color={color}>
            {maxLength - form.$(fieldName).value.length}
          </Bold>
        </FormGroupMessage>
      );
    }

    return (
      <FormGroupMessage
        align={helpTextAlign}
        size={helpTextSize}
        data-qa={`text_message-${identifier}`}
      >
        {helpText}
      </FormGroupMessage>
    );
  };

  render() {
    const {
      form,
      fieldName,
      label,
      showValidIcon,
      showErrorIcon,
      hideValidationMessages,
      errorColor,
      inputLink,
      inputLinkColor,
      onInputLinkClick,
      hideLabel,
      highlight,
      focusColor,
      noMargin,
      component,
      wrapText,
      labelAlign,
      width,
      preventShrink,
      addOn,
      isAddOnControl,
      hasAddOnControl
    } = this.props;

    let identifier;

    if (!form) return null;

    if (form.$(fieldName).label) {
      identifier = `${component}_${form
        .$(fieldName)
        .label.replace(/ +/g, '-')
        .toLowerCase()}`;
    }

    return (
      <FormGroup
        noMargin={noMargin}
        width={width}
        preventShrink={preventShrink}
      >
        {!hideLabel && (
          <Label
            htmlFor={form.$(fieldName).id}
            wrapText={wrapText}
            align={labelAlign}
          >
            {label || t(form.$(fieldName).label)}

            {this.renderOptionalText()}
          </Label>
        )}

        <InputGroup
          hasError={form.$(fieldName).hasError}
          errorColor={errorColor}
          isValid={!form.$(fieldName).isEmpty && form.$(fieldName).isValid}
          inputLink={inputLink}
          inputLinkColor={inputLinkColor}
          onInputLinkClick={onInputLinkClick}
          highlight={highlight}
          focusColor={focusColor}
          component={component}
          showValidIcon={showValidIcon}
          showErrorIcon={showErrorIcon}
          addOn={addOn}
          isAddOnControl={isAddOnControl}
          hasAddOnControl={hasAddOnControl}
        >
          {this.renderComponent(identifier)}
        </InputGroup>

        {form.$(fieldName).hasError && !hideValidationMessages && !highlight && (
          <FormGroupMessage
            errorColor={errorColor}
            hasError
            data-qa={`text_error-${identifier}`}
          >
            {t(form.$(fieldName).error, {
              customValidation: form.$(fieldName).label
            })}
          </FormGroupMessage>
        )}

        {this.renderHelpText(identifier)}
      </FormGroup>
    );
  }
}

FormControl.propTypes = {
  component: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  showValidIcon: PropTypes.bool.isRequired,
  showErrorIcon: PropTypes.bool.isRequired,
  errorColor: PropTypes.string.isRequired,
  hideValidationMessages: PropTypes.bool.isRequired
};

FormControl.defaultProps = {
  component: 'input',
  disabled: false,
  type: 'text',
  showValidIcon: true,
  showErrorIcon: true,
  errorColor: 'red',
  hideValidationMessages: false,
  menuHeight: 150,
  menuWidth: 200,
  value: '',
  optionKeyValue: 'id',
  focusColor: 'blueAccent',
  disableTopOrientation: true
};

export default FormControl;
