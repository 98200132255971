import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';

const equipmentDeploymentHistoryFiltersFormPlugins = extendedPlugins;

const equipmentDeploymentHistoryFiltersFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const equipmentDeploymentHistoryFiltersFormFields = [
  'deployedToFilters[]',
  'costCodeFilters[]',
  'operatorFilters[]',
  'statusFilters[]'
];

const equipmentDeploymentHistoryFiltersFormRules = {
  deployedToFilters: 'array',
  costCodeFilters: 'array',
  operatorFilters: 'array',
  statusFilters: 'array'
};

class EquipmentDeploymentHistoryFiltersForm extends MobxReactForm {}

export {
  EquipmentDeploymentHistoryFiltersForm,
  equipmentDeploymentHistoryFiltersFormRules,
  equipmentDeploymentHistoryFiltersFormFields,
  equipmentDeploymentHistoryFiltersFormOptions,
  equipmentDeploymentHistoryFiltersFormPlugins
};
