import { observable, action, computed } from 'mobx';

import UIStore from '../UIStore';

import InsightsToolBoxTalks from 'stores/models/InsightsToolBoxTalks';
import InsightsChecklistSummary from 'stores/models/InsightsChecklistSummary';
import InsightsChecklistCompletion from 'stores/models/InsightsChecklistCompletion';
import InsightsChecklistCompletionByWorkUnit from 'stores/models/InsightsChecklistCompletionByWorkUnit';
import InsightsChecklistFailedResponses from 'stores/models/InsightsChecklistFailedResponses';

// Observations
import InsightsPositiveNegativeObservations from 'stores/models/InsightsPositiveNegativeObservations';
import InsightsObservationsRaisedByType from 'stores/models/InsightsObservationsRaisedByType';
import InsightsObservationsRaisedByTeamMember from 'stores/models/InsightsObservationsRaisedByTeamMember';
import InsightsIssuesObservedHoursWorked from 'stores/models/InsightsIssuesObservedHoursWorked';

import { callTrack } from 'utils/segmentIntegration';
import {
  COMPANY_SAFETY_INSIGHTS_VIEWED,
  COMPANY_SAFETY_INSIGHTS_PDF
} from 'utils/segmentAnalytics/eventSpec';

export default class InsightsSafetyUI extends UIStore {
  @observable loading;

  @observable modelsSet;

  // PDF download
  @observable pdfDownload;

  constructor(options) {
    super(options);

    this.loading = true;

    this.modelsSet = false;

    // Mobile Nav
    this.mobileNavOpen = false;

    // PDF
    this.pdfDownload = false;
  }

  @computed get projects() {
    return this.parent.projects;
  }

  @computed get hasProjects() {
    return this.projects.hasModels;
  }

  @action.bound async setup() {
    this.setupModels();
    callTrack(COMPANY_SAFETY_INSIGHTS_VIEWED);
  }

  @action.bound tearDown() {
    this.tearDownModels();
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.loading = true;
    this.mobileNavOpen = false;
    this.pdfDownload = false;
  }

  @action.bound setPdfDownload(value) {
    this.pdfDownload = value;
    callTrack(COMPANY_SAFETY_INSIGHTS_PDF);
  }

  @action.bound clearPdfDownload(value) {
    this.pdfDownload = null;
  }

  @action.bound
  setupModels() {
    if (this.modelsSet) return;

    this.checklistSummary = new InsightsChecklistSummary(null, {
      typeClasses: 'Environmental,Equipment,Safety',
      rootStore: this.rootStore,
      parent: this
    });

    this.checklistCompletion = new InsightsChecklistCompletion(null, {
      typeClasses: 'Environmental,Equipment,Safety',
      rootStore: this.rootStore,
      parent: this
    });

    this.checklistCompletionByWorkUnit = new InsightsChecklistCompletionByWorkUnit(
      null,
      {
        typeClasses: 'Environmental,Equipment,Safety',
        workUnitTypes: 'WorkerClassification',
        rootStore: this.rootStore,
        parent: this
      }
    );

    this.checklistFailedResponses = new InsightsChecklistFailedResponses(null, {
      typeClasses: 'Environmental,Equipment,Safety',
      rootStore: this.rootStore,
      parent: this
    });

    this.toolBoxTalks = new InsightsToolBoxTalks(null, {
      sortField: 'ranking',
      rankField: 'numberOfActivities',
      rootStore: this.rootStore,
      parent: this
    });

    this.insightsPositiveNegativeObservations = new InsightsPositiveNegativeObservations(
      null,
      {
        parent: this,
        rootStore: this.rootStore,
        typeClasses: 'SAFETY'
      }
    );

    this.insightsObservationsRaisedByType = new InsightsObservationsRaisedByType(
      null,
      {
        parent: this,
        rootStore: this.rootStore,
        typeClasses: 'SAFETY'
      }
    );

    this.insightsObservationsRaisedByTeamMember = new InsightsObservationsRaisedByTeamMember(
      null,
      {
        parent: this,
        rootStore: this.rootStore,
        typeClasses: 'SAFETY'
      }
    );

    this.insightsIssuesObservedHoursWorked = new InsightsIssuesObservedHoursWorked(
      null,
      {
        parent: this,
        rootStore: this.rootStore,
        typeClasses: 'SAFETY'
      }
    );

    this.modelsSet = true;
  }

  @action.bound tearDownModels() {
    this.insightsChecklistSummary = null;
    this.checklistSummary = null;
    this.checklistCompletion = null;
    this.checklistCompletionByWorkUnit = null;
    this.checklistFailedResponses = null;
    this.insightsPositiveNegativeObservations = null;
    this.insightsObservationsRaisedByType = null;
    this.insightsObservationsRaisedByTeamMember = null;
    this.toolBoxTalks = null;
    this.modelsSet = false;
  }
}
