import { action, computed } from 'mobx';
import omit from 'lodash.omit';
import { Model } from 'mobx-mc';

import User from './User';

export default class ProjectMemberNotificationSettings extends Model {
  url() {
    return `/ra/projects/${this.project.id}/user/${this.user.userId}/notification-settings`;
  }

  get restAttributes() {
    return ['type', 'user', 'settings', 'project'];
  }

  @computed
  get name() {
    return this.project.name;
  }

  @action.bound
  parse(attributes) {
    if (attributes.user) {
      this.user = new User(attributes.user, {
        rootStore: this.rootStore
      });
      this.id = `${attributes.project.id}-${attributes.user.id}`;
    }

    return {
      ...omit(attributes, 'user')
    };
  }

  @computed
  get canEditSettings() {
    return (
      (this.user && this.user.isMe) ||
      this.rootStore.authorizationUI.canEditProjectNotificationSettings
    );
  }

  @action.bound
  saveSetting(name, value) {
    this.save(
      {
        settings: Object.assign(this.settings, {
          [name]: value
        })
      },

      {
        wait: true
      }
    );
  }

  @computed
  get formValues() {
    return {
      user: Object.assign({}, this.user.formValues, {
        id: this.user.id
      }),
      settings: this.settings
    };
  }
}
