import MobxReactForm from 'mobx-react-form';
import moment from 'moment';
import { t } from 'utils/translate';
import trimObject from 'utils/trimObject';
import extendedPlugins from './utils/extendedPlugins';

const incidentFormPlugins = extendedPlugins;

const incidentFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const incidentFormFields = [
  'incidentTitle',
  'status',
  'isRecordable',
  'notifications',
  'attachments',
  'incidentDate',
  'incidentTime',
  'incidentLocation',
  'injuryOrIllnessType',
  'employeeName',
  'employeeJobTitle',
  'employeeGender',
  'employeeDateOfBirth',
  'employeeDateOfHire',
  'descriptionOfInjuryOrIllness',
  'activityPriorToIncident',
  'recountOfIncident',
  'affectOnBody',
  'sourceOfHarm',
  'isTreatedInTheEmergencyRoom',
  'isHospitalized',
  'incidentOutcome',
  'daysAwayFromWork',
  'daysOnJobTransferOrRestriction',
  'dateOfDeath'
];

const incidentFormLabels = {
  incidentTitle: t('title'),
  status: t('status'),
  isRecordable: t('recordable'),
  notifications: t('notifications'),
  attachments: t('attachments'),
  incidentDate: t('date of incident'),
  incidentTime: t('time of incident'),
  incidentLocation: t('incident location'),
  injuryOrIllnessType: t('injury/illness type'),
  employeeName: t('employee name'),
  employeeJobTitle: t('employee job title'),
  employeeGender: t('gender'),
  employeeDateOfBirth: t('date of birth'),
  employeeDateOfHire: t('date of hire'),
  descriptionOfInjuryOrIllness: t('description of injury or illness'),
  activityPriorToIncident: t('activity prior to incident'),
  recountOfIncident: t('recount of incident'),
  affectOnBody: t('affect on body'),
  sourceOfHarm: t('source of harm'),
  isTreatedInTheEmergencyRoom: t('treated in the emergency room'),
  isHospitalized: t('hospitalized as an in-patient'),
  incidentOutcome: t('incident outcome'),
  daysAwayFromWork: t('days away from work'),
  daysOnJobTransferOrRestriction: t('days on job transfer/restriction'),
  dateOfDeath: t('date of death')
};

const incidentFormRules = {
  incidentTitle: 'string|required|max:255',
  status: 'string',
  isRecordable: 'boolean|required',
  incidentDate: 'required|dateCustom:YYYY-MM-DD',
  incidentTime: 'required',
  incidentLocation: 'string|max:255|oshaStandard:all',
  employeeName: 'string|max:150',
  employeeJobTitle: 'string|max:150|oshaStandard',
  employeeDateOfBirth: 'dateCustom:YYYY-MM-DD',
  employeeDateOfHire: 'dateCustom:YYYY-MM-DD',
  descriptionOfInjuryOrIllness: 'string|max:255|oshaStandard',
  activityPriorToIncident: 'string|max:255|oshaStandard',
  recountOfIncident: 'string|max:255|oshaStandard',
  affectOnBody: 'string|max:255|oshaStandard',
  sourceOfHarm: 'string|max:255|oshaStandard',
  isTreatedInTheEmergencyRoom: 'boolean',
  isHospitalized: 'boolean',
  daysAwayFromWork: 'numeric|between:0,999',
  daysOnJobTransferOrRestriction: 'numeric|between:0,999',
  dateOfDeath: `dateCustom:YYYY-MM-DD|oshaDateOfDeath:incidentOutcome,1`
};

const incidentFormValues = {
  incidentTitle: '',
  status: 'Open',
  isRecordable: false,
  notifications: [],
  attachments: [],
  incidentDate: moment().format('YYYY-MM-DD'),
  incidentTime: moment().format('HH:mm'),
  employeeName: '',
  employeeJobTitle: '',
  employeeGender: 'Undisclosed',
  descriptionOfInjuryOrIllness: '',
  activityPriorToIncident: '',
  recountOfIncident: '',
  affectOnBody: '',
  sourceOfHarm: ''
};

class IncidentForm extends MobxReactForm {
  trimmedValues() {
    return trimObject(this.values());
  }
}

export {
  IncidentForm,
  incidentFormOptions,
  incidentFormFields,
  incidentFormValues,
  incidentFormLabels,
  incidentFormRules,
  incidentFormPlugins
};
