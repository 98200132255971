import request from 'axios';
import { observable, action, computed } from 'mobx';

import UIStore from '../UIStore';
import InsightsAllTimeStatsByProject from 'stores/models/InsightsAllTimeStatsByProject';
import InsightsChartWithDateRanges from 'stores/models/InsightsChartWithDateRanges';
import InsightsTopUsers from 'stores/models/InsightsTopUsers';
import InsightsComplianceBySubContractor from 'stores/models/InsightsComplianceBySubContractor';
import InsightsComplianceByProject from 'stores/models/InsightsComplianceByProject';

import errorHandler from 'utils/errorHandler';

export default class InsightsDailyLogsUI extends UIStore {
  @observable loading;

  @observable modelsSet;

  // All charts
  @observable applySelectionsToAllCharts;
  @observable fetchingAllCharts;

  // PDF download
  @observable pdfDownload;

  constructor(options) {
    super(options);

    this.loading = true;

    this.applySelectionsToAllCharts = true;
    this.fetchingAllCharts = false;

    this.modelsSet = false;

    // Mobile Nav
    this.mobileNavOpen = false;

    // PDF
    this.pdfDownload = false;
  }

  @computed get projects() {
    return this.parent.projects;
  }

  @computed get hasProjects() {
    return this.projects.hasModels;
  }

  @action.bound async setup() {
    this.setupModels();
  }

  @action.bound tearDown() {
    this.tearDownModels();
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.loading = true;
    this.applySelectionsToAllCharts = true;
    this.fetchingAllCharts = false;
    this.mobileNavOpen = false;
    this.pdfDownload = false;
  }

  @action.bound setPdfDownload(value) {
    this.pdfDownload = value;
  }

  @action.bound clearPdfDownload(value) {
    this.pdfDownload = null;
  }

  @action.bound
  setupModels() {
    if (this.modelsSet) return;

    this.allTimeStats = new InsightsAllTimeStatsByProject(null, {
      rootStore: this.rootStore,
      parent: this
    });

    this.workersByProject = new InsightsChartWithDateRanges(null, {
      chartProjectSelection: 'RECENT',
      chartProjectSelectionLimit: 5,
      withAverageLine: true,
      chartStat: 'workers',
      rootStore: this.rootStore,
      parent: this
    });

    this.topUsers = new InsightsTopUsers(null, {
      sortField: 'ranking',
      rankField: 'numberOfActivities',
      rootStore: this.rootStore,
      parent: this
    });

    this.complianceBySubContractor = new InsightsComplianceBySubContractor(
      null,
      {
        sortField: 'ranking',
        rankField: 'missedDailies',
        rootStore: this.rootStore,
        parent: this
      }
    );

    this.hoursByProject = new InsightsChartWithDateRanges(null, {
      chartProjectSelection: 'RECENT',
      chartProjectSelectionLimit: 5,
      withAverageLine: true,
      chartStat: 'hours',
      rootStore: this.rootStore,
      parent: this
    });

    this.complianceByProject = new InsightsComplianceByProject(null, {
      sortField: 'ranking',
      rankField: 'dailyReports',
      rootStore: this.rootStore,
      parent: this
    });

    this.projectDelays = new InsightsChartWithDateRanges(null, {
      chartProjectSelection: 'RECENT',
      chartProjectSelectionLimit: 5,
      withAverageLine: false,
      chartStat: 'delays',
      rootStore: this.rootStore,
      parent: this
    });

    this.safetyIncidents = new InsightsChartWithDateRanges(null, {
      chartProjectSelection: 'RECENT',
      chartProjectSelectionLimit: 5,
      withAverageLine: false,
      chartStat: 'safetyIncidents',
      rootStore: this.rootStore,
      parent: this
    });

    this.modelsSet = true;
  }

  @action.bound tearDownModels() {
    this.allTimeStats = null;
    this.workersByProject = null;
    this.topUsers = null;
    this.complianceBySubContractor = null;
    this.hoursByProject = null;
    this.complianceByProject = null;
    this.projectDelays = null;
    this.safetyIncidents = null;
    this.modelsSet = false;
  }

  @action.bound
  fetchStatsForAllCharts() {
    if (this.fetchingAllCharts || this.projects.fetching || !this.modelsSet)
      return;

    this.fetchingAllCharts = true;

    return request
      .get('/ra/insights/statsByProject', {
        params: Object.assign({}, this.hoursByProject.params, {
          include: 'workers, hours, safetyIncidents, delays'
        })
      })
      .then(response => {
        this.parseStatsForAllCharts(response.data);
        this.fetchingAllCharts = false;
      })
      .catch(error => {
        this.fetchingAllCharts = false;
        errorHandler(error, this.rootStore.notificationsUI.pushError);
      });
  }

  @action.bound
  parseStatsForAllCharts(attributes) {
    if (!this.modelsSet) return;

    this.hoursByProject.parse(attributes);
    this.workersByProject.parse(attributes);
    this.projectDelays.parse(attributes);
    this.safetyIncidents.parse(attributes);
  }

  @action.bound
  toggleApplySelectionsToAllCharts(activeChildModel) {
    this.applySelectionsToAllCharts = !this.applySelectionsToAllCharts;

    if (this.applySelectionsToAllCharts) {
      this.replaceSelectedProjectsOnAllCharts(activeChildModel);

      if (activeChildModel.withAverageLine) {
        this.replaceSelectedAverageLineProjectsOnAllCharts(activeChildModel);
      }
    }
  }

  @action.bound
  setDefaultSelectionsOnAllCharts() {
    if (!this.modelsSet) return;

    this.setAverageLineSelectionOnAllCharts('CHART');
    this.setChartProjectSelectionOnAllCharts('RECENT');
  }

  @action.bound
  setChartProjectSelectionOnAllCharts(selection) {
    this.workersByProject.chartProjectSelection = selection;
    this.hoursByProject.chartProjectSelection = selection;
    this.projectDelays.chartProjectSelection = selection;
    this.safetyIncidents.chartProjectSelection = selection;
  }

  @action.bound
  clearChartProjectSelectionOnAllCharts() {
    this.workersByProject.selectedChartProjects.clear();
    this.hoursByProject.selectedChartProjects.clear();
    this.projectDelays.selectedChartProjects.clear();
    this.safetyIncidents.selectedChartProjects.clear();
  }

  @action.bound
  pushChartProjectToAllCharts(project) {
    const charts = [
      this.workersByProject,
      this.hoursByProject,
      this.projectDelays,
      this.safetyIncidents
    ];

    charts.forEach(chart => {
      if (
        !chart.atChartProjectSelectionLimit &&
        !chart.selectedChartProjects.includes(project)
      ) {
        chart.selectedChartProjects.push(project);
      }
    });
  }

  @action.bound
  removeChartProjectFromAllCharts(project) {
    this.workersByProject.selectedChartProjects.remove(project);
    this.hoursByProject.selectedChartProjects.remove(project);
    this.projectDelays.selectedChartProjects.remove(project);
    this.safetyIncidents.selectedChartProjects.remove(project);
  }

  @action.bound
  replaceSelectedProjectsOnAllCharts(activeChildModel) {
    const charts = [
      this.workersByProject,
      this.hoursByProject,
      this.safetyIncidents,
      this.projectDelays
    ];

    charts.forEach(chart => {
      if (chart !== activeChildModel) {
        chart.chartProjectSelection = activeChildModel.chartProjectSelection;
        chart.selectedChartProjects.replace(
          activeChildModel.selectedChartProjects.slice()
        );
      }
    });
  }

  @action.bound
  setAverageLineSelectionOnAllCharts(selection, activeChildModel) {
    if (selection === 'SELECTED') {
      this.workersByProject.averageLineSelectedProjects.replace(
        activeChildModel.selectedChartProjects.slice(0, 5)
      );

      this.hoursByProject.averageLineSelectedProjects.replace(
        activeChildModel.selectedChartProjects.slice(0, 5)
      );
    }

    this.workersByProject.averageLineSelection = selection;
    this.hoursByProject.averageLineSelection = selection;
  }

  @action.bound
  pushAverageLineProjectToAllCharts(project) {
    this.workersByProject.averageLineSelectedProjects.push(project);
    this.hoursByProject.averageLineSelectedProjects.push(project);
  }

  @action.bound
  removeAverageLineProjectFromAllCharts(project) {
    this.workersByProject.averageLineSelectedProjects.remove(project);
    this.hoursByProject.averageLineSelectedProjects.remove(project);
  }

  @action.bound
  replaceSelectedAverageLineProjectsOnAllCharts(activeChildModel) {
    if (this.workersByProject !== activeChildModel) {
      this.workersByProject.averageLineSelection =
        activeChildModel.averageLineSelection;

      this.workersByProject.averageLineSelectedProjects.replace(
        activeChildModel.averageLineSelectedProjects.slice()
      );
    }

    if (this.hoursByProject !== activeChildModel) {
      this.hoursByProject.averageLineSelection =
        activeChildModel.averageLineSelection;

      this.hoursByProject.averageLineSelectedProjects.replace(
        activeChildModel.averageLineSelectedProjects.slice()
      );
    }
  }
}
