import debounce from 'lodash.debounce';
import request from 'axios';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import ProjectChildUI from './../ProjectChildUI';

import EquipmentLogs from 'stores/collections/equipment/EquipmentLogs';
import EquipmentLogAddUI from './EquipmentLogAddUI';
import EquipmentLogEditUI from './EquipmentLogEditUI';

import { BASE_DEBOUNCE } from 'fixtures/constants';

import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import history from 'utils/history';

export default class EquipmentLogsUI extends ProjectChildUI {
  @observable sortField;
  @observable sortDirection;
  @observable pageSize;
  @observable page;
  @observable loading;
  @observable selectedEquipmentLog;
  @observable rollingOver;

  constructor(options) {
    super(options);

    this.loading = true;
    this.sortField = 'date';
    this.sortDirection = 'desc';
    this.page = 1;
    this.pageSize = 10;

    this.selectedEquipmentLog = null;
    this.rollingOver = false;

    this.equipmentLogs = new EquipmentLogs(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.equipmentLogAddUI = new EquipmentLogAddUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.equipmentLogEditUI = new EquipmentLogEditUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchEquipmentLogsDebounced = debounce(
      this.fetchEquipmentLogs,
      BASE_DEBOUNCE
    );
  }

  @action.bound setup() {
    this.setupReactions();
    this.fetchEquipmentLogs();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchEquipmentLogsDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      sortField: this.sortField,
      sortDirection: this.sortDirection.toUpperCase(),
      projectUuids: this.projectUuid
    };
  }

  @computed get entryForEdit() {
    return this.parent.entryForEdit;
  }

  @computed get hasRequiredParams() {
    return this.entryForEdit?.uuid;
  }

  @action.bound async fetchEquipmentLogs() {
    if (!this.hasRequiredParams) return;

    this.equipmentLogs.cancelRequest();
    this.equipmentLogs.reset();

    try {
      await this.equipmentLogs.fetch({
        url: `${this.entryForEdit.url()}/logs`,
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasEquipmentLogs() {
    return this.equipmentLogs.hasModels;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.equipmentLogs.totalElements / this.pageSize);
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.equipmentLogs.clear();
    this.page = 1;
    this.loading = true;
    this.saving = false;
    this.sortField = 'date';
    this.sortDirection = 'desc';
    this.selectedEquipmentLog = null;
  }

  getEquipmentLogActions = equipmentLog => {
    return [
      {
        title: t('Edit'),
        onClick: () => {
          this.editEquipmentLog(equipmentLog);
        }
      },
      {
        title: t('Delete'),
        onClick: () => {
          this.deleteEquipmentLog(equipmentLog);
        }
      }
    ];
  };

  @action.bound async deleteEquipmentLog(equipmentLog) {
    await this.authorization.checkFeatureAccess('CRUDEquipmentLogs');

    this.selectedEquipmentLog = equipmentLog;
    this.showModal('DeleteModal');
  }

  @action.bound async cancelDeleteEquipmentLog() {
    await this.hideActiveModal();

    this.selectedEquipmentLog = null;
  }

  @action.bound async confirmDeleteEquipmentLog() {
    this.saving = true;

    try {
      await this.selectedEquipmentLog.destroy({ wait: true });

      this.refetchEquipmentLogs();

      this.entryForEdit.logCount--;

      await this.hideActiveModal();

      this.selectedEquipmentLog = null;

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Log deleted')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound sortByLastCreated() {
    this.sortField = 'date';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchEquipmentLogsDebounced();
  }

  @action.bound refetchEquipmentLogs() {
    this.loading = true;

    if (!this.hasEquipmentLogs) {
      this.setPage(null, 1);
      this.fetchEquipmentLogs();
    } else {
      this.fetchEquipmentLogs();
    }
  }

  @action.bound async addEquipmentLog() {
    await this.authorization.checkFeatureAccess('CRUDEquipmentLogs');

    // Prevent discard changes modal on the parent Equipment Log
    this.parent.unblockHistory();

    history.push({
      pathname: `${this.project.viewUrl}/equipment/${this.entryForEdit.uuid}/logs/add`,
      search: this.baseQueryParams
    });
  }

  @action.bound async editEquipmentLog(equipmentLog) {
    await this.authorization.checkFeatureAccess('CRUDEquipmentLogs');

    // Prevent discard changes modal on the parent Equipment Log
    this.parent.unblockHistory();

    history.push({
      pathname: `${this.project.viewUrl}/equipment/${this.entryForEdit.uuid}/logs/${equipmentLog.uuid}`,
      search: this.baseQueryParams
    });
  }

  // Roll over
  @action.bound async showRollOverModal() {
    this.showModal('EquipmentLogsRollOverModal');
  }

  @action.bound
  async confirmRollOver() {
    this.rollingOver = true;

    const url = `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/companies/${this.rootStore.me.company.uuid}/equipment/deployments/${
      this.entryForEdit.uuid
    }/logs/rollover`;

    try {
      await request.post(url, this.reportParams);

      this.hideActiveModal();

      this.refetchEquipmentLogs();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Work logs rolled over')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.rollingOver = false;
    }
  }
}
