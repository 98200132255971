export const getIntercom = () => {
  return new Promise((resolve, reject) => {
    //works on alpha-priv and prod. doesn't work in develop
    const elements = document.getElementsByClassName(
      'intercom-lightweight-app'
    );
    if (elements.length > 0) {
      const intercom = elements[0];
      return resolve(intercom);
    } else {
      //try to get Intercom every 1 second
      const intervalId = setInterval(() => {
        const elements = document.getElementsByClassName(
          'intercom-lightweight-app'
        );
        if (elements.length > 0) {
          const intercom = elements[0];
          clearTimeout(timeoutId);
          clearInterval(intervalId);
          resolve(intercom);
        }
      }, 500);

      //if no Intercom within 10 seconds, clear interval and return reject()
      const timeoutId = setTimeout(() => {
        clearInterval(intervalId);
        reject();
      }, 10000);
    }
  });
};

export const hideIntercom = async () => {
  try {
    const intercom = await getIntercom();
    intercom.style.display = 'none';
  } catch (error) {
    console.log('No Intercom found');
  }
};

export const showIntercom = async () => {
  try {
    const intercom = await getIntercom();
    intercom.style.display = 'block';
  } catch (error) {
    console.log('No Intercom found');
  }
};
