import { RakenCollection } from '../lib';
import Talk from '../models/Talk';
import { computed, action } from 'mobx';
import request from 'axios';
import moment from 'moment';
import errorHandler from 'utils/errorHandler';
import getMimeType from 'utils/getMimeType';
import Project from '../models/Project';

export default class Talks extends RakenCollection {
  constructor(data, options) {
    super(data, options);
    this.latestNewRelease;
  }

  model() {
    return Talk;
  }

  url() {
    if (
      this.parent &&
      (this.parent instanceof Project || this.parent.project)
    ) {
      return `${this.rootStore.urlMicroService('toolbox')}/tbt/projects/${this
        .parent.uuid || this.parent.project.uuid}/talks`;
    }

    return `${this.rootStore.urlMicroService('toolbox')}/tbt/company/talks`;
  }

  @computed
  get selectedTalks() {
    return this.models.filter(talk => talk.selected);
  }

  @computed
  get hasTalks() {
    return this.hasModels;
  }

  checkIfTalkExists = talkName => {
    return new Promise((resolve, reject) => {
      request
        .get(`${this.url()}?name=${encodeURIComponent(talkName)}`)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  };

  @action.bound
  async checkIfNewTalksUploaded() {
    const url =
      this.rootStore.urlMicroService('toolbox') + '/tbt/company/talks';
    try {
      const response = await request.get(url, {
        params: {
          hasNewTalks: true,
          sortDirection: 'DESC',
          searchQuery: '',
          page: 0,
          limit: 1
        }
      });

      this.latestNewRelease = response.data.collection.find(
        talk => talk.newRelease
      );

      return this.latestNewRelease
        ? this.latestNewRelease.createdTimestamp
        : false;
    } catch (err) {
      return false;
    }
  }

  @action.bound
  async upload(progressCallback, file) {
    const lastModified = moment(
      file.lastModified || file.lastModifiedDate.getTime()
    ).toISOString();

    const type = await getMimeType(file);

    return new Promise((resolve, reject) => {
      request
        .post(`/ra/media/request-upload`, {
          originalFileName: file.name,
          lastModifiedDateTime: lastModified,
          contentType: `${type}`,
          contentLength: file.size,
          attachmentType: 'TBT_DOCUMENT',
          withContentDisposition: true
        })
        .then(signedUrlResponse => {
          if (signedUrlResponse.data) {
            request
              .put(signedUrlResponse.data.url, file, {
                onUploadProgress: progressEvent => {
                  let percentCompleted = Math.floor(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );

                  if (progressCallback) {
                    progressCallback(percentCompleted);
                  }
                },
                transformRequest: [
                  function(data, headers) {
                    headers.put['Content-Type'] = `${type}`;

                    Object.keys(signedUrlResponse.data.customHeaders).forEach(
                      key => {
                        headers.put[key] =
                          signedUrlResponse.data.customHeaders[key];
                      }
                    );

                    return data;
                  }
                ]
              })
              .then(
                response => {
                  request
                    .post(`/ra/media/`, {
                      s3ObjectKeys: [signedUrlResponse.data.key],
                      attachmentType: 'TBT_DOCUMENT'
                    })
                    .then(async response => {
                      if (response.data) {
                        const media = response.data.collection[0];
                        const uploadedTalk = await this.create(
                          {
                            name: media.fileName,
                            document: {
                              contentUrl: media.contentUrl
                            }
                          },
                          {
                            wait: true,
                            unshift: true
                          }
                        );

                        const talk = this.get(uploadedTalk.uuid);

                        talk.new = true;
                        talk.document.fileSize = media.fileSize;
                        talk.document.contentType = media.contentType;
                        talk.document.contentUrl = media.contentUrl;

                        resolve(talk);
                      }
                    })
                    .catch(error => {
                      errorHandler(
                        error,
                        this.rootStore.notificationsUI.pushError
                      );

                      reject(error);
                    });
                },
                error => {
                  errorHandler(error, this.rootStore.notificationsUI.pushError);
                  reject(error);
                }
              );
          }
        });
    });
  }
}
