import styled from 'styled-components';
import media from '../utils/mediaTemplate';

export default styled.div`
  opacity: ${props => (props.disabled ? 0.25 : 1)};
  pointer-events: ${props => (props.disabled ? 'none' : 'all')};
  width: 100%;
  margin: 2rem 0 10px 0;
  position: relative;
  z-index: 10;
  ${media.desktop`
    margin: 40px 0 10px 0;
  `};
`;
