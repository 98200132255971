import { reaction } from 'mobx';

export default (uiStore, form, addressField = 'address') => {
  return reaction(
    () => uiStore.searchedPlace,
    searchedPlace => {
      if (!searchedPlace) {
        form.select(addressField).reset();
      } else {
        form.update(
          {
            [addressField]: {
              streetAddress: searchedPlace.streetAddress,
              city: searchedPlace.city,
              state:
                searchedPlace.country === 'US'
                  ? uiStore.stateOptions.find(
                      option => option.name === searchedPlace.state
                    )?.value
                  : searchedPlace.state,
              postCode: searchedPlace.postCode,
              country: searchedPlace.country
            }
          },
          { validate: false }
        );
      }
    }
  );
};
