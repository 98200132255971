import { computed } from 'mobx';
import { RakenCollection } from 'stores/lib';
import SageIntacctProject from 'stores/models/integrations/SageIntacctProject';

export default class SageIntacctProjects extends RakenCollection {
  url() {
    return `${this.rootStore.urlMicroService('integrations')}/companies/${
      this.company.uuid
    }/integrations/1024/project`;
  }

  model() {
    return SageIntacctProject;
  }

  @computed get company() {
    if (this.rootStore.isSuperAdmin) {
      return this.parent.activeCompany;
    }

    return this.rootStore.me.company;
  }
}
