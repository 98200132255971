import { action, computed } from 'mobx';
import ProjectFormsUI from 'stores/ui/project/forms/FormsUI';
import { t } from 'utils/translate';
import history from 'utils/history';

export default class FormsUI extends ProjectFormsUI {
  @computed
  get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      sortField: this.sortField,
      sortDirection: this.sortDirection
    };
  }

  @computed get hasRequiredParams() {
    return true;
  }

  @action.bound async editForm(form) {
    history.push({
      pathname: `${form.relatedProject.viewUrl}/forms/${form.uuid}`
    });
  }

  getFormActions = form => {
    let actions = [
      {
        title: this.hasWriteAccess ? t('Edit') : t('View'),
        onClick: () => {
          this.editForm(form);
        }
      },
      {
        title: t('Download'),
        onClick: () => {
          this.formActionsUI.downloadForm(form);
        }
      },
      {
        title: t('Share'),
        onClick: () => {
          this.formActionsUI.shareForm(form);
        }
      }
    ];

    if (this.hasWriteAccess) {
      actions = actions.concat([
        {
          title: t('Delete'),
          onClick: () => {
            this.formActionsUI.deleteForm(form);
          }
        }
      ]);
    }

    return actions;
  };
}
