import orderBy from 'lodash.orderby';
import { observable, computed, action, runInAction, toJS } from 'mobx';
import UIStore from './UIStore';

import {
  MaterialForm,
  materialFormOptions,
  materialFormFields,
  materialFormRules,
  materialFormLabels,
  materialFormPlugins
} from 'forms/material';

import { t } from 'utils/translate';

export default class MaterialsUI extends UIStore {
  @observable materialForm;
  @observable activeMaterialLogForm;

  constructor(options) {
    super(options);

    // Creating
    this.materialForm = null;

    // Selecting Materials
    this.activeMaterialLogForm = null;
  }

  @action.bound
  clearUIState() {
    this.activeModal = null;
    this.materialForm = null;
    this.activeMaterialLogForm = null;
  }

  @computed get sortedMaterials() {
    return orderBy(
      this.project.materials,
      [material => material.name.toLowerCase()],
      ['asc']
    );
  }

  @action.bound
  fetchMaterials() {
    if (this.rootStore.materials.fetching) return;

    if (this.authorization.canCRUDMaterialLogs) {
      this.rootStore.materials.fetch({
        params: {
          limit: 10000,
          projectUuids: [this.project.uuid]
        },
        add: true,
        remove: false,
        update: true
      });
    }
  }

  @action.bound
  showCreateMaterialModal(materialLogForm) {
    return this.authorization
      .checkFeatureAccess('CRUDMaterialLogs')
      .then(() => {
        runInAction(() => {
          this.materialLogUI.showModal('createMaterial');

          this.unitsUI.fetchUnits();

          this.activeMaterialLogForm = materialLogForm;

          this.materialForm = new MaterialForm(
            {
              fields: materialFormFields,
              rules: materialFormRules,
              labels: materialFormLabels
            },
            {
              options: materialFormOptions,
              plugins: materialFormPlugins,
              rootStore: this.rootStore
            }
          );
        });
      });
  }

  @action.bound
  submitMaterialForm(e) {
    e.preventDefault();
    e.stopPropagation();

    this.materialForm.submit({
      onSuccess: this.submitMaterialFormSuccess,
      onError: this.submitMaterialFormError
    });
  }

  @action.bound
  async submitMaterialFormSuccess() {
    this.saving = true;

    const values = this.materialForm.trimmedValues();
    let material = this.materialForm.existingMaterial;

    if (material) {
      material.projects.push({
        uuid: this.project.uuid
      });

      await material.save(
        {
          projects: toJS(material.projects)
        },
        {
          wait: true
        }
      );
    } else {
      material = await this.rootStore.materials.create(
        {
          name: values.name,
          unit: {
            uuid: values.unit
          },
          isDefault: false,
          projects: [
            {
              uuid: this.project.uuid
            }
          ]
        },
        {
          wait: true
        }
      );
    }

    this.activeMaterialLogForm.addMaterial(material);
    this.cancelCreateMaterial();
  }

  @action.bound
  submitMaterialFormError() {
    console.log(this.materialForm.errors());
  }

  @action.bound
  cancelCreateMaterial() {
    this.materialLogUI.hideActiveModal().then(() => {
      runInAction(() => {
        this.saving = false;
        this.materialForm = null;
        this.activeMaterialLogForm = null;
      });
    });
  }

  @computed get materialExistsInProject() {
    if (this.saving) return false;

    return (
      this.materialForm.existingMaterial &&
      this.materialForm.existingMaterial.getProjectByUuid(this.project.uuid)
    );
  }

  @computed
  get materialFormIsInValid() {
    if (this.materialExistsInProject) return true;

    return Boolean(
      this.materialForm.$('name').check('hasError') ||
        this.materialForm.$('name').check('isPristine') ||
        this.materialForm.$('unit').check('hasError') ||
        this.materialForm.$('unit').check('isPristine')
    );
  }

  @action.bound softDeleteMaterial(material) {
    let originalIndex;

    if (material.hasMultipleProjects || material.isDefault) {
      material.removeProjectByUuid(this.project.uuid, {
        save: false
      });

      this.rootStore.notificationsUI.pushNotification({
        onUndo: () => {
          material.addProjectByUuid(this.project.uuid, {
            save: false
          });
        },
        onDismiss: () => {
          material.removeProjectByUuid(this.project.uuid, {
            save: true
          });
        },
        title: t('Material Disabled')
      });
    } else {
      originalIndex = material.collection.models.indexOf(material);

      material.collection.remove(material);

      this.rootStore.notificationsUI.pushNotification({
        onUndo: () => {
          this.cancelDeleteMaterial(material, originalIndex);
        },
        onDismiss: () => {
          this.confirmDeleteMaterial(material);
        },
        title: t('Material Removed')
      });
    }
  }

  @action.bound
  cancelDeleteMaterial(material, index) {
    this.rootStore.materials.add(material, {
      at: index
    });
  }

  @action.bound
  confirmDeleteMaterial(material) {
    material.destroy();
  }
}
