import { RakenCollection } from '../lib';
import ActivityWorkLog from '../models/ActivityWorkLog';
import Note from '../models/Note';
import Task from '../models/Task';
import SurveyActivity from '../models/SurveyActivityNew';
import AttachmentActivity from '../models/AttachmentActivity';
import MaterialLogActivity from '../models/MaterialLogActivity';
import EquipmentLogActivity from '../models/EquipmentLogActivity';
import EquipmentLogNoteActivity from '../models/EquipmentLogNoteActivity';

export default class Activity extends RakenCollection {
  model(type) {
    switch (type) {
      case 'CREW_WORK_LOG':
      case 'STANDARD_WORK_LOG':
        return ActivityWorkLog;
      case 'MATERIAL_LOG':
        return MaterialLogActivity;
      case 'EQUIPMENT_LOG':
        return EquipmentLogActivity;
      case 'EQUIPMENT_LOG_NOTE':
        return EquipmentLogNoteActivity;
      case 'TASK':
        return Task;
      case 'NOTE':
        return Note;
      case 'SURVEY':
        return SurveyActivity;
      case 'ATTACHMENT':
        return AttachmentActivity;
      default:
        return ActivityWorkLog;
    }
  }

  url() {
    return `${this.rootStore.urlMicroService('dashboard')}/companies/${
      this.rootStore.me.company.uuid
    }/dashboard/search`;
  }
}
