import { computed } from 'mobx';
import { RakenCollection } from '../lib';
import ObservationField from '../models/ObservationField';

export default class ObservationFields extends RakenCollection {
  model() {
    return ObservationField;
  }

  url() {
    return `${this.rootStore.urlMicroService('toolbox')}/companies/${
      this.rootStore.me.company.uuid
    }/observations/fields`;
  }

  @computed
  get type() {
    return this.models.find(field => field.name === 'Type');
  }

  @computed
  get status() {
    return this.models.find(field => field.name === 'Status');
  }

  @computed
  get priority() {
    return this.models.find(field => field.name === 'Priority');
  }

  @computed
  get location() {
    return this.models.find(field => field.name === 'Location');
  }

  @computed
  get description() {
    return this.models.find(field => field.name === 'Description');
  }

  @computed
  get assignees() {
    return this.models.find(field => field.name === 'Assignee');
  }

  @computed
  get emails() {
    return this.models.find(
      field => field.name === 'Team Member Notifications'
    );
  }

  @computed
  get observationFieldsFormRules() {
    return {
      type: this.type?.mandatory ? 'required' : '',
      subType: this.type?.mandatory ? 'required' : '',
      priority: this.priority?.mandatory ? 'required' : '',
      status: this.status?.mandatory ? 'required' : '',
      location: this.location?.mandatory ? 'required' : '',
      description: this.description?.mandatory
        ? 'required|max:1000'
        : 'max:1000',
      emails: this.emails?.mandatory ? 'required' : '',
      assignee: 'required_with:action|required_with:dueDate',
      dueDate: 'dateCustom:YYYY-MM-DD',
      action: 'max:500',
      'assignees[].assignee': this.assignees?.mandatory ? 'required' : '',
      'assignees[].dueDate': 'dateCustom:YYYY-MM-DD',
      'assignees[].action': 'max:500'
    };
  }
}
