import without from 'lodash.without';

export default (form, field, value) => {
  const values = form.$(field).values();
  const selected = values.includes(value);

  if (selected) {
    form.update({ [field]: without(values, value) });
  } else {
    form.update({ [field]: values.concat([value]) });
  }
};
