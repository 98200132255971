import omit from 'lodash.omit';
import orderBy from 'lodash.orderby';

import { action, computed, reaction } from 'mobx';

import InsightsUIModel from './InsightsUIModel';
import UserMeasures from '../collections/UserMeasures';

import rankCollectionByValue from '../../utils/rankCollectionByValue';

export default class InsightsTopUsers extends InsightsUIModel {
  constructor(attributes, options) {
    super(attributes, options);

    this.userMeasures = new UserMeasures(null, {
      rootStore: this.rootStore,
      parent: this
    });

    // React to selection changes
    this.reactToSelection = reaction(
      () => this.params,
      params => {
        if (params.projectIds || params.projectStatuses) {
          this.fetchStats();
        } else {
          this.cancelRequest();
          this.userMeasures.reset();
        }
      },
      { fireImmediately: true }
    );
  }

  url() {
    return '/ra/insights/topUsers';
  }

  @action.bound
  parse(attributes) {
    this.parseUserMeasures(attributes);

    return {
      ...omit(attributes, ['userMeasures'])
    };
  }

  @action.bound
  parseUserMeasures(attributes) {
    if (attributes.userMeasures) {
      this.userMeasures.reset(
        rankCollectionByValue(attributes.userMeasures, this.rankField)
      );
    } else {
      this.userMeasures.reset();
    }
  }

  @computed
  get hasUserMeasures() {
    return this.userMeasures && this.userMeasures.length > 0;
  }

  @computed
  get sortedUserMeasures() {
    switch (this.sortField) {
      case 'name':
        return orderBy(
          this.userMeasures.models,
          [userMeasure => userMeasure.user.fullName.toLowerCase()],
          [this.sortDirection]
        );
      default:
        return orderBy(
          this.userMeasures.models,
          [userMeasure => userMeasure[this.sortField]],
          [this.sortDirection]
        );
    }
  }
}
