import MobxReactForm from 'mobx-react-form';

import { t } from 'utils/translate';

import extendedPlugins from 'forms/utils/extendedPlugins';

const ryvitConnectFormPlugins = extendedPlugins;

const ryvitConnectFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const ryvitConnectFormFields = ['subscriberCode'];

const ryvitConnectFormRules = {
  subscriberCode: 'string|required'
};

const ryvitConnectFormLabels = {
  subscriberCode: t('Ryvit Subscriber Code')
};

const ryvitConnectFormValues = {};

class RyvitConnectForm extends MobxReactForm {}

export {
  RyvitConnectForm,
  ryvitConnectFormOptions,
  ryvitConnectFormFields,
  ryvitConnectFormRules,
  ryvitConnectFormLabels,
  ryvitConnectFormValues,
  ryvitConnectFormPlugins
};
