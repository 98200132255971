import MobxReactForm from 'mobx-react-form';
import validatorjs from 'validatorjs';
import { reaction, computed } from 'mobx';

const checklistsReportFormPlugins = { dvr: validatorjs };

const checklistsReportFormOptions = {
  validateOnInit: true,
  validateOnChange: true
};

const checklistsReportFormFields = [
  'timeFrameType',
  'fromDate',
  'toDate',
  'projectIncludeOption',
  'projects',
  'groups',
  'checklistName',
  'emails',
  'email'
];

const checklistsReportFormRules = {
  timeFrameType: 'required',
  projects: 'array|required_if:projectIncludeOption,SELECTED',
  groups: 'array|required_if:projectIncludeOption,SELECTED_GROUPS',
  emails: 'array|required',
  email: 'required_without:emails|email'
};

const checklistsReportFormValues = {
  projectIncludeOption: '',
  projects: [],
  groups: [],
  checklistName: '',
  email: ''
};

class ChecklistsReportForm extends MobxReactForm {
  constructor(settings, options) {
    super(settings, options);

    this.cancelReactToEnableDatePickers = reaction(
      () => this.enableFromToDate,
      enableFromToDate => {
        if (enableFromToDate) {
          this.$('fromDate').set('rules', 'required|string');
          this.$('toDate').set('rules', 'required|string');
        } else {
          this.$('fromDate').set('rules', '');
          this.$('toDate').set('rules', '');
        }
      },
      {
        fireImmediately: true
      }
    );
  }

  @computed get enableFromToDate() {
    return this.$('timeFrameType').value === 'SPECTIMEFRAME';
  }
}

export {
  ChecklistsReportForm,
  checklistsReportFormRules,
  checklistsReportFormFields,
  checklistsReportFormOptions,
  checklistsReportFormPlugins,
  checklistsReportFormValues
};
