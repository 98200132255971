import moment from 'moment';
import { action, computed, reaction } from 'mobx';
import InsightsUIModel from './InsightsUIModel';
import { t } from 'utils/translate';

import main from 'themes/main';

export default class ProjectInsightsChecklistCompletionByWorkUnit extends InsightsUIModel {
  constructor(attributes, options) {
    super(attributes, options);

    this.typeClasses = options.typeClasses;
    this.workUnitTypes = options.workUnitTypes;

    // React to selection changes
    this.reactToSelection = reaction(
      () => this.params,
      params => {
        if (params.projectUuids) {
          this.fetchStats();
        } else {
          this.cancelRequest();
          this.assigned.clear();
          this.completed.clear();
        }
      },
      { fireImmediately: true }
    );
  }

  url() {
    return `ra/companies/${this.rootStore.me.company.uuid}/insights/checklists/completion-by-work-unit`;
  }

  get restAttributes() {
    return ['type', 'assigned', 'completed'];
  }

  get restAttributeDefaults() {
    return {
      assigned: [],
      completed: []
    };
  }

  @computed
  get params() {
    return {
      projectUuids: this.parent.projectUuid,
      startDay: moment(this.parent.startDay)
        .startOf('day')
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss'),
      endDay: moment(this.parent.endDay)
        .endOf('day')
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss'),
      typeClasses: this.typeClasses,
      workUnitTypes: this.workUnitTypes
    };
  }

  @action.bound
  async fetchStats() {
    await this.fetch({
      params: this.params
    });
  }

  @computed get hasStats() {
    return this.assigned.length > 0 || this.completed.length > 0;
  }

  @computed get chartConfig() {
    return {
      chart: {
        type: 'bar',
        height: 350
      },
      lang: {
        drillUpText: t('◁ Back to all')
      },
      title: {
        text: ''
      },
      subtitle: {
        text: t('Click to drill down'),
        align: 'left'
      },
      credits: {
        enabled: false
      },
      legend: {
        itemStyle: {
          fontSize: '13px',
          fontFamily: main.fonts.primary
        }
      },
      xAxis: {
        type: 'category',
        labels: {
          style: {
            fontSize: '13px',
            fontFamily: main.fonts.primary
          }
        }
      },
      yAxis: {
        allowDecimals: false,
        title: {
          text: t('Total')
        },
        labels: {
          style: {
            fontSize: '10px',
            fontFamily: main.fonts.primary
          }
        }
      },
      plotOptions: {
        column: {
          stacking: 'normal'
        }
      },
      series: [
        {
          name: t('Assigned'),
          color: 'rgb(124, 181, 236)',
          data: this.assigned.map(assigned => {
            return {
              name: assigned.workUnit,
              y: assigned.count
            };
          })
        },
        {
          name: t('Completed'),
          color: '#4caf50',
          data: this.completed.map(completed => {
            return {
              name: completed.workUnit,
              y: completed.count,
              drilldown: `Completed (${completed.workUnit})`
            };
          })
        }
      ],
      drilldown: {
        series: [
          ...this.completed.map(completed => {
            return {
              name: t('Completed'),
              id: `Completed (${completed.workUnit})`,
              colorByPoint: false,
              data: completed.users.map(user => {
                return {
                  name: user.name,
                  y: user.count
                };
              })
            };
          })
        ]
      }
    };
  }
}
