import { observable, action, computed } from 'mobx';
import errorHandler from 'utils/errorHandler';
import matchModelField from 'utils/matchModelField';
import orderBy from 'lodash.orderby';

import UIStore from './UIStore';
import { t } from 'utils/translate';

export default class ProjectSettingsPayTypesUI extends UIStore {
  @observable searchQuery;
  @observable entryForEdit;
  @observable entryEditForm;
  @observable pageSize;
  @observable page;
  @observable sortDirection;
  @observable sortField;

  constructor(options) {
    super(options);

    this.uploadProgress = 0;

    // Editing
    this.entryForEdit = null;
    this.entryEditForm = null;

    // Sorting
    this.sortDirection = 'asc';

    // Search & Pagination
    this.searchQuery = '';
    this.pageSize = 20;
    this.page = 0;
  }

  @action.bound clearUIState() {
    this.searchQuery = '';
    this.page = 0;
  }

  @computed
  get project() {
    return this.parent.project;
  }

  @computed
  get payTypes() {
    return this.rootStore.payTypes;
  }

  @computed
  get changed() {
    return this.saving;
  }

  @computed get hasPayTypes() {
    return this.payTypes.hasModels;
  }

  @action.bound
  async fetchPayTypes() {
    try {
      await this.payTypes.fetch({
        params: {
          limit: 10000
        },
        add: true,
        remove: false,
        update: true
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  @computed get sortedPayTypes() {
    return orderBy(this.payTypes.models, ['order'], [this.sortDirection]);
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 0;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 0;
  }

  @computed get searchedPayTypes() {
    if (!this.searchQuery) {
      return this.sortedPayTypes;
    }

    const query = this.searchQuery.toLowerCase();

    return this.sortedPayTypes.filter(payType => {
      return (
        matchModelField(payType, query, 'name') ||
        matchModelField(payType, query, 'code')
      );
    });
  }

  @computed get hasSearchedPayTypes() {
    return this.searchedPayTypes.length > 0;
  }

  @computed get paginatedPayTypes() {
    return this.searchedPayTypes.slice(
      this.page * this.pageSize,
      this.page * this.pageSize + this.pageSize
    );
  }

  @computed
  get totalPages() {
    return Math.ceil(this.searchedPayTypes.length / this.pageSize);
  }

  @computed
  get hiddenPayTypesForProject() {
    return this.payTypes.models.filter(payType =>
      payType.hiddenProjects.find(
        hiddenProject => hiddenProject.uuid === this.project.uuid
      )
    );
  }

  @computed
  get projectHasNoPayTypes() {
    return this.hiddenPayTypesForProject.length === this.payTypes.length;
  }

  @action.bound
  setPage(page) {
    this.page = page.selected;
  }

  @action.bound
  sortByColumn() {
    if (this.sortDirection === 'asc') {
      this.sortDirection = 'desc';
    } else {
      this.sortDirection = 'asc';
    }
  }

  @action.bound
  restorePayType(payType) {
    const payTypeIndex = payType.hiddenProjects
      .map(item => item.uuid)
      .indexOf(this.project.uuid);

    payType.hiddenProjects.splice(payTypeIndex, 1);
  }

  @action.bound
  async togglePayTypeForProject(payType) {
    if (!payType.assignedToCurrentProject) {
      this.restorePayType(payType);
    } else {
      payType.hiddenProjects.push({ uuid: this.project.uuid });
    }

    if (this.projectHasNoPayTypes) {
      // We can't allow a project with no pay types so if they toggle the last one off we need to add it back.
      this.rootStore.notificationsUI.pushNotification({
        showUndo: false,
        title: t('This project must contain at least one pay type')
      });
      setTimeout(() => {
        // We need to set time out to meet ticket AC: "When they select the slider it should animate off and then back on"
        this.restorePayType(payType);
      }, 500);
      return;
    }

    try {
      await payType.save();
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
      this.restorePayType(payType);
    }
  }
}
