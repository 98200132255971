import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import ToggleButton from './ToggleButton';

const getSize = size => {
  switch (size) {
    case 'large':
      return {
        width: '70px',
        height: '40px'
      };
    case 'small':
      return {
        width: '25px',
        height: '15px'
      };
    default:
      return {
        width: '40px',
        height: '25px'
      };
  }
};

const ToggleWrapper = styled.button`
  outline: none;
  border: none;
  position: relative;
  border-radius: 20px;
  background-color: ${props =>
    props.active
      ? props.theme.colors.goGreen
      : props.theme.colors[props.inActiveColor]};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  width: ${props => getSize(props.size).width};
  height: ${props => getSize(props.size).height};
  display: ${props => (props.inline ? 'inline-block' : 'block')};
  &:focus {
    outline: 0;
    box-shadow: 0 0 8px
      ${props =>
        props.active
          ? props.theme.colors.goGreen
          : props.theme.colors[props.inActiveColor]};
  }
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `};
`;

class Toggle extends Component {
  handleKeyDown = e => {
    if (e.keyCode === 32) {
      e.preventDefault();
      this.props.onClick.call(null);
    }
  };

  render() {
    const {
      inActiveColor,
      disabled,
      size,
      active,
      onClick,
      tabIndex,
      margin,
      inline,
      dataQA
    } = this.props;

    return (
      <ToggleWrapper
        active={active}
        inActiveColor={inActiveColor}
        disabled={disabled}
        size={size}
        onClick={onClick}
        onKeyDown={this.handleKeyDown}
        tabIndex={tabIndex}
        margin={margin}
        inline={inline}
        type="button"
      >
        <ToggleButton
          active={Boolean(active)}
          disabled={disabled}
          size={size}
          data-qa={dataQA}
          data-is-active={Boolean(active)}
        />
      </ToggleWrapper>
    );
  }
}

Toggle.propTypes = {
  disabled: PropTypes.bool.isRequired,
  size: PropTypes.string.isRequired,
  inline: PropTypes.bool
};

Toggle.defaultProps = {
  size: 'default',
  disabled: false,
  inActiveColor: 'lightGrey1',
  tabIndex: -1
};

export default Toggle;
