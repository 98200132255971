import { observable, action, computed } from 'mobx';

export default class Notifications {
  @observable show;
  @observable hiding;

  constructor(options) {
    this.show = false;
    this.hiding = false;

    if (options.rootStore) {
      this.rootStore = options.rootStore;
    }
  }

  @computed
  get notifications() {
    return this.rootStore.notifications;
  }

  @computed
  get hasNotifications() {
    return this.notifications.length > 0;
  }

  @action.bound
  showCurrentNotification() {
    this.show = true;
  }

  @action.bound
  hideCurrentNotification() {
    clearTimeout(this.hideTimer);
    this.hiding = true;

    return new Promise((resolve, reject) => {
      this.hideTimer = setTimeout(() => {
        this.hiding = false;
        this.show = false;
        resolve();
      }, 350);
    });
  }

  @action.bound
  pushNotification(data, delay = 0) {
    setTimeout(() => {
      this.notifications.add(data);
      this.showCurrentNotification();
    }, delay);
  }

  @action.bound
  pushError(data) {
    let title;
    let details;

    if (!data.message || data.message === 'No message available') {
      title = data.error || 'Error';
    } else {
      title = data.message;
    }

    details = data.validationDetails;

    if (details) {
      this.pushNotification({
        showUndo: false,
        title: title,
        validationDetails: details
      });
    } else {
      this.pushNotification({
        showUndo: false,
        title: title
      });
    }
  }

  @action.bound
  undoCurrentNotification() {
    this.currentNotification.onUndo();
    this.moveToNextNotification();
  }

  @action.bound
  dismissCurrentNotification() {
    this.currentNotification &&
      this.currentNotification.onDismiss &&
      this.currentNotification.onDismiss();
    this.moveToNextNotification();
  }

  @action.bound
  async dismissAllNotifications() {
    await this.hideCurrentNotification();

    this.notifications.models.forEach(notification => {
      notification.onDismiss && notification.onDismiss();
    });

    this.notifications.clear();
  }

  @action.bound
  async moveToNextNotification() {
    await this.hideCurrentNotification();

    if (this.notifications.length && this.notifications.at(0)) {
      this.notifications.at(0).destroy();
    }

    if (this.totalNotifications) {
      this.showCurrentNotification();
    }
  }

  @computed
  get currentNotification() {
    return this.notifications.at(0);
  }

  @computed
  get totalNotifications() {
    return this.notifications.length;
  }

  @computed
  get additionalNotifications() {
    return this.notifications.length - 1;
  }
}
