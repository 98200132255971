import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';

const collaboratorFilterFormPlugins = extendedPlugins;

const collaboratorFilterFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const collaboratorFilterFormFields = [
  'projectFilters[]',
  'projectStateFilters'
];

const collaboratorFilterFormRules = {
  projectFilters: 'array',
  projectStateFilters: 'array'
};

class CollaboratorFilterForm extends MobxReactForm {}

export {
  CollaboratorFilterForm,
  collaboratorFilterFormRules,
  collaboratorFilterFormFields,
  collaboratorFilterFormOptions,
  collaboratorFilterFormPlugins
};
