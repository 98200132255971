import debounce from 'lodash.debounce';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import ProjectChildUI from './ProjectChildUI';

import Attachments from '../../collections/Attachments';
import Attachment from '../../models/Attachment';

import { BASE_DEBOUNCE } from 'fixtures/constants';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

import getFilePreviewIcon from 'utils/getFilePreviewIcon';
import fileToBase64 from 'utils/fileToBase64';
import bytesToSize from 'utils/bytesToSize';

export default class AttachmentsUI extends ProjectChildUI {
  @observable sortField;
  @observable sortDirection;
  @observable loading;

  constructor(options) {
    super(options);

    this.loading = true;

    this.sortField = 'createdTimestamp';
    this.sortDirection = 'desc';
    this.pageSize = 1000;

    // Attachments collection
    this.attachments = new Attachments(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchAttachmentsDebounced = debounce(
      this.fetchAttachments,
      BASE_DEBOUNCE
    );
  }

  url() {
    return `ra/companies/${this.company.uuid}`;
  }

  @action.bound setup() {
    window.scrollTo(0, 0);
    this.setupReactions();
    this.fetchAttachments();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchAttachmentsDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed
  get params() {
    return {
      fromDate: this.date,
      toDate: this.date,
      projectUuids: this.projectUuid,
      segmentUuids: this.segmentUuid,
      limit: this.pageSize,
      offset: 0,
      query: this.searchQuery,
      sortField: this.sortField,
      sortDirection: this.sortDirection
    };
  }

  @computed get hasRequiredParams() {
    return (
      this.params.projectUuids && this.params.fromDate && this.params.toDate
    );
  }

  @action.bound async fetchAttachments() {
    if (!this.hasRequiredParams) return;

    this.attachments.cancelRequest();
    this.attachments.clear();

    try {
      await this.attachments.fetch({
        url: `ra/companies/${this.company.uuid}/attachments`,
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasAttachments() {
    return this.attachments.hasModels;
  }

  @computed
  get imageCount() {
    return this.attachments.models.filter(attachment => {
      return attachment.mediaType === 'IMAGE';
    }).length;
  }

  @computed
  get documentCount() {
    return this.attachments.models.filter(attachment => {
      return attachment.mediaType === 'DOCUMENT';
    }).length;
  }

  @computed
  get videoCount() {
    return this.attachments.models.filter(attachment => {
      return attachment.mediaType === 'VIDEO';
    }).length;
  }

  @computed
  get audioCount() {
    return this.attachments.models.filter(attachment => {
      return attachment.mediaType === 'AUDIO';
    }).length;
  }

  @action.bound clearUIState() {
    this.searchQuery = '';
    this.attachments.clear();
    this.loading = true;
    this.saving = false;
    this.sortField = 'createdTimestamp';
    this.sortDirection = 'desc';
  }

  @computed get showEmptyState() {
    return !this.loading && !this.searchQuery && !this.hasAttachments;
  }

  @computed get showEmptySearchState() {
    return !this.loading && this.searchQuery && !this.hasAttachments;
  }

  @computed get showGrid() {
    return !this.showEmptyState && !this.showEmptySearchState;
  }

  @action.bound async uploadAttachment(uploadItem) {
    await this.authorization.checkFeatureAccess('UploadAttachments');

    const file = uploadItem.file;

    if (file.size > 62914560) {
      this.rootStore.notificationsUI.pushNotification({
        title: `${t('File is too big ')} (${bytesToSize(file.size)}). ${t(
          'Limit is 60MB.'
        )}`,
        snackbar: 'error',
        icon: 'notification'
      });

      return;
    }

    file.preview = await fileToBase64(file);

    const filePreviewIcon = getFilePreviewIcon(this.rootStore.assetsURL, file);

    const previewAttachment = new Attachment(
      {
        fileName: file.name,
        thumbUrl: filePreviewIcon
      },
      {
        rootStore: this.rootStore
      }
    );

    this.attachments.add(previewAttachment, {
      unshift: true
    });

    try {
      await this.attachments.uploadVersionTwo({
        file: file,
        metadata: this.reportParams,
        analytics: {
          module: 'Attachments',
          project: {
            id: this.project.uuid,
            name: this.project.name
          }
        },
        previewAttachment: previewAttachment,
        progressCallback: percentCompleted => {
          // If we wanted to show a progress bar on the attachment
          // we can use previewAttachment.uploadProgress.
          previewAttachment.setUploadProgress(percentCompleted);
        }
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      // Remove the preview model now that the new one is ready
      this.attachments.remove(previewAttachment);

      // Only refetch the report summary once all are uploaded.
      if (!this.hasAttachmentsUploading) {
        this.refetchReportSummary();
      }
    }
  }

  @computed get hasAttachmentsUploading() {
    return this.attachments.models.find(attachment => attachment.isNew);
  }

  @action.bound rejectFileType(fileName) {
    const extension = fileName.split('.').pop();

    this.rootStore.notificationsUI.pushNotification({
      title: `File of type .${extension} is not supported.`,
      snackbar: 'error',
      icon: 'notification'
    });
  }
}
