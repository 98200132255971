import { RakenCollection } from '../lib';
import EquipmentType from '../models/EquipmentType';

export default class EquipmentTypes extends RakenCollection {
  model() {
    return EquipmentType;
  }

  url() {
    return `${this.rootStore.appConfig.tracking_api_url}/companies/${this.rootStore.me.company.uuid}/equipment/types`;
  }
}
