import request from 'axios';
import UIStore from 'stores/ui/UIStore';
import { observable, action, computed, runInAction } from 'mobx';
import errorHandler from 'utils/errorHandler';
import axiosDownload from 'utils/axiosDownload';
import moment from 'moment';

export default class SuperAdminCompanySilentInviteUI extends UIStore {
  @observable uploadComplete;
  @observable sending;
  constructor(options) {
    super(options);
    this.usersList = observable([]);
    this.errorsList = observable([]);
    this.uploadComplete = false;
    this.sending = false;
  }

  @computed
  get activeCompany() {
    return this.parent.activeCompany;
  }

  @computed
  get hasErrors() {
    return this.errorsList && this.errorsList.length > 0;
  }

  @computed
  get hasUsers() {
    return this.usersList && this.usersList.length > 0;
  }

  @action.bound setup() {}

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound
  clearUIState() {
    this.usersList.clear();
    this.errorsList.clear();
    this.uploadComplete = false;
    this.sending = false;
  }

  @action.bound download() {
    axiosDownload(
      `/ra/sadmin/companies/templates/silent-invite`,
      `silent-invite.csv`
    );
  }

  @action.bound
  upload(file) {
    this.sending = true;

    const formData = new FormData();
    formData.append('Media-LastModifiedDate', moment().valueOf());
    formData.append('file', file);

    request
      .post(
        `ra/sadmin/companies/${this.activeCompany.id}/silent-invite`,
        formData
      )
      .then(
        response => {
          runInAction(() => {
            this.usersList.replace(response.data.users);
            this.errorsList.replace(response.data.errorMessages);
            this.uploadComplete = false;
            this.sending = false;
          });
        },
        error => {
          this.sending = false;
          errorHandler(error, this.notifications.pushError);
        }
      );
  }

  @action.bound
  addUsers() {
    this.sending = true;
    request
      .post(
        `ra/sadmin/companies/${this.activeCompany.id}/silent-invite`,
        this.usersList
      )
      .then(
        response => {
          runInAction(() => {
            this.usersList.replace(response.data.users);
            this.uploadComplete = true;
            this.sending = false;
          });
        },
        error => {
          this.sending = false;
          errorHandler(error, this.notifications.pushError);
        }
      );
  }
}
