import { RakenCollection } from '../lib';
import ProjectMinimal from '../models/ProjectMinimal';

export default class ProjectMemberships extends RakenCollection {
  model() {
    return ProjectMinimal;
  }

  url() {
    return `${this.rootStore.users.url()}/${this.parent.memberUuid}/projects`;
  }

  parse(data) {
    return data.collection.map(membership => {
      let disabled = true;

      if (this.parent.entryForEdit?.role === 'ROLE_WORKER') {
        disabled = !this.rootStore.me.projectUuids.includes(membership.uuid);
      } else {
        if (
          this.parent.memberUuid === this.rootStore.me.uuid ||
          this.rootStore.me.isAdmin
        ) {
          disabled = !membership.canLeave && membership.isMember;
        }
      }

      return Object.assign(membership, {
        disabled: disabled
      });
    });
  }
}
