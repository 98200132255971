import { action, computed, observable } from 'mobx';

import {
  ProjectTemplateProjectRadiusForm,
  projectTemplateProjectRadiusFormOptions,
  projectTemplateProjectRadiusFormFields,
  projectTemplateProjectRadiusFormRules,
  projectTemplateProjectRadiusFormPlugins,
  projectTemplateProjectRadiusFormLabels
} from 'forms/projectTemplates/projectTemplateProjectRadius';

import { t } from 'utils/translate';

import ProjectTemplateChildEditUI from './ProjectTemplateChildEditUI';

export default class ProjectTemplateProjectRadiusUI extends ProjectTemplateChildEditUI {
  @observable form;
  constructor(options) {
    super(options);

    this.form = null;

    this.saveTitle = t('Project template radius saved');
  }

  @computed get selectedTemplate() {
    return this.parent.selectedTemplate;
  }

  @action.bound setup() {
    this.setupForm();
  }

  @action.bound tearDown() {
    this.form = null;
    this.unblockHistory();
  }

  @action.bound
  setupForm() {
    this.form = new ProjectTemplateProjectRadiusForm(
      {
        fields: projectTemplateProjectRadiusFormFields,
        rules: projectTemplateProjectRadiusFormRules,
        labels: projectTemplateProjectRadiusFormLabels,
        values: this.selectedTemplate.projectRadiusValues
      },
      {
        options: projectTemplateProjectRadiusFormOptions,
        plugins: projectTemplateProjectRadiusFormPlugins
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @computed get mapCenter() {
    if (this.company.address?.streetAddress && this.company.hasGeoLocation) {
      return this.company.address.geolocation;
    }

    return this.rootStore.userLocation?.geolocation;
  }
}
