import MobxReactForm from 'mobx-react-form';
import extendedPlugins from 'forms/utils/extendedPlugins';
import { t } from 'utils/translate';

const projectTemplateProjectRadiusFormPlugins = extendedPlugins;

const projectTemplateProjectRadiusFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false,
  retrieveOnlyEnabledFields: false
};

const projectTemplateProjectRadiusFormFields = ['projectRadius'];

const projectTemplateProjectRadiusFormLabels = {
  projectRadius: t('project radius')
};

const projectTemplateProjectRadiusFormRules = {
  projectRadius: 'integer'
};

const projectTemplateProjectRadiusFormValues = {
  projectRadius: ''
};

class ProjectTemplateProjectRadiusForm extends MobxReactForm {}

export {
  ProjectTemplateProjectRadiusForm,
  projectTemplateProjectRadiusFormOptions,
  projectTemplateProjectRadiusFormFields,
  projectTemplateProjectRadiusFormRules,
  projectTemplateProjectRadiusFormValues,
  projectTemplateProjectRadiusFormPlugins,
  projectTemplateProjectRadiusFormLabels
};
