import { RakenCollection } from '../../lib';
import ChecklistSchedule from 'stores/models/checklists/ChecklistSchedule';

export default class ChecklistSchedules extends RakenCollection {
  model() {
    return ChecklistSchedule;
  }

  url() {
    return `${this.rootStore.urlMicroService('toolbox')}/companies/${
      this.rootStore.me.company.uuid
    }/checklist-schedules`;
  }
}
