import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import omit from 'lodash.omit';
import { action, computed, observable, when } from 'mobx';
import User from './User';

export default class Me extends User {
  @observable company;
  @observable settings;
  @observable signature;
  @observable isSaveSignature;

  constructor(data, options) {
    super(data, options);

    this.isSaveSignature = undefined;

    this.setupReactions();
  }

  url() {
    return `${this.rootStore.users.url()}/${this.uuid}`;
  }

  get restAttributeDefaults() {
    return {
      title: '',
      employeeId: '',
      projectTeamIds: [],
      projectTeamUuids: [],
      projectUuids: [],
      permissions: {},
      settings: {
        mobileDownloadModalDone: false
      }
    };
  }

  @action.bound
  parse(attributes) {
    // Keep reference to legacy id until we full switch to members
    attributes.userId = attributes.id;

    if (attributes.projectTeamUuids) {
      // TODO we need to update /ra/user to new members contract
      attributes.projectUuids = [...attributes.projectTeamUuids];
    }

    this.parseCompany(attributes);
    this.parseSettings(attributes);

    return {
      ...omit(attributes, ['company', 'settings'])
    };
  }

  @action.bound
  setupReactions() {
    // Establish isSaveSignature once
    when(
      () => Boolean(this.uuid) && this.isSaveSignature === undefined,
      () => {
        this.isSaveSignature = Boolean(this.signature);
      }
    );
  }

  @computed
  get requiredInfoComplete() {
    return this.phoneNumber && this.firstName && this.lastName;
  }

  @computed
  get requiredInfoFormValues() {
    return pickBy(
      {
        firstName: this.firstName,
        lastName: this.lastName,
        phoneNumber: this.phoneNumber
      },
      identity
    );
  }

  @computed
  get hasRequiredModals() {
    return (
      !this.requiredInfoComplete ||
      !this.company ||
      (this.company && !this.company.requiredInfoComplete)
    );
  }

  @computed
  get showTimeCardsDivision() {
    return (
      (this.company && this.company.preferences.showTimeCardsDivision) || false
    );
  }

  @computed
  get hasSavedSignature() {
    return Boolean(this.signature);
  }

  @action.bound
  toggleSaveSignature(boolVal) {
    this.isSaveSignature =
      typeof boolVal === 'boolean' ? boolVal : !this.isSaveSignature;
  }

  /**
   * Gives a way to save or unsave user's signature
   * @param {String} signature -  URL or Base64 string
   */
  @action.bound
  saveUserSignature(signature) {
    return this.save(
      {
        signature: this.isSaveSignature ? signature : null
      },
      {
        url: `/ra/user`
      }
    );
  }

  @computed get isTestingAccount() {
    if (!this.username) return false;

    // Allow any non @rakenapp.com
    if (!this.username.includes('@rakenapp.com')) return false;

    return (
      this.username.includes('testing+') ||
      this.username.includes('automation+') ||
      this.username.includes('auto+')
    );
  }
}
