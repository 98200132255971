import React from 'react';
import styled from 'styled-components';
import { borderRadius, borderStyle, borderWidth } from 'polished';
import media from '../utils/mediaTemplate';

const Header = styled.header`
  display: block;
  position: relative;
  z-index: 5;
  .collapsed &::before {
    content: '';
    background-color: #f5f5f7;
    position: absolute;
    left: -25px;
    right: -25px;
    top: -25px;
    height: 25px;
  }
`;

const HeaderInner = styled.div`
  background-color: ${props => props.theme.colors.white};
  ${props =>
    props.noTopRadius ? borderRadius('top', 0) : borderRadius('top', '4px')};
  ${borderStyle('solid', 'solid', 'none', 'solid')};
  ${borderWidth('1px', '1px', '0', '1px')};
  border-color: ${props => props.theme.colors.lightGrey5};
  box-sizing: border-box;
  padding: ${props => props.padding || '2rem 1.5rem'};
  transform: translateZ(0);

  ${media.desktop`
    padding: ${props => props.padding || '1rem 2rem'};
  `};
`;

export default ({ children, padding, noTopRadius }) => {
  return (
    <Header>
      <HeaderInner padding={padding} noTopRadius={noTopRadius}>
        {children}
      </HeaderInner>
    </Header>
  );
};
