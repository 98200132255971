import request from 'axios';
import { observable, action, computed } from 'mobx';
import omit from 'lodash.omit';
import debounce from 'lodash.debounce';
import pickBy from 'lodash.pickby';
import UIStore from './UIStore';

import {
  MemberForm,
  memberFormOptions,
  memberFormFields,
  memberFormFieldOptions,
  memberFormLabels,
  memberFormRules,
  memberFormPlugins
} from 'forms/member';

import {
  MembersForm,
  membersFormOptions,
  membersFormFields,
  membersFormRules,
  membersFormPlugins
} from 'forms/members';

import {
  CompanyNameForm,
  companyNameFormOptions,
  companyNameFormFields,
  companyNameFormRules,
  companyNameFormValues,
  companyNameFormPlugins
} from 'forms/companyName';

import { t } from 'utils/translate';
import history from 'utils/history';
import alertErrorHandler from 'utils/alertErrorHandler';
import { callTrack } from 'utils/segmentIntegration';
import { PROJECT_COLLABORATOR_ADDED } from 'utils/segmentAnalytics/eventSpec';

import { BASE_DEBOUNCE } from 'fixtures/constants';

export default class ProjectDirectoryCollaboratorsAddUI extends UIStore {
  @observable memberForm;
  @observable membersForm;
  @observable step;

  @observable newCompanyName;
  @observable companyInputValue;

  constructor(options) {
    super(options);

    this.step = 'members';
    this.addSeatsModalShown = false;
    this.memberForm = null;
    this.membersForm = null;

    this.companies = observable([]);

    this.fetchCompaniesDebounced = debounce(this.fetchCompanies, BASE_DEBOUNCE);

    this.companyInputValue = '';
  }

  @action.bound setup(step = 'members') {
    this.setupMemberForm();
    this.setupMembersForm();
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @computed get memberFormRules() {
    return Object.assign({}, memberFormRules, {
      username: `required|email|max:200|username_available|not_in:${this.membersFormUsernames}`,
      role: 'string',
      'company.name': 'required'
    });
  }

  @action.bound setupMemberForm() {
    this.memberForm = new MemberForm(
      {
        fields: memberFormFields,
        rules: this.memberFormRules,
        labels: memberFormLabels,
        options: memberFormFieldOptions
      },
      {
        options: Object.assign({}, memberFormOptions, {
          validateOnInit: true,
          showErrorsOnInit: false
        }),
        plugins: memberFormPlugins,
        rootStore: this.rootStore
      }
    );

    if (this.parent.selectedProjectTeam) {
      this.memberForm.set({
        company: {
          uuid: this.parent.selectedProjectTeam.company.uuid,
          name: this.parent.selectedProjectTeam.company.name
        }
      });
    }
  }

  @action.bound setupMembersForm() {
    this.membersForm = new MembersForm(
      {
        fields: membersFormFields,
        rules: membersFormRules
      },
      {
        options: membersFormOptions,
        plugins: membersFormPlugins
      }
    );
  }

  @action.bound cancelMembersAdd() {
    history.push(`${this.project.viewUrl}/directory/collaborators`);
    this.parent.selectedProjectTeam = null;
  }

  @action.bound submitMemberForm(event) {
    event.preventDefault();

    if (this.memberForm.submitting) return;

    this.memberForm.submit({
      onSuccess: this.submitMemberFormSuccess,
      onError: this.submitMemberFormError
    });
  }

  @action.bound submitMemberFormError() {
    console.error(this.memberForm.errors());
  }

  @action.bound submitMemberFormSuccess() {
    const values = this.membersForm.$('members').values();

    values.unshift(omit(this.memberForm.trimmedValues(), 'avatar'));

    this.membersForm.update({
      members: values
    });

    this.setupMemberForm();
  }

  @computed
  get membersFormUsernames() {
    if (!this.membersForm) return [];

    return this.membersForm
      .$('members')
      .map(member => member.$('username').value)
      .join(',');
  }

  @computed
  get membersFormEmployeeIds() {
    if (!this.membersForm) return [];

    return this.membersForm
      .$('members')
      .map(member => member.$('employeeId').value)
      .join(',');
  }

  @computed get memberFormIsValid() {
    return this.memberForm.check('isValid');
  }

  @computed get hasAddedMembers() {
    return this.membersForm.$('members').size > 0;
  }

  @action.bound removeMember(member) {
    this.membersForm.$('members').del(member.key);

    if (!this.membersForm.$('members').size) {
      this.clearValidationDetails();
    }
  }

  @action.bound submitMembersForm(event) {
    event.preventDefault();

    if (this.membersForm.submitting) return;

    this.membersForm.submit({
      onSuccess: this.submitMembersFormSuccess,
      onError: this.submitMembersFormError
    });
  }

  @action.bound submitMembersFormSuccess() {
    this.inviteCollaborators();
  }

  @action.bound async inviteCollaborators() {
    this.saving = true;

    try {
      const memberFields = this.membersForm.trimmedValues().members;
      const message = this.membersForm.$('message').value;

      memberFields.forEach(memberField => {
        memberField.message = message;
        memberField.projectUuids = [this.project.uuid];
        memberField.role = 'ROLE_PROJECT_MEMBER';
      });

      const payload = memberFields.map(memberField =>
        pickBy(
          memberField,
          value => value !== undefined && value !== '' && value !== null
        )
      );

      await request.post(`${this.rootStore.users.url()}/batch`, payload);

      await this.project.fetch();

      this.parent.sortByLastCreated();
      this.cancelMembersAdd();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Collaborators invited')
      });

      memberFields.forEach(memberField => {
        callTrack(PROJECT_COLLABORATOR_ADDED, {
          project_id: this.project.id,
          project_name: this.project.name,
          collaborator_business_id: memberField.company.uuid,
          collaborator_company_name: memberField.company.name
        });
      });

      this.parent.selectedProjectTeam = null;
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitMembersFormError() {
    console.error(this.membersForm.errors());
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.memberForm = null;
    this.membersForm = null;
    this.companyInputValue = '';
  }

  @action.bound
  async fetchExternalMembers(value) {
    if (!value.length) return;

    try {
      const response = await request.get(
        `${this.rootStore.users.url()}/username/${value}`,
        {
          params: {
            external: true,
            internal: false,
            includePending: true
          }
        }
      );

      const user = response.data;

      if (!user) return;

      this.companyOptions.push({
        uuid: user.company.uuid,
        name: user.company.name
      });

      this.memberForm.set({
        username: user.username,
        firstName: user.firstName,
        lastName: user.lastName,
        role: 'ROLE_PROJECT_MEMBER',
        company: {
          uuid: user.company.uuid,
          name: user.company.name
        }
      });
    } catch (error) {
      return;
    }
  }

  @action.bound async fetchCompanies(value) {
    this.companyInputValue = value;

    if (value && value.length < 3) return;

    if (!value) {
      this.companies.clear();
      this.memberForm.update({
        company: {}
      });
      this.memberForm.validate();
      return;
    }

    const response = await request.get('/ra/company/search', {
      params: {
        query: value
      }
    });

    response.data.collection.forEach(company => {
      company.uuid = company.companyUuid;
    });

    this.companies.replace(response.data.collection);
  }

  @computed get companyOptions() {
    return this.companies.map(company => {
      return {
        uuid: company.uuid,
        name: company.companyName
      };
    });
  }

  @action.bound
  initCompanyNameForm() {
    this.companyNameForm = new CompanyNameForm(
      {
        fields: companyNameFormFields,
        rules: companyNameFormRules,
        values: companyNameFormValues
      },
      {
        options: companyNameFormOptions,
        plugins: companyNameFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound
  showAddNewCompanyNameModal() {
    this.initCompanyNameForm();
    this.showModal('NewCompanyName');
  }

  @action.bound
  async hideAddNewCompanyNameModal() {
    await this.hideActiveModal();
    this.companyNameForm = null;
  }

  @action.bound
  submitAddNewCompanyNameFormSuccess() {
    const { name } = this.companyNameForm.values();
    this.memberForm.set({
      company: {
        uuid: '',
        name
      }
    });
    this.hideAddNewCompanyNameModal();
  }

  @action.bound
  submitAddNewCompanyNameForm(e) {
    e.preventDefault();
    this.companyNameForm.submit({
      onSuccess: this.submitAddNewCompanyNameFormSuccess,
      onError: e => {
        console.error(this.companyNameForm.errors());
      }
    });
  }

  @computed get addNewCompanyDisabled() {
    return this.companyInputValue.length < 3;
  }
}
