import { action } from 'mobx';
import { RakenCollection } from '../lib';
import PayType from '../models/PayType';

export default class PayTypes extends RakenCollection {
  model() {
    return PayType;
  }

  url() {
    return `${this.rootStore.urlMicroService('performanceTracking')}/paytypes`;
  }

  @action.bound clearNew() {
    this.models.forEach(model => {
      model.clearNew();
    });
  }
}
