import { Model } from 'mobx-mc';

export default class EquipmentDeficiency extends Model {
  get restAttributes() {
    return [
      'createdBy',
      'createdTimestamp',
      'date',
      'description',
      'equipmentLogUuid',
      'faultCode',
      'modifiedBy',
      'modifyTimestamp',
      'operator',
      'status',
      'projectName',
      'updatedBy',
      'updatedTimestamp',
      'uuid'
    ];
  }

  idAttribute() {
    return 'uuid';
  }
}
