import styled, { css } from 'styled-components';

export default styled.table`
  border-spacing: 0;
  min-width: ${props => (props.minWidth ? `${props.minWidth}px` : '100%')};
  margin-top: ${props => props.marginTop || 0}px;
  margin-bottom: ${props => props.marginBottom || 0}px;
  width: ${props => props.width || '100%'};
  table-layout: ${props => (props.fixed ? 'fixed' : 'auto')};
  word-wrap: break-word;
  ${props =>
    props.bordered &&
    css`
      border: 1px solid ${props.theme.colors.lightGrey4};
    `};
`;
