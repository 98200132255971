import request from 'axios';
import moment from 'moment';
import { action, runInAction, observable, computed } from 'mobx';
import UIStore from './UIStore';
import pickBy from 'lodash.pickby';

import { t } from 'utils/translate';

import {
  QUANTITIES_REPORT_DOWNLOADED,
  QUANTITIES_REPORT_SCHEDULED,
  QUANTITIES_REPORT_STARTED
} from 'utils/segmentAnalytics/eventSpec';

import { callTrack } from 'utils/segmentIntegration';
import errorHandler from 'utils/errorHandler';
import alertErrorHandler from 'utils/alertErrorHandler';

import MaterialReport from '../models/MaterialReport';

import {
  ProductionQuantitiesReportForm,
  productionQuantitiesReportFormOptions,
  productionQuantitiesReportFormFields,
  productionQuantitiesReportFormRules,
  productionQuantitiesReportFormLabels,
  productionQuantitiesReportFormValues,
  productionQuantitiesReportFormPlugins
} from 'forms/productionQuantitiesReport';

export default class ProductionQuantitiesReportsUI extends UIStore {
  @observable activeReportTab;
  @observable scheduledReportToEdit;

  constructor(options) {
    super(options);

    this.activeReportTab = 'runOnce';
    this.scheduledReportToEdit = null;
  }

  @action.bound showProductionQuantitiesReportModal() {
    this.rootStore.authorizationUI
      .checkFeatureAccess('ScheduleTimeCardReports', true)
      .then(() => {
        this.showModal('productionQuantitiesReport');
        callTrack(QUANTITIES_REPORT_STARTED);
        this.fetchScheduled();
        this.setupModalForm();
      });
  }

  @action.bound
  fetchScheduled() {
    return this.materialReports.fetch();
  }

  @computed
  get projectDefaultValue() {
    if (this.scheduledReportToEdit?.projects.length) {
      return this.scheduledReportToEdit.projects.map(project => {
        return {
          value: project.uuid,
          name: project.name
        };
      });
    }

    if (this.project) {
      return [
        {
          value: this.project.uuid,
          name: this.project.name
        }
      ];
    }

    return [];
  }

  @action.bound
  setupModalForm() {
    this.initReportForm();
    if (this.project) {
      this.setProjectIncludeOption('SELECTED');
      this.selectProjects(this.projectDefaultValue);
    } else {
      this.setProjectIncludeOption('ACTIVE');
    }
  }

  @action.bound
  initReportForm() {
    const rules =
      this.activeReportTab === 'scheduled'
        ? Object.assign({}, productionQuantitiesReportFormRules, {
            name: 'required|string|max:255',
            time: 'required'
          })
        : productionQuantitiesReportFormRules;

    const values =
      this.activeReportTab === 'scheduled' && this.scheduledReportToEdit
        ? Object.assign(
            {},
            productionQuantitiesReportFormValues,
            this.scheduledReportToEdit.formValues
          )
        : productionQuantitiesReportFormValues;

    this.activeForm = new ProductionQuantitiesReportForm(
      {
        fields: productionQuantitiesReportFormFields,
        rules: rules,
        values: values,
        labels: productionQuantitiesReportFormLabels
      },
      {
        options: productionQuantitiesReportFormOptions,
        plugins: productionQuantitiesReportFormPlugins,
        reportType: this.activeReportTab
      }
    );
  }

  @action.bound
  hideProductionQuantitiesReportModal() {
    this.hideActiveModal().then(() => {
      runInAction(() => {
        this.activeForm = null;
        this.activeReportTab = 'runOnce';
        this.scheduledReportToEdit = null;
        this.saving = false;
      });
    });
  }

  @action.bound
  switchTab(e, tab) {
    this.activeForm = null;
    this.scheduledReportToEdit = null;
    this.activeReportTab = tab;
    this.setupModalForm();
  }

  @computed
  get scheduledReportNewOrSaveButtonText() {
    if (this.saving) return t('Saving...');

    return this.scheduledReportToEdit
      ? this.scheduledReportToEdit?.isNew
        ? t('Schedule report')
        : t('Save changes')
      : t('New report');
  }

  @computed get materialReports() {
    return this.rootStore.materialReports;
  }

  @computed
  get hasNoScheduledReports() {
    return (
      !this.fetchingScheduledReports &&
      !this.materialReports.fetching &&
      !this.materialReports.hasModels
    );
  }

  @action.bound
  selectProjects(selectedOptions) {
    this.activeForm.update({
      projects: selectedOptions.map(option => {
        return {
          uuid: option.value,
          name: option.name
        };
      })
    });

    this.activeForm.validate();
  }

  @computed
  get projectIncludeOptions() {
    const options = [
      {
        label: t('Active projects'),
        value: 'ACTIVE',
        dataQA: 'active-projects'
      },
      {
        label: t('Active and inactive projects'),
        value: 'ACTIVE_AND_INACTIVE',
        dataQA: 'active-and-inactive-projects'
      },
      {
        label: t('Selected'),
        value: 'SELECTED',
        dataQA: 'selected-projects'
      }
    ];

    if (this.activeReportTab === 'scheduled') {
      return options.filter(option => {
        return option.value !== 'ACTIVE_AND_INACTIVE';
      });
    }

    return options;
  }

  @action.bound
  setProjectIncludeOption(value) {
    if (value === 'ACTIVE' || value === 'ACTIVE_AND_INACTIVE') {
      this.activeForm.update({
        projects: []
      });
    }

    this.activeForm.$('projectIncludeOption').set('value', value);

    this.activeForm.validate();
  }

  @computed get numberOfSelectedProjects() {
    return this.activeForm?.$('projects').values().length;
  }

  @computed
  get weekFirstDay() {
    return moment(new Date()).add(
      (this.company.preferences.weekFirstDay -
        moment(new Date()).isoWeekday() -
        7) %
        7,
      'days'
    );
  }

  @computed
  get weekLastDay() {
    return moment(this.weekFirstDay).add(6, 'days');
  }

  @computed
  get biWeeklyFirstDay() {
    return moment(this.weekFirstDay).subtract(7, 'days');
  }

  @computed get timeFrames() {
    let timeFrames = [];

    if (this.activeReportTab === 'runOnce') {
      timeFrames = [
        {
          id: 'DAILY',
          fromDate: moment(new Date()),
          toDate: moment(new Date()),
          title: t('Current day'),
          projectEntire: false
        },
        {
          id: 'WEEKLY',
          fromDate: this.weekFirstDay,
          toDate: this.weekLastDay,
          title: t('Current week'),
          projectEntire: false
        },
        {
          id: 'BI_WEEKLY',
          fromDate: this.biWeeklyFirstDay,
          toDate: this.weekLastDay,
          title: t('Bi-weekly'),
          projectEntire: false
        },
        {
          id: 'MONTHLY',
          fromDate: moment(new Date()).startOf('month'),
          toDate: moment(new Date()).endOf('month'),
          title: t('Current month'),
          projectEntire: false
        }
      ];
    } else {
      timeFrames = [
        {
          id: 'DAILY',
          title: t('Daily'),
          projectEntire: false
        },
        {
          id: 'WEEKLY',
          title: t('Weekly'),
          projectEntire: false
        },
        {
          id: 'BI_WEEKLY',
          title: t('Bi-weekly'),
          projectEntire: false
        },
        {
          id: 'MONTHLY',
          title: t('Monthly'),
          actionDate: moment(new Date()).isoWeekday(8),
          projectEntire: false
        }
      ];
    }

    if (this.numberOfSelectedProjects === 1) {
      timeFrames.push({
        id: 'PROJECTENTIRE',
        title: t('Entire duration of the project'),
        projectEntire: true
      });
    }

    if (this.activeReportTab === 'runOnce') {
      timeFrames.push({
        id: 'SPECTIMEFRAME',
        title: t('Specific time frame'),
        projectEntire: false
      });
    }

    return timeFrames;
  }

  @computed get selectedTimeFrameOption() {
    return this.timeFrames.find(
      timeFrame => timeFrame.id === this.activeForm.$('timeFrameType').value
    );
  }

  @action.bound
  setTimeFrame(timeFrame) {
    const fromDate = timeFrame.fromDate
      ? moment(timeFrame.fromDate).format('YYYY-MM-DD')
      : '';

    const toDate = timeFrame.toDate
      ? moment(timeFrame.toDate).format('YYYY-MM-DD')
      : '';

    const actionDate = timeFrame.actionDate
      ? moment(timeFrame.actionDate).format('YYYY-MM-DD')
      : '';

    const projectEntire = timeFrame.id === 'PROJECTENTIRE' ? true : false;

    this.activeForm.update({
      timeFrameType: timeFrame.id,
      fromDate: fromDate,
      toDate: toDate,
      projectEntire: projectEntire,
      actionDate: actionDate
    });
  }

  @action.bound setFromDate(value) {
    this.activeForm.$('fromDate').set(moment(value).format('YYYY-MM-DD'));
  }

  @action.bound setToDate(value) {
    this.activeForm.$('toDate').set(moment(value).format('YYYY-MM-DD'));
  }

  @action.bound setActionDate(value) {
    this.activeForm.$('actionDate').set(moment(value).format('YYYY-MM-DD'));
  }

  @action.bound
  submitProductionQuantitiesReportForm(event) {
    event.preventDefault();

    if (this.activeReportTab === 'scheduled' && !this.scheduledReportToEdit) {
      this.createNewScheduledReport();
      return;
    }

    if (this.saving) return;

    this.clearValidationDetails();

    this.activeForm.submit({
      onSuccess: this.submitProductionQuantitiesReportFormSuccess,
      onError: this.submitProductionQuantitiesReportFormError
    });
  }

  @action.bound submitProductionQuantitiesReportFormSuccess() {
    if (this.activeReportTab === 'runOnce') {
      this.submitProductionQuantitiesRunOnceReport();
    } else {
      this.submitProductionQuantitiesScheduledReport();
    }
  }

  @action.bound submitProductionQuantitiesReportFormError() {
    console.error(this.activeForm.errors());
  }

  @action.bound async submitProductionQuantitiesRunOnceReport() {
    this.saving = true;

    const values = this.activeForm.values();

    const cleanedValues = pickBy(
      values,
      value => value !== null && (value !== undefined) & (value !== '')
    );

    try {
      await request.post(
        `${this.rootStore.urlMicroService(
          'performanceTracking'
        )}/material/reports/run-once`,
        cleanedValues
      );

      callTrack(QUANTITIES_REPORT_DOWNLOADED);

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Your download request has been sent.')
      });

      this.hideProductionQuantitiesReportModal();
    } catch (error) {
      this.saving = false;
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound async submitProductionQuantitiesScheduledReport() {
    this.saving = true;

    const values = this.activeForm.values();
    const hoursMins = values.time.split(':');

    values.time = {
      hour: parseInt(hoursMins[0], 10),
      minute: parseInt(hoursMins[1], 10)
    };

    if (values.timeFrameType === 'PROJECTENTIRE') {
      values.timeFrameType = 'DAILY';
    }

    const cleanedValues = pickBy(
      values,
      value => value !== null && (value !== undefined) & (value !== '')
    );

    const method = this.scheduledReportToEdit.isNew ? 'post' : 'put';

    const notificationMessage = this.scheduledReportToEdit.isNew
      ? t('Scheduled report created.')
      : t('Scheduled report updated.');

    try {
      await this.scheduledReportToEdit.save(cleanedValues, {
        method,
        wait: true
      });

      callTrack(QUANTITIES_REPORT_SCHEDULED, {
        frequency: values.timeFrameType
      });

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: notificationMessage
      });

      this.fetchScheduled();
      this.cancelEditingReport();
    } catch (error) {
      this.saving = false;
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound
  createNewScheduledReport() {
    // Use a new model to hold our report when creating a new one.
    this.scheduledReportToEdit = new MaterialReport(
      {},
      {
        rootStore: this.rootStore
      }
    );

    this.setupModalForm();
  }

  @action.bound
  cancelEditingReport() {
    this.activeForm = null;
    this.scheduledReportToEdit = null;
    this.saving = false;
  }

  @computed get fromDateLabel() {
    if (this.activeReportTab === 'runOnce') return null;

    switch (this.activeForm.$('timeFrameType').value) {
      case 'WEEKLY':
        return t('Start of week');
      case 'BI_WEEKLY':
        return t('Start of bi-weekly period');
      default:
        return t('From date');
    }
  }

  @computed get toDateLabel() {
    if (this.activeReportTab === 'runOnce') return null;

    switch (this.activeForm.$('timeFrameType').value) {
      case 'WEEKLY':
        return t('End of week');
      case 'BI_WEEKLY':
        return t('End of bi-weekly period');
      default:
        return t('From date');
    }
  }

  @computed get scheduleText() {
    switch (this.activeForm.$('timeFrameType').value) {
      case 'MONTHLY':
        return t('This report will be sent monthly starting:');
      case 'WEEKLY':
        return t(
          'This report will be sent at the end of your weekly period starting:'
        );
      case 'BI_WEEKLY':
        return t(
          'This report will be sent at the end of your bi-weekly period starting:'
        );
      default:
        return t('This report will be sent daily at:');
    }
  }

  @computed
  get enableSubmit() {
    if (!this.scheduledReportToEdit && this.activeReportTab === 'scheduled') {
      return true;
    }

    if (!this.activeForm || this.activeForm.isPristine || this.saving) {
      return false;
    }

    return this.activeForm.isValid;
  }

  @action.bound
  editScheduledReport(report) {
    this.scheduledReportToEdit = report;
    this.initReportForm();
  }

  @action.bound
  async deleteScheduledReport(report) {
    try {
      await report.destroy({ wait: true });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  @action.bound
  handleScheduledReportCancelButton() {
    this.scheduledReportToEdit
      ? this.cancelEditingReport()
      : this.hideProductionQuantitiesReportModal();
  }

  @computed get restrictSpecificTimeframe() {
    return (
      this.activeForm.$('projects').size > 5 &&
      this.activeForm.$('timeFrameType').value === 'SPECTIMEFRAME'
    );
  }

  @computed
  get maximumToDate() {
    const fromDate = this.activeForm.$('fromDate').value;

    if (!fromDate || this.numberOfSelectedProjects <= 1) return undefined;

    if (this.numberOfSelectedProjects > 5) {
      return moment(fromDate, 'YYYY-MM-DD').add(3, 'months');
    }

    return moment(fromDate, 'YYYY-MM-DD').add(1, 'year');
  }
}
