import request from 'axios';
import { observable, action, computed } from 'mobx';
import { t } from 'utils/translate';
import history from 'utils/history';
import UIStore from './UIStore';
import alertErrorHandler from 'utils/alertErrorHandler';
import TeamWorkersActionsProjectsUI from './TeamWorkersActionsProjectsUI';
import download from 'utils/download';

import WorkerPromoteUI from './WorkerPromoteUI';
export default class TeamWorkersActionsUI extends UIStore {
  @observable selectedWorker;
  @observable selectedWorkerAction;
  @observable selectedWorkerClassification;
  @observable promotedWorker;

  constructor(options) {
    super(options);

    this.selectedWorker = null;
    this.selectedWorkerAction = null;
    this.selectedWorkerClassification;

    // Bulk Actions
    this.selectedWorkers = observable([], { deep: false });

    // Bulk Project Actions
    this.teamWorkersActionsProjectsUI = new TeamWorkersActionsProjectsUI({
      parent: this,
      rootStore: this.rootStore
    });

    // Promote worker
    this.workerPromoteUI = new WorkerPromoteUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @computed get workers() {
    return this.parent.workers;
  }

  getWorkerActions = worker => {
    let actions = [
      {
        title: t('View profile'),
        onClick: () => {
          this.showWorkerProfile(worker);
        }
      }
    ];

    switch (worker.status) {
      case 'INVITED':
        actions = [
          ...actions,
          ...(this.authorization.hasTimeClockAccess && !worker.invitesDisabled
            ? [
                {
                  title: 'Resend invite',
                  disabled: worker.lastLogin,
                  onClick: () => {
                    this.workerAction('RESEND_INVITE', worker);
                  }
                },
                {
                  title: t('Remove invite'),
                  onClick: () => {
                    this.workerAction('REMOVE_INVITE', worker);
                  },
                  disabled: worker.isMe
                }
              ]
            : [])
        ];
        break;
      case 'INACTIVE':
      case 'DELETED':
        actions.push({
          title: t('Make active'),
          onClick: () => {
            this.workerAction('REACTIVATE', worker);
          }
        });
        break;
      case 'ACTIVE':
        actions = [
          ...actions,
          {
            title: t('Make inactive'),
            onClick: () => {
              this.workerAction('DEACTIVATE', worker);
            }
          },
          {
            title: t('Promote Worker'),
            onClick: () => {
              this.promoteWorker(worker);
            }
          },
          ...(this.authorization.hasTimeClockAccess &&
          !worker.settings.blockTimeClock
            ? [
                {
                  title: t('Block from time clock'),
                  onClick: () => {
                    this.workerAction('BLOCK', worker);
                  }
                }
              ]
            : []),
          ...(this.authorization.hasTimeClockAccess &&
          worker.settings.blockTimeClock
            ? [
                {
                  title: t('Unblock from time clock'),
                  onClick: () => {
                    this.workerAction('UNBLOCK', worker);
                  }
                }
              ]
            : []),
          {
            title: t('Delete'),
            onClick: () => {
              this.workerAction('DELETE', worker);
            }
          },
          ...(this.authorization.hasTimeClockAccess && !worker.invitesDisabled
            ? [
                {
                  title: t('Send Invite'),
                  onClick: () => {
                    this.sendInvite(worker);
                  }
                }
              ]
            : [])
        ];
    }

    return actions;
  };

  @computed get workerActionTitle() {
    if (!this.selectedWorker) return null;

    switch (this.selectedWorkerAction) {
      case 'DELETE':
        return t(`Delete worker?`);
      case 'DEACTIVATE':
        return t(`Deactivate worker?`);
      case 'REACTIVATE':
        return t(`Reactivate worker?`);
      case 'BLOCK':
        return t(`Block worker from time clock?`);
      case 'UNBLOCK':
        return t(`Unblock worker from time clock?`);
      case 'RESEND_INVITE':
        return t(`Resend invite?`);
      case 'REMOVE_INVITE':
        return t(`Remove invite?`);
      default:
        return '';
    }
  }

  @computed get workerActionText() {
    if (!this.selectedWorker) return null;

    switch (this.selectedWorkerAction) {
      case 'DELETE':
        return t(
          `You are attempting to delete a worker from your account. Doing so will remove their project assignments permanently. Would you like to proceed?`
        );
      case 'DEACTIVATE':
        return t(
          `You are attempting to deactivate a worker. Doing so will remove them from the list of available workers on your projects, but will maintain their project assignments in case they need to be reactivated. Would you like to proceed?`
        );
      case 'REACTIVATE':
        return t(
          `You are attempting to reactivate a worker. Doing so will make them available on assigned projects for your team. Would you like to proceed?`
        );
      case 'RESEND_INVITE':
        return t(
          `You are attempting to resend the invite for a worker. Doing so will send them a new invitation email. Would you like to proceed?`
        );
      case 'REMOVE_INVITE':
        return t(
          `You are attempting to remove an invite for a worker. Doing so will make any invite they have received invalid. Would you like to proceed?`
        );
      case 'BLOCK':
        return t(
          `You are attempting to block a worker from the time clock. Doing so will revoke their access from using the time clock through the mobile application. You can re enable their access at any time. Would you like to proceed?`
        );
      case 'UNBLOCK':
        return t(
          `You are attempting to unblock a worker from the time clock. Doing so will give this user access to the time clock through the mobile application. Would you like to proceed?`
        );
      default:
        return '';
    }
  }

  @computed get workerActionButtonColor() {
    switch (this.selectedWorkerAction) {
      case 'DELETE':
      case 'DEACTIVATE':
      case 'REMOVE_INVITE':
      case 'BLOCK':
      case 'UNBLOCK':
        return 'red';
      default:
        return 'orange';
    }
  }

  @computed get workerActionButtonText() {
    switch (this.selectedWorkerAction) {
      case 'DELETE':
        return t(`Delete`);
      case 'DEACTIVATE':
        return t(`Deactivate`);
      case 'REACTIVATE':
        return t(`Reactivate`);
      case 'RESEND_INVITE':
        return t(`Resend`);
      case 'REMOVE_INVITE':
        return t(`Remove`);
      case 'BLOCK':
        return t(`Block from time clock`);
      case 'UNBLOCK':
        return t(`Unblock from time clock`);
      default:
        return '';
    }
  }

  @computed get workerActionButtonTextActive() {
    switch (this.selectedWorkerAction) {
      case 'DELETE':
        return t(`Deleting...`);
      case 'DEACTIVATE':
        return t(`Deactivating...`);
      case 'REACTIVATE':
        return t(`Reactivating...`);
      case 'RESEND_INVITE':
        return t(`Resening...`);
      case 'REMOVE_INVITE':
        return t(`Removing...`);
      case 'BLOCK':
        return t(`Blocking ...`);
      case 'UNBLOCK':
        return t(`Unblocking ...`);
      default:
        return '';
    }
  }

  @computed get workerActionNotificationText() {
    switch (this.selectedWorkerAction) {
      case 'DELETE':
        return t(`Worker deleted`);
      case 'DEACTIVATE':
        return t(`Worker deactivated`);
      case 'REACTIVATE':
        return t(`Worker reactivated`);
      case 'RESEND_INVITE':
        return t(`Invite sent`);
      case 'REMOVE_INVITE':
        return t(`Invite removed`);
      case 'BLOCK':
        return t(`Team member blocked`);
      case 'UNBLOCK':
        return t(`Team member unblocked`);
      default:
        return '';
    }
  }

  @action.bound async workerAction(action, worker) {
    if (action === 'RESEND_INVITE' || action === 'REMOVE_INVITE') {
      await this.authorization.checkFeatureAccess('CUDCompanyInvites');
    } else {
      await this.authorization.checkFeatureAccess('EditWorkers');
    }

    this.selectedWorker = worker;
    this.selectedWorkerAction = action;
    this.showModal('WorkerActionModal');
  }

  @action.bound
  async cancelWorkerAction() {
    await this.hideActiveModal();
    this.selectedWorker = null;
    this.selectedWorkerAction = null;
  }

  @action.bound
  async confirmWorkerAction() {
    try {
      this.clearValidationDetails();
      this.saving = true;

      switch (this.selectedWorkerAction) {
        case 'DELETE':
          await this.selectedWorker.save(
            {
              status: 'DELETED'
            },
            { wait: true }
          );

          if (
            !this.parent.statusFilters
              .map(option => option.id)
              .includes('DELETED')
          ) {
            this.selectedWorker.collection.remove(this.selectedWorker);
          }
          break;
        case 'DEACTIVATE':
          await this.selectedWorker.save(
            {
              status: 'INACTIVE'
            },
            { wait: true }
          );

          if (
            !this.parent.statusFilters
              .map(option => option.id)
              .includes('INACTIVE')
          ) {
            this.selectedWorker.collection.remove(this.selectedWorker);
          }
          break;
        case 'BLOCK':
        case 'UNBLOCK':
          await this.selectedWorker.save(
            {
              settings: {
                blockTimeClock:
                  this.selectedWorkerAction === 'BLOCK' ? true : false
              }
            },
            { wait: true, stripNonRest: false }
          );
          break;
        case 'REACTIVATE':
          await this.reactivateWorker();
          break;
        case 'RESEND_INVITE':
          await this.resendInvite();
          break;
        case 'REMOVE_INVITE':
          await this.removeInvite();
      }

      this.parent.loading = true;

      if (!this.parent.hasWorkers) {
        this.parent.setPage(null, 1);
        this.parent.fetchWorkers();
      } else {
        this.parent.fetchWorkers();
      }

      await this.hideActiveModal();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: this.workerActionNotificationText
      });

      this.selectedWorker = null;
      this.selectedWorkerAction = null;
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  async resendInvite() {
    try {
      request.post(
        `/ra/companies/${this.rootStore.me.company.uuid}/workers/invite`,
        {
          uuids: [this.selectedWorker.uuid]
        }
      );
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound
  async removeInvite() {
    try {
      await request.delete(`ra/invite/${this.selectedWorker.inviteId}`);

      this.refetchWorkers();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound async reactivateWorker() {
    const showWorkerProjects = this.selectedWorker.status === 'DELETED';

    await this.selectedWorker.save(
      {
        status: 'ACTIVE'
      },
      { wait: true }
    );

    if (showWorkerProjects) {
      history.push(`${this.selectedWorker.viewUrl}/projects`);
    }
  }

  @action.bound showWorkerProfile(worker) {
    history.push(`${worker.viewUrl}/info`);
  }

  // Bulk Actions
  @computed get bulkWorkerActions() {
    let actions = [];

    if (
      this.hasSelectedActiveWorkers ||
      (this.authorization.hasTimeClockAccess && this.hasSelectedInvitedWorkers)
    ) {
      actions.push({
        title: t('Delete selected workers'),
        onClick: () => {
          this.bulkWorkerAction('DELETE');
        }
      });
    }

    if (this.hasSelectedWorkers) {
      actions.push({
        title: t('Change classification'),
        onClick: () => {
          this.bulkWorkerAction('CHANGE_CLASSIFICATION');
        }
      });

      actions.push({
        title: t('Add to projects'),
        onClick: () => {
          this.bulkWorkerAction('ADD_PROJECTS');
        }
      });
    }

    if (
      this.authorization.hasTimeClockAccess &&
      this.selectedWorkers.length !== this.selectedInvitedWorkers.length &&
      !this.selectedWorkers.every(model => model.invitesDisabled)
    ) {
      actions.push({
        title: t('Send invite'),
        onClick: () => {
          this.bulkWorkerAction('SEND_INVITE');
        }
      });
    }

    actions.push({
      title: this.hasNoSelectedWorkers
        ? t('Export all workers')
        : t('Export selected workers'),
      onClick: () => {
        this.exportWorkers();
      }
    });

    if (
      this.authorization.hasTimeClockAccess &&
      this.hasSelectedInvitedWorkers &&
      !this.selectedWorkers.every(model => model.invitesDisabled)
    ) {
      actions.push({
        title: t('Resend invite'),
        onClick: () => {
          this.bulkWorkerAction('RESEND_INVITE');
        }
      });

      actions.push({
        title: t('Remove invite'),
        onClick: () => {
          this.bulkWorkerAction('REMOVE_INVITE');
        }
      });
    }

    return actions;
  }

  @computed get hasSelectedWorkers() {
    return this.selectedWorkers.length > 0;
  }

  @computed get hasNoSelectedWorkers() {
    return this.selectedWorkers.length === 0;
  }

  @computed get hasSelectedActiveWorkers() {
    return this.selectedWorkers.filter(model => model.status === 'ACTIVE')
      .length;
  }

  @computed get selectedInvitedWorkers() {
    return this.selectedWorkers.filter(
      model => model.status === 'INVITED' || model.invitesDisabled
    );
  }

  @computed get hasSelectedInvitedWorkers() {
    return this.selectedInvitedWorkers.length;
  }

  findSelectedWorker = worker => {
    return this.selectedWorkers.find(
      selectedWorker => selectedWorker.uuid === worker.uuid
    );
  };

  @computed get allWorkersSelected() {
    if (!this.hasSelectedWorkers) return false;

    return (
      this.workers.models.filter(worker => {
        return this.findSelectedWorker(worker);
      }).length === this.workers.length
    );
  }

  @action.bound toggleSelectAllWorkers() {
    if (this.allWorkersSelected) {
      this.workers.models.forEach(worker => {
        this.selectedWorkers.remove(this.findSelectedWorker(worker));
      });
    } else {
      this.workers.models.forEach(worker => {
        if (!this.findSelectedWorker(worker)) {
          this.selectedWorkers.push(worker);
        }
      });
    }
  }

  @action.bound toggleSelectWorker(worker) {
    const selectedWorker = this.findSelectedWorker(worker);

    if (selectedWorker) {
      this.selectedWorkers.remove(selectedWorker);
    } else {
      this.selectedWorkers.push(worker);
    }
  }

  @action.bound async bulkWorkerAction(action) {
    switch (action) {
      case 'RESEND_INVITE':
      case 'REMOVE_INVITE':
      case 'SEND_INVITE':
        await this.authorization.checkFeatureAccess('CUDCompanyInvites');
        break;
      default:
        await this.authorization.checkFeatureAccess('EditWorkers');
        break;
    }

    if (action === 'SEND_INVITE') {
      const uuids = this.selectedWorkers
        .filter(
          worker => worker.status !== 'INVITED' && !worker.invitesDisabled
        )
        .map(worker => {
          return worker.uuid;
        });

      await this.company.fetch();

      if (
        this.company.workerSeatsInUse + uuids.length >=
        this.company.companyAddOns?.timeClockWorkerSeats
      ) {
        this.showModal('WorkerLicenseCheckModal');
        return;
      }
    }

    this.selectedWorkerAction = action;
    this.showModal('WorkerBulkActionModal');
  }

  @action.bound async cancelBulkWorkerAction() {
    await this.hideActiveModal();
    this.selectedWorkerAction = null;
  }

  @computed get bulkWorkerActionTitle() {
    if (!this.selectedWorkerAction) return null;

    switch (this.selectedWorkerAction) {
      case 'DELETE':
        return t(`Delete workers?`);
      case 'CHANGE_CLASSIFICATION':
        return t(`Change classification?`);
      case 'ADD_PROJECTS':
        return t(`Add to projects?`);
      case 'REMOVE_INVITE':
        return t(`Remove invites?`);
      case 'RESEND_INVITE':
        return t(`Resend invites?`);
      case 'SEND_INVITE':
        return t(`Send invites?`);
      default:
        return '';
    }
  }

  @computed get bulkWorkerActionText() {
    if (!this.selectedWorkerAction) return null;

    switch (this.selectedWorkerAction) {
      case 'DELETE':
        return t(
          `You are attempting to delete a one or more workers from your account. Doing so will remove their project assignments permanently. Would you like to proceed?`
        );
      case 'CHANGE_CLASSIFICATION':
        return t(
          `You are attempting to change the classification for one or more workers. Would you like to proceed?`
        );
      case 'REMOVE_INVITE':
        return t(
          `You are attempting to remove the invite for one or more workers. Doing so will make any invite they have received invalid. Would you like to proceed?`
        );
      case 'RESEND_INVITE':
        return t(
          `You are attempting to resend the invite for one or more workers. Doing so will send them a new invitation email. Would you like to proceed?`
        );
      case 'SEND_INVITE':
        return t(
          `You are attempting to send the invite for one or more workers. Doing so will send them a new invitation email. Would you like to proceed?`
        );
      default:
        return '';
    }
  }

  @computed get bulkWorkerActionNoOperationText() {
    if (!this.selectedWorkerAction) return null;

    switch (this.selectedWorkerAction) {
      case 'DELETE':
        return t(`Any selected inactive or deleted workers will be ignored.`);
      case 'REMOVE_INVITE':
        return t(
          `Any selected workers that have accepted their invite will be ignored.`
        );
      case 'RESEND_INVITE':
        return t(
          `Any selected workers that have accepted their invite will be ignored.`
        );
      default:
        return '';
    }
  }

  @computed get bulkWorkerActionButtonColor() {
    switch (this.selectedWorkerAction) {
      case 'DELETE':
      case 'REMOVE_INVITE':
        return 'red';
      default:
        return 'orange';
    }
  }

  @computed get bulkWorkerActionButtonDisabled() {
    switch (this.selectedWorkerAction) {
      case 'CHANGE_CLASSIFICATION':
        return this.saving || !this.selectedWorkerClassification;

      default:
        return this.saving;
    }
  }

  @computed get bulkWorkerActionButtonText() {
    switch (this.selectedWorkerAction) {
      case 'DELETE':
        return t(`Delete`);
      case 'CHANGE_CLASSIFICATION':
        return t(`Save`);
      case 'ADD_PROJECTS':
        return t(`Save`);
      case 'REMOVE_INVITE':
        return t(`Remove`);
      case 'RESEND_INVITE':
        return t(`Resend`);
      case 'SEND_INVITE':
        return t(`Send`);
      default:
        return '';
    }
  }

  @computed get bulkWorkerActionButtonTextActive() {
    switch (this.selectedWorkerAction) {
      case 'DELETE':
        return t(`Deleting...`);
      case 'CHANGE_CLASSIFICATION':
        return t(`Saving...`);
      case 'ADD_PROJECTS':
        return t(`Saving...`);
      case 'REMOVE_INVITE':
        return t(`Removing...`);
      case 'RESEND_INVITE':
        return t(`Resending...`);
      case 'SEND_INVITE':
        return t(`Sending...`);
      default:
        return '';
    }
  }

  @computed get bulkWorkerActionNotificationText() {
    switch (this.selectedWorkerAction) {
      case 'DELETE':
        return t(`Workers deleted`);
      case 'CHANGE_CLASSIFICATION':
        return t(`Classifications updated`);
      case 'ADD_PROJECTS':
        return t(`Workers added to projects`);
      case 'REMOVE_INVITE':
        return t(`Invites removed`);
      case 'RESEND_INVITE':
      case 'SEND_INVITE':
        return t(`Invites sent`);
      default:
        return '';
    }
  }

  @action.bound async confirmBulkWorkerAction() {
    this.clearValidationDetails();
    this.saving = true;

    try {
      switch (this.selectedWorkerAction) {
        case 'DELETE':
          await this.bulkDelete();
          break;
        case 'CHANGE_CLASSIFICATION':
          await this.bulkChangeClassification();
          break;
        case 'RESEND_INVITE':
          await this.bulkResendInvites();
          break;
        case 'REMOVE_INVITE':
          await this.bulkRemoveInvites();
          break;
        case 'ADD_PROJECTS':
          await this.bulkAddProjects();
          break;
        case 'SEND_INVITE':
          await this.bulkSendInvites();
          break;
      }

      this.parent.loading = true;
      this.parent.setPage(null, 1);
      this.parent.fetchWorkers();

      await this.hideActiveModal();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: this.bulkWorkerActionNotificationText
      });

      this.selectedWorkers.clear();
      this.selectedWorkerAction = null;
      this.selectedWorkerClassification = null;
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound async bulkDelete() {
    const payload = this.selectedWorkers.map(worker => {
      return {
        uuid: worker.uuid,
        status: 'DELETED'
      };
    });

    return await request.patch(`${this.workers.url()}/batch`, payload);
  }

  @action.bound async bulkSendInvites() {
    const uuids = this.selectedWorkers
      .filter(worker => worker.status !== 'INVITED' && !worker.invitesDisabled)
      .map(worker => {
        return worker.uuid;
      });
    try {
      return await request.post(
        `/ra/companies/${this.rootStore.me.company.uuid}/workers/invite`,
        {
          uuids
        }
      );
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @computed get bulkWorkerActionClassificationOptions() {
    return this.parent.classificationOptions;
  }

  @action.bound setSelectedWorkerClassification(option) {
    this.selectedWorkerClassification = option;
  }

  @action.bound async bulkChangeClassification() {
    const payload = this.selectedWorkers.map(worker => {
      return {
        uuid: worker.uuid,
        classification: {
          uuid: this.selectedWorkerClassification.uuid
        }
      };
    });

    return await request.patch(`${this.workers.url()}/batch`, payload);
  }

  @action.bound async bulkAddProjects() {
    await this.teamWorkersActionsProjectsUI.addWorkersToProjects();
  }

  @action.bound async bulkResendInvites() {
    const uuids = this.selectedWorkers
      .filter(worker => worker.status === 'INVITED' && !worker.invitesDisabled)
      .map(worker => {
        return worker.uuid;
      });
    try {
      return await request.post(
        `/ra/companies/${this.rootStore.me.company.uuid}/workers/invite`,
        {
          uuids
        }
      );
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound async bulkRemoveInvites() {
    const requests = [];

    this.selectedWorkers
      .filter(worker => worker.status === 'INVITED' && !worker.invitesDisabled)
      .forEach(worker => {
        requests.push(request.delete(`ra/invite/${worker.inviteId}`));
      });

    return await Promise.all(requests);
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.selectedWorker = null;
    this.selectedWorkerAction = null;
    this.selectedWorkerClassification = null;
    this.selectedWorkers.clear();
  }

  @computed get roleOptions() {
    return [
      {
        value: 'ROLE_ACCOUNT_ADMIN',
        name: t('Account Administrator')
      },
      {
        value: 'ROLE_ADMIN',
        name: t('Project Administrator')
      },
      {
        value: 'ROLE_PROJECT_MEMBER',
        name: t('Project Member')
      },
      {
        value: 'ROLE_USER',
        name: t('User')
      }
    ];
  }

  @action.bound
  promoteWorker(worker) {
    this.authorization.checkFeatureAccess('PromoteWorkers').then(() => {
      this.promotedWorker = worker;
      this.workerPromoteUI.setup();
    });
  }

  @action.bound
  refetchWorkers() {
    this.parent.loading = true;
    if (!this.parent.hasWorkers) {
      this.parent.setPage(null, 1);
      this.parent.fetchWorkers();
    } else {
      this.parent.fetchWorkers();
    }
  }

  @action.bound
  exportWorkers() {
    download({
      store: this.rootStore,
      url: `${this.rootStore.apiURL}/ra/companies/${this.rootStore.me.company.uuid}/members/csv`,
      xhttpOptions: {
        sendXApiKey: true
      },
      data: {
        uuids: this.selectedWorkers.map(worker => worker.uuid),
        type: 'WORKER'
      }
    });
  }

  @action.bound
  async sendInvite(worker) {
    await this.authorization.checkFeatureAccess('CUDCompanyInvites');

    await this.company.fetch();

    if (
      this.company.workerSeatsInUse >=
      this.company.companyAddOns?.timeClockWorkerSeats
    ) {
      this.showModal('WorkerLicenseCheckModal');
      return;
    }

    try {
      await request.post(`/ra/companies/${this.company.uuid}/workers/invite`, {
        uuids: [worker.uuid]
      });

      this.refetchWorkers();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: `Invite sent`
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound cancelInviteWorker() {
    this.cancelBulkWorkerAction();
  }
}
