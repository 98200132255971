import styled from 'styled-components';

export default styled.p`
  color: ${props =>
    props.color ? props.theme.colors[props.color] : 'inherit'};
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => (props.large ? 16 : props.small ? 12 : 14)}px;
  font-weight: 300;
  line-height: ${props => (props.large ? 24 : props.small ? 14 : 20)}px;
  margin: 0 0 ${props => (props.large ? 20 : 10)}px;
  text-align: ${props => props.align || 'inherit'};
  text-transform: ${props => (props.transform ? props.transform : 'inherit')};
  word-wrap: break-word;
  &:last-child {
    margin-bottom: 0;
  }
`;
