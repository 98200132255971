import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { action, observable, computed, reaction } from 'mobx';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import moment from 'moment-timezone';

import TimeCardEvents from 'stores/collections/TimeCardEvents';

import ProjectChildUI from './ProjectChildUI';

import MapInfoWindow from 'pages/project/components/map/MapInfoWindow';
import { t } from 'utils/translate';

import { callTrack } from 'utils/segmentIntegration';

import {
  EVENT_MAP_OPENED,
  EVENT_MAP_POPPER_OPENED
} from 'utils/segmentAnalytics/eventSpec';

export default class MapUI extends ProjectChildUI {
  @observable map;
  @observable timeFrameFilter;

  constructor(options) {
    super(options);

    this.google = window.google;
    this.map = null;
    this.mapRef = null;

    this.clusterer = null;

    // TimeCardEvents collection
    this.timeCardEvents = new TimeCardEvents(null, {
      parent: this,
      rootStore: this.rootStore
    });

    // Request Params
    this.timeFrameFilter = {
      title: t('Last 24 hours'),
      value: '24_HOURS'
    };

    this.eventTypeFilters = observable([]);
    this.workerFilters = observable([]);
  }

  @action.bound
  async setup(mapRef) {
    this.mapRef = mapRef;

    var styles = [
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }]
      }
    ];

    this.map = new this.google.maps.Map(this.mapRef, {
      zoomControl: true,
      animatedZoom: false,
      mapTypeControl: false,
      fullscreenControl: false,
      clickableIcons: false,
      center: this.project.address.geolocation,
      zoom: 15,
      zoomControlOptions: {
        position: this.google.maps.ControlPosition.LEFT_BOTTOM
      },
      streetViewControl: true,
      streetViewControlOptions: {
        position: this.google.maps.ControlPosition.LEFT_BOTTOM
      },
      gestureHandling: 'cooperative',
      styles
    });

    this.setupReactions();
    this.memberSelectorUI.setup({ companySettingsTrackMemberTime: true });

    callTrack(EVENT_MAP_OPENED);

    this.google.maps.event.addListener(
      this.map,
      'idle',
      this.fetchTimeCardEvents
    );
  }

  @action.bound
  tearDown() {
    this.google.maps.event.clearListeners(this.map, 'idle');
    this.tearDownReactions();
    this.memberSelectorUI.tearDown();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        this.fetchTimeCardEvents();
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed get params() {
    return {
      ...this.timeFrameParams,
      eventTypes: this.eventTypeFilters.map(event => event.value).join(),
      workerUuids: this.workerFilters.map(event => event.workerUuid).join()
    };
  }

  @action.bound async fetchTimeCardEvents() {
    await this.timeCardEvents.fetch({
      params: this.params
    });

    if (this.clusterer) {
      this.clearPins();
    }

    this.clusterer = new MarkerClusterer({
      map: this.map,
      renderer: {
        render({ count, position }, stats) {
          // Set cluster to always stay as one color and override default changing color.
          const color = '#0000ff';
          // create svg url with fill color
          const svg = window.btoa(`
  <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
    <circle cx="120" cy="120" opacity=".6" r="70" />
    <circle cx="120" cy="120" opacity=".3" r="90" />
    <circle cx="120" cy="120" opacity=".2" r="110" />
  </svg>`);
          // create marker using svg icon
          return new window.google.maps.Marker({
            position,
            icon: {
              url: `data:image/svg+xml;base64,${svg}`,
              scaledSize: new window.google.maps.Size(45, 45)
            },
            label: {
              text: String(count),
              color: 'rgba(255,255,255,0.9)',
              fontSize: '12px'
            },
            title: `Cluster of ${count} markers`,
            // adjust zIndex to be above other markers
            zIndex: Number(window.google.maps.Marker.MAX_ZINDEX) + count
          });
        }
      }
    });

    this.dropPins();
  }

  clearPins() {
    this.timeCardEvents.models.forEach(event => {
      event.pin = null;
    });

    this.clusterer.clearMarkers();
  }

  dropPins() {
    this.timeCardEvents.models.forEach(event => {
      const pin = this.addPin(event);
      event.pin = pin;
    });
  }

  pinIsInsideProjectRadius(workerEvent) {
    return (
      this.google.maps.geometry.spherical.computeDistanceBetween(
        {
          lat: parseFloat(workerEvent.latitude),
          lng: parseFloat(workerEvent.longitude)
        },
        this.project.address?.geolocation
      ) < this.project.projectRadius
    );
  }

  addPin(workerEvent) {
    const pin = new this.google.maps.Marker({
      position: {
        lat: parseFloat(workerEvent.latitude),
        lng: parseFloat(workerEvent.longitude)
      },
      map: this.map,
      icon: this.pinIsInsideProjectRadius(workerEvent)
        ? `${this.assetsURL}/svg/map-pin-worker.svg`
        : `${this.assetsURL}/svg/map-pin.svg`
    });

    this.clusterer.addMarker(pin);

    const infowindow = new this.google.maps.InfoWindow({
      content: renderToStaticMarkup(<MapInfoWindow workerEvent={workerEvent} />)
    });

    pin.addListener('click', () => {
      callTrack(EVENT_MAP_POPPER_OPENED);
      infowindow.open({
        anchor: pin,
        map: this.map,
        shouldFocus: false
      });
    });

    pin.addListener('mouseover', () => {
      pin.setAnimation(this.google.maps.Animation.BOUNCE);
      setTimeout(() => {
        pin.setAnimation(null);
      }, 750);
    });

    pin.addListener('mouseout', () => {
      pin.setAnimation(null);
    });

    return pin;
  }

  @computed get timeFrameOptions() {
    return [
      {
        title: t('Last 24 hours'),
        value: '24_HOURS'
      },
      {
        title: t('Last week'),
        value: 'WEEK'
      },
      {
        title: t('Last month'),
        value: 'MONTH'
      },
      {
        title: t('Last 3 months'),
        value: '3_MONTHS'
      },
      {
        title: t('Past year'),
        value: 'YEAR'
      }
    ];
  }

  @computed get timeFrameParams() {
    switch (this.timeFrameFilter.value) {
      case '24_HOURS':
        return {
          fromDate: moment().format('YYYY-MM-DD'),
          toDate: moment().format('YYYY-MM-DD')
        };
      case 'WEEK':
        return {
          fromDate: (this.fromDate = moment()
            .subtract(7, 'days')
            .format('YYYY-MM-DD')),
          toDate: moment().format('YYYY-MM-DD')
        };
      case 'MONTH':
        return {
          fromDate: (this.fromDate = moment()
            .subtract(1, 'months')
            .format('YYYY-MM-DD')),
          toDate: moment().format('YYYY-MM-DD')
        };
      case '3_MONTHS':
        return {
          fromDate: (this.fromDate = moment()
            .subtract(3, 'months')
            .format('YYYY-MM-DD')),
          toDate: moment().format('YYYY-MM-DD')
        };
      case 'YEAR':
        return {
          fromDate: (this.fromDate = moment()
            .subtract(1, 'year')
            .format('YYYY-MM-DD')),
          toDate: moment().format('YYYY-MM-DD')
        };
      default:
        return {
          fromDate: moment().format('YYYY-MM-DD'),
          toDate: moment().format('YYYY-MM-DD')
        };
    }
  }

  @action.bound
  updateTimeFrameFilter(timeFrameOption) {
    this.timeFrameFilter = timeFrameOption;
  }

  @computed
  get eventTypeOptions() {
    return [
      {
        title: t('Clock In'),
        value: 'TIME_CARD_STARTED'
      },
      {
        title: t('Clock Out'),
        value: 'TIME_CARD_ENDED'
      },
      {
        title: t('Break Start'),
        value: 'BREAK_STARTED'
      },
      {
        title: t('Break End'),
        value: 'BREAK_ENDED'
      },
      {
        title: t('Crew Changed'),
        value: 'CREW_CHANGED'
      },
      {
        title: t('Task Changed'),
        value: 'TASK_CHANGED'
      }
    ];
  }

  @action.bound
  clearEventTypeFilters() {
    this.eventTypeFilters.clear();
  }

  @action.bound
  updateEventTypeFilters(timeCardEvents) {
    this.eventTypeFilters.replace(timeCardEvents);
  }

  @action.bound
  clearWorkerFilters() {
    this.workerFilters.clear();
  }

  @action.bound
  updateWorkerFilters(workers) {
    this.workerFilters.replace(workers);
  }

  @action.bound clearUIState() {
    this.fromDate = moment().format('YYYY-MM-DD');
    this.toDate = moment().format('YYYY-MM-DD');
    this.timeCardEvents.reset();
    this.timeFrameFilter = {
      title: t('Last 24 hours'),
      value: '24_HOURS'
    };
    this.workerFilters.clear();
    this.eventTypeFilters.clear();
    this.map = null;
  }
}
