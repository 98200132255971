import request from 'axios';
import moment from 'moment';
import { action, observable, computed } from 'mobx';
import ProjectChildUI from './ProjectChildUI';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import history from 'utils/history';

import {
  NoWorkDoneForm,
  noWorkDoneFormOptions,
  noWorkDoneFormFields,
  noWorkDoneFormRules,
  noWorkDoneFormLabels,
  noWorkDoneFormPlugins
} from 'forms/project/noWorkDone';

export default class NoWorkDoneUI extends ProjectChildUI {
  @observable noWorkDoneForm;
  @observable calendarFirstDayOfMonth;
  @observable fetchingReports;

  constructor(options) {
    super(options);

    this.noWorkDoneForm = null;
    // Calendar
    this.calendarFirstDayOfMonth = null;
    this.fetchingReports = false;
    this.reports = observable([]);
  }

  @action.bound setup() {
    this.noWorkDoneForm = new NoWorkDoneForm(
      {
        fields: noWorkDoneFormFields,
        rules: noWorkDoneFormRules,
        values: {
          fromDate: this.date,
          toDate: this.date
        },
        labels: noWorkDoneFormLabels
      },
      {
        options: noWorkDoneFormOptions,
        plugins: noWorkDoneFormPlugins
      }
    );
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  getCalendarReportStatus = report => {
    switch (report.status) {
      case 'COMPLETED':
        return 'signed';
      case 'UNSIGNED':
      case 'CREATED':
        return 'unsigned';
      default:
        return '';
    }
  };

  getCalendarDayStatus = momentObject => {
    if (momentObject.isSameOrAfter(moment(), 'day')) {
      return '';
    }

    if (momentObject.isBefore(moment(this.project.startDate), 'day')) {
      return '';
    }

    if (this.project.intervals.includes(momentObject.day() + 1)) {
      return 'missed';
    } else {
      return '';
    }
  };

  @computed get calendarFromDate() {
    return moment(this.calendarFirstDayOfMonth)
      .startOf('month')
      .format('YYYY-MM-DD');
  }

  @computed get calendarToDate() {
    return moment(this.calendarFirstDayOfMonth)
      .endOf('month')
      .format('YYYY-MM-DD');
  }

  @action.bound async handleCalendarMonthChange(firstDayOfMonth) {
    this.calendarFirstDayOfMonth = firstDayOfMonth;

    this.fetchingReports = true;

    try {
      const response = await request.get(
        `ra/companies/${this.company.uuid}/reports`,
        {
          params: {
            fromDate: this.calendarFromDate,
            toDate: this.calendarToDate,
            segmentUuids: this.segmentUuid,
            projectUuids: this.projectUuid,
            limit: 31
          }
        }
      );

      this.reports.replace(
        response.data.collection.map(report => {
          return {
            reportDate: report.reportDate,
            status: report.status
          };
        })
      );
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.fetchingReports = false;
    }

    return true;
  }

  @computed get calendarMonthReports() {
    const days = [];

    if (this.fetchingReports) return days;

    const currentDate = moment(this.calendarFromDate);
    const toDate = moment(this.calendarToDate);

    while (currentDate.isSameOrBefore(toDate)) {
      const relatedReport = this.reports.find(
        report => report.reportDate === currentDate.format('YYYY-MM-DD')
      );

      if (relatedReport) {
        days.push({
          date: currentDate.date(),
          status: this.getCalendarReportStatus(relatedReport)
        });
      } else {
        days.push({
          date: currentDate.date(),
          status: this.getCalendarDayStatus(currentDate)
        });
      }

      currentDate.add(1, 'days');
    }

    return days;
  }

  @action.bound submitNoWorkDoneForm(event) {
    event.preventDefault();

    this.noWorkDoneForm.submit({
      onSuccess: this.submitNoWorkDoneFormSuccess,
      onError: this.submitNoWorkDoneFormError
    });
  }

  @action.bound async submitNoWorkDoneFormSuccess() {
    this.saving = true;

    try {
      const { fromDate, toDate } = this.noWorkDoneForm.values();

      const range = moment().range(fromDate, toDate);
      const days = Array.from(range.by('days'));

      const reports = days.map(day => {
        return {
          projectUuid: this.projectUuid,
          reportDate: day.format('YYYY-MM-DD')
        };
      });

      await request.post(
        `ra/companies/${this.company.uuid}/reports/noworkdone`,
        {
          reports: reports
        }
      );

      this.cancelNoWorkDoneModal();

      this.refetchReportSummary();

      let title;

      if (reports.length > 1) {
        title = t('Reports marked as no work done');
      } else {
        title = t('Report marked as no work done');
      }

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: title
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitNoWorkDoneFormError() {
    console.error(this.noWorkDoneForm.errors());
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.noWorkDoneForm = null;
    this.calendarFirstDayOfMonth = null;
    this.reports.clear();
    this.fetchingReports = false;
  }

  @action.bound async showNoWorkDoneModal() {
    await this.authorization.checkFeatureAccess('MarkAsNoWorkDone');

    const pathname = history.location.pathname;

    history.push({
      pathname: pathname,
      search: `${this.baseQueryParams}&action=no-work-done`
    });
  }

  @action.bound async cancelNoWorkDoneModal() {
    const pathname = history.location.pathname;

    history.push({
      pathname: pathname,
      search: this.baseQueryParams
    });
  }

  @action.bound async markSingleDateAsNoWorkDone() {
    try {
      await request.post(
        `ra/companies/${this.company.uuid}/reports/noworkdone`,
        {
          reports: [
            {
              projectUuid: this.projectUuid,
              reportDate: this.date
            }
          ]
        }
      );

      this.refetchReportSummary();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Report marked as no work done')
      });
    } catch (error) {
      alertErrorHandler(error);
    }
  }
}
