import React from 'react';
import { observer } from 'mobx-react';

import {
  Dialog,
  Button,
  Box,
  Alert,
  TextField,
  Text
} from '@raken/athens-web/lib';

import { Form } from 'raken-ui';
import { t } from 'utils/translate';

export default observer(({ uiStore }) => {
  return (
    <Dialog
      dataQA="personal-info"
      open={uiStore.activeModal === 'personalInfo'}
      fullWidth={true}
    >
      <Dialog.Title dataQA="personal-info-header">
        {t('Your personal info')}
      </Dialog.Title>

      {uiStore.userForm && (
        <Form onSubmit={uiStore.submitPersonalInfoForm}>
          <Dialog.Content style={{ minHeight: 200 }}>
            <Box mb={6}>
              <Text>{t("Now let's confirm your personal info.")}</Text>
            </Box>

            {uiStore.hasValidationDetails && (
              <Box mb={6} width="100%">
                <Alert>
                  {uiStore.validationDetails.map(item => item.fieldMessage)}
                </Alert>
              </Box>
            )}

            <Box display="flex">
              <Box width="50%" mr={4}>
                <TextField
                  fullWidth
                  placeholder={t('First name')}
                  value={uiStore.userForm.$('firstName').value}
                  onChange={uiStore.userForm.$('firstName').sync}
                  error={uiStore.userForm.$('firstName').hasError}
                  helperText={uiStore.userForm.$('firstName').error}
                  dataQA="first-name"
                  label={t('First name')}
                  autoFocus={!uiStore.me.firstName}
                />
              </Box>

              <Box width="50%">
                <TextField
                  fullWidth
                  placeholder={t('Last name')}
                  value={uiStore.userForm.$('lastName').value}
                  onChange={uiStore.userForm.$('lastName').sync}
                  error={uiStore.userForm.$('lastName').hasError}
                  helperText={uiStore.userForm.$('lastName').error}
                  dataQA="last-name"
                  label={t('Last name')}
                  autoFocus={uiStore.me.firstName && !uiStore.me.lastName}
                />
              </Box>
            </Box>

            <Box>
              <TextField
                fullWidth
                placeholder={t('Phone')}
                value={uiStore.userForm.$('phoneNumber').value}
                onChange={uiStore.userForm.$('phoneNumber').sync}
                error={uiStore.userForm.$('phoneNumber').error}
                country={uiStore.defaultPhoneCountry}
                dataQA="phone"
                label={t('Phone')}
                removeDescriptionSpace
                phoneNumber
                includePlusInValue
                autoFocus={
                  uiStore.me.firstName &&
                  uiStore.me.lastName &&
                  !uiStore.me.phoneNumber
                }
              />
            </Box>
          </Dialog.Content>

          <Dialog.Actions>
            <Button disabled={uiStore.saving} type="submit" data-qa="submit">
              {t('Continue')}
            </Button>
          </Dialog.Actions>
        </Form>
      )}
    </Dialog>
  );
});
