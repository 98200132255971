/**
 * Segment does not support Branch Metrics on the web.
 * So this is a custom integration.
 *
 * branch.init(branchKey) is added in GTM
 * @docs https://help.branch.io/developers-hub/docs/web-full-reference
 */
const branchDest = {};

branchDest.identify = uuid => {
  if (hasBranchIntegration()) {
    window.branch?.setIdentity(uuid);
  }
};

branchDest.logout = () => {
  if (hasBranchIntegration()) {
    window.branch?.logout();
  }
};

function hasBranchIntegration() {
  if (window.branch) {
    return true;
  } else {
    return false;
  }
}

export default branchDest;
