import styled from 'styled-components';

export default styled.div`
  background: ${props => props.theme.colors.white};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  border: 1px solid ${props => props.theme.colors.lightGrey3};
  border-radius: 9px;
  padding: 5px 0;
  position: absolute;
  min-width: 100%;
  width: ${props => props.width || '100%'};
  z-index: 100;
  max-height: ${props => props.menuHeight || '200px'};
  overflow: auto;
  top: 100%;
`;
