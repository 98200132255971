import { Model } from 'mobx-mc';
import { computed } from 'mobx';
import degsToCompass from '../../utils/degsToCompass';
import formatSpeed from '../../utils/formatSpeed';

export default class WeatherByTime extends Model {
  @computed
  get parent() {
    return this.collection.parent;
  }

  @computed
  get timezone() {
    return this.parent.timezone;
  }

  @computed
  get project() {
    return this.parent.project;
  }

  @computed
  get useImperial() {
    return this.rootStore?.companyUI?.company?.useImperialFormatting;
  }

  @computed
  get windSpeedFormatted() {
    return formatSpeed(this.windSpeed, this.useImperial);
  }

  @computed
  get windDirectionFormatted() {
    return degsToCompass(this.windBearing);
  }

  @computed
  get humidityFormatted() {
    return Math.max(Math.round(this.humidity * 100), 0);
  }
}
