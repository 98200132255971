import request from 'axios';
import { action, observable, computed } from 'mobx';
import { RakenCollection } from 'stores/lib';
import IntegrationConfiguration from 'stores/models/integrations/IntegrationConfiguration';
import IntegrationProjectConfigurations from './IntegrationProjectConfigurations';

export default class IntegrationConfigurations extends RakenCollection {
  @observable type;

  constructor(attributes, options) {
    super(attributes, options);
  }

  url() {
    return `${this.rootStore.urlMicroService('integrations')}/companies/${
      this.company.uuid
    }/integrations/${this.parent.id}/configurations`;
  }

  model() {
    return IntegrationConfiguration;
  }

  parse(data) {
    this.parseProjectConfigurations(data.projectConfigurations || []);

    return data.configurations;
  }

  @action.bound parseProjectConfigurations(data) {
    if (this.projectConfigurations) {
      this.projectConfigurations.set(data);
    } else {
      this.projectConfigurations = new IntegrationProjectConfigurations(data, {
        parent: this,
        rootStore: this.rootStore
      });
    }
  }

  @action.bound
  set(data, options) {
    super.set(data, options);
    this.type = data.type;
  }

  @action.bound async saveConfigurations() {
    return request.patch(this.url(), {
      type: this.type,
      configurations: this.models.map(model => model.toJSON()),
      projectConfigurations: this.projectConfigurations.models.map(model =>
        model.toJSON()
      )
    });
  }

  @computed get company() {
    if (this.rootStore.isSuperAdmin) {
      return this.rootStore.superAdminUI.superAdminCompaniesUI.activeCompany;
    }

    return this.rootStore.me.company;
  }
}
