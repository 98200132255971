import orderBy from 'lodash.orderby';

export default (projectMeasures, sortField, sortDirection) => {
  switch (sortField) {
    case 'ranking':
      return orderBy(projectMeasures, [sortField], [sortDirection]);
    case 'name':
      return orderBy(
        projectMeasures,
        [projectMeasure => projectMeasure.project.name.toLowerCase()],
        [sortDirection]
      );
    default:
      return orderBy(
        projectMeasures,
        [projectMeasure => projectMeasure.stats[sortField]],
        [sortDirection]
      );
  }
};
