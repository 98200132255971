import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';
import { t } from 'utils/translate';
import moment from 'moment';

const equipmentDeploymentFormPlugins = extendedPlugins;

const equipmentDeploymentFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const equipmentDeploymentFormFields = [
  'id',
  'equipment',
  'equipment.uuid',
  'equipment.name',
  'equipment.supplier',
  'equipment.equipmentId',
  'equipment.type',
  'equipment.inUseOrIdle',
  'equipment.activeProject',
  'status',
  'startDate',
  'endDate',
  'costCode',
  'costCode.uuid',
  'costCode.code',
  'costCode.division'
];

const equipmentDeploymentFormLabels = {
  equipment: t('equipment'),
  'equipment.uuid': t('equipment'),
  status: t('status'),
  startDate: t('start date'),
  endDate: t('end date'),
  costCode: t('cost code'),
  'costCode.uuid': t('cost code')
};

const equipmentDeploymentFormValues = {
  status: 'INUSE',
  startDate: moment().format('YYYY-MM-DD')
};

const equipmentDeploymentFormRules = {
  equipment: 'required',
  'equipment.uuid': 'required',
  startDate:
    'dateCustom:YYYY-MM-DD|required|beforeOrTodayDate:YYYY-MM-DD|equipmentDeploymentIsSameOrBeforeDate:endDate,YYYY-MM-DD',
  endDate:
    'required_if:status,REMOVED|dateCustom:YYYY-MM-DD|equipmentDeploymentIsSameOrAfterDate:startDate,YYYY-MM-DD'
};

const equipmentDeploymentFormRelated = {
  startDate: ['endDate'],
  endDate: ['startDate']
};

class EquipmentDeploymentForm extends MobxReactForm {}

export {
  EquipmentDeploymentForm,
  equipmentDeploymentFormOptions,
  equipmentDeploymentFormFields,
  equipmentDeploymentFormRules,
  equipmentDeploymentFormValues,
  equipmentDeploymentFormLabels,
  equipmentDeploymentFormPlugins,
  equipmentDeploymentFormRelated
};
