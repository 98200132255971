import omit from 'lodash.omit';
import { computed } from 'mobx';
import ReportEntry from '../ReportEntry';
import kebabCase from 'lodash.kebabcase';
import Attachment from '../Attachment';
import ProjectMinimal from '../ProjectMinimal';

import formatTimestamp from 'utils/formatTimestamp';

export default class Form extends ReportEntry {
  get urlRoot() {
    return `/ra/companies/${this.rootStore.me.company.uuid}/forms`;
  }

  idAttribute() {
    return 'uuid';
  }

  get restAttributes() {
    return [
      'uuid',
      'companyUuid',
      'name',
      'status',
      'createdTimestamp',
      'updatedTimestamp',
      'projectUuid',
      'fromTemplate'
    ];
  }

  parse(attributes) {
    super.parse(attributes);

    this.parseDocument(attributes);

    return {
      ...omit(attributes, [
        'document',
        'createdBy',
        'updatedBy',
        'company',
        'project'
      ])
    };
  }

  parseProject(attributes) {
    this.project = new ProjectMinimal(
      {
        uuid: attributes.projectUuid,
        name: attributes.projectName
      },
      {
        rootStore: this.rootStore
      }
    );
  }

  parseDocument(attributes) {
    if (!attributes.document) return;

    if (this.document) {
      this.document.set(attributes.document);
    } else {
      this.document = new Attachment(attributes.document, {
        rootStore: this.rootStore,
        parent: this
      });
    }
  }

  @computed
  get formValues() {
    return {
      name: this.name
    };
  }

  @computed
  get slug() {
    return kebabCase(this.name);
  }

  @computed
  get createdAtFormatted() {
    return formatTimestamp(this.createdTimestamp);
  }

  @computed
  get updatedAtFormatted() {
    return formatTimestamp(this.updatedTimestamp);
  }
}
