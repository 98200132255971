import { RakenCollection } from '../lib';
import TimeClockQuestionAnswer from '../models/TimeClockQuestionAnswer';

export default class TimeClockQuestionAnswers extends RakenCollection {
  model() {
    return TimeClockQuestionAnswer;
  }

  url() {
    return `${this.rootStore.urlMicroService(
      'toolbox'
    )}/timecardchecklist/question/details`;
  }
}
