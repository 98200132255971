import { Model } from 'mobx-mc';
import { action, computed, observable } from 'mobx';
import request from 'axios';
import debounce from 'lodash.debounce';
import kebabCase from 'lodash.kebabcase';

import {
  CostCodeMaterialForm,
  costCodeMaterialFormFields,
  costCodeMaterialFormLabels,
  costCodeMaterialFormOptions,
  costCodeMaterialFormPlugins,
  costCodeMaterialFormRules
} from 'forms/costCodeMaterial';
import MaterialStats from '../collections/MaterialStats';
import omit from 'lodash.omit';
import { callTrack } from 'utils/segmentIntegration';
import { PRODUCTION_INSIGHTS_PROGRESS_ADDED } from 'utils/segmentAnalytics/eventSpec';

export default class MaterialCostCode extends Model {
  @observable materialOpen;

  constructor(data = {}, options) {
    super(data, options);

    this.materialOpen = false;
    this.costCodeForm = new CostCodeMaterialForm(
      {
        fields: costCodeMaterialFormFields,
        rules: costCodeMaterialFormRules,
        labels: costCodeMaterialFormLabels,
        values: {
          progressHoursPercent: +data.progressHoursPercent
            ? data.progressHoursPercent
            : ''
        }
      },
      {
        options: costCodeMaterialFormOptions,
        plugins: costCodeMaterialFormPlugins,
        rootStore: this.rootStore
      }
    );

    this.saveCostCodeDebounce = debounce(this.saveCostCode, 500);
  }

  get restAttributes() {
    return [
      'costCode',
      'budgetedHours',
      'actualHours',
      'remainingHours',
      'completeHoursPercent',
      'completeQuantityPercent',
      'progressHoursPercent',
      'projectedTotalHours',
      'budgetedQuantityPerHour',
      'actualQuantityPerHour',
      'projectedHoursGainOrLoss'
    ];
  }

  get restAttributeDefaults() {
    return {
      materialStats: []
    };
  }

  @action.bound
  parse(attributes) {
    this.materialStats = new MaterialStats(attributes.materialStats || null, {
      rootStore: this.rootStore,
      parent: this
    });

    return {
      ...omit(attributes, ['materialStats'])
    };
  }

  @computed
  get uuid() {
    return this.costCode.uuid;
  }

  @computed
  get division() {
    return this.costCode.division;
  }

  @computed
  get code() {
    return this.costCode.code;
  }

  @computed get displayCode() {
    return `${this.division} ${this.code}`;
  }

  @computed get slug() {
    return kebabCase(this.displayCode);
  }

  @computed
  get description() {
    return this.costCode.description;
  }

  @computed
  get projects() {
    return this.costCode.projects;
  }

  @computed
  get isDefault() {
    return this.costCode.isDefault;
  }

  @computed get uiStore() {
    return this.collection.parent.uiStore;
  }

  @computed get sortedMaterialStats() {
    return this.materialStats.models;
  }

  @computed
  get material() {
    return this.materialStats.hasModels && this.materialStats.at(0);
  }

  @action.bound
  handleToggle() {
    this.materialOpen = !this.materialOpen;
  }

  @action.bound
  saveCostCode({
    selectedDay,
    urlMicroService,
    project,
    fromDate,
    toDate,
    divisions,
    stats
  }) {
    const selectedDayFormated = selectedDay.format('YYYY-MM-DD');
    const progressHoursPercent = this.costCodeForm.$('progressHoursPercent')
      .value;
    const url = `${urlMicroService('performanceTracking')}/production/${
      project.uuid
    }/statsByCostCode/manual`;

    request
      .post(url, {
        costCode: {
          uuid: this.uuid
        },
        date: selectedDayFormated,
        fromDate: fromDate,
        toDate: toDate,
        progressHoursPercent: progressHoursPercent
      })
      .then(res => {
        const { costCode, division, summary } = res.data;

        if (!res.data.costCode.progressHoursPercent) {
          costCode.progressHoursPercent = '';
        } else {
          costCode.progressHoursPercent = +res.data.costCode.progressHoursPercent.toFixed(
            2
          );
        }

        this.materialOpen = false;

        // set costCode
        this.set(costCode, {
          parse: false
        });

        // set division
        divisions.setModels([division], {
          remove: false,
          add: false,
          parse: false
        });

        // set summary
        stats.set(summary, {
          parse: false
        });

        this.costCodeForm.update({
          progressHoursPercent: this.progressHoursPercent
        });

        callTrack(PRODUCTION_INSIGHTS_PROGRESS_ADDED, {
          project_name: project.name
        });
      });
  }
}
