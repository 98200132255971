import moment from 'moment';
import { action, observable, computed } from 'mobx';
import UIStore from './UIStore';

import Attachment from 'stores/models/Attachment';
import MemberCertification from 'stores/models/MemberCertification';

import {
  MemberCertificationForm,
  memberCertificationFormOptions,
  memberCertificationFormFields,
  memberCertificationFormLabels,
  memberCertificationFormPlugins,
  memberCertificationFormRules
} from 'forms/memberCertification';

import getFilePreviewIcon from 'utils/getFilePreviewIcon';
import fileToBase64 from 'utils/fileToBase64';
import bytesToSize from 'utils/bytesToSize';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import history from 'utils/history';
import { callTrack } from 'utils/segmentIntegration';
import { MEMBER_CERTIFICATION_EDITED } from 'utils/segmentAnalytics/eventSpec';

export default class TeamMemberCertificationEditUI extends UIStore {
  @observable entryForEdit;
  @observable entryEditForm;

  constructor(options) {
    super(options);

    this.entryForEdit = null;
    this.entryEditForm = null;
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound setup(id) {
    this.findOrFetchEntry(id);
  }

  @action.bound async findOrFetchEntry(uuid) {
    if (this.entryForEdit) return;

    let model = this.parent.memberCertifications.get(uuid);

    if (!model) {
      model = new MemberCertification(
        {
          uuid: uuid
        },
        {
          parent: this.parent,
          rootStore: this.rootStore
        }
      );
    }

    try {
      await model.fetch();

      this.setEntryForEdit(model);
    } catch (error) {
      console.error(error);
      this.cancelMemberCertificationEdit();
    }
  }

  @action.bound setEntryForEdit(model) {
    this.entryForEdit = model;

    this.entryEditForm = new MemberCertificationForm(
      {
        fields: memberCertificationFormFields,
        rules: memberCertificationFormRules,
        labels: memberCertificationFormLabels,
        values: this.entryForEdit.formValues
      },
      {
        options: memberCertificationFormOptions,
        plugins: memberCertificationFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound submitEntryEditForm(event) {
    event.preventDefault();
    event.stopPropagation();

    this.entryEditForm.submit({
      onSuccess: this.submitEntryEditFormSuccess,
      onError: this.submitEntryEditFormError
    });
  }

  @action.bound async submitEntryEditFormSuccess() {
    const payload = this.entryEditForm.values();

    payload.expirationDate = payload.expirationDate
      ? moment(payload.expirationDate).format('YYYY-MM-DD')
      : null;

    this.saving = true;

    try {
      await this.entryForEdit.save(payload, {
        wait: true,
        stripNonRest: false
      });

      this.parent.sortByLastCreated();
      this.cancelMemberCertificationEdit();

      callTrack(MEMBER_CERTIFICATION_EDITED);

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Certification updated')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitEntryEditFormError() {
    console.error(this.entryEditForm.errors());
  }

  @action.bound resetEntryEditForm() {
    this.clearValidationDetails();
    this.entryEditForm.reset();
    this.entryEditForm.showErrors(false);
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.entryForEdit = null;
    this.entryEditForm = null;
    this.saving = false;
  }

  @computed get disableCancelButton() {
    return Boolean(
      !this.parent.hasWriteAccess || this.saving || this.hasAttachmentsUploading
    );
  }

  @computed get disableSaveButton() {
    return Boolean(
      !this.parent.hasWriteAccess || this.saving || this.hasAttachmentsUploading
    );
  }

  @computed get saveButtonText() {
    if (this.saving) {
      return t('Saving...');
    }

    if (this.hasAttachmentsUploading) {
      return t('Uploading files...');
    }

    return t('Save');
  }

  @computed get hasAttachmentsUploading() {
    return this.entryForEdit?.attachments.models.find(
      attachment => attachment.isNew
    );
  }

  @action.bound async uploadAttachment(uploadItem) {
    await this.authorization.checkFeatureAccess('UploadAttachments');

    const file = uploadItem.file;

    if (file.size > 62914560) {
      this.rootStore.notificationsUI.pushNotification({
        title: `${t('File is too big ')} (${bytesToSize(file.size)}). ${t(
          'Limit is 60MB.'
        )}`,
        snackbar: 'error',
        icon: 'notification'
      });

      return;
    }

    file.preview = await fileToBase64(file);

    const filePreviewIcon = getFilePreviewIcon(this.rootStore.assetsURL, file);

    const previewAttachment = new Attachment(
      {
        fileName: file.name,
        thumbUrl: filePreviewIcon
      },
      {
        rootStore: this.rootStore
      }
    );

    this.entryForEdit.attachments.add(previewAttachment);

    try {
      await this.entryForEdit.attachments.uploadVersionTwo({
        file: file,
        previewAttachment: previewAttachment,
        progressCallback: percentCompleted => {
          // If we wanted to show a progress bar on the attachment
          // we can use previewAttachment.uploadProgress.
          previewAttachment.setUploadProgress(percentCompleted);
        }
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      // Remove the preview model now that the new one is ready
      this.entryForEdit.attachments.remove(previewAttachment);
    }
  }

  @action.bound cancelMemberCertificationEdit() {
    history.push({
      pathname: `${this.parent.entryForEdit.viewUrl}/certifications`
    });
  }

  @computed get certificationOptions() {
    return this.parent.certificationOptions;
  }

  @computed get title() {
    if (this.parent.hasWriteAccess) {
      return t('Edit member certification');
    }

    return t('View member certification');
  }
}
