import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';

const timeClockQuestionsFiltersFormPlugins = extendedPlugins;

const timeClockQuestionsFiltersFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const timeClockQuestionsFiltersFormFields = [
  'projectFilters[]',
  'memberFilters[]',
  'classificationFilters[]',
  'employeeGroupFilters[]',
  'eventOriginFilters[]',
  'responseFilters[]',
  'templateFilters[]'
];

const timeClockQuestionsFiltersFormRules = {
  projectFilters: 'array',
  memberFilters: 'array',
  classificationFilters: 'array',
  employeeGroupFilters: 'array',
  eventOriginFilters: 'array',
  responseFilters: 'array',
  templateFilters: 'array'
};

class TimeClockQuestionsFiltersForm extends MobxReactForm {}

export {
  TimeClockQuestionsFiltersForm,
  timeClockQuestionsFiltersFormRules,
  timeClockQuestionsFiltersFormFields,
  timeClockQuestionsFiltersFormOptions,
  timeClockQuestionsFiltersFormPlugins
};
