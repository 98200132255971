import debounce from 'lodash.debounce';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import UIStore from 'stores/ui/UIStore';
import history from 'utils/history';
import MemberCertifications from 'stores/collections/MemberCertifications';
import CertificationMembersEditUI from './CertificationMembersEditUI';
import CertificationMembersBulkAddUI from './CertificationMembersBulkAddUI';
import CertificationMembersBulkEditUI from './CertificationMembersBulkEditUI';
import request from 'axios';

import { BASE_DEBOUNCE } from 'fixtures/constants';

import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import { callTrack } from 'utils/segmentIntegration';
import { MEMBER_CERTIFICATION_DELETED } from 'utils/segmentAnalytics/eventSpec';

export default class CertificationMembersUI extends UIStore {
  @observable searchQuery;
  @observable sortField;
  @observable sortDirection;
  @observable pageSize;
  @observable page;
  @observable loading;
  @observable selectedMemberCertification;

  constructor(options) {
    super(options);

    this.loading = true;
    this.sortField = 'certification.name';
    this.sortDirection = 'asc';
    this.searchQuery = '';
    this.page = 1;
    this.pageSize = 10;

    this.selectedMemberCertification = null;
    this.selectedMemberCertifications = observable([]);
    this.memberCertifications = new MemberCertifications(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.certificationMembersEditUI = new CertificationMembersEditUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.certificationMembersBulkAddUI = new CertificationMembersBulkAddUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.certificationMembersBulkEditUI = new CertificationMembersBulkEditUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchMemberCertificationsDebounced = debounce(
      this.fetchMemberCertifications,
      BASE_DEBOUNCE
    );
  }

  @action.bound setup() {
    this.setupReactions();
    this.fetchMemberCertifications();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchMemberCertificationsDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed
  get params() {
    return {
      query: this.searchQuery,
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      sortField: this.sortField,
      sortDirection: this.sortDirection
    };
  }

  @computed get entryForEdit() {
    return this.parent.entryForEdit;
  }

  @computed
  get hasWriteAccess() {
    return this.me.permissions.CUD_MEMBER_CERTIFICATIONS;
  }

  @action.bound async fetchMemberCertifications() {
    this.memberCertifications.cancelRequest();
    this.memberCertifications.reset();

    try {
      await this.memberCertifications.fetch({
        url: `/ra/companies/${this.rootStore.me.company.uuid}/certifications/${this.parent.entryForEdit.uuid}/member-certifications`,
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasMemberCertifications() {
    return this.memberCertifications.hasModels;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.memberCertifications.totalElements / this.pageSize);
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.memberCertifications.clear();
    this.searchQuery = '';
    this.page = 1;
    this.loading = true;
    this.saving = false;
    this.sortField = '';
    this.sortDirection = 'asc';
    this.selectedMemberCertification = null;
    this.selectedMemberCertifications.clear();
  }

  @computed get bulkMemberCertificationActions() {
    const actions = [
      {
        title: t('Edit'),
        onClick: () => {
          this.bulkMemberCertificationEdit();
        }
      },
      {
        title: t('Delete'),
        onClick: () => {
          this.bulkMemberCertificationDelete();
        }
      }
    ];

    return actions;
  }

  getMemberCertificationActions = memberCertification => {
    if (!this.hasWriteAccess) {
      return [
        {
          title: t('View'),
          onClick: () => {
            this.editMemberCertification(memberCertification);
          }
        }
      ];
    }

    return [
      {
        title: t('Edit'),
        onClick: () => {
          this.editMemberCertification(memberCertification);
        }
      },
      {
        title: t('Delete'),
        onClick: () => {
          this.deleteMemberCertification(memberCertification);
        }
      }
    ];
  };

  @action.bound async deleteMemberCertification(memberCertification) {
    if (!this.hasWriteAccess) {
      this.authorization.showModal('featureAccess');
      return;
    }

    this.selectedMemberCertification = memberCertification;
    this.showModal('DeleteModal');
  }

  @action.bound async cancelDeleteMemberCertification() {
    await this.hideActiveModal();

    this.selectedMemberCertification = null;
  }

  @action.bound async confirmDeleteMemberCertification() {
    this.saving = true;

    try {
      await this.selectedMemberCertification.destroy({ wait: true });

      this.refetchMemberCertifications();

      await this.hideActiveModal();

      this.selectedMemberCertification = null;

      callTrack(MEMBER_CERTIFICATION_DELETED);

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Certification deleted')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound sortByLastCreated() {
    this.sortField = 'createdTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchMemberCertificationsDebounced();
  }

  @action.bound refetchMemberCertifications() {
    this.loading = true;
    this.selectedMemberCertifications.clear();
    if (!this.hasMemberCertifications) {
      this.setPage(null, 1);
      this.fetchMemberCertifications();
    } else {
      this.fetchMemberCertifications();
    }
  }

  @action.bound async editMemberCertification(memberCertification) {
    history.push({
      pathname: `${this.entryForEdit.viewUrl}/employees/${memberCertification.uuid}`
    });
  }

  @action.bound async bulkMemberCertificationEdit() {
    history.push({
      pathname: `${this.entryForEdit.viewUrl}/employees/bulk-edit`
    });
  }

  @action.bound async bulkMemberCertificationDelete() {
    if (!this.hasWriteAccess) {
      this.authorization.showModal('featureAccess');
      return;
    }
    this.showModal('DeleteModal');
  }

  @action.bound
  async confirmBulkDelete() {
    this.saving = true;
    const payload = {
      memberCertificationUuids: this.selectedMemberCertifications.map(
        memberCertification => memberCertification.uuid
      )
    };
    const url = `ra/companies/${this.company?.uuid}/member-certifications/batch/delete`;

    try {
      await request.post(url, payload);
      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Certifications deleted')
      });
    } catch (error) {
      console.error(
        'Unexpected error happened while saving certifications delete',
        error
      );
    } finally {
      this.refetchMemberCertifications();
      await this.hideActiveModal();
      this.saving = false;
    }
  }

  @action.bound async assignMemberCertification() {
    history.push({
      pathname: `${this.entryForEdit.viewUrl}/employees/bulk-add`
    });
  }

  @action.bound toggleSelectMemberCertification(event, model) {
    event.stopPropagation();
    event.preventDefault();
    const selected = this.selectedMemberCertifications.find(
      cert => cert.id === model.id
    );
    if (selected) {
      this.selectedMemberCertifications.remove(selected);
    } else {
      this.selectedMemberCertifications.push(model);
    }
  }

  @computed get allMemberCertificationsSelected() {
    const selected = this.selectedMemberCertifications.filter(cert =>
      this.memberCertifications.models.find(
        memberCert => memberCert.id === cert.id
      )
    );
    return (
      !!selected.length &&
      selected.length === this.memberCertifications.models.length
    );
  }

  @action.bound toggleSelectAllMemberCertifications() {
    const allMemberCertifications = this.memberCertifications.models;
    if (this.allMemberCertificationsSelected) {
      this.selectedMemberCertifications.clear();
    } else {
      this.selectedMemberCertifications.replace(allMemberCertifications);
    }
  }

  @computed get activeMemberForm() {
    return (
      this.memberCertificationAddUI.entryAddForm ||
      this.memberCertificationEditUI.entryEditForm
    );
  }

  @computed get showEmptyState() {
    return !this.loading && !this.searchQuery && !this.hasMemberCertifications;
  }

  @computed get showEmptySearchState() {
    return !this.loading && this.searchQuery && !this.hasMemberCertifications;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @computed get hasSelectedMembers() {
    return this.selectedMemberCertifications.length > 0;
  }
}
