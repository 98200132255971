import { RakenCollection } from '../../lib';
import SuperAdminCompanyProject from '../../models/superAdmin/SuperAdminCompanyProject';

export default class SuperAdminCompanyProjects extends RakenCollection {
  model() {
    return SuperAdminCompanyProject;
  }

  url() {
    return `ra/companies/${this.parent.id}/projects`;
  }
}
