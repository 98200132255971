import debounce from 'lodash.debounce';
import { action, computed, observable, runInAction, reaction } from 'mobx';
import UIStore from '../UIStore';

import Invoices from 'stores/collections/billing/Invoices';

import alertErrorHandler from 'utils/alertErrorHandler';
import axiosDownload from 'utils/axiosDownload';
import { t } from 'utils/translate';

import { BASE_DEBOUNCE } from 'fixtures/constants';
import { axiosIgnore404 } from 'utils/axiosIgnore404';

export default class InvoicesUI extends UIStore {
  @observable loading;

  @observable sortField;
  @observable sortDirection;

  constructor(options) {
    super(options);

    this.loading = true;

    this.sortField = 'createdAt';
    this.sortDirection = 'desc';

    this.invoices = new Invoices(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchInvoicesDebounced = debounce(this.fetchInvoices, BASE_DEBOUNCE);
  }

  @action.bound clearUIState() {
    this.invoices.clear();
    this.sortField = 'createdAt';
    this.sortDirection = 'desc';
    this.loading = true;
  }

  @computed get billingUI() {
    return this.parent;
  }

  @computed get company() {
    return this.billingUI.company;
  }

  @action.bound setup() {
    this.fetchInvoices();
    this.setupReactions();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchInvoicesDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed get params() {
    return {
      limit: 10,
      sort: this.sortField,
      order: this.sortDirection
    };
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }
  }

  @action.bound async refetchInvoices() {
    this.loading = true;
    this.fetchInvoices();
  }

  @action.bound async fetchInvoices() {
    this.invoices.cancelRequest();
    this.invoices.reset();

    try {
      await this.invoices.fetch({
        params: this.params,
        axios: axiosIgnore404
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasMoreInvoices() {
    return this.invoices.bookmark;
  }

  @action.bound async fetchNextInvoices() {
    try {
      await this.invoices.fetch({
        add: true,
        remove: false,
        update: false,
        params: {
          bookmark: this.invoices.bookmark
        }
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasInvoices() {
    return this.invoices.hasModels;
  }

  @computed get showEmptyState() {
    return !this.loading && !this.hasInvoices;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  getInvoiceActions = invoice => {
    const actions = [
      {
        title: t('Download'),
        onClick: () => {
          this.downloadInvoice(invoice);
        }
      }
    ];

    if (invoice.allowCapturePayment && this.isSuperAdmin) {
      actions.push({
        title: t('Capture payment'),
        onClick: () => {
          this.capturePaymentOnInvoice(invoice);
        }
      });
    }

    return actions;
  };

  @action.bound downloadInvoice(invoice) {
    axiosDownload(
      `ra/companies/${this.company.uuid}/billing/invoices/${invoice.id}.pdf`,
      `Invoice - ${invoice.number}.pdf`
    );
  }

  @action.bound async capturePaymentOnInvoice(invoice) {
    try {
      await invoice.save(null, {
        url: `${invoice.url()}/collect`,
        method: 'put',
        wait: true
      });

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Payment captured')
      });

      this.billingUI.refreshAll();
    } catch (error) {
      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'error',
        icon: 'warning',
        title: error.response.data.message
      });
    }
  }
}
