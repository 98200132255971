import { BASE_DEBOUNCE } from 'fixtures/constants';
import debounce from 'lodash.debounce';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import DocumentsConstants from 'stores/collections/documents/DocumentsConstants';
import DocumentsFolder from 'stores/models/documents/DocumentsFolder';
import DocumentsStat from 'stores/models/documents/DocumentsStat';
import DocumentSelectorUI from 'stores/ui/DocumentSelectorUI';
import DocumentActionsUI from 'stores/ui/project/documents/DocumentActionsUI';
import DocumentAddFilesUI from 'stores/ui/project/documents/DocumentAddFilesUI';
import DocumentEditUI from 'stores/ui/project/documents/DocumentEditUI';
import DocumentFiltersUI from 'stores/ui/project/documents/DocumentFiltersUI';
import DocumentFolderUI from 'stores/ui/project/documents/DocumentFolderUI';
import DocumentSearchUI from 'stores/ui/project/documents/DocumentSearchUI';
import alertErrorHandler from 'utils/alertErrorHandler';
import history from 'utils/history';
import { t } from 'utils/translate';
import ProjectChildUI from '../ProjectChildUI';

export default class DocumentsUI extends ProjectChildUI {
  @observable previousPath;

  @observable sortField;
  @observable sortDirection;
  @observable pageSize;
  @observable page;
  @observable loading;

  @observable folder;

  constructor(options) {
    super(options);

    this.loading = true;

    this.sortField = 'name';
    this.sortDirection = 'asc';
    this.page = 1;
    this.pageSize = 20;

    this.folder = null;

    // Going back
    this.previousPath = undefined;

    this.documentsStat = new DocumentsStat(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.documentsConstants = new DocumentsConstants(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.documentFolderUI = new DocumentFolderUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.documentSearchUI = new DocumentSearchUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.documentSelectorUI = new DocumentSelectorUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.documentActionsUI = new DocumentActionsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.documentFiltersUI = new DocumentFiltersUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.documentAddFilesUI = new DocumentAddFilesUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.documentEditUI = new DocumentEditUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.folder = new DocumentsFolder(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchFolderDebounced = debounce(this.fetchFolder, BASE_DEBOUNCE);
  }

  @action.bound setup() {
    this.fetchConstants();
    this.fetchStats();

    this.fetchFolder();
    this.setupReactions();

    this.documentSelectorUI.setup();
  }

  @action.bound setPreviousPath(previousPath) {
    this.previousPath = previousPath;
  }

  @action.bound async fetchConstants() {
    this.documentsConstants.clear();

    try {
      await this.documentsConstants.fetch();
    } catch (error) {
      console.log(error);
    }
  }

  @action.bound async fetchStats() {
    this.documentsStat.clear();

    try {
      await this.documentsStat.fetch();
    } catch (error) {
      console.log(error);
    }
  }

  @action.bound showUpgradeModal() {
    this.showModal('DocumentStorageUpgradeModal');
  }

  @action.bound hideUpgradeModal() {
    this.hideActiveModal();
  }

  @action.bound searchDocuments(value) {
    this.documentSearchUI.setSearchQuery(value);

    history.push({
      pathname: `${this.project.viewUrl}/documents/search`,
      search: this.baseQueryParams
    });
  }

  @action.bound editDocument(document) {
    history.push({
      pathname: document.viewUrl,
      search: this.baseQueryParams,
      state: { previousPath: `${this.project.viewUrl}/documents/index` }
    });
  }

  @computed get goBackUrl() {
    if (this.previousPath) return this.previousPath;

    return `${this.project.viewUrl}/documents/index`;
  }

  @action.bound goBack(replaceHistory = false) {
    if (replaceHistory) {
      history.replace(this.goBackUrl);
    } else {
      history.push(this.goBackUrl);
    }
  }

  @action.bound clearUIState() {
    this.page = 1;
    this.loading = true;
    this.saving = false;
    this.sortField = 'name';
    this.sortDirection = 'asc';

    this.folder.clear();

    this.documentFiltersUI.clearUIState();
  }

  @action.bound tearDown() {
    this.clearUIState();

    this.previousPath = undefined;

    this.documentsStat.clear();
    this.documentsConstants.clear();
    this.documentFolderUI.tearDown();
    this.documentSearchUI.tearDown();
    this.documentSelectorUI.tearDown();

    this.documentActionsUI.clearUIState();
    this.documentAddFilesUI.clearUIState();
    this.documentEditUI.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchFolderDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed
  get params() {
    let result = {
      projectUuids: this.projectUuid,
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      sortField: this.sortField,
      sortDirection: this.sortDirection
    };

    if (this.filterValues.typeFilter) {
      if (this.filterValues.typeFilter === 'FOLDER') {
        result.types = 'FOLDER';
      } else {
        result.fileType = this.filterValues.typeFilter;
      }
    }

    if (this.filterValues.createdByFilters?.length > 0) {
      result.createdBy = this.filterValues.createdByFilters.join(',');
    }

    if (this.filterValues.updatedByFilters?.length > 0) {
      result.updatedBy = this.filterValues.updatedByFilters.join(',');
    }

    return result;
  }

  @computed get hasRequiredParams() {
    return this.params.projectUuids;
  }

  @computed get documents() {
    return this.folder?.children;
  }

  @action.bound async fetchFolder() {
    if (!this.hasRequiredParams) {
      this.loading = false;
      return;
    }

    this.folder.cancelRequest();
    this.folder.clear();

    try {
      await this.folder.fetch({
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasChildren() {
    return !!this.folder?.children?.hasModels;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed
  get totalPages() {
    return !!this.documents?.totalElements
      ? Math.ceil(this.documents.totalElements / this.pageSize)
      : 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  // Can be used to show top level folder info like breadcrumbs
  // We use the usual states belong for the table of children items
  @computed get showFolderUI() {
    return this.folder?.name;
  }

  @computed get showEmptyState() {
    return (
      !this.loading &&
      !this.hasChildren &&
      !this.documentFiltersUI.hasAppliedFilters
    );
  }

  @computed get showEmptyFilterState() {
    return (
      !this.loading &&
      !this.hasChildren &&
      this.documentFiltersUI.hasAppliedFilters
    );
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @action.bound sortByLastUpdated() {
    this.sortField = 'updatedTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchFolderDebounced();
  }

  @action.bound refetchDocuments() {
    this.loading = true;
    this.documentActionsUI.clearUIState();
    this.documentFiltersUI.clearUIState();
    this.setPage(null, 1);
    this.fetchFolder();
  }

  getChildActions = model => {
    // Used to populate the action buttons on each row in the table.
    // A model could be isFile or isFolder so would change depending on that.
    let actions = [];

    if (model.isFile) {
      actions.push({
        title:
          this.hasWriteAccess &&
          model?.document?.contentType === 'application/pdf'
            ? t('Edit')
            : t('View'),
        onClick: () => {
          this.editDocument(model);
        }
      });
    }

    if (this.hasWriteAccess) {
      actions.push(
        {
          title: t('Rename'),
          onClick: () => {
            this.documentActionsUI.renameDocument(model);
          }
        },
        {
          title: t('Delete'),
          onClick: () => {
            this.documentActionsUI.deleteDocument(model);
          }
        }
      );
    }

    actions.push({
      title: t('Download'),
      onClick: () => {
        model.isFile
          ? this.documentActionsUI.downloadDocumentFile(model)
          : this.documentActionsUI.downloadDocuments(model);
      }
    });

    return actions;
  };

  @computed get hasWriteAccess() {
    return this.authorization.canCUDDocuments;
  }

  @computed get filterValues() {
    return {
      typeFilter: this.documentFiltersUI.typeFilter,
      createdByFilters: this.documentFiltersUI.createdByFilters.map(
        owner => owner.uuid
      ),
      updatedByFilters: this.documentFiltersUI.updatedByFilters.map(
        owner => owner.uuid
      )
    };
  }

  @computed get enableBulkActions() {
    return true;
  }
}
