import orderBy from 'lodash.orderby';
import { action, computed, observable, toJS } from 'mobx';
import matchModelField from 'utils/matchModelField';

import SettingsChildUI from './SettingsChildUI';
import SettingsBudgetAddUI from './SettingsBudgetAddUI';
import SettingsBudgetEditUI from './SettingsBudgetEditUI';
import SettingsBudgetsImportUI from './SettingsBudgetsImportUI';

import CostCodes from 'stores/collections/CostCodes';

import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import history from 'utils/history';

import { callTrack } from 'utils/segmentIntegration';
import { PRODUCTION_BUDGET_ADDED } from 'utils/segmentAnalytics/eventSpec';

export default class SettingsBudgetsUI extends SettingsChildUI {
  @observable searchQuery;
  @observable sortField;
  @observable sortDirection;
  @observable pageSize;
  @observable page;
  @observable loading;
  @observable selectedBudget;

  @observable showInactiveCompanyLevelCodes;

  @observable selectedBulkCostCodeAction;

  constructor(options) {
    super(options);

    this.searchQuery = '';
    this.loading = true;
    this.sortField = 'division,code';
    this.sortDirection = 'asc';
    this.page = 1;
    this.pageSize = 20;
    this.showInactiveCompanyLevelCodes = false;

    this.selectedBudgets = observable([]);
    this.selectedBudget = null;

    this.selectedBulkCostCodeAction = null;

    this.budgets = new CostCodes(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.budgetAddUI = new SettingsBudgetAddUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.budgetEditUI = new SettingsBudgetEditUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.budgetsImportUI = new SettingsBudgetsImportUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @computed get title() {
    return t('Budgets');
  }

  @computed get headerTitle() {
    return t('Budget your labor hours and quantities');
  }

  @computed get hasWriteAccess() {
    return this.authorization.canCUDBudgets;
  }

  @action.bound setup() {
    this.fetchBudgets();
  }

  @action.bound tearDown() {
    this.clearUIState();
    this.project?.fetch();
  }

  @computed get hasBudgets() {
    return this.budgets.hasModels;
  }

  @action.bound refetchBudgets() {
    this.fetchBudgets();
  }

  @action.bound async fetchBudgets() {
    this.loading = true;

    this.budgets.clear();
    this.budgets.cancelRequest();

    try {
      await this.budgets.fetch({
        params: {
          limit: 10000,
          projectUuids: this.project.uuid,
          hasBudget: true
        }
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get sortedBudgets() {
    return orderBy(
      this.budgets.models,
      [
        budget => budget.division.toLowerCase(),
        budget => budget.code.toLowerCase()
      ],
      ['asc', 'asc']
    );
  }

  @computed get searchedBudgets() {
    if (!this.searchQuery) {
      return this.sortedBudgets;
    }

    const query = this.searchQuery.toLowerCase();

    return this.sortedBudgets.filter(budget => {
      return (
        matchModelField(budget, query, 'costCodeKey') ||
        matchModelField(budget, query, 'projectOrCompanyDescription')
      );
    });
  }

  @computed get hasSearchedBudgets() {
    return this.searchedBudgets.length > 0;
  }

  @computed get paginatedBudgets() {
    return this.searchedBudgets.slice(
      (this.page - 1) * this.pageSize,
      (this.page - 1) * this.pageSize + this.pageSize
    );
  }

  @computed
  get totalPages() {
    return Math.ceil(this.searchedBudgets.length / this.pageSize);
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    this.selectedBudgets.clear();
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.budgets.clear();
    this.selectedBudgets.clear();
    this.searchQuery = '';
    this.page = 1;
    this.loading = true;
    this.saving = false;
    this.selectedBudget = null;
    this.selectedBulkBudgetAction = null;
  }

  @computed get hasSelectedBudgets() {
    return this.selectedBudgets.length > 0;
  }

  @action.bound
  toggleSelectBudget(budget) {
    if (this.selectedBudgets.find(uuid => uuid === budget.uuid)) {
      this.selectedBudgets.remove(budget.uuid);
    } else {
      this.selectedBudgets.push(budget.uuid);
    }
  }

  @computed
  get allBudgetsSelected() {
    return (
      this.hasBudgets && this.selectedBudgets.length === this.budgets.length
    );
  }

  @action.bound
  toggleSelectAllBudgets() {
    if (this.allBudgetsSelected) {
      this.selectedBudgets.clear();
    } else {
      this.selectedBudgets.replace(
        this.budgets.models.map(budget => budget.uuid)
      );
    }
  }

  @action.bound async deleteBudget(budget) {
    await this.authorization.checkFeatureAccess('CUDBudgets');

    this.selectedBudget = budget;
    this.showModal('DeleteModal');
  }

  @action.bound async cancelDeleteBudget() {
    await this.hideActiveModal();

    this.selectedBudget = null;
  }

  @action.bound async confirmDeleteBudget() {
    this.saving = true;

    try {
      const project = this.selectedBudget.getProjectByUuid(this.project.uuid);

      project.materials = [];
      project.budgetedHours = '';

      await this.selectedBudget.save(
        {
          projects: toJS(this.selectedBudget.projects)
        },
        {
          url: `${this.rootStore.urlMicroService(
            'performanceTracking'
          )}/companies/${this.company.uuid}/costcodes/${
            this.selectedBudget.uuid
          }/budgets`,
          wait: true
        }
      );

      this.fetchBudgets();

      await this.hideActiveModal();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Budget deleted')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound async addBudget() {
    await this.authorization.checkFeatureAccess('CUDBudgets');

    history.push({
      pathname: `${this.project.viewUrl}/settings/production-tracking/budgets/add`,
      search: this.baseQueryParams
    });
  }

  @action.bound async editBudget(costCode) {
    await this.authorization.checkFeatureAccess('CUDBudgets');

    history.push({
      pathname: `${this.project.viewUrl}/settings/production-tracking/budgets/${costCode.uuid}`,
      search: this.baseQueryParams
    });
  }

  @action.bound async importBudgets() {
    await this.authorization.checkFeatureAccess('CUDBudgets');

    history.push({
      pathname: `${this.project.viewUrl}/settings/production-tracking/budgets/import`,
      search: this.baseQueryParams
    });
  }

  @computed get showEmptyState() {
    return !this.loading && !this.hasSearchedBudgets;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  // Bulk Actions
  @computed get bulkBudgetActions() {
    return [
      {
        title: t('Delete'),
        onClick: () => {
          this.bulkBudgetAction('DELETE');
        }
      }
    ];
  }

  @action.bound async bulkBudgetAction(action) {
    await this.authorization.checkFeatureAccess('CUDBudgets');

    this.selectedBulkBudgetAction = action;
    this.showModal('BudgetBulkActionModal');
  }

  @action.bound async cancelBulkBudgetAction() {
    await this.hideActiveModal();
    this.selectedBulkBudgetAction = null;
  }

  @computed get bulkBudgetActionTitle() {
    return t(`Delete selected budgets?`);
  }

  @computed get bulkBudgetActionText() {
    return t('Would you like to delete the selected budgets?');
  }

  @computed get bulkBudgetActionButtonText() {
    return this.saving ? t('Deleting...') : t('Delete');
  }

  @computed get bulkBudgetActionButtonColor() {
    return 'red';
  }

  @computed get bulkBudgetActionNotificationText() {
    return t(`Budgets deleted`);
  }

  @action.bound async confirmBulkBudgetAction() {
    this.clearValidationDetails();
    this.saving = true;

    try {
      switch (this.selectedBulkBudgetAction) {
        case 'DELETE':
          await this.bulkDelete();
          break;
      }

      await this.hideActiveModal();

      this.fetchBudgets();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: this.bulkBudgetActionNotificationText
      });

      this.selectedBudgets.clear();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound async bulkDelete() {
    const promises = [];

    this.budgets.models
      .filter(budget => this.selectedBudgets.includes(budget.uuid))
      .forEach(model => {
        const project = model.getProjectByUuid(this.project.uuid);

        if (project) {
          project.materials = [];
          project.budgetedHours = '';

          promises.push(
            model.save(
              {
                projects: toJS(model.projects)
              },
              {
                url: `${this.rootStore.urlMicroService(
                  'performanceTracking'
                )}/companies/${this.company.uuid}/costcodes/${
                  model.uuid
                }/budgets`,
                wait: true
              }
            )
          );
        }
      });

    await Promise.all(promises);
  }

  @action.bound updateCostCode(costCode, budgetedHours, materials, type) {
    const project = costCode.getProjectByUuid(this.project.uuid);

    /* delete empty materials */
    const cleanMaterials = materials.filter(
      material => material.uuid && material.quantity
    );

    if (project) {
      project.budgetedHours = budgetedHours;
      project.materials = cleanMaterials.map(material => {
        return {
          material: {
            uuid: material.uuid
          },
          quantity: material.quantity
        };
      });
    }

    this.saving = true;

    try {
      costCode.save(
        {
          projects: toJS(costCode.projects)
        },
        {
          url: `${this.rootStore.urlMicroService(
            'performanceTracking'
          )}/companies/${this.company.uuid}/costcodes/${costCode.uuid}/budgets`
        }
      );

      if (type === 'new') {
        callTrack(PRODUCTION_BUDGET_ADDED, {
          budgeted_hours_entered: Boolean(budgetedHours),
          budgeted_quantity_entered: cleanMaterials.length
        });
      }
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }
}
