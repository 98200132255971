import styled, { css } from 'styled-components';

export default styled.div`
  position: relative;
  z-index: 50;
  .affixed &,
  .collapsed & {
    z-index: 99999;
  }
  ${props =>
    props.withBorder &&
    css`
      border-bottom: 1px solid ${props => props.theme.colors.lightGrey5};
    `} ${props =>
    props.open &&
    props.withShadow &&
    css`
      box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.15);
      .collapsed {
        box-shadow: none;
      }
    `};
`;
