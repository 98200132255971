import React, { Component } from 'react';
import { Modal, Transition } from 'react-overlays';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import media from 'utils/mediaTemplate';
import getWidth from 'utils/getWidth';

import CircleButton from './CircleButton';
import Icon from './Icon';
import ModalHeader from './ModalHeader';
import ModalFooter from './ModalFooter';

const backdropStyle = {
  background: 'rgba(0, 0, 0, 0.75)',
  height: '100%',
  left: 0,
  position: 'fixed',
  top: 0,
  width: '100%',
  transform: 'translate3d(0, 0, 0)'
};

const Wrapper = styled(Modal)`
  position: fixed;
  position: expression(fixed);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${props => props.zindex || '2040'};
  overflow: hidden;
  height: 100%;
  ${media.smallDesktop`
    min-width: 992px;
    overflow-x: auto;
    position: absolute;
  `}
`;

const Dialog = styled.div`
  box-sizing: border-box;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  z-index: 2041;
  overflow-x: hidden;
  overflow-y: auto;
  transform: translate3d(0, 0, 0);
  margin: auto;
  outline: 0;
  position: relative;
  padding: 10px;
  .overlay-open & {
    overflow: hidden;
  }
  ${media.smallDesktop`
    overflow-x: auto;
    pointer-events: none;
  `};
  ${media.desktop`
    align-items: ${props => (props.header && !props.footer ? 'top' : 'center')};
    display: flex;
    overflow-y: ${props => (props.footer ? 'auto' : 'hidden')};
    padding: 50px;
    justify-content: center;
    margin: auto 0 0 0;
    height: 100%;
    pointer-events: none;
  `};
  ${media.large`
    padding: 25px 75px;
  `};
`;

const DesktopClose = styled(CircleButton)`
  display: none;
  position: absolute;
  right: 15px;
  top: 15px;
  pointer-events: all;

  ${media.desktop`
    display: flex; 
  `};
  ${media.large`
    right: 25px;
    top: 25px;
  `};
`;

const MobileClose = styled.div`
  cursor: pointer;
  padding: 5px;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
  ${media.desktop`
    display: none; 
  `};
`;

const Content = styled.div`
  /* remove 'transparent' background to prevent 'blinking' seen in IE, 
  inform everyone if 'transparent' value is needed again */
  background-color: ${props => props.theme.colors.white};
  box-sizing: border-box;
  border-radius: 6px;
  max-width: 100%;
  margin: 0 auto;
  width: ${props => (props.fullWidth ? '100%' : getWidth(props.size) + 'px')};
  min-width: 230px;
  pointer-events: all;
  ${media.desktop`
    border-radius: 16px;
  `};
  ${props =>
    props.fade &&
    css`
      ${Dialog}.fade > & {
        transform: translateY(100%);
        transition: transform 0.35s ease-in-out;
      }
      ${Dialog}.in > & {
        transform: translateY(0);
      }
    `};
  ${props =>
    props.hideOverflow &&
    css`
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      overflow: hidden;
    `};

  ${props =>
    props.fullScreen &&
    css`
      min-width: 90vw;
    `};
`;

const Inner = styled.div`
  ${props =>
    !props.withFooter &&
    css`
      backface-visibility: hidden;
      box-sizing: border-box;
      overflow: auto;
      max-height: calc(100vh - 12rem);
    `};
  .overlay-open & {
    overflow: hidden;
  }
`;

const Fade = props => (
  <Transition
    timeout={5000}
    className="fade"
    enteredClassName="in"
    enteringClassName="in"
    transitionAppear
    unmountOnExit={true}
    {...props}
  />
);

export default class extends Component {
  static propTypes = {
    backdrop: PropTypes.oneOf([true, false, 'static']),
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    preventClose: PropTypes.bool,
    disabledClose: PropTypes.bool
  };

  static defaultProps = {
    backdrop: true,
    size: 'medium',
    fade: true,
    preventClose: false,
    dataQA: 'modal_active',
    disabledClose: false
  };

  handleHide = e => {
    e.stopPropagation();

    this.props.onHide.call();
  };

  renderBody() {
    const { header, footer } = this.props;

    if (header) {
      return (
        <Inner withFooter={footer}>
          {React.cloneElement(this.props.children, {
            withHeader: header,
            withFooter: footer
          })}
        </Inner>
      );
    }

    return this.props.children;
  }

  render() {
    const {
      show,
      onHide,
      size,
      backdrop,
      header,
      footer,
      fade,
      preventClose,
      container,
      withTabs,
      onEntered,
      zindex,
      hideOverflow,
      dataQA,
      disabledClose,
      fullScreen,
      ...rest
    } = this.props;

    return (
      <Wrapper
        aria-labelledby="modal-label"
        backdrop={backdrop}
        backdropStyle={backdropStyle}
        backdropClassName="backdrop"
        show={show}
        onHide={() => {
          if (!disabledClose) {
            onHide();
          }
        }}
        transition={Fade}
        dialogTransitionTimeout={1000}
        onBackdropClick={e => e.stopPropagation()}
        containerClassName="body--blur"
        container={container}
        onEntered={onEntered}
        zindex={zindex}
        {...rest}
      >
        <Dialog footer={footer} header={header}>
          {!preventClose && (
            <DesktopClose
              icon="close"
              iconSize={12}
              onClick={this.handleHide}
              color="white"
              borderColor="white"
              id="desktop-modal-close"
              disabled={disabledClose}
              dataQA="button_desktop-modal-close"
            />
          )}

          <Content
            size={size}
            header={header}
            fade={fade}
            hideOverflow={hideOverflow}
            onClick={e => e.stopPropagation()}
            data-qa={dataQA}
            fullScreen={fullScreen}
          >
            {header && <ModalHeader withTabs={withTabs}>{header}</ModalHeader>}

            {!preventClose && (
              <MobileClose withHeader={header} onClick={this.handleHide}>
                <Icon color="lightGrey1" size={18} kind="close" />
              </MobileClose>
            )}

            {this.renderBody()}
            {footer && <ModalFooter>{footer}</ModalFooter>}
          </Content>
        </Dialog>
      </Wrapper>
    );
  }
}
