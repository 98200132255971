export default (plugins, newMessages) => {
  const $validator = plugins.dvr.package;
  const messages = $validator.getMessages('en');

  Object.keys(newMessages).forEach(key => {
    messages[key] = newMessages[key];
  });

  $validator.setMessages('en', messages);

  return plugins;
};
