import { action } from 'mobx';
import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import { t } from 'utils/translate';

const emailPreferencesFormPlugins = extendedPlugins;

const emailPreferencesFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const emailPreferencesFormFields = [
  'missedReportNotifications',
  'projectNotification',
  'dailyReports',
  'marketingEmails',
  'unsubscribeAll'
];

const emailPreferencesFormRules = {
  missedReportNotifications: 'boolean',
  projectNotification: 'boolean',
  dailyReports: 'boolean',
  marketingEmails: 'boolean',
  unsubscribeAll: 'boolean'
};

const emailPreferencesFormLabels = {
  missedReportNotifications: t('Notifications for missed dailies.'),
  projectNotification: t(
    'Notifications of safety items, delays, and progress on tasks, observations, and checklists, etc.'
  ),
  dailyReports: t(
    'Delivery of daily and super daily reports created in Raken.'
  ),
  marketingEmails: t(
    'Emails including newsletters, product updates, events, featured content and blog digest.'
  ),
  unsubscribeAll: t(
    'You can opt out of receiving any email or select what types of emails you would like to receive from us!'
  )
};

class EmailPreferencesForm extends MobxReactForm {
  @action.bound toggleField(field) {
    this.$(field).set(!this.$(field).value);
  }
}

export {
  EmailPreferencesForm,
  emailPreferencesFormOptions,
  emailPreferencesFormFields,
  emailPreferencesFormRules,
  emailPreferencesFormLabels,
  emailPreferencesFormPlugins
};
