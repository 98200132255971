import { computed } from 'mobx';
import { RakenCollection } from 'stores/lib';
import SubIndustry from 'stores/models/company/SubIndustry';

export default class SubIndustries extends RakenCollection {
  model() {
    return SubIndustry;
  }

  url() {
    return `ra/company-info/sub-industries`;
  }

  @computed get asOptions() {
    return this.models.map(model => {
      return {
        value: model.name,
        title: model.name
      };
    });
  }
}
