import { computed, action, observable, when, reaction } from 'mobx';

import debounce from 'lodash.debounce';
import orderBy from 'lodash.orderby';
import { t } from 'utils/translate';
import getSeriesColorHex from 'utils/getSeriesColorHex';

import UIStore from './UIStore';
import ProductionStatsByTimePeriod from '../collections/ProductionStatsByTimePeriod';

import { callTrack } from 'utils/segmentIntegration';
import { PRODUCTION_TRENDS_INTERACTED } from 'utils/segmentAnalytics/eventSpec';

export default class ProjectProductionTrendsUI extends UIStore {
  @observable costCodeSearch;
  @observable costCodeSelectionLimit;
  @observable trendsInteracted;

  constructor(options) {
    super(options);

    this.costCodeSearch = '';
    this.costCodeSelectionLimit = 5;
    this.selectedCostCodes = observable([]);
    this.trendsInteracted = false;

    this.statsByTimePeriod = new ProductionStatsByTimePeriod(null, {
      rootStore: this.rootStore,
      parent: this
    });

    this.fetchStatsByTimePeriodDebounced = debounce(
      this.fetchStatsByTimePeriod,
      1500
    );
  }

  @action.bound
  setupReactions() {
    this.reactToCostCodeSelection = reaction(
      () => this.selectedCostCodes.length,
      length => {
        this.statsByTimePeriod.fetching = true;
        this.fetchStatsByTimePeriodDebounced();
      }
    );

    when(() => this.trendsInteracted, () => this.sendEvent());
  }

  @action.bound tearDownReactions() {
    this.reactToCostCodeSelection();
  }

  @action.bound
  setTrendsInteracted() {
    this.trendsInteracted = true;
  }

  sendEvent() {
    callTrack(PRODUCTION_TRENDS_INTERACTED);
  }

  @computed get searchedCostCodes() {
    let costCodes = [];

    this.parent.divisions.models.forEach(division => {
      costCodes = costCodes.concat(division.costCodes.models.slice());
    });

    // Filter our cost codes with no budgeted or actual hours
    // Only do this when budgeting is setup for time
    if (this.project.timeTrackingEnabled) {
      costCodes = costCodes.filter(costCode => {
        return costCode.actualHours > 0 && costCode.budgetedHours > 0;
      });
    }

    if (this.costCodeSearch) {
      return costCodes.filter(costCode => {
        return (
          costCode.displayCode
            .toLowerCase()
            .indexOf(this.costCodeSearch.toLowerCase()) >= 0
        );
      });
    }

    return costCodes;
  }

  @computed get hasSearchedCostCodes() {
    return this.searchedCostCodes.length > 0;
  }

  @computed get atRiskCostCodes() {
    // PTH >= BH*1.1
    return this.searchedCostCodes.filter(costCode => {
      return costCode.projectedTotalHours >= costCode.budgetedHours * 1.1;
    });
  }

  @computed get hasAtRiskCostCodes() {
    return this.atRiskCostCodes.length > 0;
  }

  @computed get onTrackCostCodes() {
    // BH * 0.9 < PTH < BH * 1.1;
    return this.searchedCostCodes.filter(costCode => {
      return (
        costCode.budgetedHours * 0.9 < costCode.projectedTotalHours &&
        costCode.projectedTotalHours < costCode.budgetedHours * 1.1
      );
    });
  }

  @computed get hasOnTrackCostCodes() {
    return this.onTrackCostCodes.length > 0;
  }

  @computed get underBudgetCostCodes() {
    // PTH <= BH*0.9
    return this.searchedCostCodes.filter(costCode => {
      return costCode.projectedTotalHours <= costCode.budgetedHours * 0.9;
    });
  }

  @computed get hasUnderBudgetCostCodes() {
    return this.underBudgetCostCodes.length > 0;
  }

  @action.bound setCostCodeSearch(value) {
    this.costCodeSearch = value;
  }

  @action.bound clearCostCodeSearch() {
    this.costCodeSearch = '';
  }

  @action.bound clearUIState() {
    this.costCodeSearch = '';
    this.selectedCostCodes.clear();
    this.trendsInteracted = false;
  }

  @computed get costCodeSelectionString() {
    if (this.singleCostCodeView) {
      return this.selectedCostCodes
        .map(costCode => costCode.displayCode)
        .join(',');
    }

    if (this.hasCostCodeSelections) {
      return t('Selected Cost Codes');
    }

    return t('All Project Cost Codes');
  }

  @action.bound
  toggleCostCode(costCode) {
    if (this.selectedCostCodes.includes(costCode)) {
      this.selectedCostCodes.remove(costCode);
    } else {
      if (this.hasRemainingCostCodeSelections) {
        this.selectedCostCodes.push(costCode);
      }
    }
  }

  @computed get hasCostCodeSelections() {
    return this.selectedCostCodes.length > 0;
  }

  @computed get remainingCostCodeSelections() {
    return this.costCodeSelectionLimit - this.selectedCostCodes.length;
  }

  @computed get hasRemainingCostCodeSelections() {
    return this.remainingCostCodeSelections > 0;
  }

  @computed get defaultView() {
    return !this.selectedCostCodes.length;
  }

  @computed get singleCostCodeView() {
    return this.selectedCostCodes.length === 1;
  }

  @computed get multiCostCodeView() {
    return this.selectedCostCodes.length > 1;
  }

  @computed get columnChartView() {
    return this.defaultView || this.singleCostCodeView;
  }

  @computed get lineChartView() {
    return this.multiCostCodeView;
  }

  @computed get params() {
    return {
      fromDate: this.parent.fromDate,
      toDate: this.parent.toDate,
      isManual: this.parent.isManual,
      groupBy: 'WEEK',
      includeEmptyCostCodes: false,
      query: this.parent.searchQuery,
      costCodes: this.selectedCostCodes.map(costCode => costCode.uuid)
    };
  }

  @action.bound
  fetchStatsByTimePeriod() {
    return this.statsByTimePeriod.fetch({
      url: `${this.urlMicroService('performanceTracking')}/production/${
        this.project.uuid
      }/statsByTimePeriod`,
      params: this.params
    });
  }

  @computed get sortedStatsByTimePeriod() {
    return orderBy(this.statsByTimePeriod.models, ['fromDate'], ['asc']);
  }

  @computed get chartDateRanges() {
    return this.sortedStatsByTimePeriod.map(model => {
      return model.dateRange;
    });
  }

  @computed get columnChartSeries() {
    if (this.singleCostCodeView) {
      return this.singleCostCodeSeries;
    }

    return this.defaultChartSeries;
  }

  @computed get defaultChartSeries() {
    const series = [
      {
        name: 'AH',
        color: 'rgba(0, 162, 237, 0.65)',
        borderColor: 'rgb(0, 162, 237)',
        data: this.sortedStatsByTimePeriod.map(model => {
          return model.actualHours;
        })
      },
      {
        name: 'PH',
        color: 'rgba(255, 195, 0, 0.65)',
        borderColor: 'rgb(255, 195, 0)',
        data: this.sortedStatsByTimePeriod.map(model => {
          return model.projectedTotalHours;
        })
      },
      {
        name: '% C H',
        visible: false,
        data: this.sortedStatsByTimePeriod.map(model => {
          return model.completeHoursPercent;
        })
      },
      {
        name: 'BH',
        color: 'rgba(242, 102, 32, 0.65)',
        borderColor: 'rgba(242, 102, 32)',
        data: this.sortedStatsByTimePeriod.map(model => {
          return model.budgetedHours;
        })
      },
      {
        name: 'G/L',
        color: 'rgba(3, 177, 126,  0.65)',
        borderColor: 'rgba(3, 177, 126)',
        data: this.sortedStatsByTimePeriod.map(model => {
          const value = model.projectedHoursGainOrLoss;
          let color;
          let borderColor;

          if (value > 0) {
            color = 'rgba(3, 177, 126,  0.65)';
            borderColor = 'rgb(3, 177, 126)';
          } else {
            color = 'rgba(211, 17, 40,  0.65)';
            borderColor = 'rgb(211, 17, 40)';
          }

          return {
            y: value,
            color,
            borderColor
          };
        })
      }
    ];

    return series;
  }

  @computed get singleCostCodeSeries() {
    let series;

    const selectedCostCode = this.selectedCostCodes[0];

    series = [
      {
        name: 'AH',
        color: 'rgba(0, 162, 237, 0.65)',
        borderColor: 'rgb(0, 162, 237)',
        data: this.sortedStatsByTimePeriod.map(model => {
          return model.getCostCodeStat(selectedCostCode.uuid, 'actualHours');
        })
      },
      {
        name: 'PH',
        color: 'rgba(255, 195, 0, 0.65)',
        borderColor: 'rgb(255, 195, 0)',
        data: this.sortedStatsByTimePeriod.map(model => {
          return model.getCostCodeStat(
            selectedCostCode.uuid,
            'projectedTotalHours'
          );
        })
      },
      {
        name: '% C H',
        visible: false,
        data: this.sortedStatsByTimePeriod.map(model => {
          return model.getCostCodeStat(
            selectedCostCode.uuid,
            'completeHoursPercent'
          );
        })
      },
      {
        name: 'P % C',
        visible: false,
        data: this.sortedStatsByTimePeriod.map(model => {
          return model.getCostCodeStat(
            selectedCostCode.uuid,
            'progressHoursPercent'
          );
        })
      },
      {
        name: 'BH',
        color: 'rgba(242, 102, 32, 0.65)',
        borderColor: 'rgba(242, 102, 32)',
        data: this.sortedStatsByTimePeriod.map(model => {
          return model.getCostCodeStat(selectedCostCode.uuid, 'budgetedHours');
        })
      },
      {
        name: 'G/L',
        color: 'rgba(3, 177, 126,  0.65)',
        borderColor: 'rgba(3, 177, 126)',
        data: this.sortedStatsByTimePeriod.map(model => {
          const value = model.getCostCodeStat(
            selectedCostCode.uuid,
            'projectedHoursGainOrLoss'
          );
          let color;
          let borderColor;

          if (value > 0) {
            color = 'rgba(3, 177, 126,  0.65)';
            borderColor = 'rgb(3, 177, 126)';
          } else {
            color = 'rgba(211, 17, 40,  0.65)';
            borderColor = 'rgb(211, 17, 40)';
          }

          return {
            y: value,
            color,
            borderColor
          };
        })
      }
    ];

    if (!this.parent.isManual) {
      series = series.filter(series => series.name !== 'P % C');
    }

    return series;
  }

  @computed get lineChartSeries() {
    return this.selectedCostCodes.map((selectedCostCode, index) => {
      return {
        name: selectedCostCode.displayCode,
        color: getSeriesColorHex(index),
        data: this.sortedStatsByTimePeriod.map(model => {
          return model.getCostCodeStat(
            selectedCostCode.uuid,
            'projectedHoursGainOrLoss'
          );
        }),
        budgetedHours: this.sortedStatsByTimePeriod.map(model => {
          return model.getCostCodeStat(selectedCostCode.uuid, 'budgetedHours');
        }),
        actualHours: this.sortedStatsByTimePeriod.map(model => {
          return model.getCostCodeStat(selectedCostCode.uuid, 'actualHours');
        }),
        projectedTotalHours: this.sortedStatsByTimePeriod.map(
          (model, indexTwo) => {
            return model.getCostCodeStat(
              selectedCostCode.uuid,
              'projectedTotalHours'
            );
          }
        ),
        completeHoursPercent: this.sortedStatsByTimePeriod.map(model => {
          return model.getCostCodeStat(
            selectedCostCode.uuid,
            'completeHoursPercent'
          );
        }),
        progressHoursPercent: this.sortedStatsByTimePeriod.map(model => {
          return model.getCostCodeStat(
            selectedCostCode.uuid,
            'progressHoursPercent'
          );
        })
      };
    });
  }
}
