export default file => {
  if (file.type) return file.type;

  return new Promise((resolve, reject) => {
    if (window.FileReader && window.Blob) {
      const fileReader = new FileReader();

      fileReader.onloadend = function(e) {
        const arr = new Uint8Array(e.target.result).subarray(0, 4);
        let header = '';

        for (let i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }

        // for information
        // https://en.wikipedia.org/wiki/List_of_file_signatures
        switch (header) {
          case '504b34':
          case '504b56':
          case '504b78':
            return resolve(
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            );
          case 'd0cf11e0':
            return resolve('application/msword');
          default:
            return resolve('application/octet-stream');
        }
      };

      fileReader.readAsArrayBuffer(file);
    } else {
      return resolve('application/octet-stream');
    }
  });
};
