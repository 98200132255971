import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import { t } from 'utils/translate';

class ProjectCreationForm extends MobxReactForm {}

ProjectCreationForm.plugins = extendedPlugins;

ProjectCreationForm.options = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

ProjectCreationForm.fields = [
  'name',
  'projectNo',
  'address',
  'address.streetAddress',
  'address.postCode',
  'address.state',
  'address.city',
  'address.country',
  'startDate',
  'endDate',
  'projectState',
  'templateId',
  'groups[]',
  'groups[].uuid'
];

ProjectCreationForm.rules = {
  name: 'required|string|max:2048',
  projectNo: 'string|max:255',
  'address.streetAddress': 'string|max:100',
  'address.city': 'string|max:100',
  'address.state': 'string|max:255',
  'address.postCode': 'string|max:20',
  'address.country': 'string',
  startDate: 'required|dateCustom:YYYY-MM-DD',
  endDate: 'dateCustom:YYYY-MM-DD|afterCustom:startDate,YYYY-MM-DD',
  projectState: 'required|string',
  groups: 'array'
};

ProjectCreationForm.values = {
  projectState: 'ACTIVE',
  address: {
    country: 'US'
  },
  startDate: new Date(Date.now()),
  groups: []
};

ProjectCreationForm.labels = {
  name: t('project name'),
  projectNo: t('job #'),
  'address.streetAddress': t('street address'),
  'address.city': t('city'),
  'address.state': t('state'),
  'address.postCode': t('zip code'),
  'address.country': t('country'),
  startDate: t('project start date'),
  endDate: t('project end date'),
  projectState: t('project state'),
  templateId: t('Project Templates'),
  groups: t('project groups')
};

export default ProjectCreationForm;
