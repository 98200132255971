import { computed } from 'mobx';
import { RakenCollection } from '../lib';
import WorkersMapLocation from '../models/WorkersMapLocation';

export default class WorkersMapLocations extends RakenCollection {
  model() {
    return WorkersMapLocation;
  }

  url() {
    return `ra/companies/${this.rootStore.me.company.uuid}/projects`;
  }

  @computed get workerUuids() {
    return this.models
      .map(location =>
        location.workers.map(worker => {
          return worker.uuid;
        })
      )
      .flat();
  }

  @computed get workersCount() {
    return this.workerUuids.length;
  }

  @computed get hasNoWorkers() {
    return this.workersCount === 0;
  }
}
