import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import { t } from 'utils/translate';

const companyTimeCardsFormPlugins = extendedPlugins;

const companyTimeCardsFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false,
  autoParseNumbers: false
};

const companyTimeCardsFormFields = [
  'dateFormat',
  'weekFirstDay',
  'showTimeCardsDivision',
  'trackMemberTime',
  'applyTo',
  'projects[]'
];

const companyTimeCardsFormRules = {
  dateFormat: 'string',
  weekFirstDay: 'numeric',
  showTimeCardsDivision: 'boolean',
  trackMemberTime: 'string'
};

const companyTimeCardsFormValues = {
  applyTo: 'ALL_ACTIVE'
};

const companyTimeCardsFormDisabled = {};

const companyTimeCardsFormLabels = {
  showTimeCardsDivision: t('Show Division on Reports & App'),
  trackMemberTime: t('Employee Hours Tracking')
};

/**
 * TODO Look at removing this form if not in use or tidying up the reactions
 * as part of the clean up for FF_ATHENS_TIMECARDS_P2
 */
class CompanyTimeCardsForm extends MobxReactForm {
  constructor(settings, options) {
    super(settings, options);

    this.rootStore = options.parent;
  }
}

export {
  CompanyTimeCardsForm,
  companyTimeCardsFormOptions,
  companyTimeCardsFormFields,
  companyTimeCardsFormRules,
  companyTimeCardsFormPlugins,
  companyTimeCardsFormValues,
  companyTimeCardsFormDisabled,
  companyTimeCardsFormLabels
};
