import { action, computed } from 'mobx';
import ProjectChildAddUI from './ProjectChildAddUI';
import MaterialLog from '../../models/MaterialLog';

import {
  MaterialLogForm,
  materialLogFormOptions,
  materialLogFormFields,
  materialLogFormRules,
  materialLogFormValues,
  materialLogFormLabels,
  materialLogFormPlugins
} from 'forms/project/materialLog';

import history from 'utils/history';
import alertErrorHandler from 'utils/alertErrorHandler';

import { t } from 'utils/translate';

import { callTrack } from 'utils/segmentIntegration';
import { MATERIAL_LOG_ADDED } from 'utils/segmentAnalytics/eventSpec';

export default class MaterialLogAddUI extends ProjectChildAddUI {
  @action.bound setup() {
    this.materialSelectorUI.setup();
    this.costCodeSelectorUI.setup();

    this.entryForAdd = new MaterialLog(
      { type: 'MaterialLog' },
      {
        rootStore: this.rootStore
      }
    );

    this.entryAddForm = new MaterialLogForm(
      {
        fields: materialLogFormFields,
        rules: materialLogFormRules,
        labels: materialLogFormLabels,
        values: materialLogFormValues
      },
      {
        parentStore: this,
        options: materialLogFormOptions,
        plugins: materialLogFormPlugins
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @action.bound tearDown() {
    this.materialSelectorUI.tearDown();
    this.costCodeSelectorUI.tearDown();
    this.unblockHistory();
    this.clearUIState();
  }

  @action.bound async submitEntryAddFormSuccess() {
    this.saving = true;

    const values = this.entryAddForm.values();

    try {
      const attachments = this.entryForAdd.attachments.models.map(
        attachment => {
          return {
            uuid: attachment.uuid
          };
        }
      );

      if (this.entryAddForm.duplicateMaterialLog) {
        await this.mergeNewMaterialLog(values, attachments);
      } else {
        await this.entryForAdd.save(
          Object.assign(this.entryAddForm.values(), this.reportParams, {
            attachments: attachments
          }),
          {
            wait: true,
            stripNonRest: false
          }
        );
      }

      callTrack(MATERIAL_LOG_ADDED, {
        project_id: this.project?.id,
        project_name: this.project?.name
      });

      this.unblockHistory();
      this.parent.sortByLastCreated();
      this.cancelMaterialLogAdd();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Material log created')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound mergeNewMaterialLog(values, newAttachments) {
    const { duplicateMaterialLog } = this.entryAddForm;
    const { quantity, desc } = duplicateMaterialLog;

    const attachments = duplicateMaterialLog.attachments.models.map(
      attachment => {
        return {
          uuid: attachment.uuid
        };
      }
    );

    const quantityResult = +(
      parseFloat(quantity) + parseFloat(values.quantity)
    ).toFixed(2);

    return duplicateMaterialLog.save(
      {
        quantity: quantityResult,
        desc: this.concatenateDescriptions(desc, values.desc),
        attachments: attachments.concat(newAttachments)
      },
      {
        wait: true
      }
    );
  }

  concatenateDescriptions(descOne, descTwo) {
    if (descOne && descTwo) {
      return descOne + '\n\n' + descTwo;
    }

    return descOne || descTwo || '';
  }

  @action.bound cancelMaterialLogAdd() {
    history.push({
      pathname: `${this.project.viewUrl}/materials`,
      search: this.baseQueryParams
    });
  }

  @computed get title() {
    return t('Add material log');
  }

  @computed get materialsUI() {
    return this.parent.materialsUI;
  }
}
