import { action, computed, observable } from 'mobx';
import UIStore from 'stores/ui/UIStore';
import SuperAdminSuperUser from 'stores/models/superAdmin/SuperAdminSuperUser';

import {
  AddSuperUserForm,
  addSuperUserFormOptions,
  addSuperUserFormFields,
  addSuperUserFormRules,
  addSuperUserFormLabels,
  addSuperUserFormPlugins
} from 'forms/superAdmin/addSuperUserForm';

import history from 'utils/history';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';

export default class SuperAdminSuperUserAddUI extends UIStore {
  @observable entryForAdd;
  @observable entryAddForm;

  constructor(options) {
    super(options);

    this.entryForAdd = null;
    this.entryAddForm = null;
  }

  @action.bound setup() {
    this.entryForAdd = new SuperAdminSuperUser(
      { type: 'SuperAdminSuperUser' },
      {
        rootStore: this.rootStore
      }
    );

    this.entryAddForm = new AddSuperUserForm(
      {
        fields: addSuperUserFormFields,
        rules: addSuperUserFormRules,
        values: {},
        labels: addSuperUserFormLabels
      },
      {
        options: addSuperUserFormOptions,
        plugins: addSuperUserFormPlugins
      }
    );
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound submitEntryAddForm(event) {
    event.preventDefault();
    event.stopPropagation();

    this.entryAddForm.submit({
      onSuccess: this.submitEntryAddFormSuccess,
      onError: this.submitEntryAddFormError
    });
  }

  @action.bound async submitEntryAddFormSuccess() {
    this.saving = true;

    try {
      await this.entryForAdd.save(this.entryAddForm.values(), {
        wait: true,
        stripNonRest: false
      });

      this.parent.sortByLastCreated();
      this.cancelSuperAdminSuperUserAdd();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Super user created')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitEntryAddFormError() {
    console.error(this.entryAddForm.errors());
  }

  @action.bound cancelSuperAdminSuperUserAdd() {
    history.push({
      pathname: `/super-users`
    });
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();

    this.activeModal = null;
    this.entryForAdd = null;
    this.entryAddForm = null;
    this.saving = false;
  }

  @computed get disableCancelButton() {
    return Boolean(this.saving);
  }

  @computed get disableSaveButton() {
    return Boolean(this.saving);
  }
}
