import moment from 'moment';
import request from 'axios';
import { action, observable, computed } from 'mobx';

import UIStore from './UIStore';
import Weather from '../models/Weather';
import WeatherDailys from '../collections/WeatherDailys';
import { DETAILED_WEATHER_VIEWED } from 'utils/segmentAnalytics/eventSpec';
import { callTrack } from 'utils/segmentIntegration';

import { t } from 'utils/translate';

export default class WeatherUI extends UIStore {
  @observable model;
  @observable project;
  @observable modelDate;
  @observable weekDailysDate;
  @observable showCalendar;
  @observable currentMonth;
  @observable pageDaily;
  @observable weekDailys;
  @observable fetchingDaily;

  constructor(options) {
    super(options);
    this.project = null;
    this.modelDate = null;
    this.weekDailysDate = null;
    this.model = new Weather(
      {},
      {
        rootStore: this.rootStore,
        parent: this
      }
    );
    this.showCalendar = false;
    this.currentMonth = moment();
    this.pageDaily = 0;
    this.fetchingDaily = false;

    this.weekDailys = new WeatherDailys(null, {
      rootStore: this.rootStore,
      parent: this.model
    });
  }

  formatDate(date) {
    return date ? moment(date).format('YYYY-MM-DD') : null;
  }

  buildConfig() {
    const config = {};
    config[moment(this.modelDate).format('M/D/YYYY')] = {
      selected: true,
      selectedColor: 'goGreen'
    };
    return config;
  }

  @action.bound
  close() {
    this.hideActiveModal().then(() => {
      this.showCalendar = false;
    });
  }

  @action.bound
  toggleCalendar() {
    this.showCalendar = !this.showCalendar;
  }

  @action.bound
  showProjectModal(project, date, fetch = true) {
    callTrack(DETAILED_WEATHER_VIEWED);

    if (fetch) {
      this.setProjectAndDate(project, date);
    }

    this.showModal('project');
  }

  @computed
  get showDailyNextButton() {
    return this.pageDaily / 7 <= 1;
  }

  @computed
  get showNextButtonForCalendar() {
    return this.pageDaily < 14;
  }

  @action.bound
  setProjectAndDate(project, date) {
    if (this.project === project && this.modelDate === date) return;

    this.project = project;
    this.modelDate = date;
    this.weekDailysDate = date;
    this.fetchWeatherForProjectAndDate('forecast');
  }

  @action.bound
  fetchWeatherForProjectAndDate = (
    type = 'historical',
    updateType = 'both'
  ) => {
    const { geolocation } = this.project.address;
    const { languageCode } = this.rootStore.me.settings;

    if (!geolocation.lat || !geolocation.lng) return;

    let url = '';
    let date = this.modelDate;
    let lang =
      languageCode && languageCode !== 'en-US'
        ? languageCode.split('-')[0]
        : '';

    if (updateType === 'dailys') {
      date = this.weekDailysDate;
    }

    if (type === 'historical') {
      url = `${
        this.rootStore.appConfig.weather_historical_base_url
      }historical?key=${moment(date).format(
        'YYYY/MM/DD'
      )}/(${geolocation.lat.toFixed(1)})(${geolocation.lng.toFixed(1)})${
        lang !== '' ? '(' + lang + ')' : lang
      }.json`;
    } else {
      url = `${
        this.rootStore.appConfig.weather_forecast_api_url
      }forecast?lat=${geolocation.lat.toFixed(1)}&lng=${geolocation.lng.toFixed(
        1
      )}&fromDate=${date}${lang !== '' ? '&language=' + lang : lang}`;
    }

    if (updateType === 'both') {
      this.model.setRequestLabel('fetching', true);
    }

    this.fetchingDaily = true;

    return request
      .get(url, {
        withCredentials: false,
        transformRequest: [
          (data, headers) => {
            delete headers.common.Authentication;
            return data;
          }
        ]
      })
      .then(response => {
        if (response.data) {
          if (updateType === 'both') {
            this.model.set(response.data);
          }
          this.weekDailys.reset(response.data.dailys);
        }

        this.model.setRequestLabel('fetching', false);
        this.fetchingDaily = false;
        this.model.clearApiFailed();

        this.pageDaily = moment(
          updateType === 'dailys' ? this.weekDailysDate : this.modelDate
        ).diff(moment().format('YYYY-MM-DD'), 'days');
      })
      .catch(error => {
        this.model.setRequestLabel('fetching', false);
        this.fetchingDaily = false;
        this.model.setApiFailed();
      });
  };

  @action.bound
  fetchNextDay() {
    this.modelDate = this.formatDate(moment(this.modelDate).add(1, 'day'));
    this.fetchWeatherForProjectAndDate('forecast');
  }

  @action.bound
  fetchNextWeek() {
    if (this.fetchingDaily) return;

    this.weekDailysDate = this.formatDate(
      moment(this.weekDailysDate).add(7, 'days')
    );

    this.fetchWeatherForProjectAndDate('forecast', 'dailys');
  }

  @action.bound
  fetchNextMonth() {
    this.currentMonth = this.currentMonth.clone().add(1, 'month');
  }

  @action.bound
  fetchPreviousDay() {
    this.modelDate = this.formatDate(moment(this.modelDate).subtract(1, 'day'));
    this.fetchWeatherForProjectAndDate('forecast');
  }

  @action.bound
  fetchPreviousWeek() {
    if (this.fetchingDaily) return;

    this.weekDailysDate = this.formatDate(
      moment(this.weekDailysDate).subtract(7, 'days')
    );
    this.fetchWeatherForProjectAndDate('forecast', 'dailys');
  }

  @action.bound
  fetchPreviousMonth() {
    this.currentMonth = this.currentMonth.clone().subtract(1, 'month');
  }

  @action.bound
  fetchDaySelected(day) {
    this.modelDate = this.formatDate(day);
    this.weekDailysDate = this.formatDate(day);
    this.fetchWeatherForProjectAndDate('forecast');
    this.toggleCalendar();
  }

  @action.bound clearUIState() {
    this.project = null;
    this.modelDate = null;
    this.weekDailysDate = null;
  }

  @computed get selectedDayTooltip() {
    const { selectedDay } = this.model;

    if (!selectedDay) return null;

    return t('{low}° / {high}° {summary}', {
      templateStrings: {
        low: selectedDay.low,
        high: selectedDay.high,
        summary: selectedDay.summary
      }
    });
  }
}
