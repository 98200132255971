import { computed } from 'mobx';
import { Model } from 'mobx-mc';
import { t } from 'utils/translate';

export default class Question extends Model {
  get restAttributes() {
    return ['id', 'type', 'category', 'question', 'tag', 'version'];
  }

  @computed get categoryText() {
    switch (this.category) {
      case 'CATEGORY_GENERAL':
        return t('General Site Conditions');
      case 'CATEGORY_DELAYS':
        return t('Schedule Delays');
      case 'CATEGORY_DELAYS_WEATHER':
        return t('Weather Delays');
      case 'CATEGORY_SAFETY':
        return t('Site Safety');
      case 'CATEGORY_INSPECTION':
        return t('Inspection');
      case 'CATEGORY_VISITORS':
        return t('Site Visitors');
      case 'CATEGORY_EQUIPMENT':
        return t('Equipment & Deliveries');
      case 'CATEGORY_ENVIRONMENTAL':
        return t('Environmental Concerns');
      default:
        return t('General Site Conditions');
    }
  }
}
