import orderBy from 'lodash.orderby';
import omit from 'lodash.omit';
import { Model } from 'mobx-mc';
import ChecklistTemplateQuestions from 'stores/collections/checklists/ChecklistTemplateQuestions';
import { action, computed } from 'mobx';

export default class ChecklistTemplateSection extends Model {
  get restAttributes() {
    return ['uuid', 'position', 'title', 'description'];
  }

  idAttribute() {
    return 'uuid';
  }

  @action.bound parse(attributes) {
    this.parseChecklistTemplateQuestions(attributes);

    return {
      ...omit(attributes, 'checklistTemplateQuestions')
    };
  }

  @action.bound parseChecklistTemplateQuestions(attributes) {
    if (this.checklistTemplateQuestions) {
      this.checklistTemplateQuestions.reset(
        attributes.checklistTemplateQuestions
      );
    } else {
      this.checklistTemplateQuestions = new ChecklistTemplateQuestions(
        attributes.checklistTemplateQuestions,
        {
          parent: this,
          rootStore: this.rootStore
        }
      );
    }
  }

  @computed get formValues() {
    return {
      uuid: this.uuid,
      title: this.title,
      position: this.position,
      description: this.description,
      checklistTemplateQuestions: orderBy(
        this.checklistTemplateQuestions.models,
        'position',
        'asc'
      ).map(checklistTemplateQuestion => {
        return checklistTemplateQuestion.formValues;
      })
    };
  }
}
