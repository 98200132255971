import { observable, action, computed } from 'mobx';
import errorHandler from 'utils/errorHandler';
import matchModelField from 'utils/matchModelField';
import orderBy from 'lodash.orderby';

import UIStore from './UIStore';

export default class ProjectSettingsBreaksUI extends UIStore {
  @observable searchQuery;
  @observable entryForEdit;
  @observable entryEditForm;
  @observable pageSize;
  @observable page;
  @observable sortDirection;
  @observable sortField;

  constructor(options) {
    super(options);

    this.uploadProgress = 0;

    // Editing
    this.entryForEdit = null;
    this.entryEditForm = null;

    // Search & Pagination
    this.searchQuery = '';
    this.pageSize = 20;
    this.page = 0;
  }

  @action.bound clearUIState() {
    this.searchQuery = '';
    this.page = 0;
  }

  @computed
  get project() {
    return this.parent.project;
  }

  @computed
  get breaks() {
    return this.rootStore.breaks;
  }

  @computed
  get changed() {
    return this.saving;
  }

  @computed get hasBreaks() {
    return this.breaks.hasModels;
  }

  @action.bound
  async fetchBreaks() {
    try {
      await this.breaks.fetch({
        params: {
          limit: 10000
        },
        add: true,
        remove: false,
        update: true
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  @computed get sortedBreaks() {
    return orderBy(
      this.breaks.models,
      [
        orderedBreak => orderedBreak.new,
        orderedBreak => orderedBreak.name.toLowerCase()
      ],
      ['desc', this.sortDirection]
    );
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 0;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 0;
  }

  @computed get searchedBreaks() {
    if (!this.searchQuery) {
      return this.sortedBreaks;
    }

    const query = this.searchQuery.toLowerCase();

    return this.sortedBreaks.filter(sortedBreak => {
      return (
        matchModelField(sortedBreak, query, 'name') ||
        matchModelField(sortedBreak, query, 'code')
      );
    });
  }

  @computed get hasSearchedBreaks() {
    return this.searchedBreaks.length > 0;
  }

  @computed get paginatedBreaks() {
    return this.searchedBreaks.slice(
      this.page * this.pageSize,
      this.page * this.pageSize + this.pageSize
    );
  }

  @computed
  get totalPages() {
    return Math.ceil(this.searchedBreaks.length / this.pageSize);
  }

  @action.bound
  setPage(page) {
    this.page = page.selected;
  }

  @action.bound
  sortByColumn() {
    if (this.sortDirection === 'asc') {
      this.sortDirection = 'desc';
    } else {
      this.sortDirection = 'asc';
    }
  }

  @action.bound
  toggleBreakForProject(breakToToggle) {
    if (!breakToToggle.assignedToCurrentProject) {
      const breakIndex = breakToToggle.hiddenProjects
        .map(item => item.uuid)
        .indexOf(this.project.uuid);

      breakToToggle.hiddenProjects.splice(breakIndex, 1);
    } else {
      breakToToggle.hiddenProjects.push({ uuid: this.project.uuid });
    }

    try {
      breakToToggle.save();
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }
}
