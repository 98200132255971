import { action, computed, observable } from 'mobx';
import UIStore from '../../UIStore';
import history from 'utils/history';
import {
  groupFormFields,
  groupFormOptions,
  groupFormPlugins,
  groupFormRules,
  groupFormLabels,
  GroupForm
} from 'forms/group';

import Group from 'stores/models/groups/Group';
import GroupClasses from 'stores/collections/groups/GroupClasses';
import GroupProjectsUI from './GroupProjectsUI';
import GroupMembersUI from './GroupMembersUI';

import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

import { callTrack } from 'utils/segmentIntegration';
import { GROUP_EDITED } from 'utils/segmentAnalytics/eventSpec';

export default class GroupEditUI extends UIStore {
  @observable entryForEdit;
  @observable entryEditForm;

  constructor(options) {
    super(options);

    this.groupClasses = new GroupClasses(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.groupProjectsUI = new GroupProjectsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.groupMembersUI = new GroupMembersUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.entryForEdit = null;
    this.entryEditForm = null;
  }

  @action.bound async setup(id) {
    this.groupClasses.fetch({
      params: {
        limit: 10000
      }
    });
    this.fetchEntry(id);
  }

  @action.bound async fetchEntry(uuid) {
    if (this.entryForEdit) return;

    try {
      let model = this.parent.groups.get(uuid);

      if (!model) {
        model = new Group(
          {
            uuid: uuid
          },
          {
            rootStore: this.rootStore
          }
        );
      }

      await model.fetch();

      this.setEntryForEdit(model);
    } catch (error) {
      console.error(error);
      this.cancelGroupEdit();
    }
  }

  @action.bound setEntryForEdit(model) {
    this.entryForEdit = model;
    this.entryEditForm = new GroupForm(
      {
        fields: groupFormFields,
        rules: groupFormRules,
        labels: groupFormLabels,
        values: this.entryForEdit.formValues
      },
      {
        options: groupFormOptions,
        plugins: groupFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.activeModal = null;
    this.entryForEdit = null;
    this.entryEditForm = null;
    this.saving = false;
  }

  @computed get groupClassOptions() {
    return this.groupClasses.models.map(groupClass => ({
      name: groupClass.name,
      uuid: groupClass.uuid
    }));
  }

  @action.bound
  async submitGroupEditSuccess() {
    this.saving = true;
    const { groupName, groupClass } = this.entryEditForm.trimmedValues();
    try {
      await this.entryForEdit.save(
        {
          groupName,
          groupClass: {
            uuid: groupClass.uuid
          }
        },
        {
          wait: true
        }
      );

      callTrack(GROUP_EDITED, {
        group_class: groupClass.name,
        group_name: groupName
      });

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Group info saved')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  submitGroupEdit(e) {
    e.preventDefault();
    e.stopPropagation();

    this.entryEditForm.submit({
      onSuccess: this.submitGroupEditSuccess,
      onError: e => {
        console.error(this.entryEditForm.errors());
      }
    });
  }

  @action.bound
  cancelGroupEdit() {
    history.push({
      pathname: `/company-settings/groups`,
      search: this.baseQueryParams
    });
  }

  @computed get tabs() {
    return [
      {
        path: {
          pathname: `${this.entryForEdit.viewUrl}/info`,
          state: { previousPath: this.previousPath }
        },
        label: t('Info')
      },
      {
        path: {
          pathname: `${this.entryForEdit.viewUrl}/projects`,
          state: { previousPath: this.previousPath }
        },
        label: t('Projects')
      },
      {
        path: {
          pathname: `${this.entryForEdit.viewUrl}/employees`,
          state: { previousPath: this.previousPath }
        },
        label: t('Employees')
      }
    ];
  }

  @computed get showUI() {
    return this.entryForEdit && !this.entryForEdit.fetching;
  }
}
