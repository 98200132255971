import omit from 'lodash.omit';
import orderBy from 'lodash.orderby';
import { Model } from 'mobx-mc';
import { computed, action } from 'mobx';
import ChecklistType from './ChecklistType';
import ChecklistTemplateSections from 'stores/collections/checklists/ChecklistTemplateSections';
import moment from 'moment';
import { t } from 'utils/translate';
import kebabCase from 'lodash.kebabcase';

export default class ChecklistTemplate extends Model {
  get urlRoot() {
    return `${this.rootStore.urlMicroService('toolbox')}/companies/${
      this.rootStore.me.company.uuid
    }/checklist-templates`;
  }

  get restAttributes() {
    return [
      'uuid',
      'companyUuid',
      'name',
      'description',
      'status',
      'scope',
      'createdBy',
      'createdTimestamp',
      'updatedTimestamp',
      'updatedBy',
      'checklistType',
      'author'
    ];
  }

  idAttribute() {
    return 'uuid';
  }

  @action.bound parse(attributes) {
    this.parseChecklistTemplateSections(attributes);
    this.parseChecklistType(attributes);

    return {
      ...omit(attributes, ['checklistTemplateSections', 'checklistType'])
    };
  }

  @action.bound parseChecklistTemplateSections(attributes) {
    if (this.checklistTemplateSections) {
      this.checklistTemplateSections.reset(
        attributes.checklistTemplateSections
      );
    } else {
      this.checklistTemplateSections = new ChecklistTemplateSections(
        attributes.checklistTemplateSections,
        {
          parent: this,
          rootStore: this.rootStore
        }
      );
    }
  }

  @action.bound parseChecklistType(attributes) {
    if (attributes.checklistType) {
      this.checklistType = new ChecklistType(attributes.checklistType, {
        rootStore: this.rootStore
      });
    }
  }

  get isTemplate() {
    return true;
  }

  @computed
  get slug() {
    return kebabCase(this.name);
  }

  @computed
  get updatedOn() {
    return this.formatTimestamp(this.updatedTimestamp);
  }

  @computed
  get createdDate() {
    return this.formatTimestamp(this.createdTimestamp);
  }

  formatTimestamp(timestamp) {
    var now = moment();
    var then = moment(timestamp);

    if (now.isSame(then, 'day')) {
      return t('Today at') + ' ' + then.format('h:mm A');
    }

    if (now.subtract(1, 'day').isSame(then, 'day')) {
      return t('Yesterday at') + ' ' + then.format('h:mm A');
    }

    if (now.isSame(then, 'year')) {
      return then.format('MMM D h:mm A');
    }

    return then.format('MMM D, YYYY h:mm A');
  }

  @computed get formValues() {
    return {
      name: this.name,
      checklistType: this.checklistType?.formValues,
      checklistTemplateSections: orderBy(
        this.checklistTemplateSections.models,
        'position',
        'asc'
      ).map(checklistTemplateSection => {
        return checklistTemplateSection.formValues;
      })
    };
  }

  @computed get formValuesForChecklist() {
    const checklistSections = this.checklistTemplateSections.models.map(
      checklistTemplateSection => {
        const {
          checklistTemplateQuestions,
          ...sectionNoQuestions
        } = checklistTemplateSection.formValues;

        return {
          ...sectionNoQuestions,
          checklistQuestions: checklistTemplateQuestions.map(question => {
            question.checklistResponses = [
              {
                value: '',
                note: '',
                choices:
                  question.choices &&
                  question.choices.map(choice => {
                    // we need to remove the template uuid's here.
                    return {
                      position: choice.position,
                      description: choice.description,
                      selected: false
                    };
                  })
              }
            ];
            return question;
          })
        };
      }
    );

    return {
      templateUuid: this.uuid,
      name: this.name,
      checklistType: this.checklistType?.formValues,
      checklistSections: checklistSections
    };
  }

  @computed get typeString() {
    if (!this.checklistType) return '-';

    return this.checklistType.typeName;
  }
}
