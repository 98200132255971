import { RakenCollection } from '../../lib';
import Device from '../../models/superAdmin/Device';

export default class Devices extends RakenCollection {
  model() {
    return Device;
  }

  url() {
    return `/ra/cheaters/user/${this.rootStore.activeUser.id}/devices`;
  }
}
