// these sizes are arbitrary and you can set them to whatever you wish
import { css } from 'styled-components';

export const sizes = {
  xLarge: 1920,
  large: 1320,
  desktop: 768,
  tablet: 768,
  phone: 0
};

// iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((accumulator, label) => {
  // use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  const emSize = sizes[label] / 16;
  accumulator[label] = (...args) => css`
    @media (min-width: ${emSize}em) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {});

media.edge = (...args) => css`
  @supports (-ms-ime-align: auto) {
    ${css(...args)};
  }
`;

media.smallDesktop = (...args) => css`
  @media only screen and (min-width: 767px) and (max-width: 991px) {
    ${css(...args)};
  }
`;

media.beforeLarge = (...args) => css`
  @media only screen and (max-width: ${sizes.large / 16}em) {
    ${css(...args)};
  }
`;

media.mobileOnly = (...args) => css`
  @media only screen and (max-width: 767px) {
    ${css(...args)};
  }
`;

export default media;
