import request from 'axios';
import debounce from 'lodash.debounce';
import { reaction, computed, action, observable } from 'mobx';
import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import { t } from 'utils/translate';
import trimObject from '../utils/trimObject';
import Material from '../stores/models/Material';

const materialFormPlugins = extendedPlugins;

const materialFormOptions = {
  validateOnInit: false,
  strictUpdate: false,
  validateOnChange: true
};

const materialFormFields = ['name', 'unit'];

const materialFormRules = {
  name: 'string|max:255|required',
  unit: 'string|required'
};

const materialFormLabels = {
  name: t('name'),
  code: t('unit')
};

class MaterialForm extends MobxReactForm {
  @observable existingMaterial;

  constructor(settings, options) {
    super(settings, options);

    this.rootStore = options.rootStore;
    this.existingMaterial = null;
    this.searchMaterials = debounce(this.searchMaterials, 250);

    this.reactToSearchParams = reaction(
      () => this.searchParams,
      searchParams => {
        if (searchParams) {
          this.searchMaterials();
        }
      }
    );
  }

  @computed get unitName() {
    if (!this.$('unit').value) return null;

    const unit = this.rootStore.units.get(this.$('unit').value);

    if (!unit) return null;

    return unit.name;
  }

  @computed get searchParams() {
    if (!this.$('name').value || !this.$('unit').value) {
      return null;
    }

    return {
      name: this.$('name').value,
      unit: this.unitName
    };
  }

  @action.bound searchMaterials() {
    if (!this.searchParams) return;

    this.existingMaterial = null;

    request
      .get(
        `${this.rootStore.urlMicroService('performanceTracking')}/materials`,
        {
          params: this.searchParams
        }
      )
      .then(response => {
        if (response.data.collection?.length > 0) {
          this.existingMaterial = new Material(response.data.collection[0], {
            rootStore: this.rootStore
          });
        } else {
          this.existingMaterial = null;
        }
      })
      .catch(error => {
        this.existingMaterial = null;
      });
  }

  trimmedValues() {
    return trimObject(this.values());
  }
}

export {
  MaterialForm,
  materialFormOptions,
  materialFormFields,
  materialFormRules,
  materialFormLabels,
  materialFormPlugins
};
