import { computed } from 'mobx';
import { RakenCollection } from '../lib';
import InsightsPeriod from '../models/InsightsPeriod';

export default class InsightsPeriods extends RakenCollection {
  model() {
    return InsightsPeriod;
  }

  parse() {}

  @computed
  get projectMeasures() {
    const measures = [];
    this.models.forEach(insightsPeriod => {
      insightsPeriod.projectMeasures.models.forEach(projectMeasure => {
        measures.push(projectMeasure);
      });
    });
    return measures;
  }
}
