import debounce from 'lodash.debounce';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import UIStore from 'stores/ui/UIStore';

import FormTemplates from 'stores/collections/forms/FormTemplates';
import FormTemplateAddUI from './FormTemplateAddUI';
import FormTemplateEditUI from './FormTemplateEditUI';
import FormTemplateActionsUI from './FormTemplateActionsUI';

import history from 'utils/history';
import { BASE_DEBOUNCE } from 'fixtures/constants';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

import { FORM_TEMPLATE_FEATURE_VIEWED } from 'utils/segmentAnalytics/eventSpec';
import { callTrack } from 'utils/segmentIntegration';

export default class FormTemplatesUI extends UIStore {
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;

  constructor(options) {
    super(options);

    this.loading = true;

    this.sortField = 'updatedTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.searchQuery = '';
    this.pageSize = 20;

    // Forms collection
    this.formTemplates = new FormTemplates(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.formTemplateActionsUI = new FormTemplateActionsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.formTemplateAddUI = new FormTemplateAddUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.formTemplateEditUI = new FormTemplateEditUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchFormTemplatesDebounced = debounce(
      this.fetchFormTemplates,
      BASE_DEBOUNCE
    );
  }

  @action.bound setup() {
    callTrack(FORM_TEMPLATE_FEATURE_VIEWED);
    window.scrollTo(0, 0);
    this.setupReactions();
    this.fetchFormTemplates();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchFormTemplatesDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      sortField: this.sortField,
      sortDirection: this.sortDirection
    };
  }

  @action.bound async fetchFormTemplates() {
    this.formTemplates.cancelRequest();
    this.formTemplates.clear();

    try {
      await this.formTemplates.fetch({
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasFormTemplates() {
    return this.formTemplates.hasModels;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.formTemplates.totalElements / this.pageSize);
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.searchQuery = '';
    this.formTemplates.clear();
    this.page = 1;
    this.loading = true;
    this.saving = false;
    this.sortField = 'updatedTimestamp';
    this.sortDirection = 'desc';
  }

  @computed get showEmptyState() {
    return !this.loading && !this.searchQuery && !this.hasFormTemplates;
  }

  @computed get showEmptySearchState() {
    return !this.loading && this.searchQuery && !this.hasFormTemplates;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @action.bound async addFormTemplate() {
    await this.authorization.checkFeatureAccess('CUDForms');

    history.push({
      pathname: `/company-settings/forms/templates/add`
    });
  }

  @action.bound async editFormTemplate(form) {
    history.push({
      pathname: `/company-settings/forms/templates/${form.uuid}`
    });
  }

  @action.bound sortByLastCreated() {
    this.sortField = 'createdTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchFormTemplatesDebounced();
  }

  @action.bound refetchFormTemplates() {
    this.loading = true;
    if (!this.hasFormTemplates) {
      this.setPage(null, 1);
      this.fetchFormTemplates();
    } else {
      this.fetchFormTemplates();
    }
  }

  getFormTemplateActions = formTemplate => {
    let actions = [
      {
        title: this.hasWriteAccess ? t('Edit') : t('View'),
        onClick: () => {
          this.editFormTemplate(formTemplate);
        }
      },
      {
        title: t('Download'),
        onClick: () => {
          this.formTemplateActionsUI.downloadFormTemplate(formTemplate);
        }
      }
    ];

    if (this.hasWriteAccess) {
      actions = actions.concat([
        {
          title: t('Rename'),
          onClick: () => {
            this.formTemplateActionsUI.renameFormTemplate(formTemplate);
          }
        },
        {
          title: t('Delete'),
          onClick: () => {
            this.formTemplateActionsUI.deleteFormTemplate(formTemplate);
          }
        }
      ]);
    }

    return actions;
  };

  @computed get hasWriteAccess() {
    return this.authorization.canCUDForms;
  }
}
