import { when } from 'mobx';
import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import { t } from 'utils/translate';

const checklistTemplateNameFormPlugins = extendedPlugins;

const checklistTemplateNameFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const checklistTemplateNameFormFields = ['name', 'templateUrl', 'initialName'];

const checklistTemplateNameFormValues = {};

const checklistTemplateNameFormRules = {
  name: 'string|required|max:255|template_name_available'
};

const checklistTemplateNameFormLabels = {
  name: t('name')
};

const checklistTemplateNameFormFieldOptions = {
  name: {
    validationDebounceWait: 500
  }
};

class ChecklistTemplateNameForm extends MobxReactForm {
  constructor(settings, options) {
    super(settings, options);
    this.rootStore = options.rootStore;

    when(
      () => this.rootStore.me.company,
      () => {
        this.$('templateUrl').set(
          `${this.rootStore.urlMicroService('toolbox')}/companies/${
            this.rootStore.me.company.uuid
          }/checklist-templates`
        );
      }
    );
  }
}

export {
  ChecklistTemplateNameForm,
  checklistTemplateNameFormPlugins,
  checklistTemplateNameFormOptions,
  checklistTemplateNameFormFields,
  checklistTemplateNameFormRules,
  checklistTemplateNameFormLabels,
  checklistTemplateNameFormValues,
  checklistTemplateNameFormFieldOptions
};
