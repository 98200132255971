import { RakenCollection } from '../../lib';
import Checklist from 'stores/models/checklists/Checklist';

export default class Checklists extends RakenCollection {
  model() {
    return Checklist;
  }

  url() {
    return `${this.rootStore.urlMicroService('toolbox')}/companies/${
      this.rootStore.me.company.uuid
    }/checklists`;
  }
}
