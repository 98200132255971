import request from 'axios';
import debounce from 'lodash.debounce';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import UIStore from 'stores/ui/UIStore';
import SuperAdminCompanies from 'stores/collections/superAdmin/SuperAdminCompanies';
import { BASE_DEBOUNCE } from 'fixtures/constants';
import alertErrorHandler from 'utils/alertErrorHandler';
import history from 'utils/history';

export default class SuperAdminCompanyMergeUI extends UIStore {
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;

  @observable companyToMergeInto;

  constructor(options) {
    super(options);

    this.loading = false;
    this.sortField = 'name';
    this.sortDirection = 'asc';
    this.page = 1;
    this.searchQuery = '';
    this.pageSize = 20;
    this.statusFilters = observable(['ACTIVE', 'INACTIVE', 'DELETED']);

    this.companyToMergeInto = null;

    // Companies collection
    this.companies = new SuperAdminCompanies(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchCompaniesDebounced = debounce(this.fetchCompanies, BASE_DEBOUNCE);
  }

  @computed get activeCompany() {
    return this.parent.activeCompany;
  }

  @action.bound setup() {
    window.scrollTo(0, 0);
    this.setupReactions();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchCompaniesDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      sortField: this.sortField,
      sortDirection: this.sortDirection,
      companyStatuses: this.statusFilters.join(',')
    };
  }

  @action.bound async fetchCompanies() {
    this.companies.cancelRequest();
    this.companies.clear();

    try {
      await this.companies.fetch({
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasCompanies() {
    return this.companies.hasModels;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.companies.totalElements / this.pageSize);
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.searchQuery = '';
    this.companies.clear();
    this.page = 1;
    this.loading = false;
    this.sortField = 'name';
    this.sortDirection = 'asc';
    this.statusFilters = ['ACTIVE', 'INACTIVE', 'DELETED'];
    this.companyToMergeInto = null;
  }

  @computed get showEmptyState() {
    return !this.loading && !this.searchQuery && !this.hasCompanies;
  }

  @computed get showEmptySearchState() {
    return !this.loading && this.searchQuery && !this.hasCompanies;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @computed
  get extendedPermissions() {
    return this.parent.extendedPermissions;
  }

  @action.bound mergeCompany(company) {
    this.companyToMergeInto = company;
    this.showModal('SuperAdminCompanyMergeModal');
  }

  @action.bound async cancelMergeCompany() {
    await this.hideActiveModal();
    this.companyToMergeInto = null;
  }

  @action.bound async confirmMergeCompany() {
    this.saving = true;

    try {
      await request.post(`ra/sadmin/companies/${this.activeCompany.id}/merge`, {
        masterCompanyUuid: this.companyToMergeInto.id
      });

      await this.hideActiveModal();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: `${this.activeCompany.name} successfully merged`
      });

      history.push(`/companies/${this.companyToMergeInto.uuid}`);
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }
}
