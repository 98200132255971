import { computed } from 'mobx';
import { RakenCollection } from 'stores/lib';
import EmployeeRange from 'stores/models/company/EmployeeRange';

export default class EmployeeRanges extends RakenCollection {
  model() {
    return EmployeeRange;
  }

  url() {
    return `ra/company-info/employee-ranges`;
  }

  @computed get asOptions() {
    return this.models.map(model => {
      return {
        value: model.employeeRange,
        title: model.employeeRange
      };
    });
  }
}
