import UIStore from './UIStore';
import PayTypes from 'stores/collections/PayTypes';
import { action, observable, computed, reaction } from 'mobx';
import errorHandler from 'utils/errorHandler';

import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import debounce from 'lodash.debounce';

import { BASE_DEBOUNCE } from 'fixtures/constants';
import { t } from 'utils/translate';

export default class PayTypeSelectorUI extends UIStore {
  @observable searchQuery;
  @observable loading;

  constructor(options) {
    super(options);

    this.loading = false;

    this.searchQuery = '';
    this.fetchPayTypes = debounce(this.fetchPayTypes, BASE_DEBOUNCE);

    this.payTypes = new PayTypes(null, {
      rootStore: this.rootStore
    });
  }

  @action.bound
  setup() {
    this.setupReactions();
    return this.fetchPayTypes();
  }

  @action.bound
  tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  @action.bound setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        this.fetchPayTypes();
      }
    );
  }

  @action.bound tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @action.bound clearUIState() {
    this.payTypes.reset();
    this.loading = false;
    this.searchQuery = '';
  }

  @computed get payTypeSelectorProject() {
    return this.project?.uuid
      ? this.project?.uuid
      : this.rootStore.timesheetsUI.timesheetsEditUI.timeCardToEdit
          ?.projectUuid;
  }

  @computed get allowedPayTypes() {
    return this.payTypes.models.filter(
      payType =>
        !payType.hiddenProjects?.some(
          project => project.uuid === this.payTypeSelectorProject
        )
    );
  }

  @computed get options() {
    return this.allowedPayTypes.map(payType => {
      return {
        uuid: payType.uuid,
        name: payType.name
      };
    });
  }

  @computed
  get defaultOption() {
    const regularTimePayType = this.options.find(
      payType => payType.code === 'RT'
    );

    return regularTimePayType
      ? { uuid: regularTimePayType.uuid, name: regularTimePayType.name }
      : this.options[0];
  }

  @computed get noOptionsText() {
    return this.loading
      ? t('Searching')
      : !this.payTypes.hasModels
      ? t('No results')
      : false;
  }

  @action.bound
  setSearchQuery(query) {
    this.searchQuery = query;
  }

  @action.bound
  clearSearchQuery() {
    this.searchQuery = '';
  }

  @computed get hasMoreCostCodes() {
    return this.payTypes.totalElements > this.payTypes.length;
  }

  @computed get params() {
    return {
      query: this.searchQuery,
      projectUuids:
        this.project?.uuid ||
        this.rootStore.timesheetsUI.timesheetsEditUI.timeCardToEdit
          ?.projectUuid,
      limit: 80
    };
  }

  @action.bound
  async fetchPayTypes(options) {
    this.loading = true;

    try {
      await this.payTypes.fetch({
        params: pickBy(this.params, identity),
        ...options
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    } finally {
      this.loading = false;
    }
  }

  fetchNextPage = async (e, autoCompleteRef) => {
    const menu = e.target;

    const scrollTop = menu.scrollTop;
    const scrollHeight = menu.scrollHeight;
    const clientHeight = menu.clientHeight;

    if (scrollTop + clientHeight === scrollHeight) {
      this.fetchNextPageOfPayTypes().then(() => {
        autoCompleteRef.current.scrollTop = scrollHeight;
      });
    }
  };

  @action.bound async fetchNextPageOfPayTypes() {
    if (this.payTypes.fetching) return;

    try {
      this.payTypes.fetch({
        params: Object.assign(pickBy(this.params, identity), {
          offset: this.payTypes.length
        }),
        add: true,
        remove: false,
        update: false
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }
}
