import { action, computed, observable } from 'mobx';
import UIStore from 'stores/ui/UIStore';
//import SuperAdminSuperUser from 'stores/models/superAdmin/SuperAdminSuperUser';

import {
  ResetPasswordForm,
  resetPasswordFormOptions,
  resetPasswordFormFields,
  resetPasswordFormRules,
  resetPasswordFormLabels,
  resetPasswordFormPlugins
} from 'forms/superAdmin/resetPasswordForm';

import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';

export default class SuperAdminSuperUserEditUI extends UIStore {
  @observable entryForEdit;
  @observable entryEditForm;

  constructor(options) {
    super(options);

    this.entryForEdit = null;
    this.entryEditForm = null;
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound submitEntryEditForm(event) {
    event.preventDefault();
    event.stopPropagation();

    this.entryEditForm.submit({
      onSuccess: this.submitEntryEditFormSuccess,
      onError: this.submitEntryEditFormError
    });
  }

  @action.bound async submitEntryEditFormSuccess() {
    this.saving = true;

    try {
      await this.entryForEdit.save(this.entryEditForm.values(), {
        wait: true,
        stripNonRest: false
      });

      await this.cancelSuperAdminSuperUserEdit();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Super user password updated')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitEntryEditFormError() {
    console.error(this.entryEditForm.errors());
  }

  @action.bound async cancelSuperAdminSuperUserEdit() {
    await this.hideActiveModal();
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();

    this.activeModal = null;
    this.entryForEdit = null;
    this.entryEditForm = null;
    this.saving = false;
  }

  @computed get disableCancelButton() {
    return Boolean(this.saving);
  }

  @computed get disableSaveButton() {
    return Boolean(this.saving);
  }

  @action.bound async changePassword(model) {
    this.entryForEdit = model;

    this.entryEditForm = new ResetPasswordForm(
      {
        fields: resetPasswordFormFields,
        rules: resetPasswordFormRules,
        labels: resetPasswordFormLabels,
        values: {}
      },
      {
        options: resetPasswordFormOptions,
        plugins: resetPasswordFormPlugins
      }
    );

    this.showModal('ChangePassword');
  }

  @action.bound async toggleStatus(model) {
    this.entryForEdit = model;

    this.showModal('ToggleStatus');
  }

  @action.bound async confirmToggleStatus() {
    this.saving = true;

    try {
      const newStatus =
        this.entryForEdit.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';

      await this.entryForEdit.save(
        {
          status: newStatus
        },
        {
          wait: true
        }
      );

      await this.cancelSuperAdminSuperUserEdit();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Super user {action}', {
          templateStrings: {
            action: newStatus === 'ACTIVE' ? 'activated' : 'inactivated'
          }
        })
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @computed get toggleStatusButtonText() {
    return this.entryForEdit.isActive ? t('Inactivate') : t('Activate');
  }

  @computed get toggleStatusButtonVariant() {
    return this.entryForEdit.isActive ? 'destruction' : 'success';
  }

  @computed get toggleStatusButtonIcon() {
    return this.entryForEdit.isActive ? 'trash' : 'checkmark';
  }
}
