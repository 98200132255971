import moment from 'moment-timezone';
import debounce from 'lodash.debounce';
import request from 'axios';

import { action, computed, observable, reaction, runInAction } from 'mobx';
import { t } from 'utils/translate';

import UIStore from './UIStore';

import { BASE_DEBOUNCE } from 'fixtures/constants';

import { callTrack } from 'utils/segmentIntegration';

import Activity from 'stores/collections/ActivityNew';

import {
  COMPANY_ACTIVITY_DURATION_MODIFIED,
  COMPANY_ACTIVITY_FILTERS_MODIFIED,
  PROJECT_ACTIVITY_DURATION_MODIFIED,
  PROJECT_ACTIVITY_FILTERS_MODIFIED
} from 'utils/segmentAnalytics/eventSpec';

import {
  ActivityFiltersForm,
  activityFiltersFormRules,
  activityFiltersFormFields,
  activityFiltersFormOptions,
  activityFiltersFormPlugins
} from 'forms/activityFilters';

import {
  TimeFrameForm,
  timeFrameFormOptions,
  timeFrameFormFields,
  timeFrameFormRules,
  timeFrameFormLabels,
  timeFrameFormPlugins
} from 'forms/timeFrame';

export default class ActivityUINew extends UIStore {
  @observable query;
  @observable durationFilter;
  @observable previousDurationFilter;
  @observable fromDate;
  @observable toDate;
  @observable timeFrameForm;
  @observable memberSearchQuery;
  @observable loading;

  constructor(options) {
    super(options);
    this.loading = false;
    this.query = '';

    this.durationFilter = {
      id: 'LAST30',
      title: 'Last 30 Days'
    };

    this.previousDurationFilter = null;

    this.projectFilters = observable([]);
    this.memberFilters = observable([]);
    this.activityFilters = observable([]);
    this.fromDate = null;
    this.toDate = null;
    this.timeFrameForm = null;
    this.activity = new Activity(null, {
      rootStore: this.rootStore
    });

    this.fetchResultsDebounced = debounce(this.fetchResults, BASE_DEBOUNCE);

    this.fetchNextPageDebounced = debounce(this.fetchNextPage, BASE_DEBOUNCE);
  }

  @computed
  get collection() {
    return this.activity;
  }

  @computed
  get project() {
    return this.parent?.project;
  }

  @action.bound setupReactions() {
    if (this.reactionsSet) return;

    // Fetches from the API when selections change
    this.cancelParamsReaction = reaction(
      () => this.params,
      params => {
        if (this.doNotRefetch) return;
        this.fetchResultsDebounced();
      }
    );

    // Calls analytics when a duration is selected
    this.cancelDurationAnalyticsReaction = reaction(
      () => this.durationFilter.id,
      durationFilterId => {
        if (this.project) {
          callTrack(PROJECT_ACTIVITY_DURATION_MODIFIED, {
            project_name: this.project.name,
            duration: this.durationFilter.title
          });
        } else {
          callTrack(COMPANY_ACTIVITY_DURATION_MODIFIED, {
            duration: this.durationFilter.title
          });
        }
      }
    );

    // Calls analytics when one or more filters are selected
    this.cancelFiltersAnalyticsReaction = reaction(
      () => this.hasAppliedFilters,
      hasAppliedFilters => {
        if (hasAppliedFilters) {
          if (this.project) {
            callTrack(PROJECT_ACTIVITY_FILTERS_MODIFIED, {
              project_name: this.project.name
            });
          } else {
            callTrack(COMPANY_ACTIVITY_FILTERS_MODIFIED);
          }
        }
      }
    );

    this.reactionsSet = true;
  }

  @action.bound tearDownReactions() {
    this.cancelParamsReaction();
    this.cancelDurationAnalyticsReaction();
    this.cancelFiltersAnalyticsReaction();
    this.reactionsSet = false;
  }

  @computed get doNotRefetch() {
    if (this.durationFilter.id === 'CUSTOM') {
      return !this.fromDate || !this.toDate;
    }

    return false;
  }

  @action.bound
  clearAll() {
    this.collection.clear();
    this.query = '';
    this.projectFilters.clear();
    this.memberFilters.clear();
    this.activityFilters.clear();
    this.projectSelectorUI.clearProjectOptions();
    this.projectSelectorUI.setParamsToDefault();
    this.memberSelectorUI.tearDown();
    this.fromDate = null;
    this.toDate = null;
    this.durationFilter = {
      id: 'LAST30',
      title: 'Last 30 Days'
    };
  }

  @action.bound
  async fetchPageOne() {
    await this.fetchResults();
  }

  // Search
  @action.bound
  setQuery(value) {
    this.query = value;
  }

  @action.bound
  clearQuery() {
    this.query = '';
  }

  @computed
  get orderedResults() {
    return this.collection.models;
  }

  @computed
  get hasResults() {
    return this.collection.length > 0;
  }

  @computed
  get nextPage() {
    if (this.page >= this.totalPages - 1) return -1;

    return this.page + 1;
  }

  @computed
  get hasNextPage() {
    return this.nextPage >= 1;
  }

  @action.bound
  fetchNextPage() {
    if (this.fetching || !this.hasMoreResults) {
      return;
    }

    const params = Object.assign({}, this.params, {
      offset: this.collection.offset + 10
    });

    return this.requestPost(params).then(response => {
      this.collection.set(response.data, {
        add: true,
        remove: false,
        update: false
      });
    });
  }

  @action.bound
  requestPost(params) {
    return request.post(this.collection.url(), params);
  }

  @action.bound
  fetchResults() {
    this.loading = true;
    this.collection.reset();

    const params = Object.assign({}, this.params);

    if (params.duration === 'CUSTOM') {
      delete params.duration;
    }

    return this.requestPost(params).then(response => {
      this.collection.set(response.data);
      this.loading = false;
    });
  }

  @computed
  get hasMoreResults() {
    return this.collection.length < this.collection.totalElements;
  }

  @computed get durationFilterOptions() {
    return [
      {
        id: 'ALLTIME',
        title: t('All Time')
      },
      {
        id: 'TODAY',
        title: t('Today')
      },
      {
        id: 'YESTERDAY',
        title: t('Yesterday')
      },
      {
        id: 'LAST7',
        title: t('Last 7 Days')
      },
      {
        id: 'LAST14',
        title: t('Last 14 Days')
      },
      {
        id: 'LAST30',
        title: t('Last 30 Days')
      },
      {
        id: 'CUSTOM',
        title: t('Custom')
      }
    ];
  }

  @computed get durationFilterOption() {
    if (this.activeModal === 'custom-duration') {
      return { id: 'CUSTOM', title: t('Custom') };
    }

    return this.durationFilter;
  }

  @computed get durationFilterActions() {
    return this.durationFilterOptions.map(option => {
      return {
        title: option.title,
        onClick: event => {
          this.setDurationFilter(event, option);
        }
      };
    });
  }

  @action.bound setDurationFilter(e, selectedOption) {
    this.previousDurationFilter = this.durationFilter;

    if (selectedOption.id === 'CUSTOM') {
      this.timeFrameForm = new TimeFrameForm(
        {
          fields: timeFrameFormFields,
          rules: timeFrameFormRules,
          values: {
            fromDate: this.fromDate,
            toDate: this.toDate
          },
          labels: timeFrameFormLabels
        },
        {
          options: timeFrameFormOptions,
          plugins: timeFrameFormPlugins
        }
      );

      this.showModal('custom-duration');
    } else {
      this.fromDate = null;
      this.toDate = null;
      this.durationFilter = selectedOption;
    }
  }

  @action.bound submitTimeFrameForm(event) {
    event.preventDefault();

    this.timeFrameForm.submit({
      onSuccess: this.submitTimeFrameFormSuccess,
      onError: this.submitTimeFrameFormError
    });
  }

  @action.bound submitTimeFrameFormSuccess() {
    const { fromDate, toDate } = this.timeFrameForm.values();

    this.hideActiveModal().then(() => {
      runInAction(() => {
        this.durationFilter = {
          id: 'CUSTOM',
          title: t('Custom')
        };
        this.fromDate = moment(fromDate).format('YYYY-MM-DD');
        this.toDate = moment(toDate).format('YYYY-MM-DD');
        this.timeFrameForm = null;
      });
    });
  }

  @action.bound submitTimeFrameFormError() {
    console.error(this.timeFrameForm.errors());
  }

  @action.bound cancelCustomDuration() {
    this.hideActiveModal().then(() => {
      runInAction(() => {
        this.durationFilter = this.previousDurationFilter;
        this.timeFrameForm = null;
      });
    });
  }

  @computed get params() {
    return {
      limit: 10,
      offset: 0,
      query: this.query,
      duration: this.durationFilter.id,
      projects: this.projectFilters.map(project => project.value),
      users: this.memberFilters.map(member => member.value),
      types: this.activityFilters.map(activity => activity.value),
      fromDate: this.fromDate,
      toDate: this.toDate,
      userTimezone: moment.tz.guess()
    };
  }

  @action.bound async showFilters() {
    this.showModal('filters');

    await Promise.all([
      this.projectSelectorUI.fetchProjectOptions(),
      this.memberSelectorUI.setup({
        role: [
          'ROLE_ACCOUNT_ADMIN',
          'ROLE_ADMIN',
          'ROLE_PROJECT_MEMBER',
          'ROLE_USER'
        ]
      })
    ]);

    this.initFiltersForm();
  }

  @action.bound
  initFiltersForm() {
    this.activeForm = new ActivityFiltersForm(
      {
        fields: activityFiltersFormFields,
        rules: activityFiltersFormRules,
        values: {
          projectFilters: this.projectFilters.slice(),
          memberFilters: this.memberFilters.slice(),
          activityFilters: this.activityFilters.slice()
        }
      },
      {
        options: activityFiltersFormOptions,
        plugins: activityFiltersFormPlugins
      }
    );
  }

  @action.bound selectProjects(options) {
    this.activeForm.update({
      projectFilters: options.map(option => {
        return {
          value: option.value,
          name: option.name
        };
      })
    });
  }

  @computed get projectFilterDefaultOptions() {
    return this.projectFilters.slice().map(project => {
      return {
        value: project.value,
        name: project.name
      };
    });
  }

  @action.bound selectMembers(options) {
    this.activeForm.update({
      memberFilters: options.map(option => {
        return {
          uuid: option.value,
          value: option.uuid,
          name: option.name,
          company: option.company
        };
      })
    });
  }

  @computed get memberFilterDefaultOptions() {
    return this.memberFilters.slice().map(member => {
      return {
        uuid: member.value,
        value: member.value,
        name: member.name,
        company: member.company
      };
    });
  }

  @action.bound async hideFilters() {
    await this.hideActiveModal();
    this.activeForm = null;
  }

  @action.bound clearFilters() {
    this.projectSelectorUI.resetProjectOptions();
    this.projectFilters.clear();
    this.memberFilters.clear();
    this.activityFilters.clear();
    this.hideFilters();
  }

  @action.bound async applyFilters(event) {
    event.preventDefault();

    const values = this.activeForm.values();

    await this.hideActiveModal();

    this.projectFilters.replace(values.projectFilters);
    this.memberFilters.replace(values.memberFilters);
    this.activityFilters.replace(values.activityFilters);
  }

  @computed get appliedFiltersCount() {
    // Always at least 1 for project statuses
    let count = 0;

    if (this.projectFilters.length > 0) {
      count++;
    }

    if (this.memberFilters.length > 0) {
      count++;
    }
    if (this.activityFilters.length > 0) {
      count++;
    }

    return count;
  }

  @computed get hasAppliedFilters() {
    return this.appliedFiltersCount > 0;
  }

  @computed
  get activityTypeOptions() {
    return [
      {
        name: 'Surveys',
        value: 'SURVEY'
      },
      {
        name: 'Work Logs',
        value: 'WORK_LOG'
      },
      {
        name: 'Notes',
        value: 'NOTE'
      },
      {
        name: 'Material Logs',
        value: 'MATERIAL_LOG'
      },
      {
        name: 'Tasks',
        value: 'TASK'
      },
      {
        name: 'Equipment Logs',
        value: 'EQUIPMENT'
      },
      {
        name: 'Media / Attachments',
        value: 'ATTACHMENT'
      }
    ];
  }

  @action.bound selectActivityTypes(options) {
    this.activeForm.update({
      activityFilters: options
    });
  }
}
