import MobxReactForm from 'mobx-react-form';
import { t } from 'utils/translate';
import validatorjs from 'validatorjs';

const downloadDocumentFormPlugins = { dvr: validatorjs };

const downloadDocumentFormOptions = {
  validateOnInit: true,
  validateOnChange: true,
  strictUpdate: false
};

const downloadDocumentFormFields = ['emails[]', 'email'];

const downloadDocumentFormRules = {
  emails: 'array|required',
  email: 'email'
};

const downloadDocumentFormLabels = {
  recipients: t('Send download link to')
};

const downloadDocumentFormValues = {};

class DownloadDocumentForm extends MobxReactForm {}

export {
  DownloadDocumentForm,
  downloadDocumentFormOptions,
  downloadDocumentFormFields,
  downloadDocumentFormRules,
  downloadDocumentFormLabels,
  downloadDocumentFormPlugins,
  downloadDocumentFormValues
};
