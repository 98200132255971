import MobxReactForm from 'mobx-react-form';
import extendedPlugins from 'forms/utils/extendedPlugins';
import moment from 'moment';

const payrollSettingsFormPlugins = extendedPlugins;

const payrollSettingsFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const payrollSettingsFormFields = ['fromDate', 'keepSignature'];

const payrollSettingsFormRules = {
  fromDate: 'required|string',
  keepSignature: 'boolean'
};

const payrollSettingsFormLabels = {
  fromDate: 'From Date:',
  keepSignature: 'Keep Signature:'
};

const payrollSettingsFormValues = {
  fromDate: moment().format('YYYY-MM-DD'),
  keepSignature: false
};

class PayrollSettingsForm extends MobxReactForm {}

export {
  PayrollSettingsForm,
  payrollSettingsFormPlugins,
  payrollSettingsFormOptions,
  payrollSettingsFormFields,
  payrollSettingsFormRules,
  payrollSettingsFormLabels,
  payrollSettingsFormValues
};
