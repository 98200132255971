import { computed, action } from 'mobx';
import moment from 'moment';

import { Model } from 'mobx-mc';

import numberWithCommas from 'utils/numberWithCommas';

export default class SuperAdminBillingUpcomingTransaction extends Model {
  get restAttributes() {
    return [
      'amount',
      'paymentDate',
      'startDate',
      'endDate',
      'lastFour',
      'description'
    ];
  }

  @action.bound
  formatDate(date) {
    return date ? moment(date).format('YYYY-MM-DD') : null;
  }

  @computed
  get paymentDateFormatted() {
    return this.formatDate(this.paymentDate);
  }

  @computed
  get servicePeriodFormatted() {
    return `${this.formatDate(this.startDate)} - ${this.formatDate(
      this.endDate
    )}`;
  }

  @computed
  get amountFormatted() {
    return `$${numberWithCommas(this.amount / 100)}`;
  }
}
