import flatten from 'lodash.flatten';
import orderBy from 'lodash.orderby';
import { action, observable, computed, when } from 'mobx';
import ProjectChildAddUI from '../ProjectChildAddUI';
import Checklist from 'stores/models/checklists/Checklist';

import ChecklistObservationUI from './ChecklistObservationUI';
import ChecklistObservationAddUI from './ChecklistObservationAddUI';
import ChecklistObservationEditUI from './ChecklistObservationEditUI';

import {
  CHECKLIST_STARTED,
  CHECKLIST_SAVED,
  CHECKLIST_SIGNATURE_COMPLETED
} from 'utils/segmentAnalytics/eventSpec';

import {
  checklistFormFields,
  checklistFormOptions,
  checklistFormPlugins,
  checklistFormRules,
  checklistFormLabels,
  ChecklistForm
} from 'forms/checklist';

import history from 'utils/history';
import { callTrack } from 'utils/segmentIntegration';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import formatChecklistDateToUTC from 'utils/formatChecklistDateToUTC';

export default class ChecklistAddUI extends ProjectChildAddUI {
  @observable step;

  @observable tab;
  @observable searchQuery;
  @observable selectedTemplate;
  @observable entryForAdd;
  @observable entryAddForm;

  @observable padSigned;
  @observable signature;

  @observable responsibleType;

  constructor(options) {
    super(options);

    this.step = 1;

    // Template selection
    this.tab = 'Company';
    this.searchQuery = '';
    this.selectedTemplate = null;
    this.entryForAdd = null;

    // Checklist edit
    this.entryAddForm = null;
    this.padSigned = false;
    this.signature = null;

    this.responsibleType = 'WorkerClassification';

    this.checklistObservationUI = new ChecklistObservationUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.checklistObservationAddUI = new ChecklistObservationAddUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.checklistObservationEditUI = new ChecklistObservationEditUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @computed
  get checklistTemplateSelectUI() {
    return this.parent.checklistTemplateSelectUI;
  }

  @computed
  get checklistCompletedUI() {
    return this.rootStore.companyUI?.checklistCompletedUI;
  }

  @computed
  get orderedRakenChecklistTemplates() {
    return orderBy(
      this.checklistTemplateSelectUI.rakenChecklistTemplates,
      [template => template.name.toLowerCase()],
      'asc'
    );
  }

  @computed
  get searchedRakenChecklistTemplates() {
    if (!this.searchQuery) {
      return this.orderedRakenChecklistTemplates;
    }
    const query = this.searchQuery.toLowerCase();

    return this.orderedRakenChecklistTemplates.filter(
      template => template.name.toLowerCase().indexOf(query) > -1
    );
  }

  @computed
  get orderedCompanyChecklistTemplates() {
    return orderBy(
      this.checklistTemplateSelectUI.companyChecklistTemplates,
      [template => template.name.toLowerCase()],
      ['asc']
    );
  }

  @computed
  get searchedCompanyChecklistTemplates() {
    if (!this.searchQuery) {
      return this.orderedCompanyChecklistTemplates;
    }

    const query = this.searchQuery.toLowerCase();
    return this.orderedCompanyChecklistTemplates.filter(
      template => template.name.toLowerCase().indexOf(query) > -1
    );
  }

  @computed get hasCompanyChecklistTemplates() {
    return this.orderedCompanyChecklistTemplates.length > 0;
  }

  @computed get hasSearchedCompanyChecklistTemplates() {
    return this.searchedCompanyChecklistTemplates.length > 0;
  }

  @computed get hasSearchedRakenChecklistTemplates() {
    return this.searchedRakenChecklistTemplates.length > 0;
  }

  @action.bound setup() {
    this.classificationSelectorUI.setup({
      responsibleSort: true
    });
    this.collaboratorSelectorUI.setup();

    when(
      () => !this.checklistTemplateSelectUI.loading,
      () => {
        if (this.hasCompanyChecklistTemplates) {
          this.setTab('Company');
        } else {
          this.setTab('Raken');
        }
      }
    );
  }

  @action.bound tearDown() {
    this.clearValidationDetails();
    this.clearUIState();
    this.classificationSelectorUI.tearDown();
    this.collaboratorSelectorUI.tearDown();
    this.responsibleType = 'WorkerClassification';
    this.unblockHistory();
  }

  @action.bound setTab(tab) {
    this.tab = tab;
    this.setDefaultTemplateForTab(tab);
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
  }

  @action.bound clearSearchQuery(value) {
    this.searchQuery = '';
  }

  @action.bound setDefaultTemplateForTab(tab) {
    if (tab === 'Company') {
      this.setEntryForAddFromTemplate(
        this.orderedCompanyChecklistTemplates[0]?.uuid
      );
    } else {
      this.setEntryForAddFromTemplate(
        this.orderedRakenChecklistTemplates[0]?.uuid
      );
    }
  }

  @action.bound
  setEntryForAddFromTemplate(templateUuid) {
    if (templateUuid) {
      this.selectedTemplate = this.checklistTemplateSelectUI.checklistTemplates.models.find(
        template => template.uuid === templateUuid
      );

      // We now have a new checklist model when creating a checklist from a template
      this.entryForAdd = new Checklist(
        this.selectedTemplate.formValuesForChecklist,
        {
          rootStore: this.rootStore
        }
      );
    } else {
      this.entryForAdd = null;
      this.selectedTemplate = null;
    }
  }

  @action.bound clearUIState() {
    this.activeModal = null;
    this.clearValidationDetails();
    this.tab = 'Company';
    this.searchQuery = '';
    this.selectedTemplate = null;
    this.entryForAdd = null;
    this.entryAddForm = null;
    this.padSigned = false;
    this.signature = null;
    this.saving = false;
    this.step = 1;
    this.nextUrl = null;
  }

  @action.bound cancelChecklistAdd() {
    history.push({
      pathname: `${this.project.viewUrl}/checklists`,
      search: this.baseQueryParams
    });
  }

  @action.bound confirmChecklistCreationOrEditing() {
    this.cancelChecklistAdd({
      skipCancelCheck: true
    });
  }

  @action.bound startChecklist() {
    callTrack(CHECKLIST_STARTED, {
      checklist_template_name: this.entryForAdd.name,
      source:
        this.selectedTemplate.scope == 'COMPANY'
          ? 'company_template'
          : 'raken_template'
    });

    this.setupEntryAddForm();
    this.step = 2;
  }

  @action.bound setupEntryAddForm() {
    this.entryAddForm = new ChecklistForm(
      {
        fields: checklistFormFields,
        rules: checklistFormRules,
        labels: checklistFormLabels,
        values: this.entryForAdd.formValues
      },
      {
        options: checklistFormOptions,
        plugins: checklistFormPlugins,
        rootStore: this.rootStore
      }
    );

    this.entryAddForm.$('checklistSections').map(section => {
      section.$('checklistQuestions').map(question => {
        if (question.value.required) {
          question.$('checklistResponses').map(response => {
            if (question.value.responseType === 'MULTI_CHOICE') {
              response.$('choices').set('rules', 'arrayHasTrue:selected');
            } else if (question.value.responseType === 'CHECKBOX') {
              response.$('value').set('rules', 'required|is:true');
            } else if (question.value.responseType === 'YES_NO_NA') {
              response.$('value').set('rules', 'required|in:Yes,No,N/A');
            } else if (question.value.responseType === 'YES_NO') {
              response.$('value').set('rules', 'required|in:Yes,No');
            } else if (question.value.responseType === 'TEXT') {
              response.$('value').set('rules', 'required|max:10000');
            } else if (question.value.responseType === 'SIGNATURE') {
              response.$('responseSignature').set('rules', {
                signature: 'required',
                signedBy: 'required'
              });
            } else {
              response.$('value').set('rules', 'required');
            }
          });
        } else {
          question.$('checklistResponses').map(response => {
            if (question.value.responseType === 'TEXT') {
              response.$('value').set('rules', 'max:10000');
            }
          });
        }
      });
    });

    this.blockHistoryIfFormChanged();
  }

  @action.bound blockHistoryIfFormChanged() {
    this.unblock = history.block((location, action) => {
      if (!this.entryAddForm?.isDefault || this.entryForAdd.hasAttachments) {
        this.showDiscardModal(location.pathname);
        return 'Blocked';
      }
    });
  }

  @computed
  get formValuesCheckListSections() {
    return this.entryAddForm.values().checklistSections.map(section => {
      const questions = section.checklistQuestions.map(question => {
        const { uuid, ...noUuid } = question;
        // We can use is new to tell if a Checklist was built up from a template or not.
        return this.entryForAdd.isNew ? noUuid : question;
      });

      const { checklistQuestions, ...noQuestions } = section;

      questions.forEach(checklistQuestion => {
        const response = checklistQuestion.checklistResponses[0];

        response.checklistResponseAttachments = this.entryForAdd.attachments
          .filter(attachment => {
            return (
              attachment.collection.parent.uniqueId ===
              checklistQuestion.checklistResponses[0].uuid
            );
          })
          .map(attachment => {
            return {
              uuid: attachment.uuid
            };
          });

        response.observations = this.entryForAdd.observations
          .filter(observation => {
            return (
              observation.collection.parent.uniqueId ===
              checklistQuestion.checklistResponses[0].uuid
            );
          })
          .map(observation => {
            return {
              uuid: observation.uuid
            };
          });

        if (this.entryForAdd.isNew) {
          delete response.uuid;
        }
      });

      const sectionAndQuestions = {
        checklistQuestions: questions,
        ...noQuestions
      };

      if (this.entryForAdd.isNew) {
        delete sectionAndQuestions.uuid;
      }

      return sectionAndQuestions;
    });
  }

  /**
   * Required because validationjs rules does not fully work on MULTI_CHOICE options.
   * (Possible library implementation bug)
   *
   * @returns {Boolean}
   */
  @computed
  get formPassesAdditionalValidation() {
    const questions = flatten(
      this.formValuesCheckListSections.map(
        section => section.checklistQuestions
      )
    );

    const hasError =
      questions.find(question => {
        const response = question.checklistResponses[0];

        if (question.required) {
          // Add additional non-validationjs validation here.
          if (question.responseType === 'MULTI_CHOICE') {
            return !response.choices.some(choice => choice.selected);
          }
        }
      }) || false;

    return !hasError;
  }

  @computed
  get uploadRunning() {
    return this.entryForAdd.hasAttachmentsUploading;
  }

  @computed
  get disableSaveButton() {
    return this.uploadRunning || this.entryForAdd?.saving;
  }

  @action.bound async submitEntryAddForm() {
    this.saving = true;

    const values = this.entryAddForm.values();

    const payload = {
      projectUuid: this.project.uuid,
      templateUuid: values.templateUuid,
      status: 'DRAFT',
      name: values.name,
      responsible: values.responsible.uuid
        ? {
            type: this.responsibleType,
            uuid: values.responsible.uuid
          }
        : null,
      location: {
        uuid: values.location.uuid || null
      },
      checklistDate: formatChecklistDateToUTC(
        values.checklistDate,
        values.checklistTime
      ),
      checklistSections: this.formValuesCheckListSections
    };

    try {
      await this.entryForAdd.save(payload, {
        stripNonRest: false,
        wait: true
      });

      callTrack(CHECKLIST_SAVED, {
        status: 'draft',
        checklist_name: this.entryForAdd.name,
        location_name: values.location.name
      });

      this.unblockHistory();
      this.parent.sortByLastCreated();
      this.cancelChecklistAdd();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Checklist draft saved')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  async openSignAndCompleteChecklistModal() {
    this.entryAddForm.submit({
      onSuccess: () => {
        if (!this.formPassesAdditionalValidation) {
          this.showModal('checklistMandatoryQuestionsValidation');
        } else {
          this.showModal('checklistsViewerSignModal');
        }
      },
      onError: this.submitEntryEditFormError
    });
  }

  @action.bound signPad(signature) {
    this.padSigned = true;
    this.signature = signature;
  }

  @action.bound unsignPad() {
    this.padSigned = false;
    this.signature = null;
  }

  @action.bound
  async signAndCompleteChecklist(signature) {
    this.saving = true;

    const values = this.entryAddForm.values();

    const payload = {
      templateUuid: values.templateUuid,
      projectUuid: this.project.uuid,
      checklistSignatures: [
        {
          signature: signature
        }
      ],
      status: 'ACTIVE',
      name: this.entryAddForm.values().name,
      responsible: {
        type: this.responsibleType,
        uuid: values.responsible.uuid
      },
      location: {
        uuid: values.location.uuid || null
      },
      checklistDate: formatChecklistDateToUTC(
        values.checklistDate,
        values.checklistTime
      ),
      checklistSections: this.formValuesCheckListSections
    };

    try {
      await this.entryForAdd.save(payload, {
        stripNonRest: false,
        wait: true
      });

      callTrack(CHECKLIST_SIGNATURE_COMPLETED, {
        project_id: this.project.uuid,
        checklist_name: this.entryForAdd.name,
        location_name: values.location?.name
      });

      this.hideActiveModal();
      this.unblockHistory();
      this.unsignPad();
      this.parent.sortByLastCreated();
      this.cancelChecklistAdd();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Checklist signed')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  openQuestionSignatureModal(modalId) {
    this.showModal(modalId);
  }

  @action.bound setResponsibleType(value) {
    this.responsibleType = value;
    this.entryAddForm.$('responsible').clear();
    this.entryAddForm.$('responsible.uuid').resetValidation();
  }
}
