import UIStore from './UIStore';
import { computed, action, observable } from 'mobx';

import { t } from 'utils/translate';

import ScheduledReports from '../collections/ScheduledReports';
import ScheduledReport from '../models/ScheduledReport';

import {
  ScheduleTimesheetsReportForm,
  scheduleTimesheetsReportFormOptions,
  scheduleTimesheetsReportFormFields,
  scheduleTimesheetsReportFormRules,
  scheduleTimesheetsReportFormValues,
  scheduleTimesheetsReportFormPlugins
} from '../../forms/scheduleTimesheetsReport';

import errorHandler from 'utils/errorHandler';
import alertErrorHandler from 'utils/alertErrorHandler';

export default class TimesheetsScheduledReportUI extends UIStore {
  @observable scheduledReportToEdit;
  @observable fetchingScheduledReports;

  constructor(options) {
    super(options);
    this.scheduledReportToEdit = null;

    this.fetchingScheduledReports = true;

    this.scheduledReports = new ScheduledReports(null, {
      rootStore: this.rootStore,
      parent: this
    });
  }

  @computed
  get timeFrames() {
    return [
      { id: 'PAY_PERIOD', title: t('By pay period') },
      { id: 'DAILY', title: t('Daily') },
      { id: 'WEEKLY', title: t('Weekly') },
      { id: 'BI_WEEKLY', title: t('Bi-weekly') },
      { id: 'MONTHLY', title: t('Monthly') }
    ];
  }

  @computed
  get reportOnText() {
    if (this.activeForm.$('frequencyType').value === 'PAY_PERIOD') {
      return `${t(
        'You will recieve a report on '
      )} ${this.reportScheduleInputValue?.title.toLowerCase()} ${t(
        'at approximately'
      )} ${this.activeForm.formattedTime}`;
    }

    return `${t('You will recieve a report ')} ${this.timeFrames
      .find(
        timeFrame => this.activeForm.$('frequencyType').value === timeFrame.id
      )
      .title.toLowerCase()} ${t('at approximately')} ${
      this.activeForm.formattedTime
    }`;
  }

  @computed
  get scheduledFrequencyDefaultValue() {
    if (this.scheduledReportToEdit.isNew) {
      return this.timeFrames.find(timeFrame => timeFrame.id === 'PAY_PERIOD');
    }

    if (this.scheduledReportToEdit.frequencyType) {
      return this.timeFrames.find(
        timeFrame => timeFrame.id === this.scheduledReportToEdit.frequencyType
      );
    }

    return [];
  }

  @computed
  get reportScheduleOptions() {
    if (this.activeForm.$('frequencyType').value === 'PAY_PERIOD') {
      return [
        { id: 0, title: t('The last day of each pay period') },
        { id: 1, title: t('The day after each pay period') }
      ];
    }

    if (this.activeForm.$('frequencyType').value === 'DAILY') {
      return [
        { id: 'CURRENT', title: t('Current day') },
        { id: 'PREVIOUS', title: t('Previous day') }
      ];
    }

    if (this.activeForm.$('frequencyType').value === 'WEEKLY') {
      return [
        { id: 'CURRENT', title: t('Current week') },
        { id: 'PREVIOUS', title: t('Previous week') }
      ];
    }

    if (this.activeForm.$('frequencyType').value === 'BI_WEEKLY') {
      return [
        { id: 'CURRENT', title: t('Current 2 weeks') },
        { id: 'PREVIOUS', title: t('Previous 2 weeks') }
      ];
    }

    if (this.activeForm.$('frequencyType').value === 'MONTHLY') {
      return [
        { id: 'CURRENT', title: t('Current month') },
        { id: 'PREVIOUS', title: t('Previous month') }
      ];
    }

    return [];
  }

  @computed
  get reportScheduleInputValue() {
    if (this.activeForm.$('frequencyType').value === 'PAY_PERIOD') {
      return this.reportScheduleOptions.find(
        option => this.activeForm.$('offsetDays').value === option.id
      );
    }

    if (
      this.activeForm.$('timeFrameType').value &&
      !this.scheduledReportToEdit.isNew
    ) {
      return this.reportScheduleOptions.find(
        option => this.activeForm.$('timeFrameType').value === option.id
      );
    }

    return this.reportScheduleOptions[0];
  }

  setActionDate(date) {
    this.activeForm.$('actionDate').set(date?.format('YYYY-MM-DD'));
  }

  @action.bound
  setReportFrequency(frequency) {
    this.activeForm.$('frequencyType').set('value', frequency.id);
  }

  @action.bound
  fetchScheduledReports() {
    return this.scheduledReports.fetch().then(() => {
      this.fetchingScheduledReports = false;
    });
  }

  @computed
  get hasNoScheduledReports() {
    return (
      !this.fetchingScheduledReports &&
      !this.scheduledReports.fetching &&
      !this.scheduledReports.hasModels
    );
  }

  @action.bound
  createNewScheduledReport() {
    // Use a new model to hold our report when creating a new one.
    this.scheduledReportToEdit = new ScheduledReport(
      { ...scheduleTimesheetsReportFormValues },
      {
        rootStore: this.rootStore
      }
    );

    this.activeForm = new ScheduleTimesheetsReportForm(
      {
        fields: scheduleTimesheetsReportFormFields,
        rules: scheduleTimesheetsReportFormRules,
        values: {
          ...this.scheduledReportToEdit.formValues,
          projectFilters: this.project
            ? [{ value: this.project.uuid, name: this.project.name }]
            : [],
          projectIncludeOption: this.project
            ? 'SELECTED'
            : 'ACTIVE_AND_INACTIVE'
        }
      },
      {
        options: scheduleTimesheetsReportFormOptions,
        plugins: scheduleTimesheetsReportFormPlugins
      }
    );
  }

  @action.bound
  editScheduledReport(report) {
    this.scheduledReportToEdit = report;
    this.activeForm = new ScheduleTimesheetsReportForm(
      {
        fields: scheduleTimesheetsReportFormFields,
        rules: scheduleTimesheetsReportFormRules,
        values: {
          ...this.scheduledReportToEdit.formValues,
          projectFilters: this.projectDefaultValue
        }
      },
      {
        options: scheduleTimesheetsReportFormOptions,
        plugins: scheduleTimesheetsReportFormPlugins
      }
    );
  }

  @action.bound
  cancelEditingReport() {
    this.activeForm = null;
    this.scheduledReportToEdit = null;
    this.saving = false;
  }

  @action.bound
  handleScheduledReportCancelButton() {
    this.scheduledReportToEdit
      ? this.cancelEditingReport()
      : this.parent.hideTimesheetsReport();
  }

  @action.bound
  submitTimesheetsScheduledReportForm(e) {
    if (this.saving) return;

    this.activeForm.submit({
      onSuccess: this.submitTimesheetsScheduledReportFormSuccess,
      onError: this.submitTimesheetsScheduledReportFormError
    });
  }

  @action.bound
  saveOrCreateScheduledReport(e) {
    e.preventDefault();
    e.stopPropagation();

    if (this.scheduledReportToEdit) {
      // Save report
      this.submitTimesheetsScheduledReportForm();
    } else {
      // Open New Report
      this.createNewScheduledReport();
    }
  }

  submitTimesheetsScheduledReportFormError(values) {
    console.log(this.userForm.errors());
  }

  @computed
  get scheduledReportNewOrSaveButtonText() {
    if (this.saving) return t('Saving...');

    return this.scheduledReportToEdit
      ? this.scheduledReportToEdit?.isNew
        ? t('Schedule Report')
        : t('Save Changes')
      : t('New Report');
  }

  @computed
  get scheduledReportNewOrSaveButtonDisabled() {
    if (this.saving) return true;

    if (this.activeForm?.$('projectIncludeOption').value === 'SELECTED') {
      if (
        !this.activeForm.$('projectFilters').size ||
        (this.activeForm.$('projectFilters').isPristine &&
          !this.projectDefaultValue.length)
      ) {
        return true;
      }
    }

    if (this.scheduledReportToEdit && !this.activeForm?.isValid) {
      return true;
    }

    return false;
  }

  @action.bound
  async submitTimesheetsScheduledReportFormSuccess() {
    const projects = this.activeForm.$('projectFilters').value
      ? this.activeForm.$('projectFilters').value.map(project => {
          return { uuid: project.value };
        })
      : [];

    const values = {
      ...this.activeForm.values(),
      projects
    };

    const reportNotification = this.scheduledReportToEdit.isNew
      ? t('Scheduled report created')
      : t('Scheduled report updated');

    this.saving = true;

    try {
      await this.scheduledReportToEdit.save(values, {
        method: this.scheduledReportToEdit.isNew ? 'post' : 'put',
        stripNonRest: false,
        wait: true
      });

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: reportNotification
      });

      this.cancelEditingReport();
    } catch (error) {
      this.saving = false;
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound
  async deleteScheduledReport(report) {
    try {
      await report.destroy({ wait: true });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  @computed
  get projectIncludeOptions() {
    return [
      {
        label: t('All projects'),
        value: 'ACTIVE_AND_INACTIVE',
        dataQA: 'all-projects'
      },
      {
        label: t('Selected'),
        value: 'SELECTED',
        dataQA: 'selected-projects'
      }
    ];
  }

  @action.bound
  setProjectIncludeOption(value) {
    if (value === 'ACTIVE_AND_INACTIVE') {
      this.activeForm.update({
        projectFilters: [],
        projects: []
      });
      this.scheduledReportToEdit.projects = [];
    }
    this.activeForm.$('projectIncludeOption').set('value', value);
  }

  @action.bound
  selectProjects(selectedProjects) {
    this.activeForm.update({
      projectFilters: selectedProjects
    });
  }

  @action.bound
  toggleWorkersCostCodes() {
    this.activeForm
      .$('includeCostCode')
      .set('value', !this.activeForm.$('includeCostCode').value);
  }

  @action.bound
  toggleSendReportNow() {
    this.activeForm
      .$('sendReportNow')
      .set('value', !this.activeForm.$('sendReportNow').value);
  }

  @computed
  get projectDefaultValue() {
    if (this.scheduledReportToEdit.projects.length) {
      const defaultValue = this.scheduledReportToEdit.projects.map(project => {
        return {
          value: project.uuid,
          name: project.name
        };
      });
      return defaultValue;
    }

    if (this.project && this.scheduledReportToEdit.isNew) {
      return [
        {
          value: this.project.uuid,
          name: this.project.name
        }
      ];
    }

    return [];
  }

  @action.bound
  setReportSchedule(schedule) {
    if (this.activeForm.$('frequencyType').value === 'PAY_PERIOD') {
      this.activeForm.update({
        offsetDays: schedule.id
      });
    } else {
      this.activeForm.update({
        timeFrameType: schedule.id
      });
    }
  }

  @action.bound
  setScheduledReportTime(time) {
    if (!time) {
      return '';
    }
    const timeArray = time.split(':');
    this.activeForm.update({
      time: { hour: timeArray[0], minute: timeArray[1] }
    });
  }

  @computed
  get scheduledReportTimeInputValue() {
    if (this.activeForm.$('time').value) {
      return `${this.activeForm.$('time').value.hour}:${
        this.activeForm.$('time').value.minute
      }:00`;
    }

    return `09:00:00`;
  }
}
