import moment from 'moment';
import debounce from 'lodash.debounce';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import { t } from 'utils/translate';

import ReportActivity from 'stores/collections/ReportActivity';

import UIStore from './UIStore';

import { BASE_DEBOUNCE } from 'fixtures/constants';

import { callTrack } from 'utils/segmentIntegration';

import {
  COMPANY_REPORT_ACTIVITY_DURATION_MODIFIED,
  COMPANY_REPORT_ACTIVITY_FILTERS_MODIFIED
} from 'utils/segmentAnalytics/eventSpec';

import {
  ReportActivityFiltersForm,
  reportActivityFiltersFormRules,
  reportActivityFiltersFormFields,
  reportActivityFiltersFormOptions,
  reportActivityFiltersFormPlugins
} from 'forms/reportActivityFilters';

import {
  TimeFrameForm,
  timeFrameFormOptions,
  timeFrameFormFields,
  timeFrameFormRules,
  timeFrameFormLabels,
  timeFrameFormPlugins
} from 'forms/timeFrame';

export default class ReportActivityUI extends UIStore {
  @observable durationFilter;
  @observable previousDurationFilter;
  @observable fromDate;
  @observable toDate;
  @observable timeFrameForm;

  constructor(options) {
    super(options);

    this.reportActivity = new ReportActivity(null, {
      rootStore: this.rootStore
    });

    this.durationFilter = {
      id: 'LAST30',
      title: 'Last 30 Days'
    };

    this.previousDurationFilter = null;

    this.projectStatusFilters = observable(['ACTIVE']);

    this.reportStatusFilters = observable([
      {
        value: 'CREATED',
        name: t('Created')
      },
      {
        value: 'UNSIGNED',
        name: t('Unsigned')
      },
      {
        value: 'COMPLETED',
        name: t('Signed')
      }
    ]);

    this.projectFilters = observable([]);
    this.fromDate = null;
    this.toDate = null;
    this.timeFrameForm = null;

    this.fetchResultsDebounced = debounce(this.fetchResults, BASE_DEBOUNCE);
  }

  @computed
  get collection() {
    return this.reportActivity;
  }

  @action.bound setupReactions() {
    if (this.reactionsSet) return;

    // Fetches from the API when selections change
    this.cancelParamsReaction = reaction(
      () => this.params,
      params => {
        if (this.doNotRefetch) return;
        this.fetchResultsDebounced();
      }
    );

    // Calls analytics when a duration is selected
    this.cancelDurationAnalyticsReaction = reaction(
      () => this.durationFilter.id,
      durationFilterId => {
        callTrack(COMPANY_REPORT_ACTIVITY_DURATION_MODIFIED, {
          duration: this.durationFilter.title
        });
      }
    );

    // Calls analytics when one or more filters are selected
    this.cancelFiltersAnalyticsReaction = reaction(
      () => this.hasAppliedFilters,
      hasAppliedFilters => {
        if (hasAppliedFilters) {
          callTrack(COMPANY_REPORT_ACTIVITY_FILTERS_MODIFIED);
        }
      }
    );

    this.reactionsSet = true;
  }

  @action.bound tearDownReactions() {
    this.cancelParamsReaction();
    this.cancelDurationAnalyticsReaction();
    this.cancelFiltersAnalyticsReaction();
    this.reactionsSet = false;
  }

  @computed get doNotRefetch() {
    if (this.durationFilter.id === 'CUSTOM') {
      return !this.fromDate || !this.toDate;
    }

    return false;
  }

  @action.bound
  clearAll() {
    this.collection.clear();
    this.projectFilters.clear();
    this.reportStatusFilters.replace([
      {
        value: 'CREATED',
        name: t('Created')
      },
      {
        value: 'UNSIGNED',
        name: t('Unsigned')
      },
      {
        value: 'COMPLETED',
        name: t('Signed')
      }
    ]);
    this.projectSelectorUI.resetProjectOptions();
    this.fromDate = null;
    this.toDate = null;
    this.durationFilter = {
      id: 'LAST30',
      title: 'Last 30 Days'
    };
  }

  @action.bound
  async fetchPageOne() {
    await this.fetchResults();
  }

  @computed
  get orderedResults() {
    return this.collection.models;
  }

  @computed
  get hasResults() {
    return this.collection.length > 0;
  }

  @computed
  get nextPage() {
    if (this.page >= this.totalPages - 1) return -1;

    return this.page + 1;
  }

  @computed
  get hasNextPage() {
    return this.nextPage >= 1;
  }

  @action.bound
  fetchNextPage() {
    this.collection.fetchNextPage();
  }

  @computed
  get hasMoreResults() {
    return this.collection.hasNextPage;
  }

  @computed get loading() {
    return this.collection.fetching;
  }

  @computed get durationFilterOptions() {
    return [
      {
        id: 'ALLTIME',
        title: t('All Time')
      },
      {
        id: 'TODAY',
        title: t('Today')
      },
      {
        id: 'YESTERDAY',
        title: t('Yesterday')
      },
      {
        id: 'LAST7',
        title: t('Last 7 Days')
      },
      {
        id: 'LAST14',
        title: t('Last 14 Days')
      },
      {
        id: 'LAST30',
        title: t('Last 30 Days')
      },
      {
        id: 'CUSTOM',
        title: t('Custom')
      }
    ];
  }

  @computed get durationFilterOption() {
    if (this.activeModal === 'custom-duration') {
      return { id: 'CUSTOM', title: t('Custom') };
    }

    return this.durationFilter;
  }

  @computed get durationFilterActions() {
    return this.durationFilterOptions.map(option => {
      return {
        title: option.title,
        onClick: event => {
          this.setDurationFilter(event, option);
        }
      };
    });
  }

  @action.bound setDurationFilter(e, selectedOption) {
    this.previousDurationFilter = this.durationFilter;

    if (selectedOption.id === 'CUSTOM') {
      this.timeFrameForm = new TimeFrameForm(
        {
          fields: timeFrameFormFields,
          rules: timeFrameFormRules,
          values: {
            fromDate: this.fromDate,
            toDate: this.toDate
          },
          labels: timeFrameFormLabels
        },
        {
          options: timeFrameFormOptions,
          plugins: timeFrameFormPlugins
        }
      );

      this.showModal('custom-duration');
    } else {
      this.fromDate = null;
      this.toDate = null;
      this.durationFilter = selectedOption;
    }
  }

  @action.bound submitTimeFrameForm(event) {
    event.preventDefault();

    this.timeFrameForm.submit({
      onSuccess: this.submitTimeFrameFormSuccess,
      onError: this.submitTimeFrameFormError
    });
  }

  @action.bound submitTimeFrameFormSuccess() {
    const { fromDate, toDate } = this.timeFrameForm.values();

    this.hideActiveModal().then(() => {
      runInAction(() => {
        this.durationFilter = {
          id: 'CUSTOM',
          title: t('Custom')
        };
        this.fromDate = moment(fromDate).format('YYYY-MM-DD');
        this.toDate = moment(toDate).format('YYYY-MM-DD');
        this.timeFrameForm = null;
      });
    });
  }

  @action.bound submitTimeFrameFormError() {
    console.error(this.timeFrameForm.errors());
  }

  @action.bound cancelCustomDuration() {
    this.hideActiveModal().then(() => {
      runInAction(() => {
        this.durationFilter = this.previousDurationFilter;
        this.timeFrameForm = null;
      });
    });
  }

  @computed get params() {
    return {
      limit: 10,
      offset: 0,
      mn: 'full',
      mc: 'full',
      duration: this.durationFilter.id,
      projectStatuses: this.projectStatusFilters.join(','),
      projects: this.projectFilters.map(project => project.value).join(','),
      fromDate: this.fromDate,
      toDate: this.toDate,
      includeToday: true,
      reportStatuses: this.reportStatusFilters
        .map(status => status.value)
        .join(',')
    };
  }

  @action.bound async showFilters() {
    this.showModal('filters');
    await Promise.all([this.projectSelectorUI.fetchProjectOptions()]);
    this.initFiltersForm();
  }

  @action.bound
  initFiltersForm() {
    this.activeForm = new ReportActivityFiltersForm(
      {
        fields: reportActivityFiltersFormFields,
        rules: reportActivityFiltersFormRules,
        values: {
          projectFilters: this.projectFilters.slice(),
          projectStatusFilters: this.projectStatusFilters.slice(),
          reportStatusFilters: this.reportStatusFilters.slice()
        }
      },
      {
        options: reportActivityFiltersFormOptions,
        plugins: reportActivityFiltersFormPlugins
      }
    );
  }

  @action.bound hideFilters() {
    this.hideActiveModal().then(() => {
      this.activeForm = null;
    });
  }

  @action.bound clearFilters() {
    this.projectSelectorUI.resetProjectOptions();
    this.projectFilters.clear();
    this.reportStatusFilters.replace([
      {
        value: 'CREATED',
        name: t('Created')
      },
      {
        value: 'UNSIGNED',
        name: t('Unsigned')
      },
      {
        value: 'COMPLETED',
        name: t('Signed')
      }
    ]);
    this.hideFilters();
  }

  @action.bound selectProjects(options) {
    this.activeForm.update({
      projectFilters: options.map(option => {
        return {
          value: option.value,
          name: option.name
        };
      })
    });
  }

  @computed get projectFilterDefaultOptions() {
    return this.projectFilters.slice().map(project => {
      return {
        value: project.value,
        name: project.name
      };
    });
  }

  @computed get reportStatusFilterOptions() {
    return [
      {
        value: 'CREATED',
        name: t('Created')
      },
      {
        value: 'UNSIGNED',
        name: t('Unsigned')
      },
      {
        value: 'COMPLETED',
        name: t('Signed')
      },
      {
        value: 'NO_WORK_DONE',
        name: t('No work done')
      }
    ];
  }

  @action.bound selectReportStatusFilters(options) {
    this.activeForm.update({
      reportStatusFilters: options.map(option => {
        return {
          value: option.value,
          name: option.name
        };
      })
    });
  }

  @computed get reportStatusFiltersDefaultOptions() {
    return this.reportStatusFilters.slice().map(status => {
      return {
        value: status.value,
        name: status.name
      };
    });
  }

  @action.bound applyFilters(event) {
    event.preventDefault();

    const values = this.activeForm.values();

    this.hideActiveModal().then(() => {
      this.projectFilters.replace(values.projectFilters);
      this.reportStatusFilters.replace(values.reportStatusFilters);
    });
  }

  @computed get appliedFiltersCount() {
    let count = 0;

    if (
      this.projectStatusFilters.includes('INACTIVE') ||
      this.projectStatusFilters.includes('DELETED')
    ) {
      count++;
    }

    if (
      this.reportStatusFilters.map(status => status.value).join(',') !==
      'CREATED,UNSIGNED,COMPLETED'
    ) {
      count++;
    }

    if (this.projectFilters.length > 0) {
      count++;
    }

    return count;
  }

  @computed get hasAppliedFilters() {
    return this.appliedFiltersCount > 0;
  }

  @action.bound
  fetchResults() {
    this.collection.reset();

    const params = Object.assign({}, this.params);

    if (params.duration === 'CUSTOM') {
      delete params.duration;
    }

    this.collection.fetch({
      params: params
    });
  }
}
