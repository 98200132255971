import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Input = styled.input`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${props => (props.disabled ? 'none' : 'all')};
  width: 100%;
  height: 100%;
  opacity: 0;
  font-size: 0;
  display: block;
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  ${props =>
    props.highlightDragOverZone &&
    css`
      ::before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255, 0.5);
        box-sizing: border-box;
        border: 3px solid ${props => props.theme.colors['rakenOrange']};
      }
    `};
`;

export default class extends Component {
  static defaultProps = {
    dataType: 'fileReader',
    accept: 'image/*'
  };

  static propTypes = {
    dataType: PropTypes.oneOf(['fileReader', 'formData']),
    accept: PropTypes.string,
    onFileChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  };

  state = {
    highlightDragOverZone: false,
    file: null
  };

  handleFile = e => {
    const file = e.target.files[0];
    const matchedType = file?.type.match(this.props.accept);
    const isAcceptedFile = Array.isArray(matchedType);

    if (!file || !isAcceptedFile) return;

    switch (this.props.dataType) {
      case 'formData':
        this.handleWithFormData(file);
        break;
      default:
        this.handleWithFileReader(file);
        break;
    }
  };

  handleWithFileReader = file => {
    const reader = new FileReader();

    reader.onload = function(img) {
      this.setState({ file: file });
      this.input.value = ''; // So that re-upload same file works.
      this.props.onFileChange(img.target.result);
    }.bind(this);

    reader.readAsDataURL(file);
  };

  handleWithFormData = file => {
    const formData = new FormData();
    formData.append('Media-LastModifiedDate', moment().valueOf());
    formData.append('file', file);
    this.props.onFileChange(formData);
  };

  showHighlightDragOverZone = () => {
    this.setState({ highlightDragOverZone: true });
  };

  hideHighlightDragOverZone = () => {
    this.setState({ highlightDragOverZone: false });
  };

  render() {
    const { disabled, accept, dataQA } = this.props;

    return (
      <Wrapper
        highlightDragOverZone={this.state.highlightDragOverZone}
        className={this.props.className}
      >
        <Input
          ref={input => (this.input = input)}
          type="file"
          accept={accept}
          onChange={this.handleFile}
          onClick={e => e.stopPropagation()}
          name="files"
          disabled={disabled}
          onDragOver={e => (e.dataTransfer.dropEffect = 'copy')}
          onDragEnter={this.showHighlightDragOverZone}
          onDragLeave={this.hideHighlightDragOverZone}
          onDrop={this.hideHighlightDragOverZone}
          data-qa={dataQA ? `file-input_${dataQA}` : 'file-input'}
          data-filename={this?.state?.file?.name} // So we can check uploaded file in cypress
        />
      </Wrapper>
    );
  }
}
