import UIStore from './UIStore';
import Breaks from 'stores/collections/Breaks';
import { action, observable, computed, reaction } from 'mobx';
import errorHandler from 'utils/errorHandler';

import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import debounce from 'lodash.debounce';
import orderBy from 'lodash.orderby';

import { BASE_DEBOUNCE } from 'fixtures/constants';
import { t } from 'utils/translate';

export default class BreakSelectorUI extends UIStore {
  @observable searchQuery;
  @observable loading;

  constructor(options) {
    super(options);

    this.loading = false;

    this.searchQuery = '';
    this.fetchBreaks = debounce(this.fetchBreaks, BASE_DEBOUNCE);

    this.breaks = new Breaks(null, {
      rootStore: this.rootStore
    });
  }

  @computed get hasBreaks() {
    return this.breaks.length > 0;
  }

  @action.bound
  setup() {
    this.setupReactions();
    return this.fetchBreaks();
  }

  @action.bound
  tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  @action.bound setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        this.fetchBreaks();
      }
    );
  }

  @action.bound tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @action.bound clearUIState() {
    this.breaks.reset();
    this.loading = false;
    this.searchQuery = '';
  }

  @computed get options() {
    //TODO We can tidy this up if the breaktypes endpoint allows filtering by projectUuids
    const filteredOptions = this.breaks.models.filter(
      model => model.assignedToCurrentProject
    );

    const options = filteredOptions.map(breakModel => {
      return {
        uuid: breakModel.uuid,
        name: breakModel.name,
        defaultDuration: breakModel.defaultDuration,
        durationRequired: breakModel.durationRequired,
        minimumBreakDuration: breakModel.minimumBreakDuration,
        startTimeRequired: breakModel.startTimeRequired,
        endTimeRequired: breakModel.endTimeRequired,
        billable: breakModel.billable
      };
    });

    return orderBy(options, ['name'], ['asc']);
  }

  @computed get noOptionsText() {
    return this.loading
      ? t('Searching')
      : !this.options.length
      ? t('No results')
      : false;
  }

  @action.bound
  setSearchQuery(query) {
    this.searchQuery = query;
  }

  @action.bound
  clearSearchQuery() {
    this.searchQuery = '';
  }

  @computed get hasMoreBreaks() {
    return this.breaks.totalElements > this.breaks.length;
  }

  @computed get params() {
    return {
      query: this.searchQuery,
      projectUuids: this.project?.uuid,
      limit: 80,
      sortField: 'name',
      sortDirection: 'ASC'
    };
  }

  @action.bound
  async fetchBreaks(options) {
    this.loading = true;

    try {
      await this.breaks.fetch({
        params: pickBy(this.params, identity),
        ...options
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    } finally {
      this.loading = false;
    }
  }

  fetchNextPage = async (e, autoCompleteRef) => {
    const menu = e.target;

    const scrollTop = menu.scrollTop;
    const scrollHeight = menu.scrollHeight;
    const clientHeight = menu.clientHeight;

    if (scrollTop + clientHeight === scrollHeight) {
      this.fetchNextPageOfBreaks().then(() => {
        autoCompleteRef.current.scrollTop = scrollHeight;
      });
    }
  };

  @action.bound async fetchNextPageOfBreaks() {
    if (this.breaks.fetching || !this.hasMoreBreaks) return;

    try {
      this.breaks.fetch({
        params: Object.assign(pickBy(this.params, identity), {
          offset: this.breaks.length
        }),
        add: true,
        remove: false,
        update: false
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }
}
