import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Button = styled.button`
  align-items: center;
  background-color: ${props => props.theme.colors[props.color]};
  border: none;
  ${props => props.borderRadius && `border-radius: ${props.borderRadius}px`};
  box-sizing: border-box;
  color: ${props => props.theme.colors.white};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  display: ${props => (props.display ? props.display : 'inline')};
  font-family: ${props => props.theme.fonts.primary};
  font-weight: 700;
  font-size: ${props => (props.size === 'small' ? '1.2rem' : '1.8rem')};
  height: ${props => (props.size === 'small' ? '2.6rem' : '4rem')};
  ${props =>
    props.lineHeight &&
    css`
      line-height: ${props.lineHeight / 10}rem;
    `}
  text-align: center;
  text-transform: capitalize;
  outline: 0;
  margin-left: ${props => (props.marginLeft ? props.marginLeft / 10 : 0)}rem;
  margin-right: ${props => (props.marginRight ? props.marginRight / 10 : 0)}rem;
  margin-bottom: ${props =>
    props.marginBottom ? props.marginBottom / 10 : 0}rem;
  margin-top: ${props => (props.marginTop ? props.marginTop / 10 : 0)}rem;
  padding: 0
    ${props => (props.wide ? '7.5rem' : props.compact ? '1.5rem' : '2.5rem')};
  &:disabled {
    pointer-events: none;
  }
  white-space: ${props => (props.nowrap ? 'nowrap' : 'normal')};
`;

Button.propTypes = {
  active: PropTypes.bool.isRequired,
  borderRadius: PropTypes.number,
  color: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  marginLeft: PropTypes.number,
  marginRight: PropTypes.number,
  marginBottom: PropTypes.number,
  marginTop: PropTypes.number,
  onClick: PropTypes.func
};

Button.defaultProps = {
  color: 'lightGrey1',
  disabled: false,
  active: false,
  onMouseDown: e => {
    e.preventDefault();
  }
};

export default Button;
