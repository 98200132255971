import {
  computed,
  action,
  observable,
  runInAction,
  toJS,
  reaction
} from 'mobx';

import moment from 'moment';
import request from 'axios';
import UIStore from './UIStore';
import toggleArrayFieldValue from '../../forms/utils/toggleArrayFieldValue';

import {
  BulkDownloadForm,
  bulkDownloadFormOptions,
  bulkDownloadFormFields,
  bulkDownloadFormRules,
  bulkDownloadFormLabels,
  bulkDownloadFormPlugins
} from 'forms/bulkDownload';

import ProjectComplianceMissedDailiesUI from './ProjectComplianceMissedDailies';

import { t } from 'utils/translate';
import errorHandler from 'utils/errorHandler';
import { callTrack } from 'utils/segmentIntegration';
import history from 'utils/history';

import {
  REPORT_SIGNED,
  REPORT_BULK_SIGNED
} from 'utils/segmentAnalytics/eventSpec';

export default class ProjectComplianceUI extends UIStore {
  @observable activeTab;
  @observable signed;
  @observable saveSignature;
  @observable showBulkSignModal;
  @observable showBulkDownloadModal;
  @observable searchQuery;
  @observable openedProjectTeamIds;

  constructor(options) {
    super(options);

    this.selectedCompletedDailies = observable([]);

    // Signature pad state
    this.saveSignature = false;
    this.signed = false;

    this.showBulkSignModal = false;
    this.showBulkDownloadModal = false;

    this.searchQuery = '';

    this.openedProjectTeamIds = new Set();

    this.missedDailiesUI = new ProjectComplianceMissedDailiesUI({
      projectComplianceUI: this,
      rootStore: this.rootStore
    });
  }

  @action.bound setup() {
    window.scrollTo(0, 0);
    this.setupReactions();
    this.refetchProjectStats();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearSelectedCompletedDailies();
    this.clearReportSegmentForAllTeams();
    this.clearMonthAndDateForAllTeams();
    this.clearSearchQuery();
  }

  @action.bound setupReactions() {
    // If the user searches update the month and date for all teams.
    this.cancelReactToSearchQuery = reaction(
      () => this.parsedSearchQuery,
      parsedSearchQuery => {
        this.clearSelectedCompletedDailies();
        this.clearReportSegmentForAllTeams();

        if (parsedSearchQuery) {
          this.setMonthAndDateForAllTeams(parsedSearchQuery);
          this.setReportSegmentForAllTeams('all');
        } else {
          this.clearMonthAndDateForAllTeams();
        }
      }
    );
  }

  @action.bound tearDownReactions() {
    this.cancelReactToSearchQuery && this.cancelReactToSearchQuery();
  }

  @computed
  get project() {
    return this.parent.project;
  }

  @computed
  get projectName() {
    return this.project.name;
  }

  @computed
  get projectDurationDays() {
    var current = moment();
    var start = moment(this.project.startDate);
    return current.diff(start, 'days');
  }

  @computed
  get hasSelectedCompletedDailies() {
    return this.selectedCompletedDailies.length > 0;
  }

  @action.bound
  setReportSegmentForAllTeams(segment) {
    if (this.project) {
      this.project.internalProjectTeam.completedDailies.setReportsSegment(
        segment
      );

      if (!this.project.isChildProject) {
        this.project.externalProjectTeams.forEach(projectTeam => {
          projectTeam.completedDailies.setReportsSegment(segment);
        });
      }
    }
  }

  @action.bound
  clearReportSegmentForAllTeams() {
    if (this.project) {
      this.project.internalProjectTeam.completedDailies.clearReportsSegment();
      if (!this.project.isChildProject) {
        this.project.externalProjectTeams.forEach(projectTeam => {
          projectTeam.completedDailies.clearReportsSegment();
        });
      }
    }
  }

  @action.bound
  toggleDailyToSign(date) {
    if (
      this.selectedCompletedDailies.find(selectedCompletedDaily => {
        if (date.reportDate) {
          return (
            selectedCompletedDaily.reportDate === date.reportDate &&
            selectedCompletedDaily.segmentUuid === date.segmentUuid
          );
        } else {
          return selectedCompletedDaily === date;
        }
      })
    ) {
      this.removeDailyToSign(date);
    } else {
      this.addDailyToSign(date);
    }
  }

  @action.bound
  addDailyToSign(date) {
    this.selectedCompletedDailies.push(date);
  }

  @action.bound
  removeDailyToSign(date) {
    if (date.reportDate) {
      const toRemove = this.selectedCompletedDailies.find(
        selectedCompletedDaily => {
          return (
            selectedCompletedDaily.reportDate === date.reportDate &&
            selectedCompletedDaily.segmentUuid === date.segmentUuid
          );
        }
      );
      this.selectedCompletedDailies.remove(toRemove);
    } else {
      this.selectedCompletedDailies.remove(date);
    }
  }

  @action.bound
  clearSelectedCompletedDailies() {
    this.selectedCompletedDailies.clear();
  }

  @action.bound
  refetchProjectStats() {
    // We use this when we open the compliance modal to re hydrate the project and project team stats.
    this.project.refetchProjectStats();
  }

  @action.bound
  openBulkSignatureModal() {
    this.showBulkSignModal = true;
  }

  @action.bound
  closeBulkSignatureModal() {
    this.showBulkSignModal = false;
  }

  @action.bound
  sign() {
    this.signed = true;
  }

  @action.bound
  unSign() {
    this.signed = false;
  }

  @action.bound
  unSignSync() {
    this.signed = false;
  }

  @action.bound
  signTheseDailies() {
    this.project.internalProjectTeam.completedDailies.models.forEach(model => {
      if (
        this.selectedCompletedDailies.find(selectedCompletedDaily => {
          return (
            selectedCompletedDaily === model.date ||
            (selectedCompletedDaily.reportDate === model.date &&
              selectedCompletedDaily.segmentUuid === model.segmentUuid)
          );
        })
      ) {
        model.status = 'COMPLETED';
      }
      return;
    });
  }

  @action.bound
  unSignTheseDailies() {
    this.project.internalProjectTeam.completedDailies.models.forEach(model => {
      if (
        this.selectedCompletedDailies.find(selectedCompletedDaily => {
          return (
            selectedCompletedDaily === model.date ||
            (selectedCompletedDaily.reportDate === model.date &&
              selectedCompletedDaily.segmentUuid === model.segmentUuid)
          );
        })
      ) {
        model.status = 'UNSIGNED';
      }
      return;
    });
  }

  softVerify(values) {
    this.signTheseDailies();
    this.closeBulkSignatureModal();
    this.rootStore.notificationsUI.pushNotification({
      onUndo: () => {
        this.unSignTheseDailies();
        this.undoVerify(values);
      },
      onDismiss: () => {
        this.verify(values);
      },
      title: `Reports Signed`,
      text: `${values.selectedCompletedDailies.length} previously unsigned ${
        values.selectedCompletedDailies.length > 1
          ? 'reports have'
          : 'report has'
      } just been successfully signed.`
    });
  }

  @action.bound
  resetVerify() {
    this.verified = false;
  }

  @action.bound
  undoVerify(values) {
    this.saving = false;
    this.verifying = false;
    this.signed = false;
    this.saveSignature = false;
    values.selectedCompletedDailies = [];
  }

  @action.bound
  verify(values) {
    this.saving = true;
    this.verifying = true;

    const payload = {
      signature: values.base64,
      saveSignature: values.saveSignature
    };

    if (this.projectUI.segmentUuid) {
      payload.reportDatesAndSegments = toJS(values.selectedCompletedDailies);
    } else {
      payload.reportDates = toJS(values.selectedCompletedDailies);
    }

    request
      .post(
        `/ra/projects/${this.project.teamId}/reports/dailies/completed/sign`,
        payload
      )
      .then(response => {
        runInAction(() => {
          this.saving = false;
          this.verifying = false;
          this.signed = true;
          this.saveSignature = false;
          values.selectedCompletedDailies = [];

          const { signedReports } = response.data;

          if (signedReports.length > 0) {
            callTrack(REPORT_BULK_SIGNED, {
              report_count: signedReports.length
            });
          }

          signedReports.forEach(report => {
            const segment = this.project.segments.find(
              segment => segment.uuid === report.segmentUuid
            );

            callTrack(REPORT_SIGNED, {
              report_date: report.date,
              project_id: this.project.id,
              project_name: this.project.name,
              quick_sign: values.isQuickSign || false, // deprecated
              segment_name: segment?.name || null,
              status: 'COMPLETED'
            });
          });

          if (response.data.signedBy.signature) {
            this.rootStore.me.signature = response.data.signedBy.signature;
          }
          this.clearSelectedCompletedDailies();
          this.verificationMessage = 'Reports Signed';
        });
      })
      .catch(error => {
        this.saving = false;
        this.verifying = false;
        errorHandler(error, this.rootStore.notificationsUI.pushError);
      });
  }

  @action.bound
  toggleSaveSignature() {
    this.saveSignature = !this.saveSignature;
  }

  @action.bound
  openBulkDownloadModal() {
    // Instantiate a new form to be used for entering the values.
    this.activeForm = new BulkDownloadForm(
      {
        fields: bulkDownloadFormFields,
        rules: bulkDownloadFormRules,
        labels: bulkDownloadFormLabels,
        values: {
          downloadType: !this.project.hasSuperDaily
            ? 'THIS_COMPANY'
            : !this.project.isChildProject
            ? 'ALL_SUPER_DAILIES'
            : 'ALL_COMPANIES',
          dateRange: 'ENTIRE_DURATION'
        }
      },
      {
        options: bulkDownloadFormOptions,
        plugins: bulkDownloadFormPlugins
      }
    );
    this.showBulkDownloadModal = true;
  }

  @action.bound
  closeBulkDownloadModal() {
    this.activeForm = null;
    this.showBulkDownloadModal = false;
  }

  @computed
  get collaboratorProjectTeamIds() {
    return this.project.externalProjectTeams.map(model => model.id);
  }

  @computed
  get downloadTypeSelectOptions() {
    const myCompanyOption = {
      value: 'THIS_COMPANY',
      displayName: t('My Company'),
      data: [this.project.teamId]
    };

    // only show the option if 'Super Daily' is not enabled
    if (!this.project.hasSuperDaily) {
      return [myCompanyOption];
    }

    const options = [
      {
        value: 'ALL_COMPANIES',
        displayName: t('All Companies'),
        data: [] // This empty array tells the backend to get everything
      },
      {
        ...myCompanyOption
      }
    ];

    if (!this.project.isChildProject) {
      // Only if the user is not an SC on this project add All Super Dailies to the start of the array
      options.unshift({
        value: 'ALL_SUPER_DAILIES',
        displayName: t('All Super Dailies'),
        data: [] // This empty array tells the backend to get everything
      });
    }
    if (
      this.project.externalProjectTeams.length > 0 &&
      !this.project.isChildProject
    ) {
      // Only add this option if we have colaborators
      options.push({
        value: 'SPECIFIC',
        displayName: 'Specific Collaborators',
        data: this.activeForm.$('collaborators').value
      });
    }
    return options;
  }

  @computed
  get dateRangeSelectOptions() {
    return [
      {
        value: 'ENTIRE_DURATION',
        displayName: t('The Entire Duration Of This Project')
      },
      { value: 'SPECIFIC', displayName: t('Specific Time frame') }
    ];
  }

  @action.bound
  collaboratorSelected(id) {
    return (
      this.activeForm.$('collaborators').value &&
      this.activeForm.$('collaborators').value.includes(id)
    );
  }

  @action.bound
  toggleCollaborator(id) {
    toggleArrayFieldValue(this.activeForm, 'collaborators', id);
  }

  @computed
  get bulkDownloadCollaboratorsNames() {
    return this.project.externalProjectTeams
      .filter(model =>
        this.activeForm.$('collaborators').value.includes(model.id)
      )
      .map(model => model.name);
  }

  @action.bound
  submitBulkDownloadForm(e) {
    e.preventDefault();
    this.activeForm.submit({
      onSuccess: this.submitBulkDownloadFormSuccess,
      onError: this.submitBulkDownloadFormError
    });
  }

  @action.bound
  showBulkDownloadSuccessToast() {
    this.rootStore.notificationsUI.pushNotification({
      showUndo: false,
      title:
        t('Your download request has been sent.') +
        ' ' +
        t(
          ' You will receive an email with a link to download your requested completed dailies later today.'
        )
    });
  }

  @action.bound
  submitBulkDownloadFormSuccess() {
    const {
      recipients,
      fromDate,
      toDate,
      downloadType
    } = this.activeForm.values();

    const payload = {
      teamIds: this.downloadTypeSelectOptions.find(obj => {
        return obj.value === this.activeForm.values().downloadType;
      }).data,
      emails: recipients,
      fromDate: fromDate
        ? moment(fromDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
        : null,
      toDate: toDate ? moment(toDate, 'YYYY-MM-DD').format('YYYY-MM-DD') : null,
      downloadSuperDaily: downloadType === 'ALL_SUPER_DAILIES'
    };

    this.saving = true;

    return request
      .post(
        `ra/projects/${this.project.teamId}/reports/dailies/download`,
        payload
      )
      .then(response => {
        runInAction(() => {
          this.saving = false;
          this.closeBulkDownloadModal();
          this.showBulkDownloadSuccessToast();
        });
      })
      .catch(error => {
        this.saving = false;
        this.closeBulkDownloadModal();
        errorHandler(error, this.rootStore.notificationsUI.pushError);
      });
  }

  @action.bound
  setSearchQuery(value) {
    this.searchQuery = value;
  }

  @action.bound
  clearSearchQuery() {
    this.searchQuery = '';
  }

  @computed
  get parsedSearchQuery() {
    let date;
    const year = new RegExp('^20[0-9]{2}$');

    if (!this.project) return null;
    if (!this.searchQuery) return null;

    if (year.test(this.searchQuery)) {
      date = moment(this.searchQuery, 'YYYY');

      if (!date.isValid()) return null;

      return {
        month: null,
        year: date.format('YYYY')
      };
    }

    date = moment(this.searchQuery, [
      'MM/YYYY',
      'YYYY-MM-DD',
      'MMMM YYYY',
      'MM/DD/YYYY',
      'MM/DD/YY',
      'DD/MM/YYYY',
      'DD/MM/YY'
    ]);

    if (!date.isValid()) return null;

    return {
      month: date.format('MMMM'),
      year: date.format('YYYY')
    };
  }

  @action.bound
  setMonthAndDateForAllTeams(parsedSearchQuery) {
    this.project.internalProjectTeam.completedDailies.updateSelectedYearAndMonth(
      parsedSearchQuery.year,
      parsedSearchQuery.month
    );

    if (!this.project.isChildProject) {
      this.project.externalProjectTeams.forEach(projectTeam => {
        projectTeam.completedDailies.updateSelectedYearAndMonth(
          parsedSearchQuery.year,
          parsedSearchQuery.month
        );
      });
    }
  }

  @action.bound
  clearMonthAndDateForAllTeams() {
    if (this.project) {
      this.project.internalProjectTeam.completedDailies.updateSelectedYearAndMonth(
        null,
        null
      );
      if (!this.project.isChildProject) {
        this.project.externalProjectTeams.forEach(projectTeam => {
          projectTeam.completedDailies.updateSelectedYearAndMonth(null, null);
        });
      }
    }
  }

  @computed
  get searchIsValidReportDate() {
    return moment(
      this.searchQuery,
      ['DD/MM/YY', 'MM/DD/YY', 'DD/MM/YYYY', 'MM/DD/YYYY'],
      true
    ).isValid();
  }

  @action.bound
  completedDailiesBySearchTerm(completedDailies) {
    return completedDailies.models.filter(
      completedDaily =>
        completedDaily.dateFormatted.substring(0, 5) ===
        this.searchQuery.substring(0, 5)
    );
  }

  @action.bound cancelCompliance() {
    history.push({
      pathname: `${this.project.viewUrl}/worklogs`,
      search: this.parent.baseQueryParams
    });
  }
}
