import request from 'axios';
import debounce from 'lodash.debounce';
import { reaction, computed, action, observable } from 'mobx';
import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';
import { t } from 'utils/translate';

import MaterialLog from 'stores/models/MaterialLog';

const materialLogFormPlugins = extendedPlugins;

const materialLogFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const materialLogFormFields = [
  'uuid',
  'material',
  'material.uuid',
  'material.name',
  'material.unit',
  'material.unit.uuid',
  'material.unit.name',
  'quantity',
  'costCode',
  'costCode.uuid',
  'costCode.code',
  'costCode.division',
  'desc'
];

const materialLogFormRules = {
  material: 'required',
  'material.uuid': 'required',
  desc: 'string|between:0,10000',
  quantity: 'required|numeric|min:0'
};

const materialLogFormValues = {};

const materialLogFormLabels = {
  material: t('Material'),
  'material.uuid': t('material'),
  quantity: t('Quantity'),
  costCode: t('Cost Code'),
  'costCode.uuid': t('cost code'),
  desc: t('Description')
};

class MaterialLogForm extends MobxReactForm {
  @observable duplicateMaterialLog;

  constructor(settings, options) {
    super(settings, options);

    this.parentStore = options.parentStore;

    this.duplicateMaterialLog = null;

    this.searchMaterialLogs = debounce(this.searchMaterialLogs, 250);

    this.setupReactions();
  }

  @action.bound setupReactions() {
    this.reactToSearchParams = reaction(
      () => this.searchParams,
      searchParams => {
        if (searchParams) {
          this.duplicateMaterialLog = null;
          this.searchMaterialLogs();
        }
      }
    );
  }

  @action.bound tearDownReactions() {
    this.reactToSearchParams && this.reactToSearchParams();
  }

  @computed get searchParams() {
    if (!this.$('material.uuid').value) {
      return null;
    }

    return {
      materialUuids: this.$('material.uuid').value,
      costCodeUuids: this.$('costCode.uuid').value,
      fromDate: this.parentStore.date,
      toDate: this.parentStore.date,
      projectUuids: this.parentStore.projectUuid,
      segmentUuids: this.parentStore.segmentUuid,
      limit: 2
    };
  }

  @action.bound async searchMaterialLogs() {
    if (!this.searchParams) return;

    let duplicateMaterialLogData;

    try {
      const response = await request.get(
        `${this.parentStore.rootStore.urlMicroService(
          'performanceTracking'
        )}/companies/${this.parentStore.company.uuid}/materiallogs`,
        {
          params: this.searchParams
        }
      );

      if (response.data?.collection?.length) {
        duplicateMaterialLogData = response.data.collection.find(
          materialLog => {
            return (
              Boolean(materialLog.material.uuid) ===
                Boolean(this.$('material.uuid').value) &&
              Boolean(materialLog.costCode?.uuid) ===
                Boolean(this.$('costCode.uuid').value) &&
              materialLog.uuid !== this.$('uuid').value
            );
          }
        );

        if (duplicateMaterialLogData) {
          this.duplicateMaterialLog = new MaterialLog(
            duplicateMaterialLogData,
            {
              rootStore: this.parentStore.rootStore
            }
          );
        }
      }
    } catch (error) {
      this.duplicateMaterialLog = null;
    }
  }

  @computed get duplicateMaterialLogMessage() {
    if (this.$('costCode.uuid').value) {
      return t(
        'There is an existing material log with the same material and cost code. If you proceed, it will be merged with the existing log.'
      );
    }

    return t(
      'There is an existing material log with the same material and no cost code. Use a cost code to differentiate or it will be merged with the existing log.'
    );
  }
}

export {
  MaterialLogForm,
  materialLogFormOptions,
  materialLogFormFields,
  materialLogFormRules,
  materialLogFormValues,
  materialLogFormLabels,
  materialLogFormPlugins
};
