import { action, computed } from 'mobx';
import SettingsChildUI from './SettingsChildUI';

import {
  SettingsPdfLogoForm,
  settingsPdfLogoFormOptions,
  settingsPdfLogoFormFields,
  settingsPdfLogoFormLabels,
  settingsPdfLogoFormRules,
  settingsPdfLogoFormPlugins
} from 'forms/project/settingsPdfLogo';

import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';
import isDataURL from 'utils/isDataURL';

export default class SettingsPdfLogoUI extends SettingsChildUI {
  @computed get title() {
    return t('PDF & logo');
  }

  @computed get headerTitle() {
    return t('Customize your branded daily report');
  }

  @computed get hasWriteAccess() {
    return this.authorization.canEditProjectPdfLogo;
  }

  @computed get isCompanyDefault() {
    const { primaryColor, projectLogo } = this.form.values();
    const {
      primaryColor: defaultPrimaryColor,
      companyLogo: defaultProjectLogo
    } = this.company.pdfLogoFormValues;

    return (
      (primaryColor === defaultPrimaryColor || !primaryColor) &&
      (projectLogo === defaultProjectLogo || !projectLogo)
    );
  }

  @action.bound setup() {
    this.setupForm();
  }

  @action.bound setupForm(values) {
    this.form = new SettingsPdfLogoForm(
      {
        fields: settingsPdfLogoFormFields,
        rules: settingsPdfLogoFormRules,
        labels: settingsPdfLogoFormLabels,
        values: values || this.project.pdfLogoFormValues
      },
      {
        options: settingsPdfLogoFormOptions,
        plugins: settingsPdfLogoFormPlugins
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @action.bound async submitFormSuccess() {
    this.saving = true;

    const values = this.form.values();

    const payload = {
      primaryColor: values.primaryColor
    };

    if (values.projectLogo && isDataURL(values.projectLogo)) {
      payload.projectLogo = {
        base64: values.projectLogo
      };
    } else {
      payload.projectLogo = {
        base64: ''
      };
    }

    try {
      await this.project.save(payload, {
        wait: true
      });

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('PDF & logo updated')
      });

      // Reinitialize form
      this.setupForm();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound resetToCompanyDefaults(event) {
    event.preventDefault();

    this.form.clear();
  }
}
