import { action, computed, observable, reaction } from 'mobx';
import SettingsChildUI from './SettingsChildUI';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import {
  SettingsNotificationsForm,
  settingsNotificationsFormOptions,
  settingsNotificationsFormFields,
  settingsNotificationsFormPlugins
} from 'forms/project/settingsNotifications';
import orderBy from 'lodash.orderby';

export default class SettingsNotificationsUI extends SettingsChildUI {
  @observable searchQuery;
  @observable sortDirection;
  @observable sortField;
  @observable loading;

  constructor(options) {
    super(options);

    this.sortDirection = 'asc';
    this.sortField = 'user.fullName';
    this.searchQuery = '';
    this.loading = true;
  }

  @action.bound setup() {
    this.setupReactions();
    this.fetchNotifications();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToFetchingUsers = reaction(
      () => this.hasFetchingUsers,
      value => {
        if (value) {
          this.fetchNotifications();
        }
      }
    );
  }

  tearDownReactions() {
    this.reactToFetchingUsers && this.reactToFetchingUsers();
  }

  @computed
  get hasFetchingUsers() {
    return !this.rootStore.users?.fetching;
  }

  @computed get headerTitle() {
    return t('Manage notifications for your project');
  }

  @action.bound
  async fetchNotifications() {
    if (this.hasNotificationSettings) {
      this.initActiveForm();
      return;
    }

    try {
      await this.project.notificationSettings.fetch({
        url: `/ra/projects/${this.project.id}/notification-settings`,
        params: {
          limit: 1000,
          mc: 'full',
          mn: 'full'
        }
      });
      this.initActiveForm();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async updateNotification(model) {
    await this.authorization.checkFeatureAccess(
      'EditProjectNotificationSettings'
    );

    try {
      const value = this.activeForm
        .$('notificationSettings')
        .values()
        .find(value => value.user.id === model.user.id);

      await model.save(
        {
          settings: value.settings
        },
        {
          wait: true
        }
      );

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Notification settings saved')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound
  initActiveForm() {
    this.currentFormInitialValues = Object.assign(
      {},
      {
        notificationSettings: this.project?.notificationSettingsFormValues
      }
    );

    this.activeForm = new SettingsNotificationsForm(
      {
        fields: settingsNotificationsFormFields,
        values: this.currentFormInitialValues
      },
      {
        options: settingsNotificationsFormOptions,
        plugins: settingsNotificationsFormPlugins
      }
    );

    this.loading = false;
  }

  @action.bound
  sortByColumn(sortField) {
    this.sortField = sortField;
    if (this.sortDirection === 'asc') {
      this.sortDirection = 'desc';
    } else {
      this.sortDirection = 'asc';
    }
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.searchQuery = '';
    this.loading = true;
    this.sortField = 'user.fullName';
    this.sortDirection = 'asc';
  }

  @computed
  get sortedNotificationSettings() {
    return orderBy(
      this.project.notificationSettings.models,
      [this.sortField],
      [this.sortDirection]
    );
  }

  @computed
  get searchedNotificationSettings() {
    if (!this.searchQuery) {
      return this.sortedNotificationSettings;
    }

    return this.sortedNotificationSettings.filter(model => {
      return (
        model.user.fullName
          .toLowerCase()
          .indexOf(this.searchQuery.toLowerCase()) > -1 ||
        model.user.username
          ?.toLowerCase()
          .indexOf(this.searchQuery.toLowerCase()) > -1
      );
    });
  }

  @computed get hasWriteAccess() {
    return this.authorization.canEditProjectNotificationSettings;
  }

  @computed
  get hasSearchedNotificationSettings() {
    return this.searchedNotificationSettings.length > 0;
  }

  @computed
  get hasNotificationSettings() {
    return this.project.notificationSettings.length > 0;
  }

  @computed get showEmptyState() {
    return !this.loading && !this.hasSearchedNotificationSettings;
  }

  @computed get title() {
    return t('Notifications settings');
  }
}
