import UIStore from 'stores/ui/UIStore';
import { action, observable, computed } from 'mobx';
import alertErrorHandler from 'utils/alertErrorHandler';
import omit from 'lodash.omit';
import capitalize from 'lodash.capitalize';
import history from 'utils/history';
import moment from 'moment';

import { t } from 'utils/translate';
import parseGeolocation from 'utils/parseGeolocation';
import countryReaction from 'utils/countryReaction';
import searchedPlaceReaction from 'utils/searchedPlaceReaction';

import {
  ProjectInfoForm,
  projectInfoFormOptions,
  projectInfoFormFields,
  projectInfoFormSaveWarningFields,
  projectInfoFormRules,
  projectInfoFormLabels,
  projectInfoFormPlugins
} from 'forms/superAdmin/project/projectInfoForm';

export default class SuperAdminProjectInfoUI extends UIStore {
  @observable projectInfoForm;
  @observable nextURL;
  @observable searchedPlace;

  // Control Save Warning Modal
  @observable projectSettingsInfoSaveWarningFields;
  @observable projectSettingsInfoSavePayload;

  constructor(options) {
    super(options);

    this.projectInfoForm = null;
    this.nextURL = null;
  }

  @computed
  get activeProject() {
    return this.parent.activeProject;
  }

  @action.bound
  updateProjectStatus(status) {
    this.projectInfoForm.update({
      status: status
    });
  }

  @action.bound setup() {
    this.instantiateProjectInfoForm();
    this.setupReactions();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.projectInfoForm = null;
    this.searchedPlace = null;
    this.nextURL = null;
  }

  @action.bound
  setupReactions() {
    this.reactToCountry = countryReaction(this, this.projectInfoForm);
    this.reactToSearchedPlace = searchedPlaceReaction(
      this,
      this.projectInfoForm
    );
  }

  @action.bound tearDownReactions() {
    this.reactToCountry && this.reactToCountry();
    this.reactToSearchedPlace && this.reactToSearchedPlace();
  }

  @action.bound
  instantiateProjectInfoForm() {
    this.projectInfoForm = new ProjectInfoForm(
      {
        fields: projectInfoFormFields,
        rules: projectInfoFormRules,
        values: this.activeProject.infoFormValues,
        labels: projectInfoFormLabels
      },
      {
        options: projectInfoFormOptions,
        plugins: projectInfoFormPlugins
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @action.bound
  blockHistoryIfFormChanged() {
    this.unblock = history.block((location, action) => {
      if (this.projectInfoForm && this.projectInfoForm.check('isDirty')) {
        this.showExitModal(location.pathname);
        return 'Blocked';
      }
    });
  }

  @computed
  get formIsValid() {
    return Boolean(
      this.projectInfoForm && this.projectInfoForm.check('isValid')
    );
  }

  @action.bound
  clearprojectInfoForm(e) {
    e.preventDefault();
    this.projectInfoForm.reset();
  }

  @action.bound
  submitprojectInfoForm(e) {
    e.preventDefault();

    this.projectInfoForm.submit({
      onSuccess: this.submitProjectInfoSuccess,
      onError: this.submitProjectInfoError
    });
  }

  @action.bound
  async submitProjectInfoSuccess() {
    const payload = {
      ...omit(this.projectInfoForm.values(), ['projectImage'])
    };

    if (this.projectInfoForm.$('projectImage.base64').value) {
      payload.projectImage = {
        base64: this.projectInfoForm.$('projectImage.base64').value
      };
    }

    if (payload.startDate) {
      payload.startDate = moment(
        this.projectInfoForm.$('startDate').value
      ).format('YYYY-MM-DD');
    }

    if (payload.endDate) {
      payload.endDate = moment(this.projectInfoForm.$('endDate').value).format(
        'YYYY-MM-DD'
      );
    }

    const warningFields = this.hasExternalId
      ? projectInfoFormSaveWarningFields
          .filter(field => this.projectInfoForm.$(field).check('isDirty'))
          .map(field =>
            (field === 'status'
              ? 'Project State'
              : projectInfoFormLabels[field]
            )
              .split(' ')
              .map(capitalize)
              .join(' ')
          )
      : [];
    if (warningFields.length > 0) {
      this.showProjectSettingsInfoSaveWarningModal(warningFields, payload);
    } else {
      await this.applyInfoChange(payload);
    }
  }

  async applyInfoChange(payload) {
    this.saving = true;

    try {
      await this.activeProject.save(payload, {
        wait: true
      });

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: `Project info saved`
      });

      this.unblockHistory();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @computed
  get hasExternalId() {
    return !!this.activeProject.externalId;
  }

  @computed
  get isProjectNoDisabled() {
    return this.hasExternalId && !!this.activeProject.projectNo;
  }

  @action.bound
  submitProjectInfoError() {
    console.log(this.projectInfoForm.errors());
  }

  @action.bound
  showExitModal(nextURL) {
    this.showModal('DiscardChangesModal');
    this.nextURL = nextURL;
  }

  @action.bound
  cancelExitModal() {
    return this.hideActiveModal().then(() => {
      this.nextURL = null;
    });
  }

  @action.bound
  discardExitModal() {
    return this.hideActiveModal().then(() => {
      this.moveToNextURL();
    });
  }

  @action.bound moveToNextURL() {
    this.unblock();
    history.push(this.nextURL);
    this.nextURL = null;
  }

  @action.bound unblockHistory() {
    this.unblock && this.unblock();
  }

  @computed get stateOptions() {
    const value = this.projectInfoForm.$('address.state').value;

    if (!value || this.regions.isValidUsState(value)) {
      return this.regions.asOptions;
    }

    return [{ name: t('Unrecognized: ') + value, value: value }].concat(
      this.regions.asOptions
    );
  }

  @action.bound
  setSearchedPlace(place) {
    this.searchedPlace = parseGeolocation(place);
  }

  @action.bound
  showProjectSettingsInfoSaveWarningModal(warningFields, values) {
    this.projectSettingsInfoSaveWarningFields = warningFields;
    this.projectSettingsInfoSavePayload = values;
  }

  @action.bound
  hideProjectSettingsInfoSaveWarningModal() {
    this.projectSettingsInfoSaveWarningFields = null;
    this.projectSettingsInfoSavePayload = null;
  }

  @action.bound
  async confirmProjectSettingsInfoSave() {
    const values = this.projectSettingsInfoSavePayload;
    this.hideProjectSettingsInfoSaveWarningModal();
    await this.applyInfoChange(values);
  }
}
