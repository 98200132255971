import { computed } from 'mobx';
import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import { t } from 'utils/translate';
import trimObject from '../utils/trimObject';

const payTypeFormPlugins = extendedPlugins;

const payTypeFormOptions = {
  validateOnInit: false,
  strictUpdate: false,
  validateOnChange: true
};

const payTypeFormFields = ['uuid', 'name', 'code'];

const payTypeFormRules = {
  name: 'string|required|max:25',
  code: 'string|required|max:5'
};

const payTypeFormLabels = {
  name: t('Pay Type'),
  code: t('Abbreviation')
};

class PayTypeForm extends MobxReactForm {
  @computed get identificationString() {
    return `${this.$('name').value}, ${this.$('code').value}`;
  }

  trimmedValues() {
    return trimObject(this.values());
  }
}

export {
  PayTypeForm,
  payTypeFormOptions,
  payTypeFormFields,
  payTypeFormRules,
  payTypeFormLabels,
  payTypeFormPlugins
};
