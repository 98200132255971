import { computed } from 'mobx';
//import ReportEntry from 'stores/models/ReportEntry';
import moment from 'moment';
import { t } from 'utils/translate';

import { Model } from 'mobx-mc';

export default class Group extends Model {
  get restAttributes() {
    return [
      'uuid',
      'groupClass',
      'groupName',
      'status',
      'companyUuid',
      'createdBy',
      'createdTimestamp',
      'updatedBy',
      'updatedTimestamp',
      'projectUuids',
      'memberUuids'
    ];
  }

  idAttribute() {
    return 'uuid';
  }

  @computed
  get updatedTimestampFormatted() {
    return this.formatTimestamp(this.updatedTimestamp);
  }

  @computed
  get createdTimestampFormatted() {
    return this.formatTimestamp(this.createdTimestamp);
  }

  @computed get createdByName() {
    return `${this.createdBy.firstName} ${this.createdBy.lastName}`;
  }

  @computed get formValues() {
    return {
      uuid: this.uuid,
      groupClass: this.groupClass,
      groupName: this.groupName
    };
  }

  formatTimestamp(timestamp) {
    var now = moment();
    var then = moment(timestamp);

    if (now.isSame(then, 'day')) {
      return t('Today at') + ' ' + then.format('h:mm A');
    }

    if (now.subtract(1, 'day').isSame(then, 'day')) {
      return t('Yesterday at') + ' ' + then.format('h:mm A');
    }

    if (now.isSame(then, 'year')) {
      return then.format('MMM D h:mm A');
    }

    return then.format('MMM D, YYYY h:mm A');
  }

  get urlRoot() {
    return `ra/companies/${this.rootStore.me.company.uuid}/groups`;
  }

  @computed get groupString() {
    return `${this.groupClass.name} ${this.groupName}`;
  }

  @computed get viewUrl() {
    return `/company-settings/groups/${this.uuid}`;
  }
}
