import { action, computed, observable } from 'mobx';
import UIStore from '../../UIStore';
import history from 'utils/history';
import {
  checklistTypeFormFields,
  checklistTypeFormOptions,
  checklistTypeFormPlugins,
  checklistTypeFormRules,
  checklistTypeFormLabels,
  ChecklistTypeForm
} from 'forms/checklistType';
import ChecklistType from 'stores/models/checklists/ChecklistType';
import ChecklistClasses from 'stores/collections/checklists/ChecklistClasses';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

import { callTrack } from 'utils/segmentIntegration';
import { CHECKLIST_TYPE_EDITED } from 'utils/segmentAnalytics/eventSpec';

export default class ChecklistTypeEditUI extends UIStore {
  @observable entryForEdit;
  @observable entryEditForm;

  constructor(options) {
    super(options);

    this.checklistClasses = new ChecklistClasses(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.entryForEdit = null;
    this.entryEditForm = null;
  }

  @action.bound async setup(id) {
    this.checklistClasses.fetch({
      params: {
        limit: 10000
      }
    });
    this.fetchEntry(id);
  }

  @action.bound async fetchEntry(uuid) {
    if (this.entryForEdit) return;

    try {
      let model = this.parent.checklistTypes.get(uuid);

      if (!model) {
        model = new ChecklistType(
          {
            uuid: uuid
          },
          {
            rootStore: this.rootStore
          }
        );

        await model.fetch();
      }

      this.setEntryForEdit(model);
    } catch (error) {
      console.error(error);
      this.cancelChecklistTypeEdit();
    }
  }

  @action.bound setEntryForEdit(model) {
    this.entryForEdit = model;
    this.entryEditForm = new ChecklistTypeForm(
      {
        fields: checklistTypeFormFields,
        rules: checklistTypeFormRules,
        labels: checklistTypeFormLabels,
        values: this.entryForEdit.formValues
      },
      {
        options: checklistTypeFormOptions,
        plugins: checklistTypeFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.activeModal = null;
    this.entryForEdit = null;
    this.entryEditForm = null;
    this.saving = false;
  }

  @computed get checklistClassOptions() {
    return this.checklistClasses.models.map(typeClass => ({
      name: typeClass.name,
      uuid: typeClass.uuid
    }));
  }

  @action.bound
  async submitChecklistTypeEditSuccess() {
    this.saving = true;
    const { typeName, typeClass } = this.entryEditForm.trimmedValues();
    try {
      await this.entryForEdit.save(
        {
          typeName,
          typeClass: {
            uuid: typeClass.uuid
          }
        },
        {
          wait: true
        }
      );

      callTrack(CHECKLIST_TYPE_EDITED, {
        checklist_type_class: typeClass.name,
        checklist_type_name: typeName
      });

      this.parent.sortByLastUpdated();
      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Checklist type edited')
      });
      this.cancelChecklistTypeEdit();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  submitChecklistTypeEdit(e) {
    e.preventDefault();
    e.stopPropagation();

    this.entryEditForm.submit({
      onSuccess: this.submitChecklistTypeEditSuccess,
      onError: e => {
        console.error(this.entryEditForm.errors());
      }
    });
  }

  @action.bound
  cancelChecklistTypeEdit() {
    history.push({
      pathname: `/company-settings/checklists/types`,
      search: this.baseQueryParams
    });
  }
}
