import React, { Component } from 'react';
import styled from 'styled-components';

import InputMenuItem from './InputMenuItem';

const Controls = styled.div`
  display: flex;
  > button {
    margin: 0 3px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const WrapChild = styled.div`
  display: ${props => (props.block ? 'block' : 'flex')};
  align-items: center;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

export default class SelectBoxItem extends Component {
  componentDidMount() {
    if (this.props.focused) {
      this.wrapperRef.focus({ preventScroll: true });
    }
  }

  componentDidUpdate() {
    if (this.props.focused) {
      this.wrapperRef.focus();
    } else {
      this.wrapperRef.blur();
    }
  }

  render() {
    const {
      controls,
      children,
      onMouseOver,
      enableStar,
      block,
      ...rest
    } = this.props;

    return (
      <InputMenuItem
        ref={ref => (this.wrapperRef = ref)}
        onMouseOver={onMouseOver}
        {...rest}
      >
        <WrapChild block={block}>{children}</WrapChild>

        {controls && <Controls>{controls}</Controls>}
      </InputMenuItem>
    );
  }
}
