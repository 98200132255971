import styled from 'styled-components';

const getInputBorder = (props, isFocused) => {
  if (props.hasError) {
    return `2px solid ${props.theme.colors[props.errorColor]}`;
  }

  if (props.highlight) {
    return `2px solid ${props.theme.colors.goGreen}`;
  }

  if (props.isValid) {
    if (props.focusColor && isFocused) {
      return `2px solid ${props.theme.colors[props.focusColor]}`;
    } else {
      return `1px solid ${props.theme.colors.lightGrey5}`;
    }
  }

  if (props.disabled) {
    return `1px solid ${props.theme.colors.lightGrey5}`;
  }

  if (isFocused) {
    return `2px solid ${props.theme.colors[props.focusColor || 'blueAccent']}`;
  } else {
    return `1px solid ${props.theme.colors.lightGrey5}`;
  }
};

export default styled.div`
  align-items: center;
  box-sizing: border-box;
  border: ${props => getInputBorder(props)};
  border-left: none;
  border-radius: 0 4px 4px 0;
  background-color: ${props =>
    props.disabled || props.readOnly
      ? props.theme.colors.cream
      : props.theme.colors.white};
  color: ${props => props.theme.colors.lightGrey1};
  display: flex;
  font-style: italic;
  font-weight: 300;
  opacity: ${props => (props.disabled || props.readOnly ? 0.5 : 1)};
  position: relative;
  height: ${props => (props.height ? props.height : 40)}px;
  flex: 1 0 auto;
  padding: 0 1rem;
  max-width: 50%;
  white-space: nowrap;
  min-width: 46px;
  text-align: center;
  > div {
    padding: 0 2px; /*for symbol 2 in m² */
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
