import request from 'axios';
import { observable, action, reaction, computed } from 'mobx';

import getCookie from 'utils/getCookie';
import setCookie from 'utils/setCookie';
import redirectToExternalUrl from 'utils/redirectToExternalUrl';
import history from 'utils/history';

import { initAnalytics, callReset } from 'utils/segmentIntegration';

// if using Server Side Rendering, do this once per request.
class RootStore {
  @observable token;
  @observable userLocation;
  @observable loadingGlobalData;
  @observable loadingLocation;
  @observable apiURL;
  @observable assetsURL;
  @observable appConnectMode;
  @observable sessionExpired;
  @observable sendbirdApplicationId;
  @observable sendbirdApiToken;

  constructor(fixtureData) {
    this.loadingGlobalData = true;
    this.loadingLocation = false;

    this.appConnectMode = false;
    this.sessionExpired = false;

    // Global Settings
    this.appConfig = window.appConfig = window.appConfig || {};

    this.microServiceConfig = {
      toolbox: this.appConfig.safety_api_url,
      performanceTracking: this.appConfig.tracking_api_url,
      integrations: this.appConfig.integrations_api_url,
      hydra: this.appConfig.hydra_api_url,
      dashboard: this.appConfig.dashboard_api_url,
      postman: 'https://552cb8db-9df5-464f-89e7-c1fdf23b25ea.mock.pstmn.io',
      fakeBackend: 'http://localhost:3001',
      signoff:
        this.appConfig.signoffConfig && this.appConfig.signoffConfig.api_url
    };

    this.apiURL = window.appConfig.api_url || '';

    if (process.env.NODE_ENV === 'development') {
      this.assetsURL = 'http://localhost:3000';
    } else {
      this.assetsURL =
        window.appConfig.redesign_cdn_url ||
        'http://cdn.rakenapp.com/ui/production';
    }

    // fixtureData is used when mocking the store for tests
    if (fixtureData) {
      this.featureFlags = {
        ...fixtureData.featureFlags
      };
    } else {
      const { feature_flags } = this.appConfig;
      // Set the global feature flags reference
      this.featureFlags = feature_flags || {};

      this.sendbirdApplicationId = this.appConfig.sendbirdApplicationId;
      this.sendbirdApiToken = this.appConfig.sendbirdApiToken;
      this.apryseKey = this.appConfig.apryseKey;
      this.recurlyPublicKey = this.appConfig.recurly_public_key;
    }

    /**
     * This gets rendered by the Java Application in a script tag and tells us if we are a Super Admin
     */
    this.isSuperAdmin = window.appConfig.isSuperAdmin;
    this.isSudoing = window.appConfig.isSudoing;
    this.showDebugSection = this.isSudoing || window.appConfig.env !== 'prd';

    this.getToken();

    this.userLocation = localStorage.getItem('userLocation')
      ? JSON.parse(localStorage.getItem('userLocation'))
      : null;

    this.instantiateCollections(fixtureData);
    this.instantiateUIStores();

    if (!fixtureData) {
      this.setupReactions();
    }

    this.authStatus = false;

    initAnalytics(this);
  }

  @action.bound configureRecurly() {
    window.recurly?.configure(this.recurlyPublicKey);
  }

  @action.bound
  stopImpersonating() {
    redirectToExternalUrl(`/admin/sudo/exit`);
  }

  urlMicroService = key => {
    return this.microServiceConfig[key];
  };

  @action.bound
  setupReactions() {
    this.reactToToken = reaction(
      () => this.token,
      token => {
        if (token) {
          request.defaults.baseURL = this.apiURL;
          request.defaults.maxRedirects = 0;
          request.defaults.withCredentials = true;
          request.defaults.crossDomain = true;
          request.defaults.headers.common.Authentication = `Bearer ${token}`;

          if (this.featureFlags.FF_EQUIPMENT_ITERATIONS) {
            /**
             * TODO we need to send this header to the BE so it knows to handle
             * the new equipment changes.
             */
            request.defaults.headers.common['x-equipment-version'] = '2';
          }

          request.interceptors.request.use(config => {
            // Allow us to send strings as json requests and stop the browser thinking it's formdata.
            if (config.method === 'post' && typeof config.data === 'string') {
              config.headers = Object.assign(
                {
                  'Content-Type': 'application/json'
                },
                config.headers
              );
            }

            // Sendbird
            if (config.url.includes('sendbird')) {
              delete config.headers.common.Authentication;
              delete config.withCredentials;
            }

            // added header `x-api-key` for gateway
            for (let key in this.microServiceConfig) {
              if (config.url.indexOf(this.microServiceConfig[key]) !== -1) {
                config.withCredentials = false;

                if (
                  this.microServiceConfig[key] &&
                  this.microServiceConfig[key].indexOf('mock.pstmn.io') !== -1
                ) {
                  config.transformRequest[0] = (data, headers) => {
                    delete headers.common.Authentication;
                    return data;
                  };
                } else {
                  config.headers = Object.assign(
                    {
                      'x-api-key': this.appConfig.safety_api_key
                    },
                    config.headers
                  );
                }
              }
            }

            return config;
          });
          request.interceptors.response.use(
            response => {
              if (response.headers['x-updated-token']) {
                this.token = response.headers['x-updated-token'];
                setCookie('ra_api_token', this.token);
              }
              return Promise.resolve(response);
            },
            error => {
              if (error.response && error.response.status === 401) {
                this.setSessionExpired();
              } else if (
                error.response?.status === 302 ||
                error.response?.status === 502 ||
                error.response?.status === 503
              ) {
                window.location.href = 'https://maintenance.rakenapp.com';
              }

              return Promise.reject(error);
            }
          );

          this.fetchGlobalData();
          this.getUserLocation();
        } else {
          this.getNewToken();
        }
      },
      { fireImmediately: true }
    );
  }

  @action.bound setSessionExpired() {
    callReset();
    setCookie('sign_in', '', '0');

    this.loadingGlobalData = false;
    this.sessionExpired = true;

    history.push('/session-expired');
  }

  @action.bound
  getNewToken() {
    if (!this.authStatus) {
      request
        .get('/auth/jwt', {
          transformRequest: [
            (data, headers) => {
              delete headers.common.Authentication;
              return data;
            }
          ]
        })
        .then(res => {
          this.token = res.data.jwt;

          setCookie('ra_api_token', this.token);

          this.authStatus = false;
        })
        .catch(() => {
          this.logout();
        });
    }

    this.authStatus = true;
  }

  @action.bound
  getToken() {
    const cookie = getCookie('ra_api_token');

    if (cookie) {
      this.token = cookie;
    } else if (window.location.hostname === 'localhost') {
      this.token =
        'lA5LQ4sIXnbKjtrWnZQ3VpfyZ4Q9ltmBeKztVTp8mZVDYYjqeYn6PjD7Gi58PTBHBoEnCaf4N3wI4n8do/E31WVHvCF/Rsf7klJGLEVHDQWIkliCw4ieRkuX99GXGURsCakuVSqU0QwxxIjKoRiYuZmlVThW8B3sMjx/XikrEGCM8D533B7uMie/F2U8nkrw59S3F9l9ETmXkWIbfhl2huFZTOBjuHovNn1Ur4ONPmLoeMv2XFifZn3dsMPrrPonT2HkuN7LzOeyKwTcwDGczhxbh/uKQh7O+Xbn/RajpJbXoxtQAyiZUX51JnpgeMaOsgZlqcLPLf+qV+fJcQeSvaPgtjYv16/iFvOviBPZmnEGvrtfRuLMiFIaHsWMpog8Jjnwx3Lm3tFZ/UsCat4JQGnRYIU/ygvkh4rvS9ziL3i0PYxFri3v2U+z3F9PE9WAHFl71CxW+T2wH85WrqumyDpSqbrImxMiQRJPP9o+qFXwc6Ssx6GRxkq75eYnwmvaANiloAxgYnNvKsBIh6O0VBZmSmCjh4vF1+Qfj5UuxU+SWHbYOSApNQ==';
    } else {
      this.token = null;
    }
  }

  @action.bound
  getUserLocation() {
    if (this.userLocation || history.location.pathname.includes('/mobile')) {
      return;
    }

    this.loadingLocation = true;

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          this.loadingLocation = false;
          this.userLocation = {
            accuracy: position.coords.accuracy,
            geolocation: {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            }
          };

          localStorage.setItem(
            'userLocation',
            JSON.stringify(this.userLocation)
          );
        },
        error => {
          this.loadingLocation = false;
        }
      );
    } else {
      this.loadingLocation = false;
    }
  }

  @action.bound
  logout(token) {
    callReset();
    setCookie('sign_in', '', '0');
    window.location = '/logout';
  }

  /**
   * Used to display popups for app connection
   */
  @action.bound setAppConnectMode(value) {
    this.appConnectMode = value;
  }

  @computed get hideMainNavigation() {
    return this.appConnectMode || this.sessionExpired;
  }

  @computed get loading() {
    return this.loadingGlobalData;
  }
}

export { RootStore };
export default RootStore;
