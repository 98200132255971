import styled, { css } from 'styled-components';
import { inputCss } from './utils/inputStyles';

export default styled.select`
  ${inputCss};
  appearance: none;
  text-indent: 1px;
  text-overflow: '';
  text-transform: ${props => props.textTransform || 'capitalize'};
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
  &::-ms-expand {
    display: none;
  }
  ${props =>
    !props.showDefaultValue &&
    css`
      option[default] {
        display: none;
      }
      option[value=''] {
        display: none;
      }
    `}

  option {
    text-transform: none;
  }
`;
