import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import trimObject from 'utils/trimObject';
import moment from 'moment';
import { t } from 'utils/translate';

const incidentExportFormPlugins = extendedPlugins;

const incidentExportFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const incidentExportFormFields = [
  'emails[]',
  'email',
  'year',
  'exportType',
  'skipIncompleteRecordValidation'
];
const incidentExportFormLabels = {
  year: t('year'),
  emails: t('emails'),
  exportType: t('export type')
};

const incidentExportFormRules = {
  emails: 'array|required',
  email: 'required_without:emails|email',
  exportType:
    'string|required|in:INCIDENT_REPORT_301,INCIDENT_REPORT_300,INCIDENT_REPORT_300A,INCIDENT_SUBMISSION_300_301,INCIDENT_SUBMISSION_300A',
  skipIncompleteRecordValidation: 'boolean',
  year: 'string|required'
};

const incidentExportFormValues = {
  emails: [],
  year: moment()
    .year()
    .toString(),
  exportType: 'INCIDENT_REPORT_301',
  skipIncompleteRecordValidation: false
};

class IncidentExportForm extends MobxReactForm {
  trimmedValues() {
    return trimObject(this.values());
  }
}

export {
  IncidentExportForm,
  incidentExportFormOptions,
  incidentExportFormFields,
  incidentExportFormRules,
  incidentExportFormPlugins,
  incidentExportFormValues,
  incidentExportFormLabels
};
