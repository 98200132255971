import orderBy from 'lodash.orderby';
import getDescendantProp from './getDescendantProp';

export default (collection, value) => {
  let rankedEntries = [];

  const orderedEntries = orderBy(collection, [value], ['desc']);

  orderedEntries.forEach((entry, index) => {
    let ranking;

    if (index < 1) {
      ranking = index + 1;
    } else if (
      getDescendantProp(orderedEntries[index - 1], value) ===
      getDescendantProp(orderedEntries[index], value)
    ) {
      ranking = orderedEntries[index - 1].ranking;
    } else if (orderedEntries[index - 1].ranking) {
      ranking = orderedEntries[index - 1].ranking + 1;
    } else {
      ranking = index + 1;
    }

    rankedEntries.push(
      Object.assign(entry, {
        ranking: ranking
      })
    );
  });

  return rankedEntries;
};
