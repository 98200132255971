import { action, computed, observable, reaction } from 'mobx';

import ProjectChildUI from './ProjectChildUI';
import Observation from '../../models/Observation';

import history from 'utils/history';

import { t } from 'utils/translate';
import Attachment from '../../models/Attachment';
import getFilePreviewIcon from 'utils/getFilePreviewIcon';
import fileToBase64 from 'utils/fileToBase64';
import bytesToSize from 'utils/bytesToSize';

import alertErrorHandler from 'utils/alertErrorHandler';

import moment from 'moment-timezone';

import { callTrack } from 'utils/segmentIntegration';
import {
  RESOLUTION_PHOTO_ADDED,
  RESOLUTION_PHOTO_DELETED
} from 'utils/segmentAnalytics/eventSpec';

export default class ObservationViewUI extends ProjectChildUI {
  @observable entryForView;
  @observable showPreviousNext;
  @observable assigneeForUpload;
  @observable openDeleteAttachmentModal;
  @observable attachmentToDelete;
  @observable observationImageToViewKey;
  @observable pdfDownload;

  constructor(options) {
    super(options);

    this.entryForView = null;
    this.showPreviousNext = false;
    this.assigneeForUpload = null;
    this.openDeleteAttachmentModal = false;
    this.attachmentToDelete = null;
    this.observationImageToViewKey = 0;

    this.pdfDownload;
  }

  @action.bound setup(uuid) {
    window.scrollTo(0, 0);
    this.fetchEntry(uuid);
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.entryForView = null;
  }

  @action.bound async fetchEntry(uuid) {
    if (this.entryForView) return;

    let model = this.observations.getByIdOrUuid(uuid);

    if (!model) {
      model = new Observation(
        {
          type: 'Observation',
          uuid: uuid
        },
        {
          rootStore: this.rootStore
        }
      );
    }

    try {
      await model.fetch({
        url: `${this.rootStore.urlMicroService('hydra')}/companies/${
          this.rootStore.me.company.uuid
        }/observations/${model.uuid}`
      });

      this.setEntryForView(model);
    } catch (error) {
      this.cancelObservationView();
    }
  }

  @action.bound async setEntryForView(model) {
    this.parent.parent.setDate(model.reportDate);
    this.projectUI.setSegmentUuid(model.segmentUuid);
    this.entryForView = model;
  }

  @action.bound cancelObservationView() {
    history.push({
      pathname: `${this.project.viewUrl}/observations`,
      search: this.baseQueryParams
    });
  }

  @computed get observationStatuses() {
    return this.parent.observationStatuses;
  }

  @computed get observationStatusOptions() {
    return this.parent.observationStatusOptions;
  }

  @computed get observations() {
    return this.parent.observations;
  }

  @action.bound viewPreviousObservation() {
    const prevKey = this.entryForViewKey - 1;
    if (prevKey >= 0) {
      this.clearUIState();
      this.fetchEntry(this.observations.models[prevKey].uuid);
    } else {
      reaction(
        () => this.observations.fetching,
        (params, reaction) => {
          if (!params) {
            this.clearUIState();
            reaction.dispose();
            this.fetchEntry(
              this.observations.models[this.parent.pageSize - 1].uuid
            );
          }
        }
      );

      this.parent.setPage(null, this.parent.page - 1);
    }
  }

  @action.bound viewNextObservation() {
    const nextKey = this.entryForViewKey + 1;
    if (nextKey < this.parent.pageSize) {
      this.clearUIState();
      this.fetchEntry(this.observations.models[nextKey].uuid);
    } else {
      reaction(
        () => this.observations.fetching,
        (params, reaction) => {
          if (!params) {
            this.clearUIState();
            reaction.dispose();
            this.fetchEntry(this.observations.models[0].uuid);
          }
        }
      );

      this.parent.setPage(null, this.parent.page + 1);
    }
  }

  @computed get entryForViewKey() {
    return this.observations.models.findIndex(
      model => model.uuid === this.entryForView?.uuid
    );
  }

  @computed get previousObservationDisabled() {
    return this.observations.offset === 0 && this.entryForViewKey === 0;
  }

  @computed get nextObservationDisabled() {
    return (
      this.observations.offset + this.entryForViewKey + 1 ===
      this.observations.totalElements
    );
  }

  @action.bound async confirmDeleteObservation() {
    await this.parent.confirmDeleteObservation();
    this.cancelObservationView();
  }

  @action.bound openUploadModal(assignee) {
    this.assigneeForUpload = assignee;
    this.activeModal = 'AssigneeUpload';
  }

  @action.bound closeAssigneeUploadModal() {
    if (this.assigneeForUpload?.hasAttachmentsUploading) {
      return null;
    }

    this.assigneeForUpload = null;
    this.hideActiveModal();
  }

  /**
   * Attachments
   */

  @action.bound async uploadAttachment(uploadItem) {
    const file = uploadItem.file;

    if (file.size > 15728640) {
      this.rootStore.notificationsUI.pushNotification({
        title: `${t('File is too big ')} (${bytesToSize(file.size)}). ${t(
          'Limit is 60MB.'
        )}`,
        snackbar: 'error',
        icon: 'notification'
      });

      return;
    }

    file.preview = await fileToBase64(file);

    const filePreviewIcon = getFilePreviewIcon(this.rootStore.assetsURL, file);

    const previewAttachment = new Attachment(
      {
        thumbUrl: filePreviewIcon
      },
      {
        rootStore: this.rootStore
      }
    );

    if (
      this.assigneeForUpload?.hasAttachments &&
      this.assigneeForUpload.attachments?.models.length + 1 > 3
    )
      return;

    this.assigneeForUpload.attachments.add(previewAttachment);

    try {
      await this.assigneeForUpload.attachments.uploadVersionTwo({
        file: file,
        analytics: {
          module: 'Attachments',
          project: {
            id: this.project.uuid,
            name: this.project.name
          }
        },
        previewAttachment: previewAttachment,
        progressCallback: percentCompleted => {
          // If we wanted to show a progress bar on the attachment
          // we can use previewAttachment.uploadProgress.
          previewAttachment.setUploadProgress(percentCompleted);
        }
      });

      callTrack(RESOLUTION_PHOTO_ADDED, {
        file_name: file.name
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      // Remove the preview model now that the new one is ready
      this.assigneeForUpload.attachments.remove(previewAttachment);
    }
  }

  @action.bound rejectFileType(fileName) {
    const extension = fileName.split('.').pop();

    this.rootStore.notificationsUI.pushNotification({
      title: `File of type .${extension} is not supported.`,
      snackbar: 'error',
      icon: 'notification'
    });
  }

  @action.bound
  deleteAttachment(attachment) {
    this.openDeleteAttachmentModal = true;
    this.attachmentToDelete = attachment;
  }

  @action.bound
  cancelDeleteAttachmentModal() {
    this.openDeleteAttachmentModal = false;
    this.attachmentToDelete = null;
  }

  @action.bound
  async confirmDeleteAttachment() {
    const filename = this.attachmentToDelete.fileName;

    await this.attachmentToDelete.confirmDeleteAttachment(
      this.attachmentToDelete
    );

    this.cancelDeleteAttachmentModal();

    callTrack(RESOLUTION_PHOTO_DELETED, {
      file_name: filename
    });

    this.rootStore.notificationsUI.pushNotification({
      title: `File deleted.`,
      snackbar: 'warning',
      icon: 'notification'
    });
  }

  @computed get observationImageToView() {
    return this.entryForView.observationImages[this.observationImageToViewKey];
  }

  @action.bound
  updateImageToView(updatedImage) {
    const key = this.entryForView.observationImages.findIndex(
      image => image === updatedImage
    );

    this.observationImageToViewKey = key;
  }

  @computed get createdByImageToViewFormatted() {
    return t(
      `Created by ${
        this.observationImageToView.createdBy?.fullName
      }  on ${moment(this.observationImageToView.createdTimestamp)
        .tz(this.observationImageToView.timezone)
        .format('D MMM YYYY [at] h:mmA')}`
    );
  }

  @action.bound setPdfDownload() {
    this.pdfDownload = true;
  }

  @action.bound clearPdfDownload(value) {
    this.pdfDownload = null;
  }

  @computed
  get viewActionButtonOptions() {
    if (!this.entryForView?.canEditDeleteObservation) {
      return [
        {
          title: t('Download'),
          onClick: () => {
            this.setPdfDownload();
          }
        }
      ];
    }

    return [
      {
        title: t('Download'),
        onClick: () => {
          this.setPdfDownload();
        }
      },
      {
        title: t('Edit'),
        onClick: () => {
          this.parent.editObservation(this.entryForView);
        }
      },
      {
        title: t('Delete'),
        onClick: () => {
          this.parent.deleteObservation(this.entryForView);
        }
      }
    ];
  }
}
