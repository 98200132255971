import React from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

import Tab from './Tab';

const PathNavLink = props => <NavLink {...props} />;

const TabLink = Tab.withComponent(PathNavLink);

export default styled(TabLink)`
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}

  &.active {
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.rakenOrange};
    font-weight: 700;
  }
`;
