import capitalize from 'lodash.capitalize';
import moment from 'moment';
import { computed } from 'mobx';
import { Model } from 'mobx-mc';
import formatCurrency from 'utils/formatCurrency';

export default class Invoice extends Model {
  get restAttributes() {
    return [
      'id',
      'type',
      'state',
      'number',
      'collectionMethod',
      'currency',
      'balance',
      'paid',
      'total',
      'subtotal',
      'discount',
      'tax',
      'createdAt',
      'updatedAt',
      'dueAt',
      'closedAt',
      'externalLink',
      'hostedLink',
      'origin'
    ];
  }

  @computed get createdDate() {
    return moment(this.createdAt).format('Do MMMM YYYY');
  }

  @computed get dueDate() {
    return moment(this.dueAt).format('Do MMMM YYYY');
  }

  @computed get status() {
    return capitalize(this.state.toLowerCase().replace(/_/gi, ' '));
  }

  @computed get statusColor() {
    switch (this.state) {
      case 'PAST_DUE':
      case 'FAILED':
        return 'red';
      case 'CLOSED':
        return 'grey';
      default:
        return 'green';
    }
  }

  @computed get balanceFormatted() {
    return formatCurrency(this.balance);
  }

  @computed get totalFormatted() {
    return formatCurrency(this.total);
  }

  @computed get allowCapturePayment() {
    return (
      this.collectionMethod === 'AUTOMATIC' &&
      (this.state === 'PENDING' || this.state === 'PAST_DUE')
    );
  }
}
