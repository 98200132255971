import Moment from 'moment';
import { extendMoment } from 'moment-range';

import { action, computed, observable, reaction } from 'mobx';
import UIStore from '../../UIStore';
import history from 'utils/history';
import {
  checklistScheduleFormFields,
  checklistScheduleFormOptions,
  checklistScheduleFormPlugins,
  checklistScheduleFormRules,
  checklistScheduleFormValues,
  checklistScheduleFormLabels,
  ChecklistScheduleForm
} from 'forms/checklistSchedule';
import ChecklistSchedule from 'stores/models/checklists/ChecklistSchedule';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';
import formatChecklistDateToUTC from 'utils/formatChecklistDateToUTC';

import { callTrack } from 'utils/segmentIntegration';
import { CHECKLIST_SCHEDULE_ADDED } from 'utils/segmentAnalytics/eventSpec';

const moment = extendMoment(Moment);
export default class ChecklistScheduleAddUI extends UIStore {
  @observable entryAddForm;

  constructor(options) {
    super(options);
    this.entryAddForm = null;
  }

  @action.bound setup() {
    this.setupReactions();
    this.setupForm();
    this.projectSelectorUI.setup({
      projectStates: ['ACTIVE'],
      projectTypes: ['PARENT']
    });
  }

  setupReactions() {
    this.reactToProjectUuid = reaction(
      () => this.parent.projectUuid,
      projectUuid => {
        if (projectUuid) {
          this.projectLocationSelectorUI.setup(projectUuid);
        }
      }
    );
  }

  tearDownReactions() {
    this.reactToProjectUuid && this.reactToProjectUuid();
    this.projectSelectorUI.resetProjectOptions();
  }

  @action.bound setupForm() {
    this.entryForAdd = new ChecklistSchedule(
      {},
      {
        rootStore: this.rootStore
      }
    );

    this.entryAddForm = new ChecklistScheduleForm(
      {
        fields: checklistScheduleFormFields,
        rules: checklistScheduleFormRules,
        labels: checklistScheduleFormLabels,
        values: checklistScheduleFormValues
      },
      {
        options: checklistScheduleFormOptions,
        plugins: checklistScheduleFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound tearDown() {
    this.projectSelectorUI.tearDown();
    this.projectLocationSelectorUI.tearDown();
    this.tearDownReactions();
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.parent.projectUuid = null;
    this.activeModal = null;
    this.entryForAdd = null;
    this.entryAddForm = null;
    this.saving = false;
  }

  @computed
  get checklistTemplates() {
    return this.rootStore.checklistTemplates;
  }

  @computed get checklistTemplateOptions() {
    return this.checklistTemplates.models.map(template => ({
      name: template.name,
      id: template.uuid,
      type: template.typeString
    }));
  }

  @computed get selectedChecklistTemplateOption() {
    return this.checklistTemplateOptions?.find(
      option => option.id === this.entryAddForm.$('checklistTemplateUuid').value
    );
  }

  @computed get times() {
    return Array.from(
      moment.range(moment().startOf('day'), moment().endOf('day')).by('hour')
    ).map(time => {
      return {
        value: time.format('HH:mm'),
        name: time.format('h:mm A')
      };
    });
  }

  @computed get frequencyOptions() {
    return [
      {
        value: 'ONE_OFF',
        name: t('One-off')
      },
      {
        value: 'DAILY',
        name: t('Daily (Monday-Friday)')
      },
      {
        value: 'WEEKLY',
        name: t('Weekly')
      },
      {
        value: 'BI_WEEKLY',
        name: t('Bi weekly')
      },
      {
        value: 'MONTHLY',
        name: t('Monthly')
      }
    ];
  }

  @computed get displayBeforeOptions() {
    return [
      {
        value: '1-HOUR',
        name: t('One hour before due')
      },
      {
        value: '1-DAY',
        name: t('One day before due')
      },
      {
        value: '2-DAY',
        name: t('Two days before due')
      },
      {
        value: '3-DAY',
        name: t('Three days before due')
      },
      {
        value: '4-DAY',
        name: t('Four days before due')
      },
      {
        value: '5-DAY',
        name: t('Five days before due')
      },
      {
        value: '6-DAY',
        name: t('Six days before due')
      },
      {
        value: '7-DAY',
        name: t('Seven days before due')
      }
    ];
  }

  @computed get hasWriteAccess() {
    return this.parent.hasWriteAccess;
  }

  @computed get enableClassificationSelector() {
    return this.entryAddForm.$('projectType').value === 'MY_TEAM';
  }

  @computed get disableFrequency() {
    return moment(this.entryAddForm.$('startDate').value).isSame(
      moment(this.entryAddForm.$('endDate').value)
    );
  }

  @computed get selectedFrequency() {
    return this.disableFrequency
      ? {
          value: 'ONE_OFF',
          name: t('One-off')
        }
      : this.frequencyOptions.find(
          option =>
            option.value === this.entryAddForm.$('scheduleFrequency').value
        );
  }

  @computed get disableDisplayBefore() {
    return ['ONE_OFF', 'DAILY'].includes(this.selectedFrequency?.value);
  }

  @computed get selectedDisplayBefore() {
    return this.disableDisplayBefore
      ? {
          value: '1-HOUR',
          name: t('One hour before due')
        }
      : this.displayBeforeOptions.find(
          option => option.value === this.entryAddForm.$('displayBefore').value
        );
  }

  @computed get showCreateMessage() {
    const diffFromToday = moment(
      this.entryAddForm.$('startDate').value,
      'YYYY-MM-DD'
    ).diff(moment().format('YYYY-MM-DD'), 'days');
    const selectedDisplayBeforeIndex = this.displayBeforeOptions.findIndex(
      option => option.value === this.selectedDisplayBefore?.value
    );
    return diffFromToday <= selectedDisplayBeforeIndex;
  }

  @action.bound
  async submitChecklistScheduleAddSuccess() {
    this.saving = true;
    const {
      checklistTemplateUuid,
      location,
      responsibleWorkerClassification,
      responsibleChildProject,
      project,
      enableReminder,
      startDate,
      startTime,
      endDate,
      scheduleFrequency,
      displayBefore
    } = this.entryAddForm.trimmedValues();

    try {
      await this.entryForAdd.save(
        {
          projectUuid: project.uuid,
          checklistTemplateUuid,
          locationUuid: location?.uuid,
          responsibleWorkerClassificationUuid:
            responsibleWorkerClassification.uuid,
          responsibleChildProjectUuid: responsibleChildProject.uuid,
          enableReminder,
          startDate: formatChecklistDateToUTC(startDate, startTime).format(
            'YYYY-MM-DD'
          ),
          startTime: formatChecklistDateToUTC(startDate, startTime).format(
            'HH:mm'
          ),
          endDate: formatChecklistDateToUTC(endDate, startTime).format(
            'YYYY-MM-DD'
          ),
          scheduleFrequency: this.disableFrequency
            ? 'ONE_OFF'
            : scheduleFrequency,
          displayBefore: this.disableDisplayBefore ? '1-HOUR' : displayBefore
        },
        {
          wait: true
        }
      );

      callTrack(CHECKLIST_SCHEDULE_ADDED, {
        checklist_template_uuid: checklistTemplateUuid,
        location_uuid: location?.uuid,
        responsible_workerclassification_uuid:
          responsibleWorkerClassification.uuid,
        responsible_child_project_uuid: responsibleChildProject.uuid,
        project_uuid: 'project.uuid',
        start_date: moment(startDate).format('YYYY-MM-DD'),
        start_time: startTime,
        end_date: moment(endDate).format('YYYY-MM-DD'),
        schedule_frequency: scheduleFrequency,
        display_before: displayBefore
      });

      this.parent.sortByLastCreated();
      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Checklist schedule added')
      });
      this.cancelChecklistScheduleAdd();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  submitChecklistScheduleAdd(e) {
    e.preventDefault();
    e.stopPropagation();

    if (
      !this.entryAddForm.$('scheduleFrequency').value &&
      this.selectedFrequency
    ) {
      this.entryAddForm
        .$('scheduleFrequency')
        .set(this.selectedFrequency.value);
    }

    if (
      !this.entryAddForm.$('displayBefore').value &&
      this.selectedDisplayBefore
    ) {
      this.entryAddForm
        .$('displayBefore')
        .set(this.selectedDisplayBefore.value);
    }

    this.entryAddForm.submit({
      onSuccess: this.submitChecklistScheduleAddSuccess,
      onError: e => {
        console.error(this.entryAddForm.errors());
      }
    });
  }

  @action.bound
  cancelChecklistScheduleAdd() {
    history.push({
      pathname: `/company-settings/checklists/schedules`,
      search: this.baseQueryParams
    });
  }

  @action.bound async setProjectType(projectType) {
    this.entryAddForm.update({ projectType });
    const anyFieldHasError = Array.from(this.entryAddForm.fields.values()).some(
      field => field.error != null
    );

    if (anyFieldHasError) {
      await this.entryAddForm
        .$('responsibleWorkerClassification.uuid')
        .validate();
      await this.entryAddForm.$('responsibleChildProject.uuid').validate();
      this.entryAddForm.validate({ showErrors: true });
    }
  }

  @action.bound resetResponsible() {
    if (this.entryAddForm.$('responsibleWorkerClassification').value?.uuid) {
      this.entryAddForm.$('responsibleWorkerClassification').reset();
      this.entryAddForm.$('responsibleWorkerClassification').resetValidation();
    }
    if (this.entryAddForm.$('responsibleChildProject').value?.uuid) {
      this.entryAddForm.$('responsibleChildProject').reset();
      this.entryAddForm.$('responsibleChildProject').resetValidation();
    }
  }
}
