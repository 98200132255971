import styled from 'styled-components';

export default styled.h1`
  align-items: center;
  display: flex;
  justify-content: ${props =>
    props.headerAlignLeft ? 'left' : 'space-between'};
  cursor: pointer;
  font-size: 2rem;
  font-weight: 300;
  text-transform: capitalize;
  margin-top: 0;
  margin-bottom: ${props => props.marginBottom};
  padding-left: ${props =>
    props.hideIcon
      ? '0'
      : props.headerPaddingLeft
      ? props.headerPaddingLeft
      : '35px'};
  padding-bottom: 1px;
  position: relative;
  transform: translateZ(0);
`;
