import { computed } from 'mobx';
import ProjectChildUI from './ProjectChildUI';

import SafetyInsightsChecklistsUI from './SafetyInsightsChecklistsUI';
import SafetyInsightsObservationsUI from './SafetyInsightsObservationsUI';

import { t } from 'utils/translate';

export default class SafetyInsightsUI extends ProjectChildUI {
  constructor(options) {
    super(options);

    this.safetyInsightsChecklistsUI = new SafetyInsightsChecklistsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.safetyInsightsObservationsUI = new SafetyInsightsObservationsUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @computed get secondaryLinks() {
    return [
      {
        label: t('Safety'),
        path: {
          pathname: `${this.project.viewUrl}/safety-insights/checklists`,
          search: this.baseQueryParams
        }
      },
      {
        label: t('Quality control'),
        path: {
          pathname: `${this.project.viewUrl}/safety-insights/observations`,
          search: this.baseQueryParams
        }
      }
    ];
  }
}
