import { computed, action, observable, runInAction, reaction } from 'mobx';

import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import debounce from 'lodash.debounce';
import UIStore from './UIStore';

import TeamMembersActionsUI from './TeamMembersActionsUI';
import TeamMembersAddUI from './TeamMembersAddUI';
import TeamMemberProfileUI from './TeamMemberProfileUI';

import { BASE_DEBOUNCE } from 'fixtures/constants';

import { getMemberRoles } from 'utils/roles';

import {
  MembersFiltersForm,
  membersFiltersFormRules,
  membersFiltersFormFields,
  membersFiltersFormOptions,
  membersFiltersFormPlugins
} from 'forms/team/membersFilters';

export default class TeamMembersUI extends UIStore {
  @observable loading;

  @observable sortField;
  @observable sortDirection;

  @observable filterForm;
  @observable searchQuery;
  @observable pageSize;
  @observable page;

  constructor(options) {
    super(options);

    this.loading = true;

    this.searchQuery = '';
    this.pageSize = 20;
    this.page = 1;

    this.sortField = 'firstName';
    this.sortDirection = 'asc';

    this.filterForm = null;

    this.statusFilters = observable([
      { id: 'ACTIVE', title: 'Active' },
      { id: 'INVITED', title: 'Invited' }
    ]);

    this.roleFilters = observable([]);
    this.classificationFilters = observable([]);
    this.groupFilters = observable([]);

    this.fetchMembersDebounced = debounce(this.fetchMembers, BASE_DEBOUNCE);

    this.teamMembersActionsUI = new TeamMembersActionsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.teamMembersAddUI = new TeamMembersAddUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.teamMemberProfileUI = new TeamMemberProfileUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound setup() {
    this.fetchMembers();
    this.setupReactions();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  @computed get additionalSortFields() {
    if (this.sortField === 'lastName') {
      return 'firstName';
    } else if (this.sortField === 'firstName') {
      return `lastName`;
    }

    return 'firstName,lastName';
  }

  @computed get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      sortField: `${this.sortField},${this.additionalSortFields}`,
      sortDirection: this.sortDirection,
      query: this.searchQuery,
      status: this.statusFilters.map(option => option.id).join(','),
      role:
        this.roleFilters.length === 0
          ? 'ROLE_ACCOUNT_ADMIN,ROLE_ADMIN,ROLE_PROJECT_MEMBER,ROLE_USER'
          : this.roleFilters.map(option => option.value).join(','),
      classificationUuids: this.classificationFilters
        .map(option => option.uuid)
        .join(','),
      groupUuids: this.groupFilters.map(option => option.uuid).join(','),
      companyUuids: this.company?.uuid,
      withProjectMembershipLookup: 'false'
    };
  }

  setupReactions(listRef) {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchMembersDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @action.bound
  async fetchMembers() {
    this.workers.cancelRequest();
    this.members.cancelRequest();
    this.members.clear();

    await this.members.fetch({
      params: pickBy(this.params, identity)
    });

    this.loading = false;
  }

  @computed
  get disableUserRoleInput() {
    return !this.rootStore.me.isAdmin;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.members.totalElements / this.pageSize);
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound
  setSearchQuery(query) {
    this.page = 1;
    this.searchQuery = query;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @action sortByLastCreated() {
    this.sortField = 'createdTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchMembersDebounced();
  }

  @action.bound
  refetchSubscription() {
    // Refetch the subscription
    this.subscription.fetch({
      params: {
        mn: 'full',
        mc: 'full'
      }
    });
  }

  @computed get showEmptyState() {
    return (
      !this.loading &&
      !this.searchQuery &&
      this.hasDefaultFilters &&
      !this.hasMembers
    );
  }

  @computed get showEmptySearchState() {
    return (
      !this.loading &&
      (this.searchQuery || !this.hasDefaultFilters) &&
      !this.hasMembers
    );
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @action.bound showFilters() {
    this.showModal('MembersFiltersDialog');
    this.setupFiltering();
  }

  @action.bound async hideFilters() {
    await this.hideActiveModal();
    this.tearDownFiltering();
  }

  @action.bound clearFilters() {
    this.statusFilters.clear();
    this.roleFilters.clear();
    this.classificationFilters.clear();
    this.groupFilters.clear();
    this.hideFilters();
  }

  @action.bound async applyFilters(event) {
    event.preventDefault();

    const values = this.filterForm.values();

    this.statusFilters.replace(values.statusFilters);
    this.roleFilters.replace(values.roleFilters);
    this.classificationFilters.replace(values.classificationFilters);
    this.groupFilters.replace(values.groupFilters);

    this.hideFilters();
  }

  @computed get hasDefaultFilters() {
    return (
      this.statusFilters.map(option => option.id).join(',') ===
      'ACTIVE,INACTIVE'
    );
  }

  @computed get appliedFiltersCount() {
    let count = 0;

    if (this.statusFilters.length > 0) {
      count++;
    }

    if (this.roleFilters.length > 0) {
      count++;
    }

    if (this.classificationFilters.length > 0) {
      count++;
    }

    if (this.groupFilters.length > 0) {
      count++;
    }

    return count;
  }

  @computed get hasAppliedFilters() {
    return this.appliedFiltersCount > 0;
  }

  @action.bound setupFiltering() {
    this.classificationSelectorUI.setup();
    this.groupSelectorUI.setup();

    this.filterForm = new MembersFiltersForm(
      {
        fields: membersFiltersFormFields,
        rules: membersFiltersFormRules,
        values: {
          statusFilters: this.statusFilters.slice(),
          roleFilters: this.roleFilters.slice(),
          classificationFilters: this.classificationFilters.slice(),
          groupFilters: this.groupFilters.slice()
        }
      },
      {
        options: membersFiltersFormOptions,
        plugins: membersFiltersFormPlugins
      }
    );
  }

  @action.bound tearDownFiltering() {
    this.classificationSelectorUI.tearDown();
    this.groupSelectorUI.tearDown();
    this.filterForm = null;
  }

  @computed get statusFilterOptions() {
    return [
      { id: 'ACTIVE', title: 'Active' },
      { id: 'INVITED', title: 'Invited' },
      { id: 'INACTIVE', title: 'Inactive' },
      { id: 'DELETED', title: 'Deleted' }
    ];
  }

  @computed get roleFilterOptions() {
    return this.roleOptions;
  }

  @computed get roleOptions() {
    return getMemberRoles();
  }

  @action.bound clearUIState() {
    this.teamMembersActionsUI.clearUIState();
    this.clearValidationDetails();
    this.searchQuery = '';
    this.members.clear();
    this.page = 1;
    this.loading = true;
    this.saving = false;
    this.sortField = 'firstName';
    this.sortDirection = 'asc';
    this.filterForm = null;
    this.statusFilters = observable([
      { id: 'ACTIVE', title: 'Active' },
      { id: 'INVITED', title: 'Invited' }
    ]);
    this.roleFilters.clear();
    this.classificationFilters.clear();
    this.groupFilters.clear();
  }
}
