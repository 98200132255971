import { RakenCollection } from './../lib';
import PaymentMethod from './../models/PaymentMethod';
import { computed } from 'mobx';

export default class PaymentMethods extends RakenCollection {
  url() {
    if (this.rootStore.isSuperAdmin) {
      return `${this.parent.url()}/billing/credit-cards`;
    }

    return '/ra/billing/credit-cards';
  }

  model() {
    return PaymentMethod;
  }

  @computed
  get isEmpty() {
    return this.length === 0;
  }

  @computed
  get card() {
    return !this.isEmpty && this.at(0);
  }
}
