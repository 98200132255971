import React from 'react';
import { observer } from 'mobx-react';
import {
  Box,
  Text,
  Tabs,
  ModalPage,
  Grid,
  Container
} from '@raken/athens-web/lib';
import FeatureFlagsTable from './FeatureFlagsTable';
import { t } from 'utils/translate';
import styled from 'styled-components';

const StyledTab = styled(Tabs.Tab)`
  background-color: transparent;
`;

const DebugModalPage = observer(({ debugUI }) => (
  <ModalPage
    open={debugUI.activeModalPage === 'debugModalPage'}
    disableEnforceFocus
  >
    <ModalPage.Header
      onClose={debugUI.closeDebugModalPage}
      dataQA="debug-page-header"
    >
      <Text variant="h1">{t('Debug')}</Text>
    </ModalPage.Header>
    <Grid container justify="center">
      <Grid item xs={10} md={8}>
        <Box my={4}>
          <Tabs onChange={debugUI.switchTab}>
            <StyledTab
              staticRoute
              secondary
              label={t('Feature Flags')}
              active={debugUI.activeTab === 'featureFlags'}
              value="featureFlags"
            />
          </Tabs>
        </Box>
        <Container>
          {debugUI.activeTab === 'featureFlags' && (
            <FeatureFlagsTable debugUI={debugUI} />
          )}
        </Container>
      </Grid>
    </Grid>
  </ModalPage>
));

export default DebugModalPage;
