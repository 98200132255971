/**
 * Utils based on Mixpanel implementations
 * @see https://github.com/mixpanel/mixpanel-js/blob/master/src/utils.js
 */

/**
 * Operating System
 *
 * @param {String} ua - User Agent
 * @return {String}
 */
export const os = (ua = '') => {
  if (/Windows/i.test(ua)) {
    if (/Phone/.test(ua) || /WPDesktop/.test(ua)) {
      return 'Windows Phone';
    }
    return 'Windows';
  } else if (/(iPhone|iPad|iPod)/.test(ua)) {
    return 'iOS';
  } else if (/Android/.test(ua)) {
    return 'Android';
  } else if (/(BlackBerry|PlayBook|BB10)/i.test(ua)) {
    return 'BlackBerry';
  } else if (/Mac/i.test(ua)) {
    return 'Mac OS X';
  } else if (/Linux/.test(ua)) {
    return 'Linux';
  } else if (/CrOS/.test(ua)) {
    return 'Chrome OS';
  } else {
    return '';
  }
};

/**
 * Checks if Segment Analytics has the device integration or not.
 * Cloud integrations cannot be checked on the frontend.
 *
 * @param {String} name - Name of the integration name. Can be found in window.analytics.Itegrations.
 * @param {Object} winAnalytics - window.analytics Object (Used for unit testing)
 * @returns {Boolean}
 */
export const hasDeviceIntegration = (name, winAnalytics = window.analytics) => {
  if (winAnalytics) {
    return winAnalytics.Integrations.hasOwnProperty(name);
  }

  return false;
};

export default {
  os,
  hasDeviceIntegration
};
