import React from 'react';
import { observer } from 'mobx-react';
import PersonalInfoModal from './modals/PersonalInfoModal';
import CompanyInfoModal from './modals/CompanyInfoModal';
import SuperDailyModal from './modals/SuperDaily';

export default observer(({ uiStore }) => {
  return (
    <div>
      <PersonalInfoModal uiStore={uiStore} />

      <CompanyInfoModal uiStore={uiStore} />

      <SuperDailyModal uiStore={uiStore} />
    </div>
  );
});
