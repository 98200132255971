import { computed } from 'mobx';
import { Model } from 'mobx-mc';

export default class ProjectLocation extends Model {
  get restAttributes() {
    return ['uuid', 'name', 'parentUuid', 'position', 'fullPath', 'levels'];
  }

  idAttribute() {
    return 'uuid';
  }

  @computed get formValues() {
    return {
      parentUuid: this.parentUuid,
      name: this.name
    };
  }

  get urlRoot() {
    return `/ra/companies/${this.rootStore.me.company.uuid}/projects/${this.rootStore.projectUI.project.uuid}/locations`;
  }
}
