import { RakenCollection } from '../lib';
import MemberCertification from '../models/MemberCertification';

export default class MemberCertifications extends RakenCollection {
  url() {
    return `ra/companies/${this.rootStore.me.company.uuid}/members/${this.parent.parent.entryForEdit.workerUuid}/certifications`;
  }

  model() {
    return MemberCertification;
  }
}
