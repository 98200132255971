import request, { CancelToken } from 'axios';
import { Collection } from 'mobx-mc';
import { action, observable, computed, runInAction } from 'mobx';
import once from 'lodash.once';

class RakenCollection extends Collection {
  constructor(data, options) {
    super(data, options);
    this.fetchOnce = once(this.fetchOnce);
  }

  fetchOnce(options) {
    return this.fetch(options);
  }

  /**
   * Instantiate extensions
   */
  @action.bound
  instantiateAddOns() {
    this.paging = observable.map({});
    this.summary = observable.map({});
  }

  /**
   * Options for models
   */
  @computed
  get modelOptions() {
    return {
      collection: this,
      rootStore: this.rootStore
    };
  }

  parse(data) {
    return data.collection;
  }

  /**
   * Add options to an added model
   */

  @action.bound
  applyOptionsToModel(model) {
    if (!model.collection) {
      model.collection = this;
    }

    if (!model.rootStore && this.rootStore) {
      model.rootStore = this.rootStore;
    }

    return model;
  }

  /**
   * Sets the summary data into the collection.
   */
  @action.bound
  setSummary(summary = {}) {
    this.clearSummary();
    this.summary.replace(summary);
  }

  /**
   * Clears the summary object
   */
  @action.bound
  clearSummary() {
    this.summary.clear();
  }

  /**
   * Sets the links data into the collection.
   */
  @action.bound
  setPaging(paging = {}) {
    this.clearPaging();
    this.paging.replace(paging);
  }

  /**
   * Clears the paging object
   */
  @action.bound
  clearPaging() {
    this.paging.clear();
  }

  /**
   * Get a link with the given key
   */
  getPagingKey(key) {
    return this.paging.get(key);
  }

  /**
   * Shortcut to the next page
   */
  @computed
  get nextPage() {
    return this.getPagingKey('next');
  }

  /**
   * Shortcut to the previous page
   */
  @computed
  get previousPage() {
    return this.getPagingKey('previous');
  }

  /**
   * Shortcut to the offset
   */
  @computed
  get offset() {
    return this.getPagingKey('offset');
  }

  /**
   * Shortcut to the total elements
   */
  @computed
  get totalElements() {
    return this.getPagingKey('totalElements') || 0;
  }

  /**
   * Shortcut to the next offset
   */
  @computed
  get nextOffset() {
    return this.getPagingKey('nextOffset');
  }

  @action.bound
  fetchNextPage() {
    if (this.fetching || !this.hasNextPage) {
      return;
    }

    return this.fetch({
      ...this.currentFetchOptions,
      params: {
        ...this.currentFetchOptions.params,
        offset: this.paging.get('nextOffset')
      },
      add: true,
      update: true,
      remove: false
    });
  }

  @computed
  get hasNextPage() {
    return !!this.paging.get('nextOffset');
  }

  @action.bound
  clear() {
    super.clear();
    this.clearPaging();
    this.clearSummary(); // Should clear everthing including summary as well.
  }

  @action.bound
  set(data, options) {
    super.set(data, options);

    if (!Array.isArray(data)) {
      if (data.page) {
        this.setPaging(data.page);
      }

      if (data.summary) {
        this.setSummary(data.summary);
      }
    }
  }

  getByIdOrUuid(id) {
    return this.models.find(model => {
      return model.id === id || model.uuid === id;
    });
  }

  @action
  fetchWithPost(options = {}) {
    // Merge in the any options with the default
    options = Object.assign(
      {
        url: this.url(),
        params: {},
        add: true,
        merge: true,
        remove: true
      },
      options
    );

    this.currentFetchOptions = options;

    this.setRequestLabel('fetching', true);

    return new Promise((resolve, reject) => {
      // Optionally the request above could also be done as
      request
        .post(options.url, options.params, {
          cancelToken: new CancelToken(cancel => {
            // An executor function receives a cancel function as a parameter
            this.requestCanceller = cancel;
          })
        })
        .then(
          response => {
            runInAction('fetch-success', () => {
              this.set(response.data, {
                add: options.add,
                merge: options.merge,
                remove: options.remove,
                unshift: options.unshift
              });
              this.setRequestLabel('fetching', false);
              resolve(response);
            });
          },
          error => {
            runInAction('fetch-error', () => {
              this.setRequestLabel('fetching', false);
              reject(error);
            });
          }
        );
    });
  }
}

export default RakenCollection;
