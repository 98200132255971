import request from 'axios';
import { action, computed, observable, when } from 'mobx';
import { DOCUMENT } from 'stores/models/Attachment';
import DocumentsFile from 'stores/models/documents/DocumentsFile';
import alertErrorHandler from 'utils/alertErrorHandler';
import crossOriginDownload from 'utils/crossOriginDownload';

import history from 'utils/history';
import { DOCUMENTS_ANNOTATION_SAVED } from 'utils/segmentAnalytics/eventSpec';
import { callTrack } from 'utils/segmentIntegration';
import { t } from 'utils/translate';
import ProjectChildEditUI from '../ProjectChildEditUI';

export default class DocumentEditUI extends ProjectChildEditUI {
  @observable webViewerInstance;
  @observable loading;
  @observable documentIsLoaded;
  @observable documentIsDirty;
  @observable confirmDeleteModal;
  @observable isEdit;

  @observable previousPath;

  constructor(options) {
    super(options);
    this.webViewerInstance = null;
    this.loading = true;
    this.documentIsLoaded = false;
    this.documentIsDirty = false;
    this.confirmDeleteModal = false;
    this.isEdit = false;

    this.previousPath = null;
  }

  @computed get documentActionsUI() {
    return this.parent.documentActionsUI;
  }

  @computed get documentsConstants() {
    return this.parent.documentsConstants;
  }

  @computed get documentsSearch() {
    return this.parent.documentsSearch;
  }

  @computed get folder() {
    return this.parent.folder;
  }

  @action.bound setPreviousPath(location = null) {
    this.previousPath = location?.state?.previousPath;
  }

  @action.bound async fetchEntry(uuid) {
    let model = this.documentsSearch
      ? this.documentsSearch.getByIdOrUuid(uuid)
      : this.folder?.children.getByIdOrUuid(uuid);

    if (!model) {
      model = new DocumentsFile(
        {
          type: 'Document',
          uuid: uuid && uuid !== 'index' ? uuid : null
        },
        {
          rootStore: this.rootStore
        }
      );
    }

    try {
      await model.fetch();

      this.setEntryForEdit(model);
    } catch (error) {
      this.cancelDocumentEdit();
    }
  }

  @action.bound async setupWebViewer() {
    await when(() => this.webViewerInstance);

    try {
      const { UI, Core } = this.webViewerInstance;
      const { annotationManager, documentViewer } = Core;
      const { name, document } = this.entryForEdit;

      annotationManager.addEventListener(
        'annotationChanged',
        (annotations, action, { imported }) => {
          // imported indicates if the annotations were imported via a process, mainly XFDF
          if (!imported) {
            this.setDocumentIsDirty();
          }

          documentViewer.addEventListener('pagesUpdated', properties => {
            this.setDocumentIsDirty();
          });
        }
      );

      annotationManager.addEventListener('fieldChanged', () => {
        this.setDocumentIsDirty();
      });

      documentViewer.addEventListener('documentLoaded', () => {
        this.setDocumentIsLoaded();

        // Continuous layout for long docs
        UI.setLayoutMode(UI.LayoutMode.Continuous);

        documentViewer
          .getDocument()
          .addEventListener('officeDocumentEdited', properties => {
            this.setDocumentIsDirty();
          });
      });

      this.loading = false;

      UI.loadDocument(document.contentUrl, {
        filename: name,
        enableOfficeEditing: false
      });

      this.blockHistoryIfFormChanged();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound async setEntryForEdit(model) {
    this.entryForEdit = model;

    if (this.isDocument) {
      await this.setupWebViewer();
    } else {
      this.blockHistoryIfFormChanged();
      this.loading = false;
    }
  }

  @action.bound resetEntryForEdit() {
    try {
      const { UI } = this.webViewerInstance;

      this.clearDocumentIsLoaded();
      this.clearDocumentIsDirty();

      UI.loadDocument(this.entryForEdit.document.contentUrl);
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound blockHistoryIfFormChanged() {
    this.unblock = history.block((location, action) => {
      if (this.documentIsDirty) {
        this.showDiscardModal(location.pathname);
        return 'Blocked';
      }
    });
  }

  @action.bound async submitEntryEditForm(event) {
    event.preventDefault();

    this.saving = true;

    try {
      const { documentViewer, annotationManager } = await this.webViewerInstance
        .Core;

      const xfdfString = await annotationManager.exportAnnotations();

      const arrayBuffer = await documentViewer.getDocument().getFileData({
        flags: this.webViewerInstance.Core.SaveOptions.LINEARIZED,
        xfdfString: xfdfString
      });

      const formData = new FormData();
      formData.append('file', new Blob([new Uint8Array(arrayBuffer)]));

      const file = formData.get('file');

      const signedUrlResponse = await request.post(
        `${this.entryForEdit.url()}/request-replace-url`,
        {
          originalFileName: this.entryForEdit.name,
          lastModifiedDateTime: file.lastModifiedDate,
          contentType: this.entryForEdit.document.contentType,
          contentLength: file.size,
          withContentDisposition: true,
          attachmentType: 'DOCUMENT_STORAGE'
        }
      );

      if (signedUrlResponse.data) {
        await request.put(signedUrlResponse.data.url, file, {
          transformRequest: [
            (data, headers) => {
              headers.put[
                'Content-Type'
              ] = this.entryForEdit.document.contentType;

              Object.keys(signedUrlResponse.data.customHeaders).forEach(key => {
                headers.put[key] = signedUrlResponse.data.customHeaders[key];
              });

              return data;
            }
          ]
        });

        await request.patch(this.entryForEdit.url(), {
          s3ObjectKey: signedUrlResponse.data.key
        });

        this.unblockHistory();
        this.parent.sortByLastUpdated();
        this.cancelDocumentEdit();

        this.notifications.pushNotification({
          snackbar: 'warning',
          icon: 'checkmark',
          title: t('Document saved')
        });

        callTrack(DOCUMENTS_ANNOTATION_SAVED, {
          project_id: this.project.id,
          project_name: this.project.name
        });
      }
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound cancelDocumentEdit() {
    history.push({
      pathname: this.previousPath ? this.previousPath : this.folder.viewUrl,
      search: this.baseQueryParams
    });
  }

  @computed get hasWriteAccess() {
    return this.parent.hasWriteAccess;
  }

  @computed get currentMedia() {
    return this.entryForEdit?.document;
  }

  @computed get isDocument() {
    return this.currentMedia?.mediaType === DOCUMENT;
  }

  @computed get isPdf() {
    return this.currentMedia?.contentType === 'application/pdf';
  }

  @action.bound
  enableEdit() {
    this.isEdit = true;
  }

  @action.bound
  disableEdit() {
    this.isEdit = false;
    this.clearValidationDetails();
  }

  @action.bound
  handleDownload() {
    return new Promise((resolve, reject) => {
      if (this.currentMedia) {
        crossOriginDownload(
          `${this.currentMedia.contentUrl}?download`,
          this.currentMedia.fileName,
          () => resolve()
        );
      } else {
        reject();
      }
    });
  }

  @action.bound
  openConfirmDeleteModal() {
    this.confirmDeleteModal = true;
  }

  @action.bound
  closeConfirmDeleteModal() {
    this.confirmDeleteModal = false;
  }

  @action.bound
  async handleConfirmDelete() {
    try {
      await this.entryForEdit.destroy({ wait: true });

      this.parent.refetchDocuments();

      this.confirmDeleteModal = false;

      this.cancelDocumentEdit();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Document deleted')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @computed get title() {
    return this.entryForEdit?.name;
  }

  @action.bound setDocumentIsLoaded() {
    this.documentIsLoaded = true;
  }

  @action.bound clearDocumentIsLoaded() {
    this.documentIsLoaded = false;
  }

  @action.bound setDocumentIsDirty() {
    this.documentIsDirty = true;
  }

  @action.bound clearDocumentIsDirty() {
    this.documentIsDirty = false;
  }

  @action.bound setWebViewerInstance(instance) {
    this.webViewerInstance = instance;
  }

  @action.bound clearWebViewerInstance() {
    this.webViewerInstance = null;
    this.documentIsDirty = false;
    this.documentIsLoaded = false;
    this.loading = true;
  }

  @action.bound clearUIState() {
    super.clearUIState();
    this.webViewerInstance = null;
    this.documentIsDirty = false;
    this.documentIsLoaded = false;
    this.loading = true;
  }

  @computed get disabledEdit() {
    return Boolean(
      this.saving ||
        !this.documentIsLoaded ||
        !this.hasWriteAccess ||
        !this.isPdf
    );
  }
}
