import { Model } from 'mobx-mc';

export default class IntegrationsAccountingConfiguration extends Model {
  url() {
    return `${this.rootStore.urlMicroService('integrations')}/companies/${
      this.rootStore.me.company?.uuid
    }/integrations/accounting/configurations`;
  }

  get restAttributes() {
    return ['id', 'active', 'name', 'configurations'];
  }
}
