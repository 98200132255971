import MobxReactForm from 'mobx-react-form';
import moment from 'moment';

import extendedPlugins from '../utils/extendedPlugins';
import { t } from 'utils/translate';

const equipmentDeploymentFormPlugins = extendedPlugins;

const equipmentDeploymentFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const equipmentDeploymentFormFields = ['projectUuid', 'startDate', 'endDate'];

const equipmentDeploymentFormLabels = {
  projectUuid: t('deploy to'),
  startDate: t('start date'),
  endDate: t('end date')
};

const equipmentDeploymentFormRules = {
  projectUuid: 'required|string',
  startDate: 'required',
  endDate: 'afterCustom:startDate'
};

const equipmentDeploymentFormValues = {
  projectUuid: '',
  startDate: moment().format('YYYY-MM-DD'),
  endDate: ''
};

class EquipmentDeploymentForm extends MobxReactForm {}

export {
  EquipmentDeploymentForm,
  equipmentDeploymentFormOptions,
  equipmentDeploymentFormFields,
  equipmentDeploymentFormRules,
  equipmentDeploymentFormLabels,
  equipmentDeploymentFormPlugins,
  equipmentDeploymentFormValues
};
