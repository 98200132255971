import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CustomPicker } from 'react-color';
import ColorPickerPalette from './ColorPickerPalette';
import styled from 'styled-components';
import { t } from 'utils/translate';

const Wrapper = styled.div`
  position: relative;
  display: flex;
`;

const MainWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 390px;
  overflow: hidden;
`;

const SaturationBox = styled.div`
  position: relative;
  height: 95px;
  border-radius: 4px;
  overflow: hidden;
`;

const BoxRight = styled.div`
  padding-left: 1.5rem;
  width: 155px;
`;

const BoxTitle = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
  color: ${props => props.theme.colors.black};
  text-transform: capitalize;
  margin-bottom: 10px;
`;

const BoxColor = styled.div`
  display: flex;
  height: 40px;
  padding: 10px;
  border: 1px solid ${props => props.theme.colors.lightGrey3};
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 1.5rem;
`;

const BoxRect = styled.div`
  height: 18px;
  width: 18px;
  border-radius: 4px;
  background-color: ${props => props.color};
`;

const BoxColorText = styled.div`
  font-size: 1.4rem;
  color: #000;
  padding-left: 10px;
  text-transform: capitalize;
  line-height: 18px;
`;

const BoxShowSwatches = styled.div`
  color: ${props => props.theme.colors.charcoalGrey};
  font-size: 1.4rem;
  cursor: pointer;
`;

class ColorPicker extends Component {
  render() {
    const { onHide, ...restProps } = this.props;
    return (
      <Wrapper>
        <MainWrapper>
          <SaturationBox>
            <ColorPickerPalette {...restProps} />
          </SaturationBox>
        </MainWrapper>

        <BoxRight>
          <BoxTitle>{t('Hex Color')}</BoxTitle>

          <BoxColor>
            <BoxRect color={this.props.color} />
            <BoxColorText>{this.props.color.slice(1)}</BoxColorText>
          </BoxColor>
          <BoxShowSwatches onClick={this.props.onHide}>
            {t('Show Swatches')}
          </BoxShowSwatches>
        </BoxRight>
      </Wrapper>
    );
  }
}

const CustomColorPicker = CustomPicker(ColorPicker);

CustomColorPicker.propTypes = {
  color: PropTypes.string
};

CustomColorPicker.propTypes = {
  color: PropTypes.string
};

CustomColorPicker.defaultProps = {
  color: '#FFFFFF'
};

export default CustomColorPicker;
