import { computed, action } from 'mobx';

import ChecklistObservationAddUI from './checklists/ChecklistObservationAddUI';

import { callTrack } from 'utils/segmentIntegration';
import { OBSERVATION_ADDED } from 'utils/segmentAnalytics/eventSpec';

import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

export default class SurveyObservationAddUI extends ChecklistObservationAddUI {
  constructor(options) {
    super(options);
  }

  @computed get checklistObservationUI() {
    return this.parent.surveyObservationUI;
  }

  @action.bound async addObservation(questionModel, question) {
    await this.authorization.checkFeatureAccess('CreateObservations');

    this.attachObservation({
      questionModel,
      question
    });
  }

  @action.bound async submitEntryAddFormSuccess() {
    this.saving = true;

    try {
      const attachments = this.entryForAdd.attachments?.models.map(
        attachment => {
          return {
            uuid: attachment.uuid
          };
        }
      );

      const values = this.entryAddForm.values();

      const assignees = await this.checklistObservationUI.createOrEditTasksForAssignees(
        this.entryAddForm.assigneesValues,
        values.status.value
      );

      const payload = {
        projectUuid: this.project.uuid,
        attachments: attachments,
        entityStatus: this.saveAsDraft ? 'DRAFT' : 'ACTIVE',
        type: {
          uuid: values.subType
        },
        category: values.category,
        priority: values.priority?.value,
        status: {
          uuid: values.status?.value
        },
        assignees: assignees,
        location: { uuid: values.location?.uuid },
        description: values.description,
        emails: Array.isArray(values.emails)
          ? values.emails.map(member => member.email)
          : [],
        ...(this.signature && { signature: this.signature })
      };

      await this.entryForAdd.save(payload, {
        url: this.saveObservationUrl,
        wait: true,
        stripNonRest: false
      });

      this.questionModel.addObservation(this.entryForAdd.toJSON());

      callTrack(OBSERVATION_ADDED, {
        project_name: this.project.name,
        project_date: this.date,
        observation_category_name: this.entryForAdd.category,
        has_attachments: this.entryForAdd.hasAttachments
      });

      this.setDefaultObservationsType(values);

      this.hideObservation();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Observation created')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }
}
