import MobxReactForm from 'mobx-react-form';
import extendedPlugins from 'forms/utils/extendedPlugins';
import { t } from 'utils/translate';

const projectTemplateInfoFormPlugins = extendedPlugins;

const projectTemplateInfoFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false,
  retrieveOnlyEnabledFields: false
};

const projectTemplateInfoFormFields = ['isDefault', 'templateName'];

const projectTemplateInfoFormLabels = {
  templateName: t('template name'),
  isDefault: t('default template')
};

const projectTemplateInfoFormRules = {
  templateName: 'required|string',
  isDefault: 'boolean'
};

const projectTemplateInfoFormValues = {
  templateName: '',
  isDefault: false
};

class ProjectTemplateInfoForm extends MobxReactForm {}

export {
  ProjectTemplateInfoForm,
  projectTemplateInfoFormOptions,
  projectTemplateInfoFormFields,
  projectTemplateInfoFormRules,
  projectTemplateInfoFormValues,
  projectTemplateInfoFormPlugins,
  projectTemplateInfoFormLabels
};
