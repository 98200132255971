import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';
import { t } from 'utils/translate';

const trialFormPlugins = extendedPlugins;

const trialFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const trialFormFields = ['isTrial', 'trialEndDate'];

const trialFormRules = {
  isTrial: 'boolean|required_with:trialEndDate',
  trialEndDate: 'required_with:isTrial|dateCustom:YYYY-MM-DD|afterOrTodayDate'
};

const trialFormLabels = {
  isTrial: t('enable trial'),
  trialEndDate: t('trial end date')
};

class TrialForm extends MobxReactForm {}

export {
  TrialForm,
  trialFormOptions,
  trialFormFields,
  trialFormRules,
  trialFormLabels,
  trialFormPlugins
};
