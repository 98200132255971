import moment from 'moment';
import debounce from 'lodash.debounce';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import ProjectChildUI from './ProjectChildUI';

import Tasks from '../../collections/Tasks';
import MemberSelectorUI from '../MemberSelectorUI';
import TaskAddUI from './TaskAddUI';
import TaskEditUI from './TaskEditUI';

import history from 'utils/history';
import { BASE_DEBOUNCE } from 'fixtures/constants';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

import { callTrack } from 'utils/segmentIntegration';
import { TASK_COMPLETED } from 'utils/segmentAnalytics/eventSpec';

import {
  TasksFiltersForm,
  tasksFiltersFormRules,
  tasksFiltersFormFields,
  tasksFiltersFormOptions,
  tasksFiltersFormPlugins
} from 'forms/project/tasksFilters';

export default class TasksUI extends ProjectChildUI {
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;
  @observable selectedTask;

  constructor(options) {
    super(options);

    this.loading = true;

    this.selectedTask = null;

    this.sortField = 'assignee';
    this.sortDirection = 'asc';
    this.page = 1;
    this.searchQuery = '';
    this.pageSize = 20;

    this.assigneeFilters = observable([]);
    this.creatorFilters = observable([]);
    this.statusFilters = observable([
      { id: 'OPEN', title: 'Open' },
      { id: 'COMPLETED', title: 'Completed' }
    ]);

    // Notes collection
    this.tasks = new Tasks(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.taskAddUI = new TaskAddUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.taskEditUI = new TaskEditUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.assigneeSelectorUI = new MemberSelectorUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.creatorSelectorUI = new MemberSelectorUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchTasksDebounced = debounce(this.fetchTasks, BASE_DEBOUNCE);
  }

  @action.bound setup() {
    window.scrollTo(0, 0);

    this.setupReactions();
    this.fetchTasks();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchTasksDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed
  get params() {
    return {
      projectUuids: this.projectUuid,
      segmentUuid: this.segmentUuid,
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      sortField: this.sortField,
      sortDirection: this.sortDirection,
      status: this.statusFilterParams,
      assigneeUuids: this.assigneeFilters
        .map(assignee => assignee.uuid)
        .join(','),
      createdByUuids: this.creatorFilters.map(creator => creator.uuid).join(',')
    };
  }

  @computed get hasRequiredParams() {
    return this.params.projectUuids;
  }

  @action.bound async fetchTasks() {
    if (!this.hasRequiredParams) return;

    this.tasks.cancelRequest();
    this.tasks.clear();

    try {
      await this.tasks.fetch({
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasTasks() {
    return this.tasks.hasModels;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.tasks.totalElements / this.pageSize);
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.searchQuery = '';
    this.tasks.clear();
    this.page = 1;
    this.loading = true;
    this.saving = false;
    this.sortField = 'assignee';
    this.sortDirection = 'asc';
    this.selectedTask = null;
    // Filters
    this.assigneeFilters.clear();
    this.creatorFilters.clear();
    this.statusFilters.replace([
      { id: 'OPEN', title: 'Open' },
      { id: 'COMPLETED', title: 'Completed' }
    ]);
  }

  @computed get hasAssigneeOrCreatorFilters() {
    return this.assigneeFilters.length > 0 || this.creatorFilters.length > 0;
  }

  @computed get showEmptyState() {
    if (this.loading) return false;

    return (
      !this.searchQuery &&
      this.hasDefaultFilters &&
      !this.hasAssigneeOrCreatorFilters &&
      !this.hasTasks
    );
  }

  @computed get showEmptySearchState() {
    if (this.loading) return false;

    return (
      (this.searchQuery ||
        !this.hasDefaultFilters ||
        this.hasAssigneeOrCreatorFilters) &&
      !this.hasTasks
    );
  }

  @computed get hasDefaultFilters() {
    return this.statusFilterParams === 'OPEN,COMPLETED';
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @action.bound async addTask() {
    await this.authorization.checkFeatureAccess('CreateTasks');

    history.push({
      pathname: `${this.project.viewUrl}/tasks/add`,
      search: this.baseQueryParams
    });
  }

  @action.bound async editTask(task) {
    history.push({
      pathname: `${this.project.viewUrl}/tasks/${task.uuid}`,
      search: this.baseQueryParams
    });
  }

  @action.bound async deleteTask(task) {
    await this.authorization.checkFeatureAccess('DeleteTasks');

    this.selectedTask = task;
    this.showModal('DeleteModal');
  }

  @action.bound async cancelDeleteTask() {
    await this.hideActiveModal();

    this.selectedTask = null;
  }

  @action.bound async confirmDeleteTask() {
    this.saving = true;

    try {
      await this.selectedTask.destroy({ wait: true });

      this.refetchTasks();

      await this.hideActiveModal();

      this.selectedTask = null;

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Task deleted')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound sortByLastCreated() {
    this.sortField = 'createdTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchTasksDebounced();
    this.refetchReportSummary();
  }

  @action.bound refetchTasks() {
    this.loading = true;
    if (!this.hasTasks) {
      this.setPage(null, 1);
      this.fetchTasks();
    } else {
      this.fetchTasks();
    }

    this.refetchReportSummary();
  }

  @action.bound toggleTaskStatus(task) {
    let newStatus = task.status === 'OPEN' ? 'COMPLETED' : 'OPEN';

    try {
      task.save(
        {
          status: newStatus
        },
        {
          parse: false
        }
      );

      if (newStatus === 'COMPLETED') {
        this.rootStore.notificationsUI.pushNotification({
          snackbar: 'warning',
          icon: 'notification',
          title: t('Task completed')
        });

        callTrack(TASK_COMPLETED, {
          complete_date: moment().format('l'),
          project_id: this.project.id,
          project_name: this.project.name,
          task_name: task.desc
        });
      } else {
        this.rootStore.notificationsUI.pushNotification({
          snackbar: 'warning',
          icon: 'notification',
          title: t('Task reopened')
        });
      }
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  getTaskActions = task => {
    let actions = [
      {
        // Task has some further permissions. See Task model
        title: task.canEdit ? t('Edit') : t('View'),
        onClick: () => {
          this.editTask(task);
        }
      }
    ];

    if (task.canEdit) {
      actions = actions.concat([
        {
          title: t('Delete'),
          onClick: () => {
            this.deleteTask(task);
          }
        },
        {
          title: task.status === 'OPEN' ? t('Mark as complete') : t('Reopen'),
          onClick: () => {
            this.toggleTaskStatus(task);
          }
        }
      ]);
    }

    return actions;
  };

  @computed get activeProjectUuids() {
    return this.project.allProjectTeams
      .filter(projectTeam => projectTeam.projectState === 'ACTIVE')
      .map(projectTeam => {
        return projectTeam.uuid;
      });
  }

  @computed get memberProjectUuids() {
    // For collaborator, fetch the current child project and the parent project members
    if (this.project.isChildProject) {
      return [this.project.uuid, this.project.parentProject.uuid];
    }

    // For Parent Project, fetch the Parent Project members and all the active child project team members
    return this.activeProjectUuids;
  }

  @action.bound showFilters() {
    this.showModal('filters');
    this.setupFiltering();
  }

  @action.bound async hideFilters() {
    await this.hideActiveModal();
    this.tearDownFiltering();
  }

  @action.bound clearFilters() {
    this.statusFilters.clear();
    this.assigneeFilters.clear();
    this.creatorFilters.clear();
    this.hideFilters();
  }

  @action.bound async applyFilters(event) {
    event.preventDefault();

    const values = this.filterForm.values();

    this.statusFilters.replace(values.statusFilters);
    this.assigneeFilters.replace(values.assigneeFilters);
    this.creatorFilters.replace(values.creatorFilters);

    this.hideFilters();
  }

  @computed get appliedFiltersCount() {
    // Always at least 1 for project statuses
    let count = 0;

    if (this.statusFilters.length > 0) {
      count++;
    }

    if (this.assigneeFilters.length > 0) {
      count++;
    }

    if (this.creatorFilters.length > 0) {
      count++;
    }

    return count;
  }

  @computed get hasAppliedFilters() {
    return this.appliedFiltersCount > 0;
  }

  @action.bound setupFiltering() {
    this.assigneeSelectorUI.setup({
      projectUuids: this.memberProjectUuids.slice(),
      role: [
        'ROLE_ACCOUNT_ADMIN',
        'ROLE_ADMIN',
        'ROLE_PROJECT_MEMBER',
        'ROLE_USER'
      ]
    });

    this.creatorSelectorUI.setup({
      projectUuids: this.memberProjectUuids.slice(),
      role: [
        'ROLE_ACCOUNT_ADMIN',
        'ROLE_ADMIN',
        'ROLE_PROJECT_MEMBER',
        'ROLE_USER'
      ]
    });

    this.filterForm = new TasksFiltersForm(
      {
        fields: tasksFiltersFormFields,
        rules: tasksFiltersFormRules,
        values: {
          statusFilters: this.statusFilters.slice(),
          assigneeFilters: this.assigneeFilters.slice(),
          creatorFilters: this.creatorFilters.slice()
        }
      },
      {
        options: tasksFiltersFormOptions,
        plugins: tasksFiltersFormPlugins
      }
    );
  }

  @action.bound tearDownFiltering() {
    this.assigneeSelectorUI.tearDown();
    this.creatorSelectorUI.tearDown();
    this.filterForm = null;
  }

  @action.bound setStatusFilters(options) {
    this.statusFilters.replace(options);
  }

  @computed get statusFilterParams() {
    return this.statusFilters.map(filter => filter.id).join(',');
  }

  @computed get statusFilterOptions() {
    return [
      { id: 'OPEN', title: 'Open' },
      { id: 'COMPLETED', title: 'Completed' },
      { id: 'DELETED', title: 'Deleted' }
    ];
  }
}
