import request from 'axios';
import { action, computed, observable, reaction } from 'mobx';
import UIStore from 'stores/ui/UIStore';

import SuperAdminProjectEtlJobs from 'stores/collections/superAdmin/SuperAdminProjectEtlJobs';
import SuperAdminClassificationEtlJobs from 'stores/collections/superAdmin/SuperAdminClassificationEtlJobs';
import SuperAdminWorkerEtlJobs from 'stores/collections/superAdmin/SuperAdminWorkerEtlJobs';
import SuperAdminCostCodeEtlJobs from 'stores/collections/superAdmin/SuperAdminCostCodeEtlJobs';
import SuperAdminPayTypeEtlJobs from 'stores/collections/superAdmin/SuperAdminPayTypeEtlJobs';
import SuperAdminShiftEtlJobs from 'stores/collections/superAdmin/SuperAdminShiftEtlJobs';

import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';

export default class SuperAdminIntegrationEtlJobsUI extends UIStore {
  @observable startingAllJobs;

  @observable activeJob;
  @observable startingProjectJob;
  @observable startingClassificationJob;
  @observable startingWorkerJob;
  @observable startingCostCodeJob;
  @observable startingShiftJob;
  @observable startingPayTypeJob;

  @observable jobToRun;

  constructor(options) {
    super(options);

    this.startingAllJobs = false;

    this.activeJob = null;
    this.startingProjectJob = false;
    this.startingClassificationJob = false;
    this.startingWorkerJob = false;
    this.startingCostCodeJob = false;
    this.startingShiftJob = false;
    this.startingPayTypeJob = false;

    this.jobToRun = null;

    this.projectJobs = new SuperAdminProjectEtlJobs(null, {
      rootStore: this.rootStore,
      parent: this
    });

    this.classificationJobs = new SuperAdminClassificationEtlJobs(null, {
      rootStore: this.rootStore,
      parent: this
    });

    this.workerJobs = new SuperAdminWorkerEtlJobs(null, {
      rootStore: this.rootStore,
      parent: this
    });

    this.costCodeJobs = new SuperAdminCostCodeEtlJobs(null, {
      rootStore: this.rootStore,
      parent: this
    });

    this.shiftJobs = new SuperAdminShiftEtlJobs(null, {
      rootStore: this.rootStore,
      parent: this
    });

    this.payTypeJobs = new SuperAdminPayTypeEtlJobs(null, {
      rootStore: this.rootStore,
      parent: this
    });
  }

  @action.bound setupReactions() {
    this.cancelReactToProjectJobsRun = reaction(
      () => this.projectJobs.polling,
      polling => {
        if (!polling) {
          this.startingCostCodeJob = true;

          setTimeout(async () => {
            await this.costCodeJobs.fetch({
              params: {
                companyUuid: this.activeCompany.uuid
              }
            });

            this.startingCostCodeJob = false;

            this.tearDownReactions();
          }, 10000);
        }
      }
    );

    this.cancelReactToClassificationJobsRun = reaction(
      () => this.classificationJobs.polling,
      polling => {
        if (!polling) {
          this.startingWorkerJob = true;

          setTimeout(async () => {
            await this.workerJobs.fetch({
              params: {
                companyUuid: this.activeCompany.uuid
              }
            });

            this.startingWorkerJob = false;

            this.tearDownReactions();
          }, 10000);
        }
      }
    );
  }

  @computed get lastProjectJob() {
    return this.projectJobs.models[0];
  }

  @action.bound tearDownReactions() {
    this.cancelReactToProjectJobsRun && this.cancelReactToProjectJobsRun();
    this.cancelReactToClassificationJobsRun &&
      this.cancelReactToClassificationJobsRun();
  }

  @computed get activeCompany() {
    return this.parent.activeCompany;
  }

  @computed get activeIntegration() {
    return this.parent.activeIntegration;
  }

  @action async fetchJobs() {
    if (!this.activeIntegration.isConnected || !this.activeIntegration.isRyvit)
      return;

    await Promise.all([
      this.projectJobs.fetch({
        params: {
          companyUuid: this.activeCompany.uuid
        }
      }),

      this.classificationJobs.fetch({
        params: {
          companyUuid: this.activeCompany.uuid
        }
      }),

      this.workerJobs.fetch({
        params: {
          companyUuid: this.activeCompany.uuid
        }
      }),

      this.costCodeJobs.fetch({
        params: {
          companyUuid: this.activeCompany.uuid
        }
      }),

      this.shiftJobs.fetch({
        params: {
          companyUuid: this.activeCompany.uuid
        }
      }),

      this.payTypeJobs.fetch({
        params: {
          companyUuid: this.activeCompany.uuid
        }
      })
    ]);
  }

  @action.bound showJobConfirmationModal(jobToRun) {
    this.showModal('etl-job-confirmation');
    this.jobToRun = jobToRun;
  }

  @action.bound confirmRunJob() {
    this.jobToRun && this.jobToRun();

    this.hideActiveModal().then(() => {
      this.jobToRun = null;
    });
  }

  @action.bound cancelRunJob() {
    this.hideActiveModal().then(() => {
      this.jobToRun = null;
    });
  }

  @computed get runAllJobsButtonText() {
    if (this.startingAllJobs) {
      return t('Running...');
    }

    return t('Run all');
  }

  @action.bound async runAllJobs() {
    this.startingAllJobs = true;

    try {
      await request.post(
        `${this.rootStore.urlMicroService(
          'integrations'
        )}/etl/workflows/ryvit-etl/executions`,
        {
          companyUuid: this.activeCompany.uuid
        }
      );

      await this.fetchJobs();

      this.setupReactions();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.startingAllJobs = false;
    }
  }

  @computed get runAllJobsDisabled() {
    return (
      this.startingAllJobs ||
      this.projectJobRunning ||
      this.classificationJobRunning ||
      this.workerJobRunning ||
      this.costCodeJobRunning ||
      this.shiftJobRunning ||
      this.payTypeJobRunning
    );
  }

  @computed get projectJobRunning() {
    return (
      this.projectJobs.loading ||
      this.projectJobs.polling ||
      this.startingProjectJob
    );
  }

  @computed get runProjectJobDisabled() {
    return this.startingAllJobs || this.projectJobRunning;
  }

  @computed get projectJobButtonText() {
    if (
      this.projectJobs.polling ||
      this.startingProjectJob ||
      this.startingAllJobs
    ) {
      return t('Running...');
    }

    return t('Run');
  }

  @action.bound async runProjectJob() {
    this.startingProjectJob = true;

    try {
      const response = await request.post(this.projectJobs.url(), {
        companyUuid: this.activeCompany.uuid
      });

      this.projectJobs.add(response.data, {
        at: 0
      });

      this.startingProjectJob = false;
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
      this.startingProjectJob = false;
    }
  }

  @computed get classificationJobRunning() {
    return (
      this.classificationJobs.loading ||
      this.classificationJobs.polling ||
      this.startingClassificationJob
    );
  }

  @computed get runClassificationJobDisabled() {
    return this.startingAllJobs || this.classificationJobRunning;
  }

  @computed get classificationJobButtonText() {
    if (this.classificationJobs.polling || this.startingClassificationJob) {
      return t('Running...');
    }

    return t('Run');
  }

  @action.bound async runClassificationJob() {
    this.startingClassificationJob = true;

    try {
      const response = await request.post(this.classificationJobs.url(), {
        companyUuid: this.activeCompany.uuid
      });

      this.classificationJobs.add(response.data, {
        at: 0
      });

      this.startingClassificationJob = false;
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
      this.startingClassificationJob = false;
    }
  }

  @computed get workerJobRunning() {
    return (
      this.workerJobs.loading ||
      this.workerJobs.polling ||
      this.startingWorkerJob
    );
  }

  @computed get runWorkerJobDisabled() {
    return (
      this.startingAllJobs ||
      this.runClassificationJobDisabled ||
      !this.classificationJobs.lastRunJobSucceeded ||
      this.workerJobRunning
    );
  }

  @computed get workerJobButtonText() {
    if (this.workerJobs.polling || this.startingWorkerJob) {
      return t('Running...');
    }

    return t('Run');
  }

  @action.bound async runWorkerJob() {
    this.startingWorkerJob = true;

    try {
      const response = await request.post(this.workerJobs.url(), {
        companyUuid: this.activeCompany.uuid
      });

      this.workerJobs.add(response.data, {
        at: 0
      });

      this.startingWorkerJob = false;
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
      this.startingWorkerJob = false;
    }
  }

  @computed get costCodeJobRunning() {
    return (
      this.costCodeJobs.loading ||
      this.costCodeJobs.polling ||
      this.startingCostCodeJob
    );
  }

  @computed get runCostCodeJobDisabled() {
    return (
      this.startingAllJobs ||
      this.runProjectJobDisabled ||
      !this.projectJobs.lastRunJobSucceeded ||
      this.costCodeJobRunning
    );
  }

  @computed get costCodeJobButtonText() {
    if (this.costCodeJobs.polling || this.startingCostCodeJob) {
      return t('Running...');
    }

    return t('Run');
  }

  @action.bound async runCostCodeJob() {
    this.startingCostCodeJob = true;

    try {
      const response = await request.post(this.costCodeJobs.url(), {
        companyUuid: this.activeCompany.uuid
      });

      this.costCodeJobs.add(response.data, {
        at: 0
      });
      this.startingCostCodeJob = false;
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
      this.startingCostCodeJob = false;
    }
  }

  @computed get shiftJobRunning() {
    return (
      this.shiftJobs.loading || this.shiftJobs.polling || this.startingShiftJob
    );
  }

  @computed get runShiftJobDisabled() {
    return this.startingAllJobs || this.shiftJobRunning;
  }

  @computed get shiftJobButtonText() {
    if (this.shiftJobs.polling || this.startingShiftJob) {
      return t('Running...');
    }

    return t('Run');
  }

  @action.bound async runShiftJob() {
    this.startingShiftJob = true;

    try {
      const response = await request.post(this.shiftJobs.url(), {
        companyUuid: this.activeCompany.uuid
      });

      this.shiftJobs.add(response.data, {
        at: 0
      });

      this.startingShiftJob = false;
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
      this.startingShiftJob = false;
    }
  }

  @computed get payTypeJobRunning() {
    return (
      this.payTypeJobs.loading ||
      this.payTypeJobs.polling ||
      this.startingPayTypeJob
    );
  }

  @computed get runPayTypeJobDisabled() {
    return this.startingAllJobs || this.payTypeJobRunning;
  }

  @computed get payTypeJobButtonText() {
    if (this.payTypeJobs.polling || this.startingPayTypeJob) {
      return t('Running...');
    }

    return t('Run');
  }

  @action.bound async runPayTypeJob() {
    this.startingPayTypeJob = true;

    try {
      const response = await request.post(this.payTypeJobs.url(), {
        companyUuid: this.activeCompany.uuid
      });

      this.payTypeJobs.add(response.data, {
        at: 0
      });

      this.startingPayTypeJob = false;
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
      this.startingPayTypeJob = false;
    }
  }

  @action.bound viewJobDetails(collection) {
    this.activeJob = collection.at(0);
    this.showModal('etl-job-detail');
  }
}
