import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import moment from 'moment';

const subscriptionFormPlugins = extendedPlugins;

const subscriptionFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const subscriptionFormFields = [
  'periodType',
  'autoRenew',
  'subscriptionState',
  'seats',
  'accountType',
  'startDate',
  'endDate',
  'trialDays',
  'discount',
  'isDiscountPermanent',
  'billingPlatform',
  'billingPlanKey',
  'billingPlanName'
];

const subscriptionFormRules = {
  periodType: 'required|string',
  autoRenew: 'boolean',
  subscriptionState: 'string',
  seats: 'required|numeric|min:1',
  startDate: 'required|string',
  endDate: 'string',
  accountType: 'string',
  trialEndDate: 'string',
  discount: 'numeric|between:-100,100',
  isDiscountPermanent: 'boolean',
  billingPlatform: 'required|string'
};

const subscriptionFormLabels = {
  periodType: 'Period Type',
  autoRenew: 'Auto Renew',
  subscriptionState: 'Subscr State',
  startDate: 'Subscription Dates',
  accountType: 'Account Type',
  seats: 'Seats',
  discount: 'Discount',
  isDiscountPermanent: 'One-Time',
  billingPlatform: 'Billing Platform'
};

const subscriptionFormValues = {
  periodType: 'ANNUAL',
  autoRenew: true,
  subscriptionState: 'ACTIVE',
  startDate: moment().format('YYYY-MM-DD'),
  endDate: moment()
    .add(1, 'months')
    .format('YYYY-MM-DD'),
  accountType: 'Paid',
  seats: 1,
  discount: 0.0,
  isDiscountPermanent: false,
  trialEndDate: moment().format('YYYY-MM-DD'),
  billingPlatform: 'CREDIT_CARD'
};

class SubscriptionForm extends MobxReactForm {}

export {
  SubscriptionForm,
  subscriptionFormOptions,
  subscriptionFormFields,
  subscriptionFormRules,
  subscriptionFormLabels,
  subscriptionFormValues,
  subscriptionFormPlugins
};
