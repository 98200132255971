import { RakenCollection } from '../lib';
import ScheduleBulk from '../models/ScheduleBulk';

export default class ScheduleBulks extends RakenCollection {
  model() {
    return ScheduleBulk;
  }

  url() {
    return `${this.rootStore.urlMicroService(
      'toolbox'
    )}/tbt/company/batch-schedule`;
  }
}
