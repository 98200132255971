import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';

const projectsFiltersFormPlugins = extendedPlugins;

const projectsFiltersFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const projectsFiltersFormFields = ['projectStateFilters[]', 'groupFilters[]'];

const projectsFiltersFormRules = {
  projectStateFilters: 'array',
  groupsFilters: 'array'
};

class ProjectsFiltersForm extends MobxReactForm {}

export {
  ProjectsFiltersForm,
  projectsFiltersFormRules,
  projectsFiltersFormFields,
  projectsFiltersFormOptions,
  projectsFiltersFormPlugins
};
