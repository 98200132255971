import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { Toast, Bold, Paragraph } from 'raken-ui';
import { t } from 'utils/translate';

import { Snackbar } from '@raken/athens-web/lib';

const ValidationDetailListGroup = styled.ul`
  padding: 0;
  width: 90%;
  max-height: 200px;
  overflow: auto;
  &:last-child {
    margin-bottom: 0;
  }
`;

const ValidationDetailListItem = styled.li`
  justify-content: flex-start;
  list-style-type: none;
  line-height: 1.5em;
  margin: 0;
  &:nth-child(even) {
    background-color: ${props => props.theme.colors.lightGrey4};
  }
`;

// maxWidth so that the message won't get too long.
const StyledSnackbar = styled(Snackbar)`
  z-index: 2500;
  max-width: 500px;
`;

export default observer(({ uiStore }) => {
  if (!uiStore.show) return null;

  const details = uiStore.currentNotification.validationDetails;

  if (uiStore.currentNotification.snackbar) {
    return (
      <StyledSnackbar
        style={{ boxSizing: `border-box` }}
        open={uiStore.show}
        onClose={uiStore.dismissCurrentNotification}
        severity={uiStore.currentNotification.snackbar}
        icon={uiStore.currentNotification.icon}
        autoHideDuration={
          uiStore.currentNotification.autoHideDuration
            ? uiStore.currentNotification.autoHideDuration
            : 3000
        }
      >
        {t(uiStore.currentNotification.title)}
      </StyledSnackbar>
    );
  }
  return (
    <Toast
      onUndo={uiStore.undoCurrentNotification}
      onDismiss={uiStore.dismissCurrentNotification}
      onDismissAll={uiStore.dismissAllNotifications}
      notificationCount={uiStore.additionalNotifications}
      showUndo={uiStore.currentNotification.showUndo}
      position="top"
      timer={4000}
      hiding={uiStore.hiding}
    >
      <div data-qa="toast_container">
        <Bold
          transform="capitalize"
          marginBottom={2}
          block
          data-qa="toast_title"
        >
          {t(uiStore.currentNotification.title)}
        </Bold>
        {uiStore.currentNotification.text && (
          <Paragraph small data-qa="toast_text">
            {typeof uiStore.currentNotification.text === 'object'
              ? uiStore.currentNotification.text
              : t(uiStore.currentNotification.text)}
          </Paragraph>
        )}
        {details && (
          <ValidationDetailListGroup>
            {details.map((it, i) => {
              return (
                <ValidationDetailListItem key={i}>
                  {it.fieldMessage}
                </ValidationDetailListItem>
              );
            })}
          </ValidationDetailListGroup>
        )}
      </div>
    </Toast>
  );
});
