import { action, computed, observable } from 'mobx';
import errorHandler from 'utils/errorHandler';
import UIStore from './UIStore';
import { t } from 'utils/translate';

export default class CompanyRolesUI extends UIStore {
  @observable loading;

  constructor(options) {
    super(options);
    this.loading = true;
  }

  @computed
  get hasCompanyPermissions() {
    return this.companyPermissions.length > 0;
  }

  @computed
  get hasNonDefaultPermissions() {
    return this.companyPermissions.models.some(
      model => model.isDefault === false
    );
  }

  @computed get lockedPermissions() {
    return this.companyPermissions.models.filter(
      permission => permission.locked
    );
  }

  @computed get hasLockedPermissions() {
    return this.lockedPermissions.length > 0;
  }

  @action.bound
  async fetchPermissions() {
    if (this.hasCompanyPermissions) return;

    this.loading = true;
    await this.companyPermissions.fetch();
    this.loading = false;
  }

  @action.bound
  togglePermission(e, model) {
    e.preventDefault();
    this.rootStore.authorizationUI
      .checkFeatureAccess('EditCompanyRoles')
      .then(() => {
        model.updatePermission().catch(error => {
          errorHandler(error, this.notifications.pushError);
        });
      });
  }

  @action.bound
  updatePermission(model) {
    this.rootStore.authorizationUI
      .checkFeatureAccess('EditCompanyRoles')
      .then(() => {
        model.updatePermission().catch(error => {
          this.rootStore.notificationsUI.pushNotification({
            snackbar: 'warning',
            icon: 'notification',
            title: `${t(error?.message)}`
          });
        });
      });
  }

  @action.bound
  async restoreDefaultPermissions() {
    const getDefaults = await this.companyPermissions.fetch({
      params: {
        isDefault: true
      }
    });

    const nonLockedDefaults = getDefaults.data.collection.filter(
      permission => !permission.locked
    );

    this.companyPermissions.batchUpdate(nonLockedDefaults);
  }

  @action.bound
  showRestorePermissionsModal() {
    this.showModal('RestorePermissionsModal');
  }
}
