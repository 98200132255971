import omit from 'lodash.omit';
import orderBy from 'lodash.orderby';
import { Model } from 'mobx-mc';
import ChecklistSections from 'stores/collections/checklists/ChecklistSections';
import { action, computed } from 'mobx';
import moment from 'moment';
import kebabCase from 'lodash.kebabcase';
import formatTimestamp from 'utils/formatTimestamp';

export default class Checklist extends Model {
  get urlRoot() {
    return `${this.rootStore.urlMicroService('toolbox')}/companies/${
      this.rootStore.me.company.uuid
    }/checklists`;
  }

  get restAttributes() {
    return [
      'uuid',
      'companyUuid',
      'name',
      'description',
      'status',
      'createdBy',
      'completedByName',
      'createdTimestamp',
      'updatedTimestamp',
      'updatedBy',
      'checklistSignatures',
      'template',
      'templateUuid',
      'projectUuid',
      'projectName',
      'pdfUrl',
      'checklistDate',
      'completedTimestamp',
      'responsible',
      'location',
      'author'
    ];
  }

  idAttribute() {
    return 'uuid';
  }

  @action.bound parse(attributes) {
    this.parseChecklistSections(attributes);

    return {
      ...omit(attributes, 'checklistSections')
    };
  }

  @action.bound parseChecklistSections(attributes) {
    if (this.checklistSections) {
      this.checklistSections.reset(attributes.checklistSections);
    } else {
      this.checklistSections = new ChecklistSections(
        attributes.checklistSections,
        {
          parent: this,
          rootStore: this.rootStore
        }
      );
    }
  }

  @computed
  get slug() {
    return kebabCase(this.name);
  }

  @computed
  get updatedOn() {
    return formatTimestamp(this.updatedTimestamp);
  }

  @computed
  get createdDate() {
    return formatTimestamp(this.createdTimestamp);
  }

  @computed
  get checklistDateFormatted() {
    return this.checklistDate ? formatTimestamp(this.checklistDate) : '-';
  }

  @computed
  get completedDateFormatted() {
    return this.completedTimestamp
      ? formatTimestamp(this.completedTimestamp)
      : '';
  }

  @computed
  get signed() {
    return this.checklistSignatures && this.checklistSignatures.length > 0;
  }

  @computed
  get signedOn() {
    return this.signed ? formatTimestamp(this.completedTimestamp) : null;
  }

  @computed
  get attachments() {
    let attachments = [];

    this.checklistSections.models.forEach(checklistSection => {
      checklistSection.checklistQuestions.models.forEach(checklistQuestion => {
        checklistQuestion.checklistResponses.models.forEach(
          checklistResponse => {
            attachments = attachments.concat(
              checklistResponse.attachments.models.slice()
            );
          }
        );
      });
    });

    return attachments;
  }

  @computed
  get attachmentsCount() {
    return this.attachments?.length;
  }

  @computed
  get hasAttachments() {
    return this.attachmentsCount > 0;
  }

  @computed
  get attachmentsCountPreviewImage() {
    // Find the first attachment that has a thumbUrl
    const attachmentWithThumbUrl = this.attachments?.find(
      attachment => attachment.thumbUrl
    );

    return attachmentWithThumbUrl?.thumbUrl;
  }

  @computed get hasAttachmentsUploading() {
    return this.attachments.find(attachment => attachment.isNew);
  }

  @computed
  get observations() {
    let observations = [];

    this.checklistSections.models.forEach(checklistSection => {
      checklistSection.checklistQuestions.models.forEach(checklistQuestion => {
        checklistQuestion.checklistResponses.models.forEach(
          checklistResponse => {
            observations = observations.concat(
              checklistResponse.observations.models.slice()
            );
          }
        );
      });
    });

    return observations;
  }

  @computed
  get observationsRaised() {
    return this.observations.length;
  }

  @computed
  get observationsClosed() {
    return this.observations.filter(
      observation => observation.status?.closedState
    ).length;
  }

  @computed get createdByMe() {
    return this.createdBy?.uuid === this.rootStore.me?.uuid;
  }

  @computed
  get hasPdf() {
    return Boolean(this.pdfUrl);
  }

  @computed get responsibleFormValues() {
    if (!this.responsible) return { uuid: '', name: '' };

    if (this.responsible.type === 'WorkerClassification') {
      return {
        uuid: this.responsible?.uuid,
        name: this.responsible?.classification // TODO as to rename to name
      };
    } else {
      return {
        uuid: this.responsible?.uuid,
        name: this.responsible?.company.name
      };
    }
  }

  @computed get formValues() {
    return {
      uuid: this.uuid,
      name: this.name,
      templateUuid: this.templateUuid,
      checklistDate: moment
        .utc(this.checklistDate)
        .local()
        .format('YYYY-MM-DD'),
      checklistTime: this.checklistDate
        ? moment
            .utc(this.checklistDate)
            .local()
            .format('HH:mm')
        : moment().format('HH:mm'),
      responsible: this.responsibleFormValues,
      location: this.location,
      checklistSections: orderBy(
        this.checklistSections.models,
        'position',
        'asc'
      ).map(checklistSection => {
        return checklistSection.formValues;
      })
    };
  }

  @computed get viewUrl() {
    return `/projects/${this.projectUuid}/checklists/${this.id}`;
  }

  @computed get isActive() {
    return this.status === 'ACTIVE';
  }

  @computed get isDraft() {
    return this.status === 'DRAFT';
  }

  @computed get isScheduled() {
    return this.status === 'SCHEDULED';
  }

  @computed get responsibleName() {
    if (!this.responsible) return '-';

    if (this.responsible.type === 'WorkerClassification') {
      return this.responsible?.classification;
    }

    return this.responsible?.company.name;
  }

  @computed get locationName() {
    if (!this.location) return '-';

    return this.location.fullPath;
  }
}
