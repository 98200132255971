import request from 'axios';
import moment from 'moment';
import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import { observable, action, computed, runInAction, reaction } from 'mobx';

import debounce from 'lodash.debounce';

import { BASE_DEBOUNCE } from 'fixtures/constants';

import UIStore from './UIStore';
import Users from 'stores/collections/Users';

import ProjectDirectoryCollaboratorsAddUI from './ProjectDirectoryCollaboratorsAddUI';

import { t } from 'utils/translate';
import { callTrack } from 'utils/segmentIntegration';
import { PROJECT_COLLABORATOR_REMOVED } from 'utils/segmentAnalytics/eventSpec';
import errorHandler from 'utils/errorHandler';
import alertErrorHandler from 'utils/alertErrorHandler';
import history from 'utils/history';

import {
  ProjectTeamForm,
  projectTeamFormOptions,
  projectTeamFormFields,
  projectTeamFormRules,
  projectTeamFormLabels,
  projectTeamFormPlugins
} from 'forms/projectTeam';

import ProjectDirectoryCollaboratorsFilterUI from './ProjectDirectoryCollaboratorsFilterUI';

export default class ProjectDirectoryCollaboratorsUI extends UIStore {
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;

  @observable selectedProjectTeam;
  @observable projectTeamForm;

  constructor(options) {
    super(options);

    this.loading = true;
    this.sortField = 'company.name';
    this.sortDirection = 'asc';
    this.searchQuery = '';
    this.pageSize = 20;
    this.page = 1;

    this.selectedProjectTeam = null;
    this.projectTeamForm = null;

    this.selectedMember = null;

    this.fetchMembersDebounced = debounce(this.fetchMembers, BASE_DEBOUNCE);

    // Standalone Users collection
    this.users = new Users(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.projectDirectoryCollaboratorsAddUI = new ProjectDirectoryCollaboratorsAddUI(
      {
        parent: this,
        rootStore: this.rootStore
      }
    );

    this.projectDirectoryCollaboratorsFilterUI = new ProjectDirectoryCollaboratorsFilterUI(
      {
        parent: this,
        rootStore: this.rootStore
      }
    );
  }

  @computed get members() {
    return this.users;
  }

  @action.bound setup() {
    this.fetchMembers();
    this.setupReactions();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions(listRef) {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchMembersDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @action.bound
  async fetchMembers() {
    this.members.cancelRequest();
    this.members.clear();

    if (!this.hasCollaboratorProjectTeams) {
      this.loading = false;
      return;
    }

    await this.members.fetch({
      params: pickBy(this.params, identity)
    });

    this.loading = false;
  }

  @computed get hasMembers() {
    return this.members.length > 0;
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;

    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @computed get additionalSortFields() {
    if (this.sortField === 'updatedTimestamp') {
      return 'createdTimestamp,firstName,lastName';
    }

    if (this.sortField === 'lastName') {
      return 'firstName';
    } else if (this.sortField === 'firstName') {
      return `lastName`;
    }

    return 'firstName,lastName';
  }

  @computed get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      sortField: `${this.sortField},${this.additionalSortFields}`,
      sortDirection: this.sortDirection,
      query: this.searchQuery,
      status: 'ACTIVE,INVITED',
      role: 'ROLE_ACCOUNT_ADMIN,ROLE_ADMIN,ROLE_PROJECT_MEMBER,ROLE_USER',
      projectUuids: this.selectedCollaboratorProjectTeamUuids.join(','),
      projectStates: this.filterValues.projectStateFilters
    };
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @action sortByLastCreated() {
    this.sortField = 'createdTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchMembersDebounced();
  }

  @computed
  get totalPages() {
    return Math.ceil(this.members.totalElements / this.pageSize);
  }

  @computed get showEmptyState() {
    return !this.loading && !this.searchQuery && !this.hasMembers;
  }

  @computed get showEmptySearchState() {
    return !this.loading && this.searchQuery && !this.hasMembers;
  }

  @computed get showTable() {
    return !this.showEmptyState && !this.showEmptySearchState;
  }

  @computed get collaboratorProjectTeams() {
    if (!this.project) return [];

    if (this.project.isChildProject) return [];

    return this.project.childProjects.models;
  }

  @computed get hasCollaboratorProjectTeams() {
    return this.collaboratorProjectTeams.length > 0;
  }

  @computed get collaboratorProjectTeamUuids() {
    return this.collaboratorProjectTeams.map(projectTeam => projectTeam.uuid);
  }

  @computed get selectedCollaboratorProjectTeamUuids() {
    if (!this.filterValues.projectFilters.length) {
      return this.collaboratorProjectTeams.map(projectTeam => projectTeam.uuid);
    }

    return this.filterValues.projectFilters;
  }

  canRemoveMember(member) {
    if (!this.project.isActive) return false;

    if (!this.project.isChildProject) {
      // project member and admin can remove collaborators
      if (
        (this.rootStore.me.isProjectMember || this.rootStore.me.isAdmin) &&
        !member.isTeamMember
      ) {
        return true;
      }
    }

    return false;
  }

  @computed get removeMemberTitle() {
    return t('Remove collaborator from project?');
  }

  @computed get removeMemberText() {
    return t('This collaborator will no longer be able to access the project.');
  }

  @action.bound async removeMemberFromProject(member) {
    this.selectedMember = member;
    // Fetch required to populate the projectUuids array
    this.selectedMember.fetch();
    this.showModal('RemoveMemberModal');
  }

  @action.bound async cancelRemoveMemberFromProject() {
    await this.hideActiveModal();
    this.selectedMember = null;
  }

  @action.bound async confirmRemoveMemberFromProject() {
    try {
      this.saving = true;

      const subProjectUuid = this.collaboratorProjectTeamUuids.find(uuid => {
        return this.selectedMember.projectUuids.includes(uuid);
      });

      await this.selectedMember.destroy(
        {
          url: `${this.rootStore.users.url()}/${
            this.selectedMember.uuid
          }/projects/${subProjectUuid}`
        },
        { wait: true }
      );

      this.loading = true;

      if (!this.hasMembers) {
        this.setPage(null, 1);
        this.fetchMembersDebounced();
      } else {
        this.fetchMembersDebounced();
      }

      await this.hideActiveModal();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Collaborator removed from project')
      });

      callTrack(PROJECT_COLLABORATOR_REMOVED, {
        project_id: this.project.id,
        project_name: this.project.name,
        collaborator_id: this.selectedMember.uuid,
        collaborator_business_id: this.selectedMember.company?.uuid,
        collaborator_company_name: this.selectedMember.company?.name
      });

      this.project.fetch({
        params: {
          mn: 'full',
          mc: 'full'
        }
      });

      this.refetchSubscription();

      this.selectedMember = null;
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  refetchSubscription() {
    // Refetch the subscription
    this.subscription.fetch({
      params: {
        mn: 'full',
        mc: 'full'
      }
    });
  }

  @action.bound async showProjectTeamModal(projectTeam) {
    await this.authorization.checkFeatureAccess('EditProjectTeam');

    this.projectTeamForm = new ProjectTeamForm(
      {
        fields: projectTeamFormFields,
        rules: projectTeamFormRules,
        labels: projectTeamFormLabels,
        values: projectTeam.formValues
      },
      {
        options: projectTeamFormOptions,
        plugins: projectTeamFormPlugins,
        rootStore: this.rootStore
      }
    );

    this.selectedProjectTeam = projectTeam;

    this.showModal('ProjectTeamModal');
  }

  @action.bound async hideProjectTeamModal() {
    await this.hideActiveModal();
    this.projectTeamForm = null;
    this.selectedProjectTeam = null;
  }

  @action.bound
  submitProjectTeamForm(event) {
    event.preventDefault();

    this.projectTeamForm.submit({
      onSuccess: this.submitProjectTeamFormSuccess,
      onError: this.submitProjectTeamFormError
    });
  }

  @action.bound
  async submitProjectTeamFormSuccess() {
    this.saving = true;

    const values = this.projectTeamForm.values();

    values.startDate = moment(values.startDate).format('YYYY-MM-DD');
    values.endDate = values.endDate
      ? moment(values.endDate).format('YYYY-MM-DD')
      : null;

    try {
      await this.selectedProjectTeam.save(values, {
        wait: true
      });

      this.saving = false;

      await this.hideActiveModal();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: `${this.selectedProjectTeam.name} settings updated`
      });

      this.selectedProjectTeam = null;
      this.projectTeamForm = null;
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  submitProjectTeamFormError() {
    console.log(this.projectTeamForm.errors());
  }

  @action.bound clearUIState() {
    this.searchQuery = '';
    this.members.clear();
    this.page = 1;
    this.loading = true;
    this.sortField = 'company.name';
    this.sortDirection = 'asc';
    this.selectedMember = null;
    this.projectTeamForm = null;
    this.selectedProjectTeam = null;
    this.projectDirectoryCollaboratorsFilterUI.clearAllFilters();
  }

  @action.bound
  async showAddProjectCollaboratorsModal(projectTeam = null) {
    await this.authorization.checkFeatureAccess('InviteCollaborators');

    history.push(`${this.project.viewUrl}/directory/collaborators/add`);
  }

  @action.bound
  async resendInvite(member) {
    await this.authorization.checkFeatureAccess('InviteProjectMembers');

    try {
      await request.post(`/ra/invite/${member.inviteId}`, {
        inviteResend: true,
        projectId: this.project.id
      });

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Invite sent')
      });
    } catch (error) {
      errorHandler(error, this.rootStore.notificationsUI.pushError);
    }
  }

  @computed
  get filterValues() {
    return {
      projectFilters: this.projectDirectoryCollaboratorsFilterUI.projectFilters.map(
        project => project.value
      ),
      projectStateFilters: this.projectDirectoryCollaboratorsFilterUI
        .projectStateFilters.value
    };
  }
}
