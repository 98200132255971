import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';
import trimObject from '../../utils/trimObject';

const projectTemplateWatermarkFormPlugins = extendedPlugins;

const projectTemplateWatermarkFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false,
  retrieveOnlyEnabledFields: false
};

const projectTemplateWatermarkFormFields = [
  'enableWatermark',
  'watermarkIncludeCompanyLogo',
  'watermarkIncludeCompanyName',
  'watermarkIncludeProjectName',
  'watermarkIncludeTimestamp',
  'watermarkIncludeGPSCoords',
  'watermarkIncludeAutoGenLocation',
  'watermarkAlignment'
];

const projectTemplateWatermarkFormRules = {
  enableWatermark: 'boolean',
  watermarkIncludeCompanyLogo: 'boolean',
  watermarkIncludeCompanyName: 'boolean',
  watermarkIncludeProjectName: 'boolean',
  watermarkIncludeTimestamp: 'boolean',
  watermarkIncludeGPSCoords: 'boolean',
  watermarkIncludeAutoGenLocation: 'boolean',
  watermarkAlignment: 'string'
};

const projectTemplateWatermarkFormDisabled = {};

const projectTemplateWatermarkFormLabels = {};

const projectTemplateWatermarkFormValues = {};

class ProjectTemplateWatermarkForm extends MobxReactForm {
  trimmedValues() {
    return trimObject(this.values());
  }
}

export {
  ProjectTemplateWatermarkForm,
  projectTemplateWatermarkFormOptions,
  projectTemplateWatermarkFormFields,
  projectTemplateWatermarkFormRules,
  projectTemplateWatermarkFormValues,
  projectTemplateWatermarkFormLabels,
  projectTemplateWatermarkFormPlugins,
  projectTemplateWatermarkFormDisabled
};
