import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';

const membersFiltersFormPlugins = extendedPlugins;

const membersFiltersFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const membersFiltersFormFields = [
  'statusFilters[]',
  'roleFilters[]',
  'classificationFilters[]',
  'groupFilters[]'
];

const membersFiltersFormRules = {
  statusFilters: 'array',
  roleFilters: 'array',
  classificationFilters: 'array',
  groupFilters: 'array'
};

class MembersFiltersForm extends MobxReactForm {}

export {
  MembersFiltersForm,
  membersFiltersFormRules,
  membersFiltersFormFields,
  membersFiltersFormOptions,
  membersFiltersFormPlugins
};
