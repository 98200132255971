import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';

const settingsNotificationsFormPlugins = extendedPlugins;

const settingsNotificationsFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const settingsNotificationsFormFields = [
  'notificationSettings[]',
  'notificationSettings[].user',
  'notificationSettings[].user.id',
  'notificationSettings[].project',
  'notificationSettings[].settings',
  'notificationSettings[].settings.SAFETY',
  'notificationSettings[].settings.DELAYS',
  'notificationSettings[].settings.MISSED_REPORTS'
];

class SettingsNotificationsForm extends MobxReactForm {
  findFieldByUserId(userId) {
    let foundField;

    this.$('notificationSettings').map(field => {
      if (field.$('user.id').value === userId) {
        foundField = field;
      }
    });

    return foundField;
  }
}

export {
  SettingsNotificationsForm,
  settingsNotificationsFormOptions,
  settingsNotificationsFormFields,
  settingsNotificationsFormPlugins
};
