import MobxReactForm from 'mobx-react-form';
import { computed } from 'mobx';
import extendedPlugins from './utils/extendedPlugins';
import { t } from 'utils/translate';

const workLogStandardFormPlugins = extendedPlugins;

const workLogStandardFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const workLogStandardFormFields = [
  'subcontractor',
  'workDesc',
  'hours',
  'workerCount'
];

const workLogStandardFormRules = {
  subcontractor: 'required|string|max:255',
  workDesc: 'string|max:10000',
  workerCount: 'integer|max:999',
  hours: 'numeric|min:0|max:24'
};

const workLogStandardFormLabels = {
  subcontractor: t('work log name'),
  workDesc: t('description'),
  workerCount: t('quantity'),
  hours: t('hours')
};

class WorkLogStandardForm extends MobxReactForm {
  @computed
  get cleanedValues() {
    return this.values();
  }
}

export {
  WorkLogStandardForm,
  workLogStandardFormOptions,
  workLogStandardFormFields,
  workLogStandardFormRules,
  workLogStandardFormLabels,
  workLogStandardFormPlugins
};
