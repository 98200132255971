import { RakenCollection } from '../lib';
import TimeClockQuestion from '../models/TimeClockQuestion';

export default class TimeClockQuestions extends RakenCollection {
  model() {
    return TimeClockQuestion;
  }

  url() {
    return `${this.rootStore.urlMicroService('toolbox')}/companies/${
      this.rootStore.me.company.uuid
    }/checklist-templates`;
  }
}
