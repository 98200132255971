import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import Light from './Light';
import DesktopOnly from './DesktopOnly';

import media from '../utils/mediaTemplate';

const Wrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.75);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 5px;
  top: ${props => (props.top ? props.top : 'inherit')};
  left: ${props => (props.left ? props.left : 'inherit')};
  position: absolute;
  ${media.desktop`
    padding: 2rem ${props => props.gutter}px;
  `};
`;

const BarWrapper = styled.div`
  background-color: ${props => props.theme.colors[props.background]};
  border-radius: 1rem;
  height: ${props => props.height / 2}px;
  position: relative;
  overflow: hidden;
  width: 100%;
  ${media.desktop`
    height: ${props => props.height}px;
    border-radius: 2rem;
  `};
`;

const Bar = styled.div`
  background-color: ${props => props.theme.colors[props.color]};
  border-radius: 0.75rem;
  height: ${props => props.height / 2}px;
  width: ${props => props.progress}%;
  transition: width 0.35s ease-in;
  position: absolute;
  left: 0;
  top: 0;
  ${media.desktop`
    border-radius: 1.5rem;
      height: ${props => props.height}px;
  `};
`;

const ProgressBar = observer(
  ({ progress, background, color, height, withText, gutter, top, left }) => {
    return (
      <Wrapper gutter={gutter} top={top} left={left}>
        {withText && (
          <DesktopOnly>
            <Light color="white" size={20} marginRight={10}>
              {progress}
              {'%'}
            </Light>
          </DesktopOnly>
        )}

        <BarWrapper background={background} height={height}>
          <Bar
            id="File_Uploader_Progress_Bar"
            data-qa="div_file-uploader-progress-bar"
            color={color}
            height={height}
            progress={progress}
          />
        </BarWrapper>
      </Wrapper>
    );
  }
);

ProgressBar.propTypes = {
  background: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  withText: PropTypes.bool.isRequired,
  gutter: PropTypes.number
};

ProgressBar.defaultProps = {
  background: 'lightGrey3',
  color: 'goGreen',
  height: 15,
  progress: 0,
  withText: false,
  gutter: 10
};

export default ProgressBar;
