import omit from 'lodash.omit';
import { computed, action, observable } from 'mobx';
import ReportEntryWithAttachments from './ReportEntryWithAttachments';
import { t } from 'utils/translate';
import kebabCase from 'lodash.kebabcase';
import Attachment from 'stores/models/Attachment';
import getFilePreviewIcon from 'utils/getFilePreviewIcon';
import fileToBase64 from 'utils/fileToBase64';
import bytesToSize from 'utils/bytesToSize';
import truncate from 'html-truncate';

export default class ActivityWorkLog extends ReportEntryWithAttachments {
  @observable expandDescription;

  constructor(attributes, options) {
    super(attributes, options);
    this.expandDescription = false;
  }

  get restAttributes() {
    return [
      'uuid',
      'type',
      'createdTimestamp',
      'updatedTimestamp',
      'workDesc',
      'workerCount',
      'project',
      'reportDate',
      'name',
      'totalHours',
      'costCodes',
      'activityViewUrl',
      'activitySegmentName'
    ];
  }

  get restAttributeDefaults() {
    return {
      activityViewUrl: '/'
    };
  }

  idAttribute() {
    return 'uuid';
  }

  @action.bound
  parse(attributes) {
    this.parseCreatedBy(attributes);
    this.parseUpdatedBy(attributes);
    this.parseProject(attributes);
    this.parseCompany(attributes);
    this.parseAttachments(attributes);

    return {
      ...omit(attributes, [
        'attachments',
        'createdBy',
        'updatedBy',
        'project',
        'company'
      ])
    };
  }

  @computed
  get fullName() {
    return this.subcontractor;
  }

  @computed get slug() {
    return kebabCase(this.fullName);
  }

  @computed
  get title() {
    return this.type === 'STANDARD_WORK_LOG' ? t('Work Log') : t('Time Card');
  }

  @computed get activityTitle() {
    return `${t('submitted a')} ${this.title}`;
  }

  @computed
  get formValues() {
    return {
      subcontractor: this.subcontractor,
      workDesc: this.workDesc,
      workerCount: this.workerCount,
      hours: this.hours
    };
  }

  @computed
  get isEmpty() {
    return (
      !this.workerCount &&
      !this.hours &&
      !this.hasAttachments &&
      !this.description
    );
  }

  @computed
  get description() {
    return this.workDesc;
  }

  @computed get hasAttachmentsUploading() {
    return this.attachments.models.find(attachment => attachment.isNew);
  }

  @computed
  get hideDeleteButton() {
    return this.rootStore.me.role === 'ROLE_USER';
  }

  @action.bound
  async uploadAttachment(uploadItem) {
    const file = uploadItem.file;

    if (file.size > 62914560) {
      this.rootStore.notificationsUI.pushNotification({
        title: `${t('File is too big ')} (${bytesToSize(file.size)}). ${t(
          'Limit is 60MB.'
        )}`,
        showUndo: false
      });

      return;
    }

    file.preview = await fileToBase64(file);

    const filePreviewIcon = getFilePreviewIcon(this.rootStore.assetsURL, file);

    // Create a temporrary model to display the preview icon
    // in the carousel
    const previewAttachment = new Attachment(
      {
        thumbUrl: filePreviewIcon
      },
      {
        rootStore: this.rootStore
      }
    );

    // Add to the collection will remove after full upload finishes
    this.attachments.add(previewAttachment);

    return this.attachments
      .upload(
        uploadItem,
        percentCompleted => {
          // If we wanted to show a progress bar on the attachment
          // we can use previewAttachment.uploadProgress.
          previewAttachment.setUploadProgress(percentCompleted);
        },
        file,
        'WorkLog'
      )
      .then(model => {
        // Remove the preview model now that the new one is ready
        this.attachments.remove(previewAttachment);
      });
  }

  @computed
  get truncatedDescription() {
    return this.workDesc ? truncate(this.workDesc, 70) : '';
  }

  @computed
  get fullDescription() {
    return this.workDesc;
  }

  @action.bound toggleExpandDescription() {
    this.expandDescription = !this.expandDescription;
  }

  @computed get tableDescription() {
    if (this.expandDescription) return this.fullDescription;

    return this.truncatedDescription;
  }

  @computed
  get activityCostCodeCount() {
    return this.costCodes?.length;
  }
}
