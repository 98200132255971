import debounce from 'lodash.debounce';
import { action, observable, computed, reaction } from 'mobx';
import { BASE_DEBOUNCE } from 'fixtures/constants';

import UIStore from './UIStore';

import Users from '../collections/Users';

class CustomUserPaginatedQuery {
  constructor(store, uiStore) {
    this.store = store;
    this.uiStore = uiStore;
    // Required public property to determine if more data is available.
    this.hasNext = false;
  }

  // Required public property.

  async next(callback) {
    await this.uiStore.paginatedUsers.fetch({
      add: true,
      remove: true,
      update: false,
      params: {
        limit: 10,
        offset: this.uiStore.paginatedUsers.nextOffset || 0,
        role: 'ROLE_ACCOUNT_ADMIN,ROLE_ADMIN,ROLE_PROJECT_MEMBER,ROLE_USER',
        status: 'ACTIVE',
        sortField: 'firstName',
        sortDirection: 'asc',
        invitePending: 'false',
        enrichWith: 'avatar',
        projectStates: 'ACTIVE'
      }
    });

    // Set this.hasNext
    this.hasNext = this.uiStore.paginatedUsers.hasNextPage;

    const users = this.uiStore.paginatedUsers.models
      .filter(user => !user.isMe)
      .map(user => {
        return {
          userId: user.uuid,
          nickname: user.fullName,
          profileUrl: user.avatarImage
        };
      });

    callback(users);
  }
}

export default class MessagingChannelAddUI extends UIStore {
  // Channel creation UI
  @observable loading;
  @observable searchQuery;

  constructor(options) {
    super(options);

    // Channel creation UI
    this.loading = false;
    this.searchQuery = '';

    this.selectedUsers = observable([]);

    this.paginatedUsers = new Users(null, {
      rootStore: this.rootStore
    });

    this.fetchUsersDebounced = debounce(this.fetchUsers, BASE_DEBOUNCE);
  }

  // Used for the list of members from invite button on settings
  getCustomPaginatedQuery = () => {
    return new CustomUserPaginatedQuery(this.rootStore, this);
  };

  @action.bound setup() {
    this.fetchUsers();
    this.setupReactions();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  @action.bound setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        this.fetchUsers();
      }
    );

    this.reactToSearchQuery = reaction(
      () => this.searchQuery,
      params => {
        this.fetchUsersDebounced();
      }
    );
  }

  @action.bound tearDownReactions() {
    this.reactToParams && this.reactToParams();
    this.reactToSearchQuery && this.reactToSearchQuery();
  }

  @action.bound clearUIState() {
    this.paginatedUsers.clear();
    this.selectedUsers.clear();
    this.clearSearchQuery();
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
  }

  @action.bound clearSearchQuery(value) {
    this.searchQuery = '';
  }

  @computed get hasUsers() {
    return this.paginatedUsers.length > 0;
  }

  @computed get params() {
    return {
      offset: 0,
      limit: 50,
      role: 'ROLE_ACCOUNT_ADMIN,ROLE_ADMIN,ROLE_PROJECT_MEMBER,ROLE_USER',
      status: 'ACTIVE',
      sortField: 'firstName',
      sortDirection: 'asc',
      invitePending: 'false',
      enrichWith: 'avatar',
      projectStates: 'ACTIVE'
    };
  }

  @action.bound async fetchUsers() {
    this.loading = true;

    await this.paginatedUsers.fetch({
      params: {
        ...this.params,
        query: this.searchQuery
      }
    });

    this.loading = false;
  }

  @action.bound fetchNextPageOfUsers() {
    if (this.paginatedUsers.fetching) return;

    if (this.paginatedUsers.hasNextPage) {
      this.paginatedUsers.fetchNextPage();
    }
  }

  @computed get filteredPaginatedUsers() {
    return this.paginatedUsers.models.filter(user => !user.isMe);
  }

  @action.bound toggleSelectUser(user) {
    const selectedUser = this.selectedUsers.find(
      selectedUser => selectedUser.uuid === user.uuid
    );

    if (selectedUser) {
      this.selectedUsers.remove(user);
    } else {
      this.selectedUsers.push(user);
    }
  }

  @computed get disableSaveButton() {
    return this.saving || !this.selectedUsers.length;
  }

  @action.bound async createMessagingChannel(params, createChannel) {
    if (!this.selectedUsers.length) return;

    try {
      // https://sendbird.com/docs/chat/v3/javascript/guides/group-channel#4-list-of-arguments
      params.isPublic = false;
      params.isEphemeral = false;
      params.isDistinct = true;
      params.operatorUserIds = [this.me.uuid];
      params.addUserIds(this.selectedUsers.map(user => user.uuid));
      params.name = this.selectedUsers.map(user => user.fullName).join(', ');

      this.saving = true;

      const channel = await createChannel(params);

      this.parent.hideMessagingChannelAdd();

      this.paginatedUsers.clear();
      this.selectedUsers.clear();

      this.parent.setCurrentChannelUrl(channel.url);

      this.saving = false;
    } catch (error) {
      console.error(error);
    }
  }
}
