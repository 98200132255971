import { Model } from 'mobx-mc';

export default class ChecklistType extends Model {
  get restAttributes() {
    return ['uuid', 'name'];
  }

  idAttribute() {
    return 'uuid';
  }
}
