import debounce from 'lodash.debounce';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import ProjectChildUI from './ProjectChildUI';

import MaterialsUI from './MaterialsUI';
import MaterialLogs from '../../collections/MaterialLogs';
import MaterialLogAddUI from './MaterialLogAddUI';
import MaterialLogEditUI from './MaterialLogEditUI';
import MaterialLogsRollOverUI from './MaterialLogsRollOverUI';

import history from 'utils/history';
import { BASE_DEBOUNCE } from 'fixtures/constants';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

import { callTrack } from 'utils/segmentIntegration';
import { MATERIAL_LOG_DELETED } from 'utils/segmentAnalytics/eventSpec';

export default class MaterialLogsUI extends ProjectChildUI {
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;
  @observable selectedMaterialLog;

  constructor(options) {
    super(options);

    this.loading = true;

    this.selectedMaterialLog = null;

    this.sortField = 'material.name';
    this.sortDirection = 'asc';
    this.page = 1;
    this.searchQuery = '';
    this.pageSize = 20;

    // Material Logs collection
    this.materialLogs = new MaterialLogs(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.materialLogAddUI = new MaterialLogAddUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.materialLogEditUI = new MaterialLogEditUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.materialLogsRollOverUI = new MaterialLogsRollOverUI({
      parent: this,
      rootStore: this.rootStore
    });

    // Equipment inline create and update
    // TODO rename
    this.inlineMaterialsUI = new MaterialsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchMaterialLogsDebounced = debounce(
      this.fetchMaterialLogs,
      BASE_DEBOUNCE
    );
  }

  @computed get materialsUI() {
    return this.inlineMaterialsUI;
  }

  @action.bound setup() {
    window.scrollTo(0, 0);
    this.setupReactions();
    this.fetchMaterialLogs();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchMaterialLogsDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      sortField: this.sortField,
      sortDirection: this.sortDirection,
      segmentUuids: this.segmentUuid,
      projectUuids: this.projectUuid,
      fromDate: this.date,
      toDate: this.date
    };
  }

  @computed get hasRequiredParams() {
    return this.projectUuid && this.date;
  }

  @action.bound async fetchMaterialLogs() {
    if (!this.hasRequiredParams) return;

    this.materialLogs.cancelRequest();
    this.materialLogs.clear();

    try {
      await this.materialLogs.fetch({
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasMaterialLogs() {
    return this.materialLogs.hasModels;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.materialLogs.totalElements / this.pageSize);
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.searchQuery = '';
    this.materialLogs.clear();
    this.page = 1;
    this.loading = true;
    this.saving = false;
    this.sortField = 'material.name';
    this.sortDirection = 'asc';
    this.selectedMaterialLog = null;
  }

  @computed get showEmptyState() {
    return !this.loading && !this.searchQuery && !this.hasMaterialLogs;
  }

  @computed get showEmptySearchState() {
    return !this.loading && this.searchQuery && !this.hasMaterialLogs;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @action.bound async addMaterialLog() {
    await this.authorization.checkFeatureAccess('CRUDMaterialLogs');

    history.push({
      pathname: `${this.project.viewUrl}/materials/add`,
      search: this.baseQueryParams
    });
  }

  @action.bound async editMaterialLog(materialLog) {
    history.push({
      pathname: `${this.project.viewUrl}/materials/${materialLog.uuid}`,
      search: this.baseQueryParams
    });
  }

  @action.bound async deleteMaterialLog(materialLog) {
    await this.authorization.checkFeatureAccess('CRUDMaterialLogs');

    this.selectedMaterialLog = materialLog;
    this.showModal('DeleteModal');
  }

  @action.bound async cancelDeleteMaterialLog() {
    await this.hideActiveModal();

    this.selectedMaterialLog = null;
  }

  @action.bound async confirmDeleteMaterialLog() {
    this.saving = true;

    try {
      await this.selectedMaterialLog.destroy({ wait: true });

      this.refetchMaterialLogs();

      await this.hideActiveModal();

      this.selectedMaterialLog = null;

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Material log deleted')
      });

      callTrack(MATERIAL_LOG_DELETED);
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound sortByLastCreated() {
    this.sortField = 'createdTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchMaterialLogsDebounced();
    this.refetchReportSummary();
  }

  @action.bound refetchMaterialLogs() {
    this.loading = true;

    if (!this.hasMaterialLogs) {
      this.setPage(null, 1);
      this.fetchMaterialLogs();
    } else {
      this.fetchMaterialLogs();
    }

    this.refetchReportSummary();
  }

  getMaterialLogActions = materialLog => {
    return [
      {
        title: this.hasWriteAccess ? t('Edit') : t('View'),
        onClick: () => {
          this.editMaterialLog(materialLog);
        }
      },
      {
        title: t('Delete'),
        onClick: () => {
          this.deleteMaterialLog(materialLog);
        }
      }
    ];
  };
}
