import { computed } from 'mobx';
import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import { t } from 'utils/translate';
import trimObject from '../utils/trimObject';

const breakFormPlugins = extendedPlugins;

const breakFormOptions = {
  validateOnInit: false,
  strictUpdate: false,
  validateOnChange: true
};

const breakFormFields = [
  'name',
  'code',
  'type',
  'defaultDuration',
  'minimumBreakDuration',
  'durationRequired',
  'startTimeRequired',
  'endTimeRequired',
  'billable'
];

const breakFormRules = {
  name: 'string|required|max:30',
  code: 'string|required|max:5',
  type: 'string|required'
};

const breakFormLabels = {
  name: t('Break name'),
  code: t('Abbreviation'),
  type: t('Break type'),
  defaultDuration: t('Default duration (min)'),
  minimumBreakDuration: t('Minimum break duration (min)'),
  durationRequired: t('Require duration'),
  startTimeRequired: t('Require start time'),
  endTimeRequired: t('Require end time'),
  billable: t('Paid hours')
};

const breakFormValues = {
  defaultDuration: null
};

class BreakForm extends MobxReactForm {
  @computed get identificationString() {
    return `${this.$('name').value}, ${this.$('code').value}`;
  }

  trimmedValues() {
    return trimObject(this.values());
  }
}

export {
  BreakForm,
  breakFormOptions,
  breakFormFields,
  breakFormRules,
  breakFormLabels,
  breakFormPlugins,
  breakFormValues
};
