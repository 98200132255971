import React from 'react';
import Media from 'react-media';

export default ({ children }) => (
  <Media query={{ minWidth: 1200 }}>
    {matches =>
      matches && typeof children === 'string' ? (
        <span>{children}</span>
      ) : matches && typeof children !== 'string' ? (
        React.cloneElement(children, null)
      ) : null
    }
  </Media>
);
