export default size => {
  switch (size) {
    case 'large':
      return 1200;
    case 'small':
      return 500;
    case 'medium':
      return 800;
    default:
      return size;
  }
};
