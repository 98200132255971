import { RakenCollection } from '../../lib';
import GroupClass from 'stores/models/groups/GroupClass';

export default class GroupClasses extends RakenCollection {
  model() {
    return GroupClass;
  }

  url() {
    return `ra/group-classes`;
  }
}
