import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { TableSortButton } from '.';
import media from '../utils/mediaTemplate';

const TH = styled.th`
  box-sizing: border-box;
  color: ${props => props.theme.colors.charcoalGrey};
  cursor: ${props =>
    props.sortable || props.clickable ? 'pointer' : 'inherit'};
  font-weight: ${props => (props.bold ? 700 : 300)};
  font-size: ${props => props.size / 10 || 1.4}rem;
  line-height: 16px;
  padding: ${props => props.padding || '0 0.5rem'};
  position: relative;
  text-align: ${props => props.align};
  text-transform: ${props =>
    props.transform ? props.transform : 'capitalize'};
  width: ${props => props.width};
  white-space: ${props => (props.noWrap ? 'nowrap' : 'normal')};
  > span {
    border-bottom: 4px solid
      ${props =>
        props.sorted && !props.hideSortable && !props.borderDisabled
          ? props.theme.colors.rakenOrange
          : 'transparent'};
    display: ${props =>
      props.innerWrapperDisplay ? props.innerWrapperDisplay : 'inline-block'};
    padding: 0 0 6px;
  }
  ${media.large`
    padding: ${props => props.padding || '0 1.5rem'};
  `};
  ${props =>
    props.hide &&
    css`
      visibility: hidden;
      height: 0;
      overflow: hidden;
      padding-top: 0;
      padding-bottom: 0;
      font-size: 0;
      margin: 0;
      line-height: 0;
    `};
`;

const TextBlock = styled.div`
  display: ${props =>
    props.innerWrapperDisplay ? props.innerWrapperDisplay : 'inline-block'};
  text-align: center;
`;

const TableHeading = ({
  align,
  width,
  sortable,
  sorted,
  direction,
  noWrap,
  innerWrapperDisplay,
  onClick,
  children,
  hideSortable,
  absolute,
  hide,
  padding,
  borderDisabled,
  colSpan,
  bold,
  dataQA,
  size,
  clickable,
  transform
}) => (
  <TH
    align={align}
    width={width}
    sortable={sortable}
    sorted={sorted}
    direction={direction}
    noWrap={noWrap}
    onClick={onClick}
    hide={hide}
    padding={padding}
    borderDisabled={borderDisabled}
    hideSortable={hideSortable}
    colSpan={colSpan}
    bold={bold}
    data-qa={dataQA}
    size={size}
    clickable={clickable}
    transform={transform}
    innerWrapperDisplay={innerWrapperDisplay}
  >
    <span>
      <TextBlock innerWrapperDisplay={innerWrapperDisplay}>
        {children}
      </TextBlock>
      {sortable && (
        <TableSortButton
          sorted={sorted}
          direction={direction}
          hideSortable={hideSortable}
          absolute={absolute}
          borderDisabled={borderDisabled}
        />
      )}
    </span>
  </TH>
);

TableHeading.propTypes = {
  align: PropTypes.string,
  width: PropTypes.string,
  sortable: PropTypes.bool,
  sorted: PropTypes.bool,
  direction: PropTypes.oneOf([null, 'asc', 'ASC', 'desc', 'DESC']),
  noWrap: PropTypes.bool,
  clickable: PropTypes.bool
};

TableHeading.defaultProps = {
  align: 'left',
  width: 'auto',
  sortable: false,
  sorted: false,
  direction: null,
  noWrap: false,
  clickable: false
};

export default TableHeading;
