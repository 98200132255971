import { RakenCollection } from '../lib';
import CertificationsReport from '../models/CertificationsReport';

export default class CertificationsReports extends RakenCollection {
  model() {
    return CertificationsReport;
  }

  url() {
    return `/ra/certification-reports/scheduled`;
  }
}
