import MobxReactForm from 'mobx-react-form';
import validatorjs from 'validatorjs';
import { t } from 'utils/translate';

const plugins = { dvr: validatorjs };

const options = {
  validateOnInit: false,
  validateOnChange: true
};

const fields = [
  'projectIncludeOption',
  'projects',
  'taskStates',
  'emails',
  'email',
  'includeAttachments'
];

const rules = {
  projects: 'array|required_if:projectIncludeOption,SELECTED',
  taskStates: 'required|array',
  emails: 'array|required',
  email: 'required_without:emails|email'
};

const values = {
  projectIncludeOption: '',
  projects: [],
  taskStates: [],
  email: '',
  includeAttachments: true
};

const labels = {
  projects: t('Projects to include'),
  taskStates: t('Task statuses'),
  emails: t('Send to'),
  includeAttachments: t('Include attachments')
};

export class TasksReportForm extends MobxReactForm {}

export default {
  options,
  fields,
  rules,
  values,
  labels,
  plugins
};
