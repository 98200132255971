/**
 * Must match specs defined in Confluence.
 * @docs https://rakenapp.atlassian.net/wiki/spaces/PROD/pages/1402181170/Analytics+Feature+Summary
 * @settings Mixpanel Destination: Properties to send as Super Properties
 */
export const SUPER_PROPS = [
  'email',
  'plan_type',
  'subscription_type',
  'account_type',
  'business_id',
  'company_name'
];

/**
 * Identifies user
 * Data can be retrieve using `window.analytics.user().traits()`
 * @settings Mixpanel Destination: Traits to set as People Properties
 */
export const IDENTIFY = {
  name: 'Identify',
  props: [
    'account_type',
    'active_projects',
    'business_id',
    'company_name',
    'email',
    'employee_range',
    'first_name',
    'last_name',
    'number_of_seats',
    'plan_type',
    'subscription_start_date',
    'subscription_type',
    'user_id',
    'user_role',
    'employee_range'
  ]
};

/**
 * Groups user
 * Data can be retrieve using `window.analytics.group().traits()`
 * @unused
 */
export const GROUP = {
  name: 'Group',
  props: ['business_id', 'company_name']
};

/**
 * Track event begins here
 */
export const UPGRADE_PLAN_VIEWED = {
  name: 'Upgrade_Plan_Viewed',
  props: ['feature_name']
};

export const UPGRADE_PLAN_REQUESTED = {
  name: 'Upgrade_Plan_Requested',
  props: ['feature_name']
};

export const UPGRADE_PLAN_LEARN_MORE = {
  name: 'Upgrade_Plan_Learn_More',
  props: ['feature_name']
};

export const APPROVALS_ENABLED = {
  name: 'Approvals_Enabled',
  props: ['project_id', 'project_name']
};

export const APPROVALS_DISABLED = {
  name: 'Approvals_Disabled',
  props: ['project_id', 'project_name']
};

export const COMPLIANCE_TAB_VIEWED = {
  name: 'Compliance_Viewed',
  props: ['project_id', 'project_name', 'tab_name']
};

/**
 * CHECKLIST EVENTS
 */
export const SAFETY_QUALITY_TAB_CLICKED = {
  name: 'Safety_Quality_Viewed',
  props: []
};

export const CHECKLIST_FEATURE_VIEWED = {
  name: 'Checklist_Feature_Viewed',
  props: []
};

export const CHECKLIST_START_CLICKED = {
  name: 'Checklist_Start',
  props: []
};

export const CHECKLIST_SAVED = {
  name: 'Checklist_Save',
  props: ['status', 'checklist_name', 'location_name']
};

export const CHECKLIST_SIGNATURE_COMPLETED = {
  name: 'Checklist_Signature_Complete',
  props: ['checklist_name', 'project_id', 'location_name']
};

export const CHECKLIST_ATTACHMENT_ADDED = {
  name: 'Checklist_Attachment',
  props: ['attachment_type']
};

export const CHECKLIST_OBSERVATION_ATTACHMENT = {
  name: 'Checklist_Observation_Attachment'
};

export const CHECKLIST_SHARED = {
  name: 'Checklist_Completed_Share',
  props: []
};

export const CHECKLIST_NEW_TEMPLATE_CLICKED = {
  name: 'Checklist_Create_Template',
  props: ['empty_state']
};

export const CHECKLIST_TEMPLATE_CREATED = {
  name: 'Checklist_Create_Template_Modal',
  props: ['source']
};

export const CHECKLIST_TEMPLATE_DRAFT_SAVED = {
  name: 'Checklist_Template_Save_Draft',
  props: ['checklist_template_name']
};

export const CHECKLIST_TEMPLATE_PUBLISHED = {
  name: 'Checklist_Template_Publish',
  props: ['checklist_template_name', 'mandatory']
};

export const CHECKLIST_TEMPLATE_QUESTION_ADDED = {
  name: 'Checklist_Template_Add_Question',
  props: ['checklist_template_name']
};

export const CHECKLIST_TEMPLATE_SECTION_ADDED = {
  name: 'Checklist_Template_Add_Section',
  props: ['checklist_template_name']
};

export const CHECKLIST_TEMPLATE_QUESTION_TYPE_SELECTED = {
  name: 'Checklist_Template_Question_Type',
  props: ['checklist_template_name', 'question_type']
};

export const CHECKLIST_TEMPLATE_DELETED = {
  name: 'Checklist_Template_Deleted',
  props: ['checklist_template_name']
};

export const CHECKLIST_DELETED = {
  name: 'Checklist_Deleted',
  props: ['checklist_name']
};

export const CHECKLIST_COMPLETED_VIEWED = {
  name: 'Checklist_Completed_Viewed',
  props: ['checklist_name']
};

export const CHECKLIST_STARTED = {
  name: 'Checklist_Start_Template',
  props: ['checklist_template_name', 'source']
};

export const CHECKLIST_PDF_DOWNLOADED = {
  name: 'Checklist_Download_PDF',
  props: ['checklist_name', 'project_id']
};

export const COMPANY_CHECKLIST_TAB_CLICKED = {
  name: 'Checklist_Templates_Tab',
  props: ['status']
};

export const COMPANY_CERTIFICATION_TAB_CLICKED = {
  name: 'Certification_Templates_Tab',
  props: ['status']
};

export const Checklist_Download_Excel = {
  name: 'Checklist_Download_Excel',
  props: []
};

export const MATERIAL_LOG_STARTED = {
  name: 'Material_Log_Started',
  props: []
};

export const MATERIAL_LOG_ADDED = {
  name: 'Material_Log_Added',
  props: [
    'cost_code_selected',
    'description_entered',
    'photos_attached',
    'videos_attached',
    'files_attached'
  ]
};

export const MATERIAL_LOG_VIEWED = {
  name: 'Material_Log_Viewed',
  props: [
    'cost_code_selected',
    'description_entered',
    'photos_attached',
    'videos_attached',
    'files_attached'
  ]
};

export const MATERIAL_LOG_EDITED = {
  name: 'Material_Log_Edited',
  props: [
    'cost_code_selected',
    'description_entered',
    'photos_attached',
    'videos_attached',
    'files_attached'
  ]
};

export const MATERIAL_LOG_ROLLOVER = {
  name: 'Material_Log_Rollover',
  props: ['rollover_count']
};

export const MATERIAL_LOG_DELETED = {
  name: 'Material_Log_Deleted',
  props: []
};

export const QUANTITIES_REPORT_STARTED = {
  name: 'Quantities_Report_Started',
  props: []
};

export const QUANTITIES_REPORT_DOWNLOADED = {
  name: 'Quantities_Report_Downloaded',
  props: []
};

export const QUANTITIES_REPORT_SCHEDULED = {
  name: 'Quantities_Report_Scheduled',
  props: ['frequency', 'start_week', 'end_week']
};

// Time Cards

export const TIMECARD_FEATURE_VIEWED = {
  name: 'Timecard_Feature_Viewed',
  props: []
};

export const TIMECARD_STARTED = {
  name: 'Timecard_Started',
  props: []
};

export const TIMECARD_ADDED = {
  name: 'Timecard_Added',
  props: [
    'timecard_type',
    'cost_code_selected',
    'description_entered',
    'photos_attached',
    'videos_attached',
    'files_attached'
  ]
};

export const TIMECARD_VIEWED = {
  name: 'Timecard_Viewed',
  props: [
    'timecard_type',
    'cost_code_selected',
    'description_entered',
    'photos_attached',
    'videos_attached',
    'files_attached'
  ]
};

export const TIMECARD_EDITED = {
  name: 'Timecard_Edited',
  props: [
    'timecard_type',
    'cost_code_selected',
    'description_entered',
    'photos_attached',
    'videos_attached',
    'files_attached'
  ]
};

export const TIMECARD_ROLLOVER = {
  name: 'Timecard_Rollover',
  props: ['rollover_count']
};

export const TIMECARD_DELETED = {
  name: 'Timecard_Deleted',
  props: []
};

export const TIMECARD_SIGNED = {
  name: 'Timecard_Signed',
  props: ['worker_id', 'period_start_date', 'period_end_date', 'signed_date']
};

// Standard work logs

export const WORKLOG_FEATURE_VIEWED = {
  name: 'Worklog_Feature_Viewed',
  props: []
};

export const WORKLOG_STARTED = {
  name: 'Worklog_Started',
  props: []
};

export const WORKLOG_VIEWED = {
  name: 'Worklog_Viewed',
  props: ['project_name', 'worklog_name', 'worklog_type', 'has_attachments']
};

export const WORKLOG_ADD = {
  name: 'Worklog_Add',
  props: ['worklog_name', 'worklog_type', 'has_attachments']
};

export const WORKLOG_DELETED = {
  name: 'Worklog_Deleted',
  props: []
};

export const WORKLOG_EDITED = {
  name: 'Worklog_Edited',
  props: ['project_name', 'worklog_name', 'worklog_type', 'has_attachments']
};

export const WORKLOG_ROLLOVER = {
  name: 'Worklog_Rollover',
  props: ['rollover_count']
};

// Equipment Log
export const EQUIPMENT_LOG_STARTED = {
  name: 'Equipment_Log_Started',
  props: []
};

export const EQUIPMENT_LOG_ADDED = {
  name: 'Equipment_Log_Added',
  props: ['equipment_type', 'cost_code_selected', 'status']
};

export const EQUIPMENT_CREATED = {
  name: 'Equipment_Created',
  props: ['equipment_type', 'supplier_entered', 'id_entered']
};

export const EQUIPMENT_UPLOADED = {
  name: 'Equipment_Uploaded',
  props: ['equipment_entered', 'rate_entered']
};

export const EQUIPMENT_LOG_NOTE_ADDED = {
  name: 'Equipment_Note_Added',
  props: ['hours_entered', 'note_entered']
};

export const EQUIPMENT_LOG_VIEWED = {
  name: 'Equipment_Log_Viewed',
  props: []
};

export const EQUIPMENT_LOG_EDITED = {
  name: 'Equipment_Log_Edited',
  props: ['start_date', 'end_date', 'cost_code', 'status']
};

export const EQUIPMENT_LOG_DELETED = {
  name: 'Equipment_Log_Deleted',
  props: []
};

export const EQUIPMENT_REPORT_STARTED = {
  name: 'Equipment_Report_Started',
  props: []
};

export const EQUIPMENT_REPORT_DOWNLOADED = {
  name: 'Equipment_Report_Downloaded',
  props: []
};

export const EQUIPMENT_REPORT_SCHEDULED = {
  name: 'Equipment_Report_Scheduled',
  props: ['frequency']
};

export const CERTIFICATION_REPORT_STARTED = {
  name: 'Certification_Report_Started',
  props: []
};

export const CERTIFICATION_REPORT_DOWNLOADED = {
  name: 'Certification_Report_Downloaded',
  props: []
};

export const VIOLATIONS_REPORT_SCHEDULED = {
  name: 'Violations_Report_Scheduled',
  props: ['frequency']
};

export const VIOLATIONS_REPORT_STARTED = {
  name: 'Violations_Report_Started',
  props: []
};

export const VIOLATIONS_REPORT_DOWNLOADED = {
  name: 'Violations_Report_Downloaded',
  props: []
};

export const CERTIFICATION_REPORT_SCHEDULED = {
  name: 'Certification_Report_Scheduled',
  props: ['frequency']
};

// Production
export const PRODUCTION_SETTINGS_VIEWED = {
  name: 'Production_Settings_Viewed',
  props: []
};

export const PRODUCTION_SETTINGS_OPENED = {
  name: 'Production_Settings_Opened',
  props: ['tab_name']
};

export const PRODUCTION_BUDGET_ADDED = {
  name: 'Production_Budget_Added',
  props: ['budgeted_hours_entered', 'budgeted_quantity_entered']
};

export const PRODUCTION_BUDGET_UPLOADED = {
  name: 'Production_Budget_Uploaded',
  props: [
    'budgeted_codes_entered',
    'budgeted_hours_entered',
    'budgeted_quantity_entered'
  ]
};

export const PRODUCTION_INSIGHTS_VIEWED = {
  name: 'Production_Insights_Viewed',
  props: [
    'tracking_mode',
    'budgeted_codes',
    'budgeted_hours',
    'budgeted_quantities'
  ]
};

export const PRODUCTION_INSIGHTS_DOWNLOADED = {
  name: 'Production_Insights_Downloaded',
  props: ['file_type']
};

export const PRODUCTION_TRENDS_INTERACTED = {
  name: 'Production_Trends_Interacted',
  props: []
};

// Segments
export const SEGMENTS_ENABLED = {
  name: 'Segments_Enabled',
  props: ['project_id', 'project_name', 'segment_count']
};

export const SEGMENTS_DISABLED = {
  name: 'Segments_Disabled',
  props: ['project_id', 'project_name']
};

export const SEGMENTS_SELECTED = {
  name: 'Segments_Selected',
  props: ['project_id', 'project_name', 'segment_name']
};

export const COMPANY_ACTIVITY_VIEWED = {
  name: 'Company_Activity_Viewed',
  props: []
};

export const COMPANY_ACTIVITY_DURATION_MODIFIED = {
  name: 'Company_Activity_Duration_Modified',
  props: ['duration']
};

export const COMPANY_ACTIVITY_FILTERS_MODIFIED = {
  name: 'Company_Activity_Filters_Modified',
  props: []
};

export const COMPANY_REPORT_ACTIVITY_VIEWED = {
  name: 'Company_Report_Activity_Viewed',
  props: []
};

export const COMPANY_REPORT_ACTIVITY_DURATION_MODIFIED = {
  name: 'Company_Report_Activity_Duration_Modified',
  props: ['duration']
};

export const COMPANY_REPORT_ACTIVITY_FILTERS_MODIFIED = {
  name: 'Company_Report_Activity_Filters_Modified',
  props: []
};

export const PROJECT_ACTIVITY_VIEWED = {
  name: 'Project_Activity_Viewed',
  props: []
};

export const PROJECT_ACTIVITY_DURATION_MODIFIED = {
  name: 'Project_Activity_Duration_Modified',
  props: ['project_name', 'duration']
};

export const PROJECT_ACTIVITY_FILTERS_MODIFIED = {
  name: 'Project_Activity_Duration_Modified',
  props: ['project_name']
};

export const PROJECT_INSIGHTS_VIEWED = {
  name: 'Project_Insights_Viewed',
  props: ['project_name']
};

export const COMPANY_INSIGHTS_VIEWED = {
  name: 'Company_Insights_Viewed',
  props: []
};

export const HOME_BUTTON_CLICKED = {
  name: 'Home_Button_Clicked',
  props: []
};

export const QUICK_PROJECT_SELECTED = {
  name: 'Quick_Project_Selector',
  props: ['project_name']
};

// Daily & Billing
export const TRIAL_REGISTERED = {
  name: 'Trial_Registered',
  props: ['trial_varient']
};

export const USER_REGISTERED = {
  name: 'User_Registered',
  props: ['is_collaborator', 'role']
};

export const PROJECT_ADDED = {
  name: 'Project_Add',
  props: ['project_id', 'project_name']
};

export const PROJECT_UPDATED = {
  name: 'Project_Update',
  props: ['project_id', 'project_name']
};

export const TEAM_MEMBER_ADDED = {
  name: 'Team_Member_Added',
  props: ['email', 'first_name', 'last_name']
};

export const TEAM_MEMBER_REMOVED = {
  name: 'Team_Member_Removed',
  props: ['email', 'first_name', 'last_name']
};

export const PROJECT_COLLABORATOR_ADDED = {
  name: 'Project_Collaborator_Add',
  props: [
    'project_id',
    'project_name',
    'collaborator_id',
    'collaborator_business_id',
    'collaborator_company_name'
  ]
};

export const PROJECT_COLLABORATOR_REMOVED = {
  name: 'Project_Collaborator_Remove',
  props: [
    'project_id',
    'project_name',
    'collaborator_id',
    'collaborator_business_id',
    'collaborator_company_name'
  ]
};

export const NOTE_ADDED = {
  name: 'Note_Add',
  props: [
    'project_name',
    'project_date',
    'note_category_name',
    'has_attachments'
  ]
};

export const ATTACHMENT_ADDED = {
  name: 'Attachment_Add',
  props: ['project_id', 'project_name', 'attachment_type', 'module']
};

export const ATTACHMENT_DESCRIPTION_ADDED = {
  name: 'Attachment_Description_Added',
  props: []
};

export const TASK_ADDED = {
  name: 'Task_Add',
  props: [
    'project_id',
    'project_name',
    'due_date',
    'task_id',
    'has_attachments'
  ]
};

export const TASK_COMPLETED = {
  name: 'Task_Complete',
  props: ['complete_date', 'project_id', 'project_name', 'task_name']
};

export const TASK_EDITED = {
  name: 'Task_Edit',
  props: ['project_name']
};

export const REPORT_SIGNED = {
  name: 'Report_Signed',
  props: [
    'report_date',
    'project_id',
    'project_name',
    'quick_sign',
    'status',
    'segment_name'
  ]
};

export const REPORT_BULK_SIGNED = {
  name: 'Report_Bulk_Signed',
  props: ['report_count']
};

export const REPORT_DAILY_DOWNLOADED = {
  name: 'Report_Daily_Downloaded',
  props: ['project_name', 'report_date']
};

export const REPORT_SUPER_DAILY_DOWNLOADED = {
  name: 'Report_Super_Daily_Downloaded',
  props: ['project_name', 'report_date']
};

export const PRODUCTION_INSIGHTS_PROGRESS_ADDED = {
  name: 'Production_Insights_Progress_Added',
  props: ['project_name']
};

export const TRACK_TIME_ADDED = {
  name: 'Track_Time_Added',
  props: ['company_level', 'billable_break', 'billable_lunch']
};

export const SURVEY_COMPLETED = {
  name: 'Survey_Complete',
  props: ['project_name']
};

export const SIGN_IN = {
  name: 'Sign_In',
  props: [],
  options: {
    integrations: {
      Mixpanel: false // To save on MTU - https://rakenapp.atlassian.net/browse/RAK-8274
    }
  }
};

export const UPGRADE_CTA_CLICKED = {
  name: 'Upgrade_CTA_Clicked',
  props: ['project_id', 'project_name', 'path_or_name']
};

export const TBT_UPLOADED = {
  name: 'TBT_Uploaded',
  props: ['company_level', 'talk_uploaded_count']
};

export const TBT_SCHEDULED = {
  name: 'TBT_Scheduled',
  props: [
    'project_id',
    'project_name',
    'company_level',
    'project_level',
    'members_count',
    'count'
  ]
};

export const TIMECARD_REPORT_DOWNLOADED = {
  name: 'Timecard_Report_Downloaded',
  props: [
    'file_type' // csv|xls|pdf
  ]
};

export const PAYROLL_VIEWED = {
  name: 'Payroll_Viewed',
  props: []
};

export const COMPANY_BILLING_VIEWED = {
  name: 'Company_Billing_Viewed'
};

export const COMPANY_BILLING_PAYMENT_VIEWED = {
  name: 'Company_Billing_Payment_Viewed'
};

export const COMPANY_BILLING_UPDOWNGRADE_VIEWED = {
  name: 'Company_UpDowngrade_Viewed'
};

export const COMPANY_BILLING_ADD_SEATS_VIEWED = {
  name: 'Company_Billing_Add_Seats_Viewed'
};

export const COMPANY_BILLING_CANCEL_VIEWED = {
  name: 'Company_Billing_Cancel_Viewed'
};

export const PROJECT_VIEWED = {
  name: 'Project_Viewed',
  props: ['project_id', 'project_name']
};

export const PROJECT_DASHBOARD_VIEWED = {
  name: 'Project_Dashboard_Viewed'
};

export const PROJECT_MEMBERS_VIEWED = {
  name: 'Project_Members_Viewed'
};

export const PROJECT_GALLERY_VIEWED = {
  name: 'Project_Gallery_Viewed'
};

export const COMPLIANCE_COMPLETED_TAB_VIEWED = {
  name: 'Compliance-COMPLETED DAILIES'
};

export const COMPLIANCE_MISSED_TAB_VIEWED = {
  name: 'Compliance-MISSED DAILIES'
};

export const PROJECT_PRODUCTION_VIEWED = {
  name: 'Project_Production_Viewed'
};

export const PROJECT_SURVEY_VIEWED = {
  name: 'Project_Survey_Viewed'
};

export const PROJECT_LIST_VIEWED = {
  name: 'Project_List_Viewed'
};

export const REGISTRATION_VIEWED = {
  name: 'Registration_Viewed'
};

export const TIMESHEETS_VIEWED = {
  name: 'Timesheets_Viewed'
};

export const TIMESHEET_EDITED = {
  name: 'Timesheet_Edited',
  props: ['shift']
};

export const TIMESHEET_APPROVED = {
  name: 'Timesheet_Approved'
};

export const TIMESHEET_UNAPPROVED = {
  name: 'Timesheet_Unapproved'
};

export const INTEGRATION_CONNECTED = {
  name: 'Integration_Connected',
  props: ['integration_name']
};

export const INTEGRATION_DISCONNECTED = {
  name: 'Integration_Disconnected',
  props: ['integration_name']
};

export const INTEGRATION_REAUTHORIZED = {
  name: 'Integration_Reauthorized',
  props: ['integration_name']
};

export const INTEGRATION_TOKENACTIVE_UPDATED = {
  name: 'Integration_TokenActive_Updated',
  props: ['integration_name', 'update_type']
};

export const INTEGRATION_ERROR = {
  name: 'Integration_Error',
  props: ['integration_name']
};

export const INTEGRATION_TIME_SYNCED = {
  name: 'Integration_Time_Synced',
  props: ['integration_name']
};

export const INTEGRATION_CONTACT_CLICKED = {
  name: 'Integration_Contact_Clicked',
  props: ['integration_name']
};

export const INTEGRATION_FLOW_ENABLED = {
  name: 'Integration_Flow_Enabled',
  props: ['integration_name', 'data_flow_name']
};

export const INTEGRATION_FLOW_DISABLED = {
  name: 'Integration_Flow_Disabled',
  props: ['integration_name', 'data_flow_name']
};

export const COMPANY_PAST_ACTIVITY_PANEL_OPENED = {
  name: 'Company_Past_Activity_Panel_Opened',
  props: ['project_id', 'project_name', 'report_date']
};

export const COMPANY_PAST_ACTIVITY_HEADER_CLICKED = {
  name: 'Company_Past_Activity_Header_Clicked',
  props: ['project_id', 'project_name', 'report_date']
};

export const COMPANY_TEAM_VIEWED = {
  name: 'Company_Team_Viewed'
};

export const COMPANY_WORKERS_VIEWED = {
  name: 'Company_Workers_Viewed'
};

export const COMPANY_INFO_VIEWED = {
  name: 'Company_Info_Viewed'
};

export const COMPANY_ROLES_VIEWED = {
  name: 'Company_Roles_Viewed'
};

export const COMPANY_PDF_LOGO_VIEWED = {
  name: 'Company_Pdf_Logo_Viewed'
};

export const COMPANY_PROJECT_TEMPLATES_VIEWED = {
  name: 'Company_Project_Templates_Viewed'
};

export const COMPANY_PRODUCTION_VIEWED = {
  name: 'Company_Production_Viewed'
};

export const COMPANY_CHECKLISTS_VIEWED = {
  name: 'Company_Checklists_Viewed'
};

export const COMPANY_TOOLBOX_TALKS_VIEWED = {
  name: 'Company_Toolbox_Talks_Viewed'
};

export const COMPANY_INTEGRATIONS_VIEWED = {
  name: 'Company_Integrations_Viewed'
};

export const COMPANY_CERTIFICATIONS_VIEWED = {
  name: 'Company_Certifications_Viewed'
};

export const USER_PROFILE_VIEWED = {
  name: 'User_Profile_Viewed'
};

export const USER_PROJECTS_VIEWED = {
  name: 'User_Projects_Viewed'
};

export const USER_NOTIFICATIONS_VIEWED = {
  name: 'User_Notifications_Viewed'
};

export const USER_EMAIL_PREFERENCES_VIEWED = {
  name: 'User_Email_Preferences_Viewed'
};

export const USER_TASKS_VIEWED = {
  name: 'User_Tasks_Viewed'
};

export const TASKS_REPORT_STARTED = {
  name: 'Tasks_Report_Started'
};

export const TASKS_REPORT_DOWNLOADED = {
  name: 'Tasks_Report_Downloaded'
};

export const MANPOWER_REPORT_STARTED = {
  name: 'Manpower_Report_Started'
};

export const MANPOWER_REPORT_DOWNLOADED = {
  name: 'Manpower_Report_Downloaded'
};

export const PROJECT_SUMMARY_REPORT_STARTED = {
  name: 'Project_Summary_Report_Started'
};

export const PROJECT_SUMMARY_REPORT_DOWNLOADED = {
  name: 'Project_Summary_Report_Downloaded'
};

export const PROJECT_SUMMARY_REPORT_SCHEDULED = {
  name: 'Project_Summary_Report_Scheduled'
};

export const TBT_REPORT_STARTED = {
  name: 'TBT_Report_Started'
};

export const TBT_REPORT_DOWNLOADED = {
  name: 'TBT_Report_Downloaded'
};

export const MESSAGING_FEATURE_VIEWED = {
  name: 'Messaging_Feature_Viewed',
  props: ['messaging_enabled']
};

export const MESSAGING_FEATURE_ENABLED = {
  name: 'Messaging_Feature_Enabled'
};

// Over Time Rules Events
export const TIMECARD_REALLOCATEHOURS = {
  name: 'TimeCard_ReallocateHours',
  props: []
};

export const TIMECARD_REALLOCATEHOURS_ACCEPTED = {
  name: 'Timecard_End_Time_Reallocation_Accepted',
  props: []
};

export const TIMECARD_END_TIME_SUGGESTION_ACCEPTED = {
  name: 'Timecard_End_Time_Suggestion_Accepted',
  props: []
};

export const TIMECARD_END_TIME_SUGGESTION_OPENED = {
  name: 'Timecard_End_Time_Suggestion_Opened',
  props: []
};

export const OVERTIMERULESET_ADDED = {
  name: 'OvertimeRuleSet_Added',
  props: []
};

export const OVERTIMERULESET_EDITED = {
  name: 'OvertimeRuleSet_Edited',
  props: []
};

export const OVERTIMERULESET_DELETED = {
  name: 'OvertimeRuleSet_Deleted',
  props: []
};

export const OVERTIMERULES_PROJECTSETTINGSUPDATE = {
  name: 'OvertimeRules_ProjectSettingsUpdate',
  props: []
};

export const OVERTIMERULES_TEMPLATESUPDATE = {
  name: 'OvertimeRules_TemplatesUpdate',
  props: []
};

export const DETAILED_WEATHER_VIEWED = {
  name: 'Detailed_Weather_Viewed',
  props: []
};

export const TOGGLE_CLASSIC_UI = {
  name: 'Toggle_Classic_UI',
  props: []
};

export const TOGGLE_NEW_UI = {
  name: 'Toggle_New_UI',
  props: []
};

export const MEMBER_CERTIFICATION_ADDED = {
  name: 'Member_Certification_Added',
  props: []
};

export const MEMBER_CERTIFICATION_EDITED = {
  name: 'Member_Certification_Edited',
  props: []
};

export const MEMBER_CERTIFICATION_DELETED = {
  name: 'Member_Certification_Deleted',
  props: []
};

// Project Map Events
export const PROJECT_MAP_OPENED = {
  name: 'Project_Map_Opened',
  props: []
};

export const PROJECT_MAP_PIN_OPENED = {
  name: 'Project_Map_Pin_Opened',
  props: []
};

export const PROJECT_MAP_SELECT_PROJECT_DROPDOWN = {
  name: 'Project_Map_Select_Project_Dropdown',
  props: []
};

export const PROJECT_MAP_SELECT_PROJECT_SEARCH = {
  name: 'Project_Map_Select_Project_Search',
  props: []
};

export const PROJECT_MAP_STATUS_FILTER = {
  name: 'Project_Map_Status_Filter',
  props: []
};

export const PROJECT_MAP_VIEW_PROJECT = {
  name: 'Project_Map_View_Project',
  props: []
};

export const PROJECT_MAP_VIEW_EVENTS = {
  name: 'Project_Map_View_Events',
  props: []
};

export const EVENT_MAP_OPENED = {
  name: 'Event_Map_Opened',
  props: []
};

export const EVENT_MAP_POPPER_OPENED = {
  name: 'Event_Map_Popper_Opened',
  props: []
};

export const LABOR_MAP_OPENED = {
  name: 'Labor_Map_Opened',
  props: []
};

export const LABOR_MAP_FILTER = {
  name: 'Labor_Map_Filter',
  props: []
};

export const LABOR_MAP_VIEW_PROFILE = {
  name: 'Labor_Map_View_Profile',
  props: []
};

export const LABOR_MAP_DISPLAY_FILTERS = {
  name: 'Labor_Map_Display_Filters',
  props: []
};

export const LABOR_MAP_EXPORT = {
  name: 'Labor_Map_Export',
  props: []
};

export const PROJECT_LOCATIONS_FEATURE_VIEWED = {
  name: 'Project_Locations_Feature_Viewed',
  props: ['project_id', 'project_name']
};

export const PROJECT_LOCATION_ADDED = {
  name: 'Project_Location_Added',
  props: ['project_id', 'project_name', 'location_name']
};

export const PROJECT_LOCATION_EDITED = {
  name: 'Project_Location_Edited',
  props: ['project_id', 'project_name', 'location_name']
};

export const PROJECT_LOCATION_DELETED = {
  name: 'Project_Location_Deleted',
  props: ['project_id', 'project_name', 'location_name']
};

export const CHECKLIST_TYPE_ADDED = {
  name: 'Checklist_Type_Added',
  props: ['checklist_type_class', 'checklist_type_name']
};

export const CHECKLIST_TYPE_EDITED = {
  name: 'Checklist_Type_Edited',
  props: ['checklist_type_class', 'checklist_type_name']
};

export const CHECKLIST_TYPE_DELETED = {
  name: 'Checklist_Type_Deleted',
  props: ['checklist_type_class', 'checklist_type_name']
};

export const CHECKLIST_TEMPLATE_ADD_TYPE = {
  name: 'Checklist_Template_Add_Type',
  props: ['checklist_template_name', 'checklist_type_name']
};

export const CHECKLIST_SCHEDULE_ADDED = {
  name: 'Checklist_Schedule_Added',
  props: [
    'checklist_template_uuid',
    'location_uuid',
    ' responsible_workerclassification_uuid',
    'project_uuid',
    'enable_reminder ',
    'start_date',
    'start_time',
    'end_date',
    'schedule_frequency',
    'display_before'
  ]
};

export const CHECKLIST_SCHEDULE_EDITED = {
  name: 'Checklist_Schedule_Edited',
  props: [
    'checklist_template_uuid',
    'location_uuid',
    ' responsible_workerclassification_uuid',
    'project_uuid',
    'enable_reminder ',
    'start_date',
    'start_time',
    'end_date',
    'schedule_frequency',
    'display_before'
  ]
};

export const CHECKLIST_SCHEDULE_DELETED = {
  name: 'Checklist_Schedule_Deleted',
  props: []
};

export const CERTIFICATION_ADDED = {
  name: 'Certification_Added',
  props: ['certification_name', 'certification_type_name']
};

export const CERTIFICATION_EDITED = {
  name: 'Certification_Edited',
  props: ['certification_name', 'certification_type_name']
};

export const CERTIFICATION_DELETED = {
  name: 'Certification_Type_Deleted',
  props: []
};

export const CERTIFICATION_TYPE_ADDED = {
  name: 'Certification_Type_Added',
  props: ['certification_type_name', 'certification_type_description']
};

export const CERTIFICATION_TYPE_EDITED = {
  name: 'Certification_Type_Edited',
  props: ['certification_type_name', 'certification_type_description']
};

export const CERTIFICATION_TYPE_DELETED = {
  name: 'Certification_Type_Deleted',
  props: []
};

export const OBSERVATION_ADDED = {
  name: 'Observation_Added',
  props: [
    'project_name',
    'project_date',
    'observation_category_name',
    'has_attachments'
  ]
};

export const OBSERVATION_STATUS_UPDATE = {
  name: 'Observation_Status_Update',
  props: ['observation_status']
};

export const RESOLUTION_PHOTO_ADDED = {
  name: 'Resolution_Photo_Added',
  props: ['file_name']
};

export const RESOLUTION_PHOTO_DELETED = {
  name: 'Resolution_Photo_Deleted',
  props: ['file_name']
};

export const OBSERVATION_DOWNLOAD_CSV = {
  name: 'Observation_Download_CSV',
  props: []
};

export const PROJECT_SAFETY_INSIGHTS_VIEWED = {
  name: 'Project_Safety_Insights_Viewed',
  props: []
};

export const PROJECT_SAFETY_INSIGHTS_PDF = {
  name: 'Project_Safety_Insights_PDF',
  props: []
};

export const PROJECT_QUALITY_INSIGHTS_VIEWED = {
  name: 'Project_Quality_Insights_Viewed',
  props: []
};

export const PROJECT_QUALITY_INSIGHTS_PDF = {
  name: 'Project_Quality_Insights_PDF',
  props: []
};

export const COMPANY_SAFETY_INSIGHTS_VIEWED = {
  name: 'Company_Safety_Insights_Viewed',
  props: []
};

export const COMPANY_SAFETY_INSIGHTS_PDF = {
  name: 'Company_Safety_Insights_PDF',
  props: []
};

export const COMPANY_QUALITY_INSIGHTS_VIEWED = {
  name: 'Company_Quality_Insights_Viewed',
  props: []
};

export const COMPANY_QUALITY_INSIGHTS_PDF = {
  name: 'Company_Quality_Insights_PDF',
  props: []
};

export const TIMESHEET_CREATE_TIMECARD_START = {
  name: 'Timesheet_Create_Timecard_Start',
  props: []
};

export const TIMESHEET_CREATE_TIMECARD = {
  name: 'Timesheet_Create_Timecard',
  props: []
};

export const TIMESHEET_DELETE_TIMECARD = {
  name: 'Timesheet_Delete_Timecard',
  props: []
};

export const FORM_FEATURE_VIEWED = {
  name: 'Form_Feature_Viewed',
  props: ['project_name']
};

export const FORM_UPLOADED = {
  name: 'Form_Uploaded',
  props: ['project_name', 'form_name', 'content_type']
};

export const FORM_SHARED = {
  name: 'Form_Shared',
  props: ['project_name', 'form_name']
};

export const FORM_DOWNLOADED = {
  name: 'Form_Downloaded',
  props: ['project_name', 'form_name']
};

export const FORM_TEMPLATE_USED = {
  name: 'Form_Template_Used',
  props: ['form_template_name']
};

export const FORM_DUPLICATED = {
  name: 'Form_Duplicated',
  props: ['project_name', 'orignal_form_name', 'new_form_name']
};

export const FORM_RENAMED = {
  name: 'Form_Renamed',
  props: ['project_name', 'orignal_form_name', 'new_form_name']
};

export const FORM_DELETED = {
  name: 'Form_Deleted',
  props: ['project_name', 'form_name']
};

export const FORM_VIEWED = {
  name: 'Form_Viewed',
  props: ['project_name', 'form_name']
};

export const FORM_TEMPLATE_FEATURE_VIEWED = {
  name: 'Form_Template_Feature_Viewed'
};

export const FORM_TEMPLATE_UPLOADED = {
  name: 'Form_Template_Uploaded',
  props: ['form_template_name']
};

export const FORM_TEMPLATE_DOWNLOADED = {
  name: 'Form_Template_Downloaded',
  props: ['form_template_name']
};

export const FORM_TEMPLATE_RENAMED = {
  name: 'Form_Template_Renamed',
  props: ['orignal_template_name', 'new_template_name']
};

export const FORM_TEMPLATE_DELETED = {
  name: 'Form_Template_Deleted',
  props: ['form_template_name']
};

export const FORM_TEMPLATE_VIEWED = {
  name: 'Form_Template_Viewed',
  props: ['form_template_name']
};

export const FORM_TEMPLATE_EDITED = {
  name: 'Form_Template_Edited',
  props: ['form_template_name']
};

export const DOCUMENTS_NEW_DOCUMENT_UPLOADED = {
  name: 'Documents_New_Document_Uploaded',
  props: ['project_id', 'project_name', 'file_name', 'content_type']
};

export const DOCUMENTS_NEW_FOLDER_CREATED = {
  name: 'Documents_New_Folder_Created',
  props: ['name', 'project_id', 'project_name']
};

export const DOCUMENTS_FOLDER_DOWNLOADED = {
  name: 'Documents_Folder_Downloaded',
  props: ['project_id', 'project_name']
};

export const DOCUMENTS_ANNOTATION_SAVED = {
  name: 'Documents_Annotation_Saved',
  props: ['project_id', 'project_name']
};

export const DOCUMENTS_BULK_DOWNLOAD = {
  name: 'Documents_Bulk_Download',
  props: ['project_id', 'project_name']
};

export const GROUP_ADDED = {
  name: 'Group_Added',
  props: ['group_class', 'group_name']
};

export const GROUP_EDITED = {
  name: 'Group_Edited',
  props: ['group_class', 'group_name']
};

export const GROUP_DELETED = {
  name: 'Group_Deleted',
  props: ['group_class', 'group_name']
};

export const CLEAR_DEVICE_CHEAT = {
  name: 'Clear_Device_Cheat',
  props: ['cheater_name', 'user_name', 'submit_date']
};

// Production map events
export const MAP_CLICKED = {
  name: 'Map_Clicked',
  props: []
};

export const MAP_SEARCH_BAR_CLICKED = {
  name: 'Map_Search_Bar_Clicked',
  props: []
};

export const MAP_EMPLOYEE_CLICKED = {
  name: 'Map_Employee_Clicked',
  props: []
};

export const MAP_EDIT_TIME_CARD_CLICKED = {
  name: 'Map_Edit_Time_Card_Clicked',
  props: []
};

export const MAP_VIOLATIONS_ENABLED = {
  name: 'Map_Violations_Enabled',
  props: []
};

export const MAP_NO_GPS_ENABLED = {
  name: 'Map_No_GPS_Enabled',
  props: []
};

export const MAP_CALENDAR_CLICKED = {
  name: 'Map_Calendar_Clicked',
  props: []
};

export const MAP_EVENT_PIN_CLICKED = {
  name: 'Map_Event_Pin_Clicked',
  props: []
};

export const MAP_EVENT_EMPLOYEE_CLICKED = {
  name: 'Map_Event_Employee_Clicked',
  props: []
};

export const TIMESHEETS_FILTERS = {
  name: 'Timesheets_Filters',
  props: []
};

export const PHOTO_ID_SUMMARY_FILTERS = {
  name: 'Photo_Id_Summary_Filters',
  props: []
};

export const PHOTO_ID_DETAIL_FILTERS = {
  name: 'Photo_Id_Detail_Filters',
  props: []
};

export const QUESTIONS_REVIEW = {
  name: 'Questions_Review',
  props: []
};

export const QUESTIONS_INDIVIDUAL = {
  name: 'Questions_Individual',
  props: []
};

export const QUESTIONS_TEMPLATES = {
  name: 'Questions_Templates',
  props: []
};

export const QUESTIONS_COMPLETED = {
  name: 'Questions_Completed',
  props: []
};

export const QUESTIONS_SHARE = {
  name: 'Questions_Share',
  props: []
};

export const PHOTO_ID_REVIEW = {
  name: 'PhotoID_Review',
  props: []
};

export const PHOTO_ID_EMPLOYEE_REVIEW = {
  name: 'PhotoID_Employee_Review',
  props: []
};

export const PHOTO_ID_EMPLOYEE_PHOTO_DETAILS = {
  name: 'PhotoID_Employee_Photo_Details',
  props: []
};

export const PHOTO_ID_APPROVE = {
  name: 'PhotoID_Approve',
  props: []
};

export const PHOTO_ID_VIEW_TIMECARD = {
  name: 'PhotoID_View_Time_Card',
  props: []
};

export const PHOTO_ID_DOWNLOAD_PHOTO = {
  name: 'PhotoID_Download_Photo',
  props: []
};

export const COMPANY_INCIDENTS_VIEWED = {
  name: 'Company_Incidents_Viewed',
  props: []
};

export const COMPANY_INCIDENTS_SETTINGS_VIEWED = {
  name: 'Company_Incidents_Settings_Viewed',
  props: []
};

export const COMPANY_INCIDENTS_SETTINGS_SAVED = {
  name: 'Company_Incidents_Settings_Saved',
  props: [
    'establishment_name',
    'city',
    'state',
    'naics_code',
    'establishment_size',
    'average_annual_employees',
    'total_hours_worked'
  ]
};

export const COMPANY_INCIDENTS_REPORT_DOWNLOADED = {
  name: 'Company_Incidents_Report_Downloaded',
  props: ['incident_report_name']
};

export const INCIDENT_FEATURE_VIEWED = {
  name: 'Incident_Feature_Viewed',
  props: []
};

export const INCIDENT_CREATED = {
  name: 'Incident_Created',
  props: [
    'project_id',
    'project_name',
    'account_type',
    'incident_title',
    'incident_status',
    'incident_recordable',
    'incident_has_notifications',
    'incident_date',
    'incident_injury_or_illness_type',
    'incident_employee_job_title',
    'incident_outcome'
  ]
};

export const INCIDENT_VIEWED = {
  name: 'Incident_Viewed',
  props: [
    'project_id',
    'project_name',
    'account_type',
    'incident_title',
    'incident_status',
    'incident_recordable',
    'incident_has_notifications',
    'incident_date',
    'incident_injury_or_illness_type',
    'incident_employee_job_title',
    'incident_outcome'
  ]
};

export const INCIDENT_EDITED = {
  name: 'Incident_Edited',
  props: [
    'project_id',
    'project_name',
    'account_type',
    'incident_title',
    'incident_status',
    'incident_recordable',
    'incident_has_notifications',
    'incident_date',
    'incident_injury_or_illness_type',
    'incident_employee_job_title',
    'incident_outcome'
  ]
};

export const INCIDENT_DELETED = {
  name: 'Incident_Deleted',
  props: ['project_id', 'project_name', 'account_type']
};

export const WATERMARKS_ENABLED = {
  name: 'Watermarks_Enabled',
  props: []
};

export const WATERMARKS_TEMPLATE = {
  name: 'Watermarks_Template',
  props: []
};
