import MobxReactForm from 'mobx-react-form';
import addDemoPlugins from '../utils/extendedPlugins';
import { t } from 'utils/translate';

const addDemoOptions = {
  validateOnInit: false,
  showErrorsOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const addDemoFields = ['demoCompanyName', 'templateCompanyId'];

const addDemoRules = {
  demoCompanyName: 'string|min:3|max:255',
  templateCompanyId: 'required'
};

const addDemoLabels = {
  demoCompanyName: t('Demo Company Name'),
  templateCompanyId: t('Demo Company Template')
};

class AddDemoForm extends MobxReactForm {}

export {
  AddDemoForm,
  addDemoOptions,
  addDemoFields,
  addDemoRules,
  addDemoLabels,
  addDemoPlugins
};
