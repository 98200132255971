import request from 'axios';
import orderBy from 'lodash.orderby';
import isEqual from 'lodash.isequal';
import sortBy from 'lodash.sortby';
import { observable, action, computed } from 'mobx';

import * as Validator from 'validatorjs';

import UIStore from '../UIStore';

import history from 'utils/history';
import { t } from 'utils/translate';

import alertErrorHandler from 'utils/alertErrorHandler';
import crossOriginDownload from 'utils/crossOriginDownload';

export default class SettingsMaterialsImportUI extends UIStore {
  @observable file;

  @observable step;

  // Workers
  @observable importedMaterialsPageSize;
  @observable importedMaterialsPage;

  constructor(options) {
    super(options);

    this.file = null;

    this.step = 'import';

    this.importedMaterialsPageSize = 10;
    this.importedMaterialsPage = 1;
    this.importedMaterials = observable([]);
  }

  @action.bound setStep(step) {
    this.step = step;
  }

  @action.bound setup(step = 'import') {
    this.setStep(step);
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound cancelMaterials() {
    this.importedMaterials.clear();
    this.setStep('import');
  }

  @action.bound async submitMaterialsForm() {
    if (this.saving) return;

    try {
      this.saving = true;

      const formData = new FormData();
      formData.append('file', this.file);

      await request.post(
        `${this.rootStore.urlMicroService(
          'performanceTracking'
        )}/materials/import?projectUuid=${this.project.uuid}&limit=20&offset=0`,
        formData
        //config
      );

      // TODO: Once API suppoerts
      // this.parent.sortByLastCreated();
      this.parent.refetchMaterials();
      this.cancelMaterialsImport();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Materials added')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound cancelMaterialsImport() {
    history.push(
      `${this.project.viewUrl}/settings/production-tracking/materials`
    );
  }

  @action.bound
  downloadMaterialsCSV(event) {
    event.preventDefault();

    crossOriginDownload(
      `${this.assetsURL}/csv/materials.csv`,
      `${t('materials')}.csv`
    );
  }

  @action.bound importMaterialsCSV(file) {
    this.clearValidationDetails();
    this.file = file;

    const reader = new FileReader();

    reader.onload = event => {
      const text = event.target.result;
      this.processCSV(text);
    };

    reader.readAsText(this.file);
  }

  @action.bound processCSV(text, delim = ',') {
    let headers = text
      .trim()
      .slice(0, text.indexOf('\n'))
      .split(delim);

    const rows = text
      .trim()
      .slice(text.indexOf('\n') + 1)
      .split('\n');

    headers = headers.map(header => header.replace('\r', '').replace(/"/g, ''));

    const expectedHeaders = ['MATERIAL', 'UNIT OF MEASUREMENT'];

    if (!isEqual(sortBy(expectedHeaders), sortBy(headers))) {
      this.setValidationDetails([
        {
          field: 'import',
          fieldMessage: t(
            'Your CSV is empty or does not follow the correct format. Please use the pre-formatted template.'
          )
        }
      ]);

      return;
    }

    const materialFields = rows.map(row => {
      const values = row.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);

      //check if a number of values in a row equals to a header columns
      if (values.length !== headers.length) {
        this.setValidationDetails([
          {
            field: 'import',
            fieldMessage: t(
              'Your CSV is empty or does not follow the correct format. Please use the pre-formatted template.'
            )
          }
        ]);

        return;
      }

      const eachObject = headers.reduce((obj, header, i) => {
        obj[
          header
            .trim()
            .replace(' OF MEASUREMENT', '')
            .toLowerCase()
        ] = values[i].trim();
        return obj;
      }, {});
      return eachObject;
    });

    this.populateMaterialsFormFromCSV(materialFields);
  }

  @action.bound populateMaterialsFormFromCSV(costCodeFields) {
    this.importedMaterials.replace(
      costCodeFields.filter(costCode => {
        let validation = new Validator(costCode, {
          material: 'string|required|max:255',
          unit: 'string|required'
        });

        return !validation.fails();
      })
    );

    if (!this.hasImportedMaterials) {
      this.setValidationDetails([
        {
          field: 'import',
          fieldMessage:
            'The CSV file has no valid entries. Please check it and upload again.'
        }
      ]);

      return;
    }

    // Limit to 1000 valid records
    this.importedMaterials.replace(this.importedMaterials.slice(0, 1000));

    this.setStep('materials');
  }

  @computed get hasImportedMaterials() {
    return this.importedMaterials.length > 0;
  }

  @computed get showImportedMaterialsPagination() {
    return this.importedMaterialsTotalPages > 1;
  }

  @computed get sortedImportedMaterials() {
    return orderBy(this.importedMaterials, ['name'], ['asc']);
  }

  @computed get paginatedImportedMaterials() {
    return this.sortedImportedMaterials.slice(
      (this.importeMaterialsPage - 1) * this.importedMaterialsPageSize,
      (this.importedMaterialsPage - 1) * this.importedMaterialsPageSize +
        this.importedMaterialsPageSize
    );
  }

  @computed
  get importedMaterialsTotalPages() {
    return Math.ceil(
      this.sortedImportedMaterials.length / this.importedMaterialsPageSize
    );
  }

  @action.bound setImportedMaterialsPage(event, page) {
    this.importedMaterialsPage = page;
  }

  @action.bound removeImportedCostCode(costCode) {
    this.importedMaterials.remove(costCode);

    if (!this.hasImportedMaterials) {
      this.setStep('import');
    }
  }

  @action.bound clearUIState() {
    this.step = 'import';
    this.file = null;
    this.clearValidationDetails();
    this.loading = false;
    this.importedMaterials.clear();
    this.importedMaterials.page = 1;
  }
}
