import { RakenCollection } from '../../lib';
import SuperAdminSuperUser from '../../models/superAdmin/SuperAdminSuperUser';

export default class SuperAdminProjects extends RakenCollection {
  model() {
    return SuperAdminSuperUser;
  }

  url() {
    return '/ra/sadmin/superusers';
  }
}
