import request from 'axios';
import copy from 'copy-to-clipboard';
import { action, computed, observable } from 'mobx';
import UIStore from '../UIStore';

import BillingInfoEditUI from 'stores/ui/billing/BillingInfoEditUI';
import alertErrorHandler from 'utils/alertErrorHandler';

import history from 'utils/history';
import { t } from 'utils/translate';
import { axiosIgnore404 } from 'utils/axiosIgnore404';

export default class BillingInfoUI extends UIStore {
  @observable loading;
  @observable empty;

  constructor(options) {
    super(options);

    this.loading = true;
    this.empty = false;

    this.billingInfoEditUI = new BillingInfoEditUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @computed get billingUI() {
    return this.parent;
  }

  @computed get company() {
    return this.billingUI.company;
  }

  @computed get subscription() {
    return this.billingUI.subscription;
  }

  @computed get billingInfo() {
    return this.billingUI.billingInfo;
  }

  @computed get invoicesUI() {
    return this.billingUI.invoicesUI;
  }

  @action.bound setup() {
    this.fetchBillingInfo();
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.billingInfo.clear();
    this.loading = true;
    this.empty = false;
  }

  @action.bound async fetchBillingInfo() {
    this.loading = true;

    try {
      await this.billingInfo.fetch({ axios: axiosIgnore404 });
      this.empty = this.billingInfo.isNew;
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
      this.empty = true;
    } finally {
      this.loading = false;
    }
  }

  @computed get showUI() {
    return !this.loading;
  }

  @computed get showEmptyState() {
    return this.showUI && (this.empty || this.billingInfo.isNew);
  }

  @computed get disableEdit() {
    return Boolean(
      this.company.isInternal || this.subscription.onTrialOrStarterPlan
    );
  }

  @action.bound editBillingInfo() {
    history.push(
      `/companies/${this.company.uuid}/subscription-billing/edit-billing-info`
    );
  }

  @action.bound copyExternalLinkToClipboard(event) {
    event.preventDefault();

    copy(this.billingInfo.externalLink);

    this.notifications.pushNotification({
      snackbar: 'warning',
      icon: 'checkmark',
      title: t('Copied to clipboard')
    });
  }

  @computed get disableSendAccountManagementLink() {
    if (this.company.isInternal || this.subscription.onTrialOrStarterPlan)
      return true;

    return !this.company.email;
  }

  @action.bound sendAccountManagementLink() {
    this.showModal('SendAccountManagementLinkModal');
  }

  @action.bound cancelSendAccountManagementLink() {
    this.hideActiveModal();
  }

  @action.bound async confirmSendAccountManagementLink() {
    this.saving = true;

    try {
      const response = await request.put(
        `ra/companies/${this.company.uuid}/billing/billing-info/collect?sendEmail=true`
      );

      await this.hideActiveModal();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: response.data.message
      });

      this.billingUI.refreshAll();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }
}
