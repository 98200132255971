import { Model } from 'mobx-mc';

export default class Notification extends Model {
  get restAttributes() {
    return [
      'onUndo',
      'onDismiss',
      'title',
      'text',
      'showUndo',
      'validationDetails',
      'snackbar',
      'icon',
      'autoHideDuration',
      'vertical',
      'horizontal'
    ];
  }
}
