import request from 'axios';
import uniqBy from 'lodash.uniqby';
import { computed, action, observable } from 'mobx';

import CompanyRolesUI from 'stores/ui/CompanyRolesUI';
import SuperAdminCompanyPermissions from 'stores/collections/superAdmin/SuperAdminCompanyPermissions';

export default class SuperAdminCompanyRolesUI extends CompanyRolesUI {
  @observable lockingPermissions;
  @observable unlockingPermissions;

  constructor(options) {
    super(options);

    this.permissions = new SuperAdminCompanyPermissions(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.permissionsToLock = observable([]);
    this.lockingPermissions = false;
    this.permissionsToUnlock = observable([]);
    this.unlockingPermissions = false;
  }

  @action.bound setup() {
    this.fetchPermissions();
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.permissionsToLock.clear();
    this.lockingPermissions = false;
    this.permissionsToUnlock.clear();
    this.unlockingPermissions = false;
  }

  @computed
  get companyPermissions() {
    return this.permissions;
  }

  @computed
  get activeCompany() {
    return this.parent.activeCompany;
  }

  @computed get lockedPermissions() {
    return this.permissions.models.filter(permission => permission.locked);
  }

  @computed get hasLockedPermissions() {
    return this.lockedPermissions.length > 0;
  }

  @action.bound
  showLockPermissionsModal() {
    this.showModal('LockPermissionsModal');
  }

  @computed get lockPermissionOptions() {
    return this.companyPermissions.models
      .filter(permission => permission.role === 'ROLE_ADMIN')
      .map(permission => {
        return {
          permissionEnum: permission.permissionEnum,
          description: permission.description
        };
      });
  }

  @action selectPermissionToLock(event, permissionEnum) {
    if (event.target.checked) {
      this.permissionsToLock.push(permissionEnum);
    } else {
      this.permissionsToLock.remove(permissionEnum);
    }
  }

  @action.bound
  cancelLockPermissionsModal() {
    this.hideActiveModal().then(() => {
      this.permissionsToLock.clear();
    });
  }

  @action.bound
  async confirmLockPermissionsModal() {
    this.lockingPermissions = true;

    const permissions = this.companyPermissions.models.filter(permission =>
      this.permissionsToLock.includes(permission.permissionEnum)
    );

    permissions.forEach(permission => (permission.locked = true));

    await request.patch(this.companyPermissions.url(), permissions.slice());

    await this.hideActiveModal();

    this.permissionsToLock.clear();
    this.lockingPermissions = false;
  }

  @action.bound
  showUnlockPermissionsModal() {
    this.showModal('UnlockPermissionsModal');
  }

  @computed get unlockPermissionOptions() {
    return uniqBy(
      this.companyPermissions.models
        .filter(permission => permission.locked)
        .map(permission => {
          return {
            permissionEnum: permission.permissionEnum,
            description: permission.description
          };
        }),
      'permissionEnum'
    );
  }

  @action selectPermissionToUnlock(event, permissionEnum) {
    if (event.target.checked) {
      this.permissionsToUnlock.push(permissionEnum);
    } else {
      this.permissionsToUnlock.remove(permissionEnum);
    }
  }

  @action.bound
  cancelUnlockPermissionsModal() {
    this.hideActiveModal().then(() => {
      this.permissionsToUnlock.clear();
    });
  }

  @action.bound
  async confirmUnlockPermissionsModal() {
    this.unlockingPermissions = true;

    const permissions = this.companyPermissions.models.filter(permission =>
      this.permissionsToUnlock.includes(permission.permissionEnum)
    );

    permissions.forEach(permission => (permission.locked = false));

    await request.patch(this.companyPermissions.url(), permissions.slice());

    await this.hideActiveModal();

    this.permissionsToUnlock.clear();
    this.unlockingPermissions = false;
  }
}
