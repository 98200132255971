import React from 'react';
import styled from 'styled-components';
import { Box, Text, Button, Alert } from '@raken/athens-web/lib';
import { t } from 'utils/translate';

const StyledButton = styled(Button)`
  margin-left: auto;
  margin-right: auto;
`;

/**
 * @docs https://reactjs.org/docs/error-boundaries.html
 */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      errorMessage: ''
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      errorMessage: error.message
    };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box height={'95vh'}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height={'100%'}
          >
            <Box mb={6}>
              <img
                alt=""
                src={`${this.props.store.assetsURL}/svg/empty.svg`}
                width={40}
              />
            </Box>

            <Box mb={2}>
              <Text variant={'h1'}>{t('Whoops, something went wrong')}</Text>
            </Box>

            <Box mb={12}>
              <Text light rakenColor="grey">
                {t('Please reload the page to continue.')}
              </Text>
            </Box>

            <Box>
              <StyledButton onClick={() => window.location.reload()}>
                {t('Reload this page')}
              </StyledButton>
            </Box>

            {this.state.errorMessage && (
              <Box maxWidth={480} my={6}>
                <Alert>{this.state.errorMessage}</Alert>
              </Box>
            )}
          </Box>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
