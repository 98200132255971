import MobxReactForm from 'mobx-react-form';
import validatorjs from 'validatorjs';
import { t } from 'utils/translate';

const renameTalkFormPlugins = { dvr: validatorjs };

const renameTalkFormOptions = {
  validateOnInit: true,
  validateOnChange: true,
  strictUpdate: false
};

const renameTalkFormFields = ['name'];

const renameTalkFormRules = {
  name: 'string|required'
};

const renameTalkFormLabels = {
  name: t('name')
};

class RenameTalkForm extends MobxReactForm {}

export {
  RenameTalkForm,
  renameTalkFormOptions,
  renameTalkFormFields,
  renameTalkFormRules,
  renameTalkFormLabels,
  renameTalkFormPlugins
};
