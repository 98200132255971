import { RakenCollection } from '../lib';
import MissedDaily from '../models/MissedDaily';

export default class MissedDailies extends RakenCollection {
  model() {
    return MissedDaily;
  }

  url() {
    return `/ra/project-teams/${this.parent.id}/dailies/missed`;
  }
}
