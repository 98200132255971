import { Model } from 'mobx-mc';
import { action, computed, observable } from 'mobx';
import kebabCase from 'lodash.kebabcase';

export default class PayType extends Model {
  @observable new;

  constructor(data, options) {
    super(data, options);

    this.new = false;
  }

  get restAttributes() {
    return ['uuid', 'name', 'code', 'hiddenProjects', 'order', 'externalId'];
  }

  get restAttributeDefaults() {
    return {
      hiddenProjects: []
    };
  }

  idAttribute() {
    return 'uuid';
  }

  @computed get hasMultipleProjects() {
    return this.projects.length > 1;
  }

  @computed get identificationString() {
    return `${this.name}, ${this.code}`;
  }

  @computed
  get formValues() {
    return {
      name: this.name,
      code: this.code
    };
  }

  @action.bound setAsNew() {
    this.new = true;
  }

  @action.bound clearNew() {
    this.new = false;
  }

  @computed get slug() {
    return kebabCase(this.name);
  }

  @computed get isRtOtDt() {
    return (
      (this.code === 'RT' && this.order === 1) ||
      (this.code === 'OT' && this.order === 2) ||
      (this.code === 'DT' && this.order === 3)
    );
  }

  @computed get currentProject() {
    return this.rootStore.projectUI.project;
  }

  @computed get assignedToCurrentProject() {
    return !this.hiddenProjects.find(
      project => project.uuid === this.currentProject?.uuid
    );
  }

  @computed get entityId() {
    const parts = (this.externalId || '').split('|');
    return parts.length > 1 ? parts.pop() : null;
  }
}
