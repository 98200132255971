import styled from 'styled-components';

export default styled.a`
  background-color: ${props =>
    props.active ? props.theme.colors.white : props.theme.colors.lightGrey2};
  border-top: 1px solid ${props => props.theme.colors.lightGrey5};
  border-left: 1px solid ${props => props.theme.colors.lightGrey5};
  cursor: pointer;
  display: flex;
  font-size: ${props => props.size || '1.4rem'};
  flex: 1;
  justify-content: center;
  font-weight: ${props => (props.active ? 700 : 400)};
  color: ${props =>
    props.active
      ? props.theme.colors.rakenOrange
      : props.theme.colors.charcoalGrey};
  text-decoration: none;
  text-transform: capitalize;
  transition: color 0.25s ease-in-out;
  padding: ${props => props.padding || '1.5rem 0'};
  white-space: pre-wrap;
  &:hover {
    color: ${props => props.theme.colors.rakenOrange};
  }
  &:first-child {
    border-top-left-radius: 10px;
  }
  &:last-child {
    border-right: 1px solid ${props => props.theme.colors.lightGrey5};
    border-top-right-radius: 10px;
  }
`;
