import moment from 'moment';
import { action, observable, computed } from 'mobx';
import ProjectChildAddUI from '../ProjectChildAddUI';
import { callTrack } from 'utils/segmentIntegration';
import Observation from 'stores/models/Observation';
import {
  ObservationForm,
  observationFormOptions,
  observationFormFields,
  observationFormValues,
  observationFormLabels,
  observationFormPlugins
} from 'forms/observation';

import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import {
  OBSERVATION_ADDED,
  CHECKLIST_OBSERVATION_ATTACHMENT
} from 'utils/segmentAnalytics/eventSpec';

export default class ChecklistObservationAddUI extends ProjectChildAddUI {
  @observable entryForAdd;
  @observable entryAddForm;
  @observable padSigned;
  @observable signature;
  @observable notifyAssignees;
  @observable notifyTeamMembers;
  @observable observationToUpdateStatus;
  @observable observationStatusNewValue;
  @observable questionModel;
  @observable question;
  @observable selectedObservationUuid;

  constructor(options) {
    super(options);

    this.entryForEdit = null;
    this.entryEditForm = null;
    this.notifyAssignees = null;
    this.notifyTeamMembers = null;
    this.observationStatusNewValue = null;
    this.padSigned = false;
    this.signature = null;
    this.questionModel = null;
    this.question = null;
    this.saveAsDraft = false;
  }

  @computed
  get observationForm() {
    return this.entryAddForm;
  }

  @computed get checklistObservationUI() {
    return this.parent.checklistObservationUI;
  }

  @computed
  get observationEntry() {
    return this.entryForAdd;
  }

  @computed
  get submitObservationForm() {
    return this.submitEntryAddForm;
  }

  @computed get disableSaveButton() {
    return (
      !this.observationForm ||
      this.saving ||
      this.entryForAdd?.hasAttachmentsUploading
    );
  }

  @computed get saveButtonText() {
    if (this.saving) {
      return t('Saving...');
    }

    if (this.entryForAdd?.hasAttachmentsUploading) {
      return t('Uploading files...');
    }

    return t('Save');
  }

  @computed get saveAsDraftButtonText() {
    if (this.saving && this.saveAsDraft) {
      return t('Saving...');
    }

    return t('Save as draft');
  }

  @action.bound setup() {
    this.projectLocationSelectorUI.setup(this.projectUuid);
  }

  @action.bound tearDown() {
    this.clearValidationDetails();
    this.clearUIState();
    this.projectLocationSelectorUI.tearDown();
  }

  @computed get observationSubTypesOptions() {
    if (!this.entryAddForm.$('type').value) return [];

    return this.observationTypes.models
      .filter(type => {
        return this.entryAddForm.$('type').value.value === type.type;
      })
      .map(type => {
        return {
          value: type.uuid,
          title: type.subType
        };
      });
  }

  @computed get observationTypes() {
    return this.checklistObservationUI.observationTypes;
  }

  @computed get observationTypesOptions() {
    return this.checklistObservationUI.observationTypesOptions;
  }

  @computed get observationFields() {
    return this.checklistObservationUI.observationFields;
  }

  @computed get observationStatuses() {
    return this.checklistObservationUI.observationStatuses;
  }

  @computed get observationStatusOptions() {
    return this.checklistObservationUI.observationStatusOptions;
  }

  @computed get priorityOptions() {
    return this.checklistObservationUI.priorityOptions;
  }

  @computed get locationOptions() {
    return this.parent.parent.locationOptions;
  }

  @computed get checklistUuid() {
    return this.questionModel?.relatedChecklist.uuid;
  }

  @computed get observationDialogTitle() {
    return t('Create observation');
  }

  @computed get selectedObservationSubType() {
    return this.observationSubTypesOptions.find(
      type => type.value === this.entryAddForm.$('subType').value
    );
  }

  @action.bound
  attachObservation({ questionModel, question }) {
    this.questionModel = questionModel;
    this.question = question;
    this.showModal('observation');
    this.setUpObservation();
  }

  @action.bound
  async setUpObservation() {
    this.entryForAdd = new Observation(
      { type: 'Observation' },
      {
        rootStore: this.rootStore
      }
    );

    this.memberSelectorUI.setup({
      projectUuids: this.checklistObservationUI.memberProjectUuids.slice(),
      sortField: 'company.name, firstName, lastName',
      elevateFn: element =>
        element.company.uuid === this.rootStore.me.company.uuid,
      role: ['ROLE_ACCOUNT_ADMIN', 'ROLE_ADMIN', 'ROLE_PROJECT_MEMBER']
    });

    this.projectLocationSelectorUI.setup(this.project.uuid);

    await Promise.all([
      this.observationTypes.fetch(),
      this.observationStatuses.fetch(),
      this.observationFields.fetch()
    ]);

    this.entryAddForm = new ObservationForm(
      {
        fields: observationFormFields,
        rules: this.observationFields.observationFieldsFormRules,
        values: Object.assign(observationFormValues, {
          status: this.observationStatusOptions.find(
            status => status.title === 'Open'
          ),
          type: this.defaultType,
          subType: this.defaultSubType,
          assignees: [],
          location: this.projectLocationSelectorUI?.options.find(
            location => location.uuid === this.defaultLocation?.uuid
          ),
          emails: this.defaultEmails
        }),
        labels: observationFormLabels
      },
      {
        options: observationFormOptions,
        plugins: observationFormPlugins
      }
    );
  }

  @computed get categoryOptions() {
    return this.checklistObservationUI.categoryOptions;
  }

  @computed get selectedCategoryOption() {
    return this.categoryOptions.find(
      option => option.value === this.entryAddForm.$('category').value
    );
  }

  @computed get defaultType() {
    const defaulObservationType = localStorage.getItem('defaulObservationType');

    const type = this.observationTypes.models.find(
      type => type.uuid === defaulObservationType
    );

    if (type) {
      return {
        value: type.type,
        title: type.type,
        class: type.typeClass
      };
    } else {
      return null;
    }
  }

  @computed get defaultLocation() {
    let defaulObservationLocation;
    try {
      defaulObservationLocation = JSON.parse(
        localStorage.getItem('defaulObservationLocation')
      );
    } catch (e) {
      return null;
    }

    return defaulObservationLocation;
  }

  @computed get defaultSubType() {
    return localStorage.getItem('defaulObservationType');
  }

  @computed get defaultEmails() {
    const defaulObservationEmails = localStorage.getItem(
      'defaulObservationEmails'
    );
    if (
      !defaulObservationEmails ||
      !JSON.parse(defaulObservationEmails).every(
        item => typeof item === 'object'
      )
    )
      return null;

    return this.memberSelectorUI.options.filter(member => {
      return JSON.parse(defaulObservationEmails).find(
        defaultEmail => defaultEmail.email === member.email
      );
    });
  }

  @computed get saveObservationUrl() {
    return (
      this.rootStore.urlMicroService('toolbox') +
      `/companies/${this.rootStore.me.company.uuid}/observations`
    );
  }

  @action.bound
  addAssignee() {
    this.entryAddForm.$('assignees').add({
      assignee: this.entryAddForm.$('assignee').value,
      dueDate: this.entryAddForm.$('dueDate').value
        ? moment(this.entryAddForm.$('dueDate').value).format('YYYY-MM-DD')
        : '',
      action: this.entryAddForm.$('action').value,
      sendNotification: this.entryAddForm.$('sendNotification').value
    });

    this.entryAddForm.$('assignee').clear();
    this.entryAddForm.$('dueDate').clear();
    this.entryAddForm.$('action').clear();
    this.entryAddForm.$('sendNotification').set(true);
  }

  @action.bound
  removeAssignee(assigneePath) {
    this.entryAddForm.$('assignees').del(assigneePath);
  }

  @action.bound openObservationSignatureModal(newValue) {
    if (newValue?.title === 'Closed') {
      this.observationStatusNewValue = newValue;
      this.observationStatusOldValue = this.entryAddForm.$('status').value;
      this.activeModal = 'observationSign';
      this.entryAddForm.$('status').clear();
      this.entryAddForm.$('status').set(this.observationStatusNewValue);
    } else {
      this.entryAddForm.$('status').set(newValue);
    }
  }

  @action.bound async cancelObservationSignatureModal() {
    this.entryAddForm.$('status').set(this.observationStatusOldValue);
    await this.closeObservationSignatureModal();
  }

  @action.bound closeObservationSignatureModal() {
    this.observationStatusNewValue = null;
    this.observationStatusOldValue = null;
    this.activeModal = 'observation';
  }

  @action.bound
  async updateStatus() {
    this.entryAddForm.$('status').set(this.observationStatusNewValue);
    this.closeObservationSignatureModal();
  }

  @action.bound tearDownObservation() {
    this.entryAddForm = null;
  }

  @action.bound
  async hideObservation() {
    await this.hideActiveModal();
    this.tearDownObservation();
  }

  @action.bound
  async saveObservationDraft(e) {
    this.saveAsDraft = true;
    this.submitEntryAddForm(e);
  }

  @action.bound setDefaultObservationsType(values) {
    localStorage.setItem(`defaulObservationType`, values.subType);
    localStorage.setItem(
      `defaulObservationLocation`,
      JSON.stringify(values.location)
    );
  }

  @action.bound async submitEntryAddFormSuccess() {
    this.saving = true;

    try {
      const attachments = this.entryForAdd.attachments?.models.map(
        attachment => {
          return {
            uuid: attachment.uuid
          };
        }
      );

      const values = this.entryAddForm.values();

      const assignees = await this.checklistObservationUI.createOrEditTasksForAssignees(
        this.entryAddForm.assigneesValues,
        values.status.value
      );

      const payload = {
        projectUuid: this.project.uuid,
        attachments: attachments,
        entityStatus: this.saveAsDraft ? 'DRAFT' : 'ACTIVE',
        type: {
          uuid: values.subType
        },
        category: values.category,
        priority: values.priority?.value,
        status: {
          uuid: values.status?.value
        },
        assignees: assignees,
        location: { uuid: values.location?.uuid },
        description: values.description,
        emails: Array.isArray(values.emails)
          ? values.emails.map(member => member.email)
          : [],
        ...(this.signature && { signature: this.signature })
      };

      await this.entryForAdd.save(payload, {
        url: this.saveObservationUrl,
        wait: true,
        stripNonRest: false
      });

      this.questionModel.checklistResponse.addObservation(
        this.entryForAdd.toJSON()
      );

      if (this.checklistUuid) {
        await this.questionModel.checklistResponse.observations.attach([
          this.entryForAdd.uuid
        ]);
      }

      callTrack(OBSERVATION_ADDED, {
        project_name: this.project.name,
        project_date: this.date,
        observation_category_name: this.entryForAdd.category,
        has_attachments: this.entryForAdd.hasAttachments
      });

      callTrack(CHECKLIST_OBSERVATION_ATTACHMENT);

      this.setDefaultObservationsType(values);

      this.hideObservation();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Observation created')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound signPad(signature) {
    this.padSigned = true;
    this.signature = signature;
  }

  @action.bound unsignPad() {
    this.padSigned = false;
    this.signature = null;
  }

  @action.bound clearUIState() {
    this.activeModal = null;
    this.clearValidationDetails();
    this.entryForAdd = null;
    this.entryAddForm = null;
    this.saving = false;
    this.saveAsDraft = false;
    this.padSigned = false;
    this.notifyAssignees = null;
    this.notifyTeamMembers = null;
    this.signature = null;
  }

  @action.bound toggleNotifyAssignees() {
    this.notifyAssignees = !this.notifyAssignees;
  }

  @action.bound toggleNotifyTeamMembers() {
    this.notifyTeamMembers = !this.notifyTeamMembers;
  }
}
