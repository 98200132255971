import { action, computed, observable } from 'mobx';
import { Collection } from 'mobx-mc';
import Transaction from '../../models/billing/Transaction';

export default class Transactions extends Collection {
  @observable bookmark;

  constructor(attributes, options) {
    super(attributes, options);

    this.bookmark = null;
  }

  model() {
    return Transaction;
  }

  url() {
    return `/ra/companies/${this.company.uuid}/billing/transactions`;
  }

  @action.bound
  set(response, options) {
    super.set(response, options);
    this.bookmark = response.bookmark;
  }

  @action.bound
  clear() {
    super.clear();
    this.bookmark = null;
  }

  parse(response) {
    return response.data;
  }

  @computed get company() {
    if (this.rootStore.isSuperAdmin) {
      return this.parent.company;
    }

    return this.rootStore.me.company;
  }
}
