import styled, { css } from 'styled-components';

export default styled.a`
  color: ${props =>
    props.color ? props.theme.colors[props.color] : 'inherit'};
  cursor: ${props =>
    props.cursor ? props.cursor : props.disabled ? 'not-allowed' : 'pointer'};
  display: ${props =>
    props.block
      ? 'block'
      : props.inlineFlex
      ? 'inline-flex'
      : props.flex
      ? 'flex'
      : props.inlineBlock
      ? 'inline-block'
      : 'inline'};
  font-size: ${props => (props.size ? `${props.size / 10}rem` : 'inherit')};
  font-family: inherit;
  font-weight: ${props =>
    props.bold ? 700 : props.light ? 300 : props.normal ? 400 : 'inherit'};
  font-style: ${props => (props.italic ? 'italic' : 'inherit')};
  opacity: ${props => props.opacity || 1};
  text-align: ${props => (props.align ? props.align : 'inherit')};
  text-transform: ${props => (props.transform ? props.transform : 'inherit')};
  text-decoration: ${props => (props.underline ? 'underline' : 'inherit')};
  white-space: ${props => (props.noWrap ? 'nowrap' : 'inherit')};
  line-height: ${props => (props.lineHeight ? props.lineHeight : 'inherit')};
  margin-left: ${props => (props.marginLeft ? props.marginLeft / 10 : 0)}rem;
  margin-right: ${props => (props.marginRight ? props.marginRight / 10 : 0)}rem;
  margin-bottom: ${props =>
    props.marginBottom ? props.marginBottom / 10 : 0}rem;
  margin-top: ${props => (props.marginTop ? props.marginTop / 10 : 0)}rem;
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft / 10 : 0)}rem;
  padding-right: ${props =>
    props.paddingRight ? props.paddingRight / 10 : 0}rem;
  padding-bottom: ${props =>
    props.paddingBottom ? props.paddingBottom / 10 : 0}rem;
  padding-top: ${props => (props.paddingTop ? props.paddingTop / 10 : 0)}rem;
  &:hover {
    color: ${props =>
      props.hoverColor
        ? props.theme.colors[props.hoverColor]
        : props.theme.colors[props.color]};
    ${props =>
      props.hoverUnderline &&
      css`
        text-decoration: ${props => props.hoverUnderline};
      `};
    ${props =>
      props.hoverWeight &&
      css`
        font-weight: ${props => props.hoverWeight};
      `};
  }
`;
