import orderBy from 'lodash.orderby';
import groupBy from 'lodash.groupby';
import { RakenCollection } from '../lib';
import CompletedDaily from '../models/CompletedDaily';
import { computed, observable, action, reaction } from 'mobx';
import moment from 'moment';
import errorHandler from 'utils/errorHandler';

export default class ProjectTeamCompletedDailies extends RakenCollection {
  @observable selectedYear;
  @observable selectedMonth;
  @observable fetching;
  @observable pdfSuperDaily;
  @observable reportsSegment;

  constructor(data, options) {
    super(data, options);

    this.selectedYear = null;
    this.selectedMonth = null;
    this.fetching = false;
    this.pdfSuperDaily = false;
    this.reportsSegment = null;

    // Call fetch when selected month changes.
    this.reactToSelectedFromDateChange = reaction(
      () => this.fromDate,
      fromDate => {
        if (this.fromDate) {
          this.fetchBySelectedMonthAndYear();
        }
      }
    );
  }

  model() {
    return CompletedDaily;
  }

  url() {
    return `/ra/project-teams/${this.parent.id}/dailies/completed`;
  }

  @computed
  get reportsBySegment() {
    return groupBy(
      this.dailiesBySelectedMonth.filter(model => model.segmentName),
      'segmentName'
    );
  }

  @action.bound
  setReportsSegment(segmentType) {
    this.reportsSegment = segmentType;
  }

  @action.bound
  clearReportsSegment() {
    this.reportsSegment = null;
  }

  @computed
  get monthsForSelectedYear() {
    return Object.keys(
      groupBy(this.models.slice(), model => moment(model.date).format('MMMM'))
    ).sort(function(a, b) {
      return moment.months().indexOf(a) - moment.months().indexOf(b);
    });
  }

  @action.bound
  setPdfSuperDaily(bool) {
    this.pdfSuperDaily = bool;
  }

  @action.bound
  fetchBySelectedMonthAndYear() {
    this.fetching = true;
    this.fetch({
      params: {
        mn: 'full',
        offset: 0,
        limit: 365,
        fromDate: this.fromDate,
        toDate: this.toDate,
        status: `COMPLETED,UNSIGNED,NO_WORK_DONE`,
        includeSuperDaily: this.relatedProject?.hasSuperDaily || false
      }
    })
      .then(() => {
        this.fetching = false;
      })
      .catch(error => {
        errorHandler(error, this.rootStore.notificationsUI.pushError);
      });
  }

  @action.bound
  updateSelectedYear(year) {
    this.selectedYear = year;
  }

  @action.bound
  updateSelectedMonth(month) {
    this.selectedMonth = month;
  }

  @action.bound
  updateSelectedYearAndMonth(year, month) {
    this.selectedYear = year;
    this.selectedMonth = month;
  }

  @computed
  get fromDate() {
    if (!this.selectedYear) return null;

    return moment()
      .year(this.selectedYear)
      .startOf('year')
      .format('YYYY-MM-DD');
  }

  @computed
  get toDate() {
    if (!this.selectedYear) return null;

    return moment()
      .year(this.selectedYear)
      .endOf('year')
      .format('YYYY-MM-DD');
  }

  @computed
  get isEmpty() {
    return this.length < 1;
  }

  @computed
  get relatedProject() {
    return this.parent.relatedProject;
  }

  @computed
  get dailiesBySelectedMonth() {
    return orderBy(
      this.models.filter(model => {
        return moment(model.date).format('MMMM') === this.selectedMonth;
      }),
      ['date'],
      ['asc']
    );
  }
}
