import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import Input from './Input';
import InputMenu from './InputMenu';
import Select from './Select';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { t } from '../utils/translate';

const moment = extendMoment(Moment);

const Wrapper = styled.div`
  position: relative;
  height: 40px;
`;

const Selects = styled.div`
  align-items: center;
  display: flex;
  padding: 0.5rem;
  color: green;
`;

export default class TimePicker2 extends Component {
  static propTypes = {
    editable: PropTypes.bool, // Enable input/typing.
    minTime: PropTypes.instanceOf(Date), // Earliest time available for selection
    maxTime: PropTypes.instanceOf(Date), // Latest time available for selection
    hourFormat: PropTypes.string, // Hour format for input and display
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.object.isRequired
    ])
  };

  static defaultProps = {
    editable: true,
    hourFormat: '24'
  };

  state = {
    showMenu: false,
    hour: '',
    minute: '',
    selectedTime: '',
    selectedHour: '',
    selectedMinute: ''
  };

  componentDidUpdate(prevProps, prevState) {
    const { selectedHour, selectedMinute } = this.state;

    if (
      prevState.selectedHour !== selectedHour ||
      prevState.selectedMinute !== selectedMinute
    ) {
      if (selectedHour && selectedMinute) {
        this.setState({
          selectedTime: `${selectedHour}:${selectedMinute}`
        });
      }
      this.handleNewTime();
    }
  }

  componentDidMount() {
    const { form, fieldName } = this.props;
    const timeInitial = moment(form.$(fieldName).value, 'HH:mm');
    this.validateInput(moment(timeInitial).format('HH:mm'));
    document.addEventListener('click', this.handleHideMenu);
  }

  componentWillumount() {
    document.removeEventListener('click', this.handleHideMenu);
  }

  getHourOptions = () => {
    // Based on the props for hourFormat, minTime and maxTime we should adjust this
    return Array.from(
      moment.range(moment().startOf('day'), moment().endOf('day')).by('hour')
    ).map(time => {
      return {
        value: time.format('HH'),
        label: time.format('HH')
      };
    });
  };

  getMinuteOptions = () => {
    // Based on the props for hourFormat, minTime and maxTime we should adjust this
    return Array.from(
      moment
        .range(moment().startOf('hours'), moment().endOf('hour'))
        .by('minute')
    ).map(time => {
      return {
        value: time.format('mm'),
        label: time.format('mm')
      };
    });
  };

  handleShowMenu = () => {
    this.setState({
      showMenu: true
    });
  };

  handleHideMenu = e => {
    if (this.wrapperRef && this.wrapperRef.contains(e.target)) return;

    this.setState({
      showMenu: false
    });
  };
  handleHourChange = e => {
    this.setState({
      selectedHour: e.target.value
    });
  };

  handleMinuteChange = e => {
    this.setState({
      selectedMinute: e.target.value
    });
  };

  validateInput = inputValue => {
    if (moment(inputValue, 'HH:mm', true).isValid()) {
      this.setState({
        selectedHour: inputValue.split(':')[0],
        selectedMinute: inputValue.split(':')[1]
      });
    } else {
      this.setState({
        selectedTime: '',
        selectedHour: '',
        selectedMinute: ''
      });
    }
  };

  handleInputChange = e => {
    // This is just to allow input editable
    this.setState({
      selectedTime: e.target.value
    });
  };

  handleNewTime = () => {
    const { onChange } = this.props;
    let obj = this.createTimeObject();
    onChange(obj);
  };

  createTimeObject = () => {
    return {
      h: this.state.selectedHour,
      m: this.state.selectedMinute
    };
  };

  render() {
    return (
      <Wrapper ref={ref => (this.wrapperRef = ref)}>
        <Input
          placeholder={t('Type or Select')}
          onFocus={this.handleShowMenu}
          onBlur={e => {
            this.validateInput(e.target.value);
          }}
          onKeyDown={e => {
            if (e.keyCode && e.keyCode === 13) {
              this.validateInput(e.target.value);
            }
          }}
          value={this.state.selectedTime}
          onChange={this.handleInputChange}
        />
        {this.state.showMenu && (
          <InputMenu>
            <Selects>
              <Select
                width="50%"
                onChange={this.handleHourChange}
                value={this.state.selectedHour}
              >
                {this.getHourOptions().map(hour => {
                  return (
                    <option key={hour.value} value={hour.value} hidden="hidden">
                      {hour.label}
                    </option>
                  );
                })}
              </Select>
              {' : '}
              <Select
                width="50%"
                onChange={this.handleMinuteChange}
                value={this.state.selectedMinute}
              >
                {this.getMinuteOptions().map(minute => {
                  return (
                    <option key={minute.value} value={minute.value}>
                      {minute.label}
                    </option>
                  );
                })}
              </Select>
            </Selects>
          </InputMenu>
        )}
      </Wrapper>
    );
  }
}
