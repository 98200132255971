let css = `
<style>* {margin: 0; padding: 0;} body{font-family:sans-serif;} .wrapper{align-items:center;display:flex;flex-direction:column;justify-content:center;position:fixed;left:0;bottom:0;right:0;height:100%;} .text{display:block;color:#F26620;font-size:14px;} .rotatingIcon{display:block;animation:spin 2s ease-in-out infinite -0.5s;animation-fill-mode:forwards;transform-origin:center center;width:80px;height:80px;margin:0 auto 10px auto;} .rotatingIcon svg{display:block;transform-origin:center center;} @keyframes spin{0%{transform:rotate(0deg);opacity:0.5;} 20%{transform:rotate(0deg);} 50%{opacity:1;} 100%{transform:rotate(360deg);opacity:0.5;} } iframe{width:100%;height:100%;border:none;} .success{display:flex;-webkit-box-align:center;align-items:center;-webkit-box-pack:center;justify-content:center;height:100%;font-size:20px;font-weight:bold;color:#F26620;}</style>
`;

let loader =
  '' +
  '<div id="spinner" data-qa="div_spinner" class="wrapper">' +
  '<div class="rotatingIcon">' +
  '<SVG xmlns="http://www.w3.org/2000/svg" fill="#F26620" viewBox="0 0 24 21" width="80" height="80"><path d="M6.47604791,0 C6.13600359,0 5.82170644,0.180613996 5.65215883,0.475328633 L0.1271607,10.0251761 C-0.0423869001,10.3198504 -0.0423869001,10.6810582 0.1271607,10.9757527 L5.65215883,20.524752 C5.82075734,20.8194465 6.13600359,21 6.47604791,21 L17.5240842,21 C17.865017,21 18.1792738,20.8194465 18.3478722,20.524752 L23.8728696,10.9757527 C24.0423768,10.6810582 24.0423768,10.3198504 23.8728696,10.0251761 L18.3478722,0.475328633 C18.1792738,0.180613996 17.8640679,0 17.5240842,0 L6.47604791,0 Z M7.0274411,1.90121375 L16.9735795,1.90121375 L21.9480722,10.5004241 L16.9735795,19.0988072 L7.0274411,19.0988072 L2.05296781,10.5004241 L7.0274411,1.90121375 Z M12.000017,4.79776964 C8.84848249,4.79776964 6.28549869,7.35581288 6.28549869,10.5014117 C6.28549869,13.6470097 8.84848249,16.2050933 12.000017,16.2050933 C15.1506399,16.2050933 17.7145324,13.6470097 17.7145324,10.5014117 C17.7145324,7.35581288 15.151589,4.79776964 12.000017,4.79776964 L12.000017,4.79776964 Z M12.000017,6.69896323 C14.1020134,6.69896323 15.8096865,8.40434633 15.8096865,10.5014117 C15.8096865,12.5984771 14.1020134,14.3038795 12.000017,14.3038795 C9.89991588,14.3038795 8.19036476,12.5984771 8.19036476,10.5014117 C8.19036476,8.40434633 9.89991588,6.69896323 12.000017,6.69896323 L12.000017,6.69896323 Z"/></SVG> ' +
  '</div>' +
  '<div class="text">Loading, this may take a few minutes</div>' +
  '</div>';

let success = '<div id="success" class="success" style="display:none"></div>';

let mainFunction = function(options) {
  if (options.fileUrl) {
    getFileFromServer(options.fileUrl.file, options.fileUrl.name);
  } else {
    getFileUrl(options);
  }

  function getFileUrl(options) {
    var xhttp = new XMLHttpRequest();
    xhttp.open(options.method ? options.method : 'POST', options.url, true);
    xhttp.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
    xhttp.setRequestHeader('authentication', 'Bearer ' + options.token);

    if (options.xhttpOptions && options.xhttpOptions.sendXApiKey) {
      xhttp.setRequestHeader('x-api-key', options['x-api-key']);
    }

    xhttp.onreadystatechange = function() {
      if (this.readyState === 4) {
        if (this.status === 202 || this.status === 200) {
          const response = JSON.parse(this.response);
          getFileFromServer(
            options.fileUrlAttr
              ? response[options.fileUrlAttr]
              : response.fileUrl,
            response.fileName
          );
        } else {
          showResult('The file has not been loaded.', 'Error');
        }
      }
    };
    xhttp.send(JSON.stringify(options.data));
  }

  function getFileFromServer(fileUrl, fileName = '') {
    var intervalRepeatRequestExist = true;
    var intervalRepeatRequest = setInterval(function() {
      var xhttpS3 = new XMLHttpRequest();
      xhttpS3.open('GET', fileUrl, true);
      xhttpS3.onreadystatechange = function() {
        if (this.readyState === 4 && this.status === 200) {
          clearInterval(intervalRepeatRequest);
          intervalRepeatRequestExist = false;
          if (options.mode === 'generatePDFHtml') {
            showIframe(fileUrl, fileName);
          } else {
            window.location = fileUrl;
            showResult('File successfully downloaded.', fileName);
          }
        }
      };
      xhttpS3.send();
    }, 5000);

    setTimeout(function() {
      if (intervalRepeatRequestExist) {
        clearInterval(intervalRepeatRequest);
        showResult('The file has not been loaded.', 'Error');
      }
    }, 5 * 60 * 1000);
  }

  function showIframe(fileUrl, fileName) {
    document.getElementById('spinner').style.display = 'none';
    document.getElementById('success').style.display = 'none';

    const iframe = document.createElement('iframe');

    // url for develop
    // iframe.src = `https://cdn.rakenapp.com/ui/alpha-priv/raken-redesign-ui/viewer/generic/web/viewer.html?file=${fileUrl}&filename=${fileName}`;

    iframe.src = `${options.assetsURL}/viewer/generic/web/viewer.html?file=${fileUrl}&filename=${fileName}`;

    document.body.prepend(iframe);

    if (fileName) {
      document.title = fileName;
    }
  }

  function showResult(message, pageTitle) {
    document.getElementById('spinner').style.display = 'none';
    document.getElementById('success').style.display = 'flex';
    document.getElementById('success').innerText = message;
    if (pageTitle) {
      document.title = pageTitle;
    }
  }
};

export default function(options) {
  options.token = options.store && options.store.token;
  options['x-api-key'] =
    options.store && options.store.appConfig.safety_api_key;
  options.assetsURL = options.store && options.store.assetsURL;
  delete options.store;

  let downloadTab = window.open('');
  downloadTab.document.write('<title>Loading...</title>');
  downloadTab.document.write(
    loader +
      success +
      css +
      '<script>(' +
      mainFunction +
      ')(' +
      JSON.stringify(options) +
      ')</script>'
  );
}
