import request from 'axios';
import moment from 'moment';
import capitalize from 'lodash.capitalize';
import { action, computed, observable } from 'mobx';
import UIStore from '../UIStore';
import alertErrorHandler from 'utils/alertErrorHandler';
import SuperAdminSalesReps from 'stores/collections/superAdmin/SuperAdminSalesReps';
import Coupons from 'stores/collections/superAdmin/Coupons';
import { couponFormFactory } from 'forms/billing/coupon';
import Coupon from 'stores/models/superAdmin/Coupon';
import { t } from 'utils/translate';
import overrideValidationMessages from 'forms/utils/overrideValidationMessages';

import {
  SalesRepresentativeForm,
  salesRepresentativeFormOptions,
  salesRepresentativeFormFields,
  salesRepresentativeFormRules,
  salesRepresentativeFormLabels,
  salesRepresentativeFormPlugins
} from 'forms/billing/salesRepresentative';

import {
  PromoForm,
  promoFormOptions,
  promoFormFields,
  promoFormRules,
  promoFormLabels,
  promoFormPlugins
} from 'forms/billing/promo';

import {
  TrialForm,
  trialFormOptions,
  trialFormFields,
  trialFormRules,
  trialFormLabels,
  trialFormPlugins
} from 'forms/billing/trial';

export default class AccountInfoUI extends UIStore {
  @observable salesRepresentativeForm;
  @observable promoForm;
  @observable trialForm;
  @observable couponForm;
  @observable activeCoupon;
  @observable conversionConfirmed;

  constructor(options) {
    super(options);

    this.salesRepresentativeForm = null;
    this.trialForm = null;
    this.promoForm = null;
    this.couponForm = null;

    this.salesRepresentatives = new SuperAdminSalesReps(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.coupons = new Coupons(null, {
      parent: this,
      rootStore: this.rootStore
    });
  }

  @computed get company() {
    return this.parent.company;
  }

  @computed get billingInfoUI() {
    return this.parent.billingInfoUI;
  }

  @computed get subscriptionUI() {
    return this.parent.subscriptionUI;
  }

  @computed get subscription() {
    return this.parent.subscription;
  }

  @computed get internalSubscription() {
    return this.company.subscription;
  }

  @computed get balance() {
    return this.parent.balance;
  }

  @computed get invoicesUI() {
    return this.parent.invoicesUI;
  }

  @computed get loading() {
    return this.subscriptionUI.loading;
  }

  @computed get showUI() {
    return !this.loading;
  }

  @computed get accountStatus() {
    if (this.company.isInternal) {
      return 'INTERNAL';
    }

    if (this.subscription.onTrialOrStarterPlan) {
      return 'UNPAID';
    }

    return 'PAID';
  }

  @computed get accountStatusText() {
    return capitalize(this.accountStatus.toLowerCase());
  }

  @computed get accountStatusColor() {
    switch (this.accountStatus) {
      case 'INTERNAL':
        return 'grey';
      case 'UNPAID':
        return 'orange';
      default:
        return 'green';
    }
  }

  @computed get promoStatus() {
    if (this.company.isPromo) {
      return 'ACTIVE';
    }

    return 'INACTIVE';
  }

  @computed get isPromoAvailable() {
    return this.subscription.isPromoAvailable;
  }

  @computed get promoStatusText() {
    return capitalize(this.promoStatus.toLowerCase());
  }

  @computed get promoStatusColor() {
    switch (this.promoStatus) {
      case 'ACTIVE':
        return 'green';
      default:
        return 'orange';
    }
  }

  @computed get trialEndDateFormatted() {
    if (
      !this.internalSubscription.onTrial ||
      this.internalSubscription.trialEndDate == null
    ) {
      return '-';
    }
    return moment(this.internalSubscription.trialEndDate).format('YYYY-MM-DD');
  }

  @computed get promoEndDateFormatted() {
    if (this.company.isPromo) {
      return this.company.promoEndDate;
    }

    return '-';
  }

  @action.bound setup() {
    this.fetchActiveCoupon();
    this.conversionConfirmed = false;
  }

  @action.bound tearDown() {
    this.activeCoupon = null;
  }

  @action.bound convertToCustomer() {
    this.showModal('ConvertToCustomerModal');
  }

  @action.bound async confirmConvertToCustomer() {
    this.saving = true;

    try {
      const response = await request.put(
        `ra/companies/${this.company.uuid}/billing/billing-info/collect?sendEmail=false`
      );

      await this.hideActiveModal();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: response.data.message
      });

      window.location.reload();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound async editSalesRepresentative() {
    this.salesRepresentatives.fetch();

    this.salesRepresentativeForm = await new SalesRepresentativeForm(
      {
        fields: salesRepresentativeFormFields,
        rules: salesRepresentativeFormRules,
        values: {
          salesRepresentative: {
            id: this.company.salesRepresentative.id,
            name: this.company.salesRepresentative.name
          }
        },
        labels: salesRepresentativeFormLabels
      },
      {
        options: salesRepresentativeFormOptions,
        plugins: salesRepresentativeFormPlugins
      }
    );

    this.showModal('EditSalesRepresentativeModal');
  }

  @computed get salesRepresentativeOptions() {
    return this.salesRepresentatives.models.map(salesRepresentative => {
      return {
        id: salesRepresentative.id,
        name: salesRepresentative.name
      };
    });
  }

  @action.bound async cancelEditSalesRepresentative() {
    await this.hideActiveModal();
    this.salesRepresentativeForm = null;
  }

  @action.bound submitSalesRepresentativeForm(event) {
    event.preventDefault();

    this.salesRepresentativeForm.submit({
      onSuccess: this.submitSalesRepresentativeFormSuccess,
      onError: this.submitSalesRepresentativeFormError
    });
  }

  @action.bound async submitSalesRepresentativeFormSuccess() {
    this.saving = true;

    try {
      await this.company.save(
        {
          salesRepresentative: this.salesRepresentativeForm.values()
            .salesRepresentative
        },
        { wait: true }
      );

      await this.cancelEditSalesRepresentative();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Sales representative updated')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitSalesRepresentativeFormError() {
    console.error(this.salesRepresentativeForm.errors());
  }

  @action.bound async editPromo() {
    this.promoForm = await new PromoForm(
      {
        fields: promoFormFields,
        rules: Object.assign(promoFormRules, {
          promoEndDate: `required_with:isPromo|dateCustom:YYYY-MM-DD|afterOrTodayDate|maxPromoOrTrialDate:${moment()
            .add(31, 'days')
            .format('YYYY-MM-DD')}`
        }),
        values: {
          isPromo: this.company.isPromo,
          promoEndDate: this.company.promoEndDate
        },
        labels: promoFormLabels
      },
      {
        options: promoFormOptions,
        plugins: overrideValidationMessages(promoFormPlugins, {
          required_with: t(
            'The :attribute field is required when enabling a promo.'
          )
        })
      }
    );

    this.showModal('EditPromoModal');
  }

  @action.bound togglePromo(event) {
    this.promoForm.$('isPromo').set(event.target.checked);

    if (!event.target.checked) {
      this.promoForm.$('promoEndDate').clear();
    }
  }

  @action.bound async cancelEditPromo() {
    await this.hideActiveModal();
    this.promoForm = null;
  }

  @action.bound submitPromoForm(event) {
    event.preventDefault();

    this.promoForm.submit({
      onSuccess: this.submitPromoFormSuccess,
      onError: this.submitPromoFormError
    });
  }

  @action.bound submitPromoFormError() {
    console.error(this.promoForm.errors());
  }

  @action.bound async submitPromoFormSuccess() {
    this.saving = true;

    const values = this.promoForm.values();

    try {
      await this.company.save(
        {
          isPromo: values.isPromo,
          promoEndDate: values.promoEndDate
        },
        { wait: true }
      );

      await this.cancelEditPromo();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Promo details updated')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound async editTrial() {
    this.trialForm = await new TrialForm(
      {
        fields: trialFormFields,
        rules: Object.assign(trialFormRules, {
          trialEndDate: `required_with:isTrial|dateCustom:YYYY-MM-DD|afterOrTodayDate|maxPromoOrTrialDate:${moment()
            .add(31, 'days')
            .format('YYYY-MM-DD')}`
        }),
        values: {
          isTrial: this.internalSubscription.onTrial,
          trialEndDate: moment(this.internalSubscription.trialEndDate).format(
            'YYYY-MM-DD'
          )
        },
        labels: trialFormLabels
      },
      {
        options: trialFormOptions,
        plugins: trialFormPlugins
      }
    );

    this.showModal('EditTrialModal');
  }

  @action.bound async cancelEditTrial() {
    await this.hideActiveModal();
    this.trialForm = null;
  }

  @action.bound submitTrialForm(event) {
    event.preventDefault();

    this.trialForm.submit({
      onSuccess: this.submitTrialFormSuccess,
      onError: this.submitTrialFormError
    });
  }

  @action.bound submitTrialFormError() {
    console.error(this.trialForm.errors());
  }

  @action.bound toggleTrial(event) {
    this.trialForm.$('isTrial').set(event.target.checked);

    if (!event.target.checked) {
      this.trialForm.$('trialEndDate').clear();
    }
  }

  @action.bound async submitTrialFormSuccess() {
    this.saving = true;

    const values = this.trialForm.values();

    let payload;

    if (
      values.isTrial &&
      moment(values.trialEndDate).isSameOrAfter(moment(), 'day')
    ) {
      payload = {
        subscriptionState: 'TRIAL',
        billingPlanKey: 'performance',
        billingPlanName: 'Performance',
        trialEndDate: moment(values.trialEndDate).format('YYYY-MM-DD')
      };
    } else {
      payload = {
        subscriptionState: 'ACTIVE',
        billingPlanKey: 'starter',
        billingPlanName: 'Starter',
        trialEndDate: moment().format('YYYY-MM-DD')
      };
    }

    try {
      await this.internalSubscription.save(
        Object.assign(this.internalSubscription.asFormValues, payload),
        { wait: true, method: 'put' }
      );

      this.subscriptionUI.fetchSubscription();

      await this.cancelEditTrial();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Trial details updated')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  /* Coupons */

  @computed get couponRedemptionsUrl() {
    return `/ra/companies/${this.company.uuid}/billing/coupon-redemptions/active`;
  }

  @action.bound async fetchActiveCoupon() {
    try {
      const response = await request.get(this.couponRedemptionsUrl);
      this.activeCoupon = response.data?.data?.length
        ? new Coupon(response.data.data[0].coupon, {})
        : null;
    } catch (error) {
      if (error.response.status !== 404) {
        alertErrorHandler(error);
      } else {
        this.activeCoupon = null;
      }
    }
  }

  @action.bound async editActiveCoupon() {
    this.coupons.fetch({
      params: {
        limit: 200
      }
    });

    const defaultValues = {
      coupon: this.activeCoupon?.formValues
    };
    this.couponForm = await couponFormFactory(defaultValues);
    await this.showModal('EditActiveCouponModal');
  }

  @action.bound unselectCoupon() {
    this.couponForm?.clear();
  }

  @action.bound async cancelEditActiveCoupon() {
    await this.hideActiveModal();
    this.couponForm = null;
  }

  @action.bound submitActiveCouponForm(event) {
    event.preventDefault();

    this.couponForm.submit({
      onSuccess: this.submitActiveCouponSuccess,
      onError: this.submitActiveCouponError
    });
  }

  @action.bound async submitActiveCouponSuccess() {
    this.saving = true;

    try {
      const newCode = this.selectedCouponOption?.code;
      if (!!newCode) {
        const response = await request.post(this.couponRedemptionsUrl, {
          couponCode: newCode
        });
        this.activeCoupon = new Coupon(response.data.coupon, {});
      } else {
        await request.delete(this.couponRedemptionsUrl);
        this.activeCoupon = null;
      }

      await this.cancelEditActiveCoupon();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Active coupon updated')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitActiveCouponError() {
    console.error(this.couponForm?.errors());
  }

  @computed get activeCouponName() {
    return this.activeCoupon?.name || t('No coupons redeemed');
  }

  @computed get canEditCoupons() {
    return this.authorization.canEditCoupons;
  }

  @computed get canSaveCoupon() {
    const formId = this.couponForm?.$('coupon').value.id;
    const currentId = this.activeCoupon?.id;
    return !this.saving && (formId || '') !== (currentId || '');
  }

  @computed get selectedCoupon() {
    const selectedCoupon = this.coupons.models.find(
      value => value.id === this.couponForm?.$('coupon').value?.id
    );

    return selectedCoupon
      ? {
          id: selectedCoupon.id,
          code: selectedCoupon.code,
          discount: selectedCoupon.formattedDiscount,
          duration: selectedCoupon.duration,
          eligibleOn: selectedCoupon.appliesToAllPlans
            ? t('All plans')
            : selectedCoupon.plans.map(plan => plan.name).join(', '),
          created: selectedCoupon.createdAt.substring(0, 10)
        }
      : {
          id: '',
          code: '-',
          discount: '-',
          duration: '-',
          eligibleOn: '-',
          created: '-'
        };
  }

  @computed get couponOptions() {
    return this.coupons.models
      .filter(coupon => coupon.state === 'REDEEMABLE')
      .map(value => value.formValues);
  }

  @computed get selectedCouponOption() {
    return this.couponOptions.find(
      value => value.id === this.couponForm.$('coupon').value?.id
    );
  }

  @computed get showTrialInfo() {
    return this.accountStatus === 'UNPAID' || this.accountStatus === 'INTERNAL';
  }

  @action.bound toggleConfirm() {
    this.conversionConfirmed = !this.conversionConfirmed;
  }
}
