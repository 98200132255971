import React from 'react';
import { observer } from 'mobx-react';

import { TokenInput } from '.';
import { t } from 'utils/translate';

export default observer(
  ({
    form,
    fieldName,
    validationFieldName,
    disabled,
    emailUI,
    controls,
    focusColor,
    dataQA
  }) => (
    <TokenInput
      inputValue={form.$(validationFieldName).value}
      tokens={form.$(fieldName).values() || []}
      onChange={update => {
        form.update({
          [validationFieldName]: update.inputValue,
          [fieldName]: update.tokens
        });

        if (emailUI.fetchEmails) {
          emailUI.fetchEmails(
            form.$(validationFieldName).value,
            form.$(fieldName).value,
            fieldName,
            () => {
              form.$(validationFieldName).validate();
              form.$(fieldName).validate();
            }
          );
        }
      }}
      placeholder={t('Enter recipient email addresses')}
      isValidNewOption={() => form.$(validationFieldName).isValid}
      separatorKeyCodes={[13, 188, 32, 9]}
      disabled={disabled}
      inputItems={
        emailUI.activeField === fieldName ? emailUI.companyEmails.slice() : []
      }
      clearItems={emailUI.clearCompanyEmails}
      enableAutocomplete
      fieldName={fieldName}
      controls={controls}
      focusColor={focusColor}
      validationFieldName={validationFieldName}
      form={form}
      dataQA={dataQA}
    />
  )
);
