import UIStore from './UIStore';
import { action, observable, computed, runInAction } from 'mobx';

import {
  WorkerPhotoIdSummaryFiltersForm,
  workerPhotoIdSummaryFiltersFormFields,
  workerPhotoIdSummaryFiltersFormOptions,
  workerPhotoIdSummaryFiltersFormPlugins,
  workerPhotoIdSummaryFiltersFormRules
} from 'forms/workerPhotoIdSummaryFilters';

import errorHandler from 'utils/errorHandler';

import { callTrack } from 'utils/segmentAnalytics';

import { PHOTO_ID_SUMMARY_FILTERS } from 'utils/segmentAnalytics/eventSpec';

import { t } from 'utils/translate';

export default class PhotoIdSummaryFiltersUI extends UIStore {
  //worker options filters
  @observable filterWorkersQuery;
  @observable searchingForWorker;

  // Filters
  @observable projectFilters;
  @observable memberFilters;
  @observable classificationFilters;
  @observable shiftFilters;
  @observable employeeGroupFilters;

  constructor(options) {
    super(options);

    //Worker Options Filter
    this.filterWorkersQuery = '';
    this.searchingForWorker = false;

    //filters
    this.projectFilters = [];
    this.memberFilters = [];
    this.classificationFilters = [];
    this.shiftFilters = [];
    this.employeeGroupFilters = [];
  }

  @computed
  get eventOriginOptions() {
    return [
      {
        title: t('Kiosk'),
        value: 'KIOSK'
      },
      {
        title: t('Time clock'),
        value: 'TIME_CLOCK'
      }
    ];
  }

  @action.bound
  setFilterWorkersSearchQuery(value) {
    this.searchingForWorker = true;
    this.filterWorkersQuery = value;
  }

  @action.bound
  clearFilterWorkersSearchQuery() {
    this.filterWorkersQuery = '';
  }

  @action.bound
  initFilterForm() {
    this.activeForm = new WorkerPhotoIdSummaryFiltersForm(
      {
        fields: workerPhotoIdSummaryFiltersFormFields,
        rules: workerPhotoIdSummaryFiltersFormRules,
        values: {
          projectFilters: this.projectFilters,
          memberFilters: this.memberFilters,
          classificationFilters: this.classificationFilters,
          shiftFilters: this.shiftFilters,
          employeeGroupFilters: this.employeeGroupFilters
        }
      },
      {
        options: workerPhotoIdSummaryFiltersFormOptions,
        plugins: workerPhotoIdSummaryFiltersFormPlugins
      }
    );
  }

  @action.bound
  terminateFilterForm() {
    this.cancelWorkerSearchQueryReaction &&
      this.cancelWorkerSearchQueryReaction();
    this.activeForm = null;
  }

  getWorkerPhotoIdSummaryFilters() {
    const filters = localStorage.getItem(`photoIdFilters`);
    return filters ? JSON.parse(filters) : {};
  }

  @action.bound
  syncWorkerPhotoIdSummaryWithFilters() {
    const filters = this.getWorkerPhotoIdSummaryFilters();

    this.projectFilters = filters.projectFilters || [];
    this.memberFilters = filters.memberFilters || [];
    this.classificationFilters = filters.classificationFilters || [];
    this.shiftFilters = filters.shiftFilters || [];
    this.employeeGroupFilters = filters.employeeGroupFilters || [];
  }

  @action.bound
  persistFilters() {
    const data = {
      projectFilters: this.projectFilters,
      memberFilters: this.memberFilters,
      classificationFilters: this.classificationFilters,
      shiftFilters: this.shiftFilters,
      employeeGroupFilters: this.employeeGroupFilters
    };

    localStorage.setItem(`photoIdFilters`, JSON.stringify(data));
  }

  @action.bound
  clearPersistedFilters() {
    const data = {
      projectFilters: [],
      memberFilters: [],
      classificationFilters: [],
      shiftFilters: [],
      employeeGroupFilters: []
    };

    try {
      localStorage.setItem(`photoIdFilters`, JSON.stringify(data));
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  @action.bound async showTimeClockPhotoIdFilterDialog() {
    this.showModal('workerPhotoIdSummaryFilterDialog');

    callTrack(PHOTO_ID_SUMMARY_FILTERS);

    try {
      await Promise.all([
        this.memberSelectorUI.setup({ companySettingsTrackMemberTime: true }),
        this.projectSelectorUI.setup(),
        this.classificationSelectorUI.setup(),
        this.shiftSelectorUI.setup(),
        this.groupSelectorUI.setup()
      ]);

      this.initFilterForm();
    } catch (error) {
      this.hideActiveModal();
      errorHandler(error, this.notifications.pushError);
    }
  }

  @action.bound
  handleClearAllFiltersClick() {
    this.clearPersistedFilters();
    this.clearAllFilters();
  }

  @action.bound
  clearAllFilters() {
    return this.hideActiveModal().then(() => {
      runInAction(() => {
        this.terminateFilterForm();
        this.projectFilters.clear();
        this.memberFilters.clear();
        this.classificationFilters.clear();
        this.shiftFilters.clear();
        this.employeeGroupFilters.clear();
        if (this.parent) {
          this.parent.page = 1;
        }
      });
    });
  }

  @action.bound async applyAllFilters() {
    return this.hideActiveModal().then(() => {
      runInAction(() => {
        this.projectFilters.replace(
          this.activeForm.$('projectFilters').values()
        );
        this.memberFilters.replace(this.activeForm.$('memberFilters').values());
        this.classificationFilters.replace(
          this.activeForm.$('classificationFilters').values()
        );
        this.shiftFilters.replace(this.activeForm.$('shiftFilters').values());
        this.employeeGroupFilters.replace(
          this.activeForm.$('employeeGroupFilters').values()
        );
        this.terminateFilterForm();

        if (this.parent) {
          this.parent.page = 1;
        }

        this.persistFilters();
      });
    });
  }

  @action.bound
  async hideFilters() {
    await this.hideActiveModal();

    this.terminateFilterForm();
    this.memberSelectorUI.tearDown();
    this.projectSelectorUI.tearDown();
    this.classificationSelectorUI.tearDown();
    this.shiftSelectorUI.tearDown();
    this.groupSelectorUI.tearDown();
  }

  @computed
  get filtersCounter() {
    let counter = 0;
    if (this.projectFilters.length > 0) {
      counter++;
    }
    if (this.memberFilters.length > 0) {
      counter++;
    }
    if (this.classificationFilters.length > 0) {
      counter++;
    }
    if (this.shiftFilters.length > 0) {
      counter++;
    }
    if (this.employeeGroupFilters.length > 0) {
      counter++;
    }

    return counter;
  }

  @computed get hasAppliedFilters() {
    return !!this.filtersCounter;
  }
}
