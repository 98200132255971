import UIStore from '../UIStore';
import { action, computed, runInAction, observable } from 'mobx';
import WorkLogWorkersUI from './WorkLogWorkersUI';
import escaperegexp from 'lodash.escaperegexp';
import TimeEntriesWorkLog from 'stores/models/workLogs/TimeEntriesWorkLog';
import TimeCard from 'stores/models/workLogs/TimeCard';
import {
  TimeEntriesWorkLogForm,
  timeEntriesWorkLogFormRules,
  timeEntriesWorkLogFormFields,
  timeEntriesWorkLogFormLabels,
  timeEntriesWorkLogFormOptions,
  timeEntriesWorkLogFormPlugins
} from 'forms/timeEntriesWorkLog';
import {
  TIMECARD_STARTED,
  TIMECARD_ADDED,
  TIMECARD_EDITED,
  TIMECARD_VIEWED
} from 'utils/segmentAnalytics/eventSpec';

import OverTimeRule from 'stores/models/OverTimeRule';

import debounce from 'lodash.debounce';
import { BASE_DEBOUNCE } from 'fixtures/constants';
import { MEDIA_TYPES } from 'stores/models/Attachment';
import { callTrack } from 'utils/segmentIntegration';
import orderBy from 'lodash.orderby';
import uniq from 'lodash.uniq';
import omit from 'lodash.omit';
import alertErrorHandler from 'utils/alertErrorHandler';
import request from 'axios';
import { t } from 'utils/translate';

import TimeEntriesWorkLogBulkEditUI from './TimeEntriesWorkLogBulkEditUI';

export default class TimeEntriesWorkLogUI extends UIStore {
  @observable timeEntriesWorkLogForCreation = null;
  @observable timeEntriesWorkLogForEdit = null;
  @observable timeEntriesWorkLogForm = null;
  @observable timeCardForDeleting = null;
  @observable timeCardForm;
  @observable workersWeekTotalHours = [];
  @observable workerTotalHoursAreFetching = true;

  //Pagination
  @observable workLogLoading = false;
  @observable workLogPage = 1;
  @observable timeCardsLoading = false;
  @observable timeCardSearchQuery = '';
  @observable timeCardSearchInputValue = '';
  @observable savingTimeCards = false;

  constructor(options) {
    super(options);

    //Default settings
    this.worklogPageSize = 20;

    this.workLogWorkersUI = new WorkLogWorkersUI({
      rootStore: this.rootStore,
      parent: this
    });

    this.overtimeRule = new OverTimeRule(null, {
      rootStore: this.rootStore
    });

    this.timeEntriesWorkLogBulkEditUI = new TimeEntriesWorkLogBulkEditUI({
      rootStore: this.rootStore,
      parent: this
    });

    this.setTimeCardSearchQuery = debounce(
      this.setTimeCardSearchQuery,
      BASE_DEBOUNCE
    );
  }

  pushTimeCardUrl(workLog) {
    return this.parent.pushTimeCardUrl(workLog);
  }

  @computed
  get workLogs() {
    return this.parent.workLogs;
  }

  @computed
  get currentTimeEntriesWorkLog() {
    return this.timeEntriesWorkLogForCreation || this.timeEntriesWorkLogForEdit;
  }

  @computed
  get currentTimeEntriesWorkLogDate() {
    return this.workLogParams.date;
  }

  @action.bound
  editTimeEntriesWorkLog(timeEntriesWorkLog) {
    this.rootStore.authorizationUI
      .checkFeatureAccess('EditCrewWorkLog')
      .then(() => {
        this.timeEntriesWorkLogForEdit = timeEntriesWorkLog;

        //get week total hours
        const workersUuid = timeEntriesWorkLog.timeCards.models.map(
          timeCard => timeCard.worker.workerUuid
        );
        this.fetchWorkersWeekTotalHours(workersUuid, this.workLogParams.date);

        //open Time entries WorkLog page
        this.activeModalPage = 'timeEntriesWorkLogPage';
        this.showWorkLogLoadingState(200, 'initial').then(() => {
          runInAction(() => {
            this.sendTimeCardAnalyticEvent(TIMECARD_VIEWED);
            this.initTimeEntriesWorkLogForm();
          });
        });
      });
  }

  @action.bound
  initTimeEntriesWorkLogForm() {
    const workLogFormValues = this.currentTimeEntriesWorkLog.formValues;
    //Set default new work log name === worker name if there is only one time card
    if (
      this.selectedWorkers.length === 1 &&
      this.timeEntriesWorkLogForCreation
    ) {
      workLogFormValues.name = this.selectedWorkers[0].fullName;
    }

    this.timeEntriesWorkLogForm = new TimeEntriesWorkLogForm(
      {
        fields: timeEntriesWorkLogFormFields,
        rules: timeEntriesWorkLogFormRules,
        labels: timeEntriesWorkLogFormLabels,
        values: workLogFormValues,
        timeCards: this.currentTimeEntriesWorkLog.timeCards.models
      },
      {
        options: timeEntriesWorkLogFormOptions,
        plugins: timeEntriesWorkLogFormPlugins,
        rootStore: this.rootStore,
        timeEntriesWorkLogUI: this,
        currentTimeEntriesWorkLogDate: this.currentTimeEntriesWorkLogDate
      }
    );
  }

  @action.bound
  async cancelTimeEntriesWorkLogCreationOrEditing() {
    await this.hideActiveModal();

    this.activeModalPage = null;
    this.timeEntriesWorkLogForm = null;
    this.timeEntriesWorkLogForCreation = null;
    this.timeEntriesWorkLogForEdit = null;
    this.workLogPage = 1;
    this.timeCardSearchQuery = '';
    this.timeCardSearchInputValue = '';
    this.workersWeekTotalHours.clear();
    this.savingTimeCards = false;
    this.workLogLoading = false;

    // Remove the last url segment if it is the worklog id
    this.parent.removeLastUrlSegment();

    // Tear down the selectors
    this.tearDownSelectors();
  }

  @action.bound
  closeWorkLog() {
    //prevent closing when data updating or saving
    if (
      this.workLogLoading ||
      this.timeCardsLoading ||
      this.uploadRunning ||
      this.savingTimeCards
    )
      return;

    if (!this.timeEntriesWorkLogForm.workLogIsPristine) {
      this.activeModal = 'DiscardChangesModal';
    } else {
      this.cancelTimeEntriesWorkLogCreationOrEditing();
    }
  }

  @computed
  get uploadRunning() {
    return (
      this.currentTimeEntriesWorkLog?.hasAttachmentsUploading ||
      this.currentTimeEntriesWorkLog?.timeCards.models.find(
        timeCard => timeCard.hasAttachmentsUploading
      ) ||
      false
    );
  }

  @action.bound
  fetchOvertimeRules(projectUuid) {
    return new Promise((resolve, reject) => {
      // get overtime rule uuid by project uuid
      request
        .get(`/ra/projects/${projectUuid}/overtimerules`)
        .then(response => {
          // if there is no rules assigned to the project, fetch a default rule uuid
          if (!response.data.uuid) {
            request
              .get(
                `${this.rootStore.urlMicroService(
                  'performanceTracking'
                )}/overtimerules/default`
              )
              // if there the rule by default rule uuid
              .then(response => {
                this.overtimeRule
                  .fetchByRuleSetId(response.data.uuid)
                  .then(() => {
                    resolve();
                  });
              })
              .catch(error => reject(error));
          } else {
            // if there the rule by assigned rule uuid
            this.overtimeRule
              .fetchByRuleSetId(response.data.uuid)
              .then(() => {
                resolve();
              })
              .catch(error => reject(error));
          }
        })
        .catch(error => reject(error));
    });
  }

  @action.bound
  showWorkLogLoadingState(delay = 100, initial) {
    const requests = [
      new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve();
        }, delay);
      })
    ];

    if (initial) {
      this.workLogLoading = true;
      requests.push(this.setupSelectors());
      requests.push(this.fetchOvertimeRules(this.project.uuid));
    } else {
      this.timeCardsLoading = true;
    }

    return Promise.all(requests).then(() => {
      if (initial) {
        this.workLogLoading = false;
      } else {
        this.timeCardsLoading = false;
      }
    });
  }

  @action.bound
  sendTimeCardAnalyticEvent(event) {
    const isDataRequired =
      event === TIMECARD_VIEWED ||
      event === TIMECARD_EDITED ||
      event === TIMECARD_ADDED;

    if (isDataRequired) {
      const {
        type,
        costCodeCount,
        attachments,
        workDesc,
        shiftCount
      } = this.currentTimeEntriesWorkLog;

      const hasDescription = Boolean(workDesc);
      const countPhotos = attachments.models.filter(
        attachment => attachment.mediaType === MEDIA_TYPES.IMAGE
      ).length;
      const countVideos = attachments.models.filter(
        attachment => attachment.mediaType === MEDIA_TYPES.VIDEO
      ).length;
      const countFiles = attachments.models.filter(
        attachment => attachment.mediaType === MEDIA_TYPES.DOCUMENT
      ).length;

      callTrack(event, {
        timecard_type: type,
        cost_code_selected: Boolean(costCodeCount),
        description_entered: hasDescription,
        photos_attached: countPhotos,
        videos_attached: countVideos,
        files_attached: countFiles,
        ...(event !== TIMECARD_VIEWED && {
          shiftCount: shiftCount
        })
      });
    } else {
      callTrack(event);
    }
  }

  @computed
  get sortedTimeCards() {
    return orderBy(
      this.timeEntriesWorkLogForm.timeCards,
      ['workerFullName'],
      ['asc']
    );
  }

  @computed get hasTimeCards() {
    return this.timeEntriesWorkLogForm?.timeCards.length > 0;
  }

  @computed
  get searchedTimeCards() {
    if (!this.timeCardSearchQuery) {
      return this.sortedTimeCards;
    }

    const searchExpression = new RegExp(
      escaperegexp(this.timeCardSearchQuery.trim()),
      'i'
    );

    const timeCardFilter = timeCard => {
      const workerFullName = timeCard.workerFullName;
      const classificationNames = timeCard.timeEntriesClassificationNames;

      return (
        (workerFullName && workerFullName.search(searchExpression) !== -1) ||
        (classificationNames?.length &&
          classificationNames.find(
            classificationName =>
              classificationName?.search(searchExpression) !== -1
          ))
      );
    };
    return this.sortedTimeCards.filter(timeCardFilter);
  }

  @computed
  get noSearchedTimeCards() {
    return this.searchedTimeCards.length === 0;
  }

  @computed
  get paginatedTimeCards() {
    if (this.timeEntriesWorkLogForm) {
      return this.searchedTimeCards.slice(
        (this.workLogPage - 1) * this.worklogPageSize,
        this.workLogPage * this.worklogPageSize
      );
    }
    return [];
  }

  @computed
  get workLogTotalPages() {
    return Math.ceil(this.searchedTimeCards.length / this.worklogPageSize);
  }

  @computed
  get showTimeCardsPagination() {
    return this.workLogTotalPages > 1;
  }

  @action.bound
  setWorklogPage(page) {
    this.showWorkLogLoadingState().then(() => {
      runInAction(() => {
        this.workLogPage = page;
        window.scrollTo(0, 0);
      });
    });
  }

  @computed
  get selectedWorkers() {
    return this.workLogWorkersUI.selectedWorkers;
  }

  @action.bound
  addWorkersToWorkLog() {
    if (this.currentTimeEntriesWorkLog) {
      this.addTimeCardsToTimeEntriesWorkLogForm();
    } else {
      this.startTimeEntriesWorkLog();
    }
  }

  @action.bound
  startTimeEntriesWorkLog() {
    this.workLogWorkersUI.closeSearchAndSelectWorkerModal();
    this.activeModalPage = 'timeEntriesWorkLogPage';
    this.sendTimeCardAnalyticEvent(TIMECARD_STARTED);

    this.showWorkLogLoadingState(200, 'initial').then(() => {
      runInAction(() => {
        this.timeEntriesWorkLogForCreation = new TimeEntriesWorkLog(
          {
            projectUuid: this.projectUI.projectUuid
          },
          { rootStore: this.rootStore }
        );

        if (this.projectUI.segmentUuid) {
          this.timeEntriesWorkLogForCreation.segmentUuid = this.projectUI.segmentUuid;
        }

        this.initTimeEntriesWorkLogForm();
        this.addTimeCardsToTimeEntriesWorkLogForm();
      });
    });
  }

  @action.bound
  addTimeCardsToTimeEntriesWorkLogForm() {
    this.workLogWorkersUI.closeSearchAndSelectWorkerModal();
    this.showWorkLogLoadingState(200);
    this.workLogPage = 1;

    const workerUuids = this.selectedWorkers.map(worker => worker.workerUuid);
    this.fetchWorkersWeekTotalHours(workerUuids, this.workLogParams.date);

    //Set default new work log name === worker name if there is only one time card
    if (
      this.selectedWorkers.length === 1 &&
      this.timeEntriesWorkLogForCreation
    ) {
      this.timeEntriesWorkLogForm
        .$('name')
        .set(this.selectedWorkers[0].fullName);
    }

    this.selectedWorkers.forEach(worker => {
      const timeCard = new TimeCard(
        {
          worker: worker
        },
        { rootStore: this.rootStore }
      );

      this.timeEntriesWorkLogForm.addTimeCard(timeCard);
    });
    this.workLogWorkersUI.selectedWorkers.clear();
  }

  @action.bound
  deleteTimeCardRequest(timeCardLog) {
    this.activeModal = 'confirmDeleteTimeCard';
    this.timeCardForDeleting = timeCardLog;
  }

  @action.bound
  confirmDeleteTimeCard() {
    this.hideActiveModal().then(() => {
      this.timeEntriesWorkLogForm.removeTimeCard(this.timeCardForDeleting);
      this.timeCardForDeleting = null;
    });
  }

  @action.bound
  cancelDeleteTimeCard() {
    this.hideActiveModal().then(() => {
      this.timeCardForDeleting = null;
    });
  }

  @computed
  get workLogParams() {
    return this.parent.workLogParams;
  }

  @action.bound
  startSavingProcess() {
    this.savingTimeCards = true;
    this.workLogLoading = true;

    setTimeout(() => {
      this.saveTimeEntriesWorkLog();
    }, 50);
  }

  @action.bound
  async saveTimeEntriesWorkLog() {
    this.timeEntriesWorkLogForm.allocateHoursWorked();
    this.timeEntriesWorkLogForm.validateBeforeSaving();

    if (this.timeEntriesWorkLogForm.workLogIsValid) {
      const payload = this.timeEntriesWorkLogForm.cleanedValues;

      if (this.currentTimeEntriesWorkLog.attachments.hasModels) {
        payload.attachments = this.currentTimeEntriesWorkLog.attachments.models.map(
          attachment => {
            return { uuid: attachment.uuid };
          }
        );
      }

      if (this.projectUI.segmentUuid) {
        payload.segmentUuid = this.projectUI.segmentUuid;
      }

      const workLogFromCurrentTable = this.workLogs.models.find(
        workLog => workLog.id === this.currentTimeEntriesWorkLog.id
      );

      if (workLogFromCurrentTable) {
        this.timeEntriesWorkLogForEdit = workLogFromCurrentTable;
      }

      try {
        const timeEnytriesWorkLog = await this.currentTimeEntriesWorkLog.save(
          payload,
          {
            wait: true,
            reset: true,
            stripNonRest: false,
            url: this.timeEntriesWorkLogForCreation
              ? `${this.rootStore.urlMicroService(
                  'performanceTracking'
                )}/worklogs/projects/${this.workLogParams.projectUuid}/${
                  this.workLogParams.date
                }`
              : `${this.rootStore.urlMicroService(
                  'performanceTracking'
                )}/worklogs/${this.currentTimeEntriesWorkLog.uuid}`
          }
        );

        if (this.timeEntriesWorkLogForCreation) {
          this.workLogs.add(timeEnytriesWorkLog, {
            unshift: true
          });
        }

        const event = this.timeEntriesWorkLogForCreation
          ? TIMECARD_ADDED
          : TIMECARD_EDITED;
        this.sendTimeCardAnalyticEvent(event);

        this.cancelTimeEntriesWorkLogCreationOrEditing();
        this.rootStore.notificationsUI.pushNotification({
          snackbar: 'warning',
          icon: 'notification',
          title: t('Time entries have been saved.')
        });
      } catch (error) {
        alertErrorHandler(error, this.setValidationDetails);
        this.savingTimeCards = false;
        this.workLogLoading = false;
      }
    } else {
      this.savingTimeCards = false;
      this.workLogLoading = false;
    }
  }

  @computed
  get disableSaveOrCreateButton() {
    return (
      !this.hasTimeCards ||
      !this.timeEntriesWorkLogForm ||
      !this.timeEntriesWorkLogForm.workLogIsValid ||
      this.workLogLoading ||
      this.timeCardsLoading ||
      this.uploadRunning ||
      this.savingTimeCards ||
      this.timeEntriesWorkLogForm
        .hasKioskTimeCardsThatCannotBeSavedDueToBreakRules
    );
  }

  @computed
  get saveButtonText() {
    if (this.uploadRunning) return t('Uploading files');
    if (this.savingTimeCards) return t('Saving ...');
    if (this.timeEntriesWorkLogForEdit) return t('Save');
    return t('Create');
  }

  @action.bound
  openManageBreaksModal(timeCardForm) {
    this.timeCardForm = timeCardForm;
    // save initial break values in case of cancel changes
    this.initialBreaks = [
      ...this.timeCardForm.breaks.map(breaksForm => {
        return { formValues: breaksForm.values() };
      })
    ];
    if (this.timeEntriesWorkLogBulkEditUI.timeEntriesBulkEditForm) {
      this.hideActiveModal().then(() => {
        this.activeModal = 'manageBreaks';
      });
    } else {
      this.activeModal = 'manageBreaks';
    }
  }

  @action.bound
  closeManageBreaksForm() {
    this.hideActiveModal().then(() => {
      //cancel breaks changes
      this.timeCardForm.breaks.clear();
      this.timeCardForm.setBreaks(this.initialBreaks);
      this.initialBreaks = null;
      this.timeCardForm = null;
      if (this.timeEntriesWorkLogBulkEditUI.timeEntriesBulkEditForm) {
        this.activeModal = 'timeEntriesBulkEdit';
      }
    });
  }

  @action.bound
  submitManageBreaksForm(e) {
    e.preventDefault();

    if (!this.timeCardForm.hasEmptyBreaks) {
      for (let breakEntryForm of this.timeCardForm.breaks) {
        breakEntryForm.validate();
      }
    }

    if (this.timeCardForm.breaksAreInvalid) return;

    this.hideActiveModal().then(() => {
      this.initialBreaks = null;
      this.timeCardForm = null;
      if (this.timeEntriesWorkLogBulkEditUI.timeEntriesBulkEditForm) {
        this.activeModal = 'timeEntriesBulkEdit';
      }
    });
  }

  @computed
  get timeEntriesHours() {
    return this.timeEntriesWorkLogForm.timeCards.map(timeCard => {
      return timeCard.orderedTimeEntries.map(timeEntry => {
        return timeEntry.cleanedValues.hours;
      });
    });
  }

  @computed
  get hasMultipleTimeCardsWithSameTimeEntries() {
    return (
      uniq(
        this.timeEntriesWorkLogForm.timeCards.map(timeCard => {
          return JSON.stringify(
            timeCard.orderedTimeEntries.map(timeEntry => {
              const { uuid, ...values } = timeEntry.cleanedValues;
              return values;
            })
          );
        })
      ).length === 1 && this.timeEntriesWorkLogForm.timeCards.length > 1
    );
  }

  @computed
  get hasMultipleTimeCardsWithAllTheSameStartEndTimeAndHours() {
    return (
      uniq(
        this.timeEntriesWorkLogForm.timeCards.map(timeCard =>
          JSON.stringify(omit(timeCard.bulkEditFormValues, 'timeEntries'))
        )
      ).length === 1 && this.timeEntriesWorkLogForm.timeCards.length > 1
    );
  }

  @action.bound
  async fetchWorkersWeekTotalHours(workerUuids, date) {
    this.workerTotalHoursAreFetching = true;
    try {
      const response = await request.post(
        `${this.rootStore.urlMicroService(
          'performanceTracking'
        )}/worklogs/weekHours/${date}?type=BOTH`,
        workerUuids
      );

      this.workersWeekTotalHours.push(...response.data);
      this.workerTotalHoursAreFetching = false;
    } catch (error) {
      this.workerTotalHoursAreFetching = false;

      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound
  setSearchTimeCardsInput(query) {
    this.timeCardSearchInputValue = query;
    this.setTimeCardSearchQuery(query);
  }

  @action.bound
  setTimeCardSearchQuery(query) {
    this.showWorkLogLoadingState().then(() => {
      runInAction(() => {
        this.timeCardSearchQuery = query;
        this.workLogPage = 1;
      });
    });
  }

  @action.bound
  clearTimeCardSearchQuery() {
    this.timeCardSearchInputValue = '';
    this.showWorkLogLoadingState().then(() => {
      runInAction(() => {
        this.timeCardSearchQuery = '';
        this.workLogPage = 1;
      });
    });
  }

  @computed
  get showSyncedOrApprovedAlert() {
    if (!this.timeEntriesWorkLogForEdit) return false;
    return (
      this.timeEntriesWorkLogForEdit.hasApprovedTimeCard ||
      this.timeEntriesWorkLogForEdit.hasSyncedTimeCard
    );
  }

  @computed
  get worklogDetailsDisplayedText() {
    return `${this.currentTimeEntriesWorkLogDate} | ${this.project.name} ${
      this.project.projectNo ? `| ${this.project.projectNo}` : ''
    }`;
  }

  @computed
  get classificationsNotEditableOnTimeCards() {
    return !this.project?.classificationsEditableOnTimeCards;
  }

  @action.bound setupSelectors() {
    return Promise.all([
      this.costCodeSelectorUI.setup(),
      this.shiftSelectorUI.setup(),
      this.groupSelectorUI.setup(),
      this.classificationSelectorUI.setup(),
      this.payTypeSelectorUI.setup(),
      this.breakSelectorUI.setup()
    ]);
  }

  @action.bound tearDownSelectors() {
    this.costCodeSelectorUI.tearDown();
    this.shiftSelectorUI.tearDown();
    this.groupSelectorUI.tearDown();
    this.classificationSelectorUI.tearDown();
    this.payTypeSelectorUI.tearDown();
    this.breakSelectorUI.tearDown();
  }
}
