import { observable, action, computed } from 'mobx';
import orderBy from 'lodash.orderby';
import request from 'axios';
import pickBy from 'lodash.pickby';
import isEqual from 'lodash.isequal';
import sortBy from 'lodash.sortby';

import * as Validator from 'validatorjs';

import UIStore from './UIStore';

import history from 'utils/history';
import { t } from 'utils/translate';

import alertErrorHandler from 'utils/alertErrorHandler';
import crossOriginDownload from 'utils/crossOriginDownload';

export default class ProjectDirectoryWorkersImportUI extends UIStore {
  @observable step;

  // Workers
  @observable importedWorkersPageSize;
  @observable importedWorkersPage;

  constructor(options) {
    super(options);

    this.step = 'import';

    // Workers
    this.importedWorkersPageSize = 10;
    this.importedWorkersPage = 1;
    this.importedWorkers = observable([]);
  }

  @action.bound setStep(step) {
    this.step = step;
  }

  @action.bound setup(step = 'import') {
    this.setStep(step);
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @computed get classificationOptions() {
    return this.parent.classificationOptions;
  }

  @action.bound async createWorkers() {
    if (this.saving) return;

    try {
      this.saving = true;

      const workerFields = this.importedWorkers.slice();

      workerFields.forEach(workerField => {
        workerField.projectUuids = [this.project.uuid];
        workerField.role = 'ROLE_WORKER';

        if (
          !workerField.classification.uuid &&
          !workerField.classification.name
        ) {
          delete workerField.classification;
        }
      });

      const payload = workerFields.map(workerField =>
        pickBy(
          workerField,
          value => value !== undefined && value !== '' && value !== null
        )
      );

      // Send parallel requests if over 500.
      if (payload.length > 500) {
        await Promise.all([
          request.post(
            `${this.rootStore.users.url()}/import`,
            payload.slice(0, 500)
          ),
          request.post(
            `${this.rootStore.users.url()}/import`,
            payload.slice(500, 1000)
          )
        ]);
      } else {
        await request.post(`${this.rootStore.users.url()}/import`, payload);
      }

      this.parent.sortByLastCreated();
      this.classificationsUI.fetchClassifications();
      this.cancelWorkersImport();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Workers added')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound cancelWorkersImport() {
    history.push(`${this.project.viewUrl}/directory/workers`);
  }

  @action.bound cancelWorkers() {
    this.importedWorkers.clear();
    this.setStep('import');
  }

  @action.bound
  downloadWorkersCSV(event) {
    event.preventDefault();

    crossOriginDownload(
      `${this.assetsURL}/csv/workers.csv`,
      `${t('workers')}.csv`
    );
  }

  @action.bound importWorkersCSV(file) {
    this.clearValidationDetails();

    const reader = new FileReader();

    reader.onload = event => {
      const text = event.target.result;

      this.processCSV(text);
    };

    reader.readAsText(file);
  }

  @action.bound processCSV(text, delim = ',') {
    let headers = text
      .trim()
      .slice(0, text.indexOf('\n'))
      .split(delim);

    const rows = text
      .trim()
      .slice(text.indexOf('\n') + 1)
      .split('\n');

    headers = headers.map(header => header.replace('\r', ''));

    const expectedHeaders = [
      'firstName',
      'lastName',
      'employeeId',
      'email',
      'phoneNumber',
      'classification'
    ];

    if (!isEqual(sortBy(expectedHeaders), sortBy(headers))) {
      this.setValidationDetails([
        {
          field: 'import',
          fieldMessage: t(
            'Your CSV is empty or does not follow the correct format. Please use the pre-formatted template.'
          )
        }
      ]);

      return;
    }

    const workerFields = rows.map(row => {
      const values = row.split(delim);
      const eachObject = headers.reduce((obj, header, i) => {
        obj[header.trim()] = values[i].trim();
        return obj;
      }, {});
      return eachObject;
    });

    this.populateWorkersFormFromCSV(workerFields);
  }

  @action.bound populateWorkersFormFromCSV(workerFields) {
    this.importedWorkers.replace(
      workerFields.filter(worker => {
        worker.classification = {
          uuid: '',
          name: worker.classification
        };

        let validation = new Validator(worker, {
          firstName: 'string|max:255|required',
          lastName: 'string|max:255|required',
          employeeId: 'string|max:100',
          email: 'email|max:200',
          phoneNumber: 'string|max:30'
        });

        return !validation.fails();
      })
    );

    if (!this.hasImportedWorkers) {
      this.setValidationDetails([
        {
          field: 'unknown',
          fieldMessage:
            'The CSV file has no valid entries. Please check it and upload again.'
        }
      ]);

      return;
    }

    // Limit to 1000 valid records
    this.importedWorkers.replace(this.importedWorkers.slice(0, 1000));

    this.setStep('workers');
  }

  @computed get hasImportedWorkers() {
    return this.importedWorkers.length > 0;
  }

  @computed get showImportedWorkersPagination() {
    return this.importedWorkersTotalPages > 1;
  }

  @computed get sortedImportedWorkers() {
    return orderBy(
      this.importedWorkers,
      ['firstName', 'lastName'],
      ['asc', 'asc']
    );
  }

  @computed get paginatedImportedWorkers() {
    return this.sortedImportedWorkers.slice(
      (this.importedWorkersPage - 1) * this.importedWorkersPageSize,
      (this.importedWorkersPage - 1) * this.importedWorkersPageSize +
        this.importedWorkersPageSize
    );
  }

  @computed
  get importedWorkersTotalPages() {
    return Math.ceil(
      this.sortedImportedWorkers.length / this.importedWorkersPageSize
    );
  }

  @action.bound setImportedWorkersPage(event, page) {
    this.importedWorkersPage = page;
  }

  @action.bound submitWorkersForm(event) {
    event.preventDefault();

    this.createWorkers();
  }

  @action.bound removeImportedWorker(worker) {
    this.importedWorkers.remove(worker);

    if (!this.hasImportedWorkers) {
      this.setStep('import');
    }
  }

  @action.bound clearUIState() {
    this.step = 'import';
    this.clearValidationDetails();
    this.loading = false;
    this.importedWorkers.clear();
    this.importedWorkers.page = 1;
  }
}
