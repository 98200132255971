import request from 'axios';
import debounce from 'lodash.debounce';
import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import { action, observable, computed, reaction, runInAction } from 'mobx';

import SettingsChildUI from './SettingsChildUI';
import SettingsCostCodeAddUI from './SettingsCostCodeAddUI';
import SettingsCostCodeEditUI from './SettingsCostCodeEditUI';
import SettingsCostCodesImportUI from './SettingsCostCodesImportUI';

import CostCodes from 'stores/collections/CostCodes';

import { BASE_DEBOUNCE } from 'fixtures/constants';

import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import history from 'utils/history';

export default class SettingsCostCodesUI extends SettingsChildUI {
  @observable searchQuery;
  @observable sortField;
  @observable sortDirection;
  @observable pageSize;
  @observable page;
  @observable loading;
  @observable selectedCostCode;

  @observable showInactiveCompanyLevelCodes;

  @observable selectedBulkCostCodeAction;

  constructor(options) {
    super(options);

    this.searchQuery = '';
    this.loading = true;
    this.sortField = 'division,code';
    this.sortDirection = 'asc';
    this.page = 1;
    this.pageSize = 20;
    this.showInactiveCompanyLevelCodes = false;

    this.selectedCostCodes = observable([]);
    this.selectedCostCode = null;

    this.selectedBulkCostCodeAction = null;

    this.paginatedCostCodes = new CostCodes(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.costCodeAddUI = new SettingsCostCodeAddUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.costCodeEditUI = new SettingsCostCodeEditUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.costCodesImportUI = new SettingsCostCodesImportUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchCostCodesDebounced = debounce(this.fetchCostCodes, BASE_DEBOUNCE);
  }

  @computed get headerTitle() {
    return t('Manage available cost codes to break down hours');
  }

  @computed get costCodes() {
    return this.paginatedCostCodes;
  }

  @computed get title() {
    return t('Cost codes');
  }

  @computed get hasWriteAccess() {
    return this.authorization.canCUDCostCodes;
  }

  @action.bound setup() {
    this.setupReactions();
    this.fetchCostCodes();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchCostCodesDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      sortField: this.sortField,
      query: this.searchQuery,
      sortDirection: this.sortDirection,
      projectUuids: this.projectUuid,
      isDefault: Boolean(this.showInactiveCompanyLevelCodes),
      includeMaterials: 'false'
    };
  }

  @computed get hasCostCodes() {
    return this.costCodes.hasModels;
  }

  @action.bound async fetchCostCodes() {
    if (!this.projectUuid) return;

    this.costCodes.cancelRequest();
    this.costCodes.reset();

    try {
      await this.costCodes.fetch({
        params: pickBy(this.params, identity)
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.costCodes.totalElements / this.pageSize);
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    this.selectedCostCodes.clear();
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound toggleShowInactiveCompanyLevelCodes() {
    this.showInactiveCompanyLevelCodes = !this.showInactiveCompanyLevelCodes;
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.costCodes.clear();
    this.selectedCostCodes.clear();
    this.searchQuery = '';
    this.page = 1;
    this.loading = true;
    this.saving = false;
    this.sortField = 'division,code';
    this.sortDirection = 'asc';
    this.selectedCostCode = null;
    this.showInactiveCompanyLevelCodes = false;
    this.selectedBulkCostCodeAction = null;
  }

  @computed get hasSelectedCostCodes() {
    return this.selectedCostCodes.length > 0;
  }

  @action.bound
  toggleSelectCostCode(costCode) {
    if (this.selectedCostCodes.find(uuid => uuid === costCode.uuid)) {
      this.selectedCostCodes.remove(costCode.uuid);
    } else {
      this.selectedCostCodes.push(costCode.uuid);
    }
  }

  @computed
  get allCostCodesSelected() {
    return (
      this.hasCostCodes &&
      this.selectedCostCodes.length === this.costCodes.length
    );
  }

  @action.bound
  toggleSelectAllCostCodes() {
    if (this.allCostCodesSelected) {
      this.selectedCostCodes.clear();
    } else {
      this.selectedCostCodes.replace(
        this.costCodes.models.map(costCode => costCode.uuid)
      );
    }
  }

  @action.bound sortByLastCreated() {
    this.sortField = 'createdTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchCostCodesDebounced();
  }

  @action.bound sortByLastUpdated() {
    this.sortField = 'updatedTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchCostCodesDebounced();
  }

  @action.bound refetchCostCodes() {
    this.loading = true;

    if (!this.hasCostCodes) {
      this.setPage(null, 1);
      this.fetchCostCodes();
    } else {
      this.fetchCostCodes();
    }
  }

  @action.bound async deleteCostCode(costCode) {
    await this.authorization.checkFeatureAccess('CUDCostCodes');

    this.selectedCostCode = costCode;
    this.showModal('DeleteModal');
  }

  @action.bound async cancelDeleteCostCode() {
    await this.hideActiveModal();

    this.selectedCostCode = null;
  }

  @action.bound async confirmDeleteCostCode() {
    this.saving = true;

    try {
      if (this.selectedCostCode.isDefault) {
        await this.selectedCostCode.destroy({ wait: true });
      } else {
        await this.selectedCostCode.destroy({
          wait: true,
          url: `${this.selectedCostCode.url()}/projects/${this.projectUuid}`
        });
      }

      this.project.costCodesWithBudget.remove(this.selectedCostCode);

      this.refetchCostCodes();

      await this.hideActiveModal();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',

        title: t('Cost code deleted')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound async toggleCompanyLevelCostCode(costCode) {
    await this.authorization.checkFeatureAccess('CUDCostCodes');

    if (costCode.getProjectByUuid(this.project.uuid)) {
      costCode.removeProjectByUuid(this.project.uuid);

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Cost code disabled')
      });
    } else {
      costCode.addProjectByUuid(this.project.uuid);

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Cost code enabled')
      });
    }
  }

  @action.bound async addCostCode() {
    await this.authorization.checkFeatureAccess('CUDCostCodes');

    history.push({
      pathname: `${this.project.viewUrl}/settings/production-tracking/costcodes/add`,
      search: this.baseQueryParams
    });
  }

  @action.bound async editCostCode(costCode) {
    await this.authorization.checkFeatureAccess('CUDCostCodes');

    if (!costCode.getProjectByUuid(this.projectUuid)) return;

    history.push({
      pathname: `${this.project.viewUrl}/settings/production-tracking/costcodes/${costCode.uuid}`,
      search: this.baseQueryParams
    });
  }

  @action.bound async importCostCodes() {
    await this.authorization.checkFeatureAccess('CUDCostCodes');

    history.push({
      pathname: `${this.project.viewUrl}/settings/production-tracking/costcodes/import`,
      search: this.baseQueryParams
    });
  }

  @computed get showEmptyState() {
    return !this.loading && !this.hasCostCodes;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  // Bulk Actions
  @computed get bulkCostCodeActions() {
    return [
      {
        title: t('Enable'),
        onClick: () => {
          this.bulkCostCodeAction('ENABLE');
        }
      },
      {
        title: t('Disable'),
        onClick: () => {
          this.bulkCostCodeAction('DISABLE');
        }
      },
      {
        title: t('Delete'),
        onClick: () => {
          this.bulkCostCodeAction('DELETE');
        }
      }
    ];
  }

  @action.bound async bulkCostCodeAction(action) {
    await this.authorization.checkFeatureAccess('CUDCostCodes');

    this.selectedBulkCostCodeAction = action;
    this.showModal('CostCodeBulkActionModal');
  }

  @action.bound async cancelBulkCostCodeAction() {
    await this.hideActiveModal();
    this.selectedBulkCostCodeAction = null;
  }

  @computed get bulkCostCodeActionTitle() {
    if (!this.selectedBulkCostCodeAction) return null;

    switch (this.selectedBulkCostCodeAction) {
      case 'ENABLE':
        return t(`Enable selected cost codes?`);
      case 'DISABLE':
        return t(`Disable selected cost codes?`);
      default:
        return t(`Delete selected cost codes?`);
    }
  }

  @computed get bulkCostCodeActionText() {
    if (!this.selectedBulkCostCodeAction) return null;

    switch (this.selectedBulkCostCodeAction) {
      case 'ENABLE':
        return t(
          `Would you like to enable the selected company cost codes? Selected project cost codes will not be affected.`
        );
      case 'DISABLE':
        return t(
          `Would you like to disable the selected company cost codes? Selected project cost codes will not be affected.`
        );
      default:
        return 'Would you like to delete the selected cost codes? Selected company cost codes will be disabled.';
    }
  }

  @computed get bulkCostCodeActionButtonText() {
    switch (this.selectedBulkCostCodeAction) {
      case 'DISABLE':
        return this.saving ? t('Disabling...') : t('Disable');
      case 'ENABLE':
        return this.saving ? t('Enabling...') : t('Enable');
      default:
        return this.saving ? t('Deleting...') : t('Delete');
    }
  }

  @computed get bulkCostCodeActionButtonColor() {
    switch (this.selectedBulkCostCodeAction) {
      case 'DELETE':
        return 'red';
      default:
        return 'orange';
    }
  }

  @computed get bulkCostCodeActionNotificationText() {
    switch (this.selectedBulkCostCodeAction) {
      case 'DELETE':
        return t(`Cost codes deleted`);
      case 'ENABLE':
        return t(`Cost codes enabled`);
      case 'DISABLE':
        return t(`Cost codes disabled`);
      default:
        return '';
    }
  }

  @action.bound async confirmBulkCostCodeAction() {
    this.clearValidationDetails();
    this.saving = true;

    try {
      switch (this.selectedBulkCostCodeAction) {
        case 'DELETE':
          await this.bulkDelete();
          break;
        case 'ENABLE':
          await this.bulkEnable();
          break;
        case 'DISABLE':
          await this.bulkDisable();
          break;
      }

      this.refetchCostCodes();

      await this.hideActiveModal();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: this.bulkCostCodeActionNotificationText
      });

      this.selectedCostCodes.clear();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound async bulkDelete() {
    const costCodes = this.costCodes.models.filter(costCode =>
      this.selectedCostCodes.includes(costCode.uuid)
    );

    const promises = [];
    const costCodeUuids = [];

    costCodes.forEach(costCode => {
      if (costCode.isDefault || costCode.hasMultipleProjects) {
        promises.push(costCode.removeProjectByUuid(this.project.uuid));
      } else {
        costCodeUuids.push(costCode.uuid);
      }

      this.project.costCodesWithBudget.remove(costCode);
    });

    if (costCodeUuids.length > 0) {
      promises.push(
        request.post(
          `${this.rootStore.urlMicroService(
            'performanceTracking'
          )}/costcodes/delete`,
          costCodeUuids
        )
      );
    }

    return Promise.all(promises);
  }

  @action.bound async bulkEnable() {
    const costCodes = this.costCodes.models.filter(costCode => {
      return (
        this.selectedCostCodes.includes(costCode.uuid) && costCode.isDefault
      );
    });

    const promises = [];

    costCodes.forEach(costCode => {
      promises.push(costCode.addProjectByUuid(this.project.uuid));
    });

    return Promise.all(promises);
  }

  @action.bound async bulkDisable() {
    const costCodes = this.costCodes.models.filter(costCode => {
      return (
        this.selectedCostCodes.includes(costCode.uuid) && costCode.isDefault
      );
    });

    const promises = [];

    costCodes.forEach(costCode => {
      promises.push(costCode.removeProjectByUuid(this.project.uuid));
    });

    return Promise.all(promises);
  }
}
