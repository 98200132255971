import orderBy from 'lodash.orderby';
import ProjectInsightsUIModel from './ProjectInsightsUIModel';
import { computed, action, reaction } from 'mobx';

export default class ProjectInsightsOpenTasks extends ProjectInsightsUIModel {
  constructor(attributes, options) {
    super(attributes, options);
    // React to selection changes
    this.reactToSelection = reaction(
      () => this.params,
      params => {
        if (params && params.projectTeamIds) {
          this.fetchStats();
        } else {
          this.cancelRequest();
        }
      },
      { fireImmediately: true }
    );
  }

  @computed get tasks() {
    return this.parent.projectUI.tasksUI.tasks.models;
  }

  @computed
  get sortedTasks() {
    return orderBy(
      this.tasks,
      [task => task[this.sortField], 'createdTimestamp'],
      [this.sortDirection, 'desc']
    );
  }

  @computed get hasTasks() {
    return this.tasks.length > 0;
  }

  @action.bound fetch() {
    return this.parent.projectUI.tasksUI.tasks.fetch({
      params: {
        limit: 10000,
        status: 'OPEN',
        projectUuids: this.parent.projectUI.projectUuid
      }
    });
  }
}
