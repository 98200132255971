import { Model } from 'mobx-mc';
import { action } from 'mobx';

import omit from 'lodash.omit';

import TimeCardEvents from '../collections/TimeCardEvents';

export default class TimeCardEventsByDate extends Model {
  get restAttributes() {
    return ['date'];
  }

  @action.bound
  parse(attributes) {
    this.parseWorkers(attributes);
    return {
      ...omit(attributes, ['workers'])
    };
  }

  @action.bound
  parseWorkers(attributes) {
    this.workers = attributes.workers.map(worker => {
      return {
        timeCards: worker.timeCards.map(timeCard => {
          return {
            ...timeCard,
            events: new TimeCardEvents(
              timeCard.events.map(event => {
                return {
                  ...event,
                  workerDetails: { ...worker.workerDetails },
                  totalHours: timeCard.totalHours,
                  timeCardDetails: {
                    ...omit(timeCard, ['events']),
                    timeCardTotalHours: worker.totalHours
                  }
                };
              }),
              {
                parent: this,
                rootStore: this.rootStore
              }
            )
          };
        }),
        workerDetails: worker.workerDetails
      };
    });
  }
}
