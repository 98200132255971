import InsightsUIModel from './InsightsUIModel';
import { computed, action, observable } from 'mobx';
import escaperegexp from 'lodash.escaperegexp';
import { t } from 'utils/translate';

export default class InsightsChart extends InsightsUIModel {
  // Project Selection
  @observable chartProjectSelection;
  @observable selectedChartProjects;
  @observable chartProjectSearch;
  @observable chartProjectSelectionLimit;

  constructor(attributes, options) {
    super(attributes, options);

    // Legend Selection
    this.chartProjectSelection = options.chartProjectSelection;
    this.chartProjectSelectionLimit =
      options.chartProjectSelectionLimit || null;
    this.selectedChartProjects = [];
    this.chartProjectSearch = '';
  }

  url() {
    return '/ra/insights/statsByProject';
  }

  @computed get chartProjects() {
    if (this.chartProjectSelection === 'RECENT') {
      return this.insightsUI.recentProjects;
    }

    return this.selectedChartProjects;
  }

  @computed get chartProjectIds() {
    return this.chartProjects.map(project => project.parentProjectId);
  }

  @computed get chartProjectNames() {
    return this.chartProjects.map(project => project.name);
  }

  @computed get hasChartProjects() {
    return this.chartProjects.length > 0;
  }

  @computed get nonChartProjects() {
    if (this.chartProjectSelection === 'RECENT') {
      return this.insightsUI.nonRecentProjects;
    }

    return this.insightsUI.filteredProjects.filter(project => {
      return !this.selectedChartProjects.find(
        selectedProject => selectedProject.id === project.parentProjectId
      );
    });
  }

  @computed get hasNonChartProjects() {
    return this.nonChartProjects.length > 0;
  }

  @computed
  get remainingChartProjectSelections() {
    return (
      this.chartProjectSelectionLimit &&
      this.chartProjectSelectionLimit - this.selectedChartProjects.length
    );
  }

  @computed
  get atChartProjectSelectionMin() {
    return this.selectedChartProjects.length === 0;
  }

  @computed
  get atChartProjectSelectionLimit() {
    return (
      this.chartProjectSelectionLimit &&
      this.selectedChartProjects.length >= this.chartProjectSelectionLimit
    );
  }

  @action.bound
  setChartProjectSearch(value) {
    this.chartProjectSearch = value;
  }

  @action.bound
  clearChartProjectSearch() {
    this.chartProjectSearch = '';
  }

  @action.bound
  setChartProjectSelection(selection) {
    if (this.parent.applySelectionsToAllCharts) {
      if (selection === 'SELECTED') {
        this.parent.clearChartProjectSelectionOnAllCharts();
      }

      this.parent.setChartProjectSelectionOnAllCharts(selection);
    } else {
      if (selection === 'SELECTED') {
        this.selectedChartProjects.clear();
      }

      this.chartProjectSelection = selection;
    }
  }

  @action.bound
  toggleChartProject(project) {
    if (this.selectedChartProjects.includes(project)) {
      if (this.atChartProjectSelectionMin) {
        return;
      }

      if (this.parent.applySelectionsToAllCharts) {
        this.parent.removeChartProjectFromAllCharts(project);
      } else {
        this.selectedChartProjects.remove(project);
      }
    } else {
      if (this.atChartProjectSelectionLimit) {
        return;
      }

      if (this.parent.applySelectionsToAllCharts) {
        this.parent.pushChartProjectToAllCharts(project);
      } else {
        this.selectedChartProjects.push(project);
      }
    }
  }

  @computed
  get chartProjectSelectionString() {
    switch (this.chartProjectSelection) {
      case 'RECENT':
        return t('Recent Projects');
      case 'SELECTED':
        return t('Selected Projects');
      default:
        return t('Recent Projects');
    }
  }

  @computed
  get searchedRecentProjects() {
    const searchExpression = new RegExp(
      escaperegexp(this.chartProjectSearch),
      'i'
    );

    return this.insightsUI.recentProjects.filter(project => {
      return project.name.search(searchExpression) !== -1;
    });
  }

  @computed
  get searchedNonRecentProjects() {
    const searchExpression = new RegExp(
      escaperegexp(this.chartProjectSearch),
      'i'
    );

    return this.insightsUI.nonRecentProjects.filter(project => {
      return project.name.search(searchExpression) !== -1;
    });
  }

  @computed get hasChartProjectSelections() {
    return this.selectedChartProjects.length > 0;
  }
}
