import orderBy from 'lodash.orderby';
import escaperegexp from 'lodash.escaperegexp';
import request from 'axios';
import { observable, action, computed } from 'mobx';
import errorHandler from 'utils/errorHandler';
import timeFrameToStartDay from 'utils/timeFrameToStartDay';
import timeFrameToEndDay from 'utils/timeFrameToEndDay';

import UIStore from './UIStore';
import ProjectInsightsAllTimeStats from '../models/ProjectInsightsAllTimeStats';
import ProjectInsightsChartWithDateRanges from '../models/ProjectInsightsChartWithDateRanges';
import ProjectInsightsOpenTasks from '../models/ProjectInsightsOpenTasks';
import ProjectInsightsToolBoxTalks from '../models/ProjectInsightsToolBoxTalks';

import ProjectInsightsTopUsers from '../models/ProjectInsightsTopUsers';
import ProjectInsightsComplianceBySubContractor from '../models/ProjectInsightsComplianceBySubContractor';

import {
  TimeFrameForm,
  timeFrameFormOptions,
  timeFrameFormFields,
  timeFrameFormRules,
  timeFrameFormLabels,
  timeFrameFormPlugins,
  timeFrameFormValues
} from 'forms/timeFrame';

export default class ProjectDashboardInsightsUI extends UIStore {
  // Panel toggle states
  @observable allTimeStatsOpen;
  @observable workersOpen;
  @observable topUsersOpen;
  @observable complianceBySubContractorOpen;
  @observable hoursOpen;
  @observable delaysOpen;
  @observable openTasksOpen;
  @observable safetyIncidentsOpen;
  @observable toolBoxTalksOpen;

  // All charts
  @observable applySelectionsToAllCharts;
  @observable applyTimeFrameToAllPanels;
  @observable fetchingAllCharts;

  // Pdf All
  @observable allPdf;

  // Global Selection
  @observable projectTeamSelection;
  @observable selectedProjectTeams;
  @observable projectTeamSearch;
  @observable timeFrameSelection;
  @observable fromDate;
  @observable toDate;

  // Mobile Nav
  @observable mobileNavOpen;

  // PDF download
  @observable pdfDownload;

  // Setup
  @observable modelsSet;

  constructor(options) {
    super(options);

    this.allTimeStatsOpen = false;
    this.workersByProjectOpen = false;
    this.hoursByProjectOpen = false;

    this.topUsersOpen = false;
    this.complianceBySubContractorOpen = false;
    this.delaysOpen = false;
    this.openTasksOpen = false;
    this.safetyIncidentsOpen = false;
    this.toolBoxTalksOpen = false;

    this.applySelectionsToAllCharts = true;
    this.fetchingAllCharts = false;

    this.modelsSet = false;
    this.allPdf = [];

    // Global Selection
    this.timeFrameSelection = 'ALL_TIME';
    this.fromDate = null;
    this.toDate = null;
    this.projectTeamSelection = 'MINE';
    this.selectedProjectTeams = [];
    this.projectTeamSearch = '';

    // Mobile Nav
    this.mobileNavOpen = false;

    // PDF
    this.pdfDownload;
  }

  @action.bound setPdfDownload(value) {
    this.pdfDownload = value;
  }

  @action.bound clearPdfDownload(value) {
    this.pdfDownload = null;
  }

  @action.bound
  openMobileNav() {
    this.mobileNavOpen = true;
  }

  @action.bound
  closeMobileNav() {
    this.mobileNavOpen = false;
  }

  @action.bound setup() {
    this.setupModels();
  }

  @action.bound tearDown() {
    this.tearDownModels();
    this.setDefaultSelections();
  }

  @action.bound
  setupModels() {
    this.allTimeStats = new ProjectInsightsAllTimeStats(null, {
      rootStore: this.rootStore,
      parent: this
    });

    this.workersByProject = new ProjectInsightsChartWithDateRanges(null, {
      chartProjectTeamSelection: 'MINE',
      chartProjectTeamSelectionLimit: 5,
      chartStat: 'workers',
      rootStore: this.rootStore,
      parent: this
    });

    this.topUsers = new ProjectInsightsTopUsers(null, {
      sortField: 'ranking',
      rankField: 'numberOfActivities',
      rootStore: this.rootStore,
      parent: this
    });

    this.complianceBySubContractor = new ProjectInsightsComplianceBySubContractor(
      null,
      {
        sortField: 'ranking',
        rankField: 'missedDailies',
        rootStore: this.rootStore,
        parent: this
      }
    );

    this.hoursByProject = new ProjectInsightsChartWithDateRanges(null, {
      chartProjectTeamSelection: 'MINE',
      chartProjectTeamSelectionLimit: 5,
      chartStat: 'hours',
      rootStore: this.rootStore,
      parent: this
    });

    this.projectDelays = new ProjectInsightsChartWithDateRanges(null, {
      chartProjectTeamSelection: 'MINE',
      chartProjectTeamSelectionLimit: 5,
      chartStat: 'delays',
      rootStore: this.rootStore,
      parent: this
    });

    this.safetyIncidents = new ProjectInsightsChartWithDateRanges(null, {
      chartProjectTeamSelection: 'MINE',
      chartProjectTeamSelectionLimit: 5,
      chartStat: 'safetyIncidents',
      rootStore: this.rootStore,
      parent: this
    });

    this.openTasks = new ProjectInsightsOpenTasks(null, {
      rootStore: this.rootStore,
      parent: this
    });

    this.toolBoxTalks = new ProjectInsightsToolBoxTalks(null, {
      rootStore: this.rootStore,
      parent: this
    });

    this.modelsSet = true;
  }

  @action.bound tearDownModels() {
    this.allTimeStats.reactToSelection();
    this.allTimeStats = null;

    this.workersByProject.reactToSelection();
    this.workersByProject = null;

    this.topUsers.reactToSelection();
    this.topUsers = null;

    this.complianceBySubContractor.reactToSelection();
    this.complianceBySubContractor = null;

    this.hoursByProject.reactToSelection();
    this.hoursByProject = null;

    this.projectDelays.reactToSelection();
    this.projectDelays = null;

    this.safetyIncidents.reactToSelection();
    this.safetyIncidents = null;

    this.openTasks.reactToSelection();
    this.openTasks = null;

    this.toolBoxTalks.reactToSelection();
    this.toolBoxTalks = null;

    this.modelsSet = false;
  }

  @action.bound setDefaultSelections() {
    // Default selections
    this.timeFrameSelection = 'ALL_TIME';
    this.projectTeamSelection = 'MINE';
    this.projectTeamSearch = '';
  }

  @action.bound
  fetchStatsForAllCharts() {
    if (this.fetchingAllCharts || !this.modelsSet) return;

    this.fetchingAllCharts = true;

    return request
      .get('/ra/insights/statsByProjectTeam', {
        params: Object.assign({}, this.hoursByProject.params, {
          include: 'workers, hours, safetyIncidents, delays'
        })
      })
      .then(response => {
        this.parseStatsForAllCharts(response.data);
        this.fetchingAllCharts = false;
      })
      .catch(error => {
        this.fetchingAllCharts = false;
        errorHandler(error, this.rootStore.notificationsUI.pushError);
      });
  }

  @action.bound
  parseStatsForAllCharts(attributes) {
    this.hoursByProject.parse(attributes);
    this.workersByProject.parse(attributes);
    this.projectDelays.parse(attributes);
    this.safetyIncidents.parse(attributes);
  }

  @action.bound
  togglePanelState(panel) {
    this[`${panel}Open`] = !this[`${panel}Open`];
  }

  @action.bound
  setTimeFrameSelection(selection, fromDate, toDate) {
    this.timeFrameSelection = selection;
    this.fromDate = fromDate;
    this.toDate = toDate;
  }

  @computed
  get earliestStartDate() {
    if (!this.project) return null;

    return orderBy(this.project.allProjectTeams, ['startDate'], ['asc'])[0]
      .startDate;
  }

  @computed
  get startDay() {
    return timeFrameToStartDay(
      this.timeFrameSelection,
      this.earliestStartDate,
      this.fromDate
    );
  }

  @computed
  get endDay() {
    return timeFrameToEndDay(this.timeFrameSelection, this.toDate);
  }

  @computed
  get selectableProjectTeams() {
    if (!this.project) {
      return [];
    }

    return this.project.allProjectTeams;
  }

  @action.bound
  setProjectTeamSelection(selection) {
    if (selection === 'SELECTED') {
      this.selectedProjectTeams.clear();
    }

    this.projectTeamSelection = selection;
    this.setDefaultSelectionsOnAllCharts();
  }

  @computed
  get filteredProjectTeams() {
    if (!this.project) return [];

    switch (this.projectTeamSelection) {
      case 'ALL':
        return this.project.allProjectTeams;
      case 'MINE':
        return [this.project.internalProjectTeam];
      case 'SELECTED':
        return this.selectedProjectTeams;
      default:
        return this.project.allProjectTeams;
    }
  }

  @computed
  get hasFilteredProjectTeams() {
    return this.filteredProjectTeams.length > 0;
  }

  @computed
  get filteredProjectTeamIds() {
    if (!this.filteredProjectTeams) return [];

    return this.filteredProjectTeams.map(projectTeam => projectTeam.id);
  }

  @computed
  get projectTeamSelectionString() {
    switch (this.projectTeamSelection) {
      case 'ALL':
        return 'All Companies';
      case 'MINE':
        return 'My Company';
      case 'SELECTED':
        return 'Selected Companies';
      default:
        return 'All Companies';
    }
  }

  @action.bound
  toggleProjectTeam(projectTeam) {
    if (this.selectedProjectTeams.includes(projectTeam)) {
      this.selectedProjectTeams.remove(projectTeam);
    } else {
      this.selectedProjectTeams.push(projectTeam);
    }

    this.setDefaultSelectionsOnAllCharts();
  }

  @action.bound
  setDefaultSelectionsOnAllCharts() {
    if (this.projectTeamSelection === 'MINE') {
      this.setProjectTeamSelectionOnAllCharts('MINE');
    } else {
      this.setProjectTeamSelectionOnAllCharts('RECENT');
    }
  }

  @action.bound
  setProjectTeamSearch(value) {
    this.projectTeamSearch = value;
  }

  @action.bound
  clearProjectTeamSearch() {
    this.projectTeamSearch = '';
  }

  @computed
  get searchedProjectTeams() {
    const searchExpression = new RegExp(
      escaperegexp(this.projectTeamSearch),
      'i'
    );

    return this.selectableProjectTeams.filter(projectTeam => {
      return projectTeam.name.search(searchExpression) !== -1;
    });
  }

  @action.bound
  toggleApplySelectionsToAllCharts(activeChildModel) {
    this.applySelectionsToAllCharts = !this.applySelectionsToAllCharts;

    if (this.applySelectionsToAllCharts) {
      this.replaceSelectedProjectTeamsOnAllCharts(activeChildModel);
    }
  }

  @action.bound
  setProjectTeamSelectionOnAllCharts(selection) {
    this.workersByProject.chartProjectTeamSelection = selection;
    this.hoursByProject.chartProjectTeamSelection = selection;
    this.projectDelays.chartProjectTeamSelection = selection;
    this.safetyIncidents.chartProjectTeamSelection = selection;
  }

  @action.bound
  clearSelectedProjectTeamsOnAllCharts() {
    this.workersByProject.selectedChartProjectTeams.clear();
    this.hoursByProject.selectedChartProjectTeams.clear();
    this.projectDelays.selectedChartProjectTeams.clear();
    this.safetyIncidents.selectedChartProjectTeams.clear();
  }

  @action.bound
  pushProjectTeamToAllCharts(projectTeam) {
    this.workersByProject.selectedChartProjectTeams.push(projectTeam);
    this.hoursByProject.selectedChartProjectTeams.push(projectTeam);
    this.projectDelays.selectedChartProjectTeams.push(projectTeam);
    this.safetyIncidents.selectedChartProjectTeams.push(projectTeam);
    const charts = [
      this.workersByProject,
      this.hoursByProject,
      this.projectDelays,
      this.safetyIncidents
    ];

    charts.forEach(chart => {
      if (
        !chart.atChartProjectTeamSelectionLimit &&
        !chart.selectedChartProjectTeams.includes(projectTeam)
      ) {
        chart.selectedChartProjectTeams.push(projectTeam);
      }
    });
  }

  @action.bound
  removeProjectTeamFromAllCharts(projectTeam) {
    this.workersByProject.selectedChartProjectTeams.remove(projectTeam);
    this.hoursByProject.selectedChartProjectTeams.remove(projectTeam);
    this.projectDelays.selectedChartProjectTeams.remove(projectTeam);
    this.safetyIncidents.selectedChartProjectTeams.remove(projectTeam);
  }

  @action.bound
  replaceSelectedProjectTeamsOnAllCharts(activeChildModel) {
    const charts = [
      this.workersByProject,
      this.hoursByProject,
      this.safetyIncidents,
      this.projectDelays
    ];

    charts.forEach(chart => {
      if (chart !== activeChildModel) {
        chart.chartProjectTeamSelection =
          activeChildModel.chartProjectTeamSelection;
        chart.selectedChartProjectTeams.replace(
          activeChildModel.selectedChartProjectTeams.slice()
        );
      }
    });
  }

  @computed
  get recentProjectTeams() {
    return orderBy(
      this.filteredProjectTeams,
      project => project.lastUpdated || '',
      ['desc']
    ).slice(0, 5);
  }

  @computed
  get nonRecentProjectTeams() {
    return orderBy(
      orderBy(
        this.filteredProjectsTeams,
        project => project.lastUpdated || '',
        ['desc']
      ).slice(5),
      ['name', 'asc']
    );
  }

  @computed
  get hasNonRecentProjects() {
    return this.nonRecentProjects.length > 0;
  }

  @action.bound
  initTimeFrameForm() {
    this.activeForm = new TimeFrameForm(
      {
        fields: timeFrameFormFields,
        rules: timeFrameFormRules,
        values: timeFrameFormValues,
        labels: timeFrameFormLabels
      },
      {
        options: timeFrameFormOptions,
        plugins: timeFrameFormPlugins,
        rootStore: this.rootStore
      }
    );
  }
}
