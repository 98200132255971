const usd = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2
  // trailingZeroDisplay: 'stripIfInteger'
});

export default number => {
  if (typeof number === 'undefined') {
    return '';
  }

  if (typeof number === 'string') {
    number = parseFloat(number);
  }

  if (isNaN(number)) {
    return '';
  }
  return usd.format(number);
};
