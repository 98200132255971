import moment from 'moment';
import { Model } from 'mobx-mc';
import { action, observable, computed } from 'mobx';

import {
  PaymentMethodForm,
  paymentMethodFormOptions,
  paymentMethodFormFields,
  paymentMethodFormEditRules,
  paymentMethodFormLabels,
  paymentMethodFormValues,
  paymentMethodFormPlugins
} from 'forms/paymentMethod';

export default class PaymentMethod extends Model {
  // TODO once rolled out and the PCI Compliance Code is Stable in production we can remove all the properties which relate to the cridit card from here.
  @observable open;
  @observable editing;
  @observable form;

  constructor(data, options) {
    super(data, options);

    this.open = false;
    this.editing = false;
    this.form = null;
  }

  get restAttributes() {
    return [
      'expirationMonth',
      'expirationYear',
      'lastFour',
      'type',
      'id',
      'businessId',
      'addressZip',
      'cardNumber',
      'cardType',
      'cvc',
      'cvv',
      'exp'
    ];
  }

  @action.bound
  startEditing() {
    this.form = new PaymentMethodForm(
      {
        fields: paymentMethodFormFields,
        rules: paymentMethodFormEditRules,
        labels: paymentMethodFormLabels,
        values: this.asFormValues
      },
      {
        options: paymentMethodFormOptions,
        plugins: paymentMethodFormPlugins
      }
    );

    this.editing = true;
  }

  @action.bound
  cancelEditing() {
    this.editing = false;
    this.open = false;
    this.form = null;
  }

  @action.bound
  editSubmitError() {
    console.log(this.form.errors());
  }

  @computed
  get expiryDate() {
    return moment(
      `${this.expirationMonth}/${this.expirationYear}`,
      'MM/YYYY'
    ).format('MM/YYYY');
  }

  @computed
  get hasExpired() {
    const exp = moment(this.expiryDate, 'MM/YYYY').endOf('month');
    const today = moment(new Date());

    return exp.isBefore(today);
  }

  @computed
  get isExpiring() {
    const exp = moment(this.expiryDate, 'MM/YYYY');
    const today = moment(new Date());

    return !this.hasExpired && exp.isBefore(today.add(10, 'days'));
  }

  @computed
  get asFormValues() {
    return Object.assign({}, paymentMethodFormValues, {
      exp: this.expiryDate,
      addressZip: this.addressZip
    });
  }
}
