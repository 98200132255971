import { action, computed, observable } from 'mobx';
import UIStore from '../../UIStore';
import history from 'utils/history';
import {
  checklistTypeFormFields,
  checklistTypeFormOptions,
  checklistTypeFormPlugins,
  checklistTypeFormRules,
  checklistTypeFormValues,
  checklistTypeFormLabels,
  ChecklistTypeForm
} from 'forms/checklistType';
import ChecklistType from 'stores/models/checklists/ChecklistType';
import ChecklistClasses from 'stores/collections/checklists/ChecklistClasses';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

import { callTrack } from 'utils/segmentIntegration';
import { CHECKLIST_TYPE_ADDED } from 'utils/segmentAnalytics/eventSpec';

export default class ChecklistTypeAddUI extends UIStore {
  @observable entryAddForm;

  constructor(options) {
    super(options);

    this.checklistClasses = new ChecklistClasses(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.entryAddForm = null;
  }

  @action.bound async setup() {
    this.checklistClasses.fetch({
      params: {
        limit: 10000
      }
    });
    this.setupForm();
  }

  @action.bound setupForm() {
    this.entryForAdd = new ChecklistType(
      {},
      {
        rootStore: this.rootStore
      }
    );

    this.entryAddForm = new ChecklistTypeForm(
      {
        fields: checklistTypeFormFields,
        rules: checklistTypeFormRules,
        labels: checklistTypeFormLabels,
        values: checklistTypeFormValues
      },
      {
        options: checklistTypeFormOptions,
        plugins: checklistTypeFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.activeModal = null;
    this.entryForAdd = null;
    this.entryAddForm = null;
    this.saving = false;
  }

  @computed get checklistClassOptions() {
    return this.checklistClasses.models.map(typeClass => ({
      name: typeClass.name,
      uuid: typeClass.uuid
    }));
  }

  @computed get selectedChecklistClassOption() {
    return this.checklistClassOptions?.find(
      option => option.id === this.entryAddForm.$('typeClass').value
    );
  }

  @action.bound
  async submitChecklistTypeAddSuccess() {
    this.saving = true;
    const { typeName, typeClass } = this.entryAddForm.trimmedValues();
    try {
      await this.entryForAdd.save(
        {
          typeName,
          typeClass: {
            uuid: typeClass.uuid
          }
        },
        {
          wait: true
        }
      );

      callTrack(CHECKLIST_TYPE_ADDED, {
        checklist_type_class: typeClass.name,
        checklist_type_name: typeName
      });

      this.parent.sortByLastCreated();
      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Checklist type added')
      });
      this.cancelChecklistTypeAdd();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  submitChecklistTypeAdd(e) {
    e.preventDefault();
    e.stopPropagation();

    this.entryAddForm.submit({
      onSuccess: this.submitChecklistTypeAddSuccess,
      onError: e => {
        console.error(this.entryAddForm.errors());
      }
    });
  }

  @action.bound
  cancelChecklistTypeAdd() {
    history.push({
      pathname: `/company-settings/checklists/types`,
      search: this.baseQueryParams
    });
  }
}
