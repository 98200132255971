import styled from 'styled-components';

const ModalTabs = styled.div`
  background-color: ${props => props.theme.colors.white};
  display: flex;
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom};
`;

ModalTabs.defaultProps = {
  marginTop: '1.5rem'
};

export default ModalTabs;
