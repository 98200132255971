import { computed } from 'mobx';
import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import { t } from 'utils/translate';
import trimObject from '../utils/trimObject';

const shiftFormPlugins = extendedPlugins;

const shiftFormOptions = {
  validateOnInit: false,
  strictUpdate: false,
  validateOnChange: true
};

const shiftFormFields = ['uuid', 'name', 'code'];

const shiftFormRules = {
  name: 'string|required|max:30',
  code: 'string|required|max:5'
};

const shiftFormLabels = {
  name: t('Shift'),
  code: t('Abbreviation')
};

class ShiftForm extends MobxReactForm {
  @computed get identificationString() {
    return `${this.$('name').value}, ${this.$('code').value}`;
  }

  trimmedValues() {
    return trimObject(this.values());
  }
}

export {
  ShiftForm,
  shiftFormOptions,
  shiftFormFields,
  shiftFormRules,
  shiftFormLabels,
  shiftFormPlugins
};
