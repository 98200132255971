import { action, computed } from 'mobx';
import request from 'axios';

import alertErrorHandler from 'utils/alertErrorHandler';
import ChecklistObservationUI from './checklists/ChecklistObservationUI';

export default class SurveyObservationUI extends ChecklistObservationUI {
  constructor(options) {
    super(options);
  }

  @computed get deleteUrl() {
    return `/ra/companies/${this.rootStore.me.company.uuid}/answers/${this.questionUuid}/observations`;
  }

  @action.bound async removeObservation() {
    this.saving = true;

    try {
      this.questionModel.removeObservation(this.selectedObservationUuid);

      await request.delete(this.deleteUrl, {
        params: {
          observationUuids: this.selectedObservationUuid
        }
      });

      this.confirmDeleteModal = false;
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }
}
