import { computed } from 'mobx';
import { Model } from 'mobx-mc';
import moment from 'moment';

export default class ChecklistSchedule extends Model {
  get restAttributes() {
    return [
      'uuid',
      'companyUuid',
      'businessUuid',
      'project',
      'checklistTemplate',
      'displayBefore',
      'enableReminder',
      'endDate',
      'createdBy',
      'createdTimestamp',
      'updatedBy',
      'updatedTimestamp',
      'responsible',
      'location',
      'scheduleFrequency',
      'scheduleStatus',
      'startDate',
      'startTime',
      'checklistTemplateUuid',
      'locationUuid',
      'responsibleWorkerClassificationUuid',
      'responsibleChildProjectUuid',
      'projectUuid'
    ];
  }

  idAttribute() {
    return 'uuid';
  }

  @computed get formValues() {
    return {
      checklistTemplateUuid: this.checklistTemplate.uuid,
      locationUuid: this.location?.uuid,
      responsibleWorkerClassificationUuid: this.classificationUuid,
      responsibleChildProjectUuid: this.childProjectUuid,
      projectUuid: this.project.uuid,
      enableReminder: this.enableReminder,
      startDate: moment
        .utc(`${this.startDate}T${this.startTime}`)
        .local()
        .format('YYYY-MM-DD'),
      startTime: moment
        .utc(`${this.startDate}T${this.startTime}`)
        .local()
        .format('HH:mm'),
      endDate: moment
        .utc(`${this.endDate}T${this.startTime}`)
        .local()
        .format('YYYY-MM-DD'),
      scheduleFrequency: this.scheduleFrequency,
      displayBefore: this.displayBefore
    };
  }

  @computed
  get classificationUuid() {
    return (
      this.responsible.type === 'WorkerClassification' && this.responsible.uuid
    );
  }

  @computed
  get childProjectUuid() {
    return this.responsible.type === 'ProjectTeam' && this.responsible.uuid;
  }

  @computed get responsibleName() {
    return this.responsible.classification || this.responsible.company.name;
  }

  @computed get checklistName() {
    return `${this.checklistTemplate.name}`;
  }

  @computed get firstDateFormatted() {
    return moment
      .utc(`${this.startDate}T${this.startTime}`)
      .local()
      .format('MMM D, YYYY h:mm A');
  }

  @computed get endDateFormatted() {
    return moment
      .utc(`${this.endDate}T${this.startTime}`)
      .local()
      .format('MMM D, YYYY');
  }

  get urlRoot() {
    return `${this.rootStore.urlMicroService('toolbox')}/companies/${
      this.rootStore.me.company.uuid
    }/checklist-schedules`;
  }
}
