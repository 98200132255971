import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import moment from 'moment';
import { t } from 'utils/translate';

import {
  FormGroup,
  FormGroupMessage,
  Label,
  InputGroup,
  Input,
  DatePicker,
  DropdownV2,
  DropdownContainer
} from '..';

@observer
export default class DatePickerControl extends Component {
  state = {
    showPicker: false,
    currentMonth: moment(),
    focusedDay: null
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = () => {
    this.inputRef.blur();
  };

  handleShowDatePicker = () => {
    const startDate = this.getStartDate();
    const selectedDate = this.getSelectedDate();

    let currentMonth = moment();

    if (startDate) {
      currentMonth = startDate;
    }

    if (selectedDate) {
      currentMonth = selectedDate;
    }

    this.setState({
      showPicker: true,
      currentMonth
    });
  };

  handleHideDatePicker = () => {
    this.setState({
      showPicker: false,
      focusedDay: null
    });
  };

  handlePreviousMonthClick = () => {
    this.setState({
      currentMonth: this.state.currentMonth.clone().subtract(1, 'month')
    });
  };

  handleNextMonthClick = () => {
    this.setState({
      currentMonth: this.state.currentMonth.clone().add(1, 'month')
    });
  };

  handleDayClick = day => {
    const { form, fieldName, fieldFormat, onChange } = this.props;
    const update = {};

    update[fieldName] = day.format(fieldFormat);

    form.update(update);

    if (onChange) {
      onChange(update);
    }

    this.inputRef.focus();
    this.handleHideDatePicker();
  };

  handleInputKeyDown = e => {
    switch (e.keyCode) {
      case 9:
        this.handleInputTab();
        break;
      case 40:
        this.handleInputDownArrow(e);
        break;
      case 27:
        this.handleHideDatePicker();
        break;
      case 13:
        this.handleHideDatePicker();
        break;
      default:
        break;
    }
  };

  handleInputTab = () => {
    this.handleHideDatePicker();
    this.inputRef.blur();
  };

  handleInputDownArrow = e => {
    e.preventDefault();
    e.stopPropagation();

    const { form, fieldName, fieldFormat } = this.props;
    const selectedDate = moment(form.$(fieldName).value, fieldFormat);

    let focusedDay;

    if (selectedDate.isValid()) {
      focusedDay = selectedDate;
    } else {
      focusedDay = this.state.currentMonth.startOf('month');
    }

    this.setState(
      {
        focusedDay
      },
      () => {
        this.handleShowDatePicker();
      }
    );
  };

  handleDatePickerKeyDown = e => {
    e.preventDefault();
    e.stopPropagation();

    switch (e.keyCode) {
      case 37:
        this.handleDatePickerLeftArrow();
        break;
      case 38:
        this.handleDatePickerUpArrow();
        break;
      case 39:
        this.handleDatePickerRightArrow();
        break;
      case 40:
        this.handleDatePickerDownArrow();
        break;
      case 27:
        this.handleHideDatePicker();
        break;
      default:
        break;
    }
  };

  handleDatePickerLeftArrow = () => {
    const { focusedDay, currentMonth } = this.state;

    if (focusedDay.date() === 1) {
      this.setState({
        currentMonth: currentMonth.clone().subtract(1, 'month'),
        focusedDay: currentMonth
          .clone()
          .subtract(1, 'month')
          .endOf('month')
      });
    } else {
      this.setState({
        focusedDay: moment(focusedDay).subtract(1, 'day')
      });
    }
  };

  handleDatePickerRightArrow = () => {
    const { focusedDay, currentMonth } = this.state;

    if (focusedDay.date() === currentMonth.daysInMonth()) {
      this.setState({
        currentMonth: currentMonth.clone().add(1, 'month'),
        focusedDay: currentMonth
          .clone()
          .add(1, 'month')
          .startOf('month')
      });
    } else {
      this.setState({
        focusedDay: moment(focusedDay).add(1, 'day')
      });
    }
  };

  handleDatePickerDownArrow = () => {
    const { focusedDay, currentMonth } = this.state;

    if (focusedDay.date() + 7 > currentMonth.daysInMonth()) {
      this.setState({
        currentMonth: currentMonth.clone().add(1, 'month'),
        focusedDay: moment(this.state.focusedDay).add(7, 'days')
      });
    } else {
      this.setState({
        focusedDay: moment(this.state.focusedDay).add(7, 'days')
      });
    }
  };

  handleDatePickerUpArrow = () => {
    const { focusedDay, currentMonth } = this.state;

    if (focusedDay.date() - 7 < 1) {
      this.setState({
        currentMonth: currentMonth.clone().subtract(1, 'month'),
        focusedDay: moment(this.state.focusedDay).subtract(7, 'days')
      });
    } else {
      this.setState({
        focusedDay: moment(this.state.focusedDay).subtract(7, 'days')
      });
    }
  };

  getSelectedDate = () => {
    const { fieldName, form, fieldFormat } = this.props;

    const selectedDate = moment(form.$(fieldName).value, fieldFormat);

    if (selectedDate.isValid()) {
      return selectedDate;
    }

    return null;
  };

  getStartDate = () => {
    const {
      startFieldName,
      form,
      fieldFormat,
      allowSameDay,
      startDateTomorrow,
      disableBefore
    } = this.props;

    let startDate;

    if (startDateTomorrow) {
      return moment();
    }

    if (disableBefore) {
      return disableBefore;
    }

    if (startFieldName) {
      startDate = moment(form.$(startFieldName).value, fieldFormat);

      if (allowSameDay) startDate.subtract(1, 'day');

      if (startDate.isValid()) {
        return startDate;
      }
    }

    return null;
  };

  render() {
    const {
      form,
      fieldName,
      label,
      showValidIcon,
      errorColor,
      hideLabel,
      noMargin,
      helpText,
      helpTextSize,
      placeholder,
      highlight,
      hideValidationMessages,
      focusColor,
      tabIndex,
      disabled,
      positionFixed,
      width,
      colorAllDays,
      enabledDaysConfig,
      marginRight,
      innerRef,
      disableAfter
    } = this.props;

    const { currentMonth, showPicker, focusedDay } = this.state;
    const dayConfig = {};

    const selectedDate = this.getSelectedDate();
    const startDate = this.getStartDate();

    if (enabledDaysConfig) {
      for (let date in enabledDaysConfig) {
        dayConfig[date] = enabledDaysConfig[date];
      }
    }

    if (selectedDate) {
      dayConfig[selectedDate.format('M/D/YYYY')] = {
        selected: true,
        selectedColor: 'goGreen'
      };
    }

    if (focusedDay) {
      dayConfig[focusedDay.format('M/D/YYYY')] = {
        focused: true,
        selected:
          selectedDate &&
          Boolean(
            focusedDay.format('M/D/YYYY') === selectedDate.format('M/D/YYYY')
          ),
        selectedColor: 'goGreen'
      };
    }

    return (
      <FormGroup
        noMargin={noMargin}
        disabled={disabled}
        marginRight={marginRight}
      >
        {!hideLabel && (
          <Label htmlFor={fieldName}>
            {label || t(form.$(fieldName).label)}
          </Label>
        )}

        <InputGroup
          hasError={form.$(fieldName).hasError}
          errorColor={errorColor}
          isValid={!form.$(fieldName).isEmpty && form.$(fieldName).isValid}
          showValidIcon={showValidIcon}
          component="input"
        >
          <DropdownV2
            isOpen={showPicker}
            trigger={
              <div>
                <Input
                  value={form.$(fieldName).value}
                  onChange={form.$(fieldName).sync}
                  hasError={form.$(fieldName).hasError}
                  errorColor={errorColor}
                  onFocus={this.handleShowDatePicker}
                  placeholder={placeholder}
                  autoFocus={form.$(fieldName).autoFocus}
                  onKeyDown={this.handleInputKeyDown}
                  highlight={highlight}
                  hideValidationMessages={hideValidationMessages}
                  focusColor={focusColor}
                  showValidIcon={showValidIcon}
                  tabIndex={tabIndex}
                  focused={showPicker}
                  disabled={disabled}
                  width={width}
                  ref={ref => {
                    this.inputRef = ref;
                    if (innerRef) {
                      innerRef(ref);
                    }
                  }}
                  data-qa={`input_${form
                    .$(fieldName)
                    .label.split(' ')
                    .join('-')}`}
                />
              </div>
            }
            onHide={this.handleHideDatePicker}
            positionFixed={positionFixed}
          >
            <DropdownContainer>
              <DatePicker
                month={currentMonth}
                onPreviousMonthClick={this.handlePreviousMonthClick}
                onNextMonthClick={this.handleNextMonthClick}
                onDayClick={day => {
                  if (enabledDaysConfig) {
                    const enabled = enabledDaysConfig[day.format('M/D/YYYY')];

                    if (enabled) {
                      this.handleDayClick(day);
                    }
                  } else {
                    this.handleDayClick(day);
                  }
                }}
                onKeyDown={this.handleDatePickerKeyDown}
                dayConfig={dayConfig}
                disableBefore={startDate}
                disableAfter={disableAfter}
                enableHover
                compact
                colorAllDays={colorAllDays}
              />
            </DropdownContainer>
          </DropdownV2>
        </InputGroup>

        {form.$(fieldName).hasError && !highlight && !hideValidationMessages && (
          <FormGroupMessage errorColor={errorColor} hasError>
            {t(form.$(fieldName).error, {
              customValidation: form.$(fieldName).label
            })}
          </FormGroupMessage>
        )}

        {helpText && (
          <FormGroupMessage size={helpTextSize}>{helpText}</FormGroupMessage>
        )}
      </FormGroup>
    );
  }
}

DatePickerControl.propTypes = {
  disabled: PropTypes.bool.isRequired,
  showValidIcon: PropTypes.bool.isRequired,
  errorColor: PropTypes.string.isRequired,
  hideValidationMessages: PropTypes.bool.isRequired
};

DatePickerControl.defaultProps = {
  fieldFormat: 'MM/DD/YYYY',
  disabled: false,
  showValidIcon: false,
  errorColor: 'red',
  hideValidationMessages: false,
  allowSameDay: false,
  positionFixed: false,
  colorAllDays: false
};
