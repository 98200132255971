import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import { computed } from 'mobx';

import trimObject from '../utils/trimObject';
import { t } from 'utils/translate';

const checklistFormPlugins = extendedPlugins;

const checklistFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const checklistFormFields = [
  'templateUuid',
  'name',
  'responsible',
  'responsible.uuid',
  'responsible.name',
  'location',
  'location.uuid',
  'location.name',
  'checklistDate',
  'checklistTime',
  'checklistSections[]',
  'checklistSections[].uuid',
  'checklistSections[].title',
  'checklistSections[].description',
  'checklistSections[].position',
  'checklistSections[].status',
  'checklistSections[].checklistQuestions[]',
  'checklistSections[].checklistQuestions[].uuid',
  'checklistSections[].checklistQuestions[].position',
  'checklistSections[].checklistQuestions[].questionText',
  'checklistSections[].checklistQuestions[].responseType',
  'checklistSections[].checklistQuestions[].allowMultiple',
  'checklistSections[].checklistQuestions[].required',
  'checklistSections[].checklistQuestions[].checklistResponses[]',
  'checklistSections[].checklistQuestions[].checklistResponses[].value',
  'checklistSections[].checklistQuestions[].checklistResponses[].note',
  'checklistSections[].checklistQuestions[].checklistResponses[].choices',
  'checklistSections[].checklistQuestions[].checklistResponses[].responseSignature',
  'checklistSections[].checklistQuestions[].checklistResponses[].responseSignature.signature',
  'checklistSections[].checklistQuestions[].checklistResponses[].responseSignature.signedBy',
  'checklistSections[].checklistQuestions[].checklistResponses[].responseSignature.signedTimestamp'
];

const checklistFormValues = {
  checklistSections: [
    {
      title: '',
      description: '',
      position: 1,
      checklistQuestions: [
        {
          questionText: '',
          responseType: 'YES_NO_NA',
          position: 1,
          checklistResponses: [
            {
              value: '',
              note: ''
            }
          ]
        }
      ]
    }
  ]
};

const checklistFormRules = {
  'responsible.uuid': 'required',
  checklistDate: 'required|dateCustom:YYYY-MM-DD',
  checklistTime: 'required',
  'checklistSections[].checklistQuestions[].checklistResponses[].note':
    'string|max:10000'
};

const checklistFormLabels = {
  name: t('name'),
  'responsible.uuid': t('responsible'),
  checklistDate: t('checklist date'),
  checklistTime: t('time'),
  'checklistSections[].title': t('section title'),
  'checklistSections[].description': t('description')
};

class ChecklistForm extends MobxReactForm {
  @computed
  get sections() {
    return this.$('checklistSections');
  }

  @computed
  get numberOfSections() {
    return this.$('checklistSections').size;
  }

  trimmedValues() {
    return trimObject(this.values());
  }
}

export {
  ChecklistForm,
  checklistFormPlugins,
  checklistFormOptions,
  checklistFormFields,
  checklistFormRules,
  checklistFormValues,
  checklistFormLabels
};
