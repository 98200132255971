import request from 'axios';
import debounce from 'lodash.debounce';
import { action, computed, observable } from 'mobx';
import ProjectChildEditUI from './ProjectChildEditUI';

import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import { BASE_DEBOUNCE } from 'fixtures/constants';

import { callTrack } from 'utils/segmentIntegration';
import { WORKLOG_EDITED } from 'utils/segmentAnalytics/eventSpec';

import {
  WorkLogStandardForm,
  workLogStandardFormOptions,
  workLogStandardFormFields,
  workLogStandardFormRules,
  workLogStandardFormLabels,
  workLogStandardFormPlugins
} from 'forms/workLogStandard';

export default class WorkLogEditStandardUI extends ProjectChildEditUI {
  constructor(options) {
    super(options);

    // Subcontractors
    this.subcontractors = observable([]);

    this.fetchSubcontractorsDebounced = debounce(
      this.fetchSubcontractors,
      BASE_DEBOUNCE
    );
  }

  @action.bound setup(workLog) {
    this.entryForEdit = workLog;

    this.entryEditForm = new WorkLogStandardForm(
      {
        fields: workLogStandardFormFields,
        rules: workLogStandardFormRules,
        values: this.entryForEdit.formValues,
        labels: workLogStandardFormLabels
      },
      {
        options: workLogStandardFormOptions,
        plugins: workLogStandardFormPlugins
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @computed get title() {
    return this.parent.title;
  }

  @computed get backButtonText() {
    return this.parent.backButtonText;
  }

  @action.bound clearUIState() {
    this.subcontractors.clear();
    this.clearValidationDetails();
    this.entryForEdit = null;
    this.entryEditForm = null;
    this.saving = false;
    this.activeModal = null;
    this.nextUrl = null;
  }

  @action.bound async submitEntryEditFormSuccess() {
    this.saving = true;

    try {
      await this.entryForEdit.save(this.entryEditForm.values(), {
        wait: true
      });

      callTrack(WORKLOG_EDITED, {
        worklog_name: this.entryForEdit.subcontractor,
        worklogType: this.entryForEdit.type,
        has_attachments: this.entryForEdit.attachments.length > 0
      });

      this.unblockHistory();
      this.parent.parent.refetchWorkLogs();
      this.parent.cancelWorkLogEdit();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Work log saved')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  // Subcontractors
  @action.bound
  async fetchSubcontractors(query = '') {
    this.subcontractors.clear();

    if (query.length < 2) {
      return;
    }

    try {
      const response = await request.get(
        `${this.rootStore.urlMicroService(
          'performanceTracking'
        )}/worklogs/subcontractors`,
        {
          cancelToken: new request.CancelToken(cancelMethod => {
            this.cancelMethod = cancelMethod;
          }),
          params: {
            query: query
          }
        }
      );

      if (response.data.collection) {
        this.subcontractors.replace(response.data.collection);
      }
    } catch (error) {
      console.warn(error);
    }
  }

  @action.bound clearSubcontractors() {
    this.fetchSubcontractorsDebounced.cancel();

    if (this.cancelMethod) {
      this.cancelMethod('Fetch cancelled on blur');
      this.cancelMethod = null;
    }

    this.subcontractors.clear();
  }

  @computed
  get subContractorOptions() {
    return this.subcontractors.slice();
  }

  @computed
  get subContractorOptionsOpen() {
    return this.subContractorOptions.length > 0;
  }
}
