import styled, { css } from 'styled-components';

export default styled.div`
  background: ${props =>
    props.backgroundColor
      ? props.theme.colors[props.backgroundColor]
      : 'transparent'};
  color: ${props =>
    props.color ? props.theme.colors[props.color] : 'inherit'};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  display: ${props => (props.hide ? 'none' : 'flex')};
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  justify-content: space-between;
  align-items: center;
  font-size: 1.4rem;
  padding: 1rem;

  ${props =>
    !props.isHighlighted &&
    !props.disabled &&
    css`
      &:focus,
      &:hover {
        background: ${props.theme.colors.blueAccent};
        color: ${props.theme.colors.white};
        font-weight: 700;
        outline: none;
        position: relative;
        z-index: 2;
        & * {
          color: ${props.theme.colors.white};
        }

        & > div:first-child {
          svg {
            stroke: none;
            fill: ${props => props.theme.colors.white};
          }
        }
      }
    `}
  ${props =>
    props.isHighlighted &&
    css`
      background: ${props.theme.colors.blueAccent};
      color: ${props.theme.colors.white};
      position: relative;
      z-index: 2;
      font-weight: 700;
      & * {
        color: ${props.theme.colors.white};
      }

      & > div:first-child {
        svg {
          stroke: none;
          fill: ${props => props.theme.colors.white};
        }
      }
    `};
`;
