import { action, observable, runInAction, computed } from 'mobx';
import UIStore from '../../UIStore';
import history from 'utils/history';

import {
  checklistTemplateFormFields,
  checklistTemplateFormOptions,
  checklistTemplateFormPlugins,
  checklistTemplateFormRules,
  checklistTemplateFormLabels,
  ChecklistTemplateForm
} from 'forms/checklistTemplate';

import {
  checklistTemplateNameFormFields,
  checklistTemplateNameFormOptions,
  checklistTemplateNameFormPlugins,
  checklistTemplateNameFormRules,
  checklistTemplateNameFormLabels,
  checklistTemplateNameFormValues,
  checklistTemplateNameFormFieldOptions,
  ChecklistTemplateNameForm
} from 'forms/checklistTemplateName';

import {
  checklistTemplateQuestionChoicesFormFields,
  checklistTemplateQuestionChoicesFormOptions,
  checklistTemplateQuestionChoicesFormPlugins,
  checklistTemplateQuestionChoicesFormRules,
  ChecklistTemplateQuestionChoicesForm
} from 'forms/checklistTemplateQuestionChoices';

import { callTrack } from 'utils/segmentIntegration';

import {
  CHECKLIST_TEMPLATE_QUESTION_TYPE_SELECTED,
  CHECKLIST_TEMPLATE_SECTION_ADDED,
  CHECKLIST_TEMPLATE_QUESTION_ADDED,
  CHECKLIST_TEMPLATE_DRAFT_SAVED,
  CHECKLIST_TEMPLATE_PUBLISHED,
  CHECKLIST_TEMPLATE_ADD_TYPE
} from 'utils/segmentAnalytics/eventSpec';

import ChecklistTemplate from 'stores/models/checklists/ChecklistTemplate';

import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

export default class ChecklistTemplateEditUI extends UIStore {
  @observable startValues;
  @observable entryForEdit;
  @observable entryEditForm;
  @observable checklistTemplateNameForm;
  @observable nextUrl;

  @observable previewMode;
  @observable questionChoicesForm;
  @observable sectionToDeleteKey;
  @observable selectedQuestion;
  @observable previewOnly;

  constructor(options) {
    super(options);

    this.startValues = null;
    this.entryForEdit = null;
    this.entryEditForm = null;
    this.checklistTemplateNameForm = null;
    this.nextUrl = null;

    this.previewMode = false;
    this.sectionToDeleteKey = null;
    this.selectedQuestion = null;
    this.previousResponseOption = null;

    // This flag will put the ui into preview only mode which will not show options to edit the template
    this.previewOnly = false;
  }

  @action.bound setStartValues(values) {
    this.startValues = values;
  }

  @action.bound setup(uuid) {
    this.fetchEntry(uuid);
    this.checklistTypeSelectorUI.setup();
  }

  @action.bound async fetchEntry(uuid) {
    if (this.entryForEdit) return;

    let model = this.parent.checklistTemplates.get(uuid);

    if (!model) {
      model = new ChecklistTemplate(
        {
          type: 'ChecklistTemplate',
          uuid: uuid
        },
        {
          rootStore: this.rootStore
        }
      );
    }

    try {
      await model.fetch();

      this.setEntryForEdit(model);
    } catch (error) {
      this.cancelChecklistTemplateEdit();
    }
  }

  @action.bound setEntryForEdit(model) {
    if (model.scope === 'RAKEN') {
      this.previewOnly = true;
      this.previewMode = true;
    }
    this.entryForEdit = model;
    this.setupForm();
  }

  @action.bound setupForm() {
    this.entryEditForm = new ChecklistTemplateForm(
      {
        fields: checklistTemplateFormFields,
        rules: checklistTemplateFormRules,
        labels: checklistTemplateFormLabels,
        values: this.entryForEdit.formValues
      },
      {
        options: checklistTemplateFormOptions,
        plugins: checklistTemplateFormPlugins,
        rootStore: this.rootStore
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @action.bound tearDown() {
    this.clearUIState();
    this.checklistTypeSelectorUI.tearDown();
    this.unblockHistory();
    this.previewOnly = false;
  }

  @action.bound blockHistoryIfFormChanged() {
    this.unblock = history.block((location, action) => {
      if (this.entryEditForm.check('isDirty')) {
        this.showDiscardModal(location.pathname);
        return 'Blocked';
      }
    });
  }

  @action.bound unblockHistory() {
    this.unblock && this.unblock();
  }

  @action.bound
  showDiscardModal(nextUrl) {
    this.showModal('DiscardChangesModal');
    this.nextUrl = nextUrl;
  }

  @action.bound
  async discardChanges() {
    await this.hideActiveModal();
    this.moveToNextUrl();
  }

  @action.bound
  async moveToNextUrl() {
    this.unblock();
    history.push(this.nextUrl);
    this.nextUrl = null;
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.startValues = null;
    this.activeModal = null;
    this.entryForEdit = null;
    this.entryEditForm = null;
    this.checklistTemplateNameForm = null;
    this.previewMode = false;
    this.sectionToDeleteKey = null;
    this.selectedQuestion = null;
    this.previousResponseOption = null;

    this.saving = false;
  }

  @action.bound
  cancelChecklistTemplateEdit() {
    history.push({
      pathname: `/company-settings/checklists/templates`,
      search: this.baseQueryParams
    });
  }

  @computed get hasWriteAccess() {
    return this.authorization.canCUDCompanyChecklistTemplates;
  }

  @computed get disableSaveButton() {
    return (
      !this.hasWriteAccess ||
      this.entryEditForm?.hasError ||
      this.entryEditForm?.hasOnlyEmptySections ||
      this.saving
    );
  }

  @action.bound
  submitEntryEditForm(status = 'DRAFT') {
    this.entryEditForm.submit({
      onSuccess: () => {
        this.submitEntryEditFormSuccess(status);
      },
      onError: this.submitEntryEditFormError
    });
  }

  @action.bound
  async submitEntryEditFormSuccess(status) {
    this.saving = true;

    const checklistTemplate = this.entryEditForm.trimmedValues();

    const filteredChecklistTemplateSections = checklistTemplate.checklistTemplateSections.reduce(
      this.sectionsReducer,
      []
    );

    const filteredChecklistTemplate = {
      ...checklistTemplate,
      checklistTemplateSections: filteredChecklistTemplateSections
    };

    for (let section of filteredChecklistTemplate.checklistTemplateSections) {
      delete section.localUuid;
      for (let question of section.checklistTemplateQuestions) {
        delete question.localUuid;
      }
    }

    const payload = {
      ...filteredChecklistTemplate,
      status: status
    };

    try {
      await this.entryForEdit.save(payload, {
        wait: true,
        stripNonRest: false
      });

      if (this.entryEditForm.$('checklistType.uuid').check('isDirty')) {
        callTrack(CHECKLIST_TEMPLATE_ADD_TYPE, {
          checklist_template_name: this.entryEditForm.$('name').value,
          checklist_type_name: this.entryEditForm.$('checklistType.typeName')
            .value
        });
      }

      this.unblockHistory();
      this.parent.sortByLastUpdated();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Checklist template updated')
      });

      this.trackPublishOrEditingEvent(status);

      this.cancelChecklistTemplateEdit();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitEntryEditFormError() {
    console.log(this.entryEditForm.errors());
  }

  trackPublishOrEditingEvent(status) {
    const eventName =
      status === 'DRAFT'
        ? 'Checklist_Template_Save_Draft'
        : 'Checklist_Template_Publish';

    let mandatory = false;

    if (eventName === 'Checklist_Template_Publish') {
      const sectionWithMandatoryQuestion = this.entryEditForm
        .$('checklistTemplateSections')
        .values()
        .find(section => {
          let hasRequiredQuestion = false;
          for (const question of section.checklistTemplateQuestions) {
            if (question.required && !hasRequiredQuestion) {
              hasRequiredQuestion = true;
            }
          }
          return hasRequiredQuestion;
        });

      if (sectionWithMandatoryQuestion) {
        mandatory = true;
      }
    }

    if (status === 'DRAFT') {
      callTrack(CHECKLIST_TEMPLATE_DRAFT_SAVED, {
        checklist_template_name: this.entryEditForm.$('name').value
      });
    } else {
      callTrack(CHECKLIST_TEMPLATE_PUBLISHED, {
        checklist_template_name: this.entryEditForm.$('name').value,
        mandatory: mandatory
      });
    }
  }

  @action.bound
  togglePreviewMode() {
    this.previewMode = !this.previewMode;
  }

  @action.bound
  addDefaultSection() {
    callTrack(CHECKLIST_TEMPLATE_SECTION_ADDED, {
      checklist_template_name: this.entryEditForm.$('name').value
    });

    this.entryEditForm.addDefaultSection();
  }

  @action.bound
  addDefaultQuestion(section) {
    callTrack(CHECKLIST_TEMPLATE_QUESTION_ADDED, {
      checklist_template_name: this.entryEditForm.$('name').value
    });

    this.entryEditForm.addDefaultQuestion(section);
  }

  @action.bound
  removeQuestion(section, key) {
    this.entryEditForm.removeQuestion(section, key);
  }

  @action.bound
  moveSection(oldIndex, newIndex) {
    this.entryEditForm.moveSection(oldIndex, newIndex);
  }

  @action.bound
  moveQuestion(section, oldIndex, newIndex) {
    this.entryEditForm.moveQuestion(section, oldIndex, newIndex);
  }

  @action.bound
  softDeleteChecklistSection(key) {
    this.sectionToDeleteKey = key;
    this.showModal('ChecklistSectionDeleteModal');
  }

  @action.bound
  confirmDeleteSection() {
    this.hideActiveModal().then(() => {
      runInAction(() => {
        this.entryEditForm.removeSection(this.sectionToDeleteKey);
        this.sectionToDeleteKey = null;
      });
    });
  }

  @action.bound
  cancelDeleteSection() {
    this.hideActiveModal().then(() => {
      this.sectionToDelete = null;
    });
  }

  @action.bound
  handleResponseTypeChanging(question, option) {
    callTrack(CHECKLIST_TEMPLATE_QUESTION_TYPE_SELECTED, {
      checklist_template_name: this.entryEditForm.$('name').value,
      question_type: option.value?.toLowerCase()
    });

    if (option.value === 'MULTI_CHOICE') {
      this.previousResponseOption = question.$('responseType').value;
      this.openEditResponsesModal(question);
    }

    if (option.value === 'SIGNATURE') {
      this.handleSignatureOption(question);
    }

    question.$('responseType').set(option.value);
  }

  @action.bound
  initChecklistTemplateQuestionChoicesForm(question) {
    const choices = [...question.$('choices').values()];

    if (!choices.length) {
      choices.push({
        description: '',
        position: 1
      });
    }

    const allowMultiple = question.$('allowMultiple').value;

    return new ChecklistTemplateQuestionChoicesForm(
      {
        fields: checklistTemplateQuestionChoicesFormFields,
        rules: checklistTemplateQuestionChoicesFormRules,
        values: {
          choices,
          allowMultiple
        }
      },
      {
        options: checklistTemplateQuestionChoicesFormOptions,
        plugins: checklistTemplateQuestionChoicesFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound
  openEditResponsesModal(question) {
    this.selectedQuestion = question;

    this.questionChoicesForm = this.initChecklistTemplateQuestionChoicesForm(
      question
    );

    this.showModal('ChecklistQuestionEditResponseModal');
  }

  @action.bound
  cancelEditQuestionChoices() {
    if (this.previousResponseOption) {
      this.selectedQuestion.$('responseType').set(this.previousResponseOption);
    }

    this.hideActiveModal().then(() => {
      runInAction(() => {
        this.previousResponseOption = null;
        this.selectedQuestion = null;
        this.questionChoicesForm = null;
      });
    });
  }

  @action.bound
  saveQuestionChoices() {
    const choices = [...this.questionChoicesForm.$('choices').values()];
    const allowMultiple = this.questionChoicesForm.$('allowMultiple').value;
    this.questionChoicesForm = null;

    //filter from empty
    const choicesWithDescriptions = choices.filter(
      choice => choice.description !== ''
    );

    //update position numbers if empty removed
    if (choicesWithDescriptions.length < choices.length) {
      choicesWithDescriptions.forEach(
        (choice, index) => (choice.position = index + 1)
      );
    }

    this.selectedQuestion.update({
      choices: choicesWithDescriptions,
      allowMultiple
    });

    this.previousResponseOption = null;
    this.selectedQuestion = null;
    this.hideActiveModal();
  }

  @action.bound
  addDefaultChoice() {
    this.questionChoicesForm.addDefaultChoice();
  }

  @action.bound
  moveQuestionChoice(oldIndex, newIndex) {
    this.questionChoicesForm.moveQuestionChoice(oldIndex, newIndex);
  }

  @action.bound
  removeChoice(key) {
    this.questionChoicesForm.removeChoice(key);
  }

  /**
   * Because we want to show "Sign here" as default question text when
   * selected "Signature" option, but provide flexibility to change it if needed.
   */
  @action.bound
  handleSignatureOption(question) {
    if (!question.$('questionText').value) {
      question.$('questionText').set(t('Sign here'));
    }
  }

  @action.bound
  async editChecklistTemplateName() {
    await this.authorization.checkFeatureAccess('CUDCompanyChecklistTemplates');

    this.checklistTemplateNameForm = new ChecklistTemplateNameForm(
      {
        fields: checklistTemplateNameFormFields,
        rules: checklistTemplateNameFormRules,
        labels: checklistTemplateNameFormLabels,
        values: Object.assign(checklistTemplateNameFormValues, {
          name: this.entryEditForm.$('name').value,
          initialName: this.entryEditForm.$('name').value
        }),
        options: checklistTemplateNameFormFieldOptions
      },
      {
        options: checklistTemplateNameFormOptions,
        plugins: checklistTemplateNameFormPlugins,
        rootStore: this.rootStore
      }
    );

    this.showModal('ChecklistEditTemplateNameModal');
  }

  @action.bound submitChecklistTemplateNameForm(event) {
    event.preventDefault();

    this.checklistTemplateNameForm.submit({
      onSuccess: this.submitChecklistTemplateNameFormSuccess,
      onError: this.submitChecklistTemplateNameFormError
    });
  }

  @action.bound submitChecklistTemplateNameFormSuccess() {
    const values = this.checklistTemplateNameForm.values();

    this.entryEditForm.$('name').set(values.name);

    this.cancelEditChecklistTemplateName();
  }

  @action.bound submitChecklistTemplateNameFormError() {
    console.error(this.checklistTemplateNameForm.errors());
  }

  @action.bound async cancelEditChecklistTemplateName() {
    await this.hideActiveModal();
    this.checklistTemplateNameForm = null;
  }

  sectionsReducer(filteredSections, section) {
    //discard sections with only empty questions

    let numberOfEmptyQuestions = 0;
    section.checklistTemplateQuestions.forEach(question => {
      if (!question.questionText) {
        numberOfEmptyQuestions = ++numberOfEmptyQuestions;
      }
    });

    if (numberOfEmptyQuestions === section.checklistTemplateQuestions.length) {
      return filteredSections;
    }

    //discard empty questions
    const filteredQuestions = section.checklistTemplateQuestions.filter(
      question => question.questionText
    );

    const updatedSection = {
      ...section,
      checklistTemplateQuestions: filteredQuestions
    };

    filteredSections.push(updatedSection);
    return filteredSections;
  }
}
