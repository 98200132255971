import request from 'axios';
import UIStore from './UIStore';
import { action, computed, observable } from 'mobx';
import ProjectSelectorUI from './ProjectSelectorUI';

export default class HeaderUI extends UIStore {
  @observable anchorEl;
  @observable isHeaderVisible = true;
  @observable headerNotificationCounts;

  constructor(options) {
    super(options);
    this.anchorEl = null;
    // Set up counts that get shown in the header under the avatar menu.
    this.headerNotificationCounts = { outstandingApprovals: 0 };

    // New project selector UI specific to the header
    this.headerProjectSelectorUI = new ProjectSelectorUI({
      parent: this,
      rootStore: this.rootStore,
      projectStates: ['ACTIVE']
    });
  }

  @action.bound
  openUserMenu = e => {
    this.anchorEl = e.currentTarget;
  };

  @action.bound
  closeUserMenu = () => {
    this.anchorEl = null;
  };

  @action.bound
  toggleHeaderVisibleState = state => {
    this.isHeaderVisible = state;
  };

  @computed
  get hasHeaderNotifications() {
    return Object.values(this.headerNotificationCounts).find(
      value => value > 0
    );
  }

  @action.bound
  getTimesheetNotificationCount = async () => {
    if (!this.authorization.canViewTimesheets) return;

    /**
     * Gets the count for unapproved timecards
     */
    const url = `${this.rootStore.appConfig.tracking_api_url}/timesheets/notification`;

    const responseApprovalCount = await request.get(url);

    this.headerNotificationCounts.outstandingApprovals =
      responseApprovalCount.data.count;
  };

  @computed get messagingUI() {
    return this.rootStore.messagingUI;
  }
}
