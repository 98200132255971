import { RakenCollection } from '../lib';
import Answer from '../models/Answer';

export default class Answers extends RakenCollection {
  model() {
    return Answer;
  }

  url() {
    return `/ra/companies/${this.rootStore.me.company.uuid}/answers`;
  }
}
