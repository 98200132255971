import { Model } from 'mobx-mc';
import { action, computed } from 'mobx';
import omit from 'lodash.omit';
import orderBy from 'lodash.orderby';
import ChecklistTemplateQuestionChoices from 'stores/collections/checklists/ChecklistTemplateQuestionChoices';

export default class ChecklistTemplateQuestion extends Model {
  get restAttributes() {
    return [
      'uuid',
      'position',
      'questionText',
      'responseType',
      'required',
      'allowMultiple',
      'attachmentGuidance'
    ];
  }

  idAttribute() {
    return 'uuid';
  }

  get restAttributeDefaults() {
    return {
      allowMultiple: false
    };
  }

  @action.bound parse(attributes) {
    this.parseChecklistTemplateQuestionChoices(attributes);

    return {
      ...omit(attributes, 'choices')
    };
  }

  @action.bound parseChecklistTemplateQuestionChoices(attributes) {
    if (this.choices) {
      this.choices.reset(attributes.choices);
    } else {
      this.choices = new ChecklistTemplateQuestionChoices(attributes.choices, {
        parent: this,
        rootStore: this.rootStore
      });
    }
  }

  @computed get formValues() {
    return {
      uuid: this.uuid,
      position: this.position,
      questionText: this.questionText,
      responseType: this.responseType,
      required: this.required,
      allowMultiple: this.allowMultiple,
      attachmentGuidance: this.attachmentGuidance,
      choices: orderBy(this.choices.models, 'position', 'asc').map(choice => {
        return choice.formValues;
      })
    };
  }
}
