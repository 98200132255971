import MobxReactForm from 'mobx-react-form';
import extendedPlugins from 'forms/utils/extendedPlugins';

const projectTemplateBreaksFormPlugins = extendedPlugins;

const projectTemplateBreaksFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false,
  retrieveOnlyEnabledFields: false
};

const projectTemplateBreaksFormFields = ['hiddenBreakTypes'];

const projectTemplateBreaksFormLabels = {};

const projectTemplateBreaksFormRules = {
  hiddenBreakTypes: 'array'
};

const projectTemplateBreaksFormValues = {
  hiddenBreakTypes: []
};

class ProjectTemplateBreaksForm extends MobxReactForm {}

export {
  ProjectTemplateBreaksForm,
  projectTemplateBreaksFormOptions,
  projectTemplateBreaksFormFields,
  projectTemplateBreaksFormRules,
  projectTemplateBreaksFormValues,
  projectTemplateBreaksFormPlugins,
  projectTemplateBreaksFormLabels
};
