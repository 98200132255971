import UIStore from './UIStore';
import { action, observable, runInAction, computed, toJS } from 'mobx';
import request from 'axios';
import { t } from 'utils/translate';
import errorHandler from 'utils/errorHandler';
import alertErrorHandler from 'utils/alertErrorHandler';
import weekdays from 'utils/weekdays';
import moment from 'moment';
import orderBy from 'lodash.orderby';
import pick from 'lodash.pick';
import range from 'lodash.range';
import download from 'utils/download';

import {
  PROJECT_SUMMARY_REPORT_DOWNLOADED,
  PROJECT_SUMMARY_REPORT_SCHEDULED,
  PROJECT_SUMMARY_REPORT_STARTED
} from 'utils/segmentAnalytics/eventSpec';

import { callTrack } from 'utils/segmentIntegration';

import form, { ProjectSummaryForm } from 'forms/projectSummary';

import ProjectSummary from '../models/ProjectSummary';

export default class ProjectSummaryReportModalUI extends UIStore {
  @observable activeReportTab;
  @observable toWeeks;
  @observable loadingWeek;

  @observable scheduledReportToEdit;
  @observable monthDefaultValue;
  @observable weekDefaultValue;
  @observable reportScheduleOptionsDefaultValue;

  constructor(options) {
    super(options);
    this.activeReportTab = 'runOnce';

    this.toWeeks = [];
    this.loadingWeek = false;
    this.previousTab = null;

    this.monthDefaultValue = null;
    this.weekDefaultValue = null;
    this.reportScheduleOptionsDefaultValue = null;

    this.scheduledReportToEdit = null;
  }

  @action.bound
  initReportForm(values = null) {
    this.activeForm = new ProjectSummaryForm(
      {
        fields: form.fields,
        rules:
          this.activeReportTab === 'scheduled'
            ? Object.assign({}, form.rules, form.scheduledRules)
            : form.rules,
        values: values ? values : form.values,
        labels: form.labels
      },
      {
        options: form.options,
        plugins: form.plugins
      }
    );
  }

  @action.bound
  showProjectSummaryReportModal() {
    this.rootStore.authorizationUI
      .checkFeatureAccess('ViewProjectSummaryReports', true)
      .then(() => {
        this.showModal('projectSummaryReport');
        callTrack(PROJECT_SUMMARY_REPORT_STARTED);
        this.fetchScheduled();
        this.setupModalForm();
      });
  }

  @computed
  get projectDefaultValue() {
    if (this.scheduledReportToEdit?.projects.length) {
      return this.scheduledReportToEdit.projects.map(project => {
        return {
          value: project.uuid,
          name: project.name
        };
      });
    }

    if (this.project) {
      return [
        {
          value: this.project.uuid,
          name: this.project.name
        }
      ];
    }

    return [];
  }

  @action.bound
  async setupModalForm() {
    await this.rootStore.globalProjectInfoUI.fetchStartDates();
    this.initReportForm();
    if (this.project) {
      this.setProjectIncludeOption('SELECTED');
      this.selectProjects(this.projectDefaultValue);
    } else {
      this.setProjectIncludeOption('ACTIVE');
    }
  }

  @action.bound
  hideProjectSummaryReportModal() {
    this.hideActiveModal().then(() => {
      runInAction(() => {
        this.activeForm = null;
        this.saving = false;
        this.activeReportTab = 'runOnce';
        this.previousTab = null;
        this.monthDefaultValue = null;
        this.weekDefaultValue = null;
        this.scheduledReportToEdit = null;
        this.reportScheduleOptionsDefaultValue = null;
      });
    });
  }

  @action.bound
  switchTab(e, tab) {
    this.activeForm = null;
    this.scheduledReportToEdit = null;
    this.reportScheduleOptionsDefaultValue = null;
    this.activeReportTab = tab;
    this.setupModalForm();
  }

  @action.bound
  selectProjects(selectedOptions) {
    this.activeForm.update({
      projects: selectedOptions.map(option => {
        return {
          uuid: option.value,
          name: option.name,
          startDate: option.startDate,
          endDate: option.endDate
        };
      })
    });

    this.activeForm.validate();
  }

  @computed
  get projectIncludeOptions() {
    const options = [
      {
        label: t('Active projects'),
        value: 'ACTIVE',
        dataQA: 'active-projects'
      },
      {
        label: t('Active and inactive projects'),
        value: 'ACTIVE_AND_INACTIVE',
        dataQA: 'active-and-inactive-projects'
      },
      {
        label: t('Selected'),
        value: 'SELECTED',
        dataQA: 'selected-projects'
      }
    ];

    if (this.activeReportTab === 'scheduled') {
      return options.filter(option => {
        return option.value !== 'ACTIVE_AND_INACTIVE';
      });
    }

    return options;
  }

  @action.bound
  setProjectIncludeOption(value) {
    if (value === 'ACTIVE' || value === 'ACTIVE_AND_INACTIVE') {
      this.activeForm.update({
        projects: []
      });
    }

    if (value === 'SELECTED') {
      this.selectProjects(this.projectDefaultValue);
    }

    this.activeForm.update({
      month: '',
      week: '',
      timeFrame: 'WEEKLY'
    });

    this.monthDefaultValue = null;
    this.weekDefaultValue = null;

    this.activeForm.$('projectIncludeOption').set('value', value);
  }

  @computed
  get enableRunOnceSend() {
    if (!this.activeForm) return false;
    return this.activeForm.isValid;
  }

  @action.bound
  setTimeFrame(value) {
    this.activeForm.update({
      timeFrame: value
    });

    this.activeForm.update({
      month: '',
      week: '',
      recurringOn: ''
    });

    this.monthDefaultValue = null;
    this.weekDefaultValue = null;
    this.reportScheduleOptionsDefaultValue = null;
  }

  @action.bound
  setReportMonth(value) {
    this.activeForm.update({
      month: value.id,
      week: ''
    });

    this.weekDefaultValue = null;

    this.activeForm.validate();
  }

  @action.bound
  setReportWeek(value) {
    this.activeForm.update({
      week: value.id
    });

    this.weekDefaultValue = value;
  }

  @computed
  get monthsForSelectedProjects() {
    if (this.activeForm.$('projectIncludeOption').value === 'ACTIVE') {
      return this.getMonthsForProjectStartDates([
        this.rootStore.globalProjectInfoUI.earliestActiveProjectStartDate
      ]);
    }

    if (
      this.activeForm.$('projectIncludeOption').value === 'ACTIVE_AND_INACTIVE'
    ) {
      return this.getMonthsForProjectStartDates([
        this.rootStore.globalProjectInfoUI
          .earliestActiveOrInActiveProjectStartDate
      ]);
    }

    if (
      this.activeForm.$('projects').value &&
      this.activeForm.$('projects').value.length === 1 &&
      this.activeForm.$('projects').value[0].uuid === this.project?.uuid
    ) {
      /**
       * This is to cover an edge case where if you open the reports modal from within a project
       * and that project is not within the 85 limit this.activeForm.$('projects') knows about by default from
       * the BE we won't have the start date for this project. In this case if the only selected project is the
       * current project we can just look at this.project.startDate.
       */

      return this.getMonthsForProjectStartDates([this.project.startDate]);
    }

    return this.getMonthsForProjectStartDates(
      this.activeForm
        .$('projects')
        .values()
        .map(project => project.startDate)
    );
  }

  @computed
  get monthsForSelectedProjectsDescending() {
    return this.monthsForSelectedProjects?.reverse();
  }

  getMonthsForProjectStartDates(startDates) {
    const currentMonth = moment.unix(
      orderBy(startDates.map(date => moment(date).unix()))[0]
    );
    const now = moment().endOf('month');
    const months = [];

    while (currentMonth.isBefore(now)) {
      months.push({
        id: currentMonth.format('YYYY/M'),
        title: currentMonth.format('MMMM YYYY')
      });
      currentMonth.add(1, 'months');
    }

    return months;
  }

  @action.bound
  fetchWeeks(value) {
    const date = value.split('/');

    this.loadingWeek = true;

    request
      .get('/ra/project-summaries/weeks', {
        params: {
          year: date[0],
          month: date[1]
        }
      })
      .then(res => {
        const weeks = res.data.collection;

        this.toWeeks = [];

        weeks.forEach(({ year, weekNumber, fromDate, toDate }) => {
          if (moment(fromDate).format('M') === moment(toDate).format('M')) {
            this.toWeeks.push({
              id: `${moment(fromDate).format('YYYY/M')}/${weekNumber}`,
              title: `${moment(fromDate).format('MMMM Do')} - ${moment(
                toDate
              ).format('Do YYYY')}`
            });
          } else {
            this.toWeeks.push({
              id: `${moment(fromDate).format('YYYY/M')}/${weekNumber}`,
              title: `${moment(fromDate).format('MMMM Do')} - ${moment(
                toDate
              ).format('MMMM Do YYYY')}`
            });
          }
        });

        this.loadingWeek = false;
      })
      .catch(error => {
        errorHandler(error, this.rootStore.notificationsUI.pushError);
        this.loadingWeek = false;
      });
  }

  @computed
  get weeksForSelectedProjectsAndMonth() {
    const form = this.activeForm;

    if (form.$('month') === '') {
      return [];
    }

    return this.toWeeks;
  }

  @computed
  get weeksForSelectedProjectsAndMonthDescending() {
    return this.weeksForSelectedProjectsAndMonth?.reverse();
  }

  @computed
  get monthFieldDisabled() {
    if (this.activeForm.$('projectIncludeOption').value === 'SELECTED') {
      return !this.activeForm.$('projects').value.length;
    }
    return false;
  }

  @computed
  get weeksFieldDisabled() {
    return (
      !this.activeForm.$('month').value ||
      !this.weeksForSelectedProjectsAndMonth.length ||
      this.loadingWeek
    );
  }

  convertToServerFormat(payload, payloadFields) {
    if (payload.projects) {
      payload.projects = payload.projects.map(project => {
        return {
          uuid: project.uuid,
          name: project.name
        };
      });
    }

    if (payload.timeFrame === 'WEEKLY') {
      const timeFields = payload.week.split('/');
      payload.year = timeFields[0];
      payload.month = timeFields[1];
      payload.week = timeFields[2];
    } else {
      const timeFields = payload.month.split('/');
      payload.year = timeFields[0];
      payload.month = timeFields[1];
    }

    if (payload.timeFrame === 'WEEKLY') {
      payloadFields.push('week');
    }

    if (payload.projectIncludeOption === 'SELECTED') {
      payloadFields.push('projects');
    }

    return pick(payload, payloadFields);
  }

  @action.bound
  submitProjectSummaryReportFormRunOnce(e) {
    e.preventDefault();
    e.stopPropagation();

    if (this.saving) return;

    this.clearValidationDetails();

    this.activeForm.submit({
      onSuccess: this.submitProjectSummaryReportFormRunOnceSuccess,
      onError: this.submitProjectSummaryReportFormError
    });
  }

  @action.bound
  async submitProjectSummaryReportFormRunOnceSuccess() {
    const formValues = this.activeForm.values();

    const payloadFields = [
      'recurringOn',
      'year',
      'month',
      'timeFrame',
      'emails',
      'cover',
      'photos',
      'workers',
      'hours',
      'safety',
      'delays',
      'missedDailies',
      'openTasks',
      'weather',
      'scWorkers',
      'scHours',
      'scSafety',
      'scDelays',
      'scMissedDailies',
      'projectIncludeOption',
      'sendReport',
      'materials',
      'equipment'
    ];

    const payload = this.convertToServerFormat(formValues, payloadFields);

    this.saving = true;

    try {
      await request.post('ra/project-summaries', payload, { timeout: 5000 });

      callTrack(PROJECT_SUMMARY_REPORT_DOWNLOADED);

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Your download request has been sent.')
      });

      this.hideProjectSummaryReportModal();
    } catch (error) {
      this.saving = false;
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound
  upDateCustomizeCheckbox(fieldName) {
    this.activeForm.update({
      [fieldName]: !this.activeForm.$(fieldName).value
    });
  }

  @action.bound
  viewCustomizeSummary() {
    this.previousTab = this.activeReportTab;
    this.activeReportTab = 'customizeSummary';
  }

  @action.bound
  setMonthsDefaultValue() {
    if (this.activeForm?.$('month').value) {
      this.monthDefaultValue = this.monthsForSelectedProjects.find(month => {
        return this.activeForm?.$('month').value === month.id;
      });
    }
  }

  @action.bound
  setWeeksDefaultValue() {
    if (this.activeForm?.$('week').value) {
      this.weekDefaultValue = toJS(this.weeksForSelectedProjectsAndMonth).find(
        week => {
          return this.activeForm?.$('week').value === week.id;
        }
      );
    }
  }

  @action.bound
  hideCustomizeSummary() {
    this.activeReportTab = this.previousTab;
    this.setRecurringOnDefaultValue();
    this.setMonthsDefaultValue();
    this.setWeeksDefaultValue();
  }

  @computed
  get customiseReportContentChecked() {
    const form = this.activeForm;
    const fields = ProjectSummaryForm.reportContentFields;

    for (let i = 0; i < fields.length; i++) {
      if (fields[i] !== 'cover' && form.$(fields[i]).value) {
        return true;
      }
    }

    return false;
  }

  @action.bound
  toggleCustomiseReportContent() {
    const form = this.activeForm;
    const checked = this.customiseReportContentChecked;
    const fields = ProjectSummaryForm.reportContentFields;
    const update = {};

    for (let i = 0; i < fields.length; i++) {
      if (fields[i] !== 'cover') {
        update[fields[i]] = !checked;
      }
    }

    form.update(update);
  }

  @computed
  get includeCollaboratorsChecked() {
    const form = this.activeForm;
    const fields = ProjectSummaryForm.scFields;

    for (let i = 0; i < fields.length; i++) {
      if (form.$(fields[i]).value) {
        return true;
      }
    }

    return false;
  }

  @action.bound
  toggleCollaborators() {
    const form = this.activeForm;
    const checked = this.includeCollaboratorsChecked;
    const fields = ProjectSummaryForm.scFields;
    const update = {};

    for (let i = 0; i < fields.length; i++) {
      update[fields[i]] = !checked;
    }

    form.update(update);
  }

  /**
   * Scheduled Reports
   */

  @computed get scheduledReportNewOrSaveButtonDisabled() {
    if (this.saving) return true;

    return this.scheduledReportToEdit && !this.activeForm?.isValid;
  }

  @computed
  get scheduledReportNewOrSaveButtonText() {
    if (this.saving) return t('Saving...');

    return this.scheduledReportToEdit
      ? this.scheduledReportToEdit?.isNew
        ? t('Schedule report')
        : t('Save changes')
      : t('New report');
  }

  @computed
  get hasNoScheduledReports() {
    return (
      !this.fetchingScheduledReports &&
      !this.projectSummaries.fetching &&
      !this.projectSummaries.hasModels
    );
  }

  @computed
  get projectSummaries() {
    return this.rootStore.projectSummaries;
  }

  @action.bound
  fetchScheduled() {
    return Promise.all([
      this.projectSummaries.fetch({
        params: {
          limit: 1000
        }
      })
    ]);
  }

  @action.bound
  submitProjectSummaryReportForm() {
    if (this.saving) return;

    this.clearValidationDetails();

    this.activeForm.submit({
      onSuccess: this.submitProjectSummaryReportFormSuccess,
      onError: this.submitProjectSummaryReportFormError
    });
  }

  @action.bound
  saveOrCreateScheduledReport(e) {
    e.preventDefault();
    e.stopPropagation();

    if (this.scheduledReportToEdit) {
      // Save report
      this.submitProjectSummaryReportForm();
    } else {
      // Open New Report
      this.createNewScheduledReport();
    }
  }

  submitProjectSummaryReportFormError(values) {
    console.error(this.activeForm.errors());
  }

  @action.bound
  createNewScheduledReport() {
    // Use a new model to hold our report when creating a new one.
    this.scheduledReportToEdit = new ProjectSummary(
      {},
      {
        rootStore: this.rootStore
      }
    );

    this.setupModalForm();
  }

  @action.bound
  async submitProjectSummaryReportFormSuccess() {
    const payloadFields = [
      'id',
      'name',
      'recurringOn',
      'year',
      'month',
      'timeFrame',
      'emails',
      'cover',
      'photos',
      'workers',
      'hours',
      'safety',
      'delays',
      'missedDailies',
      'openTasks',
      'weather',
      'scWorkers',
      'scHours',
      'scSafety',
      'scDelays',
      'scMissedDailies',
      'projectIncludeOption',
      'sendReport',
      'materials',
      'equipment'
    ];

    const payload = this.convertToServerFormat(
      this.activeForm.values(),
      payloadFields
    );

    this.saving = true;

    const reportNotification = this.scheduledReportToEdit.isNew
      ? t('Scheduled report created')
      : t('Scheduled report updated');

    try {
      if (this.scheduledReportToEdit.isNew) {
        await this.scheduledReportToEdit.create(payload, {});
      } else {
        payload.id = this.scheduledReportToEdit.id;
        await this.scheduledReportToEdit.save(payload, { method: 'put' });
      }

      callTrack(PROJECT_SUMMARY_REPORT_SCHEDULED);

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: reportNotification
      });
      this.fetchScheduled();
      this.cancelEditingReport();
    } catch (error) {
      this.saving = false;
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @computed
  get reportScheduleOptions() {
    if (this.activeForm.$('timeFrame').value === 'WEEKLY') {
      return weekdays.map(weekday => {
        return {
          id: weekday.value,
          title: weekday.name
        };
      });
    } else {
      return range(1, 32).map(day => ({ id: day, title: `${day}` }));
    }
  }

  @action.bound
  setRecurringOnDefaultValue() {
    if (this.activeForm?.$('recurringOn').value) {
      this.reportScheduleOptionsDefaultValue = this.reportScheduleOptions.find(
        option => {
          return this.activeForm?.$('recurringOn').value === option.id;
        }
      );
    }
  }

  @action.bound
  setReportSchedule(schedule) {
    this.activeForm.update({
      recurringOn: schedule.id
    });

    this.setRecurringOnDefaultValue();
  }

  @action.bound
  cancelEditingReport() {
    this.saving = false;
    this.activeForm = null;
    this.scheduledReportToEdit = null;
    this.reportScheduleOptionsDefaultValue = null;
  }

  @action.bound
  editScheduledReport(report) {
    this.scheduledReportToEdit = report;

    this.initReportForm(this.scheduledReportToEdit.formValues);
    this.setRecurringOnDefaultValue();
  }

  @action.bound
  async deleteScheduledReport(report) {
    try {
      await report.destroy({ wait: true });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  @action.bound
  handleScheduledReportCancelButton() {
    this.scheduledReportToEdit
      ? this.cancelEditingReport()
      : this.hideProjectSummaryReportModal();
  }

  @action.bound
  downloadProjectList() {
    download({
      store: this.rootStore,
      url: `${this.rootStore.apiURL}/ra/companies/${this.rootStore.me.company.uuid}/projects/csv`,
      method: 'GET',
      xhttpOptions: {
        sendXApiKey: true
      }
    });
  }
}
