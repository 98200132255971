import { Model } from 'mobx-mc';
import BreakType from '../Break';
import { action, computed } from 'mobx';
import omit from 'lodash.omit';

class TimeCardBreak extends Model {
  get restAttributes() {
    return ['uuid', 'startTime', 'endTime', 'duration', 'billable'];
  }

  idAttribute() {
    return 'uuid';
  }

  @action.bound
  parse(data) {
    this.parseBreakType = this.parseBreakType(data.breakType);

    return {
      ...omit(data, ['breakType'])
    };
  }

  @action.bound
  parseBreakType(breakType) {
    this.breakType = new BreakType(breakType);
  }

  @computed
  get formValues() {
    return {
      uuid: this.uuid,
      startTime: this.startTime || '',
      endTime: this.endTime || '',
      breakType: {
        uuid: this.breakType.uuid,
        name: this.breakType.name,
        durationRequired: this.breakType.durationRequired,
        startTimeRequired: this.breakType.startTimeRequired,
        endTimeRequired: this.breakType.endTimeRequired,
        minimumBreakDuration: this.breakType.minimumBreakDuration,
        defaultDuration: this.breakType.defaultDuration,
        billable: this.breakType.billable
      },
      duration: this.duration,
      billable: this.billable
    };
  }
}

export default TimeCardBreak;
