import UIStore from 'stores/ui/UIStore';
import { action, observable, computed } from 'mobx';
import errorHandler from 'utils/errorHandler';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

import orderBy from 'lodash.orderby';
import history from 'utils/history';

import SuperAdminDemoAddUI from './SuperAdminDemoAddUI';

export default class SuperAdminDemosUI extends UIStore {
  @observable searchQuery;
  @observable sortField;
  @observable sortDirection;
  @observable deleteDemo;
  @observable loading;
  @observable errorsList;
  @observable page;
  @observable pageLimit;

  constructor(options) {
    super(options);
    this.searchQuery = '';
    this.sortDirection = 'desc';
    this.sortField = 'createdDate';
    this.deleteDemo = null;
    this.loading = true;
    this.errorsList = null;
    this.page = 1;
    this.pageLimit = 20;

    this.superAdminDemoAddUI = new SuperAdminDemoAddUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound async setup() {
    await this.fetchSuperAdminDemoAccounts();
    if (this.hasSeedingAccounts) {
      this.pollForSeedingAccounts();
    }

    this.fetchSuperAdminDemoMasterAccounts();
  }

  @action.bound tearDown() {
    this.clearPoll();
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.searchQuery = '';
    this.sortDirection = 'desc';
    this.sortField = 'createdDate';
    this.deleteDemo = null;
    this.loading = true;
    this.errorsList = null;
    this.page = 1;
    this.pageLimit = 20;
  }

  @computed
  get hasSeedingAccounts() {
    return !!this.rootStore.superAdminDemoAccounts.models.filter(demo => {
      return demo.status === 'SEEDING';
    }).length;
  }

  @computed
  get sortedDemos() {
    return orderBy(
      this.rootStore.superAdminDemoAccounts.models,
      [demo => demo[this.sortField]?.toString().toLowerCase()],
      [this.sortDirection]
    );
  }

  @computed
  get searchedDemos() {
    if (!this.searchQuery) {
      return this.sortedDemos;
    }

    return this.sortedDemos.filter(
      demo =>
        demo.type?.toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1 ||
        demo.demoCompanyName
          ?.toLowerCase()
          .indexOf(this.searchQuery.toLowerCase()) > -1 ||
        demo.username?.toLowerCase().indexOf(this.searchQuery.toLowerCase()) >
          -1 ||
        demo.createdByUserName
          ?.toLowerCase()
          .indexOf(this.searchQuery.toLowerCase()) > -1 ||
        demo.createdDateFormatted
          ?.toLowerCase()
          .indexOf(this.searchQuery.toLowerCase()) > -1
    );
  }

  @computed
  get totalPages() {
    return Math.ceil(this.searchedDemos.length / this.pageLimit);
  }

  @computed
  get rowData() {
    return this.searchedDemos.slice(
      this.pageLimit * (this.page - 1),
      this.pageLimit * this.page
    );
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound
  sortByColumn(sortField, sortDirection = null) {
    this.sortField = sortField;

    if (sortField === 'isMember') {
      this.sortDirection = sortDirection;
    } else {
      if (this.sortDirection === 'asc') {
        this.sortDirection = 'desc';
      } else {
        this.sortDirection = 'asc';
      }
    }
  }

  @action.bound
  setSearchQuery(value) {
    this.page = 1;
    this.searchQuery = value;
  }

  @action.bound
  clearSearchQuery() {
    this.searchQuery = '';
  }

  @action.bound
  fetchSuperAdminDemoMasterAccounts() {
    return this.rootStore.superAdminDemoMasterAccounts.fetch().catch(error => {
      errorHandler(error, this.rootStore.notificationsUI.pushError);
    });
  }

  @action.bound
  fetchSuperAdminDemoAccounts() {
    this.loading = true;

    return this.rootStore.superAdminDemoAccounts
      .fetch()
      .then(() => {
        this.loading = false;
      })
      .catch(error => {
        errorHandler(error, this.rootStore.notificationsUI.pushError);
      });
  }

  @action.bound
  openDeleteDemoModal(demo, index) {
    this.showModal('DeleteModal');
    this.deleteDemo = demo;
    this.deleteDemo.index = index;
  }

  @action.bound
  openShowErrorsModal(demo) {
    this.showModal('superAdminShowErrorsModal');
    this.errorsList = demo.errorInfo;
  }

  @action.bound
  closeShowErrorsModal() {
    return this.hideActiveModal().then(() => {
      this.errorsList = null;
    });
  }

  @action.bound
  closeDeleteDemoModal() {
    return this.hideActiveModal().then(() => {
      this.deleteDemo = null;
    });
  }

  @action.bound
  async deleteDemoAccount() {
    this.saving = true;

    try {
      await this.deleteDemo.destroy({
        wait: false
      });

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Demo successfully removed')
      });

      this.closeDeleteDemoModal();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  pollForSeedingAccounts() {
    this.pollSeedingAccounts = setInterval(() => {
      this.rootStore.superAdminDemoAccounts
        .fetch()
        .then(() => {
          if (!this.hasSeedingAccounts) {
            this.clearPoll();
          }
        })
        .catch(error => {
          errorHandler(error, this.rootStore.notificationsUI.pushError);
        });
    }, 60000);
  }

  clearPoll() {
    clearInterval(this.pollSeedingAccounts);
  }

  @computed get hasDemos() {
    return this.searchedDemos.length > 0;
  }

  @computed get showEmptyState() {
    return !this.loading && !this.searchQuery && !this.hasDemos;
  }

  @computed get showEmptySearchState() {
    return !this.loading && this.searchQuery && !this.hasDemos;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @action.bound addDemo() {
    history.push('/demos/add');
  }
}
