import moment from 'moment-timezone';
import { computed } from 'mobx';

import formatTemperature from '../../utils/formatTemperature';
import formatPrecipitation from '../../utils/formatPrecipitation';
import WeatherByTime from './WeatherByTime';

export default class WeatherDaily extends WeatherByTime {
  get restAttributes() {
    return [
      'type',
      'sunriseTime',
      'sunsetTime',
      'moonPhase',
      'precipIntensity',
      'precipIntensityMax',
      'precipIntensityMaxTime',
      'precipProbability',
      'temperatureMin',
      'temperatureMinTime',
      'temperatureMax',
      'temperatureMaxTime',
      'apparentTemperatureMin',
      'apparentTemperatureMinTime',
      'apparentTemperatureMax',
      'apparentTemperatureMaxTime',
      'dewPoint',
      'humidity',
      'windSpeed',
      'windGust',
      'windBearing',
      'visibility',
      'cloudCover',
      'pressure',
      'icon'
    ];
  }

  @computed
  get date() {
    return moment.unix(this.sunriseTime).tz(this.timezone);
  }

  @computed
  get isSelectedDay() {
    return this.day === this.parent.selectedDay.day;
  }

  @computed
  get longDate() {
    return this.date.format('dddd, MMM D, YYYY');
  }

  @computed
  get shortDate() {
    return moment
      .unix(this.sunriseTime)
      .tz(this.timezone)
      .format('M/DD');
  }

  @computed
  get day() {
    return moment
      .unix(this.sunriseTime)
      .tz(this.timezone)
      .format('ddd');
  }

  @computed
  get sunriseFormatted() {
    return moment
      .unix(this.sunriseTime)
      .tz(this.timezone)
      .format('h:mm A');
  }

  @computed
  get sunsetFormatted() {
    return moment
      .unix(this.sunsetTime)
      .tz(this.timezone)
      .format('h:mm A');
  }

  @computed
  get useImperial() {
    return this.rootStore?.companyUI?.company?.useImperialFormatting;
  }

  @computed
  get low() {
    return formatTemperature(this.temperatureMin, this.useImperial);
  }

  @computed
  get high() {
    return formatTemperature(this.temperatureMax, this.useImperial);
  }

  @computed
  get summary() {
    return (
      this.icon &&
      this.icon
        .replace(/-/gi, ' ')
        .replace('day', '')
        .replace('night', '')
    );
  }

  @computed
  get totalPrecipitationIntensityFormatted() {
    return formatPrecipitation(this.precipIntensity * 24, this.useImperial);
  }
}
