import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledInput = styled.input`
  -webkit-appearance: none;
  background-color: ${props => props.theme.colors.lightGrey1};
  border-radius: 2rem;
  height: 2px;

  /*ie11*/
  @media all and (-ms-high-contrast: none) {
    height: 22px;
    background: none;
  }

  /*edge*/
  @supports (-ms-ime-align: auto) {
    height: 22px;
    background: none;
  }

  width: 100%;
  padding: 0;
  &:focus {
    outline: none;
  }
  &::-ms-track {
    width: 100%;
    cursor: pointer;
    height: 2px;
    border: none;
  }

  &::-ms-fill-lower,
  &::-ms-fill-upper {
    background-color: ${props => props.theme.colors.lightGrey1};
  }

  &::-webkit-slider-thumb {
    background: ${props => props.theme.colors.charcoalGrey};
    border: 2px solid ${props => props.theme.colors.white};
    border-radius: 50%;
    -webkit-appearance: none;
    height: 18px;
    width: 18px;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    background: ${props => props.theme.colors.charcoalGrey};
    border: 2px solid ${props => props.theme.colors.white};
    border-radius: 50%;
    height: 18px;
    width: 18px;
    cursor: pointer;
  }
  &::-ms-thumb {
    border-radius: 50%;
    border: 2px solid ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.charcoalGrey};
    height: 18px;
    width: 18px;
    cursor: pointer;
  }
`;

class Slider extends Component {
  state = {
    value: this.props.startValue
  };

  handleChange = event => {
    const value = event.target.value;

    this.setState({ value });

    this.props.onChange && this.props.onChange(value);
  };

  render() {
    const { max, min, step } = this.props;

    return (
      <StyledInput
        type="range"
        max={max}
        min={min}
        onChange={this.handleChange}
        value={this.state.value}
        step={step}
      />
    );
  }
}

Slider.propTypes = {
  startValue: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};

Slider.defaultProps = {
  startValue: 0,
  min: 0,
  max: 100,
  step: 0.01
};

export default Slider;
