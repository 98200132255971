import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import { observable, action, computed, runInAction, reaction } from 'mobx';

import UIStore from './UIStore';
import ProjectDirectoryWorkersAddUI from './ProjectDirectoryWorkersAddUI';
import ProjectDirectoryWorkersImportUI from './ProjectDirectoryWorkersImportUI';

import debounce from 'lodash.debounce';

import { BASE_DEBOUNCE } from 'fixtures/constants';

import { t } from 'utils/translate';
import errorHandler from 'utils/errorHandler';
import history from 'utils/history';

export default class ProjectDirectoryWorkersUI extends UIStore {
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;

  @observable selectedWorker;

  constructor(options) {
    super(options);

    this.loading = true;
    this.sortField = 'firstName';
    this.sortDirection = 'asc';
    this.searchQuery = '';
    this.pageSize = 20;
    this.page = 1;

    this.selectedWorker = null;

    this.fetchWorkersDebounced = debounce(this.fetchWorkers, BASE_DEBOUNCE);

    this.projectDirectoryWorkersAddUI = new ProjectDirectoryWorkersAddUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.projectDirectoryWorkersImportUI = new ProjectDirectoryWorkersImportUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound setup() {
    this.fetchWorkers();
    this.classificationsUI.fetchClassifications();
    this.setupReactions();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions(listRef) {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchWorkersDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @action.bound
  async fetchWorkers() {
    this.workers.cancelRequest();
    this.workers.clear();

    await this.workers.fetch({
      params: pickBy(this.params, identity)
    });

    this.loading = false;
  }

  @computed get hasWorkers() {
    return this.workers.length > 0;
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @computed get additionalSortFields() {
    if (this.sortField === 'updatedTimestamp') {
      return 'createdTimestamp,firstName,lastName';
    }

    if (this.sortField === 'lastName') {
      return 'firstName';
    } else if (this.sortField === 'firstName') {
      return `lastName`;
    }

    return 'firstName,lastName';
  }

  @computed get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      sortField: `${this.sortField},${this.additionalSortFields}`,
      sortDirection: this.sortDirection,
      query: this.searchQuery,
      status: 'ACTIVE',
      role: 'ROLE_WORKER',
      projectUuids: this.project?.uuid
    };
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @action sortByLastUpdated() {
    this.sortField = 'updatedTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchWorkersDebounced();
  }

  @action sortByLastCreated() {
    this.sortField = 'createdTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchWorkersDebounced();
  }

  @computed
  get totalPages() {
    return Math.ceil(this.workers.totalElements / this.pageSize);
  }

  @action.bound clearUIState() {
    this.searchQuery = '';
    this.workers.clear();
    this.page = 1;
    this.loading = true;
    this.sortField = 'firstName';
    this.sortDirection = 'asc';
    this.selectedWorker = null;
  }

  @computed get showEmptyState() {
    return !this.loading && !this.searchQuery && !this.hasWorkers;
  }

  @computed get showEmptySearchState() {
    return !this.loading && this.searchQuery && !this.hasWorkers;
  }

  @computed get showTable() {
    return !this.showEmptyState && !this.showEmptySearchState;
  }

  @computed get removeWorkerTitle() {
    return t('Remove worker from project?');
  }

  @computed get removeWorkerText() {
    return t(
      'You will no longer be able to create time cards for this worker.'
    );
  }

  @action.bound async removeWorkerFromProject(worker) {
    this.selectedWorker = worker;
    this.showModal('RemoveWorkerModal');
  }

  @action.bound async cancelRemoveWorkerFromProject() {
    await this.hideActiveModal();
    this.selectedWorker = null;
  }

  @action.bound async confirmRemoveWorkerFromProject() {
    try {
      this.saving = true;

      await this.selectedWorker.destroy(
        {
          url: `${this.rootStore.workers.url()}/${
            this.selectedWorker.uuid
          }/projects/${this.project.uuid}`
        },
        { wait: true }
      );

      this.loading = true;

      if (!this.hasWorkers) {
        this.setPage(null, 1);
        this.fetchWorkers();
      } else {
        this.fetchWorkers();
      }

      await this.hideActiveModal();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Worker removed from project')
      });

      // TODO Mixpanel events may be required

      this.selectedWorker = null;
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    } finally {
      this.saving = false;
    }
  }

  @action.bound async showAddWorkersModal() {
    await this.authorization.checkFeatureAccess('EditWorkerProjects');

    history.push(`${this.project.viewUrl}/directory/workers/add`);
  }

  @action.bound async showImportWorkersModal() {
    await this.authorization.checkFeatureAccess('EditWorkers');

    history.push(`${this.project.viewUrl}/directory/workers/import`);
  }

  @computed get classificationOptions() {
    return this.classificationsUI.selectableClassifications.map(
      classification => {
        return {
          uuid: classification.uuid,
          name: classification.name
        };
      }
    );
  }
}
