import { action, observable } from 'mobx';
import UIStore from './UIStore';
import request from 'axios';

export default class GlobalProjectInfoUI extends UIStore {
  @observable earliestActiveProjectStartDate;
  @observable earliestActiveOrInActiveProjectStartDate;

  @action.bound
  async fetchStartDates() {
    await Promise.all([
      this.fetchEarliestActiveProjectStartDate(),
      this.fetchEarliestActiveOrInActiveProjectStartDate()
    ]);
  }

  @action.bound fetchEarliestActiveProjectStartDate() {
    request
      .get(`ra/companies/${this.company.uuid}/projects`, {
        params: {
          sortDirection: 'ASC',
          sortField: 'startDate',
          limit: 1,
          projectStates: 'ACTIVE'
        }
      })
      .then(response => {
        this.earliestActiveProjectStartDate =
          response.data.collection[0].startDate;
      });
  }

  @action.bound fetchEarliestActiveOrInActiveProjectStartDate() {
    request
      .get(`ra/companies/${this.company.uuid}/projects`, {
        params: {
          sortDirection: 'ASC',
          sortField: 'startDate',
          limit: 1,
          projectStates: 'ACTIVE,INACTIVE'
        }
      })
      .then(response => {
        this.earliestActiveOrInActiveProjectStartDate =
          response.data.collection[0].startDate;
      });
  }
}
