import React, { Component } from 'react';
import styled from 'styled-components';
import generateQAIdentifier from 'utils/generateQAIdentifier';

const Label = styled.label`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  display: flex;
  align-items: center;
`;

const Button = styled.div`
  background: ${props =>
    props.checked ? props.theme.colors.blueAccent : props.theme.colors.white};
  border: 2px solid
    ${props =>
      props.checked
        ? props.theme.colors.blueAccent
        : props.theme.colors.lightGrey3};
  border-radius: 50%;
  box-sizing: border-box;
  height: 16px;
  position: relative;
  width: 16px;
  flex-shrink: 0;
  &:focus {
    box-shadow: 0 0 8px
      ${props =>
        props.checked ? 'transparent' : props.theme.colors.lightGrey1};
    outline: 0;
  }
  &::before {
    border-radius: 50%;
    border: 2px solid ${props => props.theme.colors.white};
    content: '';
    display: block;
    height: 8px;
    left: 0;
    position: absolute;
    top: 0;
    width: 8px;
  }
`;

const LabelText = styled.span`
  padding-left: 8px;
`;

class RadioButton extends Component {
  render() {
    const {
      checked,
      tabIndex,
      onKeyDown,
      onClick,
      children,
      disabled
    } = this.props;

    return (
      <Label
        data-qa={`radio_${generateQAIdentifier(children)}`}
        onClick={onClick}
        disabled={disabled}
      >
        <Button
          checked={checked}
          onKeyDown={onKeyDown}
          tabIndex={tabIndex}
          ref={radio => {
            this.radio = radio;
          }}
        />

        <LabelText>{children}</LabelText>
      </Label>
    );
  }
}

RadioButton.defaultProps = {
  tabIndex: 0
};

export default RadioButton;
