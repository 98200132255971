import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';
import { t } from 'utils/translate';

const salesRepresentativeFormPlugins = extendedPlugins;

const salesRepresentativeFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const salesRepresentativeFormFields = [
  'salesRepresentative',
  'salesRepresentative.id',
  'salesRepresentative.name'
];

const salesRepresentativeFormRules = {
  'salesRepresentative.id': 'required'
};

const salesRepresentativeFormLabels = {
  'salesRepresentative.id': t('sales representative')
};

class SalesRepresentativeForm extends MobxReactForm {}

export {
  SalesRepresentativeForm,
  salesRepresentativeFormOptions,
  salesRepresentativeFormFields,
  salesRepresentativeFormRules,
  salesRepresentativeFormLabels,
  salesRepresentativeFormPlugins
};
