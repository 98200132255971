import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';
import trimObject from '../../utils/trimObject';

const settingsReportsFormPlugins = extendedPlugins;

const settingsReportsFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false,
  retrieveOnlyEnabledFields: false
};

const settingsReportsFormFields = [
  'includeHours',
  'includeStampsForEntries',
  'includeSafetyObservations',
  'includeStampsForPhotos',
  'includeHoursToDate',
  'includeWeather',
  'includeWorkLogs',
  'includeQualityControlObservations',
  'includeQuantity',
  'includeNotes',
  'includeDailySurvey',
  'hideEmptySections',
  'includeTimeCards',
  'hideEmptySubcontractors',
  'hideInactiveSubcontractors',
  'includeMaterialQuantity',
  'includeEquipment',
  'hideGrandTotal',
  'includeToolboxTalks',
  'includeCompletedChecklists'
];

const settingsReportsFormRules = {
  includeHours: 'boolean',
  includeStampsForEntries: 'boolean',
  includeSafetyObservations: 'boolean',
  includeStampsForPhotos: 'boolean',
  includeHoursToDate: 'boolean',
  includeWeather: 'boolean',
  includeWorkLogs: 'boolean',
  includeQualityControlObservations: 'boolean',
  includeQuantity: 'boolean',
  includeNotes: 'boolean',
  includeDailySurvey: 'boolean',
  hideEmptySections: 'boolean',
  includeTimeCards: 'boolean',
  hideEmptySubcontractors: 'boolean',
  hideInactiveSubcontractors: 'boolean',
  includeMaterialQuantity: 'boolean',
  includeEquipment: 'boolean',
  hideGrandTotal: 'boolean',
  includeToolboxTalks: 'boolean',
  includeCompletedChecklists: 'boolean'
};

const settingsReportsFormDisabled = {};

const settingsReportsFormLabels = {};

const settingsReportsFormValues = {
  includeHours: true,
  includeStampsForEntries: true,
  includeSafetyObservations: true,
  includeStampsForPhotos: true,
  includeHoursToDate: true,
  includeWeather: true,
  includeWorkLogs: true,
  includeQualityControlObservations: true,
  includeQuantity: true,
  includeNotes: true,
  includeDailySurvey: true,
  hideEmptySections: false,
  includeTimeCards: true,
  hideEmptySubcontractors: false,
  hideGrandTotal: false,
  includeToolboxTalks: false,
  includeCompletedChecklists: false,
  hideInactiveSubcontractors: true
};

class SettingsReportsForm extends MobxReactForm {
  trimmedValues() {
    return trimObject(this.values());
  }
}

export {
  SettingsReportsForm,
  settingsReportsFormOptions,
  settingsReportsFormFields,
  settingsReportsFormRules,
  settingsReportsFormValues,
  settingsReportsFormLabels,
  settingsReportsFormPlugins,
  settingsReportsFormDisabled
};
