import { RakenCollection } from './../lib';
import SubscriptionPlan from './../models/SubscriptionPlan';
import { computed, action } from 'mobx';

export default class SubscriptionPlans extends RakenCollection {
  url() {
    return '/ra/subscription-plans';
  }

  model() {
    return SubscriptionPlan;
  }

  /**
   * Get the plans without the starter
   */
  @computed
  get plansWithoutStarter() {
    return this.models.filter(model => {
      return model.name !== 'Starter';
    });
  }

  /**
   * Get the annual plans including starter
   */
  @computed
  get annualPlansWithStarter() {
    return this.models.filter(model => {
      return model.annual || model.name === 'Starter';
    });
  }

  @action.bound
  getPlan(planKey) {
    return this.models.find(model => model.planKey == planKey);
  }
}
