import { action, computed, observable } from 'mobx';
import moment from 'moment';

import ProjectChildAddUI from './ProjectChildAddUI';
import Observation from '../../models/Observation';

import {
  ObservationForm,
  observationFormOptions,
  observationFormFields,
  observationFormValues,
  observationFormLabels,
  observationFormPlugins
} from 'forms/observation';

import history from 'utils/history';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';

import { callTrack } from 'utils/segmentIntegration';
import {
  OBSERVATION_ADDED,
  OBSERVATION_STATUS_UPDATE
} from 'utils/segmentAnalytics/eventSpec';

export default class ObservationAddUI extends ProjectChildAddUI {
  @observable observationStatusNewValue;
  @observable signed;
  @observable signature;

  @observable notifyAssignees;
  @observable notifyTeamMembers;

  constructor(options) {
    super(options);

    this.saveAsDraft = false;

    this.observationStatusNewValue = null;
    this.signed = false;
    this.signature = null;
    this.notifyAssignees = false;
    this.notifyTeamMembers = false;
  }

  @computed get assigneeMemberSelectorUI() {
    return this.parent.assigneeMemberSelectorUI;
  }

  @computed get notificationMemberSelectorUI() {
    return this.parent.notificationMemberSelectorUI;
  }

  @computed get defaultType() {
    const defaulObservationType = localStorage.getItem('defaulObservationType');

    const type = this.parent.observationTypes.models.find(
      type => type.uuid === defaulObservationType
    );

    if (type) {
      return {
        value: type.type,
        title: type.type,
        class: type.typeClass
      };
    } else {
      return null;
    }
  }

  @computed get defaultLocation() {
    let defaulObservationLocation;
    try {
      defaulObservationLocation = JSON.parse(
        localStorage.getItem('defaulObservationLocation')
      );
    } catch (e) {
      return null;
    }

    return defaulObservationLocation;
  }

  @computed get defaultSubType() {
    return localStorage.getItem('defaulObservationType');
  }

  @computed get defaultEmails() {
    const defaulObservationEmails = localStorage.getItem(
      'defaulObservationEmails'
    );
    if (
      !defaulObservationEmails ||
      !JSON.parse(defaulObservationEmails).every(
        item => typeof item === 'object'
      )
    )
      return [];

    return this.assigneeMemberSelectorUI.options.filter(member => {
      return JSON.parse(defaulObservationEmails).find(
        defaultEmail => defaultEmail.email === member.email
      );
    });
  }

  @action.bound async setup() {
    this.entryForAdd = new Observation(
      { type: 'Observation' },
      {
        rootStore: this.rootStore
      }
    );

    this.projectLocationSelectorUI.setup(this.project.uuid);

    await Promise.all([
      this.observationTypes.fetch(),
      this.observationStatuses.fetch(),
      this.observationFields.fetch()
    ]);

    this.entryAddForm = new ObservationForm(
      {
        fields: observationFormFields,
        rules: this.observationFields.observationFieldsFormRules,
        values: Object.assign(observationFormValues, {
          status: this.observationStatusOptions.find(
            status => status.title === 'Open'
          ),
          type: this.defaultType,
          subType: this.defaultSubType,
          assignees: [],
          // For location we need to check if the default is in the options list so it doesn't show up when we change project.
          location: this.projectLocationSelectorUI?.options.find(
            location => location.uuid === this.defaultLocation?.uuid
          ),
          emails: this.defaultEmails
        }),
        labels: observationFormLabels
      },
      {
        options: observationFormOptions,
        plugins: observationFormPlugins
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @action.bound submitFormSaveAsDraft(e) {
    this.saveAsDraft = true;
    this.submitEntryAddForm(e);
  }

  @computed get saveUrl() {
    return (
      this.rootStore.urlMicroService('toolbox') +
      `/companies/${this.rootStore.me.company.uuid}/observations`
    );
  }

  @action.bound async submitEntryAddFormSuccess() {
    this.saving = true;

    try {
      const attachments = this.entryForAdd.attachments.models.map(
        attachment => {
          return {
            uuid: attachment.uuid
          };
        }
      );

      const values = this.entryAddForm.values();

      const assignees = await this.parent.createOrEditTasksForAssignees(
        this.entryAddForm.assigneesValues,
        values.status.value
      );

      const payload = {
        projectUuid: this.project.uuid,
        attachments: attachments,
        entityStatus: this.saveAsDraft ? 'DRAFT' : 'ACTIVE',
        type: {
          uuid: values.subType
        },
        category: values.category,
        priority: values.priority?.value,
        status: {
          uuid: values.status?.value
        },
        assignees: assignees,
        location: { uuid: values.location?.uuid },
        description: values.description,
        emails: values.emails?.map(member => member.email),
        ...(this.signature && { signature: this.signature })
      };

      await this.entryForAdd.save(payload, {
        url: this.saveUrl,
        wait: true,
        stripNonRest: false
      });

      callTrack(OBSERVATION_ADDED, {
        project_name: this.project.name,
        project_date: this.date,
        observation_category_name: this.entryForAdd.category,
        has_attachments: this.entryForAdd.hasAttachments
      });

      this.unblockHistory();
      this.parent.sortByLastCreated();

      this.cancelObservationAdd();

      this.setDefaultObservationsType(values);

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Observation created')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();

    if (this.entryAddForm?.tearDownReactions) {
      this.entryAddForm.tearDownReactions();
    }

    this.saveAsDraft = false;
    this.nextUrl = null;
    this.activeModal = null;
    this.entryForAdd = null;
    this.entryAddForm = null;
    this.saving = false;

    this.notifyTeamMember = false;
    this.notifyAssignees = false;
    this.signed = false;
    this.signature = null;
  }

  @action.bound cancelObservationAdd() {
    history.push({
      pathname: `${this.project.viewUrl}/observations`,
      search: this.baseQueryParams
    });
  }

  @action.bound setDefaultObservationsType(values) {
    localStorage.setItem(`defaulObservationType`, values.subType);
    localStorage.setItem(
      `defaulObservationLocation`,
      JSON.stringify(values.location)
    );
    localStorage.setItem(
      `defaulObservationEmails`,
      JSON.stringify(
        values.emails.map(user => {
          return {
            email: user.email,
            name: user.name
          };
        })
      )
    );
  }

  @computed get categoryOptions() {
    return this.parent.categoryOptions;
  }

  @computed get selectedCategoryOption() {
    return this.categoryOptions.find(
      option => option.value === this.entryAddForm.$('category').value
    );
  }

  @computed get title() {
    return t('Add observation');
  }

  @computed get observationTypes() {
    return this.parent.observationTypes;
  }

  @computed get observationTypeOptions() {
    return this.parent.observationTypeOptions;
  }

  @computed get observationFields() {
    return this.parent.observationFields;
  }

  @computed get observationSubTypesOptions() {
    if (!this.entryAddForm.$('type').value) return [];

    return this.observationTypes.models
      .filter(type => {
        return this.entryAddForm.$('type').value.value === type.type;
      })
      .map(type => {
        return {
          value: type.uuid,
          title: type.subType
        };
      });
  }

  @computed get observationStatuses() {
    return this.parent.observationStatuses;
  }

  @computed get observationStatusOptions() {
    return this.parent.observationStatusOptions;
  }

  @computed get priorityOptions() {
    return this.parent.priorityOptions;
  }

  @computed get locationOptions() {
    return this.parent.locationOptions;
  }

  @action.bound
  addAssignee() {
    this.entryAddForm.$('assignees').add({
      assignee: this.entryAddForm.$('assignee').value,
      dueDate: this.entryAddForm.$('dueDate').value
        ? moment(this.entryAddForm.$('dueDate').value).format('YYYY-MM-DD')
        : '',
      action: this.entryAddForm.$('action').value,
      sendNotification: this.entryAddForm.$('sendNotification').value
    });

    this.entryAddForm.$('assignee').clear();
    this.entryAddForm.$('dueDate').clear();
    this.entryAddForm.$('action').clear();
    this.entryAddForm.$('sendNotification').set(true);
  }

  @action.bound
  removeAssignee(assigneePath) {
    this.entryAddForm.$('assignees').del(assigneePath);
  }

  @computed get selectedObservationSubType() {
    return this.observationSubTypesOptions.find(
      type => type.value === this.entryAddForm.$('subType').value
    );
  }

  @action.bound async openObservationSignatureModal(newValue) {
    if (newValue.title === 'Closed') {
      this.observationStatusNewValue = newValue;
      this.observationStatusOldValue = this.entryAddForm.$('status').value;

      this.activeModal = 'observationSign';
      this.entryAddForm.$('status').clear();
      this.entryAddForm.$('status').set(this.observationStatusNewValue);
    } else {
      this.entryAddForm.$('status').set(newValue);
    }
  }

  @action.bound async cancelObservationSignatureModal() {
    this.entryAddForm.$('status').set(this.observationStatusOldValue);
    await this.closeObservationSignatureModal();
  }

  @action.bound async closeObservationSignatureModal() {
    this.observationStatusNewValue = null;
    this.observationStatusOldValue = null;

    await this.hideActiveModal();
  }

  @action.bound
  async updateStatus() {
    this.entryAddForm.$('status').set(this.observationStatusNewValue);

    callTrack(OBSERVATION_STATUS_UPDATE, {
      observation_status: this.observationStatusNewValue
    });

    await this.closeObservationSignatureModal();
  }

  @action.bound signPad(signature) {
    this.signed = true;
    this.signature = signature;
  }

  @action.bound unsignPad() {
    this.signed = false;
    this.signature = null;
  }

  @action.bound toggleNotifyAssignees() {
    this.notifyAssignees = !this.notifyAssignees;
  }

  @action.bound toggleNotifyTeamMembers() {
    this.notifyTeamMembers = !this.notifyTeamMembers;
  }

  @action.bound rejectFileType(fileName) {
    const extension = fileName.split('.').pop();

    this.rootStore.notificationsUI.pushNotification({
      title: `File of type .${extension} is not supported.`,
      snackbar: 'error',
      icon: 'notification'
    });
  }
}
