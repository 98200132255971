import extendedPlugins from 'forms/utils/extendedPlugins';
import MobxReactForm from 'mobx-react-form';
import { t } from 'utils/translate';

const documentNameFormPlugins = extendedPlugins;

const documentNameFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const documentNameFormFields = ['name'];

const documentNameFormRules = {
  name: 'string|required|max:250|renameDocumentName'
};

const documentNameFormLabels = {
  name: t('name')
};

const documentNameFormValues = {};

class DocumentNameForm extends MobxReactForm {}

export {
  DocumentNameForm,
  documentNameFormOptions,
  documentNameFormFields,
  documentNameFormRules,
  documentNameFormLabels,
  documentNameFormPlugins,
  documentNameFormValues
};
