import moment from 'moment-timezone';
import orderBy from 'lodash.orderby';
import groupBy from 'lodash.groupby';
import flatten from 'lodash.flatten';
import uniq from 'lodash.uniq';
import { action, computed, reaction } from 'mobx';
import InsightsUIModel from './InsightsUIModel';
import { t } from 'utils/translate';

import main from 'themes/main';

export default class InsightsChecklistCompletion extends InsightsUIModel {
  constructor(attributes, options) {
    super(attributes, options);

    this.typeClasses = options.typeClasses;

    // React to selection changes
    this.reactToSelection = reaction(
      () => this.params,
      params => {
        if (params.projectUuids || params.projectStatuses) {
          this.fetchStats();
        } else {
          this.cancelRequest();
          this.data.clear();
        }
      },
      { fireImmediately: true }
    );
  }

  url() {
    return `ra/companies/${this.rootStore.me.company.uuid}/insights/checklists/completion`;
  }

  get restAttributes() {
    return ['data', 'type'];
  }

  get restAttributeDefaults() {
    return {
      data: []
    };
  }

  @computed
  get params() {
    const params = {
      startDay: moment(this.insightsUI.startDay)
        .startOf('day')
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss'),
      endDay: moment(this.insightsUI.endDay)
        .endOf('day')
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss'),
      localTimezone: moment.tz.guess(),
      groupBy: this.groupBy,
      typeClasses: this.typeClasses
    };

    switch (this.insightsUI.projectSelection) {
      case 'ALL':
        params.projectStatuses = 'ACTIVE,INACTIVE';
        break;
      case 'ACTIVE':
        params.projectStatuses = 'ACTIVE';
        break;
      default:
        params.projectUuids = this.insightsUI.filteredProjectUuids.join(',');
        break;
    }

    return params;
  }

  @action.bound
  async fetchStats() {
    await this.fetch({
      params: this.params
    });
  }

  @computed
  get groupBy() {
    const diff = moment(this.insightsUI.endDay, 'YYYY-MM-DD').diff(
      moment(this.insightsUI.startDay, 'YYYY-MM-DD'),
      'days'
    );

    if (diff < 90) {
      return 'DAY';
    } else if (diff < 730) {
      return 'MONTH';
    } else {
      return 'YEAR';
    }
  }

  @computed get hasStats() {
    return this.data.some(group => group.data.length > 0);
  }

  @computed get chartSeriesNames() {
    return uniq(
      flatten(this.data?.map(group => group.data.map(item => item.name)))
    );
  }

  @computed get chartConfig() {
    return {
      chart: {
        type: 'column',
        height: 350
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: this.data?.map(item => {
          return item.group;
        }),
        labels: {
          style: {
            fontSize: '10px',
            fontFamily: main.fonts.primary
          }
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: t('Completed checklists'),
          fontSize: '13px',
          fontFamily: main.fonts.primary
        },
        labels: {
          style: {
            fontSize: '10px',
            fontFamily: main.fonts.primary
          }
        }
      },
      legend: {
        itemDistance: 100,
        maxHeight: 105,
        itemStyle: {
          fontSize: '13px',
          fontFamily: main.fonts.primary
        }
      },
      tooltip: {
        pointFormat:
          '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
        shared: true
      },
      plotOptions: {
        column: {
          stacking: 'number'
        }
      },
      series: this.chartSeriesNames?.map(name => {
        return {
          name: name,
          data: this.data.map(group => {
            return group.data.find(item => item.name === name)?.completed || 0;
          })
        };
      })
    };
  }

  @computed get tableData() {
    return this.chartSeriesNames.map(name => {
      return {
        name: name,
        all: flatten(
          this.data
            .map(group => {
              return group.data.find(item => item.name === name);
            })
            .map(item => {
              if (item) {
                return {
                  assigned: item.assigned,
                  completed: item.completed
                };
              } else {
                return { assigned: 0, completed: 0 };
              }
            })
        ),
        workUnits: groupBy(
          orderBy(
            flatten(
              this.data
                .map(group => {
                  return group.data.find(item => item.name === name);
                })
                .map(item => {
                  if (item) {
                    return item.workUnits.slice();
                  } else {
                    return [];
                  }
                })
            ),
            'workUnit'
          ),
          'workUnit'
        )
      };
    });
  }

  getCompletedPercentageColor = completedPercentage => {
    if (completedPercentage < 50) {
      return 'red';
    }

    if (completedPercentage > 50 && completedPercentage <= 75) {
      return 'rakenOrange';
    }

    if (completedPercentage > 75) {
      return 'goGreen';
    }
  };

  getAllTotals = all => {
    const assigned = all.reduce((accumulator, object) => {
      return accumulator + object.assigned;
    }, 0);

    const completed = all.reduce((accumulator, object) => {
      return accumulator + object.completed;
    }, 0);

    const completedPercentage = Math.round((completed / assigned) * 100);

    const completedPercentageColor = this.getCompletedPercentageColor(
      completedPercentage
    );

    return {
      assigned,
      completed,
      completedPercentage,
      completedPercentageColor
    };
  };

  getResposibleTotals = (checklistData, key) => {
    const assigned = checklistData.workUnits[key].reduce(
      (accumulator, object) => {
        return accumulator + object.assigned;
      },
      0
    );

    const completed = checklistData.workUnits[key].reduce(
      (accumulator, object) => {
        return accumulator + object.completed;
      },
      0
    );

    const completedPercentage = Math.round((completed / assigned) * 100);

    const completedPercentageColor = this.getCompletedPercentageColor(
      completedPercentage
    );

    return {
      assigned,
      completed,
      completedPercentage,
      completedPercentageColor
    };
  };
}
