import { action, computed, observable } from 'mobx';
import errorHandler from 'utils/errorHandler';
import UIStore from './UIStore';

export default class CompanySettingsUI extends UIStore {
  @observable loading;

  constructor(options) {
    super(options);

    this.loading = false;
  }

  @computed get company() {
    return this.parent.activeCompany;
  }
  @computed
  get companyAddOns() {
    return this.company?.companyAddOns;
  }

  @action.bound
  async updateCompanyAddOns(event) {
    try {
      await this.company.save({
        companyAddOns: {
          enableIntegrationsForTrial: event.target.checked
        }
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }
}
