import { action } from 'mobx';
import ChecklistTemplateEditUI from './company/checklists/ChecklistTemplateEditUI.js';
import history from 'utils/history';

export default class TimeClockQuestionsTemplateEditUI extends ChecklistTemplateEditUI {
  constructor(options) {
    super(options);

    this.timeClockQuestionsLayout = true;
  }

  @action.bound
  cancelChecklistTemplateEdit() {
    history.push({
      pathname: `/timesheets/time-clock-questions/templates`,
      search: this.baseQueryParams
    });
  }
}
