import { computed } from 'mobx';
import { Model } from 'mobx-mc';

export default class EquipmentMaintenanceSchedule extends Model {
  get restAttributes() {
    return [
      'type',
      'name',
      'step',
      'measurementType',
      'startingValue',
      'nextMilestone',
      'uuid'
    ];
  }

  idAttribute() {
    return 'uuid';
  }

  @computed
  get formValues() {
    return {
      type: this.type,
      name: this.name,
      step: this.step,
      measurementType: this.measurementType,
      startingValue: this.startingValue
    };
  }
}
