import React, { Component } from 'react';
import styled from 'styled-components';

const ToolTipModal = styled.div`
  position: absolute;
  left: 100%;
  top: 50%;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 50px #ccc;
  display: none;
  transform: translate(0, -50%);
  margin: 0 0 0 6px;
  cursor: default;
  z-index: 1;
  :before {
    position: absolute;
    display: block;
    content: '';
    top: 50%;
    left: 0;
    margin: -7px 0 0 -7px;
    transform-origin: 50% 50%;
    transform: rotate(-45deg);
    border-left: 7px solid ${props => props.theme.colors.white};
    border-top: 7px solid ${props => props.theme.colors.white};
    border-right: 7px solid transparent;
    border-bottom: 7px solid transparent;
  }
`;

const ToolTipIcon = styled.span`
  border-radius: 50%;
  border: 1px solid ${props => props.theme.colors[props.color]};
  display: inline-block;
  font-size: ${props => props.fontSize}px;
  width: ${props => props.size}px;
  line-height: ${props => props.size}px;
  text-align: center;
  color: ${props => props.theme.color};
  margin-left: 5px;
`;

const ToolTipWrapper = styled.span`
  position: relative;
  &:hover {
    ${ToolTipModal} {
      display: block;
    }
    ${ToolTipIcon} {
      background: ${props => props.theme.colors[props.color]};
      color: ${props => props.theme.colors.white};
    }
  }
`;

export default class ToolTip extends Component {
  static propTypes = {};

  static defaultProps = {
    fontSize: 11,
    size: 15,
    color: 'charcoalGrey'
  };
  render() {
    const { children, size, color, fontSize } = this.props;
    return (
      <ToolTipWrapper color={color}>
        <ToolTipIcon fontSize={fontSize} size={size} color={color}>
          {'i'}
        </ToolTipIcon>

        <ToolTipModal>{children}</ToolTipModal>
      </ToolTipWrapper>
    );
  }
}
