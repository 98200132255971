import omit from 'lodash.omit';
import { Model } from 'mobx-mc';
import Attachments from 'stores/collections/Attachments';
import Attachment from 'stores/models/Attachment';
import ChecklistObservations from 'stores/collections/ChecklistObservations';
import { action, computed } from 'mobx';
import getFilePreviewIcon from 'utils/getFilePreviewIcon';
import fileToBase64 from 'utils/fileToBase64';
import bytesToSize from 'utils/bytesToSize';
import { t } from 'utils/translate';
import orderBy from 'lodash.orderby';

export default class ChecklistResponse extends Model {
  url() {
    return `${this.rootStore.urlMicroService('toolbox')}/companies/${
      this.rootStore.me.company.uuid
    }/checklists/${this.relatedChecklist.uuid}/response/${this.uuid}`;
  }

  get restAttributes() {
    return [
      'uuid',
      'value',
      'note',
      'choices',
      'responseSignature',
      'observations'
    ];
  }

  idAttribute() {
    return 'uuid';
  }

  get restAttributeDefaults() {
    return {
      value: '',
      note: '',
      choices: [],
      responseSignature: {
        signature: '',
        signedBy: '',
        signedTimestamp: 0
      },
      observations: []
    };
  }

  @action.bound parse(attributes) {
    this.parseAttachments(attributes);
    this.parseObservations(attributes);

    return {
      ...omit(attributes, 'checklistResponseAttachments', 'observations')
    };
  }

  @action.bound parseAttachments(attributes) {
    if (this.attachments) {
      this.attachments.reset(attributes.checklistResponseAttachments);
    } else {
      this.attachments = new Attachments(
        attributes.checklistResponseAttachments,
        {
          parent: this,
          rootStore: this.rootStore
        }
      );
    }
  }

  @action.bound parseObservations(attributes) {
    if (this.observations) {
      this.observations.reset(attributes.observations);
    } else {
      this.observations = new ChecklistObservations(attributes.observations, {
        parent: this,
        rootStore: this.rootStore
      });
    }
  }

  @computed get hasObservations() {
    return this.observations.hasModels;
  }

  @action.bound addObservation(observationResponse) {
    this.observations.add(observationResponse);
  }

  @action.bound updateObservation(observationResponse) {
    this.observations.add(observationResponse, {
      update: true,
      merge: true
    });
  }

  @action.bound removeObservation(uuid) {
    const removedObservation = this.observations.get(uuid);

    if (removedObservation) {
      this.observations.remove(removedObservation);
    }
  }

  @computed get hasAttachments() {
    return this.attachments.hasModels;
  }

  @computed get formValues() {
    const values = {
      uuid: this.uniqueId,
      value: this.value,
      note: this.note
    };

    if (this.collection.parent.responseType === 'MULTI_CHOICE') {
      values.choices = this.choicesOrderedByPosition;
    }

    if (this.collection.parent.responseType === 'SIGNATURE') {
      values.responseSignature = Object.assign(
        this.restAttributeDefaults.responseSignature,
        this.responseSignature
      );
    }

    return values;
  }

  @computed get relatedChecklist() {
    return this.collection.parent.relatedChecklist;
  }

  @computed get questionUuid() {
    return this.collection.parent.uuid;
  }

  @computed get observationUuids() {
    return this.observations.models.map(observation => ({
      uuid: observation.uuid,
      label: observation.label
    }));
  }

  @action async uploadAttachment(uploadItem) {
    const file = uploadItem.file;

    if (file.size > 62914560) {
      this.rootStore.notificationsUI.pushNotification({
        title: `${t('File is too big ')} (${bytesToSize(file.size)}). ${t(
          'Limit is 60MB.'
        )}`,
        showUndo: false
      });

      return;
    }

    file.preview = await fileToBase64(file);

    const filePreviewIcon = getFilePreviewIcon(this.rootStore.assetsURL, file);

    // Create a temporrary model to display the preview icon
    // in the carousel
    const previewAttachment = new Attachment(
      {
        thumbUrl: filePreviewIcon
      },
      {
        rootStore: this.rootStore
      }
    );

    // Add to the collection will remove after full upload finishes
    this.attachments.add(previewAttachment);

    try {
      await this.attachments.uploadVersionTwo({
        file: file,
        previewAttachment: previewAttachment,
        uploadUrl: this.relatedChecklist?.uuid
          ? `${this.collection.parent.url()}/attachments`
          : null,
        progressCallback: percentCompleted => {
          // If we wanted to show a progress bar on the attachment
          // we can use previewAttachment.uploadProgress.
          previewAttachment.setUploadProgress(percentCompleted);
        }
      });
    } catch (error) {
      console.error(error);
    } finally {
      // Remove the preview model now that the new one is ready
      this.attachments.remove(previewAttachment);
    }
  }

  @computed get choicesOrderedByPosition() {
    return this.choices && orderBy(this.choices, 'position', 'asc');
  }

  @computed get selectedChoices() {
    return (
      this.choicesOrderedByPosition &&
      this.choicesOrderedByPosition.filter(choice => choice.selected)
    );
  }
}
