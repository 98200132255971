import { Model } from 'mobx-mc';
import { computed, action } from 'mobx';
import omit from 'lodash.omit';
import CostCode from './CostCode';

export default class CostCodeEntry extends Model {
  get restAttributes() {
    return ['regularTime', 'doubleTime', 'overTime', 'costCode'];
  }

  @action.bound
  parse(attributes) {
    this.parseCostCode(attributes);

    return {
      ...omit(attributes, ['costCode'])
    };
  }

  parseCostCode(attributes) {
    if (attributes.costCode) {
      if (attributes.costCode.deleted) {
        this.costCode = new CostCode(attributes.costCode, {
          rootStore: this.rootStore
        });
      } else {
        this.costCode = this.rootStore.costCodes.getOrAdd(attributes.costCode);
      }
    }
  }

  @computed
  get division() {
    return this.costCode.division;
  }

  @computed
  get code() {
    return this.costCode.code;
  }

  @computed get description() {
    return this.costCode.description;
  }

  @computed
  get name() {
    return `${this.code} ${this.description}`;
  }

  @computed
  get displayCode() {
    return this.rootStore.me && this.rootStore.me.showTimeCardsDivision
      ? `${this.division}-${this.code}`
      : this.code;
  }
}
