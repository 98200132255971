import { Model } from 'mobx-mc';
import { computed } from 'mobx';

export default class CertificationType extends Model {
  get restAttributes() {
    return ['emails', 'year', 'exportType', 'skipIncompleteRecordValidation'];
  }

  get urlRoot() {
    return `${this.rootStore.appConfig.safety_api_url}/companies/${this.rootStore.me.company.uuid}/incident-reports/export`;
  }

  @computed
  get formValues() {
    return {
      emails: this.emails,
      year: this.year,
      exportType: this.exportType,
      skipIncompleteRecordValidation: this.skipIncompleteRecordValidation
    };
  }
}
