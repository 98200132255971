import request from 'axios';
import debounce from 'lodash.debounce';
import difference from 'lodash.difference';
import { action, observable } from 'mobx';
import UIStore from './UIStore';
import errorHandler from 'utils/errorHandler';

export default class EmailUI extends UIStore {
  @observable activeField;

  constructor(options) {
    super(options);

    this.activeField = null;
    this.companyEmails = observable([]);
    this.fetchEmails = debounce(this.fetchEmails, 250);
  }

  @action.bound
  clearCompanyEmails() {
    this.companyEmails.clear();
    this.activeField = null;
  }

  @action.bound
  fetchEmails(query = '', selectedEmails = [], activeField, callBack) {
    callBack && callBack();

    this.activeField = activeField;

    this.companyEmails.clear();

    if (query.length < 1) {
      return;
    }

    return request
      .get('/ra/company/recipients?mn=full&mc=full&offset=0&limit=10', {
        params: {
          query: query
        }
      })
      .then(response => {
        if (response.data) {
          this.companyEmails.replace(
            difference(response.data.collection, selectedEmails)
          );
        }
      })
      .catch(error => {
        errorHandler(error, this.rootStore.notificationsUI.pushError);
      });
  }

  @action.bound
  handleDeleteRecipient(email) {
    request
      .delete(`/ra/company/recipients/${encodeURIComponent(email)}`)
      .then(response => {
        this.companyEmails.remove(email);
      })
      .catch(error => {
        errorHandler(error, this.rootStore.notificationsUI.pushError);
        console.log(error);
      });
  }
}
