import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';
import trimObject from 'utils/trimObject';

const settingsWatermarkFormPlugins = extendedPlugins;

const settingsWatermarkFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false,
  retrieveOnlyEnabledFields: false
};

const settingsWatermarkFormFields = [
  'enableWatermark',
  'watermarkIncludeCompanyLogo',
  'watermarkIncludeCompanyName',
  'watermarkIncludeProjectName',
  'watermarkIncludeTimestamp',
  'watermarkIncludeGPSCoords',
  'watermarkIncludeAutoGenLocation',
  'watermarkAlignment'
];

const settingsWatermarkFormRules = {
  enableWatermark: 'boolean',
  watermarkIncludeCompanyLogo: 'boolean',
  watermarkIncludeCompanyName: 'boolean',
  watermarkIncludeProjectName: 'boolean',
  watermarkIncludeTimestamp: 'boolean',
  watermarkIncludeGPSCoords: 'boolean',
  watermarkIncludeAutoGenLocation: 'boolean',
  watermarkAlignment: 'string'
};

const settingsWatermarkFormDisabled = {};

const settingsWatermarkFormLabels = {};

const settingsWatermarkFormValues = {
  enableWatermark: false,
  watermarkIncludeCompanyLogo: false,
  watermarkIncludeCompanyName: false,
  watermarkIncludeProjectName: false,
  watermarkIncludeTimestamp: false,
  watermarkIncludeGPSCoords: false,
  watermarkIncludeAutoGenLocation: false,
  watermarkAlignment: 'BOTTOM_LEFT'
};

class SettingsWatermarkForm extends MobxReactForm {
  trimmedValues() {
    return trimObject(this.values());
  }
}

export {
  SettingsWatermarkForm,
  settingsWatermarkFormOptions,
  settingsWatermarkFormFields,
  settingsWatermarkFormRules,
  settingsWatermarkFormValues,
  settingsWatermarkFormLabels,
  settingsWatermarkFormPlugins,
  settingsWatermarkFormDisabled
};
