import MobxReactForm from 'mobx-react-form';
import extendedPlugins from 'forms/utils/extendedPlugins';

const projectTemplatePayTypesFormPlugins = extendedPlugins;

const projectTemplatePayTypesFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false,
  retrieveOnlyEnabledFields: false
};

const projectTemplatePayTypesFormFields = ['hiddenPayTypes'];

const projectTemplatePayTypesFormLabels = {};

const projectTemplatePayTypesFormRules = {
  hiddenPayTypes: 'array'
};

const projectTemplatePayTypesFormValues = {
  hiddenPayTypes: []
};

class ProjectTemplatePayTypesForm extends MobxReactForm {}

export {
  ProjectTemplatePayTypesForm,
  projectTemplatePayTypesFormOptions,
  projectTemplatePayTypesFormFields,
  projectTemplatePayTypesFormRules,
  projectTemplatePayTypesFormValues,
  projectTemplatePayTypesFormPlugins,
  projectTemplatePayTypesFormLabels
};
