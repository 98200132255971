import React from 'react';
import styled from 'styled-components';
import { Icon } from '.';

const Wrapper = styled.div`
  display: inline-block;
  bottom: -2px;
  height: 2rem;
  margin-left: 5px;
  position: relative;
  width: 2rem;
  text-align: center;
  opacity: ${props => (props.hideSortable ? 0 : 1)};
  svg {
    fill: ${props =>
      props.sorted && !props.borderDisabled
        ? props.theme.colors.charcoalGrey
        : props.theme.colors.lightGrey3};
  }
`;

const TableSortButton = ({
  sorted,
  direction,
  absolute,
  hideSortable,
  borderDisabled
}) => (
  <Wrapper
    sorted={sorted}
    absolute={absolute}
    hideSortable={hideSortable}
    borderDisabled={borderDisabled}
  >
    <Icon
      kind={sorted && direction === 'asc' ? 'collapse' : 'expand'}
      size={15}
    />
  </Wrapper>
);

export default TableSortButton;
