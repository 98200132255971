import debounce from 'lodash.debounce';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

import CircleButton from './CircleButton';

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

const FadeLeft = styled.div`
  background: linear-gradient(
    to right,
    ${props => transparentize(0, props.theme.colors[props.background])} 0%,
    ${props => transparentize(0, props.theme.colors[props.background])} 10%,
    ${props => transparentize(10, props.theme.colors[props.background])} 100%
  );
  opacity: ${props => (props.canMoveLeft && props.fadeEdges ? 1 : 0)};
  transition: opacity 0.15s ease-in-out;
  height: 100%;
  left: 0;
  position: absolute;
  pointer-events: ${props =>
    props.canMoveLeft && props.fadeEdges ? 'all' : 'none'};
  top: 0;
  width: 10%;
  z-index: 20;
  ${props =>
    props.tableRowHoverBackground &&
    css`
      tr:hover & {
        background: linear-gradient(
          to right,
          ${props => transparentize(0, props.theme.colors.lightGrey2)} 0%,
          ${props => transparentize(0, props.theme.colors.lightGrey2)} 10%,
          ${props => transparentize(10, props.theme.colors.lightGrey2)} 100%
        );
      }
    `};
`;

const FadeRight = styled.div`
  background: linear-gradient(
    to right,
    ${props => transparentize(10, props.theme.colors[props.background])} 0%,
    ${props => transparentize(0, props.theme.colors[props.background])} 90%,
    ${props => transparentize(0, props.theme.colors[props.background])} 100%
  );

  opacity: ${props => (props.canMoveRight && props.fadeEdges ? 1 : 0)};
  transition: opacity 0.15s ease-in-out;
  height: 100%;
  position: absolute;
  pointer-events: ${props =>
    props.canMoveRight && props.fadeEdges ? 'all' : 'none'};
  right: 0;
  top: 0;
  z-index: 20;
  width: 10%;
  ${props =>
    props.tableRowHoverBackground &&
    css`
      tr:hover & {
        background: linear-gradient(
          to right,
          ${props => transparentize(10, props.theme.colors.lightGrey2)} 0%,
          ${props => transparentize(0, props.theme.colors.lightGrey2)} 90%,
          ${props => transparentize(0, props.theme.colors.lightGrey2)} 100%
        );
      }
    `};
`;

const Content = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  transform: translate3d(0, 0, 0);
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const LeftControl = styled(CircleButton)`
  opacity: 1;
  position: absolute;
  left: 1px;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.25s ease-in-out;
  z-index: 21;
`;

const RightControl = styled(CircleButton)`
  opacity: 1;
  position: absolute;
  right: 1px;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.25s ease-in-out;
  z-index: 21;
`;

export default class HorizontalScrollingContainer extends Component {
  static propTypes = {
    withButtons: PropTypes.bool,
    buttonSize: PropTypes.string,
    fadeEdges: PropTypes.bool,
    scrollOnEdges: PropTypes.bool,
    background: PropTypes.string,
    onNearRightEdge: PropTypes.func,
    itemWidth: PropTypes.number,
    disableMouseWheel: PropTypes.bool,
    backgroundHover: PropTypes.bool
  };

  static defaultProps = {
    withButtons: false,
    buttonSize: 'small',
    fadeEdges: false,
    scrollOnEdges: false,
    background: 'white',
    disableMouseWheel: false,
    tableRowHoverBackground: true
  };

  state = {
    canMoveRight: false,
    canMoveLeft: false,
    speed: 10
  };

  constructor(props) {
    super(props);
    this.checkWidth = debounce(this.checkWidth, 250);
  }

  componentDidMount() {
    this.mounted = true;
    window.addEventListener('resize', this.checkWidth);

    this.content.addEventListener('scroll', this.checkScrollPosition, {
      passive: true
    });

    this.content.addEventListener('mousewheel', this.handleMouseWheel, {
      passive: true
    });

    this.checkWidth();
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('resize', this.checkWidth);
    this.content.removeEventListener('scroll', this.checkScrollPosition);
    this.content.removeEventListener('mousewheel', this.handleMouseWheel);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.children !== nextProps.children) {
      this.checkWidth();
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (
      (!nextState.canMoveRight && this.state.canMoveRight) ||
      (!nextState.canMoveLeft && this.state.canMoveLeft)
    ) {
      this.setState({
        speed: 10
      });
    }
  }

  handleMouseWheel = e => {
    if (e.deltaX !== 0 || this.props.disableMouseWheel) return;

    if (e.deltaY < 0) {
      if (this.state.canMoveLeft) {
        e.preventDefault();
        e.stopPropagation();

        this.handleMoveLeft();
      }
    }
    if (e.deltaY > 0) {
      if (this.state.canMoveRight) {
        e.preventDefault();
        e.stopPropagation();
        this.handleMoveRight();
      }
    }
  };

  checkScrollPosition = () => {
    let canMoveLeft;
    let canMoveRight;

    // Check if carousel is almost at the end.
    if (
      this.props.onNearRightEdge &&
      this.content.scrollLeft + this.props.itemWidth >=
        this.content.scrollWidth - this.wrapper.clientWidth
    ) {
      this.props.onNearRightEdge();
    }

    if (
      this.content.scrollLeft >=
      this.content.scrollWidth - this.wrapper.clientWidth
    ) {
      canMoveRight = false;
    } else {
      canMoveRight = true;
    }

    if (this.content.scrollLeft > 0) {
      canMoveLeft = true;
    } else {
      canMoveLeft = false;
    }

    this.setState({
      canMoveLeft: canMoveLeft,
      canMoveRight: canMoveRight
    });
  };

  checkWidth = () => {
    if (!this.mounted) return;

    if (this.content.scrollWidth > this.wrapper.clientWidth) {
      this.setState({
        canMoveRight: true
      });
    } else {
      this.setState({
        canMoveRight: false
      });
    }
  };

  handleMoveLeft = () => {
    if (!this.mounted) return;

    if (this.content.scrollLeft <= 0) {
      this.setState({
        canMoveLeft: false
      });

      cancelAnimationFrame(this.scrollLeftAnimation);

      return;
    }

    this.content.scrollLeft -= this.state.speed;

    this.scrollLeftAnimation = requestAnimationFrame(this.handleMoveLeft);
  };

  handleMoveRight = () => {
    if (!this.mounted) return;

    if (
      this.content.scrollLeft >=
      this.content.scrollWidth - this.wrapper.clientWidth
    ) {
      this.setState({
        canMoveRight: false
      });

      cancelAnimationFrame(this.scrollRightAnimation);

      return;
    }

    this.content.scrollLeft += this.state.speed;

    this.scrollRightAnimation = requestAnimationFrame(this.handleMoveRight);
  };

  handleCancelScroll = () => {
    this.handleCancelSpeedUp();
    cancelAnimationFrame(this.scrollLeftAnimation);
    cancelAnimationFrame(this.scrollRightAnimation);
  };

  handleSpeedUp = () => {
    this.setState({
      speed: 20
    });
  };

  handleCancelSpeedUp = () => {
    this.setState({
      speed: 10
    });
  };

  render() {
    const {
      withButtons,
      fadeEdges,
      scrollOnEdges,
      children,
      buttonSize,
      background,
      tableRowHoverBackground
    } = this.props;

    return (
      <Wrapper
        ref={ref => (this.wrapper = ref)}
        canMoveRight={this.state.canMoveRight}
        canMoveLeft={this.state.canMoveLeft}
        fadeEdges={fadeEdges}
      >
        <FadeLeft
          canMoveLeft={this.state.canMoveLeft}
          fadeEdges={fadeEdges}
          background={background}
          tableRowHoverBackground={tableRowHoverBackground}
          {...(scrollOnEdges && {
            onMouseEnter: this.handleMoveLeft,
            onMouseLeave: this.handleCancelScroll
          })}
        />

        {withButtons && this.state.canMoveLeft && (
          <LeftControl
            icon="left-arrow"
            onMouseEnter={this.handleMoveLeft}
            onMouseLeave={this.handleCancelScroll}
            onMouseDown={this.handleSpeedUp}
            onMouseUp={this.handleCancelSpeedUp}
            size={buttonSize}
            solid
          />
        )}

        <Content ref={ref => (this.content = ref)}>{children}</Content>

        {withButtons && this.state.canMoveRight && (
          <RightControl
            icon="right-arrow"
            onMouseEnter={this.handleMoveRight}
            onMouseLeave={this.handleCancelScroll}
            onMouseDown={this.handleSpeedUp}
            onMouseUp={this.handleCancelSpeedUp}
            size={buttonSize}
            solid
          />
        )}

        <FadeRight
          canMoveRight={this.state.canMoveRight}
          fadeEdges={fadeEdges}
          background={background}
          tableRowHoverBackground={tableRowHoverBackground}
          {...(scrollOnEdges && {
            onMouseEnter: this.handleMoveRight,
            onMouseLeave: this.handleCancelScroll
          })}
        />
      </Wrapper>
    );
  }
}
