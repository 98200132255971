import debounce from 'lodash.debounce';
import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import { action, observable, computed, reaction, runInAction } from 'mobx';

import SettingsChildUI from './SettingsChildUI';
import SettingsMaterialAddUI from './SettingsMaterialAddUI';
import SettingsMaterialEditUI from './SettingsMaterialEditUI';
import SettingsMaterialsImportUI from './SettingsMaterialsImportUI';

import Materials from 'stores/collections/Materials';

import { BASE_DEBOUNCE } from 'fixtures/constants';

import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import history from 'utils/history';

import {
  UnitForm,
  unitFormOptions,
  unitFormFields,
  unitFormRules,
  unitFormPlugins,
  unitFormLabels
} from 'forms/unit';

export default class SettingsMaterialsUI extends SettingsChildUI {
  @observable searchQuery;
  @observable sortField;
  @observable sortDirection;
  @observable pageSize;
  @observable page;
  @observable loading;
  @observable selectedMaterial;

  @observable showInactiveCompanyLevelMaterials;
  @observable selectedBulkMaterialAction;

  @observable unitForm;

  constructor(options) {
    super(options);

    this.searchQuery = '';
    this.loading = true;
    this.sortField = 'division,code';
    this.sortDirection = 'asc';
    this.page = 1;
    this.pageSize = 20;
    this.showInactiveCompanyLevelMaterials = false;

    this.selectedMaterials = observable([]);
    this.selectedMaterial = null;
    this.selectedBulkMaterialAction = null;

    this.unitForm = null;

    this.paginatedMaterials = new Materials(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.materialAddUI = new SettingsMaterialAddUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.materialEditUI = new SettingsMaterialEditUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.materialsImportUI = new SettingsMaterialsImportUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchMaterialsDebounced = debounce(this.fetchMaterials, BASE_DEBOUNCE);
  }

  // TODO.  Make this a new instance in the store post Project Settings 2.0 rollout
  // Cannot remove rootStore.units just yet.
  @computed get units() {
    return this.rootStore.units;
  }

  @computed get materials() {
    return this.paginatedMaterials;
  }

  @computed get title() {
    return t('Materials');
  }

  @computed get hasWriteAccess() {
    return this.authorization.canCUDMaterials;
  }

  @computed get headerTitle() {
    return t('Manage available production quantities to be tracked');
  }

  @action.bound setup() {
    this.setupReactions();
    this.fetchMaterials();
    this.fetchUnits();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchMaterialsDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      sortField: this.sortField,
      query: this.searchQuery,
      sortDirection: this.sortDirection,
      projectUuids: this.projectUuid,
      isDefault: Boolean(this.showInactiveCompanyLevelMaterials),
      includeMaterials: 'false'
    };
  }

  @computed get hasMaterials() {
    return this.materials.hasModels;
  }

  @action.bound async fetchMaterials() {
    if (!this.projectUuid) return;

    this.materials.cancelRequest();
    this.materials.reset();

    try {
      await this.materials.fetch({
        params: pickBy(this.params, identity)
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.materials.totalElements / this.pageSize);
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    this.selectedMaterials.clear();
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound toggleShowInactiveCompanyLevelMaterials() {
    this.showInactiveCompanyLevelMaterials = !this
      .showInactiveCompanyLevelMaterials;
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.materials.clear();
    this.selectedMaterials.clear();
    this.searchQuery = '';
    this.page = 1;
    this.loading = true;
    this.saving = false;
    this.sortField = 'division,code';
    this.sortDirection = 'asc';
    this.selectedMaterial = null;
    this.showInactiveCompanyLevelMaterials = false;
    this.selectedBulkMaterialAction = null;
    this.unitForm = null;
  }

  @computed get hasSelectedMaterials() {
    return this.selectedMaterials.length > 0;
  }

  @action.bound
  toggleSelectMaterial(material) {
    if (this.selectedMaterials.find(uuid => uuid === material.uuid)) {
      this.selectedMaterials.remove(material.uuid);
    } else {
      this.selectedMaterials.push(material.uuid);
    }
  }

  @computed
  get allMaterialsSelected() {
    return (
      this.hasMaterials &&
      this.selectedMaterials.length === this.materials.length
    );
  }

  @action.bound
  toggleSelectAllMaterials() {
    if (this.allMaterialsSelected) {
      this.selectedMaterials.clear();
    } else {
      this.selectedMaterials.replace(
        this.materials.models.map(material => material.uuid)
      );
    }
  }

  @action.bound sortByLastCreated() {
    this.sortField = 'createdTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchMaterialsDebounced();
  }

  @action.bound sortByLastUpdated() {
    this.sortField = 'updatedTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchMaterialsDebounced();
  }

  @action.bound refetchMaterials() {
    this.loading = true;

    if (!this.hasMaterials) {
      this.setPage(null, 1);
      this.fetchMaterials();
    } else {
      this.fetchMaterials();
    }
  }

  @action.bound async deleteMaterial(material) {
    await this.authorization.checkFeatureAccess('CUDMaterials');

    this.selectedMaterial = material;
    this.showModal('DeleteModal');
  }

  @action.bound async cancelDeleteMaterial() {
    await this.hideActiveModal();

    this.selectedMaterial = null;
  }

  @action.bound async confirmDeleteMaterial() {
    this.saving = true;

    try {
      if (this.selectedMaterial.isDefault) {
        await this.selectedMaterial.destroy({ wait: true });
      } else {
        await this.selectedMaterial.destroy({
          wait: true,
          url: `${this.selectedMaterial.url()}/projects/${this.projectUuid}`
        });
      }

      this.refetchMaterials();

      await this.hideActiveModal();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Material deleted')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound async toggleCompanyLevelMaterial(material) {
    await this.authorization.checkFeatureAccess('CUDMaterials');

    if (material.getProjectByUuid(this.project.uuid)) {
      material.removeProjectByUuid(this.project.uuid);

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Material disabled')
      });
    } else {
      material.addProjectByUuid(this.project.uuid);

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Material enabled')
      });
    }
  }

  @action.bound async addMaterial() {
    await this.authorization.checkFeatureAccess('CUDMaterials');

    history.push({
      pathname: `${this.project.viewUrl}/settings/production-tracking/materials/add`,
      search: this.baseQueryParams
    });
  }

  @action.bound async editMaterial(material) {
    await this.authorization.checkFeatureAccess('CUDMaterials');

    history.push({
      pathname: `${this.project.viewUrl}/settings/production-tracking/materials/${material.uuid}`,
      search: this.baseQueryParams
    });
  }

  @action.bound async importMaterials() {
    await this.authorization.checkFeatureAccess('CUDMaterials');

    history.push({
      pathname: `${this.project.viewUrl}/settings/production-tracking/materials/import`,
      search: this.baseQueryParams
    });
  }

  @computed get showEmptyState() {
    return !this.loading && !this.hasMaterials;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  // Bulk Actions
  @computed get bulkMaterialActions() {
    return [
      {
        title: t('Enable'),
        onClick: () => {
          this.bulkMaterialAction('ENABLE');
        }
      },
      {
        title: t('Disable'),
        onClick: () => {
          this.bulkMaterialAction('DISABLE');
        }
      },
      {
        title: t('Delete'),
        onClick: () => {
          this.bulkMaterialAction('DELETE');
        }
      }
    ];
  }

  @action.bound async bulkMaterialAction(action) {
    await this.authorization.checkFeatureAccess('CUDMaterials');

    this.selectedBulkMaterialAction = action;
    this.showModal('MaterialBulkActionModal');
  }

  @action.bound async cancelBulkMaterialAction() {
    await this.hideActiveModal();
    this.selectedBulkMaterialAction = null;
  }

  @computed get bulkMaterialActionTitle() {
    if (!this.selectedBulkMaterialAction) return null;

    switch (this.selectedBulkMaterialAction) {
      case 'ENABLE':
        return t(`Enable selected materials?`);
      case 'DISABLE':
        return t(`Disable selected materials?`);
      default:
        return t(`Delete selected materials?`);
    }
  }

  @computed get bulkMaterialActionText() {
    if (!this.selectedBulkMaterialAction) return null;

    switch (this.selectedBulkMaterialAction) {
      case 'ENABLE':
        return t(
          `Would you like to enable the selected company materials? Selected project materials will not be affected.`
        );
      case 'DISABLE':
        return t(
          `Would you like to disable the selected company materials? Selected project materials will not be affected.`
        );
      default:
        return 'Would you like to delete the selected materials? Selected company materials will be disabled.';
    }
  }

  @computed get bulkMaterialActionButtonText() {
    switch (this.selectedBulkMaterialAction) {
      case 'DISABLE':
        return this.saving ? t('Disabling...') : t('Disable');
      case 'ENABLE':
        return this.saving ? t('Enabling...') : t('Enable');
      default:
        return this.saving ? t('Deleting...') : t('Delete');
    }
  }

  @computed get bulkMaterialActionButtonColor() {
    switch (this.selectedBulkMaterialAction) {
      case 'DELETE':
        return 'red';
      default:
        return 'orange';
    }
  }

  @computed get bulkMaterialActionNotificationText() {
    switch (this.selectedBulkMaterialAction) {
      case 'DELETE':
        return t(`Materials deleted`);
      case 'ENABLE':
        return t(`Materials enabled`);
      case 'DISABLE':
        return t(`Materials disabled`);
      default:
        return '';
    }
  }

  @action.bound async confirmBulkMaterialAction() {
    this.clearValidationDetails();
    this.saving = true;

    try {
      switch (this.selectedBulkMaterialAction) {
        case 'DELETE':
          await this.bulkDelete();
          break;
        case 'ENABLE':
          await this.bulkEnable();
          break;
        case 'DISABLE':
          await this.bulkDisable();
          break;
      }

      this.refetchMaterials();

      await this.hideActiveModal();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: this.bulkMaterialActionNotificationText
      });

      this.selectedMaterials.clear();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound async bulkDelete() {
    const materials = this.materials.models.filter(material =>
      this.selectedMaterials.includes(material.uuid)
    );

    const promises = [];

    materials.forEach(material => {
      if (material.isDefault || material.hasMultipleProjects) {
        promises.push(material.removeProjectByUuid(this.project.uuid));
      } else {
        // TODO: Use bulk delete endpoint when available.
        promises.push(
          material.destroy({
            wait: true
          })
        );
      }
    });

    return Promise.all(promises);
  }

  @action.bound async bulkEnable() {
    const materials = this.materials.models.filter(material => {
      return (
        this.selectedMaterials.includes(material.uuid) && material.isDefault
      );
    });

    const promises = [];

    materials.forEach(material => {
      promises.push(material.addProjectByUuid(this.project.uuid));
    });

    return Promise.all(promises);
  }

  @action.bound async bulkDisable() {
    const materials = this.materials.models.filter(material => {
      return (
        this.selectedMaterials.includes(material.uuid) && material.isDefault
      );
    });

    const promises = [];

    materials.forEach(material => {
      promises.push(material.removeProjectByUuid(this.project.uuid));
    });

    return Promise.all(promises);
  }

  @action.bound fetchUnits() {
    this.units.fetch({
      params: {
        limit: 10000
      }
    });
  }

  @computed get unitOptions() {
    return this.units.models.map(unit => {
      return {
        value: unit.uuid,
        name: unit.name
      };
    });
  }

  @action.bound addNewUnit() {
    this.unitForm = new UnitForm(
      {
        fields: unitFormFields,
        rules: Object.assign(unitFormRules, {
          name: `string|required|not_in:${this.units.models
            .map(unit => unit.name)
            .join(',')}`
        }),
        labels: unitFormLabels
      },
      {
        options: unitFormOptions,
        plugins: unitFormPlugins
      }
    );

    this.showModal('SettingsMaterialsUnitModal');
  }

  @action.bound submitUnitForm() {
    event.preventDefault();
    event.stopPropagation();

    this.unitForm.submit({
      onSuccess: this.submitUnitFormSuccess,
      onError: this.submitUnitFormError
    });
  }

  @action.bound async submitUnitFormSuccess() {
    this.clearValidationDetails();
    this.saving = true;

    try {
      const unit = await this.units.create(this.unitForm.values(), {
        wait: true
      });

      this.updateMaterialForm(unit);
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitUnitFormError() {
    console.error(this.unitForm.errors());
  }

  @action.bound async cancelUnitAdd() {
    await this.hideActiveModal();

    this.unitForm = null;
  }

  @action.bound updateMaterialForm(unit) {
    const form =
      this.materialAddUI.entryAddForm || this.materialEditUI.entryEditForm;

    form.$('unit').set(unit.uuid);

    this.cancelUnitAdd();
  }
}
