import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from '../utils/mediaTemplate';

const ButtonGroup = styled.div`
  border: 1px solid ${props => props.theme.colors.lightGrey3};
  border-radius: 2rem;
  height: ${props => (props.size === 'large' ? '40px' : '26px')};
  display: flex;
  width: auto;
  > button {
    flex: 1 1 auto;
    font-size: ${props => (props.size === 'large' ? '18px' : '1.4rem')};
    height: ${props => (props.size === 'large' ? '40px' : '26px')};
    margin: 0;
    ${media.tablet`
      flex: ${props => (props.justified ? '1 1 auto' : '0 1 auto')};
    `};
  }
  > button:first-child {
    border-bottom-left-radius: 2rem;
    border-top-left-radius: 2rem;
  }
  > button:last-child {
    border-bottom-right-radius: 2rem;
    border-top-right-radius: 2rem;
  }
  ${media.tablet`
    display: ${props => (props.justified ? 'flex' : 'inline-flex')};
  `};
`;

ButtonGroup.propTypes = {
  justified: PropTypes.bool.isRequired,
  size: PropTypes.string
};

ButtonGroup.defaultProps = {
  justified: false
};

export default ButtonGroup;
