import { computed } from 'mobx';
import { RakenCollection } from '../lib';
import Country from '../models/Country';

export default class Countries extends RakenCollection {
  model() {
    return Country;
  }

  url() {
    return `ra/countries`;
  }

  @computed get asOptions() {
    return this.models.map(model => {
      return {
        value: model.code,
        name: model.displayName
      };
    });
  }
}
