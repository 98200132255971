import { observable, action, computed, reaction } from 'mobx';
import UIStore from '../../UIStore';
import debounce from 'lodash.debounce';

import { BASE_DEBOUNCE } from 'fixtures/constants';

import EquipmentMaintenanceSchedules from 'stores/collections/EquipmentMaintenanceSchedules';
import EquipmentMaintenanceSchedule from 'stores/models/EquipmentMaintenanceSchedule';

import alertErrorHandler from 'utils/alertErrorHandler';

import {
  EquipmentMaintenanceForm,
  equipmentMaintenanceFormRules,
  equipmentMaintenanceFormFields,
  equipmentMaintenanceFormLabels,
  equipmentMaintenanceFormOptions,
  equipmentMaintenanceFormPlugins
} from 'forms/equipment/equipmentMaintenance';

import { t } from 'utils/translate';

export default class EquipmentMaintenanceUI extends UIStore {
  @observable sortField;
  @observable sortDirection;
  @observable pageSize;
  @observable page;
  @observable loading;
  @observable form;
  @observable entry;
  @observable saving;

  constructor(options) {
    super(options);

    this.loading = true;

    this.sortField = 'createdTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.pageSize = 20;

    this.form = null;
    this.entry = null;

    this.saving = false;

    // Equipment maintenance collection
    this.equipmentMaintenanceSchedules = new EquipmentMaintenanceSchedules(
      null,
      {
        parent: this,
        rootStore: this.rootStore
      }
    );

    this.fetchEquipmentMaintenanceSchedulesDebounced = debounce(
      this.fetchEquipmentMaintenanceSchedules,
      BASE_DEBOUNCE
    );
  }

  @computed
  get totalPages() {
    return Math.ceil(
      this.equipmentMaintenanceSchedules.totalElements / this.pageSize
    );
  }

  @computed get entryForEdit() {
    return this.parent.entryForEdit;
  }

  @action.bound setup() {
    window.scrollTo(0, 0);
    this.setupReactions();
    this.fetchEquipmentMaintenanceSchedules();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        this.fetchEquipmentMaintenanceSchedulesDebounced();
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.sortField = 'date';
    this.sortDirection = 'desc';
    this.page = 1;
    this.entry = null;
    this.saving = false;
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      sortField: this.sortField,
      sortDirection: this.sortDirection
    };
  }

  @action.bound async fetchEquipmentMaintenanceSchedules() {
    this.loading = true;
    this.equipmentMaintenanceSchedules.cancelRequest();
    this.equipmentMaintenanceSchedules.reset();

    try {
      await this.equipmentMaintenanceSchedules.fetch({
        url: `${this.entryForEdit.url()}/maintenance/schedules`,
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasEquipmentMaintenanceSchedules() {
    return this.equipmentMaintenanceSchedules.hasModels;
  }

  @computed get showEmptyState() {
    return !this.loading && !this.hasEquipmentMaintenanceSchedules;
  }

  @computed get showEmptySearchState() {
    return !this.loading && !this.hasEquipmentMaintenanceSchedules;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  // Edit Maintenance
  @action.bound editMaintenance(maintenance) {
    this.entry = maintenance;

    this.form = new EquipmentMaintenanceForm(
      {
        fields: equipmentMaintenanceFormFields,
        rules: equipmentMaintenanceFormRules,
        values: {
          ...maintenance.formValues
        },
        labels: equipmentMaintenanceFormLabels
      },
      {
        options: equipmentMaintenanceFormOptions,
        plugins: equipmentMaintenanceFormPlugins
      }
    );

    this.showModal('EquipmentMaintenanceEditModal');
  }

  // Add Maintenance
  @action.bound addMaintenance() {
    this.entry = new EquipmentMaintenanceSchedule(
      {},
      {
        rootStore: this.rootStore
      }
    );

    this.form = new EquipmentMaintenanceForm(
      {
        fields: equipmentMaintenanceFormFields,
        rules: equipmentMaintenanceFormRules,
        values: {
          type: 'CUMULATIVE',
          measurementType: 'HOURS',
          startingValue: this.entryForEdit?.totalHours
        },
        labels: equipmentMaintenanceFormLabels
      },
      {
        options: equipmentMaintenanceFormOptions,
        plugins: equipmentMaintenanceFormPlugins
      }
    );

    this.showModal('EquipmentMaintenanceEditModal');
  }

  @action.bound async cancelEditMaintenance() {
    await this.hideActiveModal();
    this.form = null;
    this.saving = false;
  }

  @computed get typeOptions() {
    return [
      { title: t('Recurring'), value: 'CUMULATIVE' },
      { title: t('One-off'), value: 'INDEPENDENT' }
    ];
  }

  @computed get measurementTypeOptions() {
    return [
      { title: t('Hours'), value: 'HOURS' },
      {
        title: this.parent.odometerOptions.find(
          option => option.value === this.entryForEdit.odometerType
        ).title,
        value: 'ODOMETER'
      }
    ];
  }

  @action.bound submitEditMaintenanceForm(event) {
    event.preventDefault();

    this.form.submit({
      onSuccess: this.submitEditMaintenanceFormSuccess,
      onError: this.submitEditMaintenanceFormError
    });
  }

  @action.bound async submitEditMaintenanceFormSuccess() {
    this.saving = true;

    const isNew = this.entry.isNew;

    try {
      await this.entry.save(this.form.values(), {
        url: isNew
          ? `${this.entryForEdit.url()}/maintenance/schedules`
          : `${this.entryForEdit.url()}/maintenance/schedules/${
              this.entry.uuid
            }`
      });

      this.cancelEditMaintenance();
      this.fetchEquipmentMaintenanceSchedules();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: isNew ? t('Maintenance added') : t('Maintenance updated')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitEditMaintenanceFormError() {
    console.error(this.form.errors());
  }

  // Delete
  @action.bound deleteMaintenance(maintenance) {
    this.selectedMaintenance = maintenance;
    this.showModal('DeleteModal');
  }

  @action.bound cancelDeleteMaintenance() {
    this.selectedMaintenance = null;
    this.hideActiveModal();
  }

  @action.bound async confirmDeleteMaintenance() {
    this.saving = true;
    try {
      await this.selectedMaintenance.destroy({
        url: `${this.entryForEdit.url()}/maintenance/schedules/${
          this.selectedMaintenance.uuid
        }`,
        wait: true
      });

      this.cancelDeleteMaintenance();
      this.fetchEquipmentMaintenanceSchedules();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Maintenance deleted')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }
}
