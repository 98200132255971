import UIStore from './UIStore';
import { action, computed, observable, runInAction } from 'mobx';

import form, { DailyReportForm } from '../../forms/report';
import request from 'axios';

import { t } from 'utils/translate';
import { callTrack } from 'utils/segmentIntegration';
import {
  REPORT_DAILY_DOWNLOADED,
  REPORT_SUPER_DAILY_DOWNLOADED
} from 'utils/segmentAnalytics/eventSpec';

export default class ReportDownloadUI extends UIStore {
  @observable fetchingPdfUrls;
  @observable modalType;

  constructor(options) {
    super(options);
    this.dailyReportUrls = observable([]);
  }

  @computed get modalHeader() {
    switch (this.modalType) {
      case 'superDaily':
        return t('Super Daily Report');
      default:
        return t('Daily Report');
    }
  }

  @action.bound
  showReportDownloadModal() {
    this.authorization
      .checkFeatureAccess('ViewSegmentReports', true)
      .then(() => {
        this.fetchPdfUrls().then(() => {
          runInAction(() => {
            this.modalType = 'daily';
            this.showModal('reportDropdownModal');
            this.initForm();
          });
        });
      });
  }

  @action.bound
  showSuperDailyDownloadModal() {
    this.authorization
      .checkFeatureAccess('ViewSegmentReports', true)
      .then(() => {
        this.fetchSuperDailyPdfUrls().then(() => {
          runInAction(() => {
            this.modalType = 'superDaily';
            this.showModal('reportDropdownModal');
            this.initForm();
          });
        });
      });
  }

  initForm() {
    this.activeForm = new DailyReportForm(
      {
        fields: form.fields,
        rules: form.rules,
        values: form.values,
        labels: form.labels
      },
      {
        options: form.options,
        plugins: form.plugins
      }
    );
  }

  @action.bound
  executeDownloadDailyPdf() {
    if (this.report?.pdfs?.daily) {
      window.open(this.report.pdfs.daily, '_blank');

      callTrack(REPORT_DAILY_DOWNLOADED, {
        project_name: this.report?.projectName,
        report_date: this.report?.date
      });
    } else {
      console.error('PDF is not available yet.');
    }
  }

  @action.bound
  executeDownloadSuperDailyPdf() {
    if (this.report?.pdfs?.super) {
      window.open(this.report.pdfs.super, '_blank');

      callTrack(REPORT_SUPER_DAILY_DOWNLOADED, {
        project_name: this.report?.projectName,
        report_date: this.report?.date
      });
    } else {
      console.error('PDF is not available yet.');
    }
  }

  @action.bound
  executeDownload() {
    window.open(this.activeForm.values().shift, '_blank');

    if (this.modalType === 'superDaily') {
      callTrack(REPORT_SUPER_DAILY_DOWNLOADED, {
        project_name: this.report?.projectName,
        report_date: this.report?.date
      });
    } else {
      callTrack(REPORT_DAILY_DOWNLOADED, {
        project_name: this.report?.projectName,
        report_date: this.report?.date
      });
    }

    this.hideActiveModal().then(() => {
      this.modalType = null;
    });
  }

  @computed get projectUI() {
    return this.rootStore.projectUI;
  }

  @computed get project() {
    return this.projectUI.project;
  }

  @computed
  get report() {
    return this.projectUI.reportSummary;
  }

  @computed get date() {
    return this.projectUI.date;
  }

  @action.bound
  fetchPdfUrls() {
    this.fetchingPdfUrls = false;
    return request
      .get(`/ra/projects/${this.project.id}/reports/${this.date}/daily-pdfs`)
      .then(response => {
        this.dailyReportUrls.replace(response.data.collection);
        this.fetchingPdfUrls = true;
      })
      .catch(error => {
        console.error(error);
      });
  }

  @action.bound
  fetchSuperDailyPdfUrls() {
    this.fetchingPdfUrls = false;
    return request
      .get(`/ra/projects/${this.project.id}/reports/${this.date}/super-pdfs`)
      .then(response => {
        this.dailyReportUrls.replace(response.data.collection);
        this.fetchingPdfUrls = true;
      })
      .catch(error => {
        console.error(error);
      });
  }

  @computed
  get hasCurrentSegments() {
    return this.projectUI.hasActiveSegments;
  }
}
