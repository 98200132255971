import moment from 'moment';
import { action, observable, computed } from 'mobx';
import ProjectChildEditUI from '../ProjectChildEditUI';

import Observation from 'stores/models/Observation';

import {
  ObservationForm,
  observationFormOptions,
  observationFormFields,
  observationFormLabels,
  observationFormPlugins
} from 'forms/observation';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';

export default class ChecklistObservationEditUI extends ProjectChildEditUI {
  @observable entryForEdit;
  @observable entryEditForm;
  @observable padSigned;
  @observable signature;
  @observable observationToUpdateStatus;
  @observable observationStatusNewValue;
  @observable questionModel;
  @observable question;
  @observable selectedObservationUuid;
  @observable notifyAssignees;
  @observable notifyTeamMembers;

  constructor(options) {
    super(options);

    this.entryForEdit = null;
    this.entryEditForm = null;
    this.observationStatusNewValue = null;
    this.padSigned = false;
    this.signature = null;
    this.questionModel = null;
    this.question = null;
    this.saveAsDraft = false;
  }

  @computed get checklistObservationUI() {
    return this.parent.checklistObservationUI;
  }

  @computed
  get observationForm() {
    return this.entryEditForm;
  }

  @computed
  get observationEntry() {
    return this.entryForEdit;
  }

  @computed
  get submitObservationForm() {
    return this.submitEntryEditForm;
  }

  @computed get disableSaveButton() {
    return (
      !this.observationForm ||
      this.saving ||
      this.entryForEdit?.hasAttachmentsUploading
    );
  }

  @computed get saveButtonText() {
    if (this.saving) {
      return t('Saving...');
    }

    if (this.entryForEdit?.hasAttachmentsUploading) {
      return t('Uploading files...');
    }

    return t('Save');
  }

  @computed get saveAsDraftButtonText() {
    if (this.saving && this.saveAsDraft) {
      return t('Saving...');
    }

    return t('Save as draft');
  }

  @action.bound tearDown() {
    this.clearValidationDetails();
    this.clearUIState();
    this.classificationSelectorUI.tearDown();
    this.collaboratorSelectorUI.tearDown();
    this.projectLocationSelectorUI.tearDown();
    this.unblockHistory();
  }

  @action.bound async fetchEntry(uuid) {
    if (this.entryForEdit) return;

    const model = new Observation(
      {
        type: 'Observation',
        uuid: uuid
      },
      {
        rootStore: this.rootStore
      }
    );

    try {
      await model.fetch({
        url: `${this.rootStore.urlMicroService('hydra')}/companies/${
          this.rootStore.me.company.uuid
        }/observations/${model.uuid}`
      });

      this.setEntryForEdit(model);
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound async setEntryForEdit(model) {
    this.entryForEdit = model;

    this.memberSelectorUI.setup({
      projectUuids: this.checklistObservationUI.memberProjectUuids.slice(),
      sortField: 'company.name, firstName, lastName',
      elevateFn: element =>
        element.company.uuid === this.rootStore.me.company.uuid,
      role: ['ROLE_ACCOUNT_ADMIN', 'ROLE_ADMIN', 'ROLE_PROJECT_MEMBER']
    });

    this.projectLocationSelectorUI.setup(this.project.uuid);

    await Promise.all([
      this.observationTypes.fetch(),
      this.observationStatuses.fetch(),
      this.observationFields.fetch()
    ]);

    this.entryEditForm = new ObservationForm(
      {
        fields: observationFormFields,
        rules: this.observationFields.observationFieldsFormRules,
        values: {
          ...this.entryForEdit.formValues,
          type: this.defaultType,
          subType: this.defaultSubType,
          status: this.observationStatusOptions.find(
            statusOption =>
              statusOption.value === this.entryForEdit.formValues.status?.uuid
          ),
          priority: this.priorityOptions.find(
            priorityOption =>
              priorityOption.value === this.entryForEdit.formValues.priority
          ),
          location: this.locationOptions.find(
            locationOption =>
              locationOption.uuid ===
              this.entryForEdit.formValues.location?.uuid
          ),
          emails: this.defaultEmails,
          assignees: this.defaultAssignees
        },
        labels: observationFormLabels
      },
      {
        options: observationFormOptions,
        plugins: observationFormPlugins
      }
    );
  }

  @computed get observationDialogTitle() {
    return t('Edit observation');
  }

  @action.bound
  editObservation({ observationUuid, questionModel, question }) {
    this.questionModel = questionModel;
    this.question = question;
    this.selectedObservationUuid = observationUuid;
    this.showModal('observation');
    this.fetchEntry(observationUuid);
  }

  @computed get observationTypes() {
    return this.checklistObservationUI.observationTypes;
  }

  @computed get observationTypesOptions() {
    return this.checklistObservationUI.observationTypesOptions;
  }

  @computed get observationFields() {
    return this.checklistObservationUI.observationFields;
  }

  @computed get observationSubTypesOptions() {
    if (!this.entryEditForm.$('type').value) return [];

    return this.observationTypes.models
      .filter(type => {
        return this.entryEditForm.$('type').value.value === type.type;
      })
      .map(type => {
        return {
          value: type.uuid,
          title: type.subType
        };
      });
  }

  @computed get selectedObservationSubType() {
    return this.observationSubTypesOptions.find(
      type => type.value === this.entryEditForm.$('subType').value
    );
  }

  @computed get observationStatuses() {
    return this.checklistObservationUI.observationStatuses;
  }

  @computed get observationStatusOptions() {
    return this.checklistObservationUI.observationStatusOptions;
  }

  @computed get priorityOptions() {
    return this.checklistObservationUI.priorityOptions;
  }

  @computed get locationOptions() {
    return this.checklistObservationUI.locationOptions;
  }

  @computed get categoryOptions() {
    return this.checklistObservationUI.categoryOptions;
  }

  @computed get selectedCategoryOption() {
    return this.categoryOptions.find(
      option => option.value === this.entryEditForm.$('category').value
    );
  }

  @computed get defaultType() {
    const type = this.checklistObservationUI.observationTypes.models.find(
      type => type.uuid === this.entryForEdit.formValues?.type.uuid
    );
    if (type) {
      return {
        value: type.type,
        title: type.type,
        class: type.typeClass
      };
    } else {
      return null;
    }
  }

  @computed get defaultSubType() {
    return this.entryForEdit.formValues?.type.uuid;
  }

  @computed get defaultEmails() {
    if (!this.entryForEdit.emailUsers) return [];

    return this.entryForEdit.emailUsers.map(member => {
      return {
        uuid: member.uuid,
        email: member.username,
        name: `${member.firstName}  ${member.lastName}`
      };
    });
  }

  @computed get defaultAssignees() {
    return this.entryForEdit.tasks.models.map(task => {
      return {
        assignee: {
          uuid: task.assigneeUser.uuid,
          name: task.assigneeUser.fullName,
          email: task.assigneeUser.email
        },
        uuid: task.uuid,
        dueDate: task.dueDate ? moment(task.dueDate).format('YYYY-MM-DD') : '',
        action: task.desc,
        sendNotification: ''
      };
    });
  }

  @computed get saveObservationUrl() {
    return (
      this.rootStore.urlMicroService('toolbox') +
      `/companies/${this.rootStore.me.company.uuid}/observations/${
        this.entryForEdit.uuid
      }?notifyAssignees=${!!this.notifyAssignees}&notifyTeamMembers=${!!this
        .notifyTeamMembers}`
    );
  }

  @action.bound
  addAssignee() {
    this.entryEditForm.$('assignees').add({
      assignee: this.entryEditForm.$('assignee').value,
      dueDate: this.entryEditForm.$('dueDate').value
        ? moment(this.entryEditForm.$('dueDate').value).format('YYYY-MM-DD')
        : '',
      action: this.entryEditForm.$('action').value,
      sendNotification: this.entryEditForm.$('sendNotification').value
    });

    this.entryEditForm.$('assignee').clear();
    this.entryEditForm.$('dueDate').clear();
    this.entryEditForm.$('action').clear();
    this.observationForm.$('sendNotification').set(true);
  }

  @action.bound
  removeAssignee(assigneePath, uuid) {
    this.entryEditForm.$('assignees').del(assigneePath);

    if (uuid) {
      // If this is an existing task already created on the BE
      const taskToDelete = this.entryForEdit.tasks.get(uuid);
      taskToDelete.destroy();
    }
  }

  @action.bound openObservationSignatureModal(newValue) {
    if (newValue?.title === 'Closed') {
      this.observationStatusNewValue = newValue;
      this.observationStatusOldValue = this.entryEditForm.$('status').value;
      this.activeModal = 'observationSign';
      this.entryEditForm.$('status').clear();
      this.entryEditForm.$('status').set(this.observationStatusNewValue);
    } else {
      this.entryEditForm.$('status').set(newValue);
    }
  }

  @action.bound async cancelObservationSignatureModal() {
    this.entryEditForm.$('status').set(this.observationStatusOldValue);
    await this.closeObservationSignatureModal();
  }

  @action.bound closeObservationSignatureModal() {
    this.observationStatusNewValue = null;
    this.observationStatusOldValue = null;
    this.activeModal = 'observation';
  }

  @action.bound
  async updateStatus() {
    this.entryEditForm.$('status').set(this.observationStatusNewValue);
    this.closeObservationSignatureModal();
  }

  @action.bound tearDownObservation() {
    this.entryEditForm = null;
    this.clearUIState();
  }

  @action.bound
  async hideObservation() {
    await this.hideActiveModal();
    this.tearDownObservation();
  }

  @action.bound
  async saveObservationDraft(e) {
    this.saveAsDraft = true;
    this.submitEntryEditForm(e);
  }

  @action.bound setDefaultObservationsType(values) {
    localStorage.setItem(`defaulObservationType`, values.subType);
    localStorage.setItem(`defaulObservationLocation`, values.location);
  }

  @action.bound async submitEntryEditFormSuccess() {
    this.saving = true;

    const attachments = this.entryForEdit.attachments.models.map(attachment => {
      return {
        uuid: attachment.uuid
      };
    });

    const values = this.entryEditForm.values();

    const assignees = await this.checklistObservationUI.createOrEditTasksForAssignees(
      this.entryEditForm.assigneesValues,
      values.status.value
    );

    const payload = {
      projectUuid: this.project.uuid,
      attachments: attachments,
      entityStatus: this.saveAsDraft ? 'DRAFT' : 'ACTIVE',
      type: {
        uuid: values.subType
      },
      category: values.category,
      priority: values.priority?.value,
      status: {
        uuid: values.status?.value
      },
      assignees: assignees,
      location: { uuid: values.location?.uuid },
      description: values.description,
      emails: values.emails?.map(member => member.email),
      ...(this.signature && { signature: this.signature })
    };

    try {
      await this.entryForEdit.save(payload, {
        url: this.saveObservationUrl,
        wait: true,
        stripNonRest: false
      });

      this.questionModel.checklistResponse.updateObservation(
        this.entryForEdit.toJSON()
      );

      this.hideObservation();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Observation saved')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
      this.tearDownObservation();
    }
  }

  @action.bound signPad(signature) {
    this.padSigned = true;
    this.signature = signature;
  }

  @action.bound unsignPad() {
    this.padSigned = false;
    this.signature = null;
  }

  @action.bound clearUIState() {
    this.activeModal = null;
    this.clearValidationDetails();
    this.entryForEdit = null;
    this.entryEditForm = null;
    this.saving = false;
    this.saveAsDraft = false;
    this.padSigned = false;
    this.signature = null;
  }

  @action.bound toggleNotifyAssignees() {
    this.notifyAssignees = !this.notifyAssignees;
  }

  @action.bound toggleNotifyTeamMembers() {
    this.notifyTeamMembers = !this.notifyTeamMembers;
  }
}
