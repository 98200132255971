import request from 'axios';
import { action, computed, when } from 'mobx';
import ProjectChildEditUI from './ProjectChildEditUI';
import Attachment from '../../models/Attachment';
import getFilePreviewIcon from 'utils/getFilePreviewIcon';
import fileToBase64 from 'utils/fileToBase64';
import bytesToSize from 'utils/bytesToSize';

import {
  SurveyForm,
  surveyFormOptions,
  surveyFormFields,
  surveyFormRules,
  surveyFormLabels,
  surveyFormPlugins
} from 'forms/project/survey';

import history from 'utils/history';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';

import { callTrack } from 'utils/segmentIntegration';
import { SURVEY_COMPLETED } from 'utils/segmentAnalytics/eventSpec';

import SurveyObservationUI from './SurveyObservationUI';
import SurveyObservationAddUI from './SurveyObservationAddUI';
import SurveyObservationEditUI from './SurveyObservationEditUI';

export default class SurveyEditUI extends ProjectChildEditUI {
  constructor(options) {
    super(options);

    this.surveyObservationUI = new SurveyObservationUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.surveyObservationAddUI = new SurveyObservationAddUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.surveyObservationEditUI = new SurveyObservationEditUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @computed get answers() {
    return this.parent.answers;
  }

  @computed get answerHints() {
    return this.parent.answerHints;
  }

  @action.bound setup() {
    when(
      () => !this.parent.loading,
      () => {
        this.entryEditForm = new SurveyForm(
          {
            fields: surveyFormFields,
            rules: surveyFormRules,
            values: this.formValues,
            labels: surveyFormLabels
          },
          {
            options: surveyFormOptions,
            plugins: surveyFormPlugins
          }
        );

        this.blockHistoryIfFormChanged();
      }
    );
  }

  @action.bound async submitEntryEditFormSuccess() {
    this.saving = true;

    // TODO: Super Daily 2.0 - Switch to new endppoint once available
    try {
      const values = this.entryEditForm.values();

      const answers = values.answers.map(answer => {
        const answerModel = this.getAnswerByQuestionId(answer.question.id);

        return {
          question: answer.question,
          answer: answer.answer,
          observations: answerModel.observations.models,
          attachments: answerModel.attachments.models,
          desc: answer.desc,
          ...this.reportParams
        };
      });

      await request.post(
        `ra/companies/${this.company.uuid}/answers/batch`,
        answers
      );

      callTrack(SURVEY_COMPLETED, {
        project_name: this.project.name
      });

      this.unblockHistory();
      this.parent.refetchAnswers();
      this.cancelSurveyEdit();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Survey saved')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound cancelSurveyEdit() {
    history.push({
      pathname: `${this.project.viewUrl}/survey`,
      search: this.baseQueryParams
    });
  }

  @computed get formValues() {
    return {
      answers: this.answers.models.map(answer => {
        return {
          uuid: answer.uuid,
          question: {
            id: answer.question.id,
            question: answer.question.question,
            category: answer.question.category,
            version: answer.question.version
          },
          answer: answer.answer,
          desc: answer.desc
        };
      })
    };
  }

  @computed get title() {
    if (this.hasWriteAccess) {
      if (this.parent.hasAnswers) {
        return t('Edit survey');
      } else {
        return t('Survey');
      }
    }

    return t('View survey');
  }

  getAnswerByQuestionId = questionId => {
    return this.answers.models.find(
      answer => answer.question.id === questionId
    );
  };

  @computed get hasAttachmentsUploading() {
    return (
      this.answers.models.filter(answer =>
        answer.attachments.models.find(attachment => attachment.isNew)
      ).length > 0
    );
  }

  @action.bound uploadAttachments(answer, items) {
    items.forEach(item => {
      this.uploadAttachment(answer, item);
    });
  }

  @action.bound async uploadAttachment(answer, uploadItem) {
    const file = uploadItem.file;

    if (file.size > 62914560) {
      this.rootStore.notificationsUI.pushNotification({
        title: `${t('File is too big ')} (${bytesToSize(file.size)}). ${t(
          'Limit is 60MB.'
        )}`,
        snackbar: 'error',
        icon: 'notification'
      });

      return;
    }

    file.preview = await fileToBase64(file);

    const filePreviewIcon = getFilePreviewIcon(this.rootStore.assetsURL, file);

    const previewAttachment = new Attachment(
      {
        thumbUrl: filePreviewIcon
      },
      {
        rootStore: this.rootStore
      }
    );

    answer.attachments.add(previewAttachment);

    try {
      await answer.attachments.uploadVersionTwo({
        file: file,
        analytics: {
          module: 'Attachments',
          project: {
            id: this.project.uuid,
            name: this.project.name
          }
        },
        previewAttachment: previewAttachment,
        progressCallback: percentCompleted => {
          // If we wanted to show a progress bar on the attachment
          // we can use previewAttachment.uploadProgress.
          previewAttachment.setUploadProgress(percentCompleted);
        }
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      // Remove the preview model now that the new one is ready
      answer.attachments.remove(previewAttachment);
    }
  }
}
