import { RakenCollection } from '../lib';
import ScheduledTalk from '../models/ScheduledTalk';
import { action, computed, observable, reaction } from 'mobx';
import Project from './../models/Project';

export default class ScheduledTalks extends RakenCollection {
  @observable sortField;
  @observable sortDirection;
  @observable page;
  @observable lastPage;

  constructor(data, options) {
    super(data, options);
    this.talkId = options.talkId;
    this.sortField = 'scheduleDate';
    this.sortDirection = 'ASC';
    this.page = 0;
    this.limit = 50;
    this.lastPage = 0;

    this.setupParamsReaction();
  }

  model() {
    return ScheduledTalk;
  }

  url() {
    if (this.parent instanceof Project) {
      return `${this.rootStore.urlMicroService('toolbox')}/tbt/projects/${
        this.parent.uuid
      }/project-talks`;
    }

    return `${this.rootStore.urlMicroService(
      'toolbox'
    )}/tbt/company/project-talks`;
  }

  @computed
  get requestParams() {
    return {
      sortField: this.sortField,
      sortDirection: this.sortDirection,
      page: this.page,
      limit: this.limit,
      talkId: this.talkId ? this.talkId : ''
    };
  }

  @computed
  get tab() {
    return this.rootStore.companyUI.companyToolboxTalksUI.tab;
  }

  setupParamsReaction = () => {
    if (this.cancelParamsReaction) {
      this.cancelParamsReaction();
    }
    this.cancelParamsReaction = reaction(
      () => this.requestParams,
      requestParams => {
        this.fetch({
          params: this.requestParams,
          url: `${this.url()}/${this.tab}`
        }).then(() => {
          this.lastPage = Math.ceil(
            this.getPagingKey('totalElements') / this.limit
          );
        });
      }
    );
  };

  @action.bound
  handlePageChange(data) {
    this.page = data.selected;
  }

  @action.bound
  sortByColumn(column) {
    if (this.sortField === column) {
      this.sortDirection === 'ASC'
        ? (this.sortDirection = 'DESC')
        : (this.sortDirection = 'ASC');
    } else {
      this.sortField = column;
    }
  }

  @computed
  get hasScheduledTalks() {
    return Boolean(this.models.length);
  }

  @computed
  get selectedTalks() {
    return this.models.filter(talk => talk.selected);
  }

  @action.bound
  handleFilterByProject(uuid) {
    return this.models.filter(talk => talk.project.uuid === uuid);
  }
}
