import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AvatarEditor from 'react-avatar-editor';
import styled from 'styled-components';

import { ModalBody, ModalButtonGroup, Button, Slider, FileUpload } from '.';
import { t } from 'utils/translate';

const UploadNewButton = styled.div`
  position: relative;
`;

class Cropper extends Component {
  state = {
    zoom: 1,
    image: null
  };

  handleUploadNew = image => {
    const { onUploadNew } = this.props;

    if (onUploadNew) {
      onUploadNew(image);
    } else {
      this.setState({
        image: image
      });
    }
  };

  handleCrop = () => {
    if (this.editor) {
      const { width, height, backgroundColor } = this.props;

      const canvas = document.createElement('canvas');
      const image = this.editor.getImageScaledToCanvas();

      canvas.crossOrigin = 'Anonymous';

      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext('2d');

      ctx.beginPath();
      ctx.rect(0, 0, width, height);
      ctx.fillStyle = backgroundColor;
      ctx.fill();

      ctx.drawImage(image, 0, 0);

      const data = canvas.toDataURL();
      this.props.onCrop(data);
    }
  };

  handleZoomUpdate = zoom => {
    this.setState({ zoom: parseFloat(zoom) });
  };

  render() {
    const { onHide, width, height, image, border } = this.props;

    return (
      <div>
        <ModalBody shadow>
          <AvatarEditor
            image={this.state.image || image}
            width={width}
            height={height}
            border={border}
            color={[255, 255, 255, 0.6]} // RGBA
            scale={this.state.zoom}
            rotate={0}
            style={{
              maxWidth: '100%',
              height: 'auto',
              backgroundColor: '#fff'
            }}
            ref={ref => (this.editor = ref)}
            crossOrigin="anonymous"
          />

          <Slider
            ref={ref => (this.zoom = ref)}
            onChange={this.handleZoomUpdate}
            width={width}
            min={0.1}
            max={3}
            step={0.01}
            startValue={1}
          />
        </ModalBody>
        <ModalButtonGroup>
          <Button
            color="lightGrey1"
            onClick={onHide}
            data-qa="button_cropper-cancel"
          >
            {t('Cancel')}
          </Button>
          <UploadNewButton>
            <Button color="blueAccent" data-qa="button_cropper-upload-new">
              {t('Upload New')}
              <FileUpload onFileChange={this.handleUploadNew} />
            </Button>
          </UploadNewButton>
          <Button
            color="goGreen"
            onClick={this.handleCrop}
            data-qa="button_cropper-save"
          >
            {t('Save')}
          </Button>
        </ModalButtonGroup>
      </div>
    );
  }
}

Cropper.propTypes = {
  image: PropTypes.string,
  backgroundColor: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  onCrop: PropTypes.func.isRequired,
  onUploadNew: PropTypes.func
};

Cropper.defaultProps = {
  backgroundColor: '#fff',
  width: 1000,
  height: 500,
  border: 50
};

export default Cropper;
