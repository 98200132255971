import React from 'react';
import { Box, Text, Button } from '@raken/athens-web/lib';
import { t } from 'utils/translate';

export default ({ store }) => {
  return (
    <Box height={'95vh'}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height={'100%'}
      >
        <Box mb={6}>
          <img alt="" src={`${store.assetsURL}/svg/empty.svg`} width={40} />
        </Box>

        <Box mb={3}>
          <Text variant="h1" dataQA="session-expired-header">
            {t('Session expired')}
          </Text>
        </Box>
        <Box mb={12}>
          <Text light rakenColor="grey">
            {t('Please login to continue.')}
          </Text>
        </Box>
        <Box>
          <Button onClick={store.logout}>{t('Login')}</Button>
        </Box>
      </Box>
    </Box>
  );
};
