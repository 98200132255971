import { action, computed, observable } from 'mobx';

import {
  ProjectTemplateGeneralForm,
  projectTemplateGeneralFormOptions,
  projectTemplateGeneralFormFields,
  projectTemplateGeneralFormRules,
  projectTemplateGeneralFormPlugins,
  projectTemplateGeneralFormLabels
} from 'forms/projectTemplates/projectTemplateGeneral';

import { t } from 'utils/translate';

import ProjectTemplateChildEditUI from './ProjectTemplateChildEditUI';

import moment from 'moment';
import { extendMoment } from 'moment-range';

export default class ProjectTemplateGeneralUI extends ProjectTemplateChildEditUI {
  @observable form;
  constructor(options) {
    super(options);

    this.form = null;

    this.saveTitle = t('Project template general saved');
  }

  @computed get selectedTemplate() {
    return this.parent.selectedTemplate;
  }

  @action.bound setup() {
    this.setupForm();
  }

  @action.bound tearDown() {
    this.form = null;
    this.unblockHistory();
  }

  @action.bound
  setupForm() {
    this.form = new ProjectTemplateGeneralForm(
      {
        fields: projectTemplateGeneralFormFields,
        rules: projectTemplateGeneralFormRules,
        labels: projectTemplateGeneralFormLabels,
        values: this.selectedTemplate.generalFormValues
      },
      {
        options: projectTemplateGeneralFormOptions,
        plugins: projectTemplateGeneralFormPlugins
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @computed get times() {
    const momentExtended = extendMoment(moment);
    return Array.from(
      momentExtended
        .range(momentExtended().startOf('day'), momentExtended().endOf('day'))
        .by('hour')
    ).map(time => {
      return {
        value: time.format('HH:mm'),
        name: time.format('h:mm A')
      };
    });
  }

  @computed get languageOptions() {
    return [
      { id: 1, code: 'en-US', name: 'English' },
      { id: 2, code: 'es-MX', name: 'Español' },
      { id: 3, code: 'pt-BR', name: 'Português' }
    ];
  }

  @computed get frequencyOptions() {
    return [
      {
        value: 'WEEKLY',
        name: t('Daily/Weekly')
      },
      {
        value: 'MONTHLY',
        name: t('Monthly')
      }
    ];
  }

  @computed get intervalPickerOptions() {
    const { frequency } = this.form.values();
    if (frequency === 'MONTHLY') {
      return Array.from({ length: 31 }, (_, index) => {
        return {
          name: moment.localeData().ordinal(String(index + 1)),
          value: index + 1
        };
      });
    } else {
      return [
        { name: 'Sunday', value: 1 },
        { name: 'Monday', value: 2 },
        { name: 'Tuesday', value: 3 },
        { name: 'Wednesday', value: 4 },
        { name: 'Thursday', value: 5 },
        { name: 'Friday', value: 6 },
        { name: 'Saturday', value: 7 }
      ];
    }
  }

  @computed get intervalPickerValues() {
    const { intervals } = this.form.values();
    return this.intervalPickerOptions.filter(option =>
      intervals.includes(option.value)
    );
  }
}
