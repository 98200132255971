import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';

const equipmentFiltersFormPlugins = extendedPlugins;

const equipmentFiltersFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const equipmentFiltersFormFields = [
  'typeFilters[]',
  'makeFilters[]',
  'deployedToFilters[]',
  'conditionFilters[]'
];

const equipmentFiltersFormRules = {
  typeFilters: 'array',
  makeFilters: 'array',
  deployedToFilters: 'array',
  conditionFilters: 'array'
};

class EquipmentFiltersForm extends MobxReactForm {}

export {
  EquipmentFiltersForm,
  equipmentFiltersFormRules,
  equipmentFiltersFormFields,
  equipmentFiltersFormOptions,
  equipmentFiltersFormPlugins
};
