import React, { Component, Fragment } from 'react';
import styled, { css } from 'styled-components';
import { observer } from 'mobx-react';

import VerticalScrollingContainer from './VerticalScrollingContainer';
import SelectBoxWrapper from './SelectBoxWrapper';
import { t } from 'utils/translate';
import { FormGroupMessage } from 'raken-ui';

const getListWrapperWidth = props => {
  let width = props.menuWidth || props.width;

  if (width.toString().match(/^[0-9]+$/) !== null) {
    return `${width}px`;
  }

  return width;
};

const ListWrapper = styled.div`
  background: ${props => props.theme.colors.white};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  border: 1px solid ${props => props.theme.colors.lightGrey3};
  border-radius: 9px;
  margin: ${props => (props.orientation === 'top' ? '-3px' : '3px')} 0 0 0;
  padding: 5px 0;
  position: absolute;
  top: 100%;
  width: ${props => getListWrapperWidth(props)};
  z-index: 40;
  ${props =>
    !props.makeContainer &&
    css`
      ${props.alignMenu === 'left' &&
        css`
          margin-left: ${props => (props.menuWidth - props.width) / 2}px;
        `};
      ${props.alignMenu === 'right' &&
        css`
          margin-left: -${props => (props.menuWidth - props.width) / 2}px;
        `};
      z-index: 2042;
    `};
  ${props =>
    props.container !== document.body &&
    css`
      z-index: 50;
    `};
`;

const Placeholder = styled.div`
  color: ${props => props.theme.colors.lightGrey1};
  font-weight: 300;
  overflow-y: hidden;
`;

@observer
class SelectBox extends Component {
  static defaultProps = {
    placeholder: 'Select an option',
    focusColor: 'blueAccent',
    activeColor: 'charcoalGrey',
    menuHeight: 300,
    makeContainer: true,
    container: document.body,
    alignMenu: 'left',
    width: 200,
    renderSelectedItemNumber: 0,
    errorColor: 'red',
    hideValidationMessages: false
  };

  handleChildClick = (e, child, onChange, onClose) => {
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();

    if (child.props.disabled) return false;

    if (child.props.onClick) {
      child.props.onClick(e);
    } else {
      onChange(child.props.value);
    }

    onClose(e);
  };

  renderChildren(
    open,
    onClose,
    focusedItem,
    clearFocusedItem,
    onOptionGroupHeaderFocus,
    dataQA
  ) {
    const { onChange, children } = this.props;

    return React.Children.map(children, (child, index) => {
      if (child) {
        if (child.type.name === 'SelectBoxOptionGroupHeader') {
          return React.cloneElement(child, {
            focused: focusedItem === index,
            onOptionGroupHeaderFocus: onOptionGroupHeaderFocus,
            tabIndex: -1
          });
        }

        return React.cloneElement(child, {
          focused: focusedItem === index,
          tabIndex: -1,
          onMouseOver: clearFocusedItem,
          'data-qa': `${dataQA}-option`,
          onClick: e => {
            this.handleChildClick(e, child, onChange, onClose);
          },
          onKeyDown: e => {
            if (e.keyCode === 13 || e.keyCode == 32 || e.keyCode === 9) {
              this.handleChildClick(e, child, onChange, onClose);
            }
          }
        });
      }
    });
  }

  renderHeaderOption(child, clearFocusedItem, dataQA) {
    return React.cloneElement(child, {
      tabIndex: -1,
      'data-qa': `${dataQA}-header-option`,
      onClick: e => {
        e.stopPropagation();
        clearFocusedItem();
      }
    });
  }

  renderFooterOption(child, focusedItem, onClose, dataQA) {
    const { onChange, children } = this.props;

    return React.cloneElement(child, {
      focused: focusedItem === React.Children.count(children),
      tabIndex: -1,
      'data-qa': `${dataQA}-footer-option`,
      onClick: e => {
        this.handleChildClick(e, child, onChange, onClose);
      },
      onKeyDown: e => {
        if (e.keyCode === 13) {
          this.handleChildClick(e, child, onChange, onClose);
        }
      }
    });
  }

  renderSelectedItem() {
    // renderSelectedItemFirstChild gives us the ability to have multiple html nodes in the dropdownlist but only render the first one as the selected item inside the main input.
    const {
      value,
      children,
      placeholder,
      renderSelectedItemFirstChild,
      renderSelectedItemNumber
    } = this.props;

    if (children && children.length) {
      const selectedChild = React.Children.toArray(children).find(child => {
        return String(child.props.value) === String(value);
      });

      if (selectedChild && renderSelectedItemFirstChild) {
        if (renderSelectedItemFirstChild === 'textOnly') {
          return selectedChild.props.children[renderSelectedItemNumber].props
            .children;
        }

        return selectedChild.props.children[renderSelectedItemNumber];
      }

      if (selectedChild) {
        return selectedChild.props.children;
      }
    }

    if (!Array.isArray(children)) {
      if (children.props.value === value) {
        return children.props.children;
      }
    }

    return <Placeholder>{t(placeholder)}</Placeholder>;
  }

  render() {
    const {
      width,
      menuHeight,
      menuWidth,
      marginLeft,
      marginRight,
      marginBottom,
      size,
      tabIndex,
      children,
      disableTopOrientation,
      makeContainer,
      container,
      alignMenu,
      focusColor,
      activeColor,
      hasError,
      isValid,
      bold,
      headerOption,
      footerOption,
      disabled,
      disableAsReadOnly,
      dataQA,
      autoFocus,
      onClose,
      onClear,
      innerRef,
      scrollRef,
      form,
      fieldName,
      hideValidationMessages,
      errorColor,
      isAddOnControl
    } = this.props;

    const selectedItem = this.renderSelectedItem();
    let itemLength = React.Children.count(children);

    if (footerOption) {
      itemLength++;
    }

    return (
      <Fragment>
        <SelectBoxWrapper
          size={size}
          width={width}
          marginBottom={marginBottom}
          marginLeft={marginLeft}
          marginRight={marginRight}
          selectedItem={selectedItem}
          activeColor={activeColor}
          focusColor={focusColor}
          hasError={hasError}
          isValid={isValid}
          tabIndex={tabIndex}
          itemLength={itemLength}
          disableTopOrientation={disableTopOrientation}
          container={makeContainer ? this : container}
          bold={bold}
          disabled={disabled}
          disableAsReadOnly={disableAsReadOnly}
          dataQA={dataQA}
          autoFocus={autoFocus}
          onClose={onClose}
          onClear={onClear}
          innerRef={innerRef}
          isAddOnControl={isAddOnControl}
        >
          {(
            open,
            orientation,
            onToggle,
            onClose,
            focusedItem,
            clearFocusedItem,
            onOptionGroupHeaderFocus
          ) => (
            <ListWrapper
              width={width}
              menuWidth={menuWidth}
              makeContainer={makeContainer}
              alignMenu={alignMenu}
              container={container}
              orientation={orientation}
            >
              {headerOption &&
                this.renderHeaderOption(headerOption, clearFocusedItem, dataQA)}
              <VerticalScrollingContainer
                innerRef={ref => {
                  scrollRef && scrollRef(ref);
                }}
                height={menuHeight}
              >
                {this.renderChildren(
                  open,
                  onClose,
                  focusedItem,
                  clearFocusedItem,
                  onOptionGroupHeaderFocus,
                  dataQA
                )}
              </VerticalScrollingContainer>
              {footerOption &&
                this.renderFooterOption(
                  footerOption,
                  focusedItem,
                  onClose,
                  dataQA
                )}
            </ListWrapper>
          )}
        </SelectBoxWrapper>
        {form &&
          fieldName &&
          form.$(fieldName).hasError &&
          !hideValidationMessages && (
            <FormGroupMessage
              errorColor={errorColor}
              hasError
              data-qa={`text_error-${dataQA}`}
            >
              {t(form.$(fieldName).error, {
                customValidation: form.$(fieldName).label
              })}
            </FormGroupMessage>
          )}
      </Fragment>
    );
  }
}

export default SelectBox;
