import { computed } from 'mobx';
import ProjectChildUI from './ProjectChildUI';
import ToolboxTalksAddUI from './ToolboxTalksAddUI';
import ToolboxTalksAllUI from './ToolboxTalksAllUI';
import ToolboxTalksPastUI from './ToolboxTalksPastUI';
import ToolboxTalksUpcomingUI from './ToolboxTalksUpcomingUI';

import { t } from 'utils/translate';

export default class ToolboxTalksUI extends ProjectChildUI {
  constructor(options) {
    super(options);

    this.toolboxTalksAddUI = new ToolboxTalksAddUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.toolboxTalksAllUI = new ToolboxTalksAllUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.toolboxTalksPastUI = new ToolboxTalksPastUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.toolboxTalksUpcomingUI = new ToolboxTalksUpcomingUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @computed get tabLinks() {
    return [
      {
        label: t('All'),
        path: {
          pathname: `${this.project.viewUrl}/toolbox-talks/all`,
          search: this.baseQueryParams
        }
      },
      {
        label: t('Past'),
        path: {
          pathname: `${this.project.viewUrl}/toolbox-talks/past`,
          search: this.baseQueryParams
        }
      },
      {
        label: t('Upcoming'),
        path: {
          pathname: `${this.project.viewUrl}/toolbox-talks/upcoming`,
          search: this.baseQueryParams
        }
      }
    ];
  }
}
