import { Model } from 'mobx-mc';

export default class DocumentsStat extends Model {
  get restAttributes() {
    return ['bytesUsed', 'storageLimitInBytes'];
  }

  get urlRoot() {
    return `/ra/companies/${this.rootStore.me.company.uuid}/document-storage/stats`;
  }
}
