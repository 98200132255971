import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import { t } from 'utils/translate';

const segmentsAddFormPlugins = extendedPlugins;

const segmentsAddFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const segmentsAddFormFields = ['name'];

const segmentsAddFormRules = {
  name: 'string|required|between:1,60'
};

const segmentsAddFormLabels = {
  name: t('Segment Name')
};

class SegmentsAddForm extends MobxReactForm {}

export {
  SegmentsAddForm,
  segmentsAddFormOptions,
  segmentsAddFormFields,
  segmentsAddFormRules,
  segmentsAddFormLabels,
  segmentsAddFormPlugins
};
