import { RakenCollection } from '../lib';
import TimeCardReport from '../models/TimeCardReport';

export default class TimeCardReports extends RakenCollection {
  model() {
    return TimeCardReport;
  }

  url() {
    return `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/timecards/reports/scheduled`;
  }
}
