var SI_PREFIXES = ['', 'k', 'M', 'B', 'T', 'P', 'E'];

export default (number, type) => {
  if (typeof number === 'undefined' || number === '') {
    return '';
  }

  if (typeof number === 'string') {
    number = parseFloat(number);
  }

  if (isNaN(number)) {
    return 0;
  }

  if (Math.abs(number) < 1000) {
    return parseFloat(number.toFixed(2));
  }

  if (Math.abs(number) < 10000) {
    return parseFloat(number.toFixed(0));
  }

  if (Math.abs(number) > 9999 && number < 100000) {
    return Math.round(number).toLocaleString();
  }
  // what tier? (determines SI prefix)
  var tier = (Math.log10(number) / 3) | 0;

  // if zero, we don't need a prefix
  if (tier === 0) return parseFloat(number.toFixed(1));

  // get prefix and determine scale
  var prefix = SI_PREFIXES[tier];
  var scale = Math.pow(10, tier * 3);

  // scale the number
  var scaled = Math.round(number / scale);

  // format number and add prefix as suffix
  return scaled + prefix;
};
