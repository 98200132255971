import { createBrowserHistory } from 'history';

const history = createBrowserHistory({
  getUserConfirmation(message, callback) {
    // Show some custom dialog to the user and call
    // callback(true) to continue the transiton, or
    // callback(false) to abort it.
  }
});

const historyState = [];

history.listen(location => {
  historyState.push(location);
});

export default history;
export { historyState };
