import React from 'react';
import styled from 'styled-components';
import media from '../utils/mediaTemplate';

const getMobileBackgroundColor = pathname => {
  switch (pathname) {
    case '/':
      return 'lightGrey2';
    case '/login':
      return 'darkSteel';
    default:
      return 'white';
  }
};

const getDesktopBackgroundColor = pathname => {
  switch (pathname) {
    case '/login':
      return 'darkSteel';
    default:
      return 'lightGrey2';
  }
};

const Wrapper = styled.div`
  background-color: ${props =>
    props.theme.colors[getMobileBackgroundColor(props.pathname)]};
  min-height: 100%;
  ${media.desktop`
    background-color: ${props =>
      props.theme.colors[getDesktopBackgroundColor(props.pathname)]};
  `};
`;

const Inner = styled.div`
  padding: 50px 0 2rem;
  ${media.desktop`
    //max-width: 1350px;
    margin: 0 auto;
    padding: 0;
  `};
`;

export default ({ children, ...props }) => {
  return (
    <Wrapper {...props}>
      <Inner {...props}>{children}</Inner>
    </Wrapper>
  );
};
