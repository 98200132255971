import { Model } from 'mobx-mc';
import { computed, action } from 'mobx';

export default class SubscriptionPlan extends Model {
  get restAttributes() {
    return [
      'id',
      'type',
      'name',
      'desc',
      'trialPeriod',
      'maxUsers',
      'maxProjects',
      'seatPrice',
      'annualSeatPrice',
      'annual',
      'planKey',
      'seatPriceToday'
    ];
  }

  /**
   * Format the monthly price
   */
  @computed
  get formattedMonthlyPrice() {
    return `$${this.seatPrice / 100}`;
  }

  /**
   * Format the annual price
   */
  @computed
  get formattedAnnualPrice() {
    return `$${this.annualSeatPrice / 100}`;
  }

  /**
   * Filter out the hidden features
   */
  @computed
  get visibleFeatures() {
    return this.features.filter(feature => {
      return feature.visible;
    });
  }

  /**
   * Lists the available features for the starter plan
   */
  @computed
  get starterPlanFeatures() {
    return [
      {
        type: 'Feature',
        code: 'starter',
        sequence: 26,
        visible: false,
        title: 'Starter Plan Feature'
      },
      {
        type: 'Feature',
        code: 'stamps_for_photos',
        sequence: 30,
        visible: false,
        title: 'Date/Time Stamps for photos'
      }
    ];
  }

  /**
   * Lists the available features for the basic plan
   */
  @computed
  get basicPlanFeatures() {
    return [
      {
        type: 'Feature',
        code: 'auto_weather_capture',
        sequence: 2,
        visible: true,
        title: 'Automatic Weather Capture'
      },
      {
        type: 'Feature',
        code: 'unlimited_pics',
        sequence: 4,
        visible: true,
        title: 'Unlimited HD Photos'
      },
      {
        type: 'Feature',
        code: 'v2t',
        sequence: 5,
        visible: true,
        title: 'Voice-to-Text'
      },
      {
        type: 'Feature',
        code: 'email_reminders',
        sequence: 7,
        visible: true,
        title: 'Email Daily Reports in App'
      },
      {
        type: 'Feature',
        code: 'bulk_download_media',
        sequence: 16,
        visible: true,
        title: 'Photo Management'
      },
      {
        type: 'Feature',
        code: 'basic',
        sequence: 27,
        visible: false,
        title: 'Basic Plan Feature'
      },
      {
        type: 'Feature',
        code: 'stamps_for_photos',
        sequence: 30,
        visible: false,
        title: 'Date/Time Stamps for photos'
      }
    ];
  }

  /**
   * Lists the available features for the professional plan
   */
  @computed
  get professionalPlanFeatures() {
    return [
      {
        type: 'Feature',
        code: 'auto_weather_capture',
        sequence: 2,
        visible: true,
        title: 'Automatic Weather Capture'
      },
      {
        type: 'Feature',
        code: 'unlimited_pics',
        sequence: 4,
        visible: true,
        title: 'Unlimited HD Photos'
      },
      {
        type: 'Feature',
        code: 'v2t',
        sequence: 5,
        visible: true,
        title: 'Voice-to-Text'
      },
      {
        type: 'Feature',
        code: 'email_reminders',
        sequence: 7,
        visible: true,
        title: 'Email Daily Reports in App'
      },
      {
        type: 'Feature',
        code: 'bulk_download_media',
        sequence: 16,
        visible: true,
        title: 'Photo Management'
      },
      {
        type: 'Feature',
        code: 'rt_notifications',
        sequence: 9,
        visible: true,
        title: 'Real-Time Notifications'
      },
      {
        type: 'Feature',
        code: 'hours_to_date',
        sequence: 10,
        visible: false,
        title: 'Project Hours To Date'
      },
      {
        type: 'Feature',
        code: 'cloud_integration',
        sequence: 11,
        visible: true,
        title: 'Cloud Integration',
        subFeatures: [
          {
            type: 'SubFeature',
            code: 'box',
            sequence: 1,
            title: 'Box',
            visible: true
          },
          {
            type: 'SubFeature',
            code: 'dropbox',
            sequence: 2,
            title: 'Dropbox',
            visible: true
          },
          {
            type: 'SubFeature',
            code: 'drive',
            sequence: 3,
            title: 'Google Drive',
            visible: true
          },
          {
            type: 'SubFeature',
            code: 'egnyte',
            sequence: 4,
            title: 'Egnyte',
            visible: true
          },
          {
            type: 'SubFeature',
            code: 'procore',
            sequence: 5,
            title: 'Procore',
            visible: true
          },
          {
            type: 'SubFeature',
            code: 'prolog',
            sequence: 6,
            title: 'Prolog',
            visible: true
          },
          {
            type: 'SubFeature',
            code: 'microsoftonedrive',
            sequence: 7,
            title: 'Microsoft OneDrive',
            visible: true
          },
          {
            type: 'SubFeature',
            code: 'autodeskbim360',
            sequence: 8,
            title: 'Autodesk BIM 360',
            visible: true
          }
        ]
      },
      {
        type: 'Feature',
        code: 'custom_survey',
        sequence: 12,
        visible: true,
        title: 'Customizable Survey'
      },
      {
        type: 'Feature',
        code: 'professional',
        sequence: 28,
        visible: false,
        title: 'Professional Plan Feature'
      },
      {
        type: 'Feature',
        code: 'stamps_for_photos',
        sequence: 30,
        visible: false,
        title: 'Date/Time Stamps for photos'
      }
    ];
  }

  /**
   * Lists the available features for the performance plan
   */
  @computed
  get performancePlanFeatures() {
    const features = [
      {
        type: 'Feature',
        code: 'auto_weather_capture',
        sequence: 2,
        visible: true,
        title: 'Automatic Weather Capture'
      },
      {
        type: 'Feature',
        code: 'unlimited_pics',
        sequence: 4,
        visible: true,
        title: 'Unlimited HD Photos'
      },
      {
        type: 'Feature',
        code: 'v2t',
        sequence: 5,
        visible: true,
        title: 'Voice-to-Text'
      },
      {
        type: 'Feature',
        code: 'email_reminders',
        sequence: 7,
        visible: true,
        title: 'Email Daily Reports in App'
      },
      {
        type: 'Feature',
        code: 'bulk_download_media',
        sequence: 16,
        visible: true,
        title: 'Photo Management'
      },
      {
        type: 'Feature',
        code: 'rt_notifications',
        sequence: 9,
        visible: true,
        title: 'Real-Time Notifications'
      },
      {
        type: 'Feature',
        code: 'hours_to_date',
        sequence: 10,
        visible: false,
        title: 'Project Hours To Date'
      },
      {
        type: 'Feature',
        code: 'cloud_integration',
        sequence: 11,
        visible: true,
        title: 'Cloud Integration',
        subFeatures: [
          {
            type: 'SubFeature',
            code: 'box',
            sequence: 1,
            title: 'Box',
            visible: true
          },
          {
            type: 'SubFeature',
            code: 'dropbox',
            sequence: 2,
            title: 'Dropbox',
            visible: true
          },
          {
            type: 'SubFeature',
            code: 'drive',
            sequence: 3,
            title: 'Google Drive',
            visible: true
          },
          {
            type: 'SubFeature',
            code: 'egnyte',
            sequence: 4,
            title: 'Egnyte',
            visible: true
          },
          {
            type: 'SubFeature',
            code: 'procore',
            sequence: 5,
            title: 'Procore',
            visible: true
          },
          {
            type: 'SubFeature',
            code: 'prolog',
            sequence: 6,
            title: 'Prolog',
            visible: true
          },
          {
            type: 'SubFeature',
            code: 'microsoftonedrive',
            sequence: 7,
            title: 'Microsoft OneDrive',
            visible: true
          },
          {
            type: 'SubFeature',
            code: 'autodeskbim360',
            sequence: 8,
            title: 'Autodesk BIM 360',
            visible: true
          }
        ]
      },
      {
        type: 'Feature',
        code: 'custom_survey',
        sequence: 12,
        visible: true,
        title: 'Customizable Survey'
      },
      {
        type: 'Feature',
        code: 'super_daily_report',
        sequence: 15,
        visible: true,
        title: 'Super Daily: Free Sub Access'
      },
      {
        type: 'Feature',
        code: 'pdf_project_summary',
        sequence: 17,
        visible: true,
        title: 'Weekly & Monthly Summary Reports'
      },
      {
        type: 'Feature',
        code: 'pdf_report_summary',
        sequence: 18,
        visible: true,
        title: 'Manpower Summary Reports'
      },
      {
        type: 'Feature',
        code: 'auto_email_reports',
        sequence: 19,
        visible: true,
        title: 'Automatic Email of Daily Reports'
      },
      {
        type: 'Feature',
        code: 'bulk_downloads',
        sequence: 20,
        visible: true,
        title: 'Bulk Downloads of Daily Reports'
      },
      {
        type: 'Feature',
        code: 'custom_report_templates',
        sequence: 21,
        visible: true,
        title: 'Customizable Templates',
        subFeatures: [
          {
            type: 'SubFeature',
            code: 'weekly_summaries',
            sequence: 1,
            title: 'Weekly Summarie',
            visible: true
          },
          {
            type: 'SubFeature',
            code: 'monthly_summaries',
            sequence: 2,
            title: 'Monthly Summaries',
            visible: true
          },
          {
            type: 'SubFeature',
            code: 'manpower_summaries',
            sequence: 3,
            title: 'Manpower Summaries',
            visible: true
          },
          {
            type: 'SubFeature',
            code: 'photo_reports',
            sequence: 4,
            title: 'Photo Reports',
            visible: true
          }
        ]
      },
      {
        type: 'Feature',
        code: 'toolbox_talks',
        sequence: 40,
        visible: true,
        title: 'Toolbox Talks'
      },
      {
        type: 'Feature',
        code: 'api_access',
        sequence: 22,
        visible: true,
        title: 'API Access'
      },
      {
        type: 'Feature',
        code: 'performance',
        sequence: 29,
        visible: false,
        title: 'Performance Plan Feature'
      },
      {
        type: 'Feature',
        code: 'stamps_for_photos',
        sequence: 30,
        visible: false,
        title: 'Date/Time Stamps for photos'
      }
    ];

    return features;
  }

  @computed
  get features() {
    const planFeatures = new Map()
      .set('starter', this.starterPlanFeatures)
      .set('basic', this.basicPlanFeatures)
      .set('pro', this.professionalPlanFeatures)
      .set('performance', this.performancePlanFeatures);

    return planFeatures.get(this.planKey);
  }

  @action.bound
  getPlanFeature(feature) {
    return (
      this.features &&
      this.features.find(item => {
        return item.code === feature.code;
      })
    );
  }
}
