import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';

import { t } from 'utils/translate';

const noWorkDoneFormPlugins = extendedPlugins;

const noWorkDoneFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const noWorkDoneFormFields = ['fromDate', 'toDate'];

const noWorkDoneFormLabels = {
  fromDate: t('from date'),
  toDate: t('to date')
};

const noWorkDoneFormRules = {
  fromDate: 'required|date',
  toDate: 'required|date|afterOrSameAsCustom:fromDate,YYYY-MM-DD'
};

class NoWorkDoneForm extends MobxReactForm {}

export {
  NoWorkDoneForm,
  noWorkDoneFormOptions,
  noWorkDoneFormFields,
  noWorkDoneFormLabels,
  noWorkDoneFormRules,
  noWorkDoneFormPlugins
};
