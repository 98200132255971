import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';

const subscriptionEditTrialFormPlugins = extendedPlugins;

const subscriptionEditTrialFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const subscriptionEditTrialFormFields = ['trialEndDate'];

const subscriptionEditTrialFormRules = {
  trialEndDate: 'string'
};

const subscriptionEditTrialFormLabels = {
  trialEndDate: 'Trial End Date'
};

class SubscriptionEditTrialForm extends MobxReactForm {}

export {
  SubscriptionEditTrialForm,
  subscriptionEditTrialFormOptions,
  subscriptionEditTrialFormFields,
  subscriptionEditTrialFormRules,
  subscriptionEditTrialFormLabels,
  subscriptionEditTrialFormPlugins
};
