import isFunction from 'lodash.isfunction';

const findResult = (results, name, format = 'long_name') => {
  var result = results.find(obj => {
    return obj.types[0] === name;
  });
  return result ? result[format] : '';
};

export default (results, accuracy = null) => {
  const addressComponents = results.address_components;

  return {
    formattedAddress: results.formatted_address,
    streetAddress: `${findResult(
      addressComponents,
      'street_number'
    )} ${findResult(addressComponents, 'route')}`,
    city: findResult(addressComponents, 'locality'),
    postCode: findResult(addressComponents, 'postal_code'),
    state: findResult(addressComponents, 'administrative_area_level_1'),
    country: findResult(addressComponents, 'country', 'short_name'),
    geolocation: {
      lat: isFunction(results.geometry.location.lat)
        ? results.geometry.location.lat()
        : results.geometry.location.lat,
      lng: isFunction(results.geometry.location.lng)
        ? results.geometry.location.lng()
        : results.geometry.location.lng
    },
    accuracy: accuracy,
    bounds: results.geometry.bounds,
    viewport: results.geometry.viewport
  };
};
