import styled from 'styled-components';
import { borderRadius } from 'polished';

export default styled.div`
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.lightGrey5};
  ${borderRadius('bottom', '4px')};
  display: block;
  padding: 10px 2rem;
  text-transform: capitalize;
  text-align: center;
  > span {
    cursor: pointer;
  }
  > span > span {
    color: ${props => props.theme.colors.rakenOrange};
    font-weight: 700;
  }
`;
