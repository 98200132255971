import MobxReactForm from 'mobx-react-form';
import { action, reaction } from 'mobx';
import pick from 'lodash.pick';
import extendedPlugins from '../utils/extendedPlugins';
import uuid from 'uuid-v4';
import { t } from 'utils/translate';
import trimObject from '../../utils/trimObject';

const settingsSurveyFormPlugins = extendedPlugins;

const settingsSurveyFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false,
  retrieveOnlyEnabledFields: false
};

const settingsSurveyFormFields = [
  'includeMyCompanyQuestionsForSubcontractors',
  'questions',
  'questions[].uuid',
  'questions[].id',
  'questions[].category',
  'questions[].question',
  'questions[].tag',
  'questions[].required',
  'questions[].readOnly',
  'collaboratorQuestions',
  'collaboratorQuestions[].uuid',
  'collaboratorQuestions[].id',
  'collaboratorQuestions[].category',
  'collaboratorQuestions[].question',
  'collaboratorQuestions[].tag',
  'collaboratorQuestions[].readOnly'
];

const settingsSurveyFormRules = {
  includeMyCompanyQuestionsForSubcontractors: 'boolean',
  'questions[].category': 'required|string',
  'questions[].question': 'required|string',
  'collaboratorQuestions[].category': 'required|string',
  'collaboratorQuestions[].question': 'required|string'
};

const settingsSurveyFormDisabled = {};

const settingsSurveyFormLabels = {};

const settingsSurveyFormValues = {
  includeMyCompanyQuestionsForSubcontractors: false,
  questions: [
    {
      uuid: uuid(),
      category: 'CATEGORY_SAFETY',
      question: t('Any accidents on site today?'),
      readOnly: false
    },
    {
      uuid: uuid(),
      category: 'CATEGORY_DELAYS',
      question: t('Any schedule delays occur?'),
      readOnly: false
    },
    {
      uuid: uuid(),
      category: 'CATEGORY_DELAYS_WEATHER',
      question: t('Any weather delays occur?'),
      readOnly: false
    },
    {
      uuid: uuid(),
      category: 'CATEGORY_VISITORS',
      question: t('Any visitors on site?'),
      readOnly: false
    },
    {
      uuid: uuid(),
      category: 'CATEGORY_DELAYS',
      question: t("Any areas that can't be worked on?"),
      readOnly: false
    },
    {
      uuid: uuid(),
      category: 'CATEGORY_EQUIPMENT',
      question: t('Any equipment rented on site?'),
      readOnly: false
    }
  ],
  collaboratorQuestions: []
};

class SettingsSurveyForm extends MobxReactForm {
  constructor(settings, options) {
    super(settings, options);

    this.parent = options.parent;
    this.rootStore = this.parent.rootStore;

    this.setupReactions();
  }

  setupReactions() {
    this.reactToIncludeMyCompanyQuestionsForSubcontractors = reaction(
      () => this.$('includeMyCompanyQuestionsForSubcontractors').value,
      value => {
        if (value) {
          this.copyQuestions();
        } else {
          this.clearCollaboratorQuestions();
        }
      }
    );
  }

  tearDownReactions() {
    this.reactToIncludeMyCompanyQuestionsForSubcontractors();
  }

  @action.bound
  copyQuestions() {
    const questions = this.$('questions').values();
    const collaboratorQuestions = this.$('collaboratorQuestions').values();

    questions.reverse().forEach(question => {
      const sameQuestion = collaboratorQuestions.find(collaboratorQuestion => {
        return (
          collaboratorQuestion.question === question.question &&
          collaboratorQuestion.category === question.category
        );
      });

      if (sameQuestion) {
        collaboratorQuestions.splice(
          collaboratorQuestions.indexOf(sameQuestion),
          1
        );
      }

      collaboratorQuestions.unshift(
        Object.assign({}, pick(question, ['category', 'question']), {
          uuid: question.uuid,
          readOnly: false
        })
      );
    });

    this.update({
      collaboratorQuestions: collaboratorQuestions
    });
  }

  @action.bound
  clearCollaboratorQuestions() {
    const questions = this.$('questions').value;
    const collaboratorQuestions = this.$('collaboratorQuestions').value.filter(
      collaboratorQuestion => {
        return !questions.find(
          question =>
            question.category === collaboratorQuestion.category &&
            question.question === collaboratorQuestion.question
        );
      }
    );

    this.update({
      collaboratorQuestions: collaboratorQuestions
    });
  }

  @action.bound
  handleToggleEnableQuestionsForSubcontractors() {
    const includeMyCompanyQuestionsForSubcontractors = this.$(
      'includeMyCompanyQuestionsForSubcontractors'
    ).value;

    this.update({
      includeMyCompanyQuestionsForSubcontractors: !includeMyCompanyQuestionsForSubcontractors
    });
  }

  trimmedValues() {
    return trimObject(this.values());
  }
}

export {
  SettingsSurveyForm,
  settingsSurveyFormOptions,
  settingsSurveyFormFields,
  settingsSurveyFormRules,
  settingsSurveyFormValues,
  settingsSurveyFormLabels,
  settingsSurveyFormPlugins,
  settingsSurveyFormDisabled
};
