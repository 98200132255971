import { computed, action, toJS, observable } from 'mobx';
import { Model } from 'mobx-mc';
import orderBy from 'lodash.orderby';
import kebabCase from 'lodash.kebabcase';

export default class CostCode extends Model {
  @observable new;
  @observable newForBudget;

  constructor(data, options) {
    super(data, options);

    this.new = false;
    this.newForBudget = false;
  }

  get restAttributes() {
    return [
      'uuid',
      'code',
      'description',
      'division',
      'projectUuid',
      'type',
      'costCode',
      'isDefault',
      'projects',
      'deleted',
      'externalId'
    ];
  }

  get urlRoot() {
    return `${this.rootStore.urlMicroService('performanceTracking')}/costcodes`;
  }

  get restAttributeDefaults() {
    return {
      projects: []
    };
  }

  idAttribute() {
    return 'uuid';
  }

  @computed get currentProject() {
    return this.rootStore.projectUI.project;
  }

  @computed
  get activeProject() {
    return (
      this.currentProject || {
        uuid: this.rootStore.timesheetsUI?.timeCardToEdit?.projectUuid
      }
    );
  }

  @computed
  get formValues() {
    let budgetedHours;

    if (this.activeProject) {
      budgetedHours = this.getBudgetedHoursForProject(this.activeProject.uuid);
    }

    return {
      code: this.code,
      division: this.division,
      description: this.projectOrCompanyDescription,
      budgetedHours: budgetedHours,
      projects: toJS(this.projects),
      uuid: this.uuid,
      isDefault: this.isDefault
    };
  }

  @computed
  get formTimeCardValues() {
    return {
      uuid: this.uuid,
      code: this.code,
      division: this.division,
      description: this.projectOrCompanyDescription
    };
  }

  @computed
  get formBudgetValues() {
    const materials = this.getMaterialsForProject(this.activeProject.uuid).map(
      materialItem => {
        return {
          uuid: materialItem.material.uuid,
          quantity: materialItem.quantity
        };
      }
    );

    /*if no materials add one empty*/
    if (materials.length === 0) {
      materials.push({
        uuid: '',
        quantity: ''
      });
    }

    return {
      uuid: this.uuid,
      budgetedHours: this.getBudgetedHoursForProject(this.activeProject.uuid),
      materials
    };
  }

  @computed get projectLevelDescription() {
    if (this.activeProject) {
      const projectDesc = this.getDescriptionForProject(
        this.activeProject.uuid
      );
      return projectDesc && projectDesc.trim();
    }

    return null;
  }

  @computed get projectOrCompanyDescription() {
    return (
      (this.parent && this.getDescriptionForProject(this.parent.projectUuid)) ||
      this.projectLevelDescription ||
      this.description ||
      ''
    );
  }

  @computed
  get displayCode() {
    const { code, division } = this;

    return this.rootStore.me && this.rootStore.me.showTimeCardsDivision
      ? `${division}-${code}`
      : code;
  }

  @computed
  get displayDivisionCodeDescription() {
    const { code, division } = this;
    return `${division} - ${code} - ${this.projectOrCompanyDescription}`;
  }

  @computed
  get costCodeKey() {
    const { code, division } = this;

    return `${division}-${code}`;
  }

  @computed get identificationString() {
    return `${this.division}, ${this.code}`;
  }

  @computed get slug() {
    return kebabCase(`${this.division}-${this.code}`);
  }

  getProjectByUuid = uuid => {
    return this.projects.find(project => project.projectUuid === uuid);
  };

  getBudgetedHoursForProject = uuid => {
    const project = this.getProjectByUuid(uuid);

    if (!project) return '';

    return project.budgetedHours || '';
  };

  getMaterialsForProject = uuid => {
    const project = this.getProjectByUuid(uuid);

    if (!project) return [];

    const materials = orderBy(
      project.materials,
      [
        materialInfo => {
          const relatedMaterial = this.rootStore.materials.get(
            materialInfo.material.uuid
          );

          return relatedMaterial && relatedMaterial.name.toLowerCase();
        }
      ],
      ['asc']
    );

    return materials || [];
  };

  getBudgetedQuantitiesForProject = uuid => {
    const project = this.getProjectByUuid(uuid);

    if (!project) return [];

    const materials = orderBy(project.materials, ['material.name'], ['asc']);

    return materials || [];
  };

  getDescriptionForProject = uuid => {
    const project = this.getProjectByUuid(uuid);

    if (!project) return '';

    return project.description || '';
  };

  @action.bound toggleProjectForCode(uuid) {
    if (this.getProjectByUuid(uuid)) {
      this.removeProjectByUuid(uuid);
    } else {
      this.addProjectByUuid(uuid);
    }
  }

  @action.bound removeProjectByUuid(uuid) {
    this.projects.remove(this.getProjectByUuid(uuid));

    return this.save({
      projects: toJS(this.projects)
    });
  }

  @action.bound addProjectByUuid(uuid) {
    this.projects.push({
      projectUuid: uuid,
      budgetedHours: null
    });

    return this.save({
      projects: toJS(this.projects)
    });
  }

  @action.bound addProject(project, options = {}) {
    this.projects.push(project);

    if (options.save) {
      return this.save({
        projects: toJS(this.projects)
      });
    }
  }

  @action.bound removeProject(project, options = {}) {
    this.projects.remove(project);

    if (options.save) {
      return this.save({
        projects: toJS(this.projects)
      });
    }
  }

  @computed get hasMultipleProjects() {
    return this.projects.length > 1;
  }

  @action.bound setAsNew() {
    this.new = true;
  }

  @action.bound clearNewState() {
    this.new = false;
  }

  @action.bound setAsNewForBudget() {
    this.newForBudget = true;
  }

  @action.bound clearNewForBudgetState() {
    this.newForBudget = false;
  }
}
