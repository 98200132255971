import { computed } from 'mobx';
import { RakenCollection } from 'stores/lib';
import SageIntacctEmployeeMatch from 'stores/models/integrations/SageIntacctEmployeeMatch';

export default class SageIntacctEmployeeMatches extends RakenCollection {
  url() {
    return `${this.rootStore.urlMicroService('integrations')}/companies/${
      this.company.uuid
    }/integrations/1024/employee/matches`;
  }

  model() {
    return SageIntacctEmployeeMatch;
  }

  parse(data) {
    return data.matches;
  }

  @computed get company() {
    if (this.rootStore.isSuperAdmin) {
      return this.parent.activeCompany;
    }

    return this.rootStore.me.company;
  }
}
