import UIStore from 'stores/ui/UIStore';
import { action, observable } from 'mobx';
import request from 'axios';
import SuperAdminUsersUI from './users/SuperAdminUsersUI';
import SuperAdminCompaniesUI from './companies/SuperAdminCompaniesUI';
import SuperAdminProjectsUI from './projects/SuperAdminProjectsUI';
import SuperAdminSuperUsersUI from './superUsers/SuperAdminSuperUsersUI';
import SuperAdminDemosUI from './demos/SuperAdminDemosUI';

export default class SuperAdminUI extends UIStore {
  @observable extendedPermissions;

  constructor(options) {
    super(options);

    this.superAdminUsersUI = new SuperAdminUsersUI({
      rootStore: this.rootStore,
      parent: this
    });

    this.superAdminSuperUsersUI = new SuperAdminSuperUsersUI({
      rootStore: this.rootStore,
      parent: this
    });

    this.superAdminCompaniesUI = new SuperAdminCompaniesUI({
      rootStore: this.rootStore,
      parent: this
    });

    this.superAdminProjectsUI = new SuperAdminProjectsUI({
      rootStore: this.rootStore,
      parent: this
    });

    this.superAdminDemosUI = new SuperAdminDemosUI({
      rootStore: this.rootStore,
      parent: this
    });

    this.extendedPermissions = null;
  }

  @action.bound
  async checkExtendedPermissions() {
    if (typeof this.extendedPermissions === 'boolean') return;

    const response = await request.get('/ra/sadmin/superusers/permitted');
    this.extendedPermissions = response.data.permitted;
  }
}
