import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { Overlay } from 'react-overlays';
import Icon from './Icon';
import Text from './Text';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  height: ${props => (props.size === 'small' ? 25 : 40)}px;
  margin-right: ${props => props.marginRight || 0}px;
  margin-left: ${props => props.marginLeft || 0}px;
  margin-bottom: ${props => props.marginBottom || 0}px;
  position: relative;
  width: ${props => (isNaN(props.width) ? props.width : `${props.width}px`)};
`;

const ClearButton = styled.div`
  position: absolute;
  top: 50%;
  right: ${props => (props.size === 'small' ? 5 : 10)}px;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
  svg {
    stroke: ${props => props.theme.colors.lightGrey3};
    stroke-linecap: round;
    fill: ${props => props.theme.colors.lightGrey3};
  }
`;

const CaretButton = styled.div`
  position: absolute;
  top: 50%;
  right: ${props => (props.size === 'small' ? 5 : 10)}px;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
  svg {
    fill: ${props => props.theme.colors.lightGrey3};
  }
`;

const getBorder = (props, isFocused) => {
  if (props.hasError) {
    return `2px solid ${props.theme.colors.red}`;
  }

  if (props.show || isFocused) {
    return `2px solid ${props.theme.colors[props.focusColor]}`;
  }

  return `1px solid ${props.theme.colors.lightGrey5}`;
};

const getPadding = (props, isFocused) => {
  if (props.size === 'small') {
    if (isFocused) {
      return `0 18px 0 5px`;
    }

    return `0 19px 0 6px`;
  } else {
    if (isFocused) {
      return `0 29px 0 9px`;
    }

    return `0 30px 0 10px`;
  }
};

const Button = styled.button`
  position: relative;
  background: ${props =>
    props.disabled || props.readOnly
      ? props.theme.colors.cream
      : props.theme.colors.white};
  border: ${props => getBorder(props)};
  border-radius: 3px;
  ${props =>
    props.isAddOnControl &&
    css`
      border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    `};
  box-sizing: border-box;
  color: ${props => props.theme.colors.charcoalGrey};
  cursor: ${props =>
    props.disabled || props.readOnly ? 'not-allowed' : 'pointer'};
  display: block;
  font-family: ${props => props.theme.fonts.primary};
  font-size: 1.4rem;
  font-weight: 400;
  height: ${props => (props.size === 'small' ? 25 : 40)}px;
  padding: ${props => getPadding(props, false)};
  text-align: left;
  width: 100%;
  &:focus {
    border: ${props => getBorder(props, true)};
    padding: ${props => getPadding(props, true)};
    outline: 0;
  }
  ${Text} {
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
  }
  > ${Text} {
    display: block;
  }
  ${props =>
    props.fadeEdge &&
    css`
      &:before {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 10rem;
        background: linear-gradient(
          to left,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0) 100%
        );
        background: -webkit-linear-gradient(
          to left,
          ${props => props.theme.colors.white} 20%,
          transparent
        );
        z-index: 5;
        content: '';
      }
    `};
`;

@observer
class SelectBoxWrapper extends Component {
  state = {
    open: false,
    focusedItem: -1,
    keyDirection: 'down'
  };

  static propTypes = {
    fadeEdge: PropTypes.bool
  };

  static defaultProps = {
    activeColor: 'charcoalGrey',
    focusColor: 'blueAccent',
    container: document.body,
    marginBottom: 0,
    fadeEdge: false,
    autoFocus: false,
    tabIndex: 0
  };

  componentDidMount() {
    const { comboInput } = this.props;

    this.mounted = true;
    window.addEventListener('resize', this.forceUpdateIfOpen, true);
    window.addEventListener('scroll', this.forceUpdateIfOpen, true);

    if (comboInput && this.wrapper) {
      this.wrapper.querySelector('input').addEventListener('keydown', () => {
        this.setState({
          open: true
        });
      });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  forceUpdateIfOpen = () => {
    if (!this.state.open) return;

    if (this.mounted) {
      this.forceUpdate();
    }
  };

  getOrientation = () => {
    if (!this.wrapper || this.props.disableTopOrientation) {
      return 'bottom';
    }

    return window.innerHeight - this.wrapper.getBoundingClientRect().bottom <
      400
      ? 'top'
      : 'bottom';
  };

  handleToggleOpen = e => {
    if (this.props.disabled || this.props.readOnly) return;

    e.stopPropagation();

    if (!this.state.open && this.props.onOpen) {
      this.props.onOpen();
    }

    this.setState({
      open: !this.state.open,
      focusedItem: this.state.open ? -1 : 0,
      keyDirection: 'down'
    });
  };

  handleClose = e => {
    this.setState({
      open: false,
      focusedItem: -1
    });

    if (this.wrapper.contains(e.target)) {
      setTimeout(() => {
        if (this.buttonRef) this.buttonRef.focus();
      });
    }

    this.props.onClose && this.props.onClose();
  };

  handleKeyDown = e => {
    if (this.props.disabled || this.props.readOnly) return;

    switch (e.keyCode) {
      case 38:
        this.handleFocusPreviousItem(e);
        break;
      case 40:
        this.handleFocusNextItem(e);
        break;
      default:
        break;
    }
  };

  handleFocusPreviousItem = e => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (this.state.focusedItem === -1) return;

    if (this.state.focusedItem === 0) {
      this.setState({
        focusedItem: this.props.itemLength - 1,
        keyDirection: 'up'
      });
    } else {
      this.setState({
        focusedItem: this.state.focusedItem - 1,
        keyDirection: 'up'
      });
    }
  };

  handleFocusNextItem = e => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (
      this.state.focusedItem < 0 ||
      this.state.focusedItem === this.props.itemLength - 1
    ) {
      this.setState({
        open: true,
        focusedItem: 0,
        keyDirection: 'down'
      });
    } else {
      this.setState({
        open: true,
        focusedItem: this.state.focusedItem + 1,
        keyDirection: 'down'
      });
    }
  };

  handleSkipFocusedItem = () => {
    if (this.state.keyDirection === 'up') {
      this.handleFocusPreviousItem();
    } else {
      this.handleFocusNextItem();
    }
  };

  handleClearFocusedItem = () => {
    this.setState({
      focusedItem: -1
    });
  };

  handleRef = ref => {
    this.buttonRef = ref;
    this.props.innerRef && this.props.innerRef(ref);
  };

  render() {
    const {
      width,
      marginLeft,
      marginRight,
      marginBottom,
      selectedItem,
      size,
      disabled,
      focusColor,
      activeColor,
      hasError,
      isValid,
      tabIndex,
      container,
      comboInput,
      bold,
      fadeEdge,
      hasToggle,
      dataQA,
      disableAsReadOnly,
      autoFocus,
      onClear,
      isAddOnControl
    } = this.props;

    const orientation = this.getOrientation();

    return (
      <Wrapper
        size={size}
        width={width}
        marginLeft={marginLeft}
        marginRight={marginRight}
        marginBottom={marginBottom}
        ref={wrapper => (this.wrapper = wrapper)}
        onClick={e => {
          if (hasToggle) return;
          this.handleToggleOpen(e);
        }}
        onKeyDown={this.handleKeyDown}
        disabled={disabled}
        data-qa={dataQA}
      >
        {!comboInput && (
          <Button
            data-qa={`button_${dataQA}`}
            size={size}
            selectedItem={selectedItem}
            disabled={disabled && !disableAsReadOnly}
            readOnly={disabled && disableAsReadOnly}
            focusColor={focusColor}
            hasError={hasError}
            isValid={isValid}
            tabIndex={tabIndex}
            ref={this.handleRef}
            isAddOnControl={isAddOnControl}
            type="button"
            fadeEdge={fadeEdge}
            onClick={e => {
              e.preventDefault();
              this.buttonRef.focus();
              if (!hasToggle) return;
              this.handleToggleOpen(e);
            }}
            autoFocus={autoFocus}
            clearButton={onClear}
          >
            <Text color={activeColor} bold={bold}>
              {selectedItem}
            </Text>

            {onClear && (
              <ClearButton
                onClick={e => {
                  e.stopPropagation();
                  onClear();
                }}
              >
                <Icon kind="close" size={size === 'small' ? 6 : 12} />
              </ClearButton>
            )}

            {!onClear && (
              <CaretButton>
                <Icon kind="expand" size={size === 'small' ? 10 : 15} />
              </CaretButton>
            )}
          </Button>
        )}

        {comboInput}

        <Overlay
          show={this.state.open}
          onHide={this.handleClose}
          target={this.wrapper}
          rootClose
          placement={orientation}
          shouldUpdatePosition={true}
          container={container}
          autoFocus={true}
        >
          {this.props.children(
            this.state.open,
            orientation,
            this.handleToggleOpen,
            this.handleClose,
            this.state.focusedItem,
            this.handleClearFocusedItem,
            this.handleSkipFocusedItem
          )}
        </Overlay>
      </Wrapper>
    );
  }
}

export default SelectBoxWrapper;
