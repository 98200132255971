import MobxReactForm from 'mobx-react-form';
import validatorjs from 'validatorjs';
import { t } from 'utils/translate';

const userFormPlugins = { dvr: validatorjs };

const userFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const userFormFields = ['firstName', 'lastName', 'phoneNumber'];

const userFormRules = {
  firstName: 'required|string|max:255',
  lastName: 'required|string|max:255',
  phoneNumber: 'required'
};

const userFormValues = {};

const userFormLabels = {
  firstName: t('first name'),
  lastName: t('last name'),
  phoneNumber: t('phone number')
};

class UserForm extends MobxReactForm {}

export {
  UserForm,
  userFormOptions,
  userFormFields,
  userFormRules,
  userFormValues,
  userFormLabels,
  userFormPlugins
};
