import MobxReactForm from 'mobx-react-form';
import validatorjs from 'validatorjs';
import { t } from 'utils/translate';

const emailReportFormPlugins = { dvr: validatorjs };

const emailReportFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const emailReportFormFields = [
  'subject',
  'recipients[]',
  'recipient',
  'message',
  'isSuperDaily'
];

const emailReportFormRules = {
  subject: 'string|required',
  message: 'string|required',
  recipients: 'array|required',
  'recipients[]': 'email',
  recipient: 'required_without:recipients|email',
  isSuperDaily: 'boolean|required'
};

const emailReportFormLabels = {
  subject: t('subject of email'),
  recipients: t('send to'),
  message: t('email message'),
  isSuperDaily: t('Send Super Daily')
};

class EmailReportForm extends MobxReactForm {}

export {
  EmailReportForm,
  emailReportFormOptions,
  emailReportFormFields,
  emailReportFormRules,
  emailReportFormLabels,
  emailReportFormPlugins
};
