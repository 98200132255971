import { computed } from 'mobx';
import { Model } from 'mobx-mc';

export default class JobTitle extends Model {
  get restAttributes() {
    return ['id', 'type', 'name'];
  }

  @computed
  get value() {
    return this.name;
  }
}
