import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import trimObject from 'utils/trimObject';

const membersFormPlugins = extendedPlugins;

const membersFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const membersFormFields = [
  'message',
  'members[]',
  'members[].username',
  'members[].firstName',
  'members[].lastName',
  'members[].employeeId',
  'members[].phone',
  'members[].company',
  'members[].company.id',
  'members[].company.name',
  'members[].projects[]',
  'members[].role'
];

const membersFormRules = {
  message: 'string',
  'members[].username': 'email|max:200|required',
  'members[].firstName': 'string|max:255|required',
  'members[].lastName': 'string|max:255|required',
  'members[].employeeId': 'string|max:100',
  'members[].phone': 'string|max:30',
  'members[].company': 'required',
  'members[].role':
    'in:ROLE_ACCOUNT_ADMIN,ROLE_ADMIN,ROLE_PROJECT_MEMBER,ROLE_USER'
};

const membersFormValues = {
  projects: [],
  role: '',
  company: {
    id: '',
    name: ''
  }
};

class MembersForm extends MobxReactForm {
  trimmedValues() {
    return trimObject(this.values());
  }
}

export {
  MembersForm,
  membersFormOptions,
  membersFormFields,
  membersFormRules,
  membersFormPlugins,
  membersFormValues
};
