import { Model } from 'mobx-mc';

export default class ObservationStatus extends Model {
  get restAttributes() {
    return [
      'uuid',
      'name',
      'closedState',
      'initialState',
      'order',
      'signatureRequired'
    ];
  }

  idAttribute() {
    return 'uuid';
  }
}
