import request from 'axios';
import debounce from 'lodash.debounce';
import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import { observable, action, computed, reaction } from 'mobx';
import { BASE_DEBOUNCE } from 'fixtures/constants';

import UIStore from 'stores/ui/UIStore';
import QuickbooksCustomers from 'stores/collections/integrations/QuickbooksCustomers';

import alertErrorHandler from 'utils/alertErrorHandler';

import history from 'utils/history';
import formatIntegrationSyncMessage from 'utils/formatIntegrationSyncMessage';

export default class IntegrationQBOProjectImportUI extends UIStore {
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;

  constructor(options) {
    super(options);

    this.quickbooksProjects = new QuickbooksCustomers(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.loading = true;

    this.selectedProjects = observable([]);

    this.searchQuery = '';

    this.fetchProjectsDebounced = debounce(this.fetchProjects, BASE_DEBOUNCE);
  }

  @computed get activeCompany() {
    return this.parent.activeCompany;
  }

  @computed get projects() {
    return this.quickbooksProjects;
  }

  @computed get hasProjects() {
    return this.projects.hasModels;
  }

  @action.bound setup() {
    this.setupReactions();
    this.fetchProjects();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        this.fetchProjects();
      }
    );

    this.reactToSearchQuery = reaction(
      () => this.searchQuery,
      params => {
        this.fetchProjectsDebounced();
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
    this.reactToSearchQuery && this.reactToSearchQuery();
  }

  @action.bound
  async fetchProjects() {
    this.loading = true;

    await this.projects.fetch({
      params: pickBy(
        Object.assign(this.params, {
          query: this.searchQuery
        }),
        identity
      )
    });

    this.loading = false;
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
  }

  @computed get params() {
    return {
      limit: 50,
      unlinkedOnly: true
    };
  }

  @computed
  get totalPages() {
    return Math.ceil(this.projects.totalElements / this.pageSize);
  }

  @computed get hasSelectedProjects() {
    return this.selectedProjects.length > 0;
  }

  @action.bound
  toggleSelectProject(projectId) {
    if (this.selectedProjects.find(id => id === projectId)) {
      this.selectedProjects.remove(projectId);
    } else {
      this.selectedProjects.push(projectId);
    }
  }

  @computed
  get allProjectsSelected() {
    return (
      this.hasProjects &&
      this.projects.models.every(project =>
        this.selectedProjects.includes(project.id)
      )
    );
  }

  @action.bound
  toggleSelectAllProjects() {
    if (this.allProjectsSelected) {
      this.selectedProjects.replace(
        this.selectedProjects.filter(id => {
          return !this.projects.models.map(project => project.id).includes(id);
        })
      );
    } else {
      this.projects.models.forEach(project => {
        if (!this.selectedProjects.includes(project.id)) {
          this.selectedProjects.push(project.id);
        }
      });
    }
  }

  @computed get someProjectsSelected() {
    return this.hasSelectedProjects && !this.allProjectsSelected;
  }

  @action.bound clearAllSelectedProjects() {
    this.selectedProjects.clear();
  }

  @computed get disableCancelButton() {
    return this.saving;
  }

  @computed get disableSaveButton() {
    return this.saving;
  }

  @action.bound async confirmImportQuickbooksProjects() {
    if (this.saving) return;

    this.saving = true;

    this.clearValidationDetails();

    try {
      const { data } = await request.post(`${this.projects.url()}/sync`, {
        externalIds: this.selectedProjects.slice()
      });

      const successfulCount = data.collection.filter(
        syncResult => syncResult.successful === true
      ).length;
      const failedCount = data.collection.length - successfulCount;
      const title = formatIntegrationSyncMessage(
        'Project import',
        successfulCount,
        failedCount
      );
      const snackbar = successfulCount > 0 ? 'warning' : 'error';
      this.notifications.pushNotification({
        snackbar,
        icon: 'checkmark',
        title
      });

      this.cancelImportQuickbooksProjects();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound async cancelImportQuickbooksProjects() {
    if (this.isSuperAdmin) {
      history.push(
        `/companies/${this.activeCompany.uuid}/integrations/1019/projects`
      );
    } else {
      history.push('/company-settings/integrations/1019/projects');
    }
  }

  @action.bound clearUIState() {
    this.loading = true;
    this.searchQuery = '';
    this.selectedProjects.clear();
    this.projects.clear();
    this.clearValidationDetails();
  }
}
