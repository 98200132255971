import styled, { css } from 'styled-components';

const Ellipsis = styled.div`
  ${props =>
    props.useChild
      ? css`
          & > * {
            display: ${props => (props.inlineBlock ? 'inline-block' : 'block')};
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
            ${props =>
              props.maxWidth &&
              css`
                max-width: ${props.maxWidth};
              `};
          }
        `
      : css`
          display: ${props => (props.inlineBlock ? 'inline-block' : 'block')};
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 100%;
          ${props =>
            props.maxWidth &&
            css`
              max-width: ${props.maxWidth};
            `};
        `}
`;

export default Ellipsis;
