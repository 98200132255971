import { action } from 'mobx';
import TeamMemberCertificationsUI from './TeamMemberCertificationsUI';
import history from 'utils/history';

export default class TeamWorkerCertificationsUI extends TeamMemberCertificationsUI {
  @action.bound async deleteMemberCertification(memberCertification) {
    await this.authorization.checkFeatureAccess('EditWorkers');

    this.selectedMemberCertification = memberCertification;
    this.showModal('DeleteModal');
  }

  @action.bound async addMemberCertification() {
    await this.authorization.checkFeatureAccess('EditWorkers');

    history.push({
      pathname: `${this.entryForEdit.viewUrl}/certifications/add`
    });
  }

  @action.bound async editMemberCertification(memberCertification) {
    await this.authorization.checkFeatureAccess('EditWorkers');

    history.push({
      pathname: `${this.entryForEdit.viewUrl}/certifications/${memberCertification.uuid}`
    });
  }
}
