import moment from 'moment';
import omit from 'lodash.omit';
import kebabCase from 'lodash.kebabcase';
import { Model } from 'mobx-mc';
import { action, computed, observable } from 'mobx';
import { t } from 'utils/translate';
import Settings from './Settings';

import Company from './Company';
import { getRoleName } from 'utils/roles';

export default class User extends Model {
  @observable settings;

  get restAttributes() {
    return [
      'uuid',
      'employeeId',
      'type',
      'username',
      'firstName',
      'classification',
      'lastName',
      'phoneNumber',
      'phone',
      'email',
      'images',
      'avatar',
      'permissions',
      'isCollaborator',
      'isPending',
      'role',
      'signature',
      'title',
      'company',
      'inviteId',
      'userId',
      'inviteSentTimestamp',
      'lastLogin',
      'uuid',
      'projectTeamIds',
      'projectTeamUuids',
      'invitedBy',
      'invitedTo',
      'status',
      'createdDate',
      'classificationName',
      'classificationUuid',
      'workerUuid',
      'projectUuids',
      'projectCount',
      'addToActiveProjects',
      'isViolator',
      'companyUuid',
      'userGroups',
      'updatedTimestamp',
      'createdTimestamp',
      'groupUuids'
    ];
  }

  idAttribute() {
    return 'uuid';
  }

  isViolator() {
    return 'isViolator';
  }

  get urlRoot() {
    if (!this.rootStore.isSuperAdmin) {
      return `/ra/companies/${this.rootStore.me.company?.uuid}/members`;
    }

    return '';
  }

  get restAttributeDefaults() {
    return {
      title: '',
      employeeId: '',
      projectTeamIds: [],
      projectUuids: [],
      groupUuids: []
    };
  }

  @action.bound
  parse(attributes) {
    // Keep reference to legacy id until we full switch to members
    attributes.userId = attributes.id;

    this.parseCompany(attributes);
    this.parseSettings(attributes);

    return {
      ...omit(attributes, ['company', 'settings'])
    };
  }

  @action.bound
  parseSettings(attributes) {
    if (this.settings) {
      this.settings.set(attributes.settings);
    } else {
      this.settings = new Settings(attributes.settings, {
        parent: this,
        rootStore: this.rootStore
      });
    }
  }

  @action.bound
  parseCompany(attributes) {
    if (!attributes.company) return;

    if (this.company) {
      this.company.set(attributes.company);
    } else {
      this.company = new Company(attributes.company, {
        rootStore: this.rootStore
      });
    }
  }

  @computed
  get fullName() {
    if (this.firstName === 'Unknown') {
      return 'John Smith';
    }

    if (!this.firstName && !this.lastName) return '';

    return `${this.firstName} ${
      this.lastName === 'null' || !this.lastName ? '' : this.lastName
    }`;
  }

  @computed get slug() {
    return kebabCase(this.fullName);
  }

  @computed
  get isMe() {
    return this.rootStore.me.id === this.id;
  }

  @computed
  get isTeamMember() {
    return this.company?.id === this.rootStore.me.company.id;
  }

  @computed
  get avatarImage() {
    if (this.avatar) {
      return this.avatar.thumbUrl;
    }
    // If no image fallback to the default
    return this.rootStore.assetsURL + '/images/avatar.png';
  }

  @computed
  get isAccountAdmin() {
    return this.role === 'ROLE_ACCOUNT_ADMIN';
  }

  @computed
  get isAdmin() {
    return (
      this.role === 'ROLE_SUPER_USER' ||
      this.role === 'ROLE_ACCOUNT_ADMIN' ||
      this.role === 'ROLE_ADMIN'
    );
  }

  @computed
  get isProjectMember() {
    return this.role === 'ROLE_PROJECT_MEMBER';
  }

  @computed
  get isUser() {
    return this.role === 'ROLE_USER';
  }

  @computed
  get createdAtFormatted() {
    return (
      this.createdTimestamp &&
      moment(this.createdTimestamp).format('YYYY-MM-DD')
    );
  }

  @computed
  get updatedAtFormatted() {
    return (
      this.updatedTimestamp &&
      moment(this.updatedTimestamp).format('YYYY-MM-DD')
    );
  }

  @computed get updatedOrCreatedAt() {
    return this.updatedAtFormatted || this.createdAtFormatted;
  }

  @computed
  get lastLoginFormatted() {
    return moment(this.lastLogin).format(`h:mm A on YYYY-MM-DD`);
  }

  @computed
  get lastLoginFormattedForSorting() {
    // If the user has not logged in we returnn 0
    return this.lastLogin ? moment(this.lastLogin).format('YYYYMMDD HH:mm') : 0;
  }

  @computed
  get inviteSentTimestampFormatted() {
    return moment(this.inviteSentTimestamp).format(`h:mm A on YYYY-MM-DD`);
  }

  @computed
  get lastLoginOrInviteSent() {
    if (this.lastLogin) {
      return moment(this.lastLogin).format('YYYYMMDD HH:mm');
    }

    return moment(this.inviteSentTimestamp).format('YYYYMMDD HH:mm');
  }

  @computed
  get formValues() {
    return {
      uuid: this.uuid,
      workerUuid: this.workerUuid,
      username: this.username || this.email,
      firstName: this.firstName,
      lastName: this.lastName,
      classification: this.classification,
      company: this.company?.formValues,
      title: this.title,
      phoneNumber: this.phoneNumber,
      employeeId: this.employeeId,
      images: this.images,
      avatar: this.avatar && this.avatar.contentUrl,
      role: this.role,
      defaultClassification: this.defaultClassification,
      defaultShift: this.defaultShift,
      defaultCostCode: this.defaultCostCode,
      defaultCrewName: this.defaultCrewName,
      groups: this.groupUuids.map(groupUuid => {
        return {
          uuid: groupUuid
        };
      })
    };
  }

  @computed
  get roleTranslated() {
    return getRoleName(this.role) || getRoleName('ROLE_USER');
  }

  @computed get statusFormatted() {
    if (this.isPending) {
      return t('Pending');
    }

    switch (this.status) {
      case 'DELETED':
        return t('Deleted');
      case 'INACTIVE':
        return t('Inactive');
      case 'INVITED':
        return t('Invited');
      default:
        return t('Active');
    }
  }

  @computed get statusColor() {
    if (this.isPending) {
      return 'orange';
    }

    switch (this.status) {
      case 'ACTIVE':
        return 'green';
      case 'DELETED':
        return 'red';
      case 'INACTIVE':
        return 'grey';
      case 'INVITED':
        return 'orange';
      default:
        return 'green';
    }
  }

  @computed get invitedOrPending() {
    return this.status === 'INVITED' || this.isPending;
  }

  @computed get statusTooltip() {
    return '';
  }

  @computed
  get invitedByTeamMember() {
    return this.invitedBy ? this.invitedBy.name : null;
  }

  @computed get hasProjects() {
    return this.projectUuids.length > 0 || this.projectTeamIds.length > 0;
  }

  @computed get viewUrl() {
    return `/team/${this.id}`;
  }

  @computed
  get defaultClassification() {
    return this.classification ? this.classification : null;
  }

  @computed
  get defaultShift() {
    if (this.settings.defaultShift?.name) {
      return {
        name: this.settings.defaultShift.name,
        uuid: this.settings.defaultShift.uuid
      };
    }

    return null;
  }

  @computed
  get defaultCostCode() {
    if (this.settings.defaultCostCode?.code) {
      return {
        code: this.settings.defaultCostCode.code,
        division: this.settings.defaultCostCode.division,
        description: this.settings.defaultCostCode.description,
        uuid: this.settings.defaultCostCode.uuid
      };
    }

    return null;
  }

  @computed
  get defaultCrewName() {
    if (!this.settings.defaultCrewName?.groupName) return null;

    return {
      name: this.settings.defaultCrewName.groupName,
      uuid: this.settings.defaultCrewName.uuid
    };
  }

  @computed get lastLoginText() {
    // This text will apply to the Team Members table last login  column
    if (
      this.rootStore.authorizationUI.hasTimeClockAccess &&
      this.settings.blockTimeClock
    ) {
      return { bold: false, text: t('Blocked') };
    }

    if (this.lastLogin) {
      return { bold: false, text: this.lastLoginFormatted };
    }

    return { bold: true, text: t('Never logged in') };
  }
}
