import request from 'axios';
import { action, observable, computed } from 'mobx';
import UIStore from 'stores/ui/UIStore';
import SettingsSignOff from 'stores/models/SettingsSignOff';
import alertErrorHandler from 'utils/alertErrorHandler';
import history from 'utils/history';
import { t } from 'utils/translate';

import {
  PayrollSettingsForm,
  payrollSettingsFormOptions,
  payrollSettingsFormFields,
  payrollSettingsFormRules,
  payrollSettingsFormValues,
  payrollSettingsFormLabels,
  payrollSettingsFormPlugins
} from 'forms/superAdmin/payrollSettings';

export default class SuperAdminCompanyPayrollSettingsUI extends UIStore {
  @observable payrollSettingsForm;
  @observable nextURL;
  @observable loading;
  @observable showSuccessAlert;

  constructor(options) {
    super(options);
    this.payrollSettingsForm = null;
    this.nextURL = null;
    this.loading = true;
    this.showSuccessAlert = false;

    this.settingsSignOff = new SettingsSignOff(null, {
      rootStore: this.rootStore,
      parent: this
    });
  }

  @computed
  get activeCompany() {
    return this.parent.activeCompany;
  }

  @computed
  get extendedPermissions() {
    return this.parent.extendedPermissions;
  }

  @action.bound async setup() {
    this.payPeriodSelectorUI.setup();
    this.fetchSignOffProcess();
    this.instantiatePayrollSettingsForm();
  }

  @action.bound tearDown() {
    this.payPeriodSelectorUI.tearDown();
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.loading = true;
    this.payrollSettingsForm = null;
    this.nextURL = null;
    this.showSuccessAlert = false;
  }

  @action.bound
  async fetchSignOffProcess() {
    this.loading = true;

    try {
      const url = `${this.rootStore.urlMicroService(
        'performanceTracking'
      )}/companies/${this.parent.activeCompany.id}/signOffRule`;

      await this.settingsSignOff.fetch({
        url
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  instantiatePayrollSettingsForm() {
    this.payrollSettingsForm = new PayrollSettingsForm(
      {
        fields: payrollSettingsFormFields,
        rules: payrollSettingsFormRules,
        values: Object.assign({}, payrollSettingsFormValues),
        labels: payrollSettingsFormLabels
      },
      {
        options: payrollSettingsFormOptions,
        plugins: payrollSettingsFormPlugins
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @action.bound blockHistoryIfFormChanged() {
    this.unblock = history.block((location, action) => {
      if (
        this.payrollSettingsForm &&
        this.payrollSettingsForm.check('isDirty')
      ) {
        this.showExitModal(location.pathname);
        return 'Blocked';
      }
    });
  }

  @action.bound
  resetPayrollSettingsForm() {
    this.payrollSettingsForm.reset();
  }

  @action.bound
  submitPayrollSettingsForm(event) {
    event.preventDefault();
    this.payrollSettingsForm.submit({
      onSuccess: this.submitPayrollSettingsFormSuccess,
      onError: this.submitPayrollSettingsFormError
    });
  }

  @action.bound
  async submitPayrollSettingsFormSuccess() {
    this.saving = true;

    const payload = this.payrollSettingsForm.values();

    const url = `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/companies/${this.parent.activeCompany.id}/adjust-payrolls`;

    try {
      await request.post(url, payload);

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Payroll periods are being updated')
      });

      this.unblockHistory();

      this.showSuccessAlert = true;
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);

      console.error(
        'Unexpected error happened while adjusting payroll periods',
        error
      );
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitPayrollSettingsFormError() {
    console.log(this.payrollSettingsForm.errors());
  }

  @action.bound
  showExitModal(nextURL) {
    this.showModal('DiscardChangesModal');
    this.nextURL = nextURL;
  }

  @action.bound
  cancelExitModal() {
    return this.hideActiveModal().then(() => {
      this.nextURL = null;
    });
  }

  @action.bound
  discardExitModal() {
    return this.hideActiveModal().then(() => {
      this.moveToNextURL();
    });
  }

  @action.bound moveToNextURL() {
    this.unblock();
    history.push(this.nextURL);
    this.nextURL = null;
  }

  @action.bound unblockHistory() {
    this.unblock && this.unblock();
  }
}
