import debounce from 'lodash.debounce';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CircleButton from './CircleButton';

const Wrapper = styled.div`
  min-height: ${props => props.minHeight}px;
  max-height: ${props => props.height}px;
  position: relative;
  &::before {
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 10%,
      rgba(255, 255, 255, 0) 100%
    );
    content: '';
    border-radius: ${props => props.borderRadius || 0};
    opacity: ${props => (props.canMoveUp && props.fadeEdges ? 1 : 0)};
    transition: opacity 0.15s ease-in-out;
    height: 20%;
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 20;
  }
  &::after {
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 10%,
      rgba(255, 255, 255, 0) 100%
    );
    border-radius: ${props => props.borderRadius || 0};
    content: '';
    opacity: ${props => (props.canMoveDown && props.fadeEdges ? 1 : 0)};
    transition: opacity 0.15s ease-in-out;
    height: 20%;
    position: absolute;
    pointer-events: none;
    bottom: 0;
    left: 0;
    z-index: 20;
    width: 100%;
  }
`;

const Content = styled.div`
  max-height: ${props => props.height}px;
  overflow-x: hidden;
  overflow-y: auto;
`;

const TopControl = styled(CircleButton)`
  opacity: 1;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  transition: opacity 0.25s ease-in-out;
  z-index: 21;
`;

const BottomControl = styled(CircleButton)`
  opacity: 1;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  transition: opacity 0.25s ease-in-out;
  z-index: 21;
`;

export default class VerticalScrollingContainer extends Component {
  static propTypes = {
    withButtons: PropTypes.bool,
    fadeEdges: PropTypes.bool
  };

  static defaultProps = {
    withButtons: false,
    fadeEdges: false,
    minHeight: 0
  };

  state = {
    canMoveUp: false,
    canMoveDown: false
  };

  constructor(props) {
    super(props);
    this.checkHeight = debounce(this.checkHeight, 250);
  }

  componentDidMount() {
    this.mounted = true;
    window.addEventListener('resize', this.checkHeight);
    this.content.addEventListener('scroll', this.checkScrollPosition);
    this.checkHeight();
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('resize', this.checkHeight);
    this.content.removeEventListener('scroll', this.checkScrollPosition);
  }

  checkScrollPosition = () => {
    let canMoveUp;
    let canMoveDown;

    if (
      this.content.scrollTop >=
      this.content.scrollHeight - this.wrapper.clientHeight
    ) {
      canMoveDown = false;
    } else {
      canMoveDown = true;
    }

    if (this.content.scrollTop > 0) {
      canMoveUp = true;
    } else {
      canMoveUp = false;
    }

    this.setState({
      canMoveUp: canMoveUp,
      canMoveDown: canMoveDown
    });
  };

  checkHeight = () => {
    if (!this.mounted) return;

    if (this.content.scrollHeight > this.wrapper.clientHeight) {
      this.setState({
        canMoveDown: true
      });
    } else {
      this.setState({
        canMoveDown: false
      });
    }
  };

  handleMoveUp = () => {
    if (this.content.scrollTop <= 0) {
      this.setState({
        canMoveUp: false
      });

      cancelAnimationFrame(this.scrollUpAnimation);

      return;
    }

    this.content.scrollTop -= 10;

    this.scrollUpAnimation = requestAnimationFrame(this.handleMoveUp);
  };

  handleMoveDown = () => {
    if (
      this.content.scrollTop >=
      this.content.scrollHeight - this.wrapper.clientHeight
    ) {
      this.setState({
        canMoveDown: false
      });

      cancelAnimationFrame(this.scrollDownAnimation);

      return;
    }

    this.content.scrollTop += 10;

    this.scrollDownAnimation = requestAnimationFrame(this.handleMoveDown);
  };

  handleCancelScroll = () => {
    cancelAnimationFrame(this.scrollUpAnimation);
    cancelAnimationFrame(this.scrollDownAnimation);
  };

  render() {
    const {
      withButtons,
      fadeEdges,
      height,
      minHeight,
      borderRadius,
      children,
      innerRef
    } = this.props;

    return (
      <Wrapper
        ref={ref => (this.wrapper = ref)}
        canMoveUp={this.state.canMoveUp}
        canMoveDown={this.state.canMoveDown}
        fadeEdges={fadeEdges}
        height={height}
        minHeight={minHeight}
        borderRadius={borderRadius}
      >
        {withButtons && this.state.canMoveUp && (
          <TopControl
            icon="collapse"
            onMouseEnter={this.handleMoveUp}
            onMouseLeave={this.handleCancelScroll}
            solid
          />
        )}

        <Content
          height={height}
          ref={ref => {
            this.content = ref;
            innerRef && innerRef(ref);
          }}
        >
          {children}
        </Content>

        {withButtons && this.state.canMoveDown && (
          <BottomControl
            icon="expand"
            onMouseEnter={this.handleMoveDown}
            onMouseLeave={this.handleCancelScroll}
            solid
          />
        )}
      </Wrapper>
    );
  }
}
