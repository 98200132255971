import omit from 'lodash.omit';
import { action, computed, reaction } from 'mobx';
import InsightsUIModel from './InsightsUIModel';
import ProjectMeasures from '../collections/ProjectMeasures';

export default class InsightsComplianceByProject extends InsightsUIModel {
  constructor(attributes, options) {
    super(attributes, options);

    this.projectMeasures = new ProjectMeasures(null, {
      rootStore: this.rootStore,
      parent: this
    });

    // React to selection changes
    this.reactToSelection = reaction(
      () => this.params,
      params => {
        if (params.projectIds || params.projectStatuses) {
          this.fetchStats();
        } else {
          this.cancelRequest();
          this.projectMeasures.reset();
        }
      },
      { fireImmediately: true }
    );
  }

  @action.bound
  parse(attributes) {
    this.parseProjectMeasures(attributes.projectMeasures);

    return {
      ...omit(attributes, ['projectMeasures'])
    };
  }

  url() {
    return '/ra/insights/complianceByProject';
  }

  @computed
  get sortedProjectMeasuresByProjectSelection() {
    if (this.insightsUI.selectedProjects.length > 0) {
      return this.sortedProjectMeasures.filter(projectMeasure => {
        return this.insightsUI.selectedProjects.find(selectedProject => {
          return selectedProject.id === projectMeasure.relatedProject.id;
        });
      });
    }

    return this.sortedProjectMeasures;
  }
}
