import { computed } from 'mobx';
import ReportEntryWithAttachments from './ReportEntryWithAttachments';
import { t } from 'utils/translate';

export default class AttachmentActivity extends ReportEntryWithAttachments {
  get restAttributes() {
    return [
      'id',
      'uuid',
      'type',
      'createdTimestamp',
      'updatedTimestamp',
      'report',
      'reportId',
      'reportDate',
      'segmentUuid',
      'teamId',
      'activityViewUrl'
    ];
  }

  get restAttributeDefaults() {
    return {
      activityViewUrl: '/'
    };
  }

  @computed
  get title() {
    return t('Attachments');
  }

  @computed get activityTitle() {
    return `${t('submitted')} ${this.title}`;
  }
}
