import { action, computed } from 'mobx';

import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

import ChecklistObservationEditUI from './checklists/ChecklistObservationEditUI';

export default class SurveyObservationEditUI extends ChecklistObservationEditUI {
  constructor(options) {
    super(options);
  }

  @computed get checklistObservationUI() {
    return this.parent.surveyObservationUI;
  }

  @computed get locationOptions() {
    return this.projectLocationSelectorUI.options;
  }

  @action.bound async submitEntryEditFormSuccess() {
    this.saving = true;

    const attachments = this.entryForEdit.attachments.models.map(attachment => {
      return {
        uuid: attachment.uuid
      };
    });

    const values = this.entryEditForm.values();

    const assignees = await this.checklistObservationUI.createOrEditTasksForAssignees(
      this.entryEditForm.assigneesValues,
      values.status.value
    );

    const payload = {
      projectUuid: this.project.uuid,
      attachments: attachments,
      entityStatus: this.saveAsDraft ? 'DRAFT' : 'ACTIVE',
      type: {
        uuid: values.subType
      },
      category: values.category,
      priority: values.priority?.value,
      status: {
        uuid: values.status?.value
      },
      assignees: assignees,
      location: { uuid: values.location?.uuid },
      description: values.description,
      emails: values.emails?.map(member => member.email),
      ...(this.signature && { signature: this.signature })
    };

    try {
      await this.entryForEdit.save(payload, {
        url: this.saveObservationUrl,
        wait: true,
        stripNonRest: false
      });

      this.questionModel.updateObservation(this.entryForEdit.toJSON());

      this.hideObservation();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Observation saved')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
      this.tearDownObservation();
    }
  }
}
