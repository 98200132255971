import { when } from 'mobx';
import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import { t } from 'utils/translate';

const checklistTemplateStartFormPlugins = extendedPlugins;

const checklistTemplateStartFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const checklistTemplateStartFormFields = [
  'name',
  'templateUrl', // Used for validation
  'checklistType',
  'checklistType.uuid',
  'checklistType.typeClass',
  'checklistType.typeClass.uuid',
  'checklistType.typeClass.name',
  'checklistType.typeName'
];

const checklistTemplateStartFormValues = {};

const checklistTemplateStartFormRules = {
  'checklistType.uuid': 'required',
  name: 'string|required|max:255|template_name_available'
};

const checklistTemplateStartFormLabels = {
  'checklistType.uuid': t('type'),
  name: t('name')
};

const checklistTemplateStartFormFieldOptions = {
  name: {
    validationDebounceWait: 1000
  }
};

class ChecklistTemplateStartForm extends MobxReactForm {
  constructor(settings, options) {
    super(settings, options);
    this.rootStore = options.rootStore;

    when(
      () => this.rootStore.me.company,
      () => {
        this.$('templateUrl').set(
          `${this.rootStore.urlMicroService('toolbox')}/companies/${
            this.rootStore.me.company.uuid
          }/checklist-templates`
        );
      }
    );
  }
}

export {
  ChecklistTemplateStartForm,
  checklistTemplateStartFormPlugins,
  checklistTemplateStartFormOptions,
  checklistTemplateStartFormFields,
  checklistTemplateStartFormRules,
  checklistTemplateStartFormLabels,
  checklistTemplateStartFormValues,
  checklistTemplateStartFormFieldOptions
};
