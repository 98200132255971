import moment from 'moment';
import omit from 'lodash.omit';
import { Model } from 'mobx-mc';
import { action, computed } from 'mobx';
import ProjectMeasures from '../collections/ProjectMeasures';

export default class InsightsPeriod extends Model {
  get restAttributes() {
    return ['type', 'start', 'end'];
  }

  @action.bound
  parse(attributes) {
    this.projectMeasures = new ProjectMeasures(
      attributes.projectMeasures || null,
      {
        rootStore: this.rootStore,
        parent: this
      }
    );

    return {
      ...omit(attributes, ['projectMeasures'])
    };
  }

  @computed
  get timeFrameSelection() {
    if (this.collection.parent.insightsUI) {
      return this.collection.parent.insightsUI.timeFrameSelection;
    }

    return this.collection.parent.timeFrameSelection;
  }

  @computed
  get groupBy() {
    return this.collection.parent.groupBy;
  }

  @computed
  get chartStat() {
    return this.collection.parent.chartStat;
  }

  @computed
  get formattedStartDay() {
    return moment(this.start).format('YYYY-MM-DD');
  }

  @computed
  get dateRange() {
    switch (this.timeFrameSelection) {
      case 'LAST_7_DAYS':
        return moment(this.start).format('YYYY-MM-DD');
      case 'LAST_14_DAYS':
      case 'LAST_4_WEEKS':
      case 'THIS_MONTH':
      case 'LAST_MONTH':
      case 'LAST_3_MONTHS':
        return `${moment(this.start).format('YYYY-MM-DD')} - ${moment(
          this.end
        ).format('YYYY-MM-DD')}`;
      case 'LAST_6_MONTHS':
      case 'LAST_12_MONTHS':
      case 'LAST_YEAR':
      case 'THIS_YEAR':
        return moment(this.start).format('MMM YYYY');
      case 'ALL_TIME':
        if (this.groupBy === 'YEAR') {
          return moment(this.start).format('YYYY');
        } else if (this.groupBy === 'DAY') {
          return moment(this.start).format('YYYY-MM-DD');
        } else {
          return moment(this.start).format('MMM YYYY');
        }
      default:
        return moment(this.start).format('YYYY-MM-DD');
    }
  }

  @computed
  get tooltipDateRange() {
    switch (this.timeFrameSelection) {
      case 'LAST_14_DAYS':
      case 'LAST_4_WEEKS':
      case 'THIS_MONTH':
      case 'LAST_MONTH':
      case 'LAST_3_MONTHS':
        return `${moment(this.start).format('ddd MMM D[,] YY')} - ${moment(
          this.end
        ).format('ddd MMM D[,] YY')}`;
      default:
        return this.dateRange;
    }
  }
}
