import UIStore from './UIStore';
import { computed, action } from 'mobx';

import EarthCamUI from './EarthCamUI';
import HoloBuilderUI from './HoloBuilderUI';
import DroneDeployUI from './DroneDeployUI';

export default class LiveViewsUI extends UIStore {
  constructor(options) {
    super(options);

    this.earthCamUI = new EarthCamUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.holoBuilderUI = new HoloBuilderUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.droneDeployUI = new DroneDeployUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound setup() {
    this.earthCamUI.setup();
    this.holoBuilderUI.setup();
    this.droneDeployUI.setup();
  }

  @action.bound tearDown() {
    this.earthCamUI.tearDown();
    this.holoBuilderUI.tearDown();
    this.droneDeployUI.tearDown();
  }

  @computed get loading() {
    return (
      this.earthCamUI.loading ||
      this.holoBuilderUI.loading ||
      this.droneDeployUI.loading
    );
  }

  @computed get showCompanyLevelSetup() {
    if (this.loading) return false;

    if (
      !this.earthCamUI.companyLevelUrl &&
      !this.holoBuilderUI.companyLevelUrl &&
      !this.droneDeployUI.companyLevelUrl
    ) {
      return true;
    }

    return false;
  }

  @computed get showCompanyLevelEarthCam() {
    if (this.loading) return false;

    return this.earthCamUI.companyLevelUrl;
  }

  @computed get showCompanyLevelHoloBuilder() {
    if (this.loading) return false;

    return this.holoBuilderUI.companyLevelUrl;
  }

  @computed get showCompanyLevelDroneDeploy() {
    if (this.loading) return false;

    return this.droneDeployUI.companyLevelUrl;
  }

  @computed get showProjectLevelSetup() {
    if (this.loading) return false;

    if (
      !this.earthCamUI.projectLevelUrl &&
      !this.holoBuilderUI.projectLevelUrl &&
      !this.droneDeployUI.projectLevelUrl
    ) {
      return true;
    }

    return false;
  }

  @computed get showProjectLevelEarthCam() {
    if (this.loading) return false;

    return this.earthCamUI.projectLevelUrl;
  }

  @computed get showProjectLevelHoloBuilder() {
    if (this.loading) return false;

    return this.holoBuilderUI.projectLevelUrl;
  }

  @computed get showProjectLevelDroneDeploy() {
    if (this.loading) return false;

    return this.droneDeployUI.projectLevelUrl;
  }
}
