import React, { Component } from 'react';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import styled from 'styled-components';
import range from 'lodash.range';

import Day from './Day';

const WeekWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 4px 0;
`;

export default class Week extends Component {
  static propTypes = {
    startDate: momentPropTypes.momentObj.isRequired,
    month: momentPropTypes.momentObj.isRequired,
    disableBefore: momentPropTypes.momentObj,
    disableAfter: momentPropTypes.momentObj,
    dayConfig: PropTypes.object.isRequired,
    onDayClick: PropTypes.func.isRequired,
    onDayDoubleClick: PropTypes.func.isRequired,
    enableHover: PropTypes.bool,
    colorAllDays: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  };

  getDays = () => {
    const {
      startDate,
      month,
      dayConfig,
      onDayClick,
      onDayDoubleClick,
      disableBefore,
      disableAfter,
      enableHover,
      colorAllDays,
      showGreenDot,
      greyOutAfterToday
    } = this.props;

    const currentMonth = month.format('M');

    return range(7)
      .map(dow => startDate.clone().add(dow, 'days'))
      .map(day => {
        const config = dayConfig[day.format('M/D/YYYY')] || {};
        let disabled;

        if (disableAfter) {
          disabled = day.isAfter(disableAfter);
        } else {
          disabled = disableBefore ? day.isBefore(disableBefore) : false;
        }

        return (
          <Day
            key={day.toString()}
            day={day}
            currentMonth={currentMonth}
            onDayClick={onDayClick}
            onDayDoubleClick={onDayDoubleClick}
            disabled={disabled}
            enableHover={enableHover}
            colorAllDays={colorAllDays}
            showGreenDot={showGreenDot}
            greyOutAfterToday={greyOutAfterToday}
            {...config}
          />
        );
      });
  };

  render() {
    return <WeekWrapper>{this.getDays()}</WeekWrapper>;
  }
}
