import omit from 'lodash.omit';
import orderBy from 'lodash.orderby';
import { action, computed } from 'mobx';
import Answers from '../collections/Answers';
import ReportEntry from './ReportEntry';

import { t } from 'utils/translate';

export default class SurveyActivity extends ReportEntry {
  get restAttributes() {
    return [
      'id',
      'uuid',
      'type',
      'report',
      'reportId',
      'reportDate',
      'createdTimestamp',
      'updatedTimestamp',
      'segmentUuid',
      'teamId'
    ];
  }

  @action.bound parse(attributes) {
    super.parse(attributes);
    this.parseAnswers(attributes);

    return {
      ...omit(attributes, [
        'attachments',
        'createdBy',
        'updatedBy',
        'createdByRef',
        'updatedByRef',
        'company',
        'project',
        'answers'
      ])
    };
  }

  @action.bound parseAnswers(attributes) {
    this.answers = new Answers(attributes.answers, {
      rootStore: this.rootStore,
      parent: this
    });
  }

  @computed
  get title() {
    return t('Survey');
  }

  @computed get activityTitle() {
    return `${t('submitted a')} ${this.title}`;
  }

  @computed
  get answersOrderedByQuestionTag() {
    return orderBy(this.answers.models, ['question.tag'], ['asc']);
  }
}
