import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';
import { t } from 'utils/translate';

const subscriptionFormPlugins = extendedPlugins;

const subscriptionFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const subscriptionFormFields = [
  'plan',
  'quantity',
  'collectionMethod',
  'term',
  'hasTimeClockAccess'
];

const subscriptionFormRules = {
  plan: 'string|required',
  collectionMethod: 'string|required',
  quantity: 'numeric|required|min:1|max:100000',
  term: 'numeric|min:1|max:5'
};

const subscriptionFormLabels = {
  plan: t('plan'),
  quantity: t('seats'),
  collectionMethod: t('collection method'),
  term: t('subscription term')
};

class SubscriptionForm extends MobxReactForm {}

export {
  SubscriptionForm,
  subscriptionFormOptions,
  subscriptionFormFields,
  subscriptionFormRules,
  subscriptionFormLabels,
  subscriptionFormPlugins
};
