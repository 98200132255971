import omit from 'lodash.omit';
import kebabCase from 'lodash.kebabcase';
import { computed } from 'mobx';
import { Model } from 'mobx-mc';

import Worker from 'stores/models/Worker';

export default class SageIntacctEmployee extends Model {
  get restAttributes() {
    return ['id', 'name', 'data', 'isNew'];
  }

  parse(attributes) {
    this.parseRakenWorker(attributes);

    return {
      ...omit(attributes, ['rakenRef'])
    };
  }

  parseRakenWorker(attributes) {
    this.rakenWorker = new Worker(attributes.rakenRef, {
      rootStore: this.rootStore,
      parent: this
    });
  }

  @computed get slug() {
    return kebabCase(this.name);
  }
}
