import request from 'axios';
import { action, computed } from 'mobx';
import ProjectChildEditUI from './ProjectChildEditUI';
import WorkLogEditStandardUI from './WorkLogEditStandardUI';
import WorkLogEditCrewUI from './WorkLogEditCrewUI';
import StandardWorkLog from '../../models/StandardWorkLog';
import TimeEntriesWorkLog from '../../models/workLogs/TimeEntriesWorkLog';

import history from 'utils/history';
import { t } from 'utils/translate';

export default class WorkLogEditUI extends ProjectChildEditUI {
  constructor(options) {
    super(options);

    this.workLogEditStandardUI = new WorkLogEditStandardUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.workLogEditCrewUI = new WorkLogEditCrewUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  // This is so we can change routes and wording
  // to "Time cards" from  the time cards tab
  @computed get timeCardsMode() {
    return this.parent.timeCardsMode;
  }

  @computed get workLogType() {
    return this.entryForEdit?.workLogType;
  }

  @computed get title() {
    if (this.hasWriteAccess) {
      return this.workLogType === 'CREW'
        ? t('Edit time card')
        : t('Edit work log');
    }

    return this.workLogType === 'CREW'
      ? t('View time card')
      : t('View work log');
  }

  @computed get backButtonText() {
    if (this.timeCardsMode) {
      return t('Back to time cards');
    }

    return t('Back to work logs');
  }

  @action.bound async fetchEntry(uuid) {
    if (this.entryForEdit) return;

    try {
      let model = this.parent.workLogs.getByIdOrUuid(uuid);

      if (model) {
        await model.fetch();
      } else {
        const response = await request.get(
          `${this.urlMicroService('performanceTracking')}/companies/${
            this.company.uuid
          }/worklogs/${uuid}`
        );

        if (response.data.workLogType === 'CREW') {
          model = new TimeEntriesWorkLog(response.data, {
            rootStore: this.rootStore
          });
        } else {
          model = new StandardWorkLog(response.data, {
            rootStore: this.rootStore
          });
        }
      }

      this.setEntryForEdit(model);
    } catch (error) {
      this.cancelWorkLogEdit();
    }
  }

  @action.bound setEntryForEdit(model) {
    this.projectUI.setDate(model.reportDate);
    this.projectUI.setSegmentUuid(model.segmentUuid);
    this.entryForEdit = model;

    // TODO: Tidy this up post release.  For now just use
    // the existing crew UI store.
    if (this.workLogType === 'CREW') {
      this.workLogEditCrewUI.setup(model);
    } else {
      this.workLogEditStandardUI.setup(model);
    }
  }

  @action.bound tearDown() {
    if (this.workLogType === 'STANDARD') {
      this.workLogEditStandardUI.tearDown();
    }

    if (this.workLogType === 'CREW') {
      this.workLogEditCrewUI.tearDown();
    }

    this.entryForEdit = null;
  }

  @action.bound cancelWorkLogEdit() {
    if (this.timeCardsMode) {
      history.push({
        pathname: `${this.project.viewUrl}/timecards`,
        search: this.baseQueryParams
      });
    } else {
      history.push({
        pathname: `${this.project.viewUrl}/worklogs`,
        search: this.baseQueryParams
      });
    }
  }
}
