import { action, observable, runInAction } from 'mobx';
import request from 'axios';

import UIStore from './UIStore';
import { t } from 'utils/translate';
import errorHandler from 'utils/errorHandler';
import history from 'utils/history';

import ProjectDirectoryTeamMembersUI from './ProjectDirectoryTeamMembersUI';
import ProjectDirectoryWorkersUI from './ProjectDirectoryWorkersUI';
import ProjectDirectoryCollaboratorsUI from './ProjectDirectoryCollaboratorsUI';

export default class ProjectDirectoryUI extends UIStore {
  @observable searchQuery;
  @observable stateFilter;
  @observable projectDirectoryLink;
  @observable projectTeamDeleteModalShown;
  @observable projectTeamToDelete;
  @observable sentInvites;

  @observable modalElement;

  constructor(options) {
    super(options);

    this.projectDirectoryTeamMembersUI = new ProjectDirectoryTeamMembersUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.projectDirectoryWorkersUI = new ProjectDirectoryWorkersUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.projectDirectoryCollaboratorsUI = new ProjectDirectoryCollaboratorsUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound setModalRef(modalElement) {
    this.modalElement = modalElement.firstChild;
  }

  @action.bound
  resendInvite(user) {
    return this.authorization
      .checkFeatureAccess('InviteProjectMembers')
      .then(() => {
        runInAction(() => {
          let url;

          if (user.inviteId) {
            url = `/ra/invite/${user.inviteId}`;
          } else {
            url = `/ra/invite/member/${user.id}`;
          }

          const inviteStamp = `${this.project.id}-${user.uniqueId}`;

          return request
            .post(url, {
              inviteResend: Boolean(user.inviteId),
              projectId: this.project.id
            })
            .then(() => {
              runInAction(() => {
                this.sentInvites.push(inviteStamp);

                this.rootStore.notificationsUI.pushNotification({
                  title: t('Invite Sent'),
                  showUndo: false
                });
              });
            })
            .catch(error => {
              errorHandler(error, this.rootStore.notificationsUI.pushError);
            });
        });
      });
  }

  checkInviteSent(user) {
    return this.sentInvites.includes(`${this.project.id}-${user.uniqueId}`);
  }

  @action.bound async downloadProjectDirectory() {
    await this.authorization.checkFeatureAccess('DownloadMemberDirectory');

    window.open(this.project.memberDirectoryLink);
  }

  @action.bound cancelDirectory() {
    history.push({
      pathname: `${this.project.viewUrl}/worklogs`,
      search: this.parent.baseQueryParams
    });
  }
}
