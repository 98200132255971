import styled from 'styled-components';

const PDFOnly = styled.div`
  position: absolute;
  white-space: nowrap;
  width: 1118px;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
  .k-pdf-export & {
    position: static;
    clip: auto;
    overflow: visible;
  }
`;

export default PDFOnly;
