import { action, computed, reaction } from 'mobx';
import InsightsUIModel from './InsightsUIModel';

import Highcharts from 'highcharts';

export default class InsightsIssuesObservedHoursWorked extends InsightsUIModel {
  constructor(attributes, options) {
    super(attributes, options);

    // React to selection changes
    this.reactToSelection = reaction(
      () => this.params,
      params => {
        if (params) {
          this.fetchStats();
        } else {
          this.cancelRequest();
          this.insights.clear();
        }
      },
      { fireImmediately: true }
    );

    this.typeClasses = options.typeClasses;
  }

  @computed
  get params() {
    const params = {
      startDay: this.insightsUI.startDay,
      endDay: this.insightsUI.endDay,
      typeClasses: this.typeClasses
    };

    switch (this.insightsUI.projectSelection) {
      case 'ALL':
        params.projectStatuses = 'ACTIVE,INACTIVE';
        break;
      case 'ACTIVE':
        params.projectStatuses = 'ACTIVE';
        break;
      default:
        params.projectTeamUuids = this.insightsUI.filteredProjectTeamUuids.join(
          ','
        );
        break;
    }

    return params;
  }

  url() {
    return 'ra/insights/observations/issuesRaisedByHoursWorked';
  }

  get restAttributes() {
    return ['insights'];
  }

  @action.bound
  async fetchStats() {
    try {
      await this.fetch({
        params: this.params
      });
    } catch (e) {
      this.set(
        {
          positiveCount: 0,
          negativeCount: 0
        },
        {
          reset: true
        }
      );
    }
  }

  @computed
  get filteredInsights() {
    return this.insights?.filter(insight => insight.hours);
  }

  @computed
  get hasStats() {
    return this.filteredInsights?.length > 0;
  }

  @computed
  get chartSeries() {
    return {
      companyNames: this.filteredInsights.map(
        observation => observation.companyName
      ),
      hours: this.filteredInsights.map(observation => observation.hours),
      issuesAssigned: this.filteredInsights.map(
        observation => observation.count
      ),
      issuesPerHour: this.filteredInsights.map(
        observation => observation.observationsPerHour
      )
    };
  }

  @computed
  get chartConfig() {
    return {
      chart: {
        zoomType: 'xy'
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      xAxis: [
        {
          categories: this.chartSeries.companyNames,
          crosshair: true
        }
      ],
      yAxis: [
        {
          // Primary yAxis
          labels: {
            format: '{value}',
            style: {
              color: Highcharts.getOptions().colors[1]
            }
          },
          title: {
            text: 'HSE Issues/hour',
            style: {
              color: Highcharts.getOptions().colors[1]
            }
          }
        },
        {
          // Secondary yAxis
          title: {
            text: 'Hours',
            style: {
              color: Highcharts.getOptions().colors[0]
            }
          },
          labels: {
            format: '{value}',
            style: {
              color: Highcharts.getOptions().colors[0]
            }
          },
          opposite: true
        }
      ],
      tooltip: {
        shared: true
      },
      legend: {
        align: 'left',
        x: 80,
        verticalAlign: 'top',
        y: 80,
        floating: true,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || // theme
          'rgba(255,255,255,0.25)'
      },
      series: [
        {
          name: 'Hours',
          type: 'column',
          yAxis: 1,
          data: this.chartSeries.hours,
          tooltip: {
            valueSuffix: undefined
          }
        },

        {
          name: 'HSE Issues Assigned',
          type: 'column',
          yAxis: 1,
          data: this.chartSeries.issuesAssigned,
          tooltip: {
            valueSuffix: undefined
          }
        },

        {
          name: 'HSE Issues/hour',
          type: 'spline',
          data: this.chartSeries.issuesPerHour,
          tooltip: {
            valueSuffix: undefined
          }
        }
      ]
    };
  }
}
