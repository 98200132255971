import { observable, action, computed, runInAction } from 'mobx';
import UIStore from './UIStore';
import orderBy from 'lodash.orderby';
import { t } from 'utils/translate';
import errorHandler from 'utils/errorHandler';

import {
  BreakForm,
  breakFormOptions,
  breakFormFields,
  breakFormRules,
  breakFormLabels,
  breakFormPlugins,
  breakFormValues
} from 'forms/break';

export default class CompanyBreaksUI extends UIStore {
  @observable entryForEdit;

  @observable searchQuery;
  @observable sortDirection;
  @observable pageSize;
  @observable page;

  constructor(options) {
    super(options);

    this.companyTimeCardsUI = options.companyTimeCardsUI;

    // Selecting
    this.selectedBreaks = observable([]);

    // Searching
    this.searchQuery = '';

    // Sorting
    this.sortDirection = 'asc';

    // Editing
    this.entryForEdit = null;

    // Pagination
    this.pageSize = 20;
    this.page = 0;

    /// Instantiate the create break form
    this.breakForm = new BreakForm(
      {
        fields: breakFormFields,
        rules: breakFormRules,
        labels: breakFormLabels,
        values: breakFormValues
      },
      {
        options: breakFormOptions,
        plugins: breakFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound clearUIState() {
    this.entryForEdit = null;
    this.searchQuery = '';
    this.selectedBreaks.clear();
    this.rootStore.breaks.clearNew();

    this.page = 0;
  }

  @action.bound
  async fetchBreaks() {
    try {
      await this.rootStore.breaks.fetch({
        params: {
          limit: 10000
        },
        add: true,
        remove: false,
        update: true
      });

      this.rootStore.companyBreaksFetched = true;
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  @computed get breaks() {
    return this.rootStore.breaks;
  }

  @computed get breakTypes() {
    return [
      {
        id: 'MEAL',
        name: 'Meal'
      },

      {
        id: 'BREAK',
        name: 'Break'
      }
    ];
  }

  @computed
  get hasBreaks() {
    return this.breaks.hasModels;
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 0;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 0;
  }

  @computed get sortedBreaks() {
    return orderBy(
      this.breaks.models,
      [
        breakModel => breakModel.new,
        breakModel => breakModel.name.toLowerCase()
      ],
      ['desc', this.sortDirection]
    );
  }

  @computed get searchedBreaks() {
    if (!this.searchQuery) {
      return this.sortedBreaks;
    }

    const query = this.searchQuery.toLowerCase();

    return this.sortedBreaks.filter(breakModel => {
      return (
        breakModel.name.toLowerCase().indexOf(query) > -1 ||
        breakModel.code.toLowerCase().indexOf(query) > -1
      );
    });
  }

  @computed get hasSearchedBreaks() {
    return this.searchedBreaks.length > 0;
  }

  @computed get paginatedBreaks() {
    return this.searchedBreaks.slice(
      this.page * this.pageSize,
      this.page * this.pageSize + this.pageSize
    );
  }

  @action.bound
  sortByColumn() {
    // Clear New Item at the top of list on sort.
    this.breaks.clearNew();

    if (this.sortDirection === 'asc') {
      this.sortDirection = 'desc';
    } else {
      this.sortDirection = 'asc';
    }
  }

  @computed
  get totalPages() {
    return Math.ceil(this.searchedBreaks.length / this.pageSize);
  }

  @action.bound
  setPage(page) {
    this.page = page.selected;
  }

  @computed
  get breakFormIsInValid() {
    if (!this.breakForm) return true;

    return Boolean(
      this.breakForm.check('hasError') ||
        this.breakForm.$('name').check('isPristine') ||
        this.breakForm.$('code').check('isPristine') ||
        this.breakForm.$('type').check('isPristine')
    );
  }

  @action.bound async saveNewBreak(values) {
    this.breaks
      .create({
        ...values
      })
      .then(breakCreated => {
        runInAction(() => {
          this.showBreakCreated(breakCreated);
        });
      })
      .catch(error => {
        errorHandler(error, this.notifications.pushError);
      });
  }

  @action.bound
  showBreakCreated(breakCreated) {
    breakCreated.setAsNew();

    this.rootStore.notificationsUI.pushNotification({
      showUndo: false,
      title: t('Break Created')
    });

    this.setPage({
      selected: 0
    });
  }

  @computed get hasSelectedBreaks() {
    return this.selectedBreaks.length > 0;
  }

  @action.bound
  toggleSelectBreak(toggleBreak) {
    if (this.selectedBreaks.find(id => id === toggleBreak.id)) {
      this.selectedBreaks.remove(toggleBreak.id);
    } else {
      this.selectedBreaks.push(toggleBreak.id);
    }
  }

  @computed
  get allBreaksSelected() {
    return (
      this.hasBreaks && this.selectedBreaks.length === this.breaks.models.length
    );
  }

  @action.bound
  toggleSelectAllBreaks() {
    if (this.allBreaksSelected) {
      this.selectedBreaks.clear();
    } else {
      this.selectedBreaks.replace(
        this.searchedBreaks.map(searchedBreak => searchedBreak.id)
      );
    }
  }

  @action.bound softDeleteBreak(breakToDelete) {
    const originalIndex = breakToDelete.collection.models.indexOf(
      breakToDelete
    );

    this.cancelEntryEdit();

    breakToDelete.collection.remove(breakToDelete);

    this.rootStore.notificationsUI.pushNotification({
      onUndo: () => {
        this.cancelDeleteBreak(breakToDelete, originalIndex);
      },
      onDismiss: () => {
        this.confirmDeleteBreak(breakToDelete);
      },
      title: t('Break Deleted')
    });
  }

  @action.bound
  cancelDeleteBreak(breakToCancel, index) {
    this.rootStore.breaks.add(breakToCancel, {
      at: index
    });
  }

  @action.bound
  confirmDeleteBreak(breakToDelete) {
    breakToDelete.destroy();
  }

  @action.bound
  deleteSelectedBreaks() {
    this.cancelEntryEdit();

    const breaks = this.breaks.models.filter(breakFilter =>
      this.selectedBreaks.includes(breakFilter.id)
    );

    this.softDeleteBreaks(breaks);
    this.selectedBreaks.clear();
  }

  @action.bound softDeleteBreaks(breaks) {
    this.breaks.remove(breaks);

    this.rootStore.notificationsUI.pushNotification({
      onUndo: () => {
        this.cancelDeleteBreaks(breaks);
      },
      onDismiss: () => {
        this.confirmDeleteBreaks(breaks);
      },
      title: t('Breaks Deleted')
    });
  }

  @action.bound
  cancelDeleteBreaks(breaks) {
    this.rootStore.breaks.add(breaks);
  }

  @action.bound
  confirmDeleteBreaks(breaks) {
    breaks.forEach(breakToDelete => {
      breakToDelete.destroy();
    });
  }

  @action.bound setEntryForEdit(breakToEdit) {
    this.entryForEdit = breakToEdit;

    this.entryEditForm = new BreakForm(
      {
        fields: breakFormFields,
        rules: breakFormRules,
        labels: breakFormLabels,
        values: breakToEdit.formValues
      },
      {
        options: breakFormOptions,
        plugins: breakFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound cancelEntryEdit() {
    this.entryForEdit = null;
    this.entryEditForm = null;
  }

  checkIfEntryDisabled(entry) {
    return this.entryForEdit && this.entryForEdit.id !== entry.id;
  }

  @action.bound submitEntryEditForm() {
    this.entryEditForm.submit({
      onSuccess: this.submitEntryEditFormSuccess,
      onError: this.submitEntryEditFormError
    });
  }

  @action.bound submitEntryEditFormSuccess() {
    const {
      name,
      code,
      type,
      defaultDuration,
      minimumBreakDuration,
      durationRequired,
      startTimeRequired,
      endTimeRequired,
      billable
    } = this.entryEditForm.trimmedValues();

    this.entryForEdit.save({
      name,
      code,
      type,
      defaultDuration,
      minimumBreakDuration,
      durationRequired,
      startTimeRequired,
      endTimeRequired,
      billable
    });

    this.cancelEntryEdit();
  }

  @action.bound submitEntryEditFormError() {
    console.log(this.entryEditForm.errors());
  }

  @computed get durationOptions() {
    return Array(185 / 5)
      .fill()
      .map((value, key) => {
        return key
          ? { id: key * 5, name: `${key * 5} Minutes` }
          : { id: '0', name: '' };
      });
  }
}
