import request from 'axios';
import { action, observable, computed } from 'mobx';
import UIStore from './UIStore';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

import {
  ResetPasswordForm,
  resetPasswordFormOptions,
  resetPasswordFormFields,
  resetPasswordFormLabels,
  resetPasswordFormRules,
  resetPasswordFormPlugins
} from 'forms/resetPassword';

export default class TeamMemberResetPasswordUI extends UIStore {
  @observable resetPasswordForm;

  constructor(options) {
    super(options);
    this.resetPasswordForm = null;
  }

  @computed get entryForEdit() {
    return this.parent.entryForEdit;
  }

  @action.bound showResetPasswordDialog(event) {
    event.preventDefault();

    this.resetPasswordForm = new ResetPasswordForm(
      {
        fields: resetPasswordFormFields,
        rules: Object.assign({}, resetPasswordFormRules, {
          workEmail: `email|required|is:${this.entryForEdit.username}`
        }),
        labels: resetPasswordFormLabels
      },
      {
        options: resetPasswordFormOptions,
        plugins: resetPasswordFormPlugins
      }
    );

    this.showModal('TeamMemberResetPassword');
  }

  @action.bound async cancelResetPassword() {
    await this.hideActiveModal();
    this.resetPasswordForm = null;
  }

  @computed get disableSaveButton() {
    return this.saving || this.resetPasswordForm.check('hasError');
  }

  @computed get disableCancelButton() {
    return this.saving;
  }

  @action.bound submitResetPasswordForm(event) {
    event.preventDefault();

    this.resetPasswordForm.submit({
      onSuccess: this.submitResetPasswordFormSuccess,
      onError: this.submitResetPasswordFormError
    });
  }

  @action.bound async submitResetPasswordFormSuccess() {
    this.clearValidationDetails();

    this.saving = true;

    try {
      await request.post(`/ra/passwords/forgot`);

      await this.hideActiveModal();

      this.resetPasswordForm = null;

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Reset password email sent')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitResetPasswordFormError() {
    console.error(this.resetPasswordForm.errors());
  }
}
