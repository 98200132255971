import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import styled, { css } from 'styled-components';
import media from '../../utils/mediaTemplate';

const handleColor = props => {
  if (props.color) {
    return props.theme.colors[props.color];
  } else if (props.colorAllDays || props.grey || props.disabled) {
    return props.theme.colors.lightGrey3;
  } else {
    return props.theme.colors.charcoalGrey;
  }
};

const DayWrapper = styled.div`
  box-sizing: border-box;
  text-align: center;
  color: ${props => handleColor(props)};
  font-size: 1.6rem;
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  letter-spacing: 1.6px;
  cursor: pointer;
  border-radius: 999px;
  position: relative;
  width: 36px;
  padding: 5px 0;

  ${media.desktop`
    padding: 9px 0;
  `} &:focus {
    outline: 0;
  }

  ${props =>
    props.selected &&
    `
    background-color: ${(props.selectedColor &&
      props.theme.colors[props.selectedColor]) ||
      props.theme.colors.charcoalGrey};
    color: ${
      props.selectedColorText
        ? props.theme.colors[props.selectedColorText]
        : 'white'
    };
    &:focus,
    &:hover {
      box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.35);
    }
  `};

  ${props =>
    props.isToday &&
    !props.selected &&
    !props.disabled &&
    `
    background-color: ${props.theme.colors.lightGrey2};
    &:focus,
    &:hover {
      box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.35);
    }
  `};

  ${props =>
    props.enableHover &&
    !(props.selected || props.isToday || props.disabled) &&
    `
    &:hover {
      background-color: ${props.theme.colors.lightGrey2};
    }
  `};

  ${props =>
    props.disabled &&
    `
    background-color: transparent !important;
    cursor: not-allowed;
  `};

  ${props =>
    !props.selected &&
    !props.isToday &&
    `
    &:focus {
      background-color: ${props.theme.colors.lightGrey2};
    }
  `};
`;

const DayNumber = styled.div`
  margin: 0 auto;
`;

const DotContainer = styled.div`
  width: 6px;
  height: 6px;
  margin: 0 1.5rem;
  position: absolute;
  box-sizing: border-box;
  ${props => props.show && dotCss};
`;

const dotCss = css`
  border-radius: 999px;
  ${props =>
    props.hollow &&
    `border: 1px solid ${props.theme.colors[props.color]};`} ${props =>
    !props.hollow && `background-color: ${props.theme.colors[props.color]};`};
`;

export default class Day extends Component {
  static propTypes = {
    day: momentPropTypes.momentObj.isRequired,
    currentMonth: PropTypes.string.isRequired,
    dot: PropTypes.shape({
      color: PropTypes.string,
      hollow: PropTypes.bool
    }),
    onDayClick: PropTypes.func.isRequired,
    onDayDoubleClick: PropTypes.func.isRequired,
    selected: PropTypes.bool,
    selectedColor: PropTypes.string,
    selectedColorText: PropTypes.string,
    disabled: PropTypes.bool,
    colorAllDays: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    bold: PropTypes.bool,
    color: PropTypes.string,
    greyOutAfterToday: PropTypes.bool
  };

  static defaultProps = {
    dot: {},
    disabled: false,
    bold: false,
    greyOutAfterToday: false
  };

  componentDidMount() {
    if (this.props.focused) {
      this.wrapperRef.focus();
    }
  }

  componentDidUpdate() {
    if (this.props.focused) {
      this.wrapperRef.focus();
    }
  }

  handleDayClick = () => {
    const { onDayClick, day, disabled } = this.props;

    if (disabled) return;

    onDayClick(day);
  };

  handleDayDoubleClick = () => {
    const { onDayDoubleClick, day, disabled } = this.props;

    if (disabled) return;

    onDayDoubleClick(day);
  };

  handleDayKeyDown = e => {
    if (e.keyCode === 13) {
      this.handleDayClick();
    }
  };

  render() {
    const {
      currentMonth,
      day,
      dot,
      focused,
      selected,
      selectedColor,
      selectedColorText,
      disabled,
      enableHover,
      colorAllDays,
      bold,
      color,
      showGreenDot,
      greyOutAfterToday
    } = this.props;

    let showDot = false;

    if (dot.hasOwnProperty('color')) {
      if (dot.color !== 'goGreen') {
        showDot = true;
      } else if (showGreenDot) {
        showDot = true;
      }
    }

    const isToday = day.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD');
    const isAfterToday = day.isAfter(moment(), 'day');
    const notInCurrentMonth = currentMonth !== day.format('M');

    let grey = false;

    if (notInCurrentMonth) {
      grey = true;
    }

    if (greyOutAfterToday && isAfterToday) {
      grey = true;
    }

    return (
      <DayWrapper
        grey={grey}
        onClick={this.handleDayClick}
        onKeyDown={this.handleDayKeyDown}
        onDoubleClick={this.handleDayDoubleClick}
        selected={selected}
        selectedColor={selectedColor}
        isToday={isToday}
        disabled={disabled}
        enableHover={enableHover}
        focused={focused}
        ref={ref => (this.wrapperRef = ref)}
        tabIndex={-1}
        id={
          isToday
            ? 'highlighted_current_day'
            : grey
            ? 'grey_days_section'
            : 'black_days_section'
        }
        data-qa={
          isToday
            ? 'div_highlighted-current-day'
            : grey
            ? 'div_grey-days-section'
            : 'div_black-days-section'
        }
        colorAllDays={colorAllDays}
        selectedColorText={selectedColorText}
        bold={bold}
        color={color}
      >
        <DayNumber>{day.format('D')}</DayNumber>
        <DotContainer show={showDot} {...dot} />
      </DayWrapper>
    );
  }
}
