import moment from 'moment';
import { action, computed } from 'mobx';
import ProjectChildEditUI from './ProjectChildEditUI';

import EquipmentLogNote from 'stores/models/EquipmentLogNote';

import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import history from 'utils/history';

import {
  EquipmentLogNoteForm,
  equipmentLogNoteFormOptions,
  equipmentLogNoteFormFields,
  equipmentLogNoteFormLabels,
  equipmentLogNoteFormPlugins,
  equipmentLogNoteFormRules,
  equipmentLogNoteFormRelated
} from 'forms/equipmentLogNote';

export default class EquipmentLogNoteEditUI extends ProjectChildEditUI {
  @computed get parentEquipmentLog() {
    return this.parent.entryForEdit;
  }

  @action.bound setup(id) {
    this.fetchEntry(id);
  }

  @action.bound async fetchEntry(uuid) {
    if (this.entryForEdit) return;

    try {
      let model = this.parent.equipmentLogNotes.get(uuid);

      if (!model) {
        model = new EquipmentLogNote(
          {
            type: 'EquipmentLogNote',
            uuid: uuid,
            equipmentLogUuid: this.parentEquipmentLog.uuid
          },
          {
            rootStore: this.rootStore
          }
        );

        await model.fetch();
      }

      this.setEntryForEdit(model);
    } catch (error) {
      console.error(error);
      this.cancelEquipmentLogNoteEdit();
    }
  }

  @action.bound setEntryForEdit(model) {
    this.entryForEdit = model;

    this.entryEditForm = new EquipmentLogNoteForm(
      {
        fields: equipmentLogNoteFormFields,
        rules: equipmentLogNoteFormRules,
        labels: equipmentLogNoteFormLabels,
        values: this.entryForEdit.formValues,
        related: equipmentLogNoteFormRelated
      },
      {
        options: equipmentLogNoteFormOptions,
        plugins: equipmentLogNoteFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound async submitEntryEditFormSuccess() {
    this.saving = true;

    try {
      const values = this.entryEditForm.values();

      await this.entryForEdit.save(
        Object.assign(values, {
          date: moment(values.date).format('YYYY-MM-DD')
        }),
        {
          wait: true
        }
      );

      this.parent.sortByLastCreated();
      this.cancelEquipmentLogNoteEdit();

      // Ensure any unsaved changed on the parent equipment log
      // form are prompted.
      this.parent.parent.blockHistoryIfFormChanged();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Note saved')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound cancelEquipmentLogNoteEdit() {
    history.push({
      pathname: `${this.project.viewUrl}/equipment/${this.parentEquipmentLog.uuid}`,
      search: this.baseQueryParams
    });
  }
}
