import { RakenCollection } from '../../lib';
import SuperAdminDemoAccount from '../../models/superAdmin/SuperAdminDemoAccount';

export default class SuperAdminDemoAccounts extends RakenCollection {
  model() {
    return SuperAdminDemoAccount;
  }

  url() {
    return '/ra/sadmin/demos';
  }
}
