import moment from 'moment';
import { computed } from 'mobx';
import { Model } from 'mobx-mc';

export default class ReportSummary extends Model {
  get restAttributes() {
    return [
      'uuid',
      'projectUuid',
      'reportDate',
      'segmentUuid',
      'segmentName',
      'status',
      'signature',
      'signedBy',
      'signedDate',
      'pdfs'
    ];
  }

  idAttribute() {
    return 'uuid';
  }

  @computed get signedDateFormatted() {
    return moment(this.signedDate).format('Do MMM YYYY');
  }

  @computed get signedByFullName() {
    return `${this.signedBy.firstName} ${this.signedBy.lastName}`;
  }
}
