import debounce from 'lodash.debounce';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import ProjectChildUI from './../ProjectChildUI';

import EquipmentUI from './EquipmentUI';
import EquipmentAddUI from './EquipmentAddUI';
import EquipmentDeployments from 'stores/collections/equipment/EquipmentDeployments';
import EquipmentDeploymentAddUI from './EquipmentDeploymentAddUI';
import EquipmentDeploymentEditUI from './EquipmentDeploymentEditUI';

import history from 'utils/history';
import { BASE_DEBOUNCE } from 'fixtures/constants';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

import { callTrack } from 'utils/segmentIntegration';
import { EQUIPMENT_LOG_DELETED } from 'utils/segmentAnalytics/eventSpec';

export default class EquipmentDeploymentsUI extends ProjectChildUI {
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;
  @observable selectedEquipmentDeployment;
  @observable statusFilter;

  constructor(options) {
    super(options);

    this.loading = true;

    this.selectedEquipmentDeployment = null;

    this.statusFilter = 'ACTIVE';

    this.sortField = 'startDate';
    this.sortDirection = 'desc';
    this.page = 1;
    this.searchQuery = '';
    this.pageSize = 20;

    // Equipment Deployments collection
    this.equipmentDeployments = new EquipmentDeployments(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.equipmentAddUI = new EquipmentAddUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.equipmentDeploymentAddUI = new EquipmentDeploymentAddUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.equipmentDeploymentEditUI = new EquipmentDeploymentEditUI({
      parent: this,
      rootStore: this.rootStore
    });

    // Equipment inline create and update
    // TODO rename
    this.inlineEquipmentUI = new EquipmentUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchEquipmentDeploymentsDebounced = debounce(
      this.fetchEquipmentDeployments,
      BASE_DEBOUNCE
    );
  }

  @computed get equipmentUI() {
    return this.inlineEquipmentUI;
  }

  @action.bound setup() {
    window.scrollTo(0, 0);
    this.setupReactions();
    this.fetchEquipmentDeployments();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchEquipmentDeploymentsDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      sortField: this.sortField,
      sortDirection: this.sortDirection,
      projectUuid: this.projectUuid,
      status: this.statusFilter
    };
  }

  @computed get hasRequiredParams() {
    return this.projectUuid;
  }

  @action.bound async fetchEquipmentDeployments() {
    if (!this.hasRequiredParams) return;

    this.equipmentDeployments.cancelRequest();
    this.equipmentDeployments.clear();

    try {
      await this.equipmentDeployments.fetch({
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasEquipmentDeployments() {
    return this.equipmentDeployments.hasModels;
  }

  @computed get statusFilterOptions() {
    return [
      {
        id: 'ACTIVE',
        title: t('Active deployments')
      },
      {
        id: 'PREVIOUS',
        title: t('Previous deployments')
      },
      {
        id: 'ALL',
        title: t('All')
      }
    ];
  }

  @action.bound selectStatusFilter(option) {
    this.statusFilter = option.id;
  }

  @computed get hasStatusFilter() {
    return this.statusFilter !== 'ALL';
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'desc';
    }

    this.page = 1;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.equipmentDeployments.totalElements / this.pageSize);
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.searchQuery = '';
    this.statusFilter = 'ACTIVE';
    this.equipmentDeployments.clear();
    this.page = 1;
    this.loading = true;
    this.saving = false;
    this.sortField = 'startDate';
    this.sortDirection = 'desc';
    this.selectedEquipmentDeployment = null;
  }

  @computed get showEmptyState() {
    return (
      !this.loading &&
      !this.searchQuery &&
      !this.hasStatusFilter &&
      !this.hasEquipmentDeployments
    );
  }

  @computed get showEmptySearchState() {
    return (
      !this.loading &&
      (this.searchQuery || this.hasStatusFilter) &&
      !this.hasEquipmentDeployments
    );
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @action.bound async addEquipmentDeployment() {
    await this.authorization.checkFeatureAccess('CRUDEquipmentDeployments');

    history.push({
      pathname: `${this.project.viewUrl}/equipment/add`,
      search: this.baseQueryParams
    });
  }

  @action.bound async editEquipmentDeployment(equipmentDeployment) {
    history.push({
      pathname: `${this.project.viewUrl}/equipment/${equipmentDeployment.uuid}`,
      search: this.baseQueryParams
    });
  }

  @action.bound async deleteEquipmentDeployment(equipmentDeployment) {
    await this.authorization.checkFeatureAccess('CRUDEquipmentDeployments');

    this.selectedEquipmentDeployment = equipmentDeployment;
    this.showModal('DeleteModal');
  }

  @action.bound async cancelDeleteEquipmentDeployment() {
    await this.hideActiveModal();

    this.selectedEquipmentDeployment = null;
  }

  @action.bound async confirmDeleteEquipmentDeployment() {
    this.saving = true;

    try {
      await this.selectedEquipmentDeployment.destroy({ wait: true });

      this.refetchEquipmentDeployments();

      await this.hideActiveModal();

      this.selectedEquipmentDeployment = null;

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Equipment deleted')
      });

      callTrack(EQUIPMENT_LOG_DELETED);
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound sortByLastCreated() {
    this.sortField = 'createdTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchEquipmentDeploymentsDebounced();
    this.refetchReportSummary();
  }

  @action.bound refetchEquipmentDeployments() {
    this.loading = true;

    if (!this.hasEquipmentDeployments) {
      this.setPage(null, 1);
      this.fetchEquipmentDeployments();
    } else {
      this.fetchEquipmentDeployments();
    }

    this.refetchReportSummary();
  }

  getEquipmentDeploymentActions = equipmentDeployment => {
    return [
      {
        title: this.hasWriteAccess ? t('Edit') : t('View'),
        onClick: () => {
          this.editEquipmentDeployment(equipmentDeployment);
        }
      },
      {
        title: t('Delete'),
        onClick: () => {
          this.deleteEquipmentDeployment(equipmentDeployment);
        }
      }
    ];
  };
}
