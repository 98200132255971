import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';

const userEventsMapFiltersFormPlugins = extendedPlugins;

const userEventsMapFiltersFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const userEventsMapFiltersFormFields = [
  'timeClockStatus',
  'eventTypes[]',
  'workerUuids[]',
  'eventOrigins[]',
  'violations',
  'noGps'
];

const userEventsMapFiltersFormRules = {
  eventTypes: 'array',
  workerUuids: 'array',
  eventOrigins: 'array',
  violations: 'boolean',
  noGps: 'boolean'
};

class UserEventsMapFiltersForm extends MobxReactForm {}

export {
  UserEventsMapFiltersForm,
  userEventsMapFiltersFormRules,
  userEventsMapFiltersFormFields,
  userEventsMapFiltersFormOptions,
  userEventsMapFiltersFormPlugins
};
