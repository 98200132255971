import { Model } from 'mobx-mc';

export default class TimeClockQuestion extends Model {
  get urlRoot() {
    return `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/timecardchecklist/questions`;
  }

  get restAttributes() {
    return [
      'completedCount',
      'noCount',
      'otherCount',
      'questionText',
      'questions',
      'templateName',
      'yesCount',
      'questionUuids'
    ];
  }

  idAttribute() {
    return 'uuid';
  }
}
