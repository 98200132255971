import request from 'axios';
import debounce from 'lodash.debounce';
import { action, computed, reaction } from 'mobx';
import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import { t } from 'utils/translate';
import trimObject from '../utils/trimObject';

const equipmentFormPlugins = extendedPlugins;

const equipmentFormOptions = {
  validateOnInit: false,
  strictUpdate: false,
  validateOnChange: true
};

const equipmentFormFields = [
  'name',
  'supplier',
  'equipmentId',
  'rate',
  'frequency',
  'owned'
];

const equipmentFormRules = {
  name: 'string|required|max:100',
  supplier: 'string|max:50',
  equipmentId: 'string|max:32',
  rate: 'numeric|greaterThan0|notMoreThanTwoDecimalPlaces|max:99999999.99', // BE setting: DECIMAL(10,2)
  frequency: 'required_with:rate'
};

const equipmentFormLabels = {
  name: t('Name'),
  supplier: t('Supplier/Sub'),
  equipmentId: t('Equipment Id'),
  rate: t('Rate ($)'),
  frequency: t('Frequency'),
  owned: t('Type')
};

const equipmentFormValues = {
  frequency: 'DAILY'
};

class EquipmentForm extends MobxReactForm {
  constructor(settings, options) {
    super(settings, options);

    this.rootStore = options.rootStore;

    this.searchEquipment = debounce(this.searchEquipment, 250);

    this.reactToSearchParams = reaction(
      () => this.searchParams,
      searchParams => {
        if (searchParams) {
          this.searchEquipment();
        }
      }
    );
  }

  trimmedValues() {
    return trimObject(this.values());
  }

  @computed get searchParams() {
    return {
      name: this.$('name').value,
      equipmentId: this.$('equipmentId').value,
      supplier: this.$('supplier').value
    };
  }

  @action.bound searchEquipment() {
    if (!this.searchParams) return;

    request
      .get(
        `${this.rootStore.urlMicroService('performanceTracking')}/equipment`,
        {
          params: this.searchParams
        }
      )
      .then(response => {
        if (response.data) {
          this.rootStore.equipment.add(response.data.collection, {
            update: true
          });
        }
      });
  }
}

export {
  EquipmentForm,
  equipmentFormOptions,
  equipmentFormFields,
  equipmentFormRules,
  equipmentFormValues,
  equipmentFormLabels,
  equipmentFormPlugins
};
