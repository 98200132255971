import UIStore from './UIStore';
import Classifications from 'stores/collections/Classifications';
import { action, observable, computed, reaction } from 'mobx';
import errorHandler from 'utils/errorHandler';

import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import debounce from 'lodash.debounce';

import { BASE_DEBOUNCE } from 'fixtures/constants';
import { t } from 'utils/translate';

export default class ClassificationSelectorUI extends UIStore {
  @observable loading;
  @observable searchQuery;
  @observable responsibleSort;

  constructor(options) {
    super(options);

    this.loading = false;
    this.searchQuery = '';
    this.responsibleSort = false;

    this.fetchClassifications = debounce(
      this.fetchClassifications,
      BASE_DEBOUNCE
    );

    this.classifications = new Classifications(null, {
      rootStore: this.rootStore
    });
  }

  @action.bound
  setup(options = {}) {
    if (options.responsibleSort) {
      this.responsibleSort = options.responsibleSort;
    }

    this.setupReactions();
    return this.fetchClassifications();
  }

  @action.bound
  tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  @action.bound setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        this.fetchClassifications();
      }
    );
  }

  @action.bound tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @action.bound clearUIState() {
    this.classifications.reset();
    this.loading = false;
    this.searchQuery = '';
    this.responsibleSort = false;
  }

  @computed get options() {
    const options = this.classifications.models.map(classification => {
      return {
        uuid: classification.uuid,
        name: classification.name
      };
    });

    return options;
  }

  @computed get noOptionsText() {
    return this.loading
      ? t('Searching')
      : !this.classifications.hasModels
      ? t('No results')
      : false;
  }

  @action.bound
  setSearchQuery(query) {
    this.searchQuery = query;
  }

  @action.bound
  clearSearchQuery() {
    this.searchQuery = '';
  }

  @computed get hasMoreClassifications() {
    return this.classifications.totalElements > this.classifications.length;
  }

  @computed get params() {
    return {
      query: this.searchQuery,
      projectUuids:
        this.project?.uuid ||
        this.rootStore.timesheetsUI.timesheetsEditUI.timeCardToEdit
          ?.projectUuid ||
        this.rootStore.timesheetsUI.timesheetsAddUI.timeSheetsAddProject?.uuid,
      limit: 80,
      sortField: 'name',
      sortDirection: 'ASC',
      responsibleSort: this.responsibleSort
    };
  }

  @action.bound
  async fetchClassifications(options) {
    this.loading = true;

    try {
      await this.classifications.fetch({
        params: pickBy(this.params, identity),
        ...options
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    } finally {
      this.loading = false;
    }
  }

  fetchNextPage = async (e, autoCompleteRef) => {
    const menu = e.target;

    const scrollTop = menu.scrollTop;
    const scrollHeight = menu.scrollHeight;
    const clientHeight = menu.clientHeight;

    if (scrollTop + clientHeight === scrollHeight) {
      this.fetchNextPageOfClassifications().then(() => {
        autoCompleteRef.current.scrollTop = scrollHeight;
      });
    }
  };

  @action.bound async fetchNextPageOfClassifications() {
    if (this.classifications.fetching || !this.hasMoreClassifications) return;

    try {
      this.classifications.fetch({
        params: Object.assign(pickBy(this.params, identity), {
          offset: this.classifications.length
        }),
        add: true,
        remove: false,
        update: false
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }
}
