import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';
import trimObject from '../../utils/trimObject';

const settingsTrackTimeFormPlugins = extendedPlugins;

const settingsTrackTimeFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false,
  retrieveOnlyEnabledFields: false
};

const settingsTrackTimeFormFields = [
  'timeCardsStartAndEndTime',
  'enableKiosk',
  'overtimeRuleSetUuid',
  'classificationsOnTimeCards',
  'classificationsEditableOnTimeCards',
  'costCodesOnTimeCards',
  'breakSetting'
];

const settingsTrackTimeFormRules = {
  timeCardsStartAndEndTime: 'boolean',
  enableKiosk: 'boolean'
};

const settingsTrackTimeFormDisabled = {};

const settingsTrackTimeFormLabels = {};

const settingsTrackTimeFormValues = {
  enableKiosk: false
};

class SettingsTrackTimeForm extends MobxReactForm {
  trimmedValues() {
    return trimObject(this.values());
  }
}

export {
  SettingsTrackTimeForm,
  settingsTrackTimeFormOptions,
  settingsTrackTimeFormFields,
  settingsTrackTimeFormRules,
  settingsTrackTimeFormValues,
  settingsTrackTimeFormLabels,
  settingsTrackTimeFormPlugins,
  settingsTrackTimeFormDisabled
};
