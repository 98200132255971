import { Model } from 'mobx-mc';
import crossOriginDownload from 'utils/crossOriginDownload';
import { observable, action, computed } from 'mobx';
import moment from 'moment';
import { t } from 'utils/translate';
import orderBy from 'lodash.orderby';
import sortBy from 'lodash.sortby';
import kebabCase from 'lodash.kebabcase';

export default class ScheduledTalk extends Model {
  @observable downloading;
  @observable selected;

  constructor(data, options) {
    super(data, options);

    this.selected = false;
  }

  idAttribute() {
    return 'uuid';
  }

  get restAttributes() {
    return [
      'uuid',
      'scheduleDate',
      'project',
      'talk',
      'attendees',
      'pdf',
      'signaturePhotos',
      'status',
      'businessUuid',
      'projectTalkStatus',
      'completedDates',
      'started',
      'initScheduleDate',
      'group'
    ];
  }

  @computed
  get scheduleDateFormatted() {
    return moment(this.scheduleDate).format('YYYY-MM-DD');
  }

  @computed
  get pdfUrl() {
    return this.pdf;
  }

  @computed
  get hasAttendees() {
    return this.attendees && this.attendees.length > 0;
  }

  @computed
  get sortedAttendees() {
    return orderBy(
      this.attendees,
      [
        item => {
          const fullName = `${item.worker.firstName} ${item.worker.lastName}`;

          return fullName.toLowerCase();
        }
      ],
      ['asc']
    );
  }

  @computed
  get hasSignaturePhotos() {
    return this.signaturePhotos && this.signaturePhotos.length > 0;
  }

  @computed
  get hasCompletedDates() {
    return this.completedDates && this.completedDates.length > 0;
  }

  @computed
  get talkStatus() {
    switch (this.projectTalkStatus) {
      case 'INCOMPLETE':
        return t('Incomplete');
      case 'COMPLETED':
        return t('Completed');
      case 'MISSED':
        return t('Missed');
      default:
        return null;
    }
  }

  @action.bound
  finishDownloading(error) {
    if (error) {
      this.rootStore.notificationsUI.pushNotification({
        title: t('Download Failed'),
        showUndo: false
      });
    }

    this.downloading = false;
  }

  @computed
  get attendeesReportName() {
    let talkName = this.talk.name;

    if (talkName.slice(-4) !== '.pdf') {
      talkName = talkName + '.pdf';
    }
    return `${this.project.name} - ${this.scheduleDate} - ${talkName}`;
  }

  @action.bound
  downloadAttendeesReport() {
    this.downloading = true;
    this.count = this.count || 0;

    return crossOriginDownload(this.pdfUrl, this.attendeesReportName, error => {
      if (error) {
        if (this.count === 60) {
          this.finishDownloading(error);

          this.count = 0;

          return;
        }

        setTimeout(() => {
          this.downloadAttendeesReport();

          this.count++;
        }, 5000);
      } else {
        this.count = 0;

        this.downloading = false;
      }
    });
  }

  @action.bound
  handleToggle() {
    this.selected = !this.selected;
  }

  @computed
  get sortingCompletedDates() {
    return sortBy(this.completedDates);
  }

  @computed
  get completedDatesFormatted() {
    return this.sortingCompletedDates.map(date =>
      moment(date).format('YYYY-MM-DD')
    );
  }

  @action.bound
  reset() {
    this.selected = false;
  }

  @computed get slug() {
    return kebabCase(this.talk.name);
  }

  @computed get statusColor() {
    if (this.projectTalkStatus === 'COMPLETED') {
      return 'green';
    }

    return 'red';
  }

  @computed get signaturePhotoAttachments() {
    return this.signaturePhotos.map(photo => {
      return {
        uuid: photo.uuid,
        contentUrl: photo.contentUrl,
        thumbUrl: photo.thumbUrl,
        thumbnail: photo.thumbUrl,
        hideUndefinedValues: true,
        desc: `${t('Attendees')} for ${this.talk.name} on ${
          this.scheduleDateFormatted
        }`
      };
    });
  }

  @computed get name() {
    return this.talk.name;
  }

  @computed get initScheduleDateFormatted() {
    if (this.initScheduleDate == null) {
      return '';
    }
    return moment(this.initScheduleDate).format('YYYY-MM-DD');
  }

  @computed get groupName() {
    if (this.group == null) {
      return '';
    }
    return this.group.name;
  }
}
