import { RakenCollection } from '../../lib';
import SuperAdminCompany from '../../models/superAdmin/SuperAdminCompany';

export default class SuperAdminCompanies extends RakenCollection {
  model() {
    return SuperAdminCompany;
  }

  url() {
    return 'ra/sadmin/companies';
  }
}
