import MobxReactForm from 'mobx-react-form';
import extendedPlugins from 'forms/utils/extendedPlugins';

const documentFiltersFormPlugins = extendedPlugins;

const documentFiltersFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const documentFiltersFormFields = [
  'typeFilter[]',
  'createdByFilters[]',
  'updatedByFilters[]'
];

const documentFiltersFormRules = {
  typeFilter: 'array',
  createdByFilters: 'array',
  updatedByFilters: 'array'
};

class DocumentFiltersForm extends MobxReactForm {}

export {
  DocumentFiltersForm,
  documentFiltersFormRules,
  documentFiltersFormFields,
  documentFiltersFormOptions,
  documentFiltersFormPlugins
};
