import moment from 'moment';
import { action, computed } from 'mobx';
import UIStore from '../UIStore';

import Balance from 'stores/models/billing/Balance';
import BillingInfo from 'stores/models/billing/BillingInfo';
import Subscription from 'stores/models/billing/Subscription';
import SubscriptionPendingChange from 'stores/models/billing/SubscriptionPendingChange';

import AccountInfoUI from './AccountInfoUI';
import SubscriptionUI from './SubscriptionUI';
import BillingInfoUI from './BillingInfoUI';
import InvoicesUI from './InvoicesUI';
import TransactionsUI from './TransactionsUI';
import HistoricalTransactionsUI from './HistoricalTransactionsUI';

import { t } from 'utils/translate';
import { axiosIgnore404 } from 'utils/axiosIgnore404';

export default class BillingUI extends UIStore {
  constructor(options) {
    super(options);

    this.balance = new Balance(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.billingInfo = new BillingInfo(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.subscriptionModel = new Subscription(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.subscriptionPendingChange = new SubscriptionPendingChange(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.accountInfoUI = new AccountInfoUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.subscriptionUI = new SubscriptionUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.billingInfoUI = new BillingInfoUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.invoicesUI = new InvoicesUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.transactionsUI = new TransactionsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.historicalTransactionsUI = new HistoricalTransactionsUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @computed get company() {
    if (this.isSuperAdmin) {
      return this.parent.activeCompany;
    }

    return this.rootStore.me.company;
  }

  @computed get legacyTransactions() {
    return this.company.transactions;
  }

  @computed get subscription() {
    return this.subscriptionModel;
  }

  @action.bound setup() {
    this.balance
      .fetch({ axios: axiosIgnore404 })
      .catch(error => console.error(error));
    if (!this.subscription.requiresCreation) {
      this.subscriptionPendingChange
        .fetch({ axios: axiosIgnore404 })
        .catch(error => console.error(error));
    }
  }

  @action.bound tearDown() {
    this.balance.clear();
    this.subscriptionPendingChange.clear();
  }

  @computed get accountStatus() {
    if (
      this.balance.pastDue &&
      this.subscription.state !== 'EXPIRED' &&
      this.subscription.state !== 'CANCELED'
    ) {
      return 'PASTDUE';
    }

    return this.subscription.state;
  }

  @computed get expirationMessage() {
    switch (this.subscription.expirationReason.toLowerCase()) {
      case 'canceled':
        return t('This subscription has expired due to cancellation.');
      case 'non-payment':
      case 'nonpayment_gift':
      case 'nonpayment_trial':
        return t(
          'This subscription has expired due to non-payment. Please update the billing details and add a new subscription to restore access.'
        );
      case 'fixed billing cycles':
        return t('This subscription has expired due to fixed billing cycles.');
      case 'tax location invalid':
        return t(
          'This subscription has expired due to the tax location being invalid.'
        );
      default:
        return t('The subscription has expired due to {expirationReason}.', {
          templateStrings: {
            expirationReason: this.subscription.expirationReason
          }
        });
    }
  }

  @computed get accountStatusMessage() {
    if (this.company.isInternal) {
      return t(
        'This account has been labeled as internal. You will not be able to modify Recurly related subscription and billing information.'
      );
    }

    switch (this.accountStatus) {
      case 'PASTDUE':
        return t(
          'This account is past due. Please update billing details and collect payment for the outstanding invoice(s).'
        );
      case 'EXPIRED':
        return this.expirationMessage;
      case 'CANCELED':
        return t(
          'This account requested to cancel their subscription, which is set to expire on {expiryDate}. Please reactivate the subscription if the customer wishes to retain access after that date.',
          {
            templateStrings: {
              expiryDate: moment(this.subscription.expiresAt).format(
                'YYYY-MM-DD'
              )
            }
          }
        );
      default:
        return null;
    }
  }

  @computed get accountStatusMessageForClient() {
    switch (this.accountStatus) {
      case 'PASTDUE':
        return t(
          'Your account is past due. Please update your billing details and make payment for the outstanding invoice(s).'
        );
      case 'CANCELED':
        return t(
          'You have requested to cancel your subscription, which is set to expire on {expiryDate}. If you wish to maintain access after that date, please contact support to request for your subscription to remain active.',
          {
            templateStrings: {
              expiryDate: moment(this.subscription.expiresAt).format(
                'YYYY-MM-DD'
              )
            }
          }
        );
      default:
        return null;
    }
  }

  @computed get accountStatusMessageSeverity() {
    switch (this.accountStatus) {
      case 'EXPIRED':
        return 'error';
      default:
        return 'warning';
    }
  }

  @computed get accountStatusMessageButtonColor() {
    switch (this.accountStatus) {
      case 'EXPIRED':
        return 'red';
      default:
        return 'orange';
    }
  }

  @computed get subscriptionPendingChangeMessageSeverity() {
    return 'warning';
  }

  @computed get subscriptionPendingChangeMessageButtonColor() {
    return 'orange';
  }

  @computed get subscriptionPendingChangeMessage() {
    if (this.subscriptionPendingChange.isNew) {
      return null;
    }
    return t(
      "There are pending changes scheduled to take effect on {changeDate} for this account's subscription. If they no longer wish to make the change, please cancel the pending change.",
      {
        templateStrings: {
          changeDate: this.subscriptionPendingChange.activateAtFormatted
        }
      }
    );
  }

  @computed get subscriptionPendingChangeMessageForClient() {
    if (this.subscriptionPendingChange.isNew) {
      return null;
    }
    return t(
      'There are pending changes scheduled to take effect on {changeDate} for your subscription. If you no longer wish to make the change, please contact support to cancel the change.',
      {
        templateStrings: {
          changeDate: this.subscriptionPendingChange.activateAtFormatted
        }
      }
    );
  }

  @computed get subscriptionPendingChangeDetails() {
    if (this.subscriptionPendingChange.isNew) {
      return null;
    }
    return this.subscriptionPendingChange.changeDetails;
  }

  @computed get activeCoupon() {
    return this.accountInfoUI.activeCoupon;
  }

  @action.bound
  showUpgradeModal() {
    this.showModal('upgradeModal');
  }

  @action.bound
  hideUpgradeModal() {
    this.hideActiveModal();
  }

  @computed
  get extendedPermissions() {
    return this.parent.extendedPermissions;
  }

  @action.bound async refreshAll() {
    this.company.fetch();

    this.tearDown();
    this.setup();

    this.accountInfoUI.tearDown();
    this.accountInfoUI.setup();

    this.billingInfoUI.tearDown();
    this.billingInfoUI.setup();

    this.subscriptionUI.tearDown();
    this.subscriptionUI.setup();

    this.invoicesUI.tearDown();
    this.invoicesUI.setup();

    this.transactionsUI.tearDown();
    this.transactionsUI.setup();
  }

  @computed get hasSeatOverages() {
    if (this.subscription.requiresCreation) {
      return false;
    }
    return this.subscription.seatsRemaining < 0;
  }

  @action.bound editSubscription() {
    this.subscriptionUI.editSubscription();
  }
}
