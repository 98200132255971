import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import trimObject from 'utils/trimObject';
import { t } from 'utils/translate';

const certificationTypeFormPlugins = extendedPlugins;

const certificationTypeFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const certificationTypeFormFields = ['description', 'name'];

const certificationTypeFormValues = {};

const certificationTypeFormRules = {
  name: 'string|required',
  description: 'string'
};

const certificationTypeFormLabels = {
  name: t('type'),
  description: t('description')
};

class CertificationTypeForm extends MobxReactForm {
  trimmedValues() {
    return trimObject(this.values());
  }
}

export {
  CertificationTypeForm,
  certificationTypeFormPlugins,
  certificationTypeFormOptions,
  certificationTypeFormFields,
  certificationTypeFormRules,
  certificationTypeFormLabels,
  certificationTypeFormValues
};
