import omit from 'lodash.omit';
import isObject from 'lodash.isobject';
import moment from 'moment-timezone';
import { Model } from 'mobx-mc';
import { computed } from 'mobx';
import User from './User';
import ProjectMinimal from './ProjectMinimal';
import Company from './Company';

import getContributorName from './utils/getContributorName';

export default class ReportEntry extends Model {
  parse(attributes) {
    this.parseCreatedBy(attributes);
    this.parseUpdatedBy(attributes);
    this.parseProject(attributes);
    this.parseCompany(attributes);

    return {
      ...omit(attributes, [
        'createdBy',
        'updatedBy',
        'createdByRef',
        'updatedByRef',
        'company',
        'project'
      ])
    };
  }

  parseCompany(attributes) {
    if (attributes.company) {
      this.company = new Company(attributes.company, {
        rootStore: this.rootStore
      });
    }
  }

  parseProject(attributes) {
    if (attributes.project) {
      this.project = new ProjectMinimal(attributes.project, {
        rootStore: this.rootStore
      });
    }
  }

  parseCreatedBy(attributes) {
    if (isObject(attributes.createdBy)) {
      this.createdBy = new User(attributes.createdBy, {
        rootStore: this.rootStore
      });
    } else if (
      attributes.createdBy < 1 ||
      attributes.createdByRef?.name === 'raken-tracking-system-user'
    ) {
      this.createdBy = this.rootStore.me;
    } else if (attributes.createdByRef) {
      this.createdBy = new User(attributes.createdByRef, {
        rootStore: this.rootStore
      });
    }
  }

  parseUpdatedBy(attributes) {
    if (isObject(attributes.updatedBy)) {
      this.updatedBy = new User(attributes.updatedBy, {
        rootStore: this.rootStore
      });
    } else if (
      attributes.updatedBy < 1 ||
      attributes.updatedByRef?.name === 'raken-tracking-system-user'
    ) {
      this.updatedBy = this.rootStore.me;
    } else if (attributes.updatedByRef) {
      this.updatedBy = new User(attributes.updatedByRef, {
        rootStore: this.rootStore
      });
    }
  }

  @computed
  get relatedProject() {
    if (this.project) {
      return this.project;
    }

    if (this.relatedReport?.relatedProject) {
      return this.relatedReport.relatedProject;
    }

    if (this.collection?.project) {
      return this.collection.project;
    }

    return null;
  }

  @computed get segmentName() {
    if (this.activitySegmentName) {
      return this.activitySegmentName;
    }

    if (!this.segmentUuid) return null;

    return this.relatedProject.segments?.find(
      segment => segment.uuid === this.segmentUuid
    )?.name;
  }

  @computed
  get timezone() {
    return (
      (this.relatedProject && this.relatedProject.address?.timezone) ||
      moment.tz.guess()
    );
  }

  @computed
  get timezoneAbbr() {
    return moment.tz(this.timezone).zoneAbbr();
  }

  @computed
  get timezoneSameAsLocal() {
    return this.timezone === moment.tz.guess();
  }

  @computed
  get projectName() {
    return this.relatedProject && this.relatedProject.name;
  }

  @computed
  get relatedReport() {
    return this.collection && this.collection.parent;
  }

  @computed
  get relatedReportDate() {
    return (
      this.reportDate ||
      (this.relatedReport && this.relatedReport.date) ||
      this.lastUpdatedInternational
    );
  }

  @computed
  get relatedReportDateFormatted() {
    return (
      this.relatedReportDate &&
      moment(this.relatedReportDate).format('YYYY-MM-DD')
    );
  }

  @computed
  get relatedReportDateDay() {
    return (
      this.relatedReportDate && moment(this.relatedReportDate).format('DD')
    );
  }

  @computed
  get relatedReportDateMonth() {
    return (
      this.relatedReportDate && moment(this.relatedReportDate).format('MM')
    );
  }

  @computed
  get relatedReportDateYear() {
    return (
      this.relatedReportDate && moment(this.relatedReportDate).format('YY')
    );
  }

  @computed
  get createdAtFormatted() {
    return (
      this.createdTimestamp &&
      moment(this.createdTimestamp).format('YYYY-MM-DD')
    );
  }

  @computed
  get updatedAtFormatted() {
    return (
      this.updatedTimestamp &&
      moment(this.updatedTimestamp).format('YYYY-MM-DD')
    );
  }

  @computed
  get isForToday() {
    return (
      this.relatedReportDate &&
      moment(this.relatedReportDate).isSame(moment(), 'day')
    );
  }

  @computed
  get lastUpdated() {
    return moment(this.updatedTimestamp || this.createdTimestamp).tz(
      this.timezone
    );
  }

  @computed
  get lastUpdatedRounded() {
    return moment(this.lastUpdated)
      .endOf('minute')
      .valueOf();
  }

  @computed
  get lastUpdatedFormatted() {
    return this.lastUpdated && moment(this.lastUpdated).format('YYYY-MM-DD');
  }

  @computed
  get lastUpdatedTimeFormatted() {
    return (
      this.lastUpdated &&
      moment(this.lastUpdated).format(`h:mmA ${'YYYY-MM-DD'}`)
    );
  }

  @computed
  get lastUpdatedInternational() {
    return moment(this.lastUpdated).format('YYYY-MM-DD');
  }

  @computed
  get lastUpdatedToday() {
    return (
      this.lastUpdated &&
      moment(this.lastUpdated)
        .startOf('day')
        .isSame(moment().startOf('day'), 'day')
    );
  }

  @computed
  get lastUpdatedBy() {
    return this.updatedBy || this.createdBy;
  }

  @computed
  get contributorName() {
    if (!this.updatedBy?.fullName && !this.createdBy?.fullName) {
      return this.rootStore.me.fullName;
    }

    return getContributorName(this.updatedBy, this.createdBy);
  }

  @computed
  get externalTeamEntry() {
    if (!this.company?.id) return false;

    return this.company.id !== this.rootStore.me.company.id;
  }

  @computed
  get externalTeamEntryByUuid() {
    if (!this.company?.uuid) return false;

    return this.company.uuid !== this.rootStore.me.company.uuid;
  }

  @computed
  get pdfLink() {
    if (this.relatedReport) {
      return this.relatedReport.pdfLink;
    }

    if (this.pdfs && this.pdfs.super) {
      return this.pdfs.super;
    }

    if (this.pdfs && this.pdfs.daily) {
      return this.pdfs.daily;
    }

    return null;
  }

  @computed get viewUrl() {
    /**
     * TODO This is for testing only and this function should be replaced by the BE when tidying
     */
    if (!this.relatedProject) return '';

    // TODO - Once sub content is readable remove this and allow linking
    // directly to a sub entry in read-only format. For now, we just keep the old
    // behaviour of navigating to GC Report page
    let idIfNotExternal = this.externalTeamEntry ? '' : `/${this.uuid}`;

    const dateQueryParam = `?date=${this.relatedReportDate}`;

    const segmentQueryParam = this.segmentUuid
      ? `&segmentUuid=${this.segmentUuid}`
      : '';

    if (
      this.type === 'EquipmentLogNoteActivity' ||
      this.type === 'EquipmentLogNote'
    ) {
      idIfNotExternal = this.externalTeamEntry
        ? ''
        : `/${this.equipmentLog.uuid}`;
    }

    const extension = `${idIfNotExternal}${dateQueryParam}${segmentQueryParam}`;

    switch (this.type) {
      case 'StandardWorklogActivity':
        return `${this.relatedProject.viewUrl}/worklogs${extension}`;
      case 'CrewWorklogActivity':
        return `${this.relatedProject.viewUrl}/timecards${extension}`;
      case 'MaterialLogDto':
      case 'MaterialLogActivity':
        return `${this.relatedProject.viewUrl}/materials${extension}`;
      case 'EquipmentLog':
      case 'EquipmentLogActivity':
      case 'EquipmentLogNote':
      case 'EquipmentLogNoteActivity':
        return `${this.relatedProject.viewUrl}/equipment${extension}`;
      case 'Note':
      case 'NoteActivity':
        return `${this.relatedProject.viewUrl}/notes${extension}`;
      case 'Task':
      case 'TaskActivity':
        return `${this.relatedProject.viewUrl}/tasks${extension}`;
      case 'ReportAnswer':
      case 'SurveyActivity':
        return `${this.relatedProject.viewUrl}/survey/edit${dateQueryParam}${segmentQueryParam}`;
      case 'Attachment':
      case 'AttachmentActivity':
        return `${this.relatedProject.viewUrl}/attachments${dateQueryParam}${segmentQueryParam}`;
      default:
        return '';
    }
  }
}
