import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { Overlay } from 'react-overlays';
import Arrow from './Arrow';

const getWrapperStyles = props => {
  const resetTop = props.heightWrapper / 2 + props.positionTop;

  switch (props.placement) {
    case 'left':
      return `
        top: ${
          props.disabledCenter
            ? `${resetTop}px!important`
            : `50% ${props.container ? '!important' : ''}`
        };
        transform: translate(${props.container ? '0, -50%' : '0, 0'});
        margin: ${props.marginTop || 0} 0 0 -18px
      `;
    case 'right':
      return `
        top: ${
          props.disabledCenter
            ? `${resetTop}px!important`
            : `50% ${props.container ? '!important' : ''}`
        };
        transform: translate(${props.container ? '0, -50%' : '0, 0'});
        margin: ${props.marginTop || 0}px 0 0 ${props.marginLeft || 18}px;
      `;
    case 'top':
      return `
        left: 50% ${props.container ? '!important' : ''};
        transform: translate(${props.container ? '-50%, 0' : '0, 0'});
        margin: ${props.marginTop || '-28'}px 0 0 0;
      `;
    case 'bottom':
      return `
        left: 50% ${props.container ? '!important' : ''};
        transform: translate(${props.container ? '-50%, 0' : '0, 0'});
        margin: ${props.marginTop || 12}px 0 0 ${props.marginLeft || 18}px;
      `;
    default:
      return null;
  }
};

const Wrapper = styled.div`
  position: absolute;
  pointer-events: ${props => (props.trigger === 'hover' ? 'none' : 'all')};
  padding: 0;
  z-index: 2041;
  ${props => getWrapperStyles(props)};
  ${props =>
    props.disabledCenter &&
    css`
      visibility: ${props.heightWrapper ? 'visible' : 'hidden'};
    `}
`;

const Content = styled.div`
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.lightGrey3};
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  color: ${props => props.theme.colors.charcoalGrey};
  font-family: ${props => props.theme.fonts.primary};
  font-weight: 400;
  font-size: 1.4rem;
  width: ${props => `${props.width || 320}px`};
  min-height: ${props => `${props.minHeight || 100}px`};
  max-height: ${props => `${props.maxHeight || 'inherit'}`};
  padding: ${props => props.padding || '2rem'};
`;

export default class Popover extends Component {
  constructor(props) {
    super(props);

    this.state = {
      heightWrapper: 0
    };
  }

  componentDidUpdate() {
    setTimeout(() => {
      if (
        this.overlayRef &&
        this.overlayRef.clientHeight &&
        !this.state.heightWrapper
      ) {
        this.setState({
          heightWrapper: this.overlayRef.clientHeight
        });
      }
    });
  }

  render() {
    const {
      show,
      target,
      onHide,
      rootClose,
      placement,
      container,
      arrowOffsetLeft,
      arrowOffsetTop,
      width,
      marginLeft,
      marginTop,
      padding,
      trigger,
      children,
      onMouseOver,
      onMouseOut,
      minHeight,
      maxHeight,
      disabledCenter,
      dataQA
    } = this.props;
    const { heightWrapper } = this.state;

    return (
      <Overlay
        show={show}
        target={target}
        onHide={onHide}
        rootClose={rootClose}
        placement={placement}
        container={container}
        shouldUpdatePosition={false}
      >
        <Wrapper
          placement={placement}
          container={container}
          trigger={trigger}
          marginLeft={marginLeft}
          marginTop={marginTop}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          ref={ref => (this.overlayRef = ref)}
          disabledCenter={disabledCenter}
          heightWrapper={heightWrapper}
          id="popoverCatchScroll"
          data-qa={dataQA}
        >
          <Content
            width={width}
            padding={padding}
            minHeight={minHeight}
            maxHeight={maxHeight}
          >
            <Arrow
              placement={placement}
              top={arrowOffsetTop}
              left={arrowOffsetLeft}
            />
            {children}
          </Content>
        </Wrapper>
      </Overlay>
    );
  }
}
