import UIStore from './UIStore';
import Shifts from 'stores/collections/Shifts';
import { action, observable, computed, reaction } from 'mobx';
import errorHandler from 'utils/errorHandler';

import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import debounce from 'lodash.debounce';

import { BASE_DEBOUNCE } from 'fixtures/constants';
import { t } from 'utils/translate';

export default class ShiftSelectorUI extends UIStore {
  @observable searchQuery;
  @observable hasShifts;
  @observable loading;

  constructor(options) {
    super(options);

    this.loading = false;
    this.hasShifts = false;

    this.searchQuery = '';
    this.fetchShifts = debounce(this.fetchShifts, BASE_DEBOUNCE);

    this.shifts = new Shifts(null, {
      rootStore: this.rootStore
    });
  }

  @action.bound
  setup() {
    this.setupReactions();
    return this.fetchShifts();
  }

  @action.bound
  tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  @action.bound setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        this.fetchShifts();
      }
    );
  }

  @action.bound tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @action.bound clearUIState() {
    this.shifts.reset();
    this.loading = false;
    this.searchQuery = '';
  }

  @computed get options() {
    return this.shifts.models.map(shift => {
      return {
        uuid: shift.uuid,
        name: shift.name
      };
    });
  }

  @computed get noOptionsText() {
    return this.loading
      ? t('Searching')
      : !this.shifts.hasModels
      ? t('No results')
      : false;
  }

  @action.bound
  setSearchQuery(query) {
    this.searchQuery = query;
  }

  @action.bound
  clearSearchQuery() {
    this.searchQuery = '';
  }

  @computed get hasMoreCostCodes() {
    return this.shifts.totalElements > this.shifts.length;
  }

  @computed get params() {
    return {
      query: this.searchQuery,
      projectUuids:
        this.project?.uuid ||
        this.rootStore.timesheetsUI.timesheetsEditUI.timeCardToEdit
          ?.projectUuid ||
        this.rootStore.timesheetsUI.timesheetsAddUI.timeSheetsAddProject?.uuid,
      limit: 80,
      sortField: 'name',
      sortDirection: 'ASC'
    };
  }

  @action.bound
  async fetchShifts(options) {
    this.loading = true;
    try {
      await this.shifts.fetch({
        params: pickBy(this.params, identity),
        ...options
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    } finally {
      this.loading = false;
      if (!this.params.query) {
        this.hasShifts = Boolean(this.shifts.models.length);
      }
    }
  }

  fetchNextPage = async (e, autoCompleteRef) => {
    const menu = e.target;

    const scrollTop = menu.scrollTop;
    const scrollHeight = menu.scrollHeight;
    const clientHeight = menu.clientHeight;

    if (scrollTop + clientHeight === scrollHeight) {
      this.fetchNextPageOfShifts().then(() => {
        autoCompleteRef.current.scrollTop = scrollHeight;
      });
    }
  };

  @action.bound async fetchNextPageOfShifts() {
    if (this.shifts.fetching) return;

    try {
      this.shifts.fetch({
        params: Object.assign(pickBy(this.params, identity), {
          offset: this.shifts.length
        }),
        add: true,
        remove: false,
        update: false
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }
}
