import { action, computed } from 'mobx';
import IntegrationUI from 'stores/ui/company/integrations/IntegrationUI';
import SuperAdminCompanyIntegrationMappingUI from './SuperAdminCompanyIntegrationMappingUI';
import SuperAdminCompanyIntegrationEtlJobsUI from './SuperAdminCompanyIntegrationEtlJobsUI';
import SuperAdminCompanyIntegrationDebugUI from './SuperAdminCompanyIntegrationDebugUI';

import { t } from 'utils/translate';

export default class SuperAdminIntegrationUI extends IntegrationUI {
  constructor(options) {
    super(options);

    this.integrationMappingUI = new SuperAdminCompanyIntegrationMappingUI({
      rootStore: this.rootStore,
      parent: this
    });

    this.integrationEtlJobsUI = new SuperAdminCompanyIntegrationEtlJobsUI({
      rootStore: this.rootStore,
      parent: this
    });

    this.integrationDebugUI = new SuperAdminCompanyIntegrationDebugUI({
      rootStore: this.rootStore,
      parent: this
    });

    this.activeModal = null;
  }

  @computed get activeCompany() {
    return this.parent.activeCompany;
  }

  @computed get planUpgradeRequired() {
    const billingPlan = this.activeCompany?.subscription?.billingPlanName;
    return billingPlan !== 'Performance' && billingPlan !== 'Professional';
  }

  @computed
  get planUpgradeRequiredMessage() {
    return t(
      'This feature is only available to Professional and Performance plan members. Please upgrade your plan to unlock this feature.'
    );
  }

  @action.bound
  showFeatureAccessModal() {
    this.activeModal = 'featureAccess';
  }

  @action.bound
  hideFeatureAccessModal() {
    this.activeModal = null;
  }
}
