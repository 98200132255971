import { RakenCollection } from 'stores/lib';

import Integration from 'stores/models/integrations/Integration';
import ProjectIntegration from 'stores/models/integrations/ProjectIntegration';

export default class Integrations extends RakenCollection {
  model() {
    if (this.parent?.id) {
      return ProjectIntegration;
    }

    return Integration;
  }

  /**
   * Apply options to a Collection
   */
  applyOptions(options) {
    super.applyOptions(options);
    if (options.type) {
      this.type = options.type;
    }
  }

  url() {
    if (this.parent?.id) {
      return `${this.rootStore.urlMicroService('integrations')}/projects/${
        this.parent.uuid
      }/integrations`;
    }

    return `${this.rootStore.urlMicroService('integrations')}/companies/${
      this.rootStore.me.company.uuid
    }/integrations`;
  }
}
