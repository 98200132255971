import { computed, toJS } from 'mobx';
import { Model } from 'mobx-mc';

export default class ScheduledReport extends Model {
  get restAttributes() {
    return [
      'uuid',
      'projectIncludeOption',
      'projects',
      'emails',
      'includeCostCode',
      'name',
      'time',
      'offsetDays',
      'frequencyType',
      'sendReportNow',
      'actionDate',
      'timeFrameType'
    ];
  }

  @computed
  get formValues() {
    return {
      name: this.name,
      projects: toJS(this.projects),
      emails: toJS(this.emails),
      projectIncludeOption: this.projectIncludeOption,
      email: this.email,
      offsetDays: this.offsetDays,
      time: {
        hour: this.time.hour.toLocaleString(
          'en-US',
          { minimumIntegerDigits: 2, useGrouping: false } // Convert single digit numbers to double for use with the native time picker
        ),
        minute: this.time.minute.toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        })
      },
      includeCostCode: this.includeCostCode,
      frequencyType: this.frequencyType,
      sendReportNow: this.sendReportNow,
      actionDate: this.actionDate,
      timeFrameType: this.timeFrameType
    };
  }

  idAttribute() {
    return 'uuid';
  }

  get urlRoot() {
    return `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/timesheets/reports/scheduled`;
  }
}
