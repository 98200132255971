import styled, { css } from 'styled-components';
import { borderRadius } from 'polished';
import media from '../utils/mediaTemplate';
import H1 from './H1';

const Header = styled.div`
  background-color: ${props => props.theme.colors.white};
  ${borderRadius('top', '6px')};
  ${props =>
    props.boxShadow &&
    css`
      box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.15);
    `};
  text-align: ${props => (props.center ? 'center' : 'left')};
  ${props =>
    props.padding
      ? css`
          padding: ${props => props.padding};
        `
      : css`
          padding: ${props =>
            props.withTabs ? '1rem 2rem 0 2rem' : '1rem 2rem 2rem 2rem'};
        `};
  position: relative;
  z-index: 100;
  word-wrap: break-word;
  ${media.desktop`
    ${borderRadius('top', '16px')};
    ${props =>
      props.padding
        ? css`
            padding: ${props => props.padding};
          `
        : css`padding: ${props =>
            props.withTabs ? '2rem 3rem 0 3rem' : '2rem 3rem'};
      };
    `};
  `};
  ${H1} {
    max-height: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
`;

Header.defaultProps = {
  boxShadow: true
};

export default Header;
