import { RakenCollection } from '../lib';
import Certification from '../models/Certification';

export default class Certifications extends RakenCollection {
  model() {
    return Certification;
  }

  url() {
    return `/ra/companies/${this.rootStore.me.company.uuid}/certifications`;
  }
}
