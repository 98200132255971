import ReportEntryWithAttachments from './ReportEntryWithAttachments';
import { computed } from 'mobx';
import { t } from 'utils/translate';
import moment from 'moment';
import kebabCase from 'lodash.kebabcase';

export default class EquipmentLogActivity extends ReportEntryWithAttachments {
  get urlRoot() {
    return `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/companies/${this.rootStore.me.company.uuid}/equipmentlogs`;
  }

  get restAttributes() {
    return [
      'uuid',
      'startDate',
      'endDate',
      'status',
      'daysOnsite',
      'projectUuid',
      'createdTimestamp',
      'updatedTimestamp',
      'type',
      'daysIdle',
      'noteCount',
      'project',
      'teamId',
      'equipment',
      'costCode',
      'createdBy',
      'updatedBy',
      'project',
      'company',
      'equipmentId',
      'supplier',
      'activityViewUrl',
      'activitySegmentName'
    ];
  }

  idAttribute() {
    return 'uuid';
  }

  get restAttributeDefaults() {
    return {
      status: 'INUSE',
      noteCount: 0,
      equipment: {
        projects: [],
        rate: null
      },
      activityViewUrl: '/'
    };
  }

  @computed
  get formattedEndDate() {
    if (!this.endDate) {
      return '';
    }

    return moment(this.endDate).format('YYYY-MM-DD');
  }

  @computed
  get formattedStartDate() {
    if (!this.startDate) {
      return moment().format('YYYY-MM-DD');
    }

    return moment(this.startDate).format('YYYY-MM-DD');
  }

  @computed get statusText() {
    switch (this.status) {
      case 'INUSE':
        return t('In use');
      case 'IDLE':
        if (this.daysIdle > 0) {
          return t('Idle ({daysIdle} day{s})', {
            templateStrings: {
              daysIdle: this.daysIdle,
              s: this.daysIdle === 1 ? '' : 's'
            }
          });
        }

        return t('Idle');
      case 'REMOVED':
        return t('Removed');
      default:
        return t('None');
    }
  }

  @computed get statusTextNoIdleDaysCount() {
    switch (this.status) {
      case 'INUSE':
        return t('In use');
      case 'IDLE':
        return t('Idle');
      case 'REMOVED':
        return t('Removed');
      default:
        return t('None');
    }
  }

  @computed get statusColor() {
    switch (this.status) {
      case 'INUSE':
        return 'goGreen';
      case 'IDLE':
        return 'lightGrey1';
      case 'REMOVED':
        return 'red';
      default:
        return 'lightGrey1';
    }
  }

  // Todo - replace the above once fully migrated
  // to athens
  @computed get athensStatusColor() {
    switch (this.status) {
      case 'INUSE':
        return 'green';
      case 'IDLE':
        return 'grey';
      case 'REMOVED':
        return 'red';
      default:
        return 'grey';
    }
  }

  @computed
  get title() {
    return t('Equipment Log');
  }

  @computed get activityTitle() {
    return `${t('submitted an')} ${this.title}`;
  }

  @computed get slug() {
    if (this.costCode) {
      return kebabCase(
        `${this.equipment.name} ${this.costCode.division} ${this.costCode.code}`
      );
    }

    return kebabCase(this.equipment.name);
  }
}
