import { computed } from 'mobx';
import ReportEntryWithAttachments from './ReportEntryWithAttachments';

import { t } from 'utils/translate';

export default class EquipmentDeploymentHistory extends ReportEntryWithAttachments {
  get urlRoot() {
    return `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/companies/${this.rootStore.me.company.uuid}/equipment/deployments`;
  }

  get restAttributes() {
    return [
      'equipmentLogUuid',
      'logDate',
      'project',
      'costCode',
      'hours',
      'operator',
      'logStatus'
    ];
  }

  idAttribute() {
    return 'equipmentLogUuid';
  }

  @computed get operatorFullName() {
    return `${this.operator.firstName} ${this.operator.lastName}`;
  }

  @computed get logStatusFormatted() {
    switch (this.logStatus) {
      case 'IN_USE':
        return t('In use');
      case 'NOT_IN_USE':
        return t('Not in use');
      case 'WITHDRAWN':
        return t('Withdrawn');
      default:
        return t('Not in use');
    }
  }

  @computed get costCodeFormatted() {
    return this.costCode
      ? `${this.costCode?.code} - ${this.costCode?.division}`
      : `-`;
  }
}
