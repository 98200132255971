import {
  IncidentForm,
  incidentFormFields,
  incidentFormLabels,
  incidentFormOptions,
  incidentFormPlugins,
  incidentFormRules
} from 'forms/incident';
import { action, computed } from 'mobx';
import moment from 'moment';

import Incident from 'stores/models/incidents/Incident';
import MemberSelectorUI from 'stores/ui/MemberSelectorUI';
import ProjectChildEditUI from 'stores/ui/project/ProjectChildEditUI';

import alertErrorHandler from 'utils/alertErrorHandler';
import history from 'utils/history';
import {
  INCIDENT_EDITED,
  INCIDENT_VIEWED
} from 'utils/segmentAnalytics/eventSpec';
import { callTrack } from 'utils/segmentIntegration';

import { t } from 'utils/translate';

export default class IncidentEditUI extends ProjectChildEditUI {
  constructor(options) {
    super(options);

    this.notificationMemberSelectorUI = new MemberSelectorUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound setup(uuid) {
    this.fetchEntry(uuid);
    this.notificationMemberSelectorUI.setup({
      projectUuids: [this.project.uuid],
      sortField: 'firstName, lastName',
      role: [
        'ROLE_ACCOUNT_ADMIN',
        'ROLE_ADMIN',
        'ROLE_PROJECT_MEMBER',
        'ROLE_USER'
      ]
    });
  }

  @action.bound async fetchEntry(uuid) {
    if (this.entryForEdit) return;

    let model = this.parent.incidents.getByIdOrUuid(uuid);

    if (!model) {
      model = new Incident(
        {
          uuid: uuid
        },
        {
          rootStore: this.rootStore
        }
      );
    }

    try {
      await model.fetch();

      this.setEntryForEdit(model);
    } catch (error) {
      this.cancelIncidentEdit();
    }
  }

  @action.bound setEntryForEdit(model) {
    this.entryForEdit = model;

    this.setupEntryEditForm();

    callTrack(INCIDENT_VIEWED, {
      project_id: this.entryForEdit.projectUuid,
      project_name: this.entryForEdit.projectName,
      incident_title: this.entryForEdit.incidentTitle
    });

    this.blockHistoryIfFormChanged();
  }

  @action.bound setupEntryEditForm() {
    this.entryEditForm = new IncidentForm(
      {
        fields: incidentFormFields,
        rules: incidentFormRules,
        values: this.entryForEdit.formValues,
        labels: incidentFormLabels
      },
      {
        options: incidentFormOptions,
        plugins: incidentFormPlugins
      }
    );
  }

  @action.bound resetEntryEditForm() {
    this.setupEntryEditForm();
  }

  @action.bound cancelIncidentEdit() {
    history.push({
      pathname: `${this.project.viewUrl}/incidents`,
      search: this.baseQueryParams
    });
  }

  @action.bound tearDown() {
    super.tearDown();

    this.notificationMemberSelectorUI.tearDown();
  }

  @computed get title() {
    return t('Edit incident');
  }

  @computed get incidentPIIHelperText() {
    return this.parent.incidentPIIHelperText;
  }

  @computed get incidentNotificationText() {
    return this.parent.incidentNotificationText;
  }

  @computed
  get incidentStatusOptions() {
    return this.parent.statusOptions;
  }

  @computed get incidentInjuryIllnessOptions() {
    return this.parent.incidentInjuryIllnessOptions;
  }

  @computed get employeeGenderOptions() {
    return this.parent.employeeGenderOptions;
  }

  @computed get incidentOutcomeOptions() {
    return this.parent.incidentOutcomeOptions;
  }

  @computed get isIncidentOutcomeDeath() {
    return this.entryEditForm.$('incidentOutcome').value === 1;
  }

  @computed
  get selectedStatusOption() {
    return this.incidentStatusOptions.find(
      option => option.value === this.entryEditForm.$('status').value
    );
  }

  @computed
  get selectedIncidentInjuryIllnessOption() {
    return this.incidentInjuryIllnessOptions.find(
      option =>
        option.value === this.entryEditForm.$('injuryOrIllnessType').value
    );
  }

  @computed
  get selectedEmployeeGenderOption() {
    return this.employeeGenderOptions.find(
      option => option.value === this.entryEditForm.$('employeeGender').value
    );
  }

  @computed
  get selectedIncidentOutcomeOption() {
    return this.incidentOutcomeOptions.find(
      option => option.value === this.entryEditForm.$('incidentOutcome').value
    );
  }

  @computed get hasWriteAccess() {
    return this.parent.hasWriteAccess;
  }

  @action.bound async submitEntryEditFormSuccess() {
    this.saving = true;

    try {
      const payload = this.entryEditForm.trimmedValues();

      payload.notifications = payload.notifications.map(notification => {
        return {
          uuid: notification.uuid,
          fullName: notification.name,
          email: notification.email,
          phoneNumber: notification.phoneNumber
        };
      });

      payload.attachments = this.entryForEdit.attachments.models.map(
        attachment => {
          return {
            uuid: attachment.uuid
          };
        }
      );

      payload.incidentDate = payload.incidentDate
        ? moment(payload.incidentDate).format('YYYY-MM-DD')
        : null;

      payload.incidentTime = payload.incidentTime || null;
      payload.incidentLocation = payload.incidentLocation || null;

      payload.employeeDateOfBirth = payload.employeeDateOfBirth
        ? moment(payload.employeeDateOfBirth).format('YYYY-MM-DD')
        : null;

      payload.employeeDateOfHire = payload.employeeDateOfHire
        ? moment(payload.employeeDateOfHire).format('YYYY-MM-DD')
        : null;

      payload.dateOfDeath =
        this.isIncidentOutcomeDeath && payload.dateOfDeath
          ? moment(payload.dateOfDeath).format('YYYY-MM-DD')
          : null;

      await this.entryForEdit.save(payload, {
        wait: true
      });

      callTrack(INCIDENT_EDITED, {
        project_id: this.entryForEdit.projectUuid,
        project_name: this.entryForEdit.projectName,
        account_type: this.me?.company?.accountSubType,
        incident_title: this.entryForEdit.incidentTitle,
        incident_status: this.entryForEdit.status,
        incident_recordable: this.entryForEdit.isRecordable,
        incident_has_notifications: this.entryForEdit.notifications.length > 0,
        incident_date: this.entryForEdit.incidentDate,
        incident_injury_or_illness_type: this.entryForEdit.injuryOrIllnessType,
        incident_employee_job_title: this.entryForEdit.employeeJobTitle,
        incident_outcome: this.entryForEdit.incidentOutcome
      });

      this.unblockHistory();
      this.parent.refetchIncidents();
      this.hideActiveModal();
      this.cancelIncidentEdit();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Incident updated')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }
}
