import request from 'axios';
import { action } from 'mobx';
import moment from 'moment';
import DocumentsFile from 'stores/models/documents/DocumentsFile';
import { callTrack } from 'utils/segmentAnalytics';
import { DOCUMENTS_NEW_DOCUMENT_UPLOADED } from 'utils/segmentAnalytics/eventSpec';

import { RakenCollection } from '../../lib';

export default class DocumentsFiles extends RakenCollection {
  model() {
    return DocumentsFile;
  }

  url() {
    return `/ra/companies/${this.rootStore.me.company.uuid}/document-storage/files`;
  }

  @action.bound
  async upload(options) {
    const { file, metadata, progressCallback, analytics } = options;

    const lastModified = moment(
      file.lastModified || file.lastModifiedDate.getTime()
    ).toISOString();

    const type = file.type || 'application/octet-stream';

    const signedUrlResponse = await request.post('/ra/media/request-upload', {
      originalFileName: file.name,
      lastModifiedDateTime: lastModified,
      contentType: `${type}`,
      contentLength: file.size,
      withContentDisposition: true,
      attachmentType: 'DOCUMENT_STORAGE'
    });

    if (signedUrlResponse.data) {
      await request.put(signedUrlResponse.data.url, file, {
        onUploadProgress: progressEvent => {
          let percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          if (progressCallback) {
            progressCallback(percentCompleted);
          }
        },
        transformRequest: [
          function(data, headers) {
            headers.put['Content-Type'] = `${type}`;

            Object.keys(signedUrlResponse.data.customHeaders).forEach(key => {
              headers.put[key] = signedUrlResponse.data.customHeaders[key];
            });

            return data;
          }
        ]
      });

      let data = {};

      if (metadata) {
        data = {
          s3ObjectKey: signedUrlResponse.data.key,
          ...metadata
        };
      } else {
        data = {
          s3ObjectKey: signedUrlResponse.data.key
        };
      }

      const response = await request.post(this.url(), data);

      if (response.data) {
        if (options.previewDocument) {
          this.remove(options.previewDocument);

          this.add(response.data, {
            at: this.models.indexOf(options.previewDocument)
          });
        } else {
          this.add(response.data);
        }

        const file = this.get(response.data.uuid);
        const document = file.document;

        document.setUploadProgress(100);

        if (analytics) {
          callTrack(DOCUMENTS_NEW_DOCUMENT_UPLOADED, {
            project_id: analytics.project.id,
            project_name: analytics.project.name,
            file_name: file.name,
            content_type: type
          });
        }

        return file;
      }
    }
  }
}
