import request from 'axios';
import { RakenCollection } from '../lib';
import { action } from 'mobx';
import Observation from '../models/Observation';

export default class ChecklistObservations extends RakenCollection {
  model() {
    return Observation;
  }

  url() {
    return (
      this.rootStore.urlMicroService('toolbox') +
      `/companies/${this.rootStore.me.company.uuid}/checklists/${this.parent.relatedChecklist.uuid}/questions/${this.parent.questionUuid}/observations`
    );
  }

  @action.bound async attach(observationUuids) {
    const response = await request.post(this.url(), observationUuids);
    this.add(response.data.collection);
  }
}
