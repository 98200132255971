import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import { computed, reaction } from 'mobx';
import { t } from 'utils/translate';
import moment from 'moment';

const timeEntriesBreakFormPlugins = extendedPlugins;

const timeEntriesBreakFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const timeEntriesBreakFormFields = [
  'uuid',
  'duration',
  'startTime',
  'endTime',
  'breakType',
  'billable'
];

class TimeEntriesBreakForm extends MobxReactForm {
  constructor(settings, options) {
    super(settings, options);

    const rules = {
      breakType: 'required'
    };

    if (!options.rootStore.featureFlags.FF_BREAK_END_TIME_ITERATIONS) {
      // set validation rules for the saved breaks in accordance with a breakType
      if (this.$('breakType').value) {
        rules.startTime = this.$('breakType').value?.startTimeRequired
          ? 'required|string'
          : 'string';
        rules.endTime = this.$('breakType').value?.endTimeRequired
          ? 'required|breakStartTimeAfterEndTime|string'
          : 'breakStartTimeAfterEndTime|string';
        rules.duration = this.$('breakType').value?.durationRequired
          ? 'required|numeric'
          : 'numeric';
      }

      this.set('rules', rules);

      // update rules in accordance with an updated break type
      this.reactToChangeBreak = reaction(
        () => this.$('breakType').value,
        breakType => {
          if (breakType?.startTimeRequired) {
            rules.startTime = 'required|string';
          } else {
            rules.startTime = 'string';
          }
          if (breakType?.endTimeRequired) {
            rules.endTime = 'required|breakStartTimeAfterEndTime|string';
          } else {
            rules.endTime = 'breakStartTimeAfterEndTime|string';
          }
          if (breakType?.durationRequired) {
            rules.duration = 'required|numeric';
          } else {
            rules.duration = 'numeric';
          }
          this.set('rules', rules);

          if (breakType?.defaultDuration) {
            this.$('duration').set('value', breakType.defaultDuration);
          }
        }
      );
    } else {
      this.reactToChangeBreak = reaction(
        () => this.$('breakType').value,
        breakType => {
          if (breakType?.defaultDuration) {
            this.$('duration').set('value', breakType.defaultDuration);
          }
        }
      );
    }
  }

  @computed
  get startTimePlaceholder() {
    let placeholder = t('Select start time');

    if (this.$('breakType').value?.startTimeRequired) {
      placeholder += t(` (required)`);
    }
    return placeholder;
  }

  @computed
  get endTimePlaceholder() {
    let placeholder = t('Select end time');

    if (this.$('breakType').value?.endTimeRequired) {
      placeholder += t(` (required)`);
    }
    return placeholder;
  }

  @computed
  get durationPlaceholder() {
    let placeholder = t('Select duration');

    if (this.$('breakType').value?.durationRequired) {
      placeholder += t(` (required)`);
    }
    return placeholder;
  }

  sanitizeValue = e => {
    if (e.target.value < 0) {
      e.preventDefault();
    }

    return e.target.value < 0;
  };

  calculateTimeDuration() {
    const duration = moment.duration(
      moment(this.$('endTime').value, 'HH:mm').diff(
        moment(this.$('startTime').value, 'HH:mm')
      )
    );
    this.$('duration').sync(
      duration.asMinutes() > 0 ? duration.asMinutes() : 0
    );
  }

  handleCalculateStartTime(e) {
    if (this.sanitizeValue(e)) return;

    this.$('startTime').sync(e.target.value);

    if (this.$('endTime').value) {
      this.calculateTimeDuration();
    }
  }

  handleCalculateStartTimeAndEndTimeWithDuration(e) {
    if (this.sanitizeValue(e)) return;

    this.$('startTime').sync(e.target.value);

    if (this.$('duration').value) {
      this.calculateEndTimeFromStartTimeAndDuration();
    }
  }

  handleCalculateEndTime(e) {
    if (this.sanitizeValue(e)) return;

    this.$('endTime').sync(e.target.value);

    if (this.$('startTime').value) {
      this.calculateTimeDuration();
    }
  }

  handleDuration(e) {
    if (this.sanitizeValue(e)) return;

    this.$('duration').sync(e.target.value ? parseInt(e.target.value) : '');

    if (this.$('startTime').value) {
      this.calculateEndTimeFromStartTimeAndDuration();
    }
  }

  calculateEndTimeFromStartTimeAndDuration() {
    const endTime = moment(moment(this.$('startTime').value, 'HH:mm')).add(
      this.$('duration').value,
      'minutes'
    );
    this.$('endTime').sync(endTime.format('HH:mm'));
  }

  @computed
  get hasBreakType() {
    return Boolean(this.$('breakType').value);
  }

  @computed
  get cleanedValues() {
    return {
      breakType: {
        uuid: this.$('breakType').value?.uuid
      },
      startTime: this.$('startTime').value || null,
      endTime: this.$('endTime').value || null,
      duration: this.$('duration').value,
      ...(this.$('uuid').value && {
        uuid: this.$('uuid').value
      })
    };
  }

  @computed
  get hasMinimumBreakDuration() {
    return Boolean(this.$('breakType').value?.minimumBreakDuration);
  }

  @computed
  get minimumBreakDuration() {
    return this.$('breakType').value?.minimumBreakDuration;
  }

  @computed
  get hasMinimumBreakDurationViolation() {
    return (
      this.$('duration').value < this.$('breakType').value?.minimumBreakDuration
    );
  }
}

export {
  TimeEntriesBreakForm,
  timeEntriesBreakFormFields,
  timeEntriesBreakFormOptions,
  timeEntriesBreakFormPlugins
};
