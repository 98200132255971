import { action, computed, toJS } from 'mobx';
import SettingsChildUI from './SettingsChildUI';
import omit from 'lodash.omit';
import { callTrack } from 'utils/segmentIntegration';
import moment from 'moment';
import {
  SettingsDisplayForm,
  settingsDisplayFormOptions,
  settingsDisplayFormFields,
  settingsDisplayFormRules,
  settingsDisplayFormValues,
  settingsDisplayFormLabels,
  settingsDisplayFormPlugins,
  settingsDisplayFormDisabled
} from 'forms/project/settingsDisplay';

import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import { PROJECT_UPDATED } from 'utils/segmentAnalytics/eventSpec';
import { extendMoment } from 'moment-range';

export default class SettingsDisplayUI extends SettingsChildUI {
  @action.bound setup() {
    this.setupForm();
  }

  @action.bound tearDown() {
    this.clearUIState();
    this.unblockHistory();
  }

  @action.bound
  setupForm() {
    this.currentFormInitialValues = Object.assign(
      {},
      settingsDisplayFormValues,
      this.project?.combinedSettingsFormValues
    );

    this.form = new SettingsDisplayForm(
      {
        fields: settingsDisplayFormFields,
        rules: settingsDisplayFormRules,
        disabled: settingsDisplayFormDisabled,
        values: toJS(this.currentFormInitialValues),
        labels: settingsDisplayFormLabels
      },
      {
        options: settingsDisplayFormOptions,
        plugins: settingsDisplayFormPlugins,
        parent: this
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @action.bound resetForm() {
    this.clearValidationDetails();
    this.form.init(toJS(this.currentFormInitialValues));
  }

  @action.bound
  async submitFormSuccess() {
    this.saving = true;
    const values = this.form.trimmedValues();
    const ommitedValues = ['default', 'questions', 'collaboratorQuestions'];
    const projectData = omit(values, ommitedValues);
    try {
      await this.project.save(projectData, {
        wait: true
      });
      this.handleSaveSuccess();
    } catch (error) {
      this.handleSaveError(error);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  handleSaveSuccess() {
    this.unblockHistory();
    this.saving = false;
    this.notifications.pushNotification({
      snackbar: 'warning',
      icon: 'checkmark',
      title: t('Display settings saved')
    });
    callTrack(PROJECT_UPDATED, {
      project_id: this.project?.id,
      project_name: this.project?.name
    });

    // Reset the header projects
    this.headerUI.headerProjectSelectorUI.resetProjectOptions();

    // Reinitialize form
    this.setupForm();
  }

  @action.bound
  handleSaveError(error) {
    alertErrorHandler(error, this.setValidationDetails);
    this.saving = false;
  }

  @computed get headerTitle() {
    return t('Manage report display options and required frequency');
  }

  @computed get hasWriteAccess() {
    return this.authorization.canEditGeneralSettings;
  }

  @computed get title() {
    return t('Display settings');
  }

  @computed get languageOptions() {
    return [
      { id: 1, code: 'en-US', name: 'English' },
      { id: 2, code: 'es-MX', name: 'Español' },
      { id: 3, code: 'pt-BR', name: 'Português' }
    ];
  }

  @computed get frequencyOptions() {
    return [
      {
        value: 'WEEKLY',
        name: t('Daily/Weekly')
      },
      {
        value: 'MONTHLY',
        name: t('Monthly')
      }
    ];
  }

  @computed get times() {
    const momentExtended = extendMoment(moment);
    return Array.from(
      momentExtended
        .range(momentExtended().startOf('day'), momentExtended().endOf('day'))
        .by('hour')
    ).map(time => {
      return {
        value: time.format('HH:mm'),
        name: time.format('h:mm A')
      };
    });
  }

  @computed get intervalPickerOptions() {
    const { frequency } = this.form.values();
    if (frequency === 'MONTHLY') {
      return Array.from({ length: 31 }, (_, index) => {
        return {
          name: moment.localeData().ordinal(String(index + 1)),
          value: index + 1
        };
      });
    } else {
      return [
        { name: 'Sunday', value: 1 },
        { name: 'Monday', value: 2 },
        { name: 'Tuesday', value: 3 },
        { name: 'Wednesday', value: 4 },
        { name: 'Thursday', value: 5 },
        { name: 'Friday', value: 6 },
        { name: 'Saturday', value: 7 }
      ];
    }
  }

  @computed get intervalPickerValues() {
    const { intervals } = this.form.values();
    return this.intervalPickerOptions.filter(option =>
      intervals.includes(option.value)
    );
  }
}
