import debounce from 'lodash.debounce';
import { action, computed, observable, runInAction, reaction } from 'mobx';
import UIStore from '../UIStore';
import alertErrorHandler from 'utils/alertErrorHandler';

import { BASE_DEBOUNCE } from 'fixtures/constants';
import { axiosIgnore404 } from 'utils/axiosIgnore404';

import { t } from 'utils/translate';

export default class HistoricalTransactionsUI extends UIStore {
  @observable loading;
  @observable transactionToDelete;
  @observable offset;

  constructor(options) {
    super(options);

    this.loading = true;

    this.transactionToDelete = null;

    this.fetchTransactionsDebounced = debounce(
      this.fetchTransactions,
      BASE_DEBOUNCE
    );
  }

  @action.bound clearUIState() {
    this.transactions?.clear();
    this.transactionToDelete = null;
    this.loading = true;
  }

  @computed get company() {
    return this.parent.company;
  }

  @computed get transactions() {
    return this.company?.transactions;
  }

  @action.bound setup() {
    this.fetchTransactions();
    this.setupReactions();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchTransactionsDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed get params() {
    return {
      limit: 1000,
      offset: 0
    };
  }

  @action.bound async fetchTransactions() {
    this.transactions.cancelRequest();
    this.transactions.reset();

    try {
      await this.transactions.fetch({
        params: this.params,
        axios: axiosIgnore404
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @action.bound refetchTransactions() {
    this.loading = true;

    if (!this.hasTransactions) {
      this.offset = 0;
      this.fetchTransactions();
    } else {
      this.fetchTransactions();
    }
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }
  }

  @computed get hasTransactions() {
    return this.transactions.hasModels;
  }

  @computed get showEmptyState() {
    return !this.loading && !this.hasTransactions;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @computed
  get extendedPermissions() {
    return this.parent.extendedPermissions;
  }

  getTransactionActions = transaction => {
    const actions = [
      {
        title: t('Delete'),
        disabled: !transaction.allowToDelete,
        onClick: () => {
          this.deleteTransaction(transaction);
        }
      }
    ];

    return actions;
  };

  getTransactionPopoverTitle = transaction => {
    if (transaction.hasAddedUsers) {
      return transaction.addedUsers.map(user => user.name).join(', ');
    }

    if (transaction.hasRemovedUsers) {
      return transaction.removedUsers.map(user => user.name).join(', ');
    }

    if (transaction.hasInvitedUsers) {
      return transaction.invitedUsers.map(user => user.name).join(', ');
    }

    return '';
  };

  @action.bound
  deleteTransaction(transaction) {
    this.showModal('DeleteTransactionModal');
    this.transactionToDelete = transaction;
  }

  @action.bound
  async confirmDeleteTransaction() {
    this.saving = true;

    await this.transactionToDelete.destroy({
      wait: true
    });

    this.saving = false;

    await this.cancelDeleteTransactionModal();

    this.refetchTransactions();

    this.rootStore.notificationsUI.pushNotification({
      snackbar: 'warning',
      icon: 'notification',
      title: t('Transaction deleted')
    });
  }

  @action.bound
  async cancelDeleteTransactionModal() {
    await this.hideActiveModal();
    this.transactionToDelete = null;
  }
}
