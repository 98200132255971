import { RakenCollection } from '../lib';
import TimeSheetTimeCard from '../models/TimeSheetTimeCard';
import { computed, action } from 'mobx';

export default class TimesheetTimeCards extends RakenCollection {
  model() {
    return TimeSheetTimeCard;
  }

  @computed
  get hasSelectedTimeCard() {
    return !!this.models.find(timeCard => timeCard.selected);
  }

  @action.bound
  updateSelection(selected) {
    this.models.forEach(timeCard => {
      timeCard.setSelected(selected);
    });
  }

  @computed
  get allTimeCardsSelected() {
    return (
      this.models.filter(timeCard => timeCard.selected).length === this.length
    );
  }
}
