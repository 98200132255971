import MobxReactForm from 'mobx-react-form';
import validatorjs from 'validatorjs';
import { action } from 'mobx';
import { t } from 'utils/translate';

const plugins = { dvr: validatorjs };

const options = {
  validateOnInit: true
};

const fields = ['shift'];

const rules = {
  shift: 'required|string'
};

const values = {
  shift: ''
};

const labels = {
  shift: t('Daily reports to include')
};

export class DailyReportForm extends MobxReactForm {
  @action.bound
  updateAndValidate(fieldName, value) {
    this.update({
      [fieldName]: value
    });

    this.validate();
  }
}

export default {
  options,
  fields,
  rules,
  values,
  labels,
  plugins
};
