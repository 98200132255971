import { action, computed, reaction, when } from 'mobx';

import getCookie from 'utils/getCookie';
import setCookie from 'utils/setCookie';
import { getIntercom } from 'utils/intercomControl';

import Me from './models/Me';
import Users from './collections/Users';
import Countries from './collections/Countries';
import Regions from './collections/Regions';
import Notifications from './collections/Notifications';
import ProjectSummaries from './collections/ProjectSummaries';

import ProjectTemplates from './collections/ProjectTemplates';
import TimeCardReports from './collections/TimeCardReports';
import MaterialReports from './collections/MaterialReports';
import EquipmentReports from './collections/EquipmentReports';
import CertificationsReports from './collections/CertificationsReports';
import ViolationsReports from './collections/ViolationsReports';
import Workers from './collections/Workers';

import Classifications from './collections/Classifications';
import CostCodes from './collections/CostCodes';
import CompanyPermissions from './collections/CompanyPermissions';
import ToolboxTalks from './collections/Talks';
import Units from './collections/Units';
import Materials from './collections/Materials';
import Shifts from './collections/Shifts';
import OverTimeRules from './collections/OverTimeRules';
import Breaks from './collections/Breaks';
import PayTypes from './collections/PayTypes';
import Equipment from './collections/Equipment';
import SubscriptionPlans from './collections/SubscriptionPlans';
import ChecklistTemplates from './collections/checklists/ChecklistTemplates';
import Integrations from './collections/integrations/Integrations';

import TimesheetSummary from './models/TimesheetSummary';

import ActivityUI from './ui/ActivityUI';
import ActivityUINew from './ui/ActivityUINew';
import ReportActivityUI from './ui/ReportActivityUI';
import InsightsUI from './ui/insights/InsightsUI';
import ProjectsUI from './ui/ProjectsUI';
import OnboardingUI from './ui/OnboardingUI';
import NotificationsUI from './ui/NotificationsUI';
import AuthorizationUI from './ui/AuthorizationUI';
import ProjectUI from './ui/project/ProjectUI';
import EmailUI from './ui/EmailUI';
import ClassificationsUI from './ui/ClassificationsUI';
import UnitsUI from './ui/UnitsUI';
import MaterialsUI from './ui/MaterialsUI';
import EquipmentUI from './ui/EquipmentUI';
import CompanyUI from './ui/CompanyUI';
import TeamMembersUI from './ui/TeamMembersUI';
import TeamWorkersUI from './ui/TeamWorkersUI';
import HeaderUI from './ui/HeaderUI';
import TimesheetsUI from './ui/TimesheetsUI';
import TimeClockQuestionsUI from './ui/TimeClockQuestionsUI';
import ProjectSelectorUI from './ui/ProjectSelectorUI';
import ShiftSelectorUI from './ui/ShiftSelectorUI';
import PayTypeSelectorUI from './ui/PayTypeSelectorUI';
import BreakSelectorUI from './ui/BreakSelectorUI';
import ClassificationSelectorUI from './ui/ClassificationSelectorUI';
import CollaboratorSelectorUI from './ui/CollaboratorSelectorUI';
import ProjectLocationSelectorUI from './ui/ProjectLocationSelectorUI';
import CostCodeSelectorUI from './ui/CostCodeSelectorUI';
import MaterialSelectorUI from './ui/MaterialSelectorUI';
import EquipmentSelectorUI from './ui/EquipmentSelectorUI';
import MemberSelectorUI from './ui/MemberSelectorUI';
import PayPeriodSelectorUI from './ui/PayPeriodSelectorUI';
import ChecklistTypeSelectorUI from './ui/ChecklistTypeSelectorUI';
import GroupSelectorUI from './ui/GroupSelectorUI';
import GlobalProjectInfoUI from './ui/GlobalProjectInfoUI';
import AppConnectUI from './ui/AppConnectUI';
import MediaViewerUI from './ui/MediaViewerUI';
import ProjectCreationUI from './ui/ProjectCreationUI';
import ReportsUI from './ui/ReportsUI';
import DebugUI from './ui/DebugUI';
import MessagingUI from './ui/MessagingUI';
import LiveViewsUI from './ui/LiveViewsUI';
import PhotoIdSummaryUI from './ui/PhotoIdSummaryUI';

import RootStore from 'stores/RootStore';

import { callIdentify, callTrack, callPage } from 'utils/segmentIntegration';

import { SIGN_IN } from 'utils/segmentAnalytics/eventSpec';

class CompanyRootStore extends RootStore {
  @action.bound
  instantiateCollections(fixtureData) {
    this.countries = new Countries(null, {
      rootStore: this
    });

    this.regions = new Regions(null, {
      rootStore: this
    });

    this.subscriptionPlans = new SubscriptionPlans(null, {
      rootStore: this
    });

    this.me = new Me(fixtureData ? fixtureData.meData : null, {
      rootStore: this
    });

    this.users = new Users(fixtureData ? fixtureData.usersData : null, {
      rootStore: this
    });

    this.notifications = new Notifications(null, { rootStore: this });

    this.projectSummaries = new ProjectSummaries(null, { rootStore: this });
    this.timeCardReports = new TimeCardReports(null, { rootStore: this });
    this.materialReports = new MaterialReports(null, { rootStore: this });
    this.equipmentReports = new EquipmentReports(null, { rootStore: this });
    this.certificationsReports = new CertificationsReports(null, {
      rootStore: this
    });
    this.violationsReports = new ViolationsReports(null, { rootStore: this });

    this.projectTemplates = new ProjectTemplates(null, { rootStore: this });

    this.workers = new Workers(null, { rootStore: this });

    this.classifications = new Classifications(null, { rootStore: this });

    this.costCodes = new CostCodes(
      fixtureData ? fixtureData.costCodesData : null,
      { rootStore: this }
    );

    // Permissions for the Company setting
    this.companyPermissions = new CompanyPermissions(null, { rootStore: this });

    this.toolboxTalks = new ToolboxTalks(
      fixtureData ? fixtureData.toolboxTalks : null,
      { rootStore: this }
    );

    this.units = new Units(fixtureData ? fixtureData.units : null, {
      rootStore: this
    });

    this.materials = new Materials(fixtureData ? fixtureData.units : null, {
      rootStore: this
    });

    this.shifts = new Shifts(fixtureData ? fixtureData.shifts : null, {
      rootStore: this
    });

    this.overTimeRules = new OverTimeRules(
      fixtureData ? fixtureData.overtimeRules : null,
      {
        rootStore: this
      }
    );

    this.breaks = new Breaks(fixtureData ? fixtureData.breaks : null, {
      rootStore: this
    });

    this.payTypes = new PayTypes(fixtureData ? fixtureData.payTypes : null, {
      rootStore: this
    });

    this.equipment = new Equipment(fixtureData ? fixtureData.equipment : null, {
      rootStore: this
    });

    this.checklistTemplates = new ChecklistTemplates(
      fixtureData ? fixtureData.checklists : null,
      {
        rootStore: this
      }
    );

    this.rakenTemplates = new ChecklistTemplates(
      fixtureData ? fixtureData.checklists : null,
      {
        rootStore: this
      }
    );

    this.timesheetSummary = new TimesheetSummary(null, {
      rootStore: this
    });

    this.integrations = new Integrations(null, {
      parent: this,
      rootStore: this
    });
  }

  @action.bound
  instantiateUIStores() {
    this.projectsUI = new ProjectsUI({ rootStore: this });
    this.projectUI = new ProjectUI({ rootStore: this });
    this.activityUI = new ActivityUI({ rootStore: this });
    this.activityUINew = new ActivityUINew({ rootStore: this });
    this.reportActivityUI = new ReportActivityUI({ rootStore: this });
    this.insightsUI = new InsightsUI({ rootStore: this });
    this.notificationsUI = new NotificationsUI({ rootStore: this });
    this.onboardingUI = new OnboardingUI({ rootStore: this });
    this.authorizationUI = new AuthorizationUI({ rootStore: this });
    this.emailUI = new EmailUI({ rootStore: this });

    this.classificationsUI = new ClassificationsUI({ rootStore: this });
    this.companyUI = new CompanyUI({ rootStore: this });

    this.teamMembersUI = new TeamMembersUI({ rootStore: this });
    this.teamWorkersUI = new TeamWorkersUI({ rootStore: this });

    this.projectSelectorUI = new ProjectSelectorUI({ rootStore: this });
    this.shiftSelectorUI = new ShiftSelectorUI({ rootStore: this });
    this.payTypeSelectorUI = new PayTypeSelectorUI({ rootStore: this });
    this.classificationSelectorUI = new ClassificationSelectorUI({
      rootStore: this
    });
    this.collaboratorSelectorUI = new CollaboratorSelectorUI({
      rootStore: this
    });
    this.projectLocationSelectorUI = new ProjectLocationSelectorUI({
      rootStore: this
    });

    this.breakSelectorUI = new BreakSelectorUI({ rootStore: this });
    this.costCodeSelectorUI = new CostCodeSelectorUI({ rootStore: this });
    this.materialSelectorUI = new MaterialSelectorUI({ rootStore: this });
    this.equipmentSelectorUI = new EquipmentSelectorUI({ rootStore: this });
    this.memberSelectorUI = new MemberSelectorUI({ rootStore: this });
    this.payPeriodSelectorUI = new PayPeriodSelectorUI({ rootStore: this });
    this.checklistTypeSelectorUI = new ChecklistTypeSelectorUI({
      rootStore: this
    });
    this.groupSelectorUI = new GroupSelectorUI({ rootStore: this });

    this.globalProjectInfoUI = new GlobalProjectInfoUI({ rootStore: this });
    this.unitsUI = new UnitsUI({ rootStore: this });
    this.materialsUI = new MaterialsUI({ rootStore: this });
    this.equipmentUI = new EquipmentUI({ rootStore: this });
    this.headerUI = new HeaderUI({ rootStore: this });
    this.timesheetsUI = new TimesheetsUI({ rootStore: this });
    this.timeClockQuestionsUI = new TimeClockQuestionsUI({ rootStore: this });
    this.workerPhotoIdSummaryUI = new PhotoIdSummaryUI({
      rootStore: this
    });
    this.appConnectUI = new AppConnectUI({ rootStore: this });
    this.mediaViewerUI = new MediaViewerUI({ rootStore: this });
    this.projectCreationUI = new ProjectCreationUI({ rootStore: this });
    this.reportsUI = new ReportsUI({ rootStore: this });
    this.messagingUI = new MessagingUI({ rootStore: this });

    if (this.showDebugSection) {
      this.debugUI = new DebugUI({ rootStore: this });
    }

    this.liveViewsUI = new LiveViewsUI({
      rootStore: this
    });
  }

  @action.bound
  async fetchGlobalData() {
    this.loadingGlobalData = true;

    try {
      this.countries.fetch({
        params: {
          limit: 10000
        }
      });

      this.regions.fetch({
        params: {
          limit: 10000,
          country: 'US'
        }
      });

      await this.me.fetch({
        url: `ra/user`,
        params: {
          mn: 'full',
          mc: 'full'
        }
      });

      if (this.me.company) {
        await this.me.company.subscription.fetch({
          params: {
            mn: 'full',
            mc: 'full',
            limit: 1
          }
        });

        this.headerUI.headerProjectSelectorUI.fetchProjectOptions();
        this.messagingUI.initializeUnreadMessageCountPoller();
        this.headerUI.getTimesheetNotificationCount();
        this.timesheetsUI.fetchSettings();
      }

      this.configureAnalytics();
      this.configureIntercom();
      this.configureRecurly();
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingGlobalData = false;
    }
  }

  configureAnalytics = () => {
    when(
      () => this.me.uuid,
      () => {
        callIdentify(this.me.uuid, this.identity, {});

        // Fire at least once. Just for Segment.io. See https://segment.com/docs/connections/spec/page/
        callPage('Segment Init', {}, { integrations: { All: false } });

        // callIdentify when any of the identification values change
        this.reactToIdentity = reaction(
          () => this.identity,
          () => {
            callIdentify(this.me.uuid, this.identity, {});
          }
        );

        // So that refresh won't fire another SIGN_IN event.
        if (!getCookie('sign_in')) {
          setCookie('sign_in', '1');

          callTrack(SIGN_IN);
        }
      }
    );

    /**
     * Temporary solution for Facebook GC/SC/EmployeeRangee Retargeting - ENG-2879
     * Ideally this should be handled in Segment with additional `Sign_In` props and audience filtering in Facebook.
     *
     * Removal instructions in ENG-2879.
     */
    try {
      window.dataLayer = window.dataLayer || [];
      const industry = this.me?.company?.industry || '';
      const employeeRange = parseInt(
        (this.me?.company?.employeeRange || '').replace(',', '').split('-')[0]
      );

      if (industry.toLowerCase() === 'general contractor') {
        window.dataLayer.push({
          event: 'Industry = General Contractor'
        });
      }

      if (industry.toLowerCase() === 'subcontractor') {
        window.dataLayer.push({
          event: 'Industry = Sub Contractor'
        });
      }

      if (employeeRange > 10) {
        window.dataLayer.push({
          event: 'Employee Range > 10'
        });
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  configureIntercom = () => {
    when(
      () => this.me.uuid,
      async () => {
        try {
          await getIntercom();

          window.Intercom('update', {
            hide_default_launcher: this.hideMainNavigation,
            email: this.me.email,
            user_id: this.me.id,
            name: this.me.fullName,
            phone: this.me.phoneNumber,
            company: {
              id: this.me.company.uuid,
              name: this.me.company.name
            }
          });

          const urlParams = new URLSearchParams(window.location.search);

          if (urlParams.get('tour')) {
            window.Intercom('trackEvent', 'Trial_Registered');
          }
        } catch (error) {
          console.error(
            'Failed to load Intecom. Check segment.io and the Google Tag Manager destination.'
          );
        }
      }
    );
  };

  /**
   * Used in callIdentify
   */
  @computed
  get identity() {
    return {
      account_type: this.me?.company?.accountSubType,
      active_projects: this.me?.projectTeamUuids.length || 0,
      business_id: this.me?.company?.uuid,
      company_name: this.me?.company?.name,
      email: this.me?.email || this.me?.username,
      employee_range: this.me?.company?.employeeRange,
      first_name: this.me?.firstName,
      last_name: this.me?.lastName,
      number_of_seats: this.me?.company?.seatsInUse,
      plan_type: this.me?.company?.billingPlan,
      subscription_start_date: this.me?.company?.subscription?.startDate,
      subscription_type: this.me?.company?.subscription?.periodType,
      user_id: this.me?.uuid,
      user_role: this.me?.role,
      is_super_admin: this.isSuperAdmin
    };
  }

  @computed get overTimeRulesUI() {
    return this.companyUI.companyProductionUI.companyOverTimeRulesUI
      .overTimeRulesUI;
  }

  @computed
  get isWorker() {
    return this.me.role === 'ROLE_WORKER';
  }
}

export default CompanyRootStore;
