import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';

import { t } from 'utils/translate';

const transferFormPlugins = extendedPlugins;

const transferFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const transferFormFields = ['fromDate', 'toDate'];

const transferFormLabels = {
  fromDate: t('from date'),
  toDate: t('to date')
};

const transferFormRules = {
  fromDate: 'required|date|beforeOrTodayDate',
  toDate: 'required|date|beforeOrTodayDate'
};

class TransferForm extends MobxReactForm {}

export {
  TransferForm,
  transferFormOptions,
  transferFormFields,
  transferFormLabels,
  transferFormRules,
  transferFormPlugins
};
