import Text from './Text';

const H1 = Text.withComponent('h1');

H1.defaultProps = {
  size: 25,
  block: true,
  lineHeight: '35px'
};

export default H1;
