import { computed } from 'mobx';
import { Model } from 'mobx-mc';

export default class CertificationsReport extends Model {
  urlRoot = `/ra/certification-reports/scheduled`;

  get restAttributes() {
    return [
      'id',
      'type',
      'name',
      'emails',
      'projectIncludeOption',
      'projects',
      'sendReport',
      'timeFrameType',
      'scheduleFrequency',
      'certificationTypeUuids'
    ];
  }

  get restAttributeDefaults() {
    return {
      projects: []
    };
  }

  @computed get formValues() {
    return {
      id: this.id,
      name: this.name,
      emails: this.emails,
      projectIncludeOption: this.projectIncludeOption,
      projects: this.projects.slice(),
      timeFrameType: this.timeFrameType,
      sendReport: false,
      scheduleFrequency: this.scheduleFrequency,
      certificationTypeUuids: this.certificationTypeUuids
    };
  }
}
