import MobxReactForm from 'mobx-react-form';
import moment from 'moment';

import extendedPlugins from '../utils/extendedPlugins';
import { t } from 'utils/translate';

const recordPaymentFormPlugins = extendedPlugins;

const recordPaymentFormOptions = {
  validateOnInit: false,
  showErrorsOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const recordPaymentFormFields = [
  'paymentType',
  'amount',
  'transactionId',
  'paymentDate',
  'comment'
];

const recordPaymentFormRules = {
  paymentType: 'string',
  amount: 'numeric|required',
  paymentDate: 'string|required|dateCustom:YYYY-MM-DD',
  comment: 'string'
};

const recordPaymentFormLabels = {
  paymentType: t('Type'),
  amount: t('Amount ($)'),
  transactionId: t('Transaction Id'),
  paymentDate: t('Date'),
  comment: t('Comment')
};

const recordPaymentFormValues = {
  paymentType: 'CHECK',
  paymentDate: moment().format('YYYY-MM-DD')
};

class RecordPaymentForm extends MobxReactForm {}

export {
  RecordPaymentForm,
  recordPaymentFormOptions,
  recordPaymentFormFields,
  recordPaymentFormRules,
  recordPaymentFormLabels,
  recordPaymentFormValues,
  recordPaymentFormPlugins
};
