import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import { action, computed } from 'mobx';
import arrayMove from 'utils/arrayMove';
import uuid from 'uuid-v4';
import trimObject from '../utils/trimObject';
import { t } from 'utils/translate';

const checklistTemplateFormPlugins = extendedPlugins;

const checklistTemplateFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const checklistTemplateFormFields = [
  'name',
  'checklistType',
  'checklistType.uuid',
  'checklistType.typeClass',
  'checklistType.typeClass.uuid',
  'checklistType.typeClass.name',
  'checklistType.typeName',
  'checklistTemplateSections[]',
  'checklistTemplateSections[].uuid',
  'checklistTemplateSections[].localUuid',
  'checklistTemplateSections[].title',
  'checklistTemplateSections[].description',
  'checklistTemplateSections[].position',
  'checklistTemplateSections[].checklistTemplateQuestions[]',
  'checklistTemplateSections[].checklistTemplateQuestions[].uuid',
  'checklistTemplateSections[].checklistTemplateQuestions[].localUuid',
  'checklistTemplateSections[].checklistTemplateQuestions[].position',
  'checklistTemplateSections[].checklistTemplateQuestions[].questionText',
  'checklistTemplateSections[].checklistTemplateQuestions[].attachmentGuidance',
  'checklistTemplateSections[].checklistTemplateQuestions[].responseType',
  'checklistTemplateSections[].checklistTemplateQuestions[].required',
  'checklistTemplateSections[].checklistTemplateQuestions[].choices[]',
  'checklistTemplateSections[].checklistTemplateQuestions[].choices[].uuid',
  'checklistTemplateSections[].checklistTemplateQuestions[].choices[].description',
  'checklistTemplateSections[].checklistTemplateQuestions[].choices[].position',
  'checklistTemplateSections[].checklistTemplateQuestions[].allowMultiple'
];

const checklistTemplateFormValues = {
  checklistTemplateSections: [
    {
      uuid: null,
      localUuid: uuid(),
      title: '',
      description: '',
      position: 1,
      checklistTemplateQuestions: [
        {
          uuid: null,
          localUuid: uuid(),
          questionText: '',
          attachmentGuidance: '',
          responseType: 'YES_NO_NA',
          position: 1,
          required: false,
          allowMultiple: false,
          choices: []
        }
      ]
    }
  ]
};

const checklistTemplateFormRules = {
  'checklistType.uuid': 'required',
  name: 'string|required|max:255',
  'checklistTemplateSections[].title': 'string|max:255',
  'checklistTemplateSections[].description': 'string|max:1024',
  'checklistTemplateSections[].checklistTemplateQuestions[].questionText':
    'string|max:1024',
  'checklistTemplateSections[].checklistTemplateQuestions[].attachmentGuidance':
    'string|max:255',
  'checklistTemplateSections[].checklistTemplateQuestions[].responseType':
    'required'
};

const checklistTemplateFormLabels = {
  'checklistType.uuid': t('type'),
  name: t('name'),
  'checklistTemplateSections[].title': t('section title'),
  'checklistTemplateSections[].description': t('description'),
  'checklistTemplateSections[].checklistTemplateQuestions[].questionText': t(
    'question'
  ),
  'checklistTemplateSections[].checklistTemplateQuestions[].responseType': `${t(
    'response'
  )} ${t('type')}`
};

class ChecklistTemplateForm extends MobxReactForm {
  constructor(settings, options) {
    super(settings, options);

    this.rootStore = options.rootStore;
  }

  @computed
  get sections() {
    return this.$('checklistTemplateSections');
  }

  @computed
  get numberOfSections() {
    return this.$('checklistTemplateSections').values().length;
  }

  @computed
  get numberOfSectionsWithOnlyEmptyQuestions() {
    const sectionsWithOnlyEmptyQuestions = this.$('checklistTemplateSections')
      .values()
      .filter(section => {
        let numberOfEmptyQuestions = 0;
        section.checklistTemplateQuestions.forEach(question => {
          if (!question.questionText) {
            numberOfEmptyQuestions = ++numberOfEmptyQuestions;
          }
        });
        return (
          numberOfEmptyQuestions === section.checklistTemplateQuestions.length
        );
      });
    return sectionsWithOnlyEmptyQuestions.length;
  }

  @computed
  get hasOnlyEmptySections() {
    return (
      this.numberOfSections === this.numberOfSectionsWithOnlyEmptyQuestions
    );
  }

  @action.bound
  addDefaultQuestion(section) {
    const numberOfQuestions = section.$('checklistTemplateQuestions').values()
      .length;
    section
      .$('checklistTemplateQuestions')
      .add(this.defaultQuestion(numberOfQuestions));
  }

  @action.bound
  addDefaultSection() {
    this.$('checklistTemplateSections').add(this.defaultSection());
  }

  @action.bound
  removeQuestion(section, key) {
    section.$('checklistTemplateQuestions').del(key);
    const questions = section.$('checklistTemplateQuestions').value;

    questions.forEach((question, index) => (question.position = index + 1));
    section.update({
      checklistTemplateQuestions: questions
    });
  }

  @action.bound
  removeSection(key) {
    this.$('checklistTemplateSections').del(key);
    const sections = this.$('checklistTemplateSections').value;
    sections.forEach((section, index) => (section.position = index + 1));

    this.update({
      checklistTemplateSections: sections
    });
  }

  @action.bound
  moveQuestion(section, oldIndex, newIndex) {
    const questions = arrayMove(
      section.$('checklistTemplateQuestions').values(),
      oldIndex,
      newIndex
    );

    questions.forEach((question, index) => (question.position = index + 1));

    section.update({
      checklistTemplateQuestions: questions
    });
  }

  @action.bound
  moveSection(oldIndex, newIndex) {
    const sections = arrayMove(
      this.$('checklistTemplateSections').value,
      oldIndex,
      newIndex
    );

    sections.forEach((section, index) => (section.position = index + 1));

    this.update({
      checklistTemplateSections: sections
    });
  }

  defaultSection() {
    return {
      uuid: null,
      localUuid: uuid(),
      title: '',
      description: '',
      position: this.numberOfSections + 1,
      checklistTemplateQuestions: [
        {
          uuid: null,
          localUuid: uuid(),
          questionText: '',
          responseType: 'YES_NO_NA',
          attachmentGuidance: '',
          position: 1,
          required: false,
          allowMultiple: false,
          choices: []
        }
      ]
    };
  }

  defaultQuestion(numberOfQuestions) {
    return {
      localUuid: uuid(),
      questionText: '',
      responseType: 'YES_NO_NA',
      attachmentGuidance: '',
      position: numberOfQuestions + 1,
      required: false,
      allowMultiple: false,
      choices: []
    };
  }

  trimmedValues() {
    return trimObject(this.values());
  }

  responseOptions(question) {
    const options = [
      {
        title: t('Yes No N/A'),
        value: 'YES_NO_NA'
      },
      {
        title: t('Yes No'),
        value: 'YES_NO'
      },
      {
        title: t('Checkbox'),
        value: 'CHECKBOX'
      },
      {
        title: t('Text Answer'),
        value: 'TEXT'
      },
      {
        title: `${t('Choice List')} (${question.$('choices').size})`,
        value: 'MULTI_CHOICE'
      },
      {
        title: t('Signature'),
        value: 'SIGNATURE'
      }
    ];

    return options;
  }
}

export {
  ChecklistTemplateForm,
  checklistTemplateFormPlugins,
  checklistTemplateFormOptions,
  checklistTemplateFormFields,
  checklistTemplateFormRules,
  checklistTemplateFormLabels,
  checklistTemplateFormValues
};
