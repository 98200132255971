import { action, computed, observable, reaction, runInAction } from 'mobx';

import UIStore from './UIStore';

import request from 'axios';
import debounce from 'lodash.debounce';
import { BASE_DEBOUNCE } from 'fixtures/constants';

import TimeClockQuestionsTemplatesUI from './TimeClockQuestionsTemplatesUI';
import TimeClockQuestionsCompletedUI from './TimeClockQuestionsCompletedUI';
import TimeClockIndividualQuestionUI from './TimeClockIndividualQuestionUI';
import TimeClockQuestionsViewChecklistUI from './TimeClockQuestionsViewChecklistUI';

import TimeClockQuestions from 'stores/collections/TimeClockQuestions';
import ChecklistTemplates from 'stores/collections/checklists/ChecklistTemplates';

import alertErrorHandler from 'utils/alertErrorHandler';

import history from 'utils/history';
import { t } from 'utils/translate';

import {
  TimeClockQuestionsFiltersForm,
  timeClockQuestionsFiltersFormRules,
  timeClockQuestionsFiltersFormFields,
  timeClockQuestionsFiltersFormOptions,
  timeClockQuestionsFiltersFormPlugins
} from 'forms/timeClockQuestionsFilters';

import { callTrack } from 'utils/segmentIntegration';

import {
  QUESTIONS_REVIEW,
  QUESTIONS_INDIVIDUAL
} from 'utils/segmentAnalytics/eventSpec';

export default class TimeClockQuestionsUI extends UIStore {
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;

  // Filters
  @observable filtersLoading;

  constructor(options) {
    super(options);

    this.loading = true;

    this.sortField = 'completedCount';
    this.sortDirection = 'desc';
    this.page = 1;
    this.searchQuery = '';
    this.pageSize = 20;

    // Filters
    this.filtersForm = null;
    this.filtersLoading = false;
    this.projectFilters = observable([]);
    this.memberFilters = observable([]);
    this.classificationFilters = observable([]);
    this.employeeGroupFilters = observable([]);
    this.eventOriginFilters = observable([]);
    this.responseFilters = observable([]);
    this.templateFilters = observable([]);

    // Time Clock Questions collection
    this.timeClockQuestions = new TimeClockQuestions(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.timeClockQuestionsTemplatesUI = new TimeClockQuestionsTemplatesUI({
      rootStore: this.rootStore,
      parent: this
    });

    this.timeClockQuestionsCompletedUI = new TimeClockQuestionsCompletedUI({
      rootStore: this.rootStore,
      parent: this
    });

    this.timeClockIndividualQuestionUI = new TimeClockIndividualQuestionUI({
      rootStore: this.rootStore,
      parent: this
    });

    this.checklistTemplates = new ChecklistTemplates(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.timeClockQuestionsViewChecklistUI = new TimeClockQuestionsViewChecklistUI(
      {
        parent: this,
        rootStore: this.rootStore
      }
    );

    this.fetchTimeClockQuestionsDebounced = debounce(
      this.fetchTimeClockQuestions,
      BASE_DEBOUNCE
    );
  }

  @action.bound async setup() {
    window.scrollTo(0, 0);
    this.loading = true;
    this.setupReactions();
    this.fetchTimeClockQuestionsDebounced();
    callTrack(QUESTIONS_REVIEW);
  }

  @action.bound tearDown() {
    this.timeClockQuestions.clear();
    this.reactToParams && this.reactToParams();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.fetchTimeClockQuestionsDebounced();
        });
      }
    );
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      sortField: [this.sortField],
      sortDirection: this.sortDirection.toUpperCase(),
      fromDate: this.payPeriodSelectorUI.selectedPeriod?.value?.from,
      toDate: this.payPeriodSelectorUI.selectedPeriod?.value?.to,
      projects: this.projectFilters.map(project => project.value),
      workers: this.memberFilters.map(member => member.value),
      classifications: this.classificationFilters.map(
        classification => classification.value
      ),
      employeeGroups: this.employeeGroupFilters.map(
        employeeGroup => employeeGroup.value
      ),
      eventOrigins: this.eventOriginFilters.map(
        eventOrigin => eventOrigin.value
      ),
      responses: this.responseFilters.map(response => response.value),
      templates: this.templateFilters.map(template => template.value)
    };
  }

  @action.bound fetchTimeClockQuestions() {
    this.loading = true;

    request
      .post(
        `${this.rootStore.urlMicroService(
          'performanceTracking'
        )}/timecardchecklist/questions`,
        this.params
      )
      .then(response => {
        runInAction(() => {
          this.timeClockQuestions.set(response.data);
        });
      })
      .catch(error => {
        alertErrorHandler(error, this.setValidationDetails);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  @computed get hasTimeClockQuestions() {
    return this.timeClockQuestions.hasModels;
  }

  @action.bound
  viewQuestion(question) {
    callTrack(QUESTIONS_INDIVIDUAL);
    this.timeClockIndividualQuestionUI.questionToView = question;
    history.push(`/timesheets/time-clock-questions/questions/individual`);
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @action.bound
  viewResponses(question) {
    this.timeClockQuestionsViewChecklistUI.checklistUuid =
      question.checklistUuid;

    this.timeClockQuestionsViewChecklistUI.setup(
      this.timeClockQuestionsViewChecklistUI.checklistUuid
    );

    this.showModal('ViewResponsesDialog');
  }

  @action.bound
  async closeViewResponsesDialog() {
    await this.hideActiveModal();
    this.timeClockQuestionsViewChecklistUI.tearDown();
  }

  @action.bound
  clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound async showFilterDialog() {
    this.filtersLoading = true;
    this.showModal('timeClockQuestionsFilter');

    try {
      await Promise.all([
        this.projectSelectorUI.setup({
          projectStates: ['ACTIVE']
        }),
        this.memberSelectorUI.setup({ companySettingsTrackMemberTime: true }),
        this.classificationSelectorUI.setup(),
        this.groupSelectorUI.setup(),
        this.fetchChecklistTemplates()
      ]);

      this.filtersForm = new TimeClockQuestionsFiltersForm(
        {
          fields: timeClockQuestionsFiltersFormFields,
          rules: timeClockQuestionsFiltersFormRules,
          values: {
            projectFilters: this.projectFilters,
            memberFilters: this.memberFilters,
            classificationFilters: this.classificationFilters,
            employeeGroupFilters: this.employeeGroupFilters,
            eventOriginFilters: this.eventOriginFilters,
            responseFilters: this.responseFilters,
            templateFilters: this.templateFilters
          }
        },
        {
          options: timeClockQuestionsFiltersFormOptions,
          plugins: timeClockQuestionsFiltersFormPlugins
        }
      );
    } catch (error) {
      this.hideActiveModal();
      alertErrorHandler(error, this.notifications.pushError);
    } finally {
      this.filtersLoading = false;
    }
  }

  @action.bound
  async hideFilterDialog() {
    await this.hideActiveModal();

    this.filtersForm = null;
    this.projectSelectorUI.tearDown();
    this.memberSelectorUI.tearDown();
    this.classificationSelectorUI.tearDown();
    this.groupSelectorUI.tearDown();
    this.checklistTemplates.clear();
  }

  @action.bound async applyAllFilters() {
    return this.hideActiveModal().then(() => {
      runInAction(() => {
        this.projectFilters.replace(
          this.filtersForm.$('projectFilters').values()
        );
        this.memberFilters.replace(
          this.filtersForm.$('memberFilters').values()
        );
        this.classificationFilters.replace(
          this.filtersForm.$('classificationFilters').values()
        );
        this.employeeGroupFilters.replace(
          this.filtersForm.$('employeeGroupFilters').values()
        );
        this.eventOriginFilters.replace(
          this.filtersForm.$('eventOriginFilters').values()
        );
        this.responseFilters.replace(
          this.filtersForm.$('responseFilters').values()
        );
        this.templateFilters.replace(
          this.filtersForm.$('templateFilters').values()
        );
      });
    });
  }

  @action.bound
  async clearAllFilters() {
    await this.hideFilterDialog();

    this.projectFilters.clear();
    this.memberFilters.clear();
    this.classificationFilters.clear();
    this.employeeGroupFilters.clear();
    this.eventOriginFilters.clear();
    this.responseFilters.clear();
    this.templateFilters.clear();
  }

  @computed
  get eventOriginOptions() {
    return [
      {
        title: t('Kiosk'),
        value: 'KIOSK'
      },
      {
        title: t('Time clock'),
        value: 'TIME_CLOCK'
      }
    ];
  }

  @computed
  get responseOptions() {
    return [
      {
        title: t('Yes'),
        value: 'YES'
      },
      {
        title: t('No'),
        value: 'NO'
      },
      {
        title: t('Other'),
        value: 'OTHER'
      }
    ];
  }

  @action.bound async fetchChecklistTemplates() {
    try {
      await this.checklistTemplates.fetch({
        url: `${this.rootStore.urlMicroService('toolbox')}/companies/${
          this.rootStore.me.company.uuid
        }/checklist-templates/checklist-types/${
          this.rootStore.appConfig.timeClockChecklistType
        }`
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get templateOptions() {
    return this.checklistTemplates.models.map(template => {
      return { title: template.name, value: template.uuid };
    });
  }

  @computed
  get filtersCounter() {
    let counter = 0;
    if (this.projectFilters.length > 0) {
      counter++;
    }
    if (this.memberFilters.length > 0) {
      counter++;
    }
    if (this.classificationFilters.length > 0) {
      counter++;
    }
    if (this.employeeGroupFilters.length > 0) {
      counter++;
    }
    if (this.eventOriginFilters.length > 0) {
      counter++;
    }
    if (this.responseFilters.value) {
      counter++;
    }
    if (this.templateFilters.length > 0) {
      counter++;
    }

    return counter;
  }

  @computed get hasAppliedFilters() {
    return !!this.filtersCounter;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.timeClockQuestions.totalElements / this.pageSize);
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }
}
