import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import trimObject from '../utils/trimObject';
import { t } from 'utils/translate';

const checklistTypeFormPlugins = extendedPlugins;

const checklistTypeFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const checklistTypeFormFields = [
  'typeClass',
  'typeClass.uuid',
  'typeClass.name',
  'typeName'
];

const checklistTypeFormValues = {};

const checklistTypeFormRules = {
  'typeClass.uuid': 'string|required',
  typeName: 'string|required|max:250'
};

const checklistTypeFormLabels = {
  typeName: t('type name'),
  'typeClass.uuid': t('class')
};

class ChecklistTypeForm extends MobxReactForm {
  trimmedValues() {
    return trimObject(this.values());
  }
}

export {
  ChecklistTypeForm,
  checklistTypeFormPlugins,
  checklistTypeFormOptions,
  checklistTypeFormFields,
  checklistTypeFormRules,
  checklistTypeFormLabels,
  checklistTypeFormValues
};
