import { RakenCollection } from '../lib';
import JobTitle from '../models/JobTitle';

export default class JobTitles extends RakenCollection {
  model() {
    return JobTitle;
  }

  url() {
    return '/ra/job-titles';
  }
}
