import { computed } from 'mobx';
import rankCollectionByValue from 'utils/rankCollectionByValue';

import InsightsComplianceBySubContractor from './InsightsComplianceBySubContractor';

export default class ProjectInsightsComplianceBySubContractor extends InsightsComplianceBySubContractor {
  @computed
  get project() {
    return this.parent.project;
  }

  @computed
  get params() {
    return {
      projectIds: this.project?.parentId,
      startDay: this.parent.startDay,
      endDay: this.parent.endDay
    };
  }

  @computed
  get rolledUpTeamMeasuresByProject() {
    let projectTeamMeasures = [];

    this.projectMeasures.models
      .filter(model => model.teamMeasures && model.teamMeasures.length)
      .forEach(projectMeasure => {
        projectTeamMeasures = projectTeamMeasures.concat(
          projectMeasure.teamMeasures.models.slice()
        );
      });

    return rankCollectionByValue(
      projectTeamMeasures,
      `stats.${this.rankField}`
    );
  }

  @computed get filteredRolledUpTeamMeasuresByProject() {
    return this.rolledUpTeamMeasuresByProject.filter(teamMeasure => {
      return this.parent.filteredProjectTeams.find(projectTeam => {
        return projectTeam.id === teamMeasure.projectTeamId;
      });
    });
  }
}
