import MobxReactForm from 'mobx-react-form';
import { reaction } from 'mobx';
import extendedPlugins from './utils/extendedPlugins';
import trimObject from '../utils/trimObject';
import { t } from 'utils/translate';

const checklistScheduleFormPlugins = extendedPlugins;

const checklistScheduleFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const checklistScheduleFormFields = [
  'checklistTemplateUuid',
  'location',
  'location.uuid',
  'responsibleWorkerClassification',
  'responsibleWorkerClassification.uuid',
  'responsibleWorkerClassification.name',
  'responsibleChildProject',
  'responsibleChildProject.uuid',
  'responsibleChildProject.name',
  'project',
  'project.uuid',
  'project.name',
  'projectType',
  'enableReminder',
  'startDate',
  'startTime',
  'endDate',
  'scheduleFrequency',
  'displayBefore'
];

const checklistScheduleFormValues = {
  enableReminder: false,
  projectType: 'MY_TEAM'
};

const checklistScheduleFormRules = {
  checklistTemplateUuid: 'string|required|max:255',
  'location.uuid': 'string|max:255',
  'location.name': 'string|max:255',
  'project.uuid': 'string|required|max:255',
  'project.name': 'string|required|max:255',
  enableReminder: 'boolean',
  startDate: 'required|afterOrTodayDate:YYYY-MM-DD|dateCustom:YYYY-MM-DD',
  startTime: 'required',
  endDate: 'required|afterOrTodayDate:YYYY-MM-DD|dateCustom:YYYY-MM-DD',
  scheduleFrequency: 'required',
  displayBefore: 'required'
};

const checklistScheduleFormLabels = {
  checklistTemplateUuid: t('checklist template'),
  startDate: t('first checklist date'),
  startTime: t('first checklist time'),
  endDate: t('checklist end date'),
  scheduleFrequency: t('checklist schedule frequency'),
  displayBefore: t('checklist display in list'),
  'location.uuid': t('checklist project location'),
  'project.uuid': t('checklist project'),
  'responsibleWorkerClassification.uuid': t('checklist classification'),
  'responsibleChildProject.uuid': t('checklist collaborator')
};

class ChecklistScheduleForm extends MobxReactForm {
  constructor(settings, options) {
    super(settings, options);

    this.reactToProjectType = reaction(
      () => this.$('projectType').value,
      value => {
        if (value === 'MY_TEAM') {
          this.$('responsibleChildProject.uuid').set('rules', '');
          this.$('responsibleWorkerClassification.uuid').set(
            'rules',
            'string|required|max:255'
          );
        } else {
          this.$('responsibleWorkerClassification.uuid').set('rules', '');
          this.$('responsibleChildProject.uuid').set(
            'rules',
            'string|required|max:255'
          );
        }
      },
      {
        fireImmediately: true
      }
    );
  }

  trimmedValues() {
    return trimObject(this.values());
  }
}

export {
  ChecklistScheduleForm,
  checklistScheduleFormPlugins,
  checklistScheduleFormOptions,
  checklistScheduleFormFields,
  checklistScheduleFormRules,
  checklistScheduleFormLabels,
  checklistScheduleFormValues
};
