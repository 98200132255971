import { observable, action, computed, reaction, runInAction } from 'mobx';
import UIStore from '../../UIStore';
import debounce from 'lodash.debounce';

import { BASE_DEBOUNCE } from 'fixtures/constants';

import EquipmentDeploymentsHistory from 'stores/collections/EquipmentDeploymentsHistory';

import alertErrorHandler from 'utils/alertErrorHandler';

import {
  EquipmentDeploymentHistoryFiltersForm,
  equipmentDeploymentHistoryFiltersFormRules,
  equipmentDeploymentHistoryFiltersFormFields,
  equipmentDeploymentHistoryFiltersFormOptions,
  equipmentDeploymentHistoryFiltersFormPlugins
} from 'forms/equipment/equipmentDeploymentHistoryFilters';

import { t } from 'utils/translate';

export default class EquipmentDeploymentHistoryUI extends UIStore {
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;
  @observable equipmentDeploymentHistoryFiltersForm;

  constructor(options) {
    super(options);

    this.loading = true;

    this.sortField = 'logDate';
    this.sortDirection = 'asc';
    this.page = 1;
    this.searchQuery = '';
    this.pageSize = 20;

    // Filters
    this.equipmentDeploymentHistoryFiltersForm = null;
    this.deployedToFilters = observable([]);
    this.costCodeFilters = observable([]);
    this.operatorFilters = observable([]);
    this.statusFilters = observable([]);

    // Equipment collection
    this.equipmentDeploymentsHistory = new EquipmentDeploymentsHistory(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchEquipmentDeploymentsHistoryDebounced = debounce(
      this.fetchEquipmentDeploymentsHistory,
      BASE_DEBOUNCE
    );
  }

  @computed
  get totalPages() {
    return Math.ceil(
      this.equipmentDeploymentsHistory.totalElements / this.pageSize
    );
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.loading = true;
    this.page = 1;
  }

  @computed get entryForEdit() {
    return this.parent.entryForEdit;
  }

  @action.bound setup() {
    window.scrollTo(0, 0);
    this.setupReactions();
    this.fetchEquipmentDeploymentsHistory();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.fetchEquipmentDeploymentsHistoryDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.equipmentDeploymentHistoryFiltersForm = null;
    this.sortField = 'logDate';
    this.sortDirection = 'asc';
    this.page = 1;
    this.searchQuery = '';
    this.clearEquipmentDeploymentHistoryFilters();
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      sortField: [this.sortField],
      sortDirection: this.sortDirection,
      deployedTo: this.deployedToFilters.map(
        deployedToFilter => deployedToFilter.value
      ),
      costCode: this.costCodeFilters.map(
        costCodeFilter => costCodeFilter.value
      ),
      operator: this.operatorFilters.map(
        operatorFilter => operatorFilter.workerUuid
      ),
      status: this.statusFilters.map(statusFilter => statusFilter.value)
    };
  }

  @action.bound async fetchEquipmentDeploymentsHistory() {
    this.loading = true;
    this.equipmentDeploymentsHistory.cancelRequest();
    this.equipmentDeploymentsHistory.reset();

    try {
      await this.equipmentDeploymentsHistory.fetchWithPost({
        url: `${this.rootStore.appConfig.tracking_api_url}/companies/${this.rootStore.me.company.uuid}/equipment/${this.entryForEdit.uuid}/deployments/logs/history`,
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed get hasEquipmentDeploymentsHistory() {
    return this.equipmentDeploymentsHistory.hasModels;
  }

  @computed get showEmptyState() {
    return (
      !this.loading &&
      !this.searchQuery &&
      !this.hasAppliedFilters &&
      !this.hasEquipmentDeploymentsHistory
    );
  }

  @computed get showEmptySearchState() {
    return (
      !this.loading &&
      (this.searchQuery || this.hasAppliedFilters) &&
      !this.hasEquipmentDeploymentsHistory
    );
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  // Filters

  @action.bound showEquipmentDeploymentHistoryFiltersDialog() {
    this.showModal('EquipmentDeploymentHistoryFiltersDialog');
    this.setupEquipmentDeploymentHistoryFiltersForm();
  }

  @action.bound async hideEquipmentDeploymentHistoryFiltersDialog() {
    await this.hideActiveModal();

    this.equipmentDeploymentHistoryFiltersForm = null;
    this.projectSelectorUI.tearDown();
    this.costCodeSelectorUI.tearDown();
    this.memberSelectorUI.tearDown();
  }

  @action.bound setupEquipmentDeploymentHistoryFiltersForm() {
    this.projectSelectorUI.setup({
      projectStates: ['ACTIVE']
    });

    this.costCodeSelectorUI.setup();

    this.memberSelectorUI.setup({
      role: [
        'ROLE_ACCOUNT_ADMIN',
        'ROLE_ADMIN',
        'ROLE_PROJECT_MEMBER',
        'ROLE_USER'
      ]
    });

    this.equipmentDeploymentHistoryFiltersForm = new EquipmentDeploymentHistoryFiltersForm(
      {
        fields: equipmentDeploymentHistoryFiltersFormFields,
        rules: equipmentDeploymentHistoryFiltersFormRules,
        values: {
          deployedToFilters: this.deployedToFilters.slice(),
          costCodeFilters: this.costCodeFilters.slice(),
          operatorFilters: this.operatorFilters.slice(),
          statusFilters: this.statusFilters.slice()
        }
      },
      {
        options: equipmentDeploymentHistoryFiltersFormOptions,
        plugins: equipmentDeploymentHistoryFiltersFormPlugins
      }
    );
  }

  @action.bound clearEquipmentDeploymentHistoryFilters() {
    if (this.hasAppliedFilters) {
      this.loading = true;
    }
    this.hideEquipmentDeploymentHistoryFiltersDialog();
    this.deployedToFilters.clear();
    this.costCodeFilters.clear();
    this.operatorFilters.clear();
    this.statusFilters.clear();
  }

  @action.bound submitEquipmentDeploymentHistoryFiltersForm(event) {
    event.preventDefault();

    this.equipmentDeploymentHistoryFiltersForm.submit({
      onSuccess: this.submitEquipmentDeploymentHistoryFiltersFormSuccess,
      onError: this.submitEquipmentDeploymentHistoryFiltersFormError
    });
  }

  @action.bound submitEquipmentDeploymentHistoryFiltersFormSuccess() {
    const values = this.equipmentDeploymentHistoryFiltersForm.values();

    this.deployedToFilters.replace(values.deployedToFilters);
    this.costCodeFilters.replace(values.costCodeFilters);
    this.operatorFilters.replace(values.operatorFilters);
    this.statusFilters.replace(values.statusFilters);

    this.hideEquipmentDeploymentHistoryFiltersDialog();
  }

  @action.bound submitEquipmentDeploymentHistoryFiltersFormError() {
    console.error(this.equipmentDeploymentHistoryFiltersForm.errors());
  }

  @computed
  get filtersCounter() {
    let counter = 0;

    if (this.deployedToFilters.length) {
      counter += 1;
    }

    if (this.costCodeFilters.length) {
      counter += 1;
    }

    if (this.operatorFilters.length) {
      counter += 1;
    }

    if (this.statusFilters.length) {
      counter += 1;
    }

    return counter;
  }

  @computed get hasAppliedFilters() {
    return !!this.filtersCounter;
  }

  @computed get statusFilterOptions() {
    return [
      { value: 'IN_USE', title: t('In use') },
      { value: 'NOT_IN_USE', title: t('Not in use') },
      { value: 'WITHDRAWN', title: t('Withdrawn') }
    ];
  }
}
