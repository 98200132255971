import kebabCase from 'lodash.kebabcase';
import omit from 'lodash.omit';
import { computed } from 'mobx';
import moment from 'moment';

import formatTimestamp from 'utils/formatTimestamp';
import Attachment from '../Attachment';
import ProjectMinimal from '../ProjectMinimal';
import ReportEntry from '../ReportEntry';

export default class DocumentsFile extends ReportEntry {
  get urlRoot() {
    return `/ra/companies/${this.rootStore.me.company.uuid}/document-storage/files`;
  }

  idAttribute() {
    return 'uuid';
  }

  get restAttributes() {
    return [
      'uuid',
      'type',
      'companyUuid',
      'projectUuid',
      'parentFolderUuid',
      'path',
      'name',
      'description',
      'status',
      'fileType',
      'createdByFullName',
      'updatedByFullName',
      'createdTimestamp',
      'updatedTimestamp'
    ];
  }

  parse(attributes) {
    super.parse(attributes);

    this.parseDocument(attributes);

    return {
      ...omit(attributes, [
        'document',
        'createdBy',
        'updatedBy',
        'company',
        'project'
      ])
    };
  }

  parseProject(attributes) {
    this.project = new ProjectMinimal(
      {
        uuid: attributes.projectUuid
      },
      {
        rootStore: this.rootStore
      }
    );
  }

  parseDocument(attributes) {
    if (!attributes.document) return;

    if (this.document) {
      this.document.set(attributes.document);
    } else {
      this.document = new Attachment(attributes.document, {
        parent: this,
        rootStore: this.rootStore,
        skipThumbnailPolling: true
      });
    }
  }

  @computed get isFile() {
    return true;
  }

  @computed
  get icon() {
    switch (this.fileType?.toLowerCase()) {
      case 'xls':
      case 'xlsx':
        return `${this.rootStore.assetsURL}/svg/documents/excel.svg`;
      case 'gif':
      case 'jpg':
      case 'jpeg':
      case 'png':
        return `${this.rootStore.assetsURL}/svg/documents/image.svg`;
      case 'pdf':
        return `${this.rootStore.assetsURL}/svg/documents/pdf.svg`;
      case 'ppt':
      case 'pptx':
        return `${this.rootStore.assetsURL}/svg/documents/powerpoint.svg`;
      case 'doc':
      case 'docx':
        return `${this.rootStore.assetsURL}/svg/documents/word.svg`;
      default:
        return `${this.rootStore.assetsURL}/svg/documents/pdf.svg`;
    }
  }

  @computed
  get formValues() {
    return {
      name: this.name
    };
  }

  @computed
  get slug() {
    return kebabCase(this.name);
  }

  @computed
  get date() {
    return moment(this.createdTimestamp).format('MM/DD/YYYY');
  }

  @computed
  get createdAtFormatted() {
    return formatTimestamp(this.createdTimestamp);
  }

  @computed
  get updatedAtFormatted() {
    return formatTimestamp(this.updatedTimestamp);
  }

  @computed get viewUrl() {
    if (this.parentFolderUuid) {
      return `/projects/${this.rootStore.projectUI.project.id}/documents/${this.parentFolderUuid}/files/${this.id}`;
    }

    return `/projects/${this.rootStore.projectUI.project.id}/documents/index/files/${this.id}`;
  }
}
