import MobxReactForm from 'mobx-react-form';
import validatorjs from 'validatorjs';

const jobTitleFormPlugins = { dvr: validatorjs };

const jobTitleFormOptions = {
  validateOnInit: false,
  validateOnChange: true
};

const jobTitleFormFields = ['type', 'name'];

const jobTitleFormRules = {
  name: 'required|string|max:150'
};

const jobTitleFormValues = {
  type: 'JobTitle'
};

class JobTitleForm extends MobxReactForm {}

export {
  JobTitleForm,
  jobTitleFormValues,
  jobTitleFormRules,
  jobTitleFormFields,
  jobTitleFormOptions,
  jobTitleFormPlugins
};
