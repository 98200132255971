import orderBy from 'lodash.orderby';

export default (teamMeasures, sortField, sortDirection) => {
  switch (sortField) {
    case 'ranking':
      return orderBy(teamMeasures, [sortField], [sortDirection]);
    case 'name':
      return orderBy(
        teamMeasures,
        [teamMeasure => teamMeasure.stats.company.name.toLowerCase()],
        [sortDirection]
      );
    default:
      return orderBy(
        teamMeasures,
        [teamMeasure => teamMeasure.stats[sortField]],
        [sortDirection]
      );
  }
};
