import { Model } from 'mobx-mc';
import Shift from '../Shift';
import CostCode from '../CostCode';
import PayType from '../PayType';
import Classification from '../Classification';
import { action, computed } from 'mobx';
import omit from 'lodash.omit';

class TimeEntry extends Model {
  get restAttributes() {
    return ['uuid', 'hours', 'order'];
  }

  idAttribute() {
    return 'uuid';
  }

  @action.bound
  parse(data) {
    this.parsePayType = this.parsePayType(data.payType);
    data.shift && this.parseShift(data.shift);
    data.classification && this.parseClassification(data.classification);
    data.costCode && this.parseCostCode(data.costCode);

    return {
      ...omit(data, ['payType', 'shift', 'classification', 'costCode'])
    };
  }

  @action.bound
  parsePayType(payType) {
    this.payType = new PayType(payType);
  }

  @action.bound
  parseShift(shift) {
    this.shift = new Shift(shift);
  }

  @action.bound
  parseClassification(classification) {
    this.classification = new Classification(classification);
  }

  @action.bound
  parseCostCode(costCode) {
    this.costCode = new CostCode(costCode, {
      rootStore: this.rootStore
    });
  }

  @computed
  get formValues() {
    return {
      uuid: this.uuid,
      hours: this.hours,
      payType: {
        uuid: this.payType.uuid,
        name: this.payType.name
      },
      shift: this.shift
        ? {
            uuid: this.shift.uuid,
            name: this.shift.name
          }
        : null,
      classification: this.classification
        ? {
            uuid: this.classification.uuid,
            name: this.classification.name
          }
        : null,
      costCode: this.costCode
        ? {
            uuid: this.costCode.uuid,
            code: this.costCode.code,
            division: this.costCode.division,
            description: this.costCode.projectOrCompanyDescription
          }
        : null
    };
  }
}

export default TimeEntry;
