import omit from 'lodash.omit';
import { action } from 'mobx';
import ProjectTeamMeasures from '../collections/ProjectTeamMeasures';
import InsightsPeriod from './InsightsPeriod';

export default class extends InsightsPeriod {
  get restAttributes() {
    return ['type', 'start', 'end'];
  }

  @action.bound
  parse(attributes) {
    this.teamMeasures = new ProjectTeamMeasures(
      attributes.teamMeasures || null,
      {
        rootStore: this.rootStore,
        parent: this
      }
    );

    return {
      ...omit(attributes, ['teamMeasures'])
    };
  }
}
