import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';

const costCodeMaterialFormPlugins = extendedPlugins;

const costCodeMaterialFormOptions = {
  validateOnInit: false,
  strictUpdate: false,
  validateOnChange: true
};

const costCodeMaterialFormFields = ['progressHoursPercent'];

const costCodeMaterialFormRules = {
  progressHoursPercent: 'numeric|min:0|max:100'
};

const costCodeMaterialFormLabels = {};

class CostCodeMaterialForm extends MobxReactForm {}

export {
  CostCodeMaterialForm,
  costCodeMaterialFormOptions,
  costCodeMaterialFormFields,
  costCodeMaterialFormRules,
  costCodeMaterialFormLabels,
  costCodeMaterialFormPlugins
};
