import request from 'axios';
import moment from 'moment';
import { Model } from 'mobx-mc';
import { action, computed } from 'mobx';

import numberWithCommas from 'utils/numberWithCommas';
import downloadURI from 'utils/downloadURI';
import errorHandler from 'utils/errorHandler';

export default class Transaction extends Model {
  get restAttributes() {
    return [
      'id',
      'amount',
      'cardType',
      'created',
      'createdTimeStamp',
      'description',
      'startDate',
      'endDate',
      'lastFour',
      'paymentType',
      'paymentProcessor',
      'refundTransactionId',
      'status',
      'transactionId',
      'usersAdded',
      'usersInvited',
      'usersRemoved'
    ];
  }

  get restAttributeDefaults() {
    return {
      transactionId: 'N/A'
    };
  }

  @computed
  get createdDateMoment() {
    return moment(this.created.substring(0, 10));
  }

  @computed
  get createdDateValue() {
    return this.createdDateMoment.valueOf();
  }

  @computed
  get createdDateFormatted() {
    return this.createdDateMoment.format('YYYY-MM-DD');
  }

  @computed
  get amountValue() {
    return this.amount;
  }

  @computed
  get amountFormatted() {
    if (this.isRefunded) {
      return `$${(this.amount / 100)
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    }

    return `$${numberWithCommas(this.amount / 100)}`;
  }

  @computed
  get startDateValue() {
    return moment(this.startDate).valueOf();
  }

  @computed
  get billingPeriodFormatted() {
    if (!this.startDate || !this.endDate) return 'N/A';

    return `${moment(this.startDate).format('YYYY-MM-DD')} - ${moment(
      this.endDate
    ).format('YYYY-MM-DD')}`;
  }

  @computed
  get descriptionFormatted() {
    if (!this.description) {
      if (this.hasAddedUsers) {
        return `${this.addedUsers.length} User(s) Added.`;
      }

      if (this.hasRemovedUsers) {
        return `${this.removedUsers.length} User(s) Removed.`;
      }

      if (this.hasInvitedUsers) {
        return `${this.invitedUsers.length} User(s) Invited.`;
      }

      return 'N/A';
    }

    return this.description;
  }

  @computed
  get paymentMethodFormatted() {
    if (!this.lastFour) return 'N/A';

    return `${this.cardType || 'Card'} ending in ${this.lastFour}`;
  }

  @computed
  get hasAddedUsers() {
    return this.usersAdded && this.usersAdded.length > 0;
  }

  @computed
  get addedUsers() {
    return this.usersAdded;
  }

  @computed
  get hasRemovedUsers() {
    return this.usersRemoved && this.usersRemoved.length > 0;
  }

  @computed
  get removedUsers() {
    return this.usersRemoved;
  }

  @computed
  get hasInvitedUsers() {
    return this.usersInvited && this.usersInvited.length > 0;
  }

  @computed
  get invitedUsers() {
    return this.usersInvited;
  }

  @action.bound
  download() {
    this.downloading = true;

    request
      .get(`/ra/billing/transaction-history/${this.id}`)
      .then(response => {
        downloadURI(response.data.url, `Transaction - ${this.id}`);
        this.downloading = false;
      })
      .catch(error => {
        errorHandler(error, this.rootStore.notificationsUI.pushError);
        this.downloading = false;
      });
  }

  @computed
  get isRefunded() {
    return this.paymentType === 'REFUND';
  }

  @computed
  get allowToRefund() {
    return this.paymentType === 'CREDIT_CARD' && this.status === 'SUCCESS';
  }

  @computed
  get allowToDelete() {
    return this.paymentType === 'CHECK' && this.status === 'SUCCESS';
  }

  @computed
  get paymentTransactionURL() {
    if (
      this.paymentProcessor &&
      this.paymentProcessor.toLowerCase() === 'stripe'
    ) {
      return `https://dashboard.stripe.com/payments/${this.transactionId}`;
    } else {
      return `https://merchantcenter.intuit.com/msc/portal/reporting#transaction/${this.transactionId}`;
    }
  }

  @computed get tooltip() {
    if (this.hasAddedUsers) {
      return this.addedUsers.map(user => user.name).join('\n');
    }

    if (this.hasInvitedUsers) {
      return this.invitedUsers.map(user => user.name).join('\n');
    }

    if (this.hasRemovedUsers) {
      return this.removedUsers.map(user => user.name).join('\n');
    }

    return null;
  }
}
