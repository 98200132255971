import omit from 'lodash.omit';
import { action } from 'mobx';
import User from '../User';
import Settings from '../Settings';

export default class SuperAdminMe extends User {
  constructor(data, options) {
    super(data, options);
  }

  url() {
    return '/ra/user';
  }

  @action.bound
  parse(attributes) {
    if (attributes.settings) {
      this.settings = new Settings(attributes.settings, {
        rootStore: this.rootStore
      });
    }
    // Keep reference to legacy id
    this.userId = attributes.id;

    return {
      ...omit(attributes, ['settings'])
    };
  }
}
