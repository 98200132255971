import UIStore from 'stores/ui/UIStore';
import { observable, action, computed } from 'mobx';
import isDataURL from 'utils/isDataURL';
import errorHandler from 'utils/errorHandler';
import history from 'utils/history';

import {
  CompanyPDFLogoForm,
  companyPDFLogoFormOptions,
  companyPDFLogoFormFields,
  companyPDFLogoFormRules,
  companyPDFLogoFormLabels,
  companyPDFLogoFormPlugins
} from 'forms/superAdmin/companyPDFLogo';

export default class SuperAdminCompanyPDFLogoUI extends UIStore {
  @observable pdfLogoForm;
  @observable exitModalShown;
  @observable nextURL;

  constructor(options) {
    super(options);
    this.pdfLogoForm = null;
    this.exitModalShown = false;
    this.nextURL = null;
  }

  @computed
  get activeCompany() {
    return this.parent.activeCompany;
  }

  @action.bound
  setup() {
    this.pdfLogoForm = new CompanyPDFLogoForm(
      {
        fields: companyPDFLogoFormFields,
        rules: companyPDFLogoFormRules,
        values: this.activeCompany.pdfLogoFormValues,
        labels: companyPDFLogoFormLabels
      },
      {
        options: companyPDFLogoFormOptions,
        plugins: companyPDFLogoFormPlugins
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.pdfLogoForm = null;
    this.exitModalShown = false;
    this.nextURL = null;
  }

  @action.bound blockHistoryIfFormChanged() {
    this.unblock = history.block((location, action) => {
      if (this.pdfLogoForm && this.pdfLogoForm.check('isDirty')) {
        this.showExitModal(location.pathname);
        return 'Blocked';
      }
    });
  }

  @computed
  get formIsValid() {
    return Boolean(this.pdfLogoForm && this.pdfLogoForm.check('isValid'));
  }

  @action.bound
  cancelChanges(e) {
    e.preventDefault();
    this.pdfLogoForm.onReset(e);
  }

  @action.bound
  submitPDFLogoForm(e) {
    e.preventDefault();

    try {
      this.pdfLogoForm.submit({
        onSuccess: () => {
          this.submitPdfLogoFormSuccess(this.pdfLogoForm.values());
        },
        onError: () => {
          console.log(this.pdfLogoForm.errors());
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  @action.bound
  async submitPdfLogoFormSuccess(values) {
    this.saving = true;

    const formattedValues = {
      preferences: {
        primaryColor: values.primaryColor
      }
    };

    if (values.companyLogo && isDataURL(values.companyLogo)) {
      formattedValues.logo = {
        base64: values.companyLogo
      };
    }

    try {
      await this.activeCompany.save(formattedValues, {
        wait: true
      });

      this.saving = false;

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: 'New report design saved'
      });

      if (this.nextURL) {
        this.moveToNextURL();
      } else {
        this.setupCompanyPDFLogoForm();
      }
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  @action.bound
  showExitModal(nextURL) {
    this.showModal('DiscardChangesModal');
    this.nextURL = nextURL;
  }

  @action.bound
  cancelExitModal() {
    return this.hideActiveModal().then(() => {
      this.nextURL = null;
    });
  }

  @action.bound
  discardExitModal() {
    return this.hideActiveModal().then(() => {
      this.exitModalShown = false;
      this.moveToNextURL();
    });
  }

  @action.bound
  saveExitModal(e) {
    this.exitModalShown = false;
    this.submitPDFLogoForm(e);
  }

  @action.bound moveToNextURL() {
    this.unblock();
    history.push(this.nextURL);
    this.nextURL = null;
  }
}
