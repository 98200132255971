import { BASE_DEBOUNCE } from 'fixtures/constants';
import debounce from 'lodash.debounce';
import request from 'axios';
import moment from 'moment';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import IncidentConstants from 'stores/collections/incidents/IncidentConstants';
import Incidents from 'stores/collections/incidents/Incidents';
import IncidentEditUI from 'stores/ui/project/incidents/IncidentEditUI';
import alertErrorHandler from 'utils/alertErrorHandler';
import {
  EmailIncidentForm,
  emailIncidentFormOptions,
  emailIncidentFormFields,
  emailIncidentFormRules,
  emailIncidentFormLabels,
  emailIncidentFormPlugins
} from 'forms/emailIncident';
import history from 'utils/history';
import {
  INCIDENT_DELETED,
  INCIDENT_EDITED,
  INCIDENT_FEATURE_VIEWED
} from 'utils/segmentAnalytics/eventSpec';
import { callTrack } from 'utils/segmentIntegration';
import { t } from 'utils/translate';
import ProjectChildUI from '../ProjectChildUI';
import IncidentAddUI from './IncidentAddUI';

export default class IncidentsUI extends ProjectChildUI {
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable page;
  @observable pageSize;
  @observable loading;

  @observable selectedIncident;
  @observable shareIncidentForm;

  constructor(options) {
    super(options);

    this.loading = true;

    this.sortField = 'incidentDateTime';
    this.sortDirection = 'desc';
    this.searchQuery = '';
    this.page = 1;
    this.pageSize = 20;

    this.selectedIncident = null;
    this.shareIncidentForm = null;
    // Collection
    this.incidents = new Incidents(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.incidentConstants = new IncidentConstants(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.incidentAddUI = new IncidentAddUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.incidentEditUI = new IncidentEditUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchIncidentsDebounced = debounce(this.fetchIncidents, BASE_DEBOUNCE);
  }

  @action.bound setup() {
    callTrack(INCIDENT_FEATURE_VIEWED);
    window.scrollTo(0, 0);
    this.setupReactions();
    this.fetchIncidents();
    this.fetchIncidentConstants();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.searchQuery = '';
    this.incidents.clear();
    this.incidentConstants.clear();
    this.page = 1;
    this.loading = true;
    this.saving = false;
    this.sortField = 'incidentDateTime';
    this.sortDirection = 'desc';
    this.selectedIncident = null;
    this.shareIncidentForm = null;
    this.selectedIncident = null;
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchIncidentsDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @computed get showEmptyState() {
    return !this.loading && !this.searchQuery && !this.hasIncidents;
  }

  @computed get showEmptySearchState() {
    return !this.loading && this.searchQuery && !this.hasSearchedIncidents;
  }

  @computed get hasIncidents() {
    return this.incidents.hasModels;
  }

  @action.bound async fetchIncidentConstants() {
    this.incidentConstants.clear();

    try {
      await this.incidentConstants.fetch();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @computed get hasSearchedIncidents() {
    return this.searchedIncidents.length > 0;
  }

  @computed get searchedIncidents() {
    if (!this.searchQuery) {
      return this.incidents.models;
    }

    const query = this.searchQuery.toLowerCase();

    return this.incidents.models.filter(incident => {
      return (
        incident.incidentTitle?.toLowerCase().indexOf(query) >= 0 ||
        incident.completedByName?.toLowerCase().indexOf(query) >= 0
      );
    });
  }

  @action.bound async fetchIncidents() {
    if (!this.projectUuid) return;

    this.loading = true;

    this.incidents.clear();

    try {
      await this.incidents.fetch({
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      projectUuids: this.project?.uuid,
      sortDirection: this.sortDirection,
      sortField: this.sortField
    };
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @action.bound
  sortByLastCreated() {
    this.sortField = 'updatedTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchIncidentsDebounced();
  }

  @action.bound async deleteIncident(incident) {
    await this.authorization.checkFeatureAccess('CUDIncidents');

    this.selectedIncident = incident;
    this.showModal('DeleteModal');
  }

  @action.bound async cancelDeleteIncident() {
    await this.hideActiveModal();

    this.selectedIncident = null;
  }

  @action.bound async confirmDeleteIncident() {
    this.saving = true;

    try {
      await this.selectedIncident.destroy({ wait: true });

      callTrack(INCIDENT_DELETED, {
        project_id: this.selectedIncident.projectUuid,
        project_name: this.selectedIncident.projectName,
        account_type: this.me?.company?.accountSubType
      });

      this.refetchIncidents();

      await this.hideActiveModal();

      this.selectedIncident = null;

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Incident deleted')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @computed
  get totalPages() {
    return Math.ceil(this.incidents.totalElements / this.pageSize);
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound refetchIncidents() {
    this.loading = true;

    if (!this.hasIncidents) {
      this.setPage(null, 1);
      this.fetchIncidents();
    } else {
      this.fetchIncidents();
    }
  }

  @computed get hasWriteAccess() {
    return this.authorization.canCUDIncidents;
  }

  getActions = incident => {
    let actions = [
      {
        title: this.hasWriteAccess ? t('Edit') : t('View'),
        onClick: () => {
          this.editIncident(incident.uuid);
        }
      }
    ];
    if (this.hasWriteAccess) {
      actions = actions.concat([
        {
          title: t('Share'),
          onClick: () => {
            this.openShareIncidentModal(incident);
          }
        },
        {
          title: incident.status === 'Open' ? t('Close') : t('Reopen'),
          onClick: () => {
            this.updateIncidentStatus(incident);
          }
        },
        {
          title: t('Delete'),
          onClick: () => {
            this.deleteIncident(incident);
          }
        }
      ]);
    }
    return actions;
  };

  @action.bound async updateIncidentStatus(incident) {
    await this.authorization.checkFeatureAccess('CUDIncidents');

    await incident.save({
      status: incident.status === 'Open' ? 'Closed' : 'Open'
    });

    callTrack(INCIDENT_EDITED, {
      project_id: incident.projectUuid,
      project_name: incident.projectName,
      account_type: this.me?.company?.accountSubType,
      incident_title: incident.incidentTitle,
      incident_status: incident.status,
      incident_recordable: incident.isRecordable,
      incident_has_notifications: incident.notifications.length > 0,
      incident_date: incident.incidentDate,
      incident_injury_or_illness_type: incident.injuryOrIllnessType,
      incident_employee_job_title: incident.employeeJobTitle,
      incident_outcome: incident.incidentOutcome
    });
  }

  @action.bound async addIncident() {
    await this.authorization.checkFeatureAccess('CUDIncidents');

    history.push({
      pathname: `${this.project.viewUrl}/incidents/add`,
      search: this.baseQueryParams
    });
  }

  @action.bound async editIncident(id) {
    history.push({
      pathname: `${this.project.viewUrl}/incidents/${id}`,
      search: this.baseQueryParams
    });
  }

  @action.bound async openShareIncidentModal(incident) {
    await this.authorization.checkFeatureAccess('CUDIncidents', true);
    this.shareIncidentForm = new EmailIncidentForm(
      {
        fields: emailIncidentFormFields,
        rules: emailIncidentFormRules,
        labels: emailIncidentFormLabels,
        values: {
          emails: []
        }
      },
      {
        options: emailIncidentFormOptions,
        plugins: emailIncidentFormPlugins
      }
    );
    this.selectedIncident = incident;
    this.showModal('shareIncident');
  }

  @action.bound
  async hideShareIncidentModal() {
    await this.hideActiveModal();
    this.selectedIncident = null;
    this.shareIncidentForm = null;
  }

  @action.bound submitShareIncidentForm(event) {
    event.preventDefault();
    event.stopPropagation();

    this.shareIncidentForm.submit({
      onSuccess: this.submitShareIncidentFormSuccess,
      onError: this.submitShareIncidentFormError
    });
  }

  @action.bound async submitShareIncidentFormSuccess() {
    this.saving = true;

    try {
      const payload = {
        exportType: 'INCIDENT_REPORT_301',
        year: moment().year(),
        emails: this.shareIncidentForm.$('emails').value,
        skipIncompleteRecordValidation: true
      };

      await request.post(
        `${this.rootStore.urlMicroService('toolbox')}/companies/${
          this.company.uuid
        }/incident-reports/${this.selectedIncident?.uuid}/export`,
        payload
      );

      await this.hideShareIncidentModal();
      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Incident shared')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitShareIncidentFormError() {
    console.error(this.shareIncidentForm.errors());
  }

  @computed
  get emailListIsValid() {
    return this.shareIncidentForm?.isValid;
  }

  @computed
  get statusOptions() {
    return [
      { value: 'Open', title: t('Open') },
      { value: 'Closed', title: t('Closed') }
    ];
  }

  @computed get employeeGenderOptions() {
    return [
      { value: 'Male', title: t('Male') },
      { value: 'Female', title: t('Female') },
      { value: 'Undisclosed', title: t('Undisclosed') }
    ];
  }

  @computed get incidentOutcomeOptions() {
    return this.incidentConstants.models
      .filter(model => model.type === 'IncidentOutcome')
      .slice()
      .map(model => {
        return {
          value: model.value,
          title: model.title
        };
      });
  }

  @computed get incidentInjuryIllnessOptions() {
    return this.incidentConstants.models
      .filter(model => model.type === 'InjuryOrIllnessType')
      .slice()
      .map(model => {
        return {
          value: model.value,
          title: model.title
        };
      });
  }

  @computed get incidentPIIHelperText() {
    return t(
      'Please do not include any personally identifiable information (PII).'
    );
  }

  @computed get incidentNotificationText() {
    return t(
      'Selected team members receive an email and text message (if phone number available)'
    );
  }
}
