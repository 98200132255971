import styled, { css } from 'styled-components';
import { darken } from 'polished';
import media from '../utils/mediaTemplate';

export default styled.td`
  background-color: ${props =>
    props.backgroundColor
      ? props.theme.colors[props.backgroundColor]
      : props.sorted
      ? darken(0.03, props.theme.colors.white)
      : props.theme.colors.white};
  color: ${props => props.theme.colors.charcoalGrey};
  overflow: ${props => props.overflow || 'hidden'};
  padding: ${props => props.padding || '1rem'};
  position: relative;
  text-align: ${props => props.align || 'left'};
  white-space: ${props => (props.noWrap ? 'nowrap' : 'normal')};
  word-wrap: ${props => (props.breakWord ? 'break-word' : 'normal')};
  word-break: ${props => (props.breakAll ? 'break-all' : 'normal')};
  vertical-align: ${props => props.valign || 'middle'};
  line-height: ${props => props.lineHeight || 'inherit'};
  cursor: ${props => (props.onClick ? 'pointer' : 'inherit')};
  outline: 0;
  ${props =>
    props.active &&
    css`
      border-left: 4px solid ${props => props.theme.colors.goGreen};
      padding-left: calc(1rem - 4px) !important;
    `}
  tr:nth-child(odd) > & {
    background-color: ${props =>
      props.backgroundColorOdd
        ? props.theme.colors[props.backgroundColorOdd]
        : props.backgroundColor
        ? props.theme.colors[props.backgroundColor]
        : props.sorted
        ? darken(0.03, props.theme.colors.lightGrey4)
        : props.theme.colors.lightGrey4};
  }

  ${media.large`
    padding: ${props => props.padding || '1.5rem'};
  `};

  ${props =>
    props.borderBottom &&
    `
    border-bottom: 1px solid ${props.theme.colors.lightGrey5};
  `};

  ${props =>
    props.borderRight &&
    `
    border-right: 1px solid ${props.theme.colors.lightGrey5};
  `};
`;
