import { Model } from 'mobx-mc';
import { action, computed, observable } from 'mobx';

import rankCollectionByValue from '../../utils/rankCollectionByValue';
import sortTeamMeasuresByNameOrStat from './utils/sortTeamMeasuresByNameOrStat';

export default class ProjectInsightsUIModel extends Model {
  @observable isOpen;

  // Table
  @observable sortField;
  @observable rankField;
  @observable sortDirection;

  // Mobile Nav
  @observable mobileNavOpen;

  constructor(attributes, options) {
    super(attributes, options);

    this.isOpen = false;

    // Table
    this.sortField = options.sortField || 'name';
    this.rankField = options.rankField;
    this.sortDirection = 'asc';

    // Mobile Nav
    this.mobileNavOpen = false;
  }

  @computed
  get project() {
    return this.parent.project;
  }

  @action.bound
  openMobileNav() {
    this.mobileNavOpen = true;
  }

  @action.bound
  closeMobileNav() {
    this.mobileNavOpen = false;
  }

  @action.bound
  parseTeamMeasures(teamMeasures) {
    if (this.rankField) {
      this.teamMeasures.reset(
        rankCollectionByValue(teamMeasures, `stats.${this.rankField}`)
      );
    } else {
      this.teamMeasures.reset(teamMeasures || []);
    }
  }

  @action.bound
  fetchStats() {
    this.fetch({
      params: this.params
    });
  }

  @computed
  get params() {
    if (!this.project) return null;

    return {
      projectTeamIds: this.parent.filteredProjectTeamIds.join(','),
      startDay: this.parent.startDay,
      endDay: this.parent.endDay
    };
  }

  @computed
  get hasTeamMeasures() {
    return this.teamMeasures && this.teamMeasures.length > 0;
  }

  @computed
  get sortedTeamMeasures() {
    return sortTeamMeasuresByNameOrStat(
      this.teamMeasures.models,
      this.sortField,
      this.sortDirection
    );
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      if (this.sortDirection === 'asc') {
        this.sortDirection = 'desc';
      } else {
        this.sortDirection = 'asc';
      }
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }
  }

  @action.bound toggleOpen() {
    this.isOpen = !this.isOpen;
  }
}
