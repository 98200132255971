import { RakenCollection } from '../../lib';
import SuperAdminDemoMasterAccount from '../../models/superAdmin/SuperAdminDemoMasterAccount';

export default class SuperAdminDemoMasterAccounts extends RakenCollection {
  model() {
    return SuperAdminDemoMasterAccount;
  }

  url() {
    return '/ra/sadmin/demos/templates';
  }
}
