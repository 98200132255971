import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from './Button';

const getHoverBackground = props => {
  if (props.disabled && !props.solid) {
    return 'transparent';
  }

  if (props.activeColor) {
    return props.theme.colors[props.activeColor];
  }

  return props.theme.colors[props.color];
};

const getHoverBorder = props => {
  if (props.disabled) {
    return props.theme.colors[props.color];
  }

  if (props.activeColor) {
    return props.theme.colors[props.activeColor];
  }

  return props.theme.colors[props.color];
};

const getHoverColor = props => {
  if (props.disabled && !props.solid) {
    return props.theme.colors[props.color];
  }

  if (props.hoverColor) {
    return props.theme.colors[props.hoverColor];
  }

  return props.theme.colors.white;
};

const PillButton = styled(Button)`
  align-items: center;
  background-color: ${props =>
    props.solid ? props.theme.colors[props.color] : 'transparent'};
  border: 1px solid ${props => props.theme.colors[props.color]};
  border-radius: 20px;
  color: ${props =>
    props.solid ? props.theme.colors.white : props.theme.colors[props.color]};
  display: ${props => (props.block ? 'block' : 'inline-flex')};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  margin-left: ${props => (props.marginLeft ? props.marginLeft / 10 : 0)}rem;
  margin-right: ${props => (props.marginRight ? props.marginRight / 10 : 0)}rem;
  margin-bottom: ${props =>
    props.marginBottom ? props.marginBottom / 10 : 0}rem;
  margin-top: ${props => (props.marginTop ? props.marginTop / 10 : 0)}rem;
  transition: all 0.15s ease-out;
  transition-property: box-shadow, background-color;
  width: ${props => (props.block ? '100%' : 'auto')};
  white-space: nowrap;
  svg {
    fill: ${props =>
      props.solid ? props.theme.colors.white : props.theme.colors[props.color]};
    margin-right: 5px;
  }
  &:hover,
  &:focus {
    background-color: ${props => getHoverBackground(props)};
    box-shadow: ${props =>
      props.disabled
        ? 'none'
        : `0px 2px 5px 0px ${props.shadowColor || 'rgba(0, 0, 0, 0.5)'}`};
    border-color: ${props => getHoverBorder(props)};
    color: ${props => getHoverColor(props)};
    svg {
      fill: ${props => getHoverColor(props)};
    }
  }
  &:disabled {
    cursor: not-allowed;
    pointer-events: visible;
  }
`;

PillButton.propTypes = {
  color: PropTypes.string.isRequired,
  activeColor: PropTypes.string,
  hoverColor: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  size: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  wide: PropTypes.bool.isRequired
};

PillButton.defaultProps = {
  color: 'darkSteel',
  size: 'default',
  disabled: false,
  wide: false
};

export default PillButton;
