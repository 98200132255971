import styled from 'styled-components';
import InputMenuItem from './InputMenuItem';

export default styled(InputMenuItem)`
  bottom: 0;
  background-color: ${props => props.theme.colors.white};
  border-bottom-left-radius: 9px;
  color: ${props => props.theme.colors.blueAccent};
  position: sticky;
  height: ${props => props.height};
  width: 100%;
  box-sizing: border-box;
  font-weight: 400;
  z-index: 99;
  &:hover {
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.blueAccent};
    font-weight: 400;
  }
  &:before {
    content: ' ';
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
    display: block;
    height: 15px;
    position: absolute;
    left: 0;
    top: -15px;
    width: 100%;
    pointer-events: none;
  }
`;
