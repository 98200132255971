import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import { action, computed, observable } from 'mobx';
import {
  TimeEntriesTimeCardForm,
  timeEntriesTimeCardFormRules,
  timeEntriesTimeCardFormFields,
  timeEntriesTimeCardFormOptions,
  timeEntriesTimeCardFormPlugins
} from './timeEntriesTimeCard';
import trimObject from 'utils/trimObject';
import { t } from 'utils/translate';
import abbreviateNumber from 'utils/abbreviateNumber';

const timeEntriesWorkLogFormPlugins = extendedPlugins;

const timeEntriesWorkLogFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const timeEntriesWorkLogFormFields = ['name', 'workDesc'];

const timeEntriesWorkLogFormRules = {
  name: 'string|required|max:255',
  workDesc: 'string|max:10000'
};

const timeEntriesWorkLogFormLabels = {
  name: t('name'),
  workDesc: t('description')
};

class TimeEntriesWorkLogForm extends MobxReactForm {
  @observable timeCards = [];

  constructor(settings, options) {
    super(settings, options);
    this.rootStore = options.rootStore;
    this.timeEntriesWorkLogUI = options.timeEntriesWorkLogUI;
    this.currentTimeEntriesWorkLogDate = options.currentTimeEntriesWorkLogDate;
    this.setTimeCards(settings.timeCards);
  }

  @action.bound
  setTimeCards(timeCards) {
    this.timeCards.clear();

    timeCards.forEach(timeCard => {
      this.addTimeCard(timeCard);
    });
  }

  @action.bound
  addTimeCard(timeCard) {
    const timeEntriesTimeCardForm = new TimeEntriesTimeCardForm(
      {
        fields: timeEntriesTimeCardFormFields,
        rules: timeEntriesTimeCardFormRules,
        values: timeCard.formValues,
        timeEntries: timeCard.timeEntries.hasModels
          ? timeCard.timeEntries.models
          : [],
        breaks: timeCard.breaks.hasModels ? timeCard.breaks.models : [],
        timeCardStatus: timeCard.timeCardStatus,
        isSynced: timeCard.isSynced,
        isLockedForEditing: timeCard.isLockedForEditing,
        timeCardDetails: timeCard.timeCardDetails,
        manualTimeEntry: timeCard.manualTimeEntry
      },
      {
        options: timeEntriesTimeCardFormOptions,
        plugins: timeEntriesTimeCardFormPlugins,
        rootStore: this.rootStore,
        currentTimeEntriesWorkLogDate: this.currentTimeEntriesWorkLogDate,
        timeEntriesWorkLogUI: this.timeEntriesWorkLogUI,
        worker: timeCard.worker,
        attachments: timeCard.attachments
      }
    );

    this.timeCards.push(timeEntriesTimeCardForm);
  }

  @computed get workerUuids() {
    return this.timeCards.map(timeCard => {
      return timeCard.worker.workerUuid;
    });
  }

  @computed get hasKioskTimeCardsThatCannotBeSavedDueToBreakRules() {
    return Boolean(
      this.timeCards.find(timeCard => {
        return timeCard.isKioskTimeCardAndCannotBeSavedDueToBreakRules;
      })
    );
  }

  @computed
  get hasTimeCards() {
    return this.timeCards.length > 0;
  }

  @computed
  get hasOnlyOneTimeCard() {
    return this.timeCards.length === 1;
  }

  @action.bound
  removeTimeCard(timeCard) {
    this.timeCards.remove(timeCard);
  }

  @computed
  get totalHours() {
    const totalHours = this.timeCards.reduce((totalHours, timeCard) => {
      return timeCard.totalHours + totalHours;
    }, 0);

    return abbreviateNumber(totalHours);
  }

  @computed
  get trimmedValues() {
    return trimObject(this.values());
  }

  @computed
  get cleanedValues() {
    return {
      name: this.trimmedValues.name,
      workDesc: this.trimmedValues.workDesc
        ? this.trimmedValues.workDesc
        : null,
      timeCards: this.timeCards.map(timeCard => timeCard.cleanedValues)
    };
  }

  @computed
  get hasWorkers() {
    return this.timeCards.length > 0;
  }

  @computed
  get workersNumber() {
    return this.timeCards.length;
  }

  @computed
  get project() {
    return this.timeEntriesWorkLogUI.project;
  }

  validateBeforeSaving() {
    this.$('name').validate({ showErrors: true });

    if (this.project.timeCardsStartAndEndTime) {
      for (const timeCard of this.timeCards) {
        timeCard.$('startTime').validate({ showErrors: true });
        if (!timeCard.fromKioskOrTimeClock) {
          // If timeCard.fromKioskOrTimeClock is true don't validate end time
          timeCard.$('endTime').validate({ showErrors: true });
        }
      }
    }
  }

  @computed
  get workLogIsValid() {
    //workLog name and description are valid
    const workLogFormIsValid = this.isValid;

    return workLogFormIsValid && !this.hasInvalidTimeCard;
  }

  @computed
  get hasInvalidTimeCard() {
    return Boolean(
      this.timeCards.find(timeCard => {
        return !timeCard.timeCardIsValid;
      })
    );
  }

  @computed
  get hasMoreThanOneTimeCard() {
    return Boolean(this.timeCards.length > 1);
  }

  @computed
  get invalidTimeCardWorkerNames() {
    if (!this.hasInvalidTimeCard) return [];

    return this.timeCards.reduce((invalidTimeCardWorkersName, timeCard) => {
      if (!timeCard.timeCardIsValid) {
        invalidTimeCardWorkersName.push(timeCard.workerFullName);
      }
      return invalidTimeCardWorkersName;
    }, []);
  }

  @computed
  get workLogIsPristine() {
    const workLogFormIsPristine = this.isPristine;
    const timeCardsArePristine = !Boolean(
      this.timeCards.find(timeCard => !timeCard.timeCardIsPristine)
    );
    return workLogFormIsPristine && timeCardsArePristine;
  }

  @action.bound
  allocateHoursWorked() {
    this.timeCards.forEach(timeCardForm => {
      timeCardForm.hideTimeEntriesTable && timeCardForm.allocateHoursWorked();
    });
  }
}

export {
  TimeEntriesWorkLogForm,
  timeEntriesWorkLogFormRules,
  timeEntriesWorkLogFormFields,
  timeEntriesWorkLogFormLabels,
  timeEntriesWorkLogFormOptions,
  timeEntriesWorkLogFormPlugins
};
