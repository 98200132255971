import moment from 'moment';
import { action, computed } from 'mobx';
import ProjectChildAddUI from './ProjectChildAddUI';

import EquipmentLogNote from 'stores/models/EquipmentLogNote';

import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import history from 'utils/history';

import { callTrack } from 'utils/segmentIntegration';
import { EQUIPMENT_LOG_NOTE_ADDED } from 'utils/segmentAnalytics/eventSpec';

import {
  EquipmentLogNoteForm,
  equipmentLogNoteFormOptions,
  equipmentLogNoteFormFields,
  equipmentLogNoteFormLabels,
  equipmentLogNoteFormPlugins,
  equipmentLogNoteFormRules,
  equipmentLogNoteFormRelated
} from 'forms/equipmentLogNote';

export default class EquipmentLogNoteAddUI extends ProjectChildAddUI {
  @computed get parentEquipmentLog() {
    return this.parent.entryForEdit;
  }

  @action.bound setup() {
    this.entryForAdd = new EquipmentLogNote(
      {},
      {
        rootStore: this.rootStore
      }
    );

    this.entryAddForm = new EquipmentLogNoteForm(
      {
        fields: equipmentLogNoteFormFields,
        rules: Object.assign(equipmentLogNoteFormRules, {
          date: `required|dateCustom:YYYY-MM-DD|equipmentLogNoteDate:YYYY-MM-DD,${this.parentEquipmentLog.startDate}`
        }),
        labels: equipmentLogNoteFormLabels,
        values: this.entryForAdd.formValues,
        related: equipmentLogNoteFormRelated
      },
      {
        options: equipmentLogNoteFormOptions,
        plugins: equipmentLogNoteFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound async submitEntryAddFormSuccess() {
    this.saving = true;

    try {
      const values = this.entryAddForm.values();

      const attachments = this.entryForAdd.attachments.models.map(
        attachment => {
          return {
            uuid: attachment.uuid
          };
        }
      );

      await this.entryForAdd.save(
        Object.assign(values, {
          date: moment(values.date).format('YYYY-MM-DD'),
          attachments: attachments
        }),
        {
          // TODO: Super Daily 2.0 - change to new URL once available
          url: `${this.parentEquipmentLog.url()}/notes`,
          wait: true
        }
      );

      // Manually increment the note count field
      this.parentEquipmentLog.noteCount++;

      callTrack(EQUIPMENT_LOG_NOTE_ADDED, {
        hours_entered: values.hours,
        note_entered: values.description
      });

      this.parent.sortByLastCreated();
      this.cancelEquipmentLogNoteAdd();

      // Ensure any unsaved changed on the parent equipment log
      // form are prompted.
      this.parent.parent.blockHistoryIfFormChanged();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Note created')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound cancelEquipmentLogNoteAdd() {
    history.push({
      pathname: `${this.project.viewUrl}/equipment/${this.parentEquipmentLog.uuid}`,
      search: this.baseQueryParams
    });
  }
}
