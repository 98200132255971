import orderBy from 'lodash.orderby';
import UIStore from 'stores/ui/UIStore';
import { action, computed, observable } from 'mobx';
import Overlaps from 'stores/collections/superAdmin/Overlaps';
import Pings from 'stores/collections/superAdmin/Pings';
import alertErrorHandler from 'utils/alertErrorHandler';

export default class SuperAdminUserMobileActivityOverlapsUI extends UIStore {
  @observable loading;
  @observable sortField;
  @observable sortDirection;

  @observable selectedOverlap;

  constructor(options) {
    super(options);

    this.loading = true;
    this.sortField = 'timestamp';
    this.sortDirection = 'desc';

    this.selectedOverlap = null;

    this.overlaps = new Overlaps(null, {
      rootStore: this
    });

    this.pings = new Pings(null, {
      rootStore: this
    });
  }

  @computed
  get activeUser() {
    return this.parent.activeUser;
  }

  @computed get hasOverlaps() {
    return this.overlaps.hasModels;
  }

  @computed get hasPings() {
    return this.pings.hasModels;
  }

  @action.bound setup() {
    this.fetchOverlaps();
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.overlaps.clear();
    this.pings.clear();

    this.loading = true;
    this.sortField = 'type';
    this.sortDirection = 'desc';

    this.selectedOverlap = null;
  }

  @action.bound
  async fetchOverlaps() {
    this.overlaps.cancelRequest();
    this.overlaps.clear();

    try {
      await this.overlaps.fetch({
        params: {
          limit: 10000
        }
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }
  }

  @computed
  get sortedOverlaps() {
    return orderBy(
      this.overlaps.models,
      [this.sortField],
      [this.sortDirection]
    );
  }

  @computed
  get sortedPings() {
    return orderBy(this.pings.models, [this.sortField], [this.sortDirection]);
  }

  @computed get showEmptyState() {
    return !this.loading && !this.hasOverlaps;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @action.bound showOverlapModal(overlap) {
    this.selectedOverlap = overlap;

    this.pings.fetch({
      parmams: {
        timestamp: overlap.timestamp
      }
    });

    this.showModal('SuperAdminUserMobileActivityOverlapModal');
  }

  @action.bound async hideOverlapModal() {
    await this.hideActiveModal();

    this.selectedOverlap = null;
  }
}
