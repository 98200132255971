import styled, { css } from 'styled-components';
import media from '../utils/mediaTemplate';
import { PillButton } from '.';

export default styled.div`
  display: block;
  position: relative;
  ${media.desktop`
    display: flex;
  `};
  ${props =>
    props.withButtons &&
    css`
      align-items: center;
      display: flex;
      justify-content: center;
      ${PillButton}:not(:first-child) {
        margin-left: 10px;
      }
      ${media.desktop`
      justify-content: flex-end;
      ${PillButton}:not(:first-child) {
        margin-left: 25px;
      }
    `};
    `};
`;
