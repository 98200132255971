import React from 'react';

import CircleButton from './CircleButton';

export default props => {
  return (
    <CircleButton
      size="small"
      color="white"
      hoverColor={props.hoverColor ? props.hoverColor : 'lightGrey1'}
      {...props}
    />
  );
};
