import { computed } from 'mobx';
import { RakenCollection } from 'stores/lib';
import QuickbooksCustomer from 'stores/models/integrations/QuickbooksCustomer';

export default class QuickbooksCustomers extends RakenCollection {
  url() {
    return `${this.rootStore.urlMicroService('integrations')}/companies/${
      this.company.uuid
    }/integrations/1019/customer`;
  }

  model() {
    return QuickbooksCustomer;
  }

  @computed get company() {
    if (this.rootStore.isSuperAdmin) {
      return this.parent.activeCompany;
    }

    return this.rootStore.me.company;
  }
}
