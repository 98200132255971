import IncidentConstant from 'stores/models/incidents/incidentConstant';
import { RakenCollection } from '../../lib';

export default class IncidentConstants extends RakenCollection {
  model() {
    return IncidentConstant;
  }

  url() {
    return `${this.rootStore.urlMicroService('toolbox')}/companies/${
      this.rootStore.me.company.uuid
    }/incident-reports/constants`;
  }
}
