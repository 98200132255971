import MobxReactForm from 'mobx-react-form';
import validatorjs from 'validatorjs';
import { t } from 'utils/translate';

const appConnectFormPlugins = { dvr: validatorjs };

const appConnectFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const appConnectFormFields = ['companyName', 'username', 'password'];

const appConnectFormRules = {
  companyName: 'required|string',
  username: 'required|string',
  password: 'required|string'
};

const appConnectFormLabels = {
  companyName: t('Company Name'),
  username: t('Username'),
  password: t('Password')
};

class AppConnectForm extends MobxReactForm {}

export {
  AppConnectForm,
  appConnectFormOptions,
  appConnectFormFields,
  appConnectFormRules,
  appConnectFormLabels,
  appConnectFormPlugins
};
