import request from 'axios';
import moment from 'moment';
import { action, observable, computed, reaction } from 'mobx';
import ProjectChildUI from './ProjectChildUI';
import alertErrorHandler from 'utils/alertErrorHandler';

export default class CalendarUI extends ProjectChildUI {
  @observable firstDayOfMonth;
  @observable fetchingReports;
  @observable open;

  constructor(options) {
    super(options);
    this.fetchingReports = false;
    this.reports = observable([]);
    this.open = false;
  }

  @action.bound setup() {
    this.setupReactions();
  }

  @action.bound tearDown() {
    this.reports.clear();
    this.tearDownReactions();
    this.open = false;
  }

  setupReactions() {
    this.reactToOpen = reaction(
      () => this.open,
      open => {
        if (this.open) {
          this.handleMonthChange(moment(this.date).startOf('month'));
        }
      }
    );
  }

  tearDownReactions() {
    this.reactToOpen && this.reactToOpen();
  }

  @action.bound setOpen(value) {
    this.open = value;
  }

  @action.bound navigateToDate(date) {
    this.parent.navigateToDate(date);
    this.open = false;
  }

  getCalendarReportStatus = report => {
    switch (report.status) {
      case 'COMPLETED':
        return 'signed';
      case 'UNSIGNED':
      case 'CREATED':
        return 'unsigned';
      default:
        return '';
    }
  };

  getCalendarDayStatus = momentObject => {
    if (momentObject.isSameOrAfter(moment(), 'day')) {
      return '';
    }

    if (momentObject.isBefore(moment(this.project.startDate), 'day')) {
      return '';
    }

    if (this.project.intervals.includes(momentObject.day() + 1)) {
      return 'missed';
    } else {
      return '';
    }
  };

  @action.bound async handleMonthChange(firstDayOfMonth) {
    this.firstDayOfMonth = firstDayOfMonth;
    this.fetchingReports = true;

    try {
      const response = await request.get(
        `ra/companies/${this.company.uuid}/reports`,
        {
          params: {
            fromDate: this.fromDate,
            toDate: this.toDate,
            segmentUuids: this.segmentUuid,
            projectUuids: this.projectUuid,
            limit: 31
          }
        }
      );

      this.reports.replace(
        response.data.collection.map(report => {
          return {
            reportDate: report.reportDate,
            status: report.status
          };
        })
      );
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.fetchingReports = false;
    }

    return true;
  }

  @computed get fromDate() {
    return moment(this.firstDayOfMonth)
      .startOf('month')
      .format('YYYY-MM-DD');
  }

  @computed get toDate() {
    return moment(this.firstDayOfMonth)
      .endOf('month')
      .format('YYYY-MM-DD');
  }

  @computed get monthReports() {
    const days = [];

    if (this.fetchingReports || this.project.hasSegmentedReports) return days;

    const currentDate = moment(this.fromDate);
    const toDate = moment(this.toDate);

    while (currentDate.isSameOrBefore(toDate)) {
      const relatedReport = this.reports.find(
        report => report.reportDate === currentDate.format('YYYY-MM-DD')
      );

      if (!currentDate.isSame(moment(), 'day')) {
        if (relatedReport) {
          days.push({
            date: currentDate.date(),
            status: this.getCalendarReportStatus(relatedReport)
          });
        } else {
          days.push({
            date: currentDate.date(),
            status: this.getCalendarDayStatus(currentDate)
          });
        }
      }

      currentDate.add(1, 'days');
    }

    return days;
  }
}
