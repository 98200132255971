import { Model } from 'mobx-mc';
import { action, observable, computed } from 'mobx';
import omit from 'lodash.omit';
import request from 'axios';
import orderBy from 'lodash.orderby';

import Timesheets from '../collections/Timesheets';

export default class TimesheetsSummary extends Model {
  @observable timesheets;
  @observable summaryUpdating;

  constructor(attributes, options) {
    super(attributes, options);

    this.summaryUpdating = false;
  }

  get restAttributes() {
    return [
      'dateRange',
      'totalHours',
      'regularTime',
      'overTime',
      'doubleTime',
      'approvedHours',
      'noOfUnsignedTimeSheets',
      'noOfInflightTimeCards',
      'noOfAbandonedTimeCards',
      'page',
      'periodType',
      'signOffDisclaimer'
    ];
  }

  url() {
    return `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/timesheets`;
  }

  @action.bound
  parse(attributes) {
    this.parseTimesheets(attributes);
    return {
      ...omit(attributes, ['timeSheets'])
    };
  }

  @action.bound
  parseTimesheets(attributes) {
    this.timesheets = new Timesheets(attributes.timeSheets, {
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound updateOnlySummary(params) {
    this.summaryUpdating = true;

    request.post(this.url(), params).then(response => {
      this.set(response.data, {
        parse: false
      });

      this.summaryUpdating = false;
    });
  }

  @computed
  get orderedPayTypes() {
    return orderBy(this.totalHours?.payTypeHours, ['hours'], ['desc']);
  }
}
