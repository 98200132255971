import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';
import { t } from 'utils/translate';

const couponFormPlugins = extendedPlugins;

const couponFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const couponFormFields = ['coupon', 'coupon.id', 'coupon.name', 'coupon.code'];

const couponFormRules = {
  'coupon.id': 'required'
};

const couponFormLabels = {
  'coupon.id': t('coupon')
};

class CouponForm extends MobxReactForm {}

export const couponFormFactory = async values =>
  await new CouponForm(
    {
      fields: couponFormFields,
      rules: couponFormRules,
      values: values,
      labels: couponFormLabels
    },
    {
      options: couponFormOptions,
      plugins: couponFormPlugins
    }
  );
