import request from 'axios';
import { action, runInAction, observable, computed } from 'mobx';
import UIStore from './UIStore';
import pickBy from 'lodash.pickby';

import { t } from 'utils/translate';

import {
  CERTIFICATION_REPORT_DOWNLOADED,
  CERTIFICATION_REPORT_SCHEDULED,
  CERTIFICATION_REPORT_STARTED
} from 'utils/segmentAnalytics/eventSpec';

import { callTrack } from 'utils/segmentIntegration';
import errorHandler from 'utils/errorHandler';
import alertErrorHandler from 'utils/alertErrorHandler';

import CertificationsReport from '../models/CertificationsReport';
import CertificationTypes from 'stores/collections/CertificationTypes';

import {
  CertificationsReportForm,
  certificationsReportFormOptions,
  certificationsReportFormFields,
  certificationsReportFormRules,
  certificationsReportFormLabels,
  certificationsReportFormValues,
  certificationsReportFormPlugins
} from 'forms/certificationsReport';

export default class CertificationsReportsUI extends UIStore {
  @observable activeReportTab;
  @observable scheduledReportToEdit;
  @observable certificationTypeIncludeOption;

  constructor(options) {
    super(options);

    this.certificationTypeIncludeOption = 'ALL';
    this.activeReportTab = 'runOnce';
    this.scheduledReportToEdit = null;
    this.certificationTypes = new CertificationTypes(null, {
      rootStore: this.rootStore
    });
  }

  @action.bound showCertificationsReportModal() {
    this.rootStore.authorizationUI
      .checkFeatureAccess('ScheduleTimeCardReports', true)
      .then(() => {
        this.showModal('certificationsReport');
        callTrack(CERTIFICATION_REPORT_STARTED);
        this.fetchScheduled();
        this.fetchCertificationTypes();
        this.setupModalForm();
      });
  }

  @action.bound
  fetchScheduled() {
    this.certificationsReports.fetch();
  }

  @action.bound
  fetchCertificationTypes() {
    this.certificationTypes.fetch();
  }

  @computed
  get certificationTypeIncludeOptions() {
    return [
      {
        label: t('All'),
        value: 'ALL',
        dataQA: 'all-certification-types'
      },
      {
        label: t('Selected'),
        value: 'SELECTED',
        dataQA: 'selected-certification-types'
      }
    ];
  }

  @action.bound
  setCertificationTypeIncludeOption(option) {
    this.certificationTypeIncludeOption = option;
    if (this.certificationTypeIncludeOption === 'ALL') {
      this.setCertificationTypes(undefined, []);
    }
  }

  @computed
  get certificationTypesOptions() {
    return this.certificationTypes.models.map(certificationType => {
      return {
        title: certificationType.name,
        id: certificationType.uuid
      };
    });
  }

  @computed get selectedCertificationTypes() {
    return this.certificationTypesOptions.filter(option => {
      return this.activeForm
        .$('certificationTypeUuids')
        .values()
        .includes(option.id);
    });
  }

  @action.bound setCertificationTypes(event, values) {
    this.activeForm.update({
      certificationTypeUuids: values.map(value => value.id)
    });

    this.activeForm.validate();
  }

  @computed
  get projectDefaultValue() {
    if (this.scheduledReportToEdit?.projects.length) {
      return this.scheduledReportToEdit.projects.map(project => {
        return {
          value: project.uuid,
          name: project.name
        };
      });
    }

    if (this.project) {
      return [
        {
          value: this.project.uuid,
          name: this.project.name,
          startDate: this.project.startDate,
          endDate: this.project.endDate
        }
      ];
    }

    return [];
  }

  @action.bound
  setupModalForm() {
    this.initReportForm();
    if (this.project) {
      this.setProjectIncludeOption('SELECTED');
      this.selectProjects(this.projectDefaultValue);
    } else {
      this.setProjectIncludeOption('ACTIVE');
    }
  }

  @action.bound
  initReportForm() {
    const rules =
      this.activeReportTab === 'scheduled'
        ? Object.assign({}, certificationsReportFormRules, {
            name: 'required|string|max:255'
          })
        : certificationsReportFormRules;

    const values =
      this.activeReportTab === 'scheduled' && this.scheduledReportToEdit
        ? Object.assign(
            {},
            certificationsReportFormValues,
            this.scheduledReportToEdit.formValues
          )
        : certificationsReportFormValues;

    this.activeForm = new CertificationsReportForm(
      {
        fields: certificationsReportFormFields,
        rules: rules,
        values: values,
        labels: certificationsReportFormLabels
      },
      {
        options: certificationsReportFormOptions,
        plugins: certificationsReportFormPlugins,
        reportType: this.activeReportTab
      }
    );
  }

  @action.bound
  hideCertificationsReportModal() {
    this.hideActiveModal().then(() => {
      runInAction(() => {
        this.activeForm = null;
        this.activeReportTab = 'runOnce';
        this.scheduledReportToEdit = null;
        this.saving = false;
        this.certificationTypeIncludeOption = 'ALL';
      });
    });
  }

  @action.bound
  switchTab(e, tab) {
    this.activeForm = null;
    this.scheduledReportToEdit = null;
    this.activeReportTab = tab;
    this.setupModalForm();
  }

  @computed
  get scheduledReportNewOrSaveButtonText() {
    if (this.saving) return t('Saving...');

    return this.scheduledReportToEdit
      ? this.scheduledReportToEdit?.isNew
        ? t('Schedule report')
        : t('Save changes')
      : t('New report');
  }

  @computed get certificationsReports() {
    return this.rootStore.certificationsReports;
  }

  @computed
  get hasNoScheduledReports() {
    return (
      !this.fetchingScheduledReports &&
      !this.certificationsReports.fetching &&
      !this.certificationsReports.hasModels
    );
  }

  @action.bound
  selectProjects(selectedOptions) {
    this.activeForm.update({
      projects: selectedOptions.map(option => {
        return {
          uuid: option.value,
          name: option.name
        };
      })
    });

    this.activeForm.validate();
  }

  @computed
  get projectIncludeOptions() {
    return [
      {
        label: t('Active projects'),
        value: 'ACTIVE',
        dataQA: 'active-projects'
      },
      {
        label: t('Active and inactive projects'),
        value: 'ACTIVE_AND_INACTIVE',
        dataQA: 'active-and-inactive-projects'
      },
      {
        label: t('Selected'),
        value: 'SELECTED',
        dataQA: 'selected-projects'
      }
    ];
  }

  @action.bound
  setProjectIncludeOption(value) {
    if (value === 'ACTIVE' || value === 'ACTIVE_AND_INACTIVE') {
      this.activeForm.update({
        projects: []
      });
    }

    this.activeForm.$('projectIncludeOption').set('value', value);

    this.activeForm.validate();
  }

  @computed get timeFrames() {
    return [
      {
        id: 'EXPIRING_CURRENT_MONTH',
        title: t('Expiring in Current Month')
      },
      {
        id: 'ALL',
        title: t('All Certifications')
      }
    ];
  }

  @computed get scheduleFrequencies() {
    return [
      {
        id: 'WEEKLY',
        title: t('Weekly')
      },
      {
        id: 'MONTHLY',
        title: t('Monthly')
      }
    ];
  }

  @computed get selectedTimeFrameOption() {
    return this.timeFrames.find(
      timeFrame => timeFrame.id === this.activeForm.$('timeFrameType').value
    );
  }

  @computed get selectedScheduleFrequencyOption() {
    return this.scheduleFrequencies.find(
      scheduleFrequency =>
        scheduleFrequency.id === this.activeForm.$('scheduleFrequency').value
    );
  }

  @action.bound
  setScheduleFrequency(scheduleFrequency) {
    this.activeForm.update({
      scheduleFrequency: scheduleFrequency.id
    });
  }

  @action.bound
  setTimeFrame(timeFrame) {
    this.activeForm.update({
      timeFrameType: timeFrame.id
    });
  }

  @action.bound
  submitCertificationsReportForm(event) {
    event.preventDefault();

    if (this.activeReportTab === 'scheduled' && !this.scheduledReportToEdit) {
      this.createNewScheduledReport();
      return;
    }

    if (this.saving) return;

    this.clearValidationDetails();

    this.activeForm.submit({
      onSuccess: this.submitCertificationsReportFormSuccess,
      onError: this.submitCertificationsReportFormError
    });
  }

  @action.bound submitCertificationsReportFormSuccess() {
    if (this.activeReportTab === 'runOnce') {
      this.submitCertificationsRunOnceReport();
    } else {
      this.submitCertificationsScheduledReport();
    }
  }

  @action.bound submitCertificationsReportFormError() {
    console.error(this.activeForm.errors());
  }

  @action.bound async submitCertificationsRunOnceReport() {
    this.saving = true;

    const values = this.activeForm.values();

    const cleanedValues = pickBy(
      values,
      value => value !== null && (value !== undefined) & (value !== '')
    );

    cleanedValues.projects = cleanedValues.projects.map(project => {
      return {
        uuid: project.uuid,
        name: project.name
      };
    });

    try {
      await request.post(`/ra/certification-reports/run-once`, cleanedValues);

      callTrack(CERTIFICATION_REPORT_DOWNLOADED);

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Your download request has been sent.')
      });

      this.hideCertificationsReportModal();
    } catch (error) {
      this.saving = false;
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound async submitCertificationsScheduledReport() {
    this.saving = true;

    const values = this.activeForm.values();

    values.projects = values.projects.map(project => {
      return {
        uuid: project.uuid,
        name: project.name
      };
    });

    const cleanedValues = pickBy(
      values,
      value => value !== null && (value !== undefined) & (value !== '')
    );

    const method = this.scheduledReportToEdit.isNew ? 'post' : 'put';

    const notificationMessage = this.scheduledReportToEdit.isNew
      ? t('Scheduled report created.')
      : t('Scheduled report updated.');

    try {
      await this.scheduledReportToEdit.save(cleanedValues, {
        method,
        wait: true
      });

      callTrack(CERTIFICATION_REPORT_SCHEDULED, {
        frequency: values.timeFrameType
      });

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: notificationMessage
      });

      this.fetchScheduled();
      this.cancelEditingReport();
    } catch (error) {
      this.saving = false;
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound
  createNewScheduledReport() {
    // Use a new model to hold our report when creating a new one.
    this.scheduledReportToEdit = new CertificationsReport(
      {},
      {
        rootStore: this.rootStore
      }
    );

    this.setupModalForm();
  }

  @action.bound
  cancelEditingReport() {
    this.activeForm = null;
    this.scheduledReportToEdit = null;
    this.saving = false;
  }

  @computed get scheduleText() {
    switch (this.activeForm.$('scheduleFrequency').value) {
      case 'MONTHLY':
        return t('This report will be sent on the first day of each month.');
      case 'WEEKLY':
        return t('This report will be sent each Monday.');
      default:
        return t('This report will be sent on the first day of each month.');
    }
  }

  @computed
  get enableSubmit() {
    if (!this.scheduledReportToEdit && this.activeReportTab === 'scheduled') {
      return true;
    }

    if (!this.activeForm || this.activeForm.isPristine || this.saving) {
      return false;
    }

    return this.activeForm.isValid;
  }

  @action.bound
  editScheduledReport(report) {
    this.scheduledReportToEdit = report;

    this.initReportForm();
  }

  @action.bound
  async deleteScheduledReport(report) {
    try {
      await report.destroy({ wait: true });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  @action.bound
  handleScheduledReportCancelButton() {
    this.scheduledReportToEdit
      ? this.cancelEditingReport()
      : this.hideCertificationsReportModal();
  }
}
