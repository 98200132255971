import { Model } from 'mobx-mc';

export default class MapProject extends Model {
  get restAttributes() {
    return [
      'startDate',
      'endDate',
      'createdTimestamp',
      'updatedTimestamp',
      'projectState',
      'projectNo',
      'name',
      'address',
      'uuid',
      'projectLogo',
      'kioskEnabled'
    ];
  }

  idAttribute() {
    return 'uuid';
  }
}
