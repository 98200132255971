import { css } from 'styled-components';

const getInputBorder = (props, isFocused) => {
  if (props.hasError) {
    return `2px solid ${props.theme.colors[props.errorColor]}`;
  }

  if (props.highlight) {
    return `2px solid ${props.theme.colors.goGreen}`;
  }

  if (props.isValid) {
    if (props.focusColor && isFocused) {
      return `2px solid ${props.theme.colors[props.focusColor]}`;
    } else {
      return `1px solid ${props.theme.colors.lightGrey5}`;
    }
  }

  if (props.disabled) {
    return `1px solid ${props.theme.colors.lightGrey5}`;
  }

  if (isFocused) {
    return `2px solid ${props.theme.colors[props.focusColor || 'blueAccent']}`;
  } else {
    return `1px solid ${props.theme.colors.lightGrey5}`;
  }
};

const getInputPadding = (props, isFocused) => {
  let paddingRight = 9;

  if (props.showValidIcon || props.showValidIcon || props.showSelectArrow) {
    paddingRight = 28;
  }

  if (props.hasError) {
    return `9px ${paddingRight}px 9px 9px`;
  }

  if (props.highlight) {
    return `9px`;
  }

  if (props.isValid) {
    if (props.focusColor && isFocused) {
      return `9px ${paddingRight}px 9px 9px`;
    }

    return `10px ${paddingRight + 1}px 10px 10px`;
  }

  if (isFocused) {
    return `9px ${paddingRight}px 9px 9px`;
  } else {
    return `10px ${paddingRight + 1}px 10px 10px`;
  }
};

const getBorderRadius = props => {
  if (props.withAddOn) {
    return '4px 0 0 4px';
  }

  if (props.isAddOn) {
    return '0 4px 4px 0';
  }

  return '4px';
};

const getWidth = props => {
  if (props.width) {
    return `${props.width / 10}rem`;
  }

  if (props.withAddOn) {
    return 'calc(100% - 40px)';
  }

  return '100%';
};

const focusCss = css`
  border: ${props => getInputBorder(props, true)};
  outline: 0;
  padding: ${props => getInputPadding(props, true)};
  font-weight: ${props => (props.bold ? 700 : 400)};
`;

const placeholderCss = css`
  color: ${props => props.theme.colors.lightGrey1};
  font-weight: 300;
  opacity: ${props => (props.disabled || props.readOnly ? 0.5 : 1)};
  ${props =>
    props.capitalizePlaceholder &&
    css`
      text-transform: capitalize;
    `}
`;

const inputCss = css`
  background-color: ${props =>
    props.disabled || props.readOnly
      ? props.theme.colors.cream
      : props.theme.colors.white};
  background-clip: padding-box;
  border: ${props => getInputBorder(props)};
  border-radius: ${props => getBorderRadius(props)};
  box-sizing: border-box;
  color: ${props => props.theme.colors.charcoalGrey};
  font-family: ${props => props.theme.fonts.primary};
  display: block;
  font-size: ${props => (props.fontSize ? `${props.fontSize}px` : '1.4rem')};
  font-weight: ${props => (props.bold ? 700 : 'inherit')};
  min-height: ${props => (props.height ? props.height : 40)}px;
  height: ${props => (props.height ? props.height : 40)}px;
  margin: 0;
  outline: 0;
  padding: ${props => getInputPadding(props)};
  resize: none;
  width: ${props => getWidth(props)};
  ${props =>
    props.ellipsis &&
    css`
      text-overflow: ellipsis;
    `}

  &::placeholder {
    ${placeholderCss};
  }
  &:focus {
    ${focusCss};
  }
  &:disabled {
    cursor: not-allowed;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin-right: -8px;
  }
  ${props =>
    props.hideControls &&
    css`
      -moz-appearance: textfield;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    `};
`;

const phoneInputCss = css`
  .react-tel-input .invalid-number {
    background-color: ${props => props.theme.colors.white} !important;
    border: 2px solid ${props => props.theme.colors.red} !important;
    + .flag-dropdown {
      border: 2px solid ${props => props.theme.colors.red} !important;
    }
    &::placeholder {
      color: ${props => props.theme.colors.lightGrey1};
      font-weight: 300;
      opacity: ${props => (props.disabled || props.readOnly ? 0.5 : 1)};
    }
  }

  ${props =>
    props.focusColor &&
    !props.hasError &&
    css`
      .react-tel-input .form-control:focus {
        border: 2px solid ${props => props.theme.colors[props.focusColor]} !important;
        + .flag-dropdown {
          border: 2px solid ${props => props.theme.colors[props.focusColor]} !important;
        }
      }
    `};

  ${props =>
    props.hasError &&
    css`
      .react-tel-input .form-control {
        border: 2px solid ${props => props.theme.colors[props.errorColor]} !important;
        + .flag-dropdown {
          border: 2px solid ${props => props.theme.colors[props.errorColor]} !important;
        }
      }
    `};
`;

export { getInputBorder, getInputPadding, inputCss, phoneInputCss };
