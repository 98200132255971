import PropTypes from 'prop-types';
import styled from 'styled-components';

const Align = styled.div`
  align-items: inherit;
  display: flex;
  margin-left: ${props => (props.right ? 'auto' : 0)};
  margin-right: ${props => (props.left ? 'auto' : 0)};
  flex-direction: ${props => (props.vertical ? 'column' : 'row')};
`;

Align.propTypes = {
  left: PropTypes.bool,
  right: PropTypes.bool,
  vertical: PropTypes.bool
};

Align.defaultProps = {
  left: false,
  right: false,
  vertical: false
};

export default Align;
