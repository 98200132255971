import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import { action, computed } from 'mobx';
import arrayMove from 'utils/arrayMove';

const checklistTemplateQuestionChoicesFormPlugins = extendedPlugins;

const checklistTemplateQuestionChoicesFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const checklistTemplateQuestionChoicesFormFields = [
  'choices[]',
  'choices[].uuid',
  'choices[].description',
  'choices[].position',
  'allowMultiple'
];

const checklistTemplateQuestionChoicesFormRules = {
  'choices[].description': 'string|max:150'
};

class ChecklistTemplateQuestionChoicesForm extends MobxReactForm {
  constructor(settings, options) {
    super(settings, options);
    this.rootStore = options.rootStore;
  }

  @computed
  get numberOfChoices() {
    return this.$('choices').values().length;
  }

  @computed
  get hasChoices() {
    return this.numberOfChoices > 1;
  }

  @computed
  get hasOnlyOneChoice() {
    return this.numberOfChoices === 1;
  }

  @computed
  get numberOfEmptyChoices() {
    return this.$('choices')
      .values()
      .filter(choice => choice.description === '').length;
  }

  @computed
  get hasOnlyEmptyChoices() {
    return this.numberOfChoices === this.numberOfEmptyChoices;
  }

  @computed
  get responseLimitReached() {
    return this.numberOfChoices > 49;
  }

  @action.bound
  addDefaultChoice() {
    this.$('choices').add({
      description: '',
      position: this.numberOfChoices + 1
    });
  }

  @action.bound
  moveQuestionChoice(oldIndex, newIndex) {
    const choices = arrayMove(this.$('choices').values(), oldIndex, newIndex);

    choices.forEach((choice, index) => (choice.position = index + 1));

    this.update({
      choices
    });
  }

  @action.bound
  removeChoice(key) {
    this.$('choices').del(key);

    //update positions
    const choices = this.$('choices').values();
    choices.forEach((choice, index) => (choice.position = index + 1));

    this.update({
      choices
    });
  }

  @computed
  get disableSaving() {
    return this.hasErrors || this.hasOnlyEmptyChoices;
  }
}

export {
  ChecklistTemplateQuestionChoicesForm,
  checklistTemplateQuestionChoicesFormPlugins,
  checklistTemplateQuestionChoicesFormOptions,
  checklistTemplateQuestionChoicesFormFields,
  checklistTemplateQuestionChoicesFormRules
};
