export default {
  BOX: 1000,
  DROPBOX: 1001,
  GDRIVE: 1002,
  EGNYTE: 1003,
  PROCORE: 1004,
  ONE_DRIVE: 1006,
  BIM_360: 1007,
  FOUNDATION: 1008,
  POINTS_NORTH: 1012,
  COMPUTER_EASE: 1013,
  INEIGHT: 1014,
  RYVIT_VISTA: 1015,
  RYVIT_SAGE_300: 1016,
  RYVIT_SAGE_100: 1017,
  RYVIT_VIEWPOINT_SPECTRUM: 1018,
  QUICKBOOKS_ONLINE: 1019,
  EARTHCAM: 1020,
  HOLO_BUILDER: 1021,
  JD_EDWARDS: 1022,
  DRONE_DEPLOY: 1023,
  SAGE_INTACCT: 1024,
  PAYCHEX: 1025
};
