import { observable, action, computed, runInAction } from 'mobx';
import UIStore from './UIStore';
import orderBy from 'lodash.orderby';
import { t } from 'utils/translate';
import errorHandler from 'utils/errorHandler';

import {
  ShiftForm,
  shiftFormOptions,
  shiftFormFields,
  shiftFormRules,
  shiftFormLabels,
  shiftFormPlugins
} from 'forms/shift';

export default class CompanyShiftsUI extends UIStore {
  @observable entryForEdit;

  @observable uploadProgress;
  @observable mode;

  @observable searchQuery;
  @observable sortDirection;
  @observable pageSize;
  @observable page;

  constructor(options) {
    super(options);

    this.companyProductionUI = options.companyProductionUI;

    // Selecting
    this.selectedShifts = observable([]);

    // Searching
    this.searchQuery = '';

    // Sorting
    this.sortDirection = 'asc';

    // Editing
    this.entryForEdit = null;

    // Pagination
    this.pageSize = 20;
    this.page = 0;

    /// Instantiate the create shift form
    this.shiftForm = new ShiftForm(
      {
        fields: shiftFormFields,
        rules: shiftFormRules,
        labels: shiftFormLabels
      },
      {
        options: shiftFormOptions,
        plugins: shiftFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound clearUIState() {
    this.entryForEdit = null;
    this.searchQuery = '';
    this.selectedShifts.clear();
    this.rootStore.shifts.clearNew();

    this.page = 0;
  }

  @action.bound
  async fetchShifts() {
    try {
      await this.rootStore.shifts.fetch({
        params: {
          limit: 10000
        },
        add: true,
        remove: false,
        update: true
      });

      this.rootStore.companyShiftsFetched = true;
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  @computed get shifts() {
    return this.rootStore.shifts;
  }

  @computed
  get hasShifts() {
    return this.shifts.hasModels;
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 0;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 0;
  }

  @computed get sortedShifts() {
    return orderBy(
      this.shifts.models,
      [shift => shift.new, shift => shift.name.toLowerCase()],
      ['desc', this.sortDirection]
    );
  }

  @computed get searchedShifts() {
    if (!this.searchQuery) {
      return this.sortedShifts;
    }

    const query = this.searchQuery.toLowerCase();

    return this.sortedShifts.filter(shift => {
      return (
        shift.name.toLowerCase().indexOf(query) > -1 ||
        shift.code.toLowerCase().indexOf(query) > -1
      );
    });
  }

  @computed get hasSearchedShifts() {
    return this.searchedShifts.length > 0;
  }

  @computed get paginatedShifts() {
    return this.searchedShifts.slice(
      this.page * this.pageSize,
      this.page * this.pageSize + this.pageSize
    );
  }

  @action.bound
  sortByColumn() {
    // Clear New Item at the top of list on sort.
    this.shifts.clearNew();

    if (this.sortDirection === 'asc') {
      this.sortDirection = 'desc';
    } else {
      this.sortDirection = 'asc';
    }
  }

  @computed
  get totalPages() {
    return Math.ceil(this.searchedShifts.length / this.pageSize);
  }

  @action.bound
  setPage(page) {
    this.page = page.selected;
  }

  @computed
  get shiftFormIsInValid() {
    if (!this.shiftForm) return true;

    return Boolean(
      this.shiftForm.check('hasError') ||
        this.shiftForm.$('name').check('isPristine') ||
        this.shiftForm.$('code').check('isPristine')
    );
  }

  @action.bound async saveNewShift(values) {
    this.shifts
      .create({
        name: values.name,
        code: values.code
      })
      .then(shift => {
        runInAction(() => {
          this.showShiftCreated(shift);
        });
      })
      .catch(error => {
        errorHandler(error, this.notifications.pushError);
      });
  }

  @action.bound
  showShiftCreated(shift) {
    shift.setAsNew();

    this.rootStore.notificationsUI.pushNotification({
      showUndo: false,
      title: t('Shift Created')
    });

    this.setPage({
      selected: 0
    });
  }

  @computed get hasSelectedShifts() {
    return this.selectedShifts.length > 0;
  }

  @action.bound
  toggleSelectShift(shift) {
    if (this.selectedShifts.find(id => id === shift.id)) {
      this.selectedShifts.remove(shift.id);
    } else {
      this.selectedShifts.push(shift.id);
    }
  }

  @computed
  get allShiftsSelected() {
    return (
      this.hasShifts && this.selectedShifts.length === this.shifts.models.length
    );
  }

  @action.bound
  toggleSelectAllShifts() {
    if (this.allShiftsSelected) {
      this.selectedShifts.clear();
    } else {
      this.selectedShifts.replace(this.searchedShifts.map(shift => shift.id));
    }
  }

  @action.bound softDeleteShift(shift) {
    const originalIndex = shift.collection.models.indexOf(shift);

    this.cancelEntryEdit();

    shift.collection.remove(shift);

    this.rootStore.notificationsUI.pushNotification({
      onUndo: () => {
        this.cancelDeleteShift(shift, originalIndex);
      },
      onDismiss: () => {
        this.confirmDeleteShift(shift);
      },
      title: t('Shift Deleted')
    });
  }

  @action.bound
  cancelDeleteShift(shift, index) {
    this.rootStore.shifts.add(shift, {
      at: index
    });
  }

  @action.bound
  confirmDeleteShift(shift) {
    shift.destroy();
  }

  @action.bound
  deleteSelectedShifts() {
    this.cancelEntryEdit();

    const shifts = this.shifts.models.filter(shift =>
      this.selectedShifts.includes(shift.id)
    );

    this.softDeleteShifts(shifts);
    this.selectedShifts.clear();
  }

  @action.bound softDeleteShifts(shifts) {
    this.shifts.remove(shifts);

    this.rootStore.notificationsUI.pushNotification({
      onUndo: () => {
        this.cancelDeleteShifts(shifts);
      },
      onDismiss: () => {
        this.confirmDeleteShifts(shifts);
      },
      title: t('Shifts Deleted')
    });
  }

  @action.bound
  cancelDeleteShifts(shifts) {
    this.rootStore.shifts.add(shifts);
  }

  @action.bound
  confirmDeleteShifts(shifts) {
    shifts.forEach(shift => {
      shift.destroy();
    });
  }

  @action.bound setEntryForEdit(shift) {
    this.entryForEdit = shift;

    this.entryEditForm = new ShiftForm(
      {
        fields: shiftFormFields,
        rules: shiftFormRules,
        labels: shiftFormLabels,
        values: shift.formValues
      },
      {
        options: shiftFormOptions,
        plugins: shiftFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound cancelEntryEdit() {
    this.entryForEdit = null;
    this.entryEditForm = null;
  }

  checkIfEntryDisabled(entry) {
    return this.entryForEdit && this.entryForEdit.id !== entry.id;
  }

  @action.bound submitEntryEditForm() {
    this.entryEditForm.submit({
      onSuccess: this.submitEntryEditFormSuccess,
      onError: this.submitEntryEditFormError
    });
  }

  @action.bound submitEntryEditFormSuccess() {
    const { name, code } = this.entryEditForm.trimmedValues();

    this.entryForEdit.save({
      name,
      code
    });

    this.cancelEntryEdit();
  }

  @action.bound submitEntryEditFormError() {
    console.log(this.entryEditForm.errors());
  }
}
