import { Model } from 'mobx-mc';
export default class ObservationField extends Model {
  get restAttributes() {
    return [
      'uuid',
      'name',
      'type',
      'order',
      'negative',
      'positive',
      'mandatory'
    ];
  }

  idAttribute() {
    return 'uuid';
  }
}
