import { RakenCollection } from '../../lib';
import SuperAdminProject from '../../models/superAdmin/SuperAdminProject';

export default class SuperAdminProjects extends RakenCollection {
  model() {
    return SuperAdminProject;
  }

  url() {
    return '/ra/sadmin/projects';
  }
}
