import moment from 'moment';
import orderBy from 'lodash.orderby';
import request from 'axios';
import { observable, action, computed } from 'mobx';

import Transaction from './../models/Transaction';

import { PageableBilling } from './PageableBilling';

import downloadURI from 'utils/downloadURI';
import errorHandler from 'utils/errorHandler';
import { t } from 'utils/translate';

export default class Transactions extends PageableBilling {
  url() {
    if (this.rootStore.isSuperAdmin) {
      return `${this.parent.url()}/billing/transactions`;
    }

    return 'ra/billing/transactions';
  }

  model() {
    return Transaction;
  }

  /**
   * Filtering defaults
   */
  @observable dateFilter = 'all';
  @observable statusFilter = 'success';
  @observable fromDate = null;
  @observable toDate = null;
  @observable downloading = false;
  @observable sortField = 'createdDate';
  @observable sortDirection = 'desc';

  /**
   * Set the start and end date fields
   */
  @action.bound
  setTimeFrame({ fromDate, toDate }) {
    this.fromDate = fromDate && fromDate.startOf('day');
    this.toDate = toDate && toDate.endOf('day');
    this.page = 1;

    if (this.fromDate && this.toDate) {
      this.dateFilter = 'custom';
    }
  }

  /**
   * Set the dateFilter and reset page
   */
  @action.bound
  setDateFilter(value) {
    this.dateFilter = value;
    this.page = 1;

    if (value !== 'custom') {
      this.fromDate = null;
      this.toDate = null;
    }
  }

  @action.bound
  setStatusFilter(value) {
    this.statusFilter = value;
    this.page = 1;
  }

  /**
   * Get the filtered results
   */
  @computed
  get filteredResults() {
    let filteredByDate;

    switch (this.dateFilter) {
      case 'all':
        filteredByDate = [].concat(this.models.slice());
        break;
      case 'last-month':
        filteredByDate = [].concat(
          this.models.filter(model => {
            return (
              moment(model.created).get('month') ===
                moment().get('month') - 1 &&
              moment(model.created).get('year') === moment().get('year')
            );
          })
        );
        break;
      case 'this-month':
        filteredByDate = [].concat(
          this.models.filter(model => {
            return (
              moment(model.created).get('month') === moment().get('month') &&
              moment(model.created).get('year') === moment().get('year')
            );
          })
        );
        break;
      case 'last-three-months':
        filteredByDate = [].concat(
          this.models.filter(model => {
            const transactionMonth = moment(model.created).get('month');
            const thisMonth = moment().get('month');
            const transactionYear = moment(model.created).get('year');
            const thisYear = moment().get('year');

            return (
              (transactionMonth === thisMonth ||
                transactionMonth === thisMonth - 1 ||
                transactionMonth === thisMonth - 2) &&
              transactionYear === thisYear
            );
          })
        );
        break;
      case 'custom':
        filteredByDate = [].concat(
          this.models.filter(model => {
            const paymentDate = moment(model.created);

            return (
              paymentDate.isSameOrAfter(this.fromDate) &&
              paymentDate.isSameOrBefore(this.toDate)
            );
          })
        );
        break;
      default:
        filteredByDate = [].concat(this.models.slice());
    }

    if (!this.rootStore.isSuperAdmin) return filteredByDate;

    switch (this.statusFilter) {
      case 'all':
        return filteredByDate;
      case 'success':
        return filteredByDate.filter(transaction => {
          return transaction.status === 'SUCCESS';
        });
      case 'failure':
        return filteredByDate.filter(transaction => {
          return transaction.status === 'FAILURE';
        });
      case 'na':
        return filteredByDate.filter(transaction => {
          return transaction.status === 'NA';
        });
      default:
        return [];
    }
  }

  /**
   * Get the display text for the current filter
   */
  @computed
  get filterText() {
    switch (this.dateFilter) {
      case 'all':
        return 'All Dates';
      case 'last-month':
        return 'Last Month';
      case 'this-month':
        return 'This Month';
      case 'last-three-months':
        return 'Last Three Months';
      case 'custom':
        if (this.fromDate && this.toDate) {
          return `${this.fromDate.format('YYYY-MM-DD')} - ${this.toDate.format(
            'YYYY-MM-DD'
          )}`;
        }
        return 'Custom';
      default:
        return 'All Dates';
    }
  }

  /**
   * Return a fromDate and toDate based on the dateFilter
   */
  @computed
  get timeFrame() {
    switch (this.dateFilter) {
      case 'all':
        return null;
      case 'this-month':
        return {
          fromDate: moment()
            .startOf('month')
            .format('YYYY-MM-DD'),
          toDate: moment()
            .endOf('month')
            .format('YYYY-MM-DD')
        };
      case 'last-month':
        return {
          fromDate: moment()
            .subtract(1, 'month')
            .startOf('month')
            .format('YYYY-MM-DD'),
          toDate: moment()
            .subtract(1, 'month')
            .endOf('month')
            .format('YYYY-MM-DD')
        };
      case 'last-three-months':
        return {
          fromDate: moment()
            .subtract(2, 'month')
            .startOf('month')
            .format('YYYY-MM-DD'),
          toDate: moment()
            .endOf('month')
            .format('YYYY-MM-DD')
        };
      case 'custom':
        return {
          fromDate: this.fromDate.format('YYYY-MM-DD'),
          toDate: this.toDate.format('YYYY-MM-DD')
        };
      default:
        return null;
    }
  }

  @action.bound
  download() {
    let url;

    if (this.rootStore.isSuperAdmin) {
      url = `${this.parent.url()}/billing/transaction-history`;
    } else {
      url = '/ra/billing/transaction-history';
    }

    let params = {
      type: 'CARD'
    };

    if (this.rootStore.isSuperAdmin) params.status = this.statusFilter;

    if (this.timeFrame) {
      params = Object.assign({}, params, {
        fromDate: this.timeFrame.fromDate,
        toDate: this.timeFrame.toDate
      });
    }

    this.downloading = true;

    request
      .get(url, {
        params
      })
      .then(response => {
        downloadURI(response.data.url, 'Transaction History');
        this.downloading = false;
      })
      .catch(error => {
        errorHandler(error, this.rootStore.notificationsUI.pushError);
        this.downloading = false;
      });
  }

  @computed
  get latestTransaction() {
    return orderBy(this.models, model => {
      return -moment(model.created).valueOf();
    })[0];
  }

  @computed
  get latestTransactionFailed() {
    return (
      this.latestTransaction && this.latestTransaction.statusCode === 'failed'
    );
  }

  @computed get statusFilterOptions() {
    return [
      {
        id: 'all',
        title: t('All statuses')
      },
      {
        id: 'success',
        title: t('Success status')
      },
      {
        id: 'failure',
        title: t('Failture status')
      },
      {
        id: 'na',
        title: t('N/A status')
      }
    ];
  }

  @computed get selectedStatusFilterOption() {
    return this.statusFilterOptions.find(
      option => option.id === this.statusFilter
    );
  }

  @computed get dateFilterOptions() {
    return [
      {
        id: 'all',
        title: t('All dates')
      },
      {
        id: 'this-month',
        title: t('This month')
      },
      {
        id: 'last-month',
        title: t('Last month')
      },
      {
        id: 'last-three-months',
        title: t('Last 3 months')
      },
      {
        id: 'custom',
        title: t('Custom')
      }
    ];
  }

  @computed get selectedDateFilterOption() {
    if (this.dateFilter === 'custom') {
      return {
        id: 'custom',
        title: t('Custom')
      };
    }

    return this.dateFilterOptions.find(option => option.id === this.dateFilter);
  }
}
