import UIStore from './UIStore';

import { action, computed, observable, reaction, runInAction } from 'mobx';

import request from 'axios';
import debounce from 'lodash.debounce';
import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';

import { BASE_DEBOUNCE } from 'fixtures/constants';

import WorkerPhotoIdDetailActivity from '../models/WorkerPhotoIdDetailActivity';
import PhotoIdDetailFiltersUI from './PhotoIdDetailFiltersUI';
import PhotoIdMediaViewerUI from './PhotoIdMediaViewerUI';

import alertErrorHandler from 'utils/alertErrorHandler';

import history from 'utils/history';

import { t } from 'utils/translate';

import { callTrack } from 'utils/segmentIntegration';
import {
  PHOTO_ID_EMPLOYEE_REVIEW,
  PHOTO_ID_VIEW_TIMECARD
} from 'utils/segmentAnalytics/eventSpec';

export default class PhotoIdDetailUI extends UIStore {
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;

  @observable currentWorker;

  constructor(options) {
    super(options);

    this.sortField = 'date';
    this.sortDirection = 'desc';
    this.page = 1;
    this.pageSize = 20;
    this.searchQuery = '';

    this.workerPhotoIdDetailFiltersUI = new PhotoIdDetailFiltersUI({
      rootStore: this.rootStore,
      parent: this
    });

    this.workerPhotoIdMediaViewerUI = new PhotoIdMediaViewerUI({
      rootStore: this.rootStore,
      parent: this
    });

    this.workerPhotoIdDetailActivity = new WorkerPhotoIdDetailActivity(null, {
      rootStore: this.rootStore,
      parent: this
    });

    // Running
    this.loading = true;
    this.currentWorker = null;

    this.fetchWorkerPhotoIdDetailDebounced = debounce(
      this.fetchWorkerPhotoIdDetails,
      BASE_DEBOUNCE
    );
  }

  @action.bound
  setup(id) {
    window.scrollTo(0, 0);
    this.currentWorker = id;
    this.workerPhotoIdDetailFiltersUI.syncWorkerPhotoIdDetailWithFilters();
    this.setupReactions();
    this.fetchWorkerPhotoIdDetails();
    callTrack(PHOTO_ID_EMPLOYEE_REVIEW);
  }

  @action.bound
  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchWorkerPhotoIdDetailDebounced();
        });
      }
    );
  }

  @computed
  get workerPhotoIdTitle() {
    if (this.workerPhotoIdDetailActivity.workerName) {
      return `${this.workerPhotoIdDetailActivity.workerName} ${t(
        'verification photos'
      )}`;
    }

    return t(`Verification photos`);
  }

  @computed
  get workerPhotoIdName() {
    return this.workerPhotoIdDetailActivity?.workerName;
  }

  @computed get hasWorkerPhotoIdDetails() {
    return this.workerPhotoIdDetailActivity.workerPhotoIdDetails?.hasModels;
  }

  @computed
  get workerPhotoIdDetails() {
    return this.workerPhotoIdDetailActivity.workerPhotoIdDetails.models;
  }

  @action.bound fetchWorkerPhotoIdDetails() {
    this.loading = true;

    if (this.hasWorkerPhotoIdDetails) {
      this.workerPhotoIdDetailActivity.clear();
    }

    const fetchParams = pickBy(
      { ...this.params, query: this.searchQuery },
      identity
    );

    try {
      return request
        .post(
          `${this.workerPhotoIdDetailActivity.url()}/${this.currentWorker}`,
          fetchParams
        )
        .then(response => {
          runInAction(() => {
            this.workerPhotoIdDetailActivity.set(response.data);
            this.loading = false;
          });
        });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @computed get filterValues() {
    return {
      eventType: this.workerPhotoIdDetailFiltersUI.eventType,
      photoIdStatus: this.workerPhotoIdDetailFiltersUI.photoIdStatus
    };
  }

  @action.bound tearDown() {
    this.reactToParams && this.reactToParams();
    this.clearPage();
    this.clearSearchQuery();
    this.resetParamsToDefault();

    this.workerPhotoIdDetailActivity.clear();
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      from: this.payPeriodSelectorUI.selectedPeriod?.value?.from,
      to: this.payPeriodSelectorUI.selectedPeriod?.value?.to,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      eventType: this.filterValues.eventType,
      photoIdStatus: this.filterValues.photoIdStatus,
      sortField: `${this.sortField}`,
      sortDirection: this.sortDirection
    };
  }

  @action.bound
  resetParamsToDefault() {
    this.searchQuery = '';
    this.searchMode = false;
    this.workerPhotoIdDetailFiltersUI.clearAllFilters();
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound
  clearSearchQuery() {
    this.page = 1;
    this.searchQuery = '';
    this.searchMode = false;
  }

  @computed
  get totalPages() {
    return Math.ceil(
      this.workerPhotoIdDetailActivity.page?.totalElements / this.pageSize
    );
  }

  @action.bound setPage(event, page) {
    window.scrollTo(0, 0);
    this.page = page;
  }

  @action.bound clearPage() {
    this.page = 1;
    this.loading = true;
  }

  @action.bound cancelPhotoIdsEdit() {
    history.push({
      pathname: `/timesheets/photo-id`,
      search: this.baseQueryParams
    });
  }

  @action.bound editTimeCard(workerPhotoIdDetail) {
    callTrack(PHOTO_ID_VIEW_TIMECARD);

    history.push({
      pathname: workerPhotoIdDetail.timeCardUrl,
      search: this.baseQueryParams
    });
  }
}
