import UIStore from 'stores/ui/UIStore';
import { action, computed, observable } from 'mobx';

import {
  ProjectTemplateInfoForm,
  projectTemplateInfoFormOptions,
  projectTemplateInfoFormFields,
  projectTemplateInfoFormRules,
  projectTemplateInfoFormPlugins,
  projectTemplateInfoFormLabels
} from 'forms/projectTemplates/projectTemplateInfo';

import history from 'utils/history';
import { t } from 'utils/translate';

import alertErrorHandler from 'utils/alertErrorHandler';

export default class ProjectTemplateSaveAsUI extends UIStore {
  @observable originalTemplate;
  @observable form;
  @observable saving;

  constructor(options) {
    super(options);

    this.originalTemplate = '';
    this.form = null;
    this.saving = false;
  }

  @action.bound setup() {
    this.form = new ProjectTemplateInfoForm(
      {
        fields: projectTemplateInfoFormFields,
        rules: Object.assign(projectTemplateInfoFormRules, {
          templateName: `required|string|not_in:${this.existingTemplateNames.join(
            ','
          )}`
        }),
        labels: projectTemplateInfoFormLabels,
        values: {
          ...this.originalTemplate.formValues,
          templateName: this.createUniqTemplateName(
            this.originalTemplate.templateName
          ),
          isDefault: false
        }
      },
      {
        options: projectTemplateInfoFormOptions,
        plugins: projectTemplateInfoFormPlugins
      }
    );
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.originalTemplate = null;
    this.form = null;
  }

  createUniqTemplateName(baseName) {
    const uniqueName = `Copy of ${baseName}`;

    const notUnique = this.projectTemplates.models.find(
      template => template.templateName === uniqueName
    );

    if (notUnique) {
      return this.createUniqTemplateName(uniqueName);
    } else {
      return uniqueName;
    }
  }

  @action.bound
  showSaveAsModal(originalTemplate) {
    this.originalTemplate = originalTemplate;
    this.setup();
    this.activeModal = 'projectTemplateSaveAsModal';
  }

  @action.bound
  async hideSaveAsModal() {
    await this.hideActiveModal();
    this.tearDown();
  }

  @computed get projectTemplates() {
    return this.parent.projectTemplates;
  }

  @computed get existingTemplateNames() {
    return this.projectTemplates.models.map(template => {
      // Convert Pipe to Hex code as it will break rules when passed to dvr in initCompanyProjectTemplateForm
      return `${template.templateName.replace('|', '7C')}`;
    });
  }

  @computed get saveDisabled() {
    return this.saving || !this.form.isValid;
  }

  @action.bound
  submitForm(e) {
    e.preventDefault();
    e.stopPropagation();

    this.form.submit({
      onSuccess: () => {
        this.submitFormSuccess();
      },
      onError: () => {
        console.log(this.form.errors());
      }
    });
  }

  @action.bound
  goBack() {
    this.parent.fetchTemplates();
    history.push(`/company-settings/project-templates`);
  }

  @action.bound
  async submitFormSuccess() {
    this.saving = true;

    try {
      await this.projectTemplates.create(this.form.values(), {
        wait: true
      });
      this.handleSaveSuccess();
    } catch (error) {
      this.handleSaveError(error);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  handleSaveError(error) {
    alertErrorHandler(error, this.setValidationDetails);
    this.saving = false;
  }

  @action.bound
  handleSaveSuccess() {
    this.notifications.pushNotification({
      title: t('Project template created'),
      snackbar: 'warning',
      icon: 'notification'
    });

    this.hideSaveAsModal();
    this.goBack();
  }
}
