import React from 'react';

import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const Link = styled(({ activeColor, defaultColor, ...props }) => (
  <NavLink {...props} />
))`
  cursor: pointer;
  color: ${props =>
    props.active
      ? props.theme.colors[props.activeColor]
      : props.theme.colors[props.defaultColor]};
  text-decoration: none;
  transition: color 0.25s ease-in-out;
  &:hover {
    color: ${props => props.theme.colors[props.activeColor]};
  }
  &.active {
    color: ${props => props.theme.colors[props.activeColor]};
  }
`;

Link.defaultProps = {
  activeColor: 'rakenOrange',
  defaultColor: 'charcoalGrey'
};

export default Link;
