import { RakenCollection } from '../../lib';
import Group from 'stores/models/groups/Group';

export default class Groups extends RakenCollection {
  model() {
    return Group;
  }

  url() {
    return `ra/companies/${this.rootStore.me.company.uuid}/groups`;
  }
}
