import qs from 'querystringify';
import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import request from 'axios';
import { action, observable, computed } from 'mobx';
import UIStore from './UIStore';
import Integration from 'stores/models/integrations/Integration';
import errorHandler from 'utils/errorHandler';

import {
  AppConnectForm,
  appConnectFormOptions,
  appConnectFormFields,
  appConnectFormRules,
  appConnectFormLabels,
  appConnectFormPlugins
} from 'forms/appConnect';

import {
  RyvitConnectForm,
  ryvitConnectFormOptions,
  ryvitConnectFormFields,
  ryvitConnectFormRules,
  ryvitConnectFormLabels,
  ryvitConnectFormPlugins
} from 'forms/superAdmin/integrations/ryvitConnect';

import {
  SageIntacctConnectForm,
  sageIntacctConnectFormOptions,
  sageIntacctConnectFormFields,
  sageIntacctConnectFormRules,
  sageIntacctConnectFormLabels,
  sageIntacctConnectFormPlugins
} from 'forms/superAdmin/integrations/sageIntacctConnect';

export default class AppConnectUI extends UIStore {
  @observable activeCompany;
  @observable activeIntegration;
  @observable loading;
  @observable projectId;
  @observable companyId;
  @observable companyUuid;
  @observable error;

  constructor(options) {
    super(options);
    this.loading = false;
    this.projectId = null;
    this.projectUuid = null;
    this.companyId = null;
    this.companyUuid = null;
    this.error = null;
    this.activeCompany = null;
    this.activeIntegration = null;
  }

  @action.bound async initializeConnect(options) {
    this.loading = true;

    const {
      projectId,
      projectUuid,
      companyId,
      companyUuid,
      integrationId
    } = options;

    this.setProjectId(projectId);
    this.setProjectUuid(projectUuid);
    this.setCompanyId(companyId);
    this.setCompanyUuid(companyUuid);

    await this.setActiveIntegration(integrationId);

    this.initializeForm();

    this.loading = false;
  }

  @action.bound initializeForm() {
    if (this.activeIntegration.isRyvit) {
      this.activeForm = new RyvitConnectForm(
        {
          fields: ryvitConnectFormFields,
          rules: ryvitConnectFormRules,
          labels: ryvitConnectFormLabels,
          values: {
            subscriberCode: this.activeIntegration.subscriberCode || ''
          }
        },
        {
          options: ryvitConnectFormOptions,
          plugins: ryvitConnectFormPlugins
        }
      );
    } else if (this.activeIntegration.isSageIntacct) {
      this.activeForm = new SageIntacctConnectForm(
        {
          fields: sageIntacctConnectFormFields,
          rules: sageIntacctConnectFormRules,
          labels: sageIntacctConnectFormLabels
        },
        {
          options: sageIntacctConnectFormOptions,
          plugins: sageIntacctConnectFormPlugins
        }
      );
    } else {
      this.activeForm = new AppConnectForm(
        {
          fields: appConnectFormFields,
          rules: appConnectFormRules,
          labels: appConnectFormLabels
        },
        {
          options: appConnectFormOptions,
          plugins: appConnectFormPlugins
        }
      );
    }
  }

  @action.bound async initializeSuccess(options) {
    this.loading = true;

    const {
      projectId,
      projectUuid,
      companyId,
      companyUuid,
      integrationId
    } = options;

    this.setProjectId(projectId);
    this.setProjectUuid(projectUuid);
    this.setCompanyId(companyId);
    this.setCompanyUuid(companyUuid);

    await this.setActiveIntegration(integrationId);

    this.loading = false;
  }

  @action.bound async initializeFailure(options) {
    this.loading = true;

    const {
      projectId,
      projectUuid,
      companyId,
      companyUuid,
      integrationId,
      error
    } = options;

    this.setProjectId(projectId);
    this.setProjectUuid(projectUuid);
    this.setCompanyId(companyId);
    this.setCompanyUuid(companyUuid);
    this.setError(error);

    await this.setActiveIntegration(integrationId);

    this.loading = false;
  }

  @action.bound setCompanyId(companyId) {
    this.companyId = companyId || null;
  }

  @action.bound setCompanyUuid(companyUuid) {
    this.companyUuid = companyUuid || null;
  }

  @action.bound setProjectId(projectId) {
    this.projectId = projectId || null;
  }

  @action.bound setProjectUuid(projectUuid) {
    this.projectUuid = projectUuid || null;
  }

  @action.bound setError(error) {
    this.error = error;
  }

  @action.bound async setActiveIntegration(integrationId) {
    if (this.activeIntegration && this.activeIntegration.id === integrationId)
      return;

    let url;

    if (this.projectUuid) {
      url = `${this.rootStore.urlMicroService('integrations')}/projects/${
        this.projectUuid
      }/integrations/${integrationId}`;
    } else {
      url = `${this.rootStore.urlMicroService('integrations')}/companies/${
        this.companyUuid
      }/integrations/${integrationId}`;
    }

    try {
      const response = await request.get(url);

      this.activeIntegration = new Integration(response.data, {
        rootStore: this.rootStore
      });
    } catch (error) {
      errorHandler(error, this.rootStore.notificationsUI.pushError);
    }
  }

  @action.bound submitActiveForm(event) {
    event.preventDefault();

    if (this.saving) return false;

    this.activeForm.submit({
      onSuccess: this.submitActiveFormSuccess,
      onError: this.submitActiveFormError
    });
  }

  @action.bound submitActiveFormSuccess() {
    if (this.activeIntegration.isRyvit) {
      this.submitRyvitConnectFormSuccess();
    } else {
      this.submitConnectFormSuccess();
    }
  }

  @action.bound submitActiveFormError() {
    console.log(this.activeForm.errors());
  }

  @action.bound async submitConnectFormSuccess() {
    this.clearValidationDetails();

    const payload = {};
    payload.credentials = this.activeForm.values();

    if (this.projectUuid) {
      payload.projectUuid = this.projectUuid;
    }

    if (this.activeIntegration.isSageIntacct) {
      payload.businessUuid = this.companyUuid;
    }

    this.saving = true;

    try {
      await request.post(
        `${this.rootStore.urlMicroService('integrations')}/apps/${
          this.activeIntegration.id
        }/connect`,
        payload
      );

      this.saving = false;

      window.location = this.successUrl;
    } catch (error) {
      if (error.response) {
        const { data } = error.response;

        if (data.validationDetails) {
          this.setValidationDetails(data.validationDetails);
        } else {
          this.setValidationDetails([
            { field: 'credentials', fieldMessage: data.message }
          ]);
        }
      }

      this.saving = false;
    }
  }

  @computed get successUrl() {
    return `connect/success${qs.stringify(
      pickBy({
        integrationId: this.activeIntegration.id,
        companyUuid: this.companyUuid,
        projectUuid: this.projectUuid,
        projectId: this.projectId
      }),
      identity
    )}`;
  }

  @action.bound
  async submitRyvitConnectFormSuccess() {
    this.clearValidationDetails();

    const values = this.activeForm.values();

    this.saving = true;

    try {
      await request.post(
        `${this.rootStore.urlMicroService('integrations')}/ryvit/connect`,
        {
          applicationId: this.activeIntegration.id,
          businessId: this.companyId,
          companyUuid: this.companyUuid,
          subscriberCode: values.subscriberCode
        }
      );

      this.saving = false;

      window.location = this.successUrl;
    } catch (error) {
      if (error.response) {
        const { data } = error.response;

        if (data.validationDetails) {
          this.setValidationDetails(data.validationDetails);
        } else {
          this.setValidationDetails([
            { field: 'credentials', fieldMessage: data.message }
          ]);
        }
      }
    }

    this.saving = false;
  }
}
