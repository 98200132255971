import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import Highlighter from 'utils/highlighter';

import { CollapsibleHeader, CollapsibleBody, ExpandIcon } from '.';

const Wrapper = styled.section`
  margin-bottom: ${props => props.marginBottom};
  &:last-child {
    margin-bottom: 0;
  }
  position: ${props => (props.position ? props.position : 'static')};
`;

const Title = styled.a`
  border-bottom: 1px solid transparent;
  font-size: ${props => props.titleFontSize};
  font-weight: ${props => props.titleFontWeight};
  color: ${props =>
    props.titleColor ? props.theme.colors[props.titleColor] : 'inherit'};
  &:hover {
    border-bottom-color: inherit;
  }
`;

const CollapsibleContent = styled.div`
  display: inline-flex;
  align-items: center;
  white-space: pre-wrap;
`;

@observer
export default class extends Component {
  static defaultProps = {
    marginBottom: '4rem',
    titleFontSize: 'inherit',
    titleFontWeight: 'inherit',
    titleClickForIcon: false
  };

  constructor(props) {
    super(props);

    this.state = {
      open: props.open || false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.open !== this.props.open ||
      (this.props.open &&
        !this.state.open &&
        prevState.open !== this.props.open)
    ) {
      this.setState({
        open: this.props.open
      });
    }

    // run onOpen function if state is open. This allows us to pass a callback like function which runs when opening
    if (!prevState.open && this.state.open && this.props.onOpen) {
      this.props.onOpen();
    }

    // See note above for onOpen
    if (prevState.open && !this.state.open && this.props.onClose) {
      this.props.onClose();
    }
  }

  componentDidMount() {
    if (this.props.open && this.props.onOpen) {
      this.props.onOpen();
    }
  }

  handleToggle = e => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    this.setState({
      open: !this.state.open
    });
  };

  handleTitleClick = e => {
    const { useState } = this.props;

    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    if (useState) {
      this.handleToggle(e);
      this.props.onTitleClick(!this.state.open);
    } else {
      this.props.onTitleClick();
    }
  };

  renderTitle = () => {
    const { searchQuery, title } = this.props;

    if (searchQuery)
      return (
        <Highlighter
          searchWords={searchQuery}
          autoEscape={true}
          textToHighlight={title}
        />
      );

    return title;
  };

  render() {
    const {
      onDisabledClick,
      onTitleClick,
      titleClickForIcon,
      title,
      contentHeader,
      children,
      headerAlignLeft,
      headerPaddingLeft,
      marginBottom,
      hideIcon,
      titleFontSize,
      titleFontWeight,
      titleColor,
      iconBorder,
      iconColor,
      position,
      dataQA,
      paddingLeft,
      paddingRight,
      innerRef
    } = this.props;

    const identifier = dataQA
      ? dataQA
      : String(title)
          .replace(/ /gi, '-')
          .toLowerCase();

    return (
      <Wrapper
        onClick={onDisabledClick}
        marginBottom={marginBottom}
        position={position}
        data-qa={`section_${identifier}`}
        ref={innerRef}
      >
        <CollapsibleHeader
          headerPaddingLeft={headerPaddingLeft}
          headerAlignLeft={headerAlignLeft}
          marginBottom={this.state.open ? '2rem' : '0'}
          hideIcon={hideIcon}
        >
          <div>
            {!hideIcon && (
              <Fragment>
                <ExpandIcon
                  animate={false}
                  onClick={e => {
                    if (titleClickForIcon) {
                      this.handleTitleClick(e);
                    } else {
                      this.handleToggle(e);
                    }
                  }}
                  open={this.state.open}
                  left="0px"
                  iconBorder={iconBorder}
                  iconColor={iconColor}
                />{' '}
              </Fragment>
            )}
            <Title
              data-qa={`header_${identifier}`}
              titleFontSize={titleFontSize}
              titleFontWeight={titleFontWeight}
              titleColor={titleColor}
              onClick={e => {
                if (onTitleClick) {
                  this.handleTitleClick(e);
                } else {
                  this.handleToggle(e);
                }
              }}
            >
              {this.renderTitle()}
            </Title>
          </div>

          {contentHeader && (
            <CollapsibleContent onClick={this.handleToggle}>
              {contentHeader}
            </CollapsibleContent>
          )}
        </CollapsibleHeader>

        <CollapsibleBody
          open={this.state.open}
          ref={ref => (this.body = ref)}
          paddingLeft={paddingLeft}
          paddingRight={paddingRight}
        >
          {this.state.open && children}
        </CollapsibleBody>
      </Wrapper>
    );
  }
}
