import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import trimObject from '../utils/trimObject';

const locationFormPlugins = extendedPlugins;

const locationFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const locationFormFields = ['parent', 'locationName'];

const locationFormRules = {
  parent: 'string',
  locationName: 'required|string|max:100'
};

class LocationForm extends MobxReactForm {
  trimmedValues() {
    return trimObject(this.values());
  }
}

export {
  LocationForm,
  locationFormOptions,
  locationFormFields,
  locationFormRules,
  locationFormPlugins
};
