import { action, computed, observable } from 'mobx';

import {
  ProjectTemplateTimeTrackingForm,
  projectTemplateTimeTrackingFormOptions,
  projectTemplateTimeTrackingFormFields,
  projectTemplateTimeTrackingFormRules,
  projectTemplateTimeTrackingFormLabels,
  projectTemplateTimeTrackingFormPlugins
} from 'forms/projectTemplates/projectTemplateTimeTracking';

import { t } from 'utils/translate';

import ProjectTemplateChildEditUI from './ProjectTemplateChildEditUI';

import errorHandler from 'utils/errorHandler';

export default class ProjectTemplateTimeTrackingUI extends ProjectTemplateChildEditUI {
  @observable form;
  constructor(options) {
    super(options);

    this.form = null;

    this.saveTitle = t('Project template time tracking saved');
  }

  @computed get selectedTemplate() {
    return this.parent.selectedTemplate;
  }

  @action.bound setup() {
    this.setupForm();
  }

  @action.bound tearDown() {
    this.form = null;
    this.unblockHistory();
  }

  @action.bound
  setupForm() {
    this.form = new ProjectTemplateTimeTrackingForm(
      {
        fields: projectTemplateTimeTrackingFormFields,
        rules: projectTemplateTimeTrackingFormRules,
        labels: projectTemplateTimeTrackingFormLabels,
        values: this.selectedTemplate.timeTrackingValues
      },
      {
        options: projectTemplateTimeTrackingFormOptions,
        plugins: projectTemplateTimeTrackingFormPlugins
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @computed get manualEntryTip() {
    return {
      url: `${this.assetsURL}/images/company-settings/example-of-manual-entry.png`
    };
  }

  @computed get startAndEndTimeTip() {
    return {
      url: `${this.assetsURL}/images/company-settings/example-of-start-and-end-time.png`
    };
  }

  @computed get classificationsOnTimeCardsOptions() {
    return [
      {
        value: 'NOT_REQUIRED',
        name: t('Classifications not required on time cards')
      },
      {
        value: 'REQUIRED_ON_ONE',
        name: t('Classifications required on one or more time entries')
      },
      {
        value: 'REQUIRED_ON_ALL',
        name: t('Classifications required on all time entries')
      }
    ];
  }

  @computed get classificationsEditableOnTimeCardsOptions() {
    return [
      {
        value: true,
        name: t('Allow editing of classifications on time cards')
      },
      {
        value: false,
        name: t("Don't allow editing of classifications on time cards")
      }
    ];
  }

  @computed get selectedClassificationsOnTimeCardsOption() {
    return this.classificationsOnTimeCardsOptions.find(
      option => option.value === this.form.$('classificationsOnTimeCards').value
    );
  }

  @computed get selectedClassificationsEditableOnTimeCardsOption() {
    return this.classificationsEditableOnTimeCardsOptions.find(
      option =>
        option.value === this.form.$('classificationsEditableOnTimeCards').value
    );
  }

  @computed
  get costCodesOnTimeCardsOptions() {
    return [
      { title: t('Cost code not required'), id: 'NOT_REQUIRED' },
      {
        title: t('Cost code required on one or more time entries'),
        id: 'REQUIRED_ON_ONE'
      },
      {
        title: t('Cost codes required on all time entries'),
        id: 'REQUIRED_ON_ALL'
      }
    ];
  }

  @computed get selectedCostCodesOnTimeCardsOption() {
    return this.costCodesOnTimeCardsOptions.find(
      option => option.id === this.form.$('costCodesOnTimeCards').value
    );
  }

  @computed
  get breaksRequirementOptions() {
    return [
      {
        title: t('Require start & end time'),
        id: 'REQUIRE_START_AND_END_TIME'
      },
      {
        title: t('Require start time & duration'),
        id: 'REQUIRE_START_TIME_AND_DURATION'
      },
      {
        title: t('Require duration only'),
        id: 'REQUIRE_DURATION_ONLY'
      }
    ];
  }

  @computed get breaksRequirementValue() {
    return this.breaksRequirementOptions.find(
      option => option.id === this.form.$('breakSetting').value
    );
  }

  @action.bound
  async fetchOverTimeRules() {
    try {
      await this.overTimeRules.fetch({
        params: {
          limit: 10000
        },
        add: true,
        remove: false,
        update: true
      });

      this.rootStore.companyOverTimeRulesFetched = true;
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  @action.bound
  updateOverTimeRuleType(option) {
    if (option?.id) {
      // Handle autocomplete keyboard delete button where no option is set but update still runs.
      this.form.$('overtimeRuleSetUuid').set(option.id);
    }
    return;
  }

  @computed get overTimeRulesSelectValue() {
    const searchModels = this.overTimeRules.models.find(
      model => model.uuid === this.form?.$('overtimeRuleSetUuid').value
    );

    if (searchModels) {
      return searchModels;
    }

    return this.overTimeRules.models.find(model => model.default);
  }

  @computed get overTimeRulesSelectValueDefault() {
    return this.overTimeRulesSelectValue?.rules.find(rule => rule.default);
  }

  @computed get overTimeRules() {
    return this.rootStore.overTimeRules;
  }

  @computed get formattedRuleName() {
    return this.rootStore.overTimeRulesUI.overTimeRulesType.find(
      type => type.id === this.overTimeRulesSelectValue?.type
    )?.title;
  }
}
