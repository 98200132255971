import UIStore from './UIStore';
import request from 'axios';
import moment from 'moment-timezone';
import qs from 'querystringify';
import {
  action,
  observable,
  computed,
  autorun,
  runInAction,
  reaction
} from 'mobx';

import numberWithCommas from 'utils/numberWithCommas';
import history from 'utils/history';
import valueNotEmpty from 'utils/formValueNotEmpty';

import {
  SubscriptionForm,
  subscriptionFormOptions,
  subscriptionFormFields,
  subscriptionFormRules,
  subscriptionFormLabels,
  subscriptionFormPlugins
} from 'forms/subscription';

import {
  PaymentMethodForm,
  paymentMethodFormOptions,
  paymentMethodFormFields,
  paymentMethodFormRules,
  paymentMethodFormLabels,
  paymentMethodFormValues,
  paymentMethodFormPlugins
} from 'forms/paymentMethod';

import {
  TimeFrameForm,
  timeFrameFormOptions,
  timeFrameFormFields,
  timeFrameFormRules,
  timeFrameFormLabels,
  timeFrameFormValues,
  timeFrameFormPlugins
} from 'forms/timeFrame';
import errorHandler from 'utils/errorHandler';
import { callTrack } from 'utils/segmentIntegration';

import { t } from 'utils/translate';
import {
  COMPANY_BILLING_PAYMENT_VIEWED,
  COMPANY_BILLING_UPDOWNGRADE_VIEWED
} from 'utils/segmentAnalytics/eventSpec';

export default class CompanySubscriptionBillingUI extends UIStore {
  @observable selectedPlan;
  @observable saving;
  @observable loading;
  @observable editingSubscription;
  @observable upsellShown;
  @observable showUpsell;
  @observable resolvingSubscription;
  @observable costToday;
  @observable costTodayFloat;
  @observable discountToday;
  @observable costAnnual;
  @observable location;
  @observable stripeErrorMessage;
  @observable stripeErrorCode;

  // Selected items
  @observable selectedPaymentMethod;
  @observable selectedTransaction;

  // Forms
  @observable subscriptionForm;
  @observable paymentMethodForm;
  @observable timeFrameForm;

  // Fetching failue
  @observable paymentMethodsFailedToFetch;
  @observable transactionHistoryFailedToFetch;
  @observable subscriptionPlansFailedToFetch;

  constructor(options) {
    super(options);

    this.selectedPlan = null;
    this.saving = false;
    this.loading = true;
    this.editingSubscription = false;
    this.upsellShown = false;
    this.showUpsell = false;
    this.resolvingSubscription = false;
    this.costToday = 0;
    this.costTodayFloat = 0;
    this.discountToday = 0;
    this.costTodayAnnual = 0;
    this.location = null;

    // Selected items
    this.selectedPaymentMethod = null;
    this.selectedTransaction = null;

    // Forms
    this.subscriptionForm = null;
    this.paymentMethodForm = null;

    // Fetching Failure
    this.paymentMethodsFailedToFetch = false;
    this.transactionHistoryFailedToFetch = false;
    this.subscriptionPlansFailedToFetch = false;

    this.stripeErrorMessage = '';
    this.stripeErrorCode = null;
    this.stripeInstance = null;
  }

  @computed get subscriptionPlans() {
    return this.rootStore.subscriptionPlans;
  }

  @computed get paymentMethods() {
    return this.company.paymentMethods;
  }

  @computed get transactions() {
    return this.company.transactions;
  }

  /**
   * Urls
   */

  @computed get showPlansUrl() {
    return '/company-settings/subscription-billing';
  }

  @computed get estimateUrl() {
    return 'ra/billing/estimate';
  }

  @action.bound
  setupReactions() {
    this.reactToLocation = reaction(
      () => this.location,
      location => {
        const queryParams = qs.parse(location.search);

        if (queryParams.open === 'manage-account') {
          this.showUpgradeModal();
        } else {
          this.hideUpgradeModal();
        }
      }
    );

    if (!this.costFields) {
      this.costFields = autorun(() => {
        this.fetchCostToday(this.costTodayValues);
      });
    }

    if (!this.costFieldsAnnual) {
      this.costFieldsAnnual = autorun(() => {
        this.fetchCostTodayAnnual(this.costTodayAnnualValues);
      });
    }
  }

  @action.bound
  tearDownReactions() {
    if (this.costFields) {
      this.costFields();
      this.costFields = null;
    }

    if (this.costFieldsAnnual) {
      this.costFieldsAnnual();
      this.costFieldsAnnual = null;
    }
  }

  @action.bound
  initializeSubscriptionBillingTab() {
    this.fetchBillingData();
    this.showEditSubscriptionForm = false;
  }

  @action.bound
  setLocation(location) {
    this.location = location;
  }

  @action.bound
  showUpgradeModal() {
    history.push(`${this.showPlansUrl}?open=manage-account`);
    this.showModal('upgradeModal');
  }

  @action.bound
  hideUpgradeModal() {
    history.push(this.showPlansUrl);
    this.hideActiveModal();
  }

  @action.bound
  selectPlan(plan) {
    return new Promise(resolve => {
      runInAction(() => {
        const { subscription, paymentMethods } = this;

        // Log Page View Analytics
        if (subscription.plan.id === plan.id) {
          callTrack(COMPANY_BILLING_PAYMENT_VIEWED);
        } else {
          callTrack(COMPANY_BILLING_UPDOWNGRADE_VIEWED);
        }

        if (
          (paymentMethods.isEmpty &&
            subscription.billingPlatform === 'CREDIT_CARD') ||
          subscription.billingPlatform === 'IN_APP'
        ) {
          this.subscriptionForm = new SubscriptionForm(
            {
              fields: subscriptionFormFields.concat(paymentMethodFormFields),
              rules: Object.assign(
                {},
                subscriptionFormRules,
                paymentMethodFormRules
              ),
              labels: Object.assign(
                {},
                subscriptionFormLabels,
                paymentMethodFormLabels
              ),
              values: Object.assign(
                {},
                subscription.asFormValues,
                paymentMethodFormValues,
                {
                  accountType: subscription.accountType,
                  periodType:
                    subscription.plan.id === plan.id &&
                    !subscription.onTrialOrStarterPlan
                      ? subscription.periodType
                      : 'ANNUAL',
                  billingPlanKey: plan.planKey,
                  billingPlanName: plan.name
                }
              )
            },
            {
              options: Object.assign(
                {},
                subscriptionFormOptions,
                paymentMethodFormOptions
              ),
              plugins: Object.assign(
                {},
                subscriptionFormPlugins,
                paymentMethodFormPlugins
              )
            }
          );
        } else {
          this.subscriptionForm = new SubscriptionForm(
            {
              fields: subscriptionFormFields,
              rules: subscriptionFormRules,
              labels: subscriptionFormLabels,
              values: Object.assign({}, subscription.asFormValues, {
                subscriptionPlan: plan.name,
                accountType: subscription.accountType,
                periodType:
                  subscription.plan.id === plan.id &&
                  !subscription.onTrialOrStarterPlan
                    ? subscription.periodType
                    : 'ANNUAL',
                billingPlanKey: plan.planKey,
                billingPlanName: plan.name
              })
            },
            {
              options: subscriptionFormOptions,
              plugins: subscriptionFormPlugins
            }
          );
        }

        this.selectedPlan = plan;
        resolve();
      });
    });
  }

  @action.bound
  clearPlan() {
    this.selectedPlan = null;
    this.subsriptionForm = null;
  }

  @action.bound
  updateSubscription(values) {
    const { subscription } = this;

    let periodType;

    // Handle pending actions
    if (subscription.upcomingPeriodType) {
      periodType = subscription.upcomingPeriodType;
    } else {
      periodType = values.periodType;
    }

    const { upcomingPeriodType, ...noUpcomingPeriodType } = values;
    const payload = {
      ...noUpcomingPeriodType,
      periodType: periodType,
      subscriptionState: this.editingSubscription
        ? values.subscriptionState
        : 'ACTIVE'
    };

    return new Promise((resolve, reject) => {
      subscription
        .save(payload, {
          wait: true,
          reset: true,
          method: 'put'
        })
        .then(response => {
          if (this.rootStore.isSuperAdmin) {
            const { superAdminCompaniesUI } = this.rootStore.superAdminUI;

            superAdminCompaniesUI.activeCompany.fetch();
          }

          resolve(response);
        })
        .catch(error => {
          this.saving = false;
          this.showUpsell = false;

          errorHandler(error, this.notifications.pushError);

          if (this.activeModal !== 'addSeats') {
            this.activeModal = null;
          }

          reject(error);
        });
    });
  }

  @action.bound
  async updateSubscriptionAndBusiness(type) {
    const values = this.subscriptionForm.values();

    const { transactions, subscription } = this;

    const seatsIncreased = values.seats > subscription.seats;

    // don't pass the promoEndDate property if it doesn't have promo
    // because backend will error on empty string
    if (!values.isPromo || !values.promoEndDate) {
      delete values.promoEndDate;
    }

    this.saving = true;

    if (!this.paymentMethods.card) {
      const stripeObj = await this.getStripeToken(
        this.subscriptionForm?.$('addressZip').value
      );

      if (stripeObj.error) {
        this.setStripeError(stripeObj.error.code, stripeObj.error.message);
      } else {
        this.clearStripeError();
      }

      if (!stripeObj.token) {
        this.saving = false;
        return;
      }

      await this.submitStripeToken(stripeObj);

      this.paymentMethods.fetch({
        params: {
          businessId: this.company.uuid,
          mn: 'full',
          mc: 'full'
        }
      });
    }

    await this.updateSubscription(values);

    if (seatsIncreased && !this.editingSubscription) {
      this.showModal('addSeatsConfirmation');
    } else {
      this.showModal('subscriptionConfirmation');
    }

    this.editingSubscription = false;
    this.saving = false;
    this.showUpsell = false;
    this.selectedPlan = null;
    this.subscriptionForm = null;
    this.paymentMethodForm = null;

    // Update the transaction store
    transactions.fetch({
      params: {
        limit: 100
      }
    });

    // fetch the latest business store
    subscription.fetch();

    this.fetchChildBillingData();
  }

  @action.bound
  addPaymentMethodClick() {
    this.showModal('addPaymentMethod');

    this.paymentMethodForm = new PaymentMethodForm(
      {
        fields: paymentMethodFormFields,
        rules: paymentMethodFormRules,
        labels: paymentMethodFormLabels,
        values: paymentMethodFormValues
      },
      {
        options: paymentMethodFormOptions,
        plugins: paymentMethodFormPlugins
      }
    );
  }

  @action.bound
  setStripeError(code, message) {
    this.saving = false;
    this.stripeErrorCode = code;
    this.stripeErrorMessage = message;
  }

  @action.bound
  clearStripeError() {
    this.stripeErrorCode = '';
    this.stripeErrorMessage = '';
  }

  @computed get cardNumberInvalid() {
    return (
      this.stripeErrorCode === 'incomplete_number' ||
      (!this.stripeErrorCode && this.stripeErrorMessage)
    );
  }

  @computed get cvcInvalid() {
    return (
      this.stripeErrorCode === 'cvv' ||
      this.stripeErrorCode === 'incomplete_cvc'
    );
  }

  @computed get expiryInvalid() {
    return this.stripeErrorCode === 'incomplete_expiry';
  }

  @action.bound
  getStripeToken(addressZipValue) {
    if (this.stripeInstance) {
      return this.stripeInstance.createToken({
        address_zip: addressZipValue
      });
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  }

  @action.bound
  submitStripeToken(stripe) {
    return new Promise((resolve, reject) => {
      // Send token to raken api
      return request
        .post(this.paymentMethods.url(), {
          creditCardToken: stripe.token.id
        })
        .then(response => {
          this.clearStripeError();
          return resolve(response.data);
        })
        .catch(error => {
          let code = null;
          let message = t('System error, please try again.');

          const { data } = error.response;

          if (data) {
            code = data.validationDetails.length
              ? data.validationDetails[0].field
              : null;

            message = data.validationDetails.length
              ? data.validationDetails[0].fieldMessage
              : data.message;
          }

          this.setStripeError(code, message);
        });
    });
  }

  @action.bound
  async handleSubmitEditCardForm(event) {
    let cardDetails;

    this.saving = true;

    if (event) {
      event.preventDefault();
    }

    const stripeObj = await this.getStripeToken(
      this.paymentMethods.card.form.$('addressZip').value
    );

    if (stripeObj.error) {
      this.setStripeError(stripeObj.error.code, stripeObj.error.message);
    } else {
      this.clearStripeError();
    }

    if (!stripeObj.token) return;

    try {
      cardDetails = await this.submitStripeToken(stripeObj);
    } catch (e) {
      return;
    }

    this.saving = false;
    this.paymentMethods.card.set(cardDetails);
    this.paymentMethods.card.cancelEditing();
  }

  @action.bound
  async handleSubmitAddCardForm(event) {
    this.saving = true;

    if (event) {
      event.preventDefault();
    }

    const stripeObj = await this.getStripeToken(
      this.paymentMethodForm.$('addressZip').value
    );

    if (stripeObj.error) {
      this.setStripeError(stripeObj.error.code, stripeObj.error.message);
    } else {
      this.clearStripeError();
    }

    if (!stripeObj.token) return;

    try {
      await this.submitStripeToken(stripeObj);
    } catch (e) {
      return;
    }

    this.saving = false;

    this.paymentMethods.fetch({
      params: {
        businessId: this.company.uuid,
        mn: 'full',
        mc: 'full'
      }
    });

    this.hideActiveModal();

    if (this.resolvingSubscription) {
      this.resolveSubscription();
    } else {
      this.notifications.pushNotification({
        title: t('Card added'),
        snackbar: 'warning',
        icon: 'notification'
      });
    }
  }

  @action.bound
  editPaymentMethodClick() {
    this.showModal('editPaymentMethod');
    this.paymentMethods.card.startEditing();
  }

  @action.bound
  cancelEditPaymentMethod() {
    this.hideActiveModal().then(() => {
      this.paymentMethods.card.cancelEditing();
      this.clearStripeError();
    });
  }

  @action.bound
  upsellProceed() {
    this.subscriptionForm.update({
      periodType: 'ANNUAL'
    });

    this.updateSubscriptionAndBusiness();
  }

  @action.bound
  upsellCancel() {
    this.showUpsell = false;
  }

  @action.bound
  removeUserSeatsClick() {
    this.showModal('removeUserSeats');
  }

  @action.bound
  cancelSubscription() {
    const { subscription } = this;

    this.saving = true;

    request.delete(subscription.url()).then(response => {
      runInAction(() => {
        subscription.setAttribute('subscriptionState', 'CANCELLED'); // TODO: need checking
        this.saving = false;
        this.activeModal = null;
      });
    });
  }

  @action.bound
  editSubscriptionClick() {
    const { subscription } = this;

    this.editingSubscription = true;
    this.subscriptionForm = new SubscriptionForm(
      {
        fields: subscriptionFormFields,
        rules: subscriptionFormRules,
        labels: subscriptionFormLabels,
        values: Object.assign({}, subscription.asFormValues, {
          accountType: subscription.accountType
        })
      },
      {
        options: subscriptionFormOptions,
        plugins: subscriptionFormPlugins
      }
    );
  }

  @action.bound
  cancelEditSubscriptionClick() {
    this.editingSubscription = false;
    this.subscriptionForm = null;
  }

  @action.bound
  resolveSubscriptionClick() {
    this.resolvingSubscription = true;

    if (!this.paymentMethods.isEmpty) {
      this.editPaymentMethodClick();
    } else {
      this.addPaymentMethodClick();
    }
  }

  @action.bound
  resolveSubscription() {
    const { plan } = this.subscription;
    const payload = {
      ...this.subscription.asFormValues,
      billingPlanName: plan.name,
      billingPlanKey: plan.planKey,
      subscriptionState: 'ACTIVE'
    };
    this.subscription
      .save(payload, {
        wait: true,
        method: 'put'
      })
      .then(() => {
        runInAction(() => {
          this.resolvingSubscription = false;
          this.showModal('subscriptionConfirmation');
        });
      });
  }

  @action.bound
  async fetchBillingData() {
    const { company } = this;

    this.loading = true;

    if (!this.subscriptionPlans.length) {
      await this.subscriptionPlans
        .fetch({
          params: {
            mn: 'full',
            mc: 'full'
          }
        })
        .then(() => {
          this.clearSubscriptionPlansFailedToFetch();
        })
        .catch(error => {
          this.setSubscriptionPlansFailedToFetch();
        });
    }

    await this.paymentMethods
      .fetch({
        params: {
          mn: 'full',
          mc: 'full',
          businessId: company.uuid
        }
      })
      .then(() => {
        this.clearPaymentMethodsFailedToFetch();
      })
      .catch(error => {
        this.setPaymentMethodsFailedToFetch();
      });

    await this.transactions
      .fetch({
        params: {
          limit: 1000
        }
      })
      .then(() => {
        this.clearTransactionHistoryFailedToFetch();
      })
      .catch(error => {
        this.setTransactionHistoryFailedToFetch();
      });

    this.loading = false;
  }

  @action.bound setPaymentMethodsFailedToFetch() {
    this.paymentMethodsFailedToFetch = true;
  }

  @action.bound clearPaymentMethodsFailedToFetch() {
    this.paymentMethodsFailedToFetch = false;
  }

  @action.bound setTransactionHistoryFailedToFetch() {
    this.transactionHistoryFailedToFetch = true;
  }

  @action.bound clearTransactionHistoryFailedToFetch() {
    this.transactionHistoryFailedToFetch = false;
  }

  @action.bound setSubscriptionPlansFailedToFetch() {
    this.subscriptionPlansFailedToFetch = true;
  }

  @action.bound clearSubscriptionPlansFailedToFetch() {
    this.subscriptionPlansFailedToFetch = false;
  }

  @action.bound
  cancelPendingActionClick() {
    this.showModal('cancelPendingAction');
  }

  @action.bound
  cancelPendingAction() {
    const { plan } = this.subscription;

    const payload = {
      ...this.subscription.asFormValues,
      billingPlanName: plan.name,
      billingPlanKey: plan.planKey,
      upcomingBillingPlanKey: ''
    };

    this.subscription
      .save(payload, {
        wait: true,
        method: 'put'
      })
      .then(response => {
        runInAction(() => {
          this.subscription.upcomingPeriodType = null;
          this.hideActiveModal();
        });
      });
  }

  @action.bound
  downgradeNowClick() {
    this.showModal('downgradeNow');
  }

  @action.bound
  downgradeNow() {
    const { subscription } = this;
    const { upcomingPlan, plan } = subscription;

    const payload = {
      ...subscription.asFormValues,
      periodType: subscription.upcomingPeriodType || subscription.periodType,
      billingPlanKey: upcomingPlan ? upcomingPlan.planKey : plan.planKey,
      billingPlanName: upcomingPlan ? upcomingPlan.name : plan.name,
      forceDowngrade: true
    };

    if (payload.upcomingBillingPlanKey) {
      delete payload.upcomingBillingPlanKey;
    }

    this.subscription
      .save(payload, {
        wait: true,
        method: 'put',
        reset: true
      })
      .then(response => {
        runInAction(() => {
          this.hideActiveModal();
          subscription.upcomingPeriodType = null;
          subscription.forceDowngrade = false;

          this.fetchChildBillingData();
        });
      })
      .catch(error => {
        errorHandler(error, this.notifications.pushError);
      });
  }

  @computed
  get estimatedAnnualSavings() {
    const quantity = this.seatCountFromForm;
    const plan = this.planFromForm;
    const discount = this.discountFromForm;

    if (!plan) return null;

    let annualTotal = plan.annualSeatPrice * quantity * 12;
    let monthlyTotal = plan.seatPrice * quantity * 12;

    if (discount) {
      annualTotal -= annualTotal * (discount / 100);
      monthlyTotal -= monthlyTotal * (discount / 100);
    }

    const savings = numberWithCommas((monthlyTotal - annualTotal) / 100);

    if (this.showUpsell) {
      return `save $${savings}`;
    }

    if (this.subscriptionForm.$('periodType').value === 'ANNUAL') {
      return `You are saving $${savings}!`;
    }

    return `Switch to annual to save $${savings}!`;
  }

  @computed
  get totalBillWithoutDiscount() {
    const quantity = this.seatCountFromForm;
    const plan = this.planFromForm;

    if (!plan) return null;

    let annualTotal = (plan.annualSeatPrice * quantity * 12) / 100;
    let monthlyTotal = (plan.seatPrice * quantity) / 100;

    return {
      monthly: monthlyTotal,
      annually: annualTotal
    };
  }

  @computed
  get totalBillDiscount() {
    const discount = this.discountFromForm;
    const totalBillWithoutDiscount = this.totalBillWithoutDiscount;

    if (!discount) return null;

    let annualTotal = totalBillWithoutDiscount.annually;
    let monthlyTotal = totalBillWithoutDiscount.monthly;

    return {
      monthly: monthlyTotal * (discount / 100),
      annually: annualTotal * (discount / 100)
    };
  }

  @computed
  get totalBill() {
    const discount = this.discountFromForm;
    const isDiscountPermanent = this.subscriptionForm.$('isDiscountPermanent')
      .value;
    const totalBillWithoutDiscount = this.totalBillWithoutDiscount;
    const totalBillDiscount = this.totalBillDiscount;

    if (!totalBillWithoutDiscount) return null;

    let annualTotal = totalBillWithoutDiscount.annually;
    let monthlyTotal = totalBillWithoutDiscount.monthly;

    if (discount && isDiscountPermanent) {
      annualTotal -= totalBillDiscount.annually;
      monthlyTotal -= totalBillDiscount.monthly;
    }

    return {
      monthly: monthlyTotal,
      annually: annualTotal
    };
  }

  @computed
  get costTodayValues() {
    const form = this.subscriptionForm;

    if (!form) return 0;

    const seats = parseInt(form.$('seats').value, 10);

    return {
      seats: seats,
      discount: parseFloat(form.$('discount').value),
      billingPlanKey: form.$('billingPlanKey').value,
      periodType: form.$('periodType').value,
      businessId: this.subscription.id,
      accountType: form.$('accountType').value
    };
  }

  @computed
  get costTodayAnnualValues() {
    const form = this.subscriptionForm;

    if (!form) return 0;

    const seats = parseInt(form.$('seats').value, 10);

    return {
      seats: seats,
      discount: parseFloat(form.$('discount').value),
      billingPlanKey: form.$('billingPlanKey').value,
      periodType: 'ANNUAL',
      businessId: this.subscription.id,
      accountType: form.$('accountType').value
    };
  }

  @action.bound
  fetchCostToday(formValues) {
    if (isNaN(formValues.seats)) return;

    // Don't make the call unless required.
    if (this.costTodayValues === 0) {
      this.costToday = 0;
      return;
    }

    if (this.activeModal === 'addSeats') {
      formValues.seats += this.subscription.seats;
    }

    const data = {
      annualSeatPrice: this.planFromForm
        ? this.planFromForm.annualSeatPrice
        : this.subscription.plan.annualSeatPrice,
      discount: formValues.discount || 0,
      periodType: formValues.periodType,
      seatPrice: this.planFromForm
        ? this.planFromForm.seatPrice
        : this.subscription.plan.seatPrice,
      seatsCount: formValues.seats
    };

    this.costToday = 'Calculating...';

    if (formValues.periodType === 'ANNUAL') {
      this.costTodayAnnual = 'Calculating...';
    }

    // Fetch monthly cost
    request
      .post(this.estimateUrl, data)
      .then(response => {
        runInAction(() => {
          const fields = response.data;

          if (formValues.periodType === 'ANNUAL') {
            this.costTodayAnnual = `$${numberWithCommas(
              fields.costToday / 100
            )}`;
          }

          this.costTodayFloat = parseFloat(fields.costToday / 100);
          this.costToday = `$${numberWithCommas(fields.costToday / 100)}`;
          this.discountToday = `$${numberWithCommas(
            (fields.costTodayNoDiscount - fields.costToday) / 100
          )}`;
        });
      })
      .catch(error => {
        errorHandler(error, this.notifications.pushError);
      });
  }

  @action.bound
  fetchCostTodayAnnual(formValues) {
    const form = this.subscriptionForm;

    if (!form) return;

    const periodType = form.$('periodType').value;

    if (isNaN(formValues.seats)) return;

    // Don't make the call unless required.
    if (this.costTodayAnnualValues === 0) {
      this.costTodayAnnual = '0';
      return;
    }

    if (this.activeModal === 'addSeats') {
      formValues.seats += this.subscription.seats;
    }

    const data = {
      annualSeatPrice: this.planFromForm
        ? this.planFromForm.annualSeatPrice
        : this.subscription.plan.annualSeatPrice,
      discount: formValues.discount || 0,
      periodType: formValues.periodType,
      seatPrice: this.planFromForm
        ? this.planFromForm.seatPrice
        : this.subscription.plan.seatPrice,
      seatsCount: formValues.seats
    };

    this.costTodayAnnual = 'Calculating...';

    if (periodType !== 'ANNUAL') {
      // Fetch monthly cost
      request.post(this.estimateUrl, data).then(response => {
        runInAction(() => {
          const fields = response.data;

          this.costTodayAnnual = `$${numberWithCommas(fields.costToday / 100)}`;
        });
      });
    }
  }

  @computed
  get planFromForm() {
    const form = this.subscriptionForm;

    if (!form) return null;

    return this.subscriptionPlans.getPlan(form.$('billingPlanKey').value);
  }

  @computed
  get discountFromForm() {
    const form = this.subscriptionForm;

    if (!form || !form.$('discount').check('isValid')) return 0;

    return form.$('discount').value;
  }

  @computed
  get seatCountFromForm() {
    const form = this.subscriptionForm;

    if (!form) return null;

    // Include the current seats if on the add seats modal
    if (this.activeModal === 'addSeats') {
      return (
        parseInt(form.$('seats').value, 10) +
        parseInt(this.subscription.seats, 10)
      );
    }

    return form.$('seats').value;
  }

  @computed
  get trialDaysRemaining() {
    const form = this.subscriptionForm;

    if (!form) return null;

    return Math.max(
      moment(form.$('trialEndDate').value)
        .startOf('day')
        .diff(moment(new Date()).startOf('day'), 'days'),
      0
    );
  }

  @action.bound showTimeFrameModal() {
    if (!this.timeFrameForm) {
      this.timeFrameForm = new TimeFrameForm(
        {
          fields: timeFrameFormFields,
          rules: timeFrameFormRules,
          labels: timeFrameFormLabels,
          values: Object.assign(timeFrameFormValues, {
            fromDate: moment(this.transactions.fromDate).format('YYYY-MM-DD'),
            toDate: moment(this.transactions.toDate).format('YYYY-MM-DD')
          })
        },
        {
          options: timeFrameFormOptions,
          plugins: timeFrameFormPlugins,
          rootStore: this.rootStore
        }
      );
    }

    this.showModal('timeFrame');
  }

  @action.bound hideTimeFrameModal() {
    this.hideActiveModal().then(() => {
      runInAction(() => {
        this.timeFrameForm = null;
      });
    });
  }

  @action.bound cancelTimeFrameModal() {
    this.hideActiveModal().then(() => {
      runInAction(() => {
        this.transactions.setDateFilter('all');
        this.timeFrameForm = null;
        this.transactions.resetPage();
      });
    });
  }

  @action.bound setTimeFrame(event) {
    if (event) {
      event.preventDefault();
    }

    const values = this.timeFrameForm.values();

    if (!values.fromDate || !values.toDate) {
      this.hideTimeFrameModal();
      return;
    }

    this.transactions.setTimeFrame({
      fromDate: moment(values.fromDate),
      toDate: moment(values.toDate)
    });

    this.transactions.resetPage();

    this.hideTimeFrameModal();
  }

  @computed
  get hasPromoBanner() {
    if (!this.company) return false;

    const { isPromo } = this.subscription;

    if (!isPromo && this.remainingPromoDays !== 0) return false;

    if (this.remainingPromoDays === null) return false;

    return this.remainingPromoDays >= 0;
  }

  @computed
  get remainingPromoDays() {
    const { promoLeftDaysCount } = this.subscription;

    if (!valueNotEmpty(promoLeftDaysCount)) return null;

    return promoLeftDaysCount;
  }

  /**
   * top corners of Subscription heading:
   *  true - has warning message, not rounded
   *  false - no warning message, rounded
   */
  @computed
  get hasWarning() {
    const { subscription, paymentMethods } = this;

    if (
      subscription.isCancelled ||
      subscription.onHold ||
      subscription.pastDue ||
      subscription.onTrial ||
      subscription.onStarterPlan ||
      !this.hasCreditCard ||
      paymentMethods.card.hasExpired ||
      paymentMethods.card.isExpiring
    ) {
      return true;
    }

    return false;
  }

  /**
   * returned value controls if
   *  - credit card warning message is displayed
   *  - upgrade button is displayed when promo is active
   */
  @computed
  get hasCreditCard() {
    const { paymentMethods, subscription } = this;

    return (
      !paymentMethods.isEmpty &&
      (subscription.billingPlatform === 'CREDIT_CARD' ||
        subscription.billingPlatform === 'IN_APP')
    );
  }

  @computed
  get hasDisabled() {
    let data = null;

    if (this.subscription.onTrial) {
      return {
        Starter: false,
        Basic: false,
        Professional: false,
        Performance: false
      };
    }

    if (this.billingPlan === 'Starter') {
      data = {
        Starter: false,
        Basic: false,
        Professional: false,
        Performance: false
      };
    }

    if (this.billingPlan === 'Basic') {
      data = {
        Starter: false,
        Basic: false,
        Professional: false,
        Performance: false
      };
    }

    if (this.billingPlan === 'Professional') {
      data = {
        Starter: true,
        Basic: true,
        Professional: false,
        Performance: false
      };
    }

    if (this.billingPlan === 'Performance') {
      data = {
        Starter: true,
        Basic: true,
        Professional: true,
        Performance: false
      };
    }

    return data;
  }

  @action.bound
  fetchChildBillingData() {
    if (this.rootStore.isSuperAdmin) {
      this.superAdminCompanyBillingSubscription.fetchBillingData();
    }
  }

  @computed
  get superAdminCompanyBillingSubscription() {
    return this.rootStore.superAdminUI.superAdminCompaniesUI
      .superAdminCompanySubscriptionBillingUI;
  }
}
