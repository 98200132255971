import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import { t } from 'utils/translate';

const observationFiltersFormPlugins = extendedPlugins;

const observationFiltersFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const observationFiltersFormFields = [
  'positive',
  'negative',
  'types',
  'statuses',
  'priorities',
  'locations',
  'createdBy',
  'assignees',
  'companies',
  'startDueDate',
  'endDueDate'
];

const observationFiltersFormLabels = {
  types: t('Types'),
  statuses: t('Status'),
  priorities: t('Priority'),
  locations: t('Locations'),
  createdBy: t('Created by'),
  assignees: t('Assignees'),
  companies: t('Company name'),
  startDueDate: t('Start date'),
  endDueDate: t('End date')
};

const observationFilterFormValues = {
  positive: true,
  negative: true,
  types: [],
  statuses: [],
  priorities: [],
  locations: [],
  createdBy: [],
  assignees: [],
  companies: [],
  startDueDate: '',
  endDueDate: ''
};

class ObservationFiltersForm extends MobxReactForm {}

export {
  ObservationFiltersForm,
  observationFilterFormValues,
  observationFiltersFormOptions,
  observationFiltersFormFields,
  observationFiltersFormLabels,
  observationFiltersFormPlugins
};
