import { Model } from 'mobx-mc';

export default class IncidentConstant extends Model {
  get restAttributes() {
    return ['title', 'type', 'value'];
  }

  get urlRoot() {
    return `${this.rootStore.urlMicroService('toolbox')}/companies/${
      this.rootStore.me.company.uuid
    }/incident-reports/constants`;
  }
}
