import { Model } from 'mobx-mc';

export default class MaterialStats extends Model {
  get restAttributes() {
    return [
      'budgetedHours',
      'actualHours',
      'remainingHours',
      'completeHoursPercent',
      'completeQuantityPercent',
      'projectedTotalHours',
      'projectedHoursGainOrLoss'
    ];
  }

  get restAttributeDefaults() {
    return {
      budgetedHours: 0,
      actualHours: 0,
      remainingHours: 0,
      completeHoursPercent: 0,
      completeQuantityPercent: 0,
      projectedTotalHours: 0,
      projectedHoursGainOrLoss: 0
    };
  }
}
