import { RakenCollection } from '../lib';
import CertificationType from '../models/CertificationType';
import { action } from 'mobx';

export default class CertificationTypes extends RakenCollection {
  model() {
    return CertificationType;
  }

  url() {
    return `/ra/companies/${this.rootStore.me.company.uuid}/certification-types`;
  }

  @action.bound
  fetchCertificationType(requestParams) {
    this.fetch({
      params: requestParams
    });
  }
}
