import DocumentsFile from 'stores/models/documents/DocumentsFile';

import DocumentsFolder from 'stores/models/documents/DocumentsFolder';
import { RakenCollection } from '../../lib';

export default class DocumentsFolderChildren extends RakenCollection {
  model(type) {
    switch (type) {
      case 'FOLDER':
        return DocumentsFolder;
      case 'FILE':
        return DocumentsFile;
      default:
        return DocumentsFile;
    }
  }
}
