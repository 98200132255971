import { computed } from 'mobx';
import { Model } from 'mobx-mc';

export default class Plan extends Model {
  get restAttributes() {
    return [
      'id',
      'name',
      'code',
      'description',
      'intervalLength',
      'intervalUnit',
      'totalBillingCycles',
      'unitAmount',
      'addOns',
      'category'
    ];
  }

  @computed get isFlatRate() {
    return (
      (this.category === 'flat-rate' ||
        this.code.includes('performance-enterprise')) &&
      this.code !== 'performance-enterprise-legacy'
    );
  }

  @computed get isLegacy() {
    return this.code === 'performance-enterprise-legacy';
  }

  @computed get isStandard() {
    return !this.isFlatRate && !this.isLegacy;
  }

  @computed get isMonthly() {
    return this.isStandard && (this.code || '').includes('month');
  }
}
