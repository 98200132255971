import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import pick from 'lodash.pick';
import { computed } from 'mobx';
import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';
import { t } from 'utils/translate';

const settingsInfoFormPlugins = extendedPlugins;

const settingsInfoFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const settingsInfoFormFields = [
  'name',
  'projectNo',
  'address',
  'address.streetAddress',
  'address.postCode',
  'address.state',
  'address.city',
  'address.country',
  'startDate',
  'endDate',
  'projectState',
  'projectImage',
  'groups[]',
  'groups.uuid'
];

const settingsInfoFormRules = {
  name: 'required|string',
  projectNo: 'string',
  'address.streetAddress': 'string|max:100',
  'address.city': 'string|max:100',
  'address.state': 'string|max:255',
  'address.postcode': 'string|max:20',
  'address.country': 'string',
  startDate: 'required|dateCustom:YYYY-MM-DD',
  endDate: 'dateCustom:YYYY-MM-DD|afterCustom:startDate,YYYY-MM-DD',
  projectState: 'required|string',
  projectImage: 'string',
  groups: 'array'
};

// if externalId exists, any change to the following fields will trigger a warning modal when saving the changes.
// don't check `address/location` since any other address fields change will change `address/location`, and vice versa.
const settingsInfoFormSaveWarningFields = [
  'name',
  'address.streetAddress',
  'address.city',
  'address.state',
  'address.postCode',
  'address.country',
  'projectState',
  'startDate',
  'endDate'
];

const settingsInfoFormValues = {
  projectState: 'ACTIVE',
  address: {
    country: 'US'
  },
  groups: []
};

const settingsInfoFormLabels = {
  name: t('project name'),
  projectNo: t('job #'),
  'address.streetAddress': t('street address'),
  'address.city': t('city'),
  'address.state': t('state'),
  'address.postCode': t('zip code'),
  'address.country': t('country'),
  startDate: t('project start date'),
  endDate: t('project end date'),
  projectState: t('project state'),
  projectImage: t('project image'),
  groups: t('project groups')
};

class SettingsInfoForm extends MobxReactForm {
  @computed
  get addressString() {
    const properties = pickBy(
      pick(this.$('address').value, [
        'streetAddress',
        'city',
        'postCode',
        'country'
      ]),
      identity
    );

    return Object.keys(properties)
      .filter(k => {
        return properties[k] !== 'null';
      })
      .map(k => {
        return properties[k].replace('.', '');
      })
      .join(', ');
  }
}

export {
  SettingsInfoForm,
  settingsInfoFormOptions,
  settingsInfoFormFields,
  settingsInfoFormRules,
  settingsInfoFormValues,
  settingsInfoFormLabels,
  settingsInfoFormPlugins,
  settingsInfoFormSaveWarningFields
};
