import { computed } from 'mobx';
import { t } from 'utils/translate';

import LiveViewUI from './LiveViewUI';
import Integration from 'stores/models/integrations/Integration';

export default class DroneDeployUI extends LiveViewUI {
  constructor(options) {
    super(options);

    this.loading = true;

    this.integration = new Integration(
      { id: 1023 },
      {
        rootStore: this.rootStore,
        collection: this.rootStore.integrations
      }
    );
  }

  @computed get projectLevelOverviewText() {
    if (!this.integration.isConnected) {
      return this.integration.overviewText;
    }

    if (this.hasProjectLevelConfigurations) {
      return t(
        'You have added one or more DroneDeploy sources to your other Raken Projects. To add a source for this project, click the button below.'
      );
    }

    return this.integration.connectedText;
  }
}
