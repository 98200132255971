import styled from 'styled-components';
import Align from './Align';

const Badge = styled(Align)`
  align-items: center;
  background-color: ${props => props.theme.colors[props.color]};
  border-radius: 50%;
  color: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  font-weight: 700;
  justify-content: center;
  font-size: 10px;
  width: 25px;
  height: 25px;
  *:hover > & {
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors[props.color]};
  }
`;

Badge.defaultProps = {
  color: 'rakenOrange'
};

export default Badge;
