import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';
import { t } from 'utils/translate';

const companyPDFLogoFormPlugins = extendedPlugins;

const companyPDFLogoFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const companyPDFLogoFormFields = ['companyLogo', 'primaryColor'];

const companyPDFLogoFormRules = {
  companyLogo: 'string',
  primaryColor: 'string'
};

const companyPDFLogoFormLabels = {
  companyLogo: t('Add your company logo here'),
  primaryColor: t('Change company color')
};

class CompanyPDFLogoForm extends MobxReactForm {}

export {
  CompanyPDFLogoForm,
  companyPDFLogoFormOptions,
  companyPDFLogoFormFields,
  companyPDFLogoFormRules,
  companyPDFLogoFormLabels,
  companyPDFLogoFormPlugins
};
