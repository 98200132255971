import flatten from 'lodash.flatten';
import { action, observable, computed } from 'mobx';
import ProjectChildEditUI from '../ProjectChildEditUI';
import Checklist from 'stores/models/checklists/Checklist';

import {
  CHECKLIST_SAVED,
  CHECKLIST_SIGNATURE_COMPLETED,
  CHECKLIST_COMPLETED_VIEWED
} from 'utils/segmentAnalytics/eventSpec';

import { callTrack } from 'utils/segmentIntegration';

import {
  checklistFormFields,
  checklistFormOptions,
  checklistFormPlugins,
  checklistFormRules,
  checklistFormLabels,
  ChecklistForm
} from 'forms/checklist';

import ChecklistObservationUI from './ChecklistObservationUI';
import ChecklistObservationAddUI from './ChecklistObservationAddUI';
import ChecklistObservationEditUI from './ChecklistObservationEditUI';

import history from 'utils/history';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import formatChecklistDateToUTC from 'utils/formatChecklistDateToUTC';

export default class ChecklistEditUI extends ProjectChildEditUI {
  @observable entryForEdit;
  @observable entryEditForm;
  @observable padSigned;
  @observable signature;

  @observable responsibleType;

  constructor(options) {
    super(options);

    this.entryForEdit = null;
    this.entryEditForm = null;
    this.padSigned = false;
    this.signature = null;

    this.responsibleType = 'WorkerClassification';

    this.checklistObservationUI = new ChecklistObservationUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.checklistObservationAddUI = new ChecklistObservationAddUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.checklistObservationEditUI = new ChecklistObservationEditUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @computed
  get checklistCompletedUI() {
    return this.rootStore.companyUI?.checklistCompletedUI;
  }

  @action.bound setup(id) {
    this.fetchEntry(id);
    this.classificationSelectorUI.setup({
      responsibleSort: true
    });
    this.collaboratorSelectorUI.setup();
  }

  @action.bound tearDown() {
    this.clearValidationDetails();
    this.clearUIState();
    this.classificationSelectorUI.tearDown();
    this.collaboratorSelectorUI.tearDown();
    this.unblockHistory();
  }

  @action.bound async fetchEntry(uuid) {
    if (this.selectedChecklist) return;

    let model = this.parent.checklists.get(uuid);

    if (!model) {
      model = new Checklist(
        { uuid: uuid },
        {
          rootStore: this.rootStore
        }
      );
    }

    try {
      await model.fetch();

      this.setEntryForEdit(model);
    } catch (error) {
      this.cancelChecklistEdit({
        redirect: true
      });
    }
  }

  @action.bound async setEntryForEdit(model) {
    this.entryForEdit = model;

    if (model.status !== 'ACTIVE' && !this.authorization.canModifyChecklists) {
      this.cancelChecklistEdit({
        redirect: true
      });

      this.authorization.showModal('featureAccess');

      return;
    }

    this.setupEntryEditForm();

    if (this.entryForEdit.isActive) {
      callTrack(CHECKLIST_COMPLETED_VIEWED, {
        checklist_name: this.entryForEdit.name
      });
    }
  }

  @action.bound setupEntryEditForm() {
    this.responsibleType =
      this.entryForEdit.responsible?.type || 'WorkerClassification';

    this.entryEditForm = new ChecklistForm(
      {
        fields: checklistFormFields,
        rules: checklistFormRules,
        labels: checklistFormLabels,
        values: this.entryForEdit.formValues
      },
      {
        options: checklistFormOptions,
        plugins: checklistFormPlugins,
        rootStore: this.rootStore
      }
    );

    this.entryEditForm.$('checklistSections').map(section => {
      section.$('checklistQuestions').map(question => {
        if (question.value.required) {
          question.$('checklistResponses').map(response => {
            if (question.value.responseType === 'MULTI_CHOICE') {
              response.$('choices').set('rules', 'arrayHasTrue:selected');
            } else if (question.value.responseType === 'CHECKBOX') {
              response.$('value').set('rules', 'required|is:true');
            } else if (question.value.responseType === 'YES_NO_NA') {
              response.$('value').set('rules', 'required|in:Yes,No,N/A');
            } else if (question.value.responseType === 'YES_NO') {
              response.$('value').set('rules', 'required|in:Yes,No');
            } else if (question.value.responseType === 'TEXT') {
              response.$('value').set('rules', 'required|max:10000');
            } else if (question.value.responseType === 'SIGNATURE') {
              response.$('responseSignature').set('rules', {
                signature: 'required',
                signedBy: 'required'
              });
            } else {
              response.$('value').set('rules', 'required');
            }
          });
        } else {
          question.$('checklistResponses').map(response => {
            if (question.value.responseType === 'TEXT') {
              response.$('value').set('rules', 'max:10000');
            }
          });
        }
      });
    });

    this.blockHistoryIfFormChanged();
  }

  @action.bound blockHistoryIfFormChanged() {
    this.unblock = history.block((location, action) => {
      if (!this.entryEditForm?.isDefault) {
        this.showDiscardModal(location.pathname);
        return 'Blocked';
      }
    });
  }

  @computed
  get formValuesCheckListSections() {
    return this.entryEditForm.values().checklistSections.map(section => {
      const questions = section.checklistQuestions.map(question => {
        const { uuid, ...noUuid } = question;
        // We can use is new to tell if a Checklist was built up from a template or not.
        return this.entryForEdit.isNew ? noUuid : question;
      });

      const { checklistQuestions, ...noQuestions } = section;

      questions.forEach(checklistQuestion => {
        const response = checklistQuestion.checklistResponses[0];

        response.checklistResponseAttachments = this.entryForEdit.attachments
          .filter(attachment => {
            return (
              attachment.collection.parent.uniqueId ===
              checklistQuestion.checklistResponses[0].uuid
            );
          })
          .map(attachment => {
            return {
              uuid: attachment.uuid
            };
          });

        response.observations = this.entryForEdit.observations
          .filter(observation => {
            return (
              observation.collection.parent.uniqueId ===
              checklistQuestion.checklistResponses[0].uuid
            );
          })
          .map(observation => ({
            uuid: observation.uuid
          }));

        if (this.entryForEdit.isNew) {
          delete response.uuid;
        }
      });

      const sectionAndQuestions = {
        checklistQuestions: questions,
        ...noQuestions
      };

      if (this.entryForEdit.isNew) {
        delete sectionAndQuestions.uuid;
      }

      return sectionAndQuestions;
    });
  }

  /**
   * Required because validationjs rules does not fully work on MULTI_CHOICE options.
   * (Possible library implementation bug)
   *
   * @returns {Boolean}
   */
  @computed
  get formPassesAdditionalValidation() {
    const questions = flatten(
      this.formValuesCheckListSections.map(
        section => section.checklistQuestions
      )
    );

    const hasError =
      questions.find(question => {
        const response = question.checklistResponses[0];

        if (question.required) {
          // Add additional non-validationjs validation here.
          if (question.responseType === 'MULTI_CHOICE') {
            return !response.choices.some(choice => choice.selected);
          }
        }
      }) || false;

    return !hasError;
  }

  @computed
  get uploadRunning() {
    return this.entryForEdit.hasAttachmentsUploading;
  }

  @computed
  get disableSaveButton() {
    return this.uploadRunning || this.entryForEdit?.saving;
  }

  @action.bound async submitEntryEditForm() {
    this.saving = true;

    const values = this.entryEditForm.values();

    let payload = {
      status: 'DRAFT',
      name: values.name,
      responsible: values.responsible.uuid
        ? {
            type: this.responsibleType,
            uuid: values.responsible.uuid
          }
        : null,
      location: {
        uuid: values.location.uuid || null
      },
      checklistDate: formatChecklistDateToUTC(
        values.checklistDate,
        values.checklistTime
      ),
      checklistSections: this.formValuesCheckListSections
    };

    try {
      await this.entryForEdit.save(payload, {
        stripNonRest: false,
        wait: true
      });

      callTrack(CHECKLIST_SAVED, {
        status: 'draft',
        checklist_name: this.entryForEdit.name,
        location_name: values.location?.name
      });

      this.unblockHistory();
      this.parent.refetchChecklists();
      this.cancelChecklistEdit();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Checklist draft saved')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  async openSignAndCompleteChecklistModal() {
    this.entryEditForm.submit({
      onSuccess: () => {
        if (!this.formPassesAdditionalValidation) {
          this.showModal('checklistMandatoryQuestionsValidation');
        } else {
          this.showModal('checklistsViewerSignModal');
        }
      },
      onError: this.submitEntryEditFormError
    });
  }

  @action.bound signPad(signature) {
    this.padSigned = true;
    this.signature = signature;
  }

  @action.bound unsignPad() {
    this.padSigned = false;
    this.signature = null;
  }

  @action.bound
  async signAndCompleteChecklist(signature) {
    this.saving = true;

    const values = this.entryEditForm.values();

    const payload = {
      status: 'ACTIVE',
      name: values.name,
      checklistSections: this.formValuesCheckListSections,
      responsible: {
        type: this.responsibleType,
        uuid: values.responsible.uuid
      },
      location: {
        uuid: values.location.uuid || null
      },
      checklistDate: formatChecklistDateToUTC(
        values.checklistDate,
        values.checklistTime
      ),
      checklistSignatures: [
        {
          signature: signature
        }
      ]
    };

    try {
      await this.entryForEdit.save(payload, {
        stripNonRest: false,
        wait: true
      });

      callTrack(CHECKLIST_SIGNATURE_COMPLETED, {
        project_id: this.project.uuid,
        checklist_name: this.entryForEdit.name,
        location_name: values.location?.name
      });

      this.unblockHistory();
      this.unsignPad();
      this.parent.refetchChecklists();
      this.hideActiveModal();
      this.cancelChecklistEdit();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Checklist signed')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  openQuestionSignatureModal(modalId) {
    this.showModal(modalId);
  }

  @action.bound clearUIState() {
    this.activeModal = null;
    this.nextUrl = null;
    this.clearValidationDetails();
    this.entryForEdit = null;
    this.entryEditForm = null;
    this.saving = false;

    this.padSigned = false;
    this.signature = null;

    this.responsibleType = 'WorkerClassification';
  }

  @action.bound cancelChecklistEdit(options = {}) {
    if (options.redirect) {
      history.replace({
        pathname: `${this.project.viewUrl}/checklists`,
        search: this.baseQueryParams
      });
    } else {
      history.push({
        pathname: `${this.project.viewUrl}/checklists`,
        search: this.baseQueryParams
      });
    }
  }

  @computed get disableMeta() {
    return this.entryForEdit.companyUuid !== this.company.uuid;
  }

  @computed get viewMode() {
    return this.entryForEdit?.isActive;
  }

  @action.bound deleteChecklist() {
    this.parent.deleteChecklist(this.entryForEdit);
  }

  @action.bound setResponsibleType(value) {
    this.responsibleType = value;
    this.entryEditForm.$('responsible').clear();
    this.entryEditForm.$('responsible.uuid').resetValidation();
  }
}
