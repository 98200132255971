import UIStore from './UIStore';
import { action, observable, runInAction, computed } from 'mobx';
import request from 'axios';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';
import errorHandler from 'utils/errorHandler';
import moment from 'moment-timezone';

import {
  ChecklistsReportForm,
  checklistsReportFormRules,
  checklistsReportFormFields,
  checklistsReportFormOptions,
  checklistsReportFormPlugins,
  checklistsReportFormValues
} from 'forms/checklistsReport';
import { callTrack } from 'utils/segmentIntegration';
import { Checklist_Download_Excel } from 'utils/segmentAnalytics/eventSpec';

export default class ChecklistssReportsUI extends UIStore {
  @observable recentPayPeriodOptions;
  @observable allPayPeriodOptions;
  @observable loading;
  @observable checklistNames;

  constructor(options) {
    super(options);

    //Pay periods
    this.recentPayPeriodOptions = [];
    this.allPayPeriodOptions = [];

    this.loading = false;
    this.checklistNames = [];
  }

  @action.bound
  async setup() {
    await this.fetchChecklistNames();
    this.initReportForm();
  }

  @action.bound
  tearDown() {
    this.checklistNames = [];
  }

  @action.bound
  initReportForm() {
    this.activeForm = new ChecklistsReportForm(
      {
        fields: checklistsReportFormFields,
        rules: checklistsReportFormRules,
        values: checklistsReportFormValues
      },
      {
        options: checklistsReportFormOptions,
        plugins: checklistsReportFormPlugins
      }
    );
  }

  @computed get projectDefaultValue() {
    if (this.project) {
      return [
        {
          value: this.project.uuid,
          name: this.project.name
        }
      ];
    }

    return [];
  }

  @computed get checklistNamesOptions() {
    return this.checklistNames.collection.slice().flatMap(item => {
      return item.checklists.map(checklist => {
        const name = checklist;
        const type = item.type;
        return {
          name,
          type
        };
      });
    });
  }

  @action.bound async fetchChecklistNames() {
    try {
      const response = await request.get(
        `${this.rootStore.urlMicroService('toolbox')}/companies/${
          this.rootStore.me.company.uuid
        }/checklists/namesGroupedByType`
      );
      this.checklistNames = response.data;
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async openChecklistsReport() {
    this.loading = true;

    await this.authorization.checkFeatureAccess('ViewChecklistReports', true);
    this.showModal('checklistsReport');
    await this.setup();
    if (this.project) {
      this.setProjectIncludeOption('SELECTED');
      this.selectProjects(this.projectDefaultValue);
    } else {
      this.setProjectIncludeOption('ACTIVE');
    }

    this.loading = false;
  }

  @action.bound
  hideChecklistsReport() {
    this.hideActiveModal().then(() => {
      runInAction(() => {
        this.activeForm?.cancelReactToEnableDatePickers();
        this.activeForm = null;

        this.saving = false;
      });
    });
  }

  @computed
  get showSpecificPeriod() {
    return this.activeForm.$('payPeriodFilterStatus').value === 'SPECIFIC';
  }

  @action.bound
  selectProjects(selectedProjects) {
    this.activeForm.update({
      projects: selectedProjects
    });
  }

  @action.bound
  selectGroups(selectedGroups) {
    this.activeForm.update({
      groups: selectedGroups
    });
  }

  @computed
  get projectIncludeOptions() {
    return [
      {
        label: t('Active projects'),
        value: 'ACTIVE',
        dataQA: 'active-projects'
      },
      {
        label: t('Active and inactive projects'),
        value: 'ACTIVE_AND_INACTIVE',
        dataQA: 'active-and-inactive-projects'
      },
      {
        label: t('Selected projects'),
        value: 'SELECTED',
        dataQA: 'selected-projects'
      },
      {
        label: t('Selected project groups'),
        value: 'SELECTED_GROUPS',
        dataQA: 'selected-project-groups'
      }
    ];
  }

  @action.bound
  setProjectIncludeOption(value) {
    this.activeForm.$('projectIncludeOption').set('value', value);

    if (value === 'ACTIVE_AND_INACTIVE' || value === 'ACTIVE') {
      this.activeForm.update({
        projects: []
      });
    }
  }

  @computed
  get enableSend() {
    if (this.saving || !this.activeForm || !this.selectChecklistNameOption)
      return false;

    return this.activeForm.isValid;
  }

  @computed
  get minToDate() {
    return this.activeForm.$('fromDate').value
      ? moment(this.activeForm.$('fromDate').value).endOf('day')
      : undefined;
  }

  @action.bound
  async submitChecklistsReportForm(e) {
    e.preventDefault();
    e.stopPropagation();

    if (this.saving) return;

    this.clearValidationDetails();

    this.activeForm.submit({
      onSuccess: this.submitChecklistsReportFormSuccess,
      onError: this.submitChecklistsReportFormError
    });
  }

  @action.bound
  submitChecklistsReportFormError() {
    console.log(this.activeForm?.errors());
  }

  @action.bound
  async submitChecklistsReportFormSuccess() {
    const values = this.activeForm.values();

    const payload = {
      checklistName: values.checklistName,
      projectIncludeOption: values.projectIncludeOption,
      projectUuids:
        values.projectIncludeOption !== 'SELECTED'
          ? []
          : values.projects.map(project => project.value),

      emails: values.emails,
      fromDate: moment(values.fromDate).utc(),
      toDate: moment(values.toDate).utc(),
      localTimezone: moment.tz.guess()
    };

    payload.groupUuids =
      values.projectIncludeOption !== 'SELECTED_GROUPS'
        ? []
        : values.groups.map(group => group.uuid);

    const url = `${this.rootStore.urlMicroService('toolbox')}/companies/${
      this.rootStore.me.company.uuid
    }/checklists/export`;

    this.saving = true;

    try {
      await request.post(url, payload);
      this.hideChecklistsReport();

      callTrack(Checklist_Download_Excel);
      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Your download request has been sent.')
      });
    } catch (error) {
      this.saving = false;
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @computed
  get weekFirstDay() {
    return moment(new Date()).add(
      (this.company.preferences.weekFirstDay -
        moment(new Date()).isoWeekday() -
        7) %
        7,
      'days'
    );
  }

  @computed
  get weekLastDay() {
    return moment(this.weekFirstDay).add(6, 'days');
  }

  @computed
  get biWeeklyFirstDay() {
    return moment(this.weekFirstDay).subtract(7, 'days');
  }

  @computed get timeFrames() {
    const timeFrames = [
      {
        id: 'DAILY',
        fromDate: moment(new Date())
          .startOf('day')
          .format('YYYY-MM-DDTHH:mm:ss'),
        toDate: moment(new Date())
          .endOf('day')
          .format('YYYY-MM-DDTHH:mm:ss'),
        title: t('Current day'),
        projectEntire: false
      },
      {
        id: 'WEEKLY',
        fromDate: this.weekFirstDay
          .startOf('day')
          .format('YYYY-MM-DDTHH:mm:ss'),
        toDate: this.weekLastDay.endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
        title: t('Current week'),
        projectEntire: false
      },
      {
        id: 'BI_WEEKLY',
        fromDate: this.biWeeklyFirstDay
          .startOf('day')
          .format('YYYY-MM-DDTHH:mm:ss'),
        toDate: this.weekLastDay.endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
        title: t('Bi-weekly'),
        projectEntire: false
      },
      {
        id: 'MONTHLY',
        fromDate: moment(new Date())
          .startOf('month')
          .format('YYYY-MM-DDTHH:mm:ss'),
        toDate: moment(new Date())
          .endOf('month')
          .format('YYYY-MM-DDTHH:mm:ss'),
        title: t('Current month'),
        projectEntire: false
      },
      {
        id: 'SPECTIMEFRAME',
        title: t('Specific time frame'),
        projectEntire: false
      }
    ];

    if (this.numberOfSelectedProjects === 1) {
      timeFrames.push({
        id: 'PROJECTENTIRE',
        title: t('Entire duration of the project')
      });
    }

    return timeFrames;
  }

  @computed get selectedTimeFrameOption() {
    return this.timeFrames.find(
      timeFrame => timeFrame.id === this.activeForm.$('timeFrameType').value
    );
  }

  @computed get selectChecklistNameOption() {
    return this.checklistNamesOptions.find(
      checklistName =>
        checklistName.name === this.activeForm.$('checklistName').value
    );
  }

  @action.bound
  setTimeFrame(timeFrame) {
    const fromDate = timeFrame.fromDate ? timeFrame.fromDate : '';
    const toDate = timeFrame.toDate ? timeFrame.toDate : '';

    this.activeForm.update({
      timeFrameType: timeFrame.id,
      fromDate: fromDate,
      toDate: toDate
    });
  }

  @action.bound setFromDate(value) {
    this.activeForm.$('fromDate').set(
      moment(value)
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ss')
    );
  }

  @action.bound setToDate(value) {
    this.activeForm.$('toDate').set(
      moment(value)
        .endOf('day')
        .format('YYYY-MM-DDTHH:mm:ss')
    );
  }
}
