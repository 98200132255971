import { reaction, computed, action } from 'mobx';
import MobxReactForm from 'mobx-react-form';
import { t } from 'utils/translate';

import extendedPlugins from '../utils/extendedPlugins';
import toggleArrayFieldValue from 'forms/utils/toggleArrayFieldValue';

const companyInfoFormPlugins = extendedPlugins;

const companyInfoFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const companyInfoFormFields = [
  'name',
  'phone',
  'address',
  'address.city',
  'address.state',
  'address.postCode',
  'address.streetAddress',
  'address.country',
  'address.geolocation.lat',
  'address.geolocation.lng',
  'status',
  'stripeId',
  'parentCompany.id',
  'employeeRange',
  'industry',
  'subIndustries[]',
  'email',
  'ccEmails',
  'isInternal',
  'salesforceAccountLink'
];

const companyInfoFormRules = {
  name: 'required|string',
  phone: 'required',
  'address.geolocation.lat': 'numeric|min:-90|max:90',
  'address.geolocation.lng': 'numeric|min:-180|max:180',
  address: {
    country: 'required|string',
    city: 'string',
    state: 'string',
    postCode: 'string',
    streetAddress: 'string'
  },
  employeeRange: 'string',
  industry: 'string',
  subIndustries: 'array',
  email: 'required|email',
  ccEmails: 'emailList',
  isInternal: 'boolean',
  salesforceAccountLink: 'string'
};

const companyInfoFormValues = {
  address: {
    geolocation: {
      lat: 0,
      lng: 0
    }
  }
};

const companyInfoFormLabels = {
  name: t('Company name'),
  phone: t('Company phone'),
  address: {
    city: t('City'),
    state: t('State'),
    postCode: t('Zip Code'),
    streetAddress: t('Street Address'),
    country: t('Country')
  },
  stripeId: t('Stripe ID'),
  parentCompany: {
    id: t('Parent Business ID')
  },
  employeeRange: t('Employee Range'),
  industry: t('Industry'),
  subIndustries: t('Sub Industry'),
  email: t('Email'),
  ccEmails: t('CC emails'),
  isInternal: t('Internal'),
  salesforceAccountLink: t('Salesforce URL')
};

class CompanyInfoForm extends MobxReactForm {
  constructor(settings, options) {
    super(settings, options);

    this.reactToIndustry = reaction(
      () => this.$('industry').value,
      industry => {
        if (
          industry !== 'Self-Performing General Contractor' &&
          industry !== 'Subcontractor'
        ) {
          this.update({
            subIndustries: []
          });
        }
      }
    );
  }

  @computed get disableSubIndustry() {
    return (
      this.$('industry').value !== 'Subcontractor' &&
      this.$('industry').value !== 'Self-Performing General Contractor'
    );
  }

  @action toggleSubIndustry(value) {
    toggleArrayFieldValue(this, 'subIndustries', value);
  }
}

export {
  CompanyInfoForm,
  companyInfoFormOptions,
  companyInfoFormFields,
  companyInfoFormRules,
  companyInfoFormValues,
  companyInfoFormLabels,
  companyInfoFormPlugins
};
