import debounce from 'lodash.debounce';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import ProjectChildUI from './ProjectChildUI';

import Notes from '../../collections/Notes';
import NoteAddUI from './NoteAddUI';
import NoteEditUI from './NoteEditUI';

import history from 'utils/history';
import { BASE_DEBOUNCE } from 'fixtures/constants';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

export default class NotesUI extends ProjectChildUI {
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;
  @observable selectedNote;

  constructor(options) {
    super(options);

    this.loading = true;

    this.selectedNote = null;

    this.sortField = 'category';
    this.sortDirection = 'asc';
    this.page = 1;
    this.searchQuery = '';
    this.pageSize = 20;

    // Notes collection
    this.notes = new Notes(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.noteAddUI = new NoteAddUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.noteEditUI = new NoteEditUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchNotesDebounced = debounce(this.fetchNotes, BASE_DEBOUNCE);
  }

  @action.bound setup() {
    window.scrollTo(0, 0);
    this.setupReactions();
    this.fetchNotes();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchNotesDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed
  get params() {
    return {
      fromDate: this.date,
      toDate: this.date,
      projectUuids: this.projectUuid,
      segmentUuids: this.segmentUuid,
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      sortField: this.sortField,
      sortDirection: this.sortDirection
    };
  }

  @computed get hasRequiredParams() {
    return (
      this.params.projectUuids && this.params.fromDate && this.params.toDate
    );
  }

  @action.bound async fetchNotes() {
    if (!this.hasRequiredParams) return;

    this.notes.cancelRequest();
    this.notes.clear();

    try {
      await this.notes.fetch({
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasNotes() {
    return this.notes.hasModels;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.notes.totalElements / this.pageSize);
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.searchQuery = '';
    this.notes.clear();
    this.page = 1;
    this.loading = true;
    this.saving = false;
    this.sortField = 'category';
    this.sortDirection = 'asc';
    this.selectedNote = null;
  }

  @computed get showEmptyState() {
    return !this.loading && !this.searchQuery && !this.hasNotes;
  }

  @computed get showEmptySearchState() {
    return !this.loading && this.searchQuery && !this.hasNotes;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @action.bound async addNote() {
    await this.authorization.checkFeatureAccess('CreateNotes');

    history.push({
      pathname: `${this.project.viewUrl}/notes/add`,
      search: this.baseQueryParams
    });
  }

  @action.bound async editNote(note) {
    history.push({
      pathname: `${this.project.viewUrl}/notes/${note.uuid}`,
      search: this.baseQueryParams
    });
  }

  @action.bound async deleteNote(note) {
    await this.authorization.checkFeatureAccess('DeleteNotes');

    this.selectedNote = note;
    this.showModal('DeleteModal');
  }

  @action.bound async cancelDeleteNote() {
    await this.hideActiveModal();

    this.selectedNote = null;
  }

  @action.bound async confirmDeleteNote() {
    this.saving = true;

    try {
      await this.selectedNote.destroy({ wait: true });

      this.refetchNotes();

      await this.hideActiveModal();

      this.selectedNote = null;

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Note deleted')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound sortByLastCreated() {
    this.sortField = 'createdTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchNotesDebounced();
    this.refetchReportSummary();
  }

  @action.bound refetchNotes() {
    this.loading = true;
    if (!this.hasNotes) {
      this.setPage(null, 1);
      this.fetchNotes();
    } else {
      this.fetchNotes();
    }

    this.refetchReportSummary();
  }

  getNoteActions = note => {
    return [
      {
        title: this.hasWriteAccess ? t('Edit') : t('View'),
        onClick: () => {
          this.editNote(note);
        }
      },
      {
        title: t('Delete'),
        onClick: () => {
          this.deleteNote(note);
        }
      }
    ];
  };

  @computed get categoryOptions() {
    return [
      {
        value: 'NOTES',
        title: t('General Notes')
      },
      {
        value: 'SAFETY',
        title: t('Site Safety Observations')
      },
      {
        value: 'QUALITY',
        title: t('Quality Control Observations')
      }
    ];
  }
}
