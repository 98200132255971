import orderBy from 'lodash.orderby';
import request from 'axios';
import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';
import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import isEqual from 'lodash.isequal';
import sortBy from 'lodash.sortby';
import { observable, action, computed, reaction } from 'mobx';

import * as Validator from 'validatorjs';

import UIStore from './UIStore';
import ProjectsMinimal from '../collections/ProjectsMinimal';

import history from 'utils/history';
import { t } from 'utils/translate';
import { BASE_DEBOUNCE } from 'fixtures/constants';

import alertErrorHandler from 'utils/alertErrorHandler';
import crossOriginDownload from 'utils/crossOriginDownload';

export default class TeamWorkersImportUI extends UIStore {
  @observable step;

  // Projects
  @observable projectSelectionMethod;
  @observable loading;
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable isDefault;

  // Workers
  @observable importedWorkersPageSize;
  @observable importedWorkersPage;

  constructor(options) {
    super(options);

    this.step = 'import';

    // Projects
    this.paginatedProjects = new ProjectsMinimal(null, {
      rootStore: this.rootStore
    });

    this.loading = true;
    this.projectSelectionMethod = 'ACTIVE';
    this.selectedProjects = observable([]);
    this.isDefault = true;

    this.sortField = 'name';
    this.sortDirection = 'ASC';
    this.searchQuery = '';
    this.pageSize = 0;
    this.page = 1;

    this.fetchProjectsDebounced = debounce(this.fetchProjects, BASE_DEBOUNCE);
    this.fetchNextPageOfProjects = throttle(
      this.fetchNextPageOfProjects,
      BASE_DEBOUNCE
    );

    // Workers
    this.importedWorkersPageSize = 10;
    this.importedWorkersPage = 1;
    this.importedWorkers = observable([]);
  }

  @computed get projects() {
    return this.paginatedProjects;
  }

  @computed get hasProjects() {
    return this.projects.hasModels;
  }

  @action.bound setStep(step) {
    this.step = step;
  }

  @action.bound setup(step = 'import') {
    this.setStep(step);
    this.setupReactions();
    this.fetchProjects();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.cancelReactToParams = reaction(
      () => this.params,
      params => {
        this.fetchProjects();
      }
    );

    this.cancelReactToSearchQuery = reaction(
      () => this.searchQuery,
      params => {
        this.fetchProjectsDebounced();
      }
    );
  }

  tearDownReactions() {
    this.cancelReactToParams && this.cancelReactToParams();
    this.cancelReactToSearchQuery && this.cancelReactToSearchQuery();
  }

  @computed get classificationOptions() {
    return this.parent.classificationOptions;
  }

  @action.bound
  async fetchProjects() {
    this.loading = true;

    await this.projects.fetch({
      params: pickBy(
        Object.assign(this.params, {
          query: this.searchQuery
        }),
        identity
      )
    });

    this.loading = false;
  }

  @action.bound fetchNextPageOfProjects() {
    if (this.projects.fetching) return;

    if (this.projects.hasNextPage) {
      this.projects.fetchNextPage();
    }
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @computed get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      sortField: this.sortField,
      sortDirection: this.sortDirection,
      projectStates: 'ACTIVE'
    };
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }
  }

  @computed
  get totalPages() {
    return Math.ceil(this.projects.totalElements / this.pageSize);
  }

  @action.bound setProjectSelectionMethod(event) {
    if (event.target.value === 'ACTIVE') {
      this.toggleSelectAllProjects();
    }

    this.projectSelectionMethod = event.target.value;
  }

  @computed get hasSelectedProjects() {
    return this.selectedProjects.length > 0;
  }

  @action.bound
  toggleSelectProject(projectUuid) {
    if (this.selectedProjects.find(uuid => uuid === projectUuid)) {
      this.selectedProjects.remove(projectUuid);
    } else {
      this.selectedProjects.push(projectUuid);
    }
  }

  @computed
  get allProjectsSelected() {
    return (
      this.hasProjects &&
      this.projects.models.every(project =>
        this.selectedProjects.includes(project.uuid)
      )
    );
  }

  @action.bound
  toggleSelectAllProjects() {
    if (this.allProjectsSelected) {
      this.selectedProjects.replace(
        this.selectedProjects.filter(uuid => {
          return !this.projects.models
            .map(project => project.uuid)
            .includes(uuid);
        })
      );
    } else {
      this.projects.models.forEach(project => {
        if (!this.selectedProjects.includes(project.uuid)) {
          this.selectedProjects.push(project.uuid);
        }
      });
    }
  }

  @action.bound toggleIsDefault() {
    this.isDefault = !this.isDefault;
  }

  @action.bound cancelProjects() {
    this.setStep('workers');
  }

  @action.bound cancelWorkers() {
    this.importedWorkers.clear();
    this.setStep('import');
  }

  @action.bound async createWorkers() {
    if (this.saving) return;

    try {
      this.saving = true;

      const workerFields = this.importedWorkers.slice();

      if (this.projectSelectionMethod === 'SELECTED') {
        workerFields.forEach(workerField => {
          workerField.isDefault = this.isDefault;
          workerField.projectUuids = this.selectedProjects.slice();
          workerField.role = 'ROLE_WORKER';

          if (
            !workerField.classification.uuid &&
            !workerField.classification.name
          ) {
            delete workerField.classification;
          }
        });
      } else {
        workerFields.forEach(workerField => {
          delete workerField.projectUuids;
          workerField.isDefault = this.isDefault;
          workerField.role = 'ROLE_WORKER';
          workerField.addToActiveProjects = true;

          if (
            !workerField.classification.uuid &&
            !workerField.classification.name
          ) {
            delete workerField.classification;
          }
        });
      }

      const payload = workerFields.map(workerField =>
        pickBy(
          workerField,
          value => value !== undefined && value !== '' && value !== null
        )
      );

      // Send parallel requests if over 500.
      if (payload.length > 500) {
        await Promise.all([
          request.post(
            `${this.rootStore.users.url()}/import`,
            payload.slice(0, 500)
          ),
          request.post(
            `${this.rootStore.users.url()}/import`,
            payload.slice(500, 1000)
          )
        ]);
      } else {
        await request.post(`${this.rootStore.users.url()}/import`, payload);
      }

      // Used to get the hasWorkersWithoutContactDetails field when things change.
      this.company.fetch();

      history.push('/workers');

      this.parent.sortByLastCreated();
      this.classificationsUI.fetchClassifications();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Workers added')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound cancelWorkersImport() {
    history.push('/workers');
  }

  @action.bound
  downloadWorkersCSV(event) {
    event.preventDefault();

    crossOriginDownload(
      `${this.assetsURL}/csv/workers.csv`,
      `${t('workers')}.csv`
    );
  }

  @action.bound importWorkersCSV(file) {
    this.clearValidationDetails();

    const reader = new FileReader();

    reader.onload = event => {
      const text = event.target.result;

      this.processCSV(text);
    };

    reader.readAsText(file);
  }

  @action.bound processCSV(text, delim = ',') {
    let headers = text
      .trim()
      .slice(0, text.indexOf('\n'))
      .split(delim);

    const rows = text
      .trim()
      .slice(text.indexOf('\n') + 1)
      .split('\n');

    headers = headers.map(header => header.replace('\r', '').replace(/"/g, ''));

    const expectedHeaders = [
      'firstName',
      'lastName',
      'employeeId',
      'email',
      'phoneNumber',
      'classification'
    ];

    if (!isEqual(sortBy(expectedHeaders), sortBy(headers))) {
      this.setValidationDetails([
        {
          field: 'import',
          fieldMessage: t(
            'Your CSV is empty or does not follow the correct format. Please use the pre-formatted template.'
          )
        }
      ]);

      return;
    }

    const workerFields = rows.map(row => {
      const values = row.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);

      //check if a number of values in a row equals to a header columns
      if (values.length !== headers.length) {
        this.setValidationDetails([
          {
            field: 'import',
            fieldMessage: t(
              'Your CSV is empty or does not follow the correct format. Please use the pre-formatted template.'
            )
          }
        ]);

        return;
      }

      const eachObject = headers.reduce((obj, header, i) => {
        obj[header.trim()] = values[i].trim();
        return obj;
      }, {});
      return eachObject;
    });

    this.populateWorkersFormFromCSV(workerFields);
  }

  @action.bound populateWorkersFormFromCSV(workerFields) {
    this.importedWorkers.replace(
      workerFields.filter(worker => {
        worker.classification = {
          uuid: '',
          name: worker.classification
        };

        let validation = new Validator(worker, {
          firstName: 'string|max:255|required',
          lastName: 'string|max:255|required',
          employeeId: 'string|max:100',
          email: 'email|max:200',
          phoneNumber: 'string|max:30'
        });

        return !validation.fails();
      })
    );

    if (!this.hasImportedWorkers) {
      this.setValidationDetails([
        {
          field: 'import',
          fieldMessage:
            'The CSV file has no valid entries. Please check it and upload again.'
        }
      ]);

      return;
    }

    // Limit to 1000 valid records
    this.importedWorkers.replace(this.importedWorkers.slice(0, 1000));

    this.setStep('workers');
  }

  @computed get hasImportedWorkers() {
    return this.importedWorkers.length > 0;
  }

  @computed get showImportedWorkersPagination() {
    return this.importedWorkersTotalPages > 1;
  }

  @computed get sortedImportedWorkers() {
    return orderBy(
      this.importedWorkers,
      ['firstName', 'lastName'],
      ['asc', 'asc']
    );
  }

  @computed get paginatedImportedWorkers() {
    return this.sortedImportedWorkers.slice(
      (this.importedWorkersPage - 1) * this.importedWorkersPageSize,
      (this.importedWorkersPage - 1) * this.importedWorkersPageSize +
        this.importedWorkersPageSize
    );
  }

  @computed
  get importedWorkersTotalPages() {
    return Math.ceil(
      this.sortedImportedWorkers.length / this.importedWorkersPageSize
    );
  }

  @action.bound setImportedWorkersPage(event, page) {
    this.importedWorkersPage = page;
  }

  @action.bound submitWorkersForm(event) {
    event.preventDefault();

    this.setStep('projects');
  }

  @action.bound removeImportedWorker(worker) {
    this.importedWorkers.remove(worker);

    if (!this.hasImportedWorkers) {
      this.setStep('import');
    }
  }

  @action.bound clearUIState() {
    this.searchQuery = '';
    this.isDefault = true;
    this.selectedProjects.clear();
    this.projectSelectionMethod = 'ACTIVE';
    this.projects.clear();
    this.page = 1;
    this.step = 'import';
    this.clearValidationDetails();
    this.loading = false;
    this.importedWorkers.clear();
    this.importedWorkers.page = 1;
  }
}
