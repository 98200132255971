import { RakenCollection } from '../lib';
import ViolationsReport from '../models/ViolationsReport';

export default class ViolationsReports extends RakenCollection {
  model() {
    return ViolationsReport;
  }

  url() {
    return `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/timeclock/violation/reports/scheduled`;
  }
}
