import { action, computed, observable } from 'mobx';

import {
  ProjectTemplateBreaksForm,
  projectTemplateBreaksFormOptions,
  projectTemplateBreaksFormFields,
  projectTemplateBreaksFormRules,
  projectTemplateBreaksFormPlugins,
  projectTemplateBreaksFormLabels
} from 'forms/projectTemplates/projectTemplateBreaks';

import { t } from 'utils/translate';

import SettingsBreaksUI from 'stores/ui/project/SettingsBreaksUI';
import toggleArrayFieldValue from 'forms/utils/toggleArrayFieldValue';

import alertErrorHandler from 'utils/alertErrorHandler';

export default class ProjectTemplateBreaksNewUI extends SettingsBreaksUI {
  @observable form;
  constructor(options) {
    super(options);
    this.form = null;
    this.saveTitle = t('Project template breaks saved');
  }
  @computed get selectedTemplate() {
    return this.parent.selectedTemplate;
  }
  @computed get showEmptyState() {
    return !this.breaks.hasModels && !this.loading;
  }
  @action.bound async setup() {
    this.setupReactions();
    this.fetchBreaks();
    this.setupForm();
  }

  @action.bound
  setupForm() {
    this.form = new ProjectTemplateBreaksForm(
      {
        fields: projectTemplateBreaksFormFields,
        rules: projectTemplateBreaksFormRules,
        labels: projectTemplateBreaksFormLabels,
        values: this.selectedTemplate.breaksValues
      },
      {
        options: projectTemplateBreaksFormOptions,
        plugins: projectTemplateBreaksFormPlugins
      }
    );
  }

  @action.bound tearDown() {
    this.form = null;
    this.tearDownReactions();
    this.clearUIState();
  }

  @action.bound
  async togglePayTypeForBreak(selectedBreak) {
    toggleArrayFieldValue(this.form, 'hiddenBreakTypes', selectedBreak.uuid);

    try {
      await this.selectedTemplate.save(this.form.values(), {
        wait: true,
        url: `/ra/project-templates/${this.selectedTemplate.id}`
      });
      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: this.form
          .$('hiddenBreakTypes')
          .value?.find(
            hiddenBreakType => hiddenBreakType === selectedBreak.uuid
          )
          ? t('Break enabled')
          : t('Break disabled')
      });

      this.setupForm();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
      this.notifications.pushNotification({
        snackbar: 'error',
        icon: 'close',
        title: t('Break change cannot be saved')
      });
      toggleArrayFieldValue(this.form, 'hiddenBreakTypes', selectedBreak.uuid);
    } finally {
      this.saving = false;
    }
  }
}
