import React from 'react';

export default children => {
  let label = '';

  if (!React.Children.count(children)) return '';

  React.Children.forEach(children, child => {
    if (!child) return;

    while (child && child.type !== 'string' && child.props) {
      child = child.props.children;
    }

    if (!child || typeof child === 'object') return;

    if (child.trim() === '' || child.trim() === '-') return;

    if (label === '') {
      label += child;
    } else {
      label += `-${child}`;
    }
  });

  return label.replace(/ +/g, '-').toLowerCase();
};
