import moment from 'moment';
import { computed } from 'mobx';
import { Model } from 'mobx-mc';

export default class ViolationsReport extends Model {
  urlRoot = `${this.rootStore.urlMicroService(
    'performanceTracking'
  )}/timeclock/violation/reports/scheduled`;

  get restAttributes() {
    return [
      'uuid',
      'type',
      'name',
      'emails',
      'projectIncludeOption',
      'projects',
      'sendReport',
      'timeFrameType',
      'scheduleFrequency',
      'actionDate',
      'time'
    ];
  }

  idAttribute() {
    return 'uuid';
  }

  get restAttributeDefaults() {
    return {
      time: {
        hour: moment(Date.now()).format('hh'),
        minute: moment(Date.now()).format('mm')
      },
      projects: []
    };
  }

  @computed get formValues() {
    return {
      id: this.id,
      name: this.name,
      emails: this.emails,
      projectIncludeOption: this.projectIncludeOption,
      projects: this.projects.slice(),
      timeFrameType: this.timeFrameType,
      actionDate: this.actionDate,
      sendReport: false,
      scheduleFrequency: this.scheduleFrequency,
      time: `${
        this.time?.hour.toString().length === 1
          ? '0' + this.time.hour
          : this.time.hour
      }:${
        this.time?.minute.toString().length === 1
          ? '0' + this.time.minute
          : this.time.minute
      }`
    };
  }
}
