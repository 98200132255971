import { IDENTIFY, GROUP } from './eventSpec';
import merge from 'lodash.merge';
import segmentUtils from './segmentUtils';
import branchDest from './branchDest';

const defaultOptions = {
  integrations: {
    All: true,
    Salesforce: false
  }
};

const eventLayer = window.eventLayer || (window.eventLayer = []);

let segment;

/**
 * These call functions are wrapper functions for Segment.io's API spec
 *
 * @docs https://segment.com/docs/connections/spec/
 */

/**
 * Used for individual account
 *
 * @param {String} uid - User ID
 * @param {Object} props
 * @param {Object} options
 */
const callIdentify = function(uid, traits = {}, options = {}) {
  return new Promise(resolve => {
    eventLayer.push({
      type: 'identify',
      id: uid,
      props: traits,
      options: options,
      SPEC: IDENTIFY,
      resolvePromise: () => resolve(true)
    });
  });
};

/**
 * Used for companies
 * @param {String} gid - Group ID
 * @param {Object} props
 * @param {Object} options
 */

const callGroup = function(gid, traits = {}, options = {}) {
  return new Promise(resolve => {
    eventLayer.push({
      type: 'group',
      id: gid,
      props: traits,
      options: options,
      SPEC: GROUP,
      resolvePromise: () => resolve(true)
    });
  });
};

/**
 * @param {Object} SPEC - Event spec
 * @param {Object} props
 * @param {Object} options
 * @param {Function} cb - Callback
 */
const callTrack = function(SPEC = {}, props = {}, options = {}) {
  if (typeof SPEC !== 'object') {
    console.error(
      'Function `callTrack` only accepts first param (spec) as an object.'
    );
  }

  return new Promise(resolve => {
    eventLayer.push({
      type: 'track',
      id: SPEC.name,
      props: props,
      options: options,
      SPEC: SPEC,
      resolvePromise: () => resolve(true)
    });
  });
};

/**
 * @param {Object} SPEC - Event spec
 * @param {Object} props
 * @param {Object} options
 * @param {Function} cb - Callback
 */
const callPage = function(name, props = {}, options = {}) {
  return new Promise(resolve => {
    eventLayer.push({
      type: 'page',
      name: name,
      props: props,
      options: options,
      resolvePromise: () => resolve(true)
    });
  });
};

/**
 * @docs https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#reset-or-logout
 */
const callReset = function() {
  branchDest.logout();

  if (segment?.initialized) {
    segment.reset();
  }
};

/**
 * All analytics warnings goes through this function.
 * Needs to have stack trace and hide-able in devtools.
 */
const logConsole = function(message) {
  console.warn(`%cAnalytics: ${message}`, 'color: orange;');
};

/**
 * @returns {Promise}
 */
const loadSegmentLib = function(segmentId, isSuperAdmin) {
  return new Promise(resolve => {
    /* prettier-ignore */
    !function(){
      var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.13.1";}
    }();

    window.analytics.load(segmentId, {
      integrations: { 'Google Tag Manager': !isSuperAdmin }
    });

    window.analytics.ready(() => {
      segment = window.analytics;
      handleEventLayer();
      resolve(true);
    });
  });
};

/**
 * This is required so that events actually gets fired one after another if lib is lazy loaded.
 * This also ensures that window.analytics.user().traits() is available in track calls.
 * Also allows `eventLayer` to be tested.
 * 1. `eventLayer` starts out as a normal array.
 * 2. Before handleEventLayer(), pushed events only gets stored in the `eventLayer` array.
 * 3. After handleEventLayer(), stored events in `eventLayer` array get fired.
 * 4. `eventLayer.push()` gets new functionality, future pushes gets fired immediately.
 */
function handleEventLayer() {
  // Handle all prefired events in eventLayer once.
  if (eventLayer.length > 0 && !eventLayer.isCalled) {
    eventLayer.isCalled = true;

    eventLayer.forEach(event => {
      delegateEvent(event);
    });
  }

  // Add functionality into future push events
  eventLayer.push = event => {
    eventLayer[eventLayer.length] = event;

    return delegateEvent(event);
  };
}

function checkUserId() {
  if (!window.analytics.user().id()) {
    console.warn('No userId for event.');
    return false;
  }

  return true;
}

function delegateEvent(event = {}) {
  const hasUserId = checkUserId();

  switch (event.type) {
    case 'identify':
      identify(event);
      break;

    case 'group':
      if (hasUserId) {
        group(event);
      }

      break;

    case 'page':
      if (hasUserId) {
        page(event);
      }
      break;

    case 'track':
      if (hasUserId) {
        track(event);
      }
      break;

    default:
      console.error(`Unrecognized event type: ${event.type}`);
      break;
  }
}

/**
 * @source https://github.com/segmentio/analytics.js-core/blob/master/lib/analytics.ts
 */
async function identify(e) {
  checkEventSpec(IDENTIFY, e.props);
  let finalOptions = {};

  branchDest.identify(e.id);

  merge(finalOptions, e.options);
  segment.identify(e.id, e.props, finalOptions, e.callback);
}

function page(e) {
  segment.page(e.name, e.props, e.options);
}

function group(e) {
  checkEventSpec(GROUP, e.props);

  segment.group(e.id, e.props, e.options, e.resolvePromise);
}

function track(e) {
  const { props = {}, options = {}, SPEC = {} } = e;
  checkEventSpec(SPEC, props);

  options.context = getContexts();
  if (process.env.NODE_ENV === 'development') options.spec = SPEC; // Debug
  const finalOptions = merge({}, defaultOptions, options, SPEC.options);

  segment.track(e.id, e.props, finalOptions, e.resolvePromise);
}

/**
 * Checks to see if the received event params are in the spec
 * Will only create a warning but does not cause an error.
 *
 * @info Turn on `verbose` filter in devtools to see the this in production
 */
function checkEventSpec(SPEC, props) {
  Object.keys(props).forEach(propName => {
    if (!SPEC.props?.includes(propName)) {
      logConsole(
        `[${propName}] property is not registered in the [${SPEC.name}] event spec.`
      );
    }
  });

  SPEC.props?.forEach(specProp => {
    if (!Object.keys(props).includes(specProp)) {
      logConsole(
        `[${specProp}] property is required in the [${SPEC.name}] event spec.`
      );
    }
  });

  Object.keys(props).forEach(propName => {
    if (
      typeof props[propName] === 'undefined' ||
      props[propName] === '' ||
      props[propName] === null
    ) {
      logConsole(
        `[${propName}] property empty or undefined in the [${SPEC.name}] event.`
      );
    }
  });
}

/**
 * @returns {Object} User & Group traits
 */
function getTraits() {
  const userTraits = window.analytics.user().traits();
  const groupTraits = window.analytics.group().traits();
  const allTraits = Object.assign(userTraits, groupTraits);

  return allTraits;
}

/**
 * Segment Context - Includes some default Mixpanel props like screen width/height and os name
 *
 * @returns {Object} option.context
 * @see https://segment.com/docs/connections/spec/common
 */
function getContexts() {
  return {
    traits: getTraits(),
    screen: {
      height: window.screen?.height,
      width: window.screen?.width
    },
    os: {
      name: segmentUtils.os(window.navigator.userAgent)
    }
  };
}

/**
 * Can't use segmentAnalytics const here because of named exports.
 */
export {
  loadSegmentLib,
  callIdentify,
  callGroup,
  callPage,
  callTrack,
  callReset,
  logConsole
};

const segmentAnalytics = {
  loadSegmentLib,
  callIdentify,
  callGroup,
  callPage,
  callTrack,
  callReset,
  logConsole
};

export default segmentAnalytics;
