import omit from 'lodash.omit';
import pick from 'lodash.pick';
import orderBy from 'lodash.orderby';
import debounce from 'lodash.debounce';
import request from 'axios';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import UIStore from '../UIStore';
import Plans from 'stores/collections/billing/Plans';
import RenewalPreview from 'stores/models/billing/RenewalPreview';
import moment from 'moment';

import alertErrorHandler from 'utils/alertErrorHandler';
import formatCurrency from 'utils/formatCurrency';

import history from 'utils/history';
import { t } from 'utils/translate';
import { BASE_DEBOUNCE } from 'fixtures/constants';

import {
  SubscriptionForm,
  subscriptionFormOptions,
  subscriptionFormFields,
  subscriptionFormRules,
  subscriptionFormLabels,
  subscriptionFormPlugins
} from 'forms/billing/subscription';

import {
  legacySeatsCost,
  seatAddOn,
  SEATS_ENTERPRISE_ADDON,
  UNLIMITED_SEATS_NUMBER
} from 'utils/subscriptionData';

export default class SubscriptionEditUI extends UIStore {
  @observable form;
  @observable total;
  @observable loading;
  @observable costToday;
  @observable costTodayDiscount;
  @observable tax;
  @observable taxInfo;
  @observable seatsDisabled;
  @observable termsDisabled;
  @observable showTimeframeWarning;

  constructor(options) {
    super(options);

    this.clearUIState();

    this.plans = new Plans(null, {
      rootStore: this.rootStore
    });

    this.fetchInvoicePreviewDebounced = debounce(
      this.fetchInvoicePreview,
      BASE_DEBOUNCE
    );

    this.renewalPreview = new RenewalPreview(null, {
      parent: this,
      rootStore: this.rootStore
    });
  }

  @computed get subscription() {
    return this.parent.subscription;
  }

  @computed get billingInfo() {
    return this.parent.billingInfo;
  }

  @computed get company() {
    return this.parent.company;
  }

  @computed get hasActiveCoupon() {
    return !!this.parent.activeCoupon;
  }

  @computed get isAdding() {
    return this.subscription.requiresCreation;
  }

  @action.bound async setup() {
    this.fetchPlans();
    this.setupForm();
    if (!this.subscription.requiresCreation) {
      this.renewalPreview.fetch();
    }
    this.setupReactions();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
    this.renewalPreview.clear();
    this.form = null;
  }

  @action.bound async setupForm() {
    let values;

    if (this.subscription.isNew) {
      values = {
        quantity: 1,
        term: '',
        collectionMethod: this.billingInfo.isNew ? 'MANUAL' : 'AUTOMATIC'
      };
    } else {
      values = this.subscription.formValues;
      if (this.billingInfo.isNew) {
        values['collectionMethod'] = 'MANUAL';
      }
      this.termsDisabled = !this.subscription.isFlatRate;
    }

    this.form = await new SubscriptionForm(
      {
        fields: subscriptionFormFields,
        rules: subscriptionFormRules,
        values: values,
        labels: subscriptionFormLabels
      },
      {
        options: subscriptionFormOptions,
        plugins: subscriptionFormPlugins
      }
    );
  }

  @computed get planAndQuantity() {
    if (!this.selectedPlan) return null;

    return {
      code: this.selectedPlan.code,
      quantity: this.form.$('quantity').value,
      collectionMethod: this.form.$('collectionMethod').value
    };
  }

  @action.bound setupReactions() {
    this.reactToUnitAmountAndQuantity = reaction(
      () => this.planAndQuantity,
      planAndQuantity => {
        runInAction(() => {
          this.fetchInvoicePreviewDebounced();
        });
      }
    );
  }

  @computed get flatOrLegacyRatePlanPreviewPayload() {
    const { code, quantity, collectionMethod } = this.planAndQuantity;
    return {
      planCode: code,
      quantity: 1,
      collectionMethod: collectionMethod,
      addOns: [
        {
          code: this.selectedPlanTierOption.code,
          quantity: parseInt(
            this.selectedPlan.isLegacy
              ? quantity
              : this.selectedPlanTierOption.endingQuantity
          )
        }
      ]
    };
  }

  @computed get standardPlanPreviewPayload() {
    const { code, quantity, collectionMethod } = this.planAndQuantity;
    return {
      planCode: code,
      quantity: parseInt(quantity),
      collectionMethod: collectionMethod
    };
  }

  @computed get previewPayload() {
    return this.selectedPlan.isStandard
      ? this.standardPlanPreviewPayload
      : this.flatOrLegacyRatePlanPreviewPayload;
  }

  @action.bound async fetchInvoicePreview() {
    if (!this.selectedPlan) {
      this.showTimeframeWarning = null;
      return;
    }

    let response;

    try {
      this.loading = true;
      // new subscription, always using purchase/preview
      if (this.subscription.requiresCreation) {
        if (this.selectedPlan.isLegacy) {
          throw new Error(
            'Creating of legacy plans is not allowed. No pricing is available'
          );
        }
        response = await request.post(
          `ra/companies/${this.company.uuid}/billing/purchase/preview`,
          this.previewPayload
        );
      } else {
        // we use subscription change request if plan/quantity changed
        if (this._isSubscriptionChanged) {
          response = await request.post(
            `ra/companies/${this.company.uuid}/billing/subscriptions/${this.subscription.id}/change/preview`,
            this.previewPayload
          );
        }
      }
      this.showTimeframeWarning = null;
      // otherwise nothing to check/display
      if (!response?.data) {
        this.costToday = 0;
        this.costTodayDiscount = 0;
        this.total = 0;
        this.tax = 0;
        this.taxInfo = null;
      } else {
        const data = response.data;

        const processInvoices = collection => {
          let costToday = 0;
          let costTodayDiscount = 0;
          let tax = 0;
          let taxInfo = null;

          tax = this.tax = collection.chargeInvoice?.tax || 0;
          taxInfo = collection.chargeInvoice?.taxInfo || null;

          costToday += collection.chargeInvoice?.total || 0;
          costTodayDiscount += collection.chargeInvoice?.discount || 0;

          for (const invoice of collection.creditInvoices || []) {
            costToday += invoice.total || 0;
            costTodayDiscount += invoice.discount || 0;
          }

          return { costToday, costTodayDiscount, tax, taxInfo };
        };

        // purchase/preview different structure
        const costTodayWithDiscount = processInvoices(
          data.invoiceCollection || data
        );

        this.costToday = costTodayWithDiscount.costToday;
        this.costTodayDiscount = costTodayWithDiscount.costTodayDiscount;
        this.tax = costTodayWithDiscount.tax;
        this.taxInfo = costTodayWithDiscount.taxInfo;

        let total = 0;
        if (data.quantity && data.unitAmount) {
          total += data.quantity * data.unitAmount;
        }
        if (data.addOns) {
          // need to calculate add-ons
          if (this.selectedPlan.isLegacy) {
            const { addSeatsPrice } = legacySeatsCost(data.addOns);
            total += addSeatsPrice;
          } else if (this.selectedPlan.isFlatRate) {
            const { addOn, purchasedSeats } = seatAddOn(data.addOns);
            const tier = addOn.tiers.find(
              tier =>
                tier.startingQuantity <= purchasedSeats &&
                purchasedSeats <= tier.endingQuantity
            );
            total += tier?.unitAmount || 0;
          }
        }
        // purchase/preview different structure
        if (this.subscription.requiresCreation) {
          total = data.chargeInvoice.subtotal;
        }
        if (data.timeframe === 'term_end') {
          if (this.seatsDisabled) {
            this.showTimeframeWarning = 'flt-std-downgrade';
          } else {
            this.showTimeframeWarning = 'downgrade';
          }
        }
        this.total = total;
        this.clearValidationDetails();
      }
    } catch (error) {
      this.total = 0;
      this.costToday = 0;
      this.costTodayDiscount = 0;
      this.tax = 0;
      this.taxInfo = null;

      if (!this.form?.isPristine) {
        if (error.response?.data?.message) {
          alertErrorHandler(error, this.setValidationDetails);
        }
      }
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound tearDownReactions() {
    this.reactToUnitAmountAndQuantity && this.reactToUnitAmountAndQuantity();
  }

  @action.bound clearUIState() {
    this.form = null;
    this.total = 0;
    this.costToday = 0;
    this.costTodayDiscount = 0;
    this.tax = 0;
    this.taxInfo = null;
    this.loading = true;
    this.seatsDisabled = false;
    this.termsDisabled = true;
    this.showTimeframeWarning = null;
    this.plans?.clear();
    this.clearValidationDetails();
  }

  @action.bound async fetchPlans() {
    await this.plans.fetch();
  }

  @computed get planOptions() {
    // skip legacy option if new sub or existing non-legacy
    const includeLegacy = this.subscription.requiresCreation
      ? false
      : this.subscription.isLegacyPlan;

    // skip monthly plans if no billing info is added (collection method is MANUAL only)
    const includeMonthly = !this.billingInfo.isNew;

    return orderBy(
      this.plans.models
        .filter(plan => includeLegacy || !plan.isLegacy)
        .filter(plan => includeMonthly || !plan.isMonthly)
        .map(plan => {
          return {
            value: plan.id,
            name: plan.name,
            unitAmount: plan.unitAmount || 1,
            code: plan.code,
            type: plan.isLegacy
              ? t('Legacy')
              : plan.isFlatRate
              ? t('Flat rate')
              : t('Standard'),
            isFlatRate: plan.isFlatRate,
            isLegacy: plan.isLegacy,
            isStandard: plan.isStandard,
            isMonthly: plan.isMonthly,
            addOns: plan.addOns
          };
        }),
      ['type', 'name'],
      ['asc', 'asc']
    );
  }

  @computed get selectedPlan() {
    return this.planOptions.find(
      option => option.value === this.form.$('plan').value
    );
  }

  @computed get selectedPlanUnitAmount() {
    if (this.selectedPlan?.isFlatRate) {
      return this.selectedPlanTierOption?.unitAmount;
    }
    return this.selectedPlan?.unitAmount;
  }

  @computed get planNotChanged() {
    return (this.selectedPlan?.value || '-') === this.subscription?.plan?.id;
  }

  @computed get selectedPlanUnitAmountFormatted() {
    if (this.planNotChanged) {
      if (this.subscription.isFlatRate) {
        return formatCurrency(this.subscription.selectedTier.unitAmount) || '-';
      } else {
        return this.subscription.unitAmountFormatted;
      }
    }

    return this.selectedPlanUnitAmount
      ? formatCurrency(this.selectedPlanUnitAmount) || '-'
      : '-';
  }

  @computed get legacyBasePriceFormatted() {
    // for legacy plans, pricing is stored in subscription during migration
    // there is no pricing for a new subscriptions
    // legacy plan in recurly has no price, it is overloaded on subscription level
    return this.subscription?.legacyData?.basePriceFormatted || '-';
  }

  @computed get legacyAdditionalSeatsFormatted() {
    const { includedSeats, unitAmount } = this.subscription?.legacyData;
    if (!unitAmount) {
      return '-';
    }
    const { quantity } = this.planAndQuantity;
    const extra = quantity - includedSeats;
    return extra > 0
      ? `${formatCurrency(unitAmount * extra)} (${extra} x ${formatCurrency(
          unitAmount
        )})`
      : '-';
  }

  @computed get selectedPlanTierOptions() {
    let tiers = [];
    let addOns;
    if (this.planNotChanged) {
      addOns = [this.subscription.seatsAddOn];
    } else {
      addOns = [
        this.selectedPlan.addOns.find(a =>
          a.code.startsWith(SEATS_ENTERPRISE_ADDON)
        )
      ];
    }

    addOns.forEach((addOn, index) => {
      tiers = tiers.concat(
        addOn.tiers.map((tier, index) => {
          return {
            value: `${tier.endingQuantity}`,
            startingQuantity: tier.startingQuantity,
            endingQuantity: tier.endingQuantity,
            name: `${tier.startingQuantity} - ${
              tier.endingQuantity >= UNLIMITED_SEATS_NUMBER
                ? t('Unlimited')
                : tier.endingQuantity
            } (${formatCurrency(tier.unitAmount)})`,
            unitAmount: tier.unitAmount,
            code: addOn.code || addOn.addOn?.code
          };
        })
      );
    });

    return tiers;
  }

  @computed get selectedPlanTierOption() {
    return this.selectedPlanTierOptions.find(option => {
      return (
        this.form.$('quantity').value >= option.startingQuantity &&
        this.form.$('quantity').value <= option.endingQuantity
      );
    });
  }

  @computed get collectionMethodOptions() {
    if (this.billingInfo.isNew) {
      return [{ value: 'MANUAL', name: t('Manual') }];
    }
    if (this.selectedPlan?.isMonthly) {
      return [{ value: 'AUTOMATIC', name: t('Automatic') }];
    }
    return [
      { value: 'AUTOMATIC', name: t('Automatic') },
      { value: 'MANUAL', name: t('Manual') }
    ];
  }

  @computed get selectedCollectionMethod() {
    return this.collectionMethodOptions.find(
      option => option.value === this.form.$('collectionMethod').value
    );
  }

  @action.bound cancelSubscriptionEdit() {
    Promise.resolve().then(() => {
      history.push(`/companies/${this.company.uuid}/subscription-billing`);
    });
  }

  @computed get totalFormatted() {
    // if it is a new subscription, "cost today" should be equal to "new invoice amount", cost today includes discount
    if (this.isAdding && this.costToday) {
      return this.costTodayWithDiscountFormatted;
    }
    let formatted;
    if (this.total) {
      formatted = formatCurrency(this.total);
    } else if (this.renewalPreview.chargeInvoice?.subtotal) {
      formatted = formatCurrency(this.renewalPreview.chargeInvoice.subtotal);
    } else {
      return '-';
    }

    return formatted;
  }

  @computed get subBalanceFormatted() {
    const cycles = this.selectedTerm?.value || 1;
    let formatted;
    // if it is a new subscription, "cost today" should be equal to "new invoice amount", cost today includes discount
    if (this.isAdding && this.costToday) {
      formatted = formatCurrency(this.costToday * cycles);
    } else if (this.total) {
      formatted = formatCurrency(this.total * cycles);
    } else if (this.renewalPreview.chargeInvoice?.subtotal) {
      formatted = formatCurrency(
        this.renewalPreview.chargeInvoice.subtotal * cycles
      );
    } else {
      return '-';
    }

    return formatted;
  }

  @computed get billingCyclesFormatted() {
    const cycles = this.selectedTerm?.value || 1;

    return t('The renewal term is {cycles} billing {period}.', {
      templateStrings: {
        cycles: cycles,
        period: cycles > 1 ? 'periods' : 'period'
      }
    });
  }

  @computed get costTodayWithDiscountFormatted() {
    if (!this.costToday) {
      return '-';
    }

    return formatCurrency(this.costToday);
  }

  @computed get costTodayDiscountFormatted() {
    return `${t('Includes discount of')} ${formatCurrency(
      this.costTodayDiscount
    )}.`;
  }

  @computed get hasRenewalTax() {
    return this.renewalPreview.tax > 0;
  }

  @computed get hasTax() {
    return this.tax > 0;
  }

  @computed get taxInfoFormatted() {
    if (!this.hasTax) return '';

    return `${t('Includes estimated tax of')} ${formatCurrency(this.tax)} (${
      this.taxInfo?.region
    } ${this.taxInfo?.rate * 100}%).`;
  }

  @computed get renewalTaxInfoFormatted() {
    if (!this.hasRenewalTax) return '';

    return `${t('Includes estimated tax of')} ${formatCurrency(
      this.renewalPreview.tax
    )} (${this.renewalPreview?.taxInfo.region} ${this.renewalPreview?.taxInfo
      .rate * 100}%).`;
  }

  @computed get subscriptionChangeUrl() {
    if (this.subscription.requiresCreation) {
      return `ra/companies/${this.company.uuid}/billing/subscriptions`;
    }

    return `/ra/companies/${this.company.uuid}/billing/subscriptions/${this.subscription.id}/change`;
  }

  @computed get subscriptionUpdateUrl() {
    return `/ra/companies/${this.company.uuid}/billing/subscriptions/${this.subscription.id}`;
  }

  @action.bound submitForm(event) {
    event.preventDefault();
    event.stopPropagation();

    this.form.submit({
      onSuccess: this.submitFormSuccess,
      onError: this.submitFormError
    });
  }

  @action.bound async submitFormSuccess() {
    this.saving = true;

    const values = this.form.values();

    let payload;

    // ENG-17001
    // quantity for the flat rate plans is `endingQuantity` of the selected tier
    // for the legacy plans, it is exact number of seats as there are no tiers, all extra seats charge on "per seat" basis
    if (this.selectedPlan.isFlatRate || this.selectedPlan.isLegacy) {
      payload = {
        planCode: this.selectedPlan.code,
        timeframe: 'now',
        quantity: 1,
        collectionMethod: values.collectionMethod,
        addOns: [
          {
            code: this.selectedPlan.isLegacy
              ? 'seats-enterprise-legacy'
              : this.selectedPlanTierOption.code,
            quantity: parseInt(
              this.selectedPlan.isLegacy
                ? values.quantity
                : this.selectedPlanTierOption.endingQuantity
            )
          }
        ]
      };
    } else {
      payload = {
        planCode: this.selectedPlan.code,
        timeframe: 'now',
        quantity: parseInt(values.quantity),
        collectionMethod: values.collectionMethod
      };
    }
    if (this.selectedPlan.isFlatRate) {
      if (this.subscription.requiresCreation) {
        payload['totalBillingCycles'] = parseInt(values.term);
      } else {
        payload['renewalBillingCycles'] = parseInt(values.term);
      }
    }

    try {
      if (this.subscription.requiresCreation) {
        await request.post(this.subscriptionChangeUrl, payload);
      } else {
        if (this._isSubscriptionChanged) {
          await request.post(
            this.subscriptionChangeUrl,
            omit(payload, ['collectionMethod', 'renewalBillingCycles'])
          );
        }
        // the order is important, "put" request does not allow to set renewalBillingCycles>1 for non-flat rate plans
        // if we change plan, subscription should be changed (code above) before we update it (code below)
        await request.put(
          this.subscriptionUpdateUrl,
          pick(payload, ['collectionMethod', 'renewalBillingCycles'])
        );
      }

      if (this.featureFlags.FF_TIME_CLOCK && this.isSuperAdmin) {
        await this.company.save({
          companyAddOns: {
            hasTimeClockAccess: values.hasTimeClockAccess
          }
        });
      }

      this.parent.billingUI.refreshAll();

      this.cancelSubscriptionEdit();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Subscription updated')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitFormError() {
    console.error(this.form.errors());
  }

  @action.bound changePlan(newPlanId) {
    this.form.$('plan').set(newPlanId);
    const originalPlan = this.planOptions.find(
      p => p.code === this.subscription?.plan?.code
    );

    const newPlan = this.selectedPlan;
    if (newPlan?.isMonthly) {
      this.form.$('collectionMethod').set('AUTOMATIC');
    }
    // update term
    if (!newPlan) {
      this.termsDisabled = true;
      this.form.$('term').set('');
    } else {
      this.termsDisabled = !newPlan.isFlatRate;
      if (newPlan.isFlatRate) {
        if (!this.form.$('term').value) {
          this.form.$('term').set(1);
        }
      } else {
        this.form.$('term').set(1);
      }
    }
    if (!originalPlan || this.subscription.requiresCreation) {
      return;
    }
    this.termsDisabled = true;
    // only when we edit flat rate plan
    if (originalPlan.isFlatRate) {
      if (!newPlan.isFlatRate) {
        this._lockSeats();
      } else {
        // terms enabled only when both original and new plan are enterprise plans
        this.termsDisabled = false;
        this._unlockSeats();
      }
    } else if (originalPlan.isLegacy) {
      if (newPlan.isStandard) {
        this._lockSeats();
      } else {
        this._unlockSeats();
      }
    }
    if (!newPlan.isFlatRate && this.form.$('quantity').value > 1000000) {
      this.form
        .$('quantity')
        .set(
          this.subscription.formValues.quantity < 1000000
            ? this.subscription.formValues.quantity
            : this.company.seatsInUse
        );
    }
  }

  @computed get termsOptions() {
    if (!this.selectedPlan?.isFlatRate) {
      return [
        {
          value: 1,
          name: this.selectedPlan?.isMonthly ? t('1 month') : t('1 year')
        }
      ];
    }
    return [
      { value: 1, name: t('1 year') },
      { value: 2, name: t('2 years') },
      { value: 3, name: t('3 years') },
      { value: 4, name: t('4 years') },
      { value: 5, name: t('5 years') }
    ];
  }

  @computed get selectedTerm() {
    return this.termsOptions.find(
      option => option.value === this.form.$('term').value
    );
  }

  @computed get renewalDateFormatted() {
    const term = this.selectedTerm;
    const plan = this.selectedPlan;

    if (this.subscription.requiresCreation) {
      if (!plan || !term) {
        return '-';
      }
      return _formatDate(term.value, plan.isMonthly ? 'months' : 'years');
    }
    // if change applicable after renewal, show current sub renewal date
    // if new plan's term differs (monthly vs yearly) recurly resets renewal date
    if (
      !this.showTimeframeWarning &&
      plan &&
      this.subscription.isMonthly !== plan.isMonthly
    ) {
      return _formatDate(1, 'years');
    }
    return this.subscription.renewsOn;
  }

  @computed get nextBillDateFormatted() {
    const plan = this.selectedPlan;

    if (this.subscription.requiresCreation) {
      if (!plan || !this.selectedTerm) {
        return '-';
      }
      return _formatDate(1, plan.isMonthly ? 'months' : 'years');
    }
    // if change applicable after renewal, show current sub billing date
    // if new plan's term differs (monthly vs yearly) recurly resets billing date
    if (
      !this.showTimeframeWarning &&
      plan &&
      this.subscription.isMonthly !== plan.isMonthly
    ) {
      return _formatDate(1, 'years');
    }
    return this.subscription.nextBillOn;
  }

  @computed get multiYearWarning() {
    if (this.isAdding || this.subscription.remainingBillingCycles <= 0) {
      return null;
    }
    return t(
      `This account has ${this.subscription.remainingBillingCycles} remaining billing period(s) in their subscription term. Some changes will take effect at the renewal date.`
    );
  }

  @computed get timeframeWarning() {
    switch (this.showTimeframeWarning) {
      case 'flt-std-downgrade':
        return t(
          'You are attempting to change this customer’s subscription from a flat rate plan to a standard plan. The change will take effect at their next renewal, and the new subscription seat count will be set to the current number of seats in use. If they wish to renew for fewer seats you must remove active users before submitting the subscription change.'
        );
      case 'downgrade':
        return (
          t(
            'The change will take effect at the current subscription next renewal date: '
          ) + this.subscription.renewsOn
        );
    }
    return null;
  }

  _lockSeats() {
    this.seatsDisabled = true;
    this.form.$('quantity').set(this.company.seatsInUse);
  }
  _unlockSeats() {
    // new plan is flat rate, if we tried to switch to standard, we reset quantity back to original
    if (this.seatsDisabled) {
      this.form.$('quantity').set(this.subscription.formValues.quantity);
    }
    this.seatsDisabled = false;
  }

  get _isSubscriptionChanged() {
    return this.form.$('plan').isDirty || this.form.$('quantity').isDirty;
  }

  @computed get showCompanyAddOns() {
    return this.selectedPlan?.code.startsWith('performance-');
  }
}

const _formatDate = (offset, what) => {
  return moment()
    .add(offset, what)
    .format('YYYY-MM-DD');
};
