export default index => {
  switch (index) {
    case 0:
      return '#987943';
    case 1:
      return '#F8C056';
    case 2:
      return '#03B17E';
    case 3:
      return '#F26620';
    case 4:
      return '#313E47';
    default:
      return '#4A4A4A';
  }
};
