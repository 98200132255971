import { RakenCollection } from '../lib';
import CompanyPermission from '../models/CompanyPermission';
import groupBy from 'lodash.groupby';
import { computed } from 'mobx';
import { uniqBy } from 'lodash';

export default class CompanyPermissions extends RakenCollection {
  url() {
    return `ra/company/permissions`;
  }

  model() {
    return CompanyPermission;
  }

  @computed
  get groupByRoles() {
    let roles = this.models;

    return groupBy([...roles], companyPermission => companyPermission.roleName);
  }

  /**
   * More stable to work with enums
   */
  @computed
  get groupByEnumRoles() {
    let permissions = this.models.slice();

    return groupBy(permissions, companyPermission => companyPermission.role);
  }

  /**
   * All Permissions
   * @returns {Array} of objects
   */
  @computed
  get permissionTypes() {
    const permissions = this.models.slice();
    return uniqBy(permissions, 'permissionEnum').map(permission => {
      return {
        description: permission.description,
        permissionEnum: permission.permissionEnum
      };
    });
  }

  /**
   * @returns {Array} of permissions
   */
  @computed
  get accountAdminPermissions() {
    return this.groupPermissionsBy('ROLE_ACCOUNT_ADMIN');
  }

  @computed
  get projectAdminPermissions() {
    return this.groupPermissionsBy('ROLE_ADMIN');
  }

  @computed
  get projectMemberPermissions() {
    return this.groupPermissionsBy('ROLE_PROJECT_MEMBER');
  }

  groupPermissionsBy(roleEnum) {
    if (!Object.keys(this.groupByEnumRoles).includes(roleEnum)) return [];

    return this.groupByEnumRoles[roleEnum];
  }
}
