import MobxReactForm from 'mobx-react-form';
import validatorjs from 'validatorjs';
import { action } from 'mobx';
import { t } from 'utils/translate';

const plugins = { dvr: validatorjs };

const options = {
  validateOnInit: true,
  validateOnChange: true
};

const fields = [
  'id',
  'name',
  'projects',
  'timeFrame',
  'recurringOn',
  'month',
  'week',
  'emails',
  'email',
  'cover',
  'photos',
  'workers',
  'hours',
  'safety',
  'delays',
  'missedDailies',
  'openTasks',
  'weather',
  'materials',
  'equipment',
  'scWorkers',
  'scHours',
  'scSafety',
  'scDelays',
  'scMissedDailies',
  'sendReport',
  'tabType',
  'projectIncludeOption'
];

const rules = {
  name: '',
  recurringOn: '',
  timeFrame: 'required',
  emails: 'array|required',
  email: 'required_without:emails|email',
  month: 'required',
  week: 'required_without:timeFrame|required_if:timeFrame,WEEKLY'
};

const scheduledRules = {
  name: 'string|required|max:255',
  recurringOn: 'required',
  month: '',
  week: ''
};

const values = {
  id: null,
  name: '',
  projects: [],
  timeFrame: 'WEEKLY',
  recurringOn: 1,
  month: '',
  week: '',
  email: '',
  cover: true,
  photos: true,
  workers: true,
  hours: true,
  safety: true,
  delays: true,
  missedDailies: true,
  openTasks: true,
  weather: true,
  materials: true,
  equipment: true,
  scWorkers: true,
  scHours: true,
  scSafety: true,
  scDelays: true,
  scMissedDailies: true,
  sendReport: false,
  tabType: 'runOnce'
};

const labels = {
  id: {
    title: t('Choose summary'),
    placeholder: t('Select Saved Summary')
  },
  name: t('Summary Name'),
  projects: t('Projects to include'),
  timeFrame: t('Summary Time Frame'),
  recurringOn: t('Recurring on'),
  month: t('Month of'),
  week: t('Week of'),
  emails: t('Send download link to'),
  cover: t('Cover Page'),
  photos: t('Photos'),
  workers: t('Workers'),
  hours: t('Hours'),
  safety: t('Safety'),
  delays: t('Delays'),
  missedDailies: t('Missed dailies'),
  openTasks: t('Open tasks'),
  weather: t('Weather'),
  scWorkers: t('Workers'),
  materials: t('Materials'),
  equipment: t('Equipment'),
  scHours: t('Hours'),
  scSafety: t('Safety'),
  scDelays: t('Delay'),
  scMissedDailies: t('Missed dailies'),
  sendReport: t('Also Send Report Immediately')
};

export class ProjectSummaryForm extends MobxReactForm {
  static reportContentFields = [
    'cover',
    'photos',
    'workers',
    'hours',
    'safety',
    'delays',
    'missedDailies',
    'openTasks',
    'weather',
    'materials',
    'equipment'
  ];

  static scFields = [
    'scWorkers',
    'scHours',
    'scSafety',
    'scDelays',
    'scMissedDailies'
  ];

  @action.bound
  updateAndValidate(fieldName, value) {
    this.update({
      [fieldName]: value
    });

    this.validate();
  }
}

export default {
  options,
  fields,
  rules,
  scheduledRules,
  values,
  labels,
  plugins
};
