import omit from 'lodash.omit';
import orderBy from 'lodash.orderby';
import ReportEntry from './ReportEntry';
import Attachments from '../collections/Attachments';
import { computed } from 'mobx';

export default class ReportEntryWithAttachments extends ReportEntry {
  constructor(attributes, options) {
    super(attributes, options);
  }

  parse(attributes) {
    super.parse(attributes);

    this.parseAttachments(attributes);

    return {
      ...omit(attributes, [
        'attachments',
        'createdBy',
        'updatedBy',
        'company',
        'project'
      ])
    };
  }

  parseAttachments(attributes) {
    if (this.attachments) {
      this.attachments.reset(attributes.attachments);
    } else {
      this.attachments = new Attachments(attributes.attachments, {
        rootStore: this.rootStore,
        parent: this
      });
    }
  }

  @computed
  get hasAttachments() {
    return this.attachments && this.attachments.length > 0;
  }

  @computed
  get sortedAttachments() {
    return orderBy(this.attachments.models, ['createdTimestamp'], ['desc']);
  }

  @computed
  get attachmentsCount() {
    return this.attachments?.length;
  }

  @computed
  get attachmentsCountPreviewImage() {
    // Find the first attachment that has a thumbUrl
    const attachmentWithThumbUrl = this.attachments?.models.find(
      attachment => attachment.thumbUrl
    );

    return attachmentWithThumbUrl?.thumbUrl;
  }

  @computed get hasAttachmentsUploading() {
    return this.attachments?.models.find(attachment => attachment.isNew);
  }
}
