import omit from 'lodash.omit';
import moment from 'moment';
import { computed, action, observable } from 'mobx';
import ReportEntryWithAttachments from './ReportEntryWithAttachments';

import { t } from 'utils/translate';
import kebabCase from 'lodash.kebabcase';

import User from './User';

export default class Task extends ReportEntryWithAttachments {
  @observable assigneeUser;

  get urlRoot() {
    return `/ra/companies/${this.rootStore.me.company.uuid}/tasks`;
  }

  idAttribute() {
    return 'uuid';
  }

  get restAttributes() {
    return [
      'uuid',
      'type',
      'createdTimestamp',
      'updatedTimestamp',
      'projectTeam',
      'desc',
      'status',
      'dueDate',
      'assignee',
      'project',
      'reportId',
      'reportDate',
      'teamId',
      'projectUuid',
      'taskType',
      'sendNotification',
      'activityViewUrl',
      'activitySegmentName'
    ];
  }

  get restAttributeDefaults() {
    return {
      status: 'OPEN',
      type: 'Task',
      activityViewUrl: '/'
    };
  }

  @action.bound
  parse(attributes) {
    super.parse(attributes);
    this.parseAssignee(attributes);

    return {
      ...omit(attributes, ['createdBy', 'updatedBy', 'company', 'project'])
    };
  }

  @action.bound
  parseAssignee(attributes) {
    if (attributes.assignee) {
      if (this.assigneeUser) {
        this.assigneeUser.set(attributes.assignee);
      } else {
        this.assigneeUser = new User(attributes.assignee, {
          rootStore: this.rootStore
        });
      }
    }
  }

  @computed
  get dueDateFormatted() {
    if (!this.dueDate) return moment().format('YYYY-MM-DD');

    return moment(this.dueDate).format('YYYY-MM-DD');
  }

  @computed get dueDateDisplay() {
    return moment(this.dueDate).format('MMM DD, YYYY');
  }

  @computed
  get overDue() {
    return this.status === 'OPEN' && moment(this.dueDate).isBefore(moment());
  }

  @computed get daysLeft() {
    return moment(this.dueDate).diff(moment(), 'days');
  }

  @computed
  get title() {
    return t('Task');
  }

  @computed get activityTitle() {
    if (this.status === 'DELETED') {
      return `${t('deleted a')} ${this.title}`;
    }

    return `${t('submitted a')} ${this.title}`;
  }

  @computed
  get assigneeName() {
    return this.assigneeUser.fullName;
  }

  @computed
  get createdByName() {
    return this.createdBy.fullName;
  }

  @computed
  get canEdit() {
    if (this.status === 'DELETED') return false;

    if (this.rootStore.me.isAdmin) return true;

    if (this.createdBy.uuid === this.rootStore.me.id) return true;

    if (this.assignee.uuid === this.rootStore.me.id) return true;

    return false;
  }

  @computed
  get canAddResolution() {
    // You can only upload Resolution Photos if :

    // Are a Project Member (or above)
    if (!this.rootStore.me.isAdmin && !this.rootStore.me.isProjectMember)
      return false;

    // Created the Observation
    if (this.createdBy.uuid === this.rootStore.me.id) return true;

    // Are the assignee, or the assignee is in your company
    if (this.assignee.uuid === this.rootStore.me.id) return true;
    if (this.assignee.businessUUID === this.rootStore.me.company.uuid)
      return true;

    return false;
  }

  @computed
  get canDelete() {
    if (this.rootStore.me.isAdmin) return true;

    if (this.createdBy.uuid === this.rootStore.me.id) return true;

    return false;
  }

  @computed
  get formValues() {
    return {
      desc: this.desc,
      dueDate: this.dueDate,
      assignee: {
        uuid: this.assigneeUser.uuid,
        name: this.assigneeUser.fullName
      },
      status: this.status
    };
  }

  @computed get slug() {
    return kebabCase(`${this.assigneeName} ${this.dueDate}`);
  }

  @computed get displayColor() {
    switch (this.status) {
      case 'OPEN':
        return 'red';
      case 'COMPLETED':
        return 'green';
      case 'DELETED':
        return 'grey';
      default:
        return 'green';
    }
  }

  @computed get displayStatus() {
    switch (this.status) {
      case 'OPEN':
        return t('Open');
      case 'COMPLETED':
        return t('Completed');
      case 'DELETED':
        return t('Deleted');
      default:
        return t('Open');
    }
  }
}
