import { Model } from 'mobx-mc';
import { action } from 'mobx';
import Material from '../models/Material';
import omit from 'lodash.omit';

export default class MaterialDivision extends Model {
  get restAttributes() {
    return ['budgetedQuantity', 'actualQuantity', 'completeQuantityPercent'];
  }

  get restAttributeDefaults() {
    return {
      budgetedQuantity: 0,
      actualQuantity: 0,
      completeQuantityPercent: 0
    };
  }

  @action.bound
  parse(attributes) {
    this.material = new Material(attributes.material || null, {
      rootStore: this.rootStore,
      parent: this
    });

    return {
      ...omit(attributes, ['material'])
    };
  }
}
