import omit from 'lodash.omit';
import moment from 'moment-timezone';
import { Model } from 'mobx-mc';
import { computed, action, observable } from 'mobx';
import WeatherHourlys from '../collections/WeatherHourlys';
import WeatherDailys from '../collections/WeatherDailys';

import formatPrecipitation from 'utils/formatPrecipitation';

export default class Weather extends Model {
  @observable hourlysPage;
  @observable apiFailed;

  constructor(data, options) {
    super(data, options);
    this.hourlysPage = 3;
    this.apiFailed = true;
  }

  @action.bound
  handleToday() {
    if (this.isToday) {
      this.hourlys.models.forEach((hourly, i) => {
        if (hourly.timeHourPeriod === this.currentTimeHourPeriod) {
          this.hourlysPage = Math.ceil((i + 1) / 7);
        }
      });
    }
  }

  idAttribute() {
    return 'timezone';
  }

  get restAttributes() {
    return ['forecast_hash', 'type', 'timezone'];
  }

  @action.bound
  setApiFailed() {
    this.apiFailed = true;
  }

  @action.bound
  clearApiFailed() {
    this.apiFailed = false;
  }

  @action.bound
  parse(attributes) {
    if (this.dailys) {
      this.dailys.reset(attributes.dailys);
    } else {
      this.dailys = new WeatherDailys(attributes.dailys, {
        rootStore: this.rootStore,
        parent: this
      });
    }

    if (this.hourlys) {
      this.hourlys.reset(attributes.hourlys);
    } else {
      this.hourlys = new WeatherHourlys(attributes.hourlys, {
        rootStore: this.rootStore,
        parent: this
      });
    }

    return {
      ...omit(attributes, ['hourlys', 'dailys'])
    };
  }

  @computed
  get project() {
    return this.parent.project;
  }

  @computed
  get selectedDay() {
    return this.dailys.at(0);
  }

  @computed
  get isToday() {
    return (
      moment
        .unix(this.selectedDay.sunriseTime)
        .tz(this.timezone)
        .format('YYYY-MM-DD') ===
      moment()
        .tz(this.timezone)
        .format('YYYY-MM-DD')
    );
  }

  @computed
  get isPast() {
    return moment
      .unix(this.selectedDay.sunriseTime)
      .tz(this.timezone)
      .isBefore(moment().tz(this.timezone));
  }

  @computed
  get isFuture() {
    return moment
      .unix(this.selectedDay.sunriseTime)
      .tz(this.timezone)
      .isAfter(moment().tz(this.timezone));
  }

  @computed
  get currentTime() {
    return moment()
      .tz(this.timezone)
      .format('h:mm');
  }

  @computed
  get currentTimeHourNumber24() {
    return moment()
      .tz(this.timezone)
      .format('HH');
  }

  @computed
  get currentTimePeriod() {
    return moment()
      .tz(this.timezone)
      .format('A');
  }

  @computed
  get currentTimeHourPeriod() {
    return moment()
      .tz(this.timezone)
      .format('h A');
  }

  @computed
  get currentConditions() {
    return this.hourlys.models.find(weatherHourly => {
      return (
        parseInt(weatherHourly.hourNumber24, 10) ===
        parseInt(this.currentTimeHourNumber24, 10)
      );
    });
  }

  @computed
  get selectedHourlys() {
    switch (this.hourlysPage) {
      case 1:
        return this.hourlys.models.slice(0, 7);
      case 2:
        return this.hourlys.models.slice(6, 13);
      case 3:
        return this.hourlys.models.slice(12, 19);
      case 4:
        return this.hourlys.models.slice(17, 24);
      default:
        return this.hourlys.models.slice(0, 7);
    }
  }

  @action.bound
  nextHourlysPage() {
    this.hourlysPage += 1;
  }

  @computed
  get hasNextHourlysPage() {
    return this.hourlysPage < 4;
  }

  @action.bound
  previousHourlysPage() {
    this.hourlysPage -= 1;
  }

  @computed
  get hasPreviousHourlysPage() {
    return this.hourlysPage > 1;
  }

  @computed
  get modalBackground() {
    if (this.fetching) return '';

    const icon = this.isToday
      ? this.currentConditions.icon
      : this.selectedDay.icon;

    switch (icon) {
      case 'clear-day':
        return 'clear-day-background';
      case 'clear-night':
        return 'clear-day-background';
      case 'partly-cloudy-day':
        return 'partly-cloudy-day-background';
      case 'partly-cloudy-night':
        return 'partly-cloudy-day-background';
      case 'fog':
        return 'fog-background';
      case 'rain':
        return 'rain-background';
      case 'snow':
        return 'snow-background';
      case 'sleet':
        return 'snow-background';
      case 'cloudy':
        return 'cloudy-background';
      case 'windy':
        return 'cloudy-background';
      case 'thunderstorm':
        return 'thunderstorm-background';
      default:
        return 'partly-cloudy-day-background';
    }
  }

  @computed
  get useImperial() {
    return this.rootStore?.companyUI?.company?.useImperialFormatting;
  }

  @computed
  get totalPrecipitationForSelectedDay() {
    let value = this.hourlys.models.reduce((sum, hourly) => {
      return sum + (hourly.precipIntensity || 0);
    }, 0);

    return formatPrecipitation(value, this.useImperial);
  }
}
