export default (file, name, callback) => {
  window.URL = window.URL || window.webkitURL;

  let xhr = new XMLHttpRequest();
  xhr.open('GET', file);
  xhr.responseType = 'blob';
  xhr.onload = () => {
    let file = new Blob([xhr.response], {
      type: 'application/octet-stream'
    });

    if (xhr.status === 403 || xhr.status === 404) {
      callback && callback(xhr.statusText);
    } else {
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(file, name);
      } else {
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(file);
        a.download = name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }

      callback && callback();
    }
  };

  xhr.send();
  xhr.onerror = error => {
    callback && callback(error);
  };
};
