import { reaction } from 'mobx';

export default (
  uiStore,
  form,
  countryField = 'address.country',
  stateField = 'address.state'
) => {
  return reaction(
    () => form.$(countryField).value,
    country => {
      if (country && country === 'US') {
        form
          .$(stateField)
          .set(
            'rules',
            `in:${uiStore.regions.asOptions
              .map(option => option.value)
              .join(',')}`
          );
      } else {
        form.$(stateField).set('rules', `string`);
      }

      form.$(stateField).validate();
    },
    {
      fireImmediately: true
    }
  );
};
