import { action, computed, observable, reaction, runInAction } from 'mobx';

import UIStore from './UIStore';

import request from 'axios';
import debounce from 'lodash.debounce';
import { BASE_DEBOUNCE } from 'fixtures/constants';

import TimeClockQuestionAnswers from 'stores/collections/TimeClockQuestionAnswers';

import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';

import {
  TimeClockQuestionsFiltersForm,
  timeClockQuestionsFiltersFormRules,
  timeClockQuestionsFiltersFormFields,
  timeClockQuestionsFiltersFormOptions,
  timeClockQuestionsFiltersFormPlugins
} from 'forms/timeClockQuestionsFilters';

import {
  EmailChecklistForm,
  emailChecklistFormOptions,
  emailChecklistFormFields,
  emailChecklistFormRules,
  emailChecklistFormLabels,
  emailChecklistFormPlugins
} from 'forms/emailChecklist';

import download from 'utils/download';

import { callTrack } from 'utils/segmentIntegration';

import {
  QUESTIONS_COMPLETED,
  QUESTIONS_SHARE
} from 'utils/segmentAnalytics/eventSpec';

export default class TimeClockQuestionsCompletedUI extends UIStore {
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;
  @observable shareCompletedChecklistForm;

  // Filters
  @observable filtersLoading;

  constructor(options) {
    super(options);

    this.loading = true;

    this.sortField = 'completedDate';
    this.sortDirection = 'desc';
    this.page = 1;
    this.searchQuery = '';
    this.pageSize = 20;

    // Filters
    this.filtersForm = null;
    this.filtersLoading = false;
    this.projectFilters = observable([]);
    this.memberFilters = observable([]);
    this.classificationFilters = observable([]);
    this.employeeGroupFilters = observable([]);
    this.eventOriginFilters = observable([]);

    this.completedChecklistToShare = null;
    this.shareCompletedChecklistForm = null;

    // Time Clock Questions collection
    this.timeClockQuestionAnswers = new TimeClockQuestionAnswers(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchTimeClockQuestionsCompletedDebounced = debounce(
      this.fetchTimeClockQuestionsCompleted,
      BASE_DEBOUNCE
    );
  }

  @action.bound async setup() {
    this.loading = true;
    window.scrollTo(0, 0);
    this.setupReactions();
    this.fetchTimeClockQuestionsCompletedDebounced();
    callTrack(QUESTIONS_COMPLETED);
  }

  @action.bound tearDown() {
    this.reactToParams && this.reactToParams();
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.timeClockQuestionAnswers.clear();
    this.searchQuery = '';
    this.sortDirection = 'desc';
    this.page = 1;
    this.pageSize = 20;
    this.completedChecklistToShare = null;
    this.shareCompletedChecklistForm = null;
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.fetchTimeClockQuestionsCompletedDebounced();
        });
      }
    );
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      sortField: [this.sortField],
      sortDirection: this.sortDirection.toUpperCase(),
      fromDate: this.payPeriodSelectorUI.selectedPeriod?.value?.from,
      toDate: this.payPeriodSelectorUI.selectedPeriod?.value?.to,
      projects: this.projectFilters.map(project => project.value),
      workers: this.memberFilters.map(project => project.value),
      classifications: this.classificationFilters.map(project => project.value),
      employeeGroups: this.employeeGroupFilters.map(project => project.value),
      eventOrigins: this.eventOriginFilters.map(project => project.value)
    };
  }

  @action.bound fetchTimeClockQuestionsCompleted() {
    this.loading = true;

    request
      .post(
        `${this.rootStore.urlMicroService(
          'performanceTracking'
        )}/timecardchecklist/completed`,
        this.params
      )
      .then(response => {
        runInAction(() => {
          this.timeClockQuestionAnswers.set(response.data);
        });
      })
      .catch(error => {
        alertErrorHandler(error, this.setValidationDetails);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  @computed get hasTimeClockQuestions() {
    return this.timeClockQuestionAnswers.hasModels;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @action.bound
  clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }
  @action.bound
  setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound async showFilterDialog() {
    this.filtersLoading = true;
    this.showModal('timeClockQuestionsFilter');
    try {
      await Promise.all([
        this.projectSelectorUI.setup({
          projectStates: ['ACTIVE']
        }),
        this.memberSelectorUI.setup({ companySettingsTrackMemberTime: true }),
        this.classificationSelectorUI.setup(),
        this.groupSelectorUI.setup()
      ]);
      this.filtersForm = new TimeClockQuestionsFiltersForm(
        {
          fields: timeClockQuestionsFiltersFormFields,
          rules: timeClockQuestionsFiltersFormRules,
          values: {
            projectFilters: this.projectFilters,
            memberFilters: this.memberFilters,
            classificationFilters: this.classificationFilters,
            employeeGroupFilters: this.employeeGroupFilters,
            eventOriginFilters: this.eventOriginFilters
          }
        },
        {
          options: timeClockQuestionsFiltersFormOptions,
          plugins: timeClockQuestionsFiltersFormPlugins
        }
      );
    } catch (error) {
      this.hideActiveModal();
      alertErrorHandler(error, this.notifications.pushError);
    } finally {
      this.filtersLoading = false;
    }
  }

  @action.bound
  async hideFilterDialog() {
    await this.hideActiveModal();
    this.filtersForm = null;
    this.projectSelectorUI.tearDown();
    this.memberSelectorUI.tearDown();
    this.classificationSelectorUI.tearDown();
    this.groupSelectorUI.tearDown();
  }

  @action.bound async applyAllFilters() {
    return this.hideActiveModal().then(() => {
      runInAction(() => {
        this.projectFilters.replace(
          this.filtersForm.$('projectFilters').values()
        );
        this.memberFilters.replace(
          this.filtersForm.$('memberFilters').values()
        );
        this.classificationFilters.replace(
          this.filtersForm.$('classificationFilters').values()
        );
        this.employeeGroupFilters.replace(
          this.filtersForm.$('employeeGroupFilters').values()
        );
        this.eventOriginFilters.replace(
          this.filtersForm.$('eventOriginFilters').values()
        );
      });
    });
  }

  @action.bound
  async clearAllFilters() {
    await this.hideFilterDialog();
    this.projectFilters.clear();
    this.memberFilters.clear();
    this.classificationFilters.clear();
    this.employeeGroupFilters.clear();
    this.eventOriginFilters.clear();
  }

  @computed
  get eventOriginOptions() {
    return [
      {
        title: t('Kiosk'),
        value: 'KIOSK'
      },
      {
        title: t('Time clock'),
        value: 'TIME_CLOCK'
      }
    ];
  }

  @action.bound
  openShareCompletedChecklistModal(question) {
    this.completedChecklistUuidToShare = question.checklistUuid;
    this.shareCompletedChecklistForm = new EmailChecklistForm(
      {
        fields: emailChecklistFormFields,
        rules: emailChecklistFormRules,
        labels: emailChecklistFormLabels,
        values: {
          emails: []
        }
      },
      {
        options: emailChecklistFormOptions,
        plugins: emailChecklistFormPlugins
      }
    );
    this.showModal('shareCompletedChecklist');
  }

  @action.bound
  async cancelShareCompletedChecklist() {
    await this.hideActiveModal();

    this.completedChecklistUuidToShare = null;
    this.shareCompletedChecklistForm = null;
  }

  @action.bound
  shareCompletedPDF(question) {
    const payload = {
      emails: this.shareCompletedChecklistForm.$('emails').value,
      locale: this.me.settings.languageCode || 'en-US'
    };

    request
      .post(
        `${this.rootStore.urlMicroService('toolbox')}/companies/${
          this.company.uuid
        }/checklists/${this.completedChecklistUuidToShare}/email`,
        payload
      )
      .then(() => {
        callTrack(QUESTIONS_SHARE);
        this.cancelShareCompletedChecklist();
        this.rootStore.notificationsUI.pushNotification({
          snackbar: 'warning',
          icon: 'notification',
          title: t('Completed Checklist Sent')
        });
      })
      .catch(error => {
        alertErrorHandler(error, this.setValidationDetails);
      });
  }

  @computed
  get emailListIsValid() {
    return this.shareCompletedChecklistForm?.isValid;
  }

  @action.bound
  downloadChecklistPdf = question => {
    download({
      store: this.rootStore,
      url: `${this.rootStore.urlMicroService('toolbox')}/companies/${
        this.company.uuid
      }/checklists/${question.checklistUuid}/pdf`,
      xhttpOptions: {
        sendXApiKey: true
      },
      method: 'GET',
      fileUrlAttr: 'pdfUrl'
    });
  };

  @computed
  get filtersCounter() {
    let counter = 0;
    if (this.projectFilters.length > 0) {
      counter++;
    }
    if (this.memberFilters.length > 0) {
      counter++;
    }
    if (this.classificationFilters.length > 0) {
      counter++;
    }
    if (this.employeeGroupFilters.length > 0) {
      counter++;
    }
    if (this.eventOriginFilters.length > 0) {
      counter++;
    }

    return counter;
  }

  @computed get hasAppliedFilters() {
    return !!this.filtersCounter;
  }

  @computed
  get totalPages() {
    return Math.ceil(
      this.timeClockQuestionAnswers.totalElements / this.pageSize
    );
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }
}
