import debounce from 'lodash.debounce';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import ProjectChildUI from './ProjectChildUI';

import EquipmentLogNotes from 'stores/collections/EquipmentLogNotes';
import EquipmentLogNoteAddUI from './EquipmentLogNoteAddUI';
import EquipmentLogNoteEditUI from './EquipmentLogNoteEditUI';

import { BASE_DEBOUNCE } from 'fixtures/constants';

import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import history from 'utils/history';

export default class EquipmentLogNotesUI extends ProjectChildUI {
  @observable sortField;
  @observable sortDirection;
  @observable pageSize;
  @observable page;
  @observable loading;
  @observable selectedEquipmentLogNote;

  constructor(options) {
    super(options);

    this.loading = true;
    this.sortField = 'date';
    this.sortDirection = 'desc';
    this.page = 1;
    this.pageSize = 10;

    this.selectedEquipmentLogNote = null;

    this.equipmentLogNotes = new EquipmentLogNotes(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.equipmentLogNoteAddUI = new EquipmentLogNoteAddUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.equipmentLogNoteEditUI = new EquipmentLogNoteEditUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchEquipmentLogNotesDebounced = debounce(
      this.fetchEquipmentLogNotes,
      BASE_DEBOUNCE
    );
  }

  @action.bound setup() {
    this.setupReactions();
    this.fetchEquipmentLogNotes();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchEquipmentLogNotesDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      sortField: this.sortField,
      sortDirection: this.sortDirection.toUpperCase(),
      projectUuids: this.projectUuid
    };
  }

  @computed get entryForEdit() {
    return this.parent.entryForEdit;
  }

  @computed get hasRequiredParams() {
    return this.entryForEdit.uuid;
  }

  @action.bound async fetchEquipmentLogNotes() {
    if (!this.hasRequiredParams) return;

    this.equipmentLogNotes.cancelRequest();
    this.equipmentLogNotes.reset();

    try {
      await this.equipmentLogNotes.fetch({
        url: `${this.entryForEdit.url()}/notes`,
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasEquipmentLogNotes() {
    return this.equipmentLogNotes.hasModels;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.equipmentLogNotes.totalElements / this.pageSize);
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.equipmentLogNotes.clear();
    this.page = 1;
    this.loading = true;
    this.saving = false;
    this.sortField = 'date';
    this.sortDirection = 'desc';
    this.selectedEquipmentLogNote = null;
  }

  getEquipmentLogNoteActions = equipmentLogNote => {
    return [
      {
        title: t('Edit'),
        onClick: () => {
          this.editEquipmentLogNote(equipmentLogNote);
        }
      },
      {
        title: t('Delete'),
        onClick: () => {
          this.deleteEquipmentLogNote(equipmentLogNote);
        }
      }
    ];
  };

  @action.bound async deleteEquipmentLogNote(equipmentLogNote) {
    await this.authorization.checkFeatureAccess('CRUDEquipmentLogs');

    this.selectedEquipmentLogNote = equipmentLogNote;
    this.showModal('DeleteModal');
  }

  @action.bound async cancelDeleteEquipmentLogNote() {
    await this.hideActiveModal();

    this.selectedEquipmentLogNote = null;
  }

  @action.bound async confirmDeleteEquipmentLogNote() {
    this.saving = true;

    try {
      await this.selectedEquipmentLogNote.destroy({ wait: true });

      this.refetchEquipmentLogNotes();

      this.entryForEdit.noteCount--;

      await this.hideActiveModal();

      this.selectedEquipmentLogNote = null;

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Note deleted')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound sortByLastCreated() {
    this.sortField = 'date';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchEquipmentLogNotesDebounced();
  }

  @action.bound refetchEquipmentLogNotes() {
    this.loading = true;

    if (!this.hasEquipmentLogNotes) {
      this.setPage(null, 1);
      this.fetchEquipmentLogNotes();
    } else {
      this.fetchEquipmentLogNotes();
    }
  }

  @action.bound async addEquipmentLogNote() {
    await this.authorization.checkFeatureAccess('CRUDEquipmentLogs');

    // Prevent discard changes modal on the parent Equipment Log
    this.parent.unblockHistory();

    history.push({
      pathname: `${this.project.viewUrl}/equipment/${this.entryForEdit.uuid}/notes/add`,
      search: this.baseQueryParams
    });
  }

  @action.bound async editEquipmentLogNote(equipmentLogNote) {
    await this.authorization.checkFeatureAccess('CRUDEquipmentLogs');

    // Prevent discard changes modal on the parent Equipment Log
    this.parent.unblockHistory();

    history.push({
      pathname: `${this.project.viewUrl}/equipment/${this.entryForEdit.uuid}/notes/${equipmentLogNote.uuid}`,
      search: this.baseQueryParams
    });
  }
}
