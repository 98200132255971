import MobxReactForm from 'mobx-react-form';
import { action } from 'mobx';
import extendedPlugins from './utils/extendedPlugins';

const companyTimeCardsSignOffFormPlugins = extendedPlugins;

const companyTimeCardsSignOffFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false,
  autoParseNumbers: true
};

const companyTimeCardsSignOffFormFields = [
  'id',
  'enabled',
  'scheduleData',
  'scheduleData.time',
  'scheduleData.time.hour',
  'scheduleData.time.minute',
  'scheduleData.time.period',
  'scheduleData.actionDayOfWeek',
  'scheduleData.firstDueDate',
  'scheduleData.actionDayOfMonth',
  'scheduleData.lastDayNotification',
  'signOffDisclaimer',
  'timeType',
  'weekFirstDay',
  'approvalNotificationsEnabled',
  'approvalScheduleData',
  'approvalScheduleData.time.hour',
  'approvalScheduleData.time.minute',
  'approvalScheduleData.time.period',
  'approvalScheduleData.actionDayOfWeek',
  'approvalScheduleData.firstDueDate',
  'approvalScheduleData.actionDayOfMonth',
  'approvalScheduleData.lastDayNotification'
];

const companyTimeCardsSignOffFormRules = {
  'scheduleData.actionDayOfWeek': 'numeric|required',
  'scheduleData.firstDueDate': 'string|required',
  'scheduleData.actionDayOfMonth': 'numeric|required',
  signOffDisclaimer: 'string|max:1000'
};

const companyTimeCardsSignOffFormValues = {};

const companyTimeCardsSignOffFormDisabled = {};

const companyTimeCardsSignOffFormLabels = {};

class CompanyTimeCardsSignOffForm extends MobxReactForm {
  constructor(settings, options) {
    super(settings, options);

    this.initValidationForTimeType();
  }

  @action.bound
  initValidationForTimeType() {
    const timeType = this.$('timeType').value;
    const enabled = this.$('enabled').value;

    if (enabled === false) {
      this.$('scheduleData.actionDayOfWeek').set('rules', 'numeric');
      this.$('scheduleData.firstDueDate').set('rules', 'string');
      this.$('scheduleData.actionDayOfMonth').set('rules', 'numeric');

      return;
    }

    if (timeType === 'DAILY') {
      this.$('scheduleData.actionDayOfWeek').set('rules', 'numeric');
      this.$('scheduleData.firstDueDate').set('rules', 'string');
      this.$('scheduleData.actionDayOfMonth').set('rules', 'numeric');
    }

    if (timeType === 'WEEKLY') {
      this.$('scheduleData.actionDayOfWeek').set('rules', 'numeric|required');
      this.$('scheduleData.firstDueDate').set('rules', 'string');
      this.$('scheduleData.actionDayOfMonth').set('rules', 'numeric');
    }

    if (timeType === 'BI_WEEKLY') {
      this.$('scheduleData.actionDayOfWeek').set('rules', 'numeric|required');
      this.$('scheduleData.firstDueDate').set(
        'rules',
        this.$('scheduleData.lastDayNotification').value
          ? 'string'
          : 'string|required'
      );
      this.$('scheduleData.actionDayOfMonth').set('rules', 'numeric');
    }

    if (timeType === 'MONTHLY') {
      this.$('scheduleData.actionDayOfWeek').set('rules', 'numeric');
      this.$('scheduleData.firstDueDate').set('rules', 'string');
      this.$('scheduleData.actionDayOfMonth').set('rules', 'numeric|required');
    }
  }
}

export {
  CompanyTimeCardsSignOffForm,
  companyTimeCardsSignOffFormOptions,
  companyTimeCardsSignOffFormFields,
  companyTimeCardsSignOffFormRules,
  companyTimeCardsSignOffFormPlugins,
  companyTimeCardsSignOffFormValues,
  companyTimeCardsSignOffFormDisabled,
  companyTimeCardsSignOffFormLabels
};
