import isNil from 'lodash.isnil';
import omitBy from 'lodash.omitby';
import orderBy from 'lodash.orderby';
import capitalize from 'lodash.capitalize';
import moment from 'moment';
import { action, computed, observable, when } from 'mobx';
import SettingsChildUI from './SettingsChildUI';
import {
  SettingsInfoForm,
  settingsInfoFormFields,
  settingsInfoFormLabels,
  settingsInfoFormOptions,
  settingsInfoFormPlugins,
  settingsInfoFormRules,
  settingsInfoFormSaveWarningFields,
  settingsInfoFormValues
} from 'forms/project/settingsInfo';

import isDataURL from 'utils/isDataURL';
import parseGeolocation from 'utils/parseGeolocation';
import geocodeAddress from 'utils/geocodeAddress';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import ProjectTemplates from 'stores/collections/ProjectTemplates';

import { callTrack } from 'utils/segmentIntegration';
import { PROJECT_UPDATED } from 'utils/segmentAnalytics/eventSpec';
import history from 'utils/history';

import searchedPlaceReaction from 'utils/searchedPlaceReaction';
import countryReaction from 'utils/countryReaction';
import request from 'axios';

export default class SettingsInfoUI extends SettingsChildUI {
  @observable searchedPlace;
  @observable loading;
  @observable currentTemplate;

  constructor(options) {
    super(options);
    this.searchedPlace = null;
    this.loading = true;

    this.currentTemplate = null;
    this.templates = new ProjectTemplates(null, {
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound setup() {
    when(
      () => this.project,
      () => {
        this.groupSelectorUI.setup();
        this.setupForm();
        this.setupReactions();
      }
    );
  }

  @action.bound tearDown() {
    this.groupSelectorUI.tearDown();
    this.projectTemplates.clear();
    this.clearSearchedPlace();
    this.tearDownReactions();
    this.clearUIState();
    this.unblockHistory();
  }

  @computed get title() {
    return t('Project info');
  }

  @computed get headerTitle() {
    return t('Manage your project settings and template');
  }

  @computed get hasWriteAccess() {
    if (this.project.isChildProject) {
      return this.authorization.canEditProjectState;
    }

    return this.authorization.canEditProjectInfo;
  }

  @computed get hasWriteTemplateAccess() {
    return this.authorization.canApplyProjectTemplate;
  }

  @computed get projectTemplates() {
    return this.templates;
  }

  @computed
  get hasExternalId() {
    return Boolean(this.project.externalId);
  }

  @computed
  get projectNoDisabled() {
    return this.hasExternalId && Boolean(this.project.projectNo);
  }

  @computed get projectTemplateOptions() {
    return orderBy(
      this.projectTemplates.models.map(template => {
        return {
          value: template.id,
          title: template.displayName
        };
      }),
      ['title'],
      ['asc']
    );
  }

  @computed get currentProjectTemplateOption() {
    return this.projectTemplateOptions.find(
      option => option.value === this.currentTemplate
    );
  }

  @action.bound setupForm() {
    this.fetchProjectTemplates();
    this.form = new SettingsInfoForm(
      {
        fields: settingsInfoFormFields,
        rules: settingsInfoFormRules,
        labels: settingsInfoFormLabels,
        values: Object.assign(settingsInfoFormValues, {
          ...this.project.infoFormValues
        })
      },
      {
        options: settingsInfoFormOptions,
        plugins: settingsInfoFormPlugins
      }
    );

    this.currentTemplate = this.project.templateId;

    this.blockHistoryIfFormChanged();
  }

  @action.bound
  setupReactions() {
    this.reactToSearchedPlace = searchedPlaceReaction(this, this.form);

    this.reactToCountry = countryReaction(this, this.form);
  }

  @action.bound tearDownReactions() {
    this.reactToSearchedPlace && this.reactToSearchedPlace();
    this.reactToCountry && this.reactToCountry();
  }

  @action.bound
  setSearchedPlace(place) {
    this.searchedPlace = parseGeolocation(place);
  }

  @action.bound
  clearSearchedPlace() {
    this.searchedPlace = null;
  }

  @action.bound async fetchProjectTemplates() {
    this.loading = true;

    await this.projectTemplates.fetch({
      params: {
        mc: 'full',
        mn: 'full',
        limit: 10000
      }
    });

    this.loading = false;
  }

  @computed
  get payload() {
    const values = this.form.values();

    if (this.project.isChildProject) {
      return {
        startDate: values.startDate
          ? moment(values.startDate).format('YYYY-MM-DD')
          : null,
        endDate: values.endDate
          ? moment(values.endDate).format('YYYY-MM-DD')
          : null,
        projectState: values.projectState
      };
    }

    if (isDataURL(values.projectImage)) {
      values.projectImage = {
        base64: values.projectImage
      };
    } else {
      delete values.projectImage;
    }

    return {
      name: values.name,
      startDate: values.startDate
        ? moment(values.startDate).format('YYYY-MM-DD')
        : null,
      endDate: values.endDate
        ? moment(values.endDate).format('YYYY-MM-DD')
        : null,
      projectState: values.projectState,
      projectNo: values.projectNo,
      projectImage: values.projectImage,
      address: omitBy(values.address, isNil),
      groupUuids: values.groups.map(group => group.uuid)
    };
  }

  @action.bound showSaveWarningModal() {
    this.showModal('SaveWarning');
  }

  @action.bound showProjectTemplateModal() {
    this.showModal('ProjectTemplate');
  }

  @action.bound async cancelProjectTemplateModal() {
    await this.hideActiveModal();
    this.currentTemplate = this.project.templateId;
  }

  @action.bound async cancelSaveWarningModal() {
    await this.hideActiveModal();
  }

  @action.bound async confirmProjectTemplateModal() {
    this.saving = true;

    try {
      const response = await request.patch('/ra/projects', {
        projectUuids: [this.project.uuid],
        templateId: this.currentTemplate
      });

      this.project.set(response.data.collection[0]);

      this.currentTemplate = this.project.templateId;

      // Hide the modal only after success
      await this.hideActiveModal();

      // Show on snackbar
      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: `${this.projectTemplateTitle} applied.`
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound confirmSaveWarningModal() {
    this.hideActiveModal();
    this.submitFormSuccess({
      skipSaveWarnings: true
    });
  }

  @computed get projectTemplateTitle() {
    return this.currentProjectTemplateOption?.title;
  }

  @computed get saveWarnings() {
    return this.hasExternalId
      ? settingsInfoFormSaveWarningFields
          .filter(field => this.form.$(field).check('isDirty'))
          .map(field =>
            settingsInfoFormLabels[field]
              .split(' ')
              .map(capitalize)
              .join(' ')
          )
      : [];
  }

  @computed get hasSaveWarnings() {
    return this.saveWarnings.length > 0;
  }

  @action.bound async submitFormSuccess(
    options = {
      skipSaveWarnings: false
    }
  ) {
    this.clearValidationDetails();

    if (this.hasSaveWarnings && !options.skipSaveWarnings) {
      this.showSaveWarningModal();
      return;
    }

    this.saving = true;

    const values = this.payload;

    // Attempt to geocode address
    if (values.address) {
      const geocodedAddress = await geocodeAddress(
        Object.assign({}, values.address, {
          country: values.address.country
        })
      );

      if (geocodedAddress) {
        values.address.geolocation = geocodedAddress.location;
        values.address.postCode =
          values.address.postCode || geocodedAddress.postCode;
      }
    }

    try {
      await this.project.save(values, {
        wait: true
      });

      this.unblockHistory();
      // Reset the header projects
      this.headerUI.headerProjectSelectorUI.resetProjectOptions();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Project info saved')
      });

      callTrack(PROJECT_UPDATED, {
        project_id: this.project?.id,
        project_name: this.project?.name
      });

      if (!this.project.isActive) {
        history.push({
          pathname: `/projects`
        });
      } else {
        // Reinitialize form
        this.setupForm();
      }
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @computed get projectStates() {
    const options = [
      {
        value: 'ACTIVE',
        title: t('Active')
      },
      {
        value: 'INACTIVE',
        title: t('Inactive')
      }
    ];

    if (this.authorization.canDeleteProject) {
      options.push({
        value: 'DELETED',
        title: t('Deleted')
      });
    }

    return options;
  }

  @action.bound setLocationSearchRef(ref) {
    this.locationSearchRef = ref;
  }

  @action.bound resetForm() {
    // We have to manually clear some internal state of the location
    // automplete compoment. In future we can improve this by making
    // the component use external state.
    this.locationSearchRef?.handleClearState();
    super.resetForm();
  }

  @computed get stateOptions() {
    const value = this.form.$('address.state').value;

    if (!value || this.regions.isValidUsState(value)) {
      return this.regions.asOptions;
    }

    return [{ name: t('Unrecognized: ') + value, value: value }].concat(
      this.regions.asOptions
    );
  }

  @computed get selectedState() {
    return this.stateOptions.find(state => {
      return state.value === this.form.$('address.state').value;
    });
  }

  @computed get selectedGroupUuids() {
    return this.form
      .$('groups')
      .values()
      .map(value => value.uuid);
  }

  @computed get selectedGroupOptions() {
    return this.groupSelectorUI.options.filter(option =>
      this.selectedGroupUuids.includes(option.uuid)
    );
  }
}
