import moment from 'moment';
import { action, computed, observable, reaction } from 'mobx';
import InsightsUIModel from './InsightsUIModel';

import { t } from 'utils/translate';
import abbreviateNumber from 'utils/abbreviateNumber';
import conditionalProperty from 'utils/conditionalProperty';

export default class ProjectInsightsChecklistSummary extends InsightsUIModel {
  @observable loading;

  constructor(attributes, options) {
    super(attributes, options);

    this.typeClasses = options.typeClasses;

    this.loading = true;

    // React to selection changes
    this.reactToSelection = reaction(
      () => this.params,
      params => {
        if (params.projectUuids) {
          this.fetchStats();
        } else {
          this.cancelRequest();
          this.clear();
        }
      },
      { fireImmediately: true }
    );
  }

  url() {
    return `ra/companies/${this.rootStore.me.company.uuid}/insights/checklists/summary`;
  }

  get restAttributes() {
    return [
      'assigned',
      'missed',
      'completed',
      'compliance',
      'raised',
      'pending',
      'closed'
    ];
  }

  @computed get stats() {
    return [
      {
        label: t('Checklists assigned'),
        figure: abbreviateNumber(
          Math.round(conditionalProperty(this, 'assigned', 0))
        )
      },
      {
        label: t('Checklists completed'),
        figure: abbreviateNumber(
          Math.round(conditionalProperty(this, 'completed', 0))
        )
      },
      {
        label: t('Checklist compliance'),
        figure: conditionalProperty(this, 'compliance', 0) + '%'
      },
      {
        label: t('Issues raised'),
        figure: abbreviateNumber(
          Math.round(conditionalProperty(this, 'raised', 0))
        )
      },
      {
        label: t('Issues pending'),
        figure: abbreviateNumber(
          Math.round(conditionalProperty(this, 'pending', 0))
        )
      },
      {
        label: t('Issues closed'),
        figure: abbreviateNumber(
          Math.round(conditionalProperty(this, 'closed', 0))
        )
      }
    ];
  }

  @computed get hasStats() {
    return this.stats.some(stat => stat.figure > 0);
  }

  @computed
  get params() {
    return {
      projectUuids: this.parent.projectUuid,
      startDay: moment(this.parent.startDay)
        .startOf('day')
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss'),
      endDay: moment(this.parent.endDay)
        .endOf('day')
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss'),
      typeClasses: this.typeClasses
    };
  }

  @action.bound
  async fetchStats() {
    this.loading = true;

    await this.fetch({
      params: this.params
    });

    this.loading = false;
  }
}
