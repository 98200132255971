import { action, computed, observable } from 'mobx';

import {
  ProjectTemplateDailyReportForm,
  projectTemplateDailyReportFormOptions,
  projectTemplateDailyReportFormFields,
  projectTemplateDailyReportFormRules,
  projectTemplateDailyReportFormPlugins
} from 'forms/projectTemplates/projectTemplateDailyReport';

import { t } from 'utils/translate';

import ProjectTemplateChildEditUI from './ProjectTemplateChildEditUI';

export default class ProjectTemplateDailyReportUI extends ProjectTemplateChildEditUI {
  @observable form;
  constructor(options) {
    super(options);

    this.form = null;

    this.saveTitle = t('Project template daily report saved');
  }

  @computed get selectedTemplate() {
    return this.parent.selectedTemplate;
  }

  @action.bound setup() {
    this.setupForm();
  }

  @action.bound tearDown() {
    this.form = null;
    this.unblockHistory();
  }

  @action.bound
  setupForm() {
    this.form = new ProjectTemplateDailyReportForm(
      {
        fields: projectTemplateDailyReportFormFields,
        rules: projectTemplateDailyReportFormRules,
        values: this.selectedTemplate.dailyReportFormValues
      },
      {
        options: projectTemplateDailyReportFormOptions,
        plugins: projectTemplateDailyReportFormPlugins
      }
    );

    this.blockHistoryIfFormChanged();
  }
}
