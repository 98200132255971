import { computed } from 'mobx';
import MobxReactForm from 'mobx-react-form';

import extendedPlugins from './utils/extendedPlugins';

const overTimeRuleFormPlugins = extendedPlugins;

const overTimeRuleFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const overTimeRuleFormFields = [
  'name',
  'type',
  'default',
  'rules[]',
  'rules[].order',
  'rules[].payType',
  'rules[].dailyLimit',
  'rules[].weeklyLimit'
];

class OverTimeRuleForm extends MobxReactForm {
  @computed get noRules() {
    return this.$('rules').isEmpty;
  }
}

export {
  OverTimeRuleForm,
  overTimeRuleFormFields,
  overTimeRuleFormOptions,
  overTimeRuleFormPlugins
};
