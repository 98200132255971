import MobxReactForm from 'mobx-react-form';
import validatorjs from 'validatorjs';
import { t } from 'utils/translate';

const emailIncidentFormPlugins = { dvr: validatorjs };

const emailIncidentFormOptions = {
  validateOnInit: true,
  validateOnChange: true,
  strictUpdate: false
};

const emailIncidentFormFields = ['emails[]', 'email', 'locale'];

const emailIncidentFormRules = {
  emails: 'array|required',
  email: 'required_without:emails|email'
};

const emailIncidentFormLabels = {
  emails: t('Send to')
};

class EmailIncidentForm extends MobxReactForm {}

export {
  EmailIncidentForm,
  emailIncidentFormOptions,
  emailIncidentFormFields,
  emailIncidentFormRules,
  emailIncidentFormLabels,
  emailIncidentFormPlugins
};
