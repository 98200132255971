import { action, computed } from 'mobx';
import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';
import { t } from 'utils/translate';
import moment from 'moment';

const equipmentLogFormPlugins = extendedPlugins;

const equipmentLogFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const equipmentLogFormFields = [
  'id',
  'equipment',
  'equipment.uuid',
  'equipment.name',
  'equipment.supplier',
  'equipment.equipmentId',
  'equipment.type',
  'equipment.inUseOrIdle',
  'equipment.activeProject',
  'status',
  'startDate',
  'endDate',
  'costCode',
  'costCode.uuid',
  'costCode.code',
  'costCode.division'
];

const equipmentLogFormLabels = {
  equipment: t('Equipment'),
  'equipment.uuid': t('equipment'),
  status: t('Status'),
  startDate: t('Start Date'),
  endDate: t('End Date'),
  costCode: t('Cost Code'),
  'costCode.uuid': t('cost code')
};

const equipmentLogFormValues = {
  status: 'INUSE',
  startDate: moment().format('YYYY-MM-DD')
};

const equipmentLogFormRules = {
  equipment: 'required',
  'equipment.uuid': 'required',
  startDate:
    'dateCustom:YYYY-MM-DD|required|beforeOrTodayDate:YYYY-MM-DD|equipmentLogIsSameOrBeforeDate:endDate,YYYY-MM-DD',
  endDate:
    'required_if:status,REMOVED|dateCustom:YYYY-MM-DD|equipmentLogIsSameOrAfterDate:startDate,YYYY-MM-DD'
};

const equipmentLogFormRelated = {
  startDate: ['endDate'],
  endDate: ['startDate']
};

class EquipmentLogForm extends MobxReactForm {
  @computed
  get showEndDate() {
    return Boolean(this.$('status').value === 'REMOVED');
  }

  @action.bound
  changeStatus(status) {
    this.$('status').set(status);

    if (status === 'REMOVED' && !this.$('endDate').value) {
      const today = moment().format('YYYY-MM-DD');
      this.$('endDate').set(today);
    } else {
      this.$('endDate').set(null);
    }

    this.$('endDate').validate();
  }
}

export {
  EquipmentLogForm,
  equipmentLogFormOptions,
  equipmentLogFormFields,
  equipmentLogFormRules,
  equipmentLogFormValues,
  equipmentLogFormLabels,
  equipmentLogFormPlugins,
  equipmentLogFormRelated
};
