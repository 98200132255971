import { RakenCollection } from '../lib';
import TimeCardEvent from '../models/TimeCardEvent';

export default class TimeCardEvents extends RakenCollection {
  model() {
    return TimeCardEvent;
  }

  url() {
    return `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/companies/${this.rootStore.me.company.uuid}/projects/${
      this.parent.project.uuid
    }/timecardevents`;
  }
}
