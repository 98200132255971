import { action, observable, computed } from 'mobx';
import UIStore from 'stores/ui/UIStore';
import SuperAdminCompany from 'stores/models/superAdmin/SuperAdminCompany';

import SuperAdminCompanyInfoUI from './info/SuperAdminCompanyInfoUI';
import SuperAdminCompanyProjectsUI from './projects/SuperAdminCompanyProjectsUI';
import SuperAdminCompanyUsersUI from './users/SuperAdminCompanyUsersUI';
import SuperAdminCompanySettingsUI from './settings/SuperAdminCompanySettingsUI';
import SuperAdminCompanyRolesUI from './roles/SuperAdminCompanyRolesUI';
import SuperAdminCompanySilentInviteUI from './silentInvite/SuperAdminCompanySilentInviteUI';
import SuperAdminCompanyPDFLogoUI from './pdfLogo/SuperAdminCompanyPDFLogoUI';
import SuperAdminCompanyIntegrationsUI from './integrations/SuperAdminCompanyIntegrationsUI';
import SuperAdminCompanyMergeUI from './merge/SuperAdminCompanyMergeUI';
import SuperAdminCompanyPayrollSettingsUI from './payrollSettings/SuperAdminCompanyPayrollSettingsUI';
import SuperAdminCompanySubscriptionBillingUI from './subscriptionBilling/SuperAdminCompanySubscriptionBillingUI';

import BillingUI from 'stores/ui/billing/BillingUI';

import history from 'utils/history';

export default class SuperAdminCompanyUI extends UIStore {
  @observable activeCompany;

  constructor(options) {
    super(options);
    this.activeCompany = null;

    this.superAdminCompanyInfoUI = new SuperAdminCompanyInfoUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.superAdminCompanyProjectsUI = new SuperAdminCompanyProjectsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.superAdminCompanyUsersUI = new SuperAdminCompanyUsersUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.superAdminCompanySettingsUI = new SuperAdminCompanySettingsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.superAdminCompanyRolesUI = new SuperAdminCompanyRolesUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.superAdminCompanySilentInviteUI = new SuperAdminCompanySilentInviteUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.superAdminCompanyPDFLogoUI = new SuperAdminCompanyPDFLogoUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.superAdminCompanyIntegrationsUI = new SuperAdminCompanyIntegrationsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.superAdminCompanySubscriptionBillingUI = new SuperAdminCompanySubscriptionBillingUI(
      {
        parent: this,
        rootStore: this.rootStore
      }
    );

    this.superAdminCompanyBillingUI = new BillingUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.superAdminCompanyMergeUI = new SuperAdminCompanyMergeUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.superAdminCompanyPayrollSettingsUI = new SuperAdminCompanyPayrollSettingsUI(
      {
        parent: this,
        rootStore: this.rootStore
      }
    );
  }

  @computed get extendedPermissions() {
    return this.parent.extendedPermissions;
  }

  @action.bound setup(id) {
    this.fetchEntry(id);
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.activeCompany = null;
  }

  @action.bound async fetchEntry(uuid) {
    if (this.activeCompany) return;

    let model = this.parent.companies.getByIdOrUuid(uuid);

    if (!model) {
      model = new SuperAdminCompany(
        {
          uuid: uuid
        },
        {
          rootStore: this.rootStore
        }
      );
    }

    try {
      await model.fetch();

      this.setActiveCompany(model);
    } catch (error) {
      history.replace('/companies');
    }
  }

  @action.bound setActiveCompany(model) {
    this.activeCompany = model;
  }
}
