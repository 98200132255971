import omit from 'lodash.omit';
import { action, reaction } from 'mobx';

import ProjectInsightsUIModel from './ProjectInsightsUIModel';
import ProjectMeasureStats from './ProjectMeasureStats';
import ProjectTeamMeasures from '../collections/ProjectTeamMeasures';

export default class ProjectInsightsAllTimeStats extends ProjectInsightsUIModel {
  constructor(attributes, options) {
    super(attributes, options);

    this.projectTeamMeasureStats = new ProjectMeasureStats(null, {
      rootStore: this.rootStore,
      parent: this
    });

    this.teamMeasures = new ProjectTeamMeasures(null, {
      rootStore: this.rootStore,
      parent: this
    });

    // React to selection changes
    this.reactToSelection = reaction(
      () => this.params,
      params => {
        if (params && params.projectTeamIds) {
          this.fetchStats();
        } else {
          this.cancelRequest();
          this.projectTeamMeasureStats.clear();
          this.teamMeasures.reset();
        }
      },
      { fireImmediately: true }
    );
  }

  url() {
    return '/ra/insights/allTimeStatsByProjectTeam';
  }

  @action.bound
  fetchStats() {
    this.fetch({
      params: this.params
    });
  }

  @action.bound
  parse(attributes) {
    this.parseTeamMeasureStats(attributes.rollupProjectTeamMeasureStats);
    this.parseTeamMeasures(attributes.teamMeasures);

    return {
      ...omit(attributes, ['rollupProjectTeamMeasureStats', 'teamMeasures'])
    };
  }

  @action.bound
  parseTeamMeasureStats(rollupProjectTeamMeasureStats) {
    this.projectTeamMeasureStats.set(rollupProjectTeamMeasureStats);
  }
}
