import request from 'axios';
import debounce from 'lodash.debounce';
import { action, computed, observable, reaction } from 'mobx';
import SettingsChildUI from './SettingsChildUI';

import CostCode from 'stores/models/CostCode';

import { BASE_DEBOUNCE } from 'fixtures/constants';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import history from 'utils/history';

import {
  CostCodeForm,
  costCodeFormOptions,
  costCodeFormFields,
  costCodeFormRules,
  costCodeFormPlugins,
  costCodeFormLabels
} from 'forms/costCode';

export default class SettingsCostCodeAddUI extends SettingsChildUI {
  @observable entryForAdd;
  @observable entryAddForm;

  @observable divisionNameQuery;

  @observable isDefault;

  constructor(options) {
    super(options);

    this.entryForAdd = null;
    this.entryAddForm = null;

    // Divisions
    this.divisionNameQuery = '';
    this.divisionNames = observable([]);
    this.fetchDivisionsDebounced = debounce(this.fetchDivisions, BASE_DEBOUNCE);

    this.isDefault = false;
  }

  @action.bound setup() {
    this.setupForm();
    this.setupReactions();
  }

  @action.bound setupForm() {
    this.entryForAdd = new CostCode(
      {},
      {
        rootStore: this.rootStore
      }
    );

    this.entryAddForm = new CostCodeForm(
      {
        fields: costCodeFormFields,
        rules: costCodeFormRules,
        labels: costCodeFormLabels
      },
      {
        options: costCodeFormOptions,
        plugins: costCodeFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  setupReactions() {
    this.reactToDivisionQuery = reaction(
      () => this.divisionNameQuery,
      divisionNameQuery => {
        this.fetchDivisionsDebounced();
      }
    );
  }

  @action.bound tearDown() {
    this.clearUIState();

    this.tearDownReactions();
  }

  @action.bound tearDownReactions() {
    this.reactToDivisionQuery && this.reactToDivisionQuery();
  }

  // Divisions
  @action.bound
  async fetchDivisions() {
    this.divisionNames.clear();

    if (this.divisionNameQuery.length < 2) {
      return;
    }

    try {
      const response = await request.get(
        `${this.rootStore.urlMicroService(
          'performanceTracking'
        )}/costcodes/divisions`,
        {
          params: {
            query: this.divisionNameQuery
          }
        }
      );

      this.divisionNames.replace(
        response.data.collection.map(name => {
          return {
            value: name,
            title: name
          };
        })
      );
    } catch (error) {
      console.error(error);
    }
  }

  @action.bound setDivisionNameQuery(value) {
    this.divisionNameQuery = value;
  }

  @action.bound submitEntryAddForm(event) {
    event.preventDefault();
    event.stopPropagation();

    this.entryAddForm.submit({
      onSuccess: this.submitEntryAddFormSuccess,
      onError: this.submitEntryAddFormError
    });
  }

  @action.bound async submitEntryAddFormSuccess() {
    this.saving = true;

    const companyLevelCode = this.entryAddForm.existingCostCode;

    try {
      const values = this.entryAddForm.values();

      await this.entryForAdd.save(
        {
          description: this.isDefault
            ? values.description
            : companyLevelCode?.description,
          division: values.division,
          code: values.code,
          isDefault: this.isDefault,
          projects: [
            {
              projectUuid: this.project.uuid,
              description: this.isDefault ? null : values.description
            }
          ]
        },
        {
          wait: true
        }
      );

      // TODO: Uncomment once API support for sort by updated / created
      // if (companyLevelCode) {
      //   this.parent.sortByLastUpdated();
      // } else {
      //   this.parent.sortByLastCreated();
      // }

      // TODO: Remove once API support for sort by updated / created
      this.parent.refetchCostCodes();

      this.cancelCostCodeAdd();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Cost code created')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitEntryAddFormError() {
    console.error(this.entryAddForm.errors());
  }

  @action.bound cancelCostCodeAdd() {
    history.push({
      pathname: `${this.project.viewUrl}/settings/production-tracking/costcodes`,
      search: this.baseQueryParams
    });
  }

  @action.bound clearUIState() {
    this.divisionNameQuery = '';
    this.divisionNames.clear();
    this.clearValidationDetails();
    this.activeModal = null;
    this.entryForAdd = null;
    this.entryAddForm = null;
    this.isDefault = false;
    this.saving = false;
  }

  @computed get disableCancelButton() {
    return this.saving;
  }

  @computed get disableSaveButton() {
    return Boolean(this.saving || this.costCodeExistsOnProject);
  }

  @computed get saveButtonText() {
    if (this.saving) {
      return t('Saving...');
    }

    return t('Save');
  }

  @computed
  get costCodeExistsOnProject() {
    if (this.saving) return false;

    return (
      this.entryAddForm?.existingCostCode &&
      this.entryAddForm?.existingCostCode.getProjectByUuid(this.project.uuid)
    );
  }

  @computed
  get costCodeExistsOnProjectText() {
    return t(
      'The cost code {costCode} already exists for this project. Please provide a unique combination.',
      {
        templateStrings: {
          costCode: this.entryAddForm.existingCostCode.costCodeKey
        }
      }
    );
  }

  @action.bound toggleIsDefault() {
    this.isDefault = !this.isDefault;
  }
}
