import { computed } from 'mobx';
import MobxReactForm from 'mobx-react-form';
import validatorjs from 'validatorjs';
import moment from 'moment';

const scheduleTimesheetsReportFormPlugins = { dvr: validatorjs };

const scheduleTimesheetsReportFormOptions = {
  validateOnInit: true,
  validateOnChange: true,
  strictUpdate: false
};

const scheduleTimesheetsReportFormFields = [
  'name',
  'projectIncludeOption',
  'projects',
  'projectFilters',
  'emails',
  'email',
  'includeCostCode',
  'time',
  'offsetDays',
  'frequencyType',
  'sendReportNow',
  'actionDate',
  'timeFrameType'
];

const scheduleTimesheetsReportFormValues = {
  projects: [],
  emails: [],
  projectIncludeOption: 'ACTIVE_AND_INACTIVE',
  email: '',
  offsetDays: 0,
  time: { hour: '09', minute: '00' },
  includeCostCode: true,
  frequencyType: 'PAY_PERIOD',
  sendReportNow: false,
  actionDate: moment().format('YYYY-MM-DD'),
  timeFrameType: 'CURRENT'
};

const scheduleTimesheetsReportFormRules = {
  name: 'string|required|max:255',
  emails: 'array|required',
  email: 'required_without:emails|email'
};

class ScheduleTimesheetsReportForm extends MobxReactForm {
  @computed
  get formattedTime() {
    return moment(
      `${this.$('time').value?.hour}:${this.$('time').value?.minute}`,
      'h:mm a'
    ).format('h:mm a');
  }
}

export {
  ScheduleTimesheetsReportForm,
  scheduleTimesheetsReportFormOptions,
  scheduleTimesheetsReportFormFields,
  scheduleTimesheetsReportFormValues,
  scheduleTimesheetsReportFormRules,
  scheduleTimesheetsReportFormPlugins
};
