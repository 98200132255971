import MobxReactForm from 'mobx-react-form';
import { when } from 'mobx';
import { t } from 'utils/translate';
import extendedPlugins from '../utils/extendedPlugins';

const renameFormTemplateFormPlugins = extendedPlugins;

const renameFormTemplateFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const renameFormTemplateFormFields = ['name', 'formUrl', 'initialName'];

const renameFormTemplateFormRules = {
  name: 'string|required|max:250|form_template_name_available'
};

const renameFormTemplateFormLabels = {
  name: t('name')
};

class RenameFormTemplateForm extends MobxReactForm {
  constructor(settings, options) {
    super(settings, options);
    this.rootStore = options.rootStore;

    when(
      () => this.rootStore.me.company,
      () => {
        this.$('formUrl').set(
          `ra/companies/${this.rootStore.me.company.uuid}/form-templates`
        );
      }
    );
  }
}

export {
  RenameFormTemplateForm,
  renameFormTemplateFormOptions,
  renameFormTemplateFormFields,
  renameFormTemplateFormRules,
  renameFormTemplateFormLabels,
  renameFormTemplateFormPlugins
};
