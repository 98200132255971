import React, { Component } from 'react';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import styled from 'styled-components';
import media from '../../utils/mediaTemplate';
import moment from 'moment';

import Week from './Week';
import Icon from '../Icon';
import Text from '../Text';

const Wrapper = styled.div`
  width: 290px;
  user-select: none;
  pointer-events: all;

  ${media.desktop`
    width: ${props => (props.compact ? '290' : '370')}px;
  `};
`;

const MonthSelect = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${props => props.height}px;
`;

const MonthText = styled.div`
  font-size: 22px;
  color: ${props => props.theme.colors.charcoalGrey};
  font-weight: 300;
  text-transform: capitalize;
`;

const DayHeaderRow = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.lightGrey2};
  display: flex;
  padding: 7px 10px;
  margin-bottom: 10px;
  color: ${props => props.theme.colors.darkSteel};
  font-size: 11px;
  letter-spacing: 1.1px;
  box-sizing: border-box;
  justify-content: space-around;

  ${media.desktop`
    padding: 7px 16px;
  `};
`;

const DayHeader = styled.div`
  text-align: center;
  width: 36px;
  text-transform: uppercase;
`;

const CalendarWrapper = styled.div`
  padding: 0 10px 10px 10px;
  ${props =>
    props.flexHeight &&
    `
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: ${props.flexHeight}px;
  `};

  ${media.desktop`
    padding: 0 16px;
  `};
`;

const MonthLink = styled.a`
  cursor: pointer;
`;

export default class DatePicker extends Component {
  static propTypes = {
    month: momentPropTypes.momentObj.isRequired,
    onPreviousMonthClick: PropTypes.func,
    onNextMonthClick: PropTypes.func,
    onDayClick: PropTypes.func,
    onDayDoubleClick: PropTypes.func,
    dayConfig: PropTypes.object,
    onKeyDown: PropTypes.func,
    disableBefore: momentPropTypes.momentObj,
    disableAfter: momentPropTypes.momentObj,
    subtitle: PropTypes.node,
    monthHeaderHeight: PropTypes.number,
    enableHover: PropTypes.bool,
    flexHeight: PropTypes.number,
    colorAllDays: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    greyOutAfterToday: PropTypes.bool
  };

  static defaultProps = {
    onPreviousMonthClick: () => {},
    onNextMonthClick: () => {},
    onDayClick: () => {},
    onDayDoubleClick: () => {},
    dayConfig: {},
    monthHeaderHeight: 55,
    enableHover: false
  };

  renderWeeks = () => {
    const {
      month,
      dayConfig,
      onDayClick,
      onDayDoubleClick,
      disableBefore,
      disableAfter,
      enableHover,
      colorAllDays,
      showGreenDot,
      greyOutAfterToday
    } = this.props;

    const start = month
      .clone()
      .startOf('month')
      .startOf('week');
    const currentWeek = start;
    const weeks = [];

    // always show 6 weeks
    for (let i = 0; i < 6; i++) {
      weeks.push(currentWeek.clone());
      currentWeek.add(1, 'weeks');
    }

    return weeks.map(week => (
      <Week
        startDate={week}
        month={month}
        key={week.toString()}
        dayConfig={dayConfig}
        onDayClick={onDayClick}
        onDayDoubleClick={onDayDoubleClick}
        disableBefore={disableBefore}
        disableAfter={disableAfter}
        enableHover={enableHover}
        colorAllDays={colorAllDays}
        showGreenDot={showGreenDot}
        greyOutAfterToday={greyOutAfterToday}
      />
    ));
  };

  renderNameDaysWeek = () => {
    return moment.weekdaysShort(true).map((day, i) => {
      day = day.replace('.', '');

      return <DayHeader key={i}>{day}</DayHeader>;
    });
  };

  render() {
    const {
      month,
      onNextMonthClick,
      onPreviousMonthClick,
      subtitle,
      monthHeaderHeight,
      flexHeight,
      onKeyDown,
      compact
    } = this.props;

    return (
      <Wrapper onKeyDown={onKeyDown} compact={compact}>
        <MonthSelect height={monthHeaderHeight}>
          <MonthLink
            id="Calendar_Left_Arrow"
            data-qa="link_calendar-left-arrow"
            onClick={onPreviousMonthClick}
          >
            <Icon
              kind="left-arrow"
              size={20}
              color="lightGrey1"
              marginLeft={11}
              onClick={onPreviousMonthClick}
            />
          </MonthLink>
          <Text align="center">
            <MonthText id="Calendar_Date_2" data-qa="text_calendar-date-2">
              {month.format('MMMM YYYY')}
            </MonthText>
            {subtitle}
          </Text>
          <MonthLink
            id="Calendar_Right_Arrow"
            data-qa="link_calendar-right-arrow"
            onClick={onNextMonthClick}
          >
            <Icon
              kind="right-arrow"
              size={20}
              color="lightGrey1"
              marginRight={11}
              onClick={onNextMonthClick}
            />
          </MonthLink>
        </MonthSelect>

        <DayHeaderRow>{this.renderNameDaysWeek()}</DayHeaderRow>

        <CalendarWrapper flexHeight={flexHeight}>
          {this.renderWeeks()}
        </CalendarWrapper>
      </Wrapper>
    );
  }
}
