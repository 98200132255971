import UIStore from 'stores/ui/UIStore';
import { action, computed } from 'mobx';

import alertErrorHandler from 'utils/alertErrorHandler';

import history from 'utils/history';

export default class ProjectTemplateChildEditUI extends UIStore {
  @action.bound
  resetForm() {
    this.form.reset();
  }

  @computed get disableResetButton() {
    return this.saving;
  }

  @action.bound submitForm(event) {
    event.preventDefault();
    event.stopPropagation();

    this.form.submit({
      onSuccess: this.submitFormSuccess,
      onError: this.submitFormError
    });
  }

  @action.bound
  submitFormError() {
    console.log(this.form.errors());
  }

  @action.bound
  handleSaveError(error) {
    alertErrorHandler(error, this.setValidationDetails);
    this.saving = false;
  }

  @action.bound
  async submitFormSuccess() {
    this.saving = true;

    try {
      await this.selectedTemplate.save(this.form.values(), {
        wait: true,
        url: `/ra/project-templates/${this.selectedTemplate.id}`
      });
      this.handleSaveSuccess();
      this.setupForm();
    } catch (error) {
      this.handleSaveError(error);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  handleSaveSuccess() {
    this.notifications.pushNotification({
      title: this.saveTitle,
      snackbar: 'warning',
      icon: 'notification'
    });
  }

  @action.bound blockHistoryIfFormChanged() {
    this.unblock = history.block((location, action) => {
      if (this.form && this.form.check('isDirty')) {
        this.showExitModal(location.pathname);
        return 'Blocked';
      }
    });
  }

  @action.bound unblockHistory() {
    this.unblock & this.unblock();
  }

  @action.bound
  showExitModal(nextUrl) {
    this.showModal('DiscardChangesModal');
    this.nextUrl = nextUrl;
  }

  @action.bound
  async discardChanges() {
    await this.hideActiveModal();
    this.moveToNextURL();
  }

  @action.bound
  async moveToNextURL() {
    this.unblock();
    history.push(this.nextUrl);
    this.nextUrl = null;
  }
}
