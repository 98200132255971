import { computed, toJS } from 'mobx';
import { Model } from 'mobx-mc';

export default class ProjectSummary extends Model {
  urlRoot = '/ra/project-summaries/scheduled';

  get restAttributes() {
    return [
      'id',
      'name',
      'timeFrame',
      'recurringOn',
      'projects',
      'emails',
      'cover',
      'photos',
      'workers',
      'hours',
      'safety',
      'delays',
      'missedDailies',
      'openTasks',
      'weather',
      'materials',
      'scWorkers',
      'scHours',
      'scSafety',
      'scDelays',
      'scMissedDailies',
      'sendReport',
      'projectIncludeOption',
      'equipment'
    ];
  }

  get restAttributeDefaults() {
    return {
      projects: []
    };
  }

  @computed
  get formValues() {
    return {
      name: this.name,
      timeFrame: this.timeFrame,
      recurringOn: this.recurringOn,
      projects: toJS(this.projects),
      emails: toJS(this.emails),
      cover: this.cover,
      photos: this.photos,
      workers: this.workers,
      hours: this.hours,
      safety: this.safety,
      delays: this.delays,
      missedDailies: this.missedDailies,
      openTasks: this.openTasks,
      weather: this.weather,
      materials: this.materials,
      scWorkers: this.scWorkers,
      scHours: this.scHours,
      scSafety: this.scSafety,
      scDelays: this.scDelays,
      scMissedDailies: this.scMissedDailies,
      sendReport: this.sendReport,
      projectIncludeOption: this.projectIncludeOption,
      equipment: this.equipment
    };
  }
}
