import { action, computed, observable } from 'mobx';
import UIStore from '../../UIStore';
import history from 'utils/history';
import {
  groupFormFields,
  groupFormOptions,
  groupFormPlugins,
  groupFormRules,
  groupFormValues,
  groupFormLabels,
  GroupForm
} from 'forms/group';
import Group from 'stores/models/groups/Group';
import GroupClasses from 'stores/collections/groups/GroupClasses';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

import { callTrack } from 'utils/segmentIntegration';
import { GROUP_ADDED } from 'utils/segmentAnalytics/eventSpec';

export default class GroupAddUI extends UIStore {
  @observable entryAddForm;

  constructor(options) {
    super(options);

    this.groupClasses = new GroupClasses(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.entryAddForm = null;
  }

  @action.bound async setup() {
    this.groupClasses.fetch({
      params: {
        limit: 10000
      }
    });
    this.setupForm();
  }

  @action.bound setupForm() {
    this.entryForAdd = new Group(
      {},
      {
        rootStore: this.rootStore
      }
    );

    this.entryAddForm = new GroupForm(
      {
        fields: groupFormFields,
        rules: groupFormRules,
        labels: groupFormLabels,
        values: groupFormValues
      },
      {
        options: groupFormOptions,
        plugins: groupFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.activeModal = null;
    this.entryForAdd = null;
    this.entryAddForm = null;
    this.saving = false;
  }

  @computed get groupClassOptions() {
    return this.groupClasses.models.map(groupClass => ({
      name: groupClass.name,
      uuid: groupClass.uuid
    }));
  }

  @computed get selectedChecklistClassOption() {
    return this.groupClassOptions?.find(
      option => option.id === this.entryAddForm.$('groupClass').value
    );
  }

  @action.bound
  async submitGroupAddSuccess() {
    this.saving = true;

    const { groupName, groupClass } = this.entryAddForm.trimmedValues();

    try {
      await this.entryForAdd.save(
        {
          groupName,
          groupClass
        },
        {
          wait: true
        }
      );

      callTrack(GROUP_ADDED, {
        group_class: groupClass.name,
        group_name: groupName
      });

      this.parent.sortByLastCreated();
      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Group added')
      });
      this.cancelGroupAdd();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  submitGroupAdd(e) {
    e.preventDefault();
    e.stopPropagation();

    this.entryAddForm.submit({
      onSuccess: this.submitGroupAddSuccess,
      onError: e => {
        console.error(this.entryAddForm.errors());
      }
    });
  }

  @action.bound
  cancelGroupAdd() {
    history.push({
      pathname: `/company-settings/groups`,
      search: this.baseQueryParams
    });
  }
}
