import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';

const timesheetFiltersFormPlugins = extendedPlugins;

const timesheetFiltersFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const timesheetFiltersFormFields = [
  'projectFilters[]',
  'workerFilters[]',
  'payTypeFilters[]',
  'shiftFilters[]',
  'classificationFilters[]',
  'costCodeFilters[]',
  'employeeGroupFilters[]',
  'statusFilters',
  'eventOriginFilters[]',
  'timeClockStatusFilters',
  'violationsFilters',
  'noGpsFilters'
];

const timesheetFiltersFormRules = {
  projectFilters: 'array',
  workerFilters: 'array',
  payTypeFilters: 'array',
  costCodeFilters: 'array',
  employeerGroupFilters: 'array',
  statusFilters: 'required',
  eventOriginFilters: 'array',
  timeClockStatusFilters: 'array'
};

class TimesheetFiltersForm extends MobxReactForm {}

export {
  TimesheetFiltersForm,
  timesheetFiltersFormRules,
  timesheetFiltersFormFields,
  timesheetFiltersFormOptions,
  timesheetFiltersFormPlugins
};
