import MobxReactForm from 'mobx-react-form';
import extendedPlugins from 'forms/utils/extendedPlugins';

const projectTemplateShiftsFormPlugins = extendedPlugins;

const projectTemplateShiftsFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false,
  retrieveOnlyEnabledFields: false
};

const projectTemplateShiftsFormFields = ['hiddenShifts'];

const projectTemplateShiftsFormLabels = {};

const projectTemplateShiftsFormRules = {
  hiddenShifts: 'array'
};

const projectTemplateShiftsFormValues = {
  hiddenShifts: []
};

class ProjectTemplateShiftsForm extends MobxReactForm {}

export {
  ProjectTemplateShiftsForm,
  projectTemplateShiftsFormOptions,
  projectTemplateShiftsFormFields,
  projectTemplateShiftsFormRules,
  projectTemplateShiftsFormValues,
  projectTemplateShiftsFormPlugins,
  projectTemplateShiftsFormLabels
};
