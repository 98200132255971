import { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeInUp = keyframes`
  from {
    transform: translate(0, 100%);
  }
  to {
    transform: translate(0, 0);
  }
`;

const fadeInDown = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -100%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const fadeOutUp = keyframes`
  from {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  to {
    opacity: 0;
    transform: translate(-50%, -100%);
  }
`;

const fadeInOut = keyframes`
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
`;

const spin = keyframes`
  0% {
    transform:rotate(0deg);
    opacity: 0.5;
  }
  20% {
    transform:rotate(0deg);
  }
  50% {
    opacity: 1;
  }
  100% {
    transform:rotate(360deg);
    opacity: 0.5;
  }
`;

export { fadeIn, fadeOut, fadeInDown, fadeOutUp, fadeInOut, fadeInUp, spin };
