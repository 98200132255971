import debounce from 'lodash.debounce';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import UIStore from 'stores/ui/UIStore';
import SuperAdminCompanies from 'stores/collections/superAdmin/SuperAdminCompanies';
import SuperAdminCompanyUI from './SuperAdminCompanyUI';
import SuperAdminCompanyAddUI from './SuperAdminCompanyAddUI';
import { BASE_DEBOUNCE } from 'fixtures/constants';
import alertErrorHandler from 'utils/alertErrorHandler';

export default class SuperAdminCompaniesUI extends UIStore {
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;

  constructor(options) {
    super(options);

    this.loading = false;
    this.sortField = 'name';
    this.sortDirection = 'asc';
    this.page = 1;
    this.searchQuery = '';
    this.pageSize = 20;
    this.statusFilters = observable(['ACTIVE']);

    // Companies collection
    this.companies = new SuperAdminCompanies(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.superAdminCompanyAddUI = new SuperAdminCompanyAddUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.superAdminCompanyUI = new SuperAdminCompanyUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchCompaniesDebounced = debounce(this.fetchCompanies, BASE_DEBOUNCE);
  }

  /*
  TODO: Hack for now. Remove after cleanup and update SuperAdminIntegrations collection
  */
  @computed get activeCompany() {
    return this.superAdminCompanyUI.activeCompany;
  }

  @action.bound setup() {
    window.scrollTo(0, 0);
    this.setupReactions();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchCompaniesDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      sortField: this.sortField,
      sortDirection: this.sortDirection,
      companyStatuses: this.statusFilters.join(',')
    };
  }

  @action.bound toggleStatusFilter(status) {
    if (this.statusFilters.includes(status)) {
      this.statusFilters.remove(status);
    } else {
      this.statusFilters.push(status);
    }
  }

  @action.bound async fetchCompanies() {
    this.companies.cancelRequest();
    this.companies.clear();

    try {
      await this.companies.fetch({
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasCompanies() {
    return this.companies.hasModels;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.companies.totalElements / this.pageSize);
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.searchQuery = '';
    this.companies.clear(); // Adjusted method call
    this.page = 1;
    this.loading = false;
    this.sortField = 'name';
    this.sortDirection = 'asc';
    this.statusFilters.replace(['ACTIVE']);
  }

  @computed get showEmptyState() {
    return !this.loading && !this.searchQuery && !this.hasCompanies;
  }

  @computed get showEmptySearchState() {
    return !this.loading && this.searchQuery && !this.hasCompanies;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @computed
  get extendedPermissions() {
    return this.parent.extendedPermissions;
  }

  @computed
  get showAddCompanyButton() {
    return !this.activeCompany && !this.extendedPermissions;
  }

  @action.bound
  openAddNewCompanyPage() {
    this.superAdminCompanyAddUI.setup();
    this.activeModalPage = 'addNewCompany';
  }

  @action.bound
  async closeAddNewCompanyPage() {
    await this.hideActiveModalPage();
    this.superAdminCompanyAddUI.tearDown();
  }
}
