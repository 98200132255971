import React, { Component } from 'react';
import Autocomplete from 'react-autocomplete';

import Input from './Input';
import InputMenu from './InputMenu';
import InputMenuItem from './InputMenuItem';

export default class AutoComplete extends Component {
  render() {
    return (
      <Autocomplete
        renderInput={props => (
          <Input
            {...props}
            {...this.props}
            autoComplete="nope"
            ref={ref => {
              props.ref(ref);
              this.props.innerRef && this.props.innerRef(ref);
            }}
          />
        )}
        renderMenu={(items, value, style) => <InputMenu children={items} />}
        renderItem={(item, isHighlighted) => (
          <InputMenuItem isHighlighted={isHighlighted}>
            {item.label}
          </InputMenuItem>
        )}
        wrapperStyle={{ position: 'relative' }}
        {...this.props}
      />
    );
  }
}
