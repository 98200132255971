import { action, observable } from 'mobx';
import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import { t } from 'utils/translate';

const certificationsReportFormPlugins = extendedPlugins;

const certificationsReportFormOptions = {
  validateOnInit: true,
  validateOnChange: true
};

const certificationsReportFormFields = [
  'id',
  'name',
  'projectIncludeOption',
  'projects',
  'sendReport',
  'scheduleFrequency',
  'timeFrameType',
  'email',
  'emails',
  'certificationTypeUuids'
];

const certificationsReportFormRules = {
  timeFrameType: 'required',
  emails: 'array|required',
  email: 'required_without:emails|email',
  projects: 'array|required_if:projectIncludeOption,SELECTED',
  certificationTypeUuids: 'array'
};

const certificationsReportFormValues = {
  id: '',
  name: '',
  projectIncludeOption: '',
  projects: [],
  timeFrameType: '',
  scheduleFrequency: '',
  sendReport: false,
  emails: [],
  email: '',
  certificationTypeUuids: []
};

const certificationsReportFormLabels = {
  name: t('Report name'),
  projects: t('Projects to include'),
  timeFrame: t('Time frame'),
  recurringOn: t('Recurring on'),
  emails: t('Send download link to'),
  sendReport: t('Also send report immediately'),
  certificationTypeUuids: t('Filter by type')
};

class CertificationsReportForm extends MobxReactForm {
  @observable reportType;

  constructor(settings, options) {
    super(settings, options);

    this.reportType = options.reportType;
  }

  @action.bound
  clearTimeFrame() {
    this.update({
      timeFrameType: ''
    });
  }
}

export {
  CertificationsReportForm,
  certificationsReportFormOptions,
  certificationsReportFormFields,
  certificationsReportFormRules,
  certificationsReportFormValues,
  certificationsReportFormLabels,
  certificationsReportFormPlugins
};
