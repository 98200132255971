import { action, computed, observable, reaction } from 'mobx';
import InsightsUIModel from './InsightsUIModel';
import ScheduledTalks from './../collections/ScheduledTalks';
import ParticipatingProjects from './../collections/ParticipatingProjects';
import request from 'axios';
import orderBy from 'lodash.orderby';
import errorHandler from 'utils/errorHandler';
import moment from 'moment';

export default class InsightsToolBoxTalks extends InsightsUIModel {
  @observable query;
  @observable limit;
  @observable talkFrameSelection;
  @observable loadingStatus;
  @observable participatingProjects;

  constructor(attributes, options) {
    super(attributes, options);

    this.query = '';
    this.limit = 10000;
    this.talkFrameSelection = 'ALL_TALKS';
    this.loadingStatus = false;

    this.participatingProjects = new ParticipatingProjects(null, {
      rootStore: this.rootStore,
      parent: this
    });

    this.scheduledTalksAllForInsights = new ScheduledTalks(null, {
      rootStore: this.rootStore,
      parent: this
    });

    // React to selection changes
    this.reactToSelection = reaction(
      () => this.params,
      params => {
        if (params && (params.projectTeamUuids || params.projectStatuses)) {
          this.fetchStats();
        } else {
          this.cancelRequest();
        }
      },
      { fireImmediately: true }
    );
  }

  url() {
    if (
      this.insightsUI.projectSelection === 'ALL' ||
      this.insightsUI.projectSelection === 'ACTIVE'
    ) {
      return '/ra/insights/allTbtInsightsByAllProjects';
    }

    return '/ra/insights/allTbtInsightsByProjectTeam';
  }

  get restAttributes() {
    return [
      'completedTalks',
      'compliancePercent',
      'missedTalks',
      'participating'
    ];
  }

  @computed
  get params() {
    if (this.insightsUI.projects.fetching) return null;

    switch (this.insightsUI.projectSelection) {
      case 'ALL':
        return {
          projectStatuses: 'ACTIVE,INACTIVE',
          startDay: this.insightsUI.startDay,
          endDay: this.insightsUI.endDay
        };
      case 'ACTIVE':
        return {
          projectStatuses: 'ACTIVE',
          startDay: this.insightsUI.startDay,
          endDay: this.insightsUI.endDay
        };
      default:
        return {
          projectTeamUuids: this.insightsUI.filteredProjectUuids.join(','),
          startDay: this.insightsUI.startDay,
          endDay: this.insightsUI.endDay
        };
    }
  }

  @computed
  get participatingProjectsFilter() {
    return this.participatingProjects.filter(project => project.talks.length);
  }

  @computed
  get participatingFormatted() {
    const participatingProjects = this.sortedParticipatingProjectsAll.length;
    const activeProjects = this.selectedParticipatingProjects.length;

    return `${participatingProjects}/${activeProjects}`;
  }

  @computed
  get hasStats() {
    return Boolean(this.sortedParticipatingProjectsAll.length);
  }

  @action.bound
  clearAll() {
    this.participatingProjects.clearModels();
  }

  @action.bound
  async fetchStats() {
    if (
      this.insightsUI.projects.fetching ||
      !this.insightsUI.hasFilteredProjects
    )
      return;

    this.loadingStatus = true;

    await this.fetchTotal();
    await this.fetchParticipatingProjects();

    this.loadingStatus = false;
  }

  @action.bound
  async fetchTotal() {
    if (!this.params.projectTeamUuids && !this.params.projectStatuses) return;

    try {
      await this.fetch({
        params: this.params
      });
    } catch (e) {
      this.set(
        {
          completedTalks: 0,
          compliancePercent: 0,
          missedTalks: 0,
          participating: 0
        },
        {
          reset: true
        }
      );
    }
  }

  @action.bound
  async fetchParticipatingProjects() {
    if (!this.params.projectTeamUuids && !this.params.projectStatuses) return;

    let url;

    if (
      this.insightsUI.projectSelection === 'ALL' ||
      this.insightsUI.projectSelection === 'ACTIVE'
    ) {
      url = '/ra/insights/allTbtInsightsListByAllProjects';
    } else {
      url = '/ra/insights/allTbtInsightsListByProjectTeam';
    }

    try {
      const response = await request.get(url, {
        params: {
          limit: 10000,
          ...this.params
        }
      });

      if (!response.data.length) return;

      this.participatingProjects.clear();

      this.insightsUI.filteredProjects.forEach(project => {
        const projectTeam = response.data.find(
          projectTeam => projectTeam.projectTeamId === project.uuid
        );

        if (projectTeam) {
          this.participatingProjects.add({
            id: project.id,
            uuid: project.uuid,
            name: project.name,
            projectState: project.projectState,
            completedTalks: projectTeam.completedTalks,
            compliancePercent: projectTeam.compliancePercent,
            missedTalks: projectTeam.missedTalks,
            participating: projectTeam.participating
          });
        } else {
          this.participatingProjects.add({
            id: project.id,
            uuid: project.uuid,
            name: project.name,
            projectState: project.projectState,
            completedTalks: 0,
            compliancePercent: 0,
            missedTalks: 0,
            participating: 0
          });
        }
      });
    } catch (error) {
      errorHandler(error, this.rootStore.notificationsUI.pushError);
    }
  }

  @computed
  get selectedParticipatingProjects() {
    switch (this.insightsUI.projectSelection) {
      case 'ALL':
        return this.participatingProjects.models;
      case 'ACTIVE':
        return this.participatingProjects.models.filter(({ projectState }) => {
          return projectState === 'ACTIVE';
        });
      case 'SELECTED':
        return this.insightsUI.filteredProjects
          .map(project => {
            if (
              project.projectState === 'INACTIVE' &&
              !this.insightsUI.includeInactiveProjects
            )
              return;

            return this.participatingProjects.get(project.id);
          })
          .filter(project => project);
      default:
        return this.participatingProjects.models;
    }
  }

  @computed
  get sortedParticipatingProjectsAll() {
    const participatingProjects = this.selectedParticipatingProjects.filter(
      participating => {
        return participating.hasStats;
      }
    );

    return orderBy(
      participatingProjects,
      [item => item.name.toLowerCase()],
      ['asc']
    );
  }

  @action.bound
  handleQuery(e) {
    this.query = e.target.value;
  }

  @action.bound
  handleSearchDateFormatted(search) {
    const date = moment(
      search,
      [
        'MM/DD/YYYY',
        'MM/DD/YY',
        'MMM D, YYYY',
        'MMMM D, YYYY',
        'MMMM Do,YYYY',
        'MMMM Do, YYYY'
      ],
      true
    );

    return date.isValid() ? date.format('YYYY-MM-DD') : search;
  }
}
