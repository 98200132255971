import UIStore from 'stores/ui/UIStore';
import { action, computed, observable, runInAction } from 'mobx';
import orderBy from 'lodash.orderby';
import errorHandler from 'utils/errorHandler';
import Projects from 'stores/collections/Projects';
import ProjectTemplate from 'stores/models/ProjectTemplate';
import request from 'axios';

import ProjectTemplateBreaksUI from './ProjectTemplateBreaksUI';
import ProjectTemplateShiftsUI from './ProjectTemplateShiftsUI';
import ProjectTemplatePayTypesUI from './ProjectTemplatePayTypesUI';

import ChecklistTemplates from 'stores/collections/checklists/ChecklistTemplates';

import ProjectTemplateEditUI from './ProjectTemplateEditUI';
import ProjectTemplateAddUI from './ProjectTemplateAddUI';
import ProjectTemplateSaveAsUI from './ProjectTemplateSaveAsUI';

import {
  companyProjectTemplateForm,
  companyProjectTemplateFormOptions,
  companyProjectTemplateFormFields,
  companyProjectTemplateFormRules,
  companyProjectTemplateFormValues,
  companyProjectTemplateFormPlugins,
  companyProjectTemplateFormLabels
} from 'forms/companyProjectTemplate';

import {
  QuestionForm,
  questionFormOptions,
  questionFormFields,
  questionFormRules,
  questionFormPlugins
} from 'forms/question';

import { t } from 'utils/translate';
import history from 'utils/history';

import {
  OVERTIMERULES_TEMPLATESUPDATE,
  COMPANY_PROJECT_TEMPLATES_VIEWED,
  WATERMARKS_TEMPLATE
} from 'utils/segmentAnalytics/eventSpec';

import { callTrack } from 'utils/segmentIntegration';
import alertErrorHandler from 'utils/alertErrorHandler';

export default class ProjectTemplatesUI extends UIStore {
  @observable loading;
  @observable applyingTemplates;
  @observable searchTerm;
  @observable sortDirection;
  @observable companyProjectTemplateForm;
  @observable selectedTemplate;
  @observable questionsTab;
  @observable exitModalShown;
  @observable saveModalShown;
  @observable applyTemplateModalShown;
  @observable editNoticeModalShown;
  @observable preventEditNoticeModal;
  @observable templateToDelete;

  constructor(options) {
    super(options);
    this.loading = true;
    this.applyingTemplates = false;
    this.searchTerm = '';
    this.sortDirection = 'asc';
    this.companyProjectTemplateForm = null;
    this.questionForm = null;
    this.selectedTemplate = null;
    this.exitModalShown = false;
    this.saveModalShown = false;
    this.preventEditNoticeModal = false;
    this.questionsTab = 'questions';
    this.copyCounter = 1;
    this.templateToDelete = null;

    // TODO. Eventually we will use ProjectSelectorUI
    // For now this replaces rootStore > projects
    this.projects = new Projects(null, {
      rootStore: this.rootStore
    });

    this.applyTemplateModalShown = false;
    this.selectedProjects = observable([]);

    this.projectTemplatePayTypesUI = new ProjectTemplatePayTypesUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.projectTemplateShiftsUI = new ProjectTemplateShiftsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.projectTemplateBreaksUI = new ProjectTemplateBreaksUI({
      parent: this,
      rootStore: this.rootStore
    });

    // ChecklistTemplates collection
    this.checklistTemplates = new ChecklistTemplates(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.projectTemplateAddUI = new ProjectTemplateAddUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.projectTemplateEditUI = new ProjectTemplateEditUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.projectTemplateSaveAsUI = new ProjectTemplateSaveAsUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound async setup() {
    this.fetchTemplates();
    this.fetchProjects();
    if (this.authorization.hasTimeClockAccess) {
      await this.fetchChecklistTemplates();
      callTrack(COMPANY_PROJECT_TEMPLATES_VIEWED);
    }
  }

  @action.bound tearDown() {
    this.checklistTemplates.clear();
    this.projects.clear();
    this.projectTemplates.clear();
    this.loading = true;
  }

  @action.bound fetchProjects() {
    this.projects.fetch({
      url: `ra/companies/${this.company.uuid}/projects`,
      params: {
        limit: 10000,
        projectStates: 'ACTIVE'
      }
    });
  }

  @action.bound
  async fetchTemplates() {
    this.loading = true;
    await this.projectTemplates.fetch({ params: { mc: 'full', mn: 'full' } });
    this.loading = false;
  }

  @action.bound
  sortByName() {
    if (this.sortDirection === 'asc') {
      this.sortDirection = 'desc';
    } else {
      this.sortDirection = 'asc';
    }
  }

  @action.bound
  showEditProjectModal(template) {
    this.rootStore.authorizationUI
      .checkFeatureAccess('EditProjectTemplates')
      .then(() => {
        runInAction(() => {
          this.selectedTemplate = template;
          this.initCompanyProjectTemplateForm();
          if (this.featureFlags.FF_PROJECT_TEMPLATES) {
            history.push(
              `/company-settings/project-templates/${template.id}/info`
            );
          } else {
            this.showModal('companyProjectTemplates');
          }
        });
      });
  }

  @action.bound
  showExitModal() {
    this.exitModalShown = true;
  }

  @action.bound
  cancelExitModal() {
    this.exitModalShown = false;
  }

  @action.bound
  discardExitModal() {
    this.exitModalShown = false;
    this.hideActiveModal().then(() => {
      this.clearUIState();
    });
  }

  @action.bound
  saveExitModal(e) {
    this.exitModalShown = false;
    this.submitCompanyProjectTemplateForm(e);
  }

  @action.bound
  showSaveModal() {
    this.originalTemplateName = this.companyProjectTemplateForm.$(
      'templateName'
    ).value;

    const uniqTemplateName = this.createUniqTemplateName(
      this.originalTemplateName
    );

    this.companyProjectTemplateForm.update({
      templateName: uniqTemplateName
    });
    this.saveModalShown = true;
  }

  @action.bound
  cancelSaveModal() {
    this.saveModalShown = false;
    this.companyProjectTemplateForm.update({
      templateName: this.originalTemplateName
    });

    this.originalTemplateName = '';
  }

  @action.bound
  confirmSaveModal(e) {
    this.saveModalShown = false;
    this.submitCompanyProjectTemplateForm(e, true);
  }

  @action.bound
  clearUIState() {
    this.activeModal = null;
    this.saving = false;
    this.currentFormInitialValues = null;
    this.companyProjectTemplateForm = null;
    this.questionForm = null;
    this.selectedTemplate = null;
    this.questionsTab = 'questions';
    this.clearValidationDetails();
  }

  @action.bound
  showCollaboratorQuestions() {
    this.questionsTab = 'collaboratorQuestions';
    this.questionForm.clear();
    this.questionForm.$('category').resetValidation();
    this.questionForm.$('question').resetValidation();
  }

  @action.bound
  showQuestions() {
    this.questionsTab = 'questions';
    this.questionForm.clear();
    this.questionForm.$('category').resetValidation();
    this.questionForm.$('question').resetValidation();
  }

  @computed get existingTemplateNames() {
    if (this.selectedTemplate) {
      return this.projectTemplates.models
        .filter(template => {
          return template.templateName !== this.selectedTemplate.templateName;
        })
        .map(template => {
          // Convert Pipe to Hex code as it will break rules when passed to dvr in initCompanyProjectTemplateForm
          return `${template.templateName.replace('|', '7C')}`;
        });
    }

    return this.projectTemplates.models.map(template => {
      // Convert Pipe to Hex code as it will break rules when passed to dvr in initCompanyProjectTemplateForm
      return `${template.templateName.replace('|', '7C')}`;
    });
  }

  @action.bound
  initCompanyProjectTemplateForm() {
    if (this.selectedTemplate) {
      this.currentFormInitialValues = Object.assign(
        {},
        companyProjectTemplateFormValues,
        this.selectedTemplate.formValues
      );
    } else {
      this.currentFormInitialValues = {
        ...companyProjectTemplateFormValues,
        collaboratorQuestions: companyProjectTemplateFormValues.questions,
        includeMyCompanyQuestionsForSubcontractors: true
      };
    }

    this.companyProjectTemplateForm = new companyProjectTemplateForm(
      {
        fields: companyProjectTemplateFormFields,
        labels: companyProjectTemplateFormLabels,
        rules: Object.assign(companyProjectTemplateFormRules, {
          templateName: `required|string|not_in:${this.existingTemplateNames.join(
            ','
          )}`
        }),
        values: this.currentFormInitialValues
      },
      {
        options: companyProjectTemplateFormOptions,
        plugins: companyProjectTemplateFormPlugins,
        parent: this
      }
    );

    this.questionForm = new QuestionForm(
      {
        fields: questionFormFields,
        rules: questionFormRules
      },
      {
        options: questionFormOptions,
        plugins: questionFormPlugins
      }
    );
  }

  @action.bound
  submitCompanyProjectTemplateForm(e, saveAsNew) {
    e.preventDefault();
    e.stopPropagation();

    this.companyProjectTemplateForm.submit({
      onSuccess: () => {
        this.submitCompanyProjectTemplateFormSuccess(saveAsNew);
      },
      onError: () => {
        console.log(this.companyProjectTemplateForm.errors());
      }
    });
  }

  @computed
  get saveDisabled() {
    return Boolean(
      (this.companyProjectTemplateForm.isPristine &&
        this.selectedTemplate &&
        !this.selectedTemplate.isNew) ||
        !this.companyProjectTemplateForm.isValid ||
        this.companyProjectTemplateForm.$('templateName').value.length === 0 ||
        this.saving ||
        this.overTimeRulesValueHasDisabledPayTypes
    );
  }

  @action.bound
  submitCompanyProjectTemplateFormSuccess(saveAsNew) {
    this.saving = true;

    const values = this.companyProjectTemplateForm.values();
    if (!this.featureFlags.FF_BREAK_END_TIME_ITERATIONS) {
      delete values.breakSetting;
    }

    if (!values.timeCardsStartAndEndTime) {
      values.enableKiosk = false;
      values.enableTimeClock = false;
    }

    const templateData = Object.assign({}, values, {
      questions: values.questions
        .filter(question => !question.readOnly)
        .map(question => {
          return {
            category: question.category,
            question: question.question
          };
        }),
      collaboratorQuestions: values.collaboratorQuestions.map(question => {
        return {
          category: question.category,
          question: question.question
        };
      })
    });

    if (this.selectedTemplate) {
      if (saveAsNew || this.selectedTemplate.isNew) {
        this.projectTemplates
          .create(templateData, {
            wait: true
          })
          .then(savedTemplate => {
            this.handleSaveSuccess(savedTemplate, 'create');
          })
          .catch(error => {
            this.handleSaveError(error);
          });
      } else {
        this.selectedTemplate
          .save(templateData, {
            wait: true
          })
          .then(savedTemplate => {
            this.handleSaveSuccess(savedTemplate, 'update');
          })
          .catch(error => {
            this.handleSaveError(error);
          });
      }
    } else {
      this.projectTemplates
        .create(templateData, {
          wait: true
        })
        .then(savedTemplate => {
          this.handleSaveSuccess(savedTemplate, 'create');
        })
        .catch(error => {
          this.handleSaveError(error);
        });
    }
  }

  @action.bound
  handleSaveSuccess(savedTemplate, action = 'create') {
    if (savedTemplate.isDefault) {
      this.projectTemplates.models.forEach(template => {
        if (template.isDefault && template.id !== savedTemplate.id) {
          template.set({ isDefault: false });
        }
      });
    }

    const title =
      action === 'create'
        ? t('Project Template Created')
        : t('Project Template Updated');

    this.notifications.pushNotification({
      title: title,
      showUndo: false
    });

    this.saving = false;
    this.hideModal(true);
  }

  @action.bound
  handleSaveError(error) {
    errorHandler(error, this.notifications.pushError);
    this.saving = false;
  }

  @action.bound deleteTemplate(template) {
    this.templateToDelete = template;

    this.rootStore.authorizationUI
      .checkFeatureAccess('CreateProjectTemplates')
      .then(() => {
        this.showModal('DeleteModal');
      });
  }

  @action.bound async confirmDeleteTemplate() {
    await this.templateToDelete.destroy({ wait: true });

    this.hideActiveModal().then(() => {
      this.templateToDelete = null;
    });
  }

  @action.bound cancelDeleteTemplate() {
    this.hideActiveModal().then(() => {
      this.templateToDelete = null;
    });
  }

  @action.bound
  softDeleteTemplate(e, template) {
    e.preventDefault();

    this.rootStore.authorizationUI
      .checkFeatureAccess('CreateProjectTemplates')
      .then(() => {
        const title = t('Template {name} was removed', {
          templateStrings: {
            name: template.templateName
          }
        });

        this.projectTemplates.remove(template);

        this.rootStore.notificationsUI.pushNotification({
          onUndo: () => {
            this.cancelRemoveTemplate(template);
          },
          onDismiss: () => {
            this.confirmRemoveTemplate(template);
          },
          title: title
        });
      });
  }

  @action.bound
  cancelRemoveTemplate(template) {
    this.projectTemplates.add(template);
  }

  @action.bound
  async confirmRemoveTemplate(template) {
    try {
      await template.destroy({ wait: true });
    } catch (error) {
      this.notifications.pushError(error.message);
      this.projectTemplates.add(template);
    }
  }

  createUniqTemplateName(baseName) {
    const uniqueName = `Copy of ${baseName}`;

    const notUnique = this.projectTemplates.models.find(
      template => template.templateName === uniqueName
    );

    if (notUnique) {
      return this.createUniqTemplateName(uniqueName);
    } else {
      return uniqueName;
    }
  }

  @action.bound
  duplicateProjectTemplate(template) {
    const uniqTemplateName = this.createUniqTemplateName(template.templateName);

    this.rootStore.authorizationUI
      .checkFeatureAccess('CreateProjectTemplates')
      .then(() => {
        runInAction(() => {
          if (this.featureFlags.FF_PROJECT_TEMPLATES) {
            this.projectTemplateSaveAsUI.showSaveAsModal(template);
          } else {
            this.selectedTemplate = new ProjectTemplate(
              Object.assign({}, template.formValues, {
                templateName: uniqTemplateName,
                isDefault: false
              }),
              {
                rootStore: this.rootStore
              }
            );

            this.initCompanyProjectTemplateForm();
            this.showModal('companyProjectTemplates');
          }
        });
      });
  }

  @action.bound
  setSearchTerm(value) {
    this.searchTerm = value;
  }

  @action.bound
  clearSearchTerm() {
    this.searchTerm = '';
  }

  @action.bound
  createNewProjectTemplate(e) {
    e.preventDefault();
    this.rootStore.authorizationUI
      .checkFeatureAccess('CreateProjectTemplates')
      .then(() => {
        this.initCompanyProjectTemplateForm();
        if (this.featureFlags.FF_PROJECT_TEMPLATES) {
          history.push(`/company-settings/project-templates/add/info`);
        } else {
          this.showModal('companyProjectTemplates');
        }
      });
  }

  @action.bound
  hideModal(skipExitModal) {
    if (this.companyProjectTemplateFormChanged && !skipExitModal) {
      this.showExitModal();
      return;
    }

    this.hideActiveModal();
    this.clearUIState();
  }

  @action.bound
  setTemplateAsDefault(template) {
    this.rootStore.authorizationUI
      .checkFeatureAccess('EditProjectTemplates')
      .then(() => {
        template
          .save(
            {
              isDefault: true
            },
            {
              wait: true
            }
          )
          .then(() => {
            runInAction(() => {
              this.projectTemplates.models
                .filter(
                  projectTemplate =>
                    projectTemplate.isDefault && projectTemplate !== template
                )
                .forEach(template => {
                  template.isDefault = false;
                });
            });
          });
      });
  }

  @computed
  get sortedTemplates() {
    return orderBy(
      this.projectTemplates.models,
      [projectTemplate => projectTemplate.templateName.toLowerCase()],
      [this.sortDirection]
    );
  }

  @computed
  get shownTemplates() {
    if (!this.searchTerm) {
      return this.sortedTemplates;
    }

    return this.sortedTemplates.filter(
      template =>
        template.templateName
          .toLowerCase()
          .indexOf(this.searchTerm.toLowerCase()) > -1
    );
  }

  @computed get hasShownTemplates() {
    return this.shownTemplates.length > 0;
  }

  @computed
  get companyProjectTemplateFormChanged() {
    if (!this.companyProjectTemplateForm) return null;

    if (this.selectedTemplate) {
      return (
        (this.companyProjectTemplateForm.check('isDirty') &&
          this.companyProjectTemplateForm.check('isValid')) ||
        this.companyProjectTemplateForm.$('questions').size !==
          this.selectedTemplate.questions.length ||
        this.companyProjectTemplateForm.$('collaboratorQuestions').size !==
          this.selectedTemplate.collaboratorQuestions.length
      );
    } else {
      return (
        (this.companyProjectTemplateForm.check('isDirty') &&
          this.companyProjectTemplateForm.check('isValid')) ||
        this.companyProjectTemplateForm.$('questions').size !==
          companyProjectTemplateFormValues.questions.length ||
        this.companyProjectTemplateForm.$('collaboratorQuestions').size !==
          companyProjectTemplateFormValues.questions.length
      );
    }
  }

  //Apply a template to projects
  @computed
  get sortedProjects() {
    return orderBy(
      this.projects.models.map(project => {
        return {
          value: project.uuid,
          name: project.name
        };
      }),
      [project => project.name.toLowerCase()],
      ['asc']
    );
  }

  @action.bound
  showApplyTemplateModal(template) {
    this.rootStore.authorizationUI
      .checkFeatureAccess('ApplyProjectTemplates')
      .then(() => {
        runInAction(() => {
          this.selectedTemplate = template;
          this.applyTemplateModalShown = true;
        });
      });
  }

  @action.bound
  hideApplyTemplateModal() {
    this.applyTemplateModalShown = false;
  }

  @action.bound
  clearApplyTemplateModalState() {
    this.selectedTemplate = null;
    this.selectedProjects.clear();
  }

  @computed
  get allActiveProjectsSelected() {
    return this.selectedProjects.length === this.sortedProjects.length;
  }

  @action.bound
  toggleAllActiveProjects() {
    if (this.allActiveProjectsSelected) {
      this.selectedProjects.clear();
    } else {
      this.selectedProjects.replace([...this.sortedProjects]);
    }
  }

  @action.bound
  setSelectedProjects(selectedProjects) {
    this.selectedProjects.replace([...selectedProjects]);
  }

  @action.bound
  async applyTemplateToSelectedProjects() {
    const payload = {
      templateId: this.selectedTemplate.id,
      projectUuids: this.selectedProjects.map(project => project.value)
    };

    try {
      this.applyingTemplates = true;
      await request.patch('/ra/projects', payload);

      this.hideApplyTemplateModal();
      this.applyingTemplates = false;

      this.notifications.pushNotification({
        showUndo: false,
        title: t('Apply Template'),
        text: t(
          'The survey section for this template will be applied to your project(s) starting tomorrow.'
        )
      });
    } catch (error) {
      this.applyingTemplates = false;
      errorHandler(error, this.notifications.pushError);
    }
  }

  // Overtime Rules

  @computed get overTimeRules() {
    return this.rootStore.overTimeRules;
  }

  @action.bound
  async fetchOverTimeRules() {
    try {
      await this.overTimeRules.fetch({
        params: {
          limit: 10000
        },
        add: true,
        remove: false,
        update: true
      });

      this.rootStore.companyOverTimeRulesFetched = true;
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  @action.bound
  updateOverTimeRuleType(option) {
    this.companyProjectTemplateForm.$('overtimeRuleSetUuid').set(option.id);

    callTrack(OVERTIMERULES_TEMPLATESUPDATE);
  }

  @computed get overTimeRulesSelectValue() {
    const searchModels = this.overTimeRules.models.find(
      model =>
        model.uuid ===
        this.companyProjectTemplateForm?.$('overtimeRuleSetUuid').value
    );

    if (searchModels) {
      return searchModels;
    }

    return this.overTimeRules.models.find(model => model.default);
  }

  @computed get overTimeRulesSelectValueDefault() {
    return this.overTimeRulesSelectValue?.rules.find(rule => rule.default);
  }

  @computed get formattedRuleName() {
    return this.rootStore.overTimeRulesUI.overTimeRulesType.find(
      type => type.id === this.overTimeRulesSelectValue?.type
    )?.title;
  }

  @computed get overTimeRulesValueHasDisabledPayTypes() {
    return this.overTimeRulesSelectValue?.rules?.some(rule =>
      this.companyProjectTemplateForm
        .$('hiddenPayTypes')
        .value.find(hiddenPayType => hiddenPayType === rule.payType.uuid)
    );
  }

  @computed get languageOptions() {
    return [
      { id: 1, code: 'en-US', name: 'English' },
      { id: 2, code: 'es-MX', name: 'Español' },
      { id: 3, code: 'pt-BR', name: 'Português' }
    ];
  }

  @computed get enableWatermark() {
    return !this.companyProjectTemplateForm.$('enableWatermark').value;
  }

  @computed get watermarkAlignments() {
    return [
      {
        id: 'TOP_LEFT',
        name: t('Top Left')
      },
      {
        id: 'TOP_RIGHT',
        name: t('Top Right')
      },
      {
        id: 'BOTTOM_LEFT',
        name: t('Bottom Left')
      },
      {
        id: 'BOTTOM_RIGHT',
        name: t('Bottom Right')
      }
    ];
  }

  @computed
  get costCodesOnTimeCardsOptions() {
    return [
      { id: 'NOT_REQUIRED', name: t('Cost code not required') },
      {
        id: 'REQUIRED_ON_ONE',
        name: t('Cost code required on one or more time entries')
      },
      {
        id: 'REQUIRED_ON_ALL',
        name: t('Cost codes required on all time entries')
      }
    ];
  }

  @computed
  get breaksRequirementOptions() {
    return [
      {
        name: t('Require start & end time'),
        id: 'REQUIRE_START_AND_END_TIME'
      },
      {
        name: t('Require start time & duration'),
        id: 'REQUIRE_START_TIME_AND_DURATION'
      },
      {
        name: t('Require duration only'),
        id: 'REQUIRE_DURATION_ONLY'
      }
    ];
  }

  @computed
  get classificationsOnTimeCardsOptions() {
    return [
      {
        id: 'NOT_REQUIRED',
        name: t('Classifications not required on time cards')
      },
      {
        id: 'REQUIRED_ON_ONE',
        name: t('Classifications required on one or more time entries')
      },
      {
        id: 'REQUIRED_ON_ALL',
        name: t('Classifications required on all time entries')
      }
    ];
  }

  @computed
  get classificationsEditableOnTimeCardsOptions() {
    return [
      {
        id: true,
        name: t('Allow editing of classifications on time cards')
      },
      {
        id: false,
        name: t("Don't allow editing of classifications on time cards")
      }
    ];
  }

  @action.bound async fetchChecklistTemplates() {
    try {
      await this.checklistTemplates.fetch({
        url: `${this.rootStore.urlMicroService('toolbox')}/companies/${
          this.rootStore.me.company.uuid
        }/checklist-templates/checklist-types/${
          this.rootStore.appConfig.timeClockChecklistType
        }`,
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }
  @computed get requireClockInQuestionsOptions() {
    return [
      { title: t('Clock in questions not required'), value: '' },
      ...this.checklistTemplates.models.map(template => {
        return { title: template.name, value: template.uuid };
      })
    ];
  }
  @computed get clockInQuestionsValue() {
    return this.requireClockInQuestionsOptions.find(
      option =>
        option.value ===
        this.companyProjectTemplateForm.$('clockInChecklistTemplateUuid').value
    );
  }
  @computed get requireClockOutQuestionsOptions() {
    return [
      { title: t('Clock out questions not required'), value: '' },
      ...this.checklistTemplates.models.map(template => {
        return { title: template.name, value: template.uuid };
      })
    ];
  }
  @computed get clockOutQuestionsValue() {
    return this.requireClockOutQuestionsOptions.find(
      option =>
        option.value ===
        this.companyProjectTemplateForm.$('clockOutChecklistTemplateUuid').value
    );
  }

  @computed get hasWriteAccessKiosk() {
    return (
      this.authorization.canModifyKioskSettings &&
      this.companyProjectTemplateForm.timeCaptureEnabled
    );
  }

  @computed get disableKiosk() {
    return !this.hasWriteAccessKiosk;
  }

  @computed get hasWriteAccessTimeClock() {
    return (
      this.authorization.canModifyTimeClockSettings &&
      this.companyProjectTemplateForm.timeCaptureEnabled
    );
  }

  @computed get disableTimeClock() {
    return !this.hasWriteAccessTimeClock;
  }

  @computed
  get disableFeatureCheckBoxes() {
    if (this.disableTimeClock && this.disableKiosk) return true;
    if (
      !this.companyProjectTemplateForm.$('enableKiosk').value &&
      !this.companyProjectTemplateForm.$('enableTimeClock').value
    )
      return true;
    return false;
  }

  @action.bound
  toggleWaterMark(value) {
    if (value) {
      callTrack(WATERMARKS_TEMPLATE);
    }
    this.companyProjectTemplateForm.$('enableWatermark').set(value);
  }
}
