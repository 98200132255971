import { observable, action, computed } from 'mobx';
import UIStore from 'stores/ui/UIStore';
import moment from 'moment-timezone';

import {
  CompanyEquipmentForm,
  companyEquipmentFormOptions,
  companyEquipmentFormFields,
  companyEquipmentFormRules,
  companyEquipmentFormValues,
  companyEquipmentFormLabels,
  companyEquipmentFormPlugins
} from 'forms/companyEquipment';
import CompanyEquipment from 'stores/models/equipment/CompanyEquipment';
import history from 'utils/history';

import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';
import overrideValidationMessages from 'forms/utils/overrideValidationMessages';

export default class EquipmentAddUI extends UIStore {
  @observable entryForAdd;
  @observable entryAddForm;
  @observable saving;

  constructor(options) {
    super(options);

    this.entryForAdd = null;
    this.entryAddForm = null;
    this.saving = false;
  }

  @action.bound setup() {
    this.setupForm();
  }

  @action.bound setupForm() {
    this.projectSelectorUI.setup({
      projectStates: ['ACTIVE']
    });

    this.entryForAdd = new CompanyEquipment(
      {},
      {
        rootStore: this.rootStore
      }
    );

    this.entryAddForm = new CompanyEquipmentForm(
      {
        fields: companyEquipmentFormFields,
        rules: companyEquipmentFormRules,
        labels: companyEquipmentFormLabels,
        values: companyEquipmentFormValues
      },
      {
        options: companyEquipmentFormOptions,
        plugins: overrideValidationMessages(companyEquipmentFormPlugins, {
          required_if: t('A :attribute is required when deploying equipment.')
        }),
        rootStore: this.rootStore
      }
    );
  }

  @action.bound tearDown() {
    this.clearUIState();
    this.unblockHistory();
  }

  @action clearUIState() {
    this.entryForAdd = null;
    this.entryAddForm = null;
    this.nextUrl = null;
    this.saving = false;
  }

  @action.bound blockHistoryIfFormChanged() {
    this.unblock = history.block((location, action) => {
      if (this.entryAddForm.check('isDirty')) {
        this.showDiscardModal(location.pathname);
        return 'Blocked';
      }
    });
  }

  @action.bound unblockHistory() {
    this.unblock && this.unblock();
  }

  @action.bound
  showDiscardModal(nextUrl) {
    this.showModal('DiscardChangesModal');
    this.nextUrl = nextUrl;
  }

  @action.bound
  async discardChanges() {
    await this.hideActiveModal();
    this.moveToNextUrl();
  }

  @action.bound
  async moveToNextUrl() {
    this.unblock();
    history.push(this.nextUrl);
    this.nextUrl = null;
  }

  @action.bound cancelEquipmentAdd() {
    history.push({
      pathname: `/company-settings/equipment`
    });
  }

  @action.bound
  async submitEquipmentForm(e) {
    e.preventDefault();
    await this.rootStore.authorizationUI.checkFeatureAccess(
      'CRUDOwnedEquipment'
    );
    this.entryAddForm.submit({
      onSuccess: this.submitEquipmentAddFormSuccess,
      onError: e => {
        console.error(this.entryAddForm.errors());
      }
    });
  }

  @action.bound
  async submitEquipmentAddFormSuccess() {
    this.saving = true;
    this.clearValidationDetails();
    try {
      const {
        deployedStartDate,
        deployedEndDate,
        projectUuid,
        photo,
        supplier,
        rate,
        returnDate,
        frequency,
        ...equipmentValues
      } = this.entryAddForm.values();

      const rentalDetails = {
        supplier: supplier || null,
        rate: rate || null,
        returnDate: returnDate || null,
        frequency: frequency || null
      };

      const deployment = projectUuid
        ? {
            projectUuid,
            startDate: deployedStartDate
              ? moment(deployedStartDate).format(`YYYY-MM-DD`)
              : '',
            endDate: deployedEndDate
              ? moment(deployedEndDate).format(`YYYY-MM-DD`)
              : ''
          }
        : null;

      await this.entryForAdd.save({
        ...equipmentValues,
        deployment,
        photo: photo ? { base64: photo } : null,
        ...rentalDetails
      });

      this.parent.sortByLastCreated();
      this.cancelEquipmentAdd();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Equipment added')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  setOwnershipOption(value) {
    this.entryAddForm.$('owned').set('value', value === 'owned');
  }

  @action.bound
  onChangeCondition(e, option) {
    this.entryAddForm.update({
      condition: option?.value || ''
    });

    if (option?.value === 'UNDER_MAINTENANCE') {
      this.showModal('EquipmentConditionModal');
    }
  }

  @action.bound
  async saveEquipmentConditionModal(e) {
    e?.preventDefault();
    await this.hideActiveModal();
  }

  @action.bound
  async cancelEquipmentConditionModal() {
    this.entryAddForm.$('endDate').reset();
    this.entryAddForm.$('endDate').resetValidation();
    await this.saveEquipmentConditionModal();
  }

  @action.bound
  onChangeDeployedProject(e, projectOption) {
    this.entryAddForm.update({
      projectUuid: projectOption?.value
    });
    if (projectOption?.value) {
      this.entryAddForm.$('condition').set('DEPLOYED');
    } else {
      this.entryAddForm.$('condition').reset();
      this.entryAddForm.$('deployedStartDate').reset();
      this.entryAddForm.$('deployedEndDate').reset();
    }
  }

  @computed get hasWriteAccess() {
    return this.parent.hasWriteAccess;
  }

  @computed get equipmentTypeOptions() {
    return this.parent.equipmentTypeOptions;
  }

  @computed get equipmentMakeOptions() {
    return this.parent.equipmentMakeOptions;
  }

  @computed get yearOptions() {
    return this.parent.yearOptions;
  }

  @computed get conditionOptions() {
    return this.parent.conditionOptions;
  }

  @computed get ownershipOptions() {
    return this.parent.ownershipOptions;
  }

  @computed get conditionHelperText() {
    if (
      !this.entryAddForm.$('endDate').value ||
      this.entryAddForm.$('condition').value !== 'UNDER_MAINTENANCE'
    ) {
      return null;
    }
    return `${t('Until')} ${moment(this.entryAddForm.$('endDate').value).format(
      'MMMM Do, YYYY'
    )}`;
  }
  @computed get selectedEquipmentMakeOption() {
    return this.equipmentMakeOptions.find(option => {
      return option.value === this.entryAddForm.$('make').value;
    });
  }

  @computed get selectedEquipmentTypeOption() {
    return this.equipmentTypeOptions.find(option => {
      return option.value === this.entryAddForm.$('type').value;
    });
  }

  @computed get selectedConditionOption() {
    return this.conditionOptions.find(
      option => option.value === this.entryAddForm.$('condition').value
    );
  }

  @computed get selectedYearOption() {
    return this.yearOptions.find(
      option => option.value === this.entryAddForm.$('year').value
    );
  }

  @computed get odometerOptions() {
    return this.parent.odometerOptions;
  }

  @computed get selectedOdometerOption() {
    return this.odometerOptions.find(
      option => option.value === this.entryAddForm.$('odometerType').value
    );
  }

  @computed get fuelOptions() {
    return this.parent.fuelOptions;
  }

  @computed get selectedFuelOption() {
    return this.fuelOptions.find(
      option => option.value === this.entryAddForm.$('fuelGaugeType').value
    );
  }

  @computed get frequencyOptions() {
    return this.parent.frequencyOptions;
  }

  @computed get selectedFrequencyOption() {
    return this.frequencyOptions.find(
      option => option.value === this.entryAddForm.$('frequency').value
    );
  }
}
