import { action, computed } from 'mobx';
import DocumentsConstant from 'stores/models/documents/DocumentsConstant';
import { RakenCollection } from '../../lib';

export default class DocumentsConstants extends RakenCollection {
  model() {
    return DocumentsConstant;
  }

  url() {
    return `/ra/companies/${this.rootStore.me.company.uuid}/document-storage/file-types`;
  }

  @computed get extensions() {
    return this.models.reduce((extensions, category) => {
      category.fileTypes.forEach(fileType => {
        fileType.supportedExtensions.forEach(extension => {
          extensions.push(`.${extension.extension.toLowerCase()}`);
        });
      });
      return extensions;
    }, []);
  }

  @action.bound removeExtensions(value) {
    return value
      .split(' ')
      .map(word => {
        for (const ext of this.extensions) {
          if (word.toLowerCase().endsWith(ext)) {
            return word.slice(0, -ext.length);
          }
        }
        return word;
      })
      .join(' ');
  }

  @computed get uploadAcceptedFileTypes() {
    return this.models.reduce((acc, category) => {
      category.fileTypes.forEach(fileType => {
        fileType.supportedExtensions.forEach(extension => {
          const { contentType, extension: ext } = extension;
          const extLower = `.${ext.toLowerCase()}`;

          if (!acc[contentType]) {
            acc[contentType] = [];
          }
          if (!acc[contentType].includes(extLower)) {
            acc[contentType].push(extLower);
          }
        });
      });
      return acc;
    }, {});
  }
}
