import { computed } from 'mobx';
import { RakenCollection } from 'stores/lib';
import QuickbooksEmployee from 'stores/models/integrations/QuickbooksEmployee';

export default class QuickbooksEmployees extends RakenCollection {
  url() {
    return `${this.rootStore.urlMicroService('integrations')}/companies/${
      this.company.uuid
    }/integrations/1019/employee`;
  }

  model() {
    return QuickbooksEmployee;
  }

  @computed get company() {
    if (this.rootStore.isSuperAdmin) {
      return this.parent.activeCompany;
    }

    return this.rootStore.me.company;
  }
}
