import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import Carousel from './Carousel';

@inject('store')
@observer
export default class CarouselWithGallery extends Component {
  render() {
    const rootStore = this.props.store;

    return (
      <Carousel
        {...this.props}
        onItemClick={index => {
          rootStore.mediaViewerUI.open({
            items: this.props.items,
            index
          });
        }}
      />
    );
  }
}
