import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import { t } from 'utils/translate';

const noteFormPlugins = extendedPlugins;

const noteFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const noteFormFields = ['category', 'comment'];

const noteFormRules = {
  category: 'required|string',
  comment: 'required|string'
};

const noteFormLabels = {
  category: t('category'),
  comment: t('description')
};

const noteFormValues = {
  category: '',
  comment: ''
};

class NoteForm extends MobxReactForm {}

export {
  NoteForm,
  noteFormOptions,
  noteFormFields,
  noteFormValues,
  noteFormRules,
  noteFormLabels,
  noteFormPlugins
};
