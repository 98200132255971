import UIStore from './UIStore';
import { action, computed, observable } from 'mobx';
import orderBy from 'lodash.orderby';

export default class DebugUI extends UIStore {
  @observable activeTab = 'featureFlags';
  @observable featureFlagsSortedByColumn = 'status';
  @observable featureFlagsSortedByDirection = 'desc';

  @action.bound
  switchTab(e, tab) {
    this.activeTab = tab;
  }

  @action.bound
  showDebugModalPage() {
    this.activeModalPage = 'debugModalPage';
  }
  @action.bound
  closeDebugModalPage() {
    this.activeModalPage = null;
    this.featureFlagsSortedByColumn = 'status';
    this.featureFlagsSortedByDirection = 'desc';
  }

  @action.bound
  featureFlagsSortByColumn(column) {
    if (column === this.featureFlagsSortedByColumn) {
      this.featureFlagsSortedByDirection =
        this.featureFlagsSortedByDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.featureFlagsSortedByDirection = 'asc';
    }
    this.featureFlagsSortedByColumn = column;
  }

  @computed
  get sortedFeatureFlags() {
    const featureFlags = [];
    for (const featureFlag in this.rootStore.featureFlags) {
      featureFlags.push({
        name: featureFlag,
        status: this.rootStore.featureFlags[featureFlag] ? 'ON' : 'OFF'
      });
    }
    return orderBy(
      featureFlags,
      [this.featureFlagsSortedByColumn],
      this.featureFlagsSortedByDirection
    );
  }
}
