import { RakenCollection } from '../lib';
import Task from '../models/Task';

export default class Tasks extends RakenCollection {
  model() {
    return Task;
  }

  url() {
    return `/ra/companies/${this.rootStore.me.company.uuid}/tasks`;
  }
}
