import React from 'react';
import moment from 'moment-timezone';
import { t } from 'utils/translate';

const contentStyle = {
  fontFamily: `-apple-system, BlinkMacSystemFont, sans-serif`,
  fontWeight: 500,
  lineHeight: 1.75,
  whiteSpace: 'normal',
  paddingTop: '10px'
};

const headingStyle = {
  width: '100%',
  textAlign: 'center'
};

const textLeftRightStyle = {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between'
};

const imageContainerStyle = {
  width: '50px',
  height: '50px',
  display: 'inline-flex',
  overflow: 'hidden',
  position: 'relative',
  fontSize: '2rem',
  alignItems: 'center',
  flexShrink: 0,
  lineHeight: 1,
  userSelect: 'none',
  borderRadius: '50%',
  justifyContent: 'center'
};

const imageStyle = {
  color: 'transparent',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  textAlign: 'center'
};

const textStyle = { margin: '0' };

const MapInfoWindow = ({ workerEvent }) => {
  return (
    <>
      <div id="content" style={contentStyle}>
        <div id="bodyContent">
          {workerEvent.workerDetails.avatarUrl && (
            <div style={headingStyle}>
              <div style={imageContainerStyle}>
                <img
                  src={workerEvent.workerDetails.avatarUrl}
                  style={imageStyle}
                  alt={'avatar'}
                />
              </div>
            </div>
          )}
          <h3
            id="firstHeading"
            className="firstHeading"
            style={{ ...textStyle, ...headingStyle }}
          >
            {workerEvent.workerDetails.firstName}{' '}
            {workerEvent.workerDetails.lastName}
          </h3>
          <div>
            <p style={{ ...textStyle, ...headingStyle, color: '#686664' }}>
              {workerEvent.workerDetails.employeeId}
              {workerEvent.workerDetails.employeeId &&
              workerEvent.workerDetails.classificationName
                ? ' | '
                : ''}
              {workerEvent.workerDetails.classificationName}
            </p>
          </div>
          <div
            style={{
              padding: '8px',
              borderTop: ' 1px solid #E4E3E2',
              marginTop: '6px'
            }}
          >
            <div style={textLeftRightStyle}>
              <p style={{ margin: '0 30px 0 0', color: '#22813A' }}>
                {workerEvent.eventTypeDisplayName}
              </p>
              <p style={{ ...textStyle, color: '#22813A' }}>
                {moment
                  .tz(workerEvent.deviceDate, workerEvent.timezone)
                  .format('hh:mm A')}
              </p>
            </div>
            <div style={textLeftRightStyle}>
              <p style={{ ...textStyle, color: '#686664', marginRight: '4px' }}>
                {t('Worklog:')}
              </p>
              <p style={textStyle}>{workerEvent.workLog.name}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MapInfoWindow;
