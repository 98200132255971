import styled from 'styled-components';
import React from 'react';

const TabsWrapper = styled.div`
  width: 100%;
`;

const TabsNav = styled.nav`
  background-color: ${props => props.theme.colors.white};
  box-sizing: border-box;
  display: table;
  padding: 0;
  width: ${props => (props.width ? props.width : '100%')};

  & > a {
    padding: ${props => props.padding || '1.5rem 1rem'};
    font-size: ${props => props.size || '1.4rem'};
    text-align: center;
    display: table-cell;
    vertical-align: middle;
  }
`;

export default function Tabs(props) {
  return (
    <TabsWrapper>
      <TabsNav {...props}>{props.children}</TabsNav>
    </TabsWrapper>
  );
}
