import { Model } from 'mobx-mc';

class Ping extends Model {
  get restAttributes() {
    return ['deviceId', 'timestamp', 'rawTimestamp', 'userAgent', 'ipAddress'];
  }

  get restAttributeDefaults() {
    return {
      deviceId: '',
      timestamp: '',
      rawTimestamp: '',
      userAgent: '',
      ipAddress: ''
    };
  }
}

export default Ping;
