import MobxReactForm from 'mobx-react-form';
import { t } from 'utils/translate';

import extendedPlugins from '../../utils/extendedPlugins';

const projectInfoFormPlugins = extendedPlugins;

const projectInfoFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false,
  retrieveOnlyDirtyValues: true
};

const projectInfoFormFields = [
  'name',
  'projectNo',
  'address',
  'address.streetAddress',
  'address.postCode',
  'address.state',
  'address.city',
  'address.country',
  'address.geolocation.lat',
  'address.geolocation.lng',
  'startDate',
  'endDate',
  'status',
  'projectImage.contentUrl',
  'projectImage.base64',
  'projectRadius'
];

// if externalId exists, any change to the following fields will trigger a warning modal when saving the changes.
const projectInfoFormSaveWarningFields = [
  'name',
  'address.streetAddress',
  'address.city',
  'address.state',
  'address.postCode',
  'address.country',
  'startDate',
  'endDate',
  'status'
];

const projectInfoFormRules = {
  name: 'required|string',
  'address.streetAddress': 'string|max:100',
  'address.city': 'string|max:100',
  'address.state': 'string|max:255',
  'address.postcode': 'string|max:20',
  'address.country': 'string',
  'address.geolocation.lat': 'numeric|min:-90|max:90',
  'address.geolocation.lng': 'numeric|min:-180|max:180',
  startDate: 'required|dateCustom:YYYY-MM-DD',
  endDate: 'dateCustom:YYYY-MM-DD|afterCustom:startDate,YYYY-MM-DD',
  status: 'required|string',
  'projectImage.contentUrl': 'string',
  'projectImage.base64': 'string',
  projectRadius: 'string'
};

const projectInfoFormLabels = {
  name: t('project name'),
  projectNo: t('job #'),
  'address.streetAddress': t('street address'),
  'address.city': t('city'),
  'address.state': t('state'),
  'address.postCode': t('zip code'),
  'address.country': t('country'),
  startDate: t('project start date'),
  endDate: t('project end date'),
  'projectImage.contentUrl': t('project photo'),
  'address.geolocation.lat': t('Latitude'),
  'address.geolocation.lng': t('Longitude')
};

class ProjectInfoForm extends MobxReactForm {}

export {
  ProjectInfoForm,
  projectInfoFormOptions,
  projectInfoFormFields,
  projectInfoFormSaveWarningFields,
  projectInfoFormRules,
  projectInfoFormLabels,
  projectInfoFormPlugins
};
