import request from 'axios';
import moment from 'moment';
import omit from 'lodash.omit';
import orderBy from 'lodash.orderby';
import { action, observable, computed, reaction } from 'mobx';

import ProjectInsightsChart from './ProjectInsightsChart';
import ProjectInsightsPeriods from '../collections/ProjectInsightsPeriods';
import errorHandler from 'utils/errorHandler';

export default class ProjectInsightsChartWithDateRanges extends ProjectInsightsChart {
  // Chart
  @observable chartStat;

  // Insights by day breakdown
  @observable fetchingInsightsByDay;

  constructor(attributes, options) {
    super(attributes, options);

    // Chart
    this.chartStat = options.chartStat || null;

    // Insights By Day
    this.fetchingInsightsByDay = true;

    this.insightsByDay = new ProjectInsightsPeriods(null, {
      rootStore: this.rootStore,
      parent: this
    });

    // Insights Periods Collection
    this.insightsPeriods = new ProjectInsightsPeriods(null, {
      rootStore: this.rootStore,
      parent: this
    });

    // React to selection changes
    this.reactToSelection = reaction(
      () => this.params,
      params => {
        if (params && params.projectTeamIds) {
          this.fetchStats();
        } else {
          this.cancelRequest();
          this.insightsByDay.reset();
          this.insightsPeriods.reset();
        }
      },
      { fireImmediately: true }
    );
  }

  url() {
    return '/ra/insights/statsByProjectTeam';
  }

  @action.bound
  fetchStats() {
    this.parent.fetchStatsForAllCharts();
  }

  @computed
  get isFetching() {
    return Boolean(this.fetching || this.parent.fetchingAllCharts);
  }

  @action.bound
  parse(attributes) {
    this.parseInsightsPeriods(attributes.insightsPeriods);

    return {
      ...omit(attributes, ['insightsPeriods'])
    };
  }

  @action.bound
  parseInsightsPeriods(insightsPeriods) {
    this.insightsPeriods.reset(insightsPeriods || []);
    this.fillEmptyTeamMeasures();
  }

  @action.bound
  fillEmptyTeamMeasures() {
    this.insightsPeriods.models.forEach(insightsPeriod => {
      this.parent.filteredProjectTeams.forEach((projectTeam, index) => {
        const teamMeasure = insightsPeriod.teamMeasures.models.find(
          teamMeasure => {
            return teamMeasure.projectTeamId === projectTeam.id;
          }
        );

        if (!teamMeasure) {
          insightsPeriod.teamMeasures.add({
            projectTeamId: projectTeam.id,
            stats: {
              [this.chartStat]: 0,
              company: projectTeam.company
            }
          });
        }
      });
    });
  }

  @computed
  get groupBy() {
    const diff = moment(this.parent.endDay, 'YYYY-MM-DD').diff(
      moment(this.parent.startDay, 'YYYY-MM-DD'),
      'days'
    );

    if (diff < 90) {
      return 'DAY';
    } else if (diff < 730) {
      return 'MONTH';
    } else {
      return 'YEAR';
    }
  }

  @computed
  get interval() {
    switch (this.parent.timeFrameSelection) {
      case 'LAST_14_DAYS':
        return 2;
      case 'LAST_4_WEEKS':
      case 'THIS_MONTH':
      case 'LAST_MONTH':
      case 'LAST_3_MONTHS':
        return 7;
      default:
        return 1;
    }
  }

  @computed
  get params() {
    if (!this.project) return null;

    return {
      projectTeamIds: this.parent.filteredProjectTeamIds.join(','),
      startDay: this.parent.startDay,
      endDay: this.parent.endDay,
      include: this.chartStat,
      groupBy: this.groupBy,
      interval: this.interval
    };
  }

  @computed
  get hasInsightsPeriods() {
    return this.insightsPeriods && this.insightsPeriods.length > 0;
  }

  @computed
  get dateRanges() {
    return this.insightsPeriods.models.map(
      insightsPeriod => insightsPeriod.dateRange
    );
  }

  @computed
  get teamMeasures() {
    return this.insightsPeriods.teamMeasures;
  }

  @computed
  get projectTeamTableSeries() {
    const series = [];

    this.parent.filteredProjectTeams.forEach((projectTeam, index) => {
      const seriesObject = {
        id: projectTeam.id,
        name: projectTeam.name,
        data: this.teamMeasures
          .filter(teamMeasure => {
            return teamMeasure.projectTeamId === projectTeam.id;
          })
          .map(teamMeasure => {
            return {
              teamMeasure: teamMeasure,
              y: teamMeasure.stats[this.chartStat]
            };
          })
      };

      series.push(seriesObject);
    });

    return series;
  }

  @computed
  get projectTeamTableSeriesTotal() {
    let total = 0;

    this.projectTeamTableSeries.forEach(projectTeamTableSeries => {
      projectTeamTableSeries.data.forEach(data => {
        total += data.y;
      });
    });

    return total;
  }

  @computed
  get hasDataWithValues() {
    return this.projectTeamTableSeriesTotal > 0;
  }

  @computed
  get selectedProjectTeamTableSeries() {
    return this.chartProjectTeams.map((projectTeam, index) => {
      const projectTeamTableSeries = this.projectTeamTableSeries.find(
        series => series.id === projectTeam.id
      );

      return {
        ...projectTeamTableSeries,
        index
      };
    });
  }

  @computed
  get selectedProjectTeamTableSeriesTotal() {
    let total = 0;

    this.selectedProjectTeamTableSeries.forEach(projectTeamTableSeries => {
      projectTeamTableSeries.data.forEach(data => {
        total += data.y;
      });
    });

    return total;
  }

  @computed
  get sortedSelectedProjectTeamTableSeries() {
    let series;

    if (this.sortField === 'name') {
      series = orderBy(
        this.selectedProjectTeamTableSeries,
        [series => series.name.toLowerCase()],
        [this.sortDirection]
      );
    } else {
      series = orderBy(
        this.selectedProjectTeamTableSeries,
        [
          series =>
            series.data.find(
              data => data.teamMeasure.dateRange === this.sortField
            ).y
        ],
        [this.sortDirection]
      );
    }

    return series;
  }

  @computed
  get nonSelectedProjectTeamTableSeries() {
    return this.nonChartProjectTeams.map((projectTeam, index) => {
      const projectTeamTableSeries = this.projectTeamTableSeries.find(
        series => series.id === projectTeam.id
      );

      return {
        ...projectTeamTableSeries,
        index
      };
    });
  }

  @computed
  get sortedNonSelectedProjectTeamTableSeries() {
    if (this.sortField === 'name') {
      return orderBy(
        this.nonSelectedProjectTeamTableSeries,
        [series => series.name.toLowerCase()],
        [this.sortDirection]
      );
    }

    return orderBy(
      this.nonSelectedProjectTeamTableSeries,
      [
        series =>
          series.data.find(
            data => data.teamMeasure.dateRange === this.sortField
          ).y
      ],
      [this.sortDirection]
    );
  }

  @computed
  get chartSeries() {
    const series = this.selectedProjectTeamTableSeries.slice();

    return series;
  }

  @computed
  get hasChartData() {
    return this.selectedProjectTeamTableSeriesTotal > 0;
  }

  @computed
  get seriesTotalsByDateRange() {
    const totals = [];

    this.dateRanges.forEach(dateRange => {
      const teamMeasures = this.teamMeasures.filter(teamMeasure => {
        return teamMeasure.dateRange === dateRange;
      });

      const total = teamMeasures.reduce((sum, teamMeasure) => {
        return sum + teamMeasure.stats[this.chartStat];
      }, 0);

      totals.push(total);
    });

    return totals;
  }

  @action.bound
  fetchInsightsByDay(projectTeamIds, startDay, endDay) {
    this.fetchingInsightsByDay = true;

    request
      .get(this.url(), {
        params: {
          projectTeamIds: projectTeamIds,
          startDay: startDay,
          endDay: endDay,
          include: this.chartStat,
          interval: 1,
          groupBy: 'DAY'
        }
      })
      .then(response => {
        this.insightsByDay.reset(response.data.insightsPeriods);
        this.fetchingInsightsByDay = false;
      })
      .catch(error => {
        errorHandler(error, this.rootStore.notificationsUI.pushError);
      });
  }
}
