import omit from 'lodash.omit';
import { action, computed } from 'mobx';
import ReportEntryWithAttachments from './ReportEntryWithAttachments';

import { t } from 'utils/translate';

export default class AnswerActivity extends ReportEntryWithAttachments {
  constructor(attributes, options) {
    super(attributes, options);
  }

  get restAttributes() {
    return ['answer', 'desc', 'question'];
  }

  idAttribute() {
    return 'uuid';
  }

  @computed
  get urlToSaveDescriptionAndAnswer() {
    return `${this.relatedReport.url()}/survey/answer`;
  }

  @action.bound
  parse(attributes) {
    this.parseAttachments(attributes);

    return {
      ...omit(attributes, ['attachments'])
    };
  }

  @computed get relatedCompany() {
    return this.collection.parent.company;
  }

  @computed
  get title() {
    return 'Surveys';
  }

  @computed
  get isEmpty() {
    return !this.desc && this.answer === 'NA' && !this.hasAttachments;
  }

  @computed get displayAnswer() {
    switch (this.answer) {
      case 'NA':
        return t('N/A');
      case 'YES':
        return t('Yes');
      case 'NO':
        return t('No');
      default:
        return t('N/A');
    }
  }

  @computed get displayColor() {
    switch (this.answer) {
      case 'NA':
        return 'grey';
      case 'YES':
        return 'green';
      case 'NO':
        return 'red';
      default:
        return 'grey';
    }
  }
}
