import omit from 'lodash.omit';
import { computed, action } from 'mobx';
import ReportEntryWithAttachments from './../ReportEntryWithAttachments';
import moment from 'moment';

import { t } from 'utils/translate';

export default class EquipmentLog extends ReportEntryWithAttachments {
  get restAttributes() {
    return [
      'uuid',
      'date',
      'hours',
      'idleHours',
      'description',
      'createdTimestamp',
      'updatedTimestamp',
      'type',
      'teamId',
      'project',
      'attachments',
      'equipmentDeploymentUuid',
      'costCode',
      'operator',
      'logStatus',
      'odometerReading',
      'fuelConsumption',
      'equipmentDeficiencies'
    ];
  }

  get restAttributeDefaults() {
    return {
      equipmentDeficiencies: []
    };
  }

  get urlRoot() {
    return `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/companies/${this.rootStore.me.company.uuid}/equipment/deployments/${
      this.equipmentDeploymentUuid
    }/logs`;
  }

  idAttribute() {
    return 'uuid';
  }

  @action.bound
  parse(attributes) {
    this.parseCreatedBy(attributes);
    this.parseUpdatedBy(attributes);
    this.parseProject(attributes);
    this.parseCompany(attributes);
    this.parseAttachments(attributes);

    return {
      ...omit(attributes, [
        'attachments',
        'createdBy',
        'updatedBy',
        'project',
        'company'
      ])
    };
  }

  @computed
  get formattedDate() {
    if (this.date) {
      return moment(this.date).format('YYYY-MM-DD');
    } else {
      return moment().format('YYYY-MM-DD');
    }
  }

  @computed get equipmentDeficienciesCount() {
    return this.equipmentDeficiencies.length;
  }

  @computed get formattedLogStatus() {
    if (this.logStatus === 'IN_USE') {
      return t('In use');
    }

    if (this.logStatus === 'NOT_IN_USE') {
      return t('Not in use');
    }

    if (this.logStatus === 'WITHDRAWN') {
      return t('Withdrawn');
    }

    return '';
  }

  @computed
  get formValues() {
    return {
      date: this.formattedDate,
      hours: this.hours,
      description: this.description,
      costCode: {
        uuid: this.costCode?.uuid || '',
        code: this.costCode?.code || '',
        division: this.costCode?.division || '',
        description: this.costCode?.description || ''
      },
      operator: {
        uuid: this.operator?.uuid || '',
        name: `${this.operator?.firstName} ${this.operator?.lastName}` || '',
        workerUuid: this.operator?.workerUuid || '',
        email: this.operator?.email || ''
      },
      logStatus: this.logStatus,
      idleHours: this.idleHours,
      odometerReading: this.odometerReading,
      fuelConsumption: this.fuelConsumption,
      deficiencies: this.equipmentDeficiencies.map(deficiency => {
        return {
          uuid: deficiency.uuid,
          faultCode: deficiency.faultCode,
          deficiencyDescription: deficiency.description
        };
      })
    };
  }
}
