import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { reaction } from 'mobx';
import styled from 'styled-components';
import PlacesAutocomplete, {
  geocodeByPlaceId
} from 'react-places-autocomplete';

import FormGroup from './FormGroup';
import Label from './Label';
import InputLink from './InputLink';
import { inputCss } from './utils/inputStyles';
import { t } from 'utils/translate';

const classNames = {
  input: 'places-autocomplete-input',
  autocompleteContainer: 'places-autocomplete-autocompleteContainer',
  autocompleteItem: 'places-autocomplete-autocompleteItem',
  googleLogoContainer: 'places-autocomplete-googleLogoContainer'
};

const PlacesAutocompleteStyleWrapper = styled.div`
  position: relative;

  & .${classNames.input} {
    ${inputCss};
  }

  & .${classNames.autocompleteContainer} {
    background: ${props => props.theme.colors.white};
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border: 1px solid ${props => props.theme.colors.lightGrey3};
    margin: 5px 0 0 0;
    padding: 5px 0;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 50;
    border-radius: 2px;
    max-height: '200px';
    overflow: 'scroll';
  }

  & .${classNames.autocompleteItem} {
    cursor: pointer;
    font-size: 1.4rem;
    padding: 10px;
    transition: background 0.1s ease-in-out;
    font-weight: 300;

    &:focus,
    &:hover {
      background: ${props => props.theme.colors.blueAccent};
      color: ${props => props.theme.colors.white};
      font-weight: 700;
    }
  }

  & .${classNames.googleLogoContainer} {
    text-align: right;
    padding: 1px;
  }
`;

@observer
class FormLocationSearch extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    formName: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = { address: '' };
  }

  componentDidMount() {
    const { uiStore } = this.props;

    this.reactToCancelChanges = reaction(
      () => uiStore.searchedPlace,
      searchedPlace => {
        if (!searchedPlace) {
          this.setState({ address: '' });
        }
      }
    );
  }

  componentWillUnmount() {
    this.reactToCancelChanges();
  }

  handlePlaceSelected = (address, placeId) => {
    this.setState({ address });
    geocodeByPlaceId(placeId).then(results =>
      this.props.uiStore.setSearchedPlace(results[0])
    );
  };

  handleInputChange = address => {
    this.setState({ address });
  };

  handleClearClick = () => {
    this.props.uiStore.clearSearchedPlace();
  };

  render() {
    const { uiStore, disabled, placeholder, dataQA } = this.props;

    const geolocationBias = uiStore.rootStore.userLocation
      ? {
          location: new window.google.maps.LatLng(
            uiStore.rootStore.userLocation.geolocation.lat,
            uiStore.rootStore.userLocation.geolocation.lng
          ),
          radius: uiStore.rootStore.userLocation.accuracy
        }
      : {};

    return (
      <FormGroup>
        <Label>{this.props.label}</Label>
        <PlacesAutocompleteStyleWrapper disabled={disabled}>
          <PlacesAutocomplete
            inputProps={{
              value: this.state.address,
              onChange: this.handleInputChange,
              placeholder: placeholder,
              disabled: disabled,
              'data-qa': dataQA
            }}
            classNames={classNames}
            options={{
              types: ['address'],
              ...geolocationBias
            }}
            onSelect={this.handlePlaceSelected}
          />

          {uiStore.searchedPlace && (
            <InputLink onClick={this.handleClearClick}>{t('Clear')}</InputLink>
          )}
        </PlacesAutocompleteStyleWrapper>
      </FormGroup>
    );
  }
}

export default FormLocationSearch;
