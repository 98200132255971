import { RakenCollection } from 'stores/lib';
import EquipmentDeployment from 'stores/models/equipment/EquipmentDeployment';

export default class EquipmentDeployments extends RakenCollection {
  model() {
    return EquipmentDeployment;
  }

  url() {
    return `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/companies/${this.rootStore.me.company.uuid}/equipment/deployments`;
  }
}
