import UIStore from './UIStore';
import { action, computed, observable, runInAction } from 'mobx';
import { PHOTO_ID_DETAIL_FILTERS } from 'utils/segmentAnalytics/eventSpec';

import {
  WorkerPhotoIdDetailFiltersForm,
  workerPhotoIdDetailFiltersFormFields,
  workerPhotoIdDetailFiltersFormOptions,
  workerPhotoIdDetailFiltersFormPlugins,
  workerPhotoIdDetailFiltersFormRules
} from 'forms/workerPhotoIdDetailFilters';

import errorHandler from 'utils/errorHandler';

import { callTrack } from 'utils/segmentAnalytics';

import { t } from 'utils/translate';

export default class PhotoIdDetailFiltersUI extends UIStore {
  // Filters
  @observable eventType;
  @observable photoIdStatus;

  constructor(options) {
    super(options);

    //filters
    this.eventType = '';
    this.photoIdStatus = '';
  }

  @computed
  get eventOriginOptions() {
    return [
      {
        title: t('Kiosk'),
        value: 'KIOSK'
      },
      {
        title: t('Time clock'),
        value: 'TIME_CLOCK'
      }
    ];
  }

  @computed
  get eventTypeOptions() {
    return [
      {
        title: t('All'),
        value: ''
      },
      {
        title: t('Clock In'),
        value: 'TIME_CARD_STARTED'
      },
      {
        title: t('Clock Out'),
        value: 'TIME_CARD_ENDED'
      }
    ];
  }

  @computed
  get statusOptions() {
    return [
      {
        title: t('All'),
        value: ''
      },
      {
        title: t('Verified'),
        value: 'VERIFIED'
      },
      {
        title: t('Failed'),
        value: 'FAILED'
      },
      {
        title: t('Approved'),
        value: 'APPROVED'
      },
      {
        title: t('In Progress'),
        value: 'IN_PROGRESS'
      }
    ];
  }

  @action.bound
  initFilterForm() {
    this.activeForm = new WorkerPhotoIdDetailFiltersForm(
      {
        fields: workerPhotoIdDetailFiltersFormFields,
        rules: workerPhotoIdDetailFiltersFormRules,
        values: {
          eventType: this.eventType,
          photoIdStatus: this.photoIdStatus
        }
      },
      {
        options: workerPhotoIdDetailFiltersFormOptions,
        plugins: workerPhotoIdDetailFiltersFormPlugins
      }
    );
  }

  @action.bound
  terminateFilterForm() {
    this.activeForm = null;
  }

  getWorkerPhotoIdDetailFilters() {
    const filters = localStorage.getItem(`employeePhotoIdFilters`);
    return filters ? JSON.parse(filters) : {};
  }

  @computed
  get findEventType() {
    return this.eventTypeOptions.find(
      eventType => eventType.value === this.eventType
    );
  }

  @computed
  get findStatus() {
    return this.statusOptions.find(
      status => status.value === this.photoIdStatus
    );
  }

  @action.bound
  syncWorkerPhotoIdDetailWithFilters() {
    const filters = this.getWorkerPhotoIdDetailFilters();

    this.eventType = filters.eventType || '';
    this.photoIdStatus = filters.photoIdStatus || '';
  }

  @action.bound
  persistFilters() {
    const data = {
      eventType: this.eventType,
      photoIdStatus: this.photoIdStatus
    };

    localStorage.setItem(`employeePhotoIdFilters`, JSON.stringify(data));
  }

  @action.bound
  clearPersistedFilters() {
    const data = {
      eventType: '',
      photoIdStatus: ''
    };

    try {
      localStorage.setItem(`employeePhotoIdFilters`, JSON.stringify(data));
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  @action.bound async showWorkerPhotoIdDetailFilterDialog() {
    this.showModal('workerPhotoIdDetailFilterDialog');

    callTrack(PHOTO_ID_DETAIL_FILTERS);

    try {
      this.initFilterForm();
    } catch (error) {
      this.hideActiveModal();
      errorHandler(error, this.notifications.pushError);
    }
  }

  @action.bound
  handleClearAllFiltersClick() {
    this.clearPersistedFilters();
    this.clearAllFilters();
  }

  @action.bound
  clearAllFilters() {
    return this.hideActiveModal().then(() => {
      runInAction(() => {
        this.terminateFilterForm();
        this.eventType = '';
        this.photoIdStatus = '';

        if (this.parent) {
          this.parent.page = 1;
        }
      });
    });
  }

  @action.bound async applyAllFilters() {
    return this.hideActiveModal().then(() => {
      runInAction(() => {
        this.eventType = this.activeForm.$('eventType').value;
        this.photoIdStatus = this.activeForm.$('photoIdStatus').value;

        this.terminateFilterForm();

        if (this.parent) {
          this.parent.page = 1;
        }

        this.persistFilters();
      });
    });
  }

  @action.bound
  async hideFilters() {
    await this.hideActiveModal();
  }

  @computed
  get filtersCounter() {
    let counter = 0;
    if (this.eventType) {
      counter++;
    }
    if (this.photoIdStatus) {
      counter++;
    }

    return counter;
  }

  @computed get hasAppliedFilters() {
    return !!this.filtersCounter;
  }
}
