import { computed } from 'mobx';
import DocumentsFile from 'stores/models/documents/DocumentsFile';

import DocumentsFolder from 'stores/models/documents/DocumentsFolder';
import { RakenCollection } from '../../lib';

export default class DocumentsSearch extends RakenCollection {
  url() {
    return `/ra/companies/${this.rootStore.me.company?.uuid}/document-storage/search`;
  }

  model(type) {
    switch (type) {
      case 'FOLDER':
        return DocumentsFolder;
      case 'FILE':
        return DocumentsFile;
      default:
        return DocumentsFile;
    }
  }

  @computed get isSearch() {
    return true;
  }

  @computed get viewUrl() {
    return `/projects/${this.rootStore.projectUI.project.id}/documents/search`;
  }
}
