import request from 'axios';
import { observable, action, computed, reaction } from 'mobx';
import omit from 'lodash.omit';
import debounce from 'lodash.debounce';
import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import UIStore from './UIStore';
import ProjectsMinimal from '../collections/ProjectsMinimal';

import {
  MemberForm,
  memberFormOptions,
  memberFormFields,
  memberFormFieldOptions,
  memberFormLabels,
  memberFormRules,
  memberFormPlugins
} from 'forms/member';

import {
  MembersForm,
  membersFormOptions,
  membersFormFields,
  membersFormRules,
  membersFormPlugins
} from 'forms/members';

import { t } from 'utils/translate';
import history from 'utils/history';
import { BASE_DEBOUNCE } from 'fixtures/constants';
import alertErrorHandler from 'utils/alertErrorHandler';
import { callTrack } from 'utils/segmentIntegration';

import { TEAM_MEMBER_ADDED } from 'utils/segmentAnalytics/eventSpec';
import { seatEstimate, seatEstimateTooltip } from 'utils/billing/seatEstimates';

export default class TeamMembersAddUI extends UIStore {
  @observable newMembers;
  @observable existingMembers;
  @observable memberForm;
  @observable membersForm;
  @observable step;

  @observable phoneCountry;

  // Projects
  @observable projectSelectionMethod;
  @observable loading;
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;

  constructor(options) {
    super(options);

    this.step = 'members';

    this.addSeatsModalShown = false;
    this.newMembers = null;
    this.existingMembers = null;
    this.memberForm = null;
    this.membersForm = null;

    this.activeModal = null;
    this.userToRevoke = null;

    // Projects
    this.paginatedProjects = new ProjectsMinimal(null, {
      rootStore: this.rootStore
    });

    this.loading = true;
    this.projectSelectionMethod = 'ACTIVE';
    this.selectedProjects = observable([]);
    this.sortField = 'name';
    this.sortDirection = 'ASC';
    this.searchQuery = '';
    this.pageSize = 10;
    this.page = 1;

    this.fetchProjectsDebounced = debounce(this.fetchProjects, BASE_DEBOUNCE);
  }

  @computed get projects() {
    return this.paginatedProjects;
  }

  @computed get hasProjects() {
    return this.projects.hasModels;
  }

  @action.bound setStep(step) {
    this.step = step;
  }

  @action.bound setup(step = 'members') {
    this.phoneCountry = this.defaultPhoneCountry;
    this.setStep(step);
    this.setupMemberForm();
    this.setupMembersForm();
    this.setupReactions();
    this.fetchProjects();
    this.setupWorkerAttributes();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.tearDownWorkerAttributes();
    this.clearUIState();
  }

  @action.bound async setupWorkerAttributes() {
    const promises = [
      this.costCodeSelectorUI.setup(),
      this.shiftSelectorUI.setup(),
      this.classificationSelectorUI.setup(),
      this.groupSelectorUI.setup()
    ];

    try {
      await Promise.all(promises);
    } catch (error) {
      alertErrorHandler(error, this.rootStore.notificationsUI.pushError);
    }
  }

  @action.bound tearDownWorkerAttributes() {
    this.shiftSelectorUI.tearDown();
    this.classificationSelectorUI.tearDown();
    this.costCodeSelectorUI.tearDown();
    this.costCodeSelectorUI.tearDown();
    this.groupSelectorUI.tearDown();
  }

  setupReactions() {
    this.cancelReactToParams = reaction(
      () => this.params,
      params => {
        this.fetchProjects();
      }
    );

    this.cancelReactToSearchQuery = reaction(
      () => this.searchQuery,
      params => {
        this.fetchProjectsDebounced();
      }
    );

    this.cancelReactToMemberFormRules = reaction(
      () => this.memberFormRules,
      memberFormRules => {
        this.memberForm.set('rules', this.memberFormRules);
        this.memberForm.validate();
      },
      {
        delay: 50
      }
    );
  }

  tearDownReactions() {
    this.cancelReactToParams && this.cancelReactToParams();
    this.cancelReactToSearchQuery && this.cancelReactToSearchQuery();
    this.cancelReactToMemberFormRules && this.cancelReactToMemberFormRules();
  }

  @computed get memberFormRules() {
    return Object.assign({}, memberFormRules, {
      username: `required|email|max:200|username_available|not_in:${this.membersFormUsernames}`
    });
  }

  @action.bound setupMemberForm() {
    this.memberForm = new MemberForm(
      {
        fields: memberFormFields,
        rules: this.memberFormRules,
        labels: memberFormLabels,
        options: memberFormFieldOptions
      },
      {
        options: Object.assign({}, memberFormOptions, {
          validateOnInit: true,
          showErrorsOnInit: false
        }),
        plugins: memberFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound setupMembersForm() {
    this.membersForm = new MembersForm(
      {
        fields: membersFormFields,
        rules: membersFormRules
      },
      {
        options: membersFormOptions,
        plugins: membersFormPlugins
      }
    );
  }

  @action.bound cancelMembersAdd() {
    history.push('/team');
  }

  @action.bound submitMemberForm(event) {
    event.preventDefault();

    if (this.memberForm.submitting) return;

    this.memberForm.submit({
      onSuccess: this.submitMemberFormSuccess,
      onError: this.submitMemberFormError
    });
  }

  @action.bound submitMemberFormError() {
    console.error(this.memberForm.errors());
  }

  @action.bound submitMemberFormSuccess() {
    const values = this.membersForm.$('members').values();

    values.unshift(omit(this.memberForm.trimmedValues(), 'avatar'));

    this.membersForm.update({
      members: values
    });

    this.setupMemberForm();

    setTimeout(() => {
      this.phoneCountry = '';
      this.phoneCountry = this.defaultPhoneCountry;
    }, 0);
  }

  @computed get roleOptions() {
    return this.parent.roleOptions;
  }

  @computed
  get membersFormUsernames() {
    if (!this.membersForm) return [];

    return this.membersForm
      .$('members')
      .map(member => member.$('username').value)
      .join(',');
  }

  @computed
  get membersFormEmployeeIds() {
    if (!this.membersForm) return [];

    return this.membersForm
      .$('members')
      .map(member => member.$('employeeId').value)
      .join(',');
  }

  @computed get memberFormIsValid() {
    return this.memberForm.check('isValid');
  }

  @computed get hasAddedMembers() {
    return this.membersForm.$('members').size > 0;
  }

  @computed get seatCount() {
    return this.membersForm.$('members').size;
  }

  @computed get seatEstimate() {
    if (!this.subscription || !this.hasAddedMembers) return null;

    return seatEstimate(
      this.subscription,
      this.seatCount,
      this.isRecurlyBilling
    );
  }

  @computed get seatEstimateTooltip() {
    return seatEstimateTooltip(
      this.subscription,
      this.seatCount,
      this.isRecurlyBilling
    );
  }

  @action.bound removeMember(member) {
    this.membersForm.$('members').del(member.key);

    if (!this.membersForm.$('members').size) {
      this.clearValidationDetails();
    }
  }

  @action.bound submitMembersForm(event) {
    event.preventDefault();

    if (this.membersForm.submitting) return;

    this.membersForm.submit({
      onSuccess: this.submitMembersFormSuccess,
      onError: this.submitMembersFormError
    });
  }

  @action.bound submitMembersFormSuccess() {
    // TODO : https://rakenapp.atlassian.net/browse/ENG-11854
    // Turn back on team members defaults once the API supports this.
    // this.setStep('defaults');
    this.setStep('projects');
  }

  @action.bound skipDefaults() {
    this.membersForm.$('workers').map(member => {
      member.$('defaultShift').clear();
      member.$('defaultCostCode').clear();
      member.$('defaultCrewName').clear();
    });

    this.setStep('projects');
  }

  @action.bound completeDefaults() {
    this.setStep('projects');
  }

  @action.bound submitMembersFormError() {
    console.error(this.membersForm.errors());
  }

  @action.bound
  async fetchProjects() {
    this.loading = true;

    await this.projects.fetch({
      params: pickBy(
        Object.assign(this.params, {
          query: this.searchQuery
        }),
        identity
      )
    });

    this.loading = false;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @computed get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      sortField: this.sortField,
      sortDirection: this.sortDirection,
      projectStates: 'ACTIVE'
    };
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }
  }

  @computed
  get totalPages() {
    return Math.ceil(this.projects.totalElements / this.pageSize);
  }

  @action.bound setProjectSelectionMethod(event) {
    this.selectedProjects.clear();

    if (event.target.value === 'ACTIVE') {
      this.selectAllProjects();
    }

    this.projectSelectionMethod = event.target.value;
    this.page = 1;
  }

  @computed get hasSelectedProjects() {
    return this.selectedProjects.length > 0;
  }

  @action.bound
  toggleSelectProject(projectUuid) {
    if (this.selectedProjects.find(uuid => uuid === projectUuid)) {
      this.selectedProjects.remove(projectUuid);
    } else {
      this.selectedProjects.push(projectUuid);
    }
  }

  @computed
  get allProjectsSelected() {
    return (
      this.hasProjects &&
      this.projects.models.every(project =>
        this.selectedProjects.includes(project.uuid)
      )
    );
  }

  @computed get someProjectsSelected() {
    return this.hasSelectedProjects && !this.allProjectsSelected;
  }

  @action.bound selectAllProjects() {
    this.projects.models.forEach(project => {
      if (!this.selectedProjects.includes(project.uuid)) {
        this.selectedProjects.push(project.uuid);
      }
    });
  }

  @action.bound
  toggleSelectAllProjects() {
    if (this.allProjectsSelected) {
      this.selectedProjects.replace(
        this.selectedProjects.filter(uuid => {
          return !this.projects.models
            .map(project => project.uuid)
            .includes(uuid);
        })
      );
    } else {
      this.projects.models.forEach(project => {
        if (!this.selectedProjects.includes(project.uuid)) {
          this.selectedProjects.push(project.uuid);
        }
      });
    }
  }

  @action.bound toggleIsDefault() {
    this.isDefault = !this.isDefault;
  }

  @action.bound cancelProjects() {
    this.setStep('members');
  }

  @action.bound async createMembers() {
    if (this.saving) return;

    this.saving = true;

    try {
      const memberFields = this.membersForm.trimmedValues().members;
      const message = this.membersForm.$('message').value;

      if (this.projectSelectionMethod === 'SELECTED') {
        memberFields.forEach(memberField => {
          memberField.company = {
            uuid: this.company.uuid,
            name: this.company.name
          };
          memberField.projectUuids = this.selectedProjects.slice();
          memberField.allowSubscriptionUpgrade = true;
          memberField.message = message;
        });
      } else {
        memberFields.forEach(memberField => {
          memberField.company = {
            uuid: this.company.uuid,
            name: this.company.name
          };
          delete memberField.projectUuids;
          memberField.addToActiveProjects = true;
          memberField.allowSubscriptionUpgrade = true;
          memberField.message = message;
        });
      }

      const payload = memberFields.map(memberField => {
        const memberFieldData = pickBy(
          memberField,
          value => value !== undefined && value !== '' && value !== null
        );

        memberFieldData.settings = {
          workerDefaultShift: memberField.defaultShift,
          workerDefaultCostCode: memberField.defaultCostCode,
          workerDefaultCrewName: memberField.defaultCrewName?.uuid
        };

        return omit(memberFieldData, [
          'defaultShift',
          'defaultCostCode',
          'defaultCrewName'
        ]);
      });

      await request.post(`${this.rootStore.users.url()}/batch`, payload);

      this.parent.refetchSubscription();

      this.company.fetch();

      this.parent.sortByLastCreated();
      history.push('/team');

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Team members invited')
      });

      memberFields.forEach(memberField => {
        callTrack(TEAM_MEMBER_ADDED, {
          email: memberField.username,
          first_name: memberField.firstName,
          last_name: memberField.lastName
        });
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.memberForm = null;
    this.membersForm = null;
    this.searchQuery = '';
    this.selectedProjects.clear();
    this.projectSelectionMethod = 'ACTIVE';
    this.projects.clear();
    this.page = 1;
    this.step = 'members';
    this.loading = true;
  }

  @computed
  get modalTitle() {
    if (this.step === 'members') {
      if (this.promoteWorkerMode) {
        return t('Promote Worker');
      }
      return t('Invite team members');
    }

    if (this.step === 'defaults') {
      return t('Add default attributes (optional)');
    }

    return t('Add to projects');
  }
}
