import { RakenCollection } from '../lib';
import MaterialLog from '../models/MaterialLog';

export default class MaterialLogs extends RakenCollection {
  model() {
    return MaterialLog;
  }

  url() {
    return `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/companies/${this.rootStore.me.company.uuid}/materiallogs`;
  }
}
