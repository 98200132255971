import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';

import { t } from 'utils/translate';

const surveyFormPlugins = extendedPlugins;

const surveyFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const surveyFormFields = [
  'answers[]',
  'answers[].uuid',
  'answers[].question',
  'answers[].question.id',
  'answers[].question.question',
  'answers[].question.category',
  'answers[].question.version',
  'answers[].answer',
  'answers[].desc'
];

const surveyFormRules = {
  'answers[].desc': 'string'
};

const surveyFormLabels = {
  'answers[].desc': t('description')
};

class SurveyForm extends MobxReactForm {}

export {
  SurveyForm,
  surveyFormPlugins,
  surveyFormOptions,
  surveyFormFields,
  surveyFormRules,
  surveyFormLabels
};
