import request from 'axios';
import { observable, action, computed, reaction } from 'mobx';
import debounce from 'lodash.debounce';
import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import UIStore from 'stores/ui/UIStore';
import Users from 'stores/collections/Users';

import { BASE_DEBOUNCE } from 'fixtures/constants';

export default class GroupsActionsMembersUI extends UIStore {
  // Members
  @observable memberSelectionMethod;
  @observable loading;
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable isDefault;

  constructor(options) {
    super(options);

    // Members
    this.paginatedMembers = new Users(null, {
      rootStore: this.rootStore
    });

    this.loading = true;
    this.memberSelectionMethod = 'ACTIVE';
    this.selectedMembers = observable([]);
    this.isDefault = false;
    this.sortField = 'firstName,lastName';
    this.sortDirection = 'ASC';
    this.searchQuery = '';
    this.pageSize = 10;
    this.page = 1;

    this.fetchMembersDebounced = debounce(this.fetchMembers, BASE_DEBOUNCE);
  }

  @computed get members() {
    return this.paginatedMembers;
  }

  @computed get hasMembers() {
    return this.members.hasModels;
  }

  @action.bound setup() {
    this.setupReactions();
    this.fetchMembers();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIStatus();
  }

  setupReactions() {
    this.cancelReactToParams = reaction(
      () => this.params,
      params => {
        this.fetchMembers();
      }
    );

    this.cancelReactToSearchQuery = reaction(
      () => this.searchQuery,
      params => {
        this.fetchMembersDebounced();
      }
    );
  }

  tearDownReactions() {
    this.cancelReactToParams && this.cancelReactToParams();
    this.cancelReactToSearchQuery && this.cancelReactToSearchQuery();
  }

  @action.bound
  async fetchMembers() {
    this.loading = true;

    await this.members.fetch({
      params: pickBy(
        Object.assign(this.params, {
          query: this.searchQuery
        }),
        identity
      )
    });

    this.loading = false;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @computed get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      sortField: this.sortField,
      sortDirection: this.sortDirection,
      companyUuids: this.company.uuid,
      status: 'ACTIVE',
      role:
        'ROLE_ACCOUNT_ADMIN,ROLE_ADMIN,ROLE_PROJECT_MEMBER,ROLE_USER,ROLE_WORKER'
    };
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }
  }

  @computed
  get totalPages() {
    return Math.ceil(this.members.totalElements / this.pageSize);
  }

  @action.bound setMemberSelectionMethod(event) {
    this.selectedMembers.clear();

    if (event.target.value === 'ACTIVE') {
      this.selectAllMembers();
    }

    this.memberSelectionMethod = event.target.value;
    this.page = 1;
  }

  @computed get hasSelectedMembers() {
    return this.selectedMembers.length > 0;
  }

  @action.bound
  toggleSelectMember(memberUuid) {
    if (this.selectedMembers.find(uuid => uuid === memberUuid)) {
      this.selectedMembers.remove(memberUuid);
    } else {
      this.selectedMembers.push(memberUuid);
    }
  }

  @computed
  get allMembersSelected() {
    return (
      this.hasMembers &&
      this.members.models.every(member =>
        this.selectedMembers.includes(member.uuid)
      )
    );
  }

  @action.bound selectAllMembers() {
    this.members.models.forEach(member => {
      if (!this.selectedMembers.includes(member.uuid)) {
        this.selectedMembers.push(member.uuid);
      }
    });
  }

  @action.bound
  toggleSelectAllMembers() {
    if (this.allMembersSelected) {
      this.selectedMembers.replace(
        this.selectedMembers.filter(uuid => {
          return !this.members.models.map(member => member.uuid).includes(uuid);
        })
      );
    } else {
      this.members.models.forEach(member => {
        if (!this.selectedMembers.includes(member.uuid)) {
          this.selectedMembers.push(member.uuid);
        }
      });
    }
  }

  @action.bound toggleIsDefault() {
    this.isDefault = !this.isDefault;
  }

  @action.bound async addGroupsToMembers() {
    let payload;

    if (this.memberSelectionMethod === 'SELECTED') {
      payload = this.parent.selectedGroups.map(group => {
        return {
          uuid: group.uuid,
          memberUuids: this.selectedMembers.slice(),
          memberUpdateMode: 'APPEND'
        };
      });
    } else {
      payload = this.parent.selectedGroups.map(group => {
        return {
          uuid: group.uuid,
          addToMemberStatuses: ['ACTIVE']
        };
      });
    }

    return await request.patch(`${this.parent.groups.url()}/batch`, payload);
  }

  @action.bound clearUIStatus() {
    this.clearValidationDetails();
    this.searchQuery = '';
    this.selectedMembers.clear();
    this.memberSelectionMethod = 'ACTIVE';
    this.members.clear();
    this.isDefault = false;
    this.page = 1;
    this.loading = true;
  }
}
