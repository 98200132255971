import { RakenCollection } from '../lib';
import StandardWorkLog from '../models/StandardWorkLog';
import Note from '../models/Note';
import Task from '../models/Task';
import SurveyActivity from '../models/SurveyActivity';
import AttachmentActivity from '../models/AttachmentActivity';
import TimeEntriesWorkLog from '../models/workLogs/TimeEntriesWorkLog';
import MaterialLog from '../models/MaterialLog';
import EquipmentLog from '../models/EquipmentLog';
import EquipmentLogNote from '../models/EquipmentLogNote';

export default class Activity extends RakenCollection {
  model(type) {
    switch (type) {
      case 'CrewWorklogActivity':
        return TimeEntriesWorkLog;
      case 'StandardWorklogActivity':
        return StandardWorkLog;
      case 'MaterialLogActivity':
        return MaterialLog;
      case 'EquipmentLogActivity':
        return EquipmentLog;
      case 'EquipmentLogNoteActivity':
        return EquipmentLogNote;
      case 'TaskActivity':
        return Task;
      case 'NoteActivity':
        return Note;
      case 'SurveyActivity':
        return SurveyActivity;
      case 'AttachmentActivity':
        return AttachmentActivity;
      default:
        return StandardWorkLog;
    }
  }

  url() {
    return '/ra/activities';
  }
}
