import styled from 'styled-components';
import media from '../utils/mediaTemplate';

export default styled.div`
  transform: rotate(${props => (props.diagonal ? '15deg' : '0deg')});
  margin: 0 ${props => (props.gutterMobile ? props.gutterMobile : 10)}px;
  background: ${props =>
    props.color
      ? props.theme.colors[props.color]
      : props.theme.colors.darkSteel};
  width: ${props => props.width || 1}px;
  height: ${props => props.height || 20}px;
  flex-shrink: 0;
  ${media.large`
    margin: 0 ${props => (props.gutterDesktop ? props.gutterDesktop : 10)}px;
  `};
`;
