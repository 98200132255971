import { action, computed, observable } from 'mobx';

import UIStore from 'stores/ui/UIStore';
import request from 'axios';
import { t } from 'utils/translate';
import errorHandler from 'utils/errorHandler';
import alertErrorHandler from 'utils/alertErrorHandler';
import IntegrationIds from 'fixtures/integrationIds';
import CostCodes from 'stores/collections/CostCodes';
import orderBy from 'lodash.orderby';

export default class IntegrationSageIntacctCostCodesUI extends UIStore {
  @observable loading;
  @observable showImportAlert;

  // Mappings
  @observable sortField;
  @observable sortDirection;
  @observable pageSize;
  @observable page;

  constructor(options) {
    super(options);

    this.loading = false;
    this.showImportAlert = false;

    this.selectedMappings = observable([]);

    this.sortField = 'code';
    this.sortDirection = 'asc';
    this.pageSize = 10;
    this.page = 1;

    this.sageIntacctMappings = new CostCodes(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.performanceTracking = this.rootStore.urlMicroService(
      'performanceTracking'
    );
  }

  @action.bound async setup() {
    this.loading = true;

    await Promise.all([this.fetchMappings()]);

    this.loading = false;
  }

  @action.bound tearDown() {
    this.loading = false;
    this.showImportAlert = false;
    this.clearValidationDetails();
    this.selectedMappings.clear();
    this.page = 1;
  }

  @computed get activeCompany() {
    return this.parent.activeCompany;
  }

  @computed get activeIntegration() {
    return this.parent.activeIntegration;
  }

  @action.bound
  showImportModal() {
    this.showModal('intacctCostCodesImportModal');
  }

  @computed get showTable() {
    return this.hasMappings || this.mappingsLoading;
  }

  @computed get showEmptyState() {
    return !this.hasMappings && !this.mappingsLoading;
  }

  @computed get mappingsLoading() {
    return this.sageIntacctMappings.fetching;
  }

  @computed get hasMappings() {
    return this.mappings.length > 0;
  }

  @computed get mappings() {
    // hiding deleted and not linked to any projects
    return this.sageIntacctMappings.models
      .filter(model => !model.deleted && model.projects?.length)
      .map(model => {
        return {
          id: model.id,
          externalId: model.externalId,
          code: model.code,
          division: model.division,
          description: model.description,
          slug: model.slug
        };
      });
  }

  @computed get sortedMappings() {
    if (this.sortField === 'isNew') {
      return orderBy(
        this.mappings,
        [this.sortField, 'code'],
        [this.sortDirection]
      );
    }

    return orderBy(this.mappings, [this.sortField], [this.sortDirection]);
  }

  @computed get paginatedMappings() {
    return this.sortedMappings.slice(
      (this.page - 1) * this.pageSize,
      (this.page - 1) * this.pageSize + this.pageSize
    );
  }

  @computed
  get totalPages() {
    return Math.ceil(this.mappings.length / this.pageSize);
  }

  findSelectedMapping = mappingId => {
    return this.selectedMappings.find(
      selectedMapping => selectedMapping.id === mappingId
    );
  };

  @action.bound
  toggleSelectMapping(mapping) {
    const selectedMapping = this.findSelectedMapping(mapping.id);
    if (selectedMapping) {
      this.selectedMappings.remove(selectedMapping);
    } else {
      this.selectedMappings.push(mapping);
    }
  }

  @computed
  get allMappingsSelected() {
    if (!this.hasMappings) return false;

    return (
      this.paginatedMappings.filter(mapping =>
        this.findSelectedMapping(mapping.id)
      ).length === this.paginatedMappings.length
    );
  }

  @computed get hasSelectedMappings() {
    return this.selectedMappings.length > 0;
  }

  @computed get bulkActions() {
    return [
      {
        title: t('Remove selected cost codes'),
        onClick: () => {
          this.bulkRemoveSelectedMappings();
        }
      }
    ];
  }

  @action.bound
  setPage(page) {
    this.page = page;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @action.bound
  toggleSelectAllMappings() {
    if (this.allMappingsSelected) {
      this.selectedMappings.replace(
        this.selectedMappings.filter(
          selectedMapping =>
            !this.paginatedMappings.some(o => o.id === selectedMapping.id)
        )
      );
    } else {
      this.paginatedMappings.forEach(mapping => {
        if (!this.selectedMappings.find(o => o.id === mapping.id)) {
          this.selectedMappings.push(mapping);
        }
      });
    }
  }

  @action.bound clearAllSelectedMappings() {
    this.selectedMappings.clear();
  }

  @action.bound
  async fetchMappings() {
    try {
      const url = this.isSuperAdmin
        ? `${this.performanceTracking}/admin/helper/company/${this.activeCompany.uuid}/costcodes`
        : `${this.performanceTracking}/costcodes`;

      await this.sageIntacctMappings.fetch({
        url,
        params: {
          limit: 1000,
          externalId: '*'
        }
      });

      if (this.sageIntacctMappings.nextOffset) {
        this.fetchNextMappings();
      }
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  @action.bound async fetchNextMappings() {
    await this.sageIntacctMappings.fetchNextPage();

    if (this.sageIntacctMappings.nextOffset) {
      this.fetchNextMappings();
    }
  }

  @action.bound
  async hideImportModal() {
    await this.hideActiveModal();
  }

  @action.bound
  async importCostCodes() {
    try {
      this.saving = true;
      this.clearValidationDetails();

      await this._sync(['*']);
      await this.hideActiveModal();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: 'Import started'
      });
      this.showImportAlert = true;
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  async _sync(externalIds) {
    const url = `${this.rootStore.urlMicroService('integrations')}/companies/${
      this.activeCompany.uuid
    }/integrations/${IntegrationIds.SAGE_INTACCT}/task/sync`;

    const { data } = await request.post(url, { externalIds: externalIds });
    return data;
  }

  @action.bound async removeMapping(mapping) {
    return this._remove([mapping]);
  }

  async _remove(mappings) {
    let success = true;
    try {
      this.saving = true;

      const models = mappings.map(mapping =>
        this.sageIntacctMappings.get(mapping.id)
      );
      const externalIds = models.map(model => ({
        uuid: model.id,
        externalId: ''
      }));

      await request.patch(
        `${this.performanceTracking}/companies/${this.activeCompany.uuid}/costcodes/externalIds`,
        externalIds
      );

      this.sageIntacctMappings.remove(models);

      await this.hideActiveModal();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title:
          mappings.length > 1 ? t('Cost codes removed') : t('Cost code removed')
      });

      if (this.paginatedMappings.length < 1 && this.page > 1) {
        this.page = this.page - 1;
      }
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
      success = false;
    } finally {
      this.saving = false;
    }
    return success;
  }

  @action.bound bulkRemoveSelectedMappings() {
    this.showModal('BulkRemoveSelectedMappings');
  }

  @action.bound async confirmBulkRemoveSelectedMappings() {
    const success = await this._remove(this.selectedMappings);
    if (success) this.selectedMappings.clear();
  }
}
