import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';
import trimObject from '../../utils/trimObject';

const settingsRecipientsFormPlugins = extendedPlugins;

const settingsRecipientsFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false,
  retrieveOnlyEnabledFields: false
};

const settingsRecipientsFormFields = [
  'sendBySign',
  'emailInterval',
  'superDailyIndex',
  'internalMembersRecipients[]',
  'internalMembersRecipient',
  'includeTimeCardsForExternalMembers',
  'externalMembersRecipients[]',
  'externalMembersRecipient',
  'includeMaterialQuantityForExternalMembers',
  'includeEquipmentForExternalMembers'
];

const settingsRecipientsFormRules = {
  sendBySign: 'boolean',
  emailInterval: 'string',
  superDailyIndex: 'boolean',
  internalMembersRecipients: 'array',
  internalMembersRecipient: 'email',
  includeTimeCardsForExternalMembers: 'boolean',
  externalMembersRecipients: 'array',
  externalMembersRecipient: 'email',
  includeMaterialQuantityForExternalMembers: 'boolean',
  includeEquipmentForExternalMembers: 'boolean'
};

const settingsRecipientsFormDisabled = {};

const settingsRecipientsFormLabels = {};

const settingsRecipientsFormValues = {
  sendBySign: true,
  emailInterval: '02:00',
  superDailyIndex: true,
  internalMembersRecipients: [],
  internalMembersRecipient: '',
  includeTimeCardsForExternalMembers: false,
  externalMembersRecipients: [],
  externalMembersRecipient: ''
};

class SettingsRecipientsForm extends MobxReactForm {
  trimmedValues() {
    return trimObject(this.values());
  }
}

export {
  SettingsRecipientsForm,
  settingsRecipientsFormOptions,
  settingsRecipientsFormFields,
  settingsRecipientsFormRules,
  settingsRecipientsFormValues,
  settingsRecipientsFormLabels,
  settingsRecipientsFormPlugins,
  settingsRecipientsFormDisabled
};
