import request from 'axios';
import { action, runInAction, computed } from 'mobx';
import UIStore from './UIStore';

import { t } from 'utils/translate';

import { callTrack } from 'utils/segmentIntegration';

import {
  TASKS_REPORT_DOWNLOADED,
  TASKS_REPORT_STARTED
} from 'utils/segmentAnalytics/eventSpec';

import alertErrorHandler from 'utils/alertErrorHandler';

import form, { TasksReportForm } from 'forms/tasksReport';

export default class TasksReportsUI extends UIStore {
  @action.bound async showTasksReportModal() {
    this.showModal('tasksReport');
    callTrack(TASKS_REPORT_STARTED);
    await this.initReportForm();

    if (this.project) {
      this.setProjectIncludeOption('SELECTED');
      this.selectProjects(this.projectDefaultValue);
    } else {
      this.setProjectIncludeOption('ACTIVE');
    }
  }

  @computed
  get projectDefaultValue() {
    if (this.project) {
      return [
        {
          value: this.project.uuid,
          name: this.project.name
        }
      ];
    }

    return [];
  }

  @action.bound initReportForm() {
    this.activeForm = new TasksReportForm(
      {
        fields: form.fields,
        rules: form.rules,
        values: form.values,
        labels: form.labels
      },
      {
        options: form.options,
        plugins: form.plugins
      }
    );
  }

  @action.bound
  hideTasksReportModal() {
    this.hideActiveModal().then(() => {
      runInAction(() => {
        this.activeForm = null;
        this.saving = false;
      });
    });
  }

  @action.bound
  selectProjects(selectedOptions) {
    this.activeForm.update({
      projects: selectedOptions.map(option => {
        return {
          uuid: option.value,
          name: option.name
        };
      })
    });

    this.activeForm.validate();
  }

  @computed
  get projectIncludeOptions() {
    return [
      {
        label: t('Active projects'),
        value: 'ACTIVE',
        dataQA: 'active-projects'
      },
      {
        label: t('Active and inactive projects'),
        value: 'ACTIVE_AND_INACTIVE',
        dataQA: 'active-and-inactive-projects'
      },
      {
        label: t('Selected'),
        value: 'SELECTED',
        dataQA: 'selected-projects'
      }
    ];
  }

  @action.bound
  setProjectIncludeOption(value) {
    if (value === 'ACTIVE' || value === 'ACTIVE_AND_INACTIVE') {
      this.activeForm.update({
        projects: []
      });
    }

    this.activeForm.$('projectIncludeOption').set('value', value);

    this.activeForm.validate();
  }

  @computed
  get taskStateOptions() {
    return [
      {
        title: t('Open tasks'),
        id: 'OPEN',
        dataQA: 'open-tasks'
      },
      {
        title: t('Completed tasks'),
        id: 'COMPLETED',
        dataQA: 'completed-tasks'
      }
    ];
  }

  @action.bound setTaskStates(event, values) {
    this.activeForm.update({
      taskStates: values.map(value => value.id)
    });

    this.activeForm.validate();
  }

  @computed get enableSubmit() {
    return (
      !this.activeForm.isPristine && this.activeForm.isValid && !this.saving
    );
  }

  @action.bound
  submitTasksReportForm(event) {
    event.preventDefault();

    if (this.saving) return;

    this.clearValidationDetails();

    this.activeForm.submit({
      onSuccess: this.submitTasksReportFormSuccess,
      onError: this.submitTasksReportFormError
    });
  }

  @action.bound async submitTasksReportFormSuccess() {
    this.saving = true;

    try {
      await request.post('/ra/tasks/report', this.activeForm.values());

      callTrack(TASKS_REPORT_DOWNLOADED);

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Your download request has been sent.')
      });

      this.hideTasksReportModal();
    } catch (error) {
      this.saving = false;
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound submitTasksReportFormError() {
    console.error(this.activeForm.errors());
  }
}
