import { reaction, computed, action } from 'mobx';
import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import toggleArrayFieldValue from 'forms/utils/toggleArrayFieldValue';
import { t } from 'utils/translate';

class companyInfoForm extends MobxReactForm {
  constructor(settings, options) {
    super(settings, options);

    this.reactToIndustry = reaction(
      () => this.$('industry').value,
      industry => {
        if (
          industry !== 'Self-Performing General Contractor' &&
          industry !== 'Subcontractor'
        ) {
          this.update({
            subIndustries: []
          });
        }
      }
    );
  }

  @computed get disableSubIndustry() {
    return (
      this.$('industry').value !== 'Subcontractor' &&
      this.$('industry').value !== 'Self-Performing General Contractor'
    );
  }

  @action toggleSubIndustry(value) {
    toggleArrayFieldValue(this, 'subIndustries', value);
  }
}

companyInfoForm.plugins = extendedPlugins;

companyInfoForm.options = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

companyInfoForm.fields = [
  'name',
  'phone',
  'address',
  'address.city',
  'address.state',
  'address.postCode',
  'address.streetAddress',
  'address.country',
  'address.geolocation.lat',
  'address.geolocation.lng',
  'language',
  'employeeRange',
  'industry',
  'subIndustries[]',
  'companyLogo',
  'primaryColor',
  'email',
  'ccEmails'
];

companyInfoForm.rules = {
  name: 'required|string',
  phone: 'required',
  'address.geolocation.lat': 'numeric|min:-90|max:90',
  'address.geolocation.lng': 'numeric|min:-180|max:180',
  address: {
    country: 'required|string',
    city: 'string',
    postCode: 'string|max:20',
    state: 'string'
  },
  employeeRange: 'string',
  industry: 'string',
  subIndustries: 'array',
  companyLogo: 'string',
  primaryColor: 'string',
  email: 'required|email',
  ccEmails: 'emailList'
};

companyInfoForm.labels = {
  address: {
    postCode: t('ZIP code')
  }
};

export default companyInfoForm;
