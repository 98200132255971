import moment from 'moment';
import debounce from 'lodash.debounce';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import UIStore from '../../UIStore';

import Equipment from 'stores/collections/Equipment';
import EquipmentAddUI from './EquipmentAddUI';
import EquipmentEditUI from './EquipmentEditUI';
import EquipmentActionsUI from './EquipmentActionsUI';

import EquipmentTypes from 'stores/collections/EquipmentTypes';
import EquipmentMakes from 'stores/collections/EquipmentMakes';

import history from 'utils/history';
import { BASE_DEBOUNCE } from 'fixtures/constants';
import alertErrorHandler from 'utils/alertErrorHandler';
import uniqBy from 'lodash.uniqby';
import { t } from 'utils/translate';

import {
  EquipmentFiltersForm,
  equipmentFiltersFormRules,
  equipmentFiltersFormFields,
  equipmentFiltersFormOptions,
  equipmentFiltersFormPlugins
} from 'forms/equipment/equipmentFilters';

export default class EquipmentUI extends UIStore {
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;
  @observable saving;

  @observable equipmentFiltersForm;

  constructor(options) {
    super(options);

    this.loading = true;

    this.sortField = 'name';
    this.sortDirection = 'asc';
    this.page = 1;
    this.searchQuery = '';
    this.pageSize = 20;

    // Filters
    this.equipmentFiltersForm = null;

    this.typeFilters = observable([]);
    this.makeFilters = observable([]);
    this.deployedToFilters = observable([]);
    this.conditionFilters = observable([]);

    // Equipment collection
    this.equipment = new Equipment(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.equipmentAddUI = new EquipmentAddUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.equipmentEditUI = new EquipmentEditUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.equipmentActionsUI = new EquipmentActionsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.equipmentTypes = new EquipmentTypes(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.equipmentMakes = new EquipmentMakes(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchEquipmentDebounced = debounce(this.fetchEquipment, BASE_DEBOUNCE);

    // Deploy
    this.equipmentToDeploy = null;
    this.form = null;
    this.saving = false;
  }

  @action.bound setup() {
    window.scrollTo(0, 0);
    this.setupReactions();
    this.fetchEquipment();
    this.equipmentTypes.fetch({
      params: {
        limit: 10000 // Safe number
      }
    });
    this.equipmentMakes.fetch({
      params: {
        limit: 10000 // Safe number
      }
    });
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchEquipmentDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed
  get equipmentTypeOptions() {
    const typesList = this.equipmentTypes.models.map(type => {
      return {
        value: type.name,
        title: type.name,
        category: type.category
      };
    });

    return uniqBy(typesList, 'value');
  }

  @computed
  get equipmentMakeOptions() {
    return this.equipmentMakes.models.map(make => {
      return {
        value: make.make,
        title: make.make
      };
    });
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      sortField: [this.sortField],
      sortDirection: this.sortDirection,
      types: this.typeFilters.map(typeFilter => typeFilter.value),
      makes: this.makeFilters.map(makeFilter => makeFilter.value),
      conditions: this.conditionFilters.map(
        conditionFilter => conditionFilter.value
      ),
      deployedTo: this.deployedToFilters.map(
        deployedToFilter => deployedToFilter.value
      )
    };
  }

  @action.bound async fetchEquipment() {
    this.equipment.cancelRequest();
    this.equipment.reset();

    try {
      await this.equipment.fetchWithPost({
        url: `${this.equipment.url()}/search`,
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasEquipment() {
    return this.equipment.hasModels;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.equipment.totalElements / this.pageSize);
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.equipmentActionsUI.clearUIState();

    this.searchQuery = '';
    this.equipment.clear();
    this.page = 1;
    this.loading = true;
    this.saving = false;
    this.sortField = 'name';
    this.sortDirection = 'asc';

    // Filters
    this.equipmentFiltersForm = null;
    this.clearEquipmentFilters();

    this.equipmentTypes.clear();
    this.equipmentMakes.clear();
  }

  @computed get showEmptyState() {
    return !this.loading && !this.searchQuery && !this.hasEquipment;
  }

  @computed get showEmptySearchState() {
    return !this.loading && this.searchQuery && !this.hasEquipment;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @action.bound async addEquipment() {
    await this.authorization.checkFeatureAccess('CRUDOwnedEquipment');

    history.push({
      pathname: `/company-settings/equipment/add`
    });
  }

  @action.bound async editEquipment(equipment) {
    await this.authorization.checkFeatureAccess('CRUDOwnedEquipment');
    history.push({
      pathname: `/company-settings/equipment/${equipment.uuid}/info`
    });
  }

  @action.bound sortByLastName() {
    this.sortField = 'name';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchEquipmentDebounced();
  }

  @action.bound sortByLastCreated() {
    this.sortField = 'createdTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchEquipmentDebounced();
  }

  @action.bound refetchEquipment() {
    this.loading = true;
    if (!this.hasEquipment) {
      this.setPage(null, 1);
      this.fetchEquipment();
    } else {
      this.fetchEquipment();
    }
  }

  @action.bound showEquipmentFiltersDialog() {
    this.showModal('EquipmentFiltersDialog');
    this.setupEquipmentFiltersForm();
  }

  @action.bound async hideEquipmentFiltersDialog() {
    await this.hideActiveModal();

    this.equipmentFiltersForm = null;
    this.projectSelectorUI.tearDown();
  }

  @action.bound clearEquipmentFilters() {
    this.hideEquipmentFiltersDialog();
    this.typeFilters.clear();
    this.makeFilters.clear();
    this.deployedToFilters.clear();
    this.conditionFilters.clear();
  }

  @action.bound setupEquipmentFiltersForm() {
    this.projectSelectorUI.setup({
      projectStates: ['ACTIVE']
    });

    this.equipmentFiltersForm = new EquipmentFiltersForm(
      {
        fields: equipmentFiltersFormFields,
        rules: equipmentFiltersFormRules,
        values: {
          typeFilters: this.typeFilters.slice(),
          makeFilters: this.makeFilters.slice(),
          deployedToFilters: this.deployedToFilters.slice(),
          conditionFilters: this.conditionFilters.slice()
        }
      },
      {
        options: equipmentFiltersFormOptions,
        plugins: equipmentFiltersFormPlugins
      }
    );
  }

  @action.bound submitEquipmentFiltersForm(event) {
    event.preventDefault();

    this.equipmentFiltersForm.submit({
      onSuccess: this.submitEquipmentFiltersFormSuccess,
      onError: this.submitEquipmentFiltersFormError
    });
  }

  @action.bound submitEquipmentFiltersFormSuccess() {
    const values = this.equipmentFiltersForm.values();

    this.typeFilters.replace(values.typeFilters);
    this.makeFilters.replace(values.makeFilters);
    this.deployedToFilters.replace(values.deployedToFilters);
    this.conditionFilters.replace(values.conditionFilters);

    this.hideEquipmentFiltersDialog();
  }

  @action.bound submitEquipmentFiltersFormError() {
    console.error(this.equipmentFiltersForm.errors());
  }

  @computed
  get filtersCounter() {
    let counter = 0;

    if (this.typeFilters.length) {
      counter += 1;
    }

    if (this.makeFilters.length) {
      counter += 1;
    }

    if (this.deployedToFilters.length) {
      counter += 1;
    }

    if (this.conditionFilters.length) {
      counter += 1;
    }

    return counter;
  }

  @computed get hasAppliedFilters() {
    return !!this.filtersCounter;
  }

  @computed get hasWriteAccess() {
    return this.authorization.canCRUDOwnedEquipment;
  }

  @computed get ownershipOptions() {
    return [
      { value: 'owned', title: t('Owned'), dataQA: 'owned' },
      { value: 'rented', title: t('Rented'), dataQA: 'owned' }
    ];
  }

  @computed get conditionOptions() {
    return [
      { value: 'AVAILABLE', title: t('Available') },
      { value: 'DEPLOYED', title: t('Deployed') },
      { value: 'UNDER_MAINTENANCE', title: t('Under maintenance') },
      { value: 'RETURNED_RETIRED', title: t('Returned/Retired') }
    ];
  }

  @computed get yearOptions() {
    const currentYear = moment().year();
    const startYear = 1970;
    const options = [];
    for (let year = startYear; year <= currentYear; year++) {
      options.unshift({ value: year.toString(), title: year.toString() });
    }
    return options;
  }

  @computed get odometerOptions() {
    return [
      { value: 'MILES', title: t('Miles') },
      { value: 'KILOMETERS', title: t('Kilometers') }
    ];
  }

  @computed get fuelOptions() {
    return [
      { value: 'GALLONS', title: t('Gallons') },
      { value: 'LITERS', title: t('Liters') }
    ];
  }

  @computed get frequencyOptions() {
    return [
      {
        value: 'HOURLY',
        name: t('per hour')
      },
      {
        value: 'DAILY',
        name: t('per day')
      },
      {
        value: 'WEEKLY',
        name: t('per week')
      },
      {
        value: 'MONTHLY',
        name: t('per month')
      }
    ];
  }
}
