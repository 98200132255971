import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';

import { t } from 'utils/translate';
import trimObject from 'utils/trimObject';

const certificationFormPlugins = extendedPlugins;

const certificationFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const certificationFormFields = ['name', 'certificationType'];

const certificationFormValues = {};

const certificationFormLabels = {
  name: t('name'),
  certificationType: t('type')
};

const certificationFormRules = {
  name: 'string|required',
  certificationType: 'required'
};

class CertificationForm extends MobxReactForm {
  trimmedValues() {
    return trimObject(this.values());
  }
}

export {
  CertificationForm,
  certificationFormRules,
  certificationFormFields,
  certificationFormLabels,
  certificationFormOptions,
  certificationFormPlugins,
  certificationFormValues
};
