import { RakenCollection } from '../../lib';
import Incident from 'stores/models/incidents/Incident';

export default class Incidents extends RakenCollection {
  model() {
    return Incident;
  }

  url() {
    return `${this.rootStore.urlMicroService('toolbox')}/companies/${
      this.rootStore.me.company.uuid
    }/incident-reports`;
  }
}
