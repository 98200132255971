import { Model } from 'mobx-mc';
import { observable, action, computed, runInAction } from 'mobx';
import errorHandler from 'utils/errorHandler';
import bytesToSize from 'utils/bytesToSize';
import kebabCase from 'lodash.kebabcase';

import ScheduledTalks from '../collections/ScheduledTalks';

export default class Talk extends Model {
  @observable selected;
  @observable edit;
  @observable new;
  @observable newName;

  // Company Level
  @observable expanded;
  @observable sortField;
  @observable sortName;

  constructor(data, options) {
    super(data, options);

    this.selected = false;
    this.edit = false;
    this.new = false;

    this.scheduledTalks = new ScheduledTalks(null, {
      parent: this,
      rootStore: this.rootStore,
      talkId: this.id
    });
  }

  idAttribute() {
    return 'uuid';
  }

  get restAttributes() {
    return [
      'uuid',
      'name',
      'document',
      'countUpcoming',
      'countPast',
      'lastPresentedDate',
      'status',
      'newRelease'
    ];
  }

  @computed
  get currentName() {
    return this.name;
  }

  @computed get slug() {
    return kebabCase(this.name);
  }

  @action.bound
  handleUpdateName(name) {
    this.name = name;
  }

  @action.bound
  handleToggle() {
    this.selected = !this.selected;
  }

  @action.bound
  handleEdit() {
    this.edit = true;

    if (!this.newName) {
      this.newName = this.currentName;
    }
  }

  @action.bound
  saveName(fileName) {
    this.edit = false;
    this.save({
      name: fileName ? fileName : this.newName
    })
      .then(() => {
        runInAction(() => {
          this.handleUpdateName(this.newName);
        });
      })
      .catch(error => {
        this.newName = this.name;

        errorHandler(error, this.rootStore.notificationsUI.pushError);
      });
  }

  @action.bound
  cancelNameEditing() {
    this.edit = false;
    this.newName = null;
  }

  @action.bound
  handleChangeName(e) {
    this.newName = e.target.value;
  }

  @action.bound
  handleReset() {
    this.selected = false;
    this.edit = false;
  }

  @action.bound
  handleOnKeyDown(e) {
    if (e.keyCode === 27) {
      this.edit = false;
      this.newName = null;
    }
    if (e.keyCode === 13 && this.newName !== this.currentName) {
      this.saveName();
    }
  }

  @computed
  get fileSizeFormatted() {
    return bytesToSize(this.document.fileSize);
  }

  @computed
  get hasUpcomingTalks() {
    return this.countUpcoming;
  }

  @computed
  get hasPastTalks() {
    return this.countPast;
  }

  @computed
  get scheduled() {
    return this.hasUpcomingTalks || this.hasPastTalks;
  }

  @computed get formValues() {
    return {
      name: this.name
    };
  }
}
