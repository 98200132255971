import debounce from 'lodash.debounce';
import { action, computed, observable, runInAction, reaction } from 'mobx';
import UIStore from '../UIStore';
import Transactions from 'stores/collections/billing/Transactions';
import alertErrorHandler from 'utils/alertErrorHandler';

import { BASE_DEBOUNCE } from 'fixtures/constants';
import { axiosIgnore404 } from 'utils/axiosIgnore404';

export default class TransactionsUI extends UIStore {
  @observable loading;

  @observable sortField;
  @observable sortDirection;

  constructor(options) {
    super(options);

    this.loading = true;

    this.sortField = 'createdAt';
    this.sortDirection = 'desc';

    this.transactions = new Transactions(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchTransactionsDebounced = debounce(
      this.fetchTransactions,
      BASE_DEBOUNCE
    );
  }

  @action.bound clearUIState() {
    this.transactions.clear();
    this.sortField = 'createdAt';
    this.sortDirection = 'desc';
    this.loading = true;
  }

  @computed get company() {
    return this.parent.company;
  }

  @action.bound setup() {
    this.fetchTransactions();
    this.setupReactions();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchTransactionsDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed get params() {
    return {
      limit: 10,
      sort: this.sortField,
      order: this.sortDirection
    };
  }

  @action.bound async fetchTransactions() {
    this.transactions.cancelRequest();
    this.transactions.reset();

    try {
      await this.transactions.fetch({
        params: this.params,
        axios: axiosIgnore404
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasMoreTransactions() {
    return this.transactions.bookmark;
  }

  @action.bound async fetchNextTransactions() {
    try {
      await this.transactions.fetch({
        add: true,
        remove: false,
        update: false,
        params: {
          bookmark: this.transactions.bookmark
        }
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }
  }

  @computed get hasTransactions() {
    return this.transactions.hasModels;
  }

  @computed get showEmptyState() {
    return !this.loading && !this.hasTransactions;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }
}
