import { computed, observable } from 'mobx';
import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';
import trimObject from '../../utils/trimObject';
import { t } from 'utils/translate';

const settingsDisplayFormPlugins = extendedPlugins;

const settingsDisplayFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false,
  retrieveOnlyEnabledFields: false
};

const settingsDisplayFormFields = [
  'milesPerHour',
  'inches',
  'intervals',
  'fahrenheit',
  'dateFormat',
  'weatherIntervals',
  'frequency',
  'languageId'
];

const settingsDisplayFormLabels = {
  languageId: t('Report Display language')
};

const settingsDisplayFormRules = {
  dateFormat: 'string',
  milesPerHour: 'boolean',
  inches: 'boolean',
  intervals: 'array',
  fahrenheit: 'boolean',
  weatherIntervals: 'array',
  frequency: 'required'
};

const settingsDisplayFormDisabled = {};

const settingsDisplayFormValues = {
  dateFormat: 'MM/dd/YYYY',
  milesPerHour: true,
  inches: true,
  intervals: [2, 3, 4, 5, 6],
  fahrenheit: true,
  weatherIntervals: ['06:00', '12:00', '16:00'],
  frequency: 'WEEKLY'
};

class SettingsDisplayForm extends MobxReactForm {
  @observable updatedSegmentStartTime;

  trimmedValues() {
    return trimObject(this.values());
  }

  @computed
  get DisplayHasError() {
    return (
      this.$('dateFormat').hasError ||
      this.$('weatherIntervals').hasError ||
      this.$('fahrenheit').hasError ||
      this.$('milesPerHour').hasError ||
      this.$('inches').hasError ||
      this.$('frequency').hasError
    );
  }
}

export {
  SettingsDisplayForm,
  settingsDisplayFormOptions,
  settingsDisplayFormFields,
  settingsDisplayFormRules,
  settingsDisplayFormValues,
  settingsDisplayFormLabels,
  settingsDisplayFormPlugins,
  settingsDisplayFormDisabled
};
