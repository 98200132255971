import { observable } from 'mobx';
import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';
import trimObject from '../../utils/trimObject';
import { t } from 'utils/translate';

const projectTemplateGeneralFormPlugins = extendedPlugins;

const projectTemplateGeneralFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false,
  retrieveOnlyEnabledFields: false
};

const projectTemplateGeneralFormFields = [
  'milesPerHour',
  'inches',
  'intervals',
  'fahrenheit',
  'dateFormat',
  'weatherIntervals',
  'frequency',
  'languageId'
];

const projectTemplateGeneralFormLabels = {
  languageId: t('report display language')
};

const projectTemplateGeneralFormRules = {
  dateFormat: 'string',
  milesPerHour: 'boolean',
  inches: 'boolean',
  intervals: 'array',
  fahrenheit: 'boolean',
  weatherIntervals: 'array',
  frequency: 'required'
};

const projectTemplateGeneralFormDisabled = {};

const projectTemplateGeneralFormValues = {
  dateFormat: 'MM/dd/YYYY',
  milesPerHour: true,
  inches: true,
  intervals: [2, 3, 4, 5, 6],
  fahrenheit: true,
  weatherIntervals: ['06:00', '12:00', '16:00'],
  frequency: 'WEEKLY'
};

class ProjectTemplateGeneralForm extends MobxReactForm {
  @observable updatedSegmentStartTime;

  trimmedValues() {
    return trimObject(this.values());
  }
}

export {
  ProjectTemplateGeneralForm,
  projectTemplateGeneralFormOptions,
  projectTemplateGeneralFormFields,
  projectTemplateGeneralFormRules,
  projectTemplateGeneralFormValues,
  projectTemplateGeneralFormLabels,
  projectTemplateGeneralFormPlugins,
  projectTemplateGeneralFormDisabled
};
