import { action, computed, observable, reaction } from 'mobx';
import ProjectInsightsUIModel from './ProjectInsightsUIModel';
import moment from 'moment';
import orderBy from 'lodash.orderby';
import ParticipatingProject from '../models/ParticipatingProject';

export default class ProjectInsightsToolBoxTalks extends ProjectInsightsUIModel {
  @observable query;
  @observable page;
  @observable limit;
  @observable talkFrameSelection;
  @observable participatingProject;
  @observable loadingStatus;

  constructor(attributes, options) {
    super(attributes, options);

    this.query = '';
    this.sortField = '';
    this.limit = 10000;
    this.talkFrameSelection = 'ALL_TALKS';
    this.loadingStatus = false;
    this.participatingProject = new ParticipatingProject(null, {
      rootStore: this.rootStore,
      parent: this
    });

    // Pagination
    this.pageSize = 30;
    this.page = 0;

    // React to selection changes
    this.reactToSelection = reaction(
      () => this.params,
      params => {
        if (params && params.projectTeamUuids) {
          this.fetchStats();
        }
      },
      { fireImmediately: true }
    );
  }

  url() {
    return '/ra/insights/allTbtInsightsByProjectTeam';
  }

  get restAttributes() {
    return [
      'completedTalks',
      'compliancePercent',
      'countProjects',
      'missedTalks',
      'participating'
    ];
  }

  @computed
  get params() {
    return {
      endDay: this.parent.endDay,
      startDay: this.parent.startDay,
      projectTeamUuids: this.project?.uuid
    };
  }

  @computed
  get paramsTalksDetails() {
    return {
      dateTo: this.parent.endDay,
      dateFrom: this.parent.startDay,
      limit: this.limit
    };
  }

  @computed
  get hasStats() {
    return this.hasSearchedTalks;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.searchedTalks.length / this.pageSize);
  }

  @computed get searchedTalks() {
    const query = this.query.toLowerCase();

    return this.participatingProject.talks.filter(talk => {
      const scheduleDate = moment(talk.scheduleDate).format('YYYY-MM-DD');

      if (this.talkFrameSelection !== 'ALL_TALKS') {
        if (talk.projectTalkStatus !== this.talkFrameSelection) return;
      }

      // Name
      const hasName =
        talk.talk.name && talk.talk.name.toLowerCase().indexOf(query) !== -1;

      // Date
      const hasDate =
        scheduleDate
          .toLowerCase()
          .indexOf(this.handleSearchDateFormatted(query)) !== -1;

      // Attendees
      const hasAttendees = talk.attendees.filter(item => {
        const firstName = item.worker.firstName;
        const lastName = item.worker.lastName;
        const fullName = `${firstName} ${lastName}`;

        return fullName.toLowerCase().indexOf(query) !== -1;
      }).length;

      return hasName || hasDate || hasAttendees;
    });
  }

  @computed get hasSearchedTalks() {
    return this.searchedTalks.length > 0;
  }

  @computed get paginatedTalks() {
    return orderBy(
      this.searchedTalks.slice(
        this.page * this.pageSize,
        this.page * this.pageSize + this.pageSize
      ),
      [this.sortField],
      [this.sortDirection]
    );
  }

  @action.bound
  async fetchTotal() {
    try {
      await this.fetch({
        params: this.params
      });
    } catch (e) {
      this.set(
        {
          completedTalks: 0,
          compliancePercent: 0,
          missedTalks: 0,
          participating: 0
        },
        {
          replace: true
        }
      );
    }
  }

  @action.bound
  async fetchStats() {
    this.loadingStatus = true;

    await this.fetchTotal();

    if (this.rootStore.authorizationUI.onPerformancePlan) {
      await this.fetchParticipatingProject();
    }

    this.loadingStatus = false;
  }

  @action.bound
  async fetchParticipatingProject() {
    this.participatingProject.clear();

    const participatingProject = {
      id: this.project.id,
      uuid: this.project.uuid,
      name: this.project.name,
      projectState: this.project.projectState,
      completedTalks: this.completedTalks,
      compliancePercent: this.compliancePercent,
      missedTalks: this.missedTalks,
      participating: this.participating
    };

    this.participatingProject.set(participatingProject);

    await this.participatingProject.fetchScheduledTalks();
  }

  @action.bound
  handleQuery(e) {
    this.query = e.target.value;
    this.page = 0;
    this.sortField = 'talk.name';
    this.sortDirection = 'asc';
  }

  @action.bound
  setDefaultSelectionTalks() {
    this.setTalksFrameSelection('ALL_TALKS');
  }

  @action.bound
  setTalksFrameSelection(selection) {
    this.talkFrameSelection = selection;
    this.page = 0;
    this.sortField = 'talk.name';
    this.sortDirection = 'asc';
  }

  @action.bound
  handleSortByColumn(sortField) {
    this.page = 0;

    this.sortByColumn(sortField);
  }

  @action.bound
  handleChangePage(data) {
    const { selected } = data;

    this.sortField = 'talk.name';
    this.sortDirection = 'asc';
    this.page = selected;
  }

  @action.bound
  handleSearchDateFormatted(search) {
    const date = moment(
      search,
      [
        'MM/DD/YYYY',
        'MM/DD/YY',
        'MMM D, YYYY',
        'MMMM D, YYYY',
        'MMMM Do,YYYY',
        'MMMM Do, YYYY'
      ],
      true
    );

    return date.isValid() ? date.format('YYYY-MM-DD') : search;
  }
}
