import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';

const reportActivityFiltersFormPlugins = extendedPlugins;

const reportActivityFiltersFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const reportActivityFiltersFormFields = [
  'projectFilters[]',
  'projectStatusFilters[]',
  'reportStatusFilters[]'
];

const reportActivityFiltersFormRules = {
  projectFilters: 'array',
  projectStatusFilters: 'array',
  reportStatusFilters: 'array'
};

class ReportActivityFiltersForm extends MobxReactForm {}

export {
  ReportActivityFiltersForm,
  reportActivityFiltersFormRules,
  reportActivityFiltersFormFields,
  reportActivityFiltersFormOptions,
  reportActivityFiltersFormPlugins
};
