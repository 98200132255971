import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import { t } from 'utils/translate';

const memberCertificationFormPlugins = extendedPlugins;

const memberCertificationFormOptions = {
  validateOnInit: false,
  strictUpdate: false,
  validateOnChange: true
};

const memberCertificationFormFields = [
  'certification',
  'certification.uuid',
  'certification.name',
  'certification.certificationType',
  'certification.certificationType.name',
  'expirationDate',
  'description'
];

const memberCertificationFormRules = {
  expirationDate: 'date',
  certification: 'required',
  'certification.uuid': 'required',
  'certification.certificationType': 'required',
  'certification.certificationType.name': 'required',
  description: 'string|between:0,255'
};

const memberCertificationFormLabels = {
  certification: t('certification'),
  'certification.uuid': t('certification'),
  expirationDate: t('expiration date'),
  description: t('description')
};

const memberCertificationFormValues = {};

class MemberCertificationForm extends MobxReactForm {}

export {
  MemberCertificationForm,
  memberCertificationFormValues,
  memberCertificationFormRules,
  memberCertificationFormLabels,
  memberCertificationFormFields,
  memberCertificationFormOptions,
  memberCertificationFormPlugins
};
