import styled, { css } from 'styled-components';
import TableData from './TableData';

export default styled.tbody`
  padding: 0;
  position: relative;

  ${props =>
    props.striped &&
    css`
      &:nth-child(odd) ${TableData} {
        background-color: ${props => props.theme.colors.white};
      }
      &:nth-child(even) ${TableData} {
        background-color: ${props => props.theme.colors.lightGrey4};
      }
    `};
  border-left: 4px solid
    ${props =>
      props.highlight ? props.theme.colors.lightGrey1 : 'transparent'};
`;
