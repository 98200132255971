import { action, computed, observable } from 'mobx';
import { Collection } from 'mobx-mc';
import Invoice from '../../models/billing/Invoice';

export default class Invoices extends Collection {
  @observable bookmark;

  constructor(attributes, options) {
    super(attributes, options);

    this.bookmark = null;
  }

  model() {
    return Invoice;
  }

  url() {
    return `/ra/companies/${this.company.uuid}/billing/invoices`;
  }

  @action.bound
  set(response, options) {
    super.set(response, options);
    this.bookmark = response.bookmark;
  }

  @action.bound
  clear() {
    super.clear();
    this.bookmark = null;
  }

  parse(response) {
    return response.data;
  }

  filteredModels(isAdmin) {
    if (isAdmin) {
      return this.models;
    } else {
      return this.models.filter(value => value?.origin !== 'import');
    }
  }

  @computed get company() {
    if (this.rootStore.isSuperAdmin) {
      return this.parent.company;
    }

    return this.rootStore.me.company;
  }
}
