import { action, computed } from 'mobx';
import SettingsChildUI from './SettingsChildUI';

import {
  SettingsRadiusForm,
  settingsRadiusFormOptions,
  settingsRadiusFormFields,
  settingsRadiusFormLabels,
  settingsRadiusFormRules,
  settingsRadiusFormPlugins
} from 'forms/project/settingsRadius';

import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';
import { callTrack } from 'utils/segmentIntegration';
import { PROJECT_UPDATED } from 'utils/segmentAnalytics/eventSpec';

export default class SettingsInfoUI extends SettingsChildUI {
  @action.bound setup() {
    this.setupForm();
  }

  @action.bound tearDown() {
    this.clearUIState();
    this.unblockHistory();
  }

  @computed get title() {
    return t('Project radius');
  }

  @computed get headerTitle() {
    return t('Set project radius for precise geolocation');
  }

  @computed get projectHasLocation() {
    return this.project.address?.streetAddress && this.project.hasGeoLocation;
  }

  @computed get hasWriteAccess() {
    if (!this.projectHasLocation) return false;

    return this.authorization.canEditProjectRadius;
  }

  @action.bound setupForm() {
    this.form = new SettingsRadiusForm(
      {
        fields: settingsRadiusFormFields,
        rules: settingsRadiusFormRules,
        labels: settingsRadiusFormLabels,
        values: {
          projectRadius: this.project.projectRadius
        }
      },
      {
        options: settingsRadiusFormOptions,
        plugins: settingsRadiusFormPlugins
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @action.bound async submitFormSuccess() {
    this.clearValidationDetails();
    const values = this.form.values();

    this.saving = true;

    try {
      await this.project.save(
        values,
        {
          url: `ra/projects/${this.project.uuid}`
        },
        {
          wait: true
        }
      );

      this.unblockHistory();
      // Reset the header projects
      this.headerUI.headerProjectSelectorUI.resetProjectOptions();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Project radius saved')
      });

      callTrack(PROJECT_UPDATED, {
        project_id: this.project?.id,
        project_name: this.project?.name
      });

      // Reinitialize form
      this.setupForm();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @computed get mapCenter() {
    if (this.project.address?.streetAddress && this.project.hasGeoLocation) {
      return this.project.address.geolocation;
    }

    if (this.company.address?.streetAddress && this.company.hasGeoLocation) {
      return this.company.address.geolocation;
    }

    return this.rootStore.userLocation?.geolocation;
  }
}
