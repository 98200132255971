import moment from 'moment';
import request from 'axios';
import debounce from 'lodash.debounce';
import groupBy from 'lodash.groupby';
import isEqual from 'lodash.isequal';
import { computed, action, observable, reaction, runInAction } from 'mobx';
import UIStore from './UIStore';
import ProjectMedia from '../collections/ProjectMedia';
import omit from 'lodash.omit';

import {
  BulkDownloadForm,
  bulkDownloadFormOptions,
  bulkDownloadFormFields,
  bulkDownloadFormRules,
  bulkDownloadFormPlugins
} from 'forms/bulkDownload';

import { GalleryFilterForm } from 'forms/galleryFilter';

import { t } from 'utils/translate';
import { MEDIA_TYPES } from 'stores/models/Attachment';
import history from 'utils/history';
import alertErrorHandler from 'utils/alertErrorHandler';

import { BASE_DEBOUNCE } from 'fixtures/constants';

export default class MediaGalleryUI extends UIStore {
  @observable isOpenFilterModal;
  @observable isOpenDownloadModal;
  @observable nextPageUrl;

  @observable loading;

  constructor(options) {
    super(options);

    this.loading = true;

    this.isOpenFilterModal = false;
    this.isOpenDownloadModal = false;
    this.nextPageUrl = null;

    this.filterForm = null;
    this.downloadForm = null;

    this.fetchFirstPage = debounce(this.fetchFirstPage, BASE_DEBOUNCE);
    this.fetchNextPage = debounce(this.fetchNextPage, BASE_DEBOUNCE, {
      leading: true,
      trailing: false
    });

    this.mediaTypeOptions = [
      { title: t('Photos'), value: MEDIA_TYPES.IMAGE },
      { title: t('Videos'), value: MEDIA_TYPES.VIDEO },
      { title: t('Documents'), value: MEDIA_TYPES.DOCUMENT },
      { title: t('Audio'), value: MEDIA_TYPES.AUDIO }
    ];

    this.dateRangeOptions = [
      {
        title: t('The entire duration of this project'),
        value: 'ENTIRE_DURATION'
      },
      {
        title: t('Specific time frame'),
        value: 'SPECIFIC'
      }
    ];

    this.media = new ProjectMedia(null, {
      rootStore: this.rootStore
    });

    this.setupForms();
  }

  @action.bound setup() {
    this.setupReactions();
    this.fetchFirstPage();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.closeFilterModal();
    this.closeDownloadModal();
    this.filterForm.clear();
    this.media.clear();
    this.loading = false;
  }

  @action.bound
  setupForms() {
    this.filterForm = new GalleryFilterForm(
      {
        fields: GalleryFilterForm.fields,
        rules: GalleryFilterForm.rules,
        defaults: GalleryFilterForm.values,
        values: GalleryFilterForm.values
      },
      {
        options: GalleryFilterForm.options,
        plugins: GalleryFilterForm.plugins,
        rootStore: this.rootStore
      }
    );

    this.downloadForm = new BulkDownloadForm(
      {
        fields: bulkDownloadFormFields,
        rules: bulkDownloadFormRules
      },
      {
        options: bulkDownloadFormOptions,
        plugins: bulkDownloadFormPlugins
      }
    );
  }

  @action.bound
  setupReactions() {
    this.cancelQueryChangeReaction = reaction(
      () => this.filterForm.$('query').value,
      val => {
        this.loading = true;
        this.fetchFirstPage();
      }
    );

    /**
     * Because reactivity for require_if for these fields does not work in tandem with other fields.
     * So need to manually fire the validate function.
     * @hack
     */
    this.cancelDownloadFormReaction = reaction(
      () => [
        this.downloadForm.$('downloadType').value,
        this.downloadForm.$('dateRange').value
      ],
      val => {
        this.downloadForm.validate();
      }
    );
  }

  @action.bound tearDownReactions() {
    this.cancelQueryChangeReaction && this.cancelQueryChangeReaction();
    this.cancelDownloadFormReaction && this.cancelDownloadFormReaction();
  }

  /**
   * @returns {Array} Array of objects containing the options
   */
  @computed
  get companyTypeOptions() {
    const allOption = { value: 'ALL_COMPANIES', title: t('All companies') };
    const thisOption = { value: 'THIS_COMPANY', title: t('My company') };
    const specificOption = {
      value: 'SPECIFIC',
      title: t('Specific collaborators')
    };

    // GC with collaborator projects
    if (this.project?.hasSuperDaily) {
      return [allOption, thisOption, specificOption];
    }

    // Collaborator projects & GC without collaborator projects
    return [thisOption];
  }

  @computed
  get collaboratorOptions() {
    return this.project.childProjects.models.map(projectTeam => {
      return {
        title: projectTeam.name,
        value: projectTeam.id
      };
    });
  }

  /**
   * @returns {Object}
   */
  @computed
  get mediaSummary() {
    return Object.fromEntries(this.media.summary.toJS());
  }

  @action.bound
  async fetchFirstPage() {
    this.media.clear();

    try {
      const response = await this.media.fetch({
        url: `/ra/projects/${this.project.uuid}/attachments/search`,
        params: {
          mn: 'full',
          mc: 'full',
          offset: 0,
          limit: 50,
          includeSummary: true,
          ...this.filterFormParams
        }
      });

      this.checkNextPage(response);
    } catch (error) {
      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'error',
        icon: 'warning',
        title: t(error)
      });
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  fetchNextPage() {
    if (this.media.fetching) return;

    if (!this.nextPageUrl) return;

    return this.media
      .fetch({
        url: `${this.nextPageUrl}`,
        add: true,
        remove: false,
        update: true,
        params: {
          mn: 'full',
          mc: 'full',
          ...this.filterFormParams
        }
      })
      .then(response => {
        runInAction(() => {
          this.checkNextPage(response);
        });
      })
      .catch(error => {
        this.rootStore.notificationsUI.pushNotification({
          snackbar: 'error',
          icon: 'warning',
          title: t(error)
        });
      });
  }

  @action.bound
  // Pass the next page value if present, otherwise reset to null
  checkNextPage(response) {
    const newNextPage = response?.data?.page?.next;

    this.nextPageUrl =
      newNextPage && this.nextPageUrl !== newNextPage
        ? response.data.page.next
        : null;
  }

  @computed
  get mediaGroupedByDateAndMonth() {
    // Need to check if the model is empty
    if (!this.media.length) return [];

    return [
      groupBy(this.media.models, model =>
        moment(model.takenOrUploadedDate).format('MMM YYYY')
      )
    ];
  }

  /**
   * FILTER MODAL
   */
  @action.bound
  openFilterModal() {
    this.isOpenFilterModal = true;
  }

  @action.bound
  closeFilterModal() {
    this.isOpenFilterModal = false;
  }

  @action.bound
  applyFilter() {
    this.loading = true;

    this.fetchFirstPage().finally(() => {
      runInAction(() => {
        this.closeFilterModal();
      });
    });
  }

  @action.bound
  clearFilter() {
    this.filterForm.clear();
    this.closeFilterModal();
    this.fetchFirstPage();
  }

  @computed
  get isCheckedAllFileTypes() {
    return isEqual(
      this.filterForm?.$('mediaTypes').value,
      this.mediaTypeOptions.map(opt => opt.value)
    );
  }

  @action.bound
  toggleCheckAllFileTypes(e) {
    if (this.isCheckedAllFileTypes) {
      this.filterForm.update({
        mediaTypes: []
      });
    } else {
      this.filterForm.update({
        mediaTypes: this.mediaTypeOptions.map(opt => opt.value)
      });
    }
  }

  /**
   * To be used in gallery search endpoint
   * @returns {Object} e.g. { query: 'Raken', mediaTypes: ['AUDIO', 'VIDEO'] }
   */
  @computed
  get filterFormParams() {
    const params = {};
    const keys = GalleryFilterForm.fields;

    keys.forEach(key => {
      let formValue = this.filterForm.$(key).value;

      if (key === 'mediaTypes') {
        formValue = formValue && formValue.join(',');
      }

      if (Boolean(formValue)) {
        params[key] = formValue;
      }
    });

    return params;
  }

  @computed
  get filtersCounter() {
    return Object.keys(omit(this.filterFormParams), 'query').length;
  }

  /**
   * BULK DOWNLOAD MODAL
   */
  @action.bound
  async openDownloadModal() {
    await this.authorization.checkFeatureAccess('DownloadMediaGallery');

    // Set the defaults. Some values depends on async request so we calling this manually here.
    this.downloadForm.set({
      downloadType: this.companyTypeOptions[0]?.value,
      dateRange: this.dateRangeOptions[0]?.value
    });

    this.isOpenDownloadModal = true;
  }

  @action.bound
  closeDownloadModal() {
    this.downloadForm.clear();
    this.isOpenDownloadModal = false;
  }

  @action.bound
  handleDownloadButton() {
    this.downloadForm.submit({
      onSuccess: this.submitDownloadFormSuccess,
      onError: this.submitDownloadFormError
    });
  }

  @action.bound
  async submitDownloadFormSuccess() {
    this.saving = true;

    try {
      await request.post(
        `/ra/projects/${this.project.teamId}/media/download`,
        this.bulkDownloadParams
      );

      this.closeDownloadModal();
      this.showBulkDownloadSuccessToast();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound submitDownloadFormError() {
    console.error(this.downloadForm.errors());
  }

  @computed
  get bulkDownloadParams() {
    // Property names should match API parameter names
    let teamIds, fromDate, toDate, emails;

    const downloadType = this.downloadForm.$('downloadType').value;
    const collaborators = this.downloadForm.$('collaborators').value;
    const dateRange = this.downloadForm.$('dateRange').value;
    const recipients = this.downloadForm.$('recipients').value;

    // Attach values to properties
    if (downloadType === 'THIS_COMPANY') {
      teamIds = [this.project.internalProjectTeam.id];
    } else if (downloadType === 'SPECIFIC') {
      teamIds = collaborators;
    }

    if (dateRange === 'SPECIFIC') {
      fromDate = this.downloadForm.$('fromDate').value;
      toDate = this.downloadForm.$('toDate').value;
    }

    if (recipients.length > 0) {
      emails = recipients;
    }

    // Remove undefined properties
    return {
      ...(teamIds?.length > 0 && { teamIds }),
      ...(fromDate && { fromDate }),
      ...(toDate && { toDate }),
      ...(emails?.length > 0 && { emails })
    };
  }

  @action.bound
  showBulkDownloadSuccessToast() {
    this.rootStore.notificationsUI.pushNotification({
      snackbar: 'warning',
      icon: 'info',
      title: `${t('Your download request has been sent.')} ${t(
        'You will receive an email with a link to download your requested completed files later today.'
      )}`
    });
  }

  @action.bound cancelGallery() {
    history.push({
      pathname: `${this.project.viewUrl}/worklogs`,
      search: this.parent.baseQueryParams
    });
  }

  @computed get hasMedia() {
    return this.media.hasModels;
  }

  @computed get showEmptyState() {
    return (
      !this.loading &&
      !this.filtersCounter &&
      !this.filterForm.$('query').value &&
      !this.hasMedia
    );
  }

  @computed get showEmptySearchState() {
    return (
      !this.loading &&
      (this.filtersCounter || this.filterForm.$('query').value) &&
      !this.hasMedia
    );
  }

  @computed get showGrid() {
    return !this.showEmptyState && !this.showEmptySearchState;
  }

  @action.bound
  hasQueryMatch(media) {
    const query = this.filterForm.$('query').value?.toLowerCase();

    if (!query) {
      return null;
    }

    if (media) {
      const { fileName, desc } = media;
      const lowerCaseFileName = fileName?.toLowerCase();
      const lowerCaseDesc = desc?.toLowerCase();

      if (lowerCaseFileName?.includes(query)) {
        return 'Filename';
      }
      if (lowerCaseDesc?.includes(query)) {
        return 'Description';
      }
    }

    return null;
  }
}
