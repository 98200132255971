import { action, computed, observable } from 'mobx';

import {
  ProjectTemplateWatermarkForm,
  projectTemplateWatermarkFormOptions,
  projectTemplateWatermarkFormFields,
  projectTemplateWatermarkFormRules,
  projectTemplateWatermarkFormLabels,
  projectTemplateWatermarkFormPlugins
} from 'forms/projectTemplates/projectTemplateWatermark';

import { t } from 'utils/translate';

import ProjectTemplateChildEditUI from './ProjectTemplateChildEditUI';

import { WATERMARKS_TEMPLATE } from 'utils/segmentAnalytics/eventSpec';

import { callTrack } from 'utils/segmentIntegration';

export default class ProjectTemplateWatermarkUI extends ProjectTemplateChildEditUI {
  @observable form;
  constructor(options) {
    super(options);

    this.form = null;

    this.saveTitle = t('Project template watermark saved');
  }

  @computed get selectedTemplate() {
    return this.parent.selectedTemplate;
  }

  @action.bound setup() {
    this.setupForm();
  }

  @action.bound tearDown() {
    this.form = null;
    this.unblockHistory();
  }

  @action.bound
  setupForm() {
    this.form = new ProjectTemplateWatermarkForm(
      {
        fields: projectTemplateWatermarkFormFields,
        rules: projectTemplateWatermarkFormRules,
        labels: projectTemplateWatermarkFormLabels,
        values: this.selectedTemplate.watermarkValues
      },
      {
        options: projectTemplateWatermarkFormOptions,
        plugins: projectTemplateWatermarkFormPlugins
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @action.bound
  toggleWaterMark(value) {
    if (value) {
      callTrack(WATERMARKS_TEMPLATE);
    }
    this.form.$('enableWatermark').set(value);
  }

  @computed get enableWatermark() {
    return !this.form.$('enableWatermark').value;
  }

  @computed get watermarkAlignments() {
    return [
      {
        id: 'TOP_LEFT',
        name: t('Top Left')
      },
      {
        id: 'TOP_RIGHT',
        name: t('Top Right')
      },
      {
        id: 'BOTTOM_LEFT',
        name: t('Bottom Left')
      },
      {
        id: 'BOTTOM_RIGHT',
        name: t('Bottom Right')
      }
    ];
  }

  @computed get selectedWatermarkAlignment() {
    return this.watermarkAlignments.find(
      option => option.id === this.form.$('watermarkAlignment').value
    );
  }
}
