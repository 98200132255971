import debounce from 'lodash.debounce';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import ProjectChildUI from './ProjectChildUI';
import moment from 'moment';

import Observations from '../../collections/Observations';
import ObservationTypes from '../../collections/ObservationTypes';
import ObservationStatuses from '../../collections/ObservationStatuses';
import ObservationFields from '../../collections/ObservationFields';

import Task from '../../models/Task';

import ObservationViewUI from './ObservationViewUI';
import ObservationAddUI from './ObservationAddUI';
import ObservationEditUI from './ObservationEditUI';

import history from 'utils/history';
import { BASE_DEBOUNCE } from 'fixtures/constants';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';
import uniqBy from 'lodash.uniqby';

import request from 'axios';
import errorHandler from 'utils/errorHandler';

import {
  ObservationFiltersForm,
  observationFiltersFormOptions,
  observationFiltersFormFields,
  observationFiltersFormLabels,
  observationFiltersFormPlugins,
  observationFilterFormValues
} from 'forms/observationFilters';

import { callTrack } from 'utils/segmentIntegration';
import { OBSERVATION_STATUS_UPDATE } from 'utils/segmentAnalytics/eventSpec';

import MemberSelectorUI from './../MemberSelectorUI';

export default class ObservationsUI extends ProjectChildUI {
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;
  @observable loadingEdit;
  @observable selectedObservation;
  @observable signed;
  @observable signature;
  @observable observationToUpdateStatus;
  @observable notifyAssignees;
  @observable notifyTeamMembers;
  @observable appliedFiltersCount;
  @observable filterForm;
  @observable filterValues;

  constructor(options) {
    super(options);

    this.loading = true;
    this.loadingEdit = false;

    this.selectedObservation = null;
    this.appliedFiltersCount = null;
    this.filterForm = null;
    this.filterValues = {};

    this.sortField = '';
    this.sortDirection = 'desc';
    this.page = 1;
    this.searchQuery = '';
    this.pageSize = 20;

    // Signatures for completed observations
    this.signed = false;
    this.signature = null;
    this.observationToUpdateStatus = null;
    this.observationStatusNewValue = null;
    this.notifyAssignees = false;
    this.notifyTeamMembers = false;

    // Observations collections
    this.observations = new Observations(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.observationTypes = new ObservationTypes(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.observationStatuses = new ObservationStatuses(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.observationFields = new ObservationFields(null, {
      parent: this,
      rootStore: this.rootStore
    });

    // Child UI Stores
    this.observationAddUI = new ObservationAddUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.observationEditUI = new ObservationEditUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.observationViewUI = new ObservationViewUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchObservationsDebounced = debounce(
      this.fetchObservations,
      BASE_DEBOUNCE
    );

    this.notificationMemberSelectorUI = new MemberSelectorUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.assigneeMemberSelectorUI = new MemberSelectorUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound setup() {
    window.scrollTo(0, 0);
    this.setupReactions();
    this.fetchObservations();

    this.notificationMemberSelectorUI.setup({
      projectUuids: [this.project.uuid],
      sortField: 'firstName, lastName',
      role: ['ROLE_ACCOUNT_ADMIN', 'ROLE_ADMIN', 'ROLE_PROJECT_MEMBER']
    });

    this.assigneeMemberSelectorUI.setup({
      projectUuids: this.memberProjectUuids.slice(),
      sortField: 'company.name, firstName, lastName',
      elevateFn: element =>
        element.company.uuid === this.rootStore.me.company.uuid,
      role: ['ROLE_ACCOUNT_ADMIN', 'ROLE_ADMIN', 'ROLE_PROJECT_MEMBER']
    });
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();

    this.notificationMemberSelectorUI.tearDown();
    this.assigneeMemberSelectorUI.tearDown();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchObservationsDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed
  get params() {
    return {
      entityStatuses: ['ACTIVE', 'DRAFT'],
      projects: [this.projectUuid],
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      sortDirection: this.sortDirection,
      sortField: this.sortField,
      category: this.filterValues.category,
      types: this.filterValues.types
        ? this.filterValues.types.map(type => type.value)
        : [],
      assignees: this.filterValues.assignees
        ? this.filterValues.assignees.map(assignee => assignee.uuid)
        : [],
      createdBy: this.filterValues.createdBy
        ? this.filterValues.createdBy.map(createdBy => createdBy.uuid)
        : [],
      priorities: this.filterValues.priorities
        ? this.filterValues.priorities.map(priority => priority.value)
        : [],
      statuses: this.filterValues.statuses
        ? this.filterValues.statuses.map(status => status.value)
        : [],
      locations: this.filterValues.locations
        ? this.filterValues.locations.map(status => status.uuid)
        : [],
      startDueDate: this.filterValues.startDueDate,
      endDueDate: this.filterValues.endDueDate
    };
  }

  @action.bound fetchByPostOrGet() {
    if (this.hasFilters || this.searchQuery || this.sortField) {
      return request
        .post(
          `${this.rootStore.urlMicroService('hydra')}/companies/${
            this.company.uuid
          }/observations/search`,
          this.params
        )
        .then(
          response => {
            runInAction(() => {
              this.observations.set(response.data);
            });
          },
          error => {
            errorHandler(error, this.notifications.pushError);
          }
        );
    }

    return request
      .get(
        `${this.rootStore.urlMicroService('hydra')}/companies/${
          this.company.uuid
        }/observations`,
        {
          params: {
            projectUuids: this.project.uuid,
            sortDirection: this.sortDirection,
            sortField: this.sortField,
            limit: this.pageSize,
            offset: (this.page - 1) * this.pageSize
          }
        }
      )
      .then(
        response => {
          runInAction(() => {
            this.observations.set(response.data);
          });
        },
        error => {
          errorHandler(error, this.notifications.pushError);
        }
      );
  }

  @action.bound async fetchObservations() {
    if (!this.projectUuid) return;

    this.observations.clear();

    try {
      await Promise.all([
        this.fetchByPostOrGet(),
        this.observationStatuses.fetch()
      ]);
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasObservations() {
    return this.observations.hasModels;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.observations.totalElements / this.pageSize);
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.searchQuery = '';
    this.observations.clear();
    this.memberSelectorUI.tearDown();
    this.page = 1;
    this.loading = true;
    this.saving = false;
    this.sortField = '';
    this.sortDirection = 'desc';
    this.selectedObservation = null;
    this.filterForm = null;

    this.observationToUpdateStatus = null;
    this.observationStatusNewValue = null;
  }

  @computed get showEmptyState() {
    return (
      !this.loading &&
      !this.searchQuery &&
      !this.hasObservations &&
      !this.hasFilters
    );
  }

  @computed get showEmptySearchState() {
    return (
      !this.loading &&
      (this.searchQuery || this.hasFilters) &&
      !this.hasObservations
    );
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @action.bound async addObservation() {
    await this.authorization.checkFeatureAccess('CreateObservations');

    history.push({
      pathname: `${this.project.viewUrl}/observations/add`,
      search: this.baseQueryParams
    });
  }

  @action.bound async viewObservation(observation) {
    this.observationViewUI.showPreviousNext = true;
    history.push({
      pathname: `${this.project.viewUrl}/observations/${observation.uuid}/view`,
      search: this.baseQueryParams
    });
  }

  @action.bound async editObservation(observation) {
    history.push({
      pathname: `${this.project.viewUrl}/observations/${observation.uuid}`,
      search: this.baseQueryParams
    });
  }

  @action.bound async deleteObservation(observation) {
    if (!observation.canEditDeleteObservation) return;

    this.selectedObservation = observation;
    this.showModal('DeleteModal');
  }

  @action.bound async cancelDeleteObservation() {
    await this.hideActiveModal();

    this.selectedObservation = null;
  }

  @action.bound async confirmDeleteObservation() {
    this.saving = true;

    try {
      await this.selectedObservation.destroy({ wait: true });

      await this.hideActiveModal();

      this.selectedObservation = null;

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Observation deleted')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound sortByLastCreated() {
    this.sortField = '';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.clearSearchQuery();
    this.filterValues = {};
    this.tearDownFiltering();
    this.fetchObservationsDebounced();
    this.refetchReportSummary();
  }

  @action.bound refetchObservations() {
    this.loading = true;
    if (!this.hasObservations) {
      this.setPage(null, 1);
      this.fetchObservations();
    } else {
      this.fetchObservations();
    }

    this.refetchReportSummary();
  }

  @computed get hasWriteAccess() {
    return this.rootStore.me.role !== 'ROLE_USER';
  }

  getObservationActions = observation => {
    if (!observation?.canEditDeleteObservation) {
      return [
        {
          title: t('View'),
          onClick: () => {
            this.viewObservation(observation);
          }
        }
      ];
    }

    return [
      {
        title: t('View'),
        onClick: () => {
          this.viewObservation(observation);
        }
      },

      {
        title: t('Edit'),
        onClick: () => {
          this.editObservation(observation);
        }
      },
      {
        title: t('Delete'),
        onClick: () => {
          this.deleteObservation(observation);
        }
      }
    ];
  };

  @computed
  get categoryOptions() {
    return [
      { value: 'POSITIVE', title: t('Positive') },
      { value: 'NEGATIVE', title: t('Negative') }
    ];
  }

  @computed get observationTypeOptions() {
    const typesList = this.observationTypes.models.map(type => {
      return {
        value: type.type,
        title: type.type,
        class: type.typeClass
      };
    });

    return uniqBy(typesList, 'value');
  }

  @computed get observationStatusOptions() {
    return this.observationStatuses.models.map(status => {
      return {
        value: status.uuid,
        title: status.name,
        signatureRequired: status.signatureRequired
      };
    });
  }

  @computed get priorityOptions() {
    return [
      { value: 'LOW', title: t('Low') },
      { value: 'MEDIUM', title: t('Medium') },
      { value: 'HIGH', title: t('High') },
      { value: 'CRITICAL', title: t('Critical') }
    ];
  }

  @computed get locationOptions() {
    return this.projectLocationSelectorUI.options;
  }

  @action.bound
  getTaskStatus(status) {
    const taskStatus = this.observationStatuses.models.find(
      observationStatus => {
        return status === observationStatus.uuid;
      }
    );

    return taskStatus?.closedState ? 'COMPLETED' : 'OPEN';
  }

  @action.bound
  async createOrEditTasksForAssignees(assignees, status) {
    if (!assignees) return [];

    const tasks = assignees.map(task => {
      return new Task(
        {
          taskType: 'OBSERVATIONS',
          assignee: { uuid: task.assignee.uuid },
          ...(task.dueDate && {
            dueDate: moment(task.dueDate).format('YYYY-MM-DD')
          }),
          desc: task.action,
          projectUuid: this.project.uuid,
          sendNotification: task.sendNotification,
          status: this.getTaskStatus(status),
          ...(task.uuid && { uuid: task.uuid })
        },
        {
          rootStore: this.rootStore
        }
      );
    });

    const savedTasks = await Promise.all(
      tasks.map(task =>
        task.save({
          assignee: { uuid: task.assignee.uuid },
          desc: task.desc,
          status: this.getTaskStatus(status),
          ...(task.dueDate && { dueDate: task.dueDate }),
          ...(task.isNew && { type: task.type }),
          ...(task.isNew && { projectUuid: task.projectUuid }),
          ...(task.isNew && { uuid: task.uuid })
        })
      )
    );

    return savedTasks.map(task => {
      return { taskUuid: task.uuid, sendNotification: !!task.sendNotification };
    });
  }

  @computed get hasAppliedFilters() {
    return this.appliedFiltersCount > 0;
  }

  @action.bound showFilters() {
    this.showModal('filters');
    this.setupFiltering();
  }

  @action.bound async hideFilters() {
    await this.hideActiveModal();
    this.tearDownFiltering();
  }

  @computed get activeProjectUuids() {
    return this.project.allProjectTeams
      .filter(projectTeam => projectTeam.projectState === 'ACTIVE')
      .map(projectTeam => {
        return projectTeam.uuid;
      });
  }

  @computed get memberProjectUuids() {
    // For collaborator, fetch the current collaborator and the GCs members
    if (this.project.isChildProject) {
      return [this.project.uuid, this.project.parentProject.uuid];
    }

    // For GC, fetch the GC members and all the active collaborator team members
    return this.activeProjectUuids;
  }

  @action.bound
  async updateStatus() {
    this.saving = true;

    const url =
      this.rootStore.urlMicroService('toolbox') +
      `/companies/${this.rootStore.me.company.uuid}/observations/${this.observationToUpdateStatus.uuid}?notifyAssignees=${this.notifyAssignees}&notifyTeamMembers=${this.notifyTeamMembers}`;

    try {
      await this.observationToUpdateStatus.save(
        {
          status: {
            uuid: this.observationStatusNewValue.value,
            name: this.observationStatusNewValue.title
          },
          ...(this.signature && { signature: this.signature })
        },
        { url: url, wait: true, parse: false }
      );

      callTrack(OBSERVATION_STATUS_UPDATE, {
        observation_status: this.observationStatusNewValue
      });

      await this.closeObservationSignatureModal();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound async openObservationSignatureModal(newValue, observation) {
    this.observationToUpdateStatus = observation;

    this.observationStatusNewValue = newValue;
    this.observationStatusOldValue = this.observationToUpdateStatus.status;

    this.activeModal = 'observationSign';

    this.observationToUpdateStatus.status = {
      uuid: this.observationStatusNewValue.value,
      name: this.observationStatusNewValue.title
    };
  }

  @action.bound async cancelObservationSignatureModal() {
    this.observationToUpdateStatus.status = this.observationStatusOldValue;

    await this.closeObservationSignatureModal();
  }

  @action.bound async closeObservationSignatureModal() {
    this.observationStatusNewValue = null;
    this.observationStatusOldValue = null;
    await this.hideActiveModal();
  }

  @action.bound signPad(signature) {
    this.signed = true;
    this.signature = signature;
  }

  @action.bound unsignPad() {
    this.signed = false;
    this.signature = null;
  }

  @action.bound toggleNotifyAssignees() {
    this.notifyAssignees = !this.notifyAssignees;
  }

  @action.bound toggleNotifyTeamMembers() {
    this.notifyTeamMembers = !this.notifyTeamMembers;
  }

  @action.bound async setupFiltering() {
    await Promise.all([
      this.observationTypes.fetch(),
      this.memberSelectorUI.setup({
        projectUuids: this.memberProjectUuids.slice(),
        role: ['ROLE_ACCOUNT_ADMIN', 'ROLE_ADMIN', 'ROLE_PROJECT_MEMBER']
      }),
      this.projectLocationSelectorUI.setup(this.project.uuid)
    ]);

    if (this.filterForm) return;

    this.filterForm = new ObservationFiltersForm(
      {
        fields: observationFiltersFormFields,
        labels: observationFiltersFormLabels,
        values: observationFilterFormValues
      },
      {
        options: observationFiltersFormOptions,
        plugins: observationFiltersFormPlugins
      }
    );
  }

  @action.bound tearDownFiltering() {
    this.memberSelectorUI.tearDown();
    this.projectLocationSelectorUI.tearDown();
  }

  @action setType(positive, negative) {
    if (positive && negative) {
      return 'BOTH';
    }

    if (positive) {
      return 'POSITIVE';
    }

    if (negative) {
      return 'NEGATIVE';
    }

    return '';
  }

  @action.bound applyFilters(event) {
    event.preventDefault();

    const { positive, negative, ...values } = this.filterForm.values();

    this.filterValues = {
      ...values,
      category: this.setType(
        this.filterForm.values().positive,
        this.filterForm.values().negative
      ),
      startDueDate: values.startDueDate
        ? moment(values.startDueDate).format('YYYY-MM-DD')
        : '',
      endDueDate: values.endDueDate
        ? moment(values.endDueDate).format('YYYY-MM-DD')
        : ''
    };

    this.hideActiveModal();
  }

  @action.bound clearFilters(event) {
    this.filterForm.update(observationFilterFormValues);
    this.applyFilters(event);
  }

  @computed
  get hasFilters() {
    return this.filtersCounter > 0;
  }

  @computed
  get filtersCounter() {
    let counter = 0;

    if (
      this.filterValues.category === 'POSITIVE' ||
      this.filterValues.category === 'NEGATIVE'
    ) {
      counter++;
    }
    if (this.filterValues.types?.length > 0) {
      counter++;
    }
    if (this.filterValues.assignees?.length > 0) {
      counter++;
    }
    if (this.filterValues.createdBy?.length > 0) {
      counter++;
    }
    if (this.filterValues.priorities?.length > 0) {
      counter++;
    }
    if (this.filterValues.statuses?.length > 0) {
      counter++;
    }
    if (this.filterValues.locations?.length > 0) {
      counter++;
    }
    if (this.filterValues.startDueDate || this.filterValues.endDueDate) {
      counter++;
    }

    return counter;
  }
}
