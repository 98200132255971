import styled from 'styled-components';

export default styled.section`
  border: 1px solid ${props => props.theme.colors.lightGrey3};
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin: ${props => props.margin || '0 0 25px 0'};
  min-height: ${props => props.minHeight}px;
  padding: 2rem 25px;
  position: relative;
`;
