import { action, observable, computed } from 'mobx';
import ChecklistEditUI from 'stores/ui/project/checklists/ChecklistEditUI';

import Checklist from 'stores/models/checklists/Checklist';

export default class TimeClockQuestionsViewChecklistUI extends ChecklistEditUI {
  @observable checkListUuid;

  constructor(options) {
    super(options);

    this.checklistUuid = null;
  }

  @action.bound async fetchEntry(uuid) {
    const model = new Checklist(
      { uuid: uuid },
      {
        rootStore: this.rootStore
      }
    );

    try {
      await model.fetch();

      this.setEntryForEdit(model);
    } catch (error) {
      this.cancelChecklistEdit({
        redirect: true
      });
    }
  }

  @computed get viewMode() {
    return true;
  }
}
