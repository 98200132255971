import request from 'axios';
import { action, observable } from 'mobx';
import crossOriginDownload from 'utils/crossOriginDownload';
import ProjectChildUI from '../ProjectChildUI';

import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

import {
  RenameFormForm,
  renameFormFormOptions,
  renameFormFormFields,
  renameFormFormRules,
  renameFormFormLabels,
  renameFormFormPlugins
} from 'forms/project/renameForm';

import {
  ShareFormForm,
  shareFormFormOptions,
  shareFormFormFields,
  shareFormFormRules,
  shareFormFormLabels,
  shareFormFormValues,
  shareFormFormPlugins
} from 'forms/project/shareForm';

import {
  FORM_SHARED,
  FORM_DUPLICATED,
  FORM_DOWNLOADED,
  FORM_RENAMED,
  FORM_DELETED
} from 'utils/segmentAnalytics/eventSpec';
import { callTrack } from 'utils/segmentIntegration';

export default class FormsUI extends ProjectChildUI {
  @observable selectedForm;
  @observable renameFormForm;
  @observable duplicateFormForm;
  @observable shareFormForm;

  constructor(options) {
    super(options);

    this.loading = true;

    this.selectedForm = null;
    this.renameFormForm = null;
    this.duplicateFormForm = null;
    this.shareFormForm = null;
  }

  @action.bound clearUIState() {
    this.selectedForm = null;
    this.renameFormForm = null;
    this.duplicateFormForm = null;
    this.shareFormForm = null;
  }

  @action.bound async deleteForm(form) {
    await this.authorization.checkFeatureAccess('CUDForms');

    this.selectedForm = form;
    this.showModal('DeleteModal');
  }

  @action.bound async cancelDeleteForm() {
    await this.hideActiveModal();

    this.selectedForm = null;
  }

  @action.bound async confirmDeleteForm() {
    this.saving = true;

    try {
      await this.selectedForm.destroy({ wait: true });

      this.parent.refetchForms();

      callTrack(FORM_DELETED, {
        project_name: this.selectedForm.projectName,
        form_name: this.selectedForm.name
      });

      await this.hideActiveModal();

      this.selectedForm = null;

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Form deleted')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound renameForm(form) {
    this.selectedForm = form;

    this.renameFormForm = new RenameFormForm(
      {
        fields: renameFormFormFields,
        rules: renameFormFormRules,
        values: {
          name: this.selectedForm.name.replace(/\.[^/.]+$/, ''),
          initialName: this.selectedForm.name
        },
        labels: renameFormFormLabels
      },
      {
        options: renameFormFormOptions,
        plugins: renameFormFormPlugins,
        rootStore: this.rootStore
      }
    );

    this.showModal('RenameFormModal');
  }

  @action.bound async cancelRenameForm() {
    await this.hideActiveModal();

    this.renameFormForm = null;
    this.selectedForm = null;
  }

  @action.bound submitRenameFormForm(event) {
    event.preventDefault();

    this.renameFormForm.submit({
      onSuccess: this.submitRenameFormFormSuccess,
      onError: this.submitRenameFormFormError
    });
  }

  @action.bound async submitRenameFormFormSuccess() {
    this.saving = true;

    const values = this.renameFormForm.values();
    const name = `${values.name}.${this.selectedForm.name.split('.').pop()}`;

    try {
      await this.selectedForm.save(
        {
          name: name
        },
        {
          wait: true
        }
      );

      callTrack(FORM_RENAMED, {
        project_name: this.selectedForm.projectName,
        original_form_name: this.selectedForm.name,
        new_form_name: name
      });

      this.selectedForm.fromTemplate = false;

      this.cancelRenameForm();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Form renamed')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitRenameFormFormError() {
    console.error(this.renameFormForm.errors());
  }

  @action.bound downloadForm(form) {
    crossOriginDownload(
      `${form.document.contentUrl}?version=${form.lastUpdated}`,
      form.name,
      false
    );

    callTrack(FORM_DOWNLOADED, {
      project_name: form.projectName,
      form_name: form.name
    });
  }

  @action.bound duplicateForm(form) {
    this.selectedForm = form;

    this.duplicateFormForm = new RenameFormForm(
      {
        fields: renameFormFormFields,
        rules: renameFormFormRules,
        values: {},
        labels: renameFormFormLabels
      },
      {
        options: renameFormFormOptions,
        plugins: renameFormFormPlugins,
        rootStore: this.rootStore
      }
    );

    this.showModal('DuplicateFormModal');
  }

  @action.bound async cancelDuplicateForm() {
    await this.hideActiveModal();

    this.duplicateFormForm = null;
    this.selectedForm = null;
  }

  @action.bound submitDuplicateFormForm(event) {
    event.preventDefault();

    this.duplicateFormForm.submit({
      onSuccess: this.submitDuplicateFormFormSuccess,
      onError: this.submitDuplicateFormFormError
    });
  }

  @action.bound async submitDuplicateFormFormSuccess() {
    this.saving = true;

    const values = this.duplicateFormForm.values();

    try {
      const name = `${values.name}.${this.selectedForm.name.split('.').pop()}`;

      await request.post(`${this.selectedForm.url()}/duplicate`, {
        name: name
      });

      callTrack(FORM_DUPLICATED, {
        project_name: this.selectedForm.projectName,
        original_form_name: this.selectedForm.name,
        new_form_name: name
      });

      this.parent.sortByLastCreated();

      this.cancelDuplicateForm();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Form duplicated')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitDuplicateFormFormError() {
    console.error(this.duplicateFormForm.errors());
  }

  @action.bound shareForm(form) {
    this.selectedForm = form;

    this.shareFormForm = new ShareFormForm(
      {
        fields: shareFormFormFields,
        rules: shareFormFormRules,
        values: shareFormFormValues,
        labels: shareFormFormLabels
      },
      {
        options: shareFormFormOptions,
        plugins: shareFormFormPlugins,
        rootStore: this.rootStore
      }
    );

    this.showModal('ShareFormModal');
  }

  @action.bound async cancelShareForm() {
    await this.hideActiveModal();

    this.shareFormForm = null;
    this.selectedForm = null;
  }

  @action.bound submitShareFormForm(event) {
    event.preventDefault();

    this.shareFormForm.submit({
      onSuccess: this.submitShareFormFormSuccess,
      onError: this.submitShareFormFormError
    });
  }

  @action.bound async submitShareFormFormSuccess() {
    this.saving = true;

    const values = this.shareFormForm.values();

    try {
      await request.post(`${this.selectedForm.url()}/share`, {
        emails: values.emails
      });

      callTrack(FORM_SHARED, {
        project_name: this.selectedForm.projectName,
        form_name: this.selectedForm.name
      });

      this.cancelShareForm();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Form shared')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitShareFormFormError() {
    console.error(this.shareFormForm.errors());
  }
}
