import { observable, action, computed } from 'mobx';
import UIStore from './UIStore';
import orderBy from 'lodash.orderby';
import { t } from 'utils/translate';
import errorHandler from 'utils/errorHandler';
import history from 'utils/history';

import OverTimeRulesUI from './workLogs/OverTimeRulesUI';

import {
  OverTimeRuleForm,
  overTimeRuleFormOptions,
  overTimeRuleFormFields,
  overTimeRuleFormPlugins
} from 'forms/overTimeRule';

import { callTrack } from 'utils/segmentIntegration';

import {
  OVERTIMERULESET_ADDED,
  OVERTIMERULESET_EDITED,
  OVERTIMERULESET_DELETED
} from 'utils/segmentAnalytics/eventSpec';

export default class CompanyOverTimeRulesUI extends UIStore {
  @observable entryForEdit;

  @observable searchQuery;
  @observable sortDirection;
  @observable pageSize;
  @observable page;

  constructor(options) {
    super(options);

    this.companyProductionUI = options.companyProductionUI;

    // Selecting
    this.selectedOverTimeRules = observable([]);

    // Searching
    this.searchQuery = '';

    // Sorting
    this.sortDirection = 'asc';

    // Editing
    this.entryForEdit = null;

    // Pagination
    this.pageSize = 20;
    this.page = 0;

    this.overTimeRulesUI = new OverTimeRulesUI({
      rootStore: this.rootStore,
      parent: this
    });

    /// Instantiate the create overTimeRule form
    this.overTimeRuleForm = new OverTimeRuleForm(
      {
        fields: overTimeRuleFormFields
      },
      {
        options: overTimeRuleFormOptions,
        plugins: overTimeRuleFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound clearUIState() {
    this.entryForEdit = null;
    this.searchQuery = '';
    this.selectedOverTimeRules.clear();

    this.page = 0;
  }

  @action.bound
  async fetchOverTimeRules() {
    try {
      await this.overTimeRules.fetch({
        params: {
          limit: 10000
        },
        add: true,
        remove: false,
        update: true
      });

      this.rootStore.companyOverTimeRulesFetched = true;
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  @computed get overTimeRules() {
    return this.rootStore.overTimeRules;
  }

  @computed
  get hasOverTimeRules() {
    return this.overTimeRules.hasModels;
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 0;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 0;
  }

  @computed get sortedOverTimeRules() {
    return orderBy(
      this.overTimeRules.models,
      [
        overTimeRule => overTimeRule.new,
        overTimeRule => overTimeRule.name.toLowerCase()
      ],
      ['desc', this.sortDirection]
    );
  }

  @computed get searchedOverTimeRules() {
    if (!this.searchQuery) {
      return this.sortedOverTimeRules;
    }

    const query = this.searchQuery.toLowerCase();

    return this.sortedOverTimeRules.filter(overTimeRule => {
      return overTimeRule.name.toLowerCase().indexOf(query) > -1;
    });
  }

  @computed get hasSearchedOverTimeRules() {
    return this.searchedOverTimeRules.length > 0;
  }

  @computed get paginatedOverTimeRules() {
    return this.searchedOverTimeRules.slice(
      this.page * this.pageSize,
      this.page * this.pageSize + this.pageSize
    );
  }

  @action.bound
  sortByColumn() {
    if (this.sortDirection === 'asc') {
      this.sortDirection = 'desc';
    } else {
      this.sortDirection = 'asc';
    }
  }

  @computed
  get totalPages() {
    return Math.ceil(this.searchedOverTimeRules.length / this.pageSize);
  }

  @action.bound
  setPage(page) {
    this.page = page.selected;
  }

  @action.bound
  showOverTimeRuleCreated(overTimeRule) {
    overTimeRule.setAsNew();

    this.rootStore.notificationsUI.pushNotification({
      showUndo: false,
      title: t('OverTimeRule Created')
    });

    this.setPage({
      selected: 0
    });
  }

  @computed get hasSelectedOverTimeRules() {
    return this.selectedOverTimeRules.length > 0;
  }

  @action.bound
  toggleSelectOverTimeRules(overTimeRule) {
    if (this.selectedOverTimeRules.find(id => id === overTimeRule.id)) {
      this.selectedOverTimeRules.remove(overTimeRule.id);
    } else {
      this.selectedOverTimeRules.push(overTimeRule.id);
    }
  }

  @computed
  get allOverTimeRulesSelected() {
    return (
      this.hasOverTimeRules &&
      this.selectedOverTimeRules.length === this.overTimeRules.models.length
    );
  }

  @action.bound
  toggleSelectAllOverTimeRules() {
    if (this.allOverTimeRulesSelected) {
      this.selectedOverTimeRules.clear();
    } else {
      this.selectedOverTimeRules.replace(
        this.searchedOverTimeRules.map(overTimeRule => {
          if (!overTimeRule.default) {
            return overTimeRule.id;
          }
        })
      );
    }
  }

  @action.bound softDeleteOverTimeRule(overTimeRule) {
    const originalIndex = overTimeRule.collection.models.indexOf(overTimeRule);

    this.cancelEntryEdit();

    overTimeRule.collection.remove(overTimeRule);

    this.rootStore.notificationsUI.pushNotification({
      onUndo: () => {
        this.cancelDeleteOverTimeRule(overTimeRule, originalIndex);
      },
      onDismiss: () => {
        this.confirmDeleteOverTimeRule(overTimeRule);
      },
      title: t('OverTimeRule Deleted')
    });
  }

  @action.bound
  cancelDeleteOverTimeRule(overTimeRule, index) {
    this.overTimeRules.add(overTimeRule, {
      at: index
    });
  }

  @action.bound
  confirmDeleteOverTimeRule(overTimeRule) {
    overTimeRule.destroy();
    callTrack(OVERTIMERULESET_DELETED);
  }

  @action.bound
  deleteSelectedOverTimeRules() {
    this.cancelEntryEdit();

    const overTimeRules = this.overTimeRules.models.filter(overTimeRule =>
      this.selectedOverTimeRules.includes(overTimeRule.id)
    );

    this.softDeleteOverTimeRules(overTimeRules);
    this.selectedOverTimeRules.clear();
  }

  @action.bound softDeleteOverTimeRules(overTimeRules) {
    this.overTimeRules.remove(overTimeRules);

    this.rootStore.notificationsUI.pushNotification({
      onUndo: () => {
        this.cancelDeleteOverTimeRules(overTimeRules);
      },
      onDismiss: () => {
        this.confirmDeleteOverTimeRules(overTimeRules);
      },
      title: t('OverTimeRules Deleted')
    });
  }

  @action.bound
  cancelDeleteOverTimeRules(overTimeRules) {
    this.overTimeRules.add(overTimeRules);
  }

  @action.bound
  confirmDeleteOverTimeRules(overTimeRules) {
    overTimeRules.forEach(async overTimeRule => {
      try {
        await overTimeRule.destroy();
        callTrack(OVERTIMERULESET_DELETED);
      } catch (error) {
        errorHandler(error, this.notifications.pushError);
      }
    });
  }

  @action.bound addNewOverTimeRule() {
    this.openOverTimeRulesByUrl();

    callTrack(OVERTIMERULESET_ADDED);
  }

  @action.bound setEntryForEdit(overTimeRule) {
    this.entryForEdit = overTimeRule;
    this.openOverTimeRulesByUrl(overTimeRule.uuid);

    callTrack(OVERTIMERULESET_EDITED);
  }

  @action.bound cancelEntryEdit() {
    this.entryForEdit = null;
    this.entryEditForm = null;
  }

  checkIfEntryDisabled(entry) {
    return this.entryForEdit && this.entryForEdit.id !== entry.id;
  }

  openOverTimeRulesByUrl(ruleUuid) {
    if (window.location.pathname.split('/').pop() !== 'overtime-rules') {
      history.push(
        ruleUuid
          ? `${window.location.pathname}/overtime-rules/${ruleUuid}`
          : `${window.location.pathname}/overtime-rules`
      );
    }
  }
}
