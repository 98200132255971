import {
  IncidentForm,
  incidentFormFields,
  incidentFormLabels,
  incidentFormOptions,
  incidentFormPlugins,
  incidentFormRules,
  incidentFormValues
} from 'forms/incident';
import { action, computed } from 'mobx';
import moment from 'moment';

import Incident from 'stores/models/incidents/Incident';
import MemberSelectorUI from 'stores/ui/MemberSelectorUI';

import alertErrorHandler from 'utils/alertErrorHandler';
import history from 'utils/history';
import { INCIDENT_CREATED } from 'utils/segmentAnalytics/eventSpec';
import { callTrack } from 'utils/segmentIntegration';

import { t } from 'utils/translate';

import ProjectChildAddUI from '../ProjectChildAddUI';

export default class IncidentAddUI extends ProjectChildAddUI {
  constructor(options) {
    super(options);

    this.notificationMemberSelectorUI = new MemberSelectorUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound async setup() {
    this.notificationMemberSelectorUI.setup({
      projectUuids: [this.project.uuid],
      sortField: 'firstName, lastName',
      role: [
        'ROLE_ACCOUNT_ADMIN',
        'ROLE_ADMIN',
        'ROLE_PROJECT_MEMBER',
        'ROLE_USER'
      ]
    });

    this.entryForAdd = new Incident(
      { type: 'Incident' },
      {
        rootStore: this.rootStore
      }
    );

    this.setupEntryAddForm();

    this.blockHistoryIfFormChanged();
  }

  @action.bound setupEntryAddForm() {
    this.entryAddForm = new IncidentForm(
      {
        fields: incidentFormFields,
        rules: incidentFormRules,
        labels: incidentFormLabels,
        values: Object.assign(incidentFormValues, {
          incidentLocation: this.project.incidentAddressString,
          incidentTime: moment().format('HH:mm')
        })
      },
      {
        options: incidentFormOptions,
        plugins: incidentFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound resetEntryAddForm() {
    this.setupEntryAddForm();
  }

  @action.bound cancelIncidentAdd() {
    history.push({
      pathname: `${this.project.viewUrl}/incidents`,
      search: this.baseQueryParams
    });
  }

  @action.bound tearDown() {
    super.tearDown();

    this.notificationMemberSelectorUI.tearDown();
  }

  @computed get title() {
    return t('Add incident');
  }

  @computed get incidentPIIHelperText() {
    return this.parent.incidentPIIHelperText;
  }

  @computed get incidentNotificationText() {
    return this.parent.incidentNotificationText;
  }

  @computed
  get incidentStatusOptions() {
    return this.parent.statusOptions;
  }

  @computed get incidentInjuryIllnessOptions() {
    return this.parent.incidentInjuryIllnessOptions;
  }

  @computed get employeeGenderOptions() {
    return this.parent.employeeGenderOptions;
  }

  @computed get incidentOutcomeOptions() {
    return this.parent.incidentOutcomeOptions;
  }

  @computed get isIncidentOutcomeDeath() {
    return this.entryAddForm.$('incidentOutcome').value === 1;
  }

  @computed
  get selectedStatusOption() {
    return this.incidentStatusOptions.find(
      option => option.value === this.entryAddForm.$('status').value
    );
  }

  @computed
  get selectedIncidentInjuryIllnessOption() {
    return this.incidentInjuryIllnessOptions.find(
      option =>
        option.value === this.entryAddForm.$('injuryOrIllnessType').value
    );
  }

  @computed
  get selectedEmployeeGenderOption() {
    return this.employeeGenderOptions.find(
      option => option.value === this.entryAddForm.$('employeeGender').value
    );
  }

  @computed
  get selectedIncidentOutcomeOption() {
    return this.incidentOutcomeOptions.find(
      option => option.value === this.entryAddForm.$('incidentOutcome').value
    );
  }

  @computed get hasWriteAccess() {
    return this.parent.hasWriteAccess;
  }

  @action.bound async submitEntryAddFormSuccess() {
    this.saving = true;

    try {
      const payload = this.entryAddForm.trimmedValues();

      payload.projectUuid = this.project.uuid;
      payload.year = moment().format('YYYY');

      payload.notifications = payload.notifications.map(notification => {
        return {
          uuid: notification.uuid,
          fullName: notification.name,
          email: notification.email,
          phoneNumber: notification.phoneNumber
        };
      });

      payload.attachments = this.entryForAdd.attachments.models.map(
        attachment => {
          return {
            uuid: attachment.uuid
          };
        }
      );

      payload.incidentDate = payload.incidentDate
        ? moment(payload.incidentDate).format('YYYY-MM-DD')
        : null;

      payload.incidentTime = payload.incidentTime || null;
      payload.incidentLocation = payload.incidentLocation || null;

      payload.employeeDateOfBirth = payload.employeeDateOfBirth
        ? moment(payload.employeeDateOfBirth).format('YYYY-MM-DD')
        : null;

      payload.employeeDateOfHire = payload.employeeDateOfHire
        ? moment(payload.employeeDateOfHire).format('YYYY-MM-DD')
        : null;

      payload.dateOfDeath =
        this.isIncidentOutcomeDeath && payload.dateOfDeath
          ? moment(payload.dateOfDeath).format('YYYY-MM-DD')
          : null;

      await this.entryForAdd.save(payload, {
        wait: true
      });

      callTrack(INCIDENT_CREATED, {
        project_id: this.entryForAdd.projectUuid,
        project_name: this.entryForAdd.projectName,
        account_type: this.me?.company?.accountSubType,
        incident_title: this.entryForAdd.incidentTitle,
        incident_status: this.entryForAdd.status,
        incident_recordable: this.entryForAdd.isRecordable,
        incident_has_notifications: this.entryForAdd.notifications.length > 0,
        incident_date: this.entryForAdd.incidentDate,
        incident_injury_or_illness_type: this.entryForAdd.injuryOrIllnessType,
        incident_employee_job_title: this.entryForAdd.employeeJobTitle,
        incident_outcome: this.entryForAdd.incidentOutcome
      });

      this.unblockHistory();
      this.parent.sortByLastCreated();
      this.cancelIncidentAdd();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Incident created')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }
}
