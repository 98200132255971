import { Model } from 'mobx-mc';
import { computed } from 'mobx';

export default class ChecklistTemplateQuestionChoice extends Model {
  get restAttributes() {
    return ['uuid', 'position', 'description'];
  }

  idAttribute() {
    return 'uuid';
  }

  @computed get formValues() {
    return {
      uuid: this.uuid,
      position: this.position,
      description: this.description
    };
  }
}
