import moment from 'moment';
import { action, computed, reaction } from 'mobx';
import InsightsUIModel from './InsightsUIModel';

import { t } from 'utils/translate';
import abbreviateNumber from 'utils/abbreviateNumber';
import conditionalProperty from 'utils/conditionalProperty';

export default class InsightsChecklistSummary extends InsightsUIModel {
  constructor(attributes, options) {
    super(attributes, options);

    this.typeClasses = options.typeClasses;

    // React to selection changes
    this.reactToSelection = reaction(
      () => this.params,
      params => {
        if (params.projectUuids || params.projectStatuses) {
          this.fetchStats();
        } else {
          this.cancelRequest();
          this.clear();
        }
      },
      { fireImmediately: true }
    );
  }

  url() {
    return `ra/companies/${this.rootStore.me.company.uuid}/insights/checklists/summary`;
  }

  get restAttributes() {
    return [
      'assigned',
      'missed',
      'completed',
      'compliance',
      'raised',
      'pending',
      'closed'
    ];
  }

  @computed get stats() {
    return [
      {
        label: t('Checklists assigned'),
        figure: abbreviateNumber(
          Math.round(conditionalProperty(this, 'assigned', 0))
        )
      },
      {
        label: t('Checklists completed'),
        figure: abbreviateNumber(
          Math.round(conditionalProperty(this, 'completed', 0))
        )
      },
      {
        label: t('Checklist compliance'),
        figure: conditionalProperty(this, 'compliance', 0) + '%'
      },
      {
        label: t('Issues raised'),
        figure: abbreviateNumber(
          Math.round(conditionalProperty(this, 'raised', 0))
        )
      },
      {
        label: t('Issues pending'),
        figure: abbreviateNumber(
          Math.round(conditionalProperty(this, 'pending', 0))
        )
      },
      {
        label: t('Issues closed'),
        figure: abbreviateNumber(
          Math.round(conditionalProperty(this, 'closed', 0))
        )
      }
    ];
  }

  @computed get hasStats() {
    return this.stats.some(stat => stat.figure > 0);
  }

  @computed
  get params() {
    const params = {
      startDay: moment(this.insightsUI.startDay)
        .startOf('day')
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss'),
      endDay: moment(this.insightsUI.endDay)
        .endOf('day')
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss'),
      typeClasses: this.typeClasses
    };

    switch (this.insightsUI.projectSelection) {
      case 'ALL':
        params.projectStatuses = 'ACTIVE,INACTIVE';
        break;
      case 'ACTIVE':
        params.projectStatuses = 'ACTIVE';
        break;
      default:
        params.projectUuids = this.insightsUI.filteredProjectUuids.join(',');
        break;
    }

    return params;
  }

  @action.bound
  async fetchStats() {
    await this.fetch({
      params: this.params
    });
  }
}
