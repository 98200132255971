import debounce from 'lodash.debounce';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import ProjectChildUI from '../ProjectChildUI';

import Forms from 'stores/collections/forms/Forms';
import FormAddUI from './FormAddUI';
import FormAddFromTemplateUI from './FormAddFromTemplateUI';
import FormEditUI from './FormEditUI';
import FormActionsUI from './FormActionsUI';

import history from 'utils/history';
import { BASE_DEBOUNCE } from 'fixtures/constants';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

import { FORM_FEATURE_VIEWED } from 'utils/segmentAnalytics/eventSpec';
import { callTrack } from 'utils/segmentIntegration';

export default class FormsUI extends ProjectChildUI {
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;

  constructor(options) {
    super(options);

    this.loading = true;

    this.sortField = 'createdTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.searchQuery = '';
    this.pageSize = 20;

    // Forms collection
    this.forms = new Forms(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.formActionsUI = new FormActionsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.formAddUI = new FormAddUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.formAddFromTemplateUI = new FormAddFromTemplateUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.formEditUI = new FormEditUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchFormsDebounced = debounce(this.fetchForms, BASE_DEBOUNCE);
  }

  @action.bound setup() {
    callTrack(FORM_FEATURE_VIEWED);
    window.scrollTo(0, 0);
    this.setupReactions();
    this.fetchForms();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchFormsDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed
  get params() {
    return {
      projectUuids: this.projectUuid,
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      sortField: this.sortField,
      sortDirection: this.sortDirection
    };
  }

  @computed get hasRequiredParams() {
    return this.params.projectUuids;
  }

  @action.bound async fetchForms() {
    if (!this.hasRequiredParams) return;

    this.forms.cancelRequest();
    this.forms.clear();

    try {
      await this.forms.fetch({
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasForms() {
    return this.forms.hasModels;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.forms.totalElements / this.pageSize);
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.searchQuery = '';
    this.forms.clear();
    this.page = 1;
    this.loading = true;
    this.saving = false;
    this.sortField = 'createdTimestamp';
    this.sortDirection = 'desc';
  }

  @computed get showEmptyState() {
    return !this.loading && !this.searchQuery && !this.hasForms;
  }

  @computed get showEmptySearchState() {
    return !this.loading && this.searchQuery && !this.hasForms;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @action.bound async addForm() {
    await this.authorization.checkFeatureAccess('CUDForms');

    history.push({
      pathname: `${this.project.viewUrl}/forms/add`,
      search: this.baseQueryParams
    });
  }

  @action.bound async addFormFromTemplate() {
    await this.authorization.checkFeatureAccess('CUDForms');

    history.push({
      pathname: `${this.project.viewUrl}/forms/templates`,
      search: this.baseQueryParams
    });
  }

  @action.bound async editForm(form) {
    history.push({
      pathname: `${this.project.viewUrl}/forms/${form.uuid}`,
      search: this.baseQueryParams
    });
  }

  @action.bound sortByLastCreated() {
    this.sortField = 'createdTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchFormsDebounced();
  }

  @action.bound refetchForms() {
    this.loading = true;
    if (!this.hasForms) {
      this.setPage(null, 1);
      this.fetchForms();
    } else {
      this.fetchForms();
    }
  }

  getFormActions = form => {
    let actions = [
      {
        title: this.hasWriteAccess ? t('Edit') : t('View'),
        onClick: () => {
          this.editForm(form);
        }
      },
      {
        title: t('Download'),
        onClick: () => {
          this.formActionsUI.downloadForm(form);
        }
      },
      {
        title: t('Share'),
        onClick: () => {
          this.formActionsUI.shareForm(form);
        }
      }
    ];

    if (this.hasWriteAccess) {
      actions = actions.concat([
        {
          title: t('Rename'),
          onClick: () => {
            this.formActionsUI.renameForm(form);
          }
        },
        {
          title: t('Duplicate'),
          onClick: () => {
            this.formActionsUI.duplicateForm(form);
          }
        },
        {
          title: t('Delete'),
          onClick: () => {
            this.formActionsUI.deleteForm(form);
          }
        }
      ]);
    }

    return actions;
  };

  @computed get createActions() {
    return [
      {
        title: t('Upload'),
        onClick: () => {
          this.addForm();
        }
      },
      {
        title: t('Select from template'),
        onClick: () => {
          this.addFormFromTemplate();
        }
      }
    ];
  }

  @computed get hasWriteAccess() {
    return this.authorization.canCUDForms;
  }
}
