import { computed } from 'mobx';
import moment from 'moment';
import Subscription from '../Subscription';

class SuperAdminSubscription extends Subscription {
  url() {
    return `ra/sadmin/companies/${this.id}/billing/subscription`;
  }

  get restAttributes() {
    return [
      'companyId',
      'subscriptionState',
      'startDate',
      'endDate',
      'discount',
      'isDiscountPermanent',
      'autoRenew',
      'seats',
      'seatsInUse',
      'trialEndDate',
      'active',
      'billingPlanKey',
      'billingPlanName',
      'billingPlatform',
      'periodType',
      'type',
      'upcomingBillingAmount',
      'upcomingBillingDate',
      'pricePerSeat',
      'pricePerSeatToday',
      'upcomingSubscriptionPlan',
      'upcomingBillingPlanKey',
      'upcomingPeriodType',
      'forceDowngrade',
      'scheduledPlan',
      'promoLeftDaysCount',
      'isPromo',
      'promoEndDate',
      'salesRepresentative',
      'accountType',
      'accountSubType'
    ];
  }

  @computed get scheduledBillingPlanKey() {
    if (this.scheduledPlan && this.scheduledPlan.status === 'ACTIVE') {
      return this.scheduledPlan.upcomingBillingPlanKey;
    }

    return '';
  }

  @computed
  get subscriptionEditFormValues() {
    return {
      accountType: this.accountType,
      billingPlanKey: this.billingPlanKey,
      periodType: this.periodType,
      autoRenew: this.autoRenew,
      subscriptionState: this.subscriptionState,
      startDate: moment(this.startDate).format('YYYY-MM-DD'),
      endDate: moment(this.endDate).format('YYYY-MM-DD'),
      billingPlatform: this.billingPlatform,
      seats: this.seats,
      pricePerSeat: this.pricePerSeat,
      discount: this.discount,
      isDiscountPermanent: this.isDiscountPermanent,
      salesRepresentative: this.salesRepresentative.id,
      isPromo: this.isPromo,
      trialEndDate: moment(this.trialEndDate).format('YYYY-MM-DD'),
      promoEndDate: this.promoEndDate
        ? moment(this.promoEndDate).format('YYYY-MM-DD')
        : null,
      scheduledBillingPlanKey: this.scheduledBillingPlanKey
    };
  }
}

export default SuperAdminSubscription;
