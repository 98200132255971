import { BASE_DEBOUNCE } from 'fixtures/constants';
import debounce from 'lodash.debounce';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import Incidents from 'stores/collections/incidents/Incidents';
import alertErrorHandler from 'utils/alertErrorHandler';
import request from 'axios';
import moment from 'moment';
import history from 'utils/history';
import {
  INCIDENT_DELETED,
  INCIDENT_EDITED
} from 'utils/segmentAnalytics/eventSpec';
import { COMPANY_INCIDENTS_VIEWED } from 'utils/segmentAnalytics/eventSpec';
import { callTrack } from 'utils/segmentIntegration';
import { t } from 'utils/translate';
import UIStore from '../../UIStore';
import IncidentExportUI from './IncidentExportUI';
import SubmissionSettings from 'stores/models/SubmissionSettings';
import {
  EmailIncidentForm,
  emailIncidentFormOptions,
  emailIncidentFormFields,
  emailIncidentFormRules,
  emailIncidentFormLabels,
  emailIncidentFormPlugins
} from 'forms/emailIncident';

export default class IncidentsUI extends UIStore {
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;
  @observable selectedIncident;
  @observable exportType;
  @observable shareIncidentForm;

  constructor(options) {
    super(options);

    this.loading = true;

    this.selectedIncident = null;
    this.exportType = null;
    this.sortField = 'incidentDateTime';
    this.sortDirection = 'desc';
    this.page = 1;
    this.searchQuery = '';
    this.pageSize = 20;
    this.shareIncidentForm = null;
    // Incidents collection
    this.incidents = new Incidents(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchIncidentsDebounced = debounce(this.fetchIncidents, BASE_DEBOUNCE);

    this.incidentExportUI = new IncidentExportUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.submissionSettings = new SubmissionSettings(null, {
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound setup() {
    callTrack(COMPANY_INCIDENTS_VIEWED);
    window.scrollTo(0, 0);
    this.setupReactions();
    this.submissionSettings.fetch();
    this.fetchIncidents();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchIncidentsDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      sortField: `${this.sortField}`,
      sortDirection: this.sortDirection
    };
  }

  @action.bound async fetchIncidents() {
    this.incidents.cancelRequest();
    this.incidents.reset();

    try {
      await this.incidents.fetch({
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasIncidents() {
    return this.incidents.hasModels;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.incidents.totalElements / this.pageSize);
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.searchQuery = '';
    this.incidents.clear();
    this.page = 1;
    this.loading = true;
    this.saving = false;
    this.sortField = 'incidentDateTime';
    this.sortDirection = 'desc';
    this.selectedIncident = null;
    this.exportType = null;
    this.submissionSettings.clear();
    this.shareIncidentForm = null;
  }

  @computed get showEmptyState() {
    return !this.loading && !this.searchQuery && !this.hasIncidents;
  }

  @computed get showEmptySearchState() {
    return !this.loading && this.searchQuery && !this.hasIncidents;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @action.bound async editIncident(incident) {
    history.push({
      pathname: `/projects/${incident.projectUuid}/incidents/${incident.uuid}`,
      search: this.baseQueryParams
    });
  }

  @action.bound async deleteIncident(incident) {
    await this.authorization.checkFeatureAccess('CUDIncidents');
    this.selectedIncident = incident;
    this.showModal('DeleteModal');
  }

  @action.bound async cancelDeleteIncident() {
    await this.hideActiveModal();

    this.selectedIncident = null;
  }

  @action.bound async confirmDeleteIncident() {
    this.saving = true;

    try {
      await this.selectedIncident.destroy({ wait: true });

      this.refetchIncidents();

      await this.hideActiveModal();

      callTrack(INCIDENT_DELETED, {
        project_id: this.selectedIncident.projectUuid,
        project_name: this.selectedIncident.projectName,
        account_type: this.me?.company?.accountSubType
      });

      this.selectedIncident = null;

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Incident deleted')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound async exportIncidentReports(exportType) {
    this.exportType = exportType;
    await this.authorization.checkFeatureAccess('CUDIncidents');
    history.push({
      pathname: `/company-settings/incidents/all-incidents/export-reports`,
      search: this.baseQueryParams
    });
  }

  @action.bound sortByLastCreated() {
    this.sortField = 'createdTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchIncidentsDebounced();
  }

  @action.bound sortByLastUpdated() {
    this.sortField = 'incidentDateTime';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchIncidentsDebounced();
  }

  @action.bound refetchIncidents() {
    this.loading = true;
    if (!this.hasIncidents) {
      this.setPage(null, 1);
      this.fetchIncidents();
    } else {
      this.fetchIncidents();
    }
  }

  @computed get hasWriteAccess() {
    return this.authorization.canCUDIncidents;
  }

  @action.bound async updateIncidentStatus(incident) {
    await this.authorization.checkFeatureAccess('CUDIncidents');

    await incident.save({
      status: incident.status === 'Open' ? 'Closed' : 'Open'
    });

    callTrack(INCIDENT_EDITED, {
      project_id: incident.projectUuid,
      project_name: incident.projectName,
      account_type: this.me?.company?.accountSubType,
      incident_title: incident.incidentTitle,
      incident_status: incident.status,
      incident_recordable: incident.isRecordable,
      incident_has_notifications: incident.notifications.length > 0,
      incident_date: incident.incidentDate,
      incident_injury_or_illness_type: incident.injuryOrIllnessType,
      incident_employee_job_title: incident.employeeJobTitle,
      incident_outcome: incident.incidentOutcome
    });
  }

  getActions = incident => {
    let actions = [
      {
        title: this.hasWriteAccess ? t('Edit') : t('View'),
        onClick: () => {
          this.editIncident(incident);
        }
      }
    ];
    if (this.hasWriteAccess) {
      actions = actions.concat([
        {
          title: t('Share'),
          onClick: () => {
            this.openShareIncidentModal(incident);
          }
        },
        {
          title: incident.status === 'Open' ? t('Close') : t('Reopen'),
          onClick: () => {
            this.updateIncidentStatus(incident);
          }
        },
        {
          title: t('Delete'),
          onClick: () => {
            this.deleteIncident(incident);
          }
        }
      ]);
    }
    return actions;
  };

  @action.bound async openShareIncidentModal(incident) {
    await this.authorization.checkFeatureAccess('CUDIncidents', true);
    this.shareIncidentForm = new EmailIncidentForm(
      {
        fields: emailIncidentFormFields,
        rules: emailIncidentFormRules,
        labels: emailIncidentFormLabels,
        values: {
          emails: []
        }
      },
      {
        options: emailIncidentFormOptions,
        plugins: emailIncidentFormPlugins
      }
    );
    this.selectedIncident = incident;
    this.showModal('shareIncident');
  }

  @action.bound
  async hideShareIncidentModal() {
    await this.hideActiveModal();
    this.selectedIncident = null;
    this.shareIncidentForm = null;
  }

  @action.bound submitShareIncidentForm(event) {
    event.preventDefault();
    event.stopPropagation();
    this.shareIncidentForm.submit({
      onSuccess: this.submitShareIncidentFormSuccess,
      onError: this.submitShareIncidentFormError
    });
  }

  @action.bound async submitShareIncidentFormSuccess() {
    this.saving = true;
    try {
      const payload = {
        exportType: 'INCIDENT_REPORT_301',
        year: moment().year(),
        emails: this.shareIncidentForm.$('emails').value,
        skipIncompleteRecordValidation: true
      };
      await request.post(
        `${this.rootStore.urlMicroService('toolbox')}/companies/${
          this.company.uuid
        }/incident-reports/${this.selectedIncident?.uuid}/export`,
        payload
      );
      await this.hideShareIncidentModal();
      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Incident shared')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitShareIncidentFormError() {
    console.error(this.shareIncidentForm.errors());
  }

  @computed
  get emailListIsValid() {
    return this.shareIncidentForm?.isValid;
  }
}
