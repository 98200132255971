import { Model } from 'mobx-mc';
import { observable, action, computed } from 'mobx';
import MaterialCostCodes from '../collections/MaterialCostCodes';
import omit from 'lodash.omit';
import orderBy from 'lodash.orderby';
import kebabCase from 'lodash.kebabcase';

export default class MaterialDivision extends Model {
  @observable open;

  constructor(data = {}, options) {
    super(data, options);

    this.open = false;
  }

  get restAttributes() {
    return [
      'division',
      'budgetedHours',
      'actualHours',
      'remainingHours',
      'completeHoursPercent',
      'completeQuantityPercent',
      'projectedTotalHours',
      'budgetedQuantityPerHour',
      'actualQuantityPerHour',
      'projectedHoursGainOrLoss'
    ];
  }

  idAttribute() {
    return 'division';
  }

  @action.bound
  parse(attributes) {
    this.costCodes = new MaterialCostCodes(attributes.costCodes || null, {
      rootStore: this.rootStore,
      parent: this
    });

    return {
      ...omit(attributes, ['costCodes'])
    };
  }

  @computed get slug() {
    return kebabCase(this.division);
  }

  @computed get uiStore() {
    return this.collection.parent;
  }

  @computed get sortedCostCodes() {
    return orderBy(
      this.costCodes.models,
      [this.uiStore.sortField],
      [this.uiStore.sortDirection]
    );
  }

  @computed get hasCostCodes() {
    return this.costCodes.hasModels;
  }

  @action.bound
  handleToggle() {
    this.open = !this.open;
  }

  @action.bound
  handleShow() {
    this.open = true;
  }

  @action.bound
  handleHide() {
    this.open = false;
  }
}
