import omit from 'lodash.omit';
import { action, computed, reaction } from 'mobx';

import InsightsUIModel from './InsightsUIModel';
import ProjectMeasureStats from './ProjectMeasureStats';
import ProjectMeasures from '../collections/ProjectMeasures';

export default class AllTimeStatsByProject extends InsightsUIModel {
  constructor(attributes, options) {
    super(attributes, options);

    this.projectMeasureStats = new ProjectMeasureStats(null, {
      rootStore: this.rootStore,
      parent: this
    });

    this.projectMeasures = new ProjectMeasures(null, {
      rootStore: this.rootStore,
      parent: this
    });

    // React to selection changes
    this.reactToSelection = reaction(
      () => this.params,
      params => {
        if (params.projectIds || params.projectStatuses) {
          this.fetchStats();
        } else {
          this.cancelRequest();
          this.projectMeasureStats.clear();
          this.projectMeasures.reset();
        }
      },
      { fireImmediately: true }
    );
  }

  url() {
    return '/ra/insights/allTimeStatsByProject';
  }

  @action.bound
  parse(attributes) {
    this.parseProjectMeasureStats(attributes.rollupProjectMeasureStats);
    this.parseProjectMeasures(attributes.projectMeasures);

    return {
      ...omit(attributes, ['rollupProjectMeasureStats', 'projectMeasures'])
    };
  }

  @action.bound
  parseProjectMeasureStats(rollupProjectMeasureStats) {
    this.projectMeasureStats.set(rollupProjectMeasureStats);
  }

  @computed
  get params() {
    if (this.insightsUI.projects.fetching) return null;

    const params = {
      startDay: this.insightsUI.startDay,
      endDay: this.insightsUI.endDay
    };

    switch (this.insightsUI.projectSelection) {
      case 'ALL':
        params.projectStatuses = 'ACTIVE,INACTIVE';
        break;
      case 'ACTIVE':
        params.projectStatuses = 'ACTIVE';
        break;
      default:
        params.projectIds = this.insightsUI.filteredProjectIds.join(',');
        break;
    }

    return params;
  }
}
