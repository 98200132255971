import debounce from 'lodash.debounce';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import UIStore from 'stores/ui/UIStore';

import SuperAdminProjectMembers from 'stores/collections/superAdmin/SuperAdminProjectMembers';

import { BASE_DEBOUNCE } from 'fixtures/constants';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';

export default class SuperAdminProjectUsersUI extends UIStore {
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;

  constructor(options) {
    super(options);

    this.loading = true;

    this.sortField = 'name';
    this.sortDirection = 'asc';
    this.page = 1;
    this.searchQuery = '';
    this.pageSize = 20;

    // Users collection
    this.users = new SuperAdminProjectMembers(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchUsersDebounced = debounce(this.fetchUsers, BASE_DEBOUNCE);
  }

  @computed get activeProject() {
    return this.parent.activeProject;
  }

  @computed get id() {
    return this.activeProject.id;
  }

  @action.bound setup() {
    window.scrollTo(0, 0);
    this.setupReactions();
    this.fetchUsers();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchUsersDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      sortField: this.sortField,
      sortDirection: this.sortDirection
    };
  }

  @action.bound async fetchUsers() {
    this.users.cancelRequest();
    this.users.clear();

    try {
      await this.users.fetch({
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasUsers() {
    return this.users.hasModels;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.users.totalElements / this.pageSize);
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.searchQuery = '';
    this.users.clear();
    this.page = 1;
    this.loading = true;
    this.sortField = 'name';
    this.sortDirection = 'asc';
  }

  @computed get showEmptyState() {
    return !this.loading && !this.searchQuery && !this.hasUsers;
  }

  @computed get showEmptySearchState() {
    return !this.loading && this.searchQuery && !this.hasUsers;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @computed
  get extendedPermissions() {
    return this.parent.extendedPermissions;
  }

  @action.bound
  async toggleUserMembership(user) {
    if (!user.disableToggleMembership) {
      try {
        await user.save(
          {
            isMember: !user.isMember
          },
          {
            wait: true
          }
        );

        this.notifications.pushNotification({
          snackbar: 'warning',
          icon: 'checkmark',
          title: t(`{user} {action} to project`, {
            templateStrings: {
              user: user.fullName,
              action: user.isMember ? 'added' : 'removed'
            }
          })
        });
      } catch (error) {
        alertErrorHandler(error, this.setValidationDetails);
      }
    }
  }
}
