import { computed } from 'mobx';
import { RakenCollection } from '../../lib';

import SuperAdminEtlJob from '../../models/superAdmin/SuperAdminEtlJob';

export default class SuperAdminWorkerEtlJobs extends RakenCollection {
  model() {
    return SuperAdminEtlJob;
  }

  url() {
    return `${this.rootStore.urlMicroService('integrations')}/etl/jobs`;
  }

  @computed get polling() {
    return this.hasModels && this.at(0).status === 'RUNNING';
  }

  @computed get loading() {
    return !this.hasModels && this.fetching;
  }

  @computed get lastRunJobSucceeded() {
    return this.hasModels && this.at(0).status === 'SUCCEEDED';
  }
}
