import { RakenCollection } from '../../lib';
import TimeEntriesWorkLog from '../../models/workLogs/TimeEntriesWorkLog';
import StandardWorkLog from '../../models/StandardWorkLog';

export default class WorkLogs extends RakenCollection {
  model(type) {
    if (type === 'CrewWorklogWithTimeEntries') {
      return TimeEntriesWorkLog;
    }

    return StandardWorkLog;
  }

  url() {
    return `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/companies/${this.rootStore.me.company.uuid}/worklogs`;
  }
}
