import styled from 'styled-components';

export default styled.div`
  color: ${props => props.theme.colors.charcoalGrey};
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: 100;
  line-height: 1.6rem;
  text-decoration: none;
  transition: color 0.25s ease-in-out;
  text-transform: capitalize;
  margin-right: 1rem;
  padding-bottom: 1rem;
  &:before {
    content: '>';
  }
  &:first-child {
    &:before {
      content: '';
    }
  }
  span {
    font-weight: ${props => (props.active ? '700' : '100')};
    padding-left: 1rem;
  }
`;
