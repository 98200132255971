export default (baseUrl, file) => {
  switch (file.type) {
    case 'application/pdf':
      return `${baseUrl}/svg/attachments/PDF.svg`;
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
    case 'application/vnd.ms-word.document.macroEnabled.12':
    case 'application/vnd.ms-word.template.macroEnabled.12':
      return `${baseUrl}/svg/attachments/DOC.svg`;
    case 'audio/mp3':
    case 'audio/mpeg':
      return `${baseUrl}/svg/attachments/MP3.svg`;
    case 'video/mp4':
      return `${baseUrl}/svg/attachments/MP4.svg`;
    case 'video/x-m4v':
      return `${baseUrl}/svg/attachments/M4V.svg`;
    case 'video/quicktime':
      return `${baseUrl}/svg/attachments/MOV.svg`;
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.template':
    case 'application/vnd.ms-excel.sheet.macroEnabled.12':
    case 'application/vnd.ms-excel.template.macroEnabled.12':
    case 'application/vnd.ms-excel.sheet.binary.macroEnabled.12':
      return `${baseUrl}/svg/attachments/XLS.svg`;
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    case 'application/vnd.openxmlformats-officedocument.presentationml.template':
    case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
    case 'application/vnd.ms-powerpoint.addin.macroEnabled.12':
    case 'application/vnd.ms-powerpoint.presentation.macroEnabled.12':
    case 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12':
      return `${baseUrl}/svg/attachments/PPT.svg`;
    default:
      return file.preview;
  }
};
