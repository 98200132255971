import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CircleButton } from '.';

const WrapperC = styled.div`
  display: inline-block;
  vertical-align: top;
  padding: 0 5px;
`;

const Number = styled.div`
  text-align: center;
  padding: 5px 0;
  font-size: 1.4rem;
  color: ${props => props.theme.colors.lightGrey3};
`;

export default class CounterToggle extends Component {
  static propTypes = {
    type: PropTypes.string,
    values: PropTypes.array,
    onChange: PropTypes.func,
    changeOnMount: PropTypes.bool,
    changeFromAMToPM: PropTypes.bool
  };

  static defaultProps = {
    step: 1,
    changeOnMount: true,
    changeFromAMToPM: false
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedIndex: 0
    };
  }

  componentDidMount() {
    const { value, onChange, changeOnMount } = this.props;
    const values = this.handleTypeValues();

    const index = values.indexOf(value);

    this.setState({
      selectedIndex: index
    });

    if (changeOnMount) {
      onChange(values[index]);
    }
  }

  handleTypeValues = () => {
    const { type, values } = this.props;
    const array = values ? values : [];

    switch (type) {
      case 'hour':
        for (let i = 1; i <= 12; i++) {
          let value = i < 10 ? '0' + i : i.toString();

          array.push(value);
        }

        return array;

      case 'minute':
        for (let i = 0; i <= 59; i++) {
          let value = i < 10 ? '0' + i : i.toString();

          array.push(value);
        }

        return array;

      default:
        return array;
    }
  };

  onPrev = () => {
    const values = this.handleTypeValues();
    const { onChange, step } = this.props;
    let saveSelectedIndex = this.state.selectedIndex;

    if (saveSelectedIndex >= step) {
      saveSelectedIndex = saveSelectedIndex - step;
    } else {
      saveSelectedIndex = values.length - step;
    }

    this.setState({
      selectedIndex: saveSelectedIndex
    });

    onChange(values[saveSelectedIndex]);
  };

  onNext = () => {
    const values = this.handleTypeValues();
    const { onChange, step } = this.props;
    let saveSelectedIndex = this.state.selectedIndex;

    if (saveSelectedIndex + step < values.length) {
      saveSelectedIndex = saveSelectedIndex + step;
    } else {
      saveSelectedIndex = 0;
    }

    this.setState({
      selectedIndex: saveSelectedIndex
    });

    onChange(values[saveSelectedIndex]);
  };

  render() {
    const { type, changeFromAMToPM } = this.props;
    const values = this.handleTypeValues();
    const { selectedIndex } = this.state;

    if (changeFromAMToPM) {
      this.onNext();
    }

    return (
      <WrapperC>
        <CircleButton
          icon="collapse"
          size="small"
          color="lightGrey3"
          onClick={this.onNext}
          data-qa={`timepicker_${type}-up`}
        />
        <Number data-qa={`timepicker_${type}-value`}>
          {values[selectedIndex]}
        </Number>
        <CircleButton
          icon="expand"
          size="small"
          color="lightGrey3"
          onClick={this.onPrev}
          data-qa={`timepicker_${type}-down`}
        />
      </WrapperC>
    );
  }
}
