import once from 'lodash.once';
import omit from 'lodash.omit';
import { action, observable, computed, when } from 'mobx';
import UIStore from './UIStore';
import history from 'utils/history';
import { t } from 'utils/translate';

import alertErrorHandler from 'utils/alertErrorHandler';
import JobTitles from '../collections/JobTitles';
import isDataURL from 'utils/isDataURL';

import User from 'stores/models/User';
import TeamMemberProjectsUI from './TeamMemberProjectsUI';
import TeamMemberCertificationsUI from './TeamMemberCertificationsUI';
import TeamMemberNotificationsUI from './TeamMemberNotificationsUI';
import TeamMemberTasksUI from './TeamMemberTasksUI';
import TeamMemberResetPasswordUI from './TeamMemberResetPasswordUI';
import TeamMemberEmailPreferencesUI from './TeamMemberEmailPreferencesUI';

import {
  JobTitleForm,
  jobTitleFormValues,
  jobTitleFormRules,
  jobTitleFormFields,
  jobTitleFormOptions,
  jobTitleFormPlugins
} from 'forms/jobTitle';

import {
  MemberForm,
  memberFormOptions,
  memberFormFields,
  memberFormFieldOptions,
  memberFormLabels,
  memberFormRules,
  memberFormPlugins
} from 'forms/member';
import { getMemberRoles } from 'utils/roles';

export default class TeamMemberProfileUI extends UIStore {
  @observable entryForEdit;
  @observable entryEditForm;
  @observable previousPath;

  constructor(options) {
    super(options);
    // Editing
    this.entryForEdit = null;
    this.entryEditForm = null;

    // Going back
    this.previousPath = undefined;

    this.jobTitles = new JobTitles(null, {
      rootStore: this.rootStore
    });

    this.fetchJobTitlesOnce = once(this.fetchJobTitles);

    this.fetchClassificationsOnce = once(
      this.classificationsUI.fetchClassifications
    );

    this.teamMemberProjectsUI = new TeamMemberProjectsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.teamMemberCertificationsUI = new TeamMemberCertificationsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.teamMemberNotificationsUI = new TeamMemberNotificationsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.teamMemberTasksUI = new TeamMemberTasksUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.teamMemberResetPasswordUI = new TeamMemberResetPasswordUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.teamMemberEmailPreferencesUI = new TeamMemberEmailPreferencesUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound fetchJobTitles() {
    this.jobTitles.fetch({
      params: {
        limit: 10000
      }
    });
  }

  @action.bound async setup(previousPath, id) {
    await this.setupMemberAttributes();
    this.setPreviousPath(previousPath);
    this.findOrFetchMember(id);
    this.fetchClassificationsOnce();
    this.fetchJobTitlesOnce();
  }

  @action.bound tearDown() {
    this.cancelEntryEdit();
    this.tearDownMemberAttributes();
  }

  @computed get teamMembersActionsUI() {
    return this.parent.teamMembersActionsUI;
  }

  @computed
  get members() {
    return this.parent.members;
  }

  @action.bound setPreviousPath(previousPath) {
    if (previousPath) {
      this.previousPath = previousPath;
    } else {
      this.previousPath = undefined;
    }
  }

  @action.bound async findOrFetchMember(memberUuid) {
    if (this.entryForEdit || this.fetchingEntryForEdit) return;

    let memberModel = this.members.models.find(
      member => member.uuid === memberUuid
    );

    if (memberModel) {
      this.fetchingEntryForEdit = true;

      await memberModel.fetch();

      this.setEntryForEdit(memberModel);

      this.fetchingEntryForEdit = false;
    } else {
      memberModel = new User(
        {
          uuid: memberUuid
        },
        {
          rootStore: this.rootStore
        }
      );

      try {
        this.fetchingEntryForEdit = true;

        await memberModel.fetch();

        this.setEntryForEdit(memberModel);
      } catch (error) {
        this.goBack(true);
      } finally {
        this.fetchingEntryForEdit = false;
      }
    }
  }

  @action.bound async setEntryForEdit(member) {
    this.entryForEdit = member;

    when(
      () => !this.entryForEdit.settings.fetchingWorkerDefaults,
      () => {
        this.entryEditForm = new MemberForm(
          {
            fields: memberFormFields,
            rules: Object.assign(memberFormRules, {
              username: 'email|required|max:200'
            }),
            labels: memberFormLabels,
            values: member.formValues,
            options: memberFormFieldOptions,
            disabled: {
              workerType: true,
              id: true
            }
          },
          {
            options: Object.assign(memberFormOptions, {
              retrieveOnlyEnabledFields: true
            }),
            plugins: memberFormPlugins,
            rootStore: this.rootStore
          }
        );
      }
    );
  }

  @action.bound cancelEntryEdit() {
    this.clearValidationDetails();
    this.entryForEdit = null;
    this.entryEditForm = null;
    this.fetchingEntryForEdit = false;
    this.previousPath = undefined;
  }

  @computed get goBackUrl() {
    if (this.previousPath) return this.previousPath;

    if (this.authorization.canViewTeam) {
      return '/team';
    } else {
      return '/projects';
    }
  }

  @action.bound goBack(replaceHistory = false) {
    if (replaceHistory) {
      history.replace(this.goBackUrl);
    } else {
      history.push(this.goBackUrl);
    }
  }

  @computed get roleOptions() {
    return getMemberRoles();
  }

  @computed get classificationOptions() {
    return this.classificationsUI.selectableClassifications.map(
      classification => {
        return {
          uuid: classification.uuid,
          name: classification.name
        };
      }
    );
  }

  @computed get jobTitleOptions() {
    return this.jobTitles.models.map(jobTitle => {
      return {
        value: jobTitle.name,
        name: jobTitle.name
      };
    });
  }

  @computed
  get canEditProfileInfo() {
    return (
      (this.me.isAdmin && this.entryForEdit.isTeamMember) ||
      this.entryForEdit.isMe
    );
  }

  @computed
  get canEditProfileRole() {
    return (
      this.me.isAccountAdmin &&
      !this.entryForEdit.isNew &&
      !this.entryForEdit.isMe
    );
  }

  @computed
  get canEditProfileClassification() {
    return this.me.isAdmin && this.entryForEdit.isTeamMember;
  }

  @computed
  get canEditProfileGroups() {
    return this.me.isAdmin && this.entryForEdit.isTeamMember;
  }

  @computed get entryEditFormInvalid() {
    return (
      this.memberExists ||
      !this.canEditProfileInfo ||
      this.entryEditForm.check('hasError')
    );
  }

  @computed get disableSaveButton() {
    return this.saving || this.entryEditFormInvalid;
  }

  @computed get disableCancelButton() {
    return this.saving || !this.canEditProfileInfo;
  }

  @action.bound submitEntryEditForm(event) {
    event.preventDefault();

    if (this.entryEditForm.submitting) return;

    this.entryEditForm.submit({
      onSuccess: this.submitEntryEditFormSuccess,
      onError: this.submitEntryEditFormError
    });
  }

  @action.bound async submitEntryEditFormSuccess() {
    if (this.saving) return;

    this.clearValidationDetails();

    this.saving = true;

    const values = this.entryEditForm.trimmedValues();

    values.settings = {
      workerDefaultShift: values.defaultShift.uuid,
      workerDefaultCostCode: values.defaultCostCode.uuid,
      workerDefaultCrewName: values.defaultCrewName.uuid
    };

    if (isDataURL(values.avatar)) {
      values.avatar = {
        base64: values.avatar
      };
    } else {
      delete values.avatar;
    }

    values.groupUuids = values.groups.map(group => group.uuid);

    delete values.groups;

    try {
      await this.entryForEdit.save(
        omit(values, [
          'username',
          'company',
          'workerUuid',
          'defaultShift',
          'defaultCostCode',
          'defaultCrewName'
        ]),
        {
          wait: true,
          stripNonRest: false
        }
      );

      if (this.entryForEdit.isMe) {
        this.rootStore.me.fetch({
          url: `ra/user`
        });
      }

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Team member info saved')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitEntryEditFormError() {
    history.push(`${this.entryForEdit.viewUrl}/info`);
    console.error(this.entryEditForm.errors());
  }

  @computed
  get showProjectsNotificationsTasks() {
    return (
      (this.entryForEdit.isTeamMember &&
        !this.rootStore.me.isPending &&
        this.rootStore.me.isAdmin) ||
      this.entryForEdit.isMe
    );
  }

  @computed get profileLinks() {
    let links = [
      {
        path: {
          pathname: `${this.entryForEdit.viewUrl}/info`,
          state: { previousPath: this.previousPath }
        },
        label: t('Info')
      }
    ];

    if (this.showProjectsNotificationsTasks && !this.entryForEdit.inviteId) {
      links = links.concat([
        {
          path: {
            pathname: `${this.entryForEdit.viewUrl}/attributes`,
            state: { previousPath: this.previousPath }
          },
          label: t('Default attributes')
        }
      ]);
    }

    if (this.showProjectsNotificationsTasks) {
      links = links.concat([
        {
          path: {
            pathname: `${this.entryForEdit.viewUrl}/projects`,
            state: { previousPath: this.previousPath }
          },
          label: t('Projects')
        }
      ]);
    }

    if (!this.entryForEdit.invitedOrPending) {
      links = links.concat([
        {
          path: {
            pathname: `${this.entryForEdit.viewUrl}/certifications`,
            state: { previousPath: this.previousPath }
          },
          label: t('Certifications')
        }
      ]);
    }

    if (this.showProjectsNotificationsTasks && !this.entryForEdit.inviteId) {
      links = links.concat([
        {
          path: {
            pathname: `${this.entryForEdit.viewUrl}/notifications`,
            state: { previousPath: this.previousPath }
          },
          label: t('Notifications')
        },
        {
          path: {
            pathname: `${this.entryForEdit.viewUrl}/tasks`,
            state: { previousPath: this.previousPath }
          },
          label: t('Tasks')
        }
      ]);
    }

    if (this.entryForEdit.id === this.me.id) {
      links = links.concat([
        {
          path: {
            pathname: `${this.entryForEdit.viewUrl}/email-preferences`,
            state: { previousPath: this.previousPath }
          },
          label: t('Email preferences')
        }
      ]);
    }

    return links;
  }

  @action.bound
  initJobTitleForm() {
    this.jobTitleForm = new JobTitleForm(
      {
        fields: jobTitleFormFields,
        rules: jobTitleFormRules,
        values: jobTitleFormValues
      },
      {
        options: jobTitleFormOptions,
        plugins: jobTitleFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound
  showAddNewJobTitleModal() {
    this.initJobTitleForm();
    this.showModal('NewJobTitle');
  }

  @action.bound
  async hideAddNewJobTitleModal() {
    await this.hideActiveModal();
    this.jobTitleForm = null;
  }

  @action.bound
  async submitAddNewJobTitleSuccess() {
    const payload = this.jobTitleForm.values();
    try {
      const newJobTitle = await this.jobTitles.create(payload, {
        wait: true
      });
      this.entryEditForm.update({
        title: newJobTitle.name
      });
      this.hideAddNewJobTitleModal();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound
  submitAddNewJobTitleForm(e) {
    e.preventDefault();
    this.jobTitleForm.submit({
      onSuccess: this.submitAddNewJobTitleSuccess,
      onError: e => {
        console.error(this.jobTitleForm.errors());
      }
    });
  }

  @computed
  get disableSaveNewJobTitleButton() {
    return this.jobTitles.saving || this.jobTitleForm.hasError;
  }

  @action.bound async setupMemberAttributes() {
    const promises = [
      this.costCodeSelectorUI.setup(),
      this.shiftSelectorUI.setup(),
      this.classificationSelectorUI.setup(),
      this.groupSelectorUI.setup()
    ];

    try {
      await Promise.all(promises);
    } catch (error) {
      alertErrorHandler(error, this.rootStore.notificationsUI.pushError);
    }
  }

  @action.bound tearDownMemberAttributes() {
    this.shiftSelectorUI.tearDown();
    this.costCodeSelectorUI.tearDown();
    this.groupSelectorUI.tearDown();
  }

  @computed get selectedGroupUuids() {
    return this.entryEditForm
      .$('groups')
      .values()
      .map(value => value.uuid);
  }

  @computed get selectedGroupOptions() {
    return this.groupSelectorUI.options.filter(option =>
      this.selectedGroupUuids.includes(option.uuid)
    );
  }
}
