import { action, observable } from 'mobx';
import UIStore from 'stores/ui/UIStore';
import SuperAdminProject from 'stores/models/superAdmin/SuperAdminProject';

import SuperAdminProjectInfoUI from './info/SuperAdminProjectInfoUI';
import SuperAdminProjectUsersUI from './users/SuperAdminProjectUsersUI';

import history from 'utils/history';

export default class SuperAdminProjectUI extends UIStore {
  @observable activeProject;

  constructor(options) {
    super(options);
    this.activeProject = null;

    this.superAdminProjectInfoUI = new SuperAdminProjectInfoUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.superAdminProjectUsersUI = new SuperAdminProjectUsersUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound setup(id) {
    this.fetchEntry(id);
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.activeProject = null;
  }

  @action.bound async fetchEntry(uuid) {
    if (this.activeProject) return;

    let model = this.parent.projects.getByIdOrUuid(uuid);

    if (!model) {
      model = new SuperAdminProject(
        {
          uuid: uuid
        },
        {
          rootStore: this.rootStore
        }
      );
    }

    try {
      await model.fetch();

      this.setActiveProject(model);
    } catch (error) {
      history.replace('/projects');
    }
  }

  @action.bound setActiveProject(model) {
    this.activeProject = model;
  }
}
