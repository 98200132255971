import MobxReactForm from 'mobx-react-form';
import { when } from 'mobx';
import { t } from 'utils/translate';
import extendedPlugins from '../utils/extendedPlugins';

const addFormFromTemplateFormPlugins = extendedPlugins;

const addFormFromTemplateFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const addFormFromTemplateFormFields = [
  'name',
  'templateUuid',
  'formUrl',
  'initialName'
];

const addFormFromTemplateFormRules = {
  name: 'string|required|max:250|form_name_available',
  templateUuid: 'string|required'
};

const addFormFromTemplateFormLabels = {
  name: t('name')
};

class AddFormFromTemplateForm extends MobxReactForm {
  constructor(settings, options) {
    super(settings, options);
    this.rootStore = options.rootStore;

    when(
      () => this.rootStore.me.company,
      () => {
        this.$('formUrl').set(
          `ra/companies/${this.rootStore.me.company.uuid}/forms?projectUuids=${this.rootStore.projectUI.projectUuid}`
        );
      }
    );
  }
}

export {
  AddFormFromTemplateForm,
  addFormFromTemplateFormOptions,
  addFormFromTemplateFormFields,
  addFormFromTemplateFormRules,
  addFormFromTemplateFormLabels,
  addFormFromTemplateFormPlugins
};
