import request from 'axios';
import moment from 'moment';
import UIStore from 'stores/ui/UIStore';
import { computed, action } from 'mobx';

import SuperAdminUserMobileActivityDevicesUI from './SuperAdminUserMobileActivityDevicesUI';
import SuperAdminUserMobileActivityOverlapsUI from './SuperAdminUserMobileActivityOverlapsUI';
import SuperAdminUserMobileActivityPingsUI from './SuperAdminUserMobileActivityPingsUI';

import { CLEAR_DEVICE_CHEAT } from 'utils/segmentAnalytics/eventSpec';
import { callTrack } from 'utils/segmentIntegration';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

export default class SuperAdminUserMobileActivityUI extends UIStore {
  constructor(options) {
    super(options);

    this.superAdminUserMobileActivityDevicesUI = new SuperAdminUserMobileActivityDevicesUI(
      {
        parent: this,
        rootStore: this.rootStore
      }
    );

    this.superAdminUserMobileActivityOverlapsUI = new SuperAdminUserMobileActivityOverlapsUI(
      {
        parent: this,
        rootStore: this.rootStore
      }
    );

    this.superAdminUserMobileActivityPingsUI = new SuperAdminUserMobileActivityPingsUI(
      {
        parent: this,
        rootStore: this.rootStore
      }
    );
  }

  @computed
  get activeUser() {
    return this.parent.activeUser;
  }

  @action.bound deleteDeviceActivity() {
    this.showModal('DeleteModal');
  }

  @action.bound
  async confirmDeleteDeviceActivity() {
    this.saving = true;

    try {
      await request.delete(`/ra/cheaters/user/${this.activeUser.id}`);

      callTrack(CLEAR_DEVICE_CHEAT, {
        cheater_uuid: `${this.activeUser.uuid}`,
        cheater_name: `${this.activeUser.fullName}`,
        user_uuid: `${this.me.uuid}`,
        user_name: `${this.me.username}`,
        submit_date: moment().format('YYYY-MM-DD')
      });

      await this.hideActiveModal();

      this.superAdminUserMobileActivityDevicesUI.setup();
      this.superAdminUserMobileActivityOverlapsUI.setup();
      this.superAdminUserMobileActivityPingsUI.setup();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('User device data deleted')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }
}
