import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: ${props => props.theme.colors[props.color]};
  border-radius: 4px;
  color: ${props => props.theme.colors.white};
  margin-bottom: 25px;
  padding: 10px;
`;

const UL = styled.ul`
  margin: 0;
  padding: 0;
`;

const LI = styled.li`
  list-style-type: none;
  margin-bottom: 5px;
  text-transform: capitalize;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Alert = ({ messages, color }) => {
  if (!messages || !messages.length) return null;

  return (
    <Wrapper color={color}>
      <UL>
        {messages.map((item, index) => {
          return <LI key="index">{item.fieldMessage}</LI>;
        })}
      </UL>
    </Wrapper>
  );
};

Alert.defaultProps = {
  color: 'red'
};

export default Alert;
