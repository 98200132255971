import { computed } from 'mobx';
import { Model } from 'mobx-mc';

export default class EquipmentNotification extends Model {
  get restAttributes() {
    return [
      'memberName',
      'memberUuid',
      'deficiencyLogged',
      'logMissed',
      'maintenanceDue',
      'rentalEnding',
      'uuid'
    ];
  }

  idAttribute() {
    return 'uuid';
  }

  @computed get toggleValues() {
    return {
      deficiencyLogged: this.deficiencyLogged,
      logMissed: this.logMissed,
      maintenanceDue: this.maintenanceDue,
      rentalEnding: this.rentalEnding
    };
  }
}
