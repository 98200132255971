export default (url, title, width, height) => {
  const dualScreenLeft =
    window.screenLeft != undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop != undefined ? window.screenTop : window.screenY;

  const windowWidth = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : window.screen.width;

  const windowHeight = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : window.screen.height;

  const left = windowWidth / 2 - width / 2 + dualScreenLeft;
  const top = windowHeight / 2 - height / 2 + dualScreenTop;

  const newWindow = window.open(
    url,
    title,
    `scrollbars=yes,width=${width},height=${height},top=${top},left=${left}`
  );

  if (window.focus) {
    newWindow.focus();
  }

  return newWindow;
};
