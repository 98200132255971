import upperFirst from 'lodash.upperfirst';
import { computed } from 'mobx';
import { Model } from 'mobx-mc';

export default class CompanyEquipment extends Model {
  get urlRoot() {
    return `${this.rootStore.appConfig.tracking_api_url}/companies/${this.rootStore.me.company.uuid}/equipment`;
  }

  get restAttributes() {
    return [
      'uuid',
      'name',
      'category',
      'type',
      'equipmentId',
      'make',
      'model',
      'year',
      'serialNumber',
      'activeProject',
      'condition',
      'owned',
      'photo',
      'odometerType',
      'trackFuel',
      'odometerReading',
      'fuelGaugeType',
      'lifeTimeFuelConsumption',
      'deployment',
      'totalHours',
      'idleHours',
      'totalHoursAvailablePerWeek',
      'trackMileage',
      'supplier',
      'rate',
      'frequency',
      'returnDate',
      'nextAvailableDate',
      'nextMaintenance'
    ];
  }

  idAttribute() {
    return 'uuid';
  }

  @computed get formValues() {
    return {
      name: this.name,
      category: this.category,
      type: this.type,
      equipmentId: this.equipmentId,
      make: this.make,
      model: this.model,
      year: this.year,
      serialNumber: this.serialNumber,
      condition: this.condition,
      owned: this.owned,
      photo: this.photo?.contentUrl,
      endDate: this.endDate,
      deployment: this.deployment,
      totalHours: this.totalHours,
      idleHours: this.idleHours,
      totalHoursAvailablePerWeek: this.totalHoursAvailablePerWeek,
      trackMileage: this.trackMileage,
      odometerType: this.odometerType,
      odometerReading: this.odometerReading,
      trackFuel: this.trackFuel,
      fuelGaugeType: this.fuelGaugeType,
      lifeTimeFuelConsumption: this.lifeTimeFuelConsumption,
      supplier: this.supplier,
      rate: this.rate,
      frequency: this.frequency,
      returnDate: this.returnDate
    };
  }

  @computed get rateString() {
    return `$${Number(this.rate).toLocaleString('en-US')} ${
      this.frequencyString
    }`;
  }

  @computed get totalHoursString() {
    return Number(this.totalHours).toLocaleString('en-US');
  }

  @computed get conditionString() {
    return upperFirst(this.condition.toLowerCase().replace('_', ' '));
  }

  @computed get deployedToString() {
    return this.activeProject?.name;
  }

  @computed get viewUrl() {
    return `/company-settings/equipment/${this.uuid}`;
  }
}
