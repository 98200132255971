import { action, computed } from 'mobx';
import { Model } from 'mobx-mc';

export default class IntegrationConfiguration extends Model {
  get restAttributes() {
    return [
      'key',
      'type',
      'value',
      'displayName',
      'description',
      'hidden',
      'disabled'
    ];
  }

  @action.bound toggleBoolean() {
    this.value = !this.value;
  }

  @computed get formValue() {
    if (this.type === 'list') {
      return this.value.split(',').map(s => s.trim());
    }
    return this.value;
  }

  @computed get validationFormField() {
    if (this.type === 'list') {
      return this.key + '-input';
    }
    return undefined;
  }

  @computed get isList() {
    return this.type === 'list';
  }
  @computed get isText() {
    return this.type === 'string';
  }
  @computed get isSwitch() {
    return this.type === 'boolean';
  }
}
