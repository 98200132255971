import styled from 'styled-components';
import theme from '../themes/main';

const setArrowStyles = props => {
  const { placement, top, left } = props;

  switch (placement) {
    case 'left':
      return `
        right: -1.2rem;
        margin-top: -7px;
        border-width: 7px 0 7px 1.2rem;
        border-left-color: ${theme.colors.lightGrey3};
        top: 50%;
        &:after {
          right: 2px;
          top:  0;
          border-width: 7px 0 7px 1.2rem;
          border-left-color: #fff;
          margin-top: -7px;
        }
        &:before {
          content: "";
          position: absolute;
          width: 1.5rem;
          height: 1.5rem;
          transform: rotate(154deg) skew(38deg); /* Prefixes... */
          right: 1.2rem;
          top:  0;
          margin-top: -1.2rem;
          box-shadow: -5px -5px 10px -2px rgba(0, 0, 0, 0.1);
          z-index: -1;
        }
      `;
    case 'right':
      return `
        left: -1.2rem;
        margin-top: -7px;
        border-width: 7px 1.2rem 7px 0;
        border-right-color: ${theme.colors.lightGrey3};
        top: ${top || '50%'};
        &:after {
          left: 2px;
          top:  0;
          border-width: 7px 1.2rem 7px 0;
          border-right-color: #fff;
          margin-top: -7px;
        }
        &:before {
          content: "";
          position: absolute;
          width: 1.5rem;
          height: 1.5rem;
          transform: rotate(-30deg) skew(38deg); /* Prefixes... */
          left: 1.2rem;
          top:  2px;
          margin-top: -1.2rem;
          box-shadow: -5px -5px 10px -2px rgba(0, 0, 0, 0.1);
          z-index: -1;
        }
      `;
    case 'top':
      return `
        left: 50%;
        bottom: -1.2rem;
        margin-left: -7px;
        border-width: 1.2rem 7px 0;
        border-top-color: ${theme.colors.lightGrey3}};
        &:after {
          left: 0;
          bottom:  2px;
          margin-left: -7px;
          border-width: 1.2rem 7px 0;
          border-top-color: #fff;
        }
        &:before {
          content: "";
          position: absolute;
          width: 1.5rem;
          height: 1.5rem;
          transform: rotate(62deg) skew(44deg); /* Prefixes... */
          left: 0;
          bottom:  1.2rem;
          margin-left: -11px;
          box-shadow: 5px 5px 10px -2px rgba(0, 0, 0, 0.1);
          z-index: -1;
        }
      `;
    case 'bottom':
      return `
        left: ${left || '50%'};
        top: -1.2rem;
        margin-left: -7px;
        border-width: 0 7px 1.2rem;
        border-bottom-color: ${theme.colors.lightGrey3};
        &:after {
          left: 0;
          top:  2px;
          margin-left: -7px;
          border-width: 0 7px 1.2rem;
          border-bottom-color: #fff;
        }
        &:before {
          content: "";
          position: absolute;
          width: 1.5rem;
          height: 1.5rem;
          transform: rotate(64deg) skew(44deg); /* Prefixes... */
          left: 0;
          top:  1.2rem;
          margin-left: -11px;
          box-shadow: -5px -5px 10px -2px rgba(0, 0, 0, 0.1);
          z-index: -1;
        }
      `;
    default:
      return null;
  }
};

const Arrow = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  &::after,
  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }
  ${props => setArrowStyles(props)};
`;

export default Arrow;
