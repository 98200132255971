import React, { Fragment, Component } from 'react';
import { observer } from 'mobx-react';

import { Dialog, Button, Text, Box, Link } from '@raken/athens-web/lib';

import { t } from 'utils/translate';

@observer
class FeatureAccessModal extends Component {
  renderTitle = () => {
    const { uiStore } = this.props;

    if (uiStore.featureUnavailable) {
      return t('Manage account');
    }

    if (uiStore.planUpgradeRequired && uiStore.onStarterPlan) {
      return t("Let's keep going");
    }

    if (uiStore.planUpgradeRequired) {
      return t('Please upgrade');
    }

    return t('Access denied');
  };

  renderCallToAction = () => {
    const { uiStore } = this.props;

    return (
      <Box>
        <Box>
          <Text inline>
            {t('To upgrade or manage your account, please call us at ')}
          </Text>{' '}
          <Link isExternal href="tel:+1866-438-0646" dataQA={'phone'}>
            <Text bold>{'866.438.0646'}</Text>
          </Link>{' '}
          <Text inline>
            {t('or chat now to speak with a Customer Service Rep.')}
          </Text>
        </Box>

        <Box mt={5}>
          <Link
            isExternal
            href={new URL('plans', uiStore.websiteUrl)} // Creates url without double slash.
            underline="always"
            target="_blank"
            rel="noopener"
            dataQA={'www-plans-page'}
          >
            <Text>{t('View plans')}</Text>
          </Link>
        </Box>
      </Box>
    );
  };

  renderMessage = () => {
    const { uiStore } = this.props;

    if (uiStore.onStarterPlan && uiStore.planUpgradeRequired) {
      return (
        <Fragment>
          <Box mb={4}>
            <Text dataQA="feature-access">
              {t(
                'Your 15-day trial of the Performance Plan has expired. But it’s easy to sign up.'
              )}
            </Text>
          </Box>
          {this.renderCallToAction()}
        </Fragment>
      );
    }

    if (uiStore.planUpgradeRequired) {
      return (
        <Fragment>
          <Box mb={4}>
            <Text dataQA="feature-access">{uiStore.planUpgradeRequired}</Text>
          </Box>
          {this.renderCallToAction()}
        </Fragment>
      );
    }

    if (uiStore.featureUnavailable) {
      return <Fragment>{this.renderCallToAction()}</Fragment>;
    }

    return (
      <Text dataQA="feature-access">
        {t(
          'Sorry, you do not have access to this feature. Please contact your account admin.'
        )}
      </Text>
    );
  };

  renderButtons = () => {
    const { uiStore } = this.props;

    if (
      uiStore.planUpgradeRequired ||
      uiStore.onStarterPlan ||
      uiStore.featureUnavailable
    ) {
      return (
        <Fragment>
          <Button
            rakenColor="white"
            onClick={uiStore.hideFeatureAccessModal}
            dataQA="upgrade-cancel"
          >
            {t('Cancel')}
          </Button>
          <Button
            rakenColor="green"
            onClick={() => {
              window?.Intercom('show');
            }}
            dataQA="upgrade-chat-now"
          >
            {t('Chat now')}
          </Button>
        </Fragment>
      );
    }

    return (
      <Button
        dataQA="cancel-feature-access"
        onClick={uiStore.hideFeatureAccessModal}
      >
        {t('Cancel')}
      </Button>
    );
  };

  render() {
    const { uiStore } = this.props;

    return (
      <Dialog
        dataQA="feature-access"
        onClose={uiStore.hideFeatureAccessModal}
        open={uiStore.activeModal === 'featureAccess'}
        fullWidth={true}
        style={{ zIndex: '2050' }}
        disableEnforceFocus
        disableRestoreFocus
      >
        <Fragment>
          <Dialog.Title
            dataQA="feature-access-header"
            withClose
            onClose={uiStore.hideFeatureAccessModal}
          >
            {this.renderTitle()}
          </Dialog.Title>

          <Dialog.Content>{this.renderMessage()}</Dialog.Content>

          <Dialog.Actions>{this.renderButtons()}</Dialog.Actions>
        </Fragment>
      </Dialog>
    );
  }
}

export default FeatureAccessModal;
