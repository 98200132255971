import { action, computed, observable } from 'mobx';
import ProjectChildUI from './ProjectChildUI';
import { t } from 'utils/translate';
import bytesToSize from 'utils/bytesToSize';
import history from 'utils/history';

export default class ToolboxTalksAddUI extends ProjectChildUI {
  @observable talksForUploading = [];
  @observable talksForReplacing = [];

  @action.bound tearDown() {
    this.talksForUploading.clear();
    this.talksForReplacing.clear();
    this.parent.toolboxTalksAllUI.sortByLastCreated();
  }

  @action.bound cancelToolboxTalksAdd() {
    history.push({
      pathname: `${this.project.viewUrl}/toolbox-talks/all`,
      search: this.baseQueryParams
    });
  }

  @computed
  get hasTalksForUploading() {
    return this.talksForUploading.length > 0;
  }

  @computed
  get talks() {
    return this.parent.toolboxTalksAllUI.talks;
  }

  @action.bound
  handleTalkUploading = fileObj => {
    const { file } = fileObj;

    //file size validation
    if (file.size > 15728640) {
      this.rootStore.notificationsUI.pushNotification({
        title: `${t('File is too big ')} (${bytesToSize(file.size)}). ${t(
          'Limit is 15MB.'
        )}`,
        snackbar: 'error',
        icon: 'notification'
      });
      return;
    }

    //check if a talk file exists
    this.talks
      .checkIfTalkExists(file.name)
      .then(() => {
        this.showReplaceAlert(file);
      })
      .catch(() => {
        this.talksForUploading.push(file);
      });
  };

  @action.bound rejectFileType(fileName) {
    const extension = fileName.split('.').pop();
    this.rootStore.notificationsUI.pushNotification({
      title: `File of type .${extension} is not supported.`,
      snackbar: 'error',
      icon: 'notification'
    });
  }

  @action.bound
  showReplaceAlert(file) {
    this.talksForReplacing.push(file);
  }

  @action.bound
  confirmFileReplace(file) {
    this.talksForReplacing.remove(file);
    this.talksForUploading.push(file);
  }

  @action.bound
  cancelFileReplace(file) {
    this.talksForReplacing.remove(file);
  }
}
