import { action, observable, reaction, computed } from 'mobx';
import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import { t } from 'utils/translate';

const violationsReportFormPlugins = extendedPlugins;

const violationsReportFormOptions = {
  validateOnInit: true,
  validateOnChange: true
};

const violationsReportFormFields = [
  'id',
  'name',
  'projectIncludeOption',
  'projects',
  'sendReport',
  'scheduleFrequency',
  'time',
  'timeFrameType',
  'fromDate',
  'toDate',
  'actionDate',
  'workers',
  'email',
  'emails'
];

const violationsReportFormRules = {
  emails: 'array|required',
  email: 'required_without:emails|email',
  projects: 'array|required_if:projectIncludeOption,SELECTED'
};

const violationsReportFormValues = {
  id: '',
  name: '',
  projectIncludeOption: '',
  projects: [],
  timeFrameType: '',
  scheduleFrequency: '',
  sendReport: false,
  emails: [],
  email: ''
};

const violationsReportFormLabels = {
  name: t('Report name'),
  projects: t('Projects to include'),
  timeFrame: t('Time frame'),
  recurringOn: t('Recurring on'),
  emails: t('Send download link to'),
  sendReport: t('Also send report immediately')
};

class ViolationsReportForm extends MobxReactForm {
  @observable reportType;

  constructor(settings, options) {
    super(settings, options);

    this.reportType = options.reportType;

    this.cancelReactToActionDate = reaction(
      () => this.enableActionDate,
      enableActionDate => {
        if (enableActionDate) {
          this.$('actionDate').set('rules', 'required|dateCustom:YYYY-MM-DD');
        } else {
          this.$('actionDate').set('rules', '');
        }

        this.validate();
      },
      {
        fireImmediately: true
      }
    );
  }

  @action.bound
  clearTimeFrame() {
    this.update({
      timeFrameType: ''
    });
  }

  @computed get enableActionDate() {
    if (this.reportType === 'scheduled') {
      return (
        this.$('timeFrameType').value === 'WEEKLY' ||
        this.$('timeFrameType').value === 'BI_WEEKLY' ||
        this.$('timeFrameType').value === 'MONTHLY'
      );
    }

    return false;
  }
}

export {
  ViolationsReportForm,
  violationsReportFormOptions,
  violationsReportFormFields,
  violationsReportFormRules,
  violationsReportFormValues,
  violationsReportFormLabels,
  violationsReportFormPlugins
};
