import { computed } from 'mobx';
import { Model } from 'mobx-mc';
import moment from 'moment';
import { t } from 'utils/translate';

export default class ChecklistType extends Model {
  get restAttributes() {
    return [
      'uuid',
      'typeClass',
      'typeName',
      'status',
      'companyUuid',
      'createdBy',
      'author',
      'createdTimestamp',
      'updatedBy',
      'updatedTimestamp'
    ];
  }

  idAttribute() {
    return 'uuid';
  }

  @computed
  get updatedTimestampFormatted() {
    return this.formatTimestamp(this.updatedTimestamp);
  }

  @computed
  get createdTimestampFormatted() {
    return this.formatTimestamp(this.createdTimestamp);
  }

  @computed get formValues() {
    return {
      uuid: this.uuid,
      typeClass: this.typeClass,
      typeName: this.typeName
    };
  }

  formatTimestamp(timestamp) {
    var now = moment();
    var then = moment(timestamp);

    if (now.isSame(then, 'day')) {
      return t('Today at') + ' ' + then.format('h:mm A');
    }

    if (now.subtract(1, 'day').isSame(then, 'day')) {
      return t('Yesterday at') + ' ' + then.format('h:mm A');
    }

    if (now.isSame(then, 'year')) {
      return then.format('MMM D h:mm A');
    }

    return then.format('MMM D, YYYY h:mm A');
  }

  get urlRoot() {
    return `${this.rootStore.urlMicroService('toolbox')}/companies/${
      this.rootStore.me.company.uuid
    }/checklist-types`;
  }

  @computed get typeString() {
    return `${this.typeClass.name} ${this.typeName}`;
  }
}
