import React, { Component, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { observer, Observer } from 'mobx-react';

import { Skeleton } from '@raken/athens-web/lib';

import { InView } from 'react-intersection-observer';

import HorizontalScrollingContainer from './HorizontalScrollingContainer';
import Bold from './Bold';

import supportsIntersectionObserver from 'utils/supportsIntersectionObserver';

const Items = styled.div`
  align-items: center;
  display: flex;
  height: ${props => props.itemHeight / 10}rem;
  margin-top: ${props => props.marginTop || 25}px;
  position: relative;
  &:first-child {
    margin-top: 0;
  }
`;

const Item = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 5px;
  overflow: hidden;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1px;
  width: ${props => props.width / 10}rem;
  height: ${props => props.height / 10}rem;
  position: relative;
  flex-shrink: 0;
  opacity: ${props => (props.isNew ? 0.5 : 1)};
  ${props =>
    !props.disabled &&
    props.hoverState &&
    css`
      &:hover:after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 3px solid ${props => props.theme.colors.blueAccent};
        border-radius: 5px;
        content: '';
        display: block;
        background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0) 50%,
          rgba(0, 0, 0, 0.85) 100%
        );
      }
    `};
`;

const Meta = styled.div`
  box-sizing: border-box;
  font-size: 1.2rem;
  position: absolute;
  left: 0;
  bottom: 10px;
  visibility: hidden;
  z-index: 1000;
  color: white;
  width: 100%;
  padding: 0 10px;

  ${Bold} {
    margin-bottom: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${Item}:hover & {
    visibility: visible;
  }
`;

const StyledImg = styled.img`
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  border-radius: 6px;
`;

const ImageWithLoader = props => {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoaded = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    let preloadImage = new Image();
    preloadImage.addEventListener('error', () => props.setHasError(true));
    preloadImage.src = props.src;
    preloadImage.onload = handleImageLoaded;

    return () => {
      // Prevents memory leak for image that isn't loaded when you navigate away.
      preloadImage = undefined;
    };
  }, [props]);

  return isLoading ? (
    <Skeleton variant="rect" width={props.width} height={props.height} />
  ) : (
    <StyledImg {...props} />
  );
};

const ItemLoader = props => {
  const {
    item,
    itemWidth,
    itemHeight,
    onMouseDown,
    hoverState,
    disabled,
    withMeta,
    inView,
    innerRef,
    isNew
  } = props;

  const [hasError, setHasError] = useState(false);

  return (
    <Observer>
      {() => {
        if (!inView) {
          return (
            <Item
              ref={innerRef}
              key={item.id}
              width={itemWidth}
              height={itemHeight}
            />
          );
        }

        if (hasError) return null;

        return (
          <Item
            ref={innerRef}
            key={item.id}
            url={item.thumbnail || item.thumbUrl}
            width={itemWidth}
            height={itemHeight}
            onMouseDown={onMouseDown}
            hoverState={hoverState}
            disabled={disabled}
            data-qa={`div_${item.id}`}
            isNew={isNew}
          >
            <ImageWithLoader
              src={item.thumbnail || item.thumbUrl}
              width={itemWidth}
              height={itemHeight}
              setHasError={setHasError}
            />
            {withMeta && (
              <Meta>
                <Bold block>{item.fileName}</Bold>
                {item.takenOrUploaded}
              </Meta>
            )}
          </Item>
        );
      }}
    </Observer>
  );
};

@observer
export default class Carousel extends Component {
  static defaultProps = {
    items: [],
    itemWidth: 300,
    itemHeight: 200,
    withButtons: false,
    withMeta: true,
    hoverState: true,
    disableMouseWheel: false
  };

  render() {
    const {
      items,
      itemWidth,
      itemHeight,
      marginTop,
      withButtons,
      children,
      background,
      tableRowHoverBackground,
      onItemClick,
      withMeta,
      hoverState,
      disabled,
      disableMouseWheel,
      scrollOnEdges,
      uiStore
    } = this.props;

    return (
      <HorizontalScrollingContainer
        background={background}
        tableRowHoverBackground={tableRowHoverBackground}
        onNearRightEdge={this.handleChangeLoadCount}
        itemWidth={itemWidth}
        withButtons={withButtons}
        innerRef={ref => (this.container = ref)}
        scrollOnEdges={scrollOnEdges}
        fadeEdges
        disableMouseWheel={disableMouseWheel}
      >
        <Items
          marginTop={marginTop}
          itemHeight={itemHeight}
          ref={ref => (this.wrapper = ref)}
        >
          {items.map((item, index) => {
            if (supportsIntersectionObserver()) {
              return (
                <InView key={index} triggerOnce>
                  {({ inView, ref, entry }) => (
                    <ItemLoader
                      key={item.id}
                      item={item}
                      itemWidth={itemWidth}
                      itemHeight={itemHeight}
                      onMouseDown={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        onItemClick(index);
                      }}
                      disabled={disabled}
                      hoverState={hoverState}
                      innerRef={ref}
                      withMeta={withMeta}
                      inView={inView}
                      uiStore={uiStore}
                      isNew={item.isNew}
                    />
                  )}
                </InView>
              );
            } else {
              return (
                <ItemLoader
                  key={item.id}
                  item={item}
                  itemWidth={itemWidth}
                  itemHeight={itemHeight}
                  onMouseDown={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    onItemClick(index);
                  }}
                  disabled={disabled}
                  hoverState={hoverState}
                  withMeta={withMeta}
                  inView={true}
                  uiStore={uiStore}
                  isNew={item.isNew}
                />
              );
            }
          })}
        </Items>
        {children}
      </HorizontalScrollingContainer>
    );
  }
}
