import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Align, PillButton, LinkText } from '.';
import media from '../utils/mediaTemplate';
import { observer } from 'mobx-react';

import { Portal } from 'react-overlays';

import styled, { css } from 'styled-components';
import { fadeInDown, fadeOutUp } from './utils/Animations';
import { t } from 'utils/translate';

const Wrapper = styled.div`
  align-items: center;
  box-sizing: border-box;
  animation: ${props =>
    props.hiding
      ? css`
          ${fadeOutUp} 0.5s linear;
        `
      : css`
          ${fadeInDown} 0.25s linear;
        `};
  width: 80%;
  padding: 25px;
  position: fixed;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, ${props => (props.applyOpacity ? 0.95 : 1)});
  border-radius: 16px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  outline: 0;
  z-index: 99999;
  ${media.desktop`
    display: flex;
    width: 690px;
    top: 20px;
  `};
`;

const Content = styled.div`
  text-align: center;
  ${media.desktop`
    display: inline-block;
    width: 500px;
    margin: 0;
    padding: 6px;
    text-align: left;
  `};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
  ${media.desktop`
    width: 500px;
    width: auto;
    margin-top: 0px;
  `};
`;

const ClearAction = styled.div`
  font-size: 1.2rem;
  text-align: center;
  margin-top: 10px;
  white-space: nowrap;
`;

@observer
export default class Toast extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onUndo: PropTypes.func,
    onDismiss: PropTypes.func,
    onDismissAll: PropTypes.func,
    showUndo: PropTypes.bool
  };

  static defaultProps = {
    show: false,
    showUndo: true
  };

  componentDidMount() {
    this.handleStartTimer();
  }

  handleUndo = () => {
    clearTimeout(this.timer);
    this.props.onUndo.call(null);
  };

  handleDismiss = () => {
    clearTimeout(this.timer);
    this.props.onDismiss.call(null);
  };

  handleDismissAll = () => {
    clearTimeout(this.timer);
    this.props.onDismissAll.call(null);
  };

  handleClearTimer = () => {
    clearTimeout(this.timer);
  };

  handleStartTimer = () => {
    if (this.props.timer) {
      clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        this.handleDismiss();
      }, this.props.timer);
    }
  };

  render() {
    const { hiding, notificationCount, showUndo } = this.props;

    return (
      <Portal container={document.body}>
        <Wrapper
          hiding={hiding}
          onMouseOver={this.handleClearTimer}
          onMouseOut={this.handleStartTimer}
          applyOpacity={
            document.body.classList.contains('body--blur') ? false : true
          }
        >
          <Content>{this.props.children}</Content>
          <Align right vertical>
            <ButtonContainer>
              {showUndo && (
                <PillButton
                  color="rakenOrange"
                  size="small"
                  onClick={this.handleUndo}
                  data-qa="button_toast-undo"
                >
                  {t('Undo')}
                </PillButton>
              )}
              <PillButton
                color="lightGrey1"
                marginLeft={20}
                size="small"
                onClick={this.handleDismiss}
                data-qa="button_toast-dismiss"
              >
                {t('Dismiss')}
              </PillButton>
            </ButtonContainer>

            {notificationCount > 0 && (
              <ClearAction>
                {'+'} {notificationCount} {t('more notifications.')}{' '}
                <LinkText
                  onClick={this.handleDismissAll}
                  data-qa="link_toast-dismiss-all"
                  color="rakenOrange"
                  underline
                >
                  {t('Dismiss All')}
                </LinkText>
              </ClearAction>
            )}
          </Align>
        </Wrapper>
      </Portal>
    );
  }
}
