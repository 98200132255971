import request from 'axios';
import { action, when } from 'mobx';
import ProjectChildUI from './ProjectChildUI';
import history from 'utils/history';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';

export default class UnsignUI extends ProjectChildUI {
  @action.bound setup() {
    when(
      () => !this.parent.fetchingReportSummary,
      () => {
        if (!this.enableUnsignRoute) {
          this.cancelUnsignReportModal();
        }
      }
    );
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.saving = false;
  }

  @action.bound async showUnsignReportModal() {
    await this.authorization.checkFeatureAccess('VerifyReport');

    const pathname = history.location.pathname;

    history.push({
      pathname: pathname,
      search: `${this.baseQueryParams}&action=unsign`
    });
  }

  @action.bound async cancelUnsignReportModal() {
    const pathname = history.location.pathname;

    history.push({
      pathname: pathname,
      search: this.baseQueryParams
    });
  }

  @action.bound async unsignReport() {
    this.saving = true;

    try {
      const response = await request.post(
        `ra/companies/${this.company.uuid}/reports/unsign`,
        {
          reports: [{ ...this.reportParams }]
        }
      );

      this.reportSummary.set(response.data.collection[0]);

      this.cancelUnsignReportModal();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Report unsigned')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }

    return true;
  }
}
