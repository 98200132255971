import request from 'axios';
import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import { observable, action, computed, runInAction, reaction } from 'mobx';

import debounce from 'lodash.debounce';

import { BASE_DEBOUNCE } from 'fixtures/constants';

import UIStore from './UIStore';
import Users from 'stores/collections/Users';
import ProjectDirectoryTeamMembersAddUI from './ProjectDirectoryTeamMembersAddUI';

import { t } from 'utils/translate';
import { callTrack } from 'utils/segmentIntegration';
import { TEAM_MEMBER_REMOVED } from 'utils/segmentAnalytics/eventSpec';
import errorHandler from 'utils/errorHandler';
import history from 'utils/history';

export default class ProjectDirectoryTeamMembersUI extends UIStore {
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;

  @observable selectedMember;

  constructor(options) {
    super(options);

    this.loading = true;
    this.sortField = 'firstName';
    this.sortDirection = 'asc';
    this.searchQuery = '';
    this.pageSize = 20;
    this.page = 1;

    this.selectedMember = null;

    this.fetchMembersDebounced = debounce(this.fetchMembers, BASE_DEBOUNCE);

    // Standalone Users collection
    this.users = new Users(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.projectDirectoryTeamMembersAddUI = new ProjectDirectoryTeamMembersAddUI(
      {
        parent: this,
        rootStore: this.rootStore
      }
    );
  }

  @computed get members() {
    return this.users;
  }

  @action.bound setup() {
    this.fetchMembers();
    this.setupReactions();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions(listRef) {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchMembersDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @action.bound
  async fetchMembers() {
    this.workers.cancelRequest();
    this.members.cancelRequest();
    this.members.clear();

    await this.members.fetch({
      params: pickBy(this.params, identity)
    });

    this.loading = false;
  }

  @computed get hasMembers() {
    return this.members.length > 0;
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @computed get additionalSortFields() {
    if (this.sortField === 'updatedTimestamp') {
      return 'createdTimestamp,firstName,lastName';
    }

    if (this.sortField === 'lastName') {
      return 'firstName';
    } else if (this.sortField === 'firstName') {
      return `lastName`;
    }

    return 'firstName,lastName';
  }

  @computed get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      sortField: `${this.sortField},${this.additionalSortFields}`,
      sortDirection: this.sortDirection,
      query: this.searchQuery,
      status: 'ACTIVE, INVITED',
      role: 'ROLE_ACCOUNT_ADMIN,ROLE_ADMIN,ROLE_PROJECT_MEMBER,ROLE_USER',
      projectUuids: this.project?.uuid
    };
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @action sortByLastUpdated() {
    this.sortField = 'updatedTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchMembersDebounced();
  }

  @action sortByLastCreated() {
    this.sortField = 'createdTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchMembersDebounced();
  }

  @computed
  get totalPages() {
    return Math.ceil(this.members.totalElements / this.pageSize);
  }

  @action.bound clearUIState() {
    this.searchQuery = '';
    this.members.clear();
    this.page = 1;
    this.loading = true;
    this.sortField = 'firstName';
    this.sortDirection = 'asc';
    this.selectedMember = null;
  }

  @computed get showEmptyState() {
    return !this.loading && !this.searchQuery && !this.hasMembers;
  }

  @computed get showEmptySearchState() {
    return !this.loading && this.searchQuery && !this.hasMembers;
  }

  @computed get showTable() {
    return !this.showEmptyState && !this.showEmptySearchState;
  }

  @computed get removeMemberTitle() {
    return t('Remove team member from project?');
  }

  @computed get removeMemberText() {
    return t('This team member will not longer be able to access the project.');
  }

  canRemoveMember(member) {
    if (!this.project.isActive) return false;

    // project member and admin can remove collaborators
    if (
      (this.rootStore.me.isProjectMember || this.rootStore.me.isAdmin) &&
      !member.isTeamMember
    ) {
      return true;
    }

    // Admin can remove anyone
    if (this.me.isAdmin) {
      return true;
    }

    // User can remove themselves
    if (member.id === this.rootStore.me.id) {
      return true;
    }

    return false;
  }

  @action.bound async removeMemberFromProject(member) {
    if (!this.canRemoveMember(member)) {
      this.authorization.showModal('featureAccess');
      return;
    }

    this.selectedMember = member;

    this.showModal('RemoveMemberModal');
  }

  @action.bound async cancelRemoveMemberFromProject() {
    await this.hideActiveModal();
    this.selectedMember = null;
  }

  @action.bound async confirmRemoveMemberFromProject() {
    try {
      this.saving = true;

      await this.selectedMember.destroy(
        {
          url: `${this.rootStore.users.url()}/${
            this.selectedMember.uuid
          }/projects/${this.project.uuid}`
        },
        { wait: true }
      );

      this.loading = true;

      if (!this.hasMembers) {
        this.setPage(null, 1);
        this.fetchMembers();
      } else {
        this.fetchMembers();
      }

      await this.hideActiveModal();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Member removed from project')
      });

      callTrack(TEAM_MEMBER_REMOVED, {
        email: this.selectedMember.username,
        first_name: this.selectedMember.firstName,
        last_name: this.selectedMember.lastName
      });

      this.refetchSubscription();

      if (this.selectedMember.isMe) {
        history.push('/projects');
      }

      this.selectedMember = null;
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  refetchSubscription() {
    // Refetch the subscription
    this.subscription.fetch({
      params: {
        mn: 'full',
        mc: 'full'
      }
    });
  }

  @action.bound
  async showAddProjectMembersModal() {
    await this.authorization.checkFeatureAccess('InviteProjectMembers');

    history.push(`${this.project.viewUrl}/directory/team/add`);
  }

  @action.bound
  async resendInvite(member) {
    await this.authorization.checkFeatureAccess('InviteProjectMembers');

    try {
      await request.post(`/ra/invite/${member.inviteId}`, {
        inviteResend: true,
        projectId: this.project.id
      });

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Invite sent')
      });
    } catch (error) {
      errorHandler(error, this.rootStore.notificationsUI.pushError);
    }
  }

  @action.bound async downloadProjectDirectory() {
    await this.authorization.checkFeatureAccess('DownloadMemberDirectory');

    window.open(this.project.memberDirectoryLink);
  }
}
