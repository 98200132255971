import { observable, action, computed } from 'mobx';
import errorHandler from 'utils/errorHandler';
import matchModelField from 'utils/matchModelField';
import orderBy from 'lodash.orderby';

import UIStore from './UIStore';

export default class ProjectSettingsShiftsUI extends UIStore {
  @observable searchQuery;
  @observable entryForEdit;
  @observable entryEditForm;
  @observable pageSize;
  @observable page;
  @observable sortDirection;
  @observable sortField;

  constructor(options) {
    super(options);

    this.uploadProgress = 0;

    // Editing
    this.entryForEdit = null;
    this.entryEditForm = null;

    // Search & Pagination
    this.searchQuery = '';
    this.pageSize = 20;
    this.page = 0;
  }

  @action.bound clearUIState() {
    this.searchQuery = '';
    this.page = 0;
  }

  @computed
  get project() {
    return this.parent.project;
  }

  @computed
  get shifts() {
    return this.rootStore.shifts;
  }

  @computed
  get changed() {
    return this.saving;
  }

  @computed get hasShifts() {
    return this.shifts.hasModels;
  }

  @action.bound
  async fetchShifts() {
    try {
      await this.shifts.fetch({
        params: {
          limit: 10000
        },
        add: true,
        remove: false,
        update: true
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  @computed get sortedShifts() {
    return orderBy(
      this.shifts.models,
      [shift => shift.new, shift => shift.name.toLowerCase()],
      ['desc', this.sortDirection]
    );
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 0;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 0;
  }

  @computed get searchedShifts() {
    if (!this.searchQuery) {
      return this.sortedShifts;
    }

    const query = this.searchQuery.toLowerCase();

    return this.sortedShifts.filter(shift => {
      return (
        matchModelField(shift, query, 'name') ||
        matchModelField(shift, query, 'code')
      );
    });
  }

  @computed get hasSearchedShifts() {
    return this.searchedShifts.length > 0;
  }

  @computed get paginatedShifts() {
    return this.searchedShifts.slice(
      this.page * this.pageSize,
      this.page * this.pageSize + this.pageSize
    );
  }

  @computed
  get totalPages() {
    return Math.ceil(this.searchedShifts.length / this.pageSize);
  }

  @action.bound
  setPage(page) {
    this.page = page.selected;
  }

  @action.bound
  sortByColumn() {
    if (this.sortDirection === 'asc') {
      this.sortDirection = 'desc';
    } else {
      this.sortDirection = 'asc';
    }
  }

  @action.bound
  toggleShiftForProject(shift) {
    if (!shift.assignedToCurrentProject) {
      const shiftIndex = shift.hiddenProjects
        .map(item => item.uuid)
        .indexOf(this.project.uuid);

      shift.hiddenProjects.splice(shiftIndex, 1);
    } else {
      shift.hiddenProjects.push({ uuid: this.project.uuid });
    }

    try {
      shift.save();
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }
}
