import { RakenCollection } from '../../lib';
import SuperAdminUser from '../../models/superAdmin/SuperAdminUser';

export default class SuperAdminUsers extends RakenCollection {
  model() {
    return SuperAdminUser;
  }

  url() {
    return 'ra/sadmin/users';
  }
}
