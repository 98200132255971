import orderBy from 'lodash.orderby';
import { observable, action, computed, reaction } from 'mobx';

import Projects from 'stores/collections/Projects';
import Groups from 'stores/collections/groups/Groups';
import UIStore from '../UIStore';
import InsightsDailyLogsUI from './InsightsDailyLogsUI';
import InsightsSafetyUI from './InsightsSafetyUI';
import InsightsQualityControlUI from './InsightsQualityControlUI';
import InsightsTasksUI from './InsightsTasksUI';

import escaperegexp from 'lodash.escaperegexp';
import { t } from 'utils/translate';
import timeFrameToStartDay from 'utils/timeFrameToStartDay';
import timeFrameToEndDay from 'utils/timeFrameToEndDay';

import {
  TimeFrameForm,
  timeFrameFormOptions,
  timeFrameFormFields,
  timeFrameFormRules,
  timeFrameFormLabels,
  timeFrameFormPlugins,
  timeFrameFormValues
} from 'forms/timeFrame';

import errorHandler from 'utils/errorHandler';
import groupBy from 'lodash.groupby';

export default class InsightsUI extends UIStore {
  @observable loading;

  // All charts
  @observable includeInactiveProjects;
  @observable applySelectionsToAllCharts;

  // Global Selection
  @observable projectSelection;
  @observable selectedProjects;
  @observable selectedGroups;
  @observable projectSearch;
  @observable groupSearch;
  @observable timeFrameSelection;
  @observable fromDate;
  @observable toDate;

  // Mobile Nav
  @observable mobileNavOpen;

  constructor(options) {
    super(options);

    this.loading = true;

    this.includeInactiveProjects = false;
    this.applySelectionsToAllCharts = true;

    // Global Selection
    this.timeFrameSelection = 'THIS_MONTH';
    this.fromDate = null;
    this.toDate = null;
    this.projectSelection = 'ACTIVE';
    this.selectedProjects = [];
    this.selectedGroups = [];
    this.projectSearch = '';
    this.groupSearch = '';

    // Mobile Nav
    this.mobileNavOpen = false;

    // Projects
    this.projectsCollection = new Projects(null, {
      parent: this,
      rootStore: this.rootStore
    });

    // Groups
    this.groupsCollection = new Groups(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.insightsDailyLogsUI = new InsightsDailyLogsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.insightsSafetyUI = new InsightsSafetyUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.insightsQualityControlUI = new InsightsQualityControlUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.insightsTasksUI = new InsightsTasksUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @computed get projects() {
    return this.projectsCollection;
  }

  @computed get hasProjects() {
    return this.projects.hasModels;
  }

  @computed get groups() {
    return this.groupsCollection;
  }

  @computed get hasGroups() {
    return this.groups.hasModels;
  }

  @action.bound async setup() {
    await Promise.all([
      this.projects.fetch({
        params: {
          limit: 10000,
          projectStates: 'ACTIVE'
        }
      }),
      this.groups.fetch({
        params: {
          limit: 10000,
          status: 'ACTIVE'
        }
      })
    ]);

    this.setupReactions();

    this.loading = false;
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.setDefaultSelections();
    this.projects.reset();
    this.loading = true;
  }

  @action.bound setupReactions() {
    this.reactToIncludeInactiveProjects = reaction(
      () => this.includeInactiveProjects,
      async includeInactiveProjects => {
        if (includeInactiveProjects) {
          if (!this.projects.fetchedInactive) {
            await this.fetchNonActiveProjects('INACTIVE');
          }
        }
      }
    );
  }

  @action.bound tearDownReactions() {
    this.reactToIncludeInactiveProjects &&
      this.reactToIncludeInactiveProjects();
  }

  @computed get secondaryLinks() {
    const links = [
      {
        label: t('Daily logs'),
        path: {
          pathname: `/insights/daily-logs`
        }
      },
      {
        label: t('Safety'),
        path: {
          pathname: `/insights/safety`
        }
      },
      {
        label: t('Quality control'),
        path: {
          pathname: `/insights/quality-control`
        }
      },
      {
        label: t('Tasks'),
        path: {
          pathname: `/insights/tasks`
        }
      }
    ];

    return links;
  }

  @computed get mobileSecondaryLinks() {
    return this.secondaryLinks.map(link => {
      return {
        id: link.path.pathname,
        title: link.label
      };
    });
  }

  @action.bound
  openMobileNav() {
    this.mobileNavOpen = true;
  }

  @action.bound
  closeMobileNav() {
    this.mobileNavOpen = false;
  }

  @action.bound
  fetchNonActiveProjects(projectState) {
    if (
      (projectState === 'DELETED' && !this.projects.fetchedDeleted) ||
      (projectState === 'INACTIVE' && !this.projects.fetchedInactive)
    ) {
      return this.projects
        .fetch({
          add: true,
          remove: false,
          update: false,
          params: {
            limit: 10000,
            mn: 'full',
            mc: 'full',
            projectStates: projectState
          }
        })
        .then(() => {
          if (projectState === 'DELETED') {
            this.projects.setFetchedDeleted();
          } else {
            this.projects.setFetchedInactive();
          }
        })
        .catch(error => {
          errorHandler(error, this.notifications.pushError);
        });
    } else {
      this.projects.cancelRequest();
    }
  }

  @action.bound setDefaultSelections() {
    // Default selections
    this.projectSelection = 'ACTIVE';
    this.projectSearch = '';
    this.groupSearch = '';
  }

  @action.bound
  setTimeFrameSelection(selection, fromDate, toDate) {
    this.timeFrameSelection = selection;
    this.fromDate = fromDate;
    this.toDate = toDate;
  }

  @action.bound
  setProjectSelection(selection) {
    this.selectedProjects.clear();
    this.selectedGroups.clear();

    if (selection === 'ALL') {
      this.includeInactiveProjects = true;
    } else {
      this.includeInactiveProjects = false;
    }

    this.projectSelection = selection;
  }

  @action.bound
  toggleProject(project) {
    if (this.selectedProjects.includes(project)) {
      this.selectedProjects.remove(project);
    } else {
      this.selectedProjects.push(project);
    }

    this.setDefaultSelectionsOnAllCharts();
  }

  @action.bound
  toggleGroup(group) {
    if (this.selectedGroups.includes(group)) {
      this.selectedGroups.remove(group);
    } else {
      this.selectedGroups.push(group);
    }

    this.setDefaultSelectionsOnAllCharts();
  }

  @action.bound setDefaultSelectionsOnAllCharts() {
    this.insightsDailyLogsUI.setDefaultSelectionsOnAllCharts();
    this.insightsTasksUI.setDefaultSelectionsOnAllCharts();
  }

  @action.bound
  setProjectSearch(value) {
    this.projectSearch = value;
  }

  @action.bound
  clearProjectSearch() {
    this.projectSearch = '';
  }

  @computed
  get searchedProjects() {
    const searchExpression = new RegExp(escaperegexp(this.projectSearch), 'i');

    return this.selectableProjects.filter(project => {
      return project.name.search(searchExpression) !== -1;
    });
  }

  @action.bound
  setGroupSearch(value) {
    this.groupSearch = value;
  }

  @action.bound
  clearGroupSearch() {
    this.groupSearch = '';
  }

  @computed
  get searchedGroups() {
    const searchExpression = new RegExp(escaperegexp(this.groupSearch), 'i');

    return this.groups.models.filter(group => {
      return (
        group.groupName.search(searchExpression) !== -1 ||
        group.groupClass.name.search(searchExpression) !== -1
      );
    });
  }

  @computed get searchedGroupsByClass() {
    return groupBy(this.searchedGroups, 'groupClass.name');
  }

  @computed
  get hasSelectedGroups() {
    return this.selectedGroups.length > 0;
  }

  @computed
  get selectedGroupUuids() {
    return this.selectedGroups.map(group => group.uuid);
  }

  @action.bound
  toggleIncludeInactiveProjects() {
    this.includeInactiveProjects = !this.includeInactiveProjects;
  }

  @computed
  get selectableProjects() {
    if (this.includeInactiveProjects) {
      return this.projects.activeAndInactive;
    }

    return this.projects.active;
  }

  @computed
  get filteredProjects() {
    switch (this.projectSelection) {
      case 'ACTIVE':
        return this.projects.active;
      case 'ALL':
        return this.projects.activeAndInactive;
      case 'SELECTED':
        return this.selectedProjects;
      case 'SELECTED_GROUPS':
        if (!this.hasSelectedGroups) return [];

        return this.projects.models.filter(project => {
          return this.selectedGroupUuids.every(uuid =>
            project.groupUuids.includes(uuid)
          );
        });
      default:
        return this.projects.active;
    }
  }

  @computed
  get filteredProjectIds() {
    return this.filteredProjects.map(project => project.parentProjectId);
  }

  @computed
  get filteredProjectUuids() {
    return this.filteredProjects.map(project => project.uuid);
  }

  @computed
  get filteredProjectTeamUuids() {
    return [
      ...new Set(
        this.filteredProjects.map(project => project.projectTeamUuids).flat()
      )
    ];
  }

  @computed
  get hasFilteredProjects() {
    return this.filteredProjects.length > 0;
  }

  @computed
  get projectSelectionString() {
    switch (this.projectSelection) {
      case 'ACTIVE':
        return t('Active projects');
      case 'ALL':
        return t('Active & inactive projects');
      case 'SELECTED':
        return t('Selected projects');
      case 'SELECTED_GROUPS':
        return t('Selected project groups');
      default:
        return t('Active Projects');
    }
  }

  @computed
  get recentProjects() {
    return orderBy(
      this.filteredProjects,
      project => project.lastActivity || '',
      ['desc']
    ).slice(0, 5);
  }

  @computed
  get nonRecentProjects() {
    return orderBy(
      orderBy(this.filteredProjects, project => project.lastActivity || '', [
        'desc'
      ]).slice(5),
      ['name', 'asc']
    );
  }

  @computed
  get hasNonRecentProjects() {
    return this.nonRecentProjects.length > 0;
  }

  @computed
  get earliestStartDate() {
    return orderBy(this.projects.models, ['startDate'], ['asc'])[0]?.startDate;
  }

  @computed
  get startDay() {
    return timeFrameToStartDay(
      this.timeFrameSelection,
      this.earliestStartDate,
      this.fromDate
    );
  }

  @computed
  get endDay() {
    return timeFrameToEndDay(this.timeFrameSelection, this.toDate);
  }

  @action.bound
  initTimeFrameForm() {
    this.activeForm = new TimeFrameForm(
      {
        fields: timeFrameFormFields,
        rules: timeFrameFormRules,
        values: timeFrameFormValues,
        labels: timeFrameFormLabels
      },
      {
        options: timeFrameFormOptions,
        plugins: timeFrameFormPlugins,
        rootStore: this.rootStore
      }
    );
  }
}
