import { Model } from 'mobx-mc';
import { computed } from 'mobx';

export default class SubmissionSettings extends Model {
  url() {
    return `${this.rootStore.appConfig.safety_api_url}/companies/${this.rootStore.me.company.uuid}/incident-reports/establishment`;
  }

  get restAttributes() {
    return [
      'uuid',
      'businessUuid',
      'establishmentName',
      'ein',
      'state',
      'streetAddress',
      'city',
      'zip',
      'naicsCode',
      'establishmentSize',
      'annualAverageEmployees',
      'totalHoursWorked'
    ];
  }

  idAttribute() {
    return 'uuid';
  }

  get isNew() {
    return false;
  }

  @computed
  get formValues() {
    const {
      streetAddress,
      city,
      state,
      postCode
    } = this.parent.company.address;

    return {
      establishmentName: this.establishmentName,
      ein: this.ein,
      state: this.state || state,
      streetAddress: this.streetAddress || streetAddress,
      naicsCode: this.naicsCode,
      city: this.city || city,
      zip: this.zip || postCode,
      establishmentSize: this.establishmentSize,
      annualAverageEmployees: this.annualAverageEmployees,
      totalHoursWorked: this.totalHoursWorked
    };
  }
}
