import { action } from 'mobx';
import { RakenCollection } from '../lib';
import Break from '../models/Break';

export default class Breaks extends RakenCollection {
  model() {
    return Break;
  }

  url() {
    return `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/breaktypes`;
  }

  @action.bound clearNew() {
    this.models.forEach(model => {
      model.clearNew();
    });
  }
}
