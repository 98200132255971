import moment from 'moment';
import { computed, action } from 'mobx';
import { Model } from 'mobx-mc';

class SuperAdminEtlJob extends Model {
  constructor(attributes, options) {
    super(attributes, options);

    if (attributes.status === 'RUNNING') {
      this.startPoller();
    }
  }

  get restAttributes() {
    return [
      'jobRunId',
      'jobName',
      'arguments',
      'status',
      'logsLink',
      'errorLogsLink',
      'startedAt',
      'modifiedAt',
      'completedAt'
    ];
  }

  idAttribute() {
    return 'jobRunId';
  }

  @computed get statusText() {
    switch (this.status) {
      case 'SUCCEEDED':
        return 'Succeeded';
      case 'FAILED':
        return 'Failed';
      default:
        return 'Pending';
    }
  }

  @computed get statusColor() {
    switch (this.status) {
      case 'SUCCEEDED':
        return 'green';
      case 'FAILED':
        return 'red';
      default:
        return 'orange';
    }
  }

  @computed get startedAtFormatted() {
    return moment(this.startedAt).format('dddd, MMMM Do YYYY, h:mm:ss a');
  }

  @computed get completedAtFormatted() {
    return moment(this.completedAt).format('dddd, MMMM Do YYYY, h:mm:ss a');
  }

  @computed get duration() {
    const completed = moment(this.completedAt);
    const started = moment(this.startedAt);

    const diff = completed.diff(started);
    const duration = moment.duration(diff).as('milliseconds');

    return duration;
  }

  @computed get durationFormatted() {
    return moment.utc(this.duration).format('mm:ss');
  }

  @action.bound startPoller() {
    this.statusPoller = setInterval(this.pollForStatus, 30000);
  }

  @action.bound pollForStatus() {
    if (this.status !== 'RUNNING') {
      clearInterval(this.statusPoller);
    } else {
      this.fetch();
    }
  }
}

export default SuperAdminEtlJob;
