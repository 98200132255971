import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import ReactDOM from 'react-dom';

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${props =>
    props.direction === 'vertical' ? 'column' : 'row'};
  label {
    ${props =>
      props.direction === 'horizontal' &&
      css`
        margin-right: 1.5rem;
        &:last-child {
          margin-right: 0;
        }
      `} ${props =>
      props.direction === 'vertical' &&
      css`
        margin-bottom: 1.5rem;
        &:last-child {
          margin-bottom: 0;
        }
      `};
  }
  &:focus {
    outline: 0;
  }
  ${props =>
    props.stretch &&
    `
      justify-content: space-around;
      flex: 1;
  `};
`;

export default class extends Component {
  static propTypes = {
    direction: PropTypes.string.isRequired,
    stretch: PropTypes.bool
  };

  static defaultProps = {
    direction: 'horizontal',
    stretch: false
  };

  state = {
    focusedItem: 0
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.focusedItem !== null &&
      prevState.focusedItem !== this.state.focusedItem
    ) {
      this.handleFocus();
    }
  }

  handleFocus = () => {
    ReactDOM.findDOMNode(this[`item-${this.state.focusedItem}`].radio).focus();
  };

  handleInitialFocus = () => {
    this.setState({
      focus: 0
    });
  };

  handleResetFocus = e => {
    const currentTarget = e.currentTarget;

    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        this.setState({
          focusedItem: null
        });
      }
    }, 0);
  };

  handleKeyDown = e => {
    if (e.keyCode === 38) {
      e.preventDefault();

      if (this.state.focusedItem > 0) {
        this.setState({
          focusedItem: this.state.focusedItem - 1
        });
      }
    } else if (e.keyCode === 40) {
      e.preventDefault();

      if (this.state.focusedItem < this.props.children.length - 1) {
        this.setState({
          focusedItem: this.state.focusedItem + 1
        });
      }
    }
  };

  renderChildren() {
    return React.Children.map(this.props.children, (child, index) => {
      if (!child) return null;

      return React.cloneElement(child, {
        checked: child.props.value === this.props.selectedValue,
        disabled: this.props.disabled,
        ref: ref => {
          this[`item-${index}`] = ref;
        },
        onClick: e => {
          if (!this.props.disabled) {
            this.props.onClick.call(null, child.props.value, e);
          }

          this.setState({
            focusedItem: index
          });
        },
        onKeyDown: e => {
          if (e.keyCode === 32) {
            if (!this.props.disabled) {
              this.props.onClick.call(null, child.props.value, e);
            }

            this.setState({
              focusedItem: index
            });
          }
        },
        tabIndex: (() => {
          if (index === 0) {
            return 0;
          }

          return -1;
        })()
      });
    });
  }

  render() {
    return (
      <Wrapper
        onKeyDown={this.handleKeyDown}
        onBlur={this.handleResetFocus}
        onFocus={this.handleInitialFocus}
        direction={this.props.direction}
        stretch={this.props.stretch}
      >
        {this.renderChildren()}
      </Wrapper>
    );
  }
}
