import { action, observable, computed } from 'mobx';
import UIStore from 'stores/ui/UIStore';
import SuperAdminUser from 'stores/models/superAdmin/SuperAdminUser';

import SuperAdminUserInfoUI from './info/SuperAdminUserInfoUI';
import SuperAdminUserMobileActivityUI from './mobileActivity/SuperAdminUserMobileActivityUI';

import history from 'utils/history';

export default class SuperAdminUserUI extends UIStore {
  @observable activeUser;

  constructor(options) {
    super(options);
    this.activeUser = null;

    this.superAdminUserInfoUI = new SuperAdminUserInfoUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.superAdminUserMobileActivityUI = new SuperAdminUserMobileActivityUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound setup(id) {
    this.fetchEntry(id);
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.activeUser = null;
  }

  @action.bound async fetchEntry(uuid) {
    if (this.activeUser) return;

    let model = this.parent.users.getByIdOrUuid(uuid);

    if (!model) {
      model = new SuperAdminUser(
        {
          uuid: uuid
        },
        {
          rootStore: this.rootStore
        }
      );
    }

    try {
      await model.fetch();

      this.setActiveUser(model);
    } catch (error) {
      history.replace('/users');
    }
  }

  @action.bound setActiveUser(model) {
    this.activeUser = model;
  }

  @computed
  get disableImpersonateButton() {
    return Boolean(
      this.activeUser?.status !== 'ACTIVE' ||
        this.activeUser?.inviteId ||
        this.activeUser?.isWorker
    );
  }
}
