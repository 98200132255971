import styled from 'styled-components';

export default styled.div`
  border-bottom: 4px solid
    ${props => (props.active ? props.theme.colors.rakenOrange : 'transparent')};
  color: ${props => props.theme.colors.charcoalGrey};
  cursor: pointer;
  font-weight: ${props => (props.active ? '700' : '300')};
  font-size: 1.6rem;
  text-decoration: none;
  transition: color 0.25s ease-in-out;
  text-transform: capitalize;
  margin-right: 30px;
  padding-bottom: 10px;
`;
