import { action } from 'mobx';
import { RakenCollection } from '../lib';
import Material from '../models/Material';

export default class Materials extends RakenCollection {
  model() {
    return Material;
  }

  url() {
    return `${this.rootStore.urlMicroService('performanceTracking')}/materials`;
  }

  @action.bound clearNew() {
    this.models.forEach(model => {
      model.clearNew();
    });
  }
}
