import { observable, action, computed, runInAction } from 'mobx';
import UIStore from './UIStore';
import orderBy from 'lodash.orderby';
import once from 'lodash.once';
import { t } from 'utils/translate';
import errorHandler from 'utils/errorHandler';
import {
  ClassificationForm,
  classificationFormOptions,
  classificationFormFields,
  classificationFormRules,
  classificationFormLabels,
  classificationFormPlugins
} from 'forms/classification';
export default class CompanyClassificationsUI extends UIStore {
  @observable entryForEdit = null;
  @observable searchQuery = '';
  @observable sortDirection = 'asc';
  @observable pageSize = 20;
  @observable page = 0;

  constructor(options) {
    super(options);

    this.companyProductionUI = options.companyProductionUI;

    // Selecting
    this.selectedClassifications = observable([]);

    /// Instantiate the create classification form

    this.classificationForm = new ClassificationForm(
      {
        fields: classificationFormFields,
        rules: classificationFormRules,
        labels: classificationFormLabels
      },
      {
        options: classificationFormOptions,
        plugins: classificationFormPlugins
      }
    );
    this.fetchClassifications = once(this.fetchClassifications);
  }

  @action.bound clearUIState() {
    this.entryForEdit = null;
    this.searchQuery = '';
    this.selectedClassifications.clear();
    this.rootStore.classifications.clearNew();

    this.page = 0;
  }

  @action.bound
  async fetchClassifications() {
    try {
      await this.classifications.fetch({
        params: {
          limit: 10000
        }
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  @computed get classifications() {
    return this.rootStore.classifications;
  }

  @computed
  get hasClassifications() {
    return this.classifications.hasModels;
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 0;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 0;
  }

  @computed get sortedClassifications() {
    return orderBy(
      this.classifications.models,
      [
        classification => classification.new,
        classification => classification.name.toLowerCase()
      ],
      ['desc', this.sortDirection]
    );
  }

  @computed get searchedClassifications() {
    if (!this.searchQuery) {
      return this.sortedClassifications;
    }

    const query = this.searchQuery.toLowerCase();

    return this.sortedClassifications.filter(classification => {
      return classification.name.toLowerCase().indexOf(query) > -1;
    });
  }

  @computed get hasSearchedClassifications() {
    return this.searchedClassifications.length > 0;
  }

  @computed get paginatedClassifications() {
    return this.searchedClassifications.slice(
      this.page * this.pageSize,
      this.page * this.pageSize + this.pageSize
    );
  }

  @action.bound
  sortByColumn() {
    // Clear New Item at the top of list on sort.
    this.classifications.clearNew();

    if (this.sortDirection === 'asc') {
      this.sortDirection = 'desc';
    } else {
      this.sortDirection = 'asc';
    }
  }

  @computed
  get totalPages() {
    return Math.ceil(this.searchedClassifications.length / this.pageSize);
  }

  @action.bound
  setPage(page) {
    this.page = page.selected;
  }

  @computed
  get classificationFormIsInValid() {
    if (!this.classificationForm) return true;

    return Boolean(
      this.classificationForm.check('hasError') ||
        this.classificationForm.$('name').check('isPristine')
    );
  }

  @action.bound async saveNewClassification(name) {
    this.classifications
      .create({ name })
      .then(classification => {
        runInAction(() => {
          this.showClassificationCreated(classification);
        });
      })
      .catch(error => {
        errorHandler(error, this.notifications.pushError);
      });
  }

  @action.bound
  showClassificationCreated(classification) {
    classification.setAsNew();

    this.rootStore.notificationsUI.pushNotification({
      showUndo: false,
      title: t('Classification Created')
    });

    this.setPage({
      selected: 0
    });
  }

  @computed get hasSelectedClassifications() {
    return this.selectedClassifications.length > 0;
  }

  @action.bound
  toggleSelectClassification(classification) {
    if (
      this.selectedClassifications.find(uuid => uuid === classification.uuid)
    ) {
      this.selectedClassifications.remove(classification.uuid);
    } else {
      this.selectedClassifications.push(classification.uuid);
    }
  }

  @computed
  get allClassificationsSelected() {
    return (
      this.hasClassifications &&
      this.selectedClassifications.length === this.classifications.models.length
    );
  }

  @action.bound
  toggleSelectAllClassifications() {
    if (this.allClassificationsSelected) {
      this.selectedClassifications.clear();
    } else {
      this.selectedClassifications.replace(
        this.searchedClassifications.map(classification => classification.uuid)
      );
    }
  }

  @action.bound softDeleteClassification(classification) {
    const originalIndex = classification.collection.models.indexOf(
      classification
    );
    classification.collection.remove(classification);

    this.rootStore.notificationsUI.pushNotification({
      onUndo: () => {
        this.cancelDeleteClassification(classification, originalIndex);
      },
      onDismiss: () => {
        this.confirmDeleteClassification(classification);
      },
      title: t('Classification Deleted')
    });
  }

  @action.bound
  cancelDeleteClassification(classification, index) {
    this.rootStore.classifications.add(classification, {
      at: index
    });
  }

  @action.bound
  confirmDeleteClassification(classification) {
    classification.destroy();
  }

  @action.bound
  deleteSelectedClassifications() {
    const classifications = this.classifications.models.filter(classification =>
      this.selectedClassifications.includes(classification.uuid)
    );

    this.softDeleteClassifications(classifications);
    this.selectedClassifications.clear();
  }

  @action.bound softDeleteClassifications(classifications) {
    this.classifications.remove(classifications);

    this.rootStore.notificationsUI.pushNotification({
      onUndo: () => {
        this.cancelDeleteClassifications(classifications);
      },
      onDismiss: () => {
        this.confirmDeleteClassifications(classifications);
      },
      title: t('Classifications Deleted')
    });
  }

  @action.bound
  cancelDeleteClassifications(classifications) {
    this.classifications.add(classifications);
  }

  @action.bound
  confirmDeleteClassifications(classifications) {
    classifications.forEach(classification => {
      classification.destroy();
    });
  }
}
