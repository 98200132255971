import { t } from 'utils/translate';
import abbreviateNumber from '../abbreviateNumber';
import { UNLIMITED_SEATS_NUMBER } from '../subscriptionData';

const seatEstimate = (subscription, seatCount, isRecurly) => {
  if (
    (seatCount <= subscription.seatsRemaining ||
      subscription.seatsRemaining === UNLIMITED_SEATS_NUMBER) &&
    !subscription.isLegacyPlan
  )
    return null;

  if (isRecurly) {
    return recurlySeatEstimate(subscription, seatCount);
  }

  const newSeatCount = seatCount - subscription.seatsRemaining;

  return t('{seatCount} {seats} (${price} {period})', {
    templateStrings: {
      seatCount: newSeatCount,
      seats: newSeatCount > 1 ? t('additional seats') : t('additional seat'),
      price: abbreviateNumber(subscription.pricePerSeat * newSeatCount),
      period: subscription.periodType === 'ANNUAL' ? 'per year' : 'per month'
    }
  });
};

const recurlySeatEstimate = (subscription, seatCount) => {
  let nextTier;
  let unitAmount;

  if (subscription.isStandardPlan) {
    unitAmount = subscription.unitAmount;
  } else {
    nextTier = subscription.addOnTiers.find(tier => {
      return (
        seatCount + subscription.seatsInUse >= tier.startingQuantity &&
        seatCount + subscription.seatsInUse <= tier.endingQuantity
      );
    });

    if (subscription.isLegacyPlan) {
      unitAmount = nextTier.unitAmount;
    } else {
      unitAmount = nextTier.unitAmount - subscription.selectedTier.unitAmount;
    }
  }

  if (subscription.isFlatRate) {
    return t('{seatCount} {seats} (${price} {period})', {
      templateStrings: {
        seatCount:
          nextTier.endingQuantity - subscription.selectedTier.endingQuantity,
        seats:
          nextTier.endingQuantity - subscription.selectedTier.endingQuantity > 1
            ? t('additional seats')
            : t('additional seat'),
        price: abbreviateNumber(unitAmount),
        period: subscription.periodType === 'ANNUAL' ? 'per year' : 'per month'
      }
    });
  }

  const newSeatCount = seatCount - subscription.seatsRemaining;

  if (newSeatCount <= 0) return null;

  return t('{seatCount} {seats} (${price} {period})', {
    templateStrings: {
      seatCount: newSeatCount,
      seats: newSeatCount > 1 ? t('additional seats') : t('additional seat'),
      price: abbreviateNumber(unitAmount * newSeatCount),
      period: subscription.periodType === 'ANNUAL' ? 'per year' : 'per month'
    }
  });
};

const seatEstimateTooltip = (subscription, seatCount, isRecurly) => {
  if (!isRecurly || subscription.isStandardPlan) return '';

  if (subscription.isLegacyPlan) {
    return t(
      'You have reached the initial seat cap for your subscription. Adding users will result in additional charges at the rate shown. '
    );
  }

  const nextTier = subscription.addOnTiers.find(tier => {
    return (
      seatCount + subscription.seatsInUse >= tier.startingQuantity &&
      seatCount + subscription.seatsInUse <= tier.endingQuantity
    );
  });

  return t(
    'You have reached the seat limit for your current subscription. Adding users will increase your seat allowance to the next tier ({tierRange}) and result in additional charges.',
    {
      templateStrings: {
        tierRange: `${nextTier.startingQuantity} - ${nextTier.endingQuantity}`
      }
    }
  );
};

export { seatEstimate, seatEstimateTooltip };
