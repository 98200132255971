import UIStore from './UIStore';
import { action, observable, computed, reaction, runInAction } from 'mobx';
import errorHandler from 'utils/errorHandler';
import orderBy from 'lodash.orderby';

import {
  TimesheetFiltersForm,
  timesheetFiltersFormRules,
  timesheetFiltersFormFields,
  timesheetFiltersFormOptions,
  timesheetFiltersFormPlugins
} from 'forms/timesheetFilters';

import { t } from 'utils/translate';
import { callTrack } from 'utils/segmentAnalytics';

import { TIMESHEETS_FILTERS } from 'utils/segmentAnalytics/eventSpec';

export default class TimesheetFiltersUI extends UIStore {
  //worker options filters
  @observable filterWorkersQuery;
  @observable searchingForWorker;

  // Filters
  @observable projectFilters;
  @observable workerFilters;
  @observable statusFilters;
  @observable payTypeFilters;
  @observable shiftFilters;
  @observable classificationFilters;
  @observable costCodeFilters;
  @observable employeeGroupFilters;
  @observable eventOriginFilters;
  @observable timeClockStatusFilters;
  @observable violationsFilters;
  @observable noGpsFilters;

  constructor(options) {
    super(options);

    //Worker Options Filter
    this.filterWorkersQuery = '';
    this.searchingForWorker = false;

    //filters
    this.projectFilters = [];
    this.workerFilters = [];
    this.payTypeFilters = [];
    this.shiftFilters = [];
    this.classificationFilters = [];
    this.costCodeFilters = [];
    this.employeeGroupFilters = [];
    this.eventOriginFilters = [];
    this.timeClockStatusFilters = {
      title: t('All'),
      value: ''
    };
    this.statusFilters = {
      value: '',
      name: t('All')
    };
    this.violationsFilters = false;
    this.noGpsFilters = false;
  }

  @computed
  get filterOptions() {
    const orderedStatuses = orderBy(
      [
        {
          value: '',
          name: t('All')
        },
        {
          value: 'SIGNED',
          name: t('Signed')
        },
        {
          value: 'UNSIGNED',
          name: t('Unsigned')
        },
        {
          value: 'APPROVED',
          name: t('Approved')
        },
        {
          value: 'NOT_APPROVED',
          name: t('Unapproved')
        }
      ],
      status => status.name
    );

    return {
      workers: this.workerOptionsForRender,
      statuses: orderedStatuses
    };
  }

  @computed
  get eventOriginOptions() {
    return [
      {
        title: t('Kiosk'),
        value: 'KIOSK'
      },
      {
        title: t('Time clock'),
        value: 'TIME_CLOCK'
      }
    ];
  }

  @computed
  get timeClockStatusFilterOptions() {
    return [
      {
        title: t('All'),
        value: ''
      },
      {
        title: t('On the clock'),
        value: 'ON_THE_CLOCK'
      },
      {
        title: t('Abandoned'),
        value: 'ABANDONED'
      },
      {
        title: t('Completed'),
        value: 'COMPLETED'
      }
    ];
  }

  //Worker Options

  @computed
  get workerOptionsForRender() {
    if (this.workers.hasModels) {
      const workers = this.workers.models.map(worker => {
        return {
          value: worker.workerUuid,
          name: worker.workerFullName
        };
      });

      return orderBy(workers, worker => worker.name);
    }

    return [];
  }

  @action.bound
  setFilterWorkersSearchQuery(value) {
    this.searchingForWorker = true;
    this.filterWorkersQuery = value;
  }

  @action.bound
  clearFilterWorkersSearchQuery() {
    this.filterWorkersQuery = '';
  }

  @computed
  get fetchWorkersParams() {
    return {
      params: {
        sortField: 'lastName',
        sortDirection: 'ASC',
        query: this.filterWorkersQuery,
        companyUuids: this.company.uuid,
        withProjectMembershipLookup: 'false',
        status: 'ACTIVE',
        limit: 80
      }
    };
  }

  @action.bound
  fetchWorkers() {
    return this.workers
      .fetch(this.fetchWorkersParams)
      .then(() => (this.searchingForWorker = false));
  }

  fetchNextWorkerOptions = async (e, workersAutocomplete) => {
    const dropdown = e.target;
    const scrollTop = dropdown.scrollTop;
    const scrollHeight = dropdown.scrollHeight;
    const dropdownHeight = dropdown.clientHeight;

    if (scrollTop + dropdownHeight === scrollHeight) {
      this.workers.fetchNextPage()?.then(() => {
        workersAutocomplete.current.scrollTop = scrollHeight - dropdownHeight;
      });
    }
  };

  @action.bound
  initFilterForm() {
    this.activeForm = new TimesheetFiltersForm(
      {
        fields: timesheetFiltersFormFields,
        rules: timesheetFiltersFormRules,
        values: {
          projectFilters: this.projectFilters,
          workerFilters: this.workerFilters,
          payTypeFilters: this.payTypeFilters,
          shiftFilters: this.shiftFilters,
          classificationFilters: this.classificationFilters,
          costCodeFilters: this.costCodeFilters,
          employeeGroupFilters: this.employeeGroupFilters,
          eventOriginFilters: this.eventOriginFilters,
          timeClockStatusFilters: this.timeClockStatusFilters,
          statusFilters: this.statusFilters,
          violationsFilters: this.violationsFilters,
          noGpsFilters: this.noGpsFilters
        }
      },
      {
        options: timesheetFiltersFormOptions,
        plugins: timesheetFiltersFormPlugins
      }
    );
    this.setupWorkerSearchQueryReaction();
  }

  @action.bound
  terminateFilterForm() {
    this.cancelWorkerSearchQueryReaction &&
      this.cancelWorkerSearchQueryReaction();
    this.activeForm = null;
    this.workers.reset();
  }

  setupWorkerSearchQueryReaction() {
    this.cancelWorkerSearchQueryReaction = reaction(
      () => this.filterWorkersQuery,
      query => {
        this.fetchWorkers();
      },
      { delay: 1500 }
    );
  }

  getTimeSheetsFilters() {
    const filters = localStorage.getItem(`timeSheetsFilters`);
    return filters ? JSON.parse(filters) : {};
  }

  @action.bound
  syncTimesheetSettingsWithFilters() {
    const filters = this.getTimeSheetsFilters();

    this.projectFilters = filters.projectFilters || [];
    this.payTypeFilters = filters.payTypeFilters || [];
    this.shiftFilters = filters.shiftFilters || [];
    this.classificationFilters = filters.classificationFilters || [];
    this.costCodeFilters = filters.costCodeFilters || [];
    this.employeeGroupFilters = filters.employeeGroupFilters || [];
    this.eventOriginFilters = filters.eventOriginFilters || [];
    this.timeClockStatusFilters = filters.timeClockStatusFilters || '';
    this.workerFilters = filters.workerFilters || [];
    this.statusFilters = filters.statusFilters || '';
    this.violationsFilters = filters.violationsFilters || '';
    this.noGpsFilters = filters.noGpsFilters || '';
  }

  @action.bound
  persistFilters() {
    const data = {
      projectFilters: this.projectFilters,
      payTypeFilters: this.payTypeFilters,
      shiftFilters: this.shiftFilters,
      classificationFilters: this.classificationFilters,
      costCodeFilters: this.costCodeFilters,
      employeeGroupFilters: this.employeeGroupFilters,
      eventOriginFilters: this.eventOriginFilters,
      timeClockStatusFilters: this.timeClockStatusFilters,
      workerFilters: this.workerFilters,
      statusFilters: this.statusFilters,
      violationsFilters: this.violationsFilters,
      noGpsFilters: this.noGpsFilters
    };

    localStorage.setItem(`timeSheetsFilters`, JSON.stringify(data));
  }

  @action.bound
  clearPersistedFilters() {
    const data = {
      projectFilters: [],
      workerFilters: [],
      payTypeFilters: [],
      shiftFilters: [],
      classificationFilters: [],
      costCodeFilters: [],
      employeeGroupFilters: [],
      eventOriginFilters: [],
      timeClockStatusFilters: {
        title: t('All'),
        value: ''
      },
      statusFilters: {
        value: '',
        name: 'All'
      },
      violationsFilters: false,
      noGpsFilters: false
    };

    try {
      localStorage.setItem(`timeSheetsFilters`, JSON.stringify(data));
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  @action.bound async showTimesheetsFilterDialog() {
    if (this.workers.hasModels) {
      this.workers.reset();
    }
    this.showModal('timesheetsFilter');

    callTrack(TIMESHEETS_FILTERS);

    try {
      await Promise.all([
        this.fetchWorkers(),
        this.projectSelectorUI.setup(),
        this.payTypeSelectorUI.setup(),
        this.shiftSelectorUI.setup(),
        this.costCodeSelectorUI.setup(),
        this.classificationSelectorUI.setup(),
        this.groupSelectorUI.setup()
      ]);

      this.initFilterForm();
    } catch (error) {
      this.hideActiveModal();
      errorHandler(error, this.notifications.pushError);
    }
  }

  @action.bound
  handleClearAllFiltersClick() {
    this.clearPersistedFilters();
    this.clearAllFilters();
  }

  @action.bound
  clearAllFilters() {
    return this.hideActiveModal().then(() => {
      runInAction(() => {
        this.terminateFilterForm();
        this.projectFilters.clear();
        this.payTypeFilters.clear();
        this.shiftFilters.clear();
        this.classificationFilters.clear();
        this.costCodeFilters.clear();
        this.employeeGroupFilters.clear();
        this.eventOriginFilters.clear();
        this.timeClockStatusFilters = {
          title: t('All'),
          value: ''
        };
        this.workerFilters.clear();
        this.statusFilters = {
          value: '',
          name: t('All')
        };
        this.violationsFilters = false;
        this.noGpsFilters = false;
        if (this.parent) {
          this.parent.page = 1;
        }
      });
    });
  }

  @action.bound async applyAllFilters() {
    return this.hideActiveModal().then(() => {
      runInAction(() => {
        this.projectFilters.replace(
          this.activeForm.$('projectFilters').values()
        );
        this.payTypeFilters.replace(
          this.activeForm.$('payTypeFilters').values()
        );
        this.shiftFilters.replace(this.activeForm.$('shiftFilters').values());
        this.classificationFilters.replace(
          this.activeForm.$('classificationFilters').values()
        );
        this.costCodeFilters.replace(
          this.activeForm.$('costCodeFilters').values()
        );
        this.employeeGroupFilters.replace(
          this.activeForm.$('employeeGroupFilters').values()
        );
        this.eventOriginFilters.replace(
          this.activeForm.$('eventOriginFilters').values()
        );
        this.timeClockStatusFilters = this.activeForm.$(
          'timeClockStatusFilters'
        ).value;
        this.workerFilters.replace(this.activeForm.$('workerFilters').values());
        this.statusFilters = this.activeForm.$('statusFilters').values();
        this.violationsFilters = this.activeForm.$('violationsFilters').value;
        this.noGpsFilters = this.activeForm.$('noGpsFilters').value;
        this.terminateFilterForm();

        if (this.parent) {
          this.parent.page = 1;
        }

        this.persistFilters();
      });
    });
  }

  @action.bound
  async hideFilters() {
    await this.hideActiveModal();

    this.terminateFilterForm();
    this.projectSelectorUI.tearDown();
    this.payTypeSelectorUI.tearDown();
    this.shiftSelectorUI.tearDown();
    this.costCodeSelectorUI.tearDown();
    this.classificationSelectorUI.tearDown();
    this.groupSelectorUI.tearDown();
  }

  @computed
  get filtersCounter() {
    let counter = 0;
    if (this.projectFilters.length > 0) {
      counter++;
    }
    if (this.payTypeFilters.length > 0) {
      counter++;
    }
    if (this.shiftFilters.length > 0) {
      counter++;
    }
    if (this.classificationFilters.length > 0) {
      counter++;
    }
    if (this.costCodeFilters.length > 0) {
      counter++;
    }
    if (this.employeeGroupFilters.length > 0) {
      counter++;
    }
    if (this.eventOriginFilters.length > 0) {
      counter++;
    }
    if (this.timeClockStatusFilters.value) {
      counter++;
    }
    if (this.workerFilters.length > 0) {
      counter++;
    }
    if (this.statusFilters.value) {
      counter++;
    }
    if (this.violationsFilters) {
      counter++;
    }
    if (this.noGpsFilters) {
      counter++;
    }

    return counter;
  }

  @computed get hasAppliedFilters() {
    return !!this.filtersCounter;
  }
}
