import { t } from './translate';

// These is the mapping that the monolith uses
export default [
  { name: t('Monday'), value: 1 },
  { name: t('Tuesday'), value: 2 },
  { name: t('Wednesday'), value: 3 },
  { name: t('Thursday'), value: 4 },
  { name: t('Friday'), value: 5 },
  { name: t('Saturday'), value: 6 },
  { name: t('Sunday'), value: 7 }
];
