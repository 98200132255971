import { RakenCollection } from '../../lib';

import Integration from 'stores/models/integrations/Integration';

export default class SuperAdminIntegrations extends RakenCollection {
  model() {
    return Integration;
  }

  url() {
    return `${this.rootStore.urlMicroService('integrations')}/companies/${
      this.rootStore.superAdminUI.superAdminCompaniesUI.activeCompany?.uuid
    }/integrations`;
  }
}
