import UIStore from './UIStore';
import Materials from 'stores/collections/Materials';
import { action, observable, computed, reaction } from 'mobx';
import errorHandler from 'utils/errorHandler';

import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import debounce from 'lodash.debounce';

import { BASE_DEBOUNCE } from 'fixtures/constants';
import { t } from 'utils/translate';

export default class MaterialSelectorUI extends UIStore {
  @observable searchQuery;
  @observable loading;

  constructor(options) {
    super(options);

    this.loading = false;

    this.searchQuery = '';
    this.fetchMaterials = debounce(this.fetchMaterials, BASE_DEBOUNCE);

    this.materials = new Materials(null, {
      rootStore: this.rootStore
    });
  }

  @action.bound
  setup() {
    this.fetchMaterials();
    this.setupReactions();
  }

  @action.bound
  tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  @action.bound setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        this.fetchMaterials();
      }
    );
  }

  @action.bound tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @action.bound clearUIState() {
    this.materials.reset();
    this.loading = false;
    this.searchQuery = '';
  }

  @computed get options() {
    return this.materials.models.map(material => {
      return {
        uuid: material.uuid,
        name: material.name,
        unit: {
          uuid: material.unit.uuid,
          name: material.unit.name
        }
      };
    });
  }

  @computed get noOptionsText() {
    return this.loading
      ? t('Searching')
      : !this.materials.hasModels
      ? t('No results')
      : false;
  }

  @action.bound
  setSearchQuery(query) {
    this.searchQuery = query;
  }

  @action.bound
  clearSearchQuery() {
    this.searchQuery = '';
  }

  @computed get hasMoreMaterials() {
    return this.materials.totalElements > this.materials.length;
  }

  @computed get params() {
    return {
      query: this.searchQuery,
      projectUuids: this.project?.uuid,
      limit: 80,
      sortField: 'name',
      sortDirection: 'asc'
    };
  }

  @action.bound
  async fetchMaterials(options) {
    this.loading = true;

    try {
      await this.materials.fetch({
        params: pickBy(this.params, identity),
        ...options
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    } finally {
      this.loading = false;
    }
  }

  fetchNextPage = async (e, autoCompleteRef) => {
    const menu = e.target;

    const scrollTop = menu.scrollTop;
    const scrollHeight = menu.scrollHeight;
    const clientHeight = menu.clientHeight;

    if (scrollTop + clientHeight === scrollHeight) {
      this.fetchNextPageOfMaterials().then(() => {
        autoCompleteRef.current.scrollTop = scrollHeight;
      });
    }
  };

  @action.bound async fetchNextPageOfMaterials() {
    if (this.materials.fetching || !this.hasMoreMaterials) return;

    try {
      this.materials.fetch({
        params: Object.assign(pickBy(this.params, identity), {
          offset: this.materials.length
        }),
        add: true,
        remove: false,
        update: false
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }
}
