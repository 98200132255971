import omit from 'lodash.omit';
import { computed, action } from 'mobx';
import ReportEntryWithAttachments from './ReportEntryWithAttachments';
import moment from 'moment';
import { t } from 'utils/translate';

export default class EquipmentLogNoteActivity extends ReportEntryWithAttachments {
  get restAttributes() {
    return [
      'uuid',
      'reportDate',
      'hours',
      'description',
      'createdTimestamp',
      'updatedTimestamp',
      'type',
      'project',
      'attachments',
      'equipment',
      'supplier',
      'status',
      'equipmentId',
      'description',
      'hours',
      'activityViewUrl',
      'activitySegmentName'
    ];
  }

  get restAttributeDefaults() {
    return {
      activityViewUrl: '/'
    };
  }

  get urlRoot() {
    return `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/companies/${this.rootStore.me.company.uuid}/equipmentlogs/${
      this.equipmentLogUuid
    }/notes`;
  }

  idAttribute() {
    return 'uuid';
  }

  @action.bound
  parse(attributes) {
    this.parseCreatedBy(attributes);
    this.parseUpdatedBy(attributes);
    this.parseProject(attributes);
    this.parseCompany(attributes);
    this.parseAttachments(attributes);

    return {
      ...omit(attributes, [
        'attachments',
        'createdBy',
        'updatedBy',
        'project',
        'company'
      ])
    };
  }

  @computed
  get formattedDate() {
    if (this.date) {
      return moment(this.reportDate).format('YYYY-MM-DD');
    } else {
      return moment().format('YYYY-MM-DD');
    }
  }

  @computed
  get title() {
    return t('Equipment Log Note');
  }

  @computed get activityTitle() {
    return `${t('submitted an')} ${this.title}`;
  }
}
