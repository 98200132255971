import { t } from 'utils/translate';

// Example output:
// `Employee resync started (Successful: 1, Failed: 0)` if successful > 0, or
// `Employee resync failed (Successful: 0, Failed: 7)` if successful = 0
export default (operation, successfulCount, failedCount) => {
  return (
    t(operation) +
    ' ' +
    (successfulCount > 0 ? t('started') : t('failed')) +
    ' (' +
    t('Successful') +
    `: ${successfulCount}, ` +
    t('Failed') +
    `: ${failedCount}` +
    ')'
  );
};
