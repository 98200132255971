import styled, { css } from 'styled-components';
import media from '../utils/mediaTemplate';

export default styled.h1`
  color: ${props => props.theme.colors.lightGrey1};
  font-size: 24px;
  font-weight: 100;
  margin: 0;
  text-align: center;
  line-height: 1.4;
  white-space: nowrap;
  ${props =>
    props.center &&
    css`
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 15;
    `};

  ${media.tablet`
     font-size: 40px;
  `};
`;
