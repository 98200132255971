import request from 'axios';
import { action, computed, observable, when } from 'mobx';
import UIStore from '../UIStore';

import SubscriptionEditUI from 'stores/ui/billing/SubscriptionEditUI';
import alertErrorHandler from 'utils/alertErrorHandler';

import history from 'utils/history';
import { t } from 'utils/translate';
import { axiosIgnore404 } from 'utils/axiosIgnore404';

export default class SubscriptionUI extends UIStore {
  @observable loading;
  @observable empty;
  @observable loaded;

  constructor(options) {
    super(options);

    this.loading = true;
    this.empty = false;
    this.loaded = false;

    this.subscriptionEditUI = new SubscriptionEditUI({
      parent: this,
      rootStore: this.rootStore
    });

    when(() => this.showUI, () => (this.loaded = true));
  }

  @computed get billingUI() {
    return this.parent;
  }

  @computed get company() {
    return this.billingUI.company;
  }

  @computed get billingInfo() {
    return this.billingUI.billingInfo;
  }

  @computed get subscription() {
    return this.billingUI.subscription;
  }

  @computed get activeCoupon() {
    return this.billingUI.activeCoupon;
  }

  @computed get subscriptionPendingChange() {
    return this.billingUI.subscriptionPendingChange;
  }

  @action.bound setup() {
    this.fetchSubscription();
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.subscription.clear();
    this.subscriptionPendingChange.clear();
    this.loading = true;
    this.empty = false;
  }

  @action.bound async fetchSubscriptionPendingChange() {
    this.loading = true;
    try {
      await this.subscriptionPendingChange.fetch();
    } catch (error) {
      if (error.response?.status !== 404) {
        alertErrorHandler(error, this.setValidationDetails);
      } else {
        this.subscriptionPendingChange.clear();
      }
    } finally {
      this.loading = false;
    }
  }

  @action.bound async cancelSubscriptionPendingChange() {
    this.saving = true;
    try {
      await this.subscriptionPendingChange.destroy();
      this.subscriptionPendingChange.clear();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound async fetchSubscription() {
    this.loading = true;

    try {
      await this.subscription.fetch({
        url: `/ra/companies/${this.company.uuid}/billing/subscriptions`,
        params: {
          limit: 1
        },
        axios: axiosIgnore404
      });
      this.empty = this.subscription.isNew;
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
      this.empty = true;
    } finally {
      this.loading = false;
    }

    if (!this.subscription.requiresCreation) {
      await this.fetchSubscriptionPendingChange();
    }
  }

  @computed get showUI() {
    return !this.loading;
  }

  @computed get showEmptyState() {
    return this.showUI && (this.empty || this.subscription.isNew);
  }

  @action.bound editSubscription() {
    history.push(
      `/companies/${this.company.uuid}/subscription-billing/edit-subscription`
    );
  }

  @action.bound async reactivateSubscription() {
    this.saving = true;

    try {
      await request.put(`${this.subscription.url()}/reactivate`);

      this.setup();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @computed get showWorkerLicenses() {
    if (!this.isSuperAdmin && this.subscription.isFlatRate) return false;

    return Boolean(
      !this.subscription.onStarterPlan &&
        this.company.companyAddOns?.timeClockWorkerSeats > 0
    );
  }

  @computed get disableEdit() {
    return this.subscription.onTrialOrStarterPlan && !this.company.isInternal;
  }

  @computed get disableAdd() {
    return this.company.isInternal || this.subscription.onTrialOrStarterPlan;
  }

  @computed get disableCancel() {
    return (
      this.subscription.isExpired ||
      this.subscription.isCanceled ||
      this.subscription.onTrialOrStarterPlan
    );
  }

  @action.bound cancelSubscription() {
    this.showModal('CancelSubscriptionModal');
  }

  @action.bound cancelCancelSubscription() {
    this.hideActiveModal();
  }

  @action.bound async confirmCancelSubscription() {
    this.saving = true;

    try {
      await this.subscription.save(null, {
        url: `${this.subscription.url()}/cancel`,
        method: 'put'
      });

      await this.hideActiveModal();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Subscription canceled')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }
}
