import React, { Component } from 'react';
import { observer } from 'mobx-react';

import PageAccessModal from './modals/PageAccess';
import FeatureAccessModal from './modals/FeatureAccess';

@observer
class Authorization extends Component {
  render() {
    const { uiStore } = this.props;

    return (
      <div>
        <span>
          {uiStore.denyAccessToPage && <PageAccessModal uiStore={uiStore} />}
          <FeatureAccessModal uiStore={uiStore} />
        </span>
      </div>
    );
  }
}

export default Authorization;
