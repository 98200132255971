import { action, computed } from 'mobx';
import ProjectSettingsShiftsUI from 'stores/ui/ProjectSettingsShiftsUI';
import toggleArrayFieldValue from 'forms/utils/toggleArrayFieldValue';

export default class ProjectTemplateShiftsUI extends ProjectSettingsShiftsUI {
  @computed
  get companyProjectTemplateForm() {
    return this.parent.companyProjectTemplateForm;
  }

  @action.bound
  async toggleShiftForTemplate(shift) {
    toggleArrayFieldValue(
      this.companyProjectTemplateForm,
      'hiddenShifts',
      shift.uuid
    );
  }
}
