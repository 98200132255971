import { computed } from 'mobx';
import { Model } from 'mobx-mc';
import { t } from 'utils/translate';
import formatCurrency from 'utils/formatCurrency';

export default class RenewalPreview extends Model {
  url() {
    return `/ra/companies/${this.company.uuid}/billing/subscriptions/${this.subscription.id}/renewal/preview`;
  }

  get restAttributes() {
    return ['chargeInvoice', 'creditInvoices'];
  }

  @computed get company() {
    if (this.rootStore.isSuperAdmin) {
      return this.parent.company;
    }

    return this.rootStore.me.company;
  }

  @computed get subscription() {
    return this.parent.subscription;
  }

  @computed get totalWithDiscountFormatted() {
    if (!this.chargeInvoice?.total) {
      return '-';
    }
    return `${formatCurrency(this.chargeInvoice.total)}`;
  }

  @computed get discount() {
    return formatCurrency(this.chargeInvoice?.discount);
  }

  @computed get discountFormatted() {
    if (!this.chargeInvoice?.discount) return '';

    return `${t('Includes discount of')} ${formatCurrency(
      this.chargeInvoice?.discount
    )}.`;
  }

  @computed get tax() {
    return this.chargeInvoice?.tax;
  }

  @computed get taxInfo() {
    return this.chargeInvoice.taxInfo;
  }
}
