import UIStore from '../UIStore';
import { action, computed, observable } from 'mobx';
import { t } from 'utils/translate';
import history from 'utils/history';

import {
  OverTimeRuleForm,
  overTimeRuleFormFields,
  overTimeRuleFormOptions,
  overTimeRuleFormPlugins
} from 'forms/overTimeRule';

import OverTimeRule from 'stores/models/OverTimeRule';
import errorHandler from 'utils/errorHandler';

export default class OverTimeRulesUI extends UIStore {
  @observable overTimeRulesForm;
  @observable loading;

  constructor(options) {
    super(options);

    this.overTimeRulesForm = null;
    this.loading = false;
  }

  @computed
  get overTimeRulesType() {
    return [
      { id: 'DAILY', title: t('Daily overtime rules') },
      { id: 'WEEKLY', title: t('Weekly overtime rules') },
      { id: 'BOTH', title: t('Daily & weekly overtime rules') }
    ];
  }

  @computed
  get nonSelectedPayTypes() {
    const selectedPayTypeUuids = this.overTimeRulesForm
      .$('rules')
      .value.map(rule => {
        return rule.payType.uuid;
      });
    selectedPayTypeUuids.push(
      this.overTimeRulesForm.$('default').value.payType?.uuid
    );

    return this.rootStore.payTypeSelectorUI.options.filter(
      payType => !selectedPayTypeUuids.includes(payType.uuid)
    );
  }

  @action.bound
  async setUpOverTimeRules(ruleSetUuid) {
    this.loading = true;

    const requests = [this.rootStore.payTypeSelectorUI.fetchPayTypes()];
    if (!this.rootStore.companyOverTimeRulesFetched) {
      requests.push(this.parent.fetchOverTimeRules());
    }

    try {
      await Promise.all(requests);
    } catch (error) {
      errorHandler(error, this.rootStore.notificationsUI.pushError);
    }

    const ruleSet = this.parent.overTimeRules.models.find(
      rule => rule.uuid === ruleSetUuid
    );

    if (ruleSet) {
      this.ruleSet = ruleSet;
    } else {
      this.ruleSet = new OverTimeRule(
        { rules: [], type: this.overTimeRulesType[0].id },
        {
          parent: this,
          rootStore: this.rootStore,
          projectId: this.project?.uuid
        }
      );
    }

    this.initOverTimeRulesForm();
    this.loading = false;
  }

  @action.bound
  getRulesFromType(type) {
    if (type === 'DAILY') {
      return {
        ruleSet: 'string',
        rules: 'array',
        default: 'required',
        name: 'required',
        'rules[].dailyLimit': 'numeric|min:0|max:24|required'
      };
    }

    if (type === 'WEEKLY') {
      return {
        ruleSet: 'string',
        rules: 'array',
        default: 'required',
        name: 'required',
        'rules[].weeklyLimit': 'numeric|min:0|max:168|required'
      };
    }

    if (type === 'BOTH') {
      return {
        ruleSet: 'string',
        rules: 'array',
        default: 'required',
        name: 'required',
        'rules[].dailyLimit': 'numeric|min:0|max:24|required',
        'rules[].weeklyLimit': 'numeric|min:0|max:168|required'
      };
    }

    return {
      ruleSet: 'string',
      rules: 'array'
    };
  }

  @action.bound
  initOverTimeRulesForm() {
    const formValues = this.ruleSet.formValues;

    formValues.type =
      this.overTimeRulesType.find(ruleSet => ruleSet.id === formValues.type) ||
      null;

    this.overTimeRulesForm = new OverTimeRuleForm(
      {
        fields: overTimeRuleFormFields,
        rules: this.getRulesFromType(formValues.type?.id),
        values: formValues
      },
      {
        options: overTimeRuleFormOptions,
        plugins: overTimeRuleFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound
  updateOverTimeRuleType(option) {
    const formValues = {
      ...this.overTimeRulesForm.values(),
      type: option
    };

    this.overTimeRulesForm = new OverTimeRuleForm(
      {
        fields: overTimeRuleFormFields,
        rules: this.getRulesFromType(option.id),
        values: formValues
      },
      {
        options: overTimeRuleFormOptions,
        plugins: overTimeRuleFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound
  addRule() {
    this.overTimeRulesForm.update({
      rules: [
        ...this.overTimeRulesForm.$('rules').value,
        {
          order: this.overTimeRulesForm.$('rules').value.length + 1,
          payType:
            this.nonSelectedPayTypes.find(
              payType => payType.name === 'Regular Time'
            ) || this.nonSelectedPayTypes[0], // Default to regular time for the first rule if we can otherwise use the first rule in the list
          dailyLimit: '',
          weeklyLimit: ''
        }
      ]
    });
  }

  @action.bound
  deleteRule(keyToDelete) {
    this.overTimeRulesForm.update({
      rules: [
        ...this.overTimeRulesForm
          .$('rules')
          .value.filter((rule, key) => {
            return key !== keyToDelete;
          })
          .map((rule, key) => {
            return { ...rule, order: key + 1 };
          })
      ]
    });
  }

  @action.bound
  openOverTimeRulesModal() {
    this.setUpOverTimeRules();
  }

  @action.bound
  discardChangesCloseOverTimeRulesModal() {
    this.overTimeRulesForm.isPristine
      ? this.closeOverTimeRulesModal()
      : this.showModal('DiscardChangesModal');
  }

  @action.bound
  async closeOverTimeRulesModal() {
    history.push('/company-settings/production');
    await this.hideActiveModal();

    this.ruleSet = null;
    this.overTimeRulesForm = null;
  }

  @action.bound submitOverTimeRulesForm() {
    this.overTimeRulesForm.submit({
      onSuccess: this.submitOverTimeRulesFormSuccess,
      onError: this.submitOverTimeRulesError
    });
  }

  @action.bound submitOverTimeRulesError() {
    console.log(this.overTimeRulesForm.errors());
  }

  @action.bound
  async deleteRuleSet() {
    try {
      await this.ruleSet.destroy({
        wait: true
      });
    } catch (error) {
      errorHandler(error, this.rootStore.notificationsUI.pushError);
    }
  }

  @action.bound
  async saveRuleSet(values) {
    // Clean up opposite values for DAILY and WEEKLY
    if (values.type.id === 'DAILY') {
      values.rules.forEach(rule => {
        rule.weeklyLimit = '';
      });
    }

    if (values.type.id === 'WEEKLY') {
      values.rules.forEach(rule => {
        rule.dailyLimit = '';
      });
    }

    const payload = {
      rules: [
        ...values.rules,
        {
          uuid: values.default.uuid,
          order: values.rules.length + 1,
          payType: {
            uuid: values.default.payType.uuid,
            name: values.default.payType.name
          },
          default: true
        }
      ],
      name: values.name,
      type: values.type.id,
      ...(!this.ruleSet.isNew && { uuid: values.uuid })
    };

    try {
      await this.ruleSet.save(payload, {
        wait: true
      });
    } catch (error) {
      errorHandler(error, this.rootStore.notificationsUI.pushError);
    }

    this.parent.fetchOverTimeRules();
  }

  @action.bound
  async submitOverTimeRulesFormSuccess() {
    const values = this.overTimeRulesForm.values();

    await this.saveRuleSet(values);

    this.closeOverTimeRulesModal();

    this.rootStore.notificationsUI.pushNotification({
      snackbar: 'warning',
      icon: 'notification',
      title: `${t('Overtime Rules Updated ')}`
    });
  }

  @computed
  get saveButtonDisabled() {
    return (
      !this.overTimeRulesForm ||
      this.overTimeRulesForm.hasError ||
      this.overTimeRuleNameTaken ||
      this.ruleSet.saving ||
      !this.overTimeRulesForm.$('default').value
    );
  }

  @computed
  get overTimeRuleNameTaken() {
    return (
      this.overTimeRulesForm.$('name').value !== this.ruleSet.name &&
      this.parent.overTimeRules.models.find(
        rule => rule.name === this.overTimeRulesForm.$('name').value
      )
    );
  }

  @computed
  get isNameValid() {
    return (
      !this.overTimeRulesForm.$('name').hasError && !this.overTimeRuleNameTaken
    );
  }

  @computed
  get overTimeRuleNameTakenErrorMessage() {
    if (this.overTimeRuleNameTaken) {
      return t('This rule name is already taken please choose another.');
    }

    return null;
  }
}
