import { t } from 'utils/translate';
const members = [
  {
    value: 'ROLE_ACCOUNT_ADMIN',
    name: t('Account Administrator')
  },
  {
    value: 'ROLE_ADMIN',
    name: t('Project Administrator')
  },
  {
    value: 'ROLE_PROJECT_MEMBER',
    name: t('Project Member')
  },
  {
    value: 'ROLE_USER',
    name: t('User')
  }
];
export const getMemberRoles = () => members;

export const getAllRoles = () => [
  ...members,
  {
    value: 'ROLE_WORKER',
    name: t('Worker')
  }
];

export const getRoleName = role => {
  return getAllRoles().find(o => o.value === role)?.name;
};
