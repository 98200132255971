import { Model } from 'mobx-mc';
import { computed } from 'mobx';

export default class Coupon extends Model {
  get restAttributes() {
    return [
      'id',
      'code',
      'name',
      'state',
      'duration',
      'plans',
      'appliesToAllPlans',
      'createdAt',
      'discount'
    ];
  }

  @computed get formattedDiscount() {
    if (this.discount?.type === 'FIXED') {
      return '$' + this.discount.amount;
    }

    if (this.discount?.type === 'PERCENT') {
      return this.discount.percent + '%';
    }
    return '-';
  }

  @computed get formValues() {
    return {
      id: this.id,
      name: this.name,
      code: this.code
    };
  }
}
