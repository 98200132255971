import debounce from 'lodash.debounce';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import UIStore from '../../UIStore';

import ChecklistTypes from 'stores/collections/checklists/ChecklistTypes';
import ChecklistTypeAddUI from './ChecklistTypeAddUI';
import ChecklistTypeEditUI from './ChecklistTypeEditUI';

import history from 'utils/history';
import { BASE_DEBOUNCE } from 'fixtures/constants';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

import { callTrack } from 'utils/segmentIntegration';
import { CHECKLIST_TYPE_DELETED } from 'utils/segmentAnalytics/eventSpec';

export default class ChecklistTypesUI extends UIStore {
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;
  @observable selectedChecklistType;

  constructor(options) {
    super(options);

    this.loading = true;

    this.selectedChecklistType = null;

    this.sortField = 'typeClass';
    this.sortDirection = 'asc';
    this.page = 1;
    this.searchQuery = '';
    this.pageSize = 20;

    // ChecklistTypes collection
    this.checklistTypes = new ChecklistTypes(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.checklistTypeAddUI = new ChecklistTypeAddUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.checklistTypeEditUI = new ChecklistTypeEditUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchChecklistTypesDebounced = debounce(
      this.fetchChecklistTypes,
      BASE_DEBOUNCE
    );
  }

  @action.bound setup() {
    window.scrollTo(0, 0);
    this.setupReactions();
    this.fetchChecklistTypes();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchChecklistTypesDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      sortField: `${this.sortField},typeName`,
      sortDirection: this.sortDirection
    };
  }

  @action.bound async fetchChecklistTypes() {
    this.checklistTypes.cancelRequest();
    this.checklistTypes.reset();

    try {
      await this.checklistTypes.fetch({
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasChecklistTypes() {
    return this.checklistTypes.hasModels;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.checklistTypes.totalElements / this.pageSize);
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.searchQuery = '';
    this.checklistTypes.clear();
    this.page = 1;
    this.loading = true;
    this.saving = false;
    this.sortField = 'updatedTimestamp';
    this.sortDirection = 'asc';
    this.selectedChecklistType = null;
  }

  @computed get showEmptyState() {
    return !this.loading && !this.searchQuery && !this.hasChecklistTypes;
  }

  @computed get showEmptySearchState() {
    return !this.loading && this.searchQuery && !this.hasChecklistTypes;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @action.bound async addChecklistType() {
    await this.authorization.checkFeatureAccess('CUDCompanyChecklistTypes');
    history.push({
      pathname: `/company-settings/checklists/types/add`,
      search: this.baseQueryParams
    });
  }

  @action.bound async editChecklistType(checklistType) {
    await this.authorization.checkFeatureAccess('CUDCompanyChecklistTypes');
    history.push({
      pathname: `/company-settings/checklists/types/${checklistType.uuid}`,
      search: this.baseQueryParams
    });
  }

  @action.bound async deleteChecklistType(checklistType) {
    await this.authorization.checkFeatureAccess('CUDCompanyChecklistTypes');

    this.selectedChecklistType = checklistType;
    this.showModal('DeleteModal');
  }

  @action.bound async cancelDeleteChecklistType() {
    await this.hideActiveModal();

    this.selectedChecklistType = null;
  }

  @action.bound async confirmDeleteChecklistType() {
    this.saving = true;

    try {
      await this.selectedChecklistType.destroy({ wait: true });

      this.refetchChecklistTypes();

      await this.hideActiveModal();

      callTrack(CHECKLIST_TYPE_DELETED, {
        checklist_type_class: this.selectedChecklistType.typeClass.name,
        checklist_type_name: this.selectedChecklistType.typeName
      });

      this.selectedChecklistType = null;

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Checklist type deleted')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound sortByLastCreated() {
    this.sortField = 'createdTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchChecklistTypesDebounced();
  }

  @action.bound sortByLastUpdated() {
    this.sortField = 'updatedTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchChecklistTypesDebounced();
  }

  @action.bound refetchChecklistTypes() {
    this.loading = true;
    if (!this.hasChecklistTypes) {
      this.setPage(null, 1);
      this.fetchChecklistTypes();
    } else {
      this.fetchChecklistTypes();
    }
  }

  getChecklistTypeActions = checklistType => {
    return [
      {
        title: t('Edit'),
        onClick: () => {
          this.editChecklistType(checklistType);
        }
      },
      {
        title: t('Delete'),
        onClick: () => {
          this.deleteChecklistType(checklistType);
        }
      }
    ];
  };
}
