import { action, computed, observable } from 'mobx';
import UIStore from '../../UIStore';
import history from 'utils/history';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

import { callTrack } from 'utils/segmentIntegration';
import { CERTIFICATION_ADDED } from 'utils/segmentAnalytics/eventSpec';
import {
  CertificationForm,
  certificationFormFields,
  certificationFormLabels,
  certificationFormOptions,
  certificationFormPlugins,
  certificationFormRules,
  certificationFormValues
} from 'forms/certificationForm';

import Certification from 'stores/models/Certification';
import Certifications from 'stores/collections/Certifications';
import CertificationTypes from 'stores/collections/CertificationTypes';

export default class CertificationAddUI extends UIStore {
  @observable entryAddForm;

  constructor(options) {
    super(options);

    this.certifications = new Certifications(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.certificationTypes = new CertificationTypes(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.entryAddForm = null;
  }

  @action.bound async setup() {
    this.certifications.fetch({
      params: {
        limit: 10000
      }
    });

    // For dropdown selection
    this.certificationTypes.fetch({
      params: {
        limit: 10000,
        sortField: 'name'
      }
    });

    this.setupForm();
  }

  @action.bound setupForm() {
    this.entryForAdd = new Certification(
      {},
      {
        rootStore: this.rootStore
      }
    );

    this.entryAddForm = new CertificationForm(
      {
        fields: certificationFormFields,
        rules: certificationFormRules,
        labels: certificationFormLabels,
        values: certificationFormValues
      },
      {
        options: certificationFormOptions,
        plugins: certificationFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.certifications.clear();
    this.certificationTypes.clear();
    this.activeModal = null;
    this.entryForAdd = null;
    this.entryAddForm = null;
    this.saving = false;
  }

  @action.bound
  async submitCertificationAddSuccess() {
    this.saving = true;
    const { name, certificationType, type } = this.entryAddForm.trimmedValues();
    try {
      await this.entryForAdd.save(
        {
          name,
          certificationType
        },
        {
          wait: true
        }
      );

      callTrack(CERTIFICATION_ADDED, {
        certification_name: name,
        certification_type_name: type
      });

      this.parent.sortByLastCreated();
      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Checklist added')
      });
      this.cancelCertificationAdd();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  submitCertificationAdd(e) {
    e.preventDefault();
    e.stopPropagation();

    this.entryAddForm.submit({
      onSuccess: this.submitCertificationAddSuccess,
      onError: e => {
        console.error(this.entryAddForm.errors());
      }
    });
  }

  @action.bound
  cancelCertificationAdd() {
    history.push({
      pathname: `/company-settings/certifications/certifications`,
      search: this.baseQueryParams
    });
  }

  @computed get certificationTypeOptions() {
    return this.certificationTypes.models.map(certificationType => ({
      name: certificationType.name,
      uuid: certificationType.uuid
    }));
  }
}
