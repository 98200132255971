import debounce from 'lodash.debounce';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import UIStore from '../../UIStore';

import ChecklistTemplates from 'stores/collections/checklists/ChecklistTemplates';
import ChecklistTemplateStartUI from './ChecklistTemplateStartUI';
import ChecklistTemplateAddUI from './ChecklistTemplateAddUI';
import ChecklistTemplateEditUI from './ChecklistTemplateEditUI';

import history from 'utils/history';
import { BASE_DEBOUNCE } from 'fixtures/constants';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

import { callTrack } from 'utils/segmentIntegration';

import { CHECKLIST_NEW_TEMPLATE_CLICKED } from 'utils/segmentAnalytics/eventSpec';

import {
  checklistTemplateStartFormFields,
  checklistTemplateStartFormOptions,
  checklistTemplateStartFormPlugins,
  checklistTemplateStartFormRules,
  checklistTemplateStartFormLabels,
  checklistTemplateStartFormValues,
  checklistTemplateStartFormFieldOptions,
  ChecklistTemplateStartForm
} from 'forms/checklistTemplateStart';

export default class ChecklistTemplatesUI extends UIStore {
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;

  @observable selectedChecklistTemplate;
  @observable duplicateTemplateForm;

  constructor(options) {
    super(options);

    this.loading = true;

    this.selectedChecklistTemplate = null;
    this.duplicateTemplateForm = null;

    this.sortField = 'typeName';
    this.sortDirection = 'asc';
    this.page = 1;
    this.searchQuery = '';
    this.pageSize = 20;

    // ChecklistTemplates collection
    this.checklistTemplates = new ChecklistTemplates(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.checklistTemplateStartUI = new ChecklistTemplateStartUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.checklistTemplateAddUI = new ChecklistTemplateAddUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.checklistTemplateEditUI = new ChecklistTemplateEditUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchChecklistTemplatesDebounced = debounce(
      this.fetchChecklistTemplates,
      BASE_DEBOUNCE
    );
  }

  @action.bound setup() {
    window.scrollTo(0, 0);
    this.setupReactions();
    this.fetchChecklistTemplates();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchChecklistTemplatesDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      sortField: this.sortField,
      sortDirection: this.sortDirection,
      scope: 'COMPANY',
      status: 'ACTIVE,DRAFT'
    };
  }

  @action.bound async fetchChecklistTemplates() {
    this.checklistTemplates.cancelRequest();
    this.checklistTemplates.reset();

    try {
      await this.checklistTemplates.fetch({
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasChecklistTemplates() {
    return this.checklistTemplates.hasModels;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.checklistTemplates.totalElements / this.pageSize);
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.searchQuery = '';
    this.checklistTemplates.clear();
    this.page = 1;
    this.loading = true;
    this.saving = false;
    this.sortField = 'typeName';
    this.sortDirection = 'asc';
    this.selectedChecklistTemplate = null;
    this.duplicateTemplateForm = null;
  }

  @computed get showEmptyState() {
    return !this.loading && !this.searchQuery && !this.hasChecklistTemplates;
  }

  @computed get showEmptySearchState() {
    return !this.loading && this.searchQuery && !this.hasChecklistTemplates;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @action.bound async startChecklistTemplate() {
    await this.authorization.checkFeatureAccess('CUDCompanyChecklistTemplates');

    callTrack(CHECKLIST_NEW_TEMPLATE_CLICKED, {
      empty_state: this.hasChecklistTemplates ? false : true
    });

    history.push({
      pathname: `/company-settings/checklists/templates/start`,
      search: this.baseQueryParams
    });
  }

  @action.bound async previewChecklistTemplate(checklistTemplate) {
    this.checklistTemplateEditUI.togglePreviewMode();

    history.push({
      pathname: `/company-settings/checklists/templates/${checklistTemplate.uuid}`,
      search: this.baseQueryParams
    });
  }

  @action.bound async editChecklistTemplate(checklistTemplate) {
    await this.authorization.checkFeatureAccess('CUDCompanyChecklistTemplates');

    history.push({
      pathname: `/company-settings/checklists/templates/${checklistTemplate.uuid}`,
      search: this.baseQueryParams
    });
  }

  @action.bound
  async duplicateChecklistTemplate(checklistTemplate) {
    await this.authorization.checkFeatureAccess('CUDCompanyChecklistTemplates');

    this.checklistTypeSelectorUI.setup();

    this.selectedChecklistTemplate = checklistTemplate;

    this.duplicateTemplateForm = new ChecklistTemplateStartForm(
      {
        fields: checklistTemplateStartFormFields,
        rules: checklistTemplateStartFormRules,
        labels: checklistTemplateStartFormLabels,
        values: Object.assign({}, checklistTemplateStartFormValues, {
          checklistType: checklistTemplate.formValues.checklistType
        }),
        options: checklistTemplateStartFormFieldOptions
      },
      {
        options: checklistTemplateStartFormOptions,
        plugins: checklistTemplateStartFormPlugins,
        rootStore: this.rootStore
      }
    );

    this.showModal('ChecklistTemplateDuplicateModal');
  }

  @action.bound
  async cancelDuplicateChecklistTemplate() {
    await this.hideActiveModal();

    this.checklistTypeSelectorUI.tearDown();

    this.selectedChecklistTemplate = null;
    this.duplicateTemplateForm = null;
  }

  @action.bound submitDuplicateTemplateForm(event) {
    event.preventDefault();

    this.duplicateTemplateForm.submit({
      onSuccess: this.submitDuplicateTemplateFormSuccess,
      onError: this.submitDuplicateTemplateFormError
    });
  }

  @action.bound
  async submitDuplicateTemplateFormSuccess() {
    this.saving = true;

    const duplicatedChecklistTemplate = this.selectedChecklistTemplate
      .formValues;

    for (let section of duplicatedChecklistTemplate.checklistTemplateSections) {
      delete section.uuid;
      for (let question of section.checklistTemplateQuestions) {
        delete question.uuid;
      }
    }

    const payload = {
      checklistTemplateSections:
        duplicatedChecklistTemplate.checklistTemplateSections,
      name: this.duplicateTemplateForm.$('name').value,
      checklistType: this.duplicateTemplateForm.$('checklistType').value,
      status: 'DRAFT'
    };

    try {
      await this.checklistTemplates.create(payload, {
        wait: true
      });

      await this.hideActiveModal();

      this.checklistTypeSelectorUI.tearDown();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Checklist template duplicated')
      });

      this.sortByLastCreated();

      this.duplicateTemplateForm = null;
      this.selectedChecklistTemplate = null;
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitDuplicateTemplateError() {
    console.error(this.duplicateTemplateForm.errors());
  }

  @action.bound async deleteChecklistTemplate(checklistTemplate) {
    await this.authorization.checkFeatureAccess('CUDCompanyChecklistTemplates');

    this.selectedChecklistTemplate = checklistTemplate;
    this.showModal('DeleteModal');
  }

  @action.bound async cancelDeleteChecklistTemplate() {
    await this.hideActiveModal();

    this.selectedChecklistTemplate = null;
  }

  @action.bound async confirmDeleteChecklistTemplate() {
    this.saving = true;

    try {
      await this.selectedChecklistTemplate.destroy({ wait: true });

      this.refetchChecklistTemplates();

      await this.hideActiveModal();

      this.selectedChecklistTemplate = null;

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Checklist template deleted')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound sortByLastCreated() {
    this.sortField = 'createdTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchChecklistTemplatesDebounced();
  }

  @action.bound sortByLastUpdated() {
    this.sortField = 'updatedTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchChecklistTemplatesDebounced();
  }

  @action.bound refetchChecklistTemplates() {
    this.loading = true;
    if (!this.hasChecklistTemplates) {
      this.setPage(null, 1);
      this.fetchChecklistTemplates();
    } else {
      this.fetchChecklistTemplates();
    }
  }

  getChecklistTemplateActions = checklistTemplate => {
    if (!this.authorization.canCUDCompanyChecklistTemplates) {
      return [
        {
          title: t('Preview'),
          onClick: () => {
            this.previewChecklistTemplate(checklistTemplate);
          }
        }
      ];
    }

    if (checklistTemplate.status === 'DRAFT') {
      return [
        {
          title: t('Resume editing'),
          onClick: () => {
            this.editChecklistTemplate(checklistTemplate);
          }
        },
        {
          title: t('Delete'),
          onClick: () => {
            this.deleteChecklistTemplate(checklistTemplate);
          }
        }
      ];
    }

    return [
      {
        title: t('Edit'),
        onClick: () => {
          this.editChecklistTemplate(checklistTemplate);
        }
      },
      {
        title: t('Preview'),
        onClick: () => {
          this.previewChecklistTemplate(checklistTemplate);
        }
      },
      {
        title: t('Duplicate'),
        onClick: () => {
          this.duplicateChecklistTemplate(checklistTemplate);
        }
      },
      {
        title: t('Delete'),
        onClick: () => {
          this.deleteChecklistTemplate(checklistTemplate);
        }
      }
    ];
  };
}
