import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';
import trimObject from '../../utils/trimObject';

const projectTemplateTimeTrackingFormPlugins = extendedPlugins;

const projectTemplateTimeTrackingFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false,
  retrieveOnlyEnabledFields: false
};

const projectTemplateTimeTrackingFormFields = [
  'timeCardsStartAndEndTime',
  'costCodesOnTimeCards',
  'breakSetting',
  'classificationsOnTimeCards',
  'classificationsEditableOnTimeCards',
  'overtimeRuleSetUuid'
];

const projectTemplateTimeTrackingFormRules = {
  timeCardsStartAndEndTime: 'boolean'
};

const projectTemplateTimeTrackingFormDisabled = {};

const projectTemplateTimeTrackingFormLabels = {};

const projectTemplateTimeTrackingFormValues = {};

class ProjectTemplateTimeTrackingForm extends MobxReactForm {
  trimmedValues() {
    return trimObject(this.values());
  }
}

export {
  ProjectTemplateTimeTrackingForm,
  projectTemplateTimeTrackingFormOptions,
  projectTemplateTimeTrackingFormFields,
  projectTemplateTimeTrackingFormRules,
  projectTemplateTimeTrackingFormValues,
  projectTemplateTimeTrackingFormLabels,
  projectTemplateTimeTrackingFormPlugins,
  projectTemplateTimeTrackingFormDisabled
};
