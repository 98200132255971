import Text from './Text';

const H2 = Text.withComponent('h2');

H2.defaultProps = {
  lineHeight: '24px',
  size: 20,
  block: true
};

export default H2;
