import { RakenCollection } from '../lib';
import { computed } from 'mobx';
import ProjectInsightsPeriod from '../models/ProjectInsightsPeriod';

export default class ProjectInsightsPeriods extends RakenCollection {
  model() {
    return ProjectInsightsPeriod;
  }

  parse() {}

  @computed
  get teamMeasures() {
    const measures = [];

    this.models.forEach(insightsPeriod => {
      insightsPeriod.teamMeasures.models.forEach(teamMeasure => {
        measures.push(teamMeasure);
      });
    });

    return measures;
  }
}
