import React from 'react';
import { observer } from 'mobx-react';

import { Dialog, Button, Text } from '@raken/athens-web/lib';

import { t } from 'utils/translate';
import history from 'utils/history';

export default observer(({ uiStore }) => {
  return (
    <Dialog
      dataQA="super-daily"
      open={uiStore.activeModal === 'superDaily'}
      fullWidth={true}
    >
      <Dialog.Title dataQA="personal-info-header">
        {t('Super daily not enabled')}
      </Dialog.Title>

      <Dialog.Content>
        <Text>
          {t(
            'It looks like you haven’t invited any collaborators. Super Daily allows for automatic collection, collation and creation of one daily (GC + Sub dailies in one report).'
          )}
        </Text>
      </Dialog.Content>

      <Dialog.Actions>
        <Button rakenColor="white" onClick={uiStore.hideActiveModal}>
          {t('Cancel')}
        </Button>

        <Button
          icon="add"
          onClick={() => {
            uiStore.hideActiveModal().then(() => {
              history.push(
                `${uiStore.project.viewUrl}/directory/collaborators/add`
              );
            });
          }}
        >
          {t('Collaborators')}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
});
