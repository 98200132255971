import { action } from 'mobx';
import { RakenCollection } from '../lib';
import Shift from '../models/Shift';

export default class Shifts extends RakenCollection {
  model() {
    return Shift;
  }

  url() {
    return `${this.rootStore.urlMicroService('performanceTracking')}/shifts`;
  }

  @action.bound clearNew() {
    this.models.forEach(model => {
      model.clearNew();
    });
  }
}
