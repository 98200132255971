import moment from 'moment';
import { computed } from 'mobx';
import { Model } from 'mobx-mc';

export default class ProductionStatsByTimePeriodEnty extends Model {
  get restAttributes() {
    return [
      'id',
      'fromDate',
      'toDate',
      'completeHoursPercent',
      'progressHoursPercent',
      'budgetedHours',
      'actualHours',
      'projectedHoursGainOrLoss',
      'projectedTotalHours',
      'remainingHours',
      'costCodes' // Array of nested cost code entries
    ];
  }

  get restAttributeDefaults() {
    return {
      budgetedHours: 0,
      completeHoursPercent: 0,
      projectedHoursGainOrLoss: 0,
      progressHoursPercent: 0,
      projectedTotalHours: 0,
      remainingHours: 0,
      costCodes: []
    };
  }

  @computed get dateRange() {
    return `${moment(this.fromDate).format('YYYY-MM-DD')} - ${moment(
      this.toDate
    ).format('YYYY-MM-DD')}`;
  }

  getStatsByCostCodeUuid(uuid) {
    return this.costCodes.find(
      costCodeStats => costCodeStats.costCode.uuid === uuid
    );
  }

  getCostCodeStat(uuid, statName) {
    const stat = this.getStatsByCostCodeUuid(uuid);

    if (stat) {
      return stat[statName] || 0;
    }

    return 0;
  }
}
