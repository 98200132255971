import { action, computed, observable } from 'mobx';
import UIStore from 'stores/ui/UIStore';
import SuperAdminDemoAccount from 'stores/models/superAdmin/SuperAdminDemoAccount';

import {
  AddDemoForm,
  addDemoOptions,
  addDemoFields,
  addDemoRules,
  addDemoLabels,
  addDemoPlugins
} from 'forms/superAdmin/addDemo';

import history from 'utils/history';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';

export default class SuperAdminDemoAddUI extends UIStore {
  @observable entryForAdd;
  @observable entryAddForm;

  constructor(options) {
    super(options);

    this.entryForAdd = null;
    this.entryAddForm = null;
  }

  @action.bound setup() {
    this.entryForAdd = new SuperAdminDemoAccount(
      { type: 'SuperAdminDemo' },
      {
        rootStore: this.rootStore
      }
    );

    this.entryAddForm = new AddDemoForm(
      {
        fields: addDemoFields,
        rules: addDemoRules,
        values: {},
        labels: addDemoLabels
      },
      {
        options: addDemoOptions,
        plugins: addDemoPlugins
      }
    );
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound submitEntryAddForm(event) {
    event.preventDefault();
    event.stopPropagation();

    this.entryAddForm.submit({
      onSuccess: this.submitEntryAddFormSuccess,
      onError: this.submitEntryAddFormError
    });
  }

  @action.bound async submitEntryAddFormSuccess() {
    this.saving = true;

    try {
      await this.entryForAdd.save(this.entryAddForm.values(), {
        wait: true,
        stripNonRest: false,
        reset: false
      });

      this.rootStore.superAdminDemoAccounts.add(this.entryForAdd, {
        at: 0
      });

      this.parent.pollForSeedingAccounts();

      this.cancelSuperAdminDemoAdd();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Demo created')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitEntryAddFormError() {
    console.error(this.entryAddForm.errors());
  }

  @action.bound cancelSuperAdminDemoAdd() {
    history.push({
      pathname: `/demos`
    });
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();

    this.activeModal = null;
    this.entryForAdd = null;
    this.entryAddForm = null;
    this.saving = false;
  }

  @computed get disableCancelButton() {
    return Boolean(this.saving);
  }

  @computed get disableSaveButton() {
    return Boolean(this.saving);
  }

  @computed get demoTemplateOptions() {
    return this.rootStore.superAdminDemoMasterAccounts.models.map(model => {
      return {
        value: model.id,
        name: model.name
      };
    });
  }

  @computed get selectedDemoTemplate() {
    return this.demoTemplateOptions.find(
      option => option.value === this.entryAddForm.$('templateCompanyId').value
    );
  }
}
