import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';

const activityFiltersFormPlugins = extendedPlugins;

const activityFiltersFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const activityFiltersFormFields = [
  'projectFilters[]',
  'memberFilters[]',
  'projectStatusFilters[]',
  'activityFilters[]'
];

const activityFiltersFormRules = {
  projectFilters: 'array',
  memberFilters: 'array',
  projectStatusFilters: 'array',
  activityFilters: 'array'
};

class ActivityFiltersForm extends MobxReactForm {}

export {
  ActivityFiltersForm,
  activityFiltersFormRules,
  activityFiltersFormFields,
  activityFiltersFormOptions,
  activityFiltersFormPlugins
};
