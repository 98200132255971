import styled from 'styled-components';
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormControl, Input } from 'raken-ui';
import { t } from 'utils/translate';

const Empty = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.lightGrey2};
  margin-bottom: -5px;
  padding: 10px;
  position: relative;
  z-index: 1051;
`;

const SearchContainer = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.lightGrey2};
  padding: 5px 5px 10px 5px;
  position: relative;
  z-index: 1051;
`;

const EmptySearch = styled.div`
  padding: 9px;
  position: relative;
`;

/**
 * Takes a list of item and filters it according to the input.
 */
const SelectBoxAutoComplete = function(props) {
  const {
    form,
    fieldName,
    options,
    width,
    menuWidth,
    tabIndex,
    label,
    hideLabel,
    placeholder,
    optional,
    disableAsReadOnly,
    focusColor,
    capitalizePlaceholder,
    autoFocus,
    onInputChange,
    onChange,
    onClear
  } = props;

  const [hasOptions, setHasOptions] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  /**
   * Called in useEffect to check the initial options length during mount.
   * @see https://github.com/facebook/react/issues/14920#issuecomment-471070149
   */
  const hasOptionCheck = useCallback(() => {
    if (options.length > 0) {
      setHasOptions(true);
    }
  }, [options.length]);

  useEffect(() => {
    hasOptionCheck();
  }, [hasOptionCheck]);

  useEffect(() => {
    onInputChange(searchQuery);
  }, [searchQuery, onInputChange]);

  const renderHeaderOption = () => {
    const { options } = props;

    if (hasOptions) {
      return (
        <div>
          <SearchContainer>
            <Input
              placeholder={placeholder}
              onChange={e => setSearchQuery(e.target.value)}
            />
          </SearchContainer>
          {options.length <= 0 && (
            <EmptySearch>
              {t('No results for')} {`${searchQuery}.`}
            </EmptySearch>
          )}
        </div>
      );
    } else {
      return <Empty>{t('No options')}</Empty>;
    }
  };

  return (
    <FormControl
      form={form}
      fieldName={fieldName}
      focusColor={focusColor}
      placeholder={placeholder}
      label={label}
      component="selectbox"
      options={options}
      menuHeight={160}
      onClear={onClear}
      onClose={() => setSearchQuery('')}
      onChange={onChange}
      showValidIcon={false}
      noMargin
      tabIndex={tabIndex}
      width={width}
      menuWidth={menuWidth}
      optional={optional}
      hideLabel={hideLabel}
      disableAsReadOnly={disableAsReadOnly}
      capitalizePlaceholder={capitalizePlaceholder}
      autoFocus={autoFocus}
      headerOption={renderHeaderOption()}
    />
  );
};

SelectBoxAutoComplete.propTypes = {
  form: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  menuWidth: PropTypes.string,
  tabIndex: PropTypes.number,
  optional: PropTypes.string,
  hideLabel: PropTypes.bool,
  disableAsReadOnly: PropTypes.bool,
  capitalizePlaceholder: PropTypes.bool,
  autoFocus: PropTypes.bool
};

SelectBoxAutoComplete.defaultProps = {
  placeholder: t('Search'),
  width: '100%',
  menuWidth: '100%',
  onClear: false
};

export default SelectBoxAutoComplete;
