import { action } from 'mobx';
import request from 'axios';
import moment from 'moment';
import { RakenCollection } from '../../lib';
import FormTemplate from 'stores/models/forms/FormTemplate';

import { FORM_TEMPLATE_UPLOADED } from 'utils/segmentAnalytics/eventSpec';
import { callTrack } from 'utils/segmentIntegration';

export default class FormTemplates extends RakenCollection {
  model() {
    return FormTemplate;
  }

  url() {
    return `/ra/companies/${this.rootStore.me.company.uuid}/form-templates`;
  }

  @action.bound
  async upload(options) {
    const { file, metadata, progressCallback, analytics } = options;

    const lastModified = moment(
      file.lastModified || file.lastModifiedDate.getTime()
    ).toISOString();

    const type = file.type || 'application/octet-stream';

    const signedUrlResponse = await request.post('/ra/media/request-upload', {
      originalFileName: file.name,
      lastModifiedDateTime: lastModified,
      contentType: `${type}`,
      contentLength: file.size,
      withContentDisposition: true,
      attachmentType: 'FORM'
    });

    if (signedUrlResponse.data) {
      await request.put(signedUrlResponse.data.url, file, {
        onUploadProgress: progressEvent => {
          let percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          if (progressCallback) {
            progressCallback(percentCompleted);
          }
        },
        transformRequest: [
          function(data, headers) {
            headers.put['Content-Type'] = `${type}`;

            Object.keys(signedUrlResponse.data.customHeaders).forEach(key => {
              headers.put[key] = signedUrlResponse.data.customHeaders[key];
            });

            return data;
          }
        ]
      });

      let data = {};

      if (metadata) {
        data = {
          s3ObjectKey: signedUrlResponse.data.key,
          ...metadata
        };
      } else {
        data = {
          s3ObjectKey: signedUrlResponse.data.key
        };
      }

      const response = await request.post(this.url(), data);

      if (response.data) {
        if (options.previewForm) {
          this.remove(options.previewForm);

          this.add(response.data, {
            at: this.models.indexOf(options.previewForm)
          });
        } else {
          this.add(response.data);
        }

        const form = this.get(response.data.uuid);
        const document = form.document;

        document.setUploadProgress(100);

        if (analytics) {
          callTrack(FORM_TEMPLATE_UPLOADED, {
            form_template_name: form.name
          });
        }

        return form;
      }
    }
  }
}
