import orderBy from 'lodash.orderby';
import { RakenCollection } from '../lib';
import Project from '../models/Project';
import { computed, observable, action } from 'mobx';

export default class Projects extends RakenCollection {
  @observable fetchedDeleted;
  @observable fetchedInactive;

  constructor(data, options) {
    super(data, options);

    this.fetchedDeleted = false;
    this.fetchedInactive = false;
  }

  model() {
    return Project;
  }

  url() {
    if (this.rootStore.isSuperAdmin) {
      return `/ra/companies/${this.parent.activeCompany?.uuid}/projects`;
    }

    return `/ra/companies/${this.rootStore.me.company?.uuid}/projects`;
  }

  @computed
  get active() {
    return this.models.filter(project => project.isActive);
  }

  @computed
  get hasActive() {
    return this.active.length > 0;
  }

  @computed
  get inactive() {
    return this.models.filter(project => project.isInactive);
  }

  @computed
  get hasInactive() {
    return this.inactive.length > 0;
  }

  @computed
  get deleted() {
    return this.models.filter(project => project.isDeleted);
  }

  @computed
  get activeAndInactive() {
    return this.models.filter(
      project => project.isActive || project.isInactive
    );
  }

  @computed
  get hasDeleted() {
    return this.deleted.length > 0;
  }

  @computed
  get recent() {
    return orderBy(this.active, project => project.lastActivity || '', [
      'desc'
    ]).slice(0, 5);
  }

  @computed
  get nonRecent() {
    return orderBy(
      orderBy(this.active, project => project.lastActivity || '', [
        'desc'
      ]).slice(5),
      ['name', 'asc']
    );
  }

  @computed
  get hasNonRecent() {
    return this.nonRecent.length > 0;
  }

  @action.bound
  setFetchedDeleted() {
    this.fetchedDeleted = true;
  }

  @action.bound
  setFetchedInactive() {
    this.fetchedInactive = true;
  }

  getProjectByUuid(uuid) {
    return this.models.find(model => model.uuid === uuid);
  }

  @computed
  get sampleProject() {
    return this.models.find(model => model.name === 'Sample Project');
  }
}
