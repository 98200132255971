import { RakenCollection } from '../lib';
import TimeCardEventsByDate from '../models/TimeCardEventsByDate';

export default class TimeCardEventsByDates extends RakenCollection {
  model() {
    return TimeCardEventsByDate;
  }

  url() {
    return `${this.rootStore.urlMicroService('hydra')}/companies/${
      this.rootStore.me.company.uuid
    }/projects/${this.parent.project.uuid}/timecards/timecardevents`;
  }
}
