import { action, computed } from 'mobx';
import ProjectChildAddUI from '../ProjectChildAddUI';
import Forms from 'stores/collections/forms/Forms';
import Form from 'stores/models/forms/Form';
import Attachment from 'stores/models/Attachment';

import history from 'utils/history';
import bytesToSize from 'utils/bytesToSize';
import fileToBase64 from 'utils/fileToBase64';
import alertErrorHandler from 'utils/alertErrorHandler';
import getFilePreviewIcon from 'utils/getFilePreviewIcon';
import { t } from 'utils/translate';

export default class FormAddUI extends ProjectChildAddUI {
  constructor(options) {
    super(options);

    this.forms = new Forms(null, {
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound
  setup() {
    this.forms.reset(this.forms.models.filter(form => form.document.isNew));
  }

  @action.bound
  tearDown() {}

  @computed
  get hasFormsUploading() {
    return this.forms.hasModels;
  }

  @action.bound async handleFormUpload(uploadItem) {
    const file = uploadItem.file;

    if (file.size > 262144000) {
      this.rootStore.notificationsUI.pushNotification({
        title: `${t('File is too big ')} (${bytesToSize(file.size)}). ${t(
          'Limit is 250MB.'
        )}`,
        snackbar: 'error',
        icon: 'notification'
      });

      return;
    }

    file.preview = await fileToBase64(file);

    const filePreviewIcon = getFilePreviewIcon(this.rootStore.assetsURL, file);

    const previewAttachment = new Attachment(
      {
        fileName: file.name,
        thumbUrl: filePreviewIcon
      },
      {
        rootStore: this.rootStore
      }
    );

    const previewForm = new Form(
      { name: file.name, document: previewAttachment },
      {
        rootStore: this.rootStore
      }
    );

    this.forms.add(previewForm);

    try {
      await this.forms.upload({
        file: file,
        metadata: {
          projectUuid: this.projectUuid
        },
        analytics: {
          module: 'Forms',
          project: {
            id: this.project.uuid,
            name: this.project.name
          }
        },
        previewForm: previewForm,
        progressCallback: percentCompleted => {
          // If we wanted to show a progress bar on the attachment
          // we can use previewAttachment.uploadProgress.
          previewForm.document.setUploadProgress(percentCompleted);
        }
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.parent.sortByLastCreated();
    }
  }

  @action.bound rejectFileType(fileName) {
    const extension = fileName.split('.').pop();

    this.notifications.pushNotification({
      title: `File of type .${extension} is not supported.`,
      snackbar: 'error',
      icon: 'notification'
    });
  }

  @action.bound cancelFormAdd() {
    history.push({
      pathname: `${this.project.viewUrl}/forms`,
      search: this.baseQueryParams
    });
  }
}
