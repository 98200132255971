import React, { Component } from 'react';
import styled from 'styled-components';
import autosize from 'autosize';
import { inputCss } from './utils/inputStyles';

const TextArea = styled.textarea`
  ${inputCss};
`;

export default class extends Component {
  static defaultProps = {
    autoSize: true
  };

  componentDidMount() {
    // Disable the grammarly extension
    this.textareaRef.setAttribute('data-gramm', false);

    if (this.props.autoSize) {
      this.autosize();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value === '' && this.props.autoSize) {
      autosize.destroy(this.textareaRef);
      setTimeout(() => {
        this.autosize();
      });
    }
  }

  autosize = () => {
    autosize(this.textareaRef);
  };

  handleRef = ref => {
    const { innerRef } = this.props;

    this.textareaRef = ref;

    if (innerRef) {
      innerRef(ref);
    }
  };

  render() {
    return (
      <TextArea
        data-qa={this.props.dataQA}
        {...this.props}
        ref={this.handleRef}
      />
    );
  }
}
