import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import { t } from 'utils/translate';

const equipmentLogNoteFormPlugins = extendedPlugins;

const equipmentLogNoteFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const equipmentLogNoteFormFields = ['date', 'hours', 'description'];

const equipmentLogNoteFormLabels = {
  date: t('Date'),
  hours: t('Hours in use'),
  description: t('Notes')
};

const equipmentLogNoteFormRules = {
  hours: 'numeric|min:0|max:24|notMoreThanTwoDecimalPlaces',
  description: 'string|max:255',
  date: 'required|dateCustom:YYYY-MM-DD'
};

const equipmentLogNoteFormRelated = {
  hours: ['description'],
  description: ['hours']
};

class EquipmentLogNoteForm extends MobxReactForm {}

export {
  EquipmentLogNoteForm,
  equipmentLogNoteFormOptions,
  equipmentLogNoteFormFields,
  equipmentLogNoteFormRules,
  equipmentLogNoteFormLabels,
  equipmentLogNoteFormPlugins,
  equipmentLogNoteFormRelated
};
