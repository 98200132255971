import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import { t } from 'utils/translate';
import trimObject from 'utils/trimObject';

const submissionSettingsFormPlugins = extendedPlugins;

const submissionSettingsFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const submissionSettingsFormFields = [
  'establishmentName',
  'ein',
  'city',
  'state',
  'zip',
  'streetAddress',
  'naicsCode',
  'establishmentSize',
  'annualAverageEmployees',
  'totalHoursWorked'
];

const submissionSettingsFormRules = {
  establishmentName: 'required|string|max:100',
  streetAddress: 'required|string|max:100',
  ein: 'required|exact_digits:9',
  city: 'required|string|max:100',
  zip: 'integer|required|max:999999999|min:1',
  state: 'required|string',
  naicsCode: 'required|exact_digits:6',
  establishmentSize: 'required|string',
  annualAverageEmployees: 'integer|required|max:24999|min:1',
  totalHoursWorked: 'required|integer|totalHoursRatio:annualAverageEmployees'
};

const submissionSettingsFormValues = {};

const submissionSettingsFormLabels = {
  establishmentName: t('establishment name'),
  streetAddress: t('street address'),
  ein: t('EIN number'),
  zip: t('ZIP code'),
  naicsCode: t('NAICS code'),
  establishmentSize: t('establishment size'),
  annualAverageEmployees: t('average annual employees'),
  totalHoursWorked: t('total hours worked')
};

class SubmissionSettingsForm extends MobxReactForm {
  trimmedValues() {
    return trimObject(this.values());
  }
}

export {
  SubmissionSettingsForm,
  submissionSettingsFormOptions,
  submissionSettingsFormFields,
  submissionSettingsFormRules,
  submissionSettingsFormValues,
  submissionSettingsFormLabels,
  submissionSettingsFormPlugins
};
