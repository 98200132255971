import MobxReactForm from 'mobx-react-form';
import validatorjs from 'validatorjs';
import { computed, reaction } from 'mobx';
import { t } from 'utils/translate';

const plugins = { dvr: validatorjs };

const options = {
  validateOnInit: true,
  validateOnChange: true
};

const fields = [
  'type',
  'recipients',
  'recipient',
  'dateRange',
  'includeCollaborators',
  'fromDate',
  'toDate'
];

const rules = {
  type: 'required|string',
  recipients: 'array|required',
  'recipients[]': 'email',
  recipient: 'required_without:recipients|email',
  fromDate: 'required_if:type,SPECIAL',
  toDate: 'required_if:type,SPECIAL'
};

const values = {
  type: '',
  recipient: '',
  dateRange: '',
  includeCollaborators: false
};

const labels = {
  type: t('Download all manpower for this project from'),
  recipients: t('Send download link to'),
  includeCollaborators: t('Include collaborators’ manpower')
};

export class ManpowerReportForm extends MobxReactForm {
  constructor(settings, options) {
    super(settings, options);
    this.cancelReactToEnableDatePickers = reaction(
      () => this.enableFromToDate,
      enableFromToDate => {
        if (enableFromToDate) {
          this.$('fromDate').set('rules', 'required|dateCustom:YYYY-MM-DD');
          this.$('toDate').set(
            'rules',
            'required|dateCustom:YYYY-MM-DD|afterOrSameAsCustom:fromDate,YYYY-MM-DD'
          );
        } else {
          this.$('fromDate').set('rules', '');
          this.$('toDate').set('rules', '');
        }
      },
      {
        fireImmediately: true
      }
    );
  }

  @computed get enableFromToDate() {
    return this.$('type').value === 'SPECTIMEFRAME';
  }
}

export default {
  options,
  fields,
  rules,
  values,
  labels,
  plugins
};
