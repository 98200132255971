import orderBy from 'lodash.orderby';
import UIStore from './UIStore';
import ChecklistTypes from 'stores/collections/checklists/ChecklistTypes';
import { action, observable, computed, reaction } from 'mobx';
import errorHandler from 'utils/errorHandler';

import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import debounce from 'lodash.debounce';

import { BASE_DEBOUNCE } from 'fixtures/constants';
import { t } from 'utils/translate';

export default class ChecklistTypeSelectorUI extends UIStore {
  @observable searchQuery;
  @observable loading;

  constructor(options) {
    super(options);

    this.loading = false;

    this.searchQuery = '';
    this.fetchChecklistTypes = debounce(
      this.fetchChecklistTypes,
      BASE_DEBOUNCE
    );

    this.checklistTypes = new ChecklistTypes(null, {
      rootStore: this.rootStore
    });
  }

  @action.bound
  setup() {
    this.setupReactions();
    return this.fetchChecklistTypes();
  }

  @action.bound
  tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  @action.bound setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        this.fetchChecklistTypes();
      }
    );
  }

  @action.bound tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @action.bound clearUIState() {
    this.checklistTypes.reset();
    this.loading = false;
    this.searchQuery = '';
  }

  @computed get options() {
    return orderBy(
      this.checklistTypes.models.map(checklistType => {
        return {
          uuid: checklistType.uuid,
          typeName: checklistType.typeName,
          typeClass: checklistType.typeClass
        };
      }),
      ['typeClass.name', 'typeName'],
      ['asc', 'asc']
    );
  }

  @computed get noOptionsText() {
    return this.loading
      ? t('Searching')
      : !this.checklistTypes.hasModels
      ? t('No results')
      : false;
  }

  @action.bound
  setSearchQuery(query) {
    this.searchQuery = query;
  }

  @action.bound
  clearSearchQuery() {
    this.searchQuery = '';
  }

  @computed get hasMoreChecklistTypes() {
    return this.checklistTypes.totalElements > this.checklistTypes.length;
  }

  @computed get params() {
    return {
      query: this.searchQuery,
      sortField: 'typeName',
      sortDirection: 'asc',
      limit: 10000
    };
  }

  @action.bound
  async fetchChecklistTypes(options) {
    this.loading = true;

    try {
      await this.checklistTypes.fetch({
        params: pickBy(this.params, identity),
        ...options
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    } finally {
      this.loading = false;
    }
  }

  fetchNextPage = async (e, autoCompleteRef) => {
    const menu = e.target;

    const scrollTop = menu.scrollTop;
    const scrollHeight = menu.scrollHeight;
    const clientHeight = menu.clientHeight;

    if (scrollTop + clientHeight === scrollHeight) {
      this.fetchNextPageOfChecklistTypes().then(() => {
        autoCompleteRef.current.scrollTop = scrollHeight;
      });
    }
  };

  @action.bound async fetchNextPageOfChecklistTypes() {
    if (this.checklistTypes.fetching) return;

    try {
      this.checklistTypes.fetch({
        params: Object.assign(pickBy(this.params, identity), {
          offset: this.checklistTypes.length
        }),
        add: true,
        remove: false,
        update: false
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }
}
