import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';

const addCompanyPlugins = extendedPlugins;

const addCompanyOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const addCompanyFields = [
  //Company info
  'companyName',
  'companyPhone',
  'address',
  'address.city',
  'address.state',
  'address.postCode',
  'address.streetAddress',
  'address.country',
  //Admin account info
  'firstName',
  'lastName',
  'email',
  'ccEmails',
  'password',
  'phone',
  'salesforceAccountId',
  'isTrial'
];

const addCompanyRules = {
  //Company info
  companyName: 'required|string',
  companyPhone: 'string|max:30',
  address: {
    country: 'string',
    city: 'string',
    state: 'string',
    postCode: 'string',
    streetAddress: 'string'
  },
  //Admin account info
  firstName: 'required|string',
  lastName: 'required|string',
  email: 'required|email',
  ccEmails: 'emailList',
  password: 'required|string|min:8|max:64|minLowerCase:1|minUpperCase:1',
  phone: 'string|max:30',
  salesforceAccountId: 'required|string|min:15|max:18',
  isTrial: 'boolean'
};

const addCompanyValues = {
  address: {
    streetAddress: '',
    city: '',
    state: '',
    postCode: '',
    country: 'US'
  },
  companyPhone: null,
  phone: null,
  isTrial: false
};

class AddCompanyForm extends MobxReactForm {}

export {
  AddCompanyForm,
  addCompanyOptions,
  addCompanyFields,
  addCompanyRules,
  addCompanyValues,
  addCompanyPlugins
};
