import moment from 'moment';
import { computed } from 'mobx';
import { Model } from 'mobx-mc';
import capitalize from 'lodash.capitalize';
import { t } from 'utils/translate';

export default class SubscriptionPendingChange extends Model {
  url() {
    return `/ra/companies/${this.company.uuid}/billing/subscriptions/${this.subscription.id}/change`;
  }

  get restAttributes() {
    return ['id', 'plan', 'quantity', 'unitAmount', 'activateAt', 'addOns'];
  }

  @computed get company() {
    if (this.rootStore.isSuperAdmin) {
      return this.parent.company;
    }

    return this.rootStore.me.company;
  }

  @computed get subscription() {
    return this.parent.subscription;
  }

  @computed get totalAmountFormatted() {
    const addOnsCost = this.addOns?.length
      ? this.addOns
          .map(addOn => {
            if (addOn.tierType === 'stairstep') {
              return stairstepCost(addOn);
            }
            console.warn(`Unsupported addOn pricing model: ${addOn.tierType}`);
            return 0;
          })
          .reduce((total, addOnCost) => total + addOnCost, 0)
      : 0;

    return `$${(this.unitAmount * this.quantity + addOnsCost).toFixed(2)}`;
  }

  @computed get planFormatted() {
    const parts = this.plan.code.split('-');
    return `${capitalize(parts.shift())} - ${capitalize(parts.join('-'))}`;
  }

  @computed get activateAtFormatted() {
    return moment
      .utc(this.activateAt)
      .local()
      .format('LL');
  }

  @computed get changeDetails() {
    return (
      `${this.quantity} \u2715 ${this.planFormatted} | ${this.totalAmountFormatted} ` +
      t('per billing period')
    );
  }
}

function stairstepCost(addOn) {
  const qty = addOn.quantity;
  const tier = addOn.tiers.reduce(
    (foundTier, t) =>
      t.endingQuantity >= qty &&
      t.endingQuantity < (foundTier?.endingQuantity || 10000000)
        ? t
        : foundTier,
    null
  );
  if (!tier) {
    console.warn(`Could not find correct tier for quantity: ${qty}`);
    return 0;
  }
  return tier.unitAmount || 0;
}
