import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';

class GalleryFilterForm extends MobxReactForm {}

GalleryFilterForm.plugins = extendedPlugins;

GalleryFilterForm.options = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

GalleryFilterForm.fields = ['query', 'fromDate', 'toDate', 'mediaTypes'];

GalleryFilterForm.rules = {
  query: 'string',
  fromDate: 'dateCustom:YYYY-MM-DD',
  toDate: 'dateCustom:YYYY-MM-DD|afterCustom:fromDate,YYYY-MM-DD',
  mediaTypes: 'array'
};

GalleryFilterForm.values = {
  query: '',
  fromDate: '', // used for checking default value for 'Clear Search'
  toDate: '', // used for checking default value for 'Clear Search'
  mediaTypes: []
};

export { GalleryFilterForm };
