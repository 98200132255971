import styled, { css } from 'styled-components';
import Button from './Button';
import media from '../utils/mediaTemplate';

export default styled(Button)`
  background-color: ${props =>
    props.active ? props.theme.colors[props.color] : 'transparent'};
  box-shadow: ${props =>
    props.active ? '0px 2px 10px rgba(0, 0, 0, 0.25)' : 'none'};
  color: ${props =>
    props.active ? props.theme.colors.white : props.theme.colors.lightGrey3};
  opacity: 1;
  padding: 0;
  transition: all 0.35s ease-in-out;
  transition-property: background-color, color;
  &:first-child {
    box-shadow: ${props =>
      props.active ? '2px 2px 5px rgba(0, 0, 0, 0.25)' : 'none'};
  }
  &:last-child {
    box-shadow: ${props =>
      props.active ? '-2px 2px 5px rgba(0, 0, 0, 0.25)' : 'none'};
  }

  &:focus {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    &:first-child {
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
    }
    &:last-child {
      box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.25);
    }
  }

  &:hover,
  &:focus {
    ${props =>
      !props.active &&
      css`
        color: ${props => props.theme.colors.lightGrey1};
      `};
  }

  ${media.tablet`
    padding: 0 ${props => (props.wide ? '75px' : '25px')};
  `};
`;
