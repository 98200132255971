import React, { Component } from 'react';
import CheckBox from './CheckBox';
import Text from './Text';
import { observer } from 'mobx-react';
import { t } from 'utils/translate';

@observer
export default class CheckBoxControl extends Component {
  static defaultProps = {
    textTransform: 'capitalize'
  };

  toggleFieldValue = () => {
    const { form, fieldName } = this.props;

    const update = {};
    update[fieldName] = !form.$(fieldName).value;

    form.update(update);
  };

  render() {
    const { form, fieldName, label, textTransform, ...rest } = this.props;

    return (
      <CheckBox
        checked={form.$(fieldName).value}
        onClick={this.toggleFieldValue}
        {...rest}
      >
        <Text transform={textTransform}>
          {label || t(form.$(fieldName).label)}
        </Text>
      </CheckBox>
    );
  }
}
