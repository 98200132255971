import { Model } from 'mobx-mc';
import { computed } from 'mobx';
import moment from 'moment';
import kebabCase from 'lodash.kebabcase';

import SuperAdminProjectMembers from '../../collections/superAdmin/SuperAdminProjectMembers';

import { t } from 'utils/translate';

class SuperAdminProject extends Model {
  constructor(data, options) {
    super(data, options);

    this.projectMembers = new SuperAdminProjectMembers(null, {
      parent: this,
      rootStore: this.rootStore
    });
  }

  get urlRoot() {
    return '/ra/sadmin/projects';
  }

  get restAttributes() {
    return [
      'uuid',
      'name',
      'status',
      'startDate',
      'endDate',
      'company',
      'address',
      'projectNo',
      'projectImage',
      'dateFormat',
      'stats',
      'projectId',
      'externalId'
    ];
  }

  idAttribute() {
    return 'uuid';
  }

  get formattedStartDate() {
    return moment(this.startDate).format('YYYY-MM-DD');
  }

  get formattedEndDate() {
    return moment(this.endDate).format('YYYY-MM-DD');
  }

  @computed
  get infoFormValues() {
    return {
      name: this.name,
      status: this.status,
      projectImage: {
        contentUrl: this.projectImage ? this.projectImage.contentUrl : null,
        base64: null
      },
      startDate: this.startDate ? this.formattedStartDate : null,
      endDate: this.endDate ? this.formattedEndDate : null,
      projectNo: this.projectNo,
      address: {
        streetAddress: this.address.streetAddress,
        city: this.address.city,
        state: this.address.state,
        postCode: this.address.postCode,
        country: this.address.country,
        geolocation: {
          lat: this.address.geolocation.lat,
          lng: this.address.geolocation.lng
        }
      }
    };
  }

  @computed get statusFormatted() {
    switch (this.status) {
      case 'ACTIVE':
        return t('Active');
      case 'INACTIVE':
        return t('Inactive');
      case 'DELETED':
        return t('Deleted');
      default:
        return t('Active');
    }
  }

  @computed get statusColor() {
    switch (this.status) {
      case 'ACTIVE':
        return 'green';
      case 'INACTIVE':
        return 'orange';
      case 'DELETED':
        return 'red';
      default:
        return 'green';
    }
  }

  @computed get slug() {
    return kebabCase(this.name);
  }

  @computed get initials() {
    const words = this.name.toUpperCase().split(' ');

    return `${words[0].charAt(0)}`;
  }

  @computed
  get image() {
    if (this.projectImage) {
      return this.projectImage.thumbUrl || this.projectImage.contentUrl;
    }

    return null;
  }
}

export default SuperAdminProject;
