import request from 'axios';
import { action, computed } from 'mobx';
import ProjectChildEditUI from './ProjectChildEditUI';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';

import { callTrack } from 'utils/segmentIntegration';
import { MATERIAL_LOG_ROLLOVER } from 'utils/segmentAnalytics/eventSpec';

export default class MaterialLogsRollOverUI extends ProjectChildEditUI {
  @computed get canRollOver() {
    return this.parent.materialLogs.summary.get('canRollover');
  }

  @action.bound async showRollOverModal() {
    await this.authorization.checkFeatureAccess('CRUDMaterialLogs');

    this.showModal('MaterialLogsRollOverModal');
  }

  @action.bound
  async confirmRollOver() {
    this.saving = true;

    try {
      const response = await request.post(
        `${this.rootStore.urlMicroService('performanceTracking')}/companies/${
          this.company.uuid
        }/materiallogs/rollover`,
        this.reportParams
      );

      this.hideActiveModal();

      this.parent.refetchMaterialLogs();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Material logs rolled over')
      });

      callTrack(MATERIAL_LOG_ROLLOVER, {
        rollover_count: response.data?.collection?.length
      });
    } catch (error) {
      if (error.response?.status === 400 && this.segmentUuid) {
        this.setValidationDetails([
          {
            field: 'message',
            fieldMessage: t(
              'Unable to rollover entries as the current segment does not exist on the previous day.'
            )
          }
        ]);
      } else {
        alertErrorHandler(error, this.setValidationDetails);
      }
    } finally {
      this.saving = false;
    }
  }
}
