// !!!NOTE!!!
// Designers Palette: https://rakenapp.invisionapp.com/d/main#/console/10173859/221741950/preview

export default {
  colors: {
    transparent: 'transparent',
    white: '#FFFFFF',
    black: '#000000',
    darkSteel: '#313E47',
    charcoalGrey: '#4A4A4A',
    lightGrey1: '#888888',
    lightGrey2: '#EEEEEE',
    lightGrey3: '#CCCCCC',
    lightGrey4: '#FAFAFA',
    lightGrey5: '#E4E4E4',
    lightGrey6: '#F3F3F3',
    lightGrey20: '#333333',
    lighGreyDisabled: '#9b9b9b',
    rakenOrange: '#FF8A1D',
    blueAccent: '#00A2ED',
    blueAccentDisabled: '#99CCFF',
    goGreen: '#03B17E',
    greenBright: '#00A403',
    greenDark: '#027805',
    goGreenDisabled: '#99CC99',
    red: '#D0011B',
    yellow: '#F8E81C',
    mustard: '#F8C056',
    purple: '#9012FE',
    cream: '#F9F9F9',
    brown: '#987943'
  },
  fonts: {
    arial: 'Arial, sans-serif',
    primary:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'
  }
};
