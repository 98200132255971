import styled from 'styled-components';
import { borderRadius } from 'polished';
import media from '../utils/mediaTemplate';

export default styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 -3px 3px -2px rgba(0, 0, 0, 0.15);
  ${borderRadius('bottom', '6px')};
  display: flex;
  justify-content: flex-end;
  padding: 2rem 30px;
  position: relative;
  z-index: 10;
  ${media.desktop`
    ${borderRadius('bottom', '16px')};
  `};
`;
