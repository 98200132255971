import styled from 'styled-components';

export default styled.div`
  background: ${props => props.theme.colors.white};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  border: 1px solid ${props => props.theme.colors.lightGrey3};
  border-radius: 9px;
  margin: 3px 0;
  top: 0;
  z-index: 9999;
`;
