import MobxReactForm from 'mobx-react-form';
import validatorjs from 'validatorjs';
import { t } from 'utils/translate';

const companyFormPlugins = { dvr: validatorjs };

const companyFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const companyFormFields = [
  'companyId',
  'name',
  'address',
  'address.postCode',
  'address.country'
];

const companyFormRules = {
  name: 'required|string|max:255',
  address: {
    country: 'required|string'
  }
};

const companyFormValues = {
  address: {
    country: 'US'
  }
};

const companyFormLabels = {
  name: t('company name'),
  address: {
    postCode: t('zip code'),
    country: t('country')
  }
};

class CompanyForm extends MobxReactForm {}

export {
  CompanyForm,
  companyFormOptions,
  companyFormFields,
  companyFormRules,
  companyFormValues,
  companyFormLabels,
  companyFormPlugins
};
