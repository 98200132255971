import { action, computed, observable } from 'mobx';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import {
  ProjectTemplateRecipientsForm,
  projectTemplateRecipientsFormOptions,
  projectTemplateRecipientsFormFields,
  projectTemplateRecipientsFormRules,
  projectTemplateRecipientsFormPlugins,
  projectTemplateRecipientsFormLabels
} from 'forms/projectTemplates/projectTemplateRecipients';

import { t } from 'utils/translate';

import ProjectTemplateChildEditUI from './ProjectTemplateChildEditUI';

export default class ProjectTemplateRecipientsUI extends ProjectTemplateChildEditUI {
  @observable form;
  constructor(options) {
    super(options);

    this.form = null;

    this.saveTitle = t('Project template recipients saved');
  }

  @computed get selectedTemplate() {
    return this.parent.selectedTemplate;
  }

  @action.bound setup() {
    this.setupForm();
  }

  @action.bound tearDown() {
    this.form = null;
    this.unblockHistory();
  }

  @action.bound
  setupForm() {
    this.form = new ProjectTemplateRecipientsForm(
      {
        fields: projectTemplateRecipientsFormFields,
        rules: projectTemplateRecipientsFormRules,
        labels: projectTemplateRecipientsFormLabels,
        values: this.selectedTemplate.recipientsFormValues
      },
      {
        options: projectTemplateRecipientsFormOptions,
        plugins: projectTemplateRecipientsFormPlugins
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @computed get times() {
    const moment = extendMoment(Moment);
    return Array.from(
      moment.range(moment().startOf('day'), moment().endOf('day')).by('hour')
    ).map(time => {
      return {
        value: time.format('HH:mm'),
        name: time.format('h:mm A')
      };
    });
  }
}
