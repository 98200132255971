import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import { t } from 'utils/translate';

const checklistFiltersFormPlugins = extendedPlugins;

const checklistFiltersFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const checklistFiltersFormFields = [
  'checklistNames',
  'locations',
  'startDate',
  'endDate',
  'open',
  'closed'
];

const checklistFiltersFormLabels = {
  checklistNames: t('Checklist names'),
  locations: t('Project locations'),
  startDate: t('Start date'),
  endDate: t('End date')
};

const checklistFilterFormValues = {
  checklistNames: [],
  locations: [],
  startDate: '',
  endDate: '',
  open: false,
  closed: false
};

class ChecklistFiltersForm extends MobxReactForm {}

export {
  ChecklistFiltersForm,
  checklistFilterFormValues,
  checklistFiltersFormOptions,
  checklistFiltersFormFields,
  checklistFiltersFormLabels,
  checklistFiltersFormPlugins
};
