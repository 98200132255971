import React, { Component, useState, useEffect, useCallback } from 'react';
import {
  ModalPage,
  Button,
  Box,
  Icon,
  Text,
  TextField,
  TextArea,
  Skeleton,
  Alert
} from '@raken/athens-web/lib';

import DeleteModal from 'athens-components/DeleteModal';
import moment from 'moment-timezone';
import styled from 'styled-components';
import { t } from 'utils/translate';
import PropTypes from 'prop-types';
import { MEDIA_TYPES } from 'stores/models/Attachment';
import { Observer, observer } from 'mobx-react';
import MediaViewerUI from 'stores/ui/MediaViewerUI';
import media from 'utils/mediaTemplate';
import { callTrack } from 'utils/segmentIntegration';
import { ATTACHMENT_DESCRIPTION_ADDED } from 'utils/segmentAnalytics/eventSpec';
import alertErrorHandler from 'utils/alertErrorHandler';
import ApryseWebViewer from './ApryseWebViewer';

const IconBox = styled(Box)`
  cursor: pointer;
  line-height: 0;
  padding: 8px;
`;

const StyledModalPage = styled(ModalPage)`
  .MuiDialog-paper {
    height: 100%;
    padding-bottom: 0;
  }
`;

const NavButtons = styled.div`
  padding: 8px;
  line-height: 0;
  cursor: pointer;
  color: black;

  ${({ theme, disabled }) =>
    disabled &&
    `
      cursor: not-allowed;
      color: ${theme.palette.lightGrey.main};
  `}
`;

const MediaWrapperBox = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;

  ${media.desktop`
    flex-direction: row;
  `}
`;

const MediaContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.lightGrey.lighten};
  flex: 1 1 auto;
  display: flex;
  position: relative;
  overflow: hidden;
`;

const SidePanel = styled.aside`
  background-color: white;
  border-left: solid 1px ${({ theme }) => theme.palette.lightGrey.main};
  padding: 32px;
  box-sizing: border-box;
  flex: none;
  overflow: auto;
  width: 100%;
  max-height: 40%;

  ${media.desktop`
    max-width: 320px;
    max-height: 100%;
  `}
`;

const ItemContainerBox = styled(Box)`
  transition: transform 0.25s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledImg = styled.img`
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  border-radius: 6px;
`;

const StyledVideo = styled.video`
  width: 100%;
  max-width: 1024px;
`;

const StyledAudio = styled.audio`
  flex: 1 1 auto;
  width: 100%;
  max-width: 512px;
`;

const FormEnableButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  line-height: 0;
  padding: 8px;
  cursor: pointer;
`;

const FormPreviewDiv = styled.div`
  margin: -12px;
  padding: 12px;
  border-radius: 4px;
  position: relative;
  word-break: break-word;

  ${({ canEdit, theme }) =>
    canEdit &&
    `
    cursor: pointer;
    transition: all 0.5s;

    &:hover {
      background-color: ${theme.palette.lightGrey.main};
    }
  `}
`;

/**
 * Sidebar Form Component
 */
const MediaFormEdit = props => {
  const { uiStore } = props;
  const media = uiStore.currentMedia;
  const fileName = media.fileName.replace(/\.[^/.]+$/, '');
  const fileExtension = media.fileName.split('.').pop();
  const desc = media.desc;

  const [form, setForm] = useState({
    fileName: fileName,
    desc: desc
  });

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      const isAddedDesc = form.desc !== media.desc;

      media
        .save(
          {
            fileName: `${form.fileName}.${fileExtension}`,
            desc: form.desc
          },
          { wait: true }
        )
        .then(val => {
          isAddedDesc && callTrack(ATTACHMENT_DESCRIPTION_ADDED);

          uiStore.disableEdit();
        })
        .catch(error => {
          alertErrorHandler(error, uiStore.setValidationDetails);
        });
    },
    [media, form, fileExtension, uiStore]
  );

  const isValidFileName = useCallback(() => {
    return (form.fileName || []).length <= 255;
  }, [form.fileName]);

  const isValidDesc = useCallback(() => {
    return (form.desc || []).length <= 1024;
  }, [form.desc]);

  return (
    <Observer>
      {() => (
        <form
          onSubmit={handleSubmit}
          onKeyDown={e => e.stopPropagation()} // Important
          data-qa="form__media-edit"
        >
          {uiStore.hasValidationDetails && (
            <Box mb={6}>
              <Alert>
                {uiStore.validationDetails.map(item => item.fieldMessage)}
              </Alert>
            </Box>
          )}

          <TextField
            label={t('File name')}
            placeholder={t('File name')}
            value={form.fileName}
            onChange={e =>
              setForm({
                ...form,
                fileName: e.target.value
              })
            }
            error={!isValidFileName()}
            helperText={
              !isValidFileName() &&
              t('The {label} may not be greater than {num} characters', {
                templateStrings: {
                  label: t('File name').toLowerCase(),
                  num: 255
                }
              })
            }
            fullWidth
            dataQA="media-edit-filename"
          />

          <TextField
            label={t('Uploaded by')}
            placeholder={t('Uploaded by')}
            value={media?.createdBy?.fullName}
            disabled={true}
            fullWidth
            dataQA="media-edit-created-by"
          />

          <TextField
            // Probably not the best field to use here. But following design.
            label={t('Date')}
            placeholder={t('Date')}
            value={moment(media?.takenOrUploadedDate)
              .tz(media.timezone)
              .format(`YYYY-MM-DD [${t('at').toLowerCase()}] HH:mm A`)}
            disabled={true}
            fullWidth
            dataQA="media-edit-taken-date"
          />

          <TextArea
            label={t('Description')}
            placeholder={t('Description')}
            value={form.desc}
            onChange={e => setForm({ ...form, desc: e.target.value })}
            error={!isValidDesc()}
            helperText={
              !isValidDesc() &&
              t('The {label} may not be greater than {num} characters', {
                templateStrings: {
                  label: t('Description').toLowerCase(),
                  num: 1024
                }
              })
            }
            dataQA="media-edit-desc"
          />

          <Box mt={6} display={'flex'}>
            <Box>
              <Button
                type="submit"
                disabled={media.saving || !(isValidFileName() && isValidDesc())}
                dataQA="media-edit-submit"
              >
                {media.saving ? t('Saving...') : t('Save')}
              </Button>
            </Box>

            <Box ml={2}>
              <Button
                onClick={uiStore.disableEdit}
                rakenColor={'white'}
                dataQA="media-edit-cancel"
              >
                {t('Cancel')}
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </Observer>
  );
};

MediaFormEdit.propTypes = {
  uiStore: PropTypes.instanceOf(MediaViewerUI)
};

/**
 * Sidebar Form Preview Component
 */
const MediaFormPreview = props => {
  const { uiStore } = props;

  const media = uiStore.currentMedia;

  const hideUndefinedValues = media?.hideUndefinedValues;

  const editableProps = media?.canEditOrDelete && {
    onClick: uiStore.enableEdit,
    canEdit: media.canEditOrDelete
  };

  return (
    <FormPreviewDiv {...editableProps} data-qa="form__preview">
      {media?.canEditOrDelete && (
        <FormEnableButton>
          <Icon kind={'pencil'} rakenColor={'grey'} />
        </FormEnableButton>
      )}

      {(!hideUndefinedValues || media?.fileName) && (
        <Box mb={6}>
          <Text bold>{t('File name')}</Text>

          <Box mt={2}>
            {media?.fileName ? (
              <Text dataQA="preview-filename">{media?.fileName}</Text>
            ) : (
              <Text dataQA="preview-filename-none" light>
                {t('No {item}', {
                  templateStrings: { item: t('File name').toLowerCase() }
                })}
              </Text>
            )}
          </Box>
        </Box>
      )}

      {(!hideUndefinedValues || media?.createdBy) && (
        <Box mb={6}>
          <Text bold>{t('Uploaded by')}</Text>

          <Box mt={2}>
            {media?.createdBy?.fullName ? (
              <Text dataQA="preview-created-by">
                {media?.createdBy?.fullName}
              </Text>
            ) : (
              <Text dataQA="preview-created-by-none" light>
                {t('No {item}', {
                  templateStrings: { item: t('Uploaded by').toLowerCase() }
                })}
              </Text>
            )}
          </Box>
        </Box>
      )}

      {(!hideUndefinedValues || media?.uploadDate) && (
        <Box mb={6}>
          <Text bold>{t('Date')}</Text>

          <Box mt={2}>
            {media?.uploadDate ? (
              <Text dataQA="preview-taken-date">
                {moment(media?.takenOrUploadedDate)
                  .tz(media.timezone)
                  .format(`YYYY-MM-DD [${t('at').toLowerCase()}] HH:mm A`)}
              </Text>
            ) : (
              <Text dataQA="preview-taken-date-none" light>
                {t('No {item}', {
                  templateStrings: { item: t('Date').toLowerCase() }
                })}
              </Text>
            )}
          </Box>
        </Box>
      )}

      {(!hideUndefinedValues || media?.desc) && (
        <Box mb={6}>
          <Text bold>{t('Description')}</Text>

          <Box mt={2}>
            {media?.desc ? (
              <Text dataQA="preview-desc">{media?.desc}</Text>
            ) : (
              <Text dataQA="preview-desc-none" light>
                {t('No {item}', {
                  templateStrings: { item: t('Description').toLowerCase() }
                })}
              </Text>
            )}
          </Box>
        </Box>
      )}
    </FormPreviewDiv>
  );
};

MediaFormPreview.propTypes = {
  uiStore: PropTypes.instanceOf(MediaViewerUI)
};

/**
 * Treat this like a <img> component.
 */
const ImageWithLoader = props => {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoaded = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    let preloadImage = new Image();
    preloadImage.src = props.src;
    preloadImage.onload = handleImageLoaded;

    return () => {
      // Prevents memory leak for image that isn't loaded when you navigate away.
      preloadImage = undefined;
    };
  }, [props.src]);

  return isLoading ? (
    <Skeleton variant="rect" width={160} height={90} />
  ) : (
    <StyledImg {...props} />
  );
};

/**
 * The main viewer component
 * Class component because we require the ref to work as unique identifier
 */
@observer
class MediaViewer extends Component {
  handleKeyDown = e => {
    e.stopPropagation();
    const uiStore = this.props.uiStore;

    if (e.key === 'ArrowLeft') uiStore.handlePrevious();
    else if (e.key === 'ArrowRight') uiStore.handleNext();
    else if (e.key === 'Escape') uiStore.handleClose();
  };

  /**
   * Renders previous, current & next items in the gallery so that we can swipe animate left and right.
   */
  renderItems = () => {
    const uiStore = this.props.uiStore;

    return uiStore.items.map((item, index) => {
      const offset = index - uiStore.selectedIndex;

      if (Math.abs(offset) > 1) return null;

      return (
        <ItemContainerBox
          style={{ transform: `translateX(${offset * 100}%)` }}
          key={index}
          dataQA="media-item"
        >
          {this.renderMedia(item)}
        </ItemContainerBox>
      );
    });
  };

  renderMedia = item => {
    const uiStore = this.props.uiStore;

    const { IMAGE, VIDEO, AUDIO, DOCUMENT } = MEDIA_TYPES;

    if (item.mediaType === IMAGE) {
      return <ImageWithLoader src={item.contentUrl} />;
    } else if (item.mediaType === VIDEO) {
      if (item.contentType === 'video/quicktime') {
        return <ImageWithLoader src={item.thumbnail} />;
      }
      return <StyledVideo src={item.contentUrl} controls />;
    } else if (item.mediaType === AUDIO) {
      return <StyledAudio src={item.contentUrl} controls />;
    } else if (
      item.mediaType === DOCUMENT &&
      item.contentType === 'application/pdf'
    ) {
      return (
        <ApryseWebViewer
          licenseKey={uiStore.rootStore.apryseKey}
          initialDoc={item.contentUrl}
          isReadOnly
          style={{
            margin: 0,
            height: '100%',
            width: '100%'
          }}
        />
      );
    } else if (item.thumbnail) {
      return <ImageWithLoader src={item.thumbnail} />;
    } else {
      return t('File not supported');
    }
  };

  deleteEnabled = () => {
    const { uiStore } = this.props;
    return this.props.canDelete && uiStore.currentMedia?.canEditOrDelete;
  };

  render() {
    const { uiStore, canDownload, onClose } = this.props;

    return (
      <StyledModalPage
        open={uiStore.isOpen}
        style={{ zIndex: 2050 }} // Important because old ui dialog has zIndex: 2040
        onKeyDown={this.handleKeyDown}
        onClick={e => e.stopPropagation()} // Important: prevent events from leaking into elements below this modal.
        disableEnforceFocus // Required
      >
        <ModalPage.Header
          onClose={() => {
            uiStore.handleClose();
            if (onClose) {
              onClose();
            }
          }}
        >
          {uiStore.items.length > 1 && (
            <Box display={'flex'} alignItems={'center'}>
              <NavButtons
                disabled={!uiStore.hasPrevious}
                onClick={uiStore.handlePrevious}
                dataQA="media-previous"
              >
                <Icon kind={'chevron-left'} size={12} />
              </NavButtons>

              <Box minWidth={45} textAlign={'center'} dataQA="media-count">
                <span>{`${uiStore.selectedIndex + 1}/${
                  uiStore.items.length
                }`}</span>
              </Box>

              <NavButtons
                disabled={!uiStore.hasNext}
                onClick={uiStore.handleNext}
                dataQA="media-next"
              >
                <Icon kind={'chevron-right'} size={12} />
              </NavButtons>
            </Box>
          )}
          <Box ml={'auto'} display={'flex'} alignItems={'center'}>
            {canDownload && (
              <IconBox
                onClick={() => uiStore.handleDownload()}
                title={t('Download')}
                dataQA="media-download"
              >
                <Icon kind={'download'} rakenColor="black" size={20} />
              </IconBox>
            )}

            {this.deleteEnabled() && (
              <IconBox
                ml={1}
                onClick={() => uiStore.openConfirmDeleteModal()}
                title={t('Delete')}
                dataQA="media-delete"
              >
                <Icon kind={'trash'} rakenColor="black" size={20} />
              </IconBox>
            )}
          </Box>
        </ModalPage.Header>

        <MediaWrapperBox>
          <MediaContainer>{this.renderItems()}</MediaContainer>

          <SidePanel>
            <Box position={'relative'}>
              {uiStore.isEdit ? (
                <MediaFormEdit uiStore={uiStore} />
              ) : (
                <MediaFormPreview uiStore={uiStore} />
              )}
            </Box>
          </SidePanel>
        </MediaWrapperBox>

        {this.deleteEnabled() && (
          <DeleteModal
            isOpen={uiStore.confirmDeleteModal}
            onClose={uiStore.closeConfirmDeleteModal}
            onEsc={uiStore.closeConfirmDeleteModal}
            onDelete={() => uiStore.handleConfirmDelete()}
            title={t('Delete attachment?')}
          >
            {t('Are you sure you want to delete {fileName}?', {
              templateStrings: {
                fileName:
                  uiStore.currentMedia?.fileName || t('This file').toLowerCase()
              }
            })}
          </DeleteModal>
        )}
      </StyledModalPage>
    );
  }
}

MediaViewer.propTypes = {
  uiStore: PropTypes.instanceOf(MediaViewerUI).isRequired,
  canDelete: PropTypes.bool,
  canDownload: PropTypes.bool,
  onClose: PropTypes.func
};

MediaViewer.defaultProps = {
  canDelete: true,
  canDownload: true
};

export default MediaViewer;
