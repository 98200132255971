import moment from 'moment';
import uniqBy from 'lodash.uniqby';
import { action, observable, computed } from 'mobx';
import ProjectChildEditUI from '../ProjectChildEditUI';
import request from 'axios';
import alertErrorHandler from 'utils/alertErrorHandler';
import Task from 'stores/models/Task';
import { t } from 'utils/translate';

import Observations from 'stores/collections/Observations';
import ObservationTypes from 'stores/collections/ObservationTypes';
import ObservationStatuses from 'stores/collections/ObservationStatuses';
import ObservationFields from 'stores/collections/ObservationFields';

export default class ChecklistObservationUI extends ProjectChildEditUI {
  @observable confirmDeleteModal;
  @observable questionModel;
  @observable question;

  constructor(options) {
    super(options);

    this.confirmDeleteModal = false;
    this.questionModel = null;
    this.question = null;

    this.observations = new Observations(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.observationTypes = new ObservationTypes(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.observationStatuses = new ObservationStatuses(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.observationFields = new ObservationFields(null, {
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound handleRemoveObservation(uuid, questionModel, question) {
    this.confirmDeleteModal = true;
    this.selectedObservationUuid = uuid;
    this.questionModel = questionModel;
    this.question = question;
  }

  @action.bound
  closeConfirmDeleteModal() {
    this.confirmDeleteModal = false;
  }

  @computed get checklistUuid() {
    return this.questionModel?.relatedChecklist.uuid;
  }

  @computed get questionUuid() {
    return this.question?.$('uuid').value;
  }

  @computed get deleteUrl() {
    return (
      this.rootStore.urlMicroService('toolbox') +
      `/companies/${this.company.uuid}/checklists/${this.checklistUuid}/questions/${this.questionUuid}/observations`
    );
  }

  @action.bound async removeObservation() {
    this.saving = true;

    try {
      this.questionModel.checklistResponse.removeObservation(
        this.selectedObservationUuid
      );

      if (this.parent.entryForEdit) {
        await request.delete(this.deleteUrl, {
          params: {
            observationUuids: this.selectedObservationUuid
          }
        });
      }

      this.confirmDeleteModal = false;
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  getTaskStatus(status) {
    const taskStatus = this.observationStatuses.models.find(
      observationStatus => {
        return status === observationStatus.uuid;
      }
    );

    return taskStatus?.closedState ? 'COMPLETED' : 'OPEN';
  }

  @action.bound
  async createOrEditTasksForAssignees(assignees, status) {
    if (!assignees) return [];

    const tasks = assignees.map(task => {
      return new Task(
        {
          taskType: 'OBSERVATIONS',
          assignee: { uuid: task.assignee.uuid },
          ...(task.dueDate && {
            dueDate: moment(task.dueDate).format('YYYY-MM-DD')
          }),
          desc: task.action,
          projectUuid: this.project.uuid,
          sendNotification: task.sendNotification,
          status: this.getTaskStatus(status),
          ...(task.uuid && { uuid: task.uuid })
        },
        {
          rootStore: this.rootStore
        }
      );
    });

    const savedTasks = await Promise.all(
      tasks.map(task =>
        task.save({
          assignee: { uuid: task.assignee.uuid },
          desc: task.desc,
          status: this.getTaskStatus(status),
          ...(task.dueDate && { dueDate: task.dueDate }),
          ...(task.isNew && { type: task.type }),
          ...(task.isNew && { projectUuid: task.projectUuid }),
          ...(task.isNew && { uuid: task.uuid })
        })
      )
    );

    return savedTasks.map(task => {
      return {
        taskUuid: task.uuid,
        sendNotification: !!task.sendNotification
      };
    });
  }

  @computed get activeProjectUuids() {
    return this.project.allProjectTeams
      .filter(projectTeam => projectTeam.projectState === 'ACTIVE')
      .map(projectTeam => {
        return projectTeam.uuid;
      });
  }

  @computed get memberProjectUuids() {
    // For collaborator, fetch the current collaborator and the GCs members
    if (this.project.isChildProject) {
      return [this.project.uuid, this.project.parentProject.uuid];
    }

    // For GC, fetch the GC members and all the active collaborator team members
    return this.activeProjectUuids;
  }

  @computed get observationTypesOptions() {
    const typesList = this.observationTypes.models.map(type => {
      return {
        value: type.type,
        title: type.type,
        class: type.typeClass
      };
    });

    return uniqBy(typesList, 'value');
  }

  @computed get observationStatusOptions() {
    return this.observationStatuses.models.map(status => {
      return {
        value: status.uuid,
        title: status.name,
        signatureRequired: status.signatureRequired
      };
    });
  }

  @computed get priorityOptions() {
    return [
      { value: 'LOW', title: t('Low') },
      { value: 'MEDIUM', title: t('Medium') },
      { value: 'HIGH', title: t('High') },
      { value: 'CRITICAL', title: t('Critical') }
    ];
  }

  @computed get locationOptions() {
    return this.parent.parent.locationOptions;
  }

  @computed
  get categoryOptions() {
    return [
      { value: 'POSITIVE', title: t('Positive') },
      { value: 'NEGATIVE', title: t('Negative') }
    ];
  }
}
