import styled from 'styled-components';

export default styled.tfoot`
  background-color: ${props => props.theme.colors.darkSteel};
  td {
    background-color: ${props => props.theme.colors.darkSteel} !important;
    color: ${props => props.theme.colors.white};
    &:first-child {
      border-radius: 0 0 0 6px;
    }
    &:last-child {
      border-radius: 0 0 6px 0;
    }
  }
`;
