import { action } from 'mobx';
import { RakenCollection } from '../lib';
import CostCode from '../models/CostCode';

export default class CostCodes extends RakenCollection {
  model() {
    return CostCode;
  }

  url() {
    return `${this.rootStore.urlMicroService('performanceTracking')}/costcodes`;
  }

  findCodeByIntentifier = (division, code, description) => {
    return this.models.find(costCode => {
      return costCode.division === division && costCode.code === code;
    });
  };

  @action.bound clearNew() {
    this.models.forEach(model => {
      model.clearNewState();
    });
  }

  @action.bound clearNewForBudget() {
    this.models.forEach(model => {
      model.clearNewForBudgetState();
    });
  }
}
