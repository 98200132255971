import { RakenCollection } from '../lib';
import Notification from '../models/Notification';
import { computed } from 'mobx';

export default class Notifications extends RakenCollection {
  model() {
    return Notification;
  }

  @computed
  get currentNotification() {
    return this.length && this.at(0);
  }

  @computed
  get hasNotifications() {
    return this.length > 0;
  }
}
