import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';
import { t } from 'utils/translate';

const promoFormPlugins = extendedPlugins;

const promoFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const promoFormFields = ['isPromo', 'promoEndDate'];

const promoFormRules = {
  isPromo: 'boolean|required_with:promoEndDate',
  promoEndDate: 'required_with:isPromo|dateCustom:YYYY-MM-DD|afterOrTodayDate'
};

const promoFormLabels = {
  isPromo: t('enable promo'),
  promoEndDate: t('promo end date')
};

class PromoForm extends MobxReactForm {}

export {
  PromoForm,
  promoFormOptions,
  promoFormFields,
  promoFormRules,
  promoFormLabels,
  promoFormPlugins
};
