export default (error, alertCallback) => {
  if ('response' in error) {
    if (!error.response) {
      console.error(error.stack);

      alertCallback({
        field: 'unknown',
        fieldMessage: 'An unknown network error has occurred.'
      });
    } else {
      if (error.response.status !== 401) {
        const { data } = error.response;

        let validationDetails = [];

        if (data.message && !data.validationDetails?.length) {
          validationDetails.push({
            field: 'message',
            fieldMessage: data.message
          });
        }

        if (data.validationDetails && data.validationDetails.length) {
          validationDetails = validationDetails.concat(data.validationDetails);
        }

        alertCallback(validationDetails);
      }
    }
    console.error(error);
  } else {
    alertCallback([
      {
        field: 'unknown',
        fieldMessage: error
      }
    ]);
    console.error(error);
  }
};
