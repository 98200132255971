import styled from 'styled-components';

export default styled.a`
  cursor: pointer;
  color: ${props =>
    props.color
      ? props.theme.colors[props.color]
      : props.theme.colors.lightGrey1};
  display: block;
  font-size: ${props => (props.size ? `${props.size / 10}rem` : 'inherit')};
  position: absolute;
  right: 10px;
  top: 50%;
  text-decoration: underline;
  text-transform: none;
  transform: translateY(-50%);
`;
