import MobxReactForm from 'mobx-react-form';
import { t } from 'utils/translate';
import extendedPlugins from '../../utils/extendedPlugins';

const refundTransactionFormPlugins = extendedPlugins;

const refundTransactionFormOptions = {
  validateOnInit: false,
  showErrorsOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const refundTransactionFormFields = ['refundId', 'amount', 'comment'];

const refundTransactionFormRules = {
  refundId: 'required|string',
  amount: 'required|numberCanHaveComma|notMoreThanTwoDecimalPlaces',
  comment: 'string|max:255'
};

const refundTransactionFormLabels = {
  refundId: t('transaction ID'),
  amount: t('amount'),
  comment: t('comment')
};

class RefundTransactionForm extends MobxReactForm {}

export {
  RefundTransactionForm,
  refundTransactionFormOptions,
  refundTransactionFormFields,
  refundTransactionFormRules,
  refundTransactionFormLabels,
  refundTransactionFormPlugins
};
