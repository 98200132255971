import UIStore from './UIStore';

import { action, computed, observable, runInAction } from 'mobx';
import errorHandler from 'utils/errorHandler';

import request from 'axios';
import sortBy from 'lodash.sortby';

import { t } from 'utils/translate';

export default class PayPeriodSelectorUI extends UIStore {
  @observable selectedPeriod;
  @observable recentPayPeriodOptions;
  @observable payPeriodsOptions;
  @observable loading;

  constructor(options) {
    super(options);

    this.selectedPeriod = {
      name: '',
      value: {
        from: '',
        to: ''
      }
    };

    // Pay periods
    this.recentPayPeriodOptions = [];
    this.payPeriodsOptions = [];
    this.loading = false;
  }

  @computed get hasPayPeriodsOptions() {
    return this.payPeriodsOptions.length > 0;
  }

  @computed get payPeriodsLoaded() {
    return Boolean(!this.loading && this.hasPayPeriodsOptions);
  }

  @action.bound
  async setup() {
    const url = this.rootStore.isSuperAdmin
      ? `${this.rootStore.urlMicroService('performanceTracking')}/companies/${
          this.rootStore.superAdminUI.superAdminCompaniesUI.activeCompany.id
        }/payperiods`
      : `${this.rootStore.urlMicroService('performanceTracking')}/payperiods`;

    this.loading = true;

    try {
      //catch last periods as a first step and unblock Select Period filter
      const responseRecent = await request.get(url, {
        params: {
          period: 'recent'
        }
      });

      runInAction(() => {
        this.transformRecentPeriodsIntoOptions(responseRecent.data);
        this.payPeriodsOptions = this.recentPayPeriodOptions;
        // Make first pay period currently selected if we don't already have a pay period
        if (!this.selectedPeriod.value.from && !this.selectedPeriod.value.to) {
          this.selectedPeriod = this.payPeriodsOptions[0];
        } else {
          this.selectedPeriod = { ...this.selectedPeriod };
        }
      });

      //fetch other periods after in background
      const responseAll = await request.get(url, {
        params: {
          period: 'ALL'
        }
      });

      runInAction(() => {
        const allPeriodOptions = this.transformAllPeriodsIntoOptions(
          responseAll.data
        );
        this.payPeriodsOptions = allPeriodOptions;
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  tearDown() {
    this.selectedPeriod = {
      name: '',
      value: {
        from: '',
        to: ''
      }
    };
  }

  @action.bound
  selectPeriod(period) {
    this.selectedPeriod = period;
    this.page = 1;
  }

  transformRecentPeriodsIntoOptions(data) {
    const rawRecentPayPeriodOptions = [];

    for (const year of data.years) {
      for (const month of year.months) {
        for (const period of month.periods) {
          rawRecentPayPeriodOptions.push({
            name: period.displayName,
            value: {
              from: period.fromDate,
              to: period.toDate
            },
            year: period.year,
            month: period.month
          });
        }
      }
    }

    const sortedRecentPayPeriodOptions = sortBy(rawRecentPayPeriodOptions, [
      'year',
      'month'
    ]).reverse();

    this.recentPayPeriodOptions = sortedRecentPayPeriodOptions;
  }

  transformAllPeriodsIntoOptions(data) {
    const payPeriodAllOptions = data.years.map(year => {
      const yearSubOptions = year.months.map(month => {
        const monthSubOptions = month.periods.map(period => {
          return {
            name: period.displayName,
            value: {
              from: period.fromDate,
              to: period.toDate
            }
          };
        });

        return {
          name: month.name,
          subOptions: monthSubOptions
        };
      });

      return {
        name: year.name.toString(),
        subOptions: yearSubOptions
      };
    });

    return [
      ...this.payPeriodsOptions,
      {
        name: t('View All'),
        subOptions: payPeriodAllOptions
      }
    ];
  }
}
