import request from 'axios';
import orderBy from 'lodash.orderby';
import isEqual from 'lodash.isequal';
import sortBy from 'lodash.sortby';
import { observable, action, computed } from 'mobx';

import * as Validator from 'validatorjs';

import UIStore from '../UIStore';

import history from 'utils/history';
import { t } from 'utils/translate';

import alertErrorHandler from 'utils/alertErrorHandler';
import crossOriginDownload from 'utils/crossOriginDownload';

import { callTrack } from 'utils/segmentIntegration';
import { PRODUCTION_BUDGET_UPLOADED } from 'utils/segmentAnalytics/eventSpec';

export default class SettingsBudgetsImportUI extends UIStore {
  @observable file;

  @observable step;

  // Workers
  @observable importedBudgetsPageSize;
  @observable importedBudgetsPage;

  constructor(options) {
    super(options);

    this.file = null;

    this.step = 'import';

    this.importedBudgetsPageSize = 10;
    this.importedBudgetsPage = 1;
    this.importedBudgets = observable([]);
  }

  @action.bound setStep(step) {
    this.step = step;
  }

  @action.bound setup(step = 'import') {
    this.setStep(step);
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound cancelBudgets() {
    this.importedBudgets.clear();
    this.setStep('import');
  }

  @action.bound async submitBudgetsForm() {
    if (this.saving) return;

    try {
      this.saving = true;

      const formData = new FormData();
      formData.append('file', this.file);

      await request.post(
        `${this.rootStore.urlMicroService(
          'performanceTracking'
        )}/costcodes/budget/import?projectUuid=${
          this.project.uuid
        }&limit=1000&offset=0`,
        formData
      );

      // TODO: Once API suppoerts
      // this.parent.sortByLastCreated();
      this.parent.refetchBudgets();
      this.cancelBudgetsImport();

      callTrack(PRODUCTION_BUDGET_UPLOADED);

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Budgets added')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound cancelBudgetsImport() {
    history.push(
      `${this.project.viewUrl}/settings/production-tracking/budgets`
    );
  }

  @action.bound
  downloadBudgetsCSV(event) {
    event.preventDefault();

    crossOriginDownload(
      `${this.assetsURL}/csv/budgets.csv`,
      `${t('budgets')}.csv`
    );
  }

  @action.bound importBudgetsCSV(file) {
    this.clearValidationDetails();
    this.file = file;

    const reader = new FileReader();

    reader.onload = event => {
      const text = event.target.result;
      this.processCSV(text);
    };

    reader.readAsText(this.file);
  }

  @action.bound processCSV(text, delim = ',') {
    let headers = text
      .trim()
      .slice(0, text.indexOf('\n'))
      .split(delim);

    const rows = text
      .trim()
      .slice(text.indexOf('\n') + 1)
      .split('\n');

    headers = headers.map(header => header.replace('\r', '').replace(/"/g, ''));

    const expectedHeaders = [
      'DIVISION',
      'COST CODE',
      'DESCRIPTION',
      'BUDGETED HOURS',
      'BUDGETED MATERIAL',
      'BUDGETED QUANTITY',
      'UNIT OF MEASUREMENT'
    ];

    if (!isEqual(sortBy(expectedHeaders), sortBy(headers))) {
      this.setValidationDetails([
        {
          field: 'import',
          fieldMessage: t(
            'Your CSV is empty or does not follow the correct format. Please use the pre-formatted template.'
          )
        }
      ]);

      return;
    }

    const budgetFields = rows.map(row => {
      const values = row.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);

      //check if a number of values in a row equals to a header columns
      if (values.length !== headers.length) {
        this.setValidationDetails([
          {
            field: 'import',
            fieldMessage: t(
              'Your CSV is empty or does not follow the correct format. Please use the pre-formatted template.'
            )
          }
        ]);

        return;
      }

      const eachObject = headers.reduce((obj, header, i) => {
        obj[
          header
            .trim()
            .replace('COST ', '')
            .toLowerCase()
        ] = values[i].trim();
        return obj;
      }, {});
      return eachObject;
    });

    this.populateBudgetsFormFromCSV(budgetFields);
  }

  @action.bound populateBudgetsFormFromCSV(budgetFields) {
    this.importedBudgets.replace(
      budgetFields.filter(budget => {
        let validation = new Validator(budget, {
          division: 'string|required|max:50',
          code: 'string|required|max:20',
          description: 'string|max:60'
        });

        return !validation.fails();
      })
    );

    if (!this.hasImportedBudgets) {
      this.setValidationDetails([
        {
          field: 'import',
          fieldMessage:
            'The CSV file has no valid entries. Please check it and upload again.'
        }
      ]);

      return;
    }

    // Limit to 1000 valid records
    this.importedBudgets.replace(this.importedBudgets.slice(0, 1000));

    this.setStep('budgets');
  }

  @computed get hasImportedBudgets() {
    return this.importedBudgets.length > 0;
  }

  @computed get showImportedBudgetsPagination() {
    return this.importedBudgetsTotalPages > 1;
  }

  @computed get sortedImportedBudgets() {
    return orderBy(this.importedBudgets, ['divison', 'code'], ['asc', 'asc']);
  }

  @computed get paginatedImportedBudgets() {
    return this.sortedImportedBudgets.slice(
      (this.importedBudgetsPage - 1) * this.importedBudgetsPageSize,
      (this.importedBudgetsPage - 1) * this.importedBudgetsPageSize +
        this.importedBudgetsPageSize
    );
  }

  @computed
  get importedBudgetsTotalPages() {
    return Math.ceil(
      this.sortedImportedBudgets.length / this.importedBudgetsPageSize
    );
  }

  @action.bound setImportedBudgetsPage(event, page) {
    this.importedBudgetsPage = page;
  }

  @action.bound removeImportedCostCode(budge) {
    this.importedBudgets.remove(budge);

    if (!this.hasImportedBudgets) {
      this.setStep('import');
    }
  }

  @action.bound clearUIState() {
    this.step = 'import';
    this.file = null;
    this.clearValidationDetails();
    this.loading = false;
    this.importedBudgets.clear();
    this.importedBudgets.page = 1;
  }
}
