import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { Icon } from 'raken-ui';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${props => props.padding};

  ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  li {
    a {
      display: block;
      font-size: 1.6rem;
      font-weight: 300;
      padding: 1.1rem 0.7rem;
      margin: 0 0.6rem;
      border-bottom: 4px solid transparent;
      box-sizing: border-box;
      user-select: none;
      cursor: pointer;
      outline: none;
    }

    &:hover,
    &.selected {
      a {
        border-color: ${props => props.theme.colors.rakenOrange};
        font-weight: bold;
      }
    }

    &.previous a {
      margin-right: 4.5rem;
    }

    &.next a {
      margin-left: 4.5rem;
    }

    &.previous,
    &.next {
      a {
        cursor: pointer;
        border: none;
        padding-top: 0.7rem;
      }

      &.disabled {
        a {
          cursor: default;
          opacity: 0.5;
        }
      }
    }
  }
`;

export default class Pagination extends Component {
  static propTypes = {
    initialPage: PropTypes.number,
    pageCount: PropTypes.number,
    pageRange: PropTypes.number,
    marginPages: PropTypes.number,
    breakLabel: PropTypes.bool,
    handlePageChange: PropTypes.func.isRequired,
    disableInitialCallback: PropTypes.bool,
    padding: PropTypes.string
  };

  static defaultProps = {
    initialPage: 30,
    pageCount: 30,
    pageRange: 5,
    marginPages: 0,
    breakLabel: false,
    disableInitialCallback: true,
    padding: '20px'
  };

  render() {
    const {
      initialPage,
      pageCount,
      pageRange,
      marginPages,
      breakLabel,
      handlePageChange,
      disableInitialCallback,
      forcePage,
      padding,
      dataQA
    } = this.props;

    return (
      <Wrapper padding={padding} data-qa={`div_pagination-${dataQA}`}>
        <ReactPaginate
          initialPage={initialPage}
          previousLabel={<Icon kind="left-arrow" size="20" />}
          nextLabel={<Icon kind="right-arrow" size="20" />}
          pageCount={pageCount}
          pageRangeDisplayed={pageRange}
          marginPagesDisplayed={marginPages}
          breakLabel={breakLabel}
          onPageChange={handlePageChange}
          disableInitialCallback={disableInitialCallback}
          forcePage={forcePage}
        />
      </Wrapper>
    );
  }
}
