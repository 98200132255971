import moment from 'moment';
import { action, computed } from 'mobx';
import ProjectChildEditUI from './ProjectChildEditUI';
import Task from '../../models/Task';

import {
  TaskForm,
  taskFormOptions,
  taskFormFields,
  taskFormRules,
  taskFormLabels,
  taskFormPlugins
} from 'forms/project/task';

import history from 'utils/history';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';

import { callTrack } from 'utils/segmentIntegration';
import { TASK_EDITED } from 'utils/segmentAnalytics/eventSpec';

export default class TaskEditUI extends ProjectChildEditUI {
  @action.bound setup(id) {
    this.memberSelectorUI.setup({
      projectUuids: this.memberProjectUuids.slice(),
      role: [
        'ROLE_ACCOUNT_ADMIN',
        'ROLE_ADMIN',
        'ROLE_PROJECT_MEMBER',
        'ROLE_USER'
      ],
      sortField: 'company.name, firstName, lastName'
    });

    this.fetchEntry(id);
  }

  @action.bound tearDown() {
    this.memberSelectorUI.tearDown();
    this.unblockHistory();
    this.clearUIState();
  }

  @action.bound async fetchEntry(uuid) {
    if (this.entryForEdit) return;

    let model = this.parent.tasks.getByIdOrUuid(uuid);

    if (!model) {
      model = new Task(
        {
          type: 'Task',
          uuid: uuid,
          // TODO: Post Super Daily 2.0 Release
          // Tidy up by making idAttribute `uuid` on Note
          id: uuid
        },
        {
          rootStore: this.rootStore
        }
      );
    }

    try {
      await model.fetch();

      this.setEntryForEdit(model);
    } catch (error) {
      this.cancelTaskEdit();
    }
  }

  @action.bound setEntryForEdit(model) {
    this.parent.parent.setDate(model.reportDate);
    this.entryForEdit = model;

    this.entryEditForm = new TaskForm(
      {
        fields: taskFormFields,
        rules: taskFormRules,
        values: this.entryForEdit.formValues,
        labels: taskFormLabels
      },
      {
        options: taskFormOptions,
        plugins: taskFormPlugins
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @action.bound async submitEntryEditFormSuccess() {
    this.saving = true;

    const values = this.entryEditForm.values();

    const payload = {
      desc: values.desc,
      status: values.status,
      dueDate: moment(values.dueDate).format('YYYY-MM-DD'),
      assignee: {
        uuid: values.assignee.uuid
      }
    };

    try {
      await this.entryForEdit.save(payload, {
        wait: true
      });

      callTrack(TASK_EDITED, {
        project_name: this.project?.name
      });

      this.unblockHistory();
      this.parent.refetchTasks();
      this.cancelTaskEdit();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Task saved')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound cancelTaskEdit() {
    history.push({
      pathname: `${this.project.viewUrl}/tasks`,
      search: this.baseQueryParams
    });
  }

  @computed get hasWriteAccess() {
    return this.entryForEdit?.canEdit;
  }

  @computed get title() {
    if (this.hasWriteAccess) {
      return t('Edit task');
    }

    return t('View task');
  }

  @computed get memberProjectUuids() {
    return this.parent.memberProjectUuids;
  }

  @action.bound uploadAttachment(uploadItem) {
    if (!this.entryForEdit.canEdit) {
      this.authorization.showModal('featureAccess');
      return;
    }

    super.uploadAttachment(uploadItem);
  }
}
