import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import generateQAIdentifier from 'utils/generateQAIdentifier';
import media from 'utils/mediaTemplate';
import Text from './Text';
import DesktopOnly from './DesktopOnly';
import VerticalDivider from './VerticalDivider';

const StatFigureSize = size => {
  if (size === 'small') {
    return css`
      font-size: 1.4rem;
    `;
  }

  return css`
    font-size: 2.6rem;

    ${media.desktop`
      font-size: 3rem;
    `};

    ${media.large`
      font-size: 3.6rem;
    `};
  `;
};

const Wrapper = styled.div`
  align-items: center;
  display: flex;

  ${media.desktop`
    padding: 0 ${props => (props.isExpanded ? 0.5 : 2.5)}rem;
  `}

  ${props =>
    props.minWidth &&
    `
    min-width: ${props.minWidth};
  `};

  ${props =>
    props.center &&
    `
    justify-content: center;
  `};
`;

const Stat = styled.div`
  flex: 1;
`;

const StatFigure = styled(Text)`
  display: block;
  text-align: center;

  ${props => StatFigureSize(props.size)}
`;

const StatItem = ({
  figure,
  label,
  bold,
  size,
  numberBold,
  color,
  labelTransform,
  labelSize,
  colorNegativeNumber,
  colorOfNumberMoreThan100Percent,
  qaLabel
}) => {
  qaLabel = qaLabel || generateQAIdentifier(label);

  if (colorNegativeNumber || colorOfNumberMoreThan100Percent) {
    let figureValue = figure;

    if (typeof figureValue === 'string') {
      figureValue = figure.replace(/,/g, '');
    }

    color = figureValue < 0 ? colorNegativeNumber : color || 'goGreen';
    color =
      figureValue > 100 ? colorOfNumberMoreThan100Percent : color || 'goGreen';
  } else {
    color = color || 'goGreen';
  }

  return (
    <Stat data-qa={`div_stat-${qaLabel}`}>
      <StatFigure
        light
        bold={bold || numberBold}
        data-qa={`div_stat-figure-${qaLabel}`}
        size={size}
        color={color}
      >
        {figure}
      </StatFigure>
      <Text
        data-qa={`div_stat-label-${qaLabel}`}
        block
        align="center"
        noWrap
        light
        bold={bold}
        size={labelSize}
      >
        {label}
      </Text>
    </Stat>
  );
};

export default class StatsGroup extends Component {
  static propTypes = {
    stats: PropTypes.arrayOf(
      PropTypes.shape({
        figure: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
          .isRequired,
        wrapper: PropTypes.func
      })
    ).isRequired,
    minWidth: PropTypes.string,
    gutterDesktop: PropTypes.number,
    size: PropTypes.string
  };

  static defaultProps = {
    gutterDesktop: 35,
    size: 'default'
  };

  render() {
    const { stats, gutterDesktop, center, isExpanded, size } = this.props;
    const renderedStats = [];

    stats.forEach((stat, index) => {
      if (stat.wrapper) {
        renderedStats.push(
          stat.wrapper(<StatItem size={size} {...stat} />, index)
        );
      } else {
        renderedStats.push(<StatItem size={size} key={index} {...stat} />);
      }

      renderedStats.push(
        <DesktopOnly key={(index + 1) * 10}>
          <VerticalDivider
            color="lightGrey3"
            height={50}
            gutterDesktop={isExpanded ? 10 : gutterDesktop}
          />
        </DesktopOnly>
      );
    });

    renderedStats.pop();

    return (
      <Wrapper
        center={center}
        minWidth={this.props.minWidth}
        isExpanded={isExpanded}
      >
        {renderedStats}
      </Wrapper>
    );
  }
}
