import { Model } from 'mobx-mc';

export default class DocumentsConstant extends Model {
  get restAttributes() {
    return ['name', 'fileTypes'];
  }

  get urlRoot() {
    return `/ra/companies/${this.rootStore.me.company.uuid}/document-storage/file-types`;
  }
}
