import { RakenCollection } from '../lib';
import Note from '../models/Note';

export default class Notes extends RakenCollection {
  model() {
    return Note;
  }

  url() {
    return `/ra/companies/${this.rootStore.me.company.uuid}/notes`;
  }
}
