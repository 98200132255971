import moment from 'moment';
import { action, computed } from 'mobx';
import ProjectChildAddUI from './ProjectChildAddUI';
import Task from '../../models/Task';

import {
  TaskForm,
  taskFormOptions,
  taskFormFields,
  taskFormRules,
  taskFormValues,
  taskFormLabels,
  taskFormPlugins
} from 'forms/project/task';

import history from 'utils/history';
import alertErrorHandler from 'utils/alertErrorHandler';

import { t } from 'utils/translate';

import { callTrack } from 'utils/segmentIntegration';
import { TASK_ADDED } from 'utils/segmentAnalytics/eventSpec';

export default class TaskAddUI extends ProjectChildAddUI {
  @action.bound setup() {
    this.memberSelectorUI.setup({
      projectUuids: this.memberProjectUuids.slice(),
      role: [
        'ROLE_ACCOUNT_ADMIN',
        'ROLE_ADMIN',
        'ROLE_PROJECT_MEMBER',
        'ROLE_USER'
      ],
      sortField: 'company.name, firstName, lastName'
    });

    this.entryForAdd = new Task(
      { type: 'Task' },
      {
        rootStore: this.rootStore
      }
    );

    this.entryAddForm = new TaskForm(
      {
        fields: taskFormFields,
        rules: taskFormRules,
        values: taskFormValues,
        labels: taskFormLabels
      },
      {
        options: taskFormOptions,
        plugins: taskFormPlugins
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @action.bound tearDown() {
    this.memberSelectorUI.tearDown();
    this.unblockHistory();
    this.clearUIState();
  }

  @action.bound async submitEntryAddFormSuccess() {
    this.saving = true;

    try {
      const attachments = this.entryForAdd.attachments.models.map(
        attachment => {
          return {
            uuid: attachment.uuid
          };
        }
      );

      const values = this.entryAddForm.values();

      await this.entryForAdd.save(
        Object.assign(
          Object.assign(values, {
            dueDate: moment(values.dueDate).format('YYYY-MM-DD')
          }),
          this.reportParams,
          {
            attachments: attachments
          }
        ),
        {
          wait: true,
          stripNonRest: false
        }
      );

      callTrack(TASK_ADDED, {
        project_id: this.project?.id,
        project_name: this.project?.name,
        due_date: this.entryForAdd.dueDate,
        task_id: this.entryForAdd.id,
        has_attachments: this.entryForAdd.hasAttachments
      });

      this.unblockHistory();
      this.parent.sortByLastCreated();
      this.cancelTaskAdd();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Task created')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound cancelTaskAdd() {
    history.push({
      pathname: `${this.project.viewUrl}/tasks`,
      search: this.baseQueryParams
    });
  }

  @computed get title() {
    return t('Add task');
  }

  @computed get memberProjectUuids() {
    return this.parent.memberProjectUuids;
  }
}
