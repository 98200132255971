import debounce from 'lodash.debounce';
import omit from 'lodash.omit';
import { action, computed } from 'mobx';
import ReportEntryWithAttachments from './ReportEntryWithAttachments';
import kebabCase from 'lodash.kebabcase';
import { t } from 'utils/translate';
import { BASE_DEBOUNCE } from 'fixtures/constants';

import Question from 'stores/models/Question';
import ChecklistObservations from 'stores/collections/ChecklistObservations';

export default class Answer extends ReportEntryWithAttachments {
  constructor(attributes, options) {
    super(attributes, options);

    this.saveDebounced = debounce(this.save, BASE_DEBOUNCE);
  }

  get restAttributes() {
    return [
      'uuid',
      'type',
      'createdTimestamp',
      'updatedTimestamp',
      'createdBy',
      'answer',
      'desc',
      'project',
      'reportId',
      'reportDate',
      'pdfs',
      'teamId',
      'segmentUuid',
      'observations'
    ];
  }

  idAttribute() {
    return 'uuid';
  }

  @computed
  get urlToSaveDescriptionAndAnswer() {
    return `${this.relatedReport.url()}/survey/answer`;
  }

  @action.bound
  parse(attributes) {
    this.parseCreatedBy(attributes);
    this.parseUpdatedBy(attributes);
    this.parseProject(attributes);
    this.parseAttachments(attributes);
    this.parseQuestion(attributes);
    this.parseObservations(attributes);

    return {
      ...omit(attributes, [
        'attachments',
        'question',
        'createdBy',
        'updatedBy',
        'project',
        'observations'
      ])
    };
  }

  @computed get relatedCompany() {
    return this.collection.parent.company;
  }

  @action.bound
  parseQuestion(attributes) {
    this.question = new Question(attributes.question, {
      rootStore: this.rootStore
    });
  }

  @computed
  get title() {
    return 'Surveys';
  }

  @computed get slug() {
    return kebabCase(this.question?.question);
  }

  @computed
  get formValues() {
    return {
      desc: this.desc
    };
  }

  @computed
  get isEmpty() {
    return !this.desc && this.answer === 'NA' && !this.hasAttachments;
  }

  @computed get displayAnswer() {
    switch (this.answer) {
      case 'NA':
        return t('N/A');
      case 'YES':
        return t('Yes');
      case 'NO':
        return t('No');
      default:
        return t('N/A');
    }
  }

  @computed get displayColor() {
    switch (this.answer) {
      case 'NA':
        return 'grey';
      case 'YES':
        return 'green';
      case 'NO':
        return 'red';
      default:
        return 'grey';
    }
  }

  @action.bound parseObservations(attributes) {
    if (this.observations) {
      this.observations.reset(attributes.observations);
    } else {
      this.observations = new ChecklistObservations(attributes.observations, {
        parent: this,
        rootStore: this.rootStore
      });
    }
  }

  @computed get hasObservations() {
    return this.observations.hasModels;
  }

  @action.bound addObservation(observationResponse) {
    this.observations.add(observationResponse);
  }

  @action.bound updateObservation(observationResponse) {
    this.observations.add(observationResponse, {
      update: true,
      merge: true
    });
  }

  @action.bound removeObservation(uuid) {
    const removedObservation = this.observations.get(uuid);

    if (removedObservation) {
      this.observations.remove(removedObservation);
    }
  }
}
