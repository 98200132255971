import MobxReactForm from 'mobx-react-form';
import validatorjs from 'validatorjs';
import { t } from 'utils/translate';

const emailChecklistFormPlugins = { dvr: validatorjs };

const emailChecklistFormOptions = {
  validateOnInit: true,
  validateOnChange: true,
  strictUpdate: false
};

const emailChecklistFormFields = ['emails[]', 'email', 'locale'];

const emailChecklistFormRules = {
  emails: 'array|required',
  email: 'required_without:emails|email'
};

const emailChecklistFormLabels = {
  emails: t('Send to')
};

class EmailChecklistForm extends MobxReactForm {}

export {
  EmailChecklistForm,
  emailChecklistFormOptions,
  emailChecklistFormFields,
  emailChecklistFormRules,
  emailChecklistFormLabels,
  emailChecklistFormPlugins
};
