import request from 'axios';
import pickBy from 'lodash.pickby';
import debounce from 'lodash.debounce';
import identity from 'lodash.identity';
import { action, computed, reaction, observable } from 'mobx';
import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';
import { t } from 'utils/translate';
import trimObject from 'utils/trimObject';
import objectToCSV from 'utils/objectToCSV';
import Equipment from 'stores/models/Equipment';

const equipmentFormPlugins = extendedPlugins;

const equipmentFormOptions = {
  validateOnInit: false,
  strictUpdate: false,
  validateOnChange: true
};

const equipmentFormFields = [
  'name',
  'supplier',
  'equipmentId',
  'rate',
  'frequency',
  'owned'
];

const equipmentFormRules = {
  name: 'required|string|required|max:100',
  supplier: 'string|max:50',
  equipmentId: 'string|max:32',
  rate: 'numeric|greaterThan0|notMoreThanTwoDecimalPlaces|max:99999999.99', // BE setting: DECIMAL(10,2)
  frequency: 'required_with:rate'
};

const equipmentFormLabels = {
  name: t('name'),
  supplier: t('supplier'),
  equipmentId: t('equipment ID'),
  rate: t('rate'),
  frequency: t('frequency'),
  owned: t('type')
};

const equipmentFormValues = {
  owned: false,
  frequency: 'DAILY'
};

class EquipmentForm extends MobxReactForm {
  @observable existingEquipment;

  constructor(settings, options) {
    super(settings, options);

    this.rootStore = options.rootStore;

    this.existingEquipment = null;

    this.searchEquipment = debounce(this.searchEquipment, 250);

    this.reactToSearchParams = reaction(
      () => this.searchParams,
      searchParams => {
        if (searchParams) {
          this.searchEquipment();
        }
      }
    );
  }

  trimmedValues() {
    return trimObject(this.values());
  }

  @computed get searchParams() {
    return {
      name: this.$('name').value,
      equipmentId: this.$('equipmentId').value,
      supplier: this.$('supplier').value
    };
  }

  @computed get identificationString() {
    return objectToCSV(pickBy(this.searchParams, identity));
  }

  @action.bound async searchEquipment() {
    if (!this.searchParams) return;

    try {
      const response = await request.get(
        `${this.rootStore.urlMicroService('performanceTracking')}/equipment`,
        {
          params: this.searchParams
        }
      );

      if (response.data?.collection?.length) {
        this.existingEquipment = new Equipment(response.data.collection[0]);
      } else {
        this.existingEquipment = null;
      }
    } catch (error) {
      this.existingEquipment = null;
    }
  }
}

export {
  EquipmentForm,
  equipmentFormOptions,
  equipmentFormFields,
  equipmentFormRules,
  equipmentFormValues,
  equipmentFormLabels,
  equipmentFormPlugins
};
