import request from 'axios';
import orderBy from 'lodash.orderby';
import isEqual from 'lodash.isequal';
import sortBy from 'lodash.sortby';
import { observable, action, computed } from 'mobx';

import * as Validator from 'validatorjs';

import UIStore from '../UIStore';

import history from 'utils/history';
import { t } from 'utils/translate';

import alertErrorHandler from 'utils/alertErrorHandler';
import crossOriginDownload from 'utils/crossOriginDownload';

export default class SettingsCostCodesImportUI extends UIStore {
  @observable file;

  @observable step;

  // Workers
  @observable importedCostCodesPageSize;
  @observable importedCostCodesPage;

  constructor(options) {
    super(options);

    this.file = null;

    this.step = 'import';

    this.importedCostCodesPageSize = 10;
    this.importedCostCodesPage = 1;
    this.importedCostCodes = observable([]);
  }

  @action.bound setStep(step) {
    this.step = step;
  }

  @action.bound setup(step = 'import') {
    this.setStep(step);
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound cancelCostCodes() {
    this.importedCostCodes.clear();
    this.setStep('import');
  }

  @action.bound async submitCostCodesForm() {
    if (this.saving) return;

    try {
      this.saving = true;

      const formData = new FormData();
      formData.append('file', this.file);

      await request.post(
        `${this.rootStore.urlMicroService(
          'performanceTracking'
        )}/costcodes/import?projectUuid=${this.project.uuid}&limit=20&offset=0`,
        formData
        //config
      );

      // TODO: Once API suppoerts
      // this.parent.sortByLastCreated();
      this.parent.refetchCostCodes();
      this.cancelCostCodesImport();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Cost codes added')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound cancelCostCodesImport() {
    history.push(
      `${this.project.viewUrl}/settings/production-tracking/costcodes`
    );
  }

  @action.bound
  downloadCostCodesCSV(event) {
    event.preventDefault();

    crossOriginDownload(
      `${this.assetsURL}/csv/costcodes.csv`,
      `${t('costcodes')}.csv`
    );
  }

  @action.bound importCostCodesCSV(file) {
    this.clearValidationDetails();
    this.file = file;

    const reader = new FileReader();

    reader.onload = event => {
      const text = event.target.result;
      this.processCSV(text);
    };

    reader.readAsText(this.file);
  }

  @action.bound processCSV(text, delim = ',') {
    let headers = text
      .trim()
      .slice(0, text.indexOf('\n'))
      .split(delim);

    const rows = text
      .trim()
      .slice(text.indexOf('\n') + 1)
      .split('\n');

    headers = headers.map(header => header.replace('\r', '').replace(/"/g, ''));

    const expectedHeaders = ['DIVISION', 'COST CODE', 'DESCRIPTION'];

    if (!isEqual(sortBy(expectedHeaders), sortBy(headers))) {
      this.setValidationDetails([
        {
          field: 'import',
          fieldMessage: t(
            'Your CSV is empty or does not follow the correct format. Please use the pre-formatted template.'
          )
        }
      ]);

      return;
    }

    const costCodeFields = rows.map(row => {
      const values = row.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);

      //check if a number of values in a row equals to a header columns
      if (values.length !== headers.length) {
        this.setValidationDetails([
          {
            field: 'import',
            fieldMessage: t(
              'Your CSV is empty or does not follow the correct format. Please use the pre-formatted template.'
            )
          }
        ]);

        return;
      }

      const eachObject = headers.reduce((obj, header, i) => {
        obj[
          header
            .trim()
            .replace('COST ', '')
            .toLowerCase()
        ] = values[i].trim();
        return obj;
      }, {});
      return eachObject;
    });

    this.populateCostCodesFormFromCSV(costCodeFields);
  }

  @action.bound populateCostCodesFormFromCSV(costCodeFields) {
    this.importedCostCodes.replace(
      costCodeFields.filter(costCode => {
        let validation = new Validator(costCode, {
          division: 'string|required|max:50',
          code: 'string|required|max:20',
          description: 'string|max:60'
        });

        return !validation.fails();
      })
    );

    if (!this.hasImportedCostCodes) {
      this.setValidationDetails([
        {
          field: 'import',
          fieldMessage:
            'The CSV file has no valid entries. Please check it and upload again.'
        }
      ]);

      return;
    }

    // Limit to 1000 valid records
    this.importedCostCodes.replace(this.importedCostCodes.slice(0, 1000));

    this.setStep('costcodes');
  }

  @computed get hasImportedCostCodes() {
    return this.importedCostCodes.length > 0;
  }

  @computed get showImportedCostCodesPagination() {
    return this.importedCostCodesTotalPages > 1;
  }

  @computed get sortedImportedCostCodes() {
    return orderBy(this.importedCostCodes, ['divison', 'code'], ['asc', 'asc']);
  }

  @computed get paginatedImportedCostCodes() {
    return this.sortedImportedCostCodes.slice(
      (this.importeCostCodesPage - 1) * this.importedCostCodesPageSize,
      (this.importedCostCodesPage - 1) * this.importedCostCodesPageSize +
        this.importedCostCodesPageSize
    );
  }

  @computed
  get importedCostCodesTotalPages() {
    return Math.ceil(
      this.sortedImportedCostCodes.length / this.importedCostCodesPageSize
    );
  }

  @action.bound setImportedCostCodesPage(event, page) {
    this.importedCostCodesPage = page;
  }

  @action.bound removeImportedCostCode(costCode) {
    this.importedCostCodes.remove(costCode);

    if (!this.hasImportedCostCodes) {
      this.setStep('import');
    }
  }

  @action.bound clearUIState() {
    this.step = 'import';
    this.file = null;
    this.clearValidationDetails();
    this.loading = false;
    this.importedCostCodes.clear();
    this.importedCostCodes.page = 1;
  }
}
