import { computed, action, observable } from 'mobx';

import UIStore from 'stores/ui/UIStore';

import ProjectsMinimal from 'stores/collections/ProjectsMinimal';
import GroupsActionsProjectsUI from './GroupsActionsProjectsUI';
import GroupsActionsMembersUI from './GroupsActionsMembersUI';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

export default class GroupsActionsUI extends UIStore {
  @observable selectedGroup;
  @observable selectedGroupAction;

  constructor(options) {
    super(options);

    // Individual Actions
    this.selectedGroup = null;
    this.selectedGroupAction = null;

    // Bulk Actions
    this.selectedGroups = observable([], { deep: false });

    // Bulk Project Actions
    this.groupsActionsProjectsUI = new GroupsActionsProjectsUI({
      parent: this,
      rootStore: this.rootStore
    });

    // Bulk Member Actions
    this.groupsActionsMembersUI = new GroupsActionsMembersUI({
      parent: this,
      rootStore: this.rootStore
    });

    // Bulk Project Actions
    this.paginatedProjects = new ProjectsMinimal(null, {
      rootStore: this.rootStore
    });
  }

  @computed get groups() {
    return this.parent.groups;
  }

  @computed get projects() {
    return this.paginatedProjects;
  }

  @computed get hasProjects() {
    return this.projects.hasModels;
  }

  // Bulk Actions
  @computed get bulkGroupActions() {
    let actions = [
      {
        title: t('Add to projects'),
        onClick: () => {
          this.bulkGroupAction('ADD_PROJECTS');
        }
      },
      {
        title: t('Add to employees'),
        onClick: () => {
          this.bulkGroupAction('ADD_MEMBERS');
        }
      }
    ];

    return actions;
  }

  @computed get hasSelectedGroups() {
    return this.selectedGroups.length > 0;
  }

  @computed get hasNoSelectedGroups() {
    return this.selectedGroups.length === 0;
  }

  @computed get allGroupsSelected() {
    if (!this.hasSelectedGroups) return false;

    return (
      this.groups.models.filter(group => {
        return this.findSelectedGroup(group);
      }).length === this.groups.length
    );
  }

  findSelectedGroup = group => {
    return this.selectedGroups.find(
      selectedGroup => selectedGroup.uuid === group.uuid
    );
  };

  @action.bound toggleSelectAllGroups() {
    if (this.allGroupsSelected) {
      this.groups.models.forEach(group => {
        this.selectedGroups.remove(this.findSelectedGroup(group));
      });
    } else {
      this.groups.models.forEach(group => {
        if (!this.findSelectedGroup(group)) {
          this.selectedGroups.push(group);
        }
      });
    }
  }

  @action.bound toggleSelectGroup(group) {
    const selectedGroup = this.findSelectedGroup(group);

    if (selectedGroup) {
      this.selectedGroups.remove(selectedGroup);
    } else {
      this.selectedGroups.push(group);
    }
  }

  @action.bound async bulkGroupAction(action) {
    await this.authorization.checkFeatureAccess('CUDGroups');

    this.selectedGroupAction = action;
    this.showModal('GroupBulkActionModal');
  }

  @action.bound async cancelBulkGroupAction() {
    await this.hideActiveModal();
    this.selectedGroupAction = null;
  }

  @computed get bulkGroupActionTitle() {
    if (!this.selectedGroupAction) return null;

    switch (this.selectedGroupAction) {
      case 'ADD_PROJECTS':
        return t(`Add to projects`);
      case 'ADD_MEMBERS':
        return t(`Add to employees`);
      default:
        return '';
    }
  }

  @computed get bulkGroupActionNotificationText() {
    switch (this.selectedGroupAction) {
      case 'ADD_PROJECTS':
        return t(`Groups added to projects`);
      case 'ADD_MEMBERS':
        return t(`Groups added to employees`);
      default:
        return '';
    }
  }

  @action.bound async confirmBulkGroupAction() {
    this.clearValidationDetails();
    this.saving = true;

    try {
      switch (this.selectedGroupAction) {
        case 'ADD_PROJECTS':
          await this.bulkAddProjects();
          break;
        case 'ADD_MEMBERS':
          await this.bulkAddMembers();
          break;
      }

      this.parent.loading = true;
      this.parent.setPage(null, 1);
      this.parent.fetchGroups();

      await this.hideActiveModal();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: this.bulkGroupActionNotificationText
      });

      this.selectedGroups.clear();
      this.selectedGroupAction = null;
      this.selectedGroupRole = null;
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound async bulkAddProjects() {
    await this.groupsActionsProjectsUI.addGroupsToProjects();
  }

  @action.bound async bulkAddMembers() {
    await this.groupsActionsMembersUI.addGroupsToMembers();
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.saving = false;
    this.selectedGroup = null;
    this.selectedGroupAction = null;
    this.selectedGroups.clear();
  }
}
