import { computed, action, observable } from 'mobx';
import UIStore from './UIStore';
import request from 'axios';
import { t } from 'utils/translate';
import errorHandler from 'utils/errorHandler';

export default class ProjectComplianceMissedDailiesUI extends UIStore {
  @observable signed;
  @observable saveSignature;
  @observable showBulkSignModal;
  @observable missedReportToSign;
  constructor(options) {
    super(options);

    // Reference to compliance parent store
    this.projectComplianceUI = options.projectComplianceUI;

    // Signature pad state
    this.saveSignature = false;
    this.signed = false;

    this.showBulkSignModal = false;
    this.missedReportToSign = null;
  }

  @computed
  get project() {
    return this.projectComplianceUI.project;
  }

  @computed
  get missedDailies() {
    return this.project.internalProjectTeam.missedDailies.models;
  }

  @action.bound
  async markNoWorkDone(missedDaily) {
    try {
      const payload = {
        projectUuid: missedDaily.project.uuid,
        reportDate: missedDaily.dateFor
      };

      if (missedDaily.segmentUuid) {
        payload.segmentUuid = missedDaily.segmentUuid;
      }

      await request.post(
        `ra/companies/${this.company.uuid}/reports/noworkdone`,
        { reports: [payload] }
      );

      missedDaily.collection.remove(missedDaily);

      this.notifications.pushNotification({
        showUndo: false,
        snackbar: 'warning',
        icon: 'notification',
        title: t('Report for {date} marked as No Work Done', {
          templateStrings: { date: missedDaily.dateFor }
        })
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  @action.bound
  notify(missedDailies) {
    this.authorization
      .checkFeatureAccess('NotifyMissedDailies')
      .then(() => {
        const projectTeamId = missedDailies[0].projectTeamId;

        const payload = missedDailies.map(missedDaily => {
          const obj = {
            reportDate: missedDaily.dateFor
          };
          if (missedDaily.segmentUuid) {
            obj.segmentUuid = missedDaily.segmentUuid;
          }
          return obj;
        });
        request
          .post(
            `/ra/project-teams/${projectTeamId}/dailies/missed/notify-with-segments`,
            payload
          )
          .then(() => this.notifySuccess())
          .catch(error => {
            errorHandler(error, this.rootStore.notificationsUI.pushError);
          });
      })
      .catch(error => {
        errorHandler(error, this.rootStore.notificationsUI.pushError);
      });
  }

  @action.bound
  bulkNotify = (
    projectTeamId,
    notifyCollaborators = false,
    showPopUp = true,
    fromDate,
    toDate
  ) => {
    this.authorization
      .checkFeatureAccess('NotifyMissedDailies')
      .then(() => {
        if (showPopUp) {
          this.notifySuccess(true);
        }
        return request
          .post(
            `/ra/project-teams/${projectTeamId}/dailies/missed/notify/all`,
            {
              fromDate,
              toDate,
              notifyCollaborators
            }
          )
          .catch(error => {
            errorHandler(error, this.rootStore.notificationsUI.pushError);
          });
      })
      .catch(error => {
        console.log(error);
      });
  };

  @action.bound
  notifySuccess(plural = false) {
    this.rootStore.notificationsUI.pushNotification({
      showUndo: false,
      title: plural ? t('Notifications Sent') : t('Notification Sent')
    });
  }
}
