import styled from 'styled-components';
import { Observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { Form } from 'raken-ui';

import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline';

import {
  Button,
  Img,
  Container,
  Box,
  Text,
  TextField,
  Alert
} from '@raken/athens-web/lib';

import { t } from 'utils/translate';

const StyledContainer = styled(Container)`
  min-height: 100vh;
`;

const AppConnectText = ({ uiStore }) => {
  return (
    <Observer>
      {() => {
        if (uiStore.activeIntegration.isRyvit) {
          return (
            <Text align="center">
              {t(
                'Please enter a Ryvit Subscriber Code to sync this Raken account with Vista.'
              )}
            </Text>
          );
        } else if (uiStore.activeIntegration.isSageIntacct) {
          return (
            <Text align="center">
              {t(
                'Please enter the Web Service User credentials provided by the client to connect this account to Sage Intacct.'
              )}
            </Text>
          );
        } else {
          return (
            <Text align="center">
              {t(
                'Please enter {integrationName} credentials to sync your Raken account with your {integrationName} account.',
                {
                  templateStrings: {
                    integrationName: uiStore.activeIntegration.name
                  }
                }
              )}
            </Text>
          );
        }
      }}
    </Observer>
  );
};

const AppConnectForm = ({ uiStore }) => {
  return (
    <Observer>
      {() => {
        if (uiStore.activeIntegration.isRyvit) {
          return (
            <Form
              data-qa="form_app-connect"
              onSubmit={uiStore.submitActiveForm}
            >
              <TextField
                placeholder={t('Ryvit Subscriber Code')}
                dataQA="subscriberCode"
                value={uiStore.activeForm.$('subscriberCode').value}
                onChange={uiStore.activeForm.$('subscriberCode').sync}
                error={uiStore.activeForm.$('subscriberCode').error}
                helperText={uiStore.activeForm.$('subscriberCode').error}
                fullWidth
                autoFocus
              />

              <Button
                disabled={uiStore.saving}
                type="submit"
                fullWidth
                dataQA="submit"
              >
                {uiStore.saving ? t('Saving...') : t('Save')}
              </Button>
            </Form>
          );
        } else if (uiStore.activeIntegration.isSageIntacct) {
          const {
            companyId,
            userId,
            password
          } = uiStore.activeIntegration.connectPlaceholders;
          return (
            <Form
              data-qa="form_app-connect"
              onSubmit={uiStore.submitActiveForm}
            >
              <TextField
                placeholder={companyId}
                dataQA="companyId"
                value={uiStore.activeForm.$('companyId').value}
                onChange={uiStore.activeForm.$('companyId').sync}
                error={uiStore.activeForm.$('companyId').error}
                helperText={uiStore.activeForm.$('companyId').error}
                fullWidth
                autoFocus
              />

              <TextField
                placeholder={userId}
                dataQA="userId"
                value={uiStore.activeForm.$('userId').value}
                onChange={uiStore.activeForm.$('userId').sync}
                error={uiStore.activeForm.$('userId').error}
                helperText={uiStore.activeForm.$('userId').error}
                fullWidth
              />

              <TextField
                placeholder={password}
                dataQA="password"
                value={uiStore.activeForm.$('password').value}
                onChange={uiStore.activeForm.$('password').sync}
                error={uiStore.activeForm.$('password').error}
                helperText={uiStore.activeForm.$('password').error}
                type="password"
                fullWidth
              />

              <Button
                disabled={uiStore.saving}
                type="submit"
                fullWidth
                dataQA="submit"
              >
                {uiStore.saving ? t('Signing in') : t('Sign in')}
              </Button>
            </Form>
          );
        } else {
          const {
            company,
            user,
            password
          } = uiStore.activeIntegration.connectPlaceholders;
          return (
            <Form
              data-qa="form_app-connect"
              onSubmit={uiStore.submitActiveForm}
            >
              <TextField
                placeholder={company}
                dataQA="companyName"
                value={uiStore.activeForm.$('companyName').value}
                onChange={uiStore.activeForm.$('companyName').sync}
                error={uiStore.activeForm.$('companyName').error}
                helperText={uiStore.activeForm.$('companyName').error}
                fullWidth
                autoFocus
              />

              <TextField
                placeholder={user}
                dataQA="username"
                value={uiStore.activeForm.$('username').value}
                onChange={uiStore.activeForm.$('username').sync}
                error={uiStore.activeForm.$('username').error}
                helperText={uiStore.activeForm.$('username').error}
                fullWidth
              />

              <TextField
                placeholder={password}
                dataQA="password"
                value={uiStore.activeForm.$('password').value}
                onChange={uiStore.activeForm.$('password').sync}
                error={uiStore.activeForm.$('password').error}
                helperText={uiStore.activeForm.$('password').error}
                type="password"
                fullWidth
              />

              <Button
                disabled={uiStore.saving}
                type="submit"
                fullWidth
                dataQA="submit"
              >
                {uiStore.saving ? t('Signing in') : t('Sign in')}
              </Button>
            </Form>
          );
        }
      }}
    </Observer>
  );
};

const AppConnect = ({ store, location, match }) => {
  const uiStore = store.appConnectUI;

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    uiStore.initializeConnect({
      integrationId: params.get('integrationId'),
      companyId: params.get('companyId'),
      companyUuid: params.get('companyUuid'),
      projectId: params.get('projectId'),
      projectUuid: params.get('projectUuid')
    });
  }, [uiStore, location]);

  return (
    <Observer>
      {() => {
        if (
          uiStore.loading ||
          !uiStore.activeIntegration ||
          !uiStore.activeForm
        )
          return null;

        return (
          <ScopedCssBaseline>
            <StyledContainer mx="auto" height="100%">
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                <Box mb={2}>
                  <Img
                    src={uiStore.activeIntegration.logoLarge}
                    alt={uiStore.activeIntegration.name}
                    data-qa="image_active-integration-logo"
                    height="55"
                  />
                </Box>
                <Box mb={4}>
                  <Text variant="h1" dataQA="raken" bold>
                    {'Sign In'}
                  </Text>
                </Box>
                <Box mb={6}>
                  <AppConnectText uiStore={uiStore} />
                </Box>
                {uiStore.hasValidationDetails && (
                  <Box mb={6} width="100%">
                    <Alert>
                      {uiStore.validationDetails.map(item => item.fieldMessage)}
                    </Alert>
                  </Box>
                )}
                <Box width="100%">
                  <AppConnectForm uiStore={uiStore} />
                </Box>
              </Box>
            </StyledContainer>
          </ScopedCssBaseline>
        );
      }}
    </Observer>
  );
};

export default AppConnect;
