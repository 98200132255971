import Moment from 'moment';
import { extendMoment } from 'moment-range';
import capitalize from 'lodash.capitalize';
import { computed } from 'mobx';
import { Model } from 'mobx-mc';
import { t } from 'utils/translate';
import {
  legacySeatsCost,
  SEATS_ENTERPRISE_ADDON,
  UNLIMITED_SEATS_NUMBER
} from 'utils/subscriptionData';
import formatCurrency from 'utils/formatCurrency';

const moment = extendMoment(Moment);

export default class Subscription extends Model {
  get urlRoot() {
    return `/ra/companies/${this.company.uuid}/billing/subscriptions`;
  }

  get restAttributes() {
    return [
      'id',
      'uuid',
      'plan',
      'state',
      'currentPeriodStartedAt',
      'currentPeriodEndsAt',
      'currentTermStartedAt',
      'currentTermEndsAt',
      'remainingBillingCycles',
      'totalBillingCycles',
      'renewalBillingCycles',
      'revenueScheduleType',
      'autoRenew',
      'currency',
      'unitAmount',
      'quantity',
      'subtotal',
      'collectionMethod',
      'expirationReason',
      'createdAt',
      'updatedAt',
      'activatedAt',
      'canceledAt',
      'expiresAt',
      'total',
      'addOns',
      'addOnsTotal',
      'tax',
      'taxInfo'
    ];
  }

  get restAttributeDefaults() {
    return {
      addOns: []
    };
  }

  parse(response) {
    if (response.data) {
      return response.data[0];
    }

    return response;
  }

  @computed get company() {
    if (this.rootStore.isSuperAdmin) {
      return this.parent.company;
    }

    return this.rootStore.me.company;
  }

  @computed get stateFormatted() {
    if (this.onTrial) {
      return t('Trial');
    }

    return capitalize(this.state);
  }

  @computed get stateColor() {
    if (this.onTrialOrStarterPlan) {
      return 'orange';
    }

    switch (this.state) {
      case 'ACTIVE':
        return 'green';
      case 'EXPIRED':
      case 'CANCELED':
      case 'FAILED':
        return 'red';
      default:
        return 'green';
    }
  }

  @computed get stateTooltip() {
    switch (this.state) {
      case 'EXPIRED':
        return t(
          'This subscription has expired due to failed payment attempts. Please update the billing details and add a new subscription to restore access'
        );
      case 'CANCELED':
        return t(
          'This account requested to cancel their subscription, which is set to expire on {expiryDate}. Please reactivate the subscription if the customer wishes to retain access after that date.',
          {
            templateStrings: {
              expiryDate: this.renewsOn
            }
          }
        );
      default:
        return '';
    }
  }

  @computed get onTrial() {
    return this.plan?.id === '1003';
  }

  @computed get onStarter() {
    return this.plan?.id === '1000';
  }

  @computed get onTrialOrStarterPlan() {
    return this.onTrial || this.onStarter;
  }

  @computed get isExpired() {
    return this.state === 'EXPIRED';
  }

  @computed get isCanceled() {
    return this.state === 'CANCELED';
  }

  @computed get unitAmountFormatted() {
    if (this.onTrialOrStarterPlan) {
      return '-';
    }

    return formatCurrency(this.unitAmount);
  }

  @computed get addOnsTotalFormatted() {
    if (this.onTrialOrStarterPlan) {
      return '-';
    }

    return formatCurrency(this.addOnsTotal);
  }

  @computed get totalFormatted() {
    if (this.onTrialOrStarterPlan) {
      return '-';
    }

    return formatCurrency(this.total);
  }

  @computed get termBehaviour() {
    if (this.onTrialOrStarterPlan) {
      return '-';
    }

    if (this.autoRenew) {
      return t('Auto renewing');
    }

    return t('Not auto renewing');
  }

  @computed get collectionMethodFormatted() {
    if (this.onTrialOrStarterPlan) {
      return '-';
    }

    return capitalize(this.collectionMethod);
  }

  @computed get renewsOn() {
    if (this.onTrialOrStarterPlan) {
      return '-';
    }

    return moment(this.currentTermEndsAt).format('YYYY-MM-DD');
  }

  @computed get startedOn() {
    if (this.onTrialOrStarterPlan) {
      return '-';
    }

    return moment(this.currentTermStartedAt).format('YYYY-MM-DD');
  }

  @computed get startDate() {
    return this.startedOn;
  }

  @computed get nextBillOn() {
    if (this.onTrialOrStarterPlan) {
      return '-';
    }

    return moment(this.currentPeriodEndsAt).format('YYYY-MM-DD');
  }

  @computed get remainingBilling() {
    if (this.requiresCreation) {
      return null;
    }
    return this.remainingBillingCycles || null;
  }

  @computed get isFlatRate() {
    return (
      (this.plan?.code || '').startsWith('performance-enterprise-') &&
      !this.isLegacyPlan
    );
  }

  @computed get isLegacyPlan() {
    return this.plan?.code === 'performance-enterprise-legacy';
  }

  @computed get isStandardPlan() {
    return !this.isLegacyPlan && !this.isFlatRate;
  }

  @computed get addOnQuantity() {
    if (this.isStandardPlan) return 0;

    return this.seatsAddOn?.quantity;
  }

  @computed get addOnTiers() {
    if (this.isStandardPlan) return [];

    return this.seatsAddOn?.tiers;
  }

  @computed get seatsAddOn() {
    if (this.isStandardPlan) return null;

    return this.addOns.find(a =>
      a.addOn.code.startsWith(SEATS_ENTERPRISE_ADDON)
    );
  }

  @computed get selectedTier() {
    if (this.isStandardPlan) return null;

    return this.addOnTiers.find(tier => {
      return (
        this.addOnQuantity >= tier.startingQuantity &&
        this.addOnQuantity <= tier.endingQuantity
      );
    });
  }

  @computed get maxSeats() {
    const tier = this.selectedTier;
    return this.isLegacyPlan &&
      (tier?.endingQuantity || 0) >= UNLIMITED_SEATS_NUMBER
      ? this.addOnQuantity
      : tier?.endingQuantity;
  }

  @computed get selectedTierRange() {
    if (!this.selectedTier) return null;

    return `${this.selectedTier.startingQuantity} - ${
      this.selectedTier.endingQuantity >= UNLIMITED_SEATS_NUMBER
        ? t('Unlimited')
        : this.selectedTier.endingQuantity
    }`;
  }

  @computed get formValues() {
    // form values have to be strings (quantity), otherwise isPristine/isDirty not working
    return {
      plan: this.onStarter ? '' : this.plan?.id,
      quantity: (this.isStandardPlan
        ? this.quantity || ''
        : this.addOnQuantity
      ).toString(),
      collectionMethod: this.collectionMethod,
      term: this.renewalBillingCycles
    };
  }

  @computed get requiresCreation() {
    return this.isNew || this.onTrialOrStarterPlan || this.isExpired;
  }

  @computed get seatsInUse() {
    return this.company.seatsInUse;
  }

  @computed get workerSeatsInUse() {
    return this.company.workerSeatsInUse;
  }

  @computed get seatsRemaining() {
    if (this.isStandardPlan) {
      return this.quantity - this.seatsInUse;
    }

    if (this.isLegacyPlan) {
      return this.addOnQuantity - this.seatsInUse;
    }

    return this.selectedTier.endingQuantity - this.seatsInUse;
  }

  @computed get isMonthly() {
    return this.isStandardPlan && (this.plan?.code || '').includes('month');
  }

  @computed get periodType() {
    return this.isMonthly ? 'MONTHLY' : 'ANNUAL';
  }

  @computed get legacyData() {
    const {
      purchasedSeats,
      includedSeats,
      extra,
      extraSeats,
      addSeatsPrice,
      isUnlimited
    } = legacySeatsCost(this.addOns);
    // this.unitAmount for legacy plan is the fixed cost for included seats
    return {
      purchasedSeats,
      includedSeats,
      purchasedSeatsFormatted: isUnlimited ? t('Unlimited') : purchasedSeats,
      basePriceFormatted:
        `${formatCurrency(this.unitAmount)} (` +
        (isUnlimited
          ? t('Unlimited seats')
          : `${includedSeats} ${t('seats')}`) +
        ')',
      extraSeatsPriceFormatted:
        addSeatsPrice > 0
          ? `${formatCurrency(addSeatsPrice)} (${extraSeats} x ${formatCurrency(
              extra.unitAmount
            )})`
          : '-',
      estTotalFormatted: `${formatCurrency(this.total)}`,
      unitAmount: extra?.unitAmount || 0,
      isUnlimited
    };
  }

  @computed get planUnitAmountFormatted() {
    const { basePriceFormatted, unitAmount } = this.legacyData;
    return `Min ${basePriceFormatted}, extra seats ${formatCurrency(
      unitAmount
    )} each`;
  }

  @computed get legacyBasePriceFormatted() {
    return this.legacyData.basePriceFormatted;
  }

  @computed get isPromoAvailable() {
    const planCode = this.plan?.code || '';
    return planCode.startsWith('basic') || planCode.startsWith('professional');
  }

  @computed get hasTax() {
    return this.tax > 0;
  }

  @computed get taxInfoFormatted() {
    if (!this.hasTax) return '';

    return `${t('Includes estimated tax of')} ${formatCurrency(this.tax)} (${
      this.taxInfo?.region
    } ${this.taxInfo?.rate * 100}%).`;
  }
}
