import { action, observable, computed } from 'mobx';
import UIStore from 'stores/ui/UIStore';

import {
  AddCompanyForm,
  addCompanyOptions,
  addCompanyFields,
  addCompanyRules,
  addCompanyValues,
  addCompanyPlugins
} from 'forms/superAdmin/addCompany';

import parseGeolocation from 'utils/parseGeolocation';
import request from 'axios';
import alertErrorHandler from 'utils/alertErrorHandler';
import history from 'utils/history';
import { t } from 'utils/translate';

import searchedPlaceReaction from 'utils/searchedPlaceReaction';
import countryReaction from 'utils/countryReaction';

export default class SuperAdminCompanyAddUI extends UIStore {
  @observable searchedPlace = null;
  @observable loading = false;
  @observable saving = false;

  @action.bound
  async setup() {
    this.loading = true;
    this.initAddCompanyForm();
    this.setupReactions();
    this.loading = false;
  }

  @action.bound
  tearDown() {
    this.tearDownReactions();
    this.addCompanyForm = null;
    this.searchedPlace = null;
    this.clearValidationDetails();
  }

  @action.bound
  initAddCompanyForm() {
    this.addCompanyForm = new AddCompanyForm(
      {
        fields: addCompanyFields,
        rules: addCompanyRules,
        values: addCompanyValues
      },
      {
        options: addCompanyOptions,
        plugins: addCompanyPlugins,
        rootStore: this.rootStore
      }
    );
  }

  //Company Info

  @action.bound
  setSearchedPlace(geoCode) {
    this.searchedPlace = parseGeolocation(geoCode);
  }

  @action.bound
  setupReactions() {
    this.reactToSearchedPlace = searchedPlaceReaction(
      this,
      this.addCompanyForm
    );

    this.reactToCountry = countryReaction(this, this.addCompanyForm);
  }

  @action.bound
  tearDownReactions() {
    this.reactToSearchedPlace && this.reactToSearchedPlace();
    this.reactToCountry && this.reactToCountry();
  }

  @action.bound submitAddCompanyForm(e) {
    e.preventDefault();

    this.addCompanyForm.submit({
      onSuccess: this.submitAddCompanyFormSuccess,
      onError: this.submitAddCompanyFormError
    });
  }

  @action.bound
  async submitAddCompanyFormSuccess() {
    this.saving = true;
    const payload = this.getAddCompanyPayloadData(this.addCompanyForm.values());

    try {
      const response = await request.post(
        `${this.rootStore.apiURL}/ra/companies`,
        payload
      );

      const newCompany = response.data;
      this.rootStore.companies.add(newCompany);
      this.parent.closeAddNewCompanyPage();

      //if creating a company process succeeded but with some non critical errors [HTTP response 207],
      // then show errors on the subscription page
      const errors = response.data.errors;

      //open company account screen
      history.push(`/companies/${newCompany.uuid}/subscription-billing`);

      if (errors && errors.length) {
        setTimeout(() => {
          this.rootStore.notificationsUI.pushError({
            validationDetails: errors.map(errorText => {
              return {
                fieldMessage: errorText
              };
            })
          });
        }, 1000);
      }
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  submitAddCompanyFormError() {
    console.log(this.addCompanyForm.errors());
  }

  @computed
  get disableCreateNewCompanyButton() {
    return this.loading || this.saving || this.addCompanyForm?.hasError;
  }

  getAddCompanyPayloadData(data) {
    const payload = {
      ...data,
      salesRepresentative: { userId: this.me.userId }
    };

    return payload;
  }

  get countryOptions() {
    return this.rootStore.countries.asOptions;
  }

  @action.bound
  selectCountry(option) {
    this.addCompanyForm.$('address.country').set(option.value);
  }

  @computed get selectedCountry() {
    return this.countryOptions.find(country => {
      return country.value === this.addCompanyForm.$('address.country').value;
    });
  }

  @computed get stateOptions() {
    const value = this.addCompanyForm.$('address.state').value;

    if (!value || this.rootStore.regions.isValidUsState(value)) {
      return this.rootStore.regions.asOptions;
    }

    return [{ name: t('Unrecognized: ') + value, value: value }].concat(
      this.rootStore.regions.asOptions
    );
  }

  @action.bound
  selectState(option) {
    this.addCompanyForm.$('address.state').set(option.value);
  }

  @computed get selectedState() {
    return this.stateOptions.find(state => {
      return state.value === this.addCompanyForm.$('address.state').value;
    });
  }
}
