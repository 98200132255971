import React, { Component } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import media from '../utils/mediaTemplate';
import { t } from 'utils/translate';

import {
  PanelWrapper,
  PanelHeader,
  PanelHeaderInner,
  PanelBody,
  PanelFooter,
  Loader,
  Text
} from '.';

const Content = styled(({ loading, ...rest }) => <div {...rest} />)`
  visibility: ${props => (props.loading ? 'hidden' : 'visible')};
`;

const PanelHeaderBox = styled.div`
  ${media.desktop`
    display: flex;
    align-items: center;
    min-height: 30px;
  `};
`;

const SectionDisabled = styled.section`
  display: flex;
  flex: 1;
  position: relative;

  & > section {
    filter: blur(6px);
    overflow: hidden;
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    content: '';
  }
`;

const Placeholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 1rem;
`;

@observer
export default class extends Component {
  static defaultProps = {
    minHeight: 80,
    isOpen: false,
    headerShadow: true,
    headerBorder: true
  };

  renderLoader = () => {
    if (this.props.loading) {
      return (
        <Loader size={35} fixed={false} center={true} color="lightGrey1" />
      );
    }

    return null;
  };

  renderOpenState = () => {
    return (
      <Content loading={this.props.loading}>{this.props.openBody}</Content>
    );
  };

  renderClosedState = () => {
    return (
      <Content loading={this.props.loading}>{this.props.closedBody}</Content>
    );
  };

  renderHeader = () => {
    const { isOpen, headerShadow, headerBorder } = this.props;

    return (
      <PanelHeader
        open={isOpen}
        withShadow={headerShadow}
        withBorder={headerBorder}
      >
        <PanelHeaderInner>
          <PanelHeaderBox>{this.props.header}</PanelHeaderBox>
        </PanelHeaderInner>
      </PanelHeader>
    );
  };

  renderMobileNav = () => {
    return <div>{this.props.navigation}</div>;
  };

  renderPlaceholder = () => {
    const { disabled, placeholder } = this.props;

    if (!disabled) return null;

    if (placeholder) return placeholder;

    return (
      <Placeholder>
        <Text block size="30" color="charcoalGrey" light marginBottom="18">
          {t('Feature Unavailable')}
        </Text>
        <Text
          align="center"
          lineHeight="24px"
          block
          size="16"
          color="charcoalGrey"
        >
          {t(
            'Sorry, this feature is only available to Performance plan members.'
          )}{' '}
          <br />
          {t('Please upgrade your plan to unlock this feature.')}
        </Text>
      </Placeholder>
    );
  };

  renderPanel = () => {
    const {
      pdfComponent,
      margin,
      dataQA,
      isOpen,
      loading,
      padding,
      onToggle,
      minHeight,
      hideFooter
    } = this.props;

    return (
      <PanelWrapper
        ref={ref => (this.wrapperRef = ref)}
        margin={margin}
        open={this.props.isOpen}
        {...(dataQA && {
          'data-qa': `div_${dataQA}`
        })}
      >
        {this.renderHeader()}

        <PanelBody padding={padding} minHeight={isOpen ? minHeight : 80}>
          {this.renderLoader()}
          {isOpen && !loading && this.renderOpenState()}
          {(!isOpen || loading) && this.renderClosedState()}
        </PanelBody>

        {!hideFooter && (
          <PanelFooter>
            <span
              {...(dataQA && {
                'data-qa': `span_${dataQA}-footer`
              })}
              onClick={onToggle}
            >
              {t('Show')}{' '}
              <span>{this.props.isOpen ? t('Less') : t('More')}</span>
            </span>
          </PanelFooter>
        )}

        {this.renderMobileNav()}
        {pdfComponent}
      </PanelWrapper>
    );
  };

  render() {
    const { disabled } = this.props;

    if (disabled) {
      return (
        <SectionDisabled>
          {this.renderPlaceholder()}

          {this.renderPanel()}
        </SectionDisabled>
      );
    }

    return this.renderPanel();
  }
}
