import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const ModalBody = styled.div`
  background-color: ${props =>
    props.withHeader ? props.theme.colors.white : 'transparent'};
  padding: ${props => props.padding || '2rem'};
  text-align: ${props => (props.center ? 'center' : 'left')};
  ${props =>
    props.backgroundImage &&
    css`
      background-attachment: scroll;
      background-image: ${props =>
        props.backgroundImage ? `url(${props.backgroundImage})` : `none`};
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    `};
  ${props =>
    props.withHeader &&
    !props.withFooter &&
    css`
      min-height: 100vh;
    `};
  ${props =>
    props.minHeight &&
    css`
      min-height: ${props => props.minHeight}px;
    `};
  ${props =>
    props.maxHeight &&
    css`
      max-height: ${props => props.maxHeight}px;
      overflow: auto;
    `};
`;

ModalBody.propTypes = {
  shadow: PropTypes.bool.isRequired
};

ModalBody.defaultProps = {
  shadow: false
};

export default ModalBody;
