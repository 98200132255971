export default (text, obj) => {
  let newText = text;

  Object.keys(obj).forEach(key => {
    const regEx = new RegExp(`{${key}}`, 'gi');

    newText = newText.replace(regEx, obj[key]);
  });

  return newText;
};
