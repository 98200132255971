import { RakenCollection } from '../lib';
import ProjectTemplate from '../models/ProjectTemplate';
import { computed } from 'mobx';

export default class ProjectTemplates extends RakenCollection {
  model() {
    return ProjectTemplate;
  }

  url() {
    return '/ra/project-templates';
  }

  @computed
  get defaultTemplate() {
    return this.models.find(model => model.isDefault);
  }

  @computed
  get selectOptions() {
    return this.models.map(projectTemplate => ({
      value: projectTemplate.id,
      title: projectTemplate.displayName
    }));
  }
}
