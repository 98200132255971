import { observable } from 'mobx';
import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';
import trimObject from '../../utils/trimObject';

const projectTemplateDailyReportFormPlugins = extendedPlugins;

const projectTemplateDailyReportFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false,
  retrieveOnlyEnabledFields: false
};

const projectTemplateDailyReportFormFields = [
  'includeWeather',
  'includeHours',
  'includeQuantity',
  'includeWorkLogs',
  'includeNotes',
  'includeDailySurvey',
  'includeStampsForPhotos',
  'includeStampsForEntries',
  'hideEmptySections',
  'includeSafetyObservations',
  'includeQualityControlObservations',
  'includeToolboxTalks',
  'includeCompletedChecklists',
  'includeTimeCards',
  'includeMaterialQuantity',
  'includeEquipment',
  'hideInactiveSubcontractors',
  'hideEmptySubcontractors',
  'hideGrandTotal'
];

const projectTemplateDailyReportFormRules = {
  includeWeather: 'boolean',
  includeHours: 'boolean',
  includeQuantity: 'boolean',
  includeWorkLogs: 'boolean',
  includeNotes: 'boolean',
  includeDailySurvey: 'boolean',
  includeStampsForPhotos: 'boolean',
  includeStampsForEntries: 'boolean',
  hideEmptySections: 'boolean',
  includeSafetyObservations: 'boolean',
  includeQualityControlObservations: 'boolean',
  includeToolboxTalks: 'boolean',
  includeCompletedChecklists: 'boolean',
  includeTimeCards: 'boolean',
  includeMaterialQuantity: 'boolean',
  includeEquipment: 'boolean',
  hideInactiveSubcontractors: 'boolean',
  hideEmptySubcontractors: 'boolean',
  hideGrandTotal: 'boolean'
};

const projectTemplateDailyReportFormValues = {
  includeWeather: true,
  includeHours: true,
  includeQuantity: true,
  includeWorkLogs: true,
  includeNotes: true,
  includeDailySurvey: true,
  includeStampsForPhotos: true,
  includeStampsForEntries: true,
  hideEmptySections: false,
  includeSafetyObservations: true,
  includeQualityControlObservations: true,
  includeToolboxTalks: false,
  includeCompletedChecklists: false,
  includeTimeCards: true,
  includeMaterialQuantity: true,
  includeEquipment: true,
  hideInactiveSubcontractors: true,
  hideEmptySubcontractors: false,
  hideGrandTotal: false
};

class ProjectTemplateDailyReportForm extends MobxReactForm {
  @observable updatedSegmentStartTime;

  trimmedValues() {
    return trimObject(this.values());
  }
}

export {
  ProjectTemplateDailyReportForm,
  projectTemplateDailyReportFormOptions,
  projectTemplateDailyReportFormFields,
  projectTemplateDailyReportFormRules,
  projectTemplateDailyReportFormValues,
  projectTemplateDailyReportFormPlugins
};
