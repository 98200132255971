import moment from 'moment';
import debounce from 'lodash.debounce';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import history from 'utils/history';
import ScheduledTalks from '../../collections/ScheduledTalks';
import ProjectChildUI from './ProjectChildUI';

import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';
import { BASE_DEBOUNCE } from 'fixtures/constants';

import {
  ScheduleTalkForm,
  scheduleTalkFormFields,
  scheduleTalkFormRules,
  scheduleTalkFormLabels,
  scheduleTalkFormOptions,
  scheduleTalkFormPlugins,
  scheduleTalkFormValues
} from 'forms/scheduleTalk';

import { callTrack } from 'utils/segmentIntegration';
import { TBT_SCHEDULED } from 'utils/segmentAnalytics/eventSpec';

export default class ToolboxTalksAllUI extends ProjectChildUI {
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;
  @observable selectedTalk;
  @observable scheduleTalkForm;

  constructor(options) {
    super(options);

    this.loading = true;

    this.selectedTalk = null;
    this.scheduleTalkForm = null;

    this.sortField = 'talk.name';
    this.sortDirection = 'asc';
    this.searchQuery = '';
    this.page = 1;
    this.pageSize = 20;

    // Talks collection
    this.scheduledTalks = new ScheduledTalks(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchTalksDebounced = debounce(this.fetchTalks, BASE_DEBOUNCE);
  }

  @action.bound setup() {
    window.scrollTo(0, 0);
    this.setupReactions();
    this.fetchTalks();
    this.groupSelectorUI.setup();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.groupSelectorUI.tearDown();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchTalksDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      page: this.page - 1,
      query: this.searchQuery,
      sortField: this.sortField,
      sortDirection: this.sortDirection.toUpperCase()
    };
  }

  @action.bound async fetchTalks() {
    this.scheduledTalks.cancelRequest();
    this.scheduledTalks.clear();

    try {
      await this.scheduledTalks.fetch({
        url: `${this.rootStore.urlMicroService('toolbox')}/tbt/projects/${
          this.project.uuid
        }/project-talks/upcoming`,
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasTalks() {
    return this.scheduledTalks.hasModels;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.scheduledTalks.totalElements / this.pageSize);
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.searchQuery = '';
    this.scheduledTalks.clear();
    this.page = 1;
    this.loading = true;
    this.saving = false;
    this.sortField = 'talk.name';
    this.sortDirection = 'asc';
    this.selectedTalk = null;
    this.scheduleTalkForm = null;
  }

  @computed get showEmptyState() {
    return !this.loading && !this.searchQuery && !this.hasTalks;
  }

  @computed get showEmptySearchState() {
    return !this.loading && this.searchQuery && !this.hasTalks;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @computed get deleteTalkText() {
    return t('Please confirm that you want to delete this upcoming talk.');
  }

  @computed get deleteTalkTitle() {
    return t('Delete talk?');
  }

  @action.bound async deleteTalk(talk) {
    await this.authorization.checkFeatureAccess('DeleteScheduleToolboxTalks');

    this.selectedTalk = talk;
    this.showModal('DeleteModal');
  }

  @action.bound async cancelDeleteTalk() {
    await this.hideActiveModal();

    this.selectedTalk = null;
  }

  @action.bound async confirmDeleteTalk() {
    this.saving = true;

    try {
      await this.selectedTalk.destroy({ wait: true });

      this.refetchTalks();

      await this.hideActiveModal();

      this.selectedTalk = null;

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Scheduled talk deleted')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @computed get scheduleTalkTitle() {
    return this.selectedTalk?.name;
  }

  @computed get scheduleTalkText() {
    return t(
      'Select a date to re-schedule this talk for the {projectName} project.',
      {
        templateStrings: {
          projectName: this.project?.name
        }
      }
    );
  }

  @computed get scheduleSubmitText() {
    if (this.saving) {
      return t('Saving');
    }

    return t('Save');
  }

  @computed get minimumScheduleDate() {
    return Date.now();
  }

  @computed get selectedGroupUuids() {
    return this.scheduleTalkForm
      .$('groups')
      .values()
      .map(value => value.uuid);
  }
  @computed get selectedGroupOptions() {
    return this.groupSelectorUI.options.filter(option =>
      this.selectedGroupUuids.includes(option.uuid)
    );
  }

  @action.bound async scheduleTalk(talk) {
    await this.authorization.checkFeatureAccess('ScheduleToolboxTalks');

    this.selectedTalk = talk;

    this.scheduleTalkForm = new ScheduleTalkForm(
      {
        fields: scheduleTalkFormFields,
        rules: scheduleTalkFormRules,
        labels: scheduleTalkFormLabels,
        values: Object.assign(scheduleTalkFormValues, {
          date: this.selectedTalk.scheduleDate
        })
      },
      {
        options: scheduleTalkFormOptions,
        plugins: scheduleTalkFormPlugins
      }
    );

    this.showModal('ScheduleModal');
  }

  @action.bound submitScheduleTalkForm(event) {
    event.preventDefault();

    this.scheduleTalkForm.submit({
      onSuccess: this.submitScheduleTalkFormSuccess,
      onError: this.submitScheduleTalkFormError
    });
  }

  @action.bound async submitScheduleTalkFormSuccess() {
    this.saving = true;

    try {
      const values = this.scheduleTalkForm.values();

      const payload = {
        scheduleDate: moment(values.date).format('YYYY-MM-DD'),
        group: values.groups[0] ? { uuid: values.groups[0].uuid } : null
      };

      await this.selectedTalk.save(payload, {
        wait: true
      });

      this.hideActiveModal();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Talk updated')
      });

      callTrack(TBT_SCHEDULED, {
        project_id: this.project.id,
        project_name: this.project.name,
        company_level: false,
        project_level: true
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitScheduleTalkFormError() {
    console.error(this.submitScheduleTalkForm.errors());
  }

  @action.bound async cancelScheduleTalk() {
    await this.hideActiveModal();

    this.scheduleTalkForm = null;
    this.selectedTalk = null;
  }

  @action.bound refetchTalks() {
    this.loading = true;

    if (!this.hasTalks) {
      this.setPage(null, 1);
      this.fetchTalks();
    } else {
      this.fetchTalks();
    }
  }

  getActions = talk => {
    return [
      {
        title: t('Edit'),
        onClick: () => {
          this.scheduleTalk(talk);
        }
      },
      {
        title: t('Delete'),
        onClick: () => {
          this.deleteTalk(talk);
        }
      }
    ];
  };

  @action.bound async addToolboxTalk() {
    await this.authorization.checkFeatureAccess('UploadToolboxTalks');

    history.push({
      pathname: `${this.project.viewUrl}/toolbox-talks/all/add`,
      search: this.baseQueryParams
    });
  }
}
