import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import trimObject from 'utils/trimObject';

const memberCertificationsFormPlugins = extendedPlugins;

const memberCertificationsFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const memberCertificationsFormFields = [
  'memberCertifications[]',
  'memberCertifications[].uuid',
  'memberCertifications[].workerUuid',
  'memberCertifications[].workerName',
  'memberCertifications[].expirationDate'
];

const memberCertificationsFormRules = {
  'memberCertifications[].expirationDate': 'date|required'
};

const memberCertificationsFormValues = {
  memberCertifications: []
};

class MemberCertificationsForm extends MobxReactForm {
  trimmedValues() {
    return trimObject(this.values());
  }
}

export {
  MemberCertificationsForm,
  memberCertificationsFormOptions,
  memberCertificationsFormFields,
  memberCertificationsFormRules,
  memberCertificationsFormPlugins,
  memberCertificationsFormValues
};
