import { action, computed } from 'mobx';
import ProjectChildEditUI from './ProjectChildEditUI';
import Note from '../../models/Note';

import {
  NoteForm,
  noteFormOptions,
  noteFormFields,
  noteFormRules,
  noteFormLabels,
  noteFormPlugins
} from 'forms/note';

import history from 'utils/history';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';

export default class NoteEditUI extends ProjectChildEditUI {
  @action.bound async fetchEntry(uuid) {
    if (this.entryForEdit) return;

    let model = this.parent.notes.getByIdOrUuid(uuid);

    if (!model) {
      model = new Note(
        {
          type: 'Note',
          uuid: uuid,
          // TODO: Post Super Daily 2.0 Release
          // Tidy up by making idAttribute `uuid` on Note
          id: uuid
        },
        {
          rootStore: this.rootStore
        }
      );
    }

    try {
      await model.fetch();

      this.setEntryForEdit(model);
    } catch (error) {
      this.cancelNoteEdit();
    }
  }

  @action.bound setEntryForEdit(model) {
    this.parent.parent.setDate(model.reportDate);
    this.projectUI.setSegmentUuid(model.segmentUuid);
    this.entryForEdit = model;

    this.entryEditForm = new NoteForm(
      {
        fields: noteFormFields,
        rules: noteFormRules,
        values: this.entryForEdit.formValues,
        labels: noteFormLabels
      },
      {
        options: noteFormOptions,
        plugins: noteFormPlugins
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @action.bound async submitEntryEditFormSuccess() {
    this.saving = true;

    try {
      await this.entryForEdit.save(this.entryEditForm.values(), {
        wait: true
      });

      this.unblockHistory();
      this.parent.refetchNotes();
      this.cancelNoteEdit();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Note saved')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound cancelNoteEdit() {
    history.push({
      pathname: `${this.project.viewUrl}/notes`,
      search: this.baseQueryParams
    });
  }

  @computed get categoryOptions() {
    return this.parent.categoryOptions;
  }

  @computed get selectedCategoryOption() {
    return this.categoryOptions.find(
      option => option.value === this.entryEditForm.$('category').value
    );
  }

  @computed get title() {
    if (this.hasWriteAccess) {
      return t('Edit note');
    }

    return t('View note');
  }
}
