import MobxReactForm from 'mobx-react-form';
import { reaction, action } from 'mobx';
import extendedPlugins from '../utils/extendedPlugins';

const subscriptionEditFormPlugins = extendedPlugins;

const subscriptionEditFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const subscriptionEditFormFields = [
  'periodType',
  'autoRenew',
  'subscriptionState',
  'seats',
  'accountType',
  'startDate',
  'endDate',
  'trialDays',
  'discount',
  'isDiscountPermanent',
  'billingPlatform',
  'billingPlanKey',
  'salesRepresentative',
  'pricePerSeat',
  'isPromo',
  'promoEndDate',
  'scheduledBillingPlanKey',
  'trialEndDate',
  'hasTimeClockAccess'
];

const subscriptionEditFormRules = {
  periodType: 'required|string',
  autoRenew: 'boolean',
  subscriptionState: 'string',
  seats: 'required|numeric|min:1',
  billingPlanKey: 'required|string',
  startDate: 'required|string',
  endDate: 'string',
  accountType: 'string',
  trialEndDate: 'string',
  discount: 'numeric|between:-100,100',
  isDiscountPermanent: 'boolean',
  billingPlatform: 'required|string',
  pricePerSeat: 'numeric',
  isPromo: 'boolean|required_with:promoEndDate',
  promoEndDate: 'string|required_with:isPromo',
  scheduledBillingPlanKey: 'string'
};

const subscriptionEditFormLabels = {
  periodType: 'Period Type',
  billingPlanKey: 'Plan',
  autoRenew: 'Auto Renew',
  subscriptionState: 'Subscr State',
  startDate: 'Subscription Dates',
  accountType: 'Account Type',
  seats: 'Seats',
  discount: 'Discount (%)',
  isDiscountPermanent: 'One-Time',
  billingPlatform: 'Billing Platform',
  salesRepresentative: 'Sales Rep',
  isPromo: 'Promo',
  promoEndDate: 'Promo End Date',
  scheduledBillingPlanKey: 'Scheduled Plan',
  pricePerSeat: 'Unit Price',
  trialEndDate: 'Specific Date'
};

class SubscriptionEditForm extends MobxReactForm {
  constructor(settings, options) {
    super(settings, options);

    // this responds to the Promo checkbox toggle
    this.reactToPromoToggle = reaction(
      () => this.$('isPromo').value,
      value => {
        if (value) {
          this.requirePromoEndDate();
        } else {
          this.unRequirePromoEndDate();
        }

        this.$('promoEndDate').set('');
        this.$('promoEndDate').validate({ showErrors: true });
      }
    );

    // this responds to the Promo End Date input
    this.reactToPromoEndDate = reaction(
      () => this.$('promoEndDate').value,
      value => {
        if (this.$('isPromo').value) {
          this.requirePromoEndDate();
        } else {
          this.unRequirePromoEndDate();
        }

        this.$('promoEndDate').set(value);
      }
    );
  }

  @action.bound
  requirePromoEndDate() {
    this.$('promoEndDate').set(
      'rules',
      'required|dateCustom:YYYY-MM-DD|promoEndDate'
    );
  }

  @action.bound
  unRequirePromoEndDate() {
    this.$('promoEndDate').set('rules', 'string');
  }
}

export {
  SubscriptionEditForm,
  subscriptionEditFormOptions,
  subscriptionEditFormFields,
  subscriptionEditFormRules,
  subscriptionEditFormLabels,
  subscriptionEditFormPlugins
};
