import styled from 'styled-components';
import { borderStyle, borderWidth, borderRadius } from 'polished';

export default styled.div`
  background-color: ${props => props.theme.colors.white};
  ${borderStyle('none', 'solid', 'none', 'solid')};
  ${borderWidth('0', '1px', '0', '1px')};
  border-color: ${props => props.theme.colors.lightGrey5};
  box-sizing: border-box;
  flex-grow: 1;
  min-height: ${props => props.minHeight || 0}px;
  padding: ${props => props.padding || '10px 2rem 10px 2rem'};
  position: relative;
  &:last-child {
    ${borderRadius('bottom', '4px')};
  }
`;
