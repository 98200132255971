import kebabCase from 'lodash.kebabcase';
import { computed } from 'mobx';
import { Model } from 'mobx-mc';

import moment from 'moment';
import { t } from 'utils/translate';

export default class SuperAdminDemoAccount extends Model {
  get restAttributes() {
    return [
      'type',
      'username',
      'uuid',
      'password',
      'createdByUserId',
      'createdByUserName',
      'templateCompanyId',
      'demoCompanyName',
      'subscriptionState',
      'templateBusinessName',
      'createdDate',
      'status',
      'errorInfo'
    ];
  }

  get restAttributeDefaults() {
    return {
      status: 'SEEDING'
    };
  }

  idAttribute() {
    return 'uuid';
  }

  @computed get urlRoot() {
    return '/ra/sadmin/demos';
  }

  @computed
  get createdDateFormatted() {
    return moment(this.createdDate).format('YYYY-MM-DD');
  }

  @computed get statusFormatted() {
    if (this.hasErrors) {
      return t('Errors');
    }

    switch (this.status) {
      case 'COMPLETED':
        return t('Ready');
      case 'SEEDING':
        return t('Seeding...');
      default:
        return t('Seeding...');
    }
  }

  @computed get hasErrors() {
    return this.errorInfo?.length > 0;
  }

  @computed get statusColor() {
    if (this.hasErrors) {
      return 'red';
    }

    switch (this.status) {
      case 'COMPLETED':
        return 'green';
      case 'SEEDING':
        return 'orange';
      default:
        return 'green';
    }
  }

  @computed get slug() {
    return kebabCase(this.demoCompanyName);
  }
}
