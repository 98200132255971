import ProjectInsightsUIModel from './ProjectInsightsUIModel';
import { computed, action, observable } from 'mobx';
import { t } from 'utils/translate';
import escaperegexp from 'lodash.escaperegexp';

export default class ProjectInsightsChart extends ProjectInsightsUIModel {
  @observable chartProjectTeamSelection;
  @observable selectedChartProjectTeams;
  @observable chartProjectTeamSearch;
  @observable chartProjectTeamSelectionLimit;

  constructor(attributes, options) {
    super(attributes, options);

    // Project selection
    this.chartProjectTeamSelection = options.chartProjectTeamSelection;
    this.selectedChartProjectTeams = [];
    this.chartProjectTeamSearch = '';
    this.chartProjectTeamSelectionLimit =
      options.chartProjectTeamSelectionLimit || null;
  }

  url() {
    return '/ra/insights/statsByProjectTeam';
  }

  @computed get chartProjectTeams() {
    if (!this.parent.hasFilteredProjectTeams) return [];

    switch (this.chartProjectTeamSelection) {
      case 'MINE':
        return [this.project.internalProjectTeam];
      case 'RECENT':
        return this.parent.recentProjectTeams;
      default:
        return this.selectedChartProjectTeams;
    }
  }

  @computed get chartProjectTeamIds() {
    return this.chartProjectTeams.map(projectTeam => projectTeam.id);
  }

  @computed get hasChartProjectTeams() {
    return this.chartProjectTeams.length > 0;
  }

  @computed get nonChartProjectTeams() {
    return this.parent.filteredProjectTeams.filter(projectTeam => {
      return !this.chartProjectTeams.find(
        selectedProjectTeam => selectedProjectTeam.id === projectTeam.id
      );
    });
  }

  @computed get hasNonChartProjectTeams() {
    return this.nonChartProjectTeams.length > 0;
  }

  @action.bound
  setChartProjectTeamSelection(selection) {
    if (this.parent.applySelectionsToAllCharts) {
      if (selection === 'SELECTED') {
        this.parent.clearSelectedProjectTeamsOnAllCharts();
      }

      this.parent.setProjectTeamSelectionOnAllCharts(selection);
    } else {
      if (selection === 'SELECTED') {
        this.selectedChartProjectTeams.clear();
      }

      this.projectChartTeamSelection = selection;
    }
  }

  @action.bound
  toggleChartProjectTeam(projectTeam) {
    if (this.selectedChartProjectTeams.includes(projectTeam)) {
      if (this.atChartProjectTeamSelectionMin) {
        return;
      }

      if (this.parent.applySelectionsToAllCharts) {
        this.parent.removeProjectTeamFromAllCharts(projectTeam);
      } else {
        this.selectedChartProjectTeams.remove(projectTeam);
      }
    } else {
      if (this.atChartProjectTeamSelectionLimit) {
        return;
      }

      if (this.parent.applySelectionsToAllCharts) {
        this.parent.pushProjectTeamToAllCharts(projectTeam);
      } else {
        this.selectedChartProjectTeams.push(projectTeam);
      }
    }
  }

  @computed
  get chartProjectTeamSelectionString() {
    switch (this.chartProjectTeamSelection) {
      case 'MINE':
        return t('My Company');
      case 'RECENT':
        return t('Recently Updated');
      case 'SELECTED':
        return t('Selected Companies');
      default:
        return t('My Company');
    }
  }

  @computed get disableChartProjectTeamSelection() {
    return (
      !this.parent.hasFilteredProjectTeams ||
      this.parent.projectTeamSelection === 'MINE'
    );
  }

  @computed
  get hasChartProjectTeamSelections() {
    return this.selectedChartProjectTeams.length > 0;
  }

  @computed
  get remainingChartProjectTeamSelections() {
    return (
      this.chartProjectTeamSelectionLimit &&
      this.chartProjectTeamSelectionLimit -
        this.selectedChartProjectTeams.length
    );
  }

  @computed
  get atChartProjectTeamSelectionMin() {
    return this.selectedChartProjectTeams.length === 0;
  }

  @computed
  get atChartProjectTeamSelectionLimit() {
    return (
      this.chartProjectTeamSelectionLimit &&
      this.selectedChartProjectTeams.length >=
        this.chartProjectTeamSelectionLimit
    );
  }

  @computed
  get nonSelectedChartProjectTeams() {
    return this.parent.filteredProjectTeams.filter(projectTeam => {
      return !this.chartProjectTeamIds.includes(projectTeam.id);
    });
  }

  @computed
  get hasNonSelectedChartProjectTeams() {
    return this.nonSelectedChartProjectTeams.length > 0;
  }

  @action.bound
  setChartProjectTeamSearch(value) {
    this.chartProjectTeamSearch = value;
  }

  @action.bound
  clearChartProjectTeamSearch() {
    this.chartProjectTeamSearch = '';
  }

  @computed
  get searchedChartProjectTeams() {
    const searchExpression = new RegExp(
      escaperegexp(this.chartProjectTeamSearch),
      'i'
    );

    return this.parent.filteredProjectTeams.filter(projectTeam => {
      return projectTeam.name.search(searchExpression) !== -1;
    });
  }
}
