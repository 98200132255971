import { RakenCollection } from '../lib';
import Classification from '../models/Classification';
import { action, computed } from 'mobx';

export default class Classifications extends RakenCollection {
  model() {
    return Classification;
  }

  url() {
    return `/ra/companies/${this.company.uuid}/classifications`;
  }

  @action.bound clearNew() {
    this.models.forEach(model => {
      model.clearNew();
    });
  }

  @computed get company() {
    if (this.rootStore.isSuperAdmin) {
      return this.parent.activeCompany;
    }
    return this.rootStore.me.company;
  }
}
