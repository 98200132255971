import moment from 'moment';
import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import debounce from 'lodash.debounce';
import { action, observable, computed, reaction } from 'mobx';
import UIStore from './UIStore';
import { BASE_DEBOUNCE } from 'fixtures/constants';
import Tasks from '../collections/Tasks';

import { callTrack } from 'utils/segmentIntegration';
import { TASK_COMPLETED } from 'utils/segmentAnalytics/eventSpec';

import alertErrorHandler from 'utils/alertErrorHandler';

export default class TeamMemberNotificationsUI extends UIStore {
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;

  constructor(options) {
    super(options);

    this.tasks = new Tasks(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.loading = true;

    this.sortField = 'dueDate';
    this.sortDirection = 'desc';
    this.searchQuery = '';
    this.pageSize = 10;
    this.page = 1;

    this.fetchTasksDebounced = debounce(this.fetchTasks, BASE_DEBOUNCE);
  }

  @computed get entryForEdit() {
    return this.parent.entryForEdit;
  }

  @action.bound setup() {
    this.setupReactions();
    this.fetchTasks();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        this.fetchTasks();
      }
    );

    this.reactToSearchQuery = reaction(
      () => this.searchQuery,
      params => {
        this.fetchTasksDebounced();
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
    this.reactToSearchQuery && this.reactToSearchQuery();
  }

  @action.bound
  async fetchTasks() {
    this.loading = true;

    await this.tasks.fetch({
      url: `ra/users/${this.entryForEdit.userId}/tasks`,
      params: pickBy(
        Object.assign(this.params, {
          query: this.searchQuery
        }),
        identity
      )
    });

    this.loading = false;
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @computed get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      sortField: this.sortField,
      sortDirection: this.sortDirection,
      status: 'COMPLETED,OPEN',
      mc: 'full',
      mn: 'full'
    };
  }

  @computed get hasTasks() {
    return this.tasks.hasModels;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }
  }

  @computed
  get totalPages() {
    return Math.ceil(this.tasks.totalElements / this.pageSize);
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.tasks.reset();
    this.searchQuery = '';
    this.page = 1;
    this.loading = true;
  }

  @action.bound
  async toggleTaskStatus(task) {
    await this.authorization.checkFeatureAccess('EditTasks');

    let newStatus = task.status === 'OPEN' ? 'COMPLETED' : 'OPEN';

    if (newStatus === 'COMPLETED') {
      callTrack(TASK_COMPLETED, {
        complete_date: moment().format('l'),
        project_id: task.project.id,
        project_name: task.project.name,
        task_name: task.desc
      });
    }

    try {
      task.save(
        {
          status: newStatus
        },
        {
          parse: false
        }
      );
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }
  }
}
