import identity from 'lodash.identity';
import pickBy from 'lodash.pickby';
import debounce from 'lodash.debounce';
import { observable, action, computed, reaction } from 'mobx';
import UIStore from './UIStore';
import history from 'utils/history';
import { callTrack } from 'utils/segmentIntegration';
import { PROJECT_LIST_VIEWED } from 'utils/segmentAnalytics/eventSpec';

import ProjectsMinimal from '../collections/ProjectsMinimal';

import { BASE_DEBOUNCE } from 'fixtures/constants';

import ProjectsMapUI from './ProjectsMapUI';

import {
  ProjectsFiltersForm,
  projectsFiltersFormRules,
  projectsFiltersFormFields,
  projectsFiltersFormOptions,
  projectsFiltersFormPlugins
} from 'forms/projectsFilters';

export default class ProjectsUI extends UIStore {
  @observable page;
  @observable pageSize;
  @observable searchQuery;
  @observable sortField;
  @observable sortDirection;
  @observable searchedPlace;

  @observable filterForm;

  constructor(options) {
    super(options);

    this.paginatedProjects = new ProjectsMinimal(
      {},
      {
        rootStore: this.rootStore,
        parent: this
      }
    );

    this.projectsMapUI = new ProjectsMapUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.filterForm = null;

    this.pageSize = 20;
    this.page = 1;
    this.searchQuery = '';
    this.sortField = 'name';
    this.sortDirection = 'asc';

    this.searchedPlace = null;
    this.reactionsSet = false;

    this.fetchPaginatedProjectsDebounced = debounce(
      this.fetchPaginatedProjects,
      BASE_DEBOUNCE
    );

    this.groupFilters = observable([]);
    this.projectStateFilters = observable([{ id: 'ACTIVE', title: 'Active' }]);

    this.projectStateFilterOptions = [
      { id: 'ACTIVE', title: 'Active' },
      { id: 'INACTIVE', title: 'Inactive' },
      { id: 'DELETED', title: 'Deleted' }
    ];
  }

  @computed get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      sortField: this.sortField,
      sortDirection: this.sortDirection,
      projectStates: this.projectStateFilters
        .map(option => option.id)
        .join(','),
      groupUuids: this.groupFilters.map(group => group.uuid).join(',')
    };
  }

  @computed
  get totalPages() {
    return Math.ceil(this.paginatedProjects.totalElements / this.pageSize);
  }

  @action.bound
  async setup() {
    this.setupReactions();
    this.fetchPaginatedProjects();

    callTrack(PROJECT_LIST_VIEWED);
  }

  @action.bound
  async tearDown() {
    this.clearUIState();
    this.tearDownReactions();
  }

  setupReactions() {
    if (this.reactionsSet) return;

    this.cancelReactToParams = reaction(
      () => this.params,
      params => {
        this.scrollWindowToTop();
        this.fetchPaginatedProjectsDebounced();
      }
    );

    this.reactionsSet = true;
  }

  tearDownReactions() {
    this.cancelReactToParams();

    this.reactionsSet = false;
  }

  @action.bound
  async fetchPaginatedProjects() {
    this.paginatedProjects.reset();

    await this.paginatedProjects.fetch({
      params: pickBy(this.params, identity)
    });
  }

  @action.bound
  scrollWindowToTop() {
    window.scrollTo(0, 0);
  }

  @action.bound
  setPage(e, page) {
    this.page = page;
  }

  @action.bound
  setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound
  clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setProjectStateFilters(options) {
    this.projectStateFilters.replace(options);
  }

  @computed get selectedProjectStateFilterOptions() {
    return this.projectStateFilterOptions.filter(option => {
      return this.projectStateFilters
        .map(option => option.id)
        .includes(option.id);
    });
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }
  }

  @action.bound
  clearUIState() {
    this.activeModal = null;
    this.searchQuery = '';
    this.sortField = 'name';
    this.sortDirection = 'asc';
  }

  @action.bound
  showProject(project) {
    history.push(project.viewUrl);
  }

  /**
   * get CompanySubscriptionBillingUI store
   */
  @computed
  get companySubscriptionBillingUI() {
    return (
      this.rootStore.companyUI && this.rootStore.companyUI.subscriptionBillingUI
    );
  }

  @computed
  get projectCreationUI() {
    return this.rootStore.projectCreationUI;
  }

  @action.bound showFilters() {
    this.showModal('filters');
    this.setupFiltering();
  }

  @action.bound async hideFilters() {
    await this.hideActiveModal();
    this.tearDownFiltering();
  }

  @action.bound clearFilters() {
    this.projectStateFilters.clear();
    this.groupFilters.clear();
    this.hideFilters();
  }

  @action.bound async applyFilters(event) {
    event.preventDefault();

    const values = this.filterForm.values();

    this.projectStateFilters.replace(values.projectStateFilters);
    this.groupFilters.replace(values.groupFilters);
    this.page = 1;

    this.hideFilters();
  }

  @computed get appliedFiltersCount() {
    let count = 0;

    if (this.projectStateFilters.length > 0) {
      count++;
    }

    if (this.groupFilters.length > 0) {
      count++;
    }

    return count;
  }

  @computed get hasAppliedFilters() {
    return this.appliedFiltersCount > 0;
  }

  @action.bound setupFiltering() {
    this.groupSelectorUI.setup();

    this.filterForm = new ProjectsFiltersForm(
      {
        fields: projectsFiltersFormFields,
        rules: projectsFiltersFormRules,
        values: {
          projectStateFilters: this.projectStateFilters.slice(),
          groupFilters: this.groupFilters.slice()
        }
      },
      {
        options: projectsFiltersFormOptions,
        plugins: projectsFiltersFormPlugins
      }
    );
  }

  @action.bound tearDownFiltering() {
    this.groupSelectorUI.tearDown();
    this.filterForm = null;
  }
}
