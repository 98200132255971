import React from 'react';
import { observer } from 'mobx-react';
import { Table } from '@raken/athens-web/lib';

const FeatureFlagsTable = observer(({ debugUI }) => {
  return (
    <Table.Container>
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.HeaderCell
              sortable
              width="90%"
              onClick={() => debugUI.featureFlagsSortByColumn('name')}
              direction={debugUI.featureFlagsSortedByDirection}
              sorted={debugUI.featureFlagsSortedByColumn === 'name'}
            >
              {'Name'}
            </Table.HeaderCell>
            <Table.HeaderCell
              sortable
              sorted={debugUI.featureFlagsSortedByColumn === 'status'}
              direction={debugUI.featureFlagsSortedByDirection}
              onClick={() => debugUI.featureFlagsSortByColumn('status')}
            >
              {'Status'}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {debugUI.sortedFeatureFlags.map(featureFlag => (
            <Table.Row key={featureFlag.name}>
              <Table.TextCell>{featureFlag.name}</Table.TextCell>
              <Table.TextCell
                rakenColor={featureFlag.status === 'ON' ? 'green' : 'red'}
              >
                {featureFlag.status}
              </Table.TextCell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Table.Container>
  );
});

export default FeatureFlagsTable;
