import { RakenCollection } from '../lib';
import OverTimeRule from '../models/OverTimeRule';

export default class OverTimeRules extends RakenCollection {
  model() {
    return OverTimeRule;
  }

  url() {
    return `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/overtimerules`;
  }
}
