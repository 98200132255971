import { Model } from 'mobx-mc';
import { action, computed, observable } from 'mobx';
import objectToCSV from 'utils/objectToCSV';
import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import { t } from 'utils/translate';
import objectValuesToLowerCase from 'utils/objectValuesToLowerCase';
import upperFirst from 'lodash.upperfirst';

export default class Equipment extends Model {
  @observable new;

  constructor(data, options) {
    super(data, options);

    this.new = false;
  }

  get restAttributes() {
    return [
      'uuid',
      'name',
      'supplier',
      'equipmentId',
      'owned',
      'rate',
      'frequency',
      'createdTimestamp',
      'updatedTimestamp',
      'createdBy',
      'updatedBy',
      'available',
      'inUseOrIdle',
      'condition',
      'deleted',
      'activeProject',
      'projectUuid'
    ];
  }

  get restAttributeDefaults() {
    return {
      projectUuid: null,
      owned: false,
      activeProject: {}
    };
  }

  idAttribute() {
    return 'uuid';
  }

  @computed get identificationValues() {
    return objectValuesToLowerCase({
      name: this.name,
      equipmentId: this.equipmentId
    });
  }

  @computed get identificationString() {
    return objectToCSV(
      pickBy(
        {
          name: this.name,
          supplier: this.supplier,
          equipmentId: this.equipmentId
        },
        identity
      )
    );
  }

  @computed get supplierString() {
    return objectToCSV(
      pickBy(
        {
          supplier: this.supplier,
          equipmentId: this.equipmentId
        },
        identity
      )
    );
  }

  @computed
  get formValues() {
    return {
      name: this.name ? this.name : '',
      supplier: this.supplier ? this.supplier : '',
      equipmentId: this.equipmentId ? this.equipmentId : '',
      rate: this.rate ? this.rate.toString() : '',
      frequency: this.frequency || 'DAILY',
      owned: this.owned
    };
  }

  @computed get frequencyString() {
    switch (this.frequency) {
      case 'MONTHLY':
        return t('per month');
      case 'WEEKLY':
        return t('per week');
      case 'HOURLY':
        return t('per hour');
      default:
        return t('per day');
    }
  }

  @computed get rateString() {
    return `$${Number(this.rate).toLocaleString('en-US')} ${
      this.frequencyString
    }`;
  }

  @computed get totalHoursString() {
    return Number(this.totalHours).toLocaleString('en-US');
  }

  @computed get conditionString() {
    return upperFirst(this.condition.toLowerCase().replace('_', ' '));
  }

  @computed get deployedToString() {
    return this.activeProject?.name;
  }

  @action.bound setAsNew() {
    this.new = true;
  }

  @action.bound clearNew() {
    this.new = false;
  }
}
