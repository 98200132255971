import kebabCase from 'lodash.kebabcase';
import { computed } from 'mobx';
import { Model } from 'mobx-mc';

export default class QuickbooksEmployee extends Model {
  get restAttributes() {
    return ['id', 'name', 'data'];
  }

  @computed get slug() {
    return kebabCase(this.name);
  }
}
