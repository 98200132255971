import { action, computed } from 'mobx';

import IntegrationsUI from 'stores/ui/company/integrations/IntegrationsUI';
import SuperAdminCompanyIntegrationUI from './SuperAdminCompanyIntegrationUI';

export default class SuperAdminIntegrationsUI extends IntegrationsUI {
  constructor(options) {
    super(options);

    this.integrationUI = new SuperAdminCompanyIntegrationUI({
      rootStore: this.rootStore,
      parent: this
    });
  }

  @computed get integrations() {
    return this.rootStore.integrations;
  }

  @computed
  get activeCompany() {
    return this.parent.activeCompany;
  }

  @computed get id() {
    return this.activeCompany.id;
  }

  @computed get baseUrl() {
    return `/companies/${this.activeCompany.uuid}/integrations`;
  }

  @action.bound clearUIState() {
    this.integrations.reset();
  }

  @computed get disableIntegrationsForTrialAccount() {
    if (!this.activeCompany.subscription?.onTrial) return false;

    return !this.activeCompany.companyAddOns?.enableIntegrationsForTrial;
  }
}
