import { Model } from 'mobx-mc';
import { action, computed, observable } from 'mobx';

import rankCollectionByValue from 'utils/rankCollectionByValue';
import sortProjectMeasuresByProjectNameOrStat from './utils/sortProjectMeasuresByProjectNameOrStat';

export default class extends Model {
  @observable isOpen;

  // Table
  @observable sortField;
  @observable rankField;
  @observable sortDirection;

  // Mobile Nav
  @observable mobileNavOpen;

  constructor(attributes, options) {
    super(attributes, options);

    this.isOpen = false;

    // Table
    this.sortField = options.sortField || 'name';
    this.rankField = options.rankField;
    this.sortDirection = 'asc';

    // Mobile Nav
    this.mobileNavOpen = false;
  }

  @computed get insightsUI() {
    return this.parent.parent;
  }

  @action.bound
  openMobileNav() {
    this.mobileNavOpen = true;
  }

  @action.bound
  closeMobileNav() {
    this.mobileNavOpen = false;
  }

  @action.bound
  parseProjectMeasures(projectMeasures) {
    if (this.rankField) {
      this.projectMeasures.reset(
        rankCollectionByValue(projectMeasures, `stats.${this.rankField}`)
      );
    } else {
      this.projectMeasures.reset(projectMeasures || []);
    }
  }

  @action.bound
  fetchStats() {
    if (this.insightsUI.projects?.fetching) return;

    this.fetch({
      params: this.params
    });
  }

  @computed
  get params() {
    if (this.insightsUI.projects?.fetching) return null;

    const params = {
      startDay: this.insightsUI.startDay,
      endDay: this.insightsUI.endDay
    };

    switch (this.insightsUI.projectSelection) {
      case 'ALL':
        params.projectStatuses = 'ACTIVE,INACTIVE';
        break;
      case 'ACTIVE':
        params.projectStatuses = 'ACTIVE';
        break;
      default:
        params.projectIds = this.insightsUI.filteredProjectIds.join(',');
        break;
    }

    return params;
  }

  @computed
  get hasProjectMeasures() {
    return this.projectMeasures && this.projectMeasures.length > 0;
  }

  @computed
  get sortedProjectMeasures() {
    return sortProjectMeasuresByProjectNameOrStat(
      this.projectMeasures.models,
      this.sortField,
      this.sortDirection
    );
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      if (this.sortDirection === 'asc') {
        this.sortDirection = 'desc';
      } else {
        this.sortDirection = 'asc';
      }
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }
  }

  @action.bound toggleOpen() {
    this.isOpen = !this.isOpen;
  }
}
