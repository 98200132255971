import { action, computed, observable, toJS } from 'mobx';
import SettingsChildUI from './SettingsChildUI';
import omit from 'lodash.omit';
import { callTrack } from 'utils/segmentIntegration';
import {
  SettingsSurveyForm,
  settingsSurveyFormOptions,
  settingsSurveyFormFields,
  settingsSurveyFormRules,
  settingsSurveyFormValues,
  settingsSurveyFormPlugins,
  settingsSurveyFormLabels,
  settingsSurveyFormDisabled
} from 'forms/project/settingsSurvey';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import { PROJECT_UPDATED } from 'utils/segmentAnalytics/eventSpec';
import {
  QuestionForm,
  questionFormOptions,
  questionFormFields,
  questionFormRules,
  questionFormPlugins
} from 'forms/question';
import { questionCategoriesData } from 'fixtures/collections';
import arrayMove from 'utils/arrayMove';
import uuid from 'uuid-v4';

export default class SettingsSurveyUI extends SettingsChildUI {
  @observable questionForm;
  @observable questionsTab = 'questions';

  @action.bound setup() {
    this.setupForm();
  }

  @action.bound tearDown() {
    this.clearUIState();
    this.unblockHistory();
  }

  @action.bound
  setupForm() {
    this.currentFormInitialValues = Object.assign(
      {},
      settingsSurveyFormValues,
      this.project.settingsFormValues
    );

    this.form = new SettingsSurveyForm(
      {
        fields: settingsSurveyFormFields,
        rules: settingsSurveyFormRules,
        disabled: settingsSurveyFormDisabled,
        values: toJS(this.currentFormInitialValues),
        labels: settingsSurveyFormLabels
      },
      {
        options: settingsSurveyFormOptions,
        plugins: settingsSurveyFormPlugins,
        parent: this
      }
    );

    this.questionForm = new QuestionForm(
      {
        fields: questionFormFields,
        rules: questionFormRules
      },
      {
        options: questionFormOptions,
        plugins: questionFormPlugins
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @action.bound
  async submitFormSuccess() {
    this.saving = true;
    const values = this.form.trimmedValues();
    const ommitedValues = ['default', 'questions', 'collaboratorQuestions'];
    const projectData = omit(values, ommitedValues);

    // Only send questions if they have changed.
    if (
      this.form.$('questions').check('isDirty') ||
      this.form.$('questions').size !== this.project.questions.length
    ) {
      projectData.questions = values.questions
        .filter(question => !question.readOnly && question.question)
        .map(question => {
          return {
            category: question.category,
            question: question.question
          };
        });
    }

    // Only send collaborator questions if they have changed.
    if (
      (this.form.$('collaboratorQuestions').check('isDirty') ||
        this.form.$('collaboratorQuestions').size !==
          this.project.collaboratorQuestions.length) &&
      !this.project.isChildProject
    ) {
      projectData.collaboratorQuestions = values.collaboratorQuestions
        .filter(question => question.question)
        .map(question => {
          return {
            category: question.category,
            question: question.question
          };
        });
    }

    try {
      await this.project.save(
        projectData,
        {
          url: `ra/projects/${this.project.uuid}`
        },
        {
          wait: true
        }
      );
      this.handleSaveSuccess();
    } catch (error) {
      this.handleSaveError(error);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  handleSaveSuccess() {
    this.unblockHistory();
    this.saving = false;

    this.notifications.pushNotification({
      snackbar: 'warning',
      icon: 'checkmark',
      title: t('Survey questions saved')
    });

    callTrack(PROJECT_UPDATED, {
      project_id: this.project?.id,
      project_name: this.project?.name
    });

    // Reset the header projects
    this.headerUI.headerProjectSelectorUI.resetProjectOptions();

    // Reinitialize form
    this.setupForm();
  }

  @action.bound
  handleSaveError(error) {
    alertErrorHandler(error, this.setValidationDetails);
    this.saving = false;
  }

  @computed get headerTitle() {
    return t('Add survey questions to your report');
  }

  @computed get hasWriteAccess() {
    return this.authorization.canEditSurveyQuestions;
  }

  @computed
  get currentFormChanged() {
    if (!this.form) return null;

    return (
      this.form.check('isDirty') ||
      this.form.$('questions').size !==
        this.project.questionFormValues.length ||
      this.form.$('collaboratorQuestions').size !==
        this.project.collaboratorQuestionFormValues.length
    );
  }

  @computed
  get categoryOptions() {
    return questionCategoriesData.collection.map(category => {
      return {
        value: category.value,
        name: category.displayName
      };
    });
  }

  @computed
  get questionValues() {
    return this.form.$(this.questionsTab).values();
  }

  @computed
  get questionIsInValid() {
    return (
      this.questionForm.$('category').check('hasError') ||
      this.questionForm.$('question').check('hasError') ||
      this.questionForm.$('category').check('isPristine') ||
      this.questionForm.$('question').check('isPristine')
    );
  }

  @computed
  get onBlur() {
    return () => {
      if (this.questionForm.$('question').check('isEmpty')) {
        this.resetQuestionForm();
      }
    };
  }

  @action.bound resetForm() {
    this.resetQuestionForm();
    this.clearValidationDetails();
    this.form.init(toJS(this.currentFormInitialValues));
  }

  @action.bound
  resetQuestionForm() {
    this.questionForm.$('question').reset();
    this.questionForm.$('question').resetValidation();
    this.questionForm.$('category').reset();
    this.questionForm.$('category').resetValidation();
  }

  @action.bound
  showCollaboratorQuestions() {
    this.questionsTab = 'collaboratorQuestions';
    this.questionForm.clear();
    this.questionForm.$('category').resetValidation();
    this.questionForm.$('question').resetValidation();
  }

  @action.bound
  showQuestions() {
    this.questionsTab = 'questions';
    this.questionForm.clear();
    this.questionForm.$('category').resetValidation();
    this.questionForm.$('question').resetValidation();
  }

  @computed get title() {
    return t('Survey questions');
  }

  @action.bound
  handleAddNewQuestion() {
    const { includeMyCompanyQuestionsForSubcontractors } = this.form.values();
    const UUID = uuid();

    if (this.questionIsInValid) return;

    const values = this.form.$(this.questionsTab).values();

    values.unshift(
      Object.assign({}, this.questionForm.values(), {
        uuid: UUID,
        readOnly: false
      })
    );

    this.form.update({
      [this.questionsTab]: values
    });

    if (
      this.questionsTab === 'questions' &&
      includeMyCompanyQuestionsForSubcontractors
    ) {
      const values = this.form.$('collaboratorQuestions').values();

      values.unshift(
        Object.assign({}, this.questionForm.values(), {
          uuid: UUID,
          readOnly: false
        })
      );

      this.form.update({
        collaboratorQuestions: values
      });
    }

    this.questionForm.clear();
    this.questionForm.$('category').resetValidation();
    this.questionForm.$('question').resetValidation();
  }

  @action.bound
  handleDeleteQuestion(question) {
    const { includeMyCompanyQuestionsForSubcontractors } = this.form.values();

    if (
      this.questionsTab === 'questions' &&
      includeMyCompanyQuestionsForSubcontractors
    ) {
      const values = this.form
        .$('collaboratorQuestions')
        .value.filter(
          collaboratorQuestion =>
            !(
              collaboratorQuestion.category === question.$('category').value &&
              collaboratorQuestion.question === question.$('question').value
            )
        );

      this.form.update({
        collaboratorQuestions: values
      });
    }

    this.form.$(this.questionsTab).del(question.key);
  }

  @action.bound
  handleChangeTab(event, newValue) {
    if (newValue === 'questions') {
      this.showQuestions();
    } else if (newValue === 'collaboratorQuestions') {
      this.showCollaboratorQuestions();
    }
  }

  @action.bound
  onSortEnd({ oldIndex, newIndex }) {
    const newValues = arrayMove(this.questionValues, oldIndex, newIndex);
    this.form.update({
      [this.questionsTab]: newValues
    });
  }
}
