import React, { Component } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const PinterElement = styled.div`
  position: absolute;
  width: 25px;
  height: 25px;
  transform: translateY(-10px) translateX(-10px);
  border-radius: 50%;
  border: 2px solid ${props => props.theme.colors.white};
  background-color: ${props => props.color};
  box-sizing: border-box;
  cursor: pointer;
  z-index: 10;
`;

const PreviewColorMarker = styled.div`
  position: absolute;
`;

class ColorPickerPalette extends Component {
  constructor(props) {
    super(props);
    this.drawingContext = null;
    this.state = {
      height: null,
      width: null,
      selectedColor: {
        rgb: {
          r: 255,
          g: 255,
          b: 255
        },
        hex: '#ffffff'
      }
    };
  }

  componentDidMount() {
    const wrapper = this.colorPicker;

    setTimeout(() => {
      this.setState({
        width: wrapper.clientWidth,
        height: wrapper.clientHeight,
        selectedColor: {
          hex: this.props.color
        }
      });

      this.drawPalette();
      this.init();
    }, 100);
  }

  componentToHex = color => {
    let hex = color.toString(16);

    return hex.length === 1 ? '0' + hex : hex;
  };

  rgbToHex = (r, g, b) => {
    return `#${this.componentToHex(r)}${this.componentToHex(
      g
    )}${this.componentToHex(b)}`;
  };

  handlePaletteMouseMove = event => {
    let canvasRect = this.drawingContext.canvas.getBoundingClientRect();
    let imageData;
    this.colorEventX = event.pageX - canvasRect.left;
    this.colorEventY = event.pageY - canvasRect.top;
    imageData = this.drawingContext.getImageData(
      this.colorEventX,
      this.colorEventY,
      1,
      1
    );
    let hex = this.rgbToHex(
      imageData.data[0],
      imageData.data[1],
      imageData.data[2]
    );

    if (event.buttons === 1) {
      this.setState({
        selectedColor: {
          rgb: {
            r: imageData.data[0],
            g: imageData.data[1],
            b: imageData.data[2]
          },
          hex: hex
        }
      });

      this.setMarkerPosition(event, hex);
    }
  };

  handlePaletteMouseClick = event => {
    let canvasRect = this.drawingContext.canvas.getBoundingClientRect();
    let imageData;
    this.colorEventX = event.pageX - canvasRect.left;
    this.colorEventY = event.pageY - canvasRect.top;
    imageData = this.drawingContext.getImageData(
      this.colorEventX,
      this.colorEventY,
      1,
      1
    );
    let hex = this.rgbToHex(
      imageData.data[0],
      imageData.data[1],
      imageData.data[2]
    );

    this.setState({
      selectedColor: {
        rgb: {
          r: imageData.data[0],
          g: imageData.data[1],
          b: imageData.data[2]
        },
        hex: hex
      }
    });

    this.setMarkerPosition(event, hex);
  };

  setMarkerPosition = (event, hex) => {
    let rect = this.colorPicker.getBoundingClientRect();

    this.props.onChange(
      {
        ...this.props,
        hex
      },
      event
    );

    this.previewColorMarker.style.left = event.pageX - rect.left + 'px';
    this.previewColorMarker.style.top = event.pageY - rect.top + 'px';
  };

  init = () => {
    let context2d = this.previewColorCanvas.getContext('2d');

    for (let x = 0; x < context2d.canvas.width; x++) {
      for (let y = 0; y < context2d.canvas.height; y++) {
        let dataRGB = context2d.getImageData(x, y, 1, 1).data;

        if (
          this.rgbToHex(dataRGB[0], dataRGB[1], dataRGB[2]).toUpperCase() ===
          this.props.color.toUpperCase()
        ) {
          this.previewColorMarker.style.left = x + 'px';
          this.previewColorMarker.style.top = y + 'px';
        }

        y++;
      }
      x++;
    }
  };

  drawPalette = () => {
    let canvasElement = this.previewColorCanvas;
    canvasElement.width = this.state.width;
    canvasElement.height = this.state.height;

    this.drawingContext = canvasElement.getContext('2d');

    let gradient = this.drawingContext.createLinearGradient(
      0,
      0,
      this.state.width,
      0
    );
    gradient.addColorStop(0, 'rgb(255, 0, 0)');
    gradient.addColorStop(0.15, 'rgb(255, 0, 255)');
    gradient.addColorStop(0.33, 'rgb(0, 0, 255)');
    gradient.addColorStop(0.49, 'rgb(0, 255, 255)');
    gradient.addColorStop(0.67, 'rgb(0, 255, 0)');
    gradient.addColorStop(0.84, 'rgb(255, 255, 0)');
    gradient.addColorStop(1, 'rgb(255, 0, 0)');
    this.drawingContext.fillStyle = gradient;
    this.drawingContext.fillRect(
      0,
      0,
      this.drawingContext.canvas.width,
      this.state.height
    );
    gradient = this.drawingContext.createLinearGradient(
      0,
      0,
      0,
      this.state.height
    );
    gradient.addColorStop(0, 'rgba(255, 255, 255, 1)');
    gradient.addColorStop(0.5, 'rgba(255, 255, 255, 0)');
    gradient.addColorStop(0.5, 'rgba(0, 0, 0, 0)');
    gradient.addColorStop(1, 'rgba(0, 0, 0, 1)');
    this.drawingContext.fillStyle = gradient;
    this.drawingContext.fillRect(
      0,
      0,
      this.drawingContext.canvas.width,
      this.state.height
    );
  };

  render() {
    const { pointer } = this.props;

    return (
      <Wrapper
        ref={ref => {
          this.colorPicker = ref;
        }}
        onMouseMove={this.handlePaletteMouseMove}
        onClick={this.handlePaletteMouseClick}
      >
        <PreviewColorMarker ref={ref => (this.previewColorMarker = ref)}>
          {pointer ? (
            React.createElement(pointer, { ...this.props })
          ) : (
            <PinterElement {...this.props} />
          )}
        </PreviewColorMarker>

        <canvas
          ref={ref => {
            this.previewColorCanvas = ref;
          }}
        />
      </Wrapper>
    );
  }
}

ColorPickerPalette.defaultProps = {
  pointer: null
};

export default ColorPickerPalette;
