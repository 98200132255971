import request from 'axios';
import omit from 'lodash.omit';
import orderBy from 'lodash.orderby';
import debounce from 'lodash.debounce';
import kebabcase from 'lodash.kebabcase';
import moment from 'moment-timezone';
import { Model } from 'mobx-mc';
import { observable, computed, action } from 'mobx';
import Project from './Project';

import Attachments from '../collections/Attachments';

import formatTemperature from 'utils/formatTemperature';
import errorHandler from 'utils/errorHandler';
import { t } from 'utils/translate';

export default class DailyReport extends Model {
  @observable fetchingWeatherInsights;

  constructor(attributes, options) {
    super(attributes, options);
    this.fetchingWeatherInsights = false;
    this.fetchWeatherInsights = debounce(this.fetchWeather, 500);
  }

  get restAttributes() {
    return [
      'id',
      'date',
      'status',
      'verification',
      'insights',
      'signature',
      'signedBy',
      'signedDate',
      'empty',
      'canRollover',
      'segmentUuid',
      'attachments',
      'modifiedTimestamp',
      '_links'
    ];
  }

  url() {
    return `${this.relatedProject.url()}/reports/${this.urlId}`;
  }

  get urlId() {
    return this.date;
  }

  @action.bound
  parse(attributes) {
    this.parseProject(attributes);
    this.parseCollections(attributes);

    return {
      ...omit(attributes, ['project', 'attachments'])
    };
  }

  @action.bound parseProject(attributes) {
    if (attributes.project instanceof Project) {
      this.project = attributes.project;
    } else {
      this.project = new Project(attributes.project, {
        rootStore: this.rootStore
      });
    }
  }

  @action.bound
  parseCollections(attributes) {
    if (this.attachments) {
      this.attachments.set(attributes.attachments || [], {
        add: true,
        remove: false,
        update: true
      });
    } else {
      this.attachments = new Attachments(attributes.attachments || [], {
        rootStore: this.rootStore,
        parent: this
      });
    }
  }

  @computed
  get relatedProject() {
    return this.project;
  }

  @computed
  get projectName() {
    return this.relatedProject.name;
  }

  @computed get slug() {
    return `${kebabcase(this.projectName)}-${this.date}`;
  }

  @computed get modifiedTimestampFormatted() {
    return moment(this.modifiedTimestamp).format('h:mm A | YYYY-MM-DD');
  }

  @computed
  get hasAttachments() {
    return this.attachments.length > 0;
  }

  @computed
  get sortedAttachments() {
    return orderBy(this.attachments.models, ['createdTimestamp'], ['desc']);
  }

  @computed
  get pdfs() {
    let pdfs = {};

    if (!this._links) return null;

    if (this._links.dailyPdf) {
      pdfs.daily = this._links.dailyPdf.href;
    }

    if (this._links.superDailyPdf) {
      pdfs.super = this._links.superDailyPdf.href;
    }

    return pdfs;
  }

  @computed
  get hasSuperDaily() {
    return this.pdfs && this.pdfs.super;
  }

  @computed
  get pdfLink() {
    if (!this.pdfs) return null;

    if (this.pdfs.super) return this.pdfs.super;

    return this.pdfs.daily;
  }

  @computed
  get viewUrl() {
    return `${this.relatedProject.viewUrl}?date=${this.date}${
      this.segmentUuid ? `&segmentUuid=${this.segmentUuid}` : ''
    }`;
  }

  @action.bound
  processWeatherCollection(response) {
    const daily = response.data.dailys[0];

    this.insights.weather = {
      daySummary: {
        type: 'DaySummary',
        temperatureMax: daily.temperatureMax,
        temperatureMin: daily.temperatureMin,
        icon: daily.icon
      }
    };

    this.fetchingWeatherInsights = false;
  }

  @action.bound
  fetchWeather() {
    const { geolocation } = this.relatedProject.address;
    const rangeWeather = moment(this.date) - moment(new Date());
    const typeWeather = rangeWeather < 0;

    let url = '';

    if (!geolocation.lat || !geolocation.lng) return;

    if (typeWeather) {
      url = `${
        this.rootStore.appConfig.weather_historical_base_url
      }historical?key=${moment(this.date).format(
        'YYYY/MM/DD'
      )}/(${geolocation.lat.toFixed(1)})(${geolocation.lng.toFixed(1)}).json`;
    } else {
      url = `${
        this.rootStore.appConfig.weather_forecast_api_url
      }forecast?lat=${geolocation.lat.toFixed(1)}&lng=${geolocation.lng.toFixed(
        1
      )}&fromDate=${this.date}`;
    }

    this.fetchingWeatherInsights = true;

    return request
      .get(url, {
        withCredentials: false,
        transformRequest: [
          (data, headers) => {
            delete headers.common.Authentication;
            return data;
          }
        ]
      })
      .then(
        response => {
          this.processWeatherCollection(response);
        },
        () => {
          this.fetchingWeatherInsights = false;
        }
      )
      .catch(error => {
        errorHandler(error, this.rootStore.notificationsUI.pushError);
      });
  }

  @computed
  get useImperial() {
    return this.rootStore?.companyUI?.company?.useImperialFormatting;
  }

  @computed get temperatureSymbol() {
    if (this.useImperial) {
      return '°F';
    }

    return '°C';
  }

  @computed
  get temperatureMaxFormatted() {
    return formatTemperature(
      this.insights.weather.daySummary.temperatureMax,
      this.useImperial
    );
  }

  @computed
  get temperatureMinFormatted() {
    return formatTemperature(
      this.insights.weather.daySummary.temperatureMin,
      this.useImperial
    );
  }

  @computed
  get signedDateFormatted() {
    return moment(this.signedDate)
      .tz(this.timezone)
      .format('YYYY-MM-DD' + ' hh:mm a');
  }

  @computed get segmentName() {
    if (this.segmentUuid) {
      const segment = this.relatedProject.segments?.find(
        segment => segment.uuid === this.segmentUuid
      );

      if (segment?.name) return segment.name;
    }

    return null;
  }

  @computed
  get statusIcon() {
    switch (this.status) {
      case 'UNSIGNED':
        return 'unsigned';
      case 'COMPLETED':
        return 'signed';
      case 'NO_WORK_DONE':
        return 'no-work-done';
      default:
        return 'unsigned';
    }
  }

  @computed
  get statusText() {
    switch (this.status) {
      case 'UNSIGNED':
        return t('Unsigned');
      case 'COMPLETED':
        return t('Signed');
      case 'NO_WORK_DONE':
        return t('No Work Done');
      default:
        return t('Unsigned');
    }
  }

  @computed get statusColor() {
    switch (this.status) {
      case 'UNSIGNED':
        return 'red';
      case 'COMPLETED':
        return 'green';
      case 'NO_WORK_DONE':
        return 'blue';
      default:
        return 'orange';
    }
  }

  @computed get hasHours() {
    return this.insights.hoursToday > 0;
  }

  @computed get hasSafetyIncidents() {
    return this.insights.incidentsToday > 0;
  }

  @computed get hasDelays() {
    return this.insights.delaysToday > 0;
  }
}
