import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Icon from './Icon';
import Square from './Square';
import generateQAIdentifier from 'utils/generateQAIdentifier';

const Label = styled.label`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  display: ${props => (props.inline ? 'inline-flex' : 'flex')};
  align-items: ${props => (props.valign === 'top' ? 'flex-start' : 'center')};
  justify-content: ${props => (props.center ? 'center' : 'flex-start')};
  overflow: hidden;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  margin-bottom: ${props => props.marginBottom || 0}px;
  margin-top: ${props => props.marginTop || 0}px;
  margin-right: ${props => props.marginRight || 0}px;
  margin-left: ${props => props.marginLeft || 0}px;
  ${props => props.wrap && `white-space: nowrap;`} ${Square} {
    margin-left: 8px;
  }
`;

const CheckBox = styled.div`
  background: ${props =>
    props.checked
      ? props.inverse
        ? props.theme.colors.white
        : props.theme.colors[props.backgroundColor] ||
          props.theme.colors.blueAccent
      : props.theme.colors.white};
  border: 2px solid
    ${props =>
      props.checked
        ? props.inverse
          ? props.theme.colors.white
          : props.theme.colors[props.backgroundColor] ||
            props.theme.colors.blueAccent
        : props.theme.colors.lightGrey3};
  border-radius: 3px;
  box-sizing: border-box;
  cursor: inherit;
  position: relative;
  width: 15px;
  height: 15px;
  flex-shrink: 0;
  top: ${props => props.top || 0}px;
  &:focus {
    box-shadow: 0 0 8px
      ${props =>
        props.checked ? 'transparent' : props.theme.colors.lightGrey1};
    outline: 0;
  }
  svg {
    display: ${props => (props.checked ? 'inline-block' : 'none')};
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
  }
`;

const LabelText = styled.span`
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  font-weight: ${props => (props.bold ? 700 : props.light ? 300 : 'inherit')};
  text-transform: ${props => props.textTransform};
  ${props =>
    props.labelAlign === 'right' &&
    css`
      padding-left: 8px;
    `};
  ${props =>
    props.labelAlign === 'left' &&
    css`
      padding-right: 8px;
    `};
  ${props =>
    !props.wrapText &&
    `
      max-width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
    `};
`;

export default class extends Component {
  static propTypes = {
    withSquare: PropTypes.bool,
    wrap: PropTypes.bool,
    inline: PropTypes.bool,
    inverse: PropTypes.bool,
    valign: PropTypes.string,
    labelAlign: PropTypes.oneOf(['left', 'right'])
  };

  static defaultProps = {
    withSquare: false,
    squareColor: null,
    wrap: false,
    inline: false,
    inverse: false,
    textTransform: 'none',
    labelAlign: 'right'
  };

  handleKeyDown = e => {
    if (this.props.disabled) return;

    if (e.keyCode === 32) {
      e.preventDefault();
      this.props.onClick.call(this);
    }
  };

  handleClick = e => {
    if (this.props.disabled) return;

    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    this.props.onClick.call(this, e);
  };

  render() {
    const {
      checked,
      children,
      marginBottom,
      marginTop,
      marginLeft,
      marginRight,
      withSquare,
      squareColor,
      disabled,
      wrap,
      bold,
      inline,
      inverse,
      valign,
      center,
      backgroundColor,
      textTransform,
      field,
      labelAlign,
      dataQA
    } = this.props;

    const identifier = dataQA || (field && field.label) || children;

    return (
      <Label
        onClick={this.handleClick}
        marginTop={marginTop}
        marginBottom={marginBottom}
        marginLeft={marginLeft}
        marginRight={marginRight}
        disabled={disabled}
        valign={valign}
        inline={inline}
        center={center}
        data-qa={`checkbox_${generateQAIdentifier(identifier)}`}
        data-is-checked={checked}
      >
        {children && labelAlign === 'left' && (
          <LabelText
            labelAlign={labelAlign}
            textTransform={textTransform}
            wrapText={wrap}
            bold={bold}
          >
            {children}
          </LabelText>
        )}

        <CheckBox
          inverse={inverse}
          checked={checked}
          onKeyDown={this.handleKeyDown}
          tabIndex={0}
          backgroundColor={backgroundColor}
        >
          <Icon
            kind="check"
            size={10}
            color={inverse ? 'blueAccent' : 'white'}
          />
        </CheckBox>
        {withSquare && <Square color={squareColor} />}

        {children && labelAlign === 'right' && (
          <LabelText
            labelAlign={labelAlign}
            textTransform={textTransform}
            wrapText={wrap}
            bold={bold}
          >
            {children}
          </LabelText>
        )}
      </Label>
    );
  }
}
