import { RakenCollection } from '../../lib';
import Overlap from '../../models/superAdmin/Overlap';

export default class Overlaps extends RakenCollection {
  model() {
    return Overlap;
  }

  url() {
    return `/ra/cheaters/user/${this.rootStore.activeUser.id}/overlaps`;
  }
}
