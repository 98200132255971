import request from 'axios';
import { observable, action, computed, runInAction } from 'mobx';
import UIStore from './UIStore';
import orderBy from 'lodash.orderby';
import { t } from 'utils/translate';
import errorHandler from 'utils/errorHandler';
import axiosDownload from 'utils/axiosDownload';
import objectValuesToLowerCase from 'utils/objectValuesToLowerCase';

import {
  EquipmentForm,
  equipmentFormOptions,
  equipmentFormFields,
  equipmentFormRules,
  equipmentFormValues,
  equipmentFormLabels,
  equipmentFormPlugins
} from 'forms/equipment';
import { callTrack } from 'utils/segmentIntegration';
import {
  EQUIPMENT_CREATED,
  EQUIPMENT_UPLOADED
} from 'utils/segmentAnalytics/eventSpec';

export default class CompanyEquipmentUI extends UIStore {
  @observable entryForEdit;
  @observable entryEditForm;

  @observable uploadProgress;
  @observable mode;

  @observable searchQuery;
  @observable sortDirection;
  @observable pageSize;
  @observable page;

  constructor(options) {
    super(options);

    this.companyProductionUI = options.companyProductionUI;

    // Selected
    this.selectedEquipment = observable([]);

    // Uploading
    this.uploadProgress = 0;

    // Searching
    this.searchQuery = '';

    // Sorting
    this.sortDirection = 'asc';

    // Editing
    this.entryForEdit = null;
    this.entryEditForm = null;

    // Pagination
    this.pageSize = 20;
    this.page = 0;

    // Instantiate the create equipment form
    this.equipmentForm = new EquipmentForm(
      {
        fields: equipmentFormFields,
        rules: equipmentFormRules,
        labels: equipmentFormLabels,
        values: equipmentFormValues
      },
      {
        options: equipmentFormOptions,
        plugins: equipmentFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound clearUIState() {
    this.entryForEdit = null;
    this.searchQuery = '';
    this.selectedEquipment.clear();
    this.rootStore.equipment.clearNew();
    this.page = 0;
  }

  @computed
  get hasActiveModal() {
    return this.activeModal;
  }

  @action.bound
  setMode(mode) {
    this.mode = mode;
  }

  @action.bound
  setDefaultMode() {
    this.mode = this.hasEquipment ? 'manual' : 'upload';
  }

  @action.bound
  async fetchEquipment() {
    if (this.rootStore.companyEquipmentFetched) {
      this.setDefaultMode();
      return;
    }

    await this.rootStore.equipment.fetch({
      params: {
        owned: true,
        limit: 10000
      }
    });

    this.rootStore.companyEquipmentFetched = true;
    this.setDefaultMode();
  }

  @computed get equipment() {
    return this.rootStore.equipment.ownedEquipment;
  }

  @computed
  get hasEquipment() {
    return this.equipment.length > 0;
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 0;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 0;
  }

  @computed get sortedEquipment() {
    return orderBy(
      this.equipment,
      [equipment => equipment.new, equipment => equipment.name.toLowerCase()],
      ['desc', this.sortDirection]
    );
  }

  @computed get searchedEquipment() {
    if (!this.searchQuery) {
      return this.sortedEquipment;
    }

    const query = this.searchQuery.toLowerCase();

    return this.sortedEquipment.filter(equipment => {
      return (
        (equipment.name && equipment.name.toLowerCase().indexOf(query) > -1) ||
        (equipment.equipmentId &&
          equipment.equipmentId.toLowerCase().indexOf(query) > -1)
      );
    });
  }

  @computed get hasSearchedEquipment() {
    return this.searchedEquipment.length > 0;
  }

  @computed get paginatedEquipment() {
    return this.searchedEquipment.slice(
      this.page * this.pageSize,
      this.page * this.pageSize + this.pageSize
    );
  }

  @action.bound
  sortByColumn() {
    // Clear New Item at the top of list on sort.
    this.rootStore.equipment.clearNew();

    if (this.sortDirection === 'asc') {
      this.sortDirection = 'desc';
    } else {
      this.sortDirection = 'asc';
    }
  }

  @computed
  get totalPages() {
    return Math.ceil(this.searchedEquipment.length / this.pageSize);
  }

  @action.bound
  setPage(page) {
    this.page = page.selected;
  }

  @computed get currentForm() {
    return this.entryEditForm || this.equipmentForm;
  }

  @computed
  get existingEquipment() {
    if (!this.currentForm) return false;

    const values = objectValuesToLowerCase(this.currentForm.trimmedValues());

    return this.rootStore.equipment.ownedEquipment.find(equipment => {
      if (this.entryForEdit && this.entryForEdit.id === equipment.id)
        return false;

      if (!values.equipmentId && !equipment.identificationValues.equipmentId) {
        return equipment.identificationValues.name === values.name;
      }

      return (
        equipment.identificationValues.name === values.name &&
        equipment.identificationValues.equipmentId === values.equipmentId
      );
    });
  }

  @computed
  get equipmentFormIsInValid() {
    if (!this.equipmentForm) return true;

    return Boolean(
      !this.equipmentForm.$('name').value.trim().length ||
        this.equipmentForm.$('name').check('isPristine') ||
        this.equipmentForm.check('hasError') ||
        this.existingEquipment
    );
  }

  @action.bound saveNewEquipment(values) {
    const payload = {
      name: values.name,
      equipmentId: values.equipmentId ? values.equipmentId : null,
      rate: values.rate ? values.rate : null,
      frequency: values.frequency ? values.frequency : null,
      owned: true
    };
    this.rootStore.equipment
      .create(payload)
      .then(equipment => {
        runInAction(() => {
          this.showEquipmentCreated(equipment);

          callTrack(EQUIPMENT_CREATED, {
            equipment_type: values.owned ? 'Owned' : 'Rented',
            supplier_entered: Boolean(values.supplier),
            id_entered: Boolean(values.equipmentId)
          });
        });
      })
      .catch(error => {
        errorHandler(error, this.notifications.pushError);
      });
  }

  showEquipmentCreated(equipment) {
    equipment.setAsNew();

    this.rootStore.notificationsUI.pushNotification({
      showUndo: false,
      title: t('Equipment Created')
    });

    this.setPage({
      selected: 0
    });
  }

  @computed get hasSelectedEquipment() {
    return this.selectedEquipment.length > 0;
  }

  @action.bound
  toggleSelectEquipment(equipment) {
    if (this.selectedEquipment.find(id => id === equipment.id)) {
      this.selectedEquipment.remove(equipment.id);
    } else {
      this.selectedEquipment.push(equipment.id);
    }
  }

  @computed
  get allEquipmentSelected() {
    return (
      this.hasEquipment &&
      this.selectedEquipment.length === this.equipment.length
    );
  }

  @action.bound
  toggleSelectAllEquipment() {
    if (this.allEquipmentSelected) {
      this.selectedEquipment.clear();
    } else {
      this.selectedEquipment.replace(
        this.searchedEquipment.map(equipment => equipment.id)
      );
    }
  }

  @action.bound softDeleteEquipment(equipment) {
    const originalIndex = equipment.collection.models.indexOf(equipment);

    this.cancelEntryEdit();

    equipment.collection.remove(equipment);

    this.rootStore.notificationsUI.pushNotification({
      onUndo: () => {
        this.cancelDeleteEquipment(equipment, originalIndex);
      },
      onDismiss: () => {
        this.confirmDeleteEquipment(equipment);
      },
      title: t('Equipment Deleted')
    });
  }

  @action.bound
  cancelDeleteEquipment(equipment, index) {
    this.rootStore.equipment.add(equipment, {
      at: index
    });
  }

  @action.bound
  confirmDeleteEquipment(equipment) {
    equipment.destroy();
  }

  @action.bound
  deleteSelectedEquipment() {
    this.cancelEntryEdit();

    const equipment = this.equipment.filter(equipment =>
      this.selectedEquipment.includes(equipment.id)
    );

    this.softDeleteSelectedEquipment(equipment);
    this.selectedEquipment.clear();
  }

  @action.bound softDeleteSelectedEquipment(equipment) {
    this.rootStore.equipment.remove(equipment);

    this.rootStore.notificationsUI.pushNotification({
      onUndo: () => {
        this.cancelDeleteSelectedEquipment(equipment);
      },
      onDismiss: () => {
        this.confirmDeleteSelectedEquipment(equipment);
      },
      title: t('Equipment Deleted')
    });
  }

  @action.bound
  cancelDeleteSelectedEquipment(equipment) {
    this.rootStore.equipment.add(equipment);
  }

  @action.bound
  confirmDeleteSelectedEquipment(equipment) {
    equipment.forEach(model => {
      model.destroy();
    });
  }

  @action.bound
  upload(files) {
    const formData = new FormData();
    formData.append('file', files[0]);

    const config = {
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        this.setUploadProgress(percentCompleted);
      },
      transformRequest: [
        function(data, headers) {
          delete headers.post['content-type'];
          return data;
        }
      ]
    };

    return new Promise((resolve, reject) => {
      request
        .post(
          `${this.urlMicroService('performanceTracking')}/equipment/import`,
          formData,
          config
        )
        .then(
          response => {
            runInAction(() => {
              this.rootStore.equipment.set(response.data.collection, {
                add: true,
                remove: false,
                update: true
              });

              this.clearUploadProgress();
              this.setMode('manual');

              const rates = response.data.collection.map(item => item.rate);

              callTrack(EQUIPMENT_UPLOADED, {
                equipment_entered: response.data.collection.length,
                rate_entered: rates.length
              });

              this.notifications.pushNotification({
                showUndo: false,
                title: t('Equipment successfully updated')
              });
            });

            resolve(response.data);
          },
          error => {
            this.clearUploadProgress();
            errorHandler(error, this.notifications.pushError);
          }
        );
    });
  }

  @action.bound
  setUploadProgress(progress) {
    this.uploadProgress = progress;
  }

  @action.bound
  clearUploadProgress() {
    this.uploadProgress = 0;
  }

  @action.bound
  uploadRejected() {
    this.notifications.pushNotification({
      showUndo: false,
      title: 'Please upload a valid .CSV file.'
    });
  }

  @action.bound setEntryForEdit(equipment) {
    this.entryForEdit = equipment;

    this.entryEditForm = new EquipmentForm(
      {
        fields: equipmentFormFields,
        rules: equipmentFormRules,
        labels: equipmentFormLabels,
        values: equipment.formValues
      },
      {
        options: equipmentFormOptions,
        plugins: equipmentFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound cancelEntryEdit() {
    this.entryForEdit = null;
    this.entryEditForm = null;
  }

  checkIfEntryDisabled(entry) {
    return this.entryForEdit && this.entryForEdit.id !== entry.id;
  }

  @action.bound submitEntryEditForm() {
    if (
      this.existingEquipment &&
      this.existingEquipment.id !== this.entryForEdit.id
    )
      return;

    this.entryEditForm.submit({
      onSuccess: this.submitEntryEditFormSuccess,
      onError: this.submitEntryEditFormError
    });
  }

  @action.bound submitEntryEditFormSuccess() {
    const values = this.entryEditForm.trimmedValues();

    const payload = {
      name: values.name,
      equipmentId: values.equipmentId ? values.equipmentId : null,
      rate: values.rate ? values.rate : null,
      frequency: values.frequency ? values.frequency : null,
      owned: true
    };

    this.entryForEdit.save(payload);

    this.cancelEntryEdit();
  }

  @action.bound submitEntryEditFormError() {
    console.log(this.entryEditForm.errors());
  }

  @action.bound exportCSV() {
    axiosDownload(
      `${this.rootStore.urlMicroService(
        'performanceTracking'
      )}/equipment/export/equipment.csv`,
      t('Equipment.csv')
    );
  }
}
