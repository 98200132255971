import capitalize from 'lodash.capitalize';
import moment from 'moment-timezone';
import { computed } from 'mobx';
import { Model } from 'mobx-mc';
import { t } from 'utils/translate';

export default class Transaction extends Model {
  get restAttributes() {
    return [
      'id',
      'currency',
      'amount',
      'status',
      'statusCode',
      'statusMessage',
      'type',
      'collectionMethod',
      'paymentMethod',
      'createdAt',
      'updatedAt',
      'collectedAt',
      'externalLink'
    ];
  }

  @computed get collectedAtDate() {
    return moment(this.collectedAt)
      .tz(moment.tz.guess())
      .format('Do MMMM YYYY HH:mm zz');
  }

  @computed get paymentMethodFormatted() {
    if (!this.paymentMethod.type || this.paymentMethod.type === 'UNDEFINED') {
      return t('Other');
    }

    if (this.paymentMethod.type === 'CREDIT_CARD') {
      if (this.paymentMethod.cardType === 'UNKNOWN') {
        return t('Unknown card');
      }

      return `${this.paymentMethod.cardType} ending in ${this.paymentMethod.lastFour}`;
    }

    return capitalize(
      this.paymentMethod.type.replace(/_/gi, ' ').toLowerCase()
    );
  }

  @computed get statusFormatted() {
    return capitalize(this.status);
  }

  @computed get statusColor() {
    switch (this.status) {
      case 'DECLINED':
      case 'VOID':
        return 'red';
      default:
        return 'green';
    }
  }

  @computed get amountFormatted() {
    return `$${this.amount.toFixed(2)}`;
  }

  @computed get typeFormatted() {
    switch (this.type) {
      case 'VERIFY':
        return t('Verification');
      case 'PURCHASE':
      case 'CAPTURE':
      case 'PAYMENT':
        return t('Payment');
      default:
        return capitalize(this.type.toLowerCase());
    }
  }
}
