import React, { Component, Fragment } from 'react';
import styled, { css } from 'styled-components';
import { Overlay } from 'react-overlays';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import moment from 'moment';

import { CounterToggle } from '.';
import { t } from '../utils/translate';

const Wrapper = styled.div`
  position: relative;
`;

const Times = styled.div`
  display: inline-flex;
  height: 40px;
  width: ${props => (props.styleLikeInput ? '100%' : '95px')};
  border-style: solid;
  border-width: ${props => (props.valid ? 1 : 2)}px;
  border-color: ${props =>
    props.valid ? props.theme.colors.lightGrey3 : props.theme.colors.goGreen};
  border-radius: 4px;
  background-color: ${props =>
    props.disabled ? 'none' : props.theme.colors.white};
  box-sizing: border-box;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  ${props =>
    props.styleLikeInput &&
    css`
      padding: 0 0.9rem;
      border-color: ${props =>
        props.valid
          ? props.theme.colors.lightGrey3
          : props.theme.colors.rakenOrange};
    `};
`;

const Time = styled.div`
  line-height: 38px;
  border-right: 1px solid
    ${props =>
      props.valid ? props.theme.colors.lightGrey3 : props.theme.colors.goGreen};
  ${props =>
    props.styleLikeInput &&
    css`
      border: none;
    `};
  box-sizing: border-box;
  width: ${props => (props.styleLikeInput ? 'auto' : '51px')};
  text-align: center;
  color: ${props =>
    props.value && !props.disabled
      ? props.theme.colors.charcoalGrey
      : props.theme.colors.lightGrey1};
  font-size: 1.4rem;
  font-weight: 300;
  font-style: ${props => (props.value ? 'initial' : 'italic')};
  ${props =>
    props.styleLikeInput &&
    css`
      font-weight: ${props => (props.value ? 'normal' : 300)};
      padding: 0 0 0 0.4rem;
    `};
  &:last-of-type {
    border-right: none;
  }
`;

const TimeFirst = styled(Time)`
  ${props =>
    props.styleLikeInput &&
    css`
      padding: 0;
    `};
`;
const TimePlaceholder = styled(Time)`
  width: auto;
  text-align: left;
`;

const SubMenu = styled.div`
  display: ${props => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  top: 100%;
  left: 0;
  border: 1px solid ${props => props.theme.colors.lightGrey3};
  background-color: ${props => props.theme.colors.white};
  padding: 5px 0;
  border-radius: 3px;
  box-sizing: border-box;
  white-space: nowrap;
  z-index: 99999;
`;

@observer
export default class TimePicker extends Component {
  static propTypes = {
    styleLikeInput: PropTypes.bool,
    needToCheckIsValid: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    changeOnMount: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.object.isRequired
    ])
  };

  static defaultProps = {
    styleLikeInput: false,
    needToCheckIsValid: true,
    disabled: false,
    stepMinutes: 15,
    changeOnMount: true
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      changeFromAMToPM: false
    };
  }

  componentDidMount() {
    const { onChange, changeOnMount } = this.props;
    let obj = this.handleDrawTime();

    this.getHoursInFormat24(obj);

    if (changeOnMount) {
      /*true means don't update field*/
      onChange(obj, true);
    }

    window.addEventListener('scroll', this.handleClose, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleClose, true);
  }

  handleDrawTime = () => {
    const { form, fieldName } = this.props;
    const timeInitial = form.$(fieldName).value;
    let exportObj = {};
    let time = {};

    /*check if get time filled in HH:mm format*/
    if (moment(timeInitial, 'HH:mm').isValid()) {
      time.hour = moment(timeInitial, 'HH:mm').format('H');
      time.minute = moment(timeInitial, 'HH:mm').format('m');
    } else if (timeInitial === '') {
      time.minute = moment().format('m');
      time.hour = moment().format('H');
    } else if (typeof timeInitial === 'object') {
      time = { ...timeInitial };
    } else {
      time.hour = 0;
      time.minute = 0;
    }

    if (+time.hour === 0) {
      time.hour = 24;
    }

    exportObj = {
      h: time.hour > 12 ? +time.hour - 12 : +time.hour,
      m: time.minute,
      period: time.hour >= 12 && time.hour < 24 ? 'PM' : 'AM'
    };

    exportObj.h =
      exportObj.h < 10 ? ('0' + exportObj.h).slice(-2) : exportObj.h.toString();
    exportObj.m =
      exportObj.m < 10 ? ('0' + exportObj.m).slice(-2) : exportObj.m.toString();

    return exportObj;
  };

  handleToggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  handleClose = () => {
    this.setState({
      isOpen: false
    });
  };

  handleNewTime = ({ h, m, period }) => {
    const { onChange } = this.props;
    let obj = this.handleDrawTime();

    if (
      (h && h === '12' && obj.h === '11') ||
      (h && h === '11' && obj.h === '12')
    ) {
      obj.period = obj.period === 'AM' ? 'PM' : 'AM';
      this.setState({
        changeFromAMToPM: true
      });
    } else {
      this.setState({
        changeFromAMToPM: false
      });
    }

    h && (obj.h = h);
    m && (obj.m = m);
    period && (obj.period = period);

    this.getHoursInFormat24(obj);
    onChange(obj);
  };

  getHoursInFormat24(obj) {
    obj.hourFormat24 =
      obj.period === 'PM' && +obj.h !== 12 ? +obj.h + 12 : obj.h;
    obj.hourFormat24 =
      obj.period === 'AM' && +obj.h === 12 ? '00' : obj.hourFormat24;
    return obj;
  }

  getOrientation = () => {
    if (!this.wrapper || this.props.disableTopOrientation) {
      return 'bottom';
    }

    return window.innerHeight - this.wrapper.getBoundingClientRect().bottom <
      400
      ? 'top'
      : 'bottom';
  };

  render() {
    const { isOpen } = this.state;

    const {
      value,
      styleLikeInput,
      disabled,
      stepMinutes,
      onClickDisabled,
      changeOnMount,
      dataQA
    } = this.props;

    const valid = this.props.needToCheckIsValid ? !!value : true;

    const orientation = this.getOrientation();
    const { h, m, period } = this.handleDrawTime();

    return (
      <Wrapper data-qa={dataQA}>
        <Times
          styleLikeInput={styleLikeInput}
          onClick={disabled ? onClickDisabled : this.handleToggle}
          ref={wrapper => (this.wrapper = wrapper)}
          valid={valid}
          disabled={disabled}
        >
          {!value && styleLikeInput ? (
            <TimePlaceholder>{t('Enter Time')}</TimePlaceholder>
          ) : (
            <Fragment>
              <TimeFirst
                valid={valid}
                styleLikeInput={styleLikeInput}
                value={value}
                disabled={disabled}
                data-qa={`${dataQA}-value`}
              >
                {h}
                {':'}
                {m}
              </TimeFirst>
              <Time
                valid={valid}
                disabled={disabled}
                styleLikeInput={styleLikeInput}
                value={value}
              >
                {period}
              </Time>
            </Fragment>
          )}
        </Times>

        <Overlay
          show={isOpen}
          onHide={this.handleClose}
          target={this.wrapper}
          rootClose
          placement={orientation}
          shouldUpdatePosition={true}
          container={document.body}
        >
          <SubMenu isOpen={isOpen}>
            <CounterToggle
              type="hour"
              value={h}
              changeOnMount={changeOnMount}
              onChange={value => {
                this.handleNewTime({ h: value });
              }}
            />
            <CounterToggle
              type="minute"
              value={m}
              step={stepMinutes}
              changeOnMount={changeOnMount}
              onChange={value => {
                this.handleNewTime({ m: value });
              }}
            />
            <CounterToggle
              type="period"
              values={['AM', 'PM']}
              changeFromAMToPM={this.state.changeFromAMToPM}
              value={period}
              changeOnMount={changeOnMount}
              onChange={value => {
                this.handleNewTime({ period: value });
              }}
            />
          </SubMenu>
        </Overlay>
      </Wrapper>
    );
  }
}
