import { observable, action, computed, runInAction } from 'mobx';
import UIStore from './UIStore';
import orderBy from 'lodash.orderby';
import { t } from 'utils/translate';
import errorHandler from 'utils/errorHandler';

import {
  PayTypeForm,
  payTypeFormOptions,
  payTypeFormFields,
  payTypeFormRules,
  payTypeFormLabels,
  payTypeFormPlugins
} from 'forms/payType';

export default class CompanyPayTypesUI extends UIStore {
  @observable entryForEdit;

  @observable uploadProgress;
  @observable mode;

  @observable searchQuery;
  @observable sortDirection;
  @observable pageSize;
  @observable page;

  constructor(options) {
    super(options);

    this.companyProductionUI = options.companyProductionUI;

    // Selecting
    this.selectedPayTypes = observable([]);

    // Searching
    this.searchQuery = '';

    // Sorting
    this.sortDirection = 'asc';

    // Editing
    this.entryForEdit = null;

    // Pagination
    this.pageSize = 20;
    this.page = 0;

    /// Instantiate the create pay type form
    this.payTypeForm = new PayTypeForm(
      {
        fields: payTypeFormFields,
        rules: payTypeFormRules,
        labels: payTypeFormLabels
      },
      {
        options: payTypeFormOptions,
        plugins: payTypeFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound clearUIState() {
    this.entryForEdit = null;
    this.searchQuery = '';
    this.selectedPayTypes.clear();
    this.rootStore.payTypes.clearNew();

    this.page = 0;
  }

  @action.bound
  async fetchPayTypes() {
    try {
      await this.rootStore.payTypes.fetch({
        params: {
          limit: 10000
        },
        add: true,
        remove: false,
        update: true
      });

      this.rootStore.companyPayTypesFetched = true;
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  @computed get payTypes() {
    return this.rootStore.payTypes;
  }

  @computed
  get hasPayTypes() {
    return this.payTypes.hasModels;
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 0;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 0;
  }

  @computed get sortedPayTypes() {
    return orderBy(
      this.payTypes.models,
      [payTypes => payTypes.new, payTypes => payTypes.order],
      ['desc', this.sortDirection]
    );
  }

  @computed get searchedPayTypes() {
    if (!this.searchQuery) {
      return this.sortedPayTypes;
    }

    const query = this.searchQuery.toLowerCase();

    return this.sortedPayTypes.filter(payType => {
      return (
        payType.name.toLowerCase().indexOf(query) > -1 ||
        payType.code.toLowerCase().indexOf(query) > -1
      );
    });
  }

  @computed get hasSearchedPayTypes() {
    return this.searchedPayTypes.length > 0;
  }

  @computed get paginatedPayTypes() {
    return this.searchedPayTypes.slice(
      this.page * this.pageSize,
      this.page * this.pageSize + this.pageSize
    );
  }

  @action.bound
  sortByColumn() {
    // Clear New Item at the top of list on sort.
    this.payTypes.clearNew();

    if (this.sortDirection === 'asc') {
      this.sortDirection = 'desc';
    } else {
      this.sortDirection = 'asc';
    }
  }

  @computed
  get totalPages() {
    return Math.ceil(this.searchedPayTypes.length / this.pageSize);
  }

  @action.bound
  setPage(page) {
    this.page = page.selected;
  }

  @computed
  get payTypeFormIsInValid() {
    if (!this.payTypeForm) return true;

    return Boolean(
      this.payTypeForm.check('hasError') ||
        this.payTypeForm.$('name').check('isPristine') ||
        this.payTypeForm.$('code').check('isPristine') ||
        this.hasDuplicatePayTypeNew
    );
  }

  @action.bound async saveNewPayType(values) {
    this.payTypes
      .create({
        name: values.name,
        code: values.code
      })
      .then(payType => {
        runInAction(() => {
          this.showPayTypeCreated(payType);
        });
      })
      .catch(error => {
        errorHandler(error, this.notifications.pushError);
      });
  }

  @action.bound
  showPayTypeCreated(payType) {
    payType.setAsNew();

    this.rootStore.notificationsUI.pushNotification({
      showUndo: false,
      title: t('Pay Type Created')
    });

    this.setPage({
      selected: 0
    });
  }

  @computed get hasSelectedPayTypes() {
    return this.selectedPayTypes.length > 0;
  }

  @action.bound
  toggleSelectPayType(payType) {
    if (this.selectedPayTypes.find(id => id === payType.id)) {
      this.selectedPayTypes.remove(payType.id);
    } else {
      this.selectedPayTypes.push(payType.id);
    }
  }

  @computed
  get allPayTypesSelected() {
    return (
      this.selectablePayTypes.length > 0 &&
      this.selectedPayTypes.length === this.selectablePayTypes.length
    );
  }

  @computed
  get selectablePayTypes() {
    const persistentPayTypeCodes = ['RT', 'OT', 'DT'];

    return this.searchedPayTypes.filter(payType => {
      return !persistentPayTypeCodes.includes(payType.code);
    });
  }

  @action.bound
  toggleSelectAllPayTypes() {
    if (this.allPayTypesSelected) {
      this.selectedPayTypes.clear();
    } else {
      this.selectedPayTypes.replace(
        this.selectablePayTypes.map(payType => payType.id)
      );
    }
  }

  @action.bound softDeletePayType(payType) {
    const originalIndex = payType.collection.models.indexOf(payType);

    this.cancelEntryEdit();

    payType.collection.remove(payType);

    this.rootStore.notificationsUI.pushNotification({
      onUndo: () => {
        this.cancelDeletePayType(payType, originalIndex);
      },
      onDismiss: () => {
        this.confirmDeletePayType(payType);
      },
      title: t('Pay Type Deleted')
    });
  }

  @action.bound
  cancelDeletePayType(payType, index) {
    this.rootStore.payTypes.add(payType, {
      at: index
    });
  }

  @action.bound
  async confirmDeletePayType(payType) {
    try {
      await payType.destroy();
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  @action.bound
  deleteSelectedPayTypes() {
    this.cancelEntryEdit();

    const payTypes = this.payTypes.models.filter(payType =>
      this.selectedPayTypes.includes(payType.id)
    );

    this.softDeletePayTypes(payTypes);
    this.selectedPayTypes.clear();
  }

  @action.bound softDeletePayTypes(payTypes) {
    this.payTypes.remove(payTypes);

    this.rootStore.notificationsUI.pushNotification({
      onUndo: () => {
        this.cancelDeletePayTypes(payTypes);
      },
      onDismiss: () => {
        this.confirmDeletePayTypes(payTypes);
      },
      title: t('Pay Types Deleted')
    });
  }

  @action.bound
  cancelDeletePayTypes(payTypes) {
    this.rootStore.payTypes.add(payTypes);
  }

  @action.bound
  confirmDeletePayTypes(payTypes) {
    payTypes.forEach(payType => {
      payType.destroy();
    });
  }

  @action.bound setEntryForEdit(payType) {
    this.entryForEdit = payType;

    this.entryEditForm = new PayTypeForm(
      {
        fields: payTypeFormFields,
        rules: payTypeFormRules,
        labels: payTypeFormLabels,
        values: payType.formValues
      },
      {
        options: payTypeFormOptions,
        plugins: payTypeFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound cancelEntryEdit() {
    this.entryForEdit = null;
    this.entryEditForm = null;
  }

  checkIfEntryDisabled(entry) {
    return this.entryForEdit && this.entryForEdit.id !== entry.id;
  }

  @action.bound submitEntryEditForm() {
    if (this.hasDuplicatePayTypeEdit) return;

    this.entryEditForm.submit({
      onSuccess: this.submitEntryEditFormSuccess,
      onError: this.submitEntryEditFormError
    });
  }

  @action.bound submitEntryEditFormSuccess() {
    const { name, code } = this.entryEditForm.trimmedValues();

    this.entryForEdit.save({
      name,
      code
    });

    this.cancelEntryEdit();
  }

  @action.bound submitEntryEditFormError() {
    console.log(this.entryEditForm.errors());
  }

  @computed
  get hasDuplicatePayTypeNew() {
    return this.payTypes?.models.find(payType => {
      return (
        this.payTypeForm.$('name').value === payType.name &&
        this.payTypeForm.$('code').value === payType.code
      );
    });
  }

  @computed
  get hasDuplicatePayTypeEdit() {
    return this.payTypes?.models.find(payType => {
      return (
        this.entryEditForm.$('name').value === payType.name &&
        this.entryEditForm.$('code').value === payType.code &&
        this.entryForEdit.name !== payType.name &&
        this.entryForEdit.code !== payType.code
      );
    });
  }
}
