import { Model } from 'mobx-mc';
import { computed } from 'mobx';
import formatTimestamp from 'utils/formatTimestamp';
import kebabCase from 'lodash.kebabcase';

export default class Certification extends Model {
  get restAttributes() {
    return [
      'uuid',
      'type',
      'typeUuid',
      'certificationType',
      'name',
      'createdBy',
      'updatedDate',
      'createdDate'
    ];
  }

  idAttribute() {
    return 'uuid';
  }

  get urlRoot() {
    return `/ra/companies/${this.rootStore.me.company.uuid}/certifications`;
  }

  @computed
  get formValues() {
    return {
      uuid: this.uuid,
      type: this.type,
      typeUuid: this.typeUuid,
      certificationType: {
        uuid: this.typeUuid,
        name: this.type
      },
      name: this.name,
      createdBy: this.createdBy,
      updatedDate: this.updatedDate,
      createdDate: this.createdDate
    };
  }

  @computed
  get updatedDateFormatted() {
    return formatTimestamp(this.updatedDate);
  }

  @computed
  get createdDateFormatted() {
    return formatTimestamp(this.createdDate);
  }

  @computed get viewUrl() {
    return `/company-settings/certifications/certifications/${this.uuid}`;
  }

  @computed get certificationSlug() {
    return kebabCase(this.name);
  }
}
