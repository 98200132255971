import styled from 'styled-components';

export default styled.div`
  padding: ${props => (props.padding ? props.padding : 2)}rem;
  > * + {
    &:last-child:not(:first-child) {
      padding-top: 0;
    }
  }
  label {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
