import { RakenCollection } from '../lib';
import ProjectSummary from '../models/ProjectSummary';

export default class ProjectSummaries extends RakenCollection {
  model() {
    return ProjectSummary;
  }

  url() {
    return '/ra/project-summaries/scheduled';
  }
}
