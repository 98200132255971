import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Autocomplete from 'react-autocomplete';
import { Overlay } from 'react-overlays';
import { lighten } from 'polished';
import Validatorjs from 'validatorjs';

import Icon from './Icon';
import InputMenu from './InputMenu';
import InputMenuItem from './InputMenuItem';

const TokenInputContainer = styled.div`
  padding: ${props => (props.focused ? `5px 9px 0 9px;` : `6px 10px 0 10px`)};
  display: flex;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'text')};
  background: ${props => props.theme.colors.white};
  border-radius: 4px;
  color: ${props =>
    props.hasError ? props.theme.colors.red : props.theme.colors.charcoalGrey};
  font-family: ${props => props.theme.fonts.primary};
  font-size: 1.4rem;
  min-height: 40px;
  position: relative;
  box-sizing: border-box;
  flex-wrap: wrap;

  ${props =>
    props.disabled &&
    css`
      background-color: ${props.theme.colors.cream};
    `}

  ${props =>
    props.focused
      ? `
    border: 2px solid ${
      props.highlight
        ? props.theme.colors.goGreen
        : props.theme.colors[props.focusColor]
    };
  `
      : ` border: 1px solid ${props.theme.colors.lightGrey3}`};
`;

const Token = styled.div`
  display: inline-block;
  background-color: ${props =>
    props.disabled
      ? lighten(0.3, props.theme.colors.blueAccent)
      : props.theme.colors.blueAccent};
  color: ${props => props.theme.colors.white};
  border-radius: 4px;
  padding: 3px 7px;
  margin: 0 5px 6px 0;
  flex-shrink: 0;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'text')};
  line-height: 1.5;
`;

const IconWrapper = styled.div`
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
`;

const InputField = styled.input`
  border: 0;
  color: ${props =>
    props.hasError ? props.theme.colors.red : props.theme.colors.charcoalGrey};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'text')};
  font-family: ${props => props.theme.fonts.primary};
  font-size: 1.4rem;
  flex-grow: 1;
  padding-bottom: 8px;

  &:focus {
    outline: 0;
  }

  &::placeholder {
    color: ${props => props.theme.colors.lightGrey1};
    font-weight: 300;
  }

  &:disabled {
    background-color: ${props => props.theme.colors.cream};
  }
`;

const Menu = styled(InputMenu)`
  min-width: 0;
  z-index: 9999;
  width: ${props => props.width}px;
`;

const Controls = styled.div`
  display: flex;
  > button {
    margin: 0 3px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export default class TokenInput extends Component {
  static propTypes = {
    inputValue: PropTypes.string.isRequired,
    tokens: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    isValidNewOption: PropTypes.func,
    separatorKeyCodes: PropTypes.arrayOf(PropTypes.number),
    disabled: PropTypes.bool,
    enableAutocomplete: PropTypes.bool,
    inputItems: PropTypes.arrayOf(PropTypes.string)
  };

  static defaultProps = {
    isValidNewOption: () => true,
    separatorKeyCodes: [13],
    enableAutocomplete: true, // This will probably be false but enabling for now
    inputItems: [],
    focusColor: 'blueAccent'
  };

  state = {
    focused: false,
    hasError: false
  };

  handleRemoveIconClick = index => {
    const newTokens = this.props.tokens.slice();
    newTokens.splice(index, 1);
    this.inputRef.focus();

    this.props.onChange({
      inputValue: this.props.inputValue,
      tokens: newTokens
    });
  };

  handleInputChange = e => {
    this.props.onChange({
      inputValue: e.target.value,
      tokens: this.props.tokens
    });

    this.setState({
      hasError: false
    });
  };

  handleFocus = () => {
    const { clearItems } = this.props;

    // reset the value so it doesn't show the previous emails list
    if (clearItems) {
      clearItems();
    }

    this.setState({
      focused: true
    });
  };

  handleBlur = () => {
    const { form, validationFieldName } = this.props;
    this.setState({
      focused: false
    });

    this.handleSeparator();
    if (!form.$(validationFieldName).value.trim()) {
      form.$(validationFieldName).resetValidation();
    }
  };

  handleBulkPaste = e => {
    e.preventDefault();

    const { tokens, onChange } = this.props;
    const emails = e.clipboardData.getData('Text').split(/[,|;|\s]\s*/);
    const newTokens = tokens.slice();
    let validation;

    emails.forEach(email => {
      validation = new Validatorjs({ email: email }, { email: 'email' });

      if (validation.passes()) {
        // check that the email has not been added before
        if (newTokens.indexOf(email) === -1) {
          newTokens.push(email);
        }
      }
    });

    onChange({
      inputValue: '',
      tokens: newTokens
    });

    if (this.state.hasError) {
      this.setState({
        hasError: false
      });
    }
  };

  handleKeyDown = e => {
    // don't override when there is nothing to add to the email list and the action is 'tab'
    const { inputValue } = this.props;
    if (e.keyCode === 9 && !inputValue) {
      return true;
    }

    if (e.keyCode === 8) {
      this.handleBackspace(e);
    } else if (this.props.separatorKeyCodes.indexOf(e.keyCode) !== -1) {
      this.handleSeparator(e);
    }
  };

  handleBackspace = () => {
    const { inputValue, onChange, tokens } = this.props;

    const newTokens = tokens.slice();
    newTokens.pop();

    if (inputValue === '') {
      onChange({
        inputValue: '',
        tokens: newTokens
      });
    }
  };

  handleSeparator = e => {
    e && e.preventDefault();

    const { inputValue } = this.props;
    if (inputValue) {
      this.addToStack(inputValue);
    }
  };

  addToStack = newValue => {
    const { tokens, onChange, form, validationFieldName } = this.props;

    newValue &&
      form
        .$(validationFieldName)
        .validate()
        .then(({ isValid }) => {
          if (isValid) {
            const newTokens = tokens.slice();
            // Don't allow inserting the same value twice
            if (tokens.indexOf(newValue) === -1) {
              newTokens.push(newValue);
            }

            onChange({
              inputValue: '',
              tokens: newTokens
            });

            this.setState({
              hasError: false
            });
          } else {
            this.setState({
              hasError: true
            });
          }
        });
  };

  getPlaceholder = () => {
    const { inputValue, tokens } = this.props;

    return inputValue === '' && tokens.length === 0
      ? this.props.placeholder
      : '';
  };

  renderTokens = () => {
    const { disabled, tokens } = this.props;

    return tokens.map((token, index) => (
      <Token key={token} disabled={disabled}>
        {token}
        <IconWrapper
          onClick={() => {
            if (!disabled) {
              this.handleRemoveIconClick(index);
            }
          }}
        >
          <Icon
            kind="close"
            size={10}
            color="white"
            opacity="0.7"
            marginLeft="5"
          />
        </IconWrapper>
      </Token>
    ));
  };

  renderControls = item => {
    const { controls } = this.props;

    return React.Children.map(controls, control => {
      return React.cloneElement(control, {
        ...control.props,
        onClick: e => {
          control.props.onClick(e, item);
        }
      });
    });
  };

  renderInput() {
    const {
      inputValue,
      disabled,
      enableAutocomplete,
      inputItems,
      onChange,
      tokens,
      fieldName,
      controls,
      dataQA
    } = this.props;

    // Autocomplete
    if (enableAutocomplete) {
      return (
        <Autocomplete
          renderInput={props => (
            <InputField
              {...props}
              value={inputValue}
              placeholder={this.getPlaceholder()}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              onKeyDown={this.handleKeyDown}
              onPaste={this.handleBulkPaste}
              disabled={disabled}
              autoComplete={fieldName}
              hasError={this.state.hasError}
              data-qa={dataQA}
              ref={ref => {
                props.ref(ref);
                this.inputRef = ref;
              }}
            />
          )}
          renderMenu={(items, value, style) => {
            if (items.length < 1) return <span />;

            return (
              <Overlay
                show={true}
                target={this.containerRef}
                container={this.containerRef}
                placement="bottom"
              >
                <Menu children={items} width={this.containerRef.offsetWidth} />
              </Overlay>
            );
          }}
          renderItem={(item, isHighlighted) => (
            <InputMenuItem key={item} isHighlighted={isHighlighted}>
              <div>{item}</div>

              {controls && <Controls>{this.renderControls(item)}</Controls>}
            </InputMenuItem>
          )}
          wrapperStyle={{
            position: 'relative',
            display: 'flex',
            width: !!tokens.length ? 'auto' : '100%',
            textAlign: 'start'
          }}
          value={inputValue}
          items={inputItems}
          getItemValue={item => item}
          onChange={this.handleInputChange}
          autoHighlight={false}
          open={inputItems.length > 0}
          onSelect={value => {
            onChange({
              inputValue: value
            });

            this.addToStack(value);

            this.inputRef.focus();
          }}
        />
      );
    }

    // Standard
    return (
      <InputField
        value={inputValue}
        onChange={this.handleInputChange}
        placeholder={this.getPlaceholder()}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onKeyDown={this.handleKeyDown}
        onPaste={this.handleBulkPaste}
        ref={ref => (this.inputRef = ref)}
        hasError={this.state.hasError}
        disabled={disabled}
        data-qa={dataQA}
      />
    );
  }

  render() {
    const { disabled, focusColor } = this.props;
    return (
      <TokenInputContainer
        ref={ref => (this.containerRef = ref)}
        focused={this.state.focused}
        disabled={disabled}
        focusColor={focusColor}
      >
        {this.renderTokens()}
        {this.renderInput()}
      </TokenInputContainer>
    );
  }
}
