import { Model } from 'mobx-mc';
import { computed } from 'mobx';

export default class extends Model {
  url() {
    return '/ra/user/email/preferences';
  }

  get restAttributes() {
    return [
      'dailyReports',
      'missedReportNotifications',
      'projectNotification',
      'marketingEmails',
      'unsubscribeAll'
    ];
  }

  /**
   * Override is new so all saves use PATCH
   */
  @computed
  get isNew() {
    return false;
  }

  @computed
  get preferencesValues() {
    return {
      dailyReports: this.dailyReports,
      missedReportNotifications: this.missedReportNotifications,
      projectNotification: this.projectNotification,
      marketingEmails: this.marketingEmails,
      unsubscribeAll: this.unsubscribeAll
    };
  }

  @computed
  get isAllTrue() {
    const { unsubscribeAll, ...values } = this.preferencesValues;
    return Object.values(values).indexOf(false) < 0;
  }
}
