import React, { Component } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Highlighter from 'utils/highlighter';
import FileUpload from './FileUpload';
import Modal from './Modal';
import ModalHeader from './ModalHeader';
import H2 from './H2';
import Cropper from './Cropper';
import { t } from 'utils/translate';

/* Not editable version*/

const AvatarContainer = styled.div`
  background-color: ${props =>
    props.open ? props.theme.colors.lightGrey3 : 'transparent'};
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
`;

const Image = styled.div`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  background-color: ${props => props.theme.colors.lightGrey3};
  background-image: url('${props => (props.src ? props.src : 'none')}');
  background-size: cover;
  border: 1px solid ${props => props.theme.colors.lightGrey3};
  display: flex;
  border-radius: 50%;
  flex-shrink: 0;
`;

const Name = styled.div`
  padding-left: 1rem;
  text-align: left;
`;

/* Editable version*/

const ImageAvatar = styled.div`
  background: url(${props =>
      props.imageUrl || props.avatar || props.defaultImage || ''})
    no-repeat center center;
  background-size: cover;
  border-radius: 50%;
  box-sizing: border-box;
  position: relative;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
`;

const AddButton = styled.div`
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 0;
  position: absolute;
  pointer-events: none;
  z-index: 10;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  color: ${props => props.theme.colors.goGreen};
  text-transform: capitalize;
  font-weight: 700;

  :before {
    display: block;
    content: '';
    position: absolute;
    top: -7px;
    left: -7px;
    padding: 4px;
    border: 3px dashed transparent;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;

const UploadWrapper = styled.div`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'initial')};
  position: relative;
  border-radius: 50%;
  float: left;
  :hover ${AddButton} {
    font-size: 1.4rem;
    background-color: rgba(0, 0, 0, 0.75);
  }
  :hover ${AddButton}:before {
    border-color: ${props =>
      props.images && props.images.length
        ? 'transparent'
        : props.theme.colors.goGreen};
  }
`;

@observer
export default class Avatar extends Component {
  state = {
    image: null,
    showCropper: false
  };

  handleFileChange = image => {
    this.setState({
      image: image,
      showCropper: true
    });
  };

  handleCancelCropper = () => {
    this.setState({
      showCropper: false,
      image: null
    });
  };

  handleCrop = image => {
    this.setState({
      image: null,
      showCropper: false
    });

    this.props.uiStore.activeForm.update({
      images: [
        {
          base64: image
        }
      ]
    });
  };

  render() {
    const {
      size,
      name,
      avatar,
      highlightSearch,
      editable,
      fieldName,
      disabled,
      onDisabledClick,
      uiStore,
      defaultImage
    } = this.props;

    let activeForm = null;
    if (uiStore) {
      activeForm = uiStore.activeForm;
    }

    if (editable && activeForm) {
      let imageUrl =
        activeForm.$(fieldName).value[0] &&
        activeForm.$(fieldName).value[0].base64;

      return (
        <UploadWrapper
          disabled={disabled}
          images={uiStore.activeUser.images}
          onClick={() => {
            if (disabled && onDisabledClick) {
              onDisabledClick();
            }
          }}
        >
          <ImageAvatar
            imageUrl={imageUrl}
            avatar={avatar}
            defaultImage={defaultImage}
            size={size}
          />
          <AddButton
            imageUrl={imageUrl}
            avatar={avatar}
            images={uiStore.activeUser.images}
            activeForm={activeForm}
          >
            {'+ '}
            {t('PHOTO')}
          </AddButton>
          <FileUpload
            onFileChange={this.handleFileChange}
            disabled={disabled}
          />

          <Modal
            show={this.state.showCropper}
            onHide={this.handleCancelCropper}
            size={450}
            dataQA="modal_avatar-cropper"
          >
            <ModalHeader>
              <H2>{t('Crop Avatar')}</H2>
            </ModalHeader>

            <Cropper
              image={this.state.image}
              onCrop={this.handleCrop}
              onHide={this.handleCancelCropper}
              onUploadNew={this.handleFileChange}
              width={458}
              height={450}
              border={10}
            />
          </Modal>
        </UploadWrapper>
      );
    } else {
      return (
        <div>
          <AvatarContainer>
            <Image size={size} src={avatar ? avatar : null} />

            {name && (
              <Name>
                <Highlighter
                  searchWords={highlightSearch}
                  autoEscape={true}
                  textToHighlight={name}
                />
              </Name>
            )}
          </AvatarContainer>
        </div>
      );
    }
  }
}

Avatar.propTypes = {
  editable: PropTypes.bool.isRequired,
  size: PropTypes.number.isRequired,
  fieldName: PropTypes.string
};

Avatar.defaultProps = {
  editable: false,
  size: 40,
  fieldName: 'images'
};
