import { action, computed } from 'mobx';
import ProjectChildAddUI from './ProjectChildAddUI';
import EquipmentLog from '../../models/EquipmentLog';

import {
  EquipmentLogForm,
  equipmentLogFormOptions,
  equipmentLogFormFields,
  equipmentLogFormRules,
  equipmentLogFormValues,
  equipmentLogFormLabels,
  equipmentLogFormPlugins,
  equipmentLogFormRelated
} from 'forms/project/equipmentLog';

import history from 'utils/history';
import alertErrorHandler from 'utils/alertErrorHandler';

import { t } from 'utils/translate';

import { callTrack } from 'utils/segmentIntegration';
import { EQUIPMENT_LOG_ADDED } from 'utils/segmentAnalytics/eventSpec';

export default class EquipmentLogAddUI extends ProjectChildAddUI {
  @action.bound setup() {
    this.equipmentSelectorUI.setup();
    this.costCodeSelectorUI.setup();

    this.entryForAdd = new EquipmentLog(
      { type: 'EquipmentLog' },
      {
        rootStore: this.rootStore
      }
    );

    this.entryAddForm = new EquipmentLogForm(
      {
        fields: equipmentLogFormFields,
        rules: equipmentLogFormRules,
        labels: equipmentLogFormLabels,
        values: equipmentLogFormValues,
        related: equipmentLogFormRelated
      },
      {
        options: equipmentLogFormOptions,
        plugins: equipmentLogFormPlugins
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @action.bound tearDown() {
    this.equipmentSelectorUI.tearDown();
    this.costCodeSelectorUI.tearDown();
    this.unblockHistory();
    this.clearUIState();
  }

  @action.bound async submitEntryAddFormSuccess() {
    this.saving = true;

    try {
      const values = this.entryAddForm.values();

      const payload = {
        projectUuid: this.projectUuid,
        equipment: {
          uuid: values.equipment.uuid
        },
        startDate: values.startDate,
        endDate: values.endDate,
        status: values.status,
        costCode: {
          uuid: values.costCode.uuid
        }
      };

      if (payload.status !== 'REMOVED') {
        payload.endDate = null;
      }

      await this.entryForAdd.save(payload, {
        wait: true,
        stripNonRest: false
      });

      callTrack(EQUIPMENT_LOG_ADDED, {
        equipment_type: this.entryForAdd.equipment.owned ? 'owned' : 'rented',
        cost_code_selected: this.entryForAdd.costCode,
        status: this.entryForAdd.status
      });

      this.unblockHistory();
      this.parent.sortByLastCreated();
      this.cancelEquipmentLogAdd();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Equipment log created')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound cancelEquipmentLogAdd() {
    history.push({
      pathname: `${this.project.viewUrl}/equipment`,
      search: this.baseQueryParams
    });
  }

  @computed get title() {
    return t('Add equipment log');
  }

  @computed get statusOptions() {
    return [
      {
        value: 'INUSE',
        name: t('In Use')
      },
      {
        value: 'IDLE',
        name: t('Idle')
      },
      {
        value: 'REMOVED',
        name: t('Removed')
      }
    ];
  }

  @computed get selectedStatusOption() {
    return this.statusOptions.find(
      option => option.value === this.entryAddForm.$('status').value
    );
  }

  @computed get equipmentUI() {
    return this.parent.equipmentUI;
  }

  @computed get disableSaveButton() {
    return Boolean(
      this.saving ||
        !this.hasWriteAccess ||
        this.entryAddForm.$('equipment.activeProject').value.uuid ===
          this.projectUuid
    );
  }

  @computed get equipmentInUseOrIdleText() {
    if (
      this.entryAddForm.$('equipment.activeProject').value.uuid ===
      this.projectUuid
    ) {
      return t(
        `The selected equipment is already in use or idle on this project.`
      );
    }

    return t(
      `The selected equipment is in use or idle on the ${
        this.entryAddForm.$('equipment.activeProject').value.name
      } project. If you continue, it will be removed from that project.`
    );
  }
}
