import { action, observable, computed } from 'mobx';
import UIStore from '../../UIStore';
import history from 'utils/history';

import ChecklistTemplates from 'stores/collections/checklists/ChecklistTemplates';

import {
  checklistTemplateStartFormFields,
  checklistTemplateStartFormOptions,
  checklistTemplateStartFormPlugins,
  checklistTemplateStartFormRules,
  checklistTemplateStartFormValues,
  checklistTemplateStartFormLabels,
  checklistTemplateStartFormFieldOptions,
  ChecklistTemplateStartForm
} from 'forms/checklistTemplateStart';

export default class ChecklistTypeStartUI extends UIStore {
  @observable entryStartForm;

  @observable templateName;
  @observable templateNameErrorMessage;

  @observable templateType;
  @observable selectedChecklistTemplate;

  @observable searchQuery;

  constructor(options) {
    super(options);

    this.entryStartForm = null;

    this.rakenChecklistTemplates = new ChecklistTemplates(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.templateName = null;
    this.templateType = null;
    this.selectedChecklistTemplate = null;

    this.searchQuery = '';
  }

  @computed get checklistTemplateAddUI() {
    return this.parent.checklistTemplateAddUI;
  }

  @action.bound async setup() {
    this.checklistTypeSelectorUI.setup();

    this.rakenChecklistTemplates.fetch({
      params: {
        limit: 10000,
        scope: 'RAKEN'
      }
    });

    this.setupForm();
  }

  @action.bound setupForm() {
    this.entryStartForm = new ChecklistTemplateStartForm(
      {
        fields: checklistTemplateStartFormFields,
        rules: checklistTemplateStartFormRules,
        labels: checklistTemplateStartFormLabels,
        values: checklistTemplateStartFormValues,
        options: checklistTemplateStartFormFieldOptions
      },
      {
        options: checklistTemplateStartFormOptions,
        plugins: checklistTemplateStartFormPlugins,
        rootStore: this.rootStore
      }
    );
  }
  @action.bound tearDown() {
    this.clearUIState();
    this.checklistTypeSelectorUI.tearDown();
  }

  @action.bound clearUIState() {
    this.entryStartForm = null;
    this.rakenChecklistTemplates.clear();
    this.clearValidationDetails();
    this.activeModal = null;
    this.saving = false;
    this.selectedChecklistTemplate = null;
    this.searchQuery = '';
  }

  @action.bound
  cancelStartChecklistTemplate() {
    history.push({
      pathname: `/company-settings/checklists/templates`,
      search: this.baseQueryParams
    });
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
  }

  @action.bound clearSearchQuery(value) {
    this.searchQuery = '';
  }

  @computed get filteredRakenChecklistTemplates() {
    if (!this.searchQuery) return this.rakenChecklistTemplates.models;

    return this.rakenChecklistTemplates.models.filter(template => {
      return (
        template.name?.toLowerCase().indexOf(this.searchQuery.toLowerCase()) !==
        -1
      );
    });
  }

  @computed get selectedChecklistTemplateModel() {
    return this.rakenChecklistTemplates.get(this.selectedChecklistTemplate);
  }

  @action.bound setSelectedChecklistTemplate(uuid) {
    this.selectedChecklistTemplate = uuid;

    if (this.selectedChecklistTemplateModel) {
      this.entryStartForm
        .$('name')
        .set(this.selectedChecklistTemplateModel.name);
    }
  }

  @action.bound submitEntryStartForm(event) {
    event.preventDefault();

    this.entryStartForm.submit({
      onSuccess: this.submitEntryStartFormSuccess,
      onError: this.submitEntryStartFormError
    });
  }

  @action.bound submitEntryStartFormSuccess() {
    let startValues;

    const formValues = this.entryStartForm.values();

    if (this.selectedChecklistTemplate) {
      startValues = Object.assign(
        this.selectedChecklistTemplateModel.formValues,
        {
          name: formValues.name,
          checklistType: formValues.checklistType
        }
      );
    } else {
      startValues = {
        name: formValues.name,
        checklistType: formValues.checklistType
      };
    }

    this.checklistTemplateAddUI.setStartValues(startValues);

    this.addChecklistTemplate();
  }

  @action.bound submitEntryStartFormError() {
    console.error(this.entryStartForm.errors());
  }

  @action.bound async addChecklistTemplate() {
    history.push({
      pathname: `/company-settings/checklists/templates/add`,
      search: this.baseQueryParams
    });
  }
}
