import { action, observable } from 'mobx';
import ProjectChildUI from './ProjectChildUI';
import history from 'utils/history';

import SettingsInfoUI from './SettingsInfoUI';
import SettingsRadiusUI from './SettingsRadiusUI';
import SettingsPdfLogoUI from './SettingsPdfLogoUI';
import SettingsDisplayUI from './SettingsDisplayUI';
import SettingsReportsUI from './SettingsReportsUI';
import SettingsRecipientsUI from './SettingsRecipientsUI';
import SettingsSurveyUI from './SettingsSurveyUI';
import SettingsSegmentsUI from './SettingsSegmentsUI';
import SettingsIntegrationsUI from './SettingsIntegrationsUI';
import SettingsTimeCaptureUI from './SettingsTimeCaptureUI';
import SettingsNotificationsUI from './SettingsNotificationsUI';
import SettingsCostCodesUI from './SettingsCostCodesUI';
import SettingsMaterialsUI from './SettingsMaterialsUI';
import SettingsBudgetsUI from './SettingsBudgetsUI';
import SettingsPayTypesUI from './SettingsPayTypesUI';
import SettingsTrackTimeUI from './SettingsTrackTimeUI';
import SettingsShiftsUI from './SettingsShiftsUI';
import SettingsBreaksUI from './SettingsBreaksUI';
import SettingsLocationsUI from './SettingsLocationsUI';
import SettingsWatermarkUI from './SettingsWatermarkUI';

export default class SettingsUI extends ProjectChildUI {
  @observable previousPath;

  constructor(options) {
    super(options);

    this.previousPath = null;

    this.settingsInfoUI = new SettingsInfoUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.settingsRadiusUI = new SettingsRadiusUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.settingsPdfLogoUI = new SettingsPdfLogoUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.settingsDisplayUI = new SettingsDisplayUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.settingsReportsUI = new SettingsReportsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.settingsRecipientsUI = new SettingsRecipientsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.settingsSegmentsUI = new SettingsSegmentsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.settingsSurveyUI = new SettingsSurveyUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.settingsIntegrationsUI = new SettingsIntegrationsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.settingsTimeCaptureUI = new SettingsTimeCaptureUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.settingsWatermarkUI = new SettingsWatermarkUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.settingsCostCodesUI = new SettingsCostCodesUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.settingsMaterialsUI = new SettingsMaterialsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.settingsBudgetsUI = new SettingsBudgetsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.settingsNotificationsUI = new SettingsNotificationsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.settingsPayTypesUI = new SettingsPayTypesUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.settingsTrackTimeUI = new SettingsTrackTimeUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.settingsShiftsUI = new SettingsShiftsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.settingsBreaksUI = new SettingsBreaksUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.settingsLocationsUI = new SettingsLocationsUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound setup(previousPath) {
    this.setPreviousPath(previousPath);
  }

  @action.bound setPreviousPath(previousPath) {
    if (previousPath) {
      this.previousPath = previousPath;
    } else {
      this.previousPath = undefined;
    }
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.previousPath = null;
  }

  @action.bound cancelSettings() {
    history.push({
      pathname: this.previousPath || `${this.project.viewUrl}/worklogs`,
      search: this.baseQueryParams
    });
  }
}
