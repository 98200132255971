import {
  callIdentify as identify,
  callGroup as group,
  callTrack as track,
  callPage as page,
  callReset as reset,
  loadSegmentLib,
  logConsole
} from './segmentAnalytics/index';

let rootStore;

/**
 * Called in rootStore contructor.
 * @param {Object} store The root store of the app.
 */
export const initAnalytics = function(store) {
  rootStore = store;

  if (!rootStore) {
    logConsole('Rootstore must be provided first.');
    return;
  }

  if (disableTracking()) {
    logConsole('Admin will not be tracked.');
    return;
  }

  // If everything passed, proceed to load Segment analytics.
  window.addEventListener('load', loadSegment);
};

/**
 * Called everywhere else. Use these function calls directly in the app.
 */
export const callIdentify = function() {
  if (disableTracking()) {
    logConsole('Identify event will not be fired.');
    return;
  }

  identify(...arguments);
};

export const callGroup = function() {
  if (disableTracking()) {
    logConsole('Group event will not be fired.');
    return;
  }

  group(...arguments);
};

export const callTrack = function() {
  if (disableTracking()) {
    logConsole('Track event will not be fired.');
    return;
  }

  track(...arguments);
};

export const callPage = function() {
  if (disableTracking()) {
    logConsole('Page event will not be fired.');
    return;
  }

  page(...arguments);
};

export const callReset = function() {
  if (disableTracking()) {
    logConsole('Reset event will not be fired.');
    return;
  }

  reset(...arguments);
};

export default {
  initAnalytics,
  callIdentify,
  callTrack,
  callGroup,
  callReset
};

async function loadSegment() {
  try {
    if (!window.appConfig?.segmentId) {
      throw new Error('Segment ID is not available.');
    }

    loadSegmentLib(window.appConfig.segmentId, window.appConfig.isSuperAdmin);
  } catch (err) {
    console.error(err.message);
  }
}

function isDemoOrTestingAccount(username) {
  if (!username) return false;

  // Allow any non @rakenapp.com
  if (!username.includes('@rakenapp.com')) return false;

  return (
    username.includes('testing+') ||
    username.includes('automation+') ||
    username.includes('auto+') ||
    username.includes('demo+')
  );
}

/**
 * @return {Boolean}
 */
function disableTracking() {
  if (isDemoOrTestingAccount(rootStore?.me.username)) {
    return true;
  }

  return Boolean(rootStore?.isSudoing);
}
