import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Manager, Reference, Popper } from 'react-popper';

export default class DropdownV2 extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    trigger: PropTypes.node.isRequired,
    /**
     * See https://popper.js.org/popper-documentation.html#Popper.placements
     */
    position: PropTypes.string,
    onHide: PropTypes.func,
    flip: PropTypes.bool
  };

  static defaultProps = {
    position: 'bottom',
    onHide: () => {},
    flip: false,
    modifiers: {},
    positionFixed: false
  };

  componentWillMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = e => {
    if (this.props.isOpen) {
      const domNode = ReactDOM.findDOMNode(this).parentElement;
      if (
        !domNode ||
        (e.target instanceof Node && !domNode.contains(e.target))
      ) {
        this.props.onHide(e);
      }
    }
  };

  render() {
    let {
      trigger,
      position,
      children,
      isOpen,
      flip,
      modifiers,
      positionFixed
    } = this.props;

    if (flip) {
      modifiers.preventOverflow = { enabled: true };
    }

    return (
      <Manager>
        <Reference>
          {({ ref }) =>
            React.cloneElement(trigger, {
              ...trigger.props,
              ref
            })
          }
        </Reference>
        {isOpen && (
          <Popper
            placement={position}
            modifiers={modifiers}
            positionFixed={positionFixed}
          >
            {({ ref, style, placement }) =>
              React.cloneElement(children, {
                ...children.props,
                ref,
                style,
                placement
              })
            }
          </Popper>
        )}
      </Manager>
    );
  }
}
