import { observable, action, computed } from 'mobx';

class UIStore {
  @observable activeModal;
  @observable activeForm;
  @observable validationDetails;
  @observable saving;
  @observable activeModalPage;

  constructor(options) {
    this.activeModal = null;
    this.activeModalPage = null;
    this.activeForm = null;
    this.saving = false;
    this.validationDetails = [];

    if (options.rootStore) {
      this.rootStore = options.rootStore;
    }

    if (options.parent) {
      this.parent = options.parent;
    }
  }

  @action.bound
  clearUIState() {
    this.activeModal = null;
    this.activeForm = null;
  }

  @computed
  get project() {
    return this.projectUI.project;
  }

  @computed
  get hasActiveModal() {
    return this.activeModal;
  }

  @computed
  get me() {
    return this.rootStore.me;
  }

  @computed
  get company() {
    return this.me.company;
  }

  @computed
  get countries() {
    return this.rootStore.countries;
  }

  @computed
  get regions() {
    return this.rootStore.regions;
  }

  @computed
  get settings() {
    return this.me.settings;
  }

  @computed
  get subscription() {
    return this.company && this.company.subscription;
  }

  @computed
  get billingPlan() {
    return this.company && this.company.billingPlan;
  }

  @computed get isRecurlyBilling() {
    return this.company.isRecurlyBilling;
  }

  @computed
  get authorization() {
    return this.rootStore.authorizationUI;
  }

  @computed
  get notifications() {
    return this.rootStore.notificationsUI;
  }

  @computed
  get companyPermissions() {
    return this.rootStore.companyPermissions;
  }

  @computed
  get projectTemplates() {
    return this.rootStore.projectTemplates;
  }

  @computed
  get featureFlags() {
    return this.rootStore.featureFlags;
  }

  @computed
  get isSuperAdmin() {
    return this.rootStore.isSuperAdmin;
  }

  @computed
  get assetsURL() {
    return this.rootStore.assetsURL;
  }

  @computed
  get emailUI() {
    return this.rootStore.emailUI;
  }

  @computed
  get projectTeamsUI() {
    return this.rootStore.projectTeamsUI;
  }

  @computed
  get projectsUI() {
    return this.rootStore.projectsUI;
  }

  @computed
  get projectUI() {
    return this.rootStore.projectUI;
  }

  @computed
  get workers() {
    return this.rootStore.workers;
  }

  @computed get hasWorkers() {
    return this.workers.length > 0;
  }

  @computed get members() {
    return this.rootStore.users;
  }

  @computed get hasMembers() {
    return this.members.length > 0;
  }

  @computed
  get costCodes() {
    return this.rootStore.costCodes;
  }

  @computed get hasCostCodes() {
    return this.costCodes.length > 0;
  }

  @computed
  get classificationsUI() {
    return this.rootStore.classificationsUI;
  }

  @computed
  get unitsUI() {
    return this.rootStore.unitsUI;
  }

  @computed
  get materialsUI() {
    return this.rootStore.materialsUI;
  }

  @computed
  get equipmentUI() {
    return this.rootStore.equipmentUI;
  }

  @computed get headerUI() {
    return this.rootStore.headerUI;
  }

  @action.bound
  urlMicroService(key) {
    return this.rootStore.urlMicroService(key);
  }

  @action.bound
  showModal(modalName) {
    clearTimeout(this.modalTimer);

    return new Promise((resolve, reject) => {
      if (this.activeModal) {
        this.hideActiveModal().then(() => {
          this.activeModal = modalName;
          setTimeout(() => {
            resolve();
          }, 350);
        });
      } else {
        this.activeModal = modalName;
        setTimeout(() => {
          resolve();
        }, 350);
      }
    });
  }

  @action.bound
  hideActiveModal(clearValidationDetails = true) {
    if (this.activeModal) {
      this.activeModal = null;

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (clearValidationDetails) {
            this.clearValidationDetails();
          }
          resolve();
        }, 350);
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve();
      });
    }
  }

  @action.bound
  showModalPage(modalPageName) {
    return new Promise((resolve, reject) => {
      if (this.activeModalPage) {
        this.hideActiveModal().then(() => {
          setTimeout(() => {
            this.activeModalPage = modalPageName;
            resolve();
          }, 500);
        });
      } else {
        setTimeout(() => {
          this.activeModalPage = modalPageName;
          resolve();
        }, 500);
      }
    });
  }

  @action.bound
  hideActiveModalPage() {
    if (this.activeModalPage) {
      this.activeModalPage = null;
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve();
        }, 500);
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve();
      });
    }
  }

  @action.bound
  setValidationDetails(validationDetails) {
    this.validationDetails = validationDetails;
  }

  @action.bound
  clearValidationDetails() {
    this.validationDetails = [];
  }

  @computed get hasValidationDetails() {
    return this.validationDetails.length > 0;
  }

  @action.bound
  clearActiveForm() {
    this.activeForm = null;
  }

  @computed get websiteUrl() {
    return this.rootStore.appConfig.website_url;
  }

  @computed
  get projectSelectorUI() {
    return this.rootStore.projectSelectorUI;
  }

  @computed
  get shiftSelectorUI() {
    return this.rootStore.shiftSelectorUI;
  }

  @computed
  get payTypeSelectorUI() {
    return this.rootStore.payTypeSelectorUI;
  }

  @computed
  get breakSelectorUI() {
    return this.rootStore.breakSelectorUI;
  }

  @computed
  get classificationSelectorUI() {
    return this.rootStore.classificationSelectorUI;
  }

  @computed
  get collaboratorSelectorUI() {
    return this.rootStore.collaboratorSelectorUI;
  }

  @computed
  get projectLocationSelectorUI() {
    return this.rootStore.projectLocationSelectorUI;
  }

  @computed
  get costCodeSelectorUI() {
    return this.rootStore.costCodeSelectorUI;
  }

  @computed
  get materialSelectorUI() {
    return this.rootStore.materialSelectorUI;
  }

  @computed
  get equipmentSelectorUI() {
    return this.rootStore.equipmentSelectorUI;
  }

  @computed
  get memberSelectorUI() {
    return this.rootStore.memberSelectorUI;
  }

  @computed
  get payPeriodSelectorUI() {
    return this.rootStore.payPeriodSelectorUI;
  }

  @computed
  get checklistTypeSelectorUI() {
    return this.rootStore.checklistTypeSelectorUI;
  }

  @computed
  get groupSelectorUI() {
    return this.rootStore.groupSelectorUI;
  }

  @computed get superAdminUI() {
    return this.rootStore.superAdminUI;
  }

  @computed get liveViewsUI() {
    return this.rootStore.liveViewsUI;
  }

  @computed get timesheetsUI() {
    return this.rootStore.timesheetsUI;
  }

  @computed get hasRyvitIntegration() {
    return this.timesheetsUI.hasRyvitIntegration;
  }

  @computed get ryvitIntegrationName() {
    return this.timesheetsUI.ryvitIntegrationName;
  }

  @computed get defaultPhoneCountry() {
    return this.company?.address?.country?.toLowerCase() || 'us';
  }
}

export default UIStore;
