import orderBy from 'lodash.orderby';
import UIStore from 'stores/ui/UIStore';
import { action, computed, observable } from 'mobx';
import Devices from 'stores/collections/superAdmin/Devices';
import alertErrorHandler from 'utils/alertErrorHandler';

export default class SuperAdminUserMobileActivityDevicesUI extends UIStore {
  @observable loading;
  @observable sortField;
  @observable sortDirection;

  constructor(options) {
    super(options);

    this.loading = true;
    this.sortField = 'type';
    this.sortDirection = 'desc';

    this.devices = new Devices(null, {
      rootStore: this
    });
  }

  @computed
  get activeUser() {
    return this.parent.activeUser;
  }

  @computed get hasDevices() {
    return this.devices.hasModels;
  }

  @action.bound setup() {
    this.fetchDevices();
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.devices.clear();

    this.loading = true;
    this.sortField = 'type';
    this.sortDirection = 'desc';
  }

  @action.bound
  async fetchDevices() {
    this.devices.cancelRequest();
    this.devices.clear();

    try {
      await this.devices.fetch({
        params: {
          limit: 10000
        }
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }
  }

  @computed
  get sortedDevices() {
    return orderBy(this.devices.models, [this.sortField], [this.sortDirection]);
  }

  @computed get showEmptyState() {
    return !this.loading && !this.hasDevices;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }
}
