import { computed, action, observable } from 'mobx';
import { Model } from 'mobx-mc';

import { t } from 'utils/translate';
import isLocalStorageAvailable from 'utils/isLocalStorageAvailable';

import orderBy from 'lodash.orderby';
export default class TimesheetSettings extends Model {
  @observable columns;
  constructor(attributes, options) {
    super(attributes, options);
    this.integrations = observable([]);
  }

  get restAttributes() {
    return [];
  }

  get urlRoot() {
    return `${this.rootStore.urlMicroService('integrations')}/companies/${
      this.rootStore.me.company.uuid
    }/integrations?type=accounting`;
  }

  @action.bound
  parse(attributes) {
    this.parseIntegrations(attributes);
    this.parseColumns();

    return {
      ...attributes
    };
  }

  @action.bound
  parseIntegrations(attributes) {
    this.integrations = attributes.collection;
  }

  @computed get integrationSyncEnabled() {
    return this.integrations?.some(integration => integration.apiBased);
  }

  @action.bound
  parseColumns() {
    if (!isLocalStorageAvailable()) {
      this.columns = this.defaultColumns;
      return;
    }

    const localColumns = JSON.parse(localStorage.getItem(`timesheetColumns`));

    if (localColumns) {
      this.columns = this.defaultColumnsWithSavedOrder(localColumns);
    } else {
      this.columns = this.defaultColumns;
    }
  }

  defaultColumnsWithSavedOrder(savedColumns) {
    let orderBump = 0;

    return this.defaultColumns.map(column => {
      const existingColumn = savedColumns.find(
        localColumn => localColumn.name === column.name
      );

      if (!existingColumn) {
        orderBump++;
      }

      return {
        ...column,
        order: existingColumn ? existingColumn.order + orderBump : column.order,
        hidden: existingColumn ? existingColumn.hidden : column.hidden
      };
    });
  }

  insertColumnAndReorderFollowingColumns(columns, columnToAdd) {
    const reordered = columns.map(column => {
      if (column.order < columnToAdd.order) {
        return column;
      }

      return {
        ...column,
        order: (column.order = column.order + 1)
      };
    });

    return [...reordered, columnToAdd];
  }

  @computed
  get displayedStatusName() {
    return {
      ALL: t('All'),
      APPROVED: t('Approved'),
      SIGNED: t('Signed'),
      UNSIGNED: t('Unsigned'),
      NOT_APPROVED: t('Unapproved')
    };
  }

  get defaultColumns() {
    let columns = [
      {
        name: 'WORKER_NAME',
        order: 1,
        hidden: false,
        displayName: t('Name')
      },
      {
        name: 'DATE',
        order: 2,
        hidden: false,
        displayName: t('Date')
      },
      {
        name: 'PROJECT',
        order: 3,
        hidden: false,
        displayName: t('Project')
      },
      {
        name: 'TOTAL_HOURS',
        order: 4,
        hidden: false,
        displayName: t('Total hours')
      },
      {
        name: 'STATUS',
        order: 5,
        hidden: false,
        displayName: t('Status')
      },
      {
        name: 'CLASSIFICATION',
        order: 6,
        hidden: false,
        displayName: t('Classification')
      },
      {
        name: 'SHIFT',
        order: 7,
        hidden: false,
        displayName: t('Shift')
      },
      {
        name: 'PAY_TYPES',
        order: 8,
        hidden: false,
        displayName: t('Pay type')
      },
      {
        name: 'COST_CODE',
        order: 9,
        hidden: false,
        displayName: t('Cost code')
      },
      {
        name: 'START_END_TIME',
        order: 10,
        hidden: false,
        displayName: t('Start and end time')
      },
      {
        name: 'BREAK',
        order: 11,
        hidden: false,
        displayName: t('Break')
      },
      {
        name: 'ORIGIN',
        order: 12,
        hidden: false,
        displayName: t('Origin')
      },
      {
        name: 'LAST_UPDATED_BY',
        order: 13,
        hidden: false,
        displayName: t('Last updated by')
      },
      {
        name: 'APPROVED_BY',
        order: 14,
        hidden: false,
        displayName: t('Approved by')
      },
      {
        name: 'PAYROLL_NOTE',
        order: 15,
        hidden: false,
        displayName: t('Payroll note')
      }
    ];

    if (this.integrationSyncEnabled) {
      columns = this.insertColumnAndReorderFollowingColumns(columns, {
        name: 'INTEGRATION_SYNC_STATUS',
        order: 6,
        hidden: false,
        displayName: t('Integration sync status')
      });
    }

    return orderBy(columns, ['order']);
  }

  get displayedColumnName() {
    return {
      WORKER_NAME: t('Name'),
      DATE: t('Date'),
      PROJECT: t('Project'),
      TOTAL_HOURS: t('Total hours'),
      STATUS: t('Status'),
      CLASSIFICATION: t('Classification'),
      COST_CODE: t('Cost code'),
      START_END_TIME: t('Start and end time'),
      LUNCH: t('Lunch'),
      BREAK: t('Breaks'),
      LAST_UPDATED_BY: t('Last updated by'),
      APPROVED_BY: t('Approved by'),
      PAYROLL_NOTE: t('Payroll note'),
      INTEGRATION_SYNC_STATUS: t('Integration sync status'),
      ORIGIN: t('Origin'),
      SHIFT: t('Shifts'),
      PAY_TYPES: t('Pay types'),
      BREAKS: t('Breaks')
    };
  }

  get columnsWidth() {
    const columns = {
      //first sticky column for actions, date and worker name = 350
      WORKER_NAME: 175,
      DATE: 175,
      //custom order columns
      PAY_TYPES: 280,
      PROJECT: 350,
      TOTAL_HOURS: 120,
      STATUS: 200,
      CLASSIFICATION: 310,
      COST_CODE: 310,
      START_END_TIME: 200,
      BREAK: 280,
      ORIGIN: 280,
      LAST_UPDATED_BY: 300,
      APPROVED_BY: 300,
      PAYROLL_NOTE: 500,
      SHIFT: 280,
      //sticky actions column
      ACTIONS: 70
    };

    if (this.integrationSyncEnabled) {
      columns.INTEGRATION_SYNC_STATUS = 300;
    }

    return columns;
  }

  @computed
  get tableWidth() {
    let tableWidth = this.columnsWidth['ACTIONS'];
    for (let column of this.visibleColumns) {
      tableWidth = tableWidth + this.columnsWidth[column.name];
    }

    return tableWidth;
  }

  @computed
  get visibleColumns() {
    return this.columns.filter(column => !column.hidden);
  }

  @computed get ryvitIntegrations() {
    return this.integrations?.filter(integration => {
      return [1015, 1016, 1017, 1018].includes(integration.id);
    });
  }

  @computed get hasRyvitIntegration() {
    return this.ryvitIntegrations.length > 0;
  }

  @computed get ryvitIntegrationName() {
    if (!this.hasRyvitIntegration) return null;

    return this.ryvitIntegrations[0].name;
  }
}
