import kebabCase from 'lodash.kebabcase';
import capitalize from 'lodash.capitalize';
import { computed } from 'mobx';
import { Model } from 'mobx-mc';

export default class SuperAdminSuperUser extends Model {
  get restAttributes() {
    return [
      'userId',
      'uuid',
      'username',
      'firstName',
      'lastName',
      'email',
      'status',
      'password'
    ];
  }

  idAttribute() {
    return 'uuid';
  }

  get urlRoot() {
    return '/ra/sadmin/superusers';
  }

  @computed
  get trimmedLastName() {
    if (this.lastName) {
      const arr = this.lastName.split(' ').filter(word => word !== '@Raken');

      return arr.length > 1 ? arr.join(' ') : arr[0];
    }

    return '';
  }

  get restAttributeDefaults() {
    return {
      password: ''
    };
  }

  @computed
  get fullName() {
    return `${this.firstName} ${this.trimmedLastName}`;
  }

  @computed
  get statusFormatted() {
    return capitalize(this.status);
  }

  @computed get statusColor() {
    switch (this.status) {
      case 'ACTIVE':
        return 'green';
      case 'INACTIVE':
        return 'red';
      default:
        return 'green';
    }
  }

  @computed get isActive() {
    return this.status === 'ACTIVE';
  }

  @computed
  get avatarImage() {
    return this.rootStore.assetsURL + '/images/avatar.png';
  }

  @computed get formValues() {
    return {
      username: this.username,
      password: this.password,
      firstName: this.firstName,
      lastName: this.lastName
    };
  }

  @computed get slug() {
    return kebabCase(this.fullName);
  }
}
