import styled from 'styled-components';
import media from 'utils/mediaTemplate';
import Text from './Text';

export default styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  ${Text} {
    margin: 1rem 1rem 1rem 0;
  }
  ${media.desktop`
    align-items: center;
    flex-direction: row;
    ${Text} {
      margin: 0 1rem 0 0;
    }
    > div:last-child {
      margin-left: 1rem;
      margin-top: 0;
    }
  `};
`;
