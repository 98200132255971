import omit from 'lodash.omit';
import { computed, action } from 'mobx';
import ReportEntryWithAttachments from './ReportEntryWithAttachments';
import moment from 'moment';
import EquipmentLog from './EquipmentLog';

export default class EquipmentLogNote extends ReportEntryWithAttachments {
  get restAttributes() {
    return [
      'uuid',
      'date',
      'hours',
      'description',
      'createdTimestamp',
      'updatedTimestamp',
      'equipmentLogUuid',
      'type',
      'teamId',
      'project',
      'attachments'
    ];
  }

  get urlRoot() {
    return `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/companies/${this.rootStore.me.company.uuid}/equipmentlogs/${
      this.equipmentLogUuid
    }/notes`;
  }

  idAttribute() {
    return 'uuid';
  }

  @action.bound
  parse(attributes) {
    this.parseCreatedBy(attributes);
    this.parseUpdatedBy(attributes);
    this.parseProject(attributes);
    this.parseCompany(attributes);
    this.parseAttachments(attributes);
    this.parseEquipmentLog(attributes);

    return {
      ...omit(attributes, [
        'attachments',
        'createdBy',
        'updatedBy',
        'project',
        'company'
      ])
    };
  }

  @action.bound parseEquipmentLog(attributes) {
    if (attributes.equipmentLog) {
      this.equipmentLog = new EquipmentLog(attributes.equipmentLog, {
        rootStore: this.rootStore
      });
    }
  }

  @computed get equipment() {
    return this.equipmentLog.equipment;
  }

  @computed get title() {
    return this.equipmentLog.title;
  }

  @computed get activityTitle() {
    return this.equipmentLog.activityTitle;
  }

  @computed
  get formattedDate() {
    if (this.date) {
      return moment(this.date).format('YYYY-MM-DD');
    } else {
      return moment().format('YYYY-MM-DD');
    }
  }

  @computed
  get formValues() {
    return {
      date: this.formattedDate,
      hours: this.hours,
      description: this.description
    };
  }
}
