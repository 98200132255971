import styled, { css } from 'styled-components';

export default styled.li`
  border-left: 4px solid
    ${props => (props.active ? props.theme.colors.goGreen : 'transparent')};
  cursor: ${props => (props.onClick ? 'pointer' : 'inherit')};
  display: ${props => (props.block ? 'block' : 'flex')};
  flex-direction: ${props => (props.vertical ? 'column' : 'row')};
  font-weight: ${props => (props.active ? '700' : '400')};
  align-items: ${props => (props.vertical ? 'flex-start' : 'center')};
  justify-content: flex-start;
  list-style-type: none;
  line-height: 1.5em;
  margin: 0 0 2px 0;
  padding: ${props =>
    props.padding ? props.padding : '1.5rem 2rem 1.5rem 16px'};
  &:nth-child(odd) {
    background-color: ${props => props.theme.colors.lightGrey4};
  }
  ${props =>
    props.selected &&
    css`
      box-shadow: 0 6px 6px -2px rgba(0, 0, 0, 0.5);
      border: 2px solid ${props => props.theme.colors.goGreen};
    `};
`;
