import UIStore from '../UIStore';
import { action, computed, runInAction, observable, reaction } from 'mobx';
import orderBy from 'lodash.orderby';
import escaperegexp from 'lodash.escaperegexp';
import omit from 'lodash.omit';
import request from 'axios';
import errorHandler from 'utils/errorHandler';
import Worker from 'stores/models/Worker';
import {
  WorkerForm,
  workerFormOptions,
  workerFormFields,
  workerFormRules,
  workerFormFieldOptions,
  workerFormLabels,
  workerFormPlugins
} from 'forms/worker';

import { t } from 'utils/translate';

export default class WorkLogWorkersUI extends UIStore {
  @observable workerSearchQuery = '';
  @observable selectedWorkers = [];
  @observable addWorkerForm = null;
  @observable addWorkersReturnModal = null;
  @observable step = 'new';

  constructor(options) {
    super(options);

    //Default settings
    this.workersPageSize = 80;
    this.setupWorkersReactions();
  }

  @action.bound
  openSearchAndSelectWorkerModal() {
    return this.rootStore.authorizationUI
      .checkFeatureAccess('CreateCrewWorkLog')
      .then(() => {
        runInAction(() => {
          this.activeModal = 'searchAndSelectWorkerModal';
        });
      });
  }

  @action.bound setStep(step) {
    this.step = step;
  }

  @computed
  get workersParams() {
    let loadAllTeamMembers;
    let loadOnlyWorkers;

    if (this.company?.preferences?.trackMemberTime) {
      loadAllTeamMembers = Boolean(
        this.company.preferences.trackMemberTime ===
          'assignedWorkersAndAllTeamMembers'
      );

      loadOnlyWorkers = Boolean(
        this.company.preferences.trackMemberTime === 'assignedWorkersOnly'
      );
    } else {
      loadAllTeamMembers = true;
      loadOnlyWorkers = false;
    }

    const params = {
      query: this.workerSearchQuery,
      limit: this.workersPageSize,
      sortField: 'firstName,lastName',
      sortDirection: 'ASC',
      status: 'ACTIVE',
      loadAllTeamMembersForTimecards: loadAllTeamMembers
    };

    if (loadOnlyWorkers) {
      params.role = 'ROLE_WORKER';
    }

    return params;
  }

  @action.bound
  async closeSearchAndSelectWorkerModal() {
    await this.hideActiveModal();
    this.workerSearchQuery = '';
  }

  @action.bound
  async cancelAddWorkers() {
    await this.hideActiveModal();
    this.workerSearchQuery = '';
    this.selectedWorkers.clear();
  }

  setupWorkersReactions() {
    this.reactToParams = reaction(
      () => this.workersParams,
      workersParams => {
        this.fetchWorkers({
          add: true,
          remove: false,
          update: false
        });
      }
    );
  }

  @action.bound
  setWorkerSearchQuery(query) {
    this.workerSearchQuery = query;
  }

  @action.bound
  clearWorkerSearchQuery() {
    this.workerSearchQuery = '';
  }

  @computed get canFetchWorkers() {
    return (
      this.project?.uuid &&
      this.company?.uuid &&
      this.authorization.canCreateCrewWorkLog
    );
  }

  @action.bound
  fetchWorkers(options) {
    if (!this.canFetchWorkers || this.workers.fetching) return;

    this.workers
      .fetch({
        params: {
          ...this.workersParams,
          projectUuids: this.project.uuid
        },
        ...options
      })
      .catch(error => {
        errorHandler(error, this.notifications.pushError);
      });
  }

  @computed get hasMoreWorkers() {
    return this.workers.totalElements > this.workers.length;
  }

  @action.bound fetchNextPageOfWorkers() {
    if (this.workers.fetching) return;

    if (this.hasMoreWorkers) {
      this.workers
        .fetch({
          params: {
            ...this.workersParams,
            offset: this.workers.length,
            projectUuids: this.project.uuid
          },
          add: true,
          remove: false,
          update: false
        })
        .catch(error => {
          errorHandler(error, this.notifications.pushError);
        });
    }
  }

  @computed get filteredWorkers() {
    return this.workers.models;
  }

  @computed get sortedWorkers() {
    return orderBy(this.filteredWorkers, ['new'], ['desc']);
  }

  @computed
  get searchedWorkers() {
    if (!this.workerSearchQuery) {
      return this.sortedWorkers;
    }

    const searchExpression = new RegExp(
      escaperegexp(this.workerSearchQuery.trim()),
      'i'
    );

    const workerFilter = worker => {
      return (
        (worker.fullName && worker.fullName.search(searchExpression) !== -1) ||
        (worker.employeeId && worker.employeeId.search(searchExpression) !== -1)
      );
    };
    return this.sortedWorkers.filter(workerFilter);
  }

  @computed get visibleWorkersList() {
    // for new time card log
    if (!this.parent.timeEntriesWorkLogForm) {
      return this.searchedWorkers;
    }

    // for existing time card log
    return this.searchedWorkers.filter(worker => {
      return !this.parent.timeEntriesWorkLogForm.workerUuids.includes(
        worker.workerUuid
      );
    });
  }

  @computed get hasNonSelectedWorkers() {
    return this.visibleWorkersList.length > 0;
  }

  @computed get hasSelectedWorkers() {
    return this.selectedWorkers.length > 0;
  }

  @action.bound
  toggleWorkerForSelection(worker) {
    if (
      this.selectedWorkers.find(selectedWorker => selectedWorker === worker)
    ) {
      this.selectedWorkers.remove(worker);
    } else {
      this.selectedWorkers.push(worker);
    }
  }

  @computed
  get allWorkersSelected() {
    return this.visibleWorkersList.length === this.selectedWorkers.length;
  }

  @computed
  get hasPartlySelectedWorkers() {
    return !this.allWorkersSelected && this.hasSelectedWorkers;
  }

  @action.bound
  toggleAllWorkers() {
    if (!this.hasSelectedWorkers) {
      this.selectedWorkers.replace(this.visibleWorkersList);
    } else {
      this.selectedWorkers.clear();
    }
  }

  @action.bound
  async openAddWorkerModal() {
    this.addWorkersReturnModal = this.activeModal;
    await this.hideActiveModal();

    return this.authorization.checkFeatureAccess('EditWorkers').then(() => {
      runInAction(() => {
        this.activeModal = 'addWorkerModal';

        this.addWorkerForm = new WorkerForm(
          {
            fields: workerFormFields,
            rules: workerFormRules,
            labels: workerFormLabels,
            options: workerFormFieldOptions
          },
          {
            options: workerFormOptions,
            plugins: workerFormPlugins,
            rootStore: this.rootStore
          }
        );
      });
    });
  }

  @action.bound async addExistingWorker(existingWorker) {
    const worker = this.workers.getOrAdd(existingWorker);
    await worker.fetch();

    if (!worker.projectUuids.includes(this.project.uuid)) {
      worker.projectUuids.push(this.project.uuid);
      worker.setAsNew();
      worker.save({
        projectUuids: worker.projectUuids
      });
    }

    this.selectedWorkers.push(worker);
    this.closeAddWorkerModal();
  }

  @action.bound
  async closeAddWorkerModal() {
    await this.hideActiveModal();
    this.activeModal = this.addWorkersReturnModal;
    this.addWorkersReturnModal = null;
    this.addWorkerForm = null;
    this.step = 'new';
  }

  @action.bound
  submitAddWorkerForm(e) {
    e.preventDefault();
    e.stopPropagation();

    this.addWorkerForm.submit({
      onSuccess: this.submitAddWorkerFormSuccess,
      onError: this.submitAddWorkerFormError
    });
  }

  @action.bound submitAddWorkerFormSuccess() {
    this.setStep('defaults');
  }

  @action.bound skipDefaults() {
    this.addWorkerForm.$('defaultShift').clear();
    this.addWorkerForm.$('defaultCostCode').clear();
    this.addWorkerForm.$('defaultCrewName').clear();

    this.createWorker();
  }

  @action.bound
  async createWorker() {
    this.saving = true;

    let data = {
      ...this.addWorkerForm.trimmedValues(),
      settings: {
        workerDefaultShift: this.addWorkerForm.$('defaultShift').value,
        workerDefaultCostCode: this.addWorkerForm.$('defaultCostCode').value,
        workerDefaultCrewName: this.addWorkerForm.$('defaultCrewName').value
      },
      role: 'ROLE_WORKER',
      isDefault: false,
      projectUuids: [this.project.uuid]
    };

    data = omit(data, ['defaultShift', 'defaultCostCode', 'defaultCrewName']);

    if (!data.classification?.uuid) {
      delete data.classification;
    }

    try {
      const response = await request.post(
        `${this.rootStore.users.url()}/batch`,
        [data]
      );

      const worker = new Worker(response.data[0], {
        rootStore: this.rootStore
      });

      this.workers.getOrAdd(worker);

      this.selectedWorkers.push(worker);
      worker.setAsNew();
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }

    this.saving = false;
    this.closeAddWorkerModal();
  }

  @action.bound
  submitAddWorkerFormError() {
    console.log(
      'wrong values in the add new worker form',
      this.addWorkerForm.trimmedValues()
    );
  }

  @computed
  get disableAddWorkerSaveButton() {
    return (
      this.addWorkerForm.hasError ||
      !this.addWorkerForm.$('firstName').value ||
      !this.addWorkerForm.$('lastName').value ||
      this.addWorkerForm.existingWorker
    );
  }

  @computed
  get addWorkerClassificationOptions() {
    return this.classificationsUI.selectableClassifications.map(
      classification => {
        return {
          uuid: classification.uuid,
          name: classification.name
        };
      }
    );
  }

  @computed get workerExistsInProject() {
    if (!this.addWorkerForm) return false;

    return (
      this.addWorkerForm.existingWorker &&
      this.addWorkerForm.existingWorker.getProjectByUuid(this.project.uuid)
    );
  }

  @computed
  get addWorkerFormEmployeeIdError() {
    if (this.addWorkerForm.$('employeeId').error) {
      return this.addWorkerForm.$('employeeId').error;
    }

    if (this.workerExistsInProject) {
      return t(
        'Worker already exists, please provide an EID to differentiate between the workers.'
      );
    }

    return '';
  }

  @action.bound
  addWorkersToWorkLog() {
    this.parent.addWorkersToWorkLog();
  }
}
