import UIStore from './UIStore';
import { action, runInAction, computed } from 'mobx';
import pick from 'lodash.pick';
import request from 'axios';
import moment from 'moment';

import form, { ManpowerReportForm } from 'forms/manpowerReport';

import {
  MANPOWER_REPORT_DOWNLOADED,
  MANPOWER_REPORT_STARTED
} from 'utils/segmentAnalytics/eventSpec';

import { callTrack } from 'utils/segmentIntegration';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';

export default class ManpowerReportsUI extends UIStore {
  @action.bound
  async showManpowerReportModal() {
    await this.authorization.checkFeatureAccess('DownloadManpowerReport', true);

    this.showModal('manpowerReport');
    callTrack(MANPOWER_REPORT_STARTED);
    this.initForm();
  }

  @action.bound hideManpowerReportModal() {
    this.hideActiveModal().then(() => {
      runInAction(() => {
        this.activeForm = null;
        this.saving = false;
      });
    });
  }

  initForm() {
    this.activeForm = new ManpowerReportForm(
      {
        fields: form.fields,
        rules: form.rules,
        values: form.values,
        labels: form.labels
      },
      {
        options: form.options,
        plugins: form.plugins
      }
    );
  }

  @computed get timeFrames() {
    return [
      {
        id: 'PROJECTENTIRE',
        title: t('Entire Duration of the Project')
      },
      {
        id: 'SPECTIMEFRAME',
        title: t('Specific Time frame')
      }
    ];
  }

  @action.bound setTimeFrame(value) {
    this.activeForm.$('type').set(value.id);
  }

  @computed get selectedTimeFrameOption() {
    return (
      this.timeFrames.find(
        timeFrame => timeFrame.id === this.activeForm.$('type').value
      ) || ''
    );
  }

  @action.bound setFromDate(value) {
    this.activeForm.$('fromDate').set(moment(value).format('YYYY-MM-DD'));
  }

  @action.bound setToDate(value) {
    this.activeForm.$('toDate').set(moment(value).format('YYYY-MM-DD'));
  }

  @computed
  get enableSubmit() {
    if (!this.activeForm || this.activeForm.isPristine || this.saving) {
      return false;
    }

    return this.activeForm.isValid;
  }

  @action.bound
  submitManpowerReportForm(event) {
    event.preventDefault();

    if (this.saving) return;

    this.activeForm.submit({
      onSuccess: this.submitManpowerReportFormSuccess,
      onError: this.submitManpowerReportFormError
    });
  }

  @action.bound async submitManpowerReportFormSuccess() {
    const formValues = this.activeForm.values();
    const values = this.convertValuesToServerFormat(formValues);

    this.saving = true;

    const url = `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/projects/${this.project.uuid}/reports/manpower`;

    try {
      await request.post(url, values);

      callTrack(MANPOWER_REPORT_DOWNLOADED);

      this.hideActiveModal().then(() => {
        runInAction(() => {
          this.rootStore.notificationsUI.pushNotification({
            snackbar: 'warning',
            icon: 'notification',
            title: t('Manpower report sent')
          });

          this.saving = false;
        });
      });
    } catch (error) {
      this.saving = false;
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound submitManpowerReportFormError() {
    console.error(this.activeForm.errors());
  }

  convertValuesToServerFormat(values) {
    if (values.type === 'PROJECTENTIRE') {
      const fromDateMoment = moment(this.project.startDate);

      let fromDate = fromDateMoment.format('YYYY-MM-DD');

      const toDate = this.project.endDate
        ? this.project.endDate
        : moment(new Date()).format('YYYY-MM-DD');

      values.fromDate = fromDate;
      values.toDate = toDate;
    }

    const valuesFields = [
      'recipients',
      'includeCollaborators',
      'fromDate',
      'toDate'
    ];

    return pick(values, valuesFields);
  }
}
