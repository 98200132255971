import { RakenCollection } from '../lib';
import Unit from '../models/Unit';

export default class Units extends RakenCollection {
  model() {
    return Unit;
  }

  url() {
    return `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/materials/units`;
  }
}
