import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import { t } from 'utils/translate';

const paymentMethodFormPlugins = extendedPlugins;

const paymentMethodFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const paymentMethodFormFields = ['addressZip'];

const paymentMethodFormRules = {
  addressZip: 'numeric'
};

const paymentMethodFormEditRules = {
  addressZip: 'numeric'
};

const paymentMethodFormLabels = {
  addressZip: t('Billing zip')
};

const paymentMethodFormValues = {};

class PaymentMethodForm extends MobxReactForm {}

export {
  PaymentMethodForm,
  paymentMethodFormOptions,
  paymentMethodFormFields,
  paymentMethodFormRules,
  paymentMethodFormEditRules,
  paymentMethodFormLabels,
  paymentMethodFormValues,
  paymentMethodFormPlugins
};
