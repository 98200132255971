import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import { t } from 'utils/translate';

const timesheetsTransferFormPlugins = extendedPlugins;

const timesheetsTransferFormOptions = {
  validateOnInit: false,
  strictUpdate: false,
  validateOnChange: true
};

const timesheetsTransferFormFields = ['project', 'segment'];

const timesheetsTransferFormRules = {
  project: 'required',
  segment: ''
};

const timesheetsTransferFormLabels = {
  project: t('move to project'),
  segment: t('Segment')
};

class TimesheetsTransferForm extends MobxReactForm {}

export {
  TimesheetsTransferForm,
  timesheetsTransferFormOptions,
  timesheetsTransferFormFields,
  timesheetsTransferFormRules,
  timesheetsTransferFormLabels,
  timesheetsTransferFormPlugins
};
