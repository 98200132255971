import debounce from 'lodash.debounce';
import request from 'axios';
import { action, computed, observable } from 'mobx';
import ProjectChildAddUI from './ProjectChildAddUI';
import StandardWorkLog from '../../models/StandardWorkLog';

import {
  WorkLogStandardForm,
  workLogStandardFormOptions,
  workLogStandardFormFields,
  workLogStandardFormRules,
  workLogStandardFormLabels,
  workLogStandardFormPlugins
} from 'forms/workLogStandard';

import alertErrorHandler from 'utils/alertErrorHandler';

import { t } from 'utils/translate';
import { BASE_DEBOUNCE } from 'fixtures/constants';

import { callTrack } from 'utils/segmentIntegration';
import { WORKLOG_ADD } from 'utils/segmentAnalytics/eventSpec';

export default class WorkLogAddUStandardUI extends ProjectChildAddUI {
  constructor(options) {
    super(options);

    // Subcontractors
    this.subcontractors = observable([]);

    this.fetchSubcontractorsDebounced = debounce(
      this.fetchSubcontractors,
      BASE_DEBOUNCE
    );
  }

  @action.bound setup() {
    this.entryForAdd = new StandardWorkLog(
      { type: 'StandardWorklog' },
      {
        rootStore: this.rootStore
      }
    );

    this.entryAddForm = new WorkLogStandardForm(
      {
        fields: workLogStandardFormFields,
        rules: workLogStandardFormRules,
        labels: workLogStandardFormLabels
      },
      {
        options: workLogStandardFormOptions,
        plugins: workLogStandardFormPlugins
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @action.bound async submitEntryAddFormSuccess() {
    this.saving = true;

    try {
      const attachments = this.entryForAdd.attachments.models.map(
        attachment => {
          return {
            uuid: attachment.uuid
          };
        }
      );

      await this.entryForAdd.save(
        Object.assign(this.entryAddForm.values(), this.reportParams, {
          attachments: attachments
        }),
        {
          wait: true,
          stripNonRest: false
        }
      );

      callTrack(WORKLOG_ADD, {
        worklog_name: this.entryForAdd.subcontractor,
        worklogType: this.entryForAdd.type,
        has_attachments: attachments.length > 0
      });

      this.unblockHistory();
      this.parent.parent.sortByLastCreated();
      this.parent.cancelWorkLogAdd();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Work log created')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @computed get title() {
    return this.parent.title;
  }

  @computed get backButtonText() {
    return this.parent.backButtonText;
  }

  @action.bound
  async fetchSubcontractors(query = '') {
    this.subcontractors.clear();

    if (query.length < 2) {
      return;
    }

    try {
      const response = await request.get(
        `${this.rootStore.urlMicroService(
          'performanceTracking'
        )}/worklogs/subcontractors`,
        {
          cancelToken: new request.CancelToken(cancelMethod => {
            this.cancelMethod = cancelMethod;
          }),
          params: {
            query: query
          }
        }
      );

      if (response.data.collection) {
        this.subcontractors.replace(response.data.collection);
      }
    } catch (error) {
      console.warn(error);
    }
  }

  @action.bound clearSubcontractors() {
    this.fetchSubcontractorsDebounced.cancel();

    if (this.cancelMethod) {
      this.cancelMethod('Fetch cancelled on blur');
      this.cancelMethod = null;
    }

    this.subcontractors.clear();
  }

  @computed
  get subContractorOptions() {
    return this.subcontractors.slice();
  }

  @computed
  get subContractorOptionsOpen() {
    return this.subContractorOptions.length > 0;
  }
}
