import request from 'axios';
import moment from 'moment';
import { action, runInAction, observable, computed } from 'mobx';
import UIStore from './UIStore';
import pickBy from 'lodash.pickby';

import { t } from 'utils/translate';

import {
  EQUIPMENT_REPORT_DOWNLOADED,
  EQUIPMENT_REPORT_SCHEDULED,
  EQUIPMENT_REPORT_STARTED
} from 'utils/segmentAnalytics/eventSpec';

import { callTrack } from 'utils/segmentIntegration';
import errorHandler from 'utils/errorHandler';
import alertErrorHandler from 'utils/alertErrorHandler';

import EquipmentReport from '../models/EquipmentReport';

import {
  EquipmentReportForm,
  equipmentReportFormOptions,
  equipmentReportFormFields,
  equipmentReportFormRules,
  equipmentReportFormLabels,
  equipmentReportFormValues,
  equipmentReportFormPlugins
} from 'forms/equipmentReport';

export default class EquipmentReportsUI extends UIStore {
  @observable activeReportTab;
  @observable scheduledReportToEdit;

  constructor(options) {
    super(options);

    this.activeReportTab = 'runOnce';
    this.scheduledReportToEdit = null;
  }

  @action.bound showEquipmentReportModal() {
    this.rootStore.authorizationUI
      .checkFeatureAccess('ScheduleTimeCardReports', true)
      .then(() => {
        this.showModal('equipmentReport');
        callTrack(EQUIPMENT_REPORT_STARTED);
        this.fetchScheduled();
        this.setupModalForm();
      });
  }

  @action.bound
  fetchScheduled() {
    return this.equipmentReports.fetch();
  }

  @computed
  get projectDefaultValue() {
    if (this.scheduledReportToEdit?.projects.length) {
      return this.scheduledReportToEdit.projects.map(project => {
        return {
          value: project.uuid,
          name: project.name
        };
      });
    }

    if (this.project) {
      return [
        {
          value: this.project.uuid,
          name: this.project.name,
          startDate: this.project.startDate,
          endDate: this.project.endDate
        }
      ];
    }

    return [];
  }

  @action.bound
  setupModalForm() {
    this.initReportForm();
    if (this.project) {
      this.setProjectIncludeOption('SELECTED');
      this.selectProjects(this.projectDefaultValue);
    } else {
      this.setProjectIncludeOption('ACTIVE');
    }
  }

  @action.bound
  initReportForm() {
    const rules =
      this.activeReportTab === 'scheduled'
        ? Object.assign({}, equipmentReportFormRules, {
            name: 'required|string|max:255',
            time: 'required'
          })
        : equipmentReportFormRules;

    const values =
      this.activeReportTab === 'scheduled' && this.scheduledReportToEdit
        ? Object.assign(
            {},
            equipmentReportFormValues,
            this.scheduledReportToEdit.formValues
          )
        : equipmentReportFormValues;

    this.activeForm = new EquipmentReportForm(
      {
        fields: equipmentReportFormFields,
        rules: rules,
        values: values,
        labels: equipmentReportFormLabels
      },
      {
        options: equipmentReportFormOptions,
        plugins: equipmentReportFormPlugins,
        reportType: this.activeReportTab
      }
    );
  }

  @action.bound
  hideEquipmentReportModal() {
    this.hideActiveModal().then(() => {
      runInAction(() => {
        this.activeForm = null;
        this.activeReportTab = 'runOnce';
        this.scheduledReportToEdit = null;
        this.saving = false;
      });
    });
  }

  @action.bound
  switchTab(e, tab) {
    this.activeForm = null;
    this.scheduledReportToEdit = null;
    this.activeReportTab = tab;
    this.setupModalForm();
  }

  @computed
  get scheduledReportNewOrSaveButtonText() {
    if (this.saving) return t('Saving...');

    return this.scheduledReportToEdit
      ? this.scheduledReportToEdit?.isNew
        ? t('Schedule report')
        : t('Save changes')
      : t('New report');
  }

  @computed get equipmentReports() {
    return this.rootStore.equipmentReports;
  }

  @computed
  get hasNoScheduledReports() {
    return (
      !this.fetchingScheduledReports &&
      !this.equipmentReports.fetching &&
      !this.equipmentReports.hasModels
    );
  }

  @action.bound
  selectProjects(selectedOptions) {
    this.activeForm.update({
      projects: selectedOptions.map(option => {
        return {
          uuid: option.value,
          name: option.name
        };
      })
    });

    this.activeForm.validate();
  }

  @computed
  get projectIncludeOptions() {
    const options = [
      {
        label: t('Active projects'),
        value: 'ACTIVE',
        dataQA: 'active-projects'
      },
      {
        label: t('Active and inactive projects'),
        value: 'ACTIVE_AND_INACTIVE',
        dataQA: 'active-and-inactive-projects'
      },
      {
        label: t('Selected'),
        value: 'SELECTED',
        dataQA: 'selected-projects'
      }
    ];

    if (this.activeReportTab === 'scheduled') {
      return options.filter(option => {
        return option.value !== 'ACTIVE_AND_INACTIVE';
      });
    }

    return options;
  }

  @action.bound
  setProjectIncludeOption(value) {
    if (value === 'ACTIVE' || value === 'ACTIVE_AND_INACTIVE') {
      this.activeForm.update({
        projects: []
      });
    }

    this.activeForm.$('projectIncludeOption').set('value', value);

    this.activeForm.validate();
  }

  @computed get numberOfSelectedProjects() {
    return this.activeForm?.$('projects').values().length;
  }

  @computed
  get weekFirstDay() {
    return moment(new Date()).add(
      (this.company.preferences.weekFirstDay -
        moment(new Date()).isoWeekday() -
        7) %
        7,
      'days'
    );
  }

  @computed
  get weekLastDay() {
    return moment(this.weekFirstDay).add(6, 'days');
  }

  @computed
  get monthFirstDay() {
    return moment().startOf('month');
  }

  @computed get timeFrames() {
    if (this.activeReportTab === 'runOnce') {
      if (
        this.activeForm.$('projectIncludeOption').value === 'SELECTED' &&
        this.numberOfSelectedProjects <= 1
      ) {
        return [
          {
            id: 'PROJECTENTIRE',
            title: t('Entire Duration of the Project'),
            projectEntire: true
          },
          {
            id: 'SPECTIMEFRAME',
            title: t('Specific Time frame'),
            projectEntire: false
          }
        ];
      } else {
        return [
          {
            id: 'SPECTIMEFRAME',
            title: t('Specific Time frame'),
            projectEntire: false
          }
        ];
      }
    } else {
      return [
        {
          id: 'DAILY',
          title: t('Daily'),
          projectEntire: true
        },
        {
          id: 'WEEKLY',
          title: t('Weekly'),
          actionDate: this.weekLastDay,
          projectEntire: true
        },
        {
          id: 'BI_WEEKLY',
          title: t('Bi-weekly'),
          actionDate: this.weekLastDay,
          projectEntire: true
        },
        {
          id: 'MONTHLY',
          title: t('Monthly'),
          actionDate: this.monthFirstDay,
          projectEntire: true
        }
      ];
    }
  }

  @computed get fixedTimeFrameOption() {
    return {
      id: 'PROJECTENTIRE',
      title: t('Entire Duration of the Project'),
      projectEntire: true
    };
  }

  @computed get selectedTimeFrameOption() {
    return this.timeFrames.find(
      timeFrame => timeFrame.id === this.activeForm.$('timeFrameType').value
    );
  }

  @action.bound
  setTimeFrame(timeFrame) {
    const actionDate = timeFrame.actionDate
      ? moment(timeFrame.actionDate).format('YYYY-MM-DD')
      : '';

    this.activeForm.update({
      timeFrameType: timeFrame.id,
      projectEntire: timeFrame.projectEntire,
      actionDate: actionDate
    });
  }

  @action.bound setFromDate(value) {
    this.activeForm.$('fromDate').set(moment(value).format('YYYY-MM-DD'));
  }

  @action.bound setToDate(value) {
    this.activeForm.$('toDate').set(moment(value).format('YYYY-MM-DD'));
  }

  @action.bound setActionDate(value) {
    this.activeForm.$('actionDate').set(moment(value).format('YYYY-MM-DD'));
  }

  @action.bound
  submitEquipmentReportForm(event) {
    event.preventDefault();

    if (this.activeReportTab === 'scheduled' && !this.scheduledReportToEdit) {
      this.createNewScheduledReport();
      return;
    }

    if (this.saving) return;

    this.clearValidationDetails();

    this.activeForm.submit({
      onSuccess: this.submitEquipmentReportFormSuccess,
      onError: this.submitEquipmentReportFormError
    });
  }

  @action.bound submitEquipmentReportFormSuccess() {
    if (this.activeReportTab === 'runOnce') {
      this.submitEquipmentRunOnceReport();
    } else {
      this.submitEquipmentScheduledReport();
    }
  }

  @action.bound submitEquipmentReportFormError() {
    console.error(this.activeForm.errors());
  }

  @action.bound async submitEquipmentRunOnceReport() {
    this.saving = true;

    const values = this.activeForm.values();
    const project = values.projects[0];

    const cleanedValues = pickBy(
      values,
      value => value !== null && (value !== undefined) & (value !== '')
    );

    cleanedValues.projects = cleanedValues.projects.map(project => {
      return {
        uuid: project.uuid,
        name: project.name
      };
    });

    // If there is a single project populate start and end times
    if (project && values.timeFrameType === 'PROJECTENTIRE') {
      cleanedValues.fromDate = project.startDate;

      if (moment(project.endDate).isBefore()) {
        cleanedValues.toDate = project.endDate;
      } else {
        cleanedValues.toDate = moment().format('YYYY-MM-DD');
      }
    }

    try {
      await request.post(
        `${this.rootStore.urlMicroService(
          'performanceTracking'
        )}/equipment/reports/run-once`,
        cleanedValues
      );

      callTrack(EQUIPMENT_REPORT_DOWNLOADED);

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Your download request has been sent.')
      });

      this.hideEquipmentReportModal();
    } catch (error) {
      this.saving = false;
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound async submitEquipmentScheduledReport() {
    this.saving = true;

    const values = this.activeForm.values();
    const hoursMins = values.time.split(':');

    values.time = {
      hour: parseInt(hoursMins[0], 10),
      minute: parseInt(hoursMins[1], 10)
    };

    values.projects = values.projects.map(project => {
      return {
        uuid: project.uuid,
        name: project.name
      };
    });

    const cleanedValues = pickBy(
      values,
      value => value !== null && (value !== undefined) & (value !== '')
    );

    const method = this.scheduledReportToEdit.isNew ? 'post' : 'put';

    const notificationMessage = this.scheduledReportToEdit.isNew
      ? t('Scheduled report created.')
      : t('Scheduled report updated.');

    try {
      await this.scheduledReportToEdit.save(cleanedValues, {
        method,
        wait: true
      });

      callTrack(EQUIPMENT_REPORT_SCHEDULED, {
        frequency: values.timeFrameType
      });

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: notificationMessage
      });

      this.fetchScheduled();
      this.cancelEditingReport();
    } catch (error) {
      this.saving = false;
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound
  createNewScheduledReport() {
    // Use a new model to hold our report when creating a new one.
    this.scheduledReportToEdit = new EquipmentReport(
      {},
      {
        rootStore: this.rootStore
      }
    );

    this.setupModalForm();
  }

  @action.bound
  cancelEditingReport() {
    this.activeForm = null;
    this.scheduledReportToEdit = null;
    this.saving = false;
  }

  @computed get fromDateLabel() {
    if (this.activeReportTab === 'runOnce') return null;

    switch (this.activeForm.$('timeFrameType').value) {
      case 'WEEKLY':
        return t('Start of week');
      case 'BI_WEEKLY':
        return t('Start of bi-weekly period');
      default:
        return t('From date');
    }
  }

  @computed get toDateLabel() {
    if (this.activeReportTab === 'runOnce') return null;

    switch (this.activeForm.$('timeFrameType').value) {
      case 'WEEKLY':
        return t('End of week');
      case 'BI_WEEKLY':
        return t('End of bi-weekly period');
      default:
        return t('From date');
    }
  }

  @computed get scheduleText() {
    switch (this.activeForm.$('timeFrameType').value) {
      case 'MONTHLY':
        return t('This report will be sent monthly starting:');
      case 'WEEKLY':
        return t('This report will be sent weekly starting:');
      case 'BI_WEEKLY':
        return t('This report will be sent bi-weekly starting:');
      default:
        return t('This report will be sent daily at:');
    }
  }

  @computed
  get enableSubmit() {
    if (!this.scheduledReportToEdit && this.activeReportTab === 'scheduled') {
      return true;
    }

    if (!this.activeForm || this.activeForm.isPristine || this.saving) {
      return false;
    }

    return this.activeForm.isValid;
  }

  @action.bound
  editScheduledReport(report) {
    this.scheduledReportToEdit = report;

    this.initReportForm();
  }

  @action.bound
  async deleteScheduledReport(report) {
    try {
      await report.destroy({ wait: true });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  @action.bound
  handleScheduledReportCancelButton() {
    this.scheduledReportToEdit
      ? this.cancelEditingReport()
      : this.hideEquipmentReportModal();
  }

  @computed get restrictSpecificTimeframe() {
    return (
      this.numberOfSelectedProjects > 1 &&
      this.activeForm.$('timeFrameType').value === 'SPECTIMEFRAME'
    );
  }

  @computed
  get maximumToDate() {
    const fromDate = this.activeForm.$('fromDate').value;

    if (fromDate && this.numberOfSelectedProjects > 1) {
      return moment(fromDate, 'YYYY-MM-DD').add(1, 'year');
    }

    return undefined;
  }
}
