import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Text, Box, IconBadge } from '@raken/athens-web/lib/';
import { Observer } from 'mobx-react';
import copy from 'copy-to-clipboard';
import media from 'utils/mediaTemplate';

const ImpersonateWrapper = styled.div`
  background-color: transparent;
  box-shadow: inset 0px 0px 0px 4px rgba(255, 41, 82, 0.8);
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
`;

const PersonDetail = styled.div`
  padding: 0 16px;
  height: 35px;
  background-color: rgba(255, 41, 82, 0.8);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  z-index: 99999;
  position: fixed;
  bottom: 4px;
  opacity: ${({ show }) => (show ? '1' : '0')};
  ${media.desktop`
  left: 50%;
  transform: translateX(-50%);
  `};
  ${media.mobileOnly`
  width: 100%;
  `};
`;

export default ({ store }) => {
  return (
    <Observer>
      {() => (
        <Fragment>
          <ImpersonateWrapper />
          <PersonDetail show={Boolean(store.me.email)}>
            <Text
              rakenColor="white"
              dataQA={store.me.email?.split('@')[0]}
            >{`Viewing ${store.me.email}`}</Text>
            <Box
              pt={1}
              ml={4}
              display="flex"
              width="60px"
              justifyContent="space-between"
            >
              <IconBadge
                kind="info"
                rakenColor="white"
                iconColor="red"
                button
                onClick={() => {
                  copy(
                    `Company ID: ${store.me.company.id} | Company UUID: ${store.me.company.uuid}`
                  );
                }}
                dataQA="company-info"
              />
              <IconBadge
                kind="close"
                rakenColor="white"
                iconColor="red"
                button
                onClick={store.stopImpersonating}
                dataQA="stop-viewing"
              />
            </Box>
          </PersonDetail>
        </Fragment>
      )}
    </Observer>
  );
};
