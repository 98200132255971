import { Model } from 'mobx-mc';

export default class SuperAdminDemoAccount extends Model {
  get restAttributes() {
    return ['type', 'name', 'uuid'];
  }

  idAttribute() {
    return 'uuid';
  }
}
