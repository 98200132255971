import { Model } from 'mobx-mc';
import omit from 'lodash.omit';
import WorkerPhotoIdSummaries from '../collections/WorkerPhotoIdSummaries';

export default class WorkerPhotoIdSummaryActivity extends Model {
  get restAttributes() {
    return [
      'dateRange',
      'dateRangePrev',
      'dateRangeNext',
      'page',
      'periodType'
    ];
  }

  get urlRoot() {
    return `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/photo-ids/summaries`;
  }

  parse(attributes) {
    this.parseWorkerPhotoIdSummaries(attributes);

    return {
      ...omit(attributes, [
        'workerPhotoIdSummaries' // Make sure we don't pass this through to the restAttributes
      ])
    };
  }

  parseWorkerPhotoIdSummaries(attributes) {
    if (this.workerPhotoIdSummaries) {
      // If we already have an instance, no need to create a new one.
      this.workerPhotoIdSummaries.reset(attributes.workerPhotoIdSummaries);
    } else {
      this.workerPhotoIdSummaries = new WorkerPhotoIdSummaries(
        attributes.workerPhotoIdSummaries,
        {
          rootStore: this.rootStore,
          parent: this
        }
      );
    }
  }
}
