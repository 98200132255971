import { Model } from 'mobx-mc';
import { computed, action } from 'mobx';

export default class ProjectMinimal extends Model {
  get urlRoot() {
    if (!this.rootStore.isSuperAdmin) {
      return `/ra/companies/${this.rootStore.me.company.uuid}/projects`;
    }

    return '';
  }

  idAttribute() {
    return 'uuid';
  }

  get restAttributes() {
    return [
      'uuid',
      'parentUuid',
      'name',
      'projectState',
      'startDate',
      'endDate',
      'address',
      'projectNo',
      'teamId',
      'segmentSets',
      'disabled',
      'externalId',
      'projectType'
    ];
  }

  @action.bound parse(attributes) {
    // Super Admin returns a projectId field
    if (attributes.projectId) {
      attributes.teamId = attributes.projectId;
    }

    return attributes;
  }

  @computed
  get isActive() {
    return this.projectState === 'ACTIVE';
  }

  @computed
  get isInactive() {
    return this.projectState === 'INACTIVE';
  }

  @computed
  get isDeleted() {
    return this.projectState === 'DELETED';
  }

  @computed
  get optionIdValue() {
    return this.uuid;
  }

  @computed
  get viewUrl() {
    return `/projects/${this.uuid}`;
  }

  @computed get isChildProject() {
    return this.projectType === 'CHILD';
  }

  @computed get segments() {
    let segments = [];

    this.segmentSets?.forEach(segmentSet => {
      segments = segments.concat(...segmentSet.segments);
    });

    return segments;
  }

  @computed get hasSegments() {
    return this.segments.length > 0;
  }

  @computed get identifiers() {
    return {
      id: this.id,
      teamId: this.teamId,
      uuid: this.uuid,
      parentUui: this.parentUuid
    };
  }
}
