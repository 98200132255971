import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import { t } from 'utils/translate';

const projectTeamFormPlugins = extendedPlugins;

const projectTeamFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const projectTeamFormFields = ['startDate', 'endDate', 'projectState'];

const projectTeamFormRules = {
  startDate: 'dateCustom:YYYY-MM-DD',
  endDate: 'dateCustom:YYYY-MM-DD|afterCustom:startDate,YYYY-MM-DD'
};

const projectTeamFormLabels = {
  startDate: t('Start date'),
  endDate: t('End date'),
  projectState: t('Project state')
};

class ProjectTeamForm extends MobxReactForm {}

export {
  ProjectTeamForm,
  projectTeamFormOptions,
  projectTeamFormFields,
  projectTeamFormRules,
  projectTeamFormLabels,
  projectTeamFormPlugins
};
