import kebabCase from 'lodash.kebabcase';
import omit from 'lodash.omit';
import orderBy from 'lodash.orderby';
import { computed } from 'mobx';
import { Model } from 'mobx-mc';
import moment from 'moment';
import Attachments from 'stores/collections/Attachments';
import formatTimestamp from 'utils/formatTimestamp';

export default class Incident extends Model {
  get restAttributes() {
    return [
      'uuid',
      'activityPriorToIncident',
      'affectOnBody',
      'answeredQuestionCount',
      'attachments',
      'caseNo',
      'companyUuid',
      'completedByName',
      'createdBy',
      'createdTimestamp',
      'dateOfDeath',
      'daysAwayFromWork',
      'daysOnJobTransferOrRestriction',
      'descriptionOfInjuryOrIllness',
      'employeeDateOfBirth',
      'employeeDateOfHire',
      'employeeGender',
      'employeeJobTitle',
      'employeeName',
      'incidentDate',
      'incidentLocation',
      'incidentOutcome',
      'incidentTime',
      'incidentTitle',
      'injuryOrIllnessType',
      'isHospitalized',
      'isRecordable',
      'isTreatedInTheEmergencyRoom',
      'notifications',
      'projectUuid',
      'projectName',
      'recountOfIncident',
      'sourceOfHarm',
      'status',
      'totalQuestionCount',
      'updatedBy',
      'updatedTimestamp',
      'year'
    ];
  }

  idAttribute() {
    return 'uuid';
  }

  get urlRoot() {
    return `${this.rootStore.urlMicroService('toolbox')}/companies/${
      this.rootStore.me.company.uuid
    }/incident-reports`;
  }

  parse(attributes) {
    this.parseAttachments(attributes);

    return {
      ...omit(attributes, ['attachments'])
    };
  }

  parseAttachments(attributes) {
    if (this.attachments) {
      this.attachments.reset(attributes.attachments);
    } else {
      this.attachments = new Attachments(attributes.attachments, {
        rootStore: this.rootStore,
        parent: this
      });
    }
  }

  @computed
  get slug() {
    return kebabCase(this.incidentTitle);
  }

  @computed
  get hasAttachments() {
    return this.attachments && this.attachments.length > 0;
  }

  @computed
  get sortedAttachments() {
    return orderBy(this.attachments.models, ['createdTimestamp'], ['desc']);
  }

  @computed
  get attachmentsCount() {
    return this.attachments?.length;
  }

  @computed
  get createdAtFormatted() {
    return formatTimestamp(this.createdTimestamp);
  }

  @computed
  get updatedAtFormatted() {
    return formatTimestamp(this.updatedTimestamp);
  }

  @computed get cannotEditAttachment() {
    return !this.rootStore.authorizationUI.canCUDIncidents;
  }

  @computed
  get incidentDateFormatted() {
    if (this.incidentDate) {
      if (this.incidentTime) {
        return moment(
          this.incidentDate + ' ' + this.incidentTime,
          'YYYY-MM-DD HH:mm'
        ).format('MMM D, YYYY h:mm A');
      }

      return moment(this.incidentDate, 'YYYY-MM-DD').format('MMM D, YYYY');
    }

    return '-';
  }

  @computed
  get formValues() {
    return {
      activityPriorToIncident: this.activityPriorToIncident,
      affectOnBody: this.affectOnBody,
      attachments: this.attachments,
      dateOfDeath: this.dateOfDeath,
      daysAwayFromWork: this.daysAwayFromWork,
      daysOnJobTransferOrRestriction: this.daysOnJobTransferOrRestriction,
      descriptionOfInjuryOrIllness: this.descriptionOfInjuryOrIllness,
      employeeDateOfBirth: this.employeeDateOfBirth,
      employeeDateOfHire: this.employeeDateOfHire,
      employeeGender: this.employeeGender,
      employeeJobTitle: this.employeeJobTitle,
      employeeName: this.employeeName,
      incidentDate: this.incidentDate,
      incidentLocation: this.incidentLocation,
      incidentOutcome: this.incidentOutcome,
      incidentTime: this.incidentTime,
      incidentTitle: this.incidentTitle,
      injuryOrIllnessType: this.injuryOrIllnessType,
      isHospitalized: this.isHospitalized,
      isRecordable: this.isRecordable,
      isTreatedInTheEmergencyRoom: this.isTreatedInTheEmergencyRoom,
      notifications: this.notifications.map(notification => {
        return {
          uuid: notification.uuid,
          name: notification.fullName,
          email: notification.email,
          phoneNumber: notification.phoneNumber
        };
      }),
      recountOfIncident: this.recountOfIncident,
      sourceOfHarm: this.sourceOfHarm,
      status: this.status
    };
  }
}
