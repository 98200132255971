import request from 'axios';
import { action } from 'mobx';
import { RakenCollection } from '../../lib';

import SuperAdminAuditEntry from '../../models/superAdmin/SuperAdminAuditEntry';

export default class SuperAdminAuditEntries extends RakenCollection {
  model() {
    return SuperAdminAuditEntry;
  }

  url() {
    return `${this.rootStore.urlMicroService('integrations')}/audit/search`;
  }

  @action.bound async search(body) {
    const response = await request.post(this.url(), body);

    this.set(
      {
        page: {
          totalElements: response.data.hits.total.value,
          limit: response.data.took
        },
        collection: response.data.hits?.hits || []
      },
      {
        add: true,
        remove: true,
        update: true
      }
    );
  }
}
