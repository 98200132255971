import request from 'axios';
import { action, computed, observable, when } from 'mobx';
import ProjectChildEditUI from '../ProjectChildEditUI';
import Form from 'stores/models/forms/Form';

import history from 'utils/history';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';

import { FORM_VIEWED } from 'utils/segmentAnalytics/eventSpec';
import { callTrack } from 'utils/segmentIntegration';

export default class FormEditUI extends ProjectChildEditUI {
  @observable webViewerInstance;
  @observable loading;
  @observable documentIsLoaded;
  @observable documentIsDirty;

  constructor(options) {
    super(options);
    this.webViewerInstance = null;
    this.loading = true;
    this.documentIsLoaded = false;
    this.documentIsDirty = false;
  }

  @computed get formActionsUI() {
    return this.parent.formActionsUI;
  }

  @action.bound async fetchEntry(uuid) {
    if (this.entryForEdit) return;

    let model = this.parent.forms.getByIdOrUuid(uuid);

    if (!model) {
      model = new Form(
        {
          type: 'Form',
          uuid: uuid
        },
        {
          rootStore: this.rootStore
        }
      );
    }

    try {
      await model.fetch();

      this.setEntryForEdit(model);
    } catch (error) {
      this.cancelFormEdit();
    }
  }

  @action.bound async setEntryForEdit(model) {
    this.entryForEdit = model;

    if (!this.entryForEdit.fromTemplate) {
      callTrack(FORM_VIEWED, {
        project_name: this.project.name,
        form_name: this.entryForEdit.name
      });
    }

    await when(() => this.webViewerInstance);

    try {
      const { UI, Core } = this.webViewerInstance;
      const { annotationManager, documentViewer } = Core;
      const { name, document } = this.entryForEdit;

      annotationManager.addEventListener(
        'annotationChanged',
        (annotations, action, { imported }) => {
          // imported indicates if the annotations were imported via a process, mainly XFDF
          if (!imported) {
            this.setDocumentIsDirty();
          }

          documentViewer.addEventListener('pagesUpdated', properties => {
            this.setDocumentIsDirty();
          });
        }
      );

      annotationManager.addEventListener('fieldChanged', () => {
        this.setDocumentIsDirty();
      });

      documentViewer.addEventListener('documentLoaded', () => {
        this.setDocumentIsLoaded();

        // Continuous layout for long docs
        UI.setLayoutMode(UI.LayoutMode.Continuous);

        documentViewer
          .getDocument()
          .addEventListener('officeDocumentEdited', properties => {
            this.setDocumentIsDirty();
          });
      });

      this.loading = false;

      UI.loadDocument(
        `${document.contentUrl}?version=${document.updatedTimestamp ||
          document.createdTimestamp}`,
        {
          filename: name,
          enableOfficeEditing: false
        }
      );

      this.blockHistoryIfFormChanged();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound resetEntryForEdit() {
    try {
      const { UI } = this.webViewerInstance;

      this.clearDocumentIsLoaded();
      this.clearDocumentIsDirty();

      UI.loadDocument(this.entryForEdit.document.contentUrl);
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound blockHistoryIfFormChanged() {
    this.unblock = history.block((location, action) => {
      if (this.documentIsDirty || this.entryForEdit.fromTemplate) {
        this.showDiscardModal(location.pathname);
        return 'Blocked';
      }
    });
  }

  @action.bound
  async discardChanges() {
    if (this.entryForEdit.fromTemplate) {
      await this.entryForEdit.destroy({
        wait: true
      });

      this.parent.refetchForms();
    }

    await this.hideActiveModal();

    this.moveToNextUrl();
  }

  @action.bound async submitEntryEditForm(event) {
    event.preventDefault();

    this.saving = true;

    try {
      const { documentViewer, annotationManager } = await this.webViewerInstance
        .Core;

      const xfdfString = await annotationManager.exportAnnotations();

      const arrayBuffer = await documentViewer.getDocument().getFileData({
        flags: this.webViewerInstance.Core.SaveOptions.LINEARIZED,
        xfdfString: xfdfString
      });

      const formData = new FormData();
      formData.append('file', new Blob([new Uint8Array(arrayBuffer)]));

      const file = formData.get('file');

      const signedUrlResponse = await request.post(
        `${this.entryForEdit.url()}/request-replace-url`,
        {
          originalFileName: this.entryForEdit.name,
          lastModifiedDateTime: file.lastModifiedDate,
          contentType: this.entryForEdit.document.contentType,
          contentLength: file.size,
          withContentDisposition: true,
          attachmentType: 'FORM'
        }
      );

      if (signedUrlResponse.data) {
        await request.put(signedUrlResponse.data.url, file, {
          transformRequest: [
            (data, headers) => {
              headers.put[
                'Content-Type'
              ] = this.entryForEdit.document.contentType;

              Object.keys(signedUrlResponse.data.customHeaders).forEach(key => {
                headers.put[key] = signedUrlResponse.data.customHeaders[key];
              });

              return data;
            }
          ]
        });

        await request.patch(this.entryForEdit.url(), {
          s3ObjectKey: signedUrlResponse.data.key
        });

        this.unblockHistory();
        this.parent.refetchForms();
        this.cancelFormEdit();

        this.notifications.pushNotification({
          snackbar: 'warning',
          icon: 'checkmark',
          title: t('Form saved')
        });
      }
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound cancelFormEdit() {
    history.push({
      pathname: `${this.project.viewUrl}/forms`,
      search: this.baseQueryParams
    });
  }

  @computed get hasWriteAccess() {
    return this.parent.hasWriteAccess;
  }

  @computed get title() {
    return this.entryForEdit?.name;
  }

  @action.bound setDocumentIsLoaded() {
    this.documentIsLoaded = true;
  }

  @action.bound clearDocumentIsLoaded() {
    this.documentIsLoaded = false;
  }

  @action.bound setDocumentIsDirty() {
    this.documentIsDirty = true;
  }

  @action.bound clearDocumentIsDirty() {
    this.documentIsDirty = false;
  }

  @action.bound setWebViewerInstance(instance) {
    this.webViewerInstance = instance;
  }

  @action.bound clearWebViewerInstance() {
    this.webViewerInstance = null;
    this.documentIsDirty = false;
    this.documentIsLoaded = false;
    this.loading = true;
  }

  @action.bound clearUIState() {
    super.clearUIState();
    this.webViewerInstance = null;
    this.documentIsDirty = false;
    this.documentIsLoaded = false;
    this.loading = true;
  }

  @computed get disableCancelButton() {
    return Boolean(
      this.saving || !this.documentIsLoaded || !this.hasWriteAccess
    );
  }

  @computed get disableSaveButton() {
    return Boolean(
      this.saving || !this.documentIsLoaded || !this.hasWriteAccess
    );
  }
}
