import { RakenCollection } from '../lib';
import Worker from '../models/Worker';
import trimObject from 'utils/trimObject';
import { computed } from 'mobx';

export default class Workers extends RakenCollection {
  model() {
    return Worker;
  }

  url() {
    if (this.rootStore.isSuperAdmin) {
      return `/ra/companies/${this.parent.activeCompany?.uuid}/members`;
    }

    return `/ra/companies/${this.rootStore.me.company?.uuid}/members`;
  }

  @computed
  get trimmedValues() {
    return trimObject(this.models.map(worker => worker.formValues));
  }
}
