import { Model } from 'mobx-mc';
import { computed } from 'mobx';

export default class Unit extends Model {
  get restAttributes() {
    return ['uuid', 'name'];
  }

  idAttribute() {
    return 'uuid';
  }

  @computed get formValues() {
    return {
      uuid: this.uuid,
      name: this.name
    };
  }
}
