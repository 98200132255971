import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { action, observable, computed, reaction } from 'mobx';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import history from 'utils/history';
import MapProjects from 'stores/collections/MapProjects';
import UIStore from './UIStore';
import ProjectMapInfoWindow from 'pages/projects/components/map/projectsMap/ProjectsMapInfoWindow.js';

import WorkersMapUI from './WorkersMapUI';

import { t } from 'utils/translate';
import { callTrack } from 'utils/segmentIntegration';

import {
  PROJECT_MAP_OPENED,
  PROJECT_MAP_PIN_OPENED,
  PROJECT_MAP_SELECT_PROJECT_DROPDOWN,
  PROJECT_MAP_VIEW_PROJECT,
  PROJECT_MAP_VIEW_EVENTS,
  PROJECT_MAP_STATUS_FILTER
} from 'utils/segmentAnalytics/eventSpec';

export default class ProjectsMapUI extends UIStore {
  @observable map;

  @observable mapNELat;
  @observable mapNELong;
  @observable mapSWLat;
  @observable mapSWLong;

  constructor(options) {
    super(options);

    this.google = window.google;
    this.map = null;
    this.mapRef = null;

    this.clusterer = null;

    // MapProjects collection
    this.mapProjects = new MapProjects(null, {
      parent: this,
      rootStore: this.rootStore
    });

    // Workers Map UI Store
    this.workersMapUI = new WorkersMapUI({
      parent: this,
      rootStore: this.rootStore
    });

    // Request Params
    this.projectFilters = observable([]);
    this.statusFilters = observable([this.statusFilterOptions[0]]);

    this.mapNELat = null;
    this.mapNELong = null;
    this.mapSWLat = null;
    this.mapSWLong = null;

    this.resizeMapForFilters = false;
  }

  @action.bound
  async setup(mapRef) {
    this.mapRef = mapRef;

    var styles = [
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }]
      }
    ];

    this.map = new this.google.maps.Map(this.mapRef, {
      zoomControl: true,
      animatedZoom: false,
      mapTypeControl: false,
      fullscreenControl: false,
      clickableIcons: false,
      center: this.defaultLocation,
      zoom: 7,
      zoomControlOptions: {
        position: this.google.maps.ControlPosition.LEFT_BOTTOM
      },
      streetViewControl: true,
      streetViewControlOptions: {
        position: this.google.maps.ControlPosition.LEFT_BOTTOM
      },
      gestureHandling: 'cooperative',
      styles
    });

    this.projectSelectorUI.fetchProjectOptions();

    this.setupReactions();

    callTrack(PROJECT_MAP_OPENED);

    this.google.maps.event.addListenerOnce(this.map, 'bounds_changed', () => {
      //Found a race condition where getBounds was returning Null fixed by waiting for bounds_changed once on first
      this.map.addListener('idle', () => {
        this.setMapCorners(this.map.getBounds());
      });
    });
  }

  @action.bound
  tearDown() {
    this.google.maps.event.clearListeners(this.map, 'bounds_changed');
    this.google.maps.event.clearListeners(this.map, 'idle');
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        this.fetchMapProjects();
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed get params() {
    return {
      mapNELat: this.mapNELat,
      mapNELong: this.mapNELong,
      mapSWLat: this.mapSWLat,
      mapSWLong: this.mapSWLong,
      projects: this.projectFilters.map(project => project.value).join(),
      projectStates: this.statusFilterParams,
      limit: 500
    };
  }

  @computed get defaultLocation() {
    return (
      this.rootStore.userLocation?.geolocation ||
      this.company.address.geolocation
    );
  }

  @computed get markers() {
    return this.clusterer?.markers?.map(marker => marker);
  }

  @computed get markerPositions() {
    return this.markers?.map(marker => {
      return {
        lat: parseFloat(marker.position.lat()),
        lng: parseFloat(marker.position.lng())
      };
    });
  }

  @action.bound
  setMapCorners(bounds) {
    this.mapNELat = bounds.getNorthEast().lat();
    this.mapNELong = bounds.getNorthEast().lng();
    this.mapSWLat = bounds.getSouthWest().lat();
    this.mapSWLong = bounds.getSouthWest().lng();
  }

  @action.bound async fetchMapProjects() {
    if (this.clusterer) {
      this.clearPins();
    } else {
      this.clusterer = new MarkerClusterer({
        map: this.map,
        renderer: {
          render({ count, position }, stats) {
            // Set cluster to always stay as one color and override default changing color.
            const color = '#0000ff';
            // create svg url with fill color
            const svg = window.btoa(`
    <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
      <circle cx="120" cy="120" opacity=".6" r="70" />
      <circle cx="120" cy="120" opacity=".3" r="90" />
      <circle cx="120" cy="120" opacity=".2" r="110" />
    </svg>`);
            // create marker using svg icon
            return new window.google.maps.Marker({
              position,
              icon: {
                url: `data:image/svg+xml;base64,${svg}`,
                scaledSize: new window.google.maps.Size(45, 45)
              },
              label: {
                text: String(count),
                color: 'rgba(255,255,255,0.9)',
                fontSize: '12px'
              },
              title: `Cluster of ${count} markers`,
              // adjust zIndex to be above other markers
              zIndex: Number(window.google.maps.Marker.MAX_ZINDEX) + count
            });
          }
        }
      });
    }

    await this.mapProjects.fetch({
      params: this.resizeMapForFilters
        ? {
            projects: this.params.projects,
            projectStates: this.params.projectStates
          }
        : this.params
    });

    this.dropPins();

    if (this.resizeMapForFilters) {
      this.centralize();
    }

    this.resizeMapForFilters = false;
  }

  clearPins(forceClear) {
    const bounds = this.map.getBounds();

    this.markers.forEach(marker => {
      if (!bounds.contains(marker.position) || forceClear) {
        this.clusterer.removeMarker(marker);
      }
    });
  }

  centralize() {
    const bounds = new this.google.maps.LatLngBounds();
    this.markerPositions.forEach(marker => {
      bounds.extend(new this.google.maps.LatLng(marker.lat, marker.lng));
    });
    this.map.fitBounds(bounds);

    // Make sure we don't zoom in to much
    if (this.map.getZoom() >= 12) {
      this.map.setZoom(12);
    }
  }

  dropPins() {
    this.mapProjects.models.forEach(project => {
      const pin = this.addPin(project);
      project.pin = pin;
    });
  }

  ifPinExists(position) {
    return this.markerPositions?.find(
      marker => marker.lat === position.lat && marker.lng === position.lng
    );
  }

  addPin(project) {
    const position = {
      lat: parseFloat(project.address.geolocation.lat),
      lng: parseFloat(project.address.geolocation.lng)
    };

    if (this.ifPinExists(position)) return project.pin;

    const pin = new this.google.maps.Marker({
      position,
      map: this.map,
      icon: `${this.assetsURL}/svg/project-map-pin.svg`
    });

    this.clusterer.addMarker(pin);

    const idHash = Math.random()
      .toString(36)
      .slice(2, 7);

    const infowindow = new this.google.maps.InfoWindow({
      content: renderToStaticMarkup(
        <ProjectMapInfoWindow
          project={project}
          idHash={idHash}
          showEventsButton={project.kioskEnabled}
        />
      )
    });
    this.google.maps.event.addListener(infowindow, 'domready', () => {
      const viewProject = document.getElementById(`view-project-${idHash}`);
      if (viewProject) {
        viewProject.addEventListener('click', () => {
          callTrack(PROJECT_MAP_VIEW_PROJECT);
          history.push(`/projects/${project.id}/worklogs`);
        });
      }
      const viewEvent = document.getElementById(`view-event-${idHash}`);
      if (viewEvent) {
        viewEvent.addEventListener('click', () => {
          callTrack(PROJECT_MAP_VIEW_EVENTS);
          history.push(`/projects/${project.id}/map`);
        });
      }
    });

    pin.addListener('click', () => {
      callTrack(PROJECT_MAP_PIN_OPENED);
      infowindow.open({
        anchor: pin,
        map: this.map,
        shouldFocus: false
      });
    });

    pin.addListener('mouseover', () => {
      pin.setAnimation(this.google.maps.Animation.BOUNCE);
      setTimeout(() => {
        pin.setAnimation(null);
      }, 750);
    });

    pin.addListener('mouseout', () => {
      pin.setAnimation(null);
    });

    return pin;
  }

  @action.bound
  clearProjectFilters() {
    this.projectFilters.clear();
  }

  @action.bound
  updateProjectFilters(mapProjects) {
    this.projectFilters.replace(mapProjects);
    this.clearPins(true);
    this.resizeMapForFilters = true;
    callTrack(PROJECT_MAP_SELECT_PROJECT_DROPDOWN);
  }

  @action.bound
  clearStatusFilters() {
    this.statusFilters.clear();
  }

  @computed get statusFilterOptions() {
    return [
      { id: 'ACTIVE', title: t('Active') },
      { id: 'INACTIVE', title: t('Inactive') },
      { id: 'DELETED', title: t('Deleted') }
    ];
  }

  @computed get statusFilterParams() {
    if (this.projectFilters.length > 0) {
      // If we have project filters we want to search for all status filters.
      // As specific projects could have any of these filters.
      return this.statusFilterOptions.map(status => status.id).join();
    }

    return this.statusFilters.length > 0
      ? this.statusFilters.map(status => status.id).join()
      : this.statusFilterOptions.map(status => status.id).join();
  }

  @action.bound
  updateStatusFilters(status) {
    this.statusFilters.replace(status);
    this.clearPins(true);
    callTrack(PROJECT_MAP_STATUS_FILTER);
  }

  @action.bound clearUIState() {
    this.mapProjects.reset();

    this.statusFilters.clear();
    this.projectFilters.clear();
    this.map = null;
    this.clusterer = null;

    this.mapNELat = null;
    this.mapNELong = null;
    this.mapSWLat = null;
    this.mapSWLong = null;

    this.resizeMapForFilters = false;
  }

  @computed get stateOptions() {
    return [
      {
        title: t('View projects'),
        selectedTitle: t('Viewing projects'),
        value: 'PROJECTS'
      },
      {
        title: t('View employees'),
        selectedTitle: t('Viewing employees'),
        value: 'EMPLOYEES'
      }
    ];
  }

  @action.bound
  updateMapState(state, location) {
    if (state.value === 'EMPLOYEES') {
      history.push(`/projects/map/workers`);
      return;
    }

    history.push(`/projects/map`);
  }
}
