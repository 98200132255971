import { RakenCollection } from '../lib';
import ProjectMemberNotificationSettings from '../models/ProjectMemberNotificationSettings';

export default class ProjectNotificationSettings extends RakenCollection {
  model() {
    return ProjectMemberNotificationSettings;
  }

  url() {
    return `/ra/users/${this.parent.id}/projects/notification-settings`;
  }
}
