import capitalize from 'lodash.capitalize';
import moment from 'moment';
import { computed } from 'mobx';
import { Model } from 'mobx-mc';
import { t } from 'utils/translate';

export default class BillingInfo extends Model {
  url() {
    return `/ra/companies/${this.company.uuid}/billing/billing-info`;
  }

  get restAttributes() {
    return [
      'id',
      'uuid',
      'address',
      'paymentMethod',
      'accountType',
      'firstName',
      'lastName',
      'creditCardToken',
      'externalLink'
    ];
  }

  @computed get company() {
    if (this.rootStore.isSuperAdmin) {
      return this.parent.company;
    }

    return this.rootStore.me.company;
  }

  @computed get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  @computed get expirationDate() {
    return `${this.paymentMethod.expMonth?.toString().padStart(2, '0')}/${
      this.paymentMethod.expYear
    }`;
  }

  @computed
  get hasExpired() {
    const exp = moment(this.expirationDate, 'MM/YYYY').endOf('month');
    const today = moment(new Date());

    return exp.isBefore(today);
  }

  @computed
  get isExpiring() {
    const exp = moment(this.expirationDate, 'MM/YYYY');
    const today = moment(new Date());

    return !this.hasExpired && exp.isBefore(today.add(10, 'days'));
  }

  @computed get status() {
    if (this.hasExpired) {
      return 'EXPIRED';
    }

    if (this.isExpiring) {
      return 'EXPIRING';
    }

    return 'VALID';
  }

  @computed get statusText() {
    switch (this.status) {
      case 'EXPIRED':
        return t('Expired');
      case 'EXPIRING':
        return t('Expiring');
      default:
        return t('Valid');
    }
  }

  @computed get statusColor() {
    switch (this.status) {
      case 'EXPIRED':
        return 'red';
      case 'EXPIRING':
        return 'orange';
      default:
        return 'green';
    }
  }

  @computed get statusTooltipText() {
    switch (this.status) {
      case 'EXPIRED':
        return t(
          'This billing method is expired. Please update the billing details immediately to avoid loss of client account access.'
        );
      case 'EXPIRING':
        return t(
          'This account’s billing method is about to expire. Please update the billing details to avoid loss of client account access.'
        );
      default:
        return '';
    }
  }

  @computed get accountTypeText() {
    return capitalize(this.paymentMethod.accountType.toLowerCase());
  }

  @computed
  get addressString() {
    return Object.keys(this.address)
      .filter(k => {
        return this.address[k] !== 'null';
      })
      .map(k => {
        return this.address[k].replace('.', '');
      })
      .join(', ');
  }

  @computed get bankAccountNumberString() {
    return `*****${this.paymentMethod.lastFour}`;
  }

  @computed get formValues() {
    return {
      first_name: this.firstName,
      last_name: this.lastName,
      address1: this.address?.street1,
      city: this.address?.city,
      state: this.address?.region,
      postal_code: this.address?.postalCode,
      country: this.address?.country || this.company.address?.country || 'US',
      account_type: this.paymentMethod.accountType?.toLowerCase(),
      name_on_account: this.paymentMethod.nameOnAccount,
      routing_number: this.paymentMethod.routingNumber,
      payment_method_type: this.paymentMethod
        ? this.paymentMethod.type
        : 'CREDIT_CARD'
    };
  }
}
