import { action, computed } from 'mobx';

import ProjectSettingsPayTypesUI from 'stores/ui/ProjectSettingsPayTypesUI';
import { t } from 'utils/translate';
import toggleArrayFieldValue from 'forms/utils/toggleArrayFieldValue';

export default class ProjectTemplatePayTypesUI extends ProjectSettingsPayTypesUI {
  @computed
  get companyProjectTemplateForm() {
    return this.parent.companyProjectTemplateForm;
  }

  @computed
  get templateHasNoPayTypes() {
    return (
      this.companyProjectTemplateForm.$('hiddenPayTypes').value.length ===
      this.payTypes.length
    );
  }

  @action.bound
  async togglePayTypeForTemplate(payType) {
    toggleArrayFieldValue(
      this.companyProjectTemplateForm,
      'hiddenPayTypes',
      payType.uuid
    );

    if (this.templateHasNoPayTypes) {
      // We can't allow a template with no pay types so if they toggle the last one off we need to add it back.
      this.rootStore.notificationsUI.pushNotification({
        showUndo: false,
        title: t('This template must contain at least one pay type')
      });
      setTimeout(() => {
        // We need to set time out to meet ticket AC: "When they select the slider it should animate off and then back on"
        toggleArrayFieldValue(
          this.companyProjectTemplateForm,
          'hiddenPayTypes',
          payType.uuid
        );
      }, 500);
    }
  }
}
