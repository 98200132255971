import kebabCase from 'lodash.kebabcase';
import { computed } from 'mobx';
import { Model } from 'mobx-mc';
import moment from 'moment';
import { t } from 'utils/translate';

export default class SuperAdminCompanyProject extends Model {
  constructor(data, options) {
    super(data, options);
  }

  get restAttributes() {
    return [
      'name',
      'uuid',
      'projectImage',
      'startDate',
      'address',
      'status',
      'projectId',
      'company',
      'parentProject'
    ];
  }

  @computed get companyName() {
    return this.company?.name || this.parentProject?.company?.name;
  }

  idAttribute() {
    return 'uuid';
  }

  get formattedStartDate() {
    return moment(this.startDate).format('YYYY-MM-DD');
  }

  @computed get statusFormatted() {
    switch (this.status) {
      case 'ACTIVE':
        return t('Active');
      case 'INACTIVE':
        return t('Inactive');
      case 'DELETED':
        return t('Deleted');
      default:
        return t('Active');
    }
  }

  @computed get statusColor() {
    switch (this.status) {
      case 'ACTIVE':
        return 'green';
      case 'INACTIVE':
        return 'orange';
      case 'DELETED':
        return 'red';
      default:
        return 'green';
    }
  }

  @computed get slug() {
    return kebabCase(this.name);
  }

  @computed get initials() {
    const words = this.name.toUpperCase().split(' ');

    return `${words[0].charAt(0)}`;
  }

  @computed
  get image() {
    if (this.projectImage) {
      return this.projectImage.thumbUrl || this.projectImage.contentUrl;
    }

    return null;
  }
}
