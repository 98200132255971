import kebabCase from 'lodash.kebabcase';
import omit from 'lodash.omit';
import { computed } from 'mobx';
import moment from 'moment';

import DocumentsFolderChildren from 'stores/collections/documents/DocumentsFolderChildren';

import formatTimestamp from 'utils/formatTimestamp';
import { t } from 'utils/translate';
import ReportEntry from '../ReportEntry';

export default class DocumentsFolder extends ReportEntry {
  get urlRoot() {
    return `/ra/companies/${this.rootStore.me.company.uuid}/document-storage/folders`;
  }

  idAttribute() {
    return 'uuid';
  }

  get restAttributes() {
    return [
      'uuid',
      'type',
      'companyUuid',
      'projectUuid',
      'parentFolderUuid',
      'path',
      'name',
      'description',
      'status',
      'createdByFullName',
      'updatedByFullName',
      'createdTimestamp',
      'updatedTimestamp'
    ];
  }

  get restAttributeDefaults() {
    return {
      name: 'Documents',
      path: []
    };
  }

  @computed get isFolder() {
    return true;
  }

  parse(attributes) {
    super.parse(attributes);

    this.parseChildren(attributes);

    return {
      ...omit(attributes, [
        'createdBy',
        'updatedBy',
        'company',
        'project',
        'children'
      ])
    };
  }

  parseChildren(attributes) {
    if (this.children) {
      this.children.set(attributes.children || []);
    } else {
      this.children = new DocumentsFolderChildren(attributes.children || [], {
        parent: this,
        rootStore: this.rootStore
      });
    }
  }

  @computed
  get icon() {
    return `${this.rootStore.assetsURL}/svg/documents/folder.svg`;
  }

  @computed
  get formValues() {
    return {
      name: this.name
    };
  }

  @computed
  get slug() {
    return kebabCase(this.name);
  }

  @computed
  get date() {
    return moment(this.updatedTimestamp).format('MM/DD/YYYY');
  }

  @computed
  get createdAtFormatted() {
    return formatTimestamp(this.createdTimestamp);
  }

  @computed
  get updatedAtFormatted() {
    return formatTimestamp(this.updatedTimestamp);
  }

  @computed get viewUrl() {
    if (this.id) {
      return `/projects/${this.rootStore.projectUI.project.id}/documents/${this.id}`;
    }

    return `/projects/${this.rootStore.projectUI.project.id}/documents/index`;
  }

  @computed get breadcrumbs() {
    let breadcrumbs = [
      {
        label: t('Documents'),
        path: {
          pathname: `/projects/${this.projectUuid}/documents/index`
        }
      }
    ];

    if (this.path) {
      breadcrumbs = breadcrumbs.concat(
        this.path.map(pathItem => {
          return {
            label: pathItem.name,
            path: {
              pathname: `/projects/${this.projectUuid}/documents/${pathItem.uuid}`
            }
          };
        })
      );

      breadcrumbs.pop();
    }

    return breadcrumbs;
  }
}
