import omit from 'lodash.omit';
import { action, computed } from 'mobx';
import ReportEntryWithAttachments from './ReportEntryWithAttachments';
import Material from './Material';
import CostCode from './CostCode';
import { t } from 'utils/translate';
import kebabCase from 'lodash.kebabcase';

export default class MaterialLog extends ReportEntryWithAttachments {
  get urlRoot() {
    return `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/companies/${this.rootStore.me.company.uuid}/materiallogs`;
  }

  get restAttributes() {
    return [
      'uuid',
      'createdTimestamp',
      'updatedTimestamp',
      'quantity',
      'desc',
      'teamId',
      'materialLog' /*for dashboard*/,
      'reportDate',
      'type' /*for dashboard*/,
      'project' /*for dashboard*/,
      'company',
      'projectUuid',
      'material',
      'costCode',
      'attachments',
      'empty',
      'segmentUuid'
    ];
  }

  idAttribute() {
    return 'uuid';
  }

  @action.bound
  parse(attributes) {
    /*for dashboard*/
    if (attributes.materialLog) {
      attributes = Object.assign(
        {},
        attributes,
        omit(attributes.materialLog, 'attachments', 'createdBy', 'updatedBy')
      );
    }

    this.parseCreatedBy(attributes);
    this.parseUpdatedBy(attributes);
    this.parseProject(attributes);
    this.parseCompany(attributes);
    this.parseAttachments(attributes);
    this.parseMaterial(attributes);
    this.parseCostCode(attributes);

    return {
      ...omit(attributes, [
        'attachments',
        'createdBy',
        'updatedBy',
        'material',
        'costCode',
        'project',
        'company',
        'materialLog' /*for dashboard*/
      ])
    };
  }

  @action.bound
  parseMaterial(attributes) {
    if (attributes.material) {
      if (attributes.material.deleted) {
        this.material = new Material(attributes.material, {
          rootStore: this.rootStore
        });
      } else {
        this.material = this.rootStore.materials.updateOrAdd(
          attributes.material
        );
      }
    }
  }

  @action.bound
  parseCostCode(attributes) {
    if (attributes.costCode && attributes.costCode.uuid) {
      this.costCode = new CostCode(attributes.costCode, {
        rootStore: this.rootStore
      });
    } else {
      this.costCode = null;
    }
  }

  @computed
  get costCodeDeleted() {
    return this.costCode?.deleted;
  }

  @computed get name() {
    return this.material.name;
  }

  @computed get formValues() {
    return {
      uuid: this.uuid,
      material: {
        uuid: this.material.uuid,
        name: this.material.name,
        unit: {
          uuid: this.material.unit.uuid,
          name: this.material.unit.name
        }
      },
      quantity: this.quantity,
      desc: this.desc,
      costCode: {
        uuid: this.costCode?.uuid || '',
        code: this.costCode?.code || '',
        division: this.costCode?.division || '',
        description: this.costCode?.description || ''
      }
    };
  }

  @computed
  get title() {
    return t('Material Log');
  }

  @computed get activityTitle() {
    return `${t('submitted a')} ${this.title}`;
  }

  @computed get slug() {
    if (this.costCode) {
      return kebabCase(
        `${this.material.name} ${this.costCode.division} ${this.costCode.code}`
      );
    }

    return kebabCase(this.material.name);
  }

  @computed
  get materialActiveInProject() {
    return this.relatedProject.materials.find(
      material => material.id === this.material.id
    );
  }

  @computed
  get canShowQuantity() {
    return (
      this.material &&
      this.material.unit &&
      this.material.unit.name &&
      (this.quantity || Number(this.quantity) === 0)
    );
  }
}
