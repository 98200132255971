import { Observer } from 'mobx-react';
import React, { useRef, useEffect } from 'react';
import { Box } from '@raken/athens-web/lib';
import WebViewer from '@pdftron/webviewer';

const ApryseWebViewer = ({
  uiStore,
  licenseKey,
  isReadOnly,
  annotationUser,
  initialDoc,
  mt,
  mb,
  style
}) => {
  const viewer = useRef(null);

  useEffect(() => {
    WebViewer(
      {
        path: '/libraries/pdftron',
        licenseKey: licenseKey,
        isReadOnly: isReadOnly,
        enableOfficeEditing: false,
        preloadWorker: 'pdf',
        annotationUser: annotationUser,
        disabledElements: ['settingsLanguageSection'],
        initialDoc: initialDoc
      },
      viewer.current
    ).then(instance => {
      if (uiStore) {
        uiStore.setWebViewerInstance(instance);
      }
    });

    return () => {
      if (uiStore) {
        uiStore.clearWebViewerInstance();
      }
    };
  }, [uiStore, licenseKey, isReadOnly, annotationUser, initialDoc]);

  return (
    <Observer>
      {() => {
        return <Box mt={mt} mb={mb} ref={viewer} style={style}></Box>;
      }}
    </Observer>
  );
};

export default ApryseWebViewer;
