import CompanyPermissions from '../CompanyPermissions';

export default class SuperAdminCompanyPermissions extends CompanyPermissions {
  url() {
    return `ra/company/${this.parent.activeCompany.uuid}/permissions`;
  }

  parse(data) {
    const projectMemberPermissions = data.collection.filter(
      permission => permission.role === 'ROLE_PROJECT_MEMBER'
    );

    const projectAdminPermissions = data.collection.filter(
      permission => permission.role === 'ROLE_ADMIN'
    );

    const accountAdminPermissions = data.collection.filter(
      permission => permission.role === 'ROLE_ACCOUNT_ADMIN'
    );

    projectAdminPermissions.forEach(projectAdminPermission => {
      if (
        !accountAdminPermissions.find(accountAdminPermission => {
          accountAdminPermission.permissionEnum ===
            projectAdminPermission.permissionEnum;
        })
      ) {
        accountAdminPermissions.push(
          Object.assign({}, projectAdminPermission, {
            role: 'ROLE_ACCOUNT_ADMIN',
            enabled: true
          })
        );
      }

      if (
        !projectMemberPermissions.find(projectMemberPermission => {
          projectMemberPermission.permissionEnum ===
            projectAdminPermission.permissionEnum;
        })
      ) {
        projectMemberPermissions.push(
          Object.assign({}, projectAdminPermission, {
            role: 'ROLE_PROJECT_MEMBER',
            enabled: false
          })
        );
      }
    });

    return [
      ...projectMemberPermissions,
      ...projectAdminPermissions,
      ...accountAdminPermissions
    ];
  }
}
