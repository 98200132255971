import { action, observable, computed } from 'mobx';

import {
  ProjectTemplateInfoForm,
  projectTemplateInfoFormOptions,
  projectTemplateInfoFormFields,
  projectTemplateInfoFormRules,
  projectTemplateInfoFormValues,
  projectTemplateInfoFormPlugins,
  projectTemplateInfoFormLabels
} from 'forms/projectTemplates/projectTemplateInfo';

import { t } from 'utils/translate';
import history from 'utils/history';

import ProjectTemplateChildEditUI from './ProjectTemplateChildEditUI';

export default class ProjectTemplateInfoAddUI extends ProjectTemplateChildEditUI {
  @observable form;
  constructor(options) {
    super(options);

    this.form = null;

    this.saveTitle = t('Project template created');
  }

  @action.bound setup() {
    this.form = new ProjectTemplateInfoForm(
      {
        fields: projectTemplateInfoFormFields,
        rules: Object.assign(projectTemplateInfoFormRules, {
          templateName: `required|string|not_in:${this.existingTemplateNames.join(
            ','
          )}`
        }),
        labels: projectTemplateInfoFormLabels,
        values: projectTemplateInfoFormValues
      },
      {
        options: projectTemplateInfoFormOptions,
        plugins: projectTemplateInfoFormPlugins
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @action.bound tearDown() {
    this.form = null;
    this.unblockHistory();
  }

  @action.bound
  async submitFormSuccess() {
    /**
     * We overirde this function from the base class for the purpose of creating a new template.
     */
    this.saving = true;

    try {
      const savedTemplate = await this.projectTemplates.create(
        this.form.values(),
        {
          wait: true
        }
      );
      this.handleSaveSuccess();
      this.unblockHistory();
      history.push(
        `/company-settings/project-templates/${savedTemplate.id}/info`
      );
    } catch (error) {
      this.handleSaveError(error);
    } finally {
      this.saving = false;
    }
  }

  @computed get projectTemplates() {
    return this.parent.projectTemplates;
  }

  @computed get existingTemplateNames() {
    if (this.selectedTemplate) {
      return this.projectTemplates.models
        .filter(template => {
          return template.templateName !== this.selectedTemplate.templateName;
        })
        .map(template => {
          // Convert Pipe to Hex code as it will break rules when passed to dvr in initCompanyProjectTemplateForm
          return `${template.templateName.replace('|', '7C')}`;
        });
    }

    return this.projectTemplates.models.map(template => {
      // Convert Pipe to Hex code as it will break rules when passed to dvr in initCompanyProjectTemplateForm
      return `${template.templateName.replace('|', '7C')}`;
    });
  }
}
