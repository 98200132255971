import request from 'axios';
import debounce from 'lodash.debounce';
import { action, computed, observable, reaction, toJS } from 'mobx';
import SettingsChildUI from './SettingsChildUI';

import CostCode from 'stores/models/CostCode';

import { BASE_DEBOUNCE } from 'fixtures/constants';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import history from 'utils/history';

import {
  CostCodeForm,
  costCodeFormOptions,
  costCodeFormFields,
  costCodeFormRules,
  costCodeFormPlugins,
  costCodeFormLabels
} from 'forms/costCode';

export default class SettingsCostCodeEditUI extends SettingsChildUI {
  @observable entryForEdit;
  @observable entryEditForm;

  @observable divisionNameQuery;

  @observable isDefault;

  constructor(options) {
    super(options);

    this.entryForEdit = null;
    this.entryEditForm = null;

    // Divisions
    this.divisionNameQuery = '';
    this.divisionNames = observable([]);
    this.fetchDivisionsDebounced = debounce(this.fetchDivisions, BASE_DEBOUNCE);

    this.isDefault = false;
  }

  @action.bound setup(id) {
    this.fetchEntry(id);
    this.setupReactions();
  }

  @action.bound async fetchEntry(uuid) {
    if (this.entryForEdit) return;

    try {
      let model = this.parent.costCodes.get(uuid);

      if (!model) {
        model = new CostCode(
          {
            uuid: uuid
          },
          {
            rootStore: this.rootStore
          }
        );

        await model.fetch();
      }

      this.setEntryForEdit(model);
    } catch (error) {
      console.error(error);
      this.cancelCostCodeEdit();
    }
  }

  @action.bound setEntryForEdit(model) {
    this.entryForEdit = model;

    this.entryEditForm = new CostCodeForm(
      {
        fields: costCodeFormFields,
        rules: costCodeFormRules,
        labels: costCodeFormLabels,
        values: this.entryForEdit.formValues
      },
      {
        options: costCodeFormOptions,
        plugins: costCodeFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  setupReactions() {
    this.reactToDivisionQuery = reaction(
      () => this.divisionNameQuery,
      divisionNameQuery => {
        this.fetchDivisionsDebounced();
      }
    );
  }

  @action.bound tearDown() {
    this.clearUIState();
    this.tearDownReactions();
  }

  @action.bound tearDownReactions() {
    this.reactToDivisionQuery && this.reactToDivisionQuery();
  }

  // Divisions
  @action.bound
  async fetchDivisions() {
    this.divisionNames.clear();

    if (this.divisionNameQuery.length < 2) {
      return;
    }

    try {
      const response = await request.get(
        `${this.rootStore.urlMicroService(
          'performanceTracking'
        )}/costcodes/divisions`,
        {
          params: {
            query: this.divisionNameQuery
          }
        }
      );

      this.divisionNames.replace(
        response.data.collection.map(name => {
          return {
            value: name,
            title: name
          };
        })
      );
    } catch (error) {
      console.error(error);
    }
  }

  @action.bound setDivisionNameQuery(value) {
    this.divisionNameQuery = value;
  }

  @action.bound submitEntryEditForm(event) {
    event.preventDefault();
    event.stopPropagation();

    this.entryEditForm.submit({
      onSuccess: this.submitEntryEditFormSuccess,
      onError: this.submitEntryEditFormError
    });
  }

  @action.bound async submitEntryEditFormSuccess() {
    this.saving = true;

    try {
      const values = this.entryEditForm.values();

      const project = this.entryForEdit.getProjectByUuid(this.project.uuid);

      project.description = values.description;

      if (this.entryForEdit.isDefault) {
        await this.entryForEdit.save({
          projects: toJS(this.entryForEdit.projects)
        });
      } else {
        await this.entryForEdit.save(
          {
            description: this.isDefault ? values.description : null,
            division: values.division,
            isDefault: this.isDefault,
            projects: toJS(this.entryForEdit.projects)
          },
          {
            wait: true
          }
        );
      }

      this.parent.refetchCostCodes();

      this.cancelCostCodeEdit();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Cost code updated')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitEntryAddFormError() {
    console.error(this.entryEditForm.errors());
  }

  @action.bound cancelCostCodeEdit() {
    history.push({
      pathname: `${this.project.viewUrl}/settings/production-tracking/costcodes`,
      search: this.baseQueryParams
    });
  }

  @action.bound clearUIState() {
    this.divisionNameQuery = '';
    this.divisionNames.clear();
    this.clearValidationDetails();
    this.activeModal = null;
    this.entryForEdit = null;
    this.entryEditForm = null;
    this.isDefault = false;
    this.saving = false;
  }

  @computed get disableCancelButton() {
    return this.saving;
  }

  @computed get disableSaveButton() {
    return this.saving || this.costCodeExistsOnProject;
  }

  @computed get saveButtonText() {
    if (this.saving) {
      return t('Saving...');
    }

    return t('Save');
  }

  @computed
  get costCodeExistsOnProject() {
    if (this.saving) return false;

    return (
      this.entryEditForm?.existingCostCode &&
      this.entryEditForm?.existingCostCode.getProjectByUuid(
        this.project.uuid
      ) &&
      this.entryEditForm?.existingCostCode.uuid !== this.entryForEdit.uuid
    );
  }

  @computed
  get costCodeExistsOnProjectText() {
    return t(
      'The cost code {costCode} already exists for this project. Please provide a unique combination.',
      {
        templateStrings: {
          costCode: this.entryEditForm.existingCostCode.costCodeKey
        }
      }
    );
  }

  @action.bound toggleIsDefault() {
    this.isDefault = !this.isDefault;
  }
}
