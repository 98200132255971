import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';

const workerPhotoIdDetailFiltersFormPlugins = extendedPlugins;

const workerPhotoIdDetailFiltersFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const workerPhotoIdDetailFiltersFormFields = ['eventType', 'photoIdStatus'];

const workerPhotoIdDetailFiltersFormRules = {
  eventType: 'string',
  photoIdStatus: 'string'
};

class WorkerPhotoIdDetailFiltersForm extends MobxReactForm {}

export {
  WorkerPhotoIdDetailFiltersForm,
  workerPhotoIdDetailFiltersFormRules,
  workerPhotoIdDetailFiltersFormFields,
  workerPhotoIdDetailFiltersFormOptions,
  workerPhotoIdDetailFiltersFormPlugins
};
