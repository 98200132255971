import omit from 'lodash.omit';
import React from 'react';
import { observer } from 'mobx-react';

import {
  Dialog,
  Button,
  Box,
  Alert,
  TextField,
  Text,
  Select,
  Link
} from '@raken/athens-web/lib';

import { Form } from 'raken-ui';
import { t } from 'utils/translate';

const CompanyNameHelperText = observer(({ uiStore }) => {
  if (uiStore.hasSelectedCompany) {
    return (
      <Text component="span" variant="body2" light>
        {t('Not your company?')}{' '}
        <Text component="span" inline variant="body2" rakenColor="blue">
          <Link isExternal href="#" onClick={uiStore.clearSelectedCompany}>
            {t('Reset')}
          </Link>
        </Text>
      </Text>
    );
  } else {
    return (
      <Text component="span" variant="body2" light>
        {t('Already belong to a company in Raken?')}{' '}
        <Text component="span" variant="body2" inline rakenColor="blue">
          <Link
            isExternal
            onClick={uiStore.setCompanyFormModeToSearch}
            href="#"
          >
            {t('Find it')}
          </Link>
        </Text>
      </Text>
    );
  }
});

const CompanySearchHelperText = observer(({ uiStore }) => {
  return (
    <Text component="span" variant="body2" light>
      {t("Can't find your company in Raken?")}{' '}
      <Text component="span" variant="body2" inline rakenColor="blue">
        <Link
          data-qa="link_create-new-company"
          isExternal
          onClick={uiStore.setCompanyFormModeToManual}
          href="#"
        >
          {t('Create a new one')}
        </Link>
      </Text>
    </Text>
  );
});

export default observer(({ uiStore }) => {
  return (
    <Dialog
      dataQA="company-info"
      open={uiStore.activeModal === 'companyInfo'}
      fullWidth={true}
    >
      <Dialog.Title dataQA="company-info-header">
        {t('Your company info')}
      </Dialog.Title>

      {uiStore.companyForm && (
        <Form onSubmit={uiStore.submitCompanyInfoForm}>
          <Dialog.Content style={{ minHeight: 200 }}>
            <Box mb={6}>
              <Text>{t("Lastly, let's confirm your company info.")}</Text>
            </Box>
            {uiStore.hasValidationDetails && (
              <Box mb={6} width="100%">
                <Alert>
                  {uiStore.validationDetails.map(item => item.fieldMessage)}
                </Alert>
              </Box>
            )}
            {uiStore.companyFormMode === 'search' && (
              <Box mb={4}>
                <Select
                  fullWidth
                  label={t('Company')}
                  options={uiStore.companyOptions}
                  onInputChange={(event, value, reason) => {
                    if (reason === 'clear' || reason === 'input') {
                      uiStore.setCompanyQuery(value);
                    }
                  }}
                  onChange={(e, option) => {
                    uiStore.selectCompany(option);
                    uiStore.clearCompanyQuery();
                  }}
                  noOptionsText={uiStore.companyNoOptionsText}
                  renderOption={(option, { selected }) => {
                    return (
                      <Box pt={1} pb={1} data-qa={`option_${option.name}`}>
                        <Box>
                          <Text>{option.name}</Text>
                        </Box>
                        <Box>
                          <Text light>
                            {Object.keys(omit(option.address, 'country'))
                              .map(key => {
                                return option.address[key];
                              })
                              .join(', ')}
                          </Text>
                        </Box>
                      </Box>
                    );
                  }}
                  optionTitle="name"
                  autocomplete
                  autoFocus
                  placeholder={'Search companies'}
                  dataQA="company-name"
                  helperText={<CompanySearchHelperText uiStore={uiStore} />}
                />
              </Box>
            )}
            {uiStore.companyFormMode === 'manual' && (
              <Box mb={4}>
                <TextField
                  fullWidth
                  placeholder={t('Company name')}
                  value={uiStore.companyForm.$('name').value}
                  onChange={uiStore.companyForm.$('name').sync}
                  error={uiStore.companyForm.$('name').hasError}
                  helperText={uiStore.companyForm.$('name').error}
                  disabled={uiStore.hasSelectedCompany}
                  dataQA="company-name"
                  label={t('Company name')}
                  autoFocus={
                    !uiStore.company ||
                    (uiStore.company && !uiStore.company.name)
                  }
                  removeDescriptionSpace={
                    !uiStore.companyForm.$('name').hasError // Because we are showing a component below ths field
                  }
                />
                <Box mt={1}>
                  <CompanyNameHelperText uiStore={uiStore} />
                </Box>
              </Box>
            )}

            <Box>
              <TextField
                fullWidth
                placeholder={t('Zip code')}
                value={uiStore.companyForm.$('address.postCode').value}
                onChange={uiStore.companyForm.$('address.postCode').sync}
                error={uiStore.companyForm.$('address.postCode').error}
                disabled={uiStore.hasSelectedCompany}
                dataQA="zip-code"
                label={t('Zip code')}
                autoFocus={uiStore.company && uiStore.company.name}
              />
            </Box>

            <Box>
              <Select
                options={uiStore.countryOptions}
                fullWidth
                value={uiStore.selectedCountry}
                onChange={(e, option) => {
                  uiStore.selectCountry(option);
                }}
                error={uiStore.companyForm.$('address.country').error}
                disabled={uiStore.hasSelectedCompany}
                placeholder={t('Country')}
                label={t('Country')}
                dataQA="country"
                optionTitle="name"
                removeDescriptionSpace
                getOptionLabel={option => (option.name ? option.name : '')}
              />
            </Box>
          </Dialog.Content>

          <Dialog.Actions>
            <Button disabled={uiStore.saving} type="submit" dataQA="submit">
              {t("Let's go!")}
            </Button>
          </Dialog.Actions>
        </Form>
      )}
    </Dialog>
  );
});
