import React, { Component } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';

import VerticalScrollingContainer from './VerticalScrollingContainer';
import SelectBoxWrapper from './SelectBoxWrapper';

const ListWrapper = styled.div`
  background: ${props => props.theme.colors.white};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  border: 1px solid ${props => props.theme.colors.lightGrey3};
  border-radius: 9px;
  margin: ${props => (props.orientation === 'top' ? '-3px 0' : '3px 0')};
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 100%;
  width: ${props =>
    props.width.toString().match(/^[0-9]+$/) !== null
      ? `${props.width}px`
      : props.width};
  z-index: 99999;
`;

const Placeholder = styled.div`
  color: ${props => props.theme.colors.lightGrey1};
  font-weight: 300;
`;

const SelectedValue = styled.div`
  padding-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

@observer
class CheckBoxSelect extends Component {
  static defaultProps = {
    placeholder: 'Select an option',
    height: 300,
    width: 200
  };

  renderHeader() {}

  renderChildren() {
    const { children } = this.props;

    return React.Children.map(children, child => {
      return React.cloneElement(child, {
        onClick: e => {
          e.nativeEvent.stopImmediatePropagation();
        }
      });
    });
  }

  renderSelectedItem() {
    const { placeholder, selectedItems } = this.props;

    if (selectedItems && selectedItems.length) {
      return <SelectedValue>{selectedItems.join(', ')}</SelectedValue>;
    }

    return <Placeholder>{placeholder}</Placeholder>;
  }

  render() {
    const {
      width,
      marginLeft,
      marginRight,
      size,
      disabled,
      comboInput,
      footer,
      height,
      onClose,
      header,
      hasToggle,
      container,
      dataQA,
      scrollRef
    } = this.props;

    return (
      <SelectBoxWrapper
        size={size}
        width={width}
        marginLeft={marginLeft}
        marginRight={marginRight}
        selectedItem={this.renderSelectedItem()}
        disabled={disabled}
        comboInput={comboInput}
        onClose={onClose}
        hasToggle={hasToggle}
        container={container}
        dataQA={dataQA}
      >
        {(open, orientation, onClose) => (
          <ListWrapper width={width} orientation={orientation} size={size}>
            {header}
            <VerticalScrollingContainer
              innerRef={ref => {
                scrollRef && scrollRef(ref);
              }}
              height={height}
              fadeEdges
            >
              {this.renderChildren()}
            </VerticalScrollingContainer>
            {footer}
          </ListWrapper>
        )}
      </SelectBoxWrapper>
    );
  }
}

export default CheckBoxSelect;
