import { RakenCollection } from '../lib';
import EquipmentLog from '../models/EquipmentLog';

export default class EquipmentLogsCollection extends RakenCollection {
  model() {
    return EquipmentLog;
  }

  url() {
    return `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/companies/${this.rootStore.me.company.uuid}/equipmentlogs`;
  }
}
