import orderBy from 'lodash.orderby';
import { action, observable, computed } from 'mobx';
import ProjectChildUI from './ProjectChildUI';

import ProjectInsightsChecklistSummary from 'stores/models/ProjectInsightsChecklistSummary';
import ProjectInsightsChecklistCompletion from 'stores/models/ProjectInsightsChecklistCompletion';
import ProjectInsightsChecklistCompletionByWorkUnit from 'stores/models/ProjectInsightsChecklistCompletionByWorkUnit';
import ProjectInsightsChecklistFailedResponses from 'stores/models/ProjectInsightsChecklistFailedResponses';
import ProjectInsightsToolBoxTalks from 'stores/models/ProjectInsightsToolBoxTalks';

// Observations
import ProjectInsightsPositiveNegativeObservations from 'stores/models/ProjectInsightsPositiveNegativeObservations';
import ProjectInsightsObservationsRaisedByType from 'stores/models/ProjectInsightsObservationsRaisedByType';
import ProjectInsightsObservationsRaisedByTeamMember from 'stores/models/ProjectInsightsObservationsRaisedByTeamMember';
import ProjectInsightsIssuesObservedHoursWorked from 'stores/models/ProjectInsightsIssuesObservedHoursWorked';

import {
  TimeFrameForm,
  timeFrameFormOptions,
  timeFrameFormFields,
  timeFrameFormRules,
  timeFrameFormLabels,
  timeFrameFormPlugins,
  timeFrameFormValues
} from 'forms/timeFrame';

import timeFrameToStartDay from 'utils/timeFrameToStartDay';
import timeFrameToEndDay from 'utils/timeFrameToEndDay';

import { callTrack } from 'utils/segmentIntegration';
import {
  PROJECT_SAFETY_INSIGHTS_VIEWED,
  PROJECT_SAFETY_INSIGHTS_PDF
} from 'utils/segmentAnalytics/eventSpec';

export default class SafetyInsightsChecklistsUI extends ProjectChildUI {
  @observable modelsSet;

  @observable timeFrameSelection;
  @observable fromDate;
  @observable toDate;

  // PDF download
  @observable pdfDownload;

  constructor(options) {
    super(options);

    // Global Selection
    this.timeFrameSelection = 'THIS_MONTH';
    this.fromDate = null;
    this.toDate = null;

    // PDF
    this.pdfDownload = false;

    this.modelsSet = false;
  }

  @action.bound setup() {
    this.setupModels();
    callTrack(PROJECT_SAFETY_INSIGHTS_VIEWED);
  }

  @action.bound tearDown() {
    this.tearDownModels();
    this.clearUIState();
  }

  @action.bound setupModels() {
    if (this.modelsSet) return;

    this.checklistSummary = new ProjectInsightsChecklistSummary(null, {
      typeClasses: 'Environmental,Equipment,Safety',
      parent: this,
      rootStore: this.rootStore
    });

    this.checklistCompletion = new ProjectInsightsChecklistCompletion(null, {
      typeClasses: 'Environmental,Equipment,Safety',
      parent: this,
      rootStore: this.rootStore
    });

    this.checklistCompletionByWorkUnit = new ProjectInsightsChecklistCompletionByWorkUnit(
      null,
      {
        typeClasses: 'Environmental,Equipment,Safety',
        workUnitTypes: 'WorkerClassification',
        parent: this,
        rootStore: this.rootStore
      }
    );

    this.checklistFailedResponses = new ProjectInsightsChecklistFailedResponses(
      null,
      {
        typeClasses: 'Environmental,Equipment,Safety',
        rootStore: this.rootStore,
        parent: this
      }
    );

    this.toolBoxTalks = new ProjectInsightsToolBoxTalks(null, {
      rootStore: this.rootStore,
      parent: this
    });

    this.insightsObservationsRaisedByType = new ProjectInsightsObservationsRaisedByType(
      null,
      {
        parent: this,
        rootStore: this.rootStore,
        typeClasses: 'SAFETY'
      }
    );

    this.insightsObservationsRaisedByTeamMember = new ProjectInsightsObservationsRaisedByTeamMember(
      null,
      {
        parent: this,
        rootStore: this.rootStore,
        typeClasses: 'SAFETY'
      }
    );

    this.insightsIssuesObservedHoursWorked = new ProjectInsightsIssuesObservedHoursWorked(
      null,
      {
        parent: this,
        rootStore: this.rootStore,
        typeClasses: 'SAFETY'
      }
    );

    this.insightsPositiveNegativeObservations = new ProjectInsightsPositiveNegativeObservations(
      null,
      {
        parent: this,
        rootStore: this.rootStore,
        typeClasses: 'SAFETY'
      }
    );

    this.modelsSet = true;
  }

  @action.bound tearDownModels() {
    this.checklistSummary = null;
    this.checklistCompletion = null;
    this.checklistCompletionByWorkUnit = null;
    this.checklistFailedResponses = null;
    this.toolBoxTalks = null;
    this.modelsSet = false;
  }

  @computed get safetyInsightsObservationsUI() {
    return this.parent.safetyInsightsObservationsUI;
  }

  @action.bound setPdfDownload(value) {
    this.pdfDownload = value;
    callTrack(PROJECT_SAFETY_INSIGHTS_PDF);
  }

  @action.bound clearPdfDownload(value) {
    this.pdfDownload = null;
  }

  @action.bound clearUIState() {
    this.pdfDownload = false;
  }

  @action.bound
  setTimeFrameSelection(selection, fromDate, toDate) {
    this.timeFrameSelection = selection;
    this.fromDate = fromDate;
    this.toDate = toDate;

    this.safetyInsightsObservationsUI.timeFrameSelection = selection;
    this.safetyInsightsObservationsUI.fromDate = fromDate;
    this.safetyInsightsObservationsUI.toDate = toDate;
  }

  @computed
  get earliestStartDate() {
    if (!this.project) return null;

    return orderBy(this.project.allProjectTeams, ['startDate'], ['asc'])[0]
      .startDate;
  }

  @computed
  get startDay() {
    return timeFrameToStartDay(
      this.timeFrameSelection,
      this.earliestStartDate,
      this.fromDate
    );
  }

  @computed
  get endDay() {
    return timeFrameToEndDay(this.timeFrameSelection, this.toDate);
  }

  @action.bound
  initTimeFrameForm() {
    this.activeForm = new TimeFrameForm(
      {
        fields: timeFrameFormFields,
        rules: timeFrameFormRules,
        values: timeFrameFormValues,
        labels: timeFrameFormLabels
      },
      {
        options: timeFrameFormOptions,
        plugins: timeFrameFormPlugins,
        rootStore: this.rootStore
      }
    );
  }
}
