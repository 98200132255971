import { action, computed, reaction } from 'mobx';
import InsightsUIModel from './InsightsUIModel';

import theme from 'themes/main';
import { t } from 'utils/translate';

export default class InsightsPositiveNegativeObservations extends InsightsUIModel {
  constructor(attributes, options) {
    super(attributes, options);

    // React to selection changes
    this.reactToSelection = reaction(
      () => this.params,
      params => {
        if (params) {
          this.fetchStats();
        } else {
          this.cancelRequest();
          this.insights.clear();
        }
      },
      { fireImmediately: true }
    );

    this.typeClasses = options.typeClasses;
  }

  @computed
  get params() {
    const params = {
      startDay: this.insightsUI.startDay,
      endDay: this.insightsUI.endDay,
      typeClasses: this.typeClasses
    };

    switch (this.insightsUI.projectSelection) {
      case 'ALL':
        params.projectStatuses = 'ACTIVE,INACTIVE';
        break;
      case 'ACTIVE':
        params.projectStatuses = 'ACTIVE';
        break;
      default:
        params.projectTeamUuids = this.insightsUI.filteredProjectTeamUuids.join(
          ','
        );
        break;
    }

    return params;
  }

  url() {
    return 'ra/insights/observations/raisedByCompany';
  }

  get restAttributes() {
    return ['insights'];
  }

  @action.bound
  async fetchStats() {
    try {
      await this.fetch({
        params: this.params
      });
    } catch (e) {
      this.set(
        {
          positiveCount: 0,
          negativeCount: 0
        },
        {
          reset: true
        }
      );
    }
  }

  @computed
  get hasStats() {
    return this.insights?.length > 0;
  }

  @computed
  get chartSeries() {
    return {
      companyNames: this.insights.map(observation => observation.companyName),
      positiveObservationsValues: this.insights.map(
        observation => -observation.positiveCount
      ),
      negativeObservationsValues: this.insights.map(
        observation => observation.negativeCount
      )
    };
  }

  @computed
  get chartConfig() {
    return {
      chart: {
        type: 'bar'
      },
      title: {
        text: ''
      },
      accessibility: {
        point: {
          valueDescriptionFormat: '{index}. Age {xDescription}, {value}%.'
        }
      },
      xAxis: [
        {
          categories: this.chartSeries.companyNames,
          reversed: false,
          labels: {
            step: 1
          },
          accessibility: {
            description: t('Company names')
          }
        },
        {
          // mirror axis on right side
          opposite: true,
          reversed: false,
          categories: this.chartSeries.companyNames,
          linkedTo: 0,
          labels: {
            step: 1
          },
          accessibility: {
            description: t('Company names')
          }
        }
      ],
      yAxis: {
        title: {
          text: null
        },
        labels: {
          formatter: function() {
            if (Math.round(this.value) !== this.value) {
              return;
            }
            return Math.abs(this.value);
          }
        },
        accessibility: {
          description: t('Number of Obervations')
        }
      },
      plotOptions: {
        series: {
          stacking: 'normal'
        }
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      tooltip: {
        backgroundColor: '#fff',
        borderWidth: 1,
        borderRadius: 10,
        borderColor: theme.colors.lightGrey3,
        padding: 10,
        shadow: false,
        formatter: function() {
          const observationsTranslate = t('Observations');
          const totalsTranslate = t('Total:');
          return (
            '<b>' +
            this.series.name +
            ` ${observationsTranslate} - ` +
            this.point.category +
            '</b><br/>' +
            `${totalsTranslate} ` +
            Math.abs(this.point.y)
          );
        }
      },

      series: [
        {
          name: t('Positive'),
          color: '#4caf50',
          data: this.chartSeries.positiveObservationsValues
        },
        {
          name: t('Negative'),
          color: '#F44336',
          data: this.chartSeries.negativeObservationsValues
        }
      ]
    };
  }
}
