import { RakenCollection } from '../lib';
import ObservationType from '../models/ObservationType';

export default class ObservationTypes extends RakenCollection {
  model() {
    return ObservationType;
  }

  url() {
    return `${this.rootStore.urlMicroService('toolbox')}/companies/${
      this.rootStore.me.company.uuid
    }/observations/types`;
  }
}
