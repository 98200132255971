import omit from 'lodash.omit';
import { computed, action, observable } from 'mobx';
import { t } from 'utils/translate';
import errorHandler from 'utils/errorHandler';
import Integration from './Integration';

export default class ProjectIntegration extends Integration {
  @observable newExternalCompanyId;
  @observable newExternalProjectId;

  parse(attributes) {
    this.parseMapping(attributes);

    return {
      ...omit(attributes, ['status', 'providerRef', 'companyRef', 'mapping'])
    };
  }

  @action.bound parseMapping(attributes) {
    if (attributes.mapping) {
      this.newExternalCompanyId = attributes.mapping.externalCompanyId || null;
      this.newExternalProjectId = attributes.mapping.externalProjectId || null;
    } else {
      this.newExternalCompanyId = null;
      this.newExternalProjectId = null;
    }
  }

  @computed
  get externalCompanies() {
    return this.external.map(external => external.company);
  }

  @action.bound
  setExternalCompanyId(id) {
    this.newExternalCompanyId = id;
  }

  @computed
  get externalAppProjects() {
    const selectedExternal = this.external.find(
      external => external.company.id === this.newExternalCompanyId
    );

    if (selectedExternal) {
      return selectedExternal.projects;
    }

    return [];
  }

  @action.bound
  setExternalProjectId(id) {
    this.newExternalProjectId = id;
  }

  @computed
  get mappingSaved() {
    if (!this.mapping) return false;

    if (!this.hasExternal) {
      return Boolean(this.mapping.externalProjectId);
    }

    return this.mapping.externalCompanyId && this.mapping.externalProjectId;
  }

  @computed
  get mappingSet() {
    if (!this.hasExternal && this.newExternalProjectId) return true;

    return this.newExternalCompanyId && this.newExternalProjectId;
  }

  @computed
  get mappingChanged() {
    if (!this.mappingSaved) return true;

    return (
      this.mapping.externalCompanyId !== this.newExternalCompanyId ||
      this.mapping.externalProjectId !== this.newExternalProjectId
    );
  }

  @action.bound
  applyMapping() {
    const mapping = {
      externalProjectId: this.newExternalProjectId
    };

    if (this.hasExternal) {
      mapping.externalCompanyId = this.newExternalCompanyId;
    }

    this.save(
      {
        mapping: mapping
      },
      {
        wait: true
      }
    )
      .then(response => {
        this.rootStore.notificationsUI.pushNotification({
          showUndo: false,
          title: t('Project Connected.')
        });
      })
      .catch(error => {
        errorHandler(error, this.rootStore.notificationsUI.pushError);
      });
  }

  @action.bound
  clearMapping() {
    this.newExternalProjectId = null;
    this.newExternalCompanyId = null;

    if (!this.mappingSaved) return;

    this.save(
      {
        mapping: null
      },
      {
        wait: true
      }
    )
      .then(() => {
        this.rootStore.notificationsUI.pushNotification({
          showUndo: false,
          title: t('Project Disconnected.')
        });
      })
      .catch(error => {
        errorHandler(error, this.rootStore.notificationsUI.pushError);
      });
  }
}
