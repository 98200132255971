import { reaction } from 'mobx';

export default field => {
  return reaction(
    () => field.isPristine,
    isPristine => {
      if (!isPristine) {
        field.set('rules', 'required|email|username_available');
      } else {
        field.set('rules', 'required|email');
      }
    }
  );
};
