import { computed } from 'mobx';
import { Model } from 'mobx-mc';
import formatCurrency from 'utils/formatCurrency';

export default class Plan extends Model {
  url() {
    return `/ra/companies/${this.company.uuid}/billing/balance`;
  }

  get restAttributes() {
    return ['id', 'pastDue', 'amount'];
  }

  @computed get company() {
    if (this.rootStore.isSuperAdmin) {
      return this.parent.company;
    }

    return this.rootStore.me.company;
  }

  @computed get amountFormatted() {
    return formatCurrency(this.amount);
  }
}
