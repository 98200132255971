import { RakenCollection } from '../../lib';
import SuperAdminCompanyTeamMember from '../../models/superAdmin/SuperAdminCompanyTeamMember';

export default class SuperAdminCompanyTeamMembers extends RakenCollection {
  model() {
    return SuperAdminCompanyTeamMember;
  }

  url() {
    return `ra/sadmin/companies/${this.parent.id}/members`;
  }
}
