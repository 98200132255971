import orderBy from 'lodash.orderby';
import { action, computed, observable } from 'mobx';
import { FORM_TEMPLATE_USED } from 'utils/segmentAnalytics/eventSpec';
import { callTrack } from 'utils/segmentIntegration';
import ProjectChildAddUI from '../ProjectChildAddUI';
import Forms from 'stores/collections/forms/Forms';
import FormTemplates from 'stores/collections/forms/FormTemplates';

import history from 'utils/history';
import alertErrorHandler from 'utils/alertErrorHandler';

import {
  AddFormFromTemplateForm,
  addFormFromTemplateFormOptions,
  addFormFromTemplateFormFields,
  addFormFromTemplateFormRules,
  addFormFromTemplateFormLabels,
  addFormFromTemplateFormPlugins
} from 'forms/project/addFormFromTemplate';

export default class FormAddFromTemplateUI extends ProjectChildAddUI {
  @observable loading;
  @observable searchQuery;

  constructor(options) {
    super(options);

    this.searchQuery = '';
    this.loading = true;

    this.forms = new Forms(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.formTemplates = new FormTemplates(null, {
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound
  setup() {
    this.fetchFormTemplates();

    this.entryAddForm = new AddFormFromTemplateForm(
      {
        fields: addFormFromTemplateFormFields,
        rules: addFormFromTemplateFormRules,
        values: {},
        labels: addFormFromTemplateFormLabels
      },
      {
        options: addFormFromTemplateFormOptions,
        plugins: addFormFromTemplateFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound
  tearDown() {
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.forms.reset();
    this.formTemplates.reset();
    this.searchQuery = '';
    this.nextUrl = null;
    this.activeModal = null;
    this.entryForAdd = null;
    this.entryAddForm = null;
    this.saving = false;
    this.loading = true;
  }

  @action.bound async fetchFormTemplates() {
    this.loading = true;

    await this.formTemplates.fetch({
      params: {
        limit: 10000
      }
    });

    this.loading = false;
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
  }

  @computed
  get orderedFormTemplates() {
    return orderBy(
      this.formTemplates.models,
      [template => template.name.toLowerCase()],
      ['asc']
    );
  }

  @computed
  get searchedFormTemplates() {
    if (!this.searchQuery) {
      return this.orderedFormTemplates;
    }
    const query = this.searchQuery.toLowerCase();

    return this.orderedFormTemplates.filter(template => {
      return template.name.toLowerCase().indexOf(query) > -1;
    });
  }

  @computed get hasSearchedFormTemplates() {
    return this.searchedFormTemplates.length > 0;
  }

  @computed get selectedFormTemplateOption() {
    return this.formTemplates.get(this.entryAddForm.$('templateUuid').value);
  }

  @action.bound async submitEntryAddFormSuccess() {
    this.saving = true;

    const values = this.entryAddForm.values();

    let blob = await fetch(
      this.selectedFormTemplateOption.document.contentUrl
    ).then(response => response.blob());

    const file = new File([blob], `${values.name}.pdf`, {
      type: 'application/pdf'
    });

    this.handleFormUpload(file);
  }

  @computed
  get hasFormsUploading() {
    return this.forms.hasModels;
  }

  @action.bound async handleFormUpload(file) {
    try {
      await this.forms.upload({
        file: file,
        metadata: {
          projectUuid: this.projectUuid
        }
      });

      const form = this.forms.at(0);

      this.parent.forms.add(form, { at: 0 });

      form.fromTemplate = true;

      callTrack(FORM_TEMPLATE_USED, {
        form_template_name: this.selectedFormTemplateOption.name
      });

      this.parent.editForm(form);
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound rejectFileType(fileName) {
    const extension = fileName.split('.').pop();

    this.notifications.pushNotification({
      title: `File of type .${extension} is not supported.`,
      snackbar: 'error',
      icon: 'notification'
    });
  }

  @action.bound cancelAddFormFromTemplate() {
    history.push({
      pathname: `${this.project.viewUrl}/forms`,
      search: this.baseQueryParams
    });
  }
}
