import omit from 'lodash.omit';
import orderBy from 'lodash.orderby';
import { Model } from 'mobx-mc';
import ChecklistQuestions from 'stores/collections/checklists/ChecklistQuestions';
import { action, computed } from 'mobx';

export default class ChecklistSection extends Model {
  get restAttributes() {
    return ['uuid', 'position', 'title', 'description'];
  }

  idAttribute() {
    return 'uuid';
  }

  @action.bound parse(attributes) {
    this.parseChecklistQuestions(attributes);

    return {
      ...omit(attributes, 'checklistQuestions')
    };
  }

  @action.bound parseChecklistQuestions(attributes) {
    if (this.checklistQuestions) {
      this.checklistQuestions.reset(attributes.checklistQuestions);
    } else {
      this.checklistQuestions = new ChecklistQuestions(
        attributes.checklistQuestions,
        {
          parent: this,
          rootStore: this.rootStore
        }
      );
    }
  }

  @computed get formValues() {
    return {
      uuid: this.uuid,
      position: this.position,
      title: this.title,
      description: this.description,
      checklistQuestions: orderBy(
        this.checklistQuestions.models,
        'position',
        'asc'
      ).map(checklistQuestion => {
        return checklistQuestion.formValues;
      })
    };
  }

  @computed get relatedChecklist() {
    return this.collection.parent;
  }
}
