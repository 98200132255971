import UIStore from './UIStore';
import ProjectLocations from 'stores/collections/ProjectLocations';
import { action, observable, computed } from 'mobx';
import alertErrorHandler from 'utils/alertErrorHandler';

import { t } from 'utils/translate';

export default class ProjectLocationSelectorUI extends UIStore {
  @observable searchQuery;
  @observable loading;

  constructor(options) {
    super(options);
    this.loading = false;
    this.searchQuery = '';
    this.projectLocations = new ProjectLocations(null, {
      rootStore: this.rootStore
    });
  }

  @action.bound
  async setup(id) {
    await this.fetchProjectLocations(id);
  }

  @action.bound
  tearDown() {
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.projectLocations.reset();
    this.loading = false;
    this.searchQuery = '';
  }

  @computed get options() {
    return [...this.getOptions(this.projectLocations.models)].filter(option =>
      option.name.toLowerCase().includes(this.searchQuery.toLowerCase())
    );
  }

  @computed get noOptionsText() {
    return this.loading
      ? t('Searching')
      : !this.projectLocations.hasModels
      ? t('No results')
      : false;
  }

  @action.bound
  setSearchQuery(query) {
    this.searchQuery = query;
  }

  @action.bound
  clearSearchQuery() {
    this.searchQuery = '';
  }

  @action.bound
  async fetchProjectLocations(id) {
    try {
      await this.projectLocations.fetch({
        url: `/ra/companies/${this.rootStore.me.company.uuid}/projects/${id}/locations`,
        params: {
          limit: 1000,
          mc: 'full',
          mn: 'full'
        }
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  getOptions = (projectLocationItems, optionsArray = []) => {
    for (const item of projectLocationItems) {
      optionsArray.push({
        name: item.fullPath,
        uuid: item.uuid
      });
      item.levels ? this.getOptions(item.levels, optionsArray) : null;
    }
    return optionsArray;
  };
}
