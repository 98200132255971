import request from 'axios';
import { action, computed, when, observable } from 'mobx';
import ProjectChildUI from './ProjectChildUI';
import history from 'utils/history';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';

import {
  EmailReportForm,
  emailReportFormOptions,
  emailReportFormFields,
  emailReportFormRules,
  emailReportFormLabels,
  emailReportFormPlugins
} from 'forms/emailReport';

export default class EmailUI extends ProjectChildUI {
  @observable emailForm;

  constructor(options) {
    super(options);
    this.emailForm = null;
  }

  @action.bound setup() {
    when(
      () => !this.parent.fetchingReportSummary,
      () => {
        if (!this.enableEmailRoute) {
          this.cancelSignReportModal();
        } else {
          this.emailForm = new EmailReportForm(
            {
              fields: emailReportFormFields,
              rules: emailReportFormRules,
              labels: emailReportFormLabels,
              values: {
                subject: this.defaultSubject,
                message: this.defaultMessage,
                isSuperDaily: false
              }
            },
            {
              options: emailReportFormOptions,
              plugins: emailReportFormPlugins
            }
          );
        }
      }
    );
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.emailForm = null;
  }

  @computed get defaultSubject() {
    return `${t('Daily Report')}: ${this.project.name} ${this.date}`;
  }

  @computed get defaultMessage() {
    return `${t('Daily Report')}: ${this.project.name} ${this.date}`;
  }

  @action.bound
  submitEmailForm(event) {
    event.preventDefault();

    this.emailForm.submit({
      onSuccess: this.submitEmailFormSuccess,
      onError: this.submitEmailFormError
    });
  }

  @action.bound
  async submitEmailFormSuccess() {
    this.saving = true;

    const values = Object.assign(this.emailForm.values(), {
      segmentUuid: this.segmentUuid
    });

    try {
      await request.post(
        `/ra/projects/${this.project.id}/reports/${this.date}/send`,
        values
      );

      this.cancelEmailReportModal();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Report sent')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  submitEmailFormError() {
    console.log(this.emailForm.errors());
  }

  @action.bound async showEmailReportModal() {
    await this.authorization.checkFeatureAccess('EmailReport');

    const pathname = history.location.pathname;

    history.push({
      pathname: pathname,
      search: `${this.baseQueryParams}&action=email`
    });
  }

  @action.bound async cancelEmailReportModal() {
    const pathname = history.location.pathname;

    history.push({
      pathname: pathname,
      search: this.baseQueryParams
    });
  }
}
