import { Model } from 'mobx-mc';

class Device extends Model {
  get restAttributes() {
    return ['type', 'version', 'deviceId', 'userAgents'];
  }

  get restAttributeDefaults() {
    return {
      device: '',
      version: '',
      deviceId: '',
      userAgents: []
    };
  }
}

export default Device;
