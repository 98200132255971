import UIStore from 'stores/ui/UIStore';
import { action, computed, observable } from 'mobx';

import history from 'utils/history';

import ProjectTemplateInfoUI from './ProjectTemplateInfoUI';
import ProjectTemplateGeneralUI from './ProjectTemplateGeneralUI';
import ProjectTemplateDailyReportUI from './ProjectTemplateDailyReportUI';
import ProjectTemplateSurveyUI from './ProjectTemplateSurveyUI';
import ProjectTemplateRecipientsUI from './ProjectTemplateRecipientsUI';
import ProjectTemplatePayrollAndTimeUI from './ProjectTemplatePayrollAndTimeUI';
import ProjectTemplateWatermarkUI from './ProjectTemplateWatermarkUI';

import { t } from 'utils/translate';

import ProjectTemplate from 'stores/models/ProjectTemplate';

export default class ProjectTemplateEditUI extends UIStore {
  @observable saving;
  @observable loading;
  @observable selectedTemplate;
  constructor(options) {
    super(options);

    this.projectTemplateInfoUI = new ProjectTemplateInfoUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.projectTemplateGeneralUI = new ProjectTemplateGeneralUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.projectTemplateDailyReportUI = new ProjectTemplateDailyReportUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.projectTemplateSurveyUI = new ProjectTemplateSurveyUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.projectTemplateRecipientsUI = new ProjectTemplateRecipientsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.projectTemplatePayrollAndTimeUI = new ProjectTemplatePayrollAndTimeUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.projectTemplateWatermarkUI = new ProjectTemplateWatermarkUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.saving = false;
    this.loading = true;

    this.selectedTemplate = null;
  }

  @computed get projectTemplates() {
    return this.parent.projectTemplates;
  }

  @action.bound async setup(match) {
    if (
      this.selectedTemplate &&
      String(this.selectedTemplate.id) === match.params.id
    )
      return;

    this.loading = true;

    // If we have a template id in the url segment we need to find and fetch it to allow deep links
    this.selectedTemplate = new ProjectTemplate(
      {
        id: match.params.id
      },
      {
        rootStore: this.rootStore
      }
    );

    await this.selectedTemplate.fetch();

    this.loading = false;
  }

  @action.bound tearDown() {
    this.selectedTemplate = null;
    this.loading = true;
  }

  @action.bound
  goBack() {
    this.parent.fetchTemplates();
    history.push(`/company-settings/project-templates`);
  }

  @computed get sideNavLinks() {
    return [
      {
        path: `${this.selectedTemplate.viewUrl}/info`,
        label: t('Info')
      },
      {
        path: `${this.selectedTemplate.viewUrl}/general`,
        label: t('General')
      },
      {
        path: `${this.selectedTemplate.viewUrl}/daily-report`,
        label: t('Daily report')
      },
      {
        path: `${this.selectedTemplate.viewUrl}/survey-questions`,
        label: t('Survey questions')
      },
      {
        path: `${this.selectedTemplate.viewUrl}/recipients`,
        label: t('Daily report email recipients')
      },
      {
        path: `${this.selectedTemplate.viewUrl}/payroll-and-time`,
        label: t('Payroll and time')
      },
      ...(this.featureFlags.FF_WATERMARKING_SETTINGS
        ? [
            {
              path: `${this.selectedTemplate.viewUrl}/watermark`,
              label: t('Watermark')
            }
          ]
        : [])
    ];
  }
}
