import { action, computed, observable, toJS } from 'mobx';
import SettingsChildUI from './SettingsChildUI';

import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import history from 'utils/history';

import {
  BudgetForm,
  budgetFormOptions,
  budgetFormFields,
  budgetFormRules,
  budgetFormPlugins,
  budgetFormLabels
} from 'forms/project/budget';

export default class SettingsBudgetAddUI extends SettingsChildUI {
  @observable saving;
  @observable costCode;
  @observable entryEditForm;

  constructor(options) {
    super(options);
    this.entryEditForm = null;
    this.costCode = null;
    this.saving = false;
  }

  @action.bound setup(id) {
    this.costCodeSelectorUI.setup({
      includeMaterials: true
    });
    this.materialSelectorUI.setup();
    this.setupForm(id);
  }

  @action.bound setupForm(id) {
    this.costCode = this.parent.budgets.get(id);

    const projectValues = this.costCode.getProjectByUuid(this.project.uuid);

    this.entryEditForm = new BudgetForm(
      {
        fields: budgetFormFields,
        rules: budgetFormRules,
        labels: budgetFormLabels,
        values: {
          costCode: {
            uuid: this.costCode.uuid,
            division: this.costCode.division,
            code: this.costCode.code
          },
          budgetedHours: projectValues.budgetedHours,
          materialQuantities: projectValues.materials.map(materialQuantity => {
            return {
              material: materialQuantity.material,
              quantity: materialQuantity.quantity
            };
          })
        }
      },
      {
        options: budgetFormOptions,
        plugins: budgetFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound tearDown() {
    this.clearUIState();
    this.costCodeSelectorUI.tearDown();
    this.materialSelectorUI.tearDown();
  }

  @action.bound submitEntryEditForm(event) {
    event.preventDefault();
    event.stopPropagation();

    this.entryEditForm.submit({
      onSuccess: this.submitEntryEditFormSuccess,
      onError: this.submitEntryEditFormError
    });
  }

  @action.bound async submitEntryEditFormSuccess() {
    this.saving = true;

    try {
      const values = this.entryEditForm.values();

      const { budgetedHours, materialQuantities } = values;

      await this.updateCostCode(budgetedHours, materialQuantities);

      this.parent.refetchBudgets();
      this.cancelBudgetEdit();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Budget updated')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound async updateCostCode(budgetedHours, materialQuantities, type) {
    const project = this.costCode.getProjectByUuid(this.project.uuid);

    if (project) {
      project.budgetedHours = budgetedHours;
      project.materials = materialQuantities.map(materialQuantity => {
        return {
          material: {
            uuid: materialQuantity.material.uuid
          },
          quantity: materialQuantity.quantity
        };
      });
    }

    return await this.costCode.save(
      {
        projects: toJS(this.costCode.projects)
      },
      {
        url: `${this.rootStore.urlMicroService(
          'performanceTracking'
        )}/companies/${this.company.uuid}/costcodes/${
          this.costCode.uuid
        }/budgets`
      }
    );
  }

  @action.bound submitEntryEditFormError() {
    console.error(this.entryEditForm.errors());
  }

  @action.bound cancelBudgetEdit() {
    history.push({
      pathname: `${this.project.viewUrl}/settings/production-tracking/budgets`,
      search: this.baseQueryParams
    });
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.entryEditForm = null;
    this.saving = false;
    this.costCode = null;
  }

  @computed get disableCancelButton() {
    return this.saving;
  }

  @computed get disableSaveButton() {
    return Boolean(this.saving || this.budgetExistsOnProject);
  }

  @computed get saveButtonText() {
    if (this.saving) {
      return t('Saving...');
    }

    return t('Save');
  }

  @computed
  get existingBudget() {
    return this.parent.budgets.models.find(
      budget => budget.uuid === this.entryEditForm.$('costCode.uuid').value
    );
  }

  @computed
  get budgetExistsOnProject() {
    return (
      this.existingBudget && this.existingBudget.uuid !== this.costCode.uuid
    );
  }

  @computed
  get budgetExistsOnProjectText() {
    return t(
      'A budget for the cost code {budget} already exists for this project.',
      {
        templateStrings: {
          budget: this.existingBudget.costCodeKey
        }
      }
    );
  }

  @computed get materialFormIsValid() {
    return (
      this.entryEditForm.$('material.uuid').check('isValid') &&
      this.entryEditForm.$('quantity').check('isValid')
    );
  }

  @action.bound submitMaterialForm() {
    const materialQuantity = {
      material: this.entryEditForm.$('material').values(),
      quantity: this.entryEditForm.$('quantity').value
    };

    this.entryEditForm.$('materialQuantities').add(materialQuantity);

    this.entryEditForm.$('material').clear();
    this.entryEditForm.$('quantity').clear();
  }

  @computed get filteredMaterialOptions() {
    return this.materialSelectorUI.options.filter(option => {
      return !this.entryEditForm
        .$('materialQuantities')
        .values()
        .find(
          materialQuantity => materialQuantity.material.uuid === option.uuid
        );
    });
  }

  @action.bound removeMaterialQuantity(materialQuantity) {
    this.entryEditForm.$('materialQuantities').del(materialQuantity.key);
  }

  @computed get hasMaterialQuantities() {
    return this.entryEditForm.$('materialQuantities').size > 0;
  }

  @action.bound setCostCode(option) {
    this.costCode = option;

    this.entryEditForm.$('costCode').set(
      option || {
        uuid: '',
        code: '',
        division: ''
      }
    );
  }
}
