import { computed } from 'mobx';
import CompanySettingsUI from 'stores/ui/CompanySettingsUI';

export default class SuperAdminCompanySettingsUI extends CompanySettingsUI {
  @computed
  get companyPermissions() {
    return this.permissions;
  }

  @computed
  get activeCompany() {
    return this.parent.activeCompany;
  }
}
