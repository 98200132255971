import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';

const integrationConfigurationFormPlugins = extendedPlugins;

const integrationConfigurationFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const integrationConfigurationFormFields = ['embeddedScript'];

const integrationConfigurationFormValues = {
  embeddedScript: ''
};

class IntegrationConfigurationForm extends MobxReactForm {}

class DynamicConfigurationForm extends MobxReactForm {}

export {
  IntegrationConfigurationForm,
  integrationConfigurationFormFields,
  integrationConfigurationFormValues,
  integrationConfigurationFormOptions,
  integrationConfigurationFormPlugins,
  DynamicConfigurationForm
};
