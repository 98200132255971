import { action, computed, reaction } from 'mobx';
import InsightsUIModel from './InsightsUIModel';

import theme from 'themes/main';
import { t } from 'utils/translate';

export default class InsightsObservationsRaisedByType extends InsightsUIModel {
  constructor(attributes, options) {
    super(attributes, options);

    // React to selection changes
    this.reactToSelection = reaction(
      () => this.params,
      params => {
        if (params) {
          this.fetchStats();
        } else {
          this.cancelRequest();
          this.insightsUI.clear();
        }
      },
      { fireImmediately: true }
    );

    this.typeClasses = options.typeClasses;
  }

  @computed
  get params() {
    const params = {
      startDay: this.insightsUI.startDay,
      endDay: this.insightsUI.endDay,
      typeClasses: this.typeClasses
    };

    switch (this.insightsUI.projectSelection) {
      case 'ALL':
        params.projectStatuses = 'ACTIVE,INACTIVE';
        break;
      case 'ACTIVE':
        params.projectStatuses = 'ACTIVE';
        break;
      default:
        params.projectTeamUuids = this.insightsUI.filteredProjectTeamUuids.join(
          ','
        );
        break;
    }

    return params;
  }

  url() {
    return 'ra/insights/observations/issuesRaisedByType';
  }

  get restAttributes() {
    return ['insights'];
  }

  @action.bound
  async fetchStats() {
    try {
      await this.fetch({
        params: this.params
      });
    } catch (e) {
      this.set(
        {
          positiveCount: 0,
          negativeCount: 0
        },
        {
          reset: true
        }
      );
    }
  }

  @computed
  get hasStats() {
    return this.insights?.length > 0;
  }

  @computed
  get chartSeries() {
    return this.insights?.map(insight => {
      return {
        name: insight.typeName,
        y: insight.count,
        drilldown: insight.typeName
      };
    });
  }

  @computed
  get chartDrillDownSeries() {
    return this.insights?.map(insight => {
      return {
        name: insight.typeName,
        id: insight.typeName,
        data: insight.details?.map(detail => {
          return [detail.subTypeName, detail.count];
        })
      };
    });
  }

  @computed
  get chartConfig() {
    return {
      chart: {
        type: 'column'
      },
      title: {
        text: ''
      },
      accessibility: {
        announceNewData: {
          enabled: true
        }
      },
      xAxis: {
        type: 'category'
      },
      yAxis: {
        title: {
          text: t('Total')
        },
        labels: {
          formatter: function() {
            if (Math.round(this.value) !== this.value) {
              return;
            }
            return Math.abs(this.value);
          }
        }
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y:.0f}'
          }
        },
        column: {
          stacking: 'normal'
        }
      },
      tooltip: {
        backgroundColor: '#fff',
        borderWidth: 1,
        borderRadius: 10,
        borderColor: theme.colors.lightGrey3,
        padding: 10,
        shadow: false,
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.0f}</b><br/>'
      },

      series: [
        {
          name: 'Issue Type',
          colorByPoint: true,
          data: this.chartSeries
        }
      ],
      drilldown: {
        breadcrumbs: {
          position: {
            align: 'right'
          }
        },
        series: this.chartDrillDownSeries
      }
    };
  }
}
