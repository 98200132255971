import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';
import { t } from 'utils/translate';

const settingsRadiusFormPlugins = extendedPlugins;

const settingsRadiusFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const settingsRadiusFormFields = ['projectRadius'];

const settingsRadiusFormRules = {
  projectRadius: 'integer'
};

const settingsRadiusFormValues = {
  projectRadius: ''
};

const settingsRadiusFormLabels = {
  projectRadius: t('project radius')
};

class SettingsRadiusForm extends MobxReactForm {}

export {
  SettingsRadiusForm,
  settingsRadiusFormOptions,
  settingsRadiusFormFields,
  settingsRadiusFormRules,
  settingsRadiusFormValues,
  settingsRadiusFormLabels,
  settingsRadiusFormPlugins
};
