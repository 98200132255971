import { RakenCollection } from '../lib';
import EquipmentReport from '../models/EquipmentReport';

export default class EquipmentReports extends RakenCollection {
  model() {
    return EquipmentReport;
  }

  url() {
    return `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/equipment/reports/scheduled`;
  }
}
