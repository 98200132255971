import debounce from 'lodash.debounce';
import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import { observable, action, computed, reaction } from 'mobx';
import { BASE_DEBOUNCE } from 'fixtures/constants';

import UIStore from 'stores/ui/UIStore';
import ProjectsMinimal from 'stores/collections/ProjectsMinimal';

import { t } from 'utils/translate';

import alertErrorHandler from 'utils/alertErrorHandler';

export default class GroupProjectsUI extends UIStore {
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable deleting;
  @observable loading;

  @observable showSelectionExpandedAlert;
  @observable selectionExpanded;

  constructor(options) {
    super(options);

    this.loading = true;

    this.paginatedProjects = new ProjectsMinimal(null, {
      parent: this,
      rootStore: this.rootStore
    });

    // Bulk select all active projects
    this.showSelectionExpandedAlert = false;
    this.selectionExpanded = false;

    this.selectedProjects = observable([]);

    this.selectedMembershipFilterOptions = observable([]);

    this.selectedProjectStateFilterOptions = observable([
      { id: 'ACTIVE', title: 'Active' }
    ]);

    this.searchQuery = '';
    this.pageSize = 10;
    this.page = 1;

    this.fetchProjectsDebounced = debounce(this.fetchProjects, BASE_DEBOUNCE);
  }

  @computed get entryForEdit() {
    return this.parent.entryForEdit;
  }

  @computed get groupUuid() {
    return this.entryForEdit.uuid;
  }

  @computed get projects() {
    return this.paginatedProjects;
  }

  @computed get hasProjects() {
    return this.projects.hasModels;
  }

  @action.bound setup() {
    this.setSelectedProjects();
    this.setupReactions();
    this.fetchProjects();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        this.fetchProjects();
      }
    );

    this.reactToSearchQuery = reaction(
      () => this.searchQuery,
      params => {
        this.fetchProjectsDebounced();
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
    this.reactToSearchQuery && this.reactToSearchQuery();
  }

  @action.bound
  async fetchProjects() {
    this.loading = true;

    await this.projects.fetch({
      params: pickBy(
        Object.assign(this.params, {
          query: this.searchQuery
        }),
        identity
      )
    });

    this.loading = false;
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    this.clearSelectionExpanded();
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @computed get params() {
    const params = {
      limit: this.pageSize,
      sortField: 'name',
      sortDirection: 'asc',
      offset: (this.page - 1) * this.pageSize,
      projectStates: this.selectedProjectStateFilterOptions
        .map(option => option.id)
        .join(',')
    };

    if (
      this.selectedMembershipFilterOptions.length === 1 &&
      this.selectedMembershipFilterOptions[0].id === 'assigned'
    ) {
      params.groupUuids = [this.groupUuid];
    }

    if (
      this.selectedMembershipFilterOptions.length === 1 &&
      this.selectedMembershipFilterOptions[0].id === 'unassigned'
    ) {
      params.excludeGroupUuids = [this.groupUuid];
    }

    return params;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.projects.totalElements / this.pageSize);
  }

  @action.bound setSelectedProjects() {
    this.selectedProjects.replace(this.entryForEdit.projectUuids.slice());
  }

  @computed get hasSelectedProjects() {
    return this.selectedProjects.length > 0;
  }

  @action.bound
  async toggleSelectProject(projectUuid) {
    await this.authorization.checkFeatureAccess('CUDGroups');

    this.clearSelectionExpanded();

    if (this.selectedProjects.find(uuid => uuid === projectUuid)) {
      this.selectedProjects.remove(projectUuid);
    } else {
      this.selectedProjects.push(projectUuid);
    }
  }

  @computed
  get allProjectsSelected() {
    return (
      this.hasProjects &&
      this.projects.models.every(project =>
        this.selectedProjects.includes(project.uuid)
      )
    );
  }

  @action.bound
  async toggleSelectAllProjects() {
    await this.authorization.checkFeatureAccess('CUDGroups');

    if (this.allProjectsSelected) {
      this.clearSelectionExpanded();

      this.selectedProjects.replace(
        this.selectedProjects.filter(uuid => {
          return !this.projects.models
            .map(project => project.uuid)
            .includes(uuid);
        })
      );
    } else {
      if (this.page === 1) {
        this.showSelectionExpandedAlert = true;
      }

      this.projects.models.forEach(project => {
        if (!this.selectedProjects.includes(project.uuid)) {
          this.selectedProjects.push(project.uuid);
        }
      });
    }
  }

  @computed get projectsNotOnPage() {
    return this.paginatedProjects.totalElements - this.paginatedProjects.length;
  }

  @action.bound expandSelection() {
    this.selectionExpanded = true;
  }

  @action.bound clearSelectionExpanded() {
    this.showSelectionExpandedAlert = false;
    this.selectionExpanded = false;
  }

  @computed get selectionExpandedAlertText() {
    if (this.selectionExpanded) {
      return t(
        `{projectsCount} projects are selected. Would you like to clear your selection?`,
        {
          templateStrings: {
            projectsCount: this.paginatedProjects.totalElements
          }
        }
      );
    }

    return t(
      `All {projectsOnPage} projects on this page are selected. Would you like to expand the selection to include {projectsNotOnPage} projects not shown?`,
      {
        templateStrings: {
          projectsOnPage: this.paginatedProjects.length,
          ProjectsNotOnPage: this.projectsNotOnPage
        }
      }
    );
  }

  @action.bound clearAllSelectedProjects() {
    this.clearSelectionExpanded();
    this.selectedProjects.clear();
  }

  @computed get disableCancelButton() {
    return this.saving || !this.authorization.canCUDGroups;
  }

  @computed get disableSaveButton() {
    return this.saving || !this.authorization.canCUDGroups;
  }

  @action.bound async saveProjects() {
    if (this.saving) return;

    this.clearValidationDetails();

    this.saving = true;

    let payload;

    if (this.selectionExpanded) {
      payload = {
        addToProjectStates: this.hasSelectedProjectStateFilterOptions
          ? this.selectedProjectStateFilterOptions.map(option => option.id)
          : this.projectStateFilterOptions.map(option => option.id)
      };
    } else {
      payload = {
        projectUuids: this.selectedProjects.slice()
      };
    }

    try {
      await this.entryForEdit.save(payload, {
        wait: true,
        stripNonRest: !this.selectionExpanded
      });

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Group projects saved')
      });

      this.refetchAfterSave();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound async refetchAfterSave() {
    this.page = 1;
    this.loading = true;
    this.clearValidationDetails();
    this.searchQuery = '';
    this.selectedProjects.clear();
    this.projects.clear();
    this.clearSelectionExpanded();

    await this.fetchProjects();
    this.setSelectedProjects();
  }

  @action.bound cancelProjects() {
    this.setSelectedProjects();
    this.clearSearchQuery();
    this.clearSelectionExpanded();
  }

  @action.bound clearUIState() {
    this.page = 1;
    this.loading = true;
    this.clearValidationDetails();
    this.searchQuery = '';
    this.selectedProjects.clear();
    this.selectedMembershipFilterOptions.clear();
    this.selectedProjectStateFilterOptions.replace([
      { id: 'ACTIVE', title: 'Active' }
    ]);
    this.projects.clear();
    this.clearSelectionExpanded();
  }

  @computed get membershipFilterOptions() {
    return [
      { id: 'assigned', title: 'Assigned' },
      { id: 'unassigned', title: 'Unassigned' }
    ];
  }

  @action.bound selectMembershipFilterOptions(options) {
    this.selectedMembershipFilterOptions.replace(options);
    this.clearSelectionExpanded();
    this.page = 1;
  }

  @computed get projectStateFilterOptions() {
    return [
      { id: 'ACTIVE', title: 'Active' },
      { id: 'INACTIVE', title: 'Inactive' },
      { id: 'DELETED', title: 'Deleted' }
    ];
  }

  @action.bound selectProjectStateFilterOptions(options) {
    this.selectedProjectStateFilterOptions.replace(options);
    this.clearSelectionExpanded();
    this.page = 1;
  }

  @computed get hasSelectedProjectStateFilterOptions() {
    return this.selectedProjectStateFilterOptions.length > 0;
  }
}
