import moment from 'moment';
import request from 'axios';
import orderBy from 'lodash.orderby';
import { action, computed, reaction, observable } from 'mobx';
import InsightsUIModel from './InsightsUIModel';
import { t } from 'utils/translate';

import main from 'themes/main';

export default class ProjectInsightsChecklistFailedResponses extends InsightsUIModel {
  constructor(attributes, options) {
    super(attributes, options);

    this.typeClasses = options.typeClasses;

    this.checklistNamesByType = observable([]);
    this.checklistNames = observable([]);

    this.fetchChecklistNamesByType();

    // React to selection changes
    this.reactToSelection = reaction(
      () => this.params,
      params => {
        if (params.projectUuids || params.projectStatuses) {
          this.fetchStats();
        } else {
          this.cancelRequest();
          this.data.clear();
        }
      },
      { fireImmediately: true }
    );
  }

  url() {
    return `ra/companies/${this.rootStore.me.company.uuid}/insights/checklists/failed-responses`;
  }

  get restAttributes() {
    return ['data', 'type'];
  }

  get restAttributeDefaults() {
    return {
      data: []
    };
  }

  @computed
  get params() {
    return {
      projectUuids: this.parent.projectUuid,
      startDay: moment(this.parent.startDay)
        .startOf('day')
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss'),
      endDay: moment(this.parent.endDay)
        .endOf('day')
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss'),
      typeClasses: this.typeClasses,
      checklistNames: this.checklistNames.map(name => name.name).join(',')
    };
  }

  @action.bound
  async fetchStats() {
    await this.fetch({
      params: this.params
    });
  }

  @computed get hasStats() {
    return this.data?.length > 0;
  }

  @computed get chartConfig() {
    return {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        height: 350
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.y:.0f}</b>'
      },
      legend: {
        itemStyle: {
          fontSize: '13px',
          fontFamily: main.fonts.primary
        }
      },
      accessibility: {
        point: {
          valueSuffix: ''
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            distance: -30,
            format: '{point.y}'
          },
          showInLegend: true
        }
      },
      yAxis: {
        labels: {
          style: {
            fontSize: '10px',
            fontFamily: main.fonts.primary
          }
        }
      },
      xAxis: {
        labels: {
          style: {
            fontSize: '10px',
            fontFamily: main.fonts.primary
          }
        }
      },
      series: [
        {
          name: t('"No" Answers'),
          colorByPoint: true,
          data: orderBy(
            this.data.map((response, index) => {
              if (index === 0) {
                return {
                  name: response.title,
                  y: response.count,
                  sliced: true,
                  selected: true
                };
              }

              return {
                name: response.title,
                y: response.count
              };
            }),
            'count',
            'desc'
          )
        }
      ]
    };
  }

  @action.bound async fetchChecklistNamesByType() {
    try {
      const response = await request.get(
        `${this.rootStore.urlMicroService('toolbox')}/companies/${
          this.rootStore.me.company.uuid
        }/checklists/namesGroupedByType`,
        {
          params: {
            limit: 10000,
            statuses: 'ACTIVE',
            typeClasses: this.typeClasses
          }
        }
      );

      this.checklistNamesByType.replace(response.data.collection);
    } catch (error) {
      console.error(this.error);
    } finally {
      this.loading = false;
    }
  }

  @computed get checklistNameOptions() {
    return this.checklistNamesByType.slice().flatMap(item => {
      return item.checklists.map(checklist => {
        const name = checklist;
        const type = item.type;
        return {
          name,
          type
        };
      });
    });
  }

  @action.bound setChecklistNames(options) {
    this.checklistNames.replace(options);
  }
}
