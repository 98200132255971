import React from 'react';
import * as RakenUI from 'raken-ui';
import taggedTemplate from './taggedTemplate';

// NOTE: This no longer does any mapping to locale files, however,
// it still handles dynamic template strings
class Translate {
  t = (value, options) => {
    if (typeof value !== 'string') return value;

    // string with variables
    if (options && options.templateStrings) {
      return this.parseHtml(
        taggedTemplate(value, options.templateStrings),
        options
      );
    }

    return this.parseHtml(value, options);
  };

  parseHtml = (string, options) => {
    if (options && options.hasHtml) {
      const parser = new DOMParser();
      const content = parser
        .parseFromString(string, 'text/html')
        .querySelector('body');

      const rakenUI = {};

      for (let key in RakenUI) {
        rakenUI[key.toLowerCase()] = RakenUI[key];
      }

      const createComponents = function(childNodes) {
        const reactElements = [];

        childNodes.forEach((child, i) => {
          const childNodes = child.childNodes;
          const attributes = {};

          for (let key in child.attributes) {
            if (child.attributes.hasOwnProperty(key)) {
              const attribute = child.attributes[key];

              attributes[attribute.name] = attribute.value
                ? attribute.value
                : true;
            }
          }

          if (child.nodeType === 3) {
            // just text

            reactElements.push(child.textContent);
          } else {
            const tagName = child.tagName.toLowerCase();
            const childrenComponent = createComponents(childNodes);

            if (options.elements && options.elements.length) {
              // custom react component (need use property `elements`)

              const component = options.elements.find(
                el => el.displayName.toLowerCase().indexOf(tagName) !== -1
              );

              reactElements.push(
                React.createElement(component, {
                  key: i,
                  children: childrenComponent,
                  ...attributes
                })
              );
            } else if (tagName in rakenUI) {
              // rakenUI react component

              reactElements.push(
                React.createElement(rakenUI[tagName], {
                  key: i,
                  children: childrenComponent,
                  ...attributes
                })
              );
            } else {
              reactElements.push(
                // html tags

                React.createElement(tagName, {
                  key: i,
                  children: childrenComponent,
                  ...attributes
                })
              );
            }
          }
        });

        return reactElements;
      };

      return createComponents(content.childNodes);
    } else {
      return string;
    }
  };
}

const translate = new Translate();

const { t } = translate;

export { t };
