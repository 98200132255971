import { action, observable, computed } from 'mobx';
import debounce from 'lodash.debounce';

import UIStore from 'stores/ui/UIStore';

import IntegrationMappingUI from './IntegrationMappingUI';
import IntegrationConfigurationUI from './IntegrationConfigurationUI';
import IntegrationQBOProjectMappingUI from './IntegrationQBOProjectMappingUI';
import IntegrationQBOEmployeeMappingUI from './IntegrationQBOEmployeeMappingUI';
import IntegrationSageIntacctProjectMappingUI from './IntegrationSageIntacctProjectMappingUI';

import IntegrationSageIntacctEmployeeMappingUI from './IntegrationSageIntacctEmployeeMappingUI';
import IntegrationSageIntacctPayTypesUI from './IntegrationSageIntacctPayTypesUI';
import IntegrationSageIntacctCostCodesUI from './IntegrationSageIntacctCostCodesUI';
import IntegrationSageIntacctClassificationsUI from './IntegrationSageIntacctClassificationsUI';

import { t } from 'utils/translate';
import { BASE_DEBOUNCE } from 'fixtures/constants';
import errorHandler from 'utils/errorHandler';
import openNewWindow from 'utils/openNewWindow';
import { callTrack } from 'utils/segmentIntegration';
import history from 'utils/history';

import {
  INTEGRATION_CONNECTED,
  INTEGRATION_DISCONNECTED,
  INTEGRATION_ERROR,
  INTEGRATION_REAUTHORIZED,
  INTEGRATION_TOKENACTIVE_UPDATED
} from 'utils/segmentAnalytics/eventSpec';

export default class IntegrationUI extends UIStore {
  @observable activeIntegration;
  @observable fetchingActiveIntegration;
  @observable connectingToIntegration;
  @observable disconnectingIntegration;

  constructor(options) {
    super(options);
    this.activeIntegration = null;
    this.fetchingActiveIntegration = false;
    this.connectingToIntegration = false;

    this.disconnectingIntegration = false;

    this.integrationMappingUI = new IntegrationMappingUI({
      rootStore: this.rootStore,
      parent: this
    });

    this.integrationConfigurationUI = new IntegrationConfigurationUI({
      rootStore: this.rootStore,
      parent: this
    });

    this.integrationQBOProjectMappingUI = new IntegrationQBOProjectMappingUI({
      rootStore: this.rootStore,
      parent: this
    });

    this.integrationQBOEmployeeMappingUI = new IntegrationQBOEmployeeMappingUI({
      rootStore: this.rootStore,
      parent: this
    });

    this.integrationSageIntacctProjectMappingUI = new IntegrationSageIntacctProjectMappingUI(
      {
        rootStore: this.rootStore,
        parent: this
      }
    );

    this.integrationSageIntacctEmployeeMappingUI = new IntegrationSageIntacctEmployeeMappingUI(
      {
        rootStore: this.rootStore,
        parent: this
      }
    );

    this.integrationSageIntacctCostCodesUI = new IntegrationSageIntacctCostCodesUI(
      {
        rootStore: this.rootStore,
        parent: this
      }
    );

    this.integrationSageIntacctPayTypesUI = new IntegrationSageIntacctPayTypesUI(
      {
        rootStore: this.rootStore,
        parent: this
      }
    );

    this.integrationSageIntacctClassificationsUI = new IntegrationSageIntacctClassificationsUI(
      {
        rootStore: this.rootStore,
        parent: this
      }
    );

    this.toggleTokenActive = debounce(this.toggleTokenActive, BASE_DEBOUNCE);
  }

  @computed
  get sideBarLinks() {
    let links;

    links = [
      {
        path: `${this.baseUrl}/${this.activeIntegration.id}`,
        label: t('Overview')
      }
    ];

    if (this.activeIntegration.showConfigurationSettings) {
      links.push({
        path: `${this.baseUrl}/${this.activeIntegration.id}/settings`,
        label: t('Settings')
      });
    }

    if (this.activeIntegration.showSageIntacctMappings) {
      links = links.concat([
        {
          path: `${this.baseUrl}/${this.activeIntegration.id}/classifications`,
          label: t('Classifications')
        },
        {
          path: `${this.baseUrl}/${this.activeIntegration.id}/cost-codes`,
          label: t('Cost codes')
        },
        {
          path: `${this.baseUrl}/${this.activeIntegration.id}/employees`,
          label: t('Employees')
        },
        {
          path: `${this.baseUrl}/${this.activeIntegration.id}/pay-types`,
          label: t('Pay types')
        },
        {
          path: `${this.baseUrl}/${this.activeIntegration.id}/projects`,
          label: t('Projects')
        }
      ]);
    }

    if (
      this.activeIntegration.showQBOMappings ||
      this.activeIntegration.showMappingControls
    ) {
      links.push({
        path: `${this.baseUrl}/${this.activeIntegration.id}/projects`,
        label: t('Projects')
      });
    }

    if (this.activeIntegration.showQBOMappings) {
      links.push({
        path: `${this.baseUrl}/${this.activeIntegration.id}/employees`,
        label: t('Employees')
      });
    }

    if (this.activeIntegration.showSetup) {
      links.push({
        path: `${this.baseUrl}/${this.activeIntegration.id}/setup`,
        label: t('Set up')
      });
    }

    if (this.activeIntegration.showDebugSection) {
      links.push({
        path: `${this.baseUrl}/${this.activeIntegration.id}/debug`,
        label: t('Debug')
      });
    }

    if (this.activeIntegration.showHelp) {
      links.push({
        path: `${this.baseUrl}/${this.activeIntegration.id}/help`,
        label: t('Help')
      });
    }

    return links;
  }

  @computed get integrations() {
    return this.parent.integrations;
  }

  @computed get baseUrl() {
    return this.parent.baseUrl;
  }

  @action.bound setup(id) {
    this.fetchIntegrationById(id);
  }

  @action.bound tearDown() {
    this.clearConnectingToIntegration();
    this.clearActiveIntegration();
    clearInterval(this.popupInterval);
    this.closePopUp();
    this.popup = null;
  }

  @action.bound
  async fetchIntegrationById(id) {
    if (this.fetchingActiveIntegration) return;

    this.fetchingActiveIntegration = true;

    id = parseInt(id, 10);

    try {
      this.activeIntegration = await this.integrations.getOrFetch(id, {
        fetchExisting: true
      });
    } catch (error) {
      history.replace('/company-settings/integrations');
    } finally {
      this.fetchingActiveIntegration = false;
    }
  }

  @action.bound setActiveIntegration(model) {
    this.activeIntegration = model;
  }

  @action.bound clearActiveIntegration() {
    this.activeIntegration = null;
    // Clear any open popups for integration
    this.closePopUp();
    // Clear any unmapped properties for integrations
    this.integrationMappingUI?.clearSelectionForMapping();
  }

  @action.bound
  connectIntegration() {
    if (this.activeIntegration.noAuthenticationRequired) {
      this.enableNoAuthenticationRequired();
    } else {
      this.showPopUp(this.activeIntegration);
    }
  }

  @action.bound
  reauthorizeIntegration() {
    this.connectIntegration();

    callTrack(INTEGRATION_REAUTHORIZED, {
      integration_name: this.activeIntegration.name
    });
  }

  @computed get toggleTokenActiveTitle() {
    if (this.activeIntegration.tokenActive) {
      return t('Pause Integration?');
    }

    return t('Resume integration?');
  }

  @computed get toggleTokenActiveText() {
    if (this.activeIntegration.tokenActive) {
      return t(
        'You are about to pause your connection to {integrationName}. Doing so will stop all data from syncing to or from the connected system. Do you want to proceed?',
        {
          templateStrings: {
            integrationName: this.activeIntegration.name
          }
        }
      );
    }

    return t(
      'You are about to resume your connection to {integrationName}. Doing so immediately start syncing data to and from the connected system. Do you want to proceed?',
      {
        templateStrings: {
          integrationName: this.activeIntegration.name
        }
      }
    );
  }

  @action.bound async toggleTokenActive() {
    await this.authorization.checkFeatureAccess('EditIntegrations');

    this.showModal('IntegrationToggleTokenActiveModal');
  }

  @action.bound async confirmToggleTokenActive() {
    await this.hideActiveModal();

    try {
      callTrack(INTEGRATION_TOKENACTIVE_UPDATED, {
        integration_name: this.activeIntegration.name,
        update_type: !this.activeIntegration.tokenActive
      });

      this.activeIntegration.save({
        tokenActive: !this.activeIntegration.tokenActive
      });
    } catch (error) {
      errorHandler(error, this.rootStore.notificationsUI.pushError);
    }
  }

  @action.bound cancelToggleTokenActive() {
    this.hideActiveModal();
  }

  @action.bound
  async enableNoAuthenticationRequired() {
    try {
      const response = await this.activeIntegration.save(
        {
          service: {
            status: 'CONNECTED'
          }
        },
        { wait: true }
      );

      if (response.service.status === 'CONNECTED') {
        callTrack(INTEGRATION_CONNECTED, {
          integration_name: response.name
        });

        this.notifications.pushNotification({
          snackbar: 'warning',
          icon: 'checkmark',
          title: t('Integration connected')
        });
      }
    } catch (error) {
      const pattern = new RegExp(/^5[0-9][0-9]/);

      // if we get error from server 5xx
      if (pattern.test(error.response?.status)) {
        callTrack(INTEGRATION_ERROR, {
          integration_name: this.selectedIntegration.cloudService.name
        });
      }

      errorHandler(error, this.rootStore.notificationsUI.pushError);
    }
  }

  showPopUp(cloudService) {
    this.closePopUp();

    clearInterval(this.popupInterval);

    this.popup = openNewWindow(cloudService.popupUrl, 'connect', 550, 550);

    this.connectingToIntegration = true;

    this.popupInterval = setInterval(this.checkPopUp, 1000);
  }

  closePopUp() {
    if (this.popup) {
      this.popup.close();
    }
  }

  checkPopUp = () => {
    if (this.popup.closed) {
      clearInterval(this.popupInterval);

      this.updateSelectedIntegration();
    }
  };

  @action.bound
  async updateSelectedIntegration() {
    if (!this.activeIntegration) return;

    try {
      await this.activeIntegration.fetch();
      this.connectingToIntegration = false;

      if (this.activeIntegration.isConnected) {
        callTrack(INTEGRATION_CONNECTED, {
          integration_name: this.activeIntegration.name
        });

        this.notifications.pushNotification({
          snackbar: 'warning',
          icon: 'checkmark',
          title: t('Integration connected')
        });
      }
    } catch (error) {
      const pattern = new RegExp(/^5[0-9][0-9]/);
      // if we get error from server 5xx
      if (pattern.test(error.response.status)) {
        callTrack(INTEGRATION_ERROR, {
          integration_name: this.activeIntegration.name
        });
      }
      errorHandler(error, this.notifications.pushError);
    }
  }

  @action.bound
  clearConnectingToIntegration() {
    this.connectingToIntegration = false;
  }

  @action.bound
  showDisconnectModal() {
    this.showModal('integrationDisconnect');
  }

  @action.bound
  hideDisconnectModal() {
    this.hideActiveModal();
  }

  @action.bound
  async disconnectIntegration() {
    this.disconnectingIntegration = true;

    try {
      await this.activeIntegration.save(
        { service: { status: 'NOT_CONNECTED' } },
        {
          wait: true
        }
      );

      callTrack(INTEGRATION_DISCONNECTED, {
        integration_name: this.activeIntegration.name
      });

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Integration disconnected')
      });

      this.hideDisconnectModal();
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    } finally {
      this.disconnectingIntegration = false;
    }
  }

  @computed get loading() {
    return !this.activeIntegration || this.activeIntegration.fetching;
  }

  @action.bound
  async tryConnectIntegration() {
    if (this.activeIntegration.isQBO) {
      // QBO
      if (this.integrations.length <= 1) {
        await this.integrations.fetch();
      }
      const nonQBO = this.integrations.models.filter(
        m => m.isConnected && m.isRyvit
      );
      // if any accounting integration is connected already
      if (nonQBO.length) {
        await this.showModal('integrationQBONotAllowedModal');
        return;
      }
    }

    this.authorization
      .checkFeatureAccess('EditCloudServices')
      .then(() => this.connectIntegration());
  }

  @action.bound
  openSupport() {
    this.hideActiveModal().then(() => {
      if (window?.Intercom) {
        window.Intercom('show');
      }
    });
  }

  @computed get currentAccounting() {
    const nonQBO = this.integrations.models.filter(
      m => m.isConnected && m.isRyvit
    );
    if (nonQBO.length) {
      return nonQBO[0].name;
    }
    return '-';
  }
}
