import MobxReactForm from 'mobx-react-form';
import extendedPlugins from 'forms/utils/extendedPlugins';

const projectTemplateRecipientsFormPlugins = extendedPlugins;

const projectTemplateRecipientsFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false,
  retrieveOnlyEnabledFields: false
};

const projectTemplateRecipientsFormFields = [
  'sendBySign',
  'emailInterval',
  'superDailyIndex',
  'internalMembersRecipients[]',
  'internalMembersRecipient',
  'includeTimeCardsForExternalMembers',
  'externalMembersRecipients[]',
  'externalMembersRecipient',
  'includeMaterialQuantityForExternalMembers',
  'includeEquipmentForExternalMembers'
];

const projectTemplateRecipientsFormLabels = {};

const projectTemplateRecipientsFormRules = {
  sendBySign: 'boolean',
  emailInterval: 'string',
  superDailyIndex: 'boolean',
  internalMembersRecipients: 'array',
  internalMembersRecipient: 'email',
  includeTimeCardsForExternalMembers: 'boolean',
  externalMembersRecipients: 'array',
  externalMembersRecipient: 'email',
  includeMaterialQuantityForExternalMembers: 'boolean',
  includeEquipmentForExternalMembers: 'boolean'
};

const projectTemplateRecipientsFormValues = {
  sendBySign: true,
  emailInterval: '02:00',
  superDailyIndex: true,
  internalMembersRecipients: [],
  internalMembersRecipient: '',
  includeTimeCardsForExternalMembers: false,
  externalMembersRecipients: [],
  externalMembersRecipient: ''
};

class ProjectTemplateRecipientsForm extends MobxReactForm {}

export {
  ProjectTemplateRecipientsForm,
  projectTemplateRecipientsFormOptions,
  projectTemplateRecipientsFormFields,
  projectTemplateRecipientsFormRules,
  projectTemplateRecipientsFormValues,
  projectTemplateRecipientsFormPlugins,
  projectTemplateRecipientsFormLabels
};
