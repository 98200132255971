import { computed } from 'mobx';
import { Model } from 'mobx-mc';
import { getRoleName } from 'utils/roles';

export default class SuperAdminProjectMember extends Model {
  get restAttributes() {
    return ['isMember', 'canLeave', 'user'];
  }

  get isNew() {
    return false;
  }

  url() {
    return `/ra/sadmin/projects/${this.collection.parent.id}/users/${this.user.uuid}/memberships`;
  }

  @computed get uuid() {
    return this.user.uuid;
  }

  @computed get userId() {
    return this.user.userId;
  }

  @computed get inviteId() {
    return this.user.inviteId;
  }

  @computed get fullName() {
    return `${this.user.firstName} ${this.user.lastName}`;
  }

  @computed get email() {
    return this.user.email || this.user.username;
  }

  @computed get phone() {
    return this.user.phone;
  }

  @computed
  get isTheLastMember() {
    let numberOfMembers = this.collection.models.reduce((number, member) => {
      if (member.isMember) {
        return ++number;
      }
      return number;
    }, 0);

    return numberOfMembers <= 1;
  }

  @computed
  get disableToggleMembership() {
    if (this.isMember && this.isTheLastMember) {
      return true;
    }

    return false;
  }

  @computed
  get avatarImage() {
    return (this.user?.avatar && this.user?.avatar.thumbUrl) || null;
  }

  @computed get initials() {
    return `${this.user?.firstName
      ?.toUpperCase()
      .charAt(0)} ${this.user?.lastName?.toUpperCase().charAt(0)}`;
  }

  @computed
  get roleTranslated() {
    return getRoleName(this.user?.role) || getRoleName('ROLE_USER');
  }
}
