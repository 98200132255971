import { RakenCollection } from '../lib';
import DailyReportActivity from '../models/DailyReportActivity';

export default class ReportActivity extends RakenCollection {
  model() {
    return DailyReportActivity;
  }

  url() {
    return `ra/report-activities`;
  }
}
