import styled, { css } from 'styled-components';
import media from '../utils/mediaTemplate';
import FormRow from './FormRow';
import PillButton from './PillButton';

export default styled.div`
  margin-bottom: ${props => (props.noMargin ? 0 : 25)}px;
  margin-right: ${props => (props.marginRight ? props.marginRight : 0)}px;
  position: relative;
  text-align: inherit;
  width: ${props => (props.width ? props.width : 'auto')};
  ${props =>
    props.preventShrink &&
    css`
      flex-shrink: 0;
    `};
  ${FormRow} > & {
    ${media.desktop`
      ${props =>
        (!props.width || props.width === '100%') &&
        `
        flex: 1;
      `};
      ${props =>
        props.preventShrink &&
        css`
          min-width: 0;
        `};
      margin-right: 25px;
      &:last-child {
        margin-right: 0;
      } 
    `};
  }
  cursor: ${props =>
    props.disabled
      ? 'not-allowed'
      : props.defaultCursor
      ? 'default'
      : 'pointer'};

  ${props =>
    props.withButtons &&
    css`
      align-items: flex-end;
      display: flex;
      justify-content: center;
      cursor: default;

      ${PillButton}:not(:first-child) {
        margin-left: 1rem;
      }
      ${media.desktop`
        justify-content: flex-end;
        ${PillButton}:not(:first-child) {
          margin-left: 2.5rem;
        }
      `};
    `};
`;
