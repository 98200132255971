import debounce from 'lodash.debounce';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import UIStore from './UIStore';

import {
  CompanyCertificationForm,
  companyCertificationFormOptions,
  companyCertificationFormFields,
  companyCertificationFormRules,
  companyCertificationFormValues,
  companyCertificationFormLabels,
  companyCertificationFormPlugins
} from 'forms/companyCertification';

import Certifications from 'stores/collections/Certifications';
import MemberCertifications from 'stores/collections/MemberCertifications';
import TeamMemberCertificationAddUI from './TeamMemberCertificationAddUI';
import TeamMemberCertificationEditUI from './TeamMemberCertificationEditUI';

import { BASE_DEBOUNCE } from 'fixtures/constants';

import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import history from 'utils/history';
import { callTrack } from 'utils/segmentIntegration';
import { MEMBER_CERTIFICATION_DELETED } from 'utils/segmentAnalytics/eventSpec';
import CertificationTypes from 'stores/collections/CertificationTypes';

export default class TeamMemberCertificationsUI extends UIStore {
  @observable searchQuery;
  @observable sortField;
  @observable sortDirection;
  @observable pageSize;
  @observable page;
  @observable loading;
  @observable selectedMemberCertification;

  constructor(options) {
    super(options);

    this.loading = true;
    this.sortField = 'certification.name';
    this.sortDirection = 'asc';
    this.searchQuery = '';
    this.page = 1;
    this.pageSize = 10;

    this.selectedMemberCertification = null;

    this.certifications = new Certifications(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.certificationTypes = new CertificationTypes(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.memberCertifications = new MemberCertifications(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.memberCertificationAddUI = new TeamMemberCertificationAddUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.memberCertificationEditUI = new TeamMemberCertificationEditUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchMemberCertificationsDebounced = debounce(
      this.fetchMemberCertifications,
      BASE_DEBOUNCE
    );
  }

  @action.bound setup() {
    this.setupReactions();
    this.fetchCompanyCertifications();
    this.fetchCompanyCertificationTypes();
    this.fetchMemberCertifications();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchMemberCertificationsDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @action.bound fetchCompanyCertifications() {
    this.certifications.fetch({
      params: {
        limit: 10000,
        sortField: 'name'
      }
    });
  }

  @action.bound fetchCompanyCertificationTypes() {
    this.certificationTypes.fetch({
      params: {
        limit: 10000,
        sortField: 'name'
      }
    });
  }

  @computed
  get params() {
    return {
      query: this.searchQuery,
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      sortField: this.sortField,
      sortDirection: this.sortDirection
    };
  }

  @computed get entryForEdit() {
    return this.parent.entryForEdit;
  }

  @computed
  get hasWriteAccess() {
    return (
      this.me.permissions.CUD_MEMBER_CERTIFICATIONS || this.entryForEdit.isMe
    );
  }

  @action.bound async fetchMemberCertifications() {
    this.memberCertifications.cancelRequest();
    this.memberCertifications.reset();

    try {
      await this.memberCertifications.fetch({
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasMemberCertifications() {
    return this.memberCertifications.hasModels;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.memberCertifications.totalElements / this.pageSize);
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.memberCertifications.clear();
    this.searchQuery = '';
    this.page = 1;
    this.loading = true;
    this.saving = false;
    this.sortField = 'certification.name';
    this.sortDirection = 'asc';
    this.selectedMemberCertification = null;
  }

  getMemberCertificationActions = memberCertification => {
    if (!this.hasWriteAccess) {
      return [
        {
          title: t('View'),
          onClick: () => {
            this.editMemberCertification(memberCertification);
          }
        }
      ];
    }

    return [
      {
        title: t('Edit'),
        onClick: () => {
          this.editMemberCertification(memberCertification);
        }
      },
      {
        title: t('Delete'),
        onClick: () => {
          this.deleteMemberCertification(memberCertification);
        }
      }
    ];
  };

  @action.bound async deleteMemberCertification(memberCertification) {
    if (!this.hasWriteAccess) {
      this.authorization.showModal('featureAccess');
      return;
    }

    this.selectedMemberCertification = memberCertification;
    this.showModal('DeleteModal');
  }

  @action.bound async cancelDeleteMemberCertification() {
    await this.hideActiveModal();

    this.selectedMemberCertification = null;
  }

  @action.bound async confirmDeleteMemberCertification() {
    this.saving = true;

    try {
      await this.selectedMemberCertification.destroy({ wait: true });

      this.refetchMemberCertifications();

      await this.hideActiveModal();

      this.selectedMemberCertification = null;

      callTrack(MEMBER_CERTIFICATION_DELETED);

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Certification deleted')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound sortByLastCreated() {
    this.sortField = 'createdTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchMemberCertificationsDebounced();
  }

  @action.bound refetchMemberCertifications() {
    this.loading = true;

    if (!this.hasMemberCertifications) {
      this.setPage(null, 1);
      this.fetchMemberCertifications();
    } else {
      this.fetchMemberCertifications();
    }
  }

  @action.bound async addMemberCertification() {
    if (!this.hasWriteAccess) {
      this.authorization.showModal('featureAccess');
      return;
    }

    history.push({
      pathname: `${this.entryForEdit.viewUrl}/certifications/add`
    });
  }

  @action.bound async editMemberCertification(memberCertification) {
    history.push({
      pathname: `${this.entryForEdit.viewUrl}/certifications/${memberCertification.uuid}`
    });
  }

  @computed get certificationOptions() {
    return this.certifications.models.map(certification => {
      return {
        uuid: certification.uuid,
        name: certification.name,
        certificationType: certification.certificationType
      };
    });
  }

  @computed get activeMemberForm() {
    return (
      this.memberCertificationAddUI.entryAddForm ||
      this.memberCertificationEditUI.entryEditForm
    );
  }

  @action.bound addCompanyCertification(event) {
    event.stopPropagation();

    this.companyCertificationForm = new CompanyCertificationForm(
      {
        fields: companyCertificationFormFields,
        rules: companyCertificationFormRules,
        values: companyCertificationFormValues,
        labels: companyCertificationFormLabels
      },
      {
        options: companyCertificationFormOptions,
        plugins: companyCertificationFormPlugins
      }
    );

    this.showModal('CompanyCertificationModal');
  }

  @action.bound async cancelCompanyCertificationModal() {
    await this.hideActiveModal();
    this.companyCertificationForm = null;
  }

  @action.bound
  submitCompanyCertificationForm(event) {
    event.preventDefault();

    this.companyCertificationForm.submit({
      onSuccess: this.submitCompanyCertificationFormSuccess,
      onError: this.submitCompanyCertificationFormError
    });
  }

  @action.bound
  async submitCompanyCertificationFormSuccess() {
    const payload = this.companyCertificationForm.values();

    this.saving = true;

    try {
      const newCerfication = await this.certifications.create(payload, {
        unshift: true,
        wait: true
      });

      this.activeMemberForm.$('certification').set({
        uuid: newCerfication.uuid,
        name: newCerfication.name,
        certificationType: newCerfication.certificationType
      });

      this.cancelCompanyCertificationModal();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitCompanyCertificationFormError() {
    console.error(this.companyCertificationForm.errors());
  }

  @computed get showEmptyState() {
    return !this.loading && !this.searchQuery && !this.hasMemberCertifications;
  }

  @computed get showEmptySearchState() {
    return !this.loading && this.searchQuery && !this.hasMemberCertifications;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @computed get certificationTypeOptions() {
    return this.certificationTypes.models.map(certificationType => ({
      name: certificationType.name,
      uuid: certificationType.uuid
    }));
  }
}
