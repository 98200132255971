import styled, { css } from 'styled-components';

export default styled.span`
  color: ${props =>
    props.color ? props.theme.colors[props.color] : 'inherit'};
  cursor: ${props => (props.cursor ? props.cursor : 'inherit')};
  display: ${props =>
    props.block
      ? 'block'
      : props.inlineFlex
      ? 'inline-flex'
      : props.flex
      ? 'flex'
      : props.inlineBlock
      ? 'inline-block'
      : 'inline'};
  vertical-align: ${props =>
    props.verticalAlign ? props.verticalAlign : 'baseline'};
  font-size: ${props => (props.size ? `${props.size / 10}rem` : 'inherit')};
  font-family: ${props => props.theme.fonts[props.fontFamily] || 'inherit'};
  font-weight: ${props =>
    props.bold
      ? 700
      : props.normal
      ? 400
      : props.light
      ? 300
      : props.thin
      ? 200
      : 'inherit'};
  font-style: ${props => (props.italic ? 'italic' : 'inherit')};
  opacity: ${props => props.opacity || 1};
  text-align: ${props => (props.align ? props.align : 'inherit')};
  text-transform: ${props => (props.transform ? props.transform : 'inherit')};
  text-decoration: ${props => (props.underline ? 'underline' : 'inherit')};
  white-space: ${props =>
    props.whiteSpace ? props.whiteSpace : props.noWrap ? 'nowrap' : 'inherit'};
  line-height: ${props => (props.lineHeight ? props.lineHeight : 'inherit')};
  margin-left: ${props => (props.marginLeft ? props.marginLeft / 10 : 0)}rem;
  margin-right: ${props => (props.marginRight ? props.marginRight / 10 : 0)}rem;
  margin-bottom: ${props =>
    props.marginBottom ? props.marginBottom / 10 : 0}rem;
  margin-top: ${props => (props.marginTop ? props.marginTop / 10 : 0)}rem;
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft / 10 : 0)}rem;
  padding-right: ${props =>
    props.paddingRight ? props.paddingRight / 10 : 0}rem;
  padding-bottom: ${props =>
    props.paddingBottom ? props.paddingBottom / 10 : 0}rem;
  padding-top: ${props => (props.paddingTop ? props.paddingTop / 10 : 0)}rem;
  word-break: ${props => (props.wordBreak ? props.wordBreak : 'normal')};
  word-wrap: break-word;

  ${({ ellipsis }) =>
    ellipsis &&
    css`
      text-overflow: ellipsis;
      overflow-x: hidden;
      overflow-wrap: normal;
    `}
`;
