import { computed, action, observable } from 'mobx';
import request from 'axios';
import moment from 'moment';
import UIStore from 'stores/ui/UIStore';
import { t } from 'utils/translate';
import history from 'utils/history';
import {
  MemberCertificationsForm,
  memberCertificationsFormFields,
  memberCertificationsFormOptions,
  memberCertificationsFormPlugins,
  memberCertificationsFormRules
} from 'forms/memberCertifications';
import alertErrorHandler from 'utils/alertErrorHandler';

export default class CertificationMembersBulkAddUI extends UIStore {
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable selectedFilterOptions = {
    statuses: [{ id: 'ACTIVE', title: 'Active' }]
  };
  @observable isAssigned;
  @observable workers;
  @observable memberCertificationsForm;

  constructor(options) {
    super(options);

    this.searchQuery = '';
    this.pageSize = 20;
    this.page = 1;
    this.workers = [];
    this.isAssigned = true;
  }

  @computed get selectedMemberCertifications() {
    return this.parent.selectedMemberCertifications;
  }

  @action.bound setup() {
    this.setupForm();
  }

  @action.bound setupForm() {
    this.memberCertificationsForm = new MemberCertificationsForm(
      {
        fields: memberCertificationsFormFields,
        rules: memberCertificationsFormRules,
        values: {
          memberCertifications: this.selectedMemberCertifications.map(
            ({ uuid, workerUuid, workerName, expirationDate }) => ({
              uuid,
              workerUuid,
              workerName,
              expirationDate
            })
          )
        }
      },
      {
        options: memberCertificationsFormOptions,
        plugins: memberCertificationsFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  @computed get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      status: this.selectedFilterOptionsAsParams.statuses,
      companyUuids: this.company?.uuid,
      withProjectMembershipLookup: 'false'
    };
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed
  get totalPages() {
    return Math.ceil(
      this.memberCertificationsForm?.$('memberCertifications').length /
        this.pageSize
    );
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound
  setSearchQuery(query) {
    this.page = 1;
    this.searchQuery = query;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound submitForm(event) {
    event.preventDefault();

    this.memberCertificationsForm.submit({
      onSuccess: this.submitFormSuccess,
      onError: this.submitFormError
    });
  }

  @action.bound
  submitFormError() {
    console.log(this.form.errors());
  }

  @action.bound
  async submitFormSuccess() {
    this.saving = true;
    const payload = this.memberCertificationsForm
      .values()
      .memberCertifications.map(memberCertification => ({
        memberCertificationUuid: memberCertification.uuid,
        expirationDate: moment(memberCertification.expirationDate).format(
          'YYYY-MM-DD'
        )
      }));
    const url = `ra/companies/${this.company?.uuid}/member-certifications/batch`;

    try {
      await request.patch(url, payload);
      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Certifications updated')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.cancelMemberCertificationAssign();
      this.parent.refetchMemberCertifications();
      this.saving = false;
    }
  }

  @computed get showEmptyState() {
    return !this.hasMembers;
  }

  @computed get showTable() {
    return !this.showEmptyState;
  }

  @computed get selectedFilterOptionsAsParams() {
    return {
      statuses: this.selectedFilterOptions.statuses
        .map(option => option.id)
        .join(',')
    };
  }

  @action.bound clearUIState() {
    this.searchQuery = '';
    this.members.clear();
    this.page = 1;
    this.saving = false;
    this.workers = [];
    this.isAssigned = true;
    this.memberCertificationsForm = null;
  }

  @action.bound cancelMemberCertificationAssign() {
    history.push({
      pathname: `${this.parent.entryForEdit.viewUrl}/employees`
    });
  }

  @computed get hasSelectedMembers() {
    return this.selectedMemberCertifications.length > 0;
  }

  @computed get title() {
    return t('Set certification expiration');
  }
}
