import request from 'axios';
import moment from 'moment';
import { action, observable, computed, reaction, runInAction } from 'mobx';
import ProjectChildUI from './ProjectChildUI';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import history from 'utils/history';

import {
  TransferForm,
  transferFormOptions,
  transferFormFields,
  transferFormRules,
  transferFormLabels,
  transferFormPlugins
} from 'forms/project/transfer';

export default class TransferUI extends ProjectChildUI {
  @observable transferForm;
  @observable projectOption;

  @observable calendarFirstDayOfMonth;
  @observable fetchingFromReports;
  @observable fetchingToReports;

  constructor(options) {
    super(options);

    this.transferForm = null;
    this.projectOption = null;

    this.calendarFirstDayOfMonth = null;
    this.fetchingFromReports = false;
    this.fromReports = observable([]);
    this.fetchingToReports = false;
    this.toReports = observable([]);
  }

  @action.bound setup() {
    this.transferForm = new TransferForm(
      {
        fields: transferFormFields,
        rules: transferFormRules,
        values: {
          fromDate: this.date
        },
        labels: transferFormLabels
      },
      {
        options: transferFormOptions,
        plugins: transferFormPlugins
      }
    );

    this.projectSelectorUI.fetchProjectOptions();
    this.setProjectOption(this.defaultProjectOption);
    this.setupReactions();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
    this.projectSelectorUI.resetProjectOptions();
  }

  @action.bound setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        this.checkReportStatusForDate();
      },
      {
        fireImmediately: true
      }
    );
  }

  @action.bound tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @action.bound setProjectOption(option) {
    this.projectOption = option;
    this.projectSelectorUI.clearProjectOptionsSearchQuery();
  }

  @computed get defaultProjectOption() {
    return {
      value: this.project.uuid,
      name: this.project.name,
      startDate: this.project.startDate,
      endDate: this.project.endDate
    };
  }

  getCalendarReportStatus = report => {
    switch (report.status) {
      case 'COMPLETED':
        return 'signed';
      case 'UNSIGNED':
      case 'CREATED':
        return 'unsigned';
      default:
        return '';
    }
  };

  getCalendarDayStatus = (momentObject, project) => {
    if (momentObject.isSameOrAfter(moment(), 'day')) {
      return '';
    }

    if (momentObject.isBefore(moment(project.startDate), 'day')) {
      return '';
    }

    if (this.project.intervals.includes(momentObject.day() + 1)) {
      return 'missed';
    } else {
      return '';
    }
  };

  @computed get calendarFromDate() {
    return moment(this.calendarFirstDayOfMonth)
      .startOf('month')
      .format('YYYY-MM-DD');
  }

  @computed get calendarToDate() {
    return moment(this.calendarFirstDayOfMonth)
      .endOf('month')
      .format('YYYY-MM-DD');
  }

  @action.bound async handleCalendarFromMonthChange(firstDayOfMonth) {
    this.calendarFirstDayOfMonth = firstDayOfMonth;

    this.fetchingFromReports = true;

    try {
      const response = await request.get(
        `ra/companies/${this.company.uuid}/reports`,
        {
          params: {
            fromDate: this.calendarFromDate,
            toDate: this.calendarToDate,
            segmentUuids: this.segmentUuid,
            projectUuids: this.projectUuid,
            limit: 31
          }
        }
      );

      this.fromReports.replace(
        response.data.collection.map(report => {
          return {
            reportDate: report.reportDate,
            status: report.status
          };
        })
      );
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.fetchingFromReports = false;
    }

    return true;
  }

  @computed get calendarFromMonthReports() {
    const days = [];

    if (this.fetchingFromReports) return days;

    const currentDate = moment(this.calendarFromDate);
    const toDate = moment(this.calendarToDate);

    while (currentDate.isSameOrBefore(toDate)) {
      const relatedReport = this.fromReports.find(
        report => report.reportDate === currentDate.format('YYYY-MM-DD')
      );

      if (relatedReport) {
        days.push({
          date: currentDate.date(),
          status: this.getCalendarReportStatus(relatedReport)
        });
      } else {
        days.push({
          date: currentDate.date(),
          status: this.getCalendarDayStatus(currentDate, this.project)
        });
      }

      currentDate.add(1, 'days');
    }

    return days;
  }

  @action.bound async handleCalendarToMonthChange(firstDayOfMonth) {
    this.calendarFirstDayOfMonth = firstDayOfMonth;

    this.fetchingToReports = true;

    try {
      const response = await request.get(
        `ra/companies/${this.company.uuid}/reports`,
        {
          params: {
            fromDate: this.calendarFromDate,
            toDate: this.calendarToDate,
            projectUuids: this.projectOption.value,
            limit: 31
          }
        }
      );

      this.toReports.replace(
        response.data.collection.map(report => {
          return {
            reportDate: report.reportDate,
            status: report.status
          };
        })
      );
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.fetchingToReports = false;
    }

    return true;
  }

  @computed get calendarToMonthReports() {
    const days = [];

    if (this.fetchingToReports) return days;

    const currentDate = moment(this.calendarFromDate);
    const toDate = moment(this.calendarToDate);

    while (currentDate.isSameOrBefore(toDate)) {
      const relatedReport = this.toReports.find(
        report => report.reportDate === currentDate.format('YYYY-MM-DD')
      );

      if (relatedReport) {
        days.push({
          date: currentDate.date(),
          status: this.getCalendarReportStatus(relatedReport)
        });
      } else {
        days.push({
          date: currentDate.date(),
          status: this.getCalendarDayStatus(currentDate, this.projectOption)
        });
      }

      currentDate.add(1, 'days');
    }

    return days;
  }

  @action.bound async checkReportStatusForDate() {
    this.clearValidationDetails();

    if (!this.params.to.reportDate) return;

    const response = await request.get(
      `ra/companies/${this.company.uuid}/reports`,
      {
        params: {
          fromDate: this.params.to.reportDate,
          toDate: this.params.to.reportDate,
          projectUuids: this.params.to.projectUuid,
          limit: 31
        }
      }
    );

    const report = response.data.collection[0];

    if (!report) {
      this.transferForm.$('toDate').resetValidation();

      return;
    }

    if (report.segmentUuid) {
      this.transferForm
        .$('toDate')
        .invalidate(t('The destination report date has segments enabled.'));

      return;
    }

    switch (report.status) {
      case 'COMPLETED':
        this.transferForm
          .$('toDate')
          .invalidate(t('The destination report date has been completed.'));
        break;
      case 'UNSIGNED':
        this.transferForm
          .$('toDate')
          .invalidate(t('The destination report date is not empty.'));
        break;
      default:
        this.transferForm.$('toDate').resetValidation();
        break;
    }
  }

  @action.bound submitTransferForm(event) {
    event.preventDefault();

    this.transferForm.submit({
      onSuccess: this.submitTransferFormSuccess,
      onError: this.submitTransferFormError
    });
  }

  @computed get params() {
    return {
      from: {
        projectUuid: this.projectUuid,
        reportDate: moment(this.transferForm.$('fromDate').value).format(
          'YYYY-MM-DD'
        )
      },
      to: {
        projectUuid: this.projectOption.value || this.projectUuid,
        reportDate: this.transferForm.$('toDate').value
          ? moment(this.transferForm.$('toDate').value).format('YYYY-MM-DD')
          : null
      }
    };
  }

  @action.bound async submitTransferFormSuccess() {
    this.saving = true;

    const { from, to } = this.params;

    try {
      await request.post(
        `ra/companies/${this.company.uuid}/reports/transfer`,
        this.params
      );

      setTimeout(() => {
        runInAction(() => {
          if (from.projectUuid !== to.projectUuid) {
            this.notifications.pushNotification({
              snackbar: 'warning',
              icon: 'checkmark',
              title: t('Report transferred to {projectName}, {date}', {
                templateStrings: {
                  date: to.reportDate,
                  projectName: this.projectOption.name
                }
              })
            });
          } else {
            this.notifications.pushNotification({
              snackbar: 'warning',
              icon: 'checkmark',
              title: t('Report transferred to {date}', {
                templateStrings: { date: to.reportDate }
              })
            });
          }

          const pathname = history.location.pathname.replace(
            this.project.uuid,
            to.projectUuid
          );

          // If we are transfering to the day we are already on
          // we need to go to worklogs and force a refetch
          if (
            to.projectUuid === this.projectUuid &&
            to.reportDate === this.date
          ) {
            history.push({
              pathname: `${this.project.viewUrl}/worklogs`,
              search: `?date=${to.reportDate}`
            });

            this.projectUI.workLogsUI.refetchWorkLogs();
          } else {
            history.push({
              pathname: pathname,
              search: `?date=${to.reportDate}`
            });
          }
        });

        this.saving = false;
      }, 3000);
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
      this.saving = false;
    }
  }

  @action.bound submitTransferFormError() {
    console.error(this.transferForm.errors());
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.transferForm = null;
    this.projectOption = null;
    this.calendarFirstDayOfMonth = null;
    this.fromReports.clear();
    this.fetchingFromReports = false;
    this.toReports.clear();
    this.fetchingToReports = false;
  }

  @action.bound async showTransferReportModal() {
    await this.authorization.checkFeatureAccess('TransferReports');

    history.push({
      pathname: history.location.pathname,
      search: `${this.baseQueryParams}&action=transfer`
    });
  }

  @action.bound async cancelTransferReportModal() {
    const pathname = history.location.pathname;

    history.push({
      pathname: pathname,
      search: this.baseQueryParams
    });
  }
}
