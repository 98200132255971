import { computed } from 'mobx';
import ReportEntryWithAttachments from './ReportEntryWithAttachments';

import { t } from 'utils/translate';
import kebabCase from 'lodash.kebabcase';

export default class Note extends ReportEntryWithAttachments {
  get urlRoot() {
    return `/ra/companies/${this.rootStore.me.company.uuid}/notes`;
  }

  idAttribute() {
    return 'uuid';
  }

  get restAttributes() {
    return [
      'uuid',
      'type',
      'createdTimestamp',
      'updatedTimestamp',
      'category',
      'comment',
      'project',
      'projectUuid',
      'reportId',
      'reportDate',
      'pdfs',
      'teamId',
      'segmentUuid',
      'activityViewUrl',
      'activitySegmentName'
    ];
  }

  get restAttributeDefaults() {
    return {
      activityViewUrl: '/'
    };
  }

  // TODO put back to plural
  @computed
  get categoryName() {
    switch (this.category) {
      case 'NOTES':
        return t('General Notes');
      case 'SAFETY':
        return t('Site Safety Observations');
      case 'QUALITY':
        return t('Quality Control Observations');
      default:
        return t('General Notes');
    }
  }

  @computed
  get singularCategoryName() {
    switch (this.category) {
      case 'NOTES':
        return t('General Note');
      case 'SAFETY':
        return t('Site Safety Observation');
      case 'QUALITY':
        return t('Quality Control Observation');
      default:
        return t('General Note');
    }
  }

  @computed get activityTitle() {
    return `${t('submitted a')} ${this.singularCategoryName}`;
  }

  @computed
  get formValues() {
    return {
      category: this.category,
      comment: this.comment
    };
  }

  @computed get slug() {
    return kebabCase(this.comment?.replace(/,/gi, '').substring(0, 50));
  }
}
