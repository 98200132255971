import { action, computed, observable } from 'mobx';
import SettingsChildUI from './SettingsChildUI';

import Material from 'stores/models/Material';

import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import history from 'utils/history';

import {
  MaterialForm,
  materialFormOptions,
  materialFormFields,
  materialFormRules,
  materialFormPlugins,
  materialFormLabels
} from 'forms/material';

export default class SettingsMaterialAddUI extends SettingsChildUI {
  @observable entryForAdd;
  @observable entryAddForm;

  @observable isDefault;

  constructor(options) {
    super(options);

    this.entryForAdd = null;
    this.entryAddForm = null;

    this.isDefault = false;
  }

  @action.bound setup() {
    this.setupForm();
  }

  @action.bound setupForm() {
    this.entryForAdd = new Material(
      {},
      {
        rootStore: this.rootStore
      }
    );

    this.entryAddForm = new MaterialForm(
      {
        fields: materialFormFields,
        rules: materialFormRules,
        labels: materialFormLabels
      },
      {
        options: materialFormOptions,
        plugins: materialFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound submitEntryAddForm(event) {
    event.preventDefault();
    event.stopPropagation();

    this.entryAddForm.submit({
      onSuccess: this.submitEntryAddFormSuccess,
      onError: this.submitEntryAddFormError
    });
  }

  @action.bound async submitEntryAddFormSuccess() {
    this.saving = true;

    try {
      const values = this.entryAddForm.values();

      await this.entryForAdd.save(
        {
          name: values.name,
          unit: {
            uuid: values.unit
          },
          isDefault: this.isDefault,
          projects: [
            {
              uuid: this.project.uuid
            }
          ]
        },
        {
          wait: true
        }
      );

      // TODO: Uncomment once API support for sort by updated / created
      // if (companyLevelCode) {
      //   this.parent.sortByLastUpdated();
      // } else {
      //   this.parent.sortByLastCreated();
      // }

      // TODO: Remove once API support for sort by updated / created
      this.parent.refetchMaterials();

      this.cancelMaterialAdd();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Material created')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitEntryAddFormError() {
    console.error(this.entryAddForm.errors());
  }

  @action.bound cancelMaterialAdd() {
    history.push({
      pathname: `${this.project.viewUrl}/settings/production-tracking/materials`,
      search: this.baseQueryParams
    });
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.activeModal = null;
    this.entryForAdd = null;
    this.entryAddForm = null;
    this.isDefault = false;
    this.saving = false;
  }

  @computed get disableCancelButton() {
    return this.saving;
  }

  @computed get disableSaveButton() {
    return Boolean(this.saving || this.materialExistsOnProject);
  }

  @computed get saveButtonText() {
    if (this.saving) {
      return t('Saving...');
    }

    return t('Save');
  }

  @computed
  get materialExistsOnProject() {
    if (this.saving) return false;

    return (
      this.entryAddForm?.existingMaterial &&
      this.entryAddForm?.existingMaterial.getProjectByUuid(this.project.uuid)
    );
  }

  @computed
  get materialExistsOnProjectText() {
    return t(
      'The material {material} already exists for this project. Please provide a unique combination.',
      {
        templateStrings: {
          material: this.entryAddForm.existingMaterial.materialKey
        }
      }
    );
  }

  @action.bound toggleIsDefault() {
    this.isDefault = !this.isDefault;
  }

  @computed get selectedUnitOption() {
    return this.parent.unitOptions.find(
      option => option.value === this.entryAddForm.$('unit').value
    );
  }
}
