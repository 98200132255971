import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import { t } from 'utils/translate';

const resetPasswordFormPlugins = extendedPlugins;

const resetPasswordFormOptions = {
  validateOnInit: false,
  showErrorsOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const resetPasswordFormFields = ['workEmail'];

const resetPasswordFormRules = {
  workEmail: 'email|required'
};

const resetPasswordFormValues = {};

const resetPasswordFormLabels = {
  workEmail: t('email')
};

class ResetPasswordForm extends MobxReactForm {}

export {
  ResetPasswordForm,
  resetPasswordFormOptions,
  resetPasswordFormFields,
  resetPasswordFormRules,
  resetPasswordFormValues,
  resetPasswordFormLabels,
  resetPasswordFormPlugins
};
