import { action, computed, observable, runInAction, reaction } from 'mobx';
import SettingsChildUI from './SettingsChildUI';
import { BASE_DEBOUNCE } from 'fixtures/constants';
import debounce from 'lodash.debounce';
import identity from 'lodash.identity';
import pickBy from 'lodash.pickby';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import matchModelField from 'utils/matchModelField';
import orderBy from 'lodash.orderby';
import Shifts from 'stores/collections/Shifts';

export default class SettingsShiftsUI extends SettingsChildUI {
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable sortDirection;
  @observable sortField;
  @observable loading;

  constructor(options) {
    super(options);

    // Sorting
    this.sortDirection = 'asc';
    this.sortField = 'shift';

    // Search & Pagination
    this.searchQuery = '';
    this.pageSize = 20;
    this.page = 1;

    this.loading = true;

    this.paginatedShifts = new Shifts(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchShiftsDebounced = debounce(this.fetchShifts, BASE_DEBOUNCE);
  }

  @action.bound setup() {
    this.setupReactions();
    this.fetchShifts();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchShiftsDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      sortField: this.sortField,
      query: this.searchQuery,
      sortDirection: this.sortDirection
    };
  }

  @action.bound
  async fetchShifts() {
    this.Shifts.cancelRequest();
    this.Shifts.reset();

    try {
      await this.Shifts.fetch({
        params: pickBy(this.params, identity),
        add: true,
        remove: false,
        update: true
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  sortByColumn() {
    if (this.sortDirection === 'asc') {
      this.sortDirection = 'desc';
    } else {
      this.sortDirection = 'asc';
    }
  }

  @action.bound
  restoreshift(shift) {
    const shiftIndex = shift.hiddenProjects
      .map(item => item.uuid)
      .indexOf(this.project.uuid);

    shift.hiddenProjects.splice(shiftIndex, 1);
  }

  @action.bound
  async toggleshiftForProject(shift) {
    await this.authorization.checkFeatureAccess('EditShifts');

    if (!shift.assignedToCurrentProject) {
      this.restoreshift(shift);
    } else {
      shift.hiddenProjects.push({ uuid: this.project.uuid });
    }

    try {
      await shift.save();
      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t(
          `Shift ${shift.assignedToCurrentProject ? 'enabled' : 'disabled'}`
        )
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
      this.notifications.pushNotification({
        snackbar: 'error',
        icon: 'close',
        title: t('Shift change cannot be saved')
      });
      this.restoreshift(shift);
    }
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.Shifts.clear();
    this.searchQuery = '';
    this.page = 1;
    this.loading = true;
    this.sortField = 'shift';
    this.sortDirection = 'asc';
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @computed get headerTitle() {
    return t('Are multiple shifts being worked?');
  }

  @computed get hasWriteAccess() {
    return this.authorization.canEditShifts;
  }

  @computed get Shifts() {
    return this.paginatedShifts;
  }

  @computed
  get hiddenShiftsForProject() {
    return this.Shifts.models.filter(shift =>
      shift.hiddenProjects.find(
        hiddenProject => hiddenProject.uuid === this.project.uuid
      )
    );
  }

  @computed get sortedShifts() {
    return orderBy(this.Shifts.models, ['order'], [this.sortDirection]);
  }

  @computed get searchedShifts() {
    if (!this.searchQuery) {
      return this.sortedShifts;
    }

    const query = this.searchQuery.toLowerCase();

    return this.sortedShifts.filter(shift => {
      return (
        matchModelField(shift, query, 'name') ||
        matchModelField(shift, query, 'code')
      );
    });
  }

  @computed get showEmptyState() {
    return !this.loading && !this.hasShifts;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @computed get hasShifts() {
    return this.Shifts.hasModels;
  }

  @computed get hasSearchedShifts() {
    return this.searchedShifts.length > 0;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.Shifts.totalElements / this.pageSize);
  }

  @computed get title() {
    return t('Shifts settings');
  }
}
