import request from 'axios';

import { observable, action, computed } from 'mobx';
import UIStore from './UIStore';

import Project from 'stores/models/Project';
import TimeCard from 'stores/models/workLogs/TimeCard';

import {
  TimeEntriesTimeCardForm,
  timeEntriesTimeCardFormRules,
  timeEntriesTimeCardFormFields,
  timeEntriesTimeCardFormOptions,
  timeEntriesTimeCardFormPlugins
} from 'forms/timeEntriesTimeCard';

import TimeEntriesWorkLogUI from './workLogs/TimeEntriesWorkLogUI';
import TimesheetTransferProjectUI from './TimesheetTransferProjectUI';

import errorHandler from 'utils/errorHandler';
import alertErrorHandler from 'utils/alertErrorHandler';

import { callTrack } from 'utils/segmentIntegration';

import { TIMESHEET_DELETE_TIMECARD } from 'utils/segmentAnalytics/eventSpec';

import { t } from 'utils/translate';
import history from 'utils/history';

export default class TimesheetsEditUI extends UIStore {
  @observable timeCardToEdit;
  @observable timeEntriesForm;
  @observable timeEntriesDataFetched;
  @observable savingTimeCards;

  constructor(options) {
    super(options);

    this.timeCardToEdit = false;
    this.timeEntriesForm = null;
    this.timeEntriesDataFetched = false;
    this.savingTimeCards = false;

    this.redirectUrl = null; // Use this to redirect to different parts of the application after editing a timecard

    this.timeEntriesWorkLogUI = new TimeEntriesWorkLogUI({
      rootStore: this.rootStore,
      parent: this
    });

    this.timesheetTransferProjectUI = new TimesheetTransferProjectUI({
      rootStore: this.rootStore,
      parent: this
    });
  }

  @action.bound
  async setup(timecardUuid) {
    this.timeEntriesDataFetched = false;

    try {
      const response = await request.get(
        `${this.rootStore.urlMicroService(
          'performanceTracking'
        )}/worklogs/timecards/${timecardUuid}`
      );

      this.timeCardToEdit = new TimeCard(response.data.timeCard, {
        rootStore: this.rootStore
      });

      /*
       * We need to fetch a related project for the form to work as
       * the form checks some rules on the project and these are not available on the Timecard
       * fetched from the API
       */
      const timeEntriesFormProject = new Project(
        { uuid: this.timeCardToEdit.projectUuid },
        {
          rootStore: this.rootStore
        }
      );

      this.setupSelectors();

      await Promise.all([
        timeEntriesFormProject.fetch({
          url: `ra/companies/${this.company.uuid}/projects/${this.timeCardToEdit.projectUuid}`
        }),
        this.timeEntriesWorkLogUI.fetchOvertimeRules(
          this.timeCardToEdit.projectUuid
        ),
        this.timeEntriesWorkLogUI.fetchWorkersWeekTotalHours(
          [this.timeCardToEdit.worker.workerUuid],
          this.timeCardToEdit.date
        )
      ]);

      this.timeEntriesWorkLogUI.timeSheetsAddProject = timeEntriesFormProject;

      this.timeCardToEdit.projectName = timeEntriesFormProject.name;
      this.timeCardToEdit.projectNo = timeEntriesFormProject.projectNo;

      this.timeEntriesForm = new TimeEntriesTimeCardForm(
        {
          fields: timeEntriesTimeCardFormFields,
          rules: timeEntriesTimeCardFormRules,
          values: this.timeCardToEdit.formValues,
          timeEntries: this.timeCardToEdit.timeEntries.models,
          breaks: this.timeCardToEdit.breaks.models,
          timeCardStatus: this.timeCardToEdit.timeCardStatus,
          isSynced: this.timeCardToEdit.isSynced,
          isLockedForEditing: this.timeCardToEdit.isLockedForEditing,
          timeCardDetails: this.timeCardToEdit.timeCardDetails,
          manualTimeEntry: this.timeCardToEdit.manualTimeEntry
        },
        {
          options: timeEntriesTimeCardFormOptions,
          plugins: timeEntriesTimeCardFormPlugins,
          rootStore: this.rootStore,
          timeEntriesWorkLogUI: this.timeEntriesWorkLogUI,
          currentTimeEntriesWorkLogDate: this.timeCardToEdit.date,
          worker: this.timeCardToEdit.worker,
          attachments: this.timeCardToEdit.attachments
        }
      );

      this.timeEntriesDataFetched = true;
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
      this.handleCloseEditRedirects();
    }
  }

  @action.bound
  tearDown() {
    this.timeEntriesForm = null;
    this.timeCardToEdit = false;

    this.timeEntriesDataFetched = false;
    this.timeEntriesWorkLogUI.workersWeekTotalHours.clear();

    this.tearDownSelectors();
  }

  @action.bound setupSelectors() {
    this.costCodeSelectorUI.setup();
    this.shiftSelectorUI.setup();
    this.payTypeSelectorUI.setup();
    this.classificationSelectorUI.setup();
    this.breakSelectorUI.setup();
  }

  @action.bound tearDownSelectors() {
    this.costCodeSelectorUI.tearDown();
    this.shiftSelectorUI.tearDown();
    this.payTypeSelectorUI.tearDown();
    this.classificationSelectorUI.tearDown();
    this.breakSelectorUI.tearDown();
  }

  @computed
  get overtimeRule() {
    return this.timeEntriesWorkLogUI.overtimeRule;
  }

  @action.bound
  async discardChanges() {
    if (this.redirectOnDiscard) {
      this.redirectOnDiscard = false;
      this.redirectToTimecardToMap();

      await this.hideActiveModal();
      this.tearDown();

      return;
    }

    this.timesheetTransferProjectUI.hideTransferInputs();
    this.tearDown();
    this.hideActiveModal();
    this.closeTimeSheetsEdit();
  }

  @action.bound handleCloseEditRedirects() {
    if (this.redirectUrl) {
      history.push(this.redirectUrl);
      this.redirectUrl = null;
    } else {
      history.push(`/timesheets`);
    }
  }

  @action.bound
  async closeTimeSheetsEdit() {
    if (this.savingTimeCards || this.uploadRunning) return;

    if (
      this.timeEntriesForm?.timeCardIsNotPristine ||
      this.timesheetTransferProjectUI.displayTransferInputs
    ) {
      this.activeModal = 'DiscardChangesModal';
      return;
    } else {
      this.handleCloseEditRedirects();
    }

    this.tearDown();
  }

  @computed
  get showTimeEntriesEditScreenLoader() {
    return !this.timeEntriesDataFetched || this.savingTimeCards;
  }

  @computed
  get disableSaveButton() {
    return (
      this.timeCardToEdit.saving ||
      !this.timeEntriesForm?.timeCardIsValid ||
      this.timeEntriesForm?.hasError ||
      this.timeEntriesHasError
    );
  }

  @computed
  get timeEntriesHasError() {
    if (!this.timeEntriesForm?.timeEntries) return false;

    const hasErrors = this.timeEntriesForm.timeEntries.map(
      entry => entry.hasError
    );

    return hasErrors.find(hasError => hasError);
  }

  @action.bound
  openManageBreaksModal(timeCardForm) {
    this.timeEntriesWorkLogUI.openManageBreaksModal(timeCardForm);
  }

  @computed
  get uploadRunning() {
    return this.timeEntriesForm?.hasAttachmentsUploading;
  }

  @computed
  get disableSaveOrCreateButton() {
    return (
      this.disableSaveButton ||
      this.timeCardToEdit.isLockedForEditing ||
      this.uploadRunning ||
      this.savingTimeCards ||
      (this.timeCardToEdit?.timeCardDetails?.hasKioskActivity &&
        this.timeCardToEdit?.isKioskTimeCardAndCannotBeSavedDueToBreakRules)
    );
  }

  // Delete timecard
  @action.bound async deleteTimeCard() {
    await this.authorization.checkFeatureAccess('DeleteWorkLog');

    this.showModal('DeleteModal');
  }

  @action.bound async cancelDeleteTimeCard() {
    await this.hideActiveModal();
  }

  @action.bound async confirmDeleteTimeCard() {
    this.saving = true;

    try {
      await this.timeCardToEdit.destroy(
        {
          url: `${this.rootStore.urlMicroService(
            'performanceTracking'
          )}/companies/${this.company.uuid}/timecards/${
            this.timeCardToEdit.uuid
          }`
        },
        { wait: true }
      );

      callTrack(TIMESHEET_DELETE_TIMECARD);

      this.parent.fetchTimesheetsPageData();

      await this.hideActiveModal();

      this.closeTimeSheetsEdit();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Time card deleted')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  linkTimecardToMap() {
    if (this.timeEntriesForm?.timeCardIsNotPristine) {
      this.redirectOnDiscard = true;
      this.activeModal = 'DiscardChangesModal';
      return;
    } else {
      this.redirectToTimecardToMap();
    }
  }

  @action.bound
  redirectToTimecardToMap() {
    history.push(
      `/projects/${this.timeCardToEdit.projectUuid}/map/${this.timeCardToEdit.worker.workerUuid}?date=${this.timeCardToEdit.date}`
    );
  }

  @action.bound
  async saveTimeCard() {
    this.savingTimeCards = true;

    if (
      this.timeEntriesForm.showHoursWorked &&
      this.timeEntriesForm.hoursWorked > 0
    ) {
      this.timeEntriesForm.allocateHoursWorked();
    }

    try {
      const url =
        this.rootStore.appConfig.tracking_api_url +
        `/worklogs/timecards/${this.timeCardToEdit.uuid}`;

      await request.patch(url, this.timeEntriesForm.cleanedValues);

      this.parent.fetchTimesheets();

      this.handleCloseEditRedirects();

      this.tearDown();

      if (this.parent.enableTimesheetsExport) {
        this.parent.checkTimesheetsExportStatus();
      }

      this.savingTimeCards = false;
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
      this.savingTimeCards = false;
    }
  }

  @computed
  get worklogDetailsDisplayedText() {
    return `${this.timeCardToEdit.date} | ${this.timeCardToEdit.projectName} ${
      this.timeCardToEdit?.projectNo
        ? `| ${this.timeCardToEdit?.projectNo}`
        : ''
    }`;
  }
}
