import React, { Component } from 'react';
import styled from 'styled-components';

const Div = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 0;
  background: rgba(0, 0, 0, 0.75);
`;

class Overlay extends Component {
  componentDidMount() {
    document.body.classList.add('overlay-open');
  }

  componentWillUnmount() {
    document.body.classList.remove('overlay-open');
  }

  render() {
    return <Div {...this.props} />;
  }
}

export default Overlay;
