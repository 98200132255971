import { computed } from 'mobx';
import { Model } from 'mobx-mc';

export default class SageInacctEmployeeMatch extends Model {
  get restAttributes() {
    return ['raken', 'intacct', 'message'];
  }

  @computed get patchValues() {
    return {
      uuid: this.raken.workerUuid,
      externalId: this.intacct.id
    };
  }
}
