import React from 'react';
import { Route } from 'react-router-dom';

export default ({ path, component, exact, ...rest }) => {
  const Component = component;

  return (
    <Route
      path={path}
      exact={exact}
      render={routerProps => <Component {...rest} {...routerProps} />}
    />
  );
};
