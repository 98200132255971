import styled from 'styled-components';
import media from '../utils/mediaTemplate';

export default styled.div`
  display: flex;
  background-color: ${props => props.theme.colors.white};
  border-radius: 0 0 1.5rem 1.5rem;

  > button,
  > a {
    flex: 1;
    margin: 0;
    border-radius: 0;

    &:focus {
      box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.25);
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  > button:first-child,
  > a:first-child {
    border-bottom-left-radius: 5px;
  }

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    appearance: none;
  }

  ${props =>
    !props.squareRightButton &&
    `
    > button:last-child, > a:last-child {
      border-bottom-right-radius: 5px;
    }
  `}

  ${media.desktop`
    > button:first-child, > a:first-child {
      border-bottom-left-radius: 1.5rem;
    }
    
    ${props =>
      !props.squareRightButton &&
      `
      > button:last-child, > a:last-child {
        border-bottom-right-radius: 1.5rem;
      }
    `}
  `};
`;
