import Text from './Text';

const H3 = Text.withComponent('h3');

H3.defaultProps = {
  transform: 'capitalize',
  block: true
};

export default H3;
