import moment from 'moment';
import { action } from 'mobx';
import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';
import { t } from 'utils/translate';

const taskFormPlugins = extendedPlugins;

const taskFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const taskFormFields = [
  'assignee',
  'assignee.uuid',
  'assignee.name',
  'desc',
  'dueDate',
  'attachments',
  'status'
];

const taskFormRules = {
  assignee: 'required',
  'assignee.uuid': 'required',
  desc: 'required|string|between:1,10000',
  dueDate: 'dateCustom:YYYY-MM-DD',
  status: 'string'
};

const taskFormValues = {
  dueDate: moment().format('YYYY-MM-DD'),
  status: 'OPEN'
};

const taskFormLabels = {
  assignee: t('assigned to'),
  'assignee.uuid': t('assignee'),
  desc: t('description'),
  dueDate: t('due date')
};

class TaskForm extends MobxReactForm {
  @action.bound toggleStatus() {
    if (this.$('status').value === 'OPEN') {
      this.$('status').set('COMPLETED');
    } else {
      this.$('status').set('OPEN');
    }
  }
}

export {
  TaskForm,
  taskFormOptions,
  taskFormFields,
  taskFormRules,
  taskFormValues,
  taskFormLabels,
  taskFormPlugins
};
