import moment from 'moment-timezone';
import { computed } from 'mobx';

import WeatherByTime from './WeatherByTime';

import formatTemperature from '../../utils/formatTemperature';
import formatPrecipitation from '../../utils/formatPrecipitation';

export default class WeatherHourly extends WeatherByTime {
  get restAttributes() {
    return [
      'id',
      'apparentTemperature',
      'cloudCover',
      'dewPoint',
      'humidity',
      'icon',
      'ozone',
      'precipIntensity',
      'precipProbability',
      'precipType',
      'pressure',
      'summary',
      'temperature',
      'time',
      'uvIndex',
      'visibility',
      'windBearing',
      'windGust',
      'windSpeed'
    ];
  }

  get restAttributeDefaults() {
    return {
      precipIntensity: 0
    };
  }

  @computed
  get useImperial() {
    return this.rootStore?.companyUI?.company?.useImperialFormatting;
  }

  @computed
  get temperatureFormatted() {
    return formatTemperature(this.temperature, this.useImperial);
  }

  @computed
  get hourNumber() {
    return moment
      .unix(this.time)
      .tz(this.timezone)
      .format('h');
  }

  @computed
  get hourNumber24() {
    return moment
      .unix(this.time)
      .tz(this.timezone)
      .format('HH');
  }

  @computed
  get hourPeriod() {
    return moment
      .unix(this.time)
      .tz(this.timezone)
      .format('A');
  }

  @computed
  get timeHourPeriod() {
    return moment
      .unix(this.time)
      .tz(this.timezone)
      .format('h A');
  }

  @computed
  get precipitationIntensityFormatted() {
    return formatPrecipitation(this.precipIntensity, this.useImperial);
  }
}
