import orderBy from 'lodash.orderby';
import { action, computed, observable } from 'mobx';
import alertErrorHandler from 'utils/alertErrorHandler';

import { callTrack } from 'utils/segmentIntegration';
import { t } from 'utils/translate';

import ProjectMinimal from 'stores/models/ProjectMinimal';

import {
  INTEGRATION_FLOW_ENABLED,
  INTEGRATION_FLOW_DISABLED
} from 'utils/segmentAnalytics/eventSpec';

import {
  IntegrationConfigurationForm,
  integrationConfigurationFormOptions,
  integrationConfigurationFormFields,
  integrationConfigurationFormValues,
  integrationConfigurationFormPlugins,
  DynamicConfigurationForm
} from 'forms/integrationConfiguration';

import UIStore from 'stores/ui/UIStore';

export default class IntegrationConfigurationUI extends UIStore {
  @observable companyLevelConfigurationForm;
  @observable projectLevelConfigurationForm;

  @observable dynamicConfigurationForm;

  @observable sortField;
  @observable sortDirection;
  @observable page;
  @observable pageSize;

  @observable selectedRakenProject = null;

  constructor(options) {
    super(options);
    this.companyLevelConfigurationForm = null;
    this.projectLevelConfigurationForm = null;
    this.dynamicConfigurationForm = null;

    this.selectedRakenProject = null;

    this.sortField = null;
    this.sortDirection = 'asc';
    this.page = 1;
    this.pageSize = 10;
  }

  @computed get activeIntegration() {
    return this.parent.activeIntegration;
  }

  @action.bound async setup() {
    await this.fetchConfigurations();

    this.setupConfigurationForms();
  }

  @action.bound fetchConfigurations() {
    return this.activeIntegration.configurations.fetch();
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.companyLevelConfigurationForm = null;
    this.projectLevelConfigurationForm = null;
    this.dynamicConfigurationForm = null;
    this.selectedRakenProject = null;
    this.clearValidationDetails();
    this.sortField = null;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed get visibleConfigurations() {
    if (!this.dynamicConfigurationForm) {
      return [];
    }
    return this.activeIntegration.configurations.models.filter(
      configuration => !configuration.hidden
    );
  }

  @action.bound setupConfigurationForms() {
    // Specific to LiveViews for now. Eventually we can build
    // out UI based on which type comes back from the API.
    if (this.activeIntegration.isLiveView) {
      this.instantiateCompanyLevelConfigurationForm();
      this.instantiateProjectLevelConfigurationForm();
    }
    this.instantiateDynamicConfigurationForm();
  }

  @action.bound instantiateDynamicConfigurationForm() {
    if (this.activeIntegration.configurations.hasModels) {
      const formFields = [];
      const formValues = {};
      const formLabels = {};

      this.activeIntegration.configurations.models.forEach(configuration => {
        if ('embeddedScript' === configuration.key) {
          return;
        }
        formFields.push(configuration.key);
        formValues[configuration.key] = configuration.formValue;
        formLabels[configuration.key] = configuration.displayName;

        if (configuration.isList) {
          formFields.push(configuration.validationFormField);
          formValues[configuration.validationFormField] = '';
          formLabels[configuration.validationFormField] =
            configuration.displayName;
        }
      });
      if (formFields.length > 0) {
        this.dynamicConfigurationForm = new DynamicConfigurationForm(
          {
            fields: formFields,
            rules: {},
            values: formValues,
            labels: formLabels
          },
          {
            options: integrationConfigurationFormOptions,
            plugins: integrationConfigurationFormPlugins
          }
        );
      }
    }
  }

  @action.bound instantiateCompanyLevelConfigurationForm() {
    this.companyLevelConfigurationForm = new IntegrationConfigurationForm(
      {
        fields: integrationConfigurationFormFields,
        rules: {
          embeddedScript: this.activeIntegration.sourceValidationRules
        },
        values: {
          embeddedScript: this.activeIntegration.configurations.at(0)?.value
        },
        labels: {
          embeddedScript: this.embeddedScriptLabel
        }
      },
      {
        options: integrationConfigurationFormOptions,
        plugins: integrationConfigurationFormPlugins
      }
    );
  }

  @action.bound submitCompanyLevelConfigurationForm(event) {
    this.companyLevelConfigurationForm.submit({
      onSuccess: this.submitCompanyLevelConfigurationFormSuccess,
      onError: this.submitCompanyConfigurationFormError
    });
  }

  @action.bound async submitCompanyLevelConfigurationFormSuccess() {
    if (this.saving) return;

    const values = this.companyLevelConfigurationForm.values();

    if (
      this.activeIntegration.configurations.at(0).value ===
      values.embeddedScript
    )
      return;

    this.clearValidationDetails();

    this.saving = true;

    this.activeIntegration.configurations.at(0).value = values.embeddedScript;

    try {
      await this.activeIntegration.configurations.saveConfigurations();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Configuration saved')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitCompanyConfigurationFormError() {
    console.error(this.companyLevelConfigurationForm.errors());
  }

  @action.bound fetchProjectOptions() {
    this.projectSelectorUI.setProjectStates(['ACTIVE']);
    this.projectSelectorUI.fetchProjectOptions();
  }

  @action.bound resetProjectOptions() {
    this.projectSelectorUI.resetProjectOptions();
  }

  @computed
  get projectAutoCompleteOptions() {
    if (this.projectSelectorUI.projectOptions.hasModels) {
      const projectOptions = this.projectSelectorUI.projectOptions.models.map(
        projectOption => ({
          value: projectOption.uuid,
          name: projectOption.name
        })
      );

      return orderBy(
        projectOptions.filter(option => {
          return !this.projectLevelConfigurations.models.find(configuration => {
            return configuration.projectUuid === option.value;
          });
        }),
        option => option.name
      );
    }

    return [];
  }

  @computed get projectAutoCompleteNoOptionsText() {
    if (this.projectSelectorUI.searchingForProject) {
      return t('Searching');
    }

    if (!this.projectAutoCompleteOptions.length) {
      return t('No results');
    }

    return false;
  }

  @action.bound instantiateProjectLevelConfigurationForm() {
    this.projectLevelConfigurationForm = new IntegrationConfigurationForm(
      {
        fields: integrationConfigurationFormFields,
        rules: {
          embeddedScript:
            'required|' + this.activeIntegration.sourceValidationRules
        },
        values: integrationConfigurationFormValues,
        labels: {
          embeddedScript: this.embeddedScriptLabel
        }
      },
      {
        options: integrationConfigurationFormOptions,
        plugins: integrationConfigurationFormPlugins
      }
    );
  }

  @computed get disableSubmitProjectLevelConfigurationForm() {
    return (
      !this.selectedRakenProject ||
      this.projectLevelConfigurationForm.isPristine ||
      this.projectLevelConfigurationForm.hasError
    );
  }

  @action.bound setSelectedRakenProject(project) {
    this.selectedRakenProject = project;
    this.projectSelectorUI.clearProjectOptionsSearchQuery();
  }

  @action.bound submitProjectLevelConfigurationForm(event) {
    event.preventDefault();

    this.projectLevelConfigurationForm.submit({
      onSuccess: this.submitProjectLevelConfigurationFormSuccess,
      onError: this.submitProjectLevelConfigurationFormError
    });
  }

  @action.bound async submitProjectLevelConfigurationFormSuccess() {
    if (this.saving) return;

    const values = this.projectLevelConfigurationForm.values();

    this.saving = true;

    const model = {
      projectUuid: this.selectedRakenProject.value,
      configurations: [
        {
          key: 'embeddedScript',
          type: 'string',
          value: values.embeddedScript
        }
      ]
    };

    try {
      this.projectLevelConfigurations.add(model, {
        unshift: true
      });

      this.activeIntegration.configurations.saveConfigurations();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Configuration saved')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
      this.projectLevelConfigurations.remove(model);
    } finally {
      this.saving = false;
      this.selectedRakenProject = null;
      this.instantiateProjectLevelConfigurationForm();
    }
  }

  @action.bound submitProjectLevelConfigurationFormError() {
    console.error(this.projectLevelConfigurationForm.errors());
  }

  @action.bound removeProjectLevelConfiguration(projectUuid) {
    const model = this.projectLevelConfigurations.models.find(
      configuration => configuration.projectUuid === projectUuid
    );

    this.projectLevelConfigurations.remove(model);

    try {
      this.activeIntegration.configurations.saveConfigurations();
    } catch (error) {
      this.projectLevelConfigurations.add(model);
    }
  }

  @computed get projectLevelConfigurations() {
    return this.activeIntegration.configurations.projectConfigurations;
  }

  @computed get hasProjectLevelConfigurations() {
    return this.projectLevelConfigurations.length > 0;
  }

  @computed get projectLevelConfigurationMappings() {
    return this.projectLevelConfigurations.models.map(projectConfiguration => {
      const rakenProject = new ProjectMinimal(
        { uuid: projectConfiguration.projectUuid, name: t('Loading...') },
        {
          rootStore: this.rootStore
        }
      );

      try {
        rakenProject.fetch({
          url: `ra/companies/${this.company.uuid}/projects/${projectConfiguration.projectUuid}`
        });
      } catch (error) {
        rakenProject.hasError = true;
        rakenProject.name = t('Unknown');
      }

      return {
        rakenProject: rakenProject,
        source: projectConfiguration.configuration.value
      };
    });
  }

  @computed get sortedProjectLevelConfigurationMappings() {
    if (!this.sortField) {
      return this.projectLevelConfigurationMappings;
    }

    return orderBy(
      this.projectLevelConfigurationMappings,
      [
        mapping => {
          return mapping[this.sortField].name?.toLowerCase();
        }
      ],
      [this.sortDirection]
    );
  }

  //Pagination
  @computed
  get totalPages() {
    return Math.ceil(
      this.sortedProjectLevelConfigurationMappings.length / this.pageSize
    );
  }

  @action
  setPage = page => {
    this.page = page;
  };

  @computed
  get paginatedProjectLevelConfigurationMappings() {
    return this.sortedProjectLevelConfigurationMappings.slice(
      (this.page - 1) * this.pageSize,
      (this.page - 1) * this.pageSize + this.pageSize
    );
  }

  @computed get company() {
    if (this.isSuperAdmin) {
      return this.parent.activeCompany;
    }

    return this.rootStore.me.company;
  }

  @computed get embeddedScriptLabel() {
    return t(this.activeIntegration.name + ' source');
  }

  @action.bound resetDynamicConfigurationForm(event) {
    this.instantiateDynamicConfigurationForm();
  }

  @action.bound submitDynamicConfigurationForm(event) {
    event.preventDefault();

    this.dynamicConfigurationForm.submit({
      onSuccess: this.submitDynamicConfigurationFormSuccess,
      onError: this.submitDynamicConfigurationFormError
    });
  }

  @action.bound async submitDynamicConfigurationFormSuccess() {
    if (this.saving) return;

    const values = this.dynamicConfigurationForm.values();
    const analytics = [];

    this.activeIntegration.configurations.models.forEach(config => {
      if (config.key in values) {
        config.value = Array.isArray(values[config.key])
          ? values[config.key].join(',')
          : values[config.key];

        if (config.type === 'boolean') {
          if (!this.dynamicConfigurationForm.$(config.key).isDefault) {
            // changed?
            analytics.push([
              !!config.value
                ? INTEGRATION_FLOW_ENABLED
                : INTEGRATION_FLOW_DISABLED,
              {
                integration_name: this.activeIntegration.name,
                data_flow_name: config.displayName
              }
            ]);
          }
        }
      }
    });

    this.saving = true;
    try {
      await this.activeIntegration.configurations.saveConfigurations();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Configuration saved')
      });

      this.instantiateDynamicConfigurationForm();

      analytics.forEach(event => {
        callTrack(event[0], event[1]);
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitDynamicConfigurationFormError() {
    console.error(this.dynamicConfigurationForm.errors());
  }
}
