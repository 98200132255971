import moment from 'moment';
import { computed } from 'mobx';
import { Model } from 'mobx-mc';

export default class EquipmentReport extends Model {
  urlRoot = `${this.rootStore.urlMicroService(
    'performanceTracking'
  )}/equipment/reports/scheduled`;

  get restAttributes() {
    return [
      'uuid',
      'type',
      'name',
      'emails',
      'projectIncludeOption',
      'projects',
      'sendReport',
      'includeCostCode',
      'timeFrameType',
      'projectEntire',
      'time',
      'actionDate',
      'fromDate',
      'toDate'
    ];
  }

  idAttribute() {
    return 'uuid';
  }

  get restAttributeDefaults() {
    return {
      time: {
        hour: moment(Date.now()).format('hh'),
        minute: moment(Date.now()).format('mm')
      },
      projects: []
    };
  }

  @computed get formValues() {
    return {
      uuid: this.uuid,
      name: this.name,
      emails: this.emails,
      projectIncludeOption: this.projectIncludeOption,
      projects: this.projects.slice(),
      timeFrameType: this.timeFrameType,
      projectEntire: this.projectEntire,
      time: `${
        this.time.hour.toString().length === 1
          ? '0' + this.time.hour
          : this.time.hour
      }:${
        this.time.minute.toString().length === 1
          ? '0' + this.time.minute
          : this.time.minute
      }`,
      actionDate: this.actionDate,
      sendReport: false
    };
  }
}
