import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import trimObject from '../utils/trimObject';

const workersFormPlugins = extendedPlugins;

const workersFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const workersFormFields = [
  'workers[]',
  'workers[].firstName',
  'workers[].lastName',
  'workers[].classificationId', // TODO deprecate this
  'workers[].employeeId',
  'workers[].email',
  'workers[].phoneNumber'
];

const workersFormRules = {
  'workers[].firstName': 'string|required',
  'workers[].lastName': 'string|required',
  'workers[].classificationId': 'numeric', // TODO deprecate this
  'workers[].classification.uuid': 'string',
  'workers[].classification.name': 'string',
  'workers[].employeeId': 'string',
  'workers[].email': 'string|email',
  'workers[].phoneNumber': 'string'
};

class WorkersForm extends MobxReactForm {
  trimmedValues() {
    return trimObject(this.values());
  }
}

export {
  WorkersForm,
  workersFormOptions,
  workersFormFields,
  workersFormRules,
  workersFormPlugins
};
