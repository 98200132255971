import debounce from 'lodash.debounce';
import request from 'axios';
import orderBy from 'lodash.orderby';
import { observable, computed, action, runInAction } from 'mobx';
import UIStore from './UIStore';
import errorHandler from 'utils/errorHandler';
import escaperegexp from 'lodash.escaperegexp';
import objectValuesToLowerCase from 'utils/objectValuesToLowerCase';

import { t } from 'utils/translate';

import {
  EquipmentForm,
  equipmentFormOptions,
  equipmentFormFields,
  equipmentFormRules,
  equipmentFormLabels,
  equipmentFormValues,
  equipmentFormPlugins
} from 'forms/equipment';
import { callTrack } from 'utils/segmentIntegration';
import { EQUIPMENT_CREATED } from 'utils/segmentAnalytics/eventSpec';

export default class EquipmentUI extends UIStore {
  @observable equipmentForm;
  @observable activeEquipmentLogForm;
  @observable equipmentQuery;

  constructor(options) {
    super(options);

    // Creating
    this.equipmentLogForm = null;

    // Selecting Equipment
    this.activeEquipmentLogForm = null;

    // Equipment Names
    this.equipmentNames = observable([]);
    this.fetchEquipmentNames = debounce(this.fetchEquipmentNames, 250);
    this.supplierNames = observable([]);
    this.fetchSupplierNames = debounce(this.fetchSupplierNames, 250);

    //Searching
    this.equipmentQuery = '';
  }

  @action.bound
  clearUIState() {
    this.activeModal = null;
    this.equipmentForm = null;
    this.activeEquipmentLogForm = null;
    this.equipmentNames.clear();
    this.supplierNames.clear();
  }

  @computed get sortedEquipment() {
    return orderBy(
      this.rootStore.equipment.models,
      [equipment => equipment.name.toLowerCase()],
      ['asc']
    );
  }

  @action.bound
  fetchEquipment() {
    if (this.rootStore.equipment.fetching) return;

    if (this.authorization.canCRUDEquipmentLogs) {
      return this.rootStore.equipment.fetch({
        params: {
          limit: 10000,
          owned: true
        }
      });
    }
  }

  @action.bound
  showCreateEquipmentModal(equipmentLogForm) {
    return this.authorization
      .checkFeatureAccess('CRUDEquipmentLogs')
      .then(() => {
        runInAction(() => {
          this.equipmentLogUI.showModal('createEquipment');

          this.activeEquipmentLogForm = equipmentLogForm;

          this.equipmentForm = new EquipmentForm(
            {
              fields: equipmentFormFields,
              rules: {
                ...equipmentFormRules,
                owned: 'boolean|required'
              },
              labels: equipmentFormLabels,
              values: { ...equipmentFormValues, owned: false }
            },
            {
              options: equipmentFormOptions,
              plugins: equipmentFormPlugins,
              rootStore: this.rootStore
            }
          );
        });
      });
  }

  @action.bound
  submitEquipmentForm(e) {
    e.preventDefault();
    e.stopPropagation();

    this.equipmentForm.submit({
      onSuccess: this.submitEquipmentFormSuccess,
      onError: this.submitEquipmentFormError
    });
  }

  @action.bound
  async submitEquipmentFormSuccess() {
    this.saving = true;

    const values = this.equipmentForm.trimmedValues();

    const payload = {
      name: values.name,
      supplier: values.owned || !values.supplier ? null : values.supplier,
      equipmentId: values.equipmentId ? values.equipmentId : null,
      rate: values.rate ? values.rate : null,
      frequency: values.frequency ? values.frequency : null,
      owned: values.owned
    };

    if (!values.owned) {
      payload.projectUuid = this.project.uuid;
    }

    this.rootStore.equipment
      .create(payload, {
        wait: true
      })
      .then(equipment => {
        this.saving = false;
        this.activeEquipmentLogForm.addEquipment(equipment);

        const entry = this.equipmentLogUI.entryForCreation
          ? this.equipmentLogUI.entryForCreation
          : this.equipmentLogUI.entryForEdit;

        entry.equipment.owned = values.owned;

        this.cancelCreateEquipment();

        callTrack(EQUIPMENT_CREATED, {
          equipment_type: values.owned ? 'Owned' : 'Rented',
          supplier_entered: Boolean(values.supplier),
          id_entered: Boolean(values.equipmentId)
        });
      })
      .catch(error => {
        this.saving = false;
        errorHandler(error, this.notifications.pushError);
      });
  }

  @action.bound
  submitEquipmentFormError() {
    console.log(this.equipmentForm.errors());
  }

  @action.bound
  cancelCreateEquipment() {
    this.equipmentLogUI.hideActiveModal().then(() => {
      runInAction(() => {
        this.saving = false;
        this.equipmentForm = null;
        this.activeEquipmentLogForm = null;
        this.clearSuggestions();
      });
    });
  }

  @action.bound
  showEditEquipmentModal() {
    const equipmentLog = this.equipmentLogUI.entryForEdit;

    return this.authorization
      .checkFeatureAccess('CRUDEquipmentLogs')
      .then(() => {
        runInAction(() => {
          this.equipmentLogUI.showModal('editEquipment');

          this.equipmentForm = new EquipmentForm(
            {
              fields: equipmentFormFields,
              rules: {
                ...equipmentFormRules,
                owned: 'boolean|required'
              },
              labels: equipmentFormLabels,
              values: equipmentLog.equipmentFormValues
            },
            {
              options: equipmentFormOptions,
              plugins: equipmentFormPlugins,
              rootStore: this.rootStore
            }
          );
        });
      });
  }

  @action.bound
  submitEditEquipmentForm(e) {
    e.preventDefault();
    e.stopPropagation();

    this.equipmentForm.submit({
      onSuccess: this.submitEditEquipmentFormSuccess,
      onError: this.submitEditEquipmentFormError
    });
  }

  @action.bound
  async submitEditEquipmentFormSuccess() {
    this.saving = true;
    const equipmentLog = this.equipmentLogUI.entryForEdit;

    const values = this.equipmentForm.trimmedValues();

    const payload = {
      name: values.name,
      supplier: values.owned || !values.supplier ? null : values.supplier,
      equipmentId: values.equipmentId ? values.equipmentId : null,
      rate: values.rate ? values.rate : null,
      frequency: values.frequency ? values.frequency : null
    };

    equipmentLog.equipment
      .save(payload, {
        url: `${this.rootStore.urlMicroService(
          'performanceTracking'
        )}/equipment/${equipmentLog.equipment.id}`,
        wait: true
      })
      .then(equipment => {
        this.cancelEditEquipment();
      })
      .catch(error => {
        this.saving = false;
        errorHandler(error, this.notifications.pushError);
      });
  }

  @action.bound
  submitEditEquipmentFormError() {
    console.log(this.equipmentForm.errors());
  }

  @action.bound
  cancelEditEquipment() {
    this.equipmentLogUI.hideActiveModal().then(() => {
      runInAction(() => {
        this.saving = false;
        this.equipmentForm = null;
        this.clearSuggestions();
      });
    });
  }

  @computed
  get existingEquipment() {
    if (!this.equipmentForm) return false;

    const values = objectValuesToLowerCase(this.equipmentForm.trimmedValues());

    if (values.owned) {
      return this.rootStore.equipment.ownedEquipment.find(equipment => {
        if (
          this.equipmentLogUI.entryForEdit &&
          this.equipmentLogUI.entryForEdit.equipment.id === equipment.id
        )
          return false;

        if (
          !values.equipmentId &&
          !equipment.identificationValues.equipmentId
        ) {
          return equipment.identificationValues.name === values.name;
        }

        return (
          equipment.identificationValues.name === values.name &&
          equipment.identificationValues.equipmentId === values.equipmentId
        );
      });
    }

    return false;
  }

  @computed
  get equipmentFormIsInValid() {
    if (!this.equipmentForm || this.existingEquipment) return true;

    return (
      Boolean(this.equipmentForm.check('isPristine')) ||
      Boolean(this.equipmentForm.check('hasError'))
    );
  }

  @computed get frequencyOptions() {
    return [
      {
        id: 'HOURLY',
        name: t('per hour')
      },
      {
        id: 'DAILY',
        name: t('per day')
      },
      {
        id: 'WEEKLY',
        name: t('per week')
      },
      {
        id: 'MONTHLY',
        name: t('per month')
      }
    ];
  }

  @action.bound fetchEquipmentNames(query = '') {
    this.equipmentNames.clear();

    if (query.length < 2) {
      return;
    }

    request
      .get(
        `${this.rootStore.urlMicroService(
          'performanceTracking'
        )}/equipment/names`,
        {
          params: {
            query: query
          }
        }
      )
      .then(response => {
        if (response.data.collection) {
          this.equipmentNames.replace(response.data.collection);
        }
      });
  }

  @action.bound fetchSupplierNames(query = '') {
    this.supplierNames.clear();

    if (query.length < 2) {
      return;
    }

    request
      .get(
        `${this.rootStore.urlMicroService(
          'performanceTracking'
        )}/equipment/suppliers`,
        {
          params: {
            query: query
          }
        }
      )
      .then(response => {
        if (response.data.collection) {
          this.supplierNames.replace(response.data.collection);
        }
      });
  }

  @action.bound clearSuggestions() {
    this.equipmentNames.clear();
    this.supplierNames.clear();
  }

  @action.bound
  setEquipmentQuery(query) {
    this.equipmentQuery = query;
  }

  @computed
  get searchedEquipment() {
    if (!this.equipmentQuery) {
      return this.sortedEquipment;
    }

    const searchExpression = new RegExp(escaperegexp(this.equipmentQuery), 'i');

    const equipmentFilter = equipment =>
      equipment.name.search(searchExpression) !== -1 ||
      (equipment.equipmentId &&
        equipment.equipmentId.search(searchExpression) !== -1) ||
      (equipment.supplier &&
        equipment.supplier.search(searchExpression) !== -1);

    return this.sortedEquipment.filter(equipmentFilter);
  }

  @computed
  get hasSearchedEquipment() {
    return this.searchedEquipment.length > 0;
  }

  @computed
  get hasEquipmentOptions() {
    return this.equipmentLogEquipmentOptionGroups.length > 0;
  }

  @computed
  get hasOwnedEquipmentOption() {
    return this.equipmentLogEquipmentOptionGroups.find(
      group => group.label === 'Owned'
    );
  }

  addSelectedEquipmentOption = options => {
    const form = this.equipmentLogUI.entryEditForm
      ? this.equipmentLogUI.entryEditForm
      : this.equipmentLogUI.entryCreateForm;

    const selectedEquipmentInOptions = options.find(
      option => option.id === form.$('equipment').value
    );

    if (!selectedEquipmentInOptions && form.$('equipment').value) {
      const selectedEquipment = this.rootStore.equipment.get(
        form.$('equipment').value
      );

      const supplier = selectedEquipment?.supplier
        ? selectedEquipment.supplier
        : '';
      const equipmentId = selectedEquipment.equipmentId
        ? selectedEquipment.equipmentId
        : '';
      const subText = `${supplier} ${supplier &&
        equipmentId &&
        ' | '} ${equipmentId}`;

      options.push({
        id: selectedEquipment.id,
        name: selectedEquipment.name,
        subText: subText,
        hide: true
      });
    }
  };

  @computed
  get equipmentLogEquipmentOptionGroups() {
    const optionGroups = [];
    const visibleOptions = [];
    const hiddenOptions = [];

    for (let equipment of this.searchedEquipment) {
      const supplier = equipment.supplier ? equipment.supplier : '';
      const equipmentId = equipment.equipmentId ? equipment.equipmentId : '';

      const subText = `${supplier} ${supplier &&
        equipmentId &&
        ' | '} ${equipmentId}`;

      if (equipment.owned) {
        visibleOptions.push({
          id: equipment.id,
          name: equipment.name,
          subText: subText,
          disabled: false
        });
      } else {
        hiddenOptions.push({
          id: equipment.id,
          name: equipment.name,
          subText: subText,
          hide: true
        });
      }
    }

    this.addSelectedEquipmentOption(hiddenOptions);

    if (visibleOptions.length > 0) {
      optionGroups.push({
        label: 'Owned',
        options: visibleOptions,
        hideGroupHeader: true
      });
    }

    if (hiddenOptions.length > 0) {
      optionGroups.push({
        label: 'Rented',
        hideGroupHeader: true,
        options: hiddenOptions
      });
    }

    return optionGroups;
  }

  @action.bound
  clearEquipmentQuery() {
    this.equipmentQuery = '';
  }
}
