import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import copy from 'copy-to-clipboard';

const CopyToClipboardContainer = styled.div`
  cursor: pointer;
`;

const clickToCopy = (onCopySuccess, textToCopy) => {
  copy(textToCopy);
  onCopySuccess();
};

const CopyToClipboard = ({ onCopySuccess, textToCopy, children }) => {
  if (!textToCopy) return null;
  return (
    <CopyToClipboardContainer
      onClick={() => {
        clickToCopy(onCopySuccess, textToCopy);
      }}
    >
      {children}
    </CopyToClipboardContainer>
  );
};

CopyToClipboard.propTypes = {
  textToCopy: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onCopySuccess: PropTypes.func,
  children: PropTypes.node.isRequired
};

export default CopyToClipboard;
