import request from 'axios';
import { computed, action, observable, runInAction } from 'mobx';
import errorHandler from 'utils/errorHandler';
import { t } from 'utils/translate';
import moment from 'moment';
import orderBy from 'lodash.orderby';
import numberWithCommas from 'utils/numberWithCommas';

import CompanySubscriptionBillingUI from 'stores/ui/CompanySubscriptionBillingUI';
import SuperAdminBillingUpcomingTransactions from 'stores/collections/superAdmin/SuperAdminBillingUpcomingTransactions';

import {
  SubscriptionEditForm,
  subscriptionEditFormOptions,
  subscriptionEditFormFields,
  subscriptionEditFormRules,
  subscriptionEditFormLabels,
  subscriptionEditFormPlugins
} from 'forms/superAdmin/subscriptionEdit';

import {
  SubscriptionForm,
  subscriptionFormOptions,
  subscriptionFormFields,
  subscriptionFormRules,
  subscriptionFormValues,
  subscriptionFormLabels,
  subscriptionFormPlugins
} from 'forms/subscription';

import {
  paymentMethodFormOptions,
  paymentMethodFormFields,
  paymentMethodFormRules,
  paymentMethodFormLabels,
  paymentMethodFormValues,
  paymentMethodFormPlugins
} from 'forms/paymentMethod';

import {
  SubscriptionEditTrialForm,
  subscriptionEditTrialFormOptions,
  subscriptionEditTrialFormFields,
  subscriptionEditTrialFormRules,
  subscriptionEditTrialFormLabels,
  subscriptionEditTrialFormPlugins
} from 'forms/superAdmin/subscriptionEditTrial';

import {
  RecordPaymentForm,
  recordPaymentFormOptions,
  recordPaymentFormFields,
  recordPaymentFormRules,
  recordPaymentFormLabels,
  recordPaymentFormValues,
  recordPaymentFormPlugins
} from 'forms/superAdmin/recordPaymentForm';

import {
  RefundTransactionForm,
  refundTransactionFormOptions,
  refundTransactionFormFields,
  refundTransactionFormRules,
  refundTransactionFormLabels,
  refundTransactionFormPlugins
} from 'forms/superAdmin/companies/refundTransactionForm';

export default class SuperAdminCompanySubscriptionBillingUI extends CompanySubscriptionBillingUI {
  @observable showEditSubscriptionForm;
  @observable recordPaymentForm;
  @observable paying;
  @observable superAdminRefundTransactionForm;
  @observable refundProcessing;
  @observable sortDirection;
  @observable sortField;
  @observable transactionToDelete;
  @observable cardToDelete;
  @observable cachedTrialEndDate;
  @observable salesRepSearchText;
  @observable subscriptionStartDate;

  constructor(options) {
    super(options);

    this.upcomingTransactions = new SuperAdminBillingUpcomingTransactions(
      {},
      {
        rootStore: this.rootStore,
        parent: this
      }
    );

    this.showEditSubscriptionForm = false;
    this.recordPaymentForm = null;
    this.paying = false;
    this.superAdminRefundTransactionForm = null;
    this.refundProcessing = false;
    this.cardToDelete = null;

    this.sortDirection = 'asc';
    this.sortField = 'paymentDateFormatted';

    this.transactionToDelete = null;
    this.cachedTrialEndDate = null;
    this.salesRepSearchText = '';

    this.subscriptionStartDate = '';
  }

  @computed
  get company() {
    return this.parent.activeCompany;
  }

  @computed
  get subscription() {
    return (this.company && this.company.subscription) || {};
  }

  @computed
  get hasDisabled() {
    return {
      Starter: false,
      Basic: false,
      Professional: false,
      Performance: false
    };
  }

  /**
   * Urls that get overridden from CompanySubscriptionBillingUI
   */
  @computed get showPlansUrl() {
    return `/companies/${this.company.id}/subscription-billing`;
  }

  @computed get estimateUrl() {
    return `/ra/sadmin/companies/${this.company.id}/billing/estimate`;
  }

  @computed get subscriptionStates() {
    return [
      { id: 'ACTIVE', name: 'Active' },
      { id: 'CANCELLED', name: 'Cancelled' },
      { id: 'TRIAL', name: 'Trial' },
      { id: 'PAST_DUE', name: 'Past Due' },
      { id: 'ON_HOLD', name: 'On Hold' }
    ];
  }

  @computed get billingPlatforms() {
    return [
      { id: 'CHECK', name: 'Check' },
      { id: 'CREDIT_CARD', name: 'Credit Card' },
      { id: 'IN_APP', name: 'App Store' }
    ];
  }

  @computed get scheduledPlanOptions() {
    return [
      { id: '', name: 'Keep Current Plan' },
      { id: 'performance', name: 'Upgrade to Performance' }
    ];
  }

  @computed get planOptions() {
    return this.rootStore.subscriptionPlans.models.map(plan => {
      return { id: plan.planKey, name: plan.name };
    });
  }

  @computed get paymentTypeOptions() {
    return [
      { id: 'CHECK', name: 'Check' },
      { id: 'CREDIT_CARD', name: 'Credit Card' }
    ];
  }

  @computed
  get planPeriodUnitPrice() {
    const plan = this.subscriptionPlans.models.find(
      model => model.planKey === this.subscriptionForm.$('billingPlanKey').value
    );

    if (this.subscriptionForm.$('periodType').value === 'ANNUAL') {
      return plan.formattedAnnualPrice;
    }

    return plan.formattedMonthlyPrice;
  }

  @action.bound
  sortByColumn(fieldName) {
    if (this.sortField === fieldName) {
      if (this.sortDirection === 'asc') {
        this.sortDirection = 'desc';
      } else {
        this.sortDirection = 'asc';
      }
    } else {
      this.sortField = fieldName;
      this.sortDirection = 'asc';
    }
  }

  @computed
  get sortedUpcomingTransactions() {
    return orderBy(
      this.upcomingTransactions.models,
      [
        transaction => {
          if (this.sortField === 'paymentDateFormatted') {
            return moment(transaction[this.sortField]).valueOf();
          } else {
            if (this.sortField === 'servicePeriodFormatted') {
              return moment(transaction['startDate']).valueOf();
            } else {
              return transaction[this.sortField].toLowerCase();
            }
          }
        }
      ],
      [this.sortDirection]
    );
  }

  @action.bound
  openCancelAccountModal() {
    this.showModal('superAdminCancelAccount');
  }

  @action.bound
  cancelSubscription() {
    this.hideActiveModal();
    const payload = Object.assign({}, this.subscription.asFormValues, {
      subscriptionState: 'CANCELLED'
    });
    this.subscription
      .save(payload, {
        wait: true,
        reset: true,
        method: 'put'
      })
      .catch(error => {
        errorHandler(error, this.notifications.pushError);
      });
  }

  @action.bound
  editSubscription() {
    this.showEditSubscriptionForm = true;
  }

  @action.bound
  instantiateSubscriptionEditForm() {
    this.subscriptionForm = new SubscriptionEditForm(
      {
        fields: subscriptionEditFormFields,
        rules: subscriptionEditFormRules,
        labels: subscriptionEditFormLabels,
        values: this.subscription.subscriptionEditFormValues
      },
      {
        options: subscriptionEditFormOptions,
        plugins: subscriptionEditFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound
  updateBillingPeriodType(periodType) {
    this.subscriptionForm.update({
      periodType: periodType
    });
  }

  @action.bound
  updateAutoRenew(bool) {
    this.subscriptionForm.update({
      autoRenew: bool
    });
  }

  @action.bound
  clearSubscriptionEditForm() {
    this.subscriptionForm.reset();
    this.resetEditTrialEndDate();
    this.showEditSubscriptionForm = false;
    this.cachedTrialEndDate = null;
  }

  @action.bound
  submitSubscriptionEditForm(e) {
    e.preventDefault();
    e.stopPropagation();

    if (
      this.subscription.onAnnual &&
      this.subscriptionForm.$('periodType').value === 'MONTHLY'
    ) {
      this.showModal('superAdminAnnualToMonthlyModal');
      return;
    }

    this.subscriptionForm.submit({
      onSuccess: this.submitSubscriptionEditFormSuccess,
      onError: this.submitSubscriptionEditFormError
    });
  }

  @action.bound
  approveAnnualToMonthly() {
    this.hideActiveModal();
    this.submitSubscriptionEditFormSuccess();
  }

  @action.bound
  submitSubscriptionEditFormSuccess() {
    const salesRep = this.rootStore.superAdminSalesReps.get(
      this.subscriptionForm.$('salesRepresentative').value
    );

    let payload = Object.assign(
      {},
      this.subscription.asFormValues,
      this.subscriptionForm.values(),

      {
        salesRepresentative: {
          id: salesRep && salesRep.id,
          name: salesRep && salesRep.name
        }
      },
      {
        startDate: moment(this.subscriptionForm.$('startDate').value).format(
          'YYYY-MM-DD'
        )
      },
      {
        endDate: moment(this.subscriptionForm.$('endDate').value).format(
          'YYYY-MM-DD'
        )
      },
      {
        promoEndDate: this.subscriptionForm.$('isPromo').value
          ? moment(this.subscriptionForm.$('promoEndDate').value).format(
              'YYYY-MM-DD'
            )
          : null
      },
      {
        discount: this.subscriptionForm.$('discount').value
          ? this.subscriptionForm.$('discount').value
          : 0
      },
      {
        trialEndDate: moment(
          this.subscriptionForm.$('trialEndDate').value
        ).format('YYYY-MM-DD')
      }
    );

    // don't send scheduledPlan if no promoEndDate since backend has:
    // constraint [null] ConstraintViolationException: could not execute statement
    if (
      Boolean(this.subscriptionForm.$('isPromo').value) &&
      Boolean(this.subscriptionForm.$('scheduledBillingPlanKey').value)
    ) {
      payload.scheduledPlan = {
        upcomingBillingPlanKey: this.subscriptionForm.$(
          'scheduledBillingPlanKey'
        ).value,
        transitionDate: moment(
          this.subscriptionForm.$('promoEndDate').value
        ).format('YYYY-MM-DD')
      };
    } else {
      delete payload.scheduledPlan;
    }

    this.saving = true;

    this.subscription
      .save(payload, {
        wait: true,
        reset: true,
        method: 'put'
      })
      .then(() => {
        runInAction(async () => {
          if (this.featureFlags.FF_TIME_CLOCK && this.isSuperAdmin) {
            await this.company.save({
              companyAddOns: {
                hasTimeClockAccess: this.subscriptionForm.$(
                  'hasTimeClockAccess'
                ).value
              }
            });
            this.company.fetch();
          }
          this.showEditSubscriptionForm = false;
          this.fetchBillingData();
          this.saving = false;
          this.showModal('subscriptionConfirmation');
        });
      })
      .catch(error => {
        runInAction(() => {
          this.saving = false;
          this.showEditSubscriptionForm = false;
          errorHandler(error, this.notifications.pushError);
          //Refetch transactions in order to show a transaction with the FAILURE status
          this.transactions.fetch({
            params: {
              limit: 1000
            }
          });
        });
      });
  }

  @action.bound
  submitSubscriptionEditFormError() {
    console.log(this.subscriptionForm.errors());
  }

  @action.bound
  fetchSalesRepresentatives() {
    if (!this.rootStore.superAdminSalesReps.hasModels) {
      this.rootStore.superAdminSalesReps.fetch({
        params: {
          limit: 100
        }
      });
    }
  }

  /**
   * Record Payment
   */

  @action.bound
  showSuperAdminSubscriptionRecordPaymentModal() {
    this.instantiateRecordPaymentForm();
    this.showModal('superAdminSubscriptionRecordPaymentModal');
  }

  @action.bound
  instantiateRecordPaymentForm() {
    this.recordPaymentForm = new RecordPaymentForm(
      {
        fields: recordPaymentFormFields,
        rules: recordPaymentFormRules,
        labels: recordPaymentFormLabels,
        values: recordPaymentFormValues
      },
      {
        options: recordPaymentFormOptions,
        plugins: recordPaymentFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound
  submitRecordPaymentForm() {
    this.recordPaymentForm.submit({
      onSuccess: this.submitRecordPaymentFormSuccess,
      onError: this.submitRecordPaymentFormError
    });
  }

  @action.bound
  submitRecordPaymentFormSuccess() {
    this.paying = true;
    let payload;

    if (this.recordPaymentForm.$('paymentType').value === 'CHECK') {
      payload = Object.assign(this.recordPaymentForm.values(), {
        amount: Number(this.recordPaymentForm.$('amount').value) * 100
      });
    } else {
      payload = {
        paymentType: this.recordPaymentForm.$('paymentType').value,
        amount: Number(this.recordPaymentForm.$('amount').value) * 100, // convert $ to cents,
        comment: this.recordPaymentForm.$('comment').value
      };
    }

    // backend expects YYYY-MM-DD format
    payload.paymentDate = moment(
      this.recordPaymentForm.$('paymentDate').value
    ).format(`YYYY-MM-DD`);

    request
      .post(`ra/sadmin/companies/${this.company.id}/billing/payments`, payload)
      .then(
        response => {
          runInAction(() => {
            this.hideActiveModal();
            this.paying = false;
            this.transactions.fetch({
              params: {
                limit: 1000
              }
            });
            this.notifications.pushNotification({
              snackbar: 'warning',
              icon: 'checkmark',
              title: 'Transaction Made Successfully.'
            });
          });
        },
        error => {
          this.paying = false;
          errorHandler(error, this.notifications.pushError);
        }
      );
  }

  @action.bound
  submitRecordPaymentFormError() {
    console.log(this.recordPaymentForm.errors());
  }

  @computed get cannotRecordCardPayment() {
    return (
      this.recordPaymentForm.$('paymentType').value === 'CREDIT_CARD' &&
      this.paymentMethods.isEmpty
    );
  }

  /**
   * Trial
   */
  @action.bound
  openEditTrialModal() {
    this.showModal('superAdminEditTrialModal');

    this.cachedTrialEndDate = moment(
      this.subscription.subscriptionEditFormValues.trialEndDate
    ).format('YYYY-MM-DD');

    this.editTrialForm = new SubscriptionEditTrialForm(
      {
        fields: subscriptionEditTrialFormFields,
        rules: subscriptionEditTrialFormRules,
        labels: subscriptionEditTrialFormLabels,
        values: {
          trialEndDate: moment(
            this.subscription.asFormValues.trialEndDate
          ).format('YYYY-MM-DD')
        }
      },
      {
        options: subscriptionEditTrialFormOptions,
        plugins: subscriptionEditTrialFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound submitEditTrialForm(e) {
    e.preventDefault();
    this.editTrialForm.submit({
      onSuccess: this.submitEditTrialFormSuccess,
      onError: this.submitEditTrialFormError
    });
  }

  @action.bound submitEditTrialFormSuccess() {
    const { trialEndDate } = this.editTrialForm.values();

    this.subscription.trialEndDate = moment(trialEndDate).format('MM/DD/YYYY');

    this.hideActiveModal();

    this.subscriptionForm
      .$('trialEndDate')
      .set(this.subscription.asFormValues.trialEndDate);
  }

  @action.bound submitEditTrialForError() {
    console.log(this.editTrialForm.errors());
    this.cancelEditTrialForm();
  }

  @action.bound
  resetEditTrialEndDate() {
    this.subscription.trialEndDate = this.cachedTrialEndDate;
    this.subscriptionForm.$('trialEndDate').set(this.cachedTrialEndDate);
  }

  @action.bound
  cancelEditTrialForm() {
    this.hideActiveModal().then(() => {
      runInAction(() => {
        this.resetEditTrialEndDate();
      });
    });
  }

  @action.bound
  async fetchBillingData() {
    this.loading = true;
    const fetchPromises = [];
    if (!this.subscriptionPlans.length) {
      fetchPromises.push(
        this.subscriptionPlans
          ?.fetch({
            params: {
              mn: 'full',
              mc: 'full'
            }
          })
          .then(() => {
            this.clearSubscriptionPlansFailedToFetch();
          })
          .catch(error => {
            this.setSubscriptionPlansFailedToFetch();
          })
      );
    }

    fetchPromises.push(
      this.paymentMethods
        ?.fetch({
          mn: 'full',
          mc: 'full'
        })
        .then(() => {
          this.clearPaymentMethodsFailedToFetch();
        })
        .catch(error => {
          this.setPaymentMethodsFailedToFetch();
        })
    );

    fetchPromises.push(
      this.transactions
        ?.fetch({
          params: {
            limit: 1000
          }
        })
        .then(() => {
          this.clearTransactionHistoryFailedToFetch();
        })
        .catch(error => {
          this.setTransactionHistoryFailedToFetch();
        })
    );

    fetchPromises.push(
      this.upcomingTransactions
        ?.fetch({
          mn: 'full',
          mc: 'full',
          limit: 12
        })
        .catch(error => {
          errorHandler(error, this.rootStore.notificationsUI.pushError);
        })
    );

    await Promise.all(fetchPromises);

    this.loading = false;
  }

  @computed
  get lastCardTransaction() {
    const cardPaymentType = this.transactions.models.filter(
      transaction =>
        transaction.paymentType === 'CREDIT_CARD' &&
        transaction.status === 'SUCCESS'
    );

    if (!cardPaymentType.length) return null;

    const sortedCardPaymentType = orderBy(
      cardPaymentType,
      ['createdTimeStamp'],
      ['desc']
    );

    return sortedCardPaymentType[0].lastFour;
  }

  @computed
  get formattedSubscriptionState() {
    return this.subscription.subscriptionState.replace('_', ' ');
  }

  @computed
  get hasUpcomingTransactions() {
    return this.upcomingTransactions.length > 0 && !this.hideScheduledPayments;
  }

  @computed
  get hideScheduledPayments() {
    return (
      this.subscription.subscriptionState === 'TRIAL' ||
      this.subscription.subscriptionState === 'ON_HOLD' ||
      this.subscription.subscriptionState === 'CANCELLED'
    );
  }

  @action.bound
  resolveSubscriptionClick() {
    this.resolvingSubscription = true;

    this.subscription
      .save(
        Object.assign(
          {},
          this.subscription.asFormValues,

          {
            subscriptionState: 'ACTIVE'
          }
        ),
        {
          wait: true,
          reset: true,
          method: 'put'
        }
      )
      .then(() => {
        runInAction(() => {
          this.resolvingSubscription = false;
          this.fetchBillingData();

          this.showModal('subscriptionConfirmation');
        });
      })
      .catch(error => {
        this.resolvingSubscription = false;
        errorHandler(error, this.notifications.pushError);
      });
  }

  @action.bound
  refundTransaction(transaction) {
    this.superAdminRefundTransactionForm = new RefundTransactionForm(
      {
        fields: refundTransactionFormFields,
        rules: refundTransactionFormRules,
        labels: refundTransactionFormLabels,
        values: {
          refundId: transaction.id,
          amount: numberWithCommas(transaction.amount / 100)
        }
      },
      {
        options: refundTransactionFormOptions,
        plugins: refundTransactionFormPlugins
      }
    );

    this.showModal('superAdminRefundTransactionModal');
  }

  @action.bound
  closeRefundTransactionModal() {
    return this.hideActiveModal().then(() => {
      this.superAdminRefundTransactionForm = null;
    });
  }

  @computed
  get disableSubmittingRefundTransactionForm() {
    return !this.superAdminRefundTransactionForm.isValid;
  }

  @action.bound
  submitSuperAdminRefundTransactionForm() {
    this.superAdminRefundTransactionForm.submit({
      onSuccess: this.submitSuperAdminRefundTransactionFormSuccess,
      onError: this.submitSuperAdminRefundTransactionFormError
    });
  }

  @computed
  get refundTransactionUrl() {
    return `ra/sadmin/companies/${this.company.id}/billing/payments/refund`;
  }

  @action.bound
  async submitSuperAdminRefundTransactionFormSuccess() {
    let refundPayload = this.superAdminRefundTransactionForm.values();
    refundPayload.amount =
      Number(refundPayload.amount.replace(/,/g, '')) * 100 * -1; // Use replace to remove any commas from numbers if they exist.

    try {
      this.refundProcessing = true;
      const response = await request.post(
        this.refundTransactionUrl,
        refundPayload
      );
      this.transactions.add(response.data);
      this.refundProcessing = false;
      this.closeRefundTransactionModal();
    } catch (error) {
      this.refundProcessing = false;
      errorHandler(error, this.rootStore.notificationsUI.pushError);
    }
  }

  @action.bound
  deleteTransaction(transaction) {
    this.showModal('superAdminDeleteTransactionModal');
    this.transactionToDelete = transaction;
  }

  @action.bound
  confirmDeleteTransaction() {
    this.transactionToDelete
      .destroy()
      .then(() => {
        runInAction(() => {
          this.hideDeleteTransactionModal();
          this.rootStore.notificationsUI.pushNotification({
            showUndo: false,
            title: t('Transaction Deleted')
          });
          this.transactions.fetch({
            params: {
              limit: 1000
            }
          });
        });
      })
      .catch(error => {
        errorHandler(error, this.rootStore.notificationsUI.pushError);
      });
  }

  @action.bound
  hideDeleteTransactionModal() {
    return this.hideActiveModal().then(() => {
      this.transactionToDelete = null;
    });
  }

  @action.bound
  showDeleteCardModal(card) {
    this.cardToDelete = card;
    this.showModal('superAdminDeleteCardModal');
  }

  @action.bound
  hideDeleteCardModal() {
    this.cardToDelete = null;
    this.hideActiveModal();
  }

  @action.bound
  deleteCreditCard() {
    this.cardToDelete
      .destroy({ wait: true })
      .then(() => {
        this.hideDeleteCardModal();
        this.notifications.pushNotification({
          showUndo: false,
          title: t('Credit card successfully removed')
        });
      })
      .catch(error => errorHandler(error, this.notifications.pushError));
  }

  @action.bound
  refundOrDeleteTransaction(model) {
    if (model.allowToRefund) {
      this.refundTransaction(model);
    }
    if (model.allowToDelete) {
      this.deleteTransaction(model);
    }
  }

  /**
   * Return the plan selected in the form
   */
  @computed
  get scheduledPlanFromForm() {
    const form = this.subscriptionForm;

    if (!form || !form.has('scheduledBillingPlanKey')) return null;

    return this.subscriptionPlans.getPlan(
      form.$('scheduledBillingPlanKey').value
    );
  }

  /**
   * Calculates the cost of the scheudled plan and quantity with no discount applied
   */
  @computed get scheduledBillWithoutDiscount() {
    const quantity = this.seatCountFromForm;
    const plan = this.scheduledPlanFromForm;

    if (!plan) return null;

    let annualTotal = (plan.annualSeatPrice * quantity * 12) / 100;
    let monthlyTotal = (plan.seatPrice * quantity) / 100;

    return {
      monthly: monthlyTotal,
      annually: annualTotal
    };
  }

  /**
   * Calculates amount saved with a coupon applied
   */
  @computed get scheduledBillDiscount() {
    const discount = this.discountFromForm;
    const scheduledBillWithoutDiscount = this.scheduledBillWithoutDiscount;

    if (!discount || !scheduledBillWithoutDiscount) return null;

    let annualTotal = scheduledBillWithoutDiscount.annually;
    let monthlyTotal = scheduledBillWithoutDiscount.monthly;

    return {
      monthly: monthlyTotal * (discount / 100),
      annually: annualTotal * (discount / 100)
    };
  }

  /**
   * Calculates the cost of the scheduled plan and quantity with discount applied
   */
  @computed get scheduledBill() {
    const discount = this.discountFromForm;
    const scheduledBillWithoutDiscount = this.scheduledBillWithoutDiscount;
    const scheduledBillDiscount = this.scheduledBillDiscount;

    if (!scheduledBillWithoutDiscount) return null;

    let annualTotal = scheduledBillWithoutDiscount.annually;
    let monthlyTotal = scheduledBillWithoutDiscount.monthly;

    if (discount) {
      annualTotal -= scheduledBillDiscount.annually;
      monthlyTotal -= scheduledBillDiscount.monthly;
    }

    return {
      monthly: monthlyTotal,
      annually: annualTotal
    };
  }

  @computed
  get salesRepSorted() {
    const salesReps =
      this.rootStore?.superAdminSalesReps?.models?.slice() || [];

    // https://stackoverflow.com/questions/37848030/lodash-how-to-do-a-case-insensitive-sorting-on-a-collection-using-orderby
    return orderBy(salesReps, [rep => rep.name?.toLowerCase()]);
  }

  @computed
  get salesRepOptions() {
    const options = this.salesRepSorted;

    if (this.salesRepSearchText) {
      const query = this.salesRepSearchText.toLowerCase();

      const newOptions = options.filter(option => {
        const name = option.name.toLowerCase();

        return name.indexOf(query) >= 0;
      });

      return newOptions;
    }

    return options;
  }

  @computed
  get extendedPermissions() {
    return this.parent.extendedPermissions;
  }

  @action.bound
  openSubscriptionDateModal() {
    const date = this.company?.subscription?.startDate || '';
    this.subscriptionStartDate = moment(date).format('YYYY-MM-DD');
    this.showModal('editSubscriptionDate');
  }

  @action.bound
  handleSaveSubscriptionDateModal() {
    return new Promise(async resolve => {
      try {
        this.saving = true;

        await request.post(
          `/ra/sadmin/companies/${this.company.id}/billing/update-subscription-start-date`,
          { subscriptionStartDate: this.subscriptionStartDate }
        );

        // Refetch company so that we can display the new subscription date
        await this.parent.fetchCompany(this.company.id);

        resolve(true);
      } catch (error) {
        errorHandler(error, this.notifications.pushError);
        resolve(false);
      } finally {
        this.saving = false;
        this.hideActiveModal();
      }
    });
  }

  @action.bound
  addSeatsClick() {
    const { subscription, paymentMethods } = this;

    this.showModal('addSeats');

    if (
      (paymentMethods.isEmpty &&
        subscription.billingPlatform === 'CREDIT_CARD') ||
      subscription.billingPlatform === 'IN_APP'
    ) {
      this.subscriptionForm = new SubscriptionForm(
        {
          fields: subscriptionFormFields.concat(paymentMethodFormFields),
          rules: Object.assign(
            {},
            subscriptionFormRules,
            paymentMethodFormRules
          ),

          labels: Object.assign(
            {},
            subscriptionFormLabels,
            paymentMethodFormLabels
          ),

          values: Object.assign(
            {},
            subscriptionFormValues,
            paymentMethodFormValues,
            subscription.asFormValues,
            {
              seats: 0
            }
          )
        },
        {
          options: Object.assign(
            {},
            subscriptionFormOptions,
            paymentMethodFormOptions
          ),

          plugins: Object.assign(
            {},
            subscriptionFormPlugins,
            paymentMethodFormPlugins
          )
        }
      );
    } else {
      this.subscriptionForm = new SubscriptionForm(
        {
          fields: subscriptionFormFields,
          rules: subscriptionFormRules,
          labels: subscriptionFormLabels,
          values: Object.assign(
            {},
            subscriptionFormValues,
            subscription.asFormValues,
            {
              seats: 0
            }
          )
        },
        {
          options: subscriptionFormOptions,
          plugins: subscriptionFormPlugins
        }
      );
    }
  }

  @action.bound
  addSeatsSubmit() {
    this.subscriptionForm.submit({
      onSuccess: this.addSeatsSubmitSuccess,
      onError: this.subscriptionSubmitError
    });
  }

  @action.bound
  addSeatsSubmitSuccess() {
    const values = this.subscriptionForm.values();

    const { subscription, transactions } = this;

    const { upcomingPlan, plan } = subscription;

    const payload = {
      ...subscription.asFormValues,
      seats: parseInt(subscription.seats, 10) + parseInt(values.seats, 10),
      periodType: subscription.upcomingPeriodType || subscription.periodType,
      billingPlanKey: upcomingPlan ? upcomingPlan.planKey : plan.planKey,
      billingPlanName: upcomingPlan ? upcomingPlan.name : plan.name
    };

    subscription
      .save(payload, {
        wait: true,
        method: 'put'
      })
      .then(model => {
        runInAction(() => {
          this.showModal('addSeatsConfirmation');

          this.subscriptionForm.update({
            seats: model.seats
          });

          transactions.fetch({
            params: {
              limit: 1000
            }
          });

          this.fetchChildBillingData();
        });
      })

      .catch(error => {
        transactions.fetch({
          params: {
            limit: 1000
          }
        });

        errorHandler(error, this.notifications.pushError);
      });
  }

  @computed get showCompanyAddOns() {
    return this.subscriptionForm.$('billingPlanKey').value === 'performance';
  }
}
