import { action, computed, observable, reaction } from 'mobx';
import SettingsChildUI from './SettingsChildUI';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import history from 'utils/history';

import { callTrack } from 'utils/segmentIntegration';
import {
  PROJECT_LOCATIONS_FEATURE_VIEWED,
  PROJECT_LOCATION_DELETED,
  PROJECT_LOCATION_ADDED,
  PROJECT_LOCATION_EDITED
} from 'utils/segmentAnalytics/eventSpec';

import {
  LocationForm,
  locationFormOptions,
  locationFormFields,
  locationFormRules,
  locationFormPlugins
} from 'forms/location';
import ProjectLocations from 'stores/collections/ProjectLocations';
import ProjectLocation from 'stores/models/ProjectLocation';

export default class SettingsLocationssUI extends SettingsChildUI {
  @observable loading;
  @observable locationForm;
  @observable selectedProjectLocation;
  @observable position;
  @observable projectLocations;
  @observable treeElementsMap;
  @observable expanded;

  constructor(options) {
    super(options);

    this.loading = true;
    this.projectLocations = new ProjectLocations(null, {
      parent: this,
      rootStore: this.rootStore
    });
    this.selectedProjectLocation = null;
    this.position = null;
    this.treeElementsMap = new Map();
    this.expanded = [];
  }

  @action.bound setup(ids) {
    this.fetchLocations();
    this.setupReactions();
    this.setupForm(ids);

    callTrack(PROJECT_LOCATIONS_FEATURE_VIEWED, {
      project_id: this.project.id,
      project_name: this.project.name
    });
  }

  @action.bound setupForm(ids) {
    this.locationForm = new LocationForm(
      {
        fields: locationFormFields,
        rules: locationFormRules
      },
      {
        options: locationFormOptions,
        plugins: locationFormPlugins,
        rootStore: this.rootStore
      }
    );

    ids?.parentId && this.locationForm.$('parent').set(ids.parentId);
    ids?.id &&
      this.locationForm.$('locationName').set(this.getLocationName(ids.id));
  }

  setupReactions() {
    this.reactToParentLocation = reaction(
      () => this.parentLocationName,
      parentLocation => {
        if (parentLocation.name === this.locationOptions[0].name) {
          this.locationForm.$('parent').reset();
          this.locationForm.$('parent').resetValidation();
        }
      }
    );
  }

  tearDownReactions() {
    this.reactToParentLocation && this.reactToParentLocation();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  @action.bound
  async fetchLocations() {
    try {
      await this.projectLocations.fetch({
        params: {
          limit: 1000,
          mc: 'full',
          mn: 'full'
        }
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.loading = false;
    this.saving = false;
    this.position = null;
    this.entryForAdd = null;
  }

  @computed get headerTitle() {
    return t('Manage project location hierarchy');
  }

  @computed get hasWriteAccess() {
    return this.authorization.canCUDProjectLocations;
  }

  @computed get showEmptyState() {
    return !this.loading && !this.hasLocations;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @computed get hasLocations() {
    return this.projectLocations.hasModels;
  }

  @computed get parentLocationName() {
    return (
      this.locationOptions.find(
        parent => parent.value === this.locationForm.$('parent').value
      ) || this.locationOptions[0]
    );
  }

  @computed
  get newLocationIsInValid() {
    return (
      this.locationForm.$('locationName').check('hasError') ||
      this.locationForm.$('locationName').check('isPristine')
    );
  }

  @computed
  get projectLocationItems() {
    return this.projectLocations.models;
  }

  @computed
  get locationOptions() {
    return [
      {
        name: t('New location'),
        value: null
      },
      ...this.getOptions(this.projectLocationItems)
    ];
  }

  @action.bound
  onBlur() {
    if (this.locationForm.$('locationName').check('isEmpty')) {
      this.resetlocationForm();
    }
  }

  @action.bound
  resetlocationForm() {
    this.locationForm.$('locationName').reset();
    this.locationForm.$('locationName').resetValidation();
  }

  @action.bound refetchProjectLocations() {
    this.fetchLocations();
  }

  @action.bound async addProjectLocation(location) {
    await this.authorization.checkFeatureAccess('CUDProjectLocations');

    history.push({
      pathname: `${this.project.viewUrl}/settings/general/locations/add/${location.uuid}`,
      search: this.baseQueryParams
    });
  }

  @action.bound cancelProjectLocationUpdate() {
    history.push({
      pathname: `${this.project.viewUrl}/settings/general/locations/`,
      search: this.baseQueryParams
    });
    this.resetlocationForm();
  }

  @action.bound
  submitProjectLocationAdd(e) {
    e.preventDefault();
    e.stopPropagation();

    this.locationForm.submit({
      onSuccess: this.handleAddNewLocation,
      onError: e => {
        console.error(this.locationForm.errors());
      }
    });
  }

  @action.bound async editProjectLocation(location) {
    await this.authorization.checkFeatureAccess('CUDProjectLocations');

    this.selectedProjectLocation = location;
    history.push({
      pathname: `${this.project.viewUrl}/settings/general/locations/edit/${location.parentUuid}/${location.uuid}`,
      search: this.baseQueryParams
    });
  }

  @action.bound
  submitProjectLocationEdit(e) {
    e.preventDefault();
    e.stopPropagation();
    this.locationForm.submit({
      onSuccess: this.submitProjectLocationEditSuccess,
      onError: e => {
        console.error(this.locationForm.errors());
      }
    });
  }

  @action.bound
  async submitProjectLocationEditSuccess() {
    this.saving = true;
    const { locationName, parent } = this.locationForm.trimmedValues();

    try {
      await this.selectedProjectLocation.save(
        {
          name: locationName,
          parentUuid: parent,
          position: this.position
        },
        {
          wait: true
        }
      );

      callTrack(PROJECT_LOCATION_EDITED, {
        project_id: this.project.id,
        project_name: this.project.name,
        location_name: locationName
      });

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Project location updated')
      });

      this.cancelProjectLocationUpdate();
      this.refetchProjectLocations();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
      this.position = null;
      this.resetlocationForm();
    }
  }

  @action.bound
  handleNodeSelect(e, node) {
    const nodeIndex = this.expanded.indexOf(node);
    if (nodeIndex >= 0) {
      this.expanded.splice(nodeIndex, 1);
    } else {
      this.expanded.push(node);
    }
  }

  @action.bound
  async handleAddNewLocation() {
    this.saving = true;
    const { parent, locationName } = this.locationForm.trimmedValues();
    this.entryForAdd = new ProjectLocation(
      {},
      {
        rootStore: this.rootStore
      }
    );
    try {
      const newAdd = await this.entryForAdd.save(
        {
          parentUuid: parent,
          name: locationName
        },
        {
          wait: true
        }
      );

      this.expanded.push(
        newAdd.parentUuid,
        ...this.getParentIds(newAdd.parentUuid)
      );

      callTrack(PROJECT_LOCATION_ADDED, {
        project_id: this.project.id,
        project_name: this.project.name,
        location_name: locationName
      });

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('New location added')
      });
      this.cancelProjectLocationUpdate();
      this.refetchProjectLocations();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
      this.resetlocationForm();
    }
  }

  @action.bound async deleteProjectLocation(projectLocation) {
    await this.authorization.checkFeatureAccess('CUDProjectLocations');

    this.selectedProjectLocation = projectLocation;
    this.showModal('DeleteModal');
  }

  @action.bound async confirmDeleteProjectLocation() {
    this.saving = true;

    try {
      await this.selectedProjectLocation.destroy({ wait: true });
      this.refetchProjectLocations();
      await this.hideActiveModal();

      callTrack(PROJECT_LOCATION_DELETED, {
        project_id: this.project.id,
        project_name: this.project.name,
        location_name: this.selectedProjectLocation.name
      });

      this.selectedProjectLocation = null;

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Project location deleted')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound async cancelDeleteProjectLocation() {
    await this.hideActiveModal();

    this.selectedProjectLocation = null;
  }

  @action.bound setSelectedLocation(projectLocation) {
    this.selectedProjectLocation = projectLocation;
  }

  @action.bound movePositon(oldPosition, newPosition, parentUuid) {
    if (!this.selectedProjectLocation || oldPosition === newPosition) return;
    this.treeElementsMap.set(parentUuid, [
      oldPosition,
      newPosition,
      this.selectedProjectLocation.id
    ]);
    this.position = newPosition;
    this.submitProjectLocationEditSuccess();
  }

  getParentIds(currentId, ids = []) {
    const parentId = this.locationOptions.find(item => item.value === currentId)
      ?.parent;
    if (parentId) {
      ids.push(parentId);
      this.getParentIds(parentId, ids);
    }
    return ids;
  }

  getOptions = (projectLocationItems, optionsArray = []) => {
    for (const item of projectLocationItems) {
      optionsArray.push({
        name: item.name,
        value: item.uuid,
        parent: item.parentUuid
      });
      item.levels ? this.getOptions(item.levels, optionsArray) : null;
    }
    return optionsArray;
  };

  getProjectLocationItems = levelItems =>
    levelItems.map(
      ({ uuid, name, parentUuid, position, fullPath, levels }) =>
        new ProjectLocation(
          {
            uuid,
            name,
            parentUuid,
            position,
            fullPath,
            levels
          },
          {
            rootStore: this.rootStore
          }
        )
    );

  getProjectLocationActions = projectLocation => {
    return [
      {
        title: t('Edit'),
        onClick: () => {
          this.editProjectLocation(projectLocation);
        }
      },
      {
        title: t('Delete'),
        onClick: () => {
          this.deleteProjectLocation(projectLocation);
        }
      }
    ];
  };

  getLocationName = id => {
    return (
      this.locationOptions.find(option => option.value === id)?.name ||
      t('New location')
    );
  };
}
