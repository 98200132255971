import styled, { css } from 'styled-components';
import { borderRadius } from 'polished';

export default styled.a`
  box-sizing: border-box;
  align-items: center;
  color: ${props =>
    props.color
      ? props.theme.colors[props.color]
      : props.theme.colors.charcoalGrey};
  cursor: pointer;
  display: flex;
  font-size: ${props => (props.size ? props.size / 10 : 1.4)}rem;
  font-weight: ${props => (props.default ? '700' : '300')};
  padding: ${props => (props.padding ? props.padding : '2rem')};
  line-height: 1;
  position: relative;
  text-decoration: none;
  text-transform: ${props => (props.case ? props.case : 'capitalize')};
  width: 100%;
  &:hover {
    background-color: ${props => props.theme.colors.blueAccent};
    box-shadow: inset 0 5px 5px -5px rgba(0, 0, 0, 0.5);
    color: ${props => props.theme.colors.white};
    font-weight: 700;
    &::after {
      border-top-color: ${props => props.theme.colors.blueAccent};
    }
    &:first-child {
      ${borderRadius('top', '8px')};
      box-shadow: inset 0 -5px 5px -5px rgba(0, 0, 0, 0.5);
      ${props =>
        props.hideArrow
          ? ''
          : css`
              &::before {
                content: '';
                position: absolute;
                z-index: 20;
                width: 0;
                height: 0;
                border-left: 20px solid transparent;
                border-right: 20px solid transparent;
                border-bottom: 30px solid
                  ${props => props.theme.colors.blueAccent};
                top: -21px;
                right: ${props => props.arrowPosition - 5 || 65}px;
              }
            `}
    }
    &:last-child {
      ${borderRadius('bottom', '8px')};
    }
    > svg {
      fill: ${props => props.theme.colors.white};
    }
  }
  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }
  &:last-child::after {
    display: none;
  }
`;
