import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';

const tasksFiltersFormPlugins = extendedPlugins;

const tasksFiltersFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const tasksFiltersFormFields = [
  'statusFilters[]',
  'assigneeFilters[]',
  'creatorFilters[]'
];

const tasksFiltersFormRules = {
  projectFilters: 'array',
  userFilters: 'array',
  projectStatusFilters: 'array'
};

class TasksFiltersForm extends MobxReactForm {}

export {
  TasksFiltersForm,
  tasksFiltersFormRules,
  tasksFiltersFormFields,
  tasksFiltersFormOptions,
  tasksFiltersFormPlugins
};
