import { RakenCollection } from '../lib';
import AnswerActivity from '../models/AnswerActivity';

export default class AnswersActivity extends RakenCollection {
  model() {
    return AnswerActivity;
  }

  url() {
    return `/ra/companies/${this.rootStore.me.company.uuid}/answers`;
  }
}
