import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import { action, computed } from 'mobx';

const timeEntryFormPlugins = extendedPlugins;

const timeEntryFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const timeEntryFormFields = [
  'uuid',
  'hours',
  'payType',
  'shift',
  'classification',
  'costCode'
];

const timeEntryFormRules = {
  hours: 'numeric|min:0|max:24|required',
  payType: 'required'
};

class TimeEntryForm extends MobxReactForm {
  constructor(settings, options) {
    super(settings, options);

    this.hasSavedShift = Boolean(settings.values?.shift);
    this.order = options.order;
  }
  @action.bound
  updateShift(shift) {
    if (!shift?.uuid) {
      this.$('shift').clear();
      return;
    }

    this.$('shift').set({
      uuid: shift.uuid,
      name: shift.name
    });
  }

  @action.bound
  updatePayType(payType) {
    if (!payType?.uuid) {
      this.$('payType').clear();
      return;
    }

    this.$('payType').set({
      uuid: payType.uuid,
      name: payType.name
    });
  }

  @action.bound
  updateClassification(classification) {
    if (!classification?.uuid) {
      this.$('classification').clear();
      return;
    }

    this.$('classification').set({
      uuid: classification.uuid,
      name: classification.name
    });
  }

  @action.bound
  updateCostCode(costCode) {
    if (!costCode) {
      this.$('costCode').clear();
      return;
    }
    this.$('costCode').set({
      division: costCode.division,
      code: costCode.code,
      description: costCode.projectOrCompanyDescription,
      uuid: costCode.uuid
    });
  }

  @computed
  get cleanedValues() {
    return {
      hours: this.$('hours').value,
      payType: { uuid: this.$('payType').value.uuid },
      classification: this.$('classification').value?.uuid
        ? { uuid: this.$('classification').value.uuid }
        : null,
      shift: this.$('shift').value?.uuid
        ? { uuid: this.$('shift').value.uuid }
        : null,
      costCode: this.$('costCode').value?.uuid
        ? { uuid: this.$('costCode').value.uuid }
        : null,
      ...(this.$('uuid').value && {
        uuid: this.$('uuid').value
      })
    };
  }
}

export {
  TimeEntryForm,
  timeEntryFormRules,
  timeEntryFormFields,
  timeEntryFormOptions,
  timeEntryFormPlugins
};
