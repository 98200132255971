import { action, computed } from 'mobx';
import ProjectChildEditUI from './ProjectChildEditUI';
import MaterialLog from '../../models/MaterialLog';

import history from 'utils/history';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';

import { callTrack } from 'utils/segmentIntegration';
import { MATERIAL_LOG_EDITED } from 'utils/segmentAnalytics/eventSpec';

import {
  MaterialLogForm,
  materialLogFormOptions,
  materialLogFormFields,
  materialLogFormRules,
  materialLogFormLabels,
  materialLogFormPlugins
} from 'forms/project/materialLog';

export default class MaterialLogEditUI extends ProjectChildEditUI {
  @action.bound setup(id) {
    this.materialSelectorUI.setup();
    this.costCodeSelectorUI.setup();
    this.fetchEntry(id);
  }

  @action.bound tearDown() {
    this.materialSelectorUI.tearDown();
    this.costCodeSelectorUI.tearDown();
    this.unblockHistory();
    this.clearUIState();
  }

  @action.bound async fetchEntry(uuid) {
    if (this.entryForEdit) return;

    let model = this.parent.materialLogs.getByIdOrUuid(uuid);

    if (!model) {
      model = new MaterialLog(
        {
          type: 'MaterialLog',
          uuid: uuid
        },
        {
          rootStore: this.rootStore
        }
      );
    }

    try {
      await model.fetch();

      this.setEntryForEdit(model);
    } catch (error) {
      this.cancelMaterialLogEdit();
    }
  }

  @action.bound setEntryForEdit(model) {
    this.projectUI.setDate(model.reportDate);
    this.projectUI.setSegmentUuid(model.segmentUuid);
    this.entryForEdit = model;

    this.entryEditForm = new MaterialLogForm(
      {
        fields: materialLogFormFields,
        rules: materialLogFormRules,
        values: this.entryForEdit.formValues,
        labels: materialLogFormLabels
      },
      {
        parentStore: this,
        options: materialLogFormOptions,
        plugins: materialLogFormPlugins
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @action.bound async submitEntryEditFormSuccess() {
    this.saving = true;

    const { duplicateMaterialLog } = this.entryEditForm;

    const values = this.entryEditForm.values();

    try {
      if (
        duplicateMaterialLog &&
        duplicateMaterialLog.uuid !== this.entryForEdit.uuid
      ) {
        await this.mergeEditedMaterialLog(values);
      } else {
        await this.entryForEdit.save(values, {
          wait: true
        });
      }

      callTrack(MATERIAL_LOG_EDITED);

      this.unblockHistory();
      this.parent.refetchMaterialLogs();
      this.cancelMaterialLogEdit();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Material log saved')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound mergeEditedMaterialLog(values) {
    const { duplicateMaterialLog } = this.entryEditForm;
    const { quantity, desc } = duplicateMaterialLog;

    const attachments = duplicateMaterialLog.attachments.models.map(
      attachment => {
        return {
          uuid: attachment.uuid
        };
      }
    );

    const newAttachments = this.entryForEdit.attachments.models.map(
      attachment => {
        return {
          uuid: attachment.uuid
        };
      }
    );

    const quantityResult = +(
      parseFloat(quantity) + parseFloat(values.quantity)
    ).toFixed(2);

    this.entryForEdit.destroy({
      url: `${this.rootStore.urlMicroService(
        'performanceTracking'
      )}/companies/${this.company.uuid}/materiallogs/${
        this.entryForEdit.uuid
      }?keepAttachments=true`
    });

    return duplicateMaterialLog.save(
      {
        quantity: quantityResult,
        desc: this.concatenateDescriptions(desc, values.desc),
        attachments: attachments.concat(newAttachments)
      },
      {
        wait: true
      }
    );
  }

  concatenateDescriptions(descOne, descTwo) {
    if (descOne && descTwo) {
      return descOne + '\n\n' + descTwo;
    }

    return descOne || descTwo || '';
  }

  @action.bound cancelMaterialLogEdit() {
    history.push({
      pathname: `${this.project.viewUrl}/materials`,
      search: this.baseQueryParams
    });
  }

  @computed get title() {
    if (this.hasWriteAccess) {
      return t('Edit material log');
    }

    return t('View material log');
  }

  @computed get materialsUI() {
    return this.parent.materialsUI;
  }
}
