import { RakenCollection } from '../../lib';
import SuperAdminBillingUpcomingTransaction from '../../models/superAdmin/SuperAdminBillingUpcomingTransaction';

export default class SuperAdminBillingUpcomingTransactions extends RakenCollection {
  model() {
    return SuperAdminBillingUpcomingTransaction;
  }

  url() {
    return `ra/sadmin/companies/${
      this.parent.company.uuid
    }/billing/upcoming-transactions`;
  }
}
