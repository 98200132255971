import request from 'axios';
import moment from 'moment';
import omit from 'lodash.omit';
import orderBy from 'lodash.orderby';
import { action, observable, computed, reaction } from 'mobx';

import InsightsChart from './InsightsChart';
import InsightsPeriods from '../collections/InsightsPeriods';

import { t } from 'utils/translate';
import errorHandler from 'utils/errorHandler';
import escaperegexp from 'lodash.escaperegexp';
import ProjectMeasure from './ProjectMeasure';

export default class InsightsChartWithDateRanges extends InsightsChart {
  // Average line selection
  @observable withAverageLine;
  @observable averageLineSelection;
  @observable averageLineSelectedProjects;
  @observable averageLineProjectSearch;

  // Chart
  @observable chartStat;

  // Insights by day breakdown
  @observable fetchingInsightsByDay;

  constructor(attributes, options) {
    super(attributes, options);

    // Average line selection
    this.withAverageLine = options.withAverageLine || false;
    this.averageLineSelection = 'CHART';
    this.averageLineSelectedProjects = [];
    this.averageLineProjectSearch = '';

    // Chart
    this.chartStat = options.chartStat || null;

    // Insights By Day
    this.fetchingInsightsByDay = true;

    this.insightsByDay = new InsightsPeriods(null, {
      rootStore: this.rootStore,
      parent: this
    });

    // Insights Periods Collection
    this.insightsPeriods = new InsightsPeriods(null, {
      rootStore: this.rootStore,
      parent: this
    });

    // React to selection changes
    this.reactToSelection = reaction(
      () => this.params,
      params => {
        if (params.projectIds || params.projectStatuses) {
          this.fetchStats();
        } else {
          this.cancelRequest();
          this.insightsByDay.reset();
          this.insightsPeriods.reset();
        }
      },
      { fireImmediately: true }
    );
  }

  url() {
    return '/ra/insights/statsByProject';
  }

  @action.bound
  fetchStats() {
    this.parent.fetchStatsForAllCharts();
  }

  @computed
  get isFetching() {
    return Boolean(this.fetching || this.parent.fetchingAllCharts);
  }

  @action.bound
  parse(attributes) {
    this.parseInsightsPeriods(attributes.insightsPeriods);

    return {
      ...omit(attributes, ['insightsPeriods'])
    };
  }

  @action.bound
  parseInsightsPeriods(insightsPeriods) {
    this.insightsPeriods.reset(insightsPeriods || []);
  }

  @computed
  get groupBy() {
    const diff = moment(this.insightsUI.endDay, 'YYYY-MM-DD').diff(
      moment(this.insightsUI.startDay, 'YYYY-MM-DD'),
      'days'
    );

    if (diff < 90) {
      return 'DAY';
    } else if (diff < 730) {
      return 'MONTH';
    } else {
      return 'YEAR';
    }
  }

  @computed
  get interval() {
    switch (this.insightsUI.timeFrameSelection) {
      case 'LAST_14_DAYS':
        return 2;
      case 'LAST_4_WEEKS':
      case 'THIS_MONTH':
      case 'LAST_MONTH':
      case 'LAST_3_MONTHS':
        return 7;
      default:
        return 1;
    }
  }

  @computed
  get params() {
    const params = {
      startDay: this.insightsUI.startDay,
      endDay: this.insightsUI.endDay,
      include: this.chartStat,
      groupBy: this.groupBy,
      interval: this.interval,
      includeTeamMeasures: false
    };

    switch (this.insightsUI.projectSelection) {
      case 'ALL':
        params.projectStatuses = 'ACTIVE,INACTIVE';
        break;
      case 'ACTIVE':
        params.projectStatuses = 'ACTIVE';
        break;
      default:
        params.projectIds = this.insightsUI.filteredProjectIds.join(',');
        break;
    }

    return params;
  }

  @action.bound
  setAverageLineSelection(selection) {
    if (this.parent.applySelectionsToAllCharts) {
      this.parent.setAverageLineSelectionOnAllCharts(selection, this);
    } else {
      if (selection === 'SELECTED') {
        this.averageLineSelectedProjects.replace(
          this.chartProjects.slice(0, 5)
        );
      }

      this.averageLineSelection = selection;
    }
  }

  @computed
  get averageLineProjects() {
    switch (this.averageLineSelection) {
      case 'CHART':
        return this.chartProjects;
      case 'ALL':
        return this.insightsUI.filteredProjects;
      default:
        return this.averageLineSelectedProjects;
    }
  }

  @computed
  get averageLineSelectionReadable() {
    switch (this.averageLineSelection) {
      case 'CHART':
        return t('Projects Displayed on the Chart');
      case 'ALL':
        return t('All Projects Selected Above');
      default:
        return t('Selected Projects');
    }
  }

  @action.bound
  toggleAverageLineProject(project) {
    if (this.averageLineSelectedProjects.includes(project)) {
      if (this.parent.applySelectionsToAllCharts) {
        this.parent.removeAverageLineProjectFromAllCharts(project);
      } else {
        this.averageLineSelectedProjects.remove(project);
      }
    } else {
      if (this.parent.applySelectionsToAllCharts) {
        this.parent.pushAverageLineProjectToAllCharts(project);
      } else {
        this.averageLineSelectedProjects.push(project);
      }
    }
  }

  @action.bound
  setAverageLineProjectSearch(value) {
    this.averageLineProjectSearch = value;
  }

  @action.bound
  clearAverageLineProjectSearch() {
    this.averageLineProjectSearch = '';
  }

  @computed
  get searchedAverageLineProjects() {
    const searchExpression = new RegExp(
      escaperegexp(this.averageLineProjectSearch),
      'i'
    );

    return this.insightsUI.filteredProjects.filter(project => {
      return project.name.search(searchExpression) !== -1;
    });
  }

  @computed
  get hasInsightsPeriods() {
    return this.insightsPeriods && this.insightsPeriods.length > 0;
  }

  @computed
  get dateRanges() {
    return this.insightsPeriods.models.map(
      insightsPeriod => insightsPeriod.dateRange
    );
  }

  @computed
  get projectTableSeries() {
    return this.insightsUI.filteredProjects.map((project, index) => {
      return {
        id: project.parentProjectId,
        uuid: project.uuid,
        name: project.name,
        viewUrl: project.viewUrl,
        data: this.insightsPeriods.models.map(insightsPeriod => {
          const relatedProjectMeasure = insightsPeriod.projectMeasures.models.find(
            projectMeasure => {
              return projectMeasure.project.id === project.parentProjectId;
            }
          );

          if (relatedProjectMeasure) {
            return {
              projectMeasure: relatedProjectMeasure,
              y: relatedProjectMeasure.stats[this.chartStat]
            };
          } else {
            return {
              projectMeasure: new ProjectMeasure(
                {
                  project: {
                    id: project.parentProjectId,
                    uuid: project.uuid,
                    name: project.name,
                    viewUrl: project.viewUrl
                  },
                  stats: {
                    [this.chartStat]: 0
                  }
                },
                {
                  collection: insightsPeriod.projectMeasures,
                  rootStore: this.rootStore
                }
              ),
              y: 0
            };
          }
        })
      };
    });
  }

  @computed
  get projectTableSeriesTotal() {
    let total = 0;

    this.projectTableSeries.forEach(projectTableSeries => {
      projectTableSeries.data.forEach(data => {
        total += data.y;
      });
    });

    return total;
  }

  @computed
  get hasDataWithValues() {
    return this.projectTableSeriesTotal > 0;
  }

  @computed
  get selectedProjectTableSeries() {
    return this.chartProjects.map((project, index) => {
      const projectTableSeries = this.projectTableSeries.find(
        series => series.id === project.parentProjectId
      );

      return {
        ...projectTableSeries,
        index
      };
    });
  }

  @computed
  get selectedProjectTableSeriesTotal() {
    let total = 0;

    this.selectedProjectTableSeries.forEach(projectTableSeries => {
      projectTableSeries.data.forEach(data => {
        total += data.y;
      });
    });

    return total;
  }

  @computed
  get sortedSelectedProjectTableSeries() {
    let series;

    if (this.sortField === 'name') {
      series = orderBy(
        this.selectedProjectTableSeries,
        [series => series.name.toLowerCase()],
        [this.sortDirection]
      );
    } else {
      series = orderBy(
        this.selectedProjectTableSeries,
        [
          series =>
            series.data.find(
              data => data.projectMeasure.dateRange === this.sortField
            ).y
        ],
        [this.sortDirection]
      );
    }

    return series;
  }

  @computed
  get nonSelectedProjectTableSeries() {
    return this.nonChartProjects.map((project, index) => {
      const projectTableSeries = this.projectTableSeries.find(
        series => series.id === project.parentProjectId
      );

      return {
        ...projectTableSeries,
        index
      };
    });
  }

  @computed
  get sortedNonSelectedProjectTableSeries() {
    if (this.sortField === 'name') {
      return orderBy(
        this.nonSelectedProjectTableSeries,
        [series => series.name.toLowerCase()],
        [this.sortDirection]
      );
    }

    return orderBy(
      this.nonSelectedProjectTableSeries,
      [
        series =>
          series.data.find(
            data => data.projectMeasure.dateRange === this.sortField
          ).y
      ],
      [this.sortDirection]
    );
  }

  @computed
  get chartSeries() {
    const series = this.selectedProjectTableSeries.slice();

    if (this.withAverageLine) {
      series.unshift(this.averageSeries);
    }

    return series;
  }

  @computed
  get hasChartData() {
    return this.selectedProjectTableSeriesTotal > 0;
  }

  @computed
  get averageSeries() {
    const seriesObject = {
      name: 'Average Line',
      color: this.hasChartData ? '#00A2ED' : '#FFF',
      data: [],
      dashStyle: 'dash',
      lineWidth: 3,
      marker: {
        symbol: 'circle',
        radius: 0
      }
    };

    this.dateRanges.forEach(dateRange => {
      const projectMeasures = this.insightsPeriods.models
        .find(insightsPeriod => insightsPeriod.dateRange === dateRange)
        .projectMeasures.models.filter(projectMeasure => {
          return (
            projectMeasure.dateRange === dateRange &&
            this.averageLineProjects
              .map(project => project.parentProjectId)
              .includes(projectMeasure.project.id)
          );
        });

      const total = projectMeasures.reduce((sum, projectMeasure) => {
        return sum + projectMeasure.stats[this.chartStat];
      }, 0);

      const mean = Math.ceil(total / this.averageLineProjects.length);

      seriesObject.data.push({
        projectMeasure: projectMeasures[0],
        y: mean
      });
    });

    return seriesObject;
  }

  @computed
  get seriesTotalsByDateRange() {
    const totals = [];

    this.dateRanges.forEach(dateRange => {
      const projectMeasures = this.insightsPeriods.models.find(
        insightsPeriod => insightsPeriod.dateRange === dateRange
      ).projectMeasures;

      const total = projectMeasures.models.reduce((sum, projectMeasure) => {
        return sum + projectMeasure.stats[this.chartStat];
      }, 0);

      totals.push(total);
    });

    return totals;
  }

  @action.bound
  fetchInsightsByDay(projectId, startDay, endDay) {
    this.fetchingInsightsByDay = true;

    request
      .get(this.url(), {
        params: {
          projectIds: projectId,
          startDay: startDay,
          endDay: endDay,
          include: this.chartStat,
          includeTeamMeasures: false,
          interval: 1,
          groupBy: 'DAY'
        }
      })
      .then(response => {
        this.insightsByDay.reset(response.data.insightsPeriods);
        this.fetchingInsightsByDay = false;
      })
      .catch(error => {
        errorHandler(error, this.rootStore.notificationsUI.pushError);
      });
  }
}
