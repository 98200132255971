import { action, computed, observable } from 'mobx';
import TimeEntriesWorkLogUI from '../workLogs/TimeEntriesWorkLogUI';

import {
  TIMECARD_VIEWED,
  TIMECARD_EDITED
} from 'utils/segmentAnalytics/eventSpec';

import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import history from 'utils/history';

export default class WorkLogEditCrewUI extends TimeEntriesWorkLogUI {
  @observable nextUrl;

  constructor(options) {
    super(options);
    this.nextUrl = null;
  }

  @computed get title() {
    return this.parent.title;
  }

  @computed get backButtonText() {
    return this.parent.backButtonText;
  }

  @computed get project() {
    return this.parent.project;
  }

  @computed get workLogParams() {
    return {
      date: this.parent.date,
      projectUuid: this.parent.projectUuid,
      segmentUuid: this.parent.segmentUuid
    };
  }

  @computed
  get currentTimeEntriesWorkLogDate() {
    return this.parent.date;
  }

  @action.bound setup(workLog) {
    this.timeEntriesWorkLogForEdit = workLog;

    const workersUuid = workLog.timeCards.models.map(
      timeCard => timeCard.worker.workerUuid
    );

    this.fetchWorkersWeekTotalHours(workersUuid, this.workLogParams.date);

    this.setupSelectors();
    this.workLogWorkersUI.fetchWorkers();
    this.fetchOvertimeRules(this.project.uuid);

    this.sendTimeCardAnalyticEvent(TIMECARD_VIEWED);
    this.initTimeEntriesWorkLogForm();

    this.blockHistoryIfFormChanged();
  }

  @action.bound tearDown() {
    this.clearUIState();
    this.unblockHistory();
    this.tearDownSelectors();
  }

  @action.bound blockHistoryIfFormChanged() {
    this.unblock = history.block((location, action) => {
      if (!this.timeEntriesWorkLogForm.workLogIsPristine) {
        this.showDiscardModal(location.pathname);
        return 'Blocked';
      }
    });
  }

  @action.bound unblockHistory() {
    this.unblock & this.unblock();
  }

  @action.bound
  showDiscardModal(nextUrl) {
    this.showModal('DiscardChangesModal');
    this.nextUrl = nextUrl;
  }

  @action.bound
  async discardChanges() {
    await this.hideActiveModal();
    this.moveToNextURL();
  }

  @action.bound
  async moveToNextURL() {
    this.unblock();
    history.push(this.nextUrl);
    this.nextUrl = null;
  }

  @action.bound
  async saveTimeEntriesWorkLog() {
    this.timeEntriesWorkLogForm.allocateHoursWorked();
    this.timeEntriesWorkLogForm.validateBeforeSaving();

    if (this.timeEntriesWorkLogForm.workLogIsValid) {
      const payload = this.timeEntriesWorkLogForm.cleanedValues;

      if (this.currentTimeEntriesWorkLog.attachments.hasModels) {
        payload.attachments = this.currentTimeEntriesWorkLog.attachments.models.map(
          attachment => {
            return { uuid: attachment.uuid };
          }
        );
      }

      if (this.workLogParams.segmentUuid) {
        payload.segmentUuid = this.workLogParams.segmentUuid;
      }

      try {
        await this.currentTimeEntriesWorkLog.save(payload, {
          wait: true,
          reset: true,
          stripNonRest: false
        });

        this.sendTimeCardAnalyticEvent(TIMECARD_EDITED);

        this.unblockHistory();
        this.parent.parent.refetchWorkLogs();
        this.parent.cancelWorkLogEdit();

        this.rootStore.notificationsUI.pushNotification({
          snackbar: 'warning',
          icon: 'notification',
          title: t('Time entries have been saved.')
        });
      } catch (error) {
        alertErrorHandler(error, this.setValidationDetails);
      } finally {
        this.savingTimeCards = false;
        this.workLogLoading = false;
      }
    } else {
      this.savingTimeCards = false;
      this.workLogLoading = false;
    }
  }

  @action.bound async cancelTimeEntriesWorkLogCreationOrEditing() {
    await this.hideActiveModal();
    this.parent.cancelWorkLogEdit();
  }

  @action.bound
  cancelWorkLogEdit() {
    //prevent closing when data updating or saving
    if (this.workLogLoading || this.timeCardsLoading || this.savingTimeCards)
      return;

    this.parent.cancelWorkLogEdit();
  }

  @action.bound clearUIState() {
    this.timeEntriesWorkLogForm = null;
    this.timeEntriesWorkLogForEdit = null;
    this.workLogPage = 1;
    this.timeCardSearchQuery = '';
    this.timeCardSearchInputValue = '';
    this.workersWeekTotalHours.clear();
    this.savingTimeCards = false;
    this.workLogLoading = false;
    this.activeModal = null;
    this.nextUrl = null;
  }
}
