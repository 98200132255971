import { action, computed } from 'mobx';
import ProjectChildEditUI from './ProjectChildEditUI';
import EquipmentLog from '../../models/EquipmentLog';

import history from 'utils/history';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';

import { callTrack } from 'utils/segmentIntegration';
import { EQUIPMENT_LOG_EDITED } from 'utils/segmentAnalytics/eventSpec';

import EquipmentLogNotesUI from './EquipmentLogNotesUI';

import {
  EquipmentLogForm,
  equipmentLogFormOptions,
  equipmentLogFormFields,
  equipmentLogFormRules,
  equipmentLogFormLabels,
  equipmentLogFormPlugins,
  equipmentLogFormRelated
} from 'forms/project/equipmentLog';

export default class EquipmentLogEditUI extends ProjectChildEditUI {
  constructor(options) {
    super(options);

    this.equipmentLogNotesUI = new EquipmentLogNotesUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound setup(id) {
    this.equipmentSelectorUI.setup();
    this.costCodeSelectorUI.setup();
    this.fetchEntry(id);
  }

  @action.bound tearDown() {
    this.equipmentSelectorUI.tearDown();
    this.costCodeSelectorUI.tearDown();
    this.unblockHistory();
    this.clearUIState();
  }

  @action.bound async fetchEntry(uuid) {
    if (this.entryForEdit) return;

    let model = this.parent.equipmentLogs.getByIdOrUuid(uuid);

    if (!model) {
      model = new EquipmentLog(
        {
          type: 'EquipmentLog',
          uuid: uuid
        },
        {
          rootStore: this.rootStore
        }
      );
    }

    try {
      await model.fetch();

      this.setEntryForEdit(model);
    } catch (error) {
      this.cancelEquipmentLogEdit();
    }
  }

  @action.bound setEntryForEdit(model) {
    this.entryForEdit = model;

    this.entryEditForm = new EquipmentLogForm(
      {
        fields: equipmentLogFormFields,
        rules: equipmentLogFormRules,
        values: this.entryForEdit.formValues,
        labels: equipmentLogFormLabels,
        related: equipmentLogFormRelated
      },
      {
        options: equipmentLogFormOptions,
        plugins: equipmentLogFormPlugins
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @action.bound blockHistoryIfFormChanged() {
    this.unblock = history.block((location, action) => {
      if (location.pathname.includes('notes')) return undefined;

      if (this.entryEditForm.check('isDirty')) {
        // Allow adding and editing notes if the main equipment log
        // form has changed.
        this.showDiscardModal(location.pathname);
        return 'Blocked';
      }
    });
  }

  @action.bound async submitEntryEditFormSuccess() {
    this.saving = true;

    try {
      const values = this.entryEditForm.values();

      const payload = {
        startDate: values.startDate,
        endDate: values.endDate,
        status: values.status,
        costCode: {
          uuid: values.costCode.uuid
        }
      };

      if (payload.status !== 'REMOVED') {
        payload.endDate = null;
      }

      await this.entryForEdit.save(payload, {
        wait: true
      });

      callTrack(EQUIPMENT_LOG_EDITED, {
        start_date: this.entryForEdit.startDate,
        end_date: this.entryForEdit.endDate,
        cost_code: this.entryForEdit.costCode?.displayCode,
        status: this.entryForEdit.status
      });

      this.unblockHistory();
      this.parent.refetchEquipmentLogs();
      this.cancelEquipmentLogEdit();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Equipment log saved')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound cancelEquipmentLogEdit() {
    history.push({
      pathname: `${this.project.viewUrl}/equipment`,
      search: this.baseQueryParams
    });
  }

  @computed get title() {
    if (this.hasWriteAccess) {
      return t('Edit equipment log');
    }

    return t('View equipment log');
  }

  @computed get statusOptions() {
    return [
      {
        value: 'INUSE',
        name: t('In Use')
      },
      {
        value: 'IDLE',
        name: t('Idle')
      },
      {
        value: 'REMOVED',
        name: t('Removed')
      }
    ];
  }

  @computed get selectedStatusOption() {
    return this.statusOptions.find(
      option => option.value === this.entryEditForm.$('status').value
    );
  }

  @computed get equipmentUI() {
    return this.parent.equipmentUI;
  }
}
