import { Model } from 'mobx-mc';

class Overlap extends Model {
  get restAttributes() {
    return ['timestamp', 'rawTimestamp', 'count'];
  }

  get restAttributeDefaults() {
    return {
      timestamp: '',
      rawTimestamp: '',
      count: ''
    };
  }
}

export default Overlap;
