import { action, computed, observable, runInAction, reaction } from 'mobx';
import SettingsChildUI from './SettingsChildUI';
import { BASE_DEBOUNCE } from 'fixtures/constants';
import debounce from 'lodash.debounce';
import identity from 'lodash.identity';
import pickBy from 'lodash.pickby';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import matchModelField from 'utils/matchModelField';
import orderBy from 'lodash.orderby';
import PayTypes from 'stores/collections/PayTypes';
import { callTrack } from 'utils/segmentIntegration';
import { PRODUCTION_SETTINGS_OPENED } from 'utils/segmentAnalytics/eventSpec';

export default class SettingsPayTypesUI extends SettingsChildUI {
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable sortDirection;
  @observable sortField;
  @observable loading;

  constructor(options) {
    super(options);

    // Sorting
    this.sortDirection = 'asc';
    this.sortField = 'payType';

    // Search & Pagination
    this.searchQuery = '';
    this.pageSize = 20;
    this.page = 1;

    this.loading = true;

    this.paginatedPayTypes = new PayTypes(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchPayTypesDebounced = debounce(this.fetchPayTypes, BASE_DEBOUNCE);
  }

  @action.bound setup() {
    callTrack(PRODUCTION_SETTINGS_OPENED, {
      tab_name: 'Pay Types'
    });
    this.setupReactions();
    this.fetchPayTypes();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchPayTypesDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      sortField: this.sortField,
      query: this.searchQuery,
      sortDirection: this.sortDirection
    };
  }

  @action.bound
  async fetchPayTypes() {
    this.payTypes.cancelRequest();
    this.payTypes.reset();

    try {
      await this.payTypes.fetch({
        params: pickBy(this.params, identity),
        add: true,
        remove: false,
        update: true
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  sortByColumn() {
    if (this.sortDirection === 'asc') {
      this.sortDirection = 'desc';
    } else {
      this.sortDirection = 'asc';
    }
  }

  @action.bound
  restorePayType(payType) {
    const payTypeIndex = payType.hiddenProjects
      .map(item => item.uuid)
      .indexOf(this.project.uuid);

    payType.hiddenProjects.splice(payTypeIndex, 1);
  }

  @action.bound
  async togglePayTypeForProject(payType) {
    await this.authorization.checkFeatureAccess('EditPayTypes');

    if (!payType.assignedToCurrentProject) {
      this.restorePayType(payType);
    } else {
      payType.hiddenProjects.push({ uuid: this.project.uuid });
    }

    if (this.projectHasNoPayTypes) {
      // We can't allow a project with no pay types so if they toggle the last one off we need to add it back.
      this.rootStore.notificationsUI.pushNotification({
        showUndo: false,
        title: t('This project must contain at least one pay type')
      });
      setTimeout(() => {
        // We need to set time out to meet ticket AC: "When they select the slider it should animate off and then back on"
        this.restorePayType(payType);
      }, 500);
      return;
    }

    try {
      await payType.save();
      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t(
          `Pay type ${
            payType.assignedToCurrentProject ? 'enabled' : 'disabled'
          }`
        )
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);

      this.restorePayType(payType);
    }
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.payTypes.clear();
    this.searchQuery = '';
    this.page = 1;
    this.loading = true;
    this.sortField = 'payType';
    this.sortDirection = 'asc';
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @computed get headerTitle() {
    return t('Manage available pay types');
  }

  @computed get hasWriteAccess() {
    return this.authorization.canEditPayTypes;
  }

  @computed get payTypes() {
    return this.paginatedPayTypes;
  }

  @computed
  get hiddenPayTypesForProject() {
    return this.payTypes.models.filter(payType =>
      payType.hiddenProjects.find(
        hiddenProject => hiddenProject.uuid === this.project.uuid
      )
    );
  }

  @computed
  get projectHasNoPayTypes() {
    return this.hiddenPayTypesForProject.length === this.payTypes.length;
  }

  @computed get enabledPayTypes() {
    return this.payTypes.models.filter(
      payType => payType.assignedToCurrentProject
    );
  }

  @computed get sortedPayTypes() {
    return orderBy(this.payTypes.models, ['order'], [this.sortDirection]);
  }

  @computed get searchedPayTypes() {
    if (!this.searchQuery) {
      return this.sortedPayTypes;
    }

    const query = this.searchQuery.toLowerCase();

    return this.sortedPayTypes.filter(payType => {
      return (
        matchModelField(payType, query, 'name') ||
        matchModelField(payType, query, 'code')
      );
    });
  }

  @computed get showEmptyState() {
    return !this.loading && !this.hasPayTypes;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @computed get hasPayTypes() {
    return this.payTypes.hasModels;
  }

  @computed get hasSearchedPayTypes() {
    return this.searchedPayTypes.length > 0;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.payTypes.totalElements / this.pageSize);
  }

  @computed get title() {
    return t('Pay types settings');
  }
}
