import { Model } from 'mobx-mc';

export default class TimeCardReport extends Model {
  get restAttributes() {
    return [
      'uuid',
      'type',
      'name',
      'emails',
      'projectIncludeOption',
      'projects',
      'sendReport',
      'includeQuantitiesInstalled',
      'includeCostCode',
      'timeFrameType',
      'projectEntire',
      'time',
      'actionDate',
      'fromDate',
      'toDate'
    ];
  }

  idAttribute() {
    return 'uuid';
  }
}
