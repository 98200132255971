import styled from 'styled-components';
import PropTypes from 'prop-types';
import media from '../utils/mediaTemplate';

const Gutter = styled.div`
  background-color: ${props =>
    props.solid ? props.theme.colors.white : 'transparent'};
  padding: ${props => (props.vertical ? 2.0 : 0)}rem
    ${props => (props.horizontal ? 2.5 : 0)}rem;
  position: relative;
  overflow: hidden;

  ${media.desktop`
    overflow: inherit;
  `};
`;

Gutter.propTypes = {
  vertical: PropTypes.bool.isRequired,
  horizontal: PropTypes.bool.isRequired,
  solid: PropTypes.bool.isRequired
};

Gutter.defaultProps = {
  vertical: true,
  horizontal: true,
  solid: false
};

export default Gutter;
