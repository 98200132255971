import { action, computed, toJS } from 'mobx';
import SettingsChildUI from './SettingsChildUI';
import omit from 'lodash.omit';
import { callTrack } from 'utils/segmentIntegration';
import {
  SettingsTimeCaptureForm,
  settingsTimeCaptureFormOptions,
  settingsTimeCaptureFormFields,
  settingsTimeCaptureFormRules,
  settingsTimeCaptureFormValues,
  settingsTimeCaptureFormPlugins,
  settingsTimeCaptureFormLabels,
  settingsTimeCaptureFormDisabled
} from 'forms/project/settingsTimeCapture';

import ChecklistTemplates from 'stores/collections/checklists/ChecklistTemplates';

import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import { PROJECT_UPDATED } from 'utils/segmentAnalytics/eventSpec';

export default class SettingsTimeCaptureUI extends SettingsChildUI {
  constructor(options) {
    super(options);

    // ChecklistTemplates collection
    this.checklistTemplates = new ChecklistTemplates(null, {
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound async setup() {
    if (this.authorization.hasTimeClockAccess) {
      await this.fetchChecklistTemplates();
    }

    this.setupForm();
  }

  @action.bound tearDown() {
    this.checklistTemplates.clear();
    this.clearUIState();
    this.unblockHistory();
  }

  @action.bound
  setupForm() {
    this.currentFormInitialValues = Object.assign(
      {},
      settingsTimeCaptureFormValues,
      this.project.settingsFormValues
    );

    this.form = new SettingsTimeCaptureForm(
      {
        fields: settingsTimeCaptureFormFields,
        rules: settingsTimeCaptureFormRules,
        disabled: settingsTimeCaptureFormDisabled,
        values: toJS(this.currentFormInitialValues),
        labels: settingsTimeCaptureFormLabels
      },
      {
        options: settingsTimeCaptureFormOptions,
        plugins: settingsTimeCaptureFormPlugins,
        parent: this
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @action.bound
  async submitFormSuccess() {
    this.saving = true;
    const values = this.form.trimmedValues();
    const ommitedValues = ['default', 'questions', 'collaboratorQuestions'];
    const projectData = omit(values, ommitedValues);
    try {
      await this.project.save(
        projectData,
        {
          url: `ra/projects/${this.project.uuid}`
        },
        {
          wait: true
        }
      );
      this.handleSaveSuccess();
    } catch (error) {
      this.handleSaveError(error);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  handleSaveSuccess() {
    this.unblockHistory();
    this.saving = false;

    this.notifications.pushNotification({
      snackbar: 'warning',
      icon: 'checkmark',
      title: t('Time capture settings saved')
    });

    callTrack(PROJECT_UPDATED, {
      project_id: this.project?.id,
      project_name: this.project?.name
    });

    // Reset the header projects
    this.headerUI.headerProjectSelectorUI.resetProjectOptions();

    // Reinitialize form
    this.setupForm();
  }

  @action.bound
  handleSaveError(error) {
    alertErrorHandler(error, this.setValidationDetails);
    this.saving = false;
  }

  @computed get hasWriteAccessKiosk() {
    return (
      this.authorization.canModifyKioskSettings && this.form.timeCaptureEnabled
    );
  }

  @computed get disableKiosk() {
    return !this.hasWriteAccessKiosk;
  }

  @computed get hasWriteAccessTimeClock() {
    return (
      this.authorization.canModifyTimeClockSettings &&
      this.form.timeCaptureEnabled
    );
  }

  @computed get disableTimeClock() {
    return !this.hasWriteAccessTimeClock;
  }

  @computed
  get disableFeatureCheckBoxes() {
    if (this.disableTimeClock && this.disableKiosk) return true;
    if (
      !this.form.$('enableKiosk').value &&
      !this.form.$('enableTimeClock').value
    )
      return true;

    return false;
  }

  @action.bound async fetchChecklistTemplates() {
    try {
      await this.checklistTemplates.fetch({
        url: `${this.rootStore.urlMicroService('toolbox')}/companies/${
          this.rootStore.me.company.uuid
        }/checklist-templates/checklist-types/${
          this.rootStore.appConfig.timeClockChecklistType
        }`,
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get requireClockInQuestionsOptions() {
    return [
      { title: t('Clock in questions not required'), value: '' },
      ...this.checklistTemplates.models.map(template => {
        return { title: template.name, value: template.uuid };
      })
    ];
  }

  @computed get clockInQuestionsValue() {
    return this.requireClockInQuestionsOptions.find(
      option =>
        option.value === this.form.$('clockInChecklistTemplateUuid').value
    );
  }

  @computed get requireClockOutQuestionsOptions() {
    return [
      { title: t('Clock out questions not required'), value: '' },
      ...this.checklistTemplates.models.map(template => {
        return { title: template.name, value: template.uuid };
      })
    ];
  }

  @computed get clockOutQuestionsValue() {
    return this.requireClockOutQuestionsOptions.find(
      option =>
        option.value === this.form.$('clockOutChecklistTemplateUuid').value
    );
  }
}
