import { Model } from 'mobx-mc';

export default class ScheduleBulk extends Model {
  get restAttributes() {
    return [
      'uuid',
      'name',
      'talks',
      'scheduleData',
      'groupUuids',
      'projectUuids',
      'default'
    ];
  }

  idAttribute() {
    return 'uuid';
  }
}
