import MobxReactForm from 'mobx-react-form';
import validatorjs from 'validatorjs';
import { t } from 'utils/translate';

const bulkDownloadFormPlugins = { dvr: validatorjs };

const bulkDownloadFormOptions = {
  validateOnInit: true,
  validateOnChange: true,
  strictUpdate: false
};

const bulkDownloadFormFields = [
  'downloadType',
  'collaborators[]',
  'dateRange',
  'fromDate',
  'toDate',
  'recipients[]',
  'recipient'
];

const bulkDownloadFormRules = {
  downloadType: 'string|required', // Download type will be a selectbox.
  collaborators: 'required_if:downloadType,SPECIFIC',
  dateRange: 'string|required',
  fromDate: 'required_if:dateRange,SPECIFIC',
  toDate: 'required_if:dateRange,SPECIFIC',
  recipients: 'array|required',
  'recipients[]': 'email',
  recipient: 'required_without:recipients|email'
};

const bulkDownloadFormLabels = {
  downloadType: t('Download'),
  collaborators: t('Select Collaborators'),
  recipients: t('Send download link to'),
  dateRange: t('Date range')
};

class BulkDownloadForm extends MobxReactForm {}

export {
  BulkDownloadForm,
  bulkDownloadFormOptions,
  bulkDownloadFormFields,
  bulkDownloadFormRules,
  bulkDownloadFormLabels,
  bulkDownloadFormPlugins
};
