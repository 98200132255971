import UIStore from 'stores/ui/UIStore';
import { action, computed, observable } from 'mobx';
import orderBy from 'lodash.orderby';

export default class ChecklistTemplateSelectUI extends UIStore {
  @observable status;
  @observable scope;
  @observable searchQuery;
  @observable limit;
  @observable sortField;
  @observable sortDirection;
  @observable loading;

  constructor(options) {
    super(options);

    this.tab = 'templates';
    this.status = ['ACTIVE', 'DRAFT'];
    this.scope = 'COMPANY,RAKEN';
    this.limit = 10000;
    this.searchQuerySource = '';

    this.previewMode = false;
    this.sectionToDeleteKey = null;
    this.selectedQuestion = null;
    this.previousResponseOption = null;

    this.loading = true;

    this.selectedTemplate = null;
  }

  @computed
  get checklistTemplates() {
    return this.rootStore.checklistTemplates;
  }

  @action.bound
  async fetchChecklistTemplates(scope, status) {
    this.loading = true;

    await this.checklistTemplates.fetch({
      params: {
        scope: scope ? scope : this.scope,
        status: status ? status : this.status,
        limit: this.limit
      }
    });

    this.loading = false;
  }

  @computed
  get companyChecklistTemplates() {
    return this.checklistTemplates.models.filter(
      template => template.scope === 'COMPANY'
    );
  }

  @computed
  get hasCompanyChecklistTemplates() {
    return this.companyChecklistTemplates.length > 0;
  }

  @computed
  get rakenChecklistTemplates() {
    return this.checklistTemplates.models.filter(
      template => template.scope === 'RAKEN' && template.status === 'ACTIVE'
    );
  }

  @computed
  get orderedCompanyChecklistTemplates() {
    return orderBy(
      this.companyChecklistTemplates,
      [this.sortField, 'createdDate', 'name'],
      [this.sortDirection]
    );
  }

  @computed
  get orderedRakenChecklistTemplates() {
    return orderBy(this.rakenChecklistTemplates, ['name'], 'asc');
  }

  @computed
  get searchedRakenChecklistTemplates() {
    if (!this.searchQuerySource) {
      return this.orderedRakenChecklistTemplates;
    }
    const query = this.searchQuerySource.toLowerCase();
    return this.orderedRakenChecklistTemplates.filter(template => {
      return (
        template.name.toLowerCase().indexOf(query) > -1 ||
        template.typeString.toLowerCase().indexOf(query) > -1
      );
    });
  }

  @computed
  get searchedCompanyChecklistTemplates() {
    if (!this.searchQuery) {
      return this.orderedCompanyChecklistTemplates;
    }

    const query = this.searchQuery.toLowerCase();
    return this.orderedCompanyChecklistTemplates.filter(
      template =>
        template.name.toLowerCase().indexOf(query) > -1 ||
        template.typeString.toLowerCase().indexOf(query) > -1
    );
  }
}
