import { RakenCollection } from '../../lib';
import ChecklistClass from 'stores/models/checklists/ChecklistClass';

export default class ChecklistClasses extends RakenCollection {
  model() {
    return ChecklistClass;
  }

  url() {
    return `${this.rootStore.urlMicroService('toolbox')}/companies/${
      this.rootStore.me.company.uuid
    }/checklist-classes`;
  }
}
