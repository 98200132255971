import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import RootStore from 'stores/RootStore';
import { Box, Text, TextField, Icon } from '@raken/athens-web/lib';
import PlacesAutocomplete, {
  geocodeByPlaceId
} from 'react-places-autocomplete7';
import kebabCase from 'lodash.kebabcase';

const FieldContainer = styled.div`
  position: relative;
  margin-bottom: 22px;
`;

const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  background-color: white;
  box-shadow: -1px 4px 16px 1px rgba(0, 0, 0, 0.05);
  margin: 4px 0;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
`;

const DropdownItem = styled.div`
  min-height: 44px;
  padding: 6px 16px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #f6f6f7;
  }

  ${({ $isHover }) =>
    $isHover &&
    css`
      background-color: #f6f6f7;
    `}
`;

const DropdownText = styled(Text)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

class LocationSearchInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedAddress: '' // When a dropdown item is focused using up/down key
    };
  }

  handleClearState = () => {
    this.setState({ focusedAddress: '' });
  };

  render() {
    const {
      rootStore,
      classNames,
      onChange,
      disabled,
      dataQA,
      tabIndex
    } = this.props;

    const geolocationBias = rootStore.userLocation
      ? {
          location: new window.google.maps.LatLng(
            rootStore.userLocation.geolocation.lat,
            rootStore.userLocation.geolocation.lng
          ),
          radius: rootStore.userLocation.accuracy
        }
      : {};

    return (
      <PlacesAutocomplete
        value={this.state.focusedAddress}
        // Fires when a dropdown item is focused using up/down key
        onChange={address => this.setState({ focusedAddress: address })}
        // Fires when a dropdown item is selected (the actual onChange event)
        onSelect={(address, placeId) => {
          if (placeId) {
            this.setState({ focusedAddress: address });
            geocodeByPlaceId(placeId).then(results => {
              onChange && onChange(results[0]);
            });
          }
        }}
        classNames={classNames}
        searchOptions={{
          types: ['address'],
          ...geolocationBias
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          return (
            <FieldContainer>
              <TextField
                {...getInputProps()}
                label={'Search address'}
                placeholder={'Search address'}
                disabled={disabled}
                fullWidth
                removeDescriptionSpace
                InputProps={{
                  endAdornment: (
                    <Box ml={2} display={'flex'}>
                      <Icon kind={'search'} rakenColor="grey" />
                    </Box>
                  )
                }}
                /* eslint-disable-next-line react/jsx-no-duplicate-props */
                inputProps={{
                  tabIndex: tabIndex
                }}
                dataQA={dataQA}
              />
              {suggestions.length > 0 && (
                <Dropdown
                  className="LocationSearchField_Dropdown"
                  data-qa={`dropdown_${dataQA}`}
                >
                  {suggestions.map((item, i) => {
                    return (
                      <DropdownItem
                        {...getSuggestionItemProps(item)}
                        $isHover={
                          item.description === this.state.focusedAddress
                        }
                        key={i}
                        data-qa={`option_${kebabCase(item.description)}`}
                      >
                        <DropdownText>{item.description}</DropdownText>
                      </DropdownItem>
                    );
                  })}
                </Dropdown>
              )}
            </FieldContainer>
          );
        }}
      </PlacesAutocomplete>
    );
  }
}

LocationSearchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  classNames: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  dataQA: PropTypes.string,
  rootStore: PropTypes.instanceOf(RootStore).isRequired // So that we can have access to geolocation
};

export default LocationSearchInput;
