import { RakenCollection } from '../lib';
import ProjectLocation from 'stores/models/ProjectLocation';

export default class ProjectLocations extends RakenCollection {
  model() {
    return ProjectLocation;
  }

  url() {
    return `/ra/companies/${this.rootStore.me.company.uuid}/projects/${this.rootStore.projectUI.project?.uuid}/locations`;
  }
}
