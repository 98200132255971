import { BASE_DEBOUNCE } from 'fixtures/constants';
import debounce from 'lodash.debounce';
import identity from 'lodash.identity';

import pickBy from 'lodash.pickby';
import { action, computed, observable, reaction } from 'mobx';
import DocumentsSearch from 'stores/collections/documents/DocumentsSearch';
import errorHandler from 'utils/errorHandler';
import { t } from 'utils/translate';
import UIStore from './UIStore';

export default class DocumentSelectorUI extends UIStore {
  @observable searchQuery;
  @observable loading;

  @observable documentsSearch;

  constructor(options) {
    super(options);

    this.loading = false;

    this.searchQuery = '';
    this.sortField = false;

    this.documentsSearch = new DocumentsSearch(null, {
      rootStore: this
    });

    this.fetchDocumentsDebounced = debounce(this.fetchDocuments, BASE_DEBOUNCE);
  }

  @action.bound
  setup() {
    this.fetchDocuments();
    this.setupReactions();
  }

  @action.bound
  tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  @action.bound setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        this.loading = true;
        this.fetchDocumentsDebounced();
      }
    );
  }

  @action.bound tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @action.bound clearUIState() {
    this.loading = false;
    this.searchQuery = '';

    this.documents.reset();
  }

  @computed get noOptionsText() {
    if (
      !this.documents.hasModels &&
      this.searchQuery.length > 2 &&
      !this.loading
    ) {
      return t('No Results found. Try adjusting your search.');
    }

    return t('Search documents');
  }

  @computed get documents() {
    return this.documentsSearch;
  }

  @computed get options() {
    return this.documents.models.slice();
  }

  @action.bound
  setSearchQuery(value) {
    this.searchQuery = value;
  }

  @action.bound
  clearSearchQuery() {
    this.searchQuery = '';
  }

  @action.bound
  refreshSearchQuery() {
    const temp = this.searchQuery;
    this.clearSearchQuery();
    this.setSearchQuery(temp);
  }

  @computed get hasMoreDocuments() {
    return !!(this.documents?.totalElements > this.documents?.length);
  }

  @computed get params() {
    return {
      projectUuid: this.project.id,
      limit: 10,
      query: this.searchQuery,
      sortField: this.sortField,
      sortDirection: 'asc'
    };
  }

  @action.bound
  async fetchDocuments(options) {
    if (this.searchQuery.length < 3) {
      this.documents.clear();
      return;
    }

    try {
      await this.documents.fetch({
        params: pickBy(this.params, identity),
        ...options
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    } finally {
      this.loading = false;
    }
  }

  fetchNextPage = async (e, autoCompleteRef) => {
    const menu = e.target;

    const scrollTop = menu.scrollTop;
    const scrollHeight = menu.scrollHeight;
    const clientHeight = menu.clientHeight;

    if (scrollTop + clientHeight === scrollHeight) {
      this.fetchNextPageOfDocuments().then(() => {
        autoCompleteRef.current.scrollTop = scrollHeight;
      });
    }
  };

  @action.bound async fetchNextPageOfDocuments() {
    if (this.documents.fetching || !this.hasMoreDocuments) return;

    try {
      await this.documents.fetch({
        params: Object.assign(pickBy(this.params, identity), {
          offset: this.documents.length
        }),
        add: true,
        remove: false,
        update: false
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }
}
