import { computed } from 'mobx';
import MobxReactForm from 'mobx-react-form';

import validatorjs from 'validatorjs';

import { customRules } from './utils/extendedPlugins';

import { t } from 'utils/translate';

const observationFormPlugins = {
  dvr: {
    package: validatorjs,
    extend(validator) {
      const messages = validator.getMessages('en');
      messages.required_with = 'The :attribute field is required.';
      validator.setMessages('en', messages);

      Object.keys(customRules).forEach(key =>
        validator.register(
          key,
          customRules[key].function,
          customRules[key].message
        )
      );
    }
  }
};

const observationFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const observationFormFields = [
  'category',
  'type',
  'subType',
  'priority',
  'status',
  'location',
  'description',
  'emails',
  'assignee',
  'dueDate',
  'action',
  'sendNotification',
  'assignees',
  'assignees[].assignee',
  'assignees[].dueDate',
  'assignees[].action',
  'assignees[].sendNotification'
];

const observationFormLabels = {
  category: t('Category'),
  type: t('Type'),
  subType: t('Sub Type'),
  priority: t('Priority'),
  status: t('Status'),
  location: t('Location'),
  description: t('Description'),
  dueDate: t('Due date'),
  'assignees[].dueDate': t('Due date'),
  action: t('Action'),
  'assignees[].action': t('Action'),
  emails: t('Team member notifications')
};

const observationFormValues = {
  category: 'NEGATIVE',
  priority: '',
  status: '',
  location: '',
  description: '',
  emails: [],
  sendNotification: true,
  'assignees[].sendNotification': true
};

class ObservationForm extends MobxReactForm {
  @computed get subTypeDisabled() {
    return this.$('type').isEmpty;
  }

  @computed get assigneesValues() {
    const nestedValues = this.$('assignees').value;

    return [
      ...nestedValues,
      ...(this.$('assignee').value
        ? [
            {
              assignee: this.$('assignee').value,
              dueDate: this.$('dueDate').value,
              action: this.$('action').value,
              sendNotification: this.$('sendNotification').value
            }
          ]
        : [])
    ];
  }
}

export {
  ObservationForm,
  observationFormOptions,
  observationFormFields,
  observationFormValues,
  observationFormLabels,
  observationFormPlugins
};
