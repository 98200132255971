import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import debounce from 'lodash.debounce';
import { action, observable, computed, reaction } from 'mobx';
import UIStore from './UIStore';
import { BASE_DEBOUNCE } from 'fixtures/constants';
import ProjectNotificationSettings from '../collections/ProjectNotificationSettings';

export default class TeamMemberNotificationsUI extends UIStore {
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;

  constructor(options) {
    super(options);

    this.notificationSettings = new ProjectNotificationSettings(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.loading = true;

    this.searchQuery = '';
    this.pageSize = 10;
    this.page = 1;

    this.fetchNotificationSettingsDebounced = debounce(
      this.fetchNotificationSettings,
      BASE_DEBOUNCE
    );
  }

  @computed get entryForEdit() {
    return this.parent.entryForEdit;
  }

  @computed get id() {
    return this.entryForEdit.userId;
  }

  @action.bound setup() {
    this.setupReactions();
    this.fetchNotificationSettings();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        this.fetchNotificationSettings();
      }
    );

    this.reactToSearchQuery = reaction(
      () => this.searchQuery,
      params => {
        this.fetchNotificationSettingsDebounced();
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
    this.reactToSearchQuery && this.reactToSearchQuery();
  }

  @action.bound
  async fetchNotificationSettings() {
    this.loading = true;

    await this.notificationSettings.fetch({
      params: pickBy(
        Object.assign(this.params, {
          query: this.searchQuery
        }),
        identity
      )
    });

    this.loading = false;
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @computed get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      projectStates: 'ACTIVE'
    };
  }

  @computed get hasNotificationSettings() {
    return this.notificationSettings.hasModels;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.notificationSettings.totalElements / this.pageSize);
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.notificationSettings.reset();
    this.searchQuery = '';
    this.page = 1;
    this.loading = true;
  }

  @action.bound toggleNotificationSetting(notificationSetting, settingEnum) {
    this.authorization
      .checkFeatureAccess('EditProjectNotificationSettings')
      .then(() => {
        notificationSetting.saveSetting(
          settingEnum,
          (notificationSetting.settings[settingEnum] = !notificationSetting
            .settings[settingEnum])
        );
      });
  }
}
