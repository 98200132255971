import { Model } from 'mobx-mc';

export default class ObservationType extends Model {
  get restAttributes() {
    return ['uuid', 'typeClass', 'type', 'subType', 'negative', 'positive'];
  }

  idAttribute() {
    return 'uuid';
  }
}
