import { RakenCollection } from 'stores/lib';
import TimeCard from 'stores/models/workLogs/TimeCard';

export default class TimeCards extends RakenCollection {
  model() {
    return TimeCard;
  }

  url() {
    return `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/worklogs/timecards`;
  }
}
