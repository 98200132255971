export const UNLIMITED_SEATS_NUMBER = 999999999;
export const SEATS_ENTERPRISE_ADDON = 'seats-enterprise';

export function legacySeatsCost(addOns) {
  const { addOn, purchasedSeats } = seatAddOn(addOns);
  if (!addOn) {
    console.warn('No seat add-on found');
    return {};
  }
  const includedSeats = addOn.tiers.find(t => t.unitAmount < 0.001)
    .endingQuantity;
  const extra = addOn.tiers.find(t => t.unitAmount > 0.001);
  const extraSeats = extra ? purchasedSeats - includedSeats : 0;
  const addSeatsPrice =
    extra && extraSeats > 0 ? extraSeats * extra.unitAmount : 0;
  const isUnlimited = includedSeats >= UNLIMITED_SEATS_NUMBER;
  return {
    addOn,
    purchasedSeats,
    includedSeats,
    extra,
    extraSeats,
    addSeatsPrice,
    isUnlimited
  };
}

export function seatAddOn(addOns) {
  const addOn = addOns.find(a =>
    a.addOn.code.startsWith(SEATS_ENTERPRISE_ADDON)
  );
  const purchasedSeats = addOn?.quantity || 0;
  return { addOn, purchasedSeats };
}
