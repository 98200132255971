import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';

const workerPhotoIdSummaryFiltersFormPlugins = extendedPlugins;

const workerPhotoIdSummaryFiltersFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const workerPhotoIdSummaryFiltersFormFields = [
  'projectFilters[]',
  'memberFilters[]',
  'classificationFilters[]',
  'shiftFilters[]',
  'employeeGroupFilters[]'
];

const workerPhotoIdSummaryFiltersFormRules = {
  projectFilters: 'array',
  memberFilters: 'array',
  classificationFilters: 'array',
  shiftFilters: 'array',
  employeeGroupFilters: 'array'
};

class WorkerPhotoIdSummaryFiltersForm extends MobxReactForm {}

export {
  WorkerPhotoIdSummaryFiltersForm,
  workerPhotoIdSummaryFiltersFormRules,
  workerPhotoIdSummaryFiltersFormFields,
  workerPhotoIdSummaryFiltersFormOptions,
  workerPhotoIdSummaryFiltersFormPlugins
};
