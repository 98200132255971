import styled, { css } from 'styled-components';
import media from 'utils/mediaTemplate';

export default styled.section`
  display: flex;
  flex-direction: column;
  margin: ${props => props.margin || '0 0 10px 0'};
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  z-index: ${props => props.zIndex || 1};
  ${props =>
    props.open &&
    css`
      width: 100%;
      flex-basis: 100%;
    `};
  ${props =>
    !props.open &&
    css`
      flex: 1;
    `};
  ${media.desktop`
    position: relative;
  `};
`;
