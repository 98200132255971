import { action, observable, computed, runInAction } from 'mobx';
import SettingsChildUI from './SettingsChildUI';
import orderBy from 'lodash.orderby';
import openNewWindow from 'utils/openNewWindow';
import errorHandler from 'utils/errorHandler';
import { callTrack } from 'utils/segmentIntegration';
import { t } from 'utils/translate';
import {
  INTEGRATION_CONNECTED,
  INTEGRATION_ERROR,
  INTEGRATION_DISCONNECTED
} from 'utils/segmentAnalytics/eventSpec';

export default class SettingsIntegrationsUI extends SettingsChildUI {
  @action.bound setup() {
    this.fetchIntegrations();
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @observable selectedIntegration;
  @observable fetching;

  constructor(options) {
    super(options);

    this.selectedIntegration = null;
    this.fetching = true;
  }

  @action.bound
  checkBim360AccessDenied(integration) {
    if (!integration) return null;

    if (
      integration.providerId === 1007 &&
      integration.integrationError === 'ACCESS_DENIED' &&
      integration.status === 'CONNECTED'
    ) {
      // 1007 is the Bim360 integration which we have to handle with a different modal if in the state of ACCESS_DENIED
      this.showModal('bim360AccessDenied');
      return true;
    }
    return false;
  }

  @computed get sortedIntegrations() {
    return orderBy(this.project.integrations.models, 'name', 'asc');
  }

  @action.bound
  selectIntegration(integration) {
    if (integration.isConnected) {
      this.showModal('disconnect');
    } else {
      this.showPopUp(integration);
    }

    this.selectedIntegration = integration;
  }

  showPopUp(integration) {
    if (this.popup) {
      this.popup.close();
    }

    clearInterval(this.popupInterval);

    this.popup = openNewWindow(integration.popupUrl, 'connect', 550, 550);

    this.popupInterval = setInterval(this.checkPopUp, 1000);
  }

  checkPopUp = () => {
    if (this.popup.closed) {
      clearInterval(this.popupInterval);

      this.updateSelectedIntegration();
    }
  };

  @action.bound
  clearSelectedIntegration() {
    this.selectedIntegration = null;
  }

  @action.bound
  async updateSelectedIntegration() {
    if (!this.selectedIntegration) return;

    try {
      const response = await this.selectedIntegration.fetch();

      if (response.service.status === 'CONNECTED') {
        callTrack(INTEGRATION_CONNECTED, {
          integration_name: response?.name
        });

        this.notifications.pushNotification({
          snackbar: 'warning',
          icon: 'checkmark',
          title: t('Integration connected')
        });
      }

      this.checkBim360AccessDenied(this.selectedIntegration);
      this.clearSelectedIntegration();

      if (response.mapping?.integrationError) {
        this.showModal('configurationError');
      }
    } catch (error) {
      const pattern = new RegExp(/^5[0-9][0-9]/);

      // if we get error from server 5xx
      if (pattern.test(error.response?.status)) {
        callTrack(INTEGRATION_ERROR, {
          integration_name: this.selectedIntegration.name
        });
      }

      errorHandler(error, this.notifications.pushError);
    }
  }

  @action.bound
  async confirmDisconnectIntegration() {
    try {
      await this.selectedIntegration.save(
        {
          service: {
            status: 'NOT_CONNECTED'
          }
        },
        {
          wait: true
        }
      );

      callTrack(INTEGRATION_DISCONNECTED, {
        integration_name: this.selectedIntegration.name
      });

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Integration disconnected')
      });

      this.cancelDisconnectIntegration();
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  @action.bound
  cancelDisconnectIntegration() {
    this.hideActiveModal().then(() => {
      runInAction(() => {
        this.selectedIntegration = null;
      });
    });
  }

  @action.bound
  async fetchIntegrations() {
    if (!this.project.integrations.length) {
      this.fetching = true;
      try {
        await this.project.integrations.fetch();
      } catch (error) {
        errorHandler(error, this.notifications.pushError);
      }
    }

    this.fetching = false;

    if (this.hasMappingError) {
      this.showModal('configurationError');
    }
  }

  @action.bound clearUIState() {
    this.fetching = false;
    this.selectedIntegration = null;
  }

  @computed get headerTitle() {
    return t(
      'Connect an account to have this Raken project be automatically synced'
    );
  }

  @computed
  get integrationsError() {
    return this.project.integrations.models
      .filter(model => {
        if (!model.mapping) return false;

        return model.mapping.integrationError === 'NOT_CONFIGURED';
      })
      .map(integration => integration.name);
  }

  @computed
  get hasMappingError() {
    return this.integrationsError.length > 0;
  }

  @computed get title() {
    return t('Integrations settings');
  }

  @computed get disableIntegrationsForTrialAccount() {
    return this.authorization.disableIntegrationsForTrialAccount;
  }
}
