import { Model } from 'mobx-mc';
import { computed } from 'mobx';

export default class ProjectMeasureStats extends Model {
  get restAttributes() {
    return [
      'stats',
      'type',
      'workers',
      'hours',
      'safetyIncidents',
      'delays',
      'dailyReports',
      'missedDailies',
      'dailiesCompliancePercent',
      'openTasks',
      'ranking',
      'totalProjectDays'
    ];
  }

  @computed
  get manDays() {
    return this.hours / 8;
  }
}
