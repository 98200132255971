import { computed, action, observable } from 'mobx';
import TeamMembersAddUI from './TeamMembersAddUI';
import {
  MemberForm,
  memberFormOptions,
  memberFormFields,
  memberFormFieldOptions,
  memberFormLabels,
  memberFormRules,
  memberFormPlugins
} from 'forms/member';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';
import { callTrack } from 'utils/segmentIntegration';
import { TEAM_MEMBER_ADDED } from 'utils/segmentAnalytics/eventSpec';
import request from 'axios';
import { seatEstimate, seatEstimateTooltip } from 'utils/billing/seatEstimates';

export default class WorkerPromoteUI extends TeamMembersAddUI {
  @observable memberForm = null;

  constructor(options) {
    super(options);
    //mark for the Add Team Memeber modal
    this.promoteWorkerMode = true;
  }

  @computed
  get promotedWorker() {
    return this.parent.promotedWorker;
  }

  @action.bound setupMemberForm() {
    this.memberForm = new MemberForm(
      {
        fields: memberFormFields,
        rules: memberFormRules,
        labels: memberFormLabels,
        options: memberFormFieldOptions,
        values: {
          firstName: this.promotedWorker.firstName,
          lastName: this.promotedWorker.lastName,
          username: this.promotedWorker.email,
          employeeId: this.promotedWorker.employeeId,
          phoneNumber: this.promotedWorker.phoneNumber,
          uuid: this.promotedWorker.uuid
        }
      },
      {
        options: memberFormOptions,
        plugins: memberFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound cancelMembersAdd() {
    this.parent.promotedWorker = null;
    this.tearDown();
  }

  @computed get seatEstimate() {
    if (!this.subscription) return null;

    return seatEstimate(this.subscription, 1, this.isRecurlyBilling);
  }

  @computed get seatEstimateTooltip() {
    return seatEstimateTooltip(this.subscription, 1, this.isRecurlyBilling);
  }

  @action.bound submitMemberFormSuccess() {
    this.setStep('projects');
  }

  @action
  workerSuccessfulyPromoted() {
    this.parent.refetchWorkers();
    this.refetchSubscription();
    this.notifications.pushNotification({
      snackbar: 'warning',
      icon: 'checkmark',
      title: t('Worker Promoted')
    });

    callTrack(TEAM_MEMBER_ADDED, {
      email: this.promotedWorker.email,
      first_name: this.promotedWorker.firstName,
      last_name: this.promotedWorker.lastName
    });
    this.cancelMembersAdd();
  }

  @action.bound
  refetchSubscription() {
    // Refetch the subscription
    this.subscription.fetch({
      params: {
        mn: 'full',
        mc: 'full'
      }
    });
  }

  @action.bound
  async promoteWorker() {
    this.saving = true;
    const worker = this.memberForm.values();

    const payload = {
      firstName: worker.firstName,
      lastName: worker.lastName,
      role: worker.role,
      username: worker.username,
      message: this.membersForm.$('message').value,
      allowSubscriptionUpgrade: true
    };

    if (this.projectSelectionMethod === 'SELECTED') {
      payload.projectUuids = this.selectedProjects.slice();
    } else {
      payload.addToActiveProjects = true;
    }

    try {
      await request.post(
        `ra/companies/${this.company.uuid}/members/${this.promotedWorker.uuid}/promote`,
        payload
      ),
        this.workerSuccessfulyPromoted();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }
}
