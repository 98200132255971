import UIStore from './UIStore';
import { action, computed, observable, runInAction } from 'mobx';
import errorHandler from 'utils/errorHandler';

import {
  CollaboratorFilterForm,
  collaboratorFilterFormFields,
  collaboratorFilterFormOptions,
  collaboratorFilterFormPlugins,
  collaboratorFilterFormRules
} from 'forms/collaboratorFilterForm';

import orderBy from 'lodash.orderby';

import { t } from 'utils/translate';

export default class ProjectDirectoryCollaboratorsFilterUI extends UIStore {
  @observable projectStateFilters;

  constructor(options) {
    super(options);

    //filters
    this.projectFilters = observable([]);
    this.projectStateFilters = {
      value: '',
      name: t('All')
    };
  }

  @computed
  get filterOptions() {
    return orderBy(
      [
        {
          value: '',
          name: t('All')
        },
        {
          value: 'ACTIVE',
          name: t('Active')
        },
        {
          value: 'INACTIVE',
          name: t('Inactive')
        },
        {
          value: 'DELETED',
          name: t('Deleted')
        }
      ],
      status => status.name
    );
  }

  @action.bound
  initFilterForm() {
    this.activeForm = new CollaboratorFilterForm(
      {
        fields: collaboratorFilterFormFields,
        rules: collaboratorFilterFormRules,
        values: {
          projectFilters: this.projectFilters,
          projectStateFilters: this.projectStateFilters
        }
      },
      {
        options: collaboratorFilterFormOptions,
        plugins: collaboratorFilterFormPlugins
      }
    );
  }

  @action.bound
  terminateFilterForm() {
    this.activeForm = null;
  }

  @action.bound
  clearAllFilters() {
    return this.hideActiveModal().then(() => {
      runInAction(() => {
        this.terminateFilterForm();
        this.projectFilters.clear();
        this.projectStateFilters = {
          value: '',
          name: t('All')
        };
        this.parent.page = 1;
      });
    });
  }

  @action.bound
  async showFilters() {
    this.showModal(`collaboratorFilter`);

    try {
      this.initFilterForm();
    } catch (error) {
      this.hideActiveModal();
      errorHandler(error, this.notifications.pushError);
    }
  }

  @action.bound
  async hideFilters() {
    await this.hideActiveModal();
    this.terminateFilterForm();
  }

  @action.bound async applyAllFilters() {
    await this.hideActiveModal();

    this.projectFilters.replace(this.activeForm.$('projectFilters').values());

    this.projectStateFilters = this.activeForm
      .$('projectStateFilters')
      .values();

    this.terminateFilterForm();

    this.parent.page = 1;
  }

  @computed
  get filtersCounter() {
    let counter = 0;
    if (this.projectFilters.length > 0) {
      counter++;
    }
    if (this.projectStateFilters.value) {
      counter++;
    }

    return counter;
  }

  @computed get hasAppliedFilters() {
    return !!this.filtersCounter;
  }

  @computed get collaboratorProjectTeams() {
    if (!this.project) return [];

    if (this.project.isChildProject) return [];

    return this.project.childProjects.models;
  }

  @computed get collaboratorProjectTeamOptions() {
    return this.collaboratorProjectTeams.map(projectTeam => {
      return {
        value: projectTeam.uuid,
        name: projectTeam.name
      };
    });
  }
}
