import { Model } from 'mobx-mc';
import { computed } from 'mobx';

export default class ProjectTeamMeasure extends Model {
  get restAttributes() {
    return ['type', 'stats', 'company', 'projectTeamId'];
  }

  @computed
  get manDays() {
    return this.stats.hours / 8;
  }

  @computed
  get relatedProject() {
    return this.collection.parent.relatedProject;
  }

  @computed
  get relatedInsightsPeriod() {
    return this.collection.parent;
  }

  @computed
  get startDay() {
    return this.relatedInsightsPeriod.start;
  }

  @computed
  get viewUrl() {
    return `/projects/${this.relatedProject.uuid}`;
  }

  @computed
  get surveyUrl() {
    return `${this.viewUrl}/survey?date=${this.startDay}`;
  }

  @computed
  get formattedStartDay() {
    return this.relatedInsightsPeriod.formattedStartDay;
  }

  @computed
  get endDay() {
    return this.relatedInsightsPeriod.end;
  }

  @computed
  get dateRange() {
    return this.relatedInsightsPeriod.dateRange;
  }

  @computed
  get tooltipDateRange() {
    return this.relatedInsightsPeriod.tooltipDateRange;
  }

  @computed
  get selectedTeamMeasures() {
    return this.collection.models.filter(teamMeasure => {
      return this.relatedInsightsPeriod.collection.parent.chartProjectTeamIds.includes(
        teamMeasure.projectTeamId
      );
    });
  }

  @computed
  get selectedTeamMeasuresWithSameDateRangeAndChartStat() {
    return this.selectedTeamMeasures.filter(teamMeasure => {
      return (
        teamMeasure.dateRange === this.dateRange &&
        teamMeasure.stats[this.relatedInsightsPeriod.chartStat] ===
          this.stats[this.relatedInsightsPeriod.chartStat]
      );
    });
  }

  @computed
  get hasSelectedTeamMeasuresWithSameDateRangeAndChartStat() {
    return this.selectedTeamMeasuresWithSameDateRangeAndChartStat.length > 1;
  }
}
