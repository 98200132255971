import { action, computed, observable } from 'mobx';
import ProjectChildUI from './ProjectChildUI';

import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

import {
  MaterialForm,
  materialFormOptions,
  materialFormFields,
  materialFormRules,
  materialFormLabels,
  materialFormPlugins
} from 'forms/project/material';

export default class MaterialsUI extends ProjectChildUI {
  @observable materialForm;
  @observable materialLogField;

  constructor(options) {
    super(options);

    this.materialForm = null;
    this.materialLogField = null;
  }

  @action.bound async addMaterial(field) {
    await this.authorization.checkFeatureAccess('CUDMaterials');

    this.materialLogField = field;

    this.unitsUI.fetchUnits();

    this.materialForm = new MaterialForm(
      {
        fields: materialFormFields,
        rules: materialFormRules,
        labels: materialFormLabels
      },
      {
        options: materialFormOptions,
        plugins: materialFormPlugins,
        rootStore: this.rootStore
      }
    );

    this.showModal('MaterialSelectorAddModal');
  }

  @computed get unitOptions() {
    return this.rootStore.units.models.map(unit => {
      return {
        value: unit.uuid,
        name: unit.name
      };
    });
  }

  @computed get selectedUnitOption() {
    return this.unitOptions.find(
      option => option.uuid === this.materialForm.$('unit').value
    );
  }

  @action.bound async cancelMaterialAdd() {
    await this.hideActiveModal();

    this.materialForm = null;
    this.materialLogField = null;
  }

  @action.bound submitMaterialForm(event) {
    event.preventDefault();
    event.stopPropagation();

    this.materialForm.submit({
      onSuccess: this.submitMaterialFormSuccess,
      onError: this.submitMaterialFormError
    });
  }

  @action.bound async submitMaterialFormSuccess() {
    this.saving = true;

    const values = this.materialForm.values();

    try {
      const material = await this.materialSelectorUI.materials.create(
        Object.assign(values, {
          projects: [{ uuid: this.project.uuid }],
          unit: {
            uuid: values.unit
          }
        }),
        {
          wait: true
        }
      );

      this.updateExternalField(material);
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitMaterialFormError() {
    console.log(this.materialForm.errors());
  }

  @computed get existingMaterial() {
    return this.materialForm.existingMaterial;
  }

  @computed get materialExistsMessage() {
    return t('A material with this name and unit already exists.');
  }

  @computed get disableSaveButton() {
    return this.saving || this.existingMaterial;
  }

  @action.bound async addExistingMaterial() {
    const material = this.materialSelectorUI.materials.getOrAdd(
      this.materialForm.existingMaterial
    );

    // Add the existing material to the project if not already
    if (!material.getProjectByUuid(this.project.uuid)) {
      material.addProjectByUuid(this.project.uuid, {
        save: true
      });
    }

    this.updateExternalField(material);
  }

  @action.bound updateExternalField(material) {
    // Set it on the form
    this.materialLogField.set({
      uuid: material.uuid,
      name: material.name,
      unit: {
        uuid: material.unit.uuid,
        name: material.unit.name
      }
    });

    this.cancelMaterialAdd();
  }
}
