import { computed } from 'mobx';
import { RakenCollection } from 'stores/lib';
import Industry from 'stores/models/company/Industry';

export default class Industries extends RakenCollection {
  model() {
    return Industry;
  }

  url() {
    return `ra/company-info/industries`;
  }

  @computed get asOptions() {
    return this.models.map(model => {
      return {
        value: model.name,
        title: model.name
      };
    });
  }
}
