import omit from 'lodash.omit';
import moment from 'moment';
import { Model } from 'mobx-mc';
import { observable, computed } from 'mobx';

import Project from './Project';

export default class MissedDaily extends Model {
  @observable deleted;

  get restAttributes() {
    return [
      'id',
      'dateFor',
      'project',
      'company',
      'projectTeamId',
      'notified',
      'reportStatus',
      'segmentUuid'
    ];
  }

  parse(attributes) {
    this.project = new Project(
      {
        id: attributes.project.id,
        name: attributes.project.name,
        uuid: attributes.projectTeamUuid
      },
      {
        rootStore: this.rootStore
      }
    );

    return {
      ...omit(attributes, ['project'])
    };
  }

  @computed
  get projectName() {
    return this.project.name;
  }

  @computed
  get dateForFormatted() {
    return moment(this.dateFor).format('YYYY-MM-DD');
  }

  @computed
  get viewUrl() {
    const segmentQueryParam = this.segmentUuid
      ? `&segmentUuid=${this.segmentUuid}`
      : '';

    return `/projects/${this.project.uuid}/worklogs?date=${this.dateFor}${segmentQueryParam}`;
  }

  @computed
  get unsigned() {
    return this.reportStatus === 'UNSIGNED';
  }
}
