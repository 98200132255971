import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import { t } from 'utils/translate';
import trimObject from '../utils/trimObject';

const unitFormPlugins = extendedPlugins;

const unitFormOptions = {
  validateOnInit: false,
  strictUpdate: false,
  validateOnChange: true
};

const unitFormFields = ['name'];

const unitFormRules = {
  name: 'string|required'
};

const unitFormLabels = {
  name: t('unit of measurement')
};

class UnitForm extends MobxReactForm {
  trimmedValues() {
    return trimObject(this.values());
  }
}

export {
  UnitForm,
  unitFormOptions,
  unitFormFields,
  unitFormRules,
  unitFormLabels,
  unitFormPlugins
};
