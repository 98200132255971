import { action, computed } from 'mobx';
import DocumentsFiles from 'stores/collections/documents/DocumentsFiles';
import Attachment from 'stores/models/Attachment';
import DocumentFile from 'stores/models/documents/DocumentsFile';
import alertErrorHandler from 'utils/alertErrorHandler';
import bytesToSize from 'utils/bytesToSize';
import fileToBase64 from 'utils/fileToBase64';
import getFilePreviewIcon from 'utils/getFilePreviewIcon';

import history from 'utils/history';
import { t } from 'utils/translate';
import ProjectChildAddUI from '../ProjectChildAddUI';

export default class DocumentAddFilesUI extends ProjectChildAddUI {
  constructor(options) {
    super(options);

    this.files = new DocumentsFiles(null, {
      parent: this,
      rootStore: this.rootStore
    });
  }

  @computed get folder() {
    return this.parent.folder;
  }

  @action.bound setup() {
    this.files.reset(this.files.models.filter(file => file.document.isNew));
  }

  @computed get documentsStat() {
    return this.parent.documentsStat;
  }

  @computed get hasFilesUploading() {
    return this.files.hasModels;
  }

  @computed get uploadRequirements() {
    return t(`Supported file types: ${this.extensions.join(', ')}`);
  }

  @computed get documentsConstants() {
    return this.parent.documentsConstants;
  }

  @computed get extensions() {
    return this.documentsConstants.extensions;
  }

  @computed
  get uploadAcceptedFileTypes() {
    return this.documentsConstants.uploadAcceptedFileTypes;
  }

  @action.bound async handleDocumentUpload(uploadItem) {
    const file = uploadItem.file;

    if (file.size > 262144000) {
      this.rootStore.notificationsUI.pushNotification({
        title: `${t('File is too big ')} (${bytesToSize(file.size)}). ${t(
          'Limit is 250MB per file.'
        )}`,
        snackbar: 'error',
        icon: 'notification'
      });

      return;
    }

    this.documentsStat.bytesUsed += file.size; // Increment by each file size

    if (this.documentsStat.bytesUsed > this.documentsStat.storageLimitInBytes) {
      this.documentsStat.bytesUsed -= file.size; // Decrement current file size

      await this.showUpgradeModal();

      this.rootStore.notificationsUI.pushNotification({
        title: `${t('Cannot process upload ')} (${bytesToSize(file.size)}). ${t(
          'Storage limit exceeded.'
        )}`,
        snackbar: 'error',
        icon: 'notification'
      });

      return;
    }

    file.preview = await fileToBase64(file);

    const filePreviewIcon = getFilePreviewIcon(this.rootStore.assetsURL, file);

    const previewAttachment = new Attachment(
      {
        fileName: file.name,
        thumbUrl: filePreviewIcon
      },
      {
        rootStore: this.rootStore,
        skipThumbnailPolling: true
      }
    );

    const previewDocument = new DocumentFile(
      { name: file.name, document: previewAttachment },
      {
        rootStore: this.rootStore
      }
    );

    this.files.add(previewDocument);

    try {
      await this.files.upload({
        file: file,
        metadata: {
          projectUuid: this.projectUuid,
          parentFolderUuid: this.folder?.id
        },
        analytics: {
          module: 'Documents',
          project: {
            id: this.project.id,
            name: this.project.name
          }
        },
        previewDocument: previewDocument,
        progressCallback: percentCompleted => {
          // If we wanted to show a progress bar on the attachment
          // we can use previewAttachment.uploadProgress.
          previewDocument.document.setUploadProgress(percentCompleted);
        }
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.parent.sortByLastUpdated();
    }
  }

  @action.bound rejectFileType(fileName) {
    const extension = fileName.split('.').pop();

    this.notifications.pushNotification({
      title: `File of type .${extension} is not supported.`,
      snackbar: 'error',
      icon: 'notification'
    });
  }

  @action.bound showUpgradeModal() {
    this.parent.showUpgradeModal();
  }

  @action.bound hideUpgradeModal() {
    this.parent.hideActiveModal();
  }

  @action.bound cancelAddFiles() {
    history.push({
      pathname: `${this.folder?.viewUrl}`,
      search: this.baseQueryParams
    });
  }
}
