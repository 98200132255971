import request from 'axios';
import debounce from 'lodash.debounce';
import { reaction, computed, action, observable } from 'mobx';
import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import { t } from 'utils/translate';
import trimObject from '../utils/trimObject';
import CostCode from '../stores/models/CostCode';

const costCodeFormPlugins = extendedPlugins;

const costCodeFormOptions = {
  validateOnInit: false,
  strictUpdate: false,
  validateOnChange: true
};

const costCodeFormFields = [
  'uuid',
  'division',
  'code',
  'description',
  'isDefault'
];

const costCodeFormRules = {
  division: 'string|required|max:50',
  code: 'string|required|max:20',
  description: 'string|max:60',
  isDefault: 'boolean'
};

const costCodeFormLabels = {
  division: t('division'),
  code: t('code'),
  description: t('description')
};

class CostCodeForm extends MobxReactForm {
  @observable existingCostCode;

  constructor(settings, options) {
    super(settings, options);
    this.rootStore = options.rootStore;
    this.existingCostCode = null;

    this.searchCostCodes = debounce(this.searchCostCodes, 250);

    this.reactToSearchParams = reaction(
      () => this.searchParams,
      searchParams => {
        if (searchParams) {
          this.searchCostCodes();
        } else {
          this.existingCostCode = null;
        }
      }
    );
  }

  @computed get searchParams() {
    if (!this.$('division').value || !this.$('code').value) {
      return null;
    }

    return {
      division: this.$('division').value,
      code: this.$('code').value
    };
  }

  @action.bound searchCostCodes() {
    if (!this.searchParams) return;

    this.existingCostCode = null;

    request
      .get(
        `${this.rootStore.urlMicroService('performanceTracking')}/costcodes`,
        {
          params: this.searchParams
        }
      )
      .then(response => {
        if (response.data) {
          this.existingCostCode = new CostCode(response.data, {
            rootStore: this.rootStore
          });
        } else {
          this.existingCostCode = null;
        }
      })
      .catch(error => {
        this.existingCostCode = null;
      });
  }

  @computed get indentificationString() {
    return `${this.$('division').value}, ${this.$('code').value}`;
  }

  trimmedValues() {
    return trimObject(this.values());
  }
}

export {
  CostCodeForm,
  costCodeFormOptions,
  costCodeFormFields,
  costCodeFormRules,
  costCodeFormLabels,
  costCodeFormPlugins
};
