import { Model } from 'mobx-mc';
import { action, computed, toJS, observable } from 'mobx';
import kebabCase from 'lodash.kebabcase';

export default class Material extends Model {
  @observable new;

  constructor(data, options) {
    super(data, options);

    this.new = false;
  }

  get urlRoot() {
    return `${this.rootStore.urlMicroService('performanceTracking')}/materials`;
  }

  get restAttributes() {
    return ['uuid', 'name', 'unit', 'projects', 'isDefault', 'deleted'];
  }

  get restAttributeDefaults() {
    return {
      projects: []
    };
  }

  idAttribute() {
    return 'uuid';
  }

  getProjectByUuid = uuid => {
    return this.projects.find(project => project.uuid === uuid);
  };

  @action.bound toggleProjectForMaterial(uuid) {
    if (this.getProjectByUuid(uuid)) {
      this.removeProjectByUuid(uuid);
    } else {
      this.addProjectByUuid(uuid);
    }
  }

  @action.bound removeProjectByUuid(uuid, options = { save: true }) {
    this.projects.remove(this.getProjectByUuid(uuid));

    if (options.save) {
      return this.save({
        projects: toJS(this.projects)
      });
    }
  }

  @action.bound addProjectByUuid(uuid, options = { save: true }) {
    this.projects.push({
      uuid: uuid
    });

    if (options.save) {
      return this.save({
        projects: toJS(this.projects)
      });
    }
  }

  @computed get hasMultipleProjects() {
    return this.projects.length > 1;
  }

  @computed get identificationString() {
    return `${this.name}, ${this.unit.name}`;
  }

  @computed
  get formValues() {
    return {
      name: this.name,
      unit: this.unit.uuid
    };
  }

  @action.bound setAsNew() {
    this.new = true;
  }

  @action.bound clearNew() {
    this.new = false;
  }

  @computed get slug() {
    return kebabCase(this.name);
  }

  @computed
  get materialKey() {
    const { name, unit } = this;

    return `${name}-${unit.name}`;
  }
}
