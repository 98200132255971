import omit from 'lodash.omit';
import { Model } from 'mobx-mc';
import { action } from 'mobx';
import User from './User';
import Company from './Company';

export default class UserMeasure extends Model {
  get restAttributes() {
    return [
      'id',
      'type',
      'numberOfActivities',
      'signedReports',
      'compliancePercent',
      'ranking'
    ];
  }

  @action.bound
  parse(attributes) {
    this.parseUser(attributes);
    this.parseCompany(attributes);

    return {
      ...omit(attributes, ['user', 'company'])
    };
  }

  @action.bound
  parseUser(attributes) {
    this.user = new User(attributes.user, {
      rootStore: this.rootStore
    });
  }

  @action.bound
  parseCompany(attributes) {
    this.company = new Company(attributes.company, {
      rootStore: this.rootStore
    });
  }
}
