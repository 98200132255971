import UIStore from './UIStore';
import CompanyInfoUI from './CompanyInfoUI';
import CompanyRolesUI from './CompanyRolesUI';

import IntegrationsUI from 'stores/ui/company/integrations/IntegrationsUI';

import CompanySubscriptionBillingUI from 'stores/ui/CompanySubscriptionBillingUI';
import BillingUI from 'stores/ui/billing/BillingUI';

import ProjectTemplatesUI from 'stores/ui/company/projectTemplates/ProjectTemplatesUI';
import CompanyProductionUI from './CompanyProductionUI';
import CompanyToolboxTalksUI from './CompanyToolboxTalksUI';

import ChecklistTemplatesUI from 'stores/ui/company/checklists/ChecklistTemplatesUI';
import ChecklistCompletedUI from 'stores/ui/company/checklists/ChecklistCompletedUI';

import ChecklistTypesUI from 'stores/ui/company/checklists/ChecklistTypesUI';
import ChecklistSchedulesUI from 'stores/ui/company/checklists/ChecklistSchedulesUI';

import EquipmentUI from 'stores/ui/company/equipment/EquipmentUI';

import GroupsUI from 'stores/ui/company/groups/GroupsUI';

import CertificationTypesUI from './company/certifications/CertificationTypesUI';
import CertificationsUI from './company/certifications/CertificationsUI';
import IncidentsUI from 'stores/ui/company/incidents/IncidentsUI';
import IncidentsSubmissionSettingsUI from 'stores/ui/company/incidents/IncidentsSubmissionSettingsUI';

import CompanySettingsUI from './CompanySettingsUI';

import FormTemplatesUI from 'stores/ui/company/forms/FormTemplatesUI';
import FormsUI from 'stores/ui/company/forms/FormsUI';

export default class CompanyUI extends UIStore {
  constructor(options) {
    super(options);

    this.companyInfoUI = new CompanyInfoUI({
      rootStore: this.rootStore
    });

    this.companyRolesUI = new CompanyRolesUI({
      rootStore: this.rootStore
    });

    this.companySettingsUI = new CompanySettingsUI({
      rootStore: this.rootStore
    });

    this.integrationsUI = new IntegrationsUI({
      rootStore: this.rootStore
    });

    this.subscriptionBillingUI = new CompanySubscriptionBillingUI({
      rootStore: this.rootStore
    });

    this.subscriptionBillingRecurlyUI = new BillingUI({
      rootStore: this.rootStore
    });

    this.projectTemplatesUI = new ProjectTemplatesUI({
      rootStore: this.rootStore
    });

    this.companyProductionUI = new CompanyProductionUI({
      rootStore: this.rootStore
    });

    this.companyToolboxTalksUI = new CompanyToolboxTalksUI({
      rootStore: this.rootStore
    });

    this.checklistTypesUI = new ChecklistTypesUI({
      rootStore: this.rootStore
    });

    this.checklistTemplatesUI = new ChecklistTemplatesUI({
      rootStore: this.rootStore
    });

    this.checklistCompletedUI = new ChecklistCompletedUI({
      rootStore: this.rootStore
    });

    this.checklistSchedulesUI = new ChecklistSchedulesUI({
      rootStore: this.rootStore
    });

    this.certificationTypesUI = new CertificationTypesUI({
      rootStore: this.rootStore
    });

    this.certificationsUI = new CertificationsUI({
      rootStore: this.rootStore
    });

    this.incidentsUI = new IncidentsUI({
      rootStore: this.rootStore
    });

    this.companyEquipmentUI = new EquipmentUI({
      rootStore: this.rootStore
    });

    this.incidentsSubmissionSettingsUI = new IncidentsSubmissionSettingsUI({
      rootStore: this.rootStore
    });

    this.groupsUI = new GroupsUI({
      rootStore: this.rootStore
    });

    this.formTemplatesUI = new FormTemplatesUI({
      rootStore: this.rootStore
    });

    this.formsUI = new FormsUI({
      rootStore: this.rootStore
    });
  }
}
