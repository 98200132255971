import { RakenCollection } from '../../lib';
import ChecklistType from 'stores/models/checklists/ChecklistType';

export default class ChecklistTypes extends RakenCollection {
  model() {
    return ChecklistType;
  }

  url() {
    return `${this.rootStore.urlMicroService('toolbox')}/companies/${
      this.rootStore.me.company.uuid
    }/checklist-types`;
  }
}
