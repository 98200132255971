import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';

import { t } from 'utils/translate';

const settingsPdfLogoFormPlugins = extendedPlugins;

const settingsPdfLogoFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const settingsPdfLogoFormFields = ['projectLogo', 'primaryColor'];

const settingsPdfLogoFormLabels = {
  projectLogo: t('logo'),
  primaryColor: t('color')
};

const settingsPdfLogoFormRules = {
  projectLogo: 'string',
  primaryColor: 'string'
};

class SettingsPdfLogoForm extends MobxReactForm {}

export {
  SettingsPdfLogoForm,
  settingsPdfLogoFormOptions,
  settingsPdfLogoFormFields,
  settingsPdfLogoFormLabels,
  settingsPdfLogoFormRules,
  settingsPdfLogoFormPlugins
};
