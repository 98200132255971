import omit from 'lodash.omit';
import MobxReactForm from 'mobx-react-form';
import extendedPlugins from 'forms/utils/extendedPlugins';
import { t } from 'utils/translate';
import trimObject from 'utils/trimObject';

const userInfoFormInvitedUserPlugins = extendedPlugins;

const userInfoFormInvitedUserOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false,
  validatePristineFields: true
};

const userInfoFormInvitedUserFields = [
  'uuid', // Used in validation endpoint
  'companyUuid', // Used in validation endpoint
  'firstName',
  'lastName',
  'phone',
  'username',
  'password'
];

const userInfoFormInvitedUserRules = {
  firstName: 'string|required|max:255',
  lastName: 'string|required|max:255',
  phone: 'string|phone_available|max:30',
  username: 'email|max:200|required',
  password: 'required|string|min:8|max:64|minLowerCase:1|minUpperCase:1'
};

const userInfoFormInvitedUserLabels = {
  firstName: t('first name'),
  lastName: t('last name'),
  phone: t('phone'),
  username: t('email'),
  password: t('password')
};

class UserInfoFormInvitedUser extends MobxReactForm {
  trimmedValues() {
    return omit(trimObject(this.values()), ['companyUuid', 'uuid']);
  }
}

export {
  UserInfoFormInvitedUser,
  userInfoFormInvitedUserOptions,
  userInfoFormInvitedUserFields,
  userInfoFormInvitedUserRules,
  userInfoFormInvitedUserLabels,
  userInfoFormInvitedUserPlugins
};
