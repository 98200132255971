export default values => {
  return new Promise((resolve, reject) => {
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode(
      {
        address: Object.keys(values)
          .filter(key => key !== 'geolocation') // filter this out or the string will have [object Object] appended to the end
          .map(key => {
            return values[key];
          })
          .join(' ')
      },
      (results, status) => {
        if (status === 'OK') {
          resolve({
            location: results[0].geometry.location,
            postCode: results[0].address_components[6]
              ? results[0].address_components[6].long_name
              : null
          });
        } else {
          resolve(null);
        }
      }
    );
  });
};
