import request from 'axios';
import debounce from 'lodash.debounce';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import ProjectChildUI from './ProjectChildUI';

import Answers from '../../collections/Answers';
import SurveyEditUI from './SurveyEditUI';

import { BASE_DEBOUNCE } from 'fixtures/constants';
import alertErrorHandler from 'utils/alertErrorHandler';
import history from 'utils/history';

export default class SurveyUI extends ProjectChildUI {
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable loading;

  constructor(options) {
    super(options);

    this.loading = true;

    this.sortField = 'createdTimestamp';
    this.sortDirection = 'desc';
    this.pageSize = 1000;
    this.searchQuery = '';

    // Answers collection
    this.answers = new Answers(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.surveyEditUI = new SurveyEditUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.answerHints = observable([]);

    this.fetchAnswerHintsDebounced = debounce(
      this.fetchAnswerHints,
      BASE_DEBOUNCE
    );

    this.fetchAnswersDebounced = debounce(this.fetchAnswers, BASE_DEBOUNCE);
  }

  @action.bound async setup() {
    window.scrollTo(0, 0);
    this.setupReactions();
    await this.fetchAnswers();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchAnswersDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed
  get params() {
    return {
      fromDate: this.date,
      toDate: this.date,
      projectUuids: this.projectUuid,
      segmentUuids: this.segmentUuid,
      limit: this.pageSize,
      offset: 0,
      sortField: this.sortField,
      sortDirection: this.sortDirection
    };
  }

  @computed get hasRequiredParams() {
    return (
      this.params.projectUuids && this.params.fromDate && this.params.toDate
    );
  }

  @action.bound async fetchAnswers() {
    if (!this.hasRequiredParams) return;

    this.answers.cancelRequest();
    this.answers.clear();

    try {
      await this.answers.fetch({
        url: `ra/companies/${this.company.uuid}/answers`,
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }

    // populate empty answers
    if (!this.hasAnswers) {
      await this.populateEmptyAnswers();
      this.loading = false;
    } else {
      this.loading = false;
    }
  }

  @action.bound async refetchAnswers() {
    this.loading = true;
    await this.fetchAnswers();
    this.refetchReportSummary();
  }

  @computed get hasSurveyQuestions() {
    return (
      this.project.questions.length > 0 ||
      (this.project.isChildProject &&
        this.project.collaboratorQuestions.length > 0)
    );
  }

  @computed get hasAnswers() {
    return this.answers.hasModels;
  }

  @computed get hasSavedAnswers() {
    return this.answers.models.filter(answer => answer.uuid).length > 0;
  }

  @action.bound async populateEmptyAnswers() {
    if (this.project.isChildProject) {
      this.answers.add(
        this.project.collaboratorQuestions.map(question => {
          return {
            question: {
              id: question.id,
              question: question.question,
              category: question.category,
              version: question.version
            },
            answer: 'NA',
            desc: '',
            ...this.reportParams
          };
        })
      );
    }

    this.answers.add(
      this.project.questions.map(question => {
        return {
          question: {
            id: question.id,
            question: question.question,
            category: question.category,
            version: question.version
          },
          answer: 'NA',
          desc: '',
          ...this.reportParams
        };
      })
    );
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @computed get searchedAnswers() {
    if (!this.searchQuery) {
      return this.answers.models;
    }

    const query = this.searchQuery.toLowerCase();

    return this.answers.models.filter(answer => {
      const { question } = answer;

      return (
        question.question.toLowerCase().indexOf(query) >= 0 ||
        question.categoryText.toLowerCase().indexOf(query) >= 0 ||
        answer.answer.toLowerCase().indexOf(query) >= 0 ||
        answer.desc?.toLowerCase().indexOf(query) >= 0
      );
    });
  }

  @computed get hasSearchedAnswers() {
    return this.searchedAnswers.length > 0;
  }

  @action.bound clearUIState() {
    this.searchQuery = '';
    this.answers.clear();
    this.loading = true;
    this.saving = false;
    this.sortField = 'createdTimestamp';
    this.sortDirection = 'desc';
    this.searchQuery = '';
    this.answerHints.clear();
  }

  @computed get showEmptyState() {
    return !this.loading && !this.searchQuery && !this.hasAnswers;
  }

  @computed get showEmptySearchState() {
    return !this.loading && this.searchQuery && !this.hasSearchedAnswers;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @action.bound async editSurvey() {
    history.push({
      pathname: `${this.project.viewUrl}/survey/edit`,
      search: this.baseQueryParams
    });
  }

  @action.bound
  async fetchAnswerHints(questionId, value) {
    this.answerHints.clear();

    if (value.length < 3) return;

    const response = await request.get(
      `/ra/survey-answers/descriptions/${questionId}?mn=full&mc=full&offset=0&limit=10&query=${value}`
    );

    if (response.data.collection) {
      this.answerHints.replace(
        response.data.collection.map(hint => {
          return {
            questionId: questionId,
            hint: hint
          };
        })
      );
    }
  }
}
