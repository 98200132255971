import { action, computed } from 'mobx';
import ProjectSettingsBreaksUI from 'stores/ui/ProjectSettingsBreaksUI';

import toggleArrayFieldValue from 'forms/utils/toggleArrayFieldValue';

export default class ProjectTemplateBreaksUI extends ProjectSettingsBreaksUI {
  @computed
  get companyProjectTemplateForm() {
    return this.parent.companyProjectTemplateForm;
  }

  @action.bound
  async toggleBreakForTemplate(breakType) {
    toggleArrayFieldValue(
      this.companyProjectTemplateForm,
      'hiddenBreakTypes',
      breakType.uuid
    );
  }
}
