import styled, { css } from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: ${props =>
    props.direction === 'vertical' ? 'column' : 'row'};
  label {
    ${props =>
      props.direction === 'horizontal' &&
      css`
        margin-right: 1.5rem;
        &:last-child {
          margin-right: 0;
        }
      `} ${props =>
      props.direction === 'vertical' &&
      css`
        margin-bottom: 1.5rem;
        &:last-child {
          margin-bottom: 0;
        }
      `};
  }
  &:focus {
    outline: 0;
  }
`;
