import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';
import trimObject from '../../utils/trimObject';
import { t } from 'utils/translate';

const shareFormFormPlugins = extendedPlugins;

const shareFormFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false,
  retrieveOnlyEnabledFields: false
};

const shareFormFormFields = ['emails[]', 'email'];

const shareFormFormRules = {
  emails: 'array|required',
  email: 'email'
};

const shareFormFormLabels = {
  emails: t('recipients')
};

const shareFormFormValues = {
  emails: [],
  email: ''
};

class ShareFormForm extends MobxReactForm {
  trimmedValues() {
    return trimObject(this.values());
  }
}

export {
  ShareFormForm,
  shareFormFormOptions,
  shareFormFormFields,
  shareFormFormRules,
  shareFormFormValues,
  shareFormFormLabels,
  shareFormFormPlugins
};
