import styled from 'styled-components';
import SelectBoxItem from './SelectBoxItem';

export default styled(SelectBoxItem)`
  bottom: -5px;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  position: relative;
  height: 20px;
  text-transform: capitalize;
  &:before {
    content: ' ';
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
    display: block;
    height: 20px;
    position: absolute;
    left: 0;
    top: -20px;
    width: 100%;
  }
`;
